import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Menu } from 'semantic-ui-react';
import { Translate } from 'react-redux-i18n';
import * as accountSettingsActions from './reducer';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { settingsMenuItems, i18nKeys } from '../constants';
import get from 'lodash.get';

class VerticalMenu extends Component {
  state = { activeItem: settingsMenuItems.ACCOUNT_DETAILS };

  handleItemClick = (e, { name }) => {
    this.props.menuItemClick(name);
  };

  render() {
    const { ACCOUNT_DETAILS, UPDATE_PASSWORD, EYES_ONLY, ACCOUNT_SECURITY, EMAIL_NOTIFICATIONS } =
      settingsMenuItems;
    const showSecurityTab =
      !this.props.impersonated &&
      this.props.accountId.toString() === this.props.match.params.id;

    return (
      <Menu vertical fluid>
        <Menu.Item
          name={ACCOUNT_DETAILS}
          active={this.props.activeItem === ACCOUNT_DETAILS}
          as="a"
          onClick={this.handleItemClick}
        >
          <Translate value={i18nKeys.ACCOUNT_DETAILS_ITEM} />
        </Menu.Item>
        <Menu.Item
          name={UPDATE_PASSWORD}
          active={this.props.activeItem === UPDATE_PASSWORD}
          as="a"
          onClick={this.handleItemClick}
        >
          <Translate
            value={i18nKeys.UPDATE_PASSWORD_ITEM}
            onClick={this.handleItemClick}
          />
        </Menu.Item>
        {showSecurityTab && (
          <Menu.Item
            name={ACCOUNT_SECURITY}
            active={this.props.activeItem === ACCOUNT_SECURITY}
            as="a"
            onClick={this.handleItemClick}
          >
            <Translate
              value={i18nKeys.ACCOUNT_SECURITY_ITEM}
              onClick={this.handleItemClick}
            />
          </Menu.Item>
        )}
        {(this.props.isOrgAdmin || this.props.isAccountCampaignAdmin) && (
          <Menu.Item
            name={EMAIL_NOTIFICATIONS}
            active={this.props.activeItem === EMAIL_NOTIFICATIONS}
            as="a"
            onClick={this.handleItemClick}
          >
            <Translate
              value={i18nKeys.EMAIL_NOTIFICATIONS_ITEM}
              onClick={this.handleItemClick}
            />
          </Menu.Item>
        )}
        {this.props.isSystemAdmin && !this.props.isAccountSystemAdmin && (
          <Menu.Item
            name={EYES_ONLY}
            active={this.props.activeItem === EYES_ONLY}
            as="a"
            onClick={this.handleItemClick}
          >
            <Translate
              value={i18nKeys.EYES_ONLY_ITEM}
              onClick={this.handleItemClick}
            />
          </Menu.Item>
        )}
      </Menu>
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  const activeItem = accountSettings.activeItem;
  const account = accountSettings.record.data.data;
  
  return {
    activeItem,
    accountId: get(session, 'account.id'),
    isOrgAdmin: account.isOrgAdmin,
    impersonated: get(session, 'impersonated'),
    isSystemAdmin: get(session, 'isSystemAdmin'),
    isAccountSystemAdmin: account.isSysAdmin,
    isAccountCampaignAdmin: get(
      accountSettings,
      'record.data.data.isCampaignAdmin'
    )
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(accountSettingsActions, dispatch);
};

export default withRouter(connect(mapState, mapDispatch)(VerticalMenu));
