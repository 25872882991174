import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Button, Icon, Modal, Popup, TextArea } from 'semantic-ui-react';
import { useAppDispatch } from '../../../hooks';
import { AppDispatch, RootState } from '../../../store';
import { paths } from '../store/paths';
import * as ticketingActions from '../store/ticketingActions';
import '../_styles/modals/viewPaymentUrlWindow.css';

export const ViewPaymentUrlWindow = () => {
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch: AppDispatch = useAppDispatch();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const installmentManualPaymentRequest = ticketing?.relatedRecord?.data?.data?.installmentManualPaymentRequests.slice(-1)[0]
 
  const getDaysRemainingText = () => {
    const today = new Date();
    const expiresAt = new Date(installmentManualPaymentRequest?.expiresAt);
    //@ts-ignore
    const diffTime = Math.abs(expiresAt - today);
    const daysRemaining = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    
    return daysRemaining == 1 ? `${daysRemaining} day` : `${daysRemaining} days`;
  }

  const onClose = () => {
    dispatch(ticketingActions.onValueChange(paths.paymentUrlWindowIsOpen, false));
  }

  const isOpen = ticketing?.sale?.paymentUrlWindow?.isOpen;
  const daysRemainingText = getDaysRemainingText();
  const link = installmentManualPaymentRequest?.url;

  const handleOpen = () => {
    setIsPopupOpen(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 3500);
  };

  return (
    <Modal
      className="view-payment-url-window"
      size="tiny"
      dimmer="inverted"
      closeIcon
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {I18n.t('ticketing.edit.collect-payment.view-payment-url-modal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className="content">
          {I18n.t('ticketing.edit.collect-payment.view-payment-url-modal.content')}
        </div>
        <div className="sub-content">
          {I18n.t('ticketing.edit.collect-payment.view-payment-url-modal.sub-content', { daysRemainingText })}
        </div>
        <div className="text-area-container">
          <label>Payment link</label>
          <TextArea disabled value={link}/>
        </div>
        <div className="payment-link-section">
          <Popup
            className='payment-link-section-copy-link'
            trigger={
              <div
                className="item"
                onClick={() => {
                  handleOpen();
                  navigator.clipboard.writeText(link);
                }}
              >
                <Icon name="copy" />
                <span>
                  {I18n.t(
                    'ticketing.edit.collect-payment.view-payment-url-modal.payment-link-section.buttons.copy'
                  )}
                </span>
              </div>
            }
            content={"Copied!"}
            on="click"
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
          />
          <a className="item" href={link} target="_blank">
            <Icon name="eye"/>
            <span>
              {I18n.t('ticketing.edit.collect-payment.view-payment-url-modal.payment-link-section.buttons.preview')}
            </span>
          </a>
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={onClose}>Close</Button>
      </Modal.Actions>
    </Modal>
  );

  
}
