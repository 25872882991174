export default Object.assign(String.prototype, {
  addFieldName(fieldName) {
    return `${this}?fieldName=${fieldName}`;
  },
  addFieldAggregate(fieldAggregate) {
    return `${this}&fieldAggregate=${fieldAggregate}`;
  },
  addFilter(key, operator, value, index) {
    return `${this}&filters[${index}].key=${key}&filters[${index}].operator=${operator}&filters[${index}].value=${value}`;
  }
});
