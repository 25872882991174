import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as organizationActions from './store/organizationActions';

import * as querystring from 'querystring';
import * as templateHelper from '../../components/template/templateHelper';

import { Grid, Item, Button } from 'semantic-ui-react';

import { Record } from '../../modules/bin/utility';

import salesforceIcon from '../../images/salesforceIcon.png';

class TemplateSalesforceConnection extends Component {
  onConnectClick = () => {
    this.props.organizationActions.getSalesforceLink(this.props.organizationId);
  };

  redirectToSalesforce = () => {
    let parsedUrl = querystring.parse(
      this.props.salesforceLinkRecord.data.data
    );
    parsedUrl['redirect_uri'] = `${window.location.origin}/salesforce/connect`;
    window.location.href = unescape(querystring.stringify(parsedUrl));
  };

  render() {
    if (Record.isRecordReady(this.props.salesforceLinkRecord)) {
      this.redirectToSalesforce();
    }
    const buttonCaption = this.props.isConnected ? 'Connected' : 'Connect';

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={11}>
            <Item.Group>
              <Item>
                <Item.Image size="tiny" src={salesforceIcon} />
                <Item.Content>
                  <Item.Header>Salesforce</Item.Header>
                  <Item.Description>
                    <div>
                      Allow Grassrootz to automatically push information into
                      your Salesforce account in real time.
                    </div>
                    <div>Application access: Read and write</div>
                  </Item.Description>
                </Item.Content>
              </Item>
            </Item.Group>
          </Grid.Column>
          <Grid.Column width={5}>
            <br />
            <Button
              id="btnStripeConnect"
              style={{ marginRight: '1.1%' }}
              floated="right"
              onClick={this.onConnectClick}
              loading={Record.isRecordLoading(this.props.salesforceLinkRecord)}
              disabled={this.props.isConnected}
            >
              {buttonCaption}
            </Button>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapState = (state, ownProps) => {
  const crmPlatforms = templateHelper.getValue(
    state.templateBuilderEx,
    ownProps.path
  );

  const isConnected = crmPlatforms.some((item) => {
    return item.platform === 'salesforce';
  });

  return {
    isConnected,
    organizationId: templateHelper.getValue(state.templateBuilderEx, 'id'),
    salesforceLinkRecord: state.organization.salesforceLinkRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch)
  };
};
export default withRouter(
  connect(mapState, mapDispatch)(TemplateSalesforceConnection)
);
