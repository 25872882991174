import { uniq } from 'lodash';
import get from 'lodash.get';

export const isArrayWithItems = (arr, length = 1) => {
  if (!arr) {
    return false;
  }

  return Array.isArray(arr) && arr.length >= length;
};

export const hasArrayDuplicates = (arr) => {
  if (!isArrayWithItems(arr)) {
    return false;
  }

  return uniq(arr).length !== arr.length;
};

export const sortArray = (arr, path) => {
  if (arr && arr.length > 0) {
    if (!path) {
      return arr.sort();
    }
    return arr.sort((element1, element2) => {
      return get(element1, path) > get(element2, path) ? 1 : -1;
    });
  }
  return arr;
};

export const arrayToString = (arr) => {
  if (arr && arr.length > 0) {
    return arr.join();
  }
  return '';
};

export const stringToArray = (str) => {
  if (str) {
    return str.split(',').map((item) => item.trim());
  }
  return [];
};
