import React from 'react';
import { Table, Dropdown } from 'semantic-ui-react';

class PriorityDropdown extends React.Component {
  render() {
    const { value, id, getDefaultOption, onPriorityChange, disabled, options } =
      this.props;

    return (
      <Table.Cell collapsing className="priority-cell">
        <Dropdown
          disabled={disabled}
          value={value ? value : getDefaultOption(id)}
          options={options}
          onChange={(event, value) => {
            onPriorityChange(event, value, id);
          }}
        />
      </Table.Cell>
    );
  }
}

export default PriorityDropdown;
