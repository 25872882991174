import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { cloneDeep } from 'lodash';
import update from '../../helpers/update';
import { withRouter } from 'react-router-dom';

import get from 'lodash.get';

import * as templateBuilderActions from '../../modules/template-builder-ex';
import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';

import { getSetupTab } from './templateTabs/setup';
import { getTiersTab } from './templateTabs/tiers';
import { getOrganizationsTab } from './templateTabs/organizations';
import { getFundraisingTab } from './templateTabs/fundraising';
import { getDonationsTab } from './templateTabs/donations';
import { getAdvancedTab } from './templateTabs/advanced';
import { getFitnessTab } from './templateTabs/fitness';
import { getEyesOnlyTab } from './templateTabs/eyesOnly';
import { campaignContract } from '../campaign/create/templates';

import { getAboutValidation } from '../../helpers/pageAboutHelper';
import { getTimezoneOptions } from '../../helpers/timezoneOptionsHelper';
import * as templateHelper from '../../components/template/templateHelper';

import { TARGET_VALIDATION } from '../../constants/validationModels';
import {
  defaultFitnessTrackedStatistics,
  defaultFitnessActivities,
  raisedTotalTypeOptions,
  fitnessMeasure
} from '../campaign/constants';
import { paths } from './constants/template';
import config from '../../constants';
import { getCustomActivitiesTab } from './templateTabs/customActivities';
import { isArrayWithItems } from '../../helpers/arrayHelper';

class EventTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.nameValidation = {
      required: true,
      maxLength: true,
      length: config.EVENT_NAME_MAX_LENGTH
    };

    this.subdomainValidation = {
      required: true,
      maxLength: true,
      length: config.URL_PATH_MAX_LENGTH
    };

    this.formBuilderQuestionsValidation = {
      formBuilderQuestions: true
    };

    this.defaultAmountsValidation = {
      defaultAmounts: true
    };

    this.urlValidation = {
      url: true
    };

    this.targetValidation = {
      required: false,
      ...TARGET_VALIDATION
    };

    this.timeDetailsValidation = {
      maxLength: true,
      length: config.TIME_DETAILS_MAX_LENGTH
    };
this.locationValidation = {
      maxLength: true,
      length: config.LOCATION_MAX_LENGTH
    };

    this.urlValidation = {
      required: true,
      url: true
    };

    this.dateValidation = {
      date: true
    };

    this.maxWith200Style = {
      width: '224px'
    };

    this.eventAboutValidation = getAboutValidation();

    this.campaignAboutValidation = {
      validateAboutElements: true
    };

    this.fitnessActivitiesValidation = {
      fitnessActivities: true
    };

    this.fitnessTrackedStatisticsValidation = {
      fitnessTrackedStatistics: true
    };

    this.fitnessTargetsValidation = {
      fitnessTargets: true,
      optionalParamPath: campaignContract.fitnessThermometersToShow
    };

    this.timeZone = {
      required: true
    };
  }

  onChange = (path, value, _item) => {
    this.props.setResultValue(path, value);
  };

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getEventOrganizerRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  onOrganizationChange = (path, value, item) => {
    const orgPrimaryColor = get(item, 'template.value.primaryColor');
    const orgSecondaryColor = get(item, 'template.value.secondaryColor');

    this.props.setResultValue(
      paths.PRIMARY_COLOR,
      orgPrimaryColor || '#FF0000'
    );
    this.props.setResultValue(
      paths.SECONDARY_COLOR,
      orgSecondaryColor || '#0000FF'
    );

    if (item && item.paymentPlatforms && item.paymentPlatforms.length !== 0) {
      this.props.setResultValue(
        'organizationPaymentPlatformId',
        item.paymentPlatforms[0].id
      );
    }

    this.props.setResultValue(path, value);
  };

  onEventOrganizerChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  getOrganizationItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  getEventOrganizerItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  getTicketingValidation = () => {
    const toggleValue = get(this.props, `templateBuilder.${paths.TICKETING}`);

    return toggleValue
      ? [this.getChildValidation(paths.PURCHASE_TICKET_URL, this.urlValidation)]
      : [];
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  fundraisingComponentEnabledChange = (value, contractPath) => {
    if (value) {
      this.props.showControl(contractPath);
    } else {
      this.props.changeItemVisibility(contractPath, false, false);
    }
  };

  onFundraiserEnabledChange = (path, value) => {
    const teamsEnabled = get(
      this.props,
      `templateBuilder.${paths.TEAMS_ENABLED}`
    );
    const fundraisingVisible = value || teamsEnabled;

    this.fundraisingComponentEnabledChange(
      value,
      paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES
    );
    this.fundraisingComponentEnabledChange(
      fundraisingVisible,
      'defaultAboutHeader'
    );

    this.onChange(path, value);
  };

  onTeamEnabledChange = (path, value) => {
    const fundraisersEnabled = get(
      this.props,
      `templateBuilder.${paths.FUNDRAISERS_ENABLED}`
    );
    const fundraisingVisible = value || fundraisersEnabled;

    this.fundraisingComponentEnabledChange(
      value,
      paths.DEFAULT_TEXT_FOR_TEAM_PAGES
    );
    this.fundraisingComponentEnabledChange(
      fundraisingVisible,
      'defaultAboutHeader'
    );

    this.onChange(path, value);
  };

  getConditionValidation = (parentPath, childPath, validationModel) => {
    const parentCondition = get(this.props, `templateBuilder.${parentPath}`);
    const childCondition = childPath
      ? get(this.props, `templateBuilder.${childPath}`)
      : true;
    return parentCondition && childCondition ? validationModel : [];
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  getTargetValidation = (parentPath, childPath, contractPath) => {
    const validationModel = [
      this.getChildValidation(contractPath, this.targetValidation)
    ];
    return this.getConditionValidation(parentPath, childPath, validationModel);
  };

  onAllowFitnessChange = (path, value) => {
    if (
      !this.props.fitnessEnabled &&
      this.props.fitnessAllowedTypes === null &&
      this.props.fitnessAllowedTrackedStatistics === null
    ) {
      this.onChange(
        campaignContract.fitnessAllowedTypes,
        defaultFitnessActivities
      );
      this.onChange(
        campaignContract.fitnessAllowedTrackedStatistics,
        defaultFitnessTrackedStatistics
      );
    }

    this.props.changeItemVisibility(
      campaignContract.fitnessAllowedTypes,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.fitnessTargets,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.fitnessAllowedPlatforms,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.allowPageTracking,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.fitnessAllowedTrackedStatistics,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.fitnessThermometersToShow,
      value,
      false
    );
    this.props.changeItemVisibility(
      campaignContract.fitnessLeaderboardsToShow,
      value,
      false
    );
    this.props.changeItemVisibility('fitnessSetup', value, false);

    if (!value) {
      this.props.changeItemVisibility(
        'fitnessFundraiserDistanceTarget',
        value,
        false
      );
      this.props.changeItemVisibility(
        'fitnessFundraiserTimeTarget',
        value,
        false
      );
      this.props.changeItemVisibility(
        'fitnessFundraiserNumberOfStepsTarget',
        value,
        false
      );
      this.props.changeItemVisibility(
        'fitnessTeamDistanceTarget',
        value,
        false
      );
      this.props.changeItemVisibility('fitnessTeamTimeTarget', value, false);
      this.props.changeItemVisibility(
        'fitnessTeamNumberOfStepsTarget',
        value,
        false
      );
    }

    this.onChange(path, value);
  };

  onFundraiserFitnessTargetAmountChange = (key, value) => {
    const targetIndex = isArrayWithItems(this.props.fitnessTargets)
      ? this.props.fitnessTargets.findIndex((item) => item.key === key)
      : -1;

    if (targetIndex !== -1) {
      this.onChange(
        'fitnessSetup.targets',
        update(this.props.fitnessTargets, {
          [targetIndex]: {
            fundraiserDefaultTarget: {
              $set: value
            }
          }
        })
      );
    } else {
      const newFitnessTargets = [
        ...this.props.fitnessTargets,
        {
          key,
          fundraiserDefaultTarget: value,
          teamDefaultTarget: null,
          target: null
        }
      ];
      this.onChange('fitnessSetup.targets', newFitnessTargets);
    }
  };

  onTeamFitnessTargetAmountChange = (key, value) => {
    const targetIndex = isArrayWithItems(this.props.fitnessTargets)
      ? this.props.fitnessTargets.findIndex((item) => item.key === key)
      : -1;

    if (targetIndex !== -1) {
      this.onChange(
        'fitnessSetup.targets',
        update(this.props.fitnessTargets, {
          [targetIndex]: {
            teamDefaultTarget: {
              $set: value
            }
          }
        })
      );
    } else {
      const newFitnessTargets = [
        ...this.props.fitnessTargets,
        {
          key,
          fundraiserDefaultTarget: null,
          teamDefaultTarget: value,
          target: null
        }
      ];
      this.onChange('fitnessSetup.targets', newFitnessTargets);
    }
  };

  onAllowFundraiserAndTeamFitnessTargetsChange = (path, value) => {
    this.props.changeItemVisibility('fitnessFundraiserDates', value, true);

    const isTargetsAvailable = isArrayWithItems(this.props.fitnessTargets);
    const fitnessDistanceTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.distance
      );
    const fitnessTimeTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.time
      );
    const fitnessNumberOfStepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.numberOfSteps
      );

    if (isTargetsAvailable && !value) {
      let fitnessTargets = cloneDeep(this.props.fitnessTargets);

      const fitnessDistanceTargetIndex = this.props.fitnessTargets.indexOf(
        fitnessDistanceTarget
      );
      const fitnessTimeTargetIndex =
        this.props.fitnessTargets.indexOf(fitnessTimeTarget);
      const fitnessNumberOfStepsTargetIndex = this.props.fitnessTargets.indexOf(
        fitnessNumberOfStepsTarget
      );

      if (fitnessDistanceTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessDistanceTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      if (fitnessTimeTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessTimeTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      if (fitnessNumberOfStepsTarget) {
        fitnessTargets = update(fitnessTargets, {
          [fitnessNumberOfStepsTargetIndex]: {
            teamDefaultTarget: {
              $set: null
            },
            fundraiserDefaultTarget: {
              $set: null
            }
          }
        });
      }

      this.onChange(campaignContract.fitnessTargets, fitnessTargets);
    }

    this.onChange(path, value);
  };

  onAllowTimeAndPlaceChange = (path, value) => {
    this.props.changeItemVisibility('dateAndTime', value, false);
    this.props.changeItemVisibility('timeAndPlace.time.year', value, false);
    this.props.changeItemVisibility('eventLocation', value, false);
    this.props.changeItemVisibility(campaignContract.timeDetails, value, true);
    this.props.changeItemVisibility(campaignContract.place, value, true);

    if (!value) {
      this.onChange(paths.START_DATE, null);
      this.onChange('timeAndPlace.time.start', null);
      this.onChange(paths.END_DATE, null);
      this.onChange('timeAndPlace.time.end', null);
    }

    this.onChange(path, value);
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  onCityAutoCompleteChange = (value) => {
    const country = get(value, 'country');
    const state = get(value, 'state');

    if (!value.searchString) {
      this.onChange('timeAndPlace.place.country', null);
      this.onChange('timeAndPlace.place.state', null);
      this.onChange('timeAndPlace.place.city', null);
    } else {
      this.onChange('timeAndPlace.place.country', country);
      this.onChange('timeAndPlace.place.state', state);
      this.onChange(
        'timeAndPlace.place.city',
        get(value, 'suburb') || get(value, 'colloquial_suburb')
      );
    }

    this.onChange('eventLocation', value);
  };

  onEnableCustomActivitiesChange = (path, value) => {
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_NAME,
      value,
      true
    );

    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED,
      value,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_LEADERBOARD_ENABLED,
      value,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_UNIT,
      value,
      true
    );

    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD,
      false,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD,
      false,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD,
      false,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_SVG,
      false,
      true
    );

    this.onChange(path, value);
  };

  onEnableCustomActivitiesThermometerChange = (path, value) => {
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD,
      value,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD,
      value,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD,
      value,
      true
    );
    this.props.changeItemVisibility(
      paths.CUSTOM_ACTIVITIES_VALUE1_SVG,
      value,
      true
    );

    this.onChange(path, value);
  };

  render() {
    const isTargetsAvailable = isArrayWithItems(this.props.fitnessTargets);
    const fitnessDistanceTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.distance
      );
    const fitnessTimeTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.time
      );
    const fitnessNumberOfStepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.numberOfSteps
      );

    const setupTab = getSetupTab({
      onChange: this.onChange,
      onCityAutoCompleteChange: this.onCityAutoCompleteChange,
      onAllowTimeAndPlaceChange: this.onAllowTimeAndPlaceChange,
      getChildValidation: this.getChildValidation,
      getOrganizationRequest: this.getOrganizationRequest,
      getOrganizationItem: this.getOrganizationItem,
      onOrganizationChange: this.onOrganizationChange,
      onEventOrganizerChange: this.onEventOrganizerChange,
      onEventOrganizerRequest: this.getEventOrganizerRequest,
      getEventOrganizerItem: this.getEventOrganizerItem,
      getTimeZoneOptions: getTimezoneOptions,
      required: this.required,
      nameValidation: this.nameValidation,
      subdomainValidation: this.subdomainValidation,
      eventAboutValidation: this.eventAboutValidation,
      campaignAboutValidation: this.campaignAboutValidation,
      targetValidation: this.targetValidation,
      locationValidation: this.locationValidation,
      timeDetailsValidation: this.timeDetailsValidation,
      urlValidation: this.urlValidation,
      dateValidation: this.dateValidation,
      timeZone: this.timeZone
    });

    const tiersTab = getTiersTab({
      onChange: this.onChange,
      clearItems: this.props.clearItems,
      targetValidation: this.targetValidation
    });

    const organizationsTab = getOrganizationsTab({
      isTiersEnabled: this.props.isTiersEnabled,
      tiersNames: this.props.tiersNames,
      onChange: this.onChange,
    });

    const fundraisingTab = getFundraisingTab({
      onChange: this.onChange,
      onFundraiserEnabledChange: this.onFundraiserEnabledChange,
      onTeamEnabledChange: this.onTeamEnabledChange,
      formBuilderQuestionsValidation: this.formBuilderQuestionsValidation,
      required: this.required,
      targetValidation: this.targetValidation,
      getTargetValidation: this.getTargetValidation
    });

    const donationsTab = getDonationsTab({
      onChange: this.onChange,
      required: this.required,
      formBuilderQuestionsValidation: this.formBuilderQuestionsValidation,
      defaultAmountsValidation: this.defaultAmountsValidation
    });

    const ticketingValidation = this.getTicketingValidation();
    const advancedTab = getAdvancedTab({
      onChange: this.onChange,
      ticketingValidation: ticketingValidation,
      urlValidation: this.urlValidation,
      maxWith200Style: this.maxWith200Style,
      isSystemAdmin: this.props.isSystemAdmin,
    });

    const fitnessTab = getFitnessTab({
      isSystemAdmin: this.props.isSystemAdmin,
      targetValidation: this.targetValidation,
      onChangeItemVisibility: this.props.changeItemVisibility,
      fitnessDistanceTarget: fitnessDistanceTarget,
      fitnessTimeTarget: fitnessTimeTarget,
      fitnessNumberOfStepsTarget: fitnessNumberOfStepsTarget,
      onChange: this.onChange,
      onAllowFitnessChange: this.onAllowFitnessChange,
      onTeamFitnessTargetAmountChange: this.onTeamFitnessTargetAmountChange,
      onFundraiserFitnessTargetAmountChange:
        this.onFundraiserFitnessTargetAmountChange,
      onAllowFundraiserAndTeamFitnessTargetsChange:
        this.onAllowFundraiserAndTeamFitnessTargetsChange,
      fitnessActivitiesValidation: this.fitnessActivitiesValidation,
      fitnessTrackedStatisticsValidation:
        this.fitnessTrackedStatisticsValidation,
      fitnessTargetsValidation: this.fitnessTargetsValidation
    });

    const eyesOnlyTab = getEyesOnlyTab({
      onChange: this.onChange,
      raisedTotalTypeOptions: raisedTotalTypeOptions
    });

    const customActivitiesTab = getCustomActivitiesTab({
      onChange: this.onChange,
      onEnableCustomActivitiesChange: this.onEnableCustomActivitiesChange,
      onEnableCustomActivitiesThermometerChange:
        this.onEnableCustomActivitiesThermometerChange,
      customActivitiesValue1Unit: this.props.customActivitiesValue1Unit
    });
    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
        className="event-template"
      >
        {setupTab}
        {tiersTab}
        {organizationsTab}
        {fundraisingTab}
        {donationsTab}
        {advancedTab}
        {fitnessTab}
        {this.props.isSystemAdmin && eyesOnlyTab}
        {this.props.isSystemAdmin && customActivitiesTab}
      </TemplateBuilderContainerEx>
    );
  }
}

const getTiersNames = (tiers) => {
  if (tiers) {
    const tiersNames = [];
    Object.keys(tiers).forEach((tierKey) => {
      tiersNames[tierKey] = tiers[tierKey] && tiers[tierKey].name;
    });
    return tiersNames;
  }
};

const mapState = ({ templateBuilderEx, session }) => {
  const tiers = templateHelper.getValue(
    templateBuilderEx,
    'template.value.tiers'
  );
  const tiersNames = getTiersNames(tiers);
  return {
    templateBuilder: templateBuilderEx.data,
    fitnessTargets: templateHelper.getValue(
      templateBuilderEx,
      campaignContract.fitnessTargets
    ),
    isTiersEnabled: templateHelper.getValue(templateBuilderEx, paths.HAS_TIERS),
    customActivitiesValue1Unit: templateHelper.getValue(
      templateBuilderEx,
      paths.CUSTOM_ACTIVITIES_VALUE1_UNIT
    ),
    tiersNames,
    isSystemAdmin: get(session, 'isSystemAdmin')
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const EventTemplate = withRouter(
  connect(mapState, mapDispatch)(EventTemplateView)
);
export default EventTemplate;
