import { Record } from '../../modules/bin/utility';
import * as entityHandlers from '../../modules/bin/entityHandlers';

export const SALESFORCE_CONNECTION_REQUESTED =
  'salesforce/SALESFORCE_CONNECTION_REQUESTED';
export const SALESFORCE_CONNECTION_SUCCESS =
  'salesforce/SALESFORCE_CONNECTION_SUCCESS';
export const SALESFORCE_CONNECTION_FAILURE =
  'salesforce/SALESFORCE_CONNECTION_FAILURE';
export const ERROR_CLEAR = 'salesforce/ERROR_CLEAR';

const initialState = {
  salesforceConnectRecord: Record.getDefaultState()
};

const salesforceReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case SALESFORCE_CONNECTION_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case SALESFORCE_CONNECTION_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case SALESFORCE_CONNECTION_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;
    default:
      return newState;
  }

  return newState;
};

export const salesforceConnect = (state, code) => {
  const requestModel = {
    state: state,
    code: code,
    platform: 'Salesforce'
  };

  return entityHandlers.createRecord(
    'organization/crm',
    SALESFORCE_CONNECTION_REQUESTED,
    SALESFORCE_CONNECTION_SUCCESS,
    SALESFORCE_CONNECTION_FAILURE,
    requestModel,
    null,
    'salesforceConnectRecord'
  );
};

export const clearRecordMessages = (recordKey) => ({
  type: ERROR_CLEAR,
  payload: { key: recordKey }
});

export default salesforceReducer;
