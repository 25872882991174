import React from 'react';
import { Form, Input } from 'semantic-ui-react';
import { verificationVariant } from '../../constants/multiFactorAuth';
import { Record } from '../../modules/bin/utility';

class RecoveryForm extends React.Component {
  componentDidUpdate = (prevProps) => {
    this.handleSecurityApprovalError(prevProps);
  };

  handleSecurityApprovalError = (prevProps) => {
    if (this.props.verificationRecord) {
      const isPrevRecordError = Record.isError(prevProps.verificationRecord);
      const isCurrentRecordError = Record.isError(
        this.props.verificationRecord
      );

      if (isCurrentRecordError && isPrevRecordError !== isCurrentRecordError) {
        this.props.handleValueChange(`${this.props.path}.recoveryCode`, null);
      }
    }
  };

  handleValueChange = (event) => {
    const value = event.target.value || '';
    this.props.handleValueChange(`${this.props.path}.recoveryCode`, value);
  };

  handleVerificationVariantChange = () => {
    this.props.handleVerificationVariantChange(verificationVariant.authCode);
    this.props.handleValueChange(`${this.props.path}.recoveryCode`, null);
  };

  render() {
    return (
      <React.Fragment>
        <Form>
          <Form.Field>
            <label>Recovery Code</label>
            <Input
              autofocus
              autocomplete="off"
              value={this.props.value || ''}
              onChange={this.handleValueChange}
            />
          </Form.Field>
        </Form>
        <p className="alternative-variant">
          <p className="Links" onClick={this.handleVerificationVariantChange}>
            Use 2 Factor Authorisation code
          </p>
        </p>
      </React.Fragment>
    );
  }
}

export default RecoveryForm;
