export const METADATA_REQUESTED = 'donation/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'donation/METADATA_SUCCESS';
export const METADATA_FAILURE = 'donation/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'donation/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'donation/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'donation/LIST_DATA_FAILURE';

export const UPDATE_DONATION_REPLY_REQUESTED =
  'donation/UPDATE_DONATION_REPLY_REQUESTED';
export const UPDATE_DONATION_REPLY_SUCCESS =
  'donation/UPDATE_DONATION_REPLY_SUCCESS';
export const UPDATE_DONATION_REPLY_FAILURE =
  'donation/UPDATE_DONATION_REPLY_FAILURE';

export const DELETE_DONATION_REPLY_REQUESTED =
  'donation/DELETE_DONATION_REPLY_REQUESTED';
export const DELETE_DONATION_REPLY_SUCCESS =
  'donation/DELETE_DONATION_REPLY_SUCCESS';
export const DELETE_DONATION_REPLY_FAILURE =
  'donation/DELETE_DONATION_REPLY_FAILURE';

export const CUSTOM_EMAIL_SETTING_CREATE_REQUESTED =
  'donations/CUSTOM_EMAIL_SETTING_CREATE_REQUESTED';
export const CUSTOM_EMAIL_SETTING_CREATE_SUCCESS =
  'donations/CUSTOM_EMAIL_SETTING_CREATE_SUCCESS';
export const CUSTOM_EMAIL_SETTING_CREATE_FAILURE =
  'donations/CUSTOM_EMAIL_SETTING_CREATE_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'donation/TOGGLE_COLUMNS_CHANGE';

export const GET_EXPORT_FILE_REQUESTED = 'donation/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'donation/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'donation/GET_EXPORT_FILE_FAILURE';

export const CREATE_REQUESTED = 'donation/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'donation/CREATE_SUCCESS';
export const CREATE_FAILURE = 'donation/CREATE_FAILURE';

export const UPDATE_REQUESTED = 'donation/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'donation/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'donation/UPDATE_FAILURE';

export const REFUND_REQUESTED = 'donation/REFUND_REQUESTED';
export const REFUND_SUCCESS = 'donation/REFUND_SUCCESS';
export const REFUND_FAILURE = 'donation/REFUND_FAILURE';

export const DELETE_REQUESTED = 'donation/DELETE_REQUESTED';
export const DELETE_SUCCESS = 'donation/DELETE_SUCCESS';
export const DELETE_FAILURE = 'donation/DELETE_FAILURE';

export const RECEIPT_REQUESTED = 'donation/RECEIPT_REQUESTED';
export const RECEIPT_SUCCESS = 'donation/RECEIPT_SUCCESS';
export const RECEIPT_FAILURE = 'donation/RECEIPT_FAILURE';
export const RECEIPT_MESSAGE_ERROR = 'donation/RECEIPT_MESSAGE_ERROR';

export const GET_REQUESTED = 'donation/GET_REQUESTED';
export const GET_SUCCESS = 'donation/GET_SUCCESS';
export const GET_FAILURE = 'donation/GET_FAILURE';

export const GET_CAMPAIGN_REQUESTED = 'donation/GET_CAMPAIGN_REQUESTED';
export const GET_CAMPAIGN_SUCCESS = 'donation/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'donation/GET_CAMPAIGN_FAILURE';

export const GET_PAGE_REQUESTED = 'donation/GET_PAGE_REQUESTED';
export const GET_PAGE_SUCCESS = 'donation/GET_PAGE_SUCCESS';
export const GET_PAGE_FAILURE = 'donation/GETCPAGE_FAILURE';

export const GET_ORGANIZATION_REQUESTED = 'donation/GET_ORGANIZATION_REQUESTED';
export const GET_ORGANIZATION_SUCCESS = 'donation/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'donation/GET_ORGANIZATION_FAILURE';

export const CREATE_TRANSFER_DONATION_REQUESTED =
  'donation/CREATE_TRANSFER_DONATION_REQUESTED';
export const CREATE_TRANSFER_DONATION_SUCCESS =
  'donation/CREATE_TRANSFER_DONATION_SUCCESS';
export const CREATE_TRANSFER_DONATION_FAILURE =
  'donation/CREATE_TRANSFER_DONATION_FAILURE';

export const ERROR_CLEAR = 'donation/ERROR_CLEAR';
export const REFUND_CONFIRMATION_SHOW = 'donation/REFUND_CONFIRMATION_SHOW';
export const REFUND_CONFIRMATION_HIDE = 'donation/REFUND_CONFIRMATION_HIDE';
export const RECEIPT_CONFIRMATION_SHOW = 'donation/RECEIPT_CONFIRMATION_SHOW';
export const RECEIPT_CONFIRMATION_HIDE = 'donation/RECEIPT_CONFIRMATION_HIDE';
export const IS_DELETED_CLEAR = 'donation/IS_DELETED_CLEAR';
export const IS_REFUNDED_CLEAR = 'donation/IS_REFUNDED_CLEAR';
