//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from '../templateHelper';

//------------Components
import { CollectionBuilder } from '../../collectionBuilder';
import WaiverItem from '../../collectionBuilder/components/waiverItem';

class ManagedWaiverBuilder extends Component {
  getModel = () => {
    return {
      title: '',
      link: ''
    };
  };

  render() {
    const { path, disabled, validation, onChange } = this.props;

    return (
      <CollectionBuilder
        path={path}
        onChange={onChange}
        disabled={disabled}
        validation={validation}
        entity="page"
        i18Key="termsAndConditions.waivers"
        model={this.getModel()}
        itemType={<WaiverItem />}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateWaiverBuilder = connect(mapState)(ManagedWaiverBuilder);
