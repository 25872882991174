import { isArrayWithItems } from './arrayHelper';

export const convertFiltersToV3 = (filters) => {
  if (filters && filters.length) {
    return filters.map((item) => {
      return {
        key: item.key,
        operator: item.operator,
        value: isArrayWithItems(item.value) ? item.value : [item.value]
      };
    });
  }
};

export const convertFiltersToV2 = (filters) => {
  if (filters && filters.length) {
    return filters.map((item) => {
      return {
        key: item.key,
        operator: item.operator,
        value: isArrayWithItems(item.value) ? item.value[0] : item.value
      };
    });
  }
};
