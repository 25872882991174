//---------------React/Redux
import React, { Component } from 'react';

//---------------Components
import { Form, Icon } from 'semantic-ui-react';

//---------------Constants
import {
  AboutElementStringTypes,
  AboutElementIcons
} from '../constants/aboutElementType';

class AboutElementRenderComponent extends Component {
  render() {
    const { index, connectDragSource, type } = this.props;
    return connectDragSource(
      <div>
        <Form className={`about-element ${type}-type`}>
          <Form.Field className="about-element-title-container">
            <label className="about-element-title-label">
              <Icon name={AboutElementIcons[type]} size="large" />
              {AboutElementStringTypes[type]}
              <Icon
                name="remove circle"
                className="right-float"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.onDeleteClick(index)}
              />
            </label>
          </Form.Field>

          <div className="about-element-content">{this.props.children}</div>
        </Form>
      </div>
    );
  }
}

export default AboutElementRenderComponent;
