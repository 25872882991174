import axios from 'axios';
import constants from '../constants';

export default class UploadImageService {
  static upload(entity, type, file, token, updateProgressBarFunc) {
    const UPLOAD_IMAGE_URL = `${constants.baseApiHost}/api/v2/${entity}/${type}/upload`;

    const form = new FormData();
    form.append('file', file);

    return axios.post(UPLOAD_IMAGE_URL, form, {
      headers: {
        'Content-Type': 'application/json',
        ApiKey: constants.apikey,
        Authorization: `bearer ${token}`
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable) {
          updateProgressBarFunc(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          );
        }
      }
    });
  }

  static uploadBlob(entity, type, file, token, updateProgressBarFunc) {
    const UPLOAD_IMAGE_URL = `${constants.baseApiHost}/api/v2/${entity}/${type}/upload`;

    const form = new FormData();
    form.append('file', file, file.filename);

    return axios.post(UPLOAD_IMAGE_URL, form, {
      headers: {
        'Content-Type': 'application/json',
        ApiKey: constants.apikey,
        Authorization: `bearer ${token}`
      },
      onUploadProgress: (progressEvent) => {
        if (progressEvent.lengthComputable && updateProgressBarFunc) {
          updateProgressBarFunc(
            Math.floor((progressEvent.loaded / progressEvent.total) * 100)
          );
        }
      }
    });
  }
}
