import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Modal } from 'semantic-ui-react';
import { Common } from '../../../components';
import { useAppDispatch } from '../../../hooks';
import { ITerminal } from '../../../models/Terminal';
import { AppDispatch, RootState } from '../../../store';
import {
  defaultTerminalListSearch,
  updateTerminalBulk
} from '../store/terminalActions';
import { assignedFilter } from '../store/terminalReducer';

interface IUnassignDeviceProps {
  terminalList: ITerminal[];
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

export const UnassignDevice = ({
  terminalList,
  isOpen,
  setIsOpen
}: IUnassignDeviceProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const terminalReducer = useSelector((state: RootState) => state.terminal);
  const dispatch: AppDispatch = useAppDispatch();
  // prettier-ignore
  const orgName = terminalList.length === 1 ? terminalList[0].organization?.name : '';

  const onUnassignConfirm = () => {
    if (terminalList.length === 0) return;
    setIsLoading(true);
    dispatch(
      updateTerminalBulk({
        ids: terminalList.map((x) => x.id)
      })
    );
  };

  useEffect(() => {
    if (
      isOpen &&
      isLoading &&
      (terminalReducer.recordEdit.success.show ||
        terminalReducer.recordEdit.error)
    ) {
      setTimeout(() => {
        setIsOpen(false);
        setIsLoading(false);
        dispatch(defaultTerminalListSearch(assignedFilter));
      }, 100);
    }
  }, [terminalReducer, terminalReducer.recordEdit.error]);

  return (
    <Modal open={isOpen} size="tiny">
      <Modal.Content>
        <h3 className="modal-title">
          <Translate value="terminal.unassign-modal.title" />
        </h3>
        <p className="modal-description">
          <Translate
            value={`terminal.unassign-modal.description`}
            count={terminalList.length}
            orgName={orgName}
          />
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Common.Button
          disabled={isLoading}
          content={<Translate value="terminal.unassign-modal.cancel" />}
          onClick={() => setIsOpen(false)}
        />
        <Common.Button
          primary
          loading={isLoading}
          content={<Translate value="terminal.unassign-modal.confrim" />}
          onClick={onUnassignConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};
