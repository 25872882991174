export const eventPriority = [
  {
    key: 'primary',
    label: 'Tier 1'
  },
  {
    key: 'secondary',
    label: 'Tier 2'
  },
  {
    key: 'tertiary',
    label: 'Tier 3'
  },
  {
    key: 'quaternary',
    label: 'Tier 4'
  }
];

export const eventTierLabels = {
  PRIMARY: 'Tier 1',
  SECONDARY: 'Tier 2',
  TERTIARY: 'Tier 3',
  QUATERNERY: 'Tier 4'
};

export const eventTierLabel = {
  [eventTierLabels.PRIMARY]: {
    label: eventTierLabels.PRIMARY
  },
  [eventTierLabels.SECONDARY]: {
    label: eventTierLabels.SECONDARY
  },
  [eventTierLabels.TERTIARY]: {
    label: eventTierLabels.TERTIARY
  },
  [eventTierLabels.QUATERNERY]: {
    label: eventTierLabels.QUATERNERY
  }
};

export const dependentOrganisationsGridColumns = {
  ASSOCIATED: 'associated',
  IMAGE: 'mainImagePath',
  NAME: 'name',
  PRIORITY: 'priority'
};

export const errorMessages = {
  paymentPlatformMissing: 'Payment platform missing'
};
