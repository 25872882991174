// React / Redux / Related
import React, { Fragment, useEffect, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { I18n } from 'react-redux-i18n';
import { useHistory } from 'react-router-dom';
import { History } from 'history';
import { useParams } from 'react-router-dom';

// Helpers
import RecordResultMessage from '../../../components/common/recordResult';
import { Record } from '../../../modules/bin/utility';
import get from 'lodash.get';

// Components
import EventCloneTemplate from './template';
import BasePage from '../../../components/common/basePageView';
import Spinner from '../../../components/common/spinner';

// Constants
import config from '../../../constants/index';
import SavedEntityModal from '../../../components/common/savedEntityModal';
import Text from '../../../components/common/text';
import { cloneRecord, getRecord } from '../store/eventActions';
import { RootState } from '../../../store';
import { RouteParams } from '../../../containers/app';
import { useAppDispatch } from '../../../hooks';

const getSavedModalContent = (name: string) => {
  return (
    <Fragment>
      <Text>
        {I18n.t(`event.template.page.saved-modal.event-create-message`).replace(
          '{eventName}',
          name
        )}
      </Text>
    </Fragment>
  );
};

const getSavedModalButtons = (url: string, history: History) => {
  return (
    <Fragment>
      <Button
        className="primary"
        onClick={() => {
          window.open(url, '_blank');
        }}
      >
        {I18n.t('event.template.page.saved-modal.event-view-button')}
      </Button>
      <Button onClick={() => history.push(config.DEFAULT_EVENTS_URL)}>
        {I18n.t('event.template.page.saved-modal.event-return-button')}
      </Button>
    </Fragment>
  );
};

const CloneEventContainer = () => {
  const eventSelector = useSelector((state: RootState) => state.event);
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParams>();
  const history = useHistory();
  const [isSavedModalOpened, setIsSavedModalOpened] = useState(false);
  // prettier-ignore
  const [isSavedOperationFinished, setIsSavedOperationFinished] = useState(false);
  const { record, recordClone, clearRecordCloneMessages } = eventSelector;
  const isSaving = Record.isRecordLoading(recordClone);

  useEffect(() => {
    dispatch(getRecord(id));
  }, [id]);

  const onSave = (data: any) => {
    dispatch(cloneRecord(id, data));
    setIsSavedOperationFinished(true);
  };

  const isRecordReady = Record.isRecordReady(record);
  if (!isRecordReady) {
    return <Spinner />;
  }

  const goToEventView = () => {
    if (isSavedOperationFinished) {
      setIsSavedModalOpened(true);
      setIsSavedOperationFinished(false);
    }
  };

  const successModalRedirectButtons = getSavedModalButtons(
    get(recordClone, 'data.urlFull'),
    history
  );
  const successModalContent = getSavedModalContent(
    get(recordClone, 'data.name')
  );

  return (
    <BasePage>
      <RecordResultMessage
        record={recordClone}
        onDismiss={clearRecordCloneMessages}
        redirectOnSuccess={goToEventView}
      />

      <SavedEntityModal
        open={isSavedModalOpened}
        content={successModalContent}
        buttons={successModalRedirectButtons}
        isEdit={false}
      />

      <h1>
        <Translate value="event.template.page.setup-clone.title" />
      </h1>
      <EventCloneTemplate onSave={onSave} isLoading={isSaving} />
    </BasePage>
  );
};

export default CloneEventContainer;
