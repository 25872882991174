import { useEffect } from 'react';
import config from '../../constants';

const dataLayer = { 'gtm.whitelist ': ['google', 'customPixels'] };

export const useGoogleTagManager = () => {
  useEffect(() => {
    const script = document.createElement('script');

    script.innerHTML = `(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != "dataLayer" ? "&l=" + l : "";
        j.async = true;
        j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, "script", "dataLayer", "${config.GOOGLE_TAG_MANAGER_ID}");`;

    document.head.appendChild(script);

    return () => {
      const scriptElement = document.querySelector(
        `script[src="https://www.googletagmanager.com/gtm.js?id=${config.GOOGLE_TAG_MANAGER_ID}"]`
      );
      if (scriptElement) {
        document.head.removeChild(scriptElement);
      }
    };
  }, [config.GOOGLE_TAG_MANAGER_ID]);

  return null;
};
