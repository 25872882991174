import * as entityHandlers from '../../../modules/bin/entityHandlers';
import update from '../../../helpers/update';
import { Record } from '../../../modules/bin/utility';
import { settingsMenuItems, securitySettingTabs, paths, subscriptionOptions } from '../constants';
import { UPLOAD_SUCCESS } from '../../../modules/upload';
import { CLEAR } from '../../../modules/general';
import { isArrayWithItems } from '../../../helpers/arrayHelper';

export const MENU_ITEM_CLICK = 'accountSettings/MENU_ITEM_CLICK';
export const FIRST_NAME_CHANGE = 'accountSettings/FIRST_NAME_CHANGE';
export const LAST_NAME_CHANGE = 'accountSettings/LAST_NAME_CHANGE';
export const EMAIL_CHANGE = 'accountSettings/EMAIL_CHANGE';
export const NEW_PASSWORD_CHANGE = 'accountSettings/NEW_PASSWORD_CHANGE';
export const CONFIRM_PASSWORD_CHANGE =
  'accountSettings/CONFIRM_PASSWORD_CHANGE';
export const ACCOUNT_IMAGE_CHANGE = 'accountSettings/ACCOUNT_IMAGE_CHANGE';

export const GET_REQUESTED = 'accountSettings/GET_REQUESTED';
export const GET_SUCCESS = 'accountSettings/GET_SUCCESS';
export const GET_FAILURE = 'accountSettings/GET_FAILURE';

export const SAVE_SETTINGS_REQUESTED = 'account/SAVE_SETTINGS_REQUESTED';
export const SAVE_SETTINGS_SUCCESS = 'account/SAVE_SETTINGS_SUCCESS';
export const SAVE_SETTINGS_FAILURE = 'account/SAVE_SETTINGS_FAILURE';

export const UPDATE_PASSWORD_REQUESTED = 'account/UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_SUCCESS = 'account/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'account/UPDATE_PASSWORD_FAILURE';

export const PROMOTE_ADMIN_REQUESTED =
  'accountSettings/PROMOTE_ADMIN_REQUESTED';
export const PROMOTE_ADMIN_SUCCESS = 'accountSettings/PROMOTE_ADMIN_SUCCESS';
export const PROMOTE_ADMIN_FAILURE = 'accountSettings/PROMOTE_ADMIN_FAILURE';

export const DEMOTE_ADMIN_REQUESTED = 'accountSettings/DEMOTE_ADMIN_REQUESTED';
export const DEMOTE_ADMIN_SUCCESS = 'accountSettings/DEMOTE_ADMIN_SUCCESS';
export const DEMOTE_ADMIN_FAILURE = 'accountSettings/DEMOTE_ADMIN_FAILURE';

export const GET_LINKED_CAMPAIGNS_REQUESTED =
  'accountSettings/GET_LINKED_CAMPAIGNS_REQUESTED';
export const GET_LINKED_CAMPAIGNS_SUCCESS =
  'accountSettings/GET_LINKED_CAMPAIGNS_SUCCESS';
export const GET_LINKED_CAMPAIGNS_FAILURE =
  'accountSettings/GET_LINKED_CAMPAIGNS_FAILURE';

export const GET_LINKED_EVENTS_REQUESTED =
  'accountSettings/GET_LINKED_EVENTS_REQUESTED';
export const GET_LINKED_EVENTS_SUCCESS =
  'accountSettings/GET_LINKED_EVENTS_SUCCESS';
export const GET_LINKED_EVENTS_FAILURE =
  'accountSettings/GET_LINKED_EVENTS_FAILURE';

export const GET_LINKED_EVENT_ORGANIZERS_REQUESTED =
  'accountSettings/GET_LINKED_EVENT_ORGANIZERS_REQUESTED';
export const GET_LINKED_EVENT_ORGANIZERS_SUCCESS =
  'accountSettings/GET_LINKED_EVENT_ORGANIZERS_SUCCESS';
export const GET_LINKED_EVENT_ORGANIZERS_FAILURE =
  'accountSettings/GET_LINKED_EVENT_ORGANIZERS_FAILURE';

export const GET_CAMPAIGN_OPTIONS_REQUESTED =
  'accountSettings/GET_CAMPAIGN_OPTIONS_REQUESTED';
export const GET_CAMPAIGN_OPTIONS_SUCCESS =
  'accountSettings/GET_CAMPAIGN_OPTIONS_SUCCESS';
export const GET_CAMPAIGN_OPTIONS_FAILURE =
  'accountSettings/GET_CAMPAIGN_OPTIONS_FAILURE';

export const LINKED_CAMPAIGN_ADD = 'accountSettings/LINKED_CAMPAIGN_ADD';
export const LINKED_CAMPAIGN_REMOVE = 'accountSettings/LINKED_CAMPAIGN_REMOVE';

export const LINKED_EVENT_ADD = 'accountSettings/LINKED_EVENT_ADD';
export const LINKED_EVENT_REMOVE = 'accountSettings/LINKED_EVENT_REMOVE';

export const LINKED_EVENT_ORGANIZER_ADD =
  'accountSettings/LINKED_EVENT_ORGANIZER_ADD';
export const LINKED_EVENT_ORGANIZER_REMOVE =
  'accountSettings/LINKED_EVENT_ORGANIZER_REMOVE';

export const HANDLE_VALUE_CHANGE = 'accountSettings/HANDLE_VALUE_CHANGE';

export const ERROR_CLEAR = 'account/ERROR_CLEAR';

export const CLEAR_RECORD = 'account/CLEAR_RECORD';

export const SECURITY_MODAL_CHANGE = 'accountSettings/SECURITY_MODAL_CHANGE';
export const SECURITY_DISABLE_MODAL_CHANGE =
  'accountSettings/SECURITY_DISABLE_MODAL_CHANGE';
export const VERIFICATION_MODAL_CLEAR =
  'accountSettings/VERIFICATION_MODAL_CLEAR';

export const GET_SECURITY_APPROVAL_REQUESTED =
  'accountSettings/GET_SECURITY_APPROVAL_REQUESTED';
export const GET_SECURITY_APPROVAL_SUCCESS =
  'accountSettings/GET_SECURITY_APPROVAL_SUCCESS';
export const GET_SECURITY_APPROVAL_FAILURE =
  'accountSettings/GET_SECURITY_APPROVAL_FAILURE';

export const GET_QR_CODE_REQUESTED = 'accountSettings/GET_QR_CODE_REQUESTED';
export const GET_QR_CODE_SUCCESS = 'accountSettings/GET_QR_CODE_SUCCESS';
export const GET_QR_CODE_FAILURE = 'accountSettings/GET_QR_CODE_FAILURE';

export const GET_EMAIL_NOTIFICATIONS_REQUESTED = 'accountSettings/GET_EMAIL_NOTIFICATIONS_REQUESTED';
export const GET_EMAIL_NOTIFICATIONS_SUCCESS = 'accountSettings/GET_EMAIL_NOTIFICATIONS_SUCCESS';
export const GET_EMAIL_NOTIFICATIONS_FAILURE = 'accountSettings/GET_EMAIL_NOTIFICATIONS_FAILURE';

export const SAVE_EMAIL_NOTIFICATIONS_REQUESTED = 'accountSettings/SAVE_EMAIL_NOTIFICATIONS_REQUESTED';
export const SAVE_EMAIL_NOTIFICATIONS_SUCCESS = 'accountSettings/SAVE_EMAIL_NOTIFICATIONS_SUCCESS';
export const SAVE_EMAIL_NOTIFICATIONS_FAILURE = 'accountSettings/SAVE_EMAIL_NOTIFICATIONS_FAILURE';

export const EMAIL_NOTIFICATION_CHANGE = 'accountSettings/EMAIL_NOTIFICATION_CHANGE';

export const DISABLE_SECURITY_REQUESTED =
  'accountSettings/DISABLE_SECURITY_REQUESTED';
export const DISABLE_SECURITY_SUCCESS =
  'accountSettings/DISABLE_SECURITY_SUCCESS';
export const DISABLE_SECURITY_FAILURE =
  'accountSettings/DISABLE_SECURITY_FAILURE';

export const clearRecord = (key) => {
  return {
    type: CLEAR_RECORD,
    payload: {
      key
    }
  };
};

export const getRecord = (id) =>
  entityHandlers.getRecord(
    'account',
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    id
  );

export const updateRecord = (
  id,
  editableData,
  verificationMethod = null,
  verificationCode = null
) => {
  return entityHandlers.patchRecord(
    'account',
    SAVE_SETTINGS_REQUESTED,
    SAVE_SETTINGS_SUCCESS,
    SAVE_SETTINGS_FAILURE,
    id,
    editableData,
    'recordEdit',
    verificationMethod,
    verificationCode
  );
};

export const updateRecordPassword = (
  id,
  editableData,
  verificationMethod = null,
  verificationCode = null
) => {
  return entityHandlers.patchRecord(
    'account',
    SAVE_SETTINGS_REQUESTED,
    SAVE_SETTINGS_SUCCESS,
    SAVE_SETTINGS_FAILURE,
    id,
    editableData,
    'recordChangePassword',
    verificationMethod,
    verificationCode
  );
};

export const promoteAdmin = (id, organizationId, data) => {
  return entityHandlers.createRecord(
    `account/${id}/promote/${organizationId}`,
    PROMOTE_ADMIN_REQUESTED,
    PROMOTE_ADMIN_SUCCESS,
    PROMOTE_ADMIN_FAILURE,
    data,
    null,
    'promoteRecord'
  );
};

export const demoteAdmin = (id) => {
  return entityHandlers.createRecord(
    `account/${id}/demote`,
    DEMOTE_ADMIN_REQUESTED,
    DEMOTE_ADMIN_SUCCESS,
    DEMOTE_ADMIN_FAILURE,
    null,
    null,
    'demoteRecord'
  );
};

export const menuItemClick = (activeItem) => {
  return {
    type: MENU_ITEM_CLICK,
    payload: activeItem
  };
};

export const securityModalChange = (open, finish = false) => {
  return {
    type: SECURITY_MODAL_CHANGE,
    payload: {
      open,
      finish
    }
  };
};

export const verificationModalClear = () => {
  return {
    type: VERIFICATION_MODAL_CLEAR
  };
};

export const disableSecurityModalChange = (open) => {
  return {
    type: SECURITY_DISABLE_MODAL_CHANGE,
    payload: {
      open
    }
  };
};

export const handleValueChange = (path, value) => {
  return {
    type: HANDLE_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const firstNameChange = (firstname) => {
  return {
    type: FIRST_NAME_CHANGE,
    payload: firstname
  };
};

export const lastNameChange = (lastName) => {
  return {
    type: LAST_NAME_CHANGE,
    payload: lastName
  };
};

export const emailChange = (email) => {
  return {
    type: EMAIL_CHANGE,
    payload: email
  };
};

export const newPasswordChange = (newPassword) => {
  return {
    type: NEW_PASSWORD_CHANGE,
    payload: newPassword
  };
};

export const confirmPasswordChange = (confirmPassword) => {
  return {
    type: CONFIRM_PASSWORD_CHANGE,
    payload: confirmPassword
  };
};

export const accountImageChange = (imagePath) => {
  return {
    type: ACCOUNT_IMAGE_CHANGE,
    payload: imagePath
  };
};

export const handleLinkedCampaignAdd = (item) => {
  return {
    type: LINKED_CAMPAIGN_ADD,
    payload: { item }
  };
};

export const handleLinkedEventAdd = (item) => {
  return {
    type: LINKED_EVENT_ADD,
    payload: { item }
  };
};

export const handleLinkedEventOrganizerAdd = (item) => {
  return {
    type: LINKED_EVENT_ORGANIZER_ADD,
    payload: { item }
  };
};

export const handleLinkedCampaignRemove = (index) => {
  return {
    type: LINKED_CAMPAIGN_REMOVE,
    payload: { index }
  };
};

export const handleLinkedEventRemove = (index) => {
  return {
    type: LINKED_EVENT_REMOVE,
    payload: { index }
  };
};

export const handleLinkedEventOrganizerRemove = (index) => {
  return {
    type: LINKED_EVENT_ORGANIZER_REMOVE,
    payload: { index }
  };
};

export const emailNotificationChange = (payload) => {
  return {
    type: EMAIL_NOTIFICATION_CHANGE,
    payload
  };
};

export const getLinkedCampaigns = (campaigns) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: '',
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'campaignId',
        operator: 'InList',
        value: campaigns
      },
      {
        key: 'type',
        operator: 'EqualTo',
        value: ['campaign']
      }
    ]
  };

  return entityHandlers.getListData(
    'page',
    'linkedCampaignsRecord',
    null,
    GET_LINKED_CAMPAIGNS_REQUESTED,
    GET_LINKED_CAMPAIGNS_SUCCESS,
    GET_LINKED_CAMPAIGNS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

export const getLinkedEvents = (events) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: '',
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'eventId',
        operator: 'InList',
        value: events
      },
      {
        key: 'type',
        operator: 'EqualTo',
        value: ['event']
      }
    ]
  };

  return entityHandlers.getListData(
    'page',
    'linkedEventsRecord',
    null,
    GET_LINKED_EVENTS_REQUESTED,
    GET_LINKED_EVENTS_SUCCESS,
    GET_LINKED_EVENTS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

export const getLinkedEventOrganizers = (eventOrganizers) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: '',
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'id',
        operator: 'InList',
        value: eventOrganizers
      },
      {
        key: 'type',
        operator: 'EqualTo',
        value: ['id']
      }
    ]
  };

  return entityHandlers.getListData(
    'EventOrganizer',
    'linkedEventOrganizersRecord',
    null,
    GET_LINKED_EVENT_ORGANIZERS_REQUESTED,
    GET_LINKED_EVENT_ORGANIZERS_SUCCESS,
    GET_LINKED_EVENT_ORGANIZERS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

export const getCampaignOptions = (searchText, customFilter = []) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: [
      {
        key: 'status',
        operator: 'EqualTo',
        value: 1
      },
      ...customFilter
    ]
  };

  return entityHandlers.getListData(
    'page/campaign',
    'campaignOptions',
    null,
    GET_CAMPAIGN_OPTIONS_REQUESTED,
    GET_CAMPAIGN_OPTIONS_SUCCESS,
    GET_CAMPAIGN_OPTIONS_FAILURE,
    request
  );
};

export const clearRecordChangePasswordMessages = () => ({
  type: ERROR_CLEAR,
  payload: { key: 'recordChangePassword' }
});
export const clearRecordEditMessages = () => ({
  type: ERROR_CLEAR,
  payload: { key: 'recordEdit' }
});
export const clearPromoteRecord = () => ({
  type: ERROR_CLEAR,
  payload: { key: 'promoteRecord' }
});
export const clearDemoteRecord = () => ({
  type: ERROR_CLEAR,
  payload: { key: 'demoteRecord' }
});
export const clearSecurityDisableRecordMessages = () => ({
  type: ERROR_CLEAR,
  payload: { key: 'disableSecurityRecord' }
});

const mapLinkedCampaigns = (list) => {
  if (!isArrayWithItems(list)) {
    return [];
  }

  return list.map((campaign) => campaign.name);
};

const mapLinkedEvents = (list) => {
  if (!isArrayWithItems(list)) {
    return [];
  }

  return list.map((event) => event.name);
};

const mapLinkedEventOrganizers = (list) => {
  if (!isArrayWithItems(list)) {
    return [];
  }

  return list.map((eventOrganizer) => eventOrganizer.name);
};

export const getQRCode = (id, verificationToken) => {
  return entityHandlers.createRecord(
    `account/${id}/multifactor`,
    GET_QR_CODE_REQUESTED,
    GET_QR_CODE_SUCCESS,
    GET_QR_CODE_FAILURE,
    {},
    null,
    'qrCodeRecord',
    'v2/',
    verificationToken
  );
};

export const saveEmailNotifications = (id, data, verificationToken) => {
  return entityHandlers.createRecord(
    `account/${id}/email/opt`,
    SAVE_EMAIL_NOTIFICATIONS_REQUESTED,
    SAVE_EMAIL_NOTIFICATIONS_SUCCESS,
    SAVE_EMAIL_NOTIFICATIONS_FAILURE,
    data,
    null,
    'emailNotificationsRecord',
    'v2/',
    verificationToken
  );
};

export const getEmailNotificationSettings = (id, instanceTypes, verificationToken) => {
  return entityHandlers.getRecord(
    `account/${id}/email`,
    GET_EMAIL_NOTIFICATIONS_REQUESTED,
    GET_EMAIL_NOTIFICATIONS_SUCCESS,
    GET_EMAIL_NOTIFICATIONS_FAILURE,
    `opt?instanceTypes=${instanceTypes}`,
    'emailNotificationsRecord',
    'v2',
    verificationToken
  );
};

export const getSecurityAppoval = (id, code, verificationToken) => {
  const data = {
    code: code
  };
  return entityHandlers.createRecord(
    `account/${id}/multifactor/activate`,
    GET_SECURITY_APPROVAL_REQUESTED,
    GET_SECURITY_APPROVAL_SUCCESS,
    GET_SECURITY_APPROVAL_FAILURE,
    data,
    null,
    'securityApprovalRecord',
    'v2/',
    verificationToken
  );
};

export const disableSecurity = (
  id,
  verificationToken,
  verificationCode,
  verificationMethod
) => {
  return entityHandlers.deleteRecord(
    'account',
    DISABLE_SECURITY_REQUESTED,
    DISABLE_SECURITY_SUCCESS,
    DISABLE_SECURITY_FAILURE,
    `${id}/multifactor`,
    'disableSecurityRecord',
    verificationToken,
    verificationCode,
    verificationMethod
  );
};

const initialState = {
  activeItem: settingsMenuItems.ACCOUNT_DETAILS,
  record: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(false),
  uploadImageRecord: Record.getDefaultState(false),
  recordChangePassword: Record.getDefaultState(false),
  promoteRecord: Record.getDefaultState(false),
  demoteRecord: Record.getDefaultState(false),
  linkedCampaignsRecord: Record.getDefaultState(false),
  linkedEventsRecord: Record.getDefaultState(false),
  linkedEventOrganizersRecord: Record.getDefaultState(false),
  campaignOptions: Record.getDefaultState(),
  qrCodeRecord: Record.getDefaultState(false),
  securityApprovalRecord: Record.getDefaultState(false),
  disableSecurityRecord: Record.getDefaultState(false),
  emailNotificationsRecord: Record.getDefaultState(false),
  emailNotifications: {},
  newPassword: '',
  confirmPassword: '',
  linkedCampaigns: [],
  linkedEvents: [],
  linkedEventOrganizers: [],
  MFA: {
    open: false,
    activeTab: securitySettingTabs.START
  },
  accountVerification: {
    open: false,
    recoveryCode: '',
    authCode: ''
  }
};

const accountSettingsReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case CLEAR_RECORD: {
      newState = update.set(
        newState,
        action.payload.key,
        Record.getDefaultState(false)
      );
      break;
    }

    case MENU_ITEM_CLICK:
      newState = update.set(newState, paths.ACTIVE_ITEM, action.payload);
      break;

    case HANDLE_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    case FIRST_NAME_CHANGE:
      newState = update.set(newState, paths.FIRST_NAME, action.payload);
      break;

    case LAST_NAME_CHANGE:
      newState = update.set(newState, paths.LAST_NAME, action.payload);
      break;

    case EMAIL_CHANGE:
      newState = update.set(newState, paths.EMAIL, action.payload);
      break;

    case NEW_PASSWORD_CHANGE:
      newState = update.set(newState, 'newPassword', action.payload);
      break;

    case CONFIRM_PASSWORD_CHANGE:
      newState = update.set(newState, 'confirmPassword', action.payload);
      break;

    case LINKED_CAMPAIGN_ADD:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                campaignId: { $push: [action.payload.item.id] }
              }
            }
          }
        },
        linkedCampaigns: { $push: [action.payload.item.name] }
      });
      break;

    case LINKED_EVENT_ADD:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                eventId: { $push: [action.payload.item.id] }
              }
            }
          }
        },
        linkedEvents: { $push: [action.payload.item.name] }
      });
      break;

    case LINKED_EVENT_ORGANIZER_ADD:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                eventOrganizerId: { $push: [action.payload.item.id] }
              }
            }
          }
        },
        linkedEventOrganizers: { $push: [action.payload.item.name] }
      });
      break;

    case LINKED_CAMPAIGN_REMOVE:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                campaignId: { $splice: [[action.payload.index, 1]] }
              }
            }
          }
        },
        linkedCampaigns: { $splice: [[action.payload.index, 1]] }
      });
      break;

    case LINKED_EVENT_REMOVE:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                eventId: { $splice: [[action.payload.index, 1]] }
              }
            }
          }
        },
        linkedEvents: { $splice: [[action.payload.index, 1]] }
      });
      break;

    case LINKED_EVENT_ORGANIZER_REMOVE:
      newState = update(newState, {
        record: {
          data: {
            data: {
              roleDetail: {
                eventOrganizerId: { $splice: [[action.payload.index, 1]] }
              }
            }
          }
        },
        linkedEventOrganizers: { $splice: [[action.payload.index, 1]] }
      });
      break;

    case GET_REQUESTED:
    case SAVE_SETTINGS_REQUESTED:
    case PROMOTE_ADMIN_REQUESTED:
    case DEMOTE_ADMIN_REQUESTED:
    case GET_LINKED_CAMPAIGNS_REQUESTED:
    case GET_LINKED_EVENTS_REQUESTED:
    case GET_LINKED_EVENT_ORGANIZERS_REQUESTED:
    case GET_CAMPAIGN_OPTIONS_REQUESTED:
    case GET_QR_CODE_REQUESTED:
    case SAVE_EMAIL_NOTIFICATIONS_REQUESTED:
    case GET_EMAIL_NOTIFICATIONS_REQUESTED:
    case GET_SECURITY_APPROVAL_REQUESTED:
    case DISABLE_SECURITY_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
    case GET_CAMPAIGN_OPTIONS_SUCCESS:
    case SAVE_EMAIL_NOTIFICATIONS_SUCCESS:
    case GET_QR_CODE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;
    case GET_EMAIL_NOTIFICATIONS_SUCCESS:
      let instanceTypeOptions = {};
      const data = action?.payload?.data?.data || [];
      data.forEach(x => instanceTypeOptions[x.instanceType] = x.opt === subscriptionOptions.in);
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update(newState, {
        emailNotifications: { $set: instanceTypeOptions }
      });
      break;
    case DISABLE_SECURITY_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'record.data.data.hasMfaActive', false);
      newState = update(newState, {
        accountVerification: {
          open: { $set: false },
          recoveryCode: { $set: null },
          authCode: { $set: null }
        }
      });
      break;

    case GET_LINKED_CAMPAIGNS_SUCCESS:
      newState = update.set(
        state,
        'linkedCampaigns',
        mapLinkedCampaigns(action.payload.data.list)
      );
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case GET_LINKED_EVENTS_SUCCESS:
      newState = update.set(
        state,
        'linkedEvents',
        mapLinkedEvents(action.payload.data.list)
      );
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case GET_LINKED_EVENT_ORGANIZERS_SUCCESS:
      newState = update.set(
        state,
        'linkedEventOrganizers',
        mapLinkedEventOrganizers(action.payload.data.list)
      );
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case PROMOTE_ADMIN_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case DEMOTE_ADMIN_SUCCESS:
      newState = update.set(newState, paths.IS_ORG_ADMIN, false);
      newState = update.set(newState, paths.IS_EVENT_ADMIN, false);
      newState = update.set(newState, paths.IS_EVENT_ORGANIZER_ADMIN, false);
      newState = update.set(newState, paths.IS_CAMPAIGN_ADMIN, false);
      newState = update.set(
        newState,
        'record.data.data.roleDetail.eventId',
        []
      );
      newState = update.set(
        newState,
        'record.data.data.roleDetail.eventOrganizerId',
        []
      );
      newState = update.set(
        newState,
        'record.data.data.roleDetail.campaignId',
        []
      );
      newState = update.set(newState, 'linkedEvents', []);
      newState = update.set(newState, 'linkedEventOrganizers', []);
      newState = update.set(newState, 'linkedCampaigns', []);
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case GET_FAILURE:
    case SAVE_SETTINGS_FAILURE:
    case PROMOTE_ADMIN_FAILURE:
    case DEMOTE_ADMIN_FAILURE:
    case GET_LINKED_CAMPAIGNS_FAILURE:
    case GET_LINKED_EVENTS_FAILURE:
    case GET_CAMPAIGN_OPTIONS_FAILURE:
    case GET_QR_CODE_FAILURE:
    case SAVE_EMAIL_NOTIFICATIONS_FAILURE:
    case GET_EMAIL_NOTIFICATIONS_FAILURE:
    case GET_SECURITY_APPROVAL_FAILURE:
    case DISABLE_SECURITY_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case UPLOAD_SUCCESS:
      newState = update.set(newState, action.payload.path, action.payload.data);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case ACCOUNT_IMAGE_CHANGE:
      newState = update.set(
        newState,
        'record.data.data.mainImagePath',
        action.payload
      );
      break;

    case EMAIL_NOTIFICATION_CHANGE:
      newState = update.set(
        newState,
        `${paths.EMAIL_NOTIFICATIONS}.${action.payload.path}`,
        action.payload.value
      );
      break;

    case SECURITY_MODAL_CHANGE:
      const { open, finish } = action.payload;

      if (finish) {
        newState = update.set(newState, 'record.data.data.hasMfaActive', true);
      }

      newState = update.set(newState, 'MFA.open', open);
      newState = update.set(
        newState,
        'qrCodeRecord',
        Record.getDefaultState(false)
      );
      newState = update.set(
        newState,
        'securityApprovalRecord',
        Record.getDefaultState(false)
      );
      newState = update.set(
        newState,
        'MFA.activeTab',
        securitySettingTabs.START
      );
      break;

    case SECURITY_DISABLE_MODAL_CHANGE:
      if (action.payload.open) {
        newState = update.set(
          newState,
          'disableSecurityRecord',
          Record.getDefaultState(false)
        );
      }

      newState = update(newState, {
        accountVerification: {
          open: { $set: action.payload.open },
          recoveryCode: { $set: null },
          authCode: { $set: null }
        }
      });
      break;

    case VERIFICATION_MODAL_CLEAR:
      newState = update(newState, {
        accountVerification: {
          open: { $set: false },
          recoveryCode: { $set: null },
          authCode: { $set: null }
        }
      });
      break;

    case GET_SECURITY_APPROVAL_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'record.data.data.hasMfaActive', true);
      break;

    default:
      return state;
  }
  return newState;
};

export default accountSettingsReducer;
