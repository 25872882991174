import React from 'react';

import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';

import { ViewTable } from '../../../components/layouts/viewTable';
import ViewIconItem from '../../../components/layouts/viewIconItem';

import { pageTypesStrings } from '../../../constants/pageTypes';

export const DonationPageInformation = (props) => {
  const getImageUrl = (primaryImage, secondaryImage) => {
    return secondaryImage || primaryImage;
  };

  const { campaign, team, fundraiser, collection, event } = props.donation;

  const eventImage = get(event, 'mainImageUrl');
  const campaignImage = get(campaign, 'mainImageUrl')
    ? get(campaign, 'mainImageUrl')
    : eventImage;

  const mainImage = event ? eventImage : campaignImage;

  const fundraiserImage = getImageUrl(
    mainImage,
    get(fundraiser, 'mainImageUrl')
  );
  const teamImage = getImageUrl(mainImage, get(team, 'mainImageUrl'));
  const collectionImage = getImageUrl(
    mainImage,
    get(collection, 'mainImageUrl')
  );

  return (
    <React.Fragment>
      <div className="donation-additional-information">
        <ViewTable
          title={
            <Translate value="donation.donation-view.donation-details.page-information" />
          }
        >
          <ViewIconItem
            type={pageTypesStrings.FUNDRAISER}
            data={fundraiser}
            imageUrl={fundraiserImage}
          />
          <ViewIconItem
            type={pageTypesStrings.TEAM}
            data={team}
            imageUrl={teamImage}
          />
          <ViewIconItem
            type={pageTypesStrings.COLLECTION}
            data={collection}
            imageUrl={collectionImage}
          />
          <ViewIconItem
            type={pageTypesStrings.CAMPAIGN}
            data={campaign}
            imageUrl={campaignImage}
          />
          <ViewIconItem
            type={pageTypesStrings.EVENT}
            hideId
            data={event}
            imageUrl={eventImage}
          />
        </ViewTable>
      </div>
    </React.Fragment>
  );
};
