import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

class DropdownInput extends Component {
  onValueChange = (evt, obj) => {
    if (obj.value !== this.props.value) {
      this.props.onChange(evt, obj);
    }
  };

  render() {
    const {
      id,
      label,
      inputLabel,
      name,
      subtext,
      required,
      search,
      ...pprops
    } = this.props;

    return (
      <Form.Field id={id} required={required} className={this.props.className}>
        {label && <label>{label}</label>}
        <Dropdown
          name={name}
          selection
          disabled={this.props.disabled}
          options={this.props.options}
          value={this.props.value}
          onChange={this.onValueChange}
          search={search}
          onFocus={this.props.onFocus}
          text={this.props.value ? null : this.props.defaultLabel}
          {...pprops}
        />
        {subtext && <div className="input-sub">{subtext}</div>}
      </Form.Field>
    );
  }
}

export default DropdownInput;
