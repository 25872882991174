import React from 'react';

const BadgeFormatter = (props) => {
  return (
    <div className="badge-formatter" style={props.styles[props.value]}>
      {props.value.capitalize()}
    </div>
  );
};

export default BadgeFormatter;
