import React from 'react';
import { Icon } from 'semantic-ui-react';
import Styles from './label.module.css';
import { Tooltip } from 'react-tippy';

export const Label: React.FunctionComponent<{
  children?: React.ReactNode;
  required?: boolean;
  info?: string;
}> = ({ children, required, info }) => (
  <div className={required ? Styles.LabelRequired : Styles.Label}>
    {children}
    {info ? (
      <Tooltip title={info}>
        <div className={Styles.LabelIcon}>
          <Icon className="info circle icon" />
        </div>
      </Tooltip>
    ) : null}
  </div>
);

export const ErrorLabel: React.FunctionComponent<{
  children?: React.ReactNode;
}> = ({ children }) => <div className={Styles.ErrorLabel}>{children}</div>;
