import {
  generalFormBuilderPath,
  customFieldCategory
} from '../../constants/formBuilder';
import { campaignTypes } from './create/templates';

export const MerchandiseConstants = {
  MERCHANDISE_NAME_MAX_LENGTH: 80,
  MERCHANDISE_DESCRIPTION_MAX_LENGTH: 280
};

export const SkuConstants = {
  SKU_NAME_MAX_LENGTH: 80,
  SKU_DESCRIPTION_MAX_LENGTH: 280
};

export const couponsRequestPageSize = 75;

export const paths = {
  GRID_TAB_FILTER: 'gridTab',
  CAMPAIGN_STATUS: 'status',
  IS_BULK_UPLOAD_MODEL_OPENED: 'isBulkUploadModelOpened',
  CSV_URL_PATH: 'bulkUploadData.csvUrlPath',
  BULK_UPLOAD_DATA: 'bulkUploadData',
  BULK_UPLOAD_RECORD: 'bulkUploadRecord'
};

export const i18nKeys = {
  BACK_OFFICE: 'components.common.back-office'
};

export const storeKeys = {
  RECORD_CREATE: 'recordCreate',
  RECORD_EDIT: 'record.data'
};

export const gridTabs = {
  SALES: 'sales',
  TICKETS: 'tickets',
  MERCHANDISE: 'merchandise',
  DONATIONS: 'donations',
  FUNDRAISERS: 'fundraisers',
};

export const campaignStatuses = {
  LIVE: {
    description: 'Live',
    value: 1
  },
  COMPLETED: {
    description: 'Complete',
    value: 2
  },
  ARCHIVED: {
    description: 'Archived',
    value: 3
  }
};

export const pageStatuses = {
  Live: 1,
  Completed: 2,
  Archived: 3
};

export const pageTypes = {
  FUNDRAISER: '1',
  TEAM: '2',
  COLLECTION: '3'
};

export const pageTypeStrings = {
  [pageTypes.FUNDRAISER]: 'fundraiser',
  [pageTypes.TEAM]: 'team',
  [pageTypes.COLLECTION]: 'collection'
};

export const formBuilderKeys = {
  CAMPAIGN_TICKETING: 'campaign-ticketing',
  CAMPAIGN_FUNDRAISING: 'campaign-fundraising',
  CAMPAIGN_DONATIONS: 'campaign-donations',
  EVENT_FUNDRAISING: 'event-fundraising',
  EVENT_DONAIONS: 'event-donations',
  MERCHANDISE: 'merchandise',
  ENTRY: 'application-entry',
  CONFIRMATION: 'application-confirmation',
  ADDITIONAL: 'application-additional'
};

export const funraisningExtraFields = {
  PHONE: 'phone',
  ADDRESS: 'address'
};

export const campaignSalesAvailableFilters = [
  'amount',
  'soldAtLocal',
  'type',
  'financials.returned',
  'financials.installments.lastPaymentFailed',
  'financials.installments.paymentCompleted'
];
export const campaignTicketsAvailableFilters = [
  'ticketId',
  'soldAtLocal',
  'sale.type',
  'financials.returned'
];
export const campaignMerchandiseAvailableFilters = [
  'merchandiseId',
  'soldAtLocal',
  'sale.type',
  'financials.returned'
];
export const campaignFundraisingAvailableFilters = [
  'teamId',
  'fundraiserId',
  'collectionId',
  'type',
  'raisedAmount',
  'averageDonationAmount',
  'donationCount',
  'createdAtLocal',
  'isActive'
];
export const campaignDonationsAvailableFilters = [
  'teamId',
  'fundraiserId',
  'collectionId',
  'donatedAtLocal',
  'amount',
  'type',
  'financials.refunded',
  'paymentPlatform.transferId'
];

export const teamFundraisingAvailableFilters = [
  'fundraiserId',
  'type',
  'raisedAmount',
  'averageDonationAmount',
  'donationCount',
  'createdAtLocal',
  'isActive'
];
export const teamDonationsAvailableFilters = [
  'fundraiserId',
  'donatedAtLocal',
  'amount',
  'type',
  'financials.refunded',
  'paymentPlatform.transferId'
];
export const fundraiserDonationsAvailableFilters = [
  'donatedAtLocal',
  'amount',
  'type',
  'financials.refunded',
  'paymentPlatform.transferId'
];

export const ONLINE_DONATION_TYPE = 'online';
export const OFFLINE_DONATION_TYPE = 'offline';
export const PLEDGE_DONATION_TYPE = 'pledge';
export const PENDING_DONATION_TYPE = 'pending';

export const GOOGLE_PAY_DONATION_WALLET_TYPE = 'googlePay';
export const APPLE_PAY_DONATION_WALLET_TYPE = 'applePay';

export const DONATION_VIDEO = 'donationVideo';

export const individualAboutDefaultText = `Hi there\n\nI am trying to fundraise $*|amount|* to help *|organisationName|*. Please help me by making a donation through my page.\n\nIt only takes a minute and any money you can give (no matter how large or small) will go a long way to helping me reach my target.\n\nThank you, I really appreciate your support.\n\n*|firstName|*.`;
export const individualAboutDefaultTextInMemoryCampaign = `Hi there\n\nWe are trying to fundraise $*|amount|* in honour of *[deceased name]*. Please help us by making a donation through our page.\n\nIt only takes a minute and any money you can give (no matter how large or small) will go a long way. All money fundraised will help *|organisationName|*.\n\nThank you, we really appreciate your support.\n\n*|firstName|*.`;
export const teamAboutDefaultText = `Hi there\n\nWe are trying to fundraise $*|amount|* to help *|organisationName|*. To accomplish this we have created a team called *|teamName|*. If you can, please help by fundraising alongside us in *|teamName|*.\n\nIf fundraising is not your thing then you can also help by making a donation through our team's page.\n\nIt only takes a minute and any money you can give (no matter how large or small) will go a long way to helping us reach our target.\n\nThank you, we really appreciate your support.\n\n*|teamName|*.`;
export const collectionAboutDefaultText = `Hi there\n\nWe are trying to fundraise $*|amount|* to help *|organisationName|*. To accomplish this we have created a collection called *|collectionName|*. If you can, please help by fundraising alongside us in *|collectionName|*.\n\nIf fundraising is not your thing then you can also help by making a donation through our team's page.\n\nIt only takes a minute and any money you can give (no matter how large or small) will go a long way to helping us reach our target.\n\nThank you, we really appreciate your support.\n\n*|collectionName|*.`;

export const PRIVACY_COLUMN = 'isPublic';
export const TYPE_COLUMN = 'type';

export const OFFLINE_SALE_TYPE = 'offline';

export const privacyStrings = {
  PUBLIC: 'Public',
  PRIVATE: 'Private'
};

export const campaignAvailableFiltersKeys = [
  'targetAmount',
  'raisedAmount',
  'averageDonationAmount',
  'additionalDonationsNeededForTarget',
  'pagesCount',
  'activePagesCount',
  'donationCount',
  'createdAtLocal',
  'fundraisers.enabled',
  'teams.enabled',
  'type'
];

export const CSV_BULK_TEMPLATE =
  'PageName,PageType,TargetAmount,EmailAddress,LinkedTeam,LinkedCollection,PhoneNumber,AddressStreet,AddressCity,AddressState,AddressPostcode,AddressCountry,FirstName,LastName\nMy Team,Team,1000,,,,,,,,,,,\nMy Page,Fundraiser,,,My Team,,,,,,,,John,Doe';

  export const FORMBUILDER_REGISTRATION_PATH = `${generalFormBuilderPath}.${customFieldCategory.FUNDRAISING}`;

export const donationType = {
  recurringAndOnceOff: 'recurringAndOnceOff',
  onlyOnceOff: 'onlyOnceOff',
  onlyRecurring: 'onlyRecurring'
};

export const donationTypeOptions = [
  {
    value: donationType.recurringAndOnceOff,
    label:
      "Offer both once-off donations and recurring giving when selecting 'Give now'"
  },
  {
    value: donationType.onlyOnceOff,
    label: "Offer only once-off donations when selecting 'Give now'"
  },
  {
    value: donationType.onlyRecurring,
    label: "Offer only recurring donations when selecting 'Give now'"
  }
];

export const saleDonationTypeOptions = [
  {
    value: donationType.recurringAndOnceOff,
    label:
      'Offer both once-off donations and recurring giving during ticket checkout'
  },
  {
    value: donationType.onlyOnceOff,
    label: 'Offer only once-off donations during ticket checkout'
  },
  {
    value: donationType.onlyRecurring,
    label: 'Offer only recurring donations during ticket checkout'
  }
];

export const ticketIssuingStyle = {
  IndividualTicket: 'individualTicket',
  GroupedToBuyer: 'groupedToBuyer'
};

export const admissionTranslationKeys = {
  [ticketIssuingStyle.GroupedToBuyer]: 'grouped-to-buyer',
  [ticketIssuingStyle.IndividualTicket]: 'individual-ticket-text'
};

export const ticketIssuingStyleOptions = [
  {
    value: ticketIssuingStyle.GroupedToBuyer,
    label: 'General admission'
  },
  {
    value: ticketIssuingStyle.IndividualTicket,
    label: 'Specific admission'
  }
];

export const simpleTicketingEnabledOptions = [
  {
    value: false,
    label: 'Using Grassrootz'
  },
  {
    value: true,
    label: 'Using a third party website'
  }
];

export const defaultCustomUrlStyle = 'campaignAsMainDomain';
export const customUrlStyles = [
  {
    value: 'campaignAsMainDomain',
    label: 'Campaign as main domain'
  },
  {
    value: 'campaignAsSubDomain',
    label: 'Campaign as subdomain'
  },
  {
    value: 'campaignAsPath',
    label: 'Campaign as path'
  }
];

export const mandatoryOptions = [
  {
    value: false,
    label: 'Optional'
  },
  {
    value: true,
    label: 'Mandatory'
  }
];

export const defaultTicketPurchaseFundraisingStyle = 'buyerOnlyFundraises';
export const buyerOnlyFundraises = 'buyerOnlyFundraises';
export const eachTicketholderFundraises = 'eachTicketholderFundraises';

export const ticketPurchaseFundraisingStyles = [
  {
    value: buyerOnlyFundraises,
    label: 'Creates a page for the purchaser only'
  },
  {
    value: eachTicketholderFundraises,
    label: 'Creates page(s) for all tickets holders'
  }
];

export const joiningExperience = {
  adminOnly: 1,
  singleLevelPlusTeam: 2,
  allLevelsPlusTeams: 3
};

export const joiningExperienceOptions = [
  {
    value: joiningExperience.adminOnly,
    text: 'Admin only'
  },
  {
    value: joiningExperience.singleLevelPlusTeam,
    text: 'Single Level plus Team'
  },
  {
    value: joiningExperience.allLevelsPlusTeams,
    text: 'All Levels plus Teams'
  }
];

export const recurringLimitOptions = [
  {
    value: null,
    text: 'Monthly - No time limit'
  },
  {
    value: 3,
    text: 'Monthly - Stop after 3 payments'
  },
  {
    value: 6,
    text: 'Monthly - Stop after 6 payments'
  }
];

export const campaignTypeOptions = [
  {
    value: campaignTypes.default,
    text: 'Default'
  },
  {
    value: campaignTypes.giveNow,
    text: 'Give Now'
  },
  {
    value: campaignTypes.appeal,
    text: 'Appeal'
  },
  {
    value: campaignTypes.bespoke,
    text: 'Bespoke'
  },
  {
    value: campaignTypes.inMemory,
    text: 'In Memory'
  },
  {
    value: campaignTypes.celebration,
    text: 'Celebration'
  },
  {
    value: campaignTypes.challenge,
    text: 'Challenge'
  },
  {
    value: campaignTypes.cycling,
    text: 'Cycling'
  },
  {
    value: campaignTypes.running,
    text: 'Running'
  },
  {
    value: campaignTypes.event,
    text: 'Event'
  },
  {
    value: campaignTypes.custom,
    text: 'Custom'
  }
];

export const invertionTextColourOptions = [
  {
    value: false,
    text: 'White'
  },
  {
    value: true,
    text: 'Black'
  }
];

export const fitnessActivityType = {
  walk: 'walk',
  run: 'run',
  swim: 'swim',
  cycle: 'cycle',
  wheel: 'wheel',
  workout: 'workout',
  other: 'other'
};

export const fitnessActivityTypeOptions = [
  {
    value: fitnessActivityType.walk,
    i18nKey: 'walk'
  },
  {
    value: fitnessActivityType.run,
    i18nKey: 'run'
  },
  {
    value: fitnessActivityType.cycle,
    i18nKey: 'cycle'
  },
  {
    value: fitnessActivityType.swim,
    i18nKey: 'swim'
  },
  {
    value: fitnessActivityType.wheel,
    i18nKey: 'wheel'
  },
  {
    value: fitnessActivityType.workout,
    i18nKey: 'workout'
  },
  {
    value: fitnessActivityType.other,
    i18nKey: 'other'
  }
];

export const fitnessPlatform = {
  strava: 'strava',
  fitbit: 'fitbit'
};

export const fitnessPlatformOptions = [
  {
    value: fitnessPlatform.strava,
    i18nKey: 'strava',
    disabled: false
  },
  {
    value: fitnessPlatform.fitbit,
    i18nKey: 'fitbit',
    disabled: false
  }
];

export const fitnessMeasure = {
  distance: 'distance',
  time: 'time',
  numberOfSteps: 'numberOfSteps',
  reps: 'repetitions'
};

export const fitnessMeasureUnit = {
  distance: 'KMs',
  time: 'Hours',
  numberOfSteps: 'Steps',
  repetitions: 'Reps'
};

export const fitnessMeasureOptions = [
  {
    value: fitnessMeasure.distance,
    i18nKey: 'distance',
    isVisible: (activities) => {
      return true;
    }
  },
  {
    value: fitnessMeasure.time,
    i18nKey: 'time',
    isVisible: (activities) => {
      return true;
    }
  },
  {
    value: fitnessMeasure.numberOfSteps,
    i18nKey: 'numberOfSteps',
    isVisible: (activities) => {
      return activities.some(
        (item) =>
          item === fitnessActivityType.walk || item === fitnessActivityType.run
      );
    }
  },
  {
    value: fitnessMeasure.reps,
    i18nKey: 'repetitions',
    isVisible: (activities) => {
      return activities.some(
        (item) =>
          item === fitnessActivityType.workout
      );
    }
  }
];

export const raisedTotalType = {
  donationAndSales: 'donationAndSales',
  donations: 'donations',
  sales: 'sales',
  donationsAndSalesNoFee: 'donationsAndSalesNoFee',
  donationsNoFee: 'donationsNoFee',
  campaignAndEventOnlineOnly: 'campaignAndEventOnlineOnly'
};

export const raisedTotalTypeOptions = [
  {
    value: null,
    text: ''
  },
  {
    value: raisedTotalType.donationAndSales,
    text: 'Donations and Sales'
  },
  {
    value: raisedTotalType.donations,
    text: 'Donations'
  },
  {
    value: raisedTotalType.sales,
    text: 'Sales'
  },
  {
    value: raisedTotalType.donationsAndSalesNoFee,
    text: 'Donations and Sales no fee'
  },
  {
    value: raisedTotalType.donationsNoFee,
    text: 'Donations no fee'
  },
  {
    value: raisedTotalType.campaignAndEventOnlineOnly,
    text: 'Event and Campaign Online Donations only'
  }
];

export const defaultFitnessActivities = `${fitnessActivityType.walk},${fitnessActivityType.run}`;
export const defaultFitnessTrackedStatistics = `${fitnessMeasure.distance}`;
export const defaultLeaderboardsToShow = [fitnessMeasure.distance];

export const activityTypeMeasures = {
  [fitnessActivityType.cycle]: [fitnessMeasure.distance, fitnessMeasure.time],
  [fitnessActivityType.run]: [
    fitnessMeasure.distance,
    fitnessMeasure.numberOfSteps,
    fitnessMeasure.time
  ],
  [fitnessActivityType.wheel]: [fitnessMeasure.distance, fitnessMeasure.time],
  [fitnessActivityType.workout]: [fitnessMeasure.reps],
  [fitnessActivityType.swim]: [fitnessMeasure.distance, fitnessMeasure.time],
  [fitnessActivityType.walk]: [
    fitnessMeasure.distance,
    fitnessMeasure.numberOfSteps,
    fitnessMeasure.time
  ],
  [fitnessActivityType.other]: [fitnessMeasure.distance, fitnessMeasure.time]
};

export const paymentFeeCalculation = {
  onTopOfTicketPrice: 'onTopOfTicketPrice',
  constantTicketPricing: 'constantTicketPricing'
};
