// React / Redux / Related
import React, { Component } from 'react';
import get from 'lodash.get';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';

// Actions
import * as programActions from '../store/vipProgramActions';

// Components
import ListsViewContainer from './listview';
import ContextMenu from '../../../components/common/grid/contextMenu';
import { Common } from '../../../components';
import clear from '../../../components/clear';
import BasePage from '../../../components/common/basePageView';
import { Record } from '../../../modules/bin/utility';

// Constants
import config from '../../../constants/index';
import { gridTabs, i18nKeys } from '../constants';
import { exportFileNames } from '../../../constants/exportFiles';

import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';

// ----------------------------------------------------------------------

/** A page to view all campaigns in a list */
class CampaignAmbassadorView extends Component {
  constructor(props) {
    super(props);

    const campaignId = get(props, 'match.params.campaignId');

    props.getCampaignAmbassadorProgramByCampaignRecord(campaignId);

    props.getTotalCountApplications(
      'eventAmbassador/aggregate',
      'campaignId',
      campaignId
    );
    props.getTotalCountPendingApprovalProgramsByReceivedStatus(
      'eventAmbassador/aggregate',
      'campaignId',
      campaignId
    );
    props.getTotalCountVIPProgramsByClaimedStatus(
      'eventAmbassador/aggregate',
      'campaignId',
      campaignId
    );
    props.getTotalCountVIPProgramsByAcceptedStatus(
      'eventAmbassador/aggregate',
      'campaignId',
      campaignId
    );
  }

  getCampaignApplicationsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('campaignApplications.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('campaignApplications.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
        />
      </ContextMenu>
    );
  };

  getCampaignPendingApprovalProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('campaignApplications.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('campaignApplications.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
        />
      </ContextMenu>
    );
  };

  getCampaignVipProgramsContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION.key}
          label={I18n.t('campaignVipPrograms.list.context.edit-application')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.EDIT_APPLICATION}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER.key}
          label={I18n.t('campaignVipPrograms.list.context.view-fundraiser')}
          contextMenuOptions={CONTEXT_MENU_KEYS.PROGRAM.VIEW_FUNDRAISER}
        />
      </ContextMenu>
    );
  };

  onEditCampaignAmbassadorProgramClick = () => {
    const campaignId = get(this.props, 'match.params.campaignId');

    this.props.history.push(`/vip-program/campaign/edit/${campaignId}/setup`);
  };

  onViewApplicationFormClick = () => {
    const campaignAmbassadorUrlFull = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data.urlFull'
    );

    window.open(campaignAmbassadorUrlFull, '_blank');
  };

  /**
   * Renders the component
   */
  render() {
    const campaignId = get(this.props, 'match.params.campaignId');

    const baseFilter = {
      key: 'campaignId',
      operator: 'EqualTo',
      value: campaignId
    };

    const vipProgramsFilters = [
      { key: 'campaignId', operator: 'EqualTo', value: [campaignId] },
      { key: 'status', operator: 'InList', value: ['Accepted', 'Claimed'] }
    ];
    const pendingApprovalProgramsFilters = [
      { key: 'campaignId', operator: 'EqualTo', value: campaignId },
      { key: 'status', operator: 'EqualTo', value: 'Received' }
    ];

    const campaignAmbassadorData = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data'
    );

    const isLoading =
      Record.isRecordReady(this.props.campaignAmbassadorProgramRecord) &&
      Record.isRecordReady(this.props.totalCountApplicationsRecord) &&
      Record.isRecordReady(
        this.props.totalCountVIPProgramsByClaimedStatusRecord
      ) &&
      Record.isRecordReady(
        this.props.totalCountPendingApprovalProgramsByReceivedStatusRecord
      ) &&
      Record.isRecordReady(
        this.props.totalCountVIPProgramsByAcceptedStatusRecord
      );

    const campaignAmbassadorAvailability = get(campaignAmbassadorData, 'availability.newEntries');
    const organizationName = get(campaignAmbassadorData, 'organization.name');
    const campaignName = get(campaignAmbassadorData, 'campaign.name');

    const campaignImage = get(campaignAmbassadorData, 'mainImagePath');
    const organizationImage = get(
      campaignAmbassadorData,
      'organization.mainImagePath'
    );

    const metrics = get(campaignAmbassadorData, 'metrics');

    return (
      <BasePage>
        <div>
          <Common.PageHeader
            loading={!isLoading}
            name={campaignName}
            image={organizationImage ? organizationImage : campaignImage}
            afterBreadcrumbs={
              <>
                <Icon
                  name="circle"
                  style={{
                    marginLeft: "1rem",
                    color: campaignAmbassadorAvailability ? "#56B68B" : "#757575"
                  }}
                />
                Applications {campaignAmbassadorAvailability ? "Open" : "Closed"}
              </>
            }
            headerRightPanel={
              <div className="page-header-right-panel">
                <Common.Button onClick={this.onViewApplicationFormClick}>
                  <Translate value={i18nKeys.VIEW_APPLICATION_FORM} />
                </Common.Button>
                <Common.Button
                  onClick={this.onEditCampaignAmbassadorProgramClick}
                >
                  <Translate
                    value={i18nKeys.EDIT_PROGRAM_CAMPAIGN_VIEW_BUTTON}
                  />
                </Common.Button>
              </div>
            }
          >
            <Link to={config.DEFAULT_PROGRAMS_URL} push>
              <Common.PageHeader.Breadcrumb
                label={I18n.t(i18nKeys.VIEW_LABEL)}
              />
            </Link>
            <Common.PageHeader.Breadcrumb label={organizationName} />
          </Common.PageHeader>
        </div>
        <ListsViewContainer
          baseFilter={baseFilter}
          filterPath="vip-program/campaign"
          metrics={metrics}
          applicationsGridKey={gridTabs.CAMPAIGN_APPLICATIONS}
          applicationsExportName={exportFileNames.CAMPAIGN_APPLICATIONS}
          applicationsExportEndpoint={'EventAmbassador'}
          applicationsContextMenu={this.getCampaignApplicationsContextMenu()}
          pendingApprovalProgramsGridKey={
            gridTabs.CAMPAIGN_PENDING_APPROVAL_PROGRAMS
          }
          pendingApprovalProgramsExportName={
            exportFileNames.CAMPAIGN_PENDING_APPROVAL_PROGRAMS
          }
          pendingApprovalProgramsExportEndpoint={'EventAmbassador'}
          pendingApprovalProgramsContextMenu={this.getCampaignPendingApprovalProgramsContextMenu()}
          pendingApprovalProgramsFilters={pendingApprovalProgramsFilters}
          vipProgramsGridKey={gridTabs.CAMPAIGN_VIP_PROGRAMS}
          vipProgramsExportName={exportFileNames.CAMPAIGN_VIP_PRORGAMS}
          vipProgramsExportEndpoint={'EventAmbassador'}
          vipProgramsContextMenu={this.getCampaignVipProgramsContextMenu()}
          vipProgramsFilters={vipProgramsFilters}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = (state) => {
  return {
    campaignAmbassadorProgramRecord:
      state.program.campaignAmbassadorProgramRecord,
    totalCountProgramsRecord: state.program.totalCountProgramsRecord,
    totalCountApplicationsRecord: state.program.totalCountApplicationsRecord,
    totalCountPendingApprovalProgramsByReceivedStatusRecord:
      state.program.totalCountPendingApprovalProgramsByReceivedStatusRecord,
    totalCountVIPProgramsByClaimedStatusRecord:
      state.program.totalCountVIPProgramsByClaimedStatusRecord,
    totalCountVIPProgramsByAcceptedStatusRecord:
      state.program.totalCountVIPProgramsByAcceptedStatusRecord
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(programActions, dispatch);

  return dispatchers;
};

/** Connects component to Redux store */
const CampaignAmbassadorViewPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(CampaignAmbassadorView))
);
export default CampaignAmbassadorViewPageContainer;
