import get from 'lodash.get';
import recordConstants from '../../constants/record';

export interface IRecord {
  id: any;
  ready: recordConstants;
  error: any;
  success: any;
  data: any;
  state: any;
}

export const processHttpCodes = (
  action: any,
  filterList: any,
  elseFunc: any
) => {
  const errorStatus = get(action, 'payload.status');
  if (errorStatus && filterList) {
    for (let i = 0; i < filterList.length; i++) {
      const filter = filterList[i];

      if (
        errorStatus >= filter.start &&
        errorStatus <= (filter.end || filter.start)
      ) {
        if (filter.handler) {
          return filter.handler();
        }
      }
    }
  }

  return elseFunc ? elseFunc() : null;
};

export class Record {
  static getDefaultState(isLoading?: boolean) {
    const emptyState = {} as any;
    return {
      id: null,
      ready: isLoading
        ? recordConstants.RECORD_STATUS_LOADING
        : recordConstants.RECORD_STATUS_NO_DATA,
      error: {
        show: false,
        errorKeys: null
      },
      success: {
        show: false
      },
      data: emptyState,
      state: emptyState
    };
  }

  static isSuccess(record: IRecord) {
    return record.success.show;
  }

  static isRecordReady(record: IRecord) {
    return record.ready === recordConstants.RECORD_STATUS_READY;
  }

  static isRecordLoading(record: IRecord) {
    return record.ready === recordConstants.RECORD_STATUS_LOADING;
  }

  static notStarted(record: IRecord) {
    return record.ready === recordConstants.RECORD_STATUS_NO_DATA;
  }

  static isError(record: IRecord) {
    return record.ready === recordConstants.RECORD_STATUS_ERROR;
  }

  static isRecordLoadingError(record: IRecord) {
    return (
      record.ready !== recordConstants.RECORD_STATUS_READY &&
      record.ready !== recordConstants.RECORD_STATUS_LOADING
    );
  }
}

export const getUrl = (name: string) => {
  if (name) {
    return name.toLowerCase().replace(/ /g, '-');
  }
  return '';
};
