import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';

import { injectStripe } from 'react-stripe-elements';
import { Form, Message } from 'semantic-ui-react';
import Card from './card';

import { cardActions } from '../../../modules/card';

export class MakePaymentForm extends React.Component {
  componentDidUpdate() {
    if (this.props.needPaymentData) {
      this.handleSubmit();
    }
  }

  handleSubmit = () => {
    this.props.setPaymentStarted();
    this.props.stripe
      .createToken({
        name: `${this.props.donor.firstName} ${this.props.donor.lastName}`
      })
      .then(this.submitPayment)
      .catch((error) => {
        this.props.makePaymentErrorOccured(error);
      });
  };

  submitPayment = ({ token }) => {
    const payment = get(this.props.templateBuilder, `data.${this.props.path}`);
    const paymentInfo = {
      ...payment,
      token: token.id,
      cardBrand: token.card.brand,
      cardCountry: token.card.country,
      cardExpiryMonth: token.card.exp_month,
      cardExpiryYear: token.card.exp_year,
      cardLast4Digits: token.card.last4
    };

    this.props.setPaymentSuccess();

    this.props.onChange(this.props.path, paymentInfo);
    if (this.props.makePayment) {
      this.props.makePayment(this.props.templateBuilder.data);
    }
  };

  render() {
    return (
      <Form>
        <Form.Field required={this.props.required}>
          {this.props.label && <label>{this.props.label}</label>}
          <Card path={this.props.path} onChange={this.props.onChange} />
        </Form.Field>
        {this.props.serverErrorOccured && (
          <Message negative>
            <Message.Header>
              There was a problem with processing your transaction
            </Message.Header>
            <p className="error">
              Please check your information or reload the page and try again
            </p>
          </Message>
        )}
      </Form>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    serverErrorOccured: get(state.card, `${ownProps.path}.serverErrorOccured`),
    needPaymentData: get(state.card, `${ownProps.path}.needPaymentData`),
    templateBuilder: state.templateBuilderEx
  };
};

const mapDispatch = (dispatch, ownProps) => {
  return {
    setPaymentStarted: () => {
      dispatch(cardActions.setPaymentStarted(ownProps.path));
    },
    setPaymentSuccess: () => {
      dispatch(cardActions.setPaymentSuccess(ownProps.path));
    },
    makePaymentErrorOccured: (error) => {
      dispatch(cardActions.makePaymentErrorOccured(error, ownProps.path));
    }
  };
};

export default injectStripe(connect(mapState, mapDispatch)(MakePaymentForm));
