import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pageActions } from '../reducer';
import EditPageContainer from './edit-page';
import { pageTypes } from '../../../../constants/pageTypes';

const EditTeamPage = (props) => {
  return (
    <EditPageContainer
      getRecord={props.pageActions.getTeamRecord}
      updateRecord={props.pageActions.updateTeamRecord}
      updateStatus={props.pageActions.updateTeamStatus}
      template={props.template}
      type={pageTypes.TEAM}
    />
  );
};

const mapState = ({ campaignPage }) => {
  return {
    template: campaignPage.teamTemplate
  };
};

const mapDispatch = (dispatch) => {
  return {
    pageActions: bindActionCreators(pageActions, dispatch)
  };
};

const EditTeamPageContainer = connect(mapState, mapDispatch)(EditTeamPage);
export default EditTeamPageContainer;
