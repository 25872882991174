import update from '../../../helpers/update';
import {
  getListData,
  getMetadata,
  createRecord,
  updateRecord,
  deleteRecord,
  getRecord
} from '../../../modules/bin/entityHandlers';
import {
  WEBHOOK_API_PATH,
  WEBHOOKS_MESSAGE_CLEAR,
  WEBHOOKS_GET_REQUESTED,
  WEBHOOKS_GET_SUCCESS,
  WEBHOOKS_GET_FAILURE,
  WEBHOOKS_METADATA_REQUESTED,
  WEBHOOKS_METADATA_SUCCESS,
  WEBHOOKS_METADATA_FAILURE,
  WEBHOOKS_LIST_DATA_REQUESTED,
  WEBHOOKS_LIST_DATA_SUCCESS,
  WEBHOOKS_LIST_DATA_FAILURE,
  WEBHOOKS_CREATE_SUCCESS,
  WEBHOOKS_CREATE_FAILURE,
  WEBHOOKS_CREATE_REQUESTED,
  WEBHOOK_UPDATE_REQUESTED,
  WEBHOOK_UPDATE_SUCCESS,
  WEBHOOK_UPDATE_FAILURE,
  WEBHOOKS_DELETE_REQUESTED,
  WEBHOOKS_DELETE_FAILURE,
  WEBHOOKS_DELETE_SUCCESS
} from './webhookTypes';

export const clearWebhookErrors = (record: any) => ({
  type: WEBHOOKS_MESSAGE_CLEAR,
  payload: { key: record }
});

export const getWebhook = (id: string) =>
  getRecord(
    WEBHOOK_API_PATH,
    WEBHOOKS_GET_REQUESTED,
    WEBHOOKS_GET_SUCCESS,
    WEBHOOKS_GET_FAILURE,
    id
  );

export const getWebhookMetadata = (listKey: any) =>
  getMetadata(
    WEBHOOK_API_PATH,
    listKey,
    WEBHOOKS_METADATA_REQUESTED,
    WEBHOOKS_METADATA_SUCCESS,
    WEBHOOKS_METADATA_FAILURE
  );

export const getWebhookListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  getListData(
    WEBHOOK_API_PATH,
    listKey,
    id,
    WEBHOOKS_LIST_DATA_REQUESTED,
    WEBHOOKS_LIST_DATA_SUCCESS,
    WEBHOOKS_LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );

export const getWebhookListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: any,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getWebhookListData(listKey, id, newRequest, true);
};

export const createWebhook = (webhook: any) =>
  createRecord(
    WEBHOOK_API_PATH,
    WEBHOOKS_CREATE_REQUESTED,
    WEBHOOKS_CREATE_SUCCESS,
    WEBHOOKS_CREATE_FAILURE,
    webhook,
    null,
    'recordCreate'
  );

export const updateWebhook = (id: number, webhook: any) =>
  updateRecord(
    WEBHOOK_API_PATH,
    WEBHOOK_UPDATE_REQUESTED,
    WEBHOOK_UPDATE_SUCCESS,
    WEBHOOK_UPDATE_FAILURE,
    id,
    webhook,
    null,
    'recordEdit'
  );

export const deleteWebhook = (id: number) =>
  deleteRecord(
    WEBHOOK_API_PATH,
    WEBHOOKS_DELETE_REQUESTED,
    WEBHOOKS_DELETE_SUCCESS,
    WEBHOOKS_DELETE_FAILURE,
    id
  );
