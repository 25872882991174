import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../';
import * as templateHelper from './templateHelper';

class ManagedColorPicker extends Component {
  onChange = (value) => {
    if (value === 'transparent')
    {
      value = '#fff';
    }
    this.props.onChange(this.props.path, value);
  };
  render() {
    const { id, label, subtext, value, validation } = this.props;
    const defaultValue = this.props.default;

    return (
      <Form.ColorPicker
        id={id}
        label={label}
        subtext={subtext}
        default={defaultValue}
        value={value}
        onChange={this.onChange}
        required={validation && validation.required}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  //const config = templateHelper.getConfigurationEntry(template, id);

  // Component configuration
  //const defaultValue = config.default;

  return {
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export const TemplateColorPicker = connect(mapState)(ManagedColorPicker);
