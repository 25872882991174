import React from 'react';
import { connect } from 'react-redux';
import CreateChildModalContainer from './childModal';
import CreateParentModalContainer from './parentModal';
import { Common } from '../../../../components';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  changeParentCreateWindowVisible,
  changeChildCreateWindowVisible,
  addCustomCampaignOption
} from '../../store/campaignActions';

class PreCreateCampaign extends React.Component {
  /**
   * Navigates to the appropriate campaign template
   * @param {string} parentTemplate - the name of the parent template (if applicable)
   * @param {string} template - the name of the selected template
   */
  onWizardCommand = (parentTemplate, template) => {
    this.props.closeWindows();
    const path = parentTemplate
      ? `/campaigns/create/${parentTemplate}/${template}/step/setup`
      : `/campaigns/create/${template}/step/setup`;
    this.props.history.push(path);
  };

  /**
   * Handles 'Get Started' command on parent window
   */
  onParentWizardCommand = () => {
    // if we have child templates
    if (!!this.props.data.children[this.props.parentTemplate]) {
      this.props.showChildWindow();
    } else {
      this.onWizardCommand(null, this.props.parentTemplate);
    }
  };

  /**
   * Handles 'Get Started' command on child window
   */
  onChildWizardCommand = () => {
    this.onWizardCommand(this.props.parentTemplate, this.props.childTemplate);
  };

  onCreateCampaignClick = () => {
    if (this.props.isSystemAdmin) {
      this.props.addCustomCampaignOption();
    }
    this.props.showCampaignWindow();
  };

  render() {
    return (
      <div>
        {!this.props.isCampaignAdmin && !this.props.isEventAdmin && (
          <Common.Button
            primary
            onClick={this.onCreateCampaignClick}
            id="create-campaign"
          >
            <Translate value="campaign.button.create-campaign" />
          </Common.Button>
        )}
        <CreateParentModalContainer onCommand={this.onParentWizardCommand} />
        <CreateChildModalContainer onCommand={this.onChildWizardCommand} />
      </div>
    );
  }
}

const mapState = ({ campaign, session }) => {
  const isEventAdmin = session.isEventAdmin;
  const isCampaignAdmin = session.isCampaignAdmin;

  return {
    data: campaign.templates.metadata,
    parentTemplate: campaign.parentTemplate,
    childTemplate: campaign.childTemplate,
    isSystemAdmin: session.isSystemAdmin,
    isCampaignAdmin,
    isEventAdmin
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    addCustomCampaignOption() {
      dispatch(addCustomCampaignOption());
    },
    showCampaignWindow() {
      dispatch(changeParentCreateWindowVisible(true));
    },
    showChildWindow() {
      dispatch(changeParentCreateWindowVisible(false));
      dispatch(changeChildCreateWindowVisible(true));
    },
    closeWindows() {
      dispatch(changeParentCreateWindowVisible(false));
      dispatch(changeChildCreateWindowVisible(false));
    }
  };
};

const PreCreateCampaignContainer = withRouter(
  connect(mapState, mapDispatch)(PreCreateCampaign)
);
export default PreCreateCampaignContainer;
