import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Button, Modal } from 'semantic-ui-react';
import { useAppDispatch } from '../../../hooks';
import { IWebhook } from '../../../models/Webhook';
import { AppDispatch, RootState } from '../../../store';
import { deleteWebhook } from '../store/webhookActions';

interface DeleteWebhookModalProps {
  webhook?: IWebhook;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

export const DeleteWebhookModal = ({
  webhook,
  isOpen,
  setIsOpen
}: DeleteWebhookModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const webhookReducer = useSelector((state: RootState) => state.webhook);
  const dispatch: AppDispatch = useAppDispatch();

  const onDeleteConfirm = () => {
    if (!webhook) return;
    setIsLoading(true);
    dispatch(deleteWebhook(webhook.id));
  };

  useEffect(() => {
    if (webhookReducer.record.ready === -1 || webhookReducer.record.error) {
      setIsOpen(false);
      setIsLoading(false);
    }
  }, [webhookReducer, webhookReducer.record.error]);

  return (
    <Modal open={isOpen}>
      {webhook && (
        <Modal.Content>
          <h1>
            <Translate value={'webhooks.page.delete-modal.title'} />
          </h1>
          <p>
            <b>
              <Translate value={'webhooks.page.delete-modal.organization'} />
            </b>
            {webhook.organization.name}
          </p>
          <p>
            <b>
              <Translate value={'webhooks.page.delete-modal.url'} />
            </b>
            {webhook.subscriberEndpoint}
          </p>
        </Modal.Content>
      )}
      <Modal.Actions>
        <Button
          disabled={isLoading}
          className="basic"
          secondary
          onClick={() => setIsOpen(false)}
        >
          <Translate value={'webhooks.page.delete-modal.cancel'} />
        </Button>
        <Button
          className="red"
          loading={isLoading}
          content={<Translate value={'webhooks.page.delete-modal.delete'} />}
          onClick={onDeleteConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};
