import React, { Component } from 'react';
import { Icon } from 'semantic-ui-react';

class SortIcon extends Component {
  render() {
    const { active, direction } = this.props;
    const activeClass = active === true ? 'active' : '';
    const iconClass = `sort-icon ${activeClass}`;
    const iconKey = direction === 'desc' ? 'chevron down' : 'chevron up';

    return <Icon className={iconClass} name={iconKey} />;
  }
}

export default SortIcon;
