import React, { useMemo } from 'react';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';
import { Loader } from 'semantic-ui-react';

import { stripeName } from '../../../../pages/organization/constants';

// TODO: the data source can come from either organization or campaings, both data sets have different paymentPlatorms
// i.e. one inclues paymentPlatorms as a list, the other is an object with main and alternative paymentPlatorm list
// update the below to only include campaigns data set (main/alternative) when donations are converted over
export const DonationFinancialsPlatformSectionRow = (props) => {
  const {
    title,
    paymentPlatforms,
    alternativePaymentPlatforms,
    data,
    isSystemAdmin,
    getPaymentPlatforms,
    isPaymentPlatformsLoaded,
    customPaymentPlatform
  } = props;

  const platform = get(data, 'platform');
  const chargeKey = get(data, 'platformDetails.chargeKey');
  const intentKey = get(data, 'platformDetails.intentKey');
  const saleInstallmentKey = get(data, 'platformDetails.installmentKey');
  const paymentKey = intentKey || chargeKey;
  const isValidKey = intentKey || chargeKey || saleInstallmentKey;

  const accountKey = useMemo(() => {
    if (customPaymentPlatform) {
      return customPaymentPlatform.accountKey;
    }
    const platform =
      alternativePaymentPlatforms?.find(
        (p) => p.platform === stripeName && p.purpose?.toLowerCase() === 'sale'
      ) || paymentPlatforms?.find((p) => p.platform === stripeName);
    return platform?.accountIdentifier || platform?.accountKey;
  }, [alternativePaymentPlatforms, paymentPlatforms, stripeName, customPaymentPlatform]);

  const paymentUrl = isSystemAdmin && accountKey && paymentKey && isPaymentPlatformsLoaded ?
                    `https://dashboard.stripe.com/${accountKey}/payments/${paymentKey}` :
                    !isSystemAdmin && paymentKey ?
                    `https://dashboard.stripe.com/payments/${paymentKey}` :
                    null;

  const installmentUrl = isSystemAdmin && accountKey && saleInstallmentKey && isPaymentPlatformsLoaded ?
                        `https://dashboard.stripe.com/${accountKey}/subscriptions/${saleInstallmentKey}` :
                        !isSystemAdmin && saleInstallmentKey ?
                        `https://dashboard.stripe.com/subscriptions/${saleInstallmentKey}` :
                        null;

  const paymentPlatformUrl = paymentUrl || installmentUrl;

  if (isValidKey && !isPaymentPlatformsLoaded) {
    getPaymentPlatforms();
  }

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span style={{ textTransform: 'capitalize' }}>{platform}</span>
          {paymentPlatformUrl && (
            <React.Fragment>
              {!accountKey && isPaymentPlatformsLoaded && (
                <Loader active inline size="tiny" />
              )}
              {paymentPlatformUrl && (
                <a
                  href={paymentPlatformUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  View in Stripe
                </a>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
