//------------React/Redux
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import React from 'react';
import withDnDContext from '../../formbuilder/HTML5BackendSingleton';

//-------------Helpers
import * as templateHelper from '../../template/templateHelper';
import update from '../../../helpers/update';

//-------------Components
import AboutElement from './components/aboutElement';
import AboutElementContainer from './components/aboutElementContainer';
import CreateAboutElementModal from './components/createAboutElementModal';
import { TemplateHeader } from '../../template/templateHeader';
import { Form } from 'semantic-ui-react';

//-------------Constants
import { i18Keys } from './constants/translation';

//-------------Other
import { v4 as uuidv4 } from 'uuid';
import './index.css';

class AboutElementsBuilder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false
    };
  }

  onItemChange = (index, value) => {
    const newAboutElements = this.getNewAboutElements(index, value);

    this.props.onChange(newAboutElements);
  };

  getNewAboutElements(index, value) {
    let valueCopy = this.getDeepCopy(this.props.value);

    valueCopy = update.set(valueCopy, `elements.${index}.value`, value);

    return valueCopy;
  }

  getDeepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
  };

  getAboutElements = () => {
    const aboutElements = this.props.value;

    if (!aboutElements) {
      return null;
    }

    return aboutElements.elements.map((element, index) => (
      <AboutElementContainer
        index={index}
        onDeleteClick={this.onDeleteAboutElementClick}
        moveAboutElement={this.moveAboutElement}
        type={element.type}
      >
        <AboutElement
          {...element}
          index={index}
          sessionKey={this.props.sessionKey}
          entity={this.props.entity}
          onChange={this.onItemChange}
        />
      </AboutElementContainer>
    ));
  };

  onAboutElementAdd = (type) => {
    const valueCopy = this.props.value
      ? this.getDeepCopy(this.props.value)
      : { elements: [] };
    valueCopy.elements.push({
      guid: uuidv4(),
      value: '',
      type: type
    });
    this.props.onChange(valueCopy);
  };

  onDeleteAboutElementClick = (index) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    valueCopy.elements.splice(index, 1);
    this.props.onChange(valueCopy);
  };

  moveAboutElement = (dragIndex, hoverIndex) => {
    let valueCopy = this.props.value;
    const dragAboutElement = valueCopy.elements[dragIndex];

    valueCopy = update(valueCopy, {
      elements: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragAboutElement]
        ]
      }
    });

    this.props.onChange(valueCopy);
  };

  onModalOpen = () => {
    this.setState({
      isModalOpen: true
    });
  };

  onModalClose = () => {
    this.setState({
      isModalOpen: false
    });
  };

  render() {
    const aboutElements = this.getAboutElements();
    const i18Key = 'template.page.content.about-elements';

    return (
      <div className="about-elements-container">
        <Form.Field>
          <label>{this.props.label}</label>
          {this.props.subtext && (
            <div className="input-sub">{this.props.subtext}</div>
          )}
        </Form.Field>
        {aboutElements}
        <CreateAboutElementModal
          open={this.state.isModalOpen}
          onClose={this.onModalClose}
          onAboutElementAdd={this.onAboutElementAdd}
        />
        <div className="add-about-element-container" onClick={this.onModalOpen}>
          <span className="add-ticket-text">
            {I18n.t(`${i18Key}.${i18Keys.ADD_ABOUT_ELEMENT}`)}
          </span>
        </div>
        <TemplateHeader
          as="h5"
          label="template.page.content.setup.campaign-story.description"
        />
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value
  };
};

const withoutContext = connect(mapState, null);
export default withoutContext(withDnDContext(AboutElementsBuilder));
export { withoutContext };
