import React from 'react';

import { getFromBuilderInitialData } from '../../../../helpers/formBuilderHelper';

import TemplateFormBuilder from '../../../../components/template/templateFromBuilder';
import { TemplatePage } from '../../../../components/common/pager';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateText } from '../../../../components/template/templateText';

import { formBuilderKeys } from '../../../campaign/constants';
import { paths } from '../constants/template';

export const getApplicationQuestionsTab = (props) => {
  const {
    onChange,
    formBuilderQuestionsValidation,
    isAddressHidden,
    isPhoneHidden,
    isCountryAllowed
  } = props;

  return (
    <TemplatePage
      id="applicationQuestions"
      path="edit-program-applicationQuestions"
      i18nKey="programs.template.page.applicationQuestions.title"
    >
      <TemplateHeader
        as="h2"
        label="eventPrograms.template.page.applicationQuestions.header"
      />

      <TemplateText
        size="small"
        label="eventPrograms.template.page.applicationQuestions.subheader"
      />

      <TemplateFormBuilder
        formBuilderKey={formBuilderKeys.ADDITIONAL}
        path={paths.APPLICATION_ADDITIONAL}
        initialData={getFromBuilderInitialData(formBuilderKeys.ADDITIONAL)}
        onChange={onChange}
        isAddressHidden={isAddressHidden}
        isPhoneHidden={isPhoneHidden}
        isCountryAllowed={isCountryAllowed}
        validation={formBuilderQuestionsValidation}
      />
    </TemplatePage>
  );
};
