import { HtttpRequestMethods, sendRequest } from "./request";
import { MerchandiseUrls } from "./constants"

export async function removeMerchandise(
  apikey: string,
  sessionKey: string,
  id: number
) {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${MerchandiseUrls.Remove}/${id}`,
    method: HtttpRequestMethods.Delete,
  });
}

export async function removeMerchandiseSku(
  apikey: string,
  sessionKey: string,
  merchandiseId: number,
  id: number
) {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${MerchandiseUrls.Remove}${merchandiseId}/sku/${id}`,
    method: HtttpRequestMethods.Delete,
  });
}
