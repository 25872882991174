import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import {
  getTeamClickableColumnUrl,
  getFundraiserClickableColumnUrl,
  getCollectionClickableColumnUrl,
  getCampaignClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';
import { listFilterOperator } from '../../../constants/aggregate';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { gridPageSize } from '../constants';

export const getTopCampaignsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('name', `${translationKey}.name`)
        .visibleByDefault()
        .makeClickable(getCampaignClickableColumnUrl, true, false),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('targetAmount', `${translationKey}.goalAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('donationCount', `${translationKey}.donationCount`)
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel('fundraisersCount', `${translationKey}.fundraisersCount`)
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel('teamsCount', `${translationKey}.teamsCount`)
        .setType(columnTypes.INT32)
        .visibleByDefault()
    ])
    .orderBy('raisedAmount', 'desc')
    .setPageSize(gridPageSize)
    .getState();
};

export const getLastDonationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('collection.name', `${translationKey}.collection-name`)
        .visibleByDefault()
        .makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .setPageSize(gridPageSize)
    .getState();
};

export const getTopDonationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('collection.name', `${translationKey}.collection-name`)
        .visibleByDefault()
        .makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false)
    ])
    .orderBy('amount', 'desc')
    .setPageSize(gridPageSize)
    .getState();
};

export const getLastFundraisersGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault()
    ])
    .orderBy('createdAtLocal', 'desc')
    .addFilter('type', listFilterOperator.IN_LIST, [
      pageTypesStrings.FUNDRAISER,
      pageTypesStrings.TEAM,
      pageTypesStrings.COLLECTION
    ])
    .setPageSize(gridPageSize)
    .getState();
};

export const getTopFundraisersGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault()
    ])
    .orderBy('raisedAmount', 'desc')
    .addFilter('type', listFilterOperator.IN_LIST, [
      pageTypesStrings.FUNDRAISER,
      pageTypesStrings.TEAM,
      pageTypesStrings.COLLECTION
    ])
    .setPageSize(gridPageSize)
    .getState();
};

export const getLastSalesGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('collection.name', `${translationKey}.collection-name`)
        .visibleByDefault()
        .makeClickable(getCollectionClickableColumnUrl, true, false)
    ])
    .orderBy('soldAtLocal', 'desc')
    .setPageSize(gridPageSize)
    .getState();
};
