import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as templateHelper from './templateHelper';
import { nodeTypes, additionNodeTypes } from '../formbuilder/typeDefinitions';
import FormbuilderText from '../formBuilderAnswersComponents/formbuilderText';
import FormbuilderCurrency from '../formBuilderAnswersComponents/formbuilderCurrency';
import FormbuilderTextArea from '../formBuilderAnswersComponents/formbuilderTextArea';
import FormbuilderDropdown from '../formBuilderAnswersComponents/formbuilderDropdown';
import FormbuilderCheckbox from '../formBuilderAnswersComponents/formbuilderCheckbox';
import FormbuilderPhoneNumber from '../formBuilderAnswersComponents/formbuilderPhoneNumber';
import FormbuilderAddress from '../formBuilderAnswersComponents/formbuilderAddress';
import FormbuilderDatePicker from '../formBuilderAnswersComponents/formbuilderDatePicker';
import FormbuilderCountry from '../formBuilderAnswersComponents/formbuilderCountry';
import { TemplateHeader } from './templateHeader';
import {
  hasFormbuilderQuestions,
  getFormbuilderQuestions
} from '../../helpers/formBuilderHelper';
import get from 'lodash.get';
import config from '../../constants';

class FormBuilderAnswers extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkboxAnswers: this.getInitialCheckboxAnswers()
    };
  }

  componentWillReceiveProps(newProps) {
    const newFormBuilderIdentifier = get(newProps, 'value.identifier');
    const oldFormBuilderIdentifier = get(this.props, 'value.identifier');

    if (newFormBuilderIdentifier !== oldFormBuilderIdentifier) {
      this.setState({
        checkboxAnswers: this.getInitialCheckboxAnswers(newProps)
      });
    }
  }

  getInitialCheckboxAnswers = (newProps) => {
    const props = newProps ? newProps : this.props;
    if (props.value && hasFormbuilderQuestions(props.value)) {
      const checkboxQuestions = props.value.children[0].children.filter(
        (x) => x.type === nodeTypes.checkbox
      );
      const answers = [];

      checkboxQuestions.forEach((question) => {
        if (question.additionalCheckboxInfo) {
          answers[question.identifier] = question.additionalCheckboxInfo;
          return;
        }

        const options = this.getOptionsFromString(question.props.options);
        const additionalCheckboxInfo = [];

        if (options) {
          options.forEach((option, index) => {
            additionalCheckboxInfo[index] = false;
          });
        }

        answers[question.identifier] = additionalCheckboxInfo;
        const questionsModel = this.getNewQuestionsModel(
          this.getAnswerFromCheckboxInfo(additionalCheckboxInfo, options),
          question.identifier,
          additionalCheckboxInfo,
          props
        );
        props.onChange(props.path, questionsModel);
      });

      return answers;
    }

    return null;
  };

  getAnswerFromCheckboxInfo = (checkboxInfo, options) => {
    if (options && options.length) {
      const answerOptions = options.map((item) => {
        return checkboxInfo[item.index] === true ? item.text : '';
      });
      const checkedAnswerOptions = answerOptions.filter((x) => x !== '');

      return checkedAnswerOptions && checkedAnswerOptions.length
        ? checkedAnswerOptions.join(', ')
        : undefined;
    }
  };

  hasComponentPropsOptions(componentProps) {
    return (
      componentProps && componentProps.options && componentProps.options.length
    );
  }

  onChange = (value, identifier, additionalCheckboxInfo) => {
    this.props.onChange(
      this.props.path,
      this.getNewQuestionsModel(
        value || null,
        identifier,
        additionalCheckboxInfo
      )
    );
  };

  getNewQuestionsModel = (
    newAnswer,
    identifier,
    additionalCheckboxInfo,
    newProps
  ) => {
    const props = newProps ? newProps : this.props;
    const newModel = JSON.parse(JSON.stringify(props.value));

    if (hasFormbuilderQuestions(newModel)) {
      const currentQuestion = newModel.children[0].children.find(
        (x) => x.identifier === identifier
      );
      currentQuestion.answer = newAnswer;

      if (additionalCheckboxInfo) {
        currentQuestion.additionalCheckboxInfo = additionalCheckboxInfo;
      }
    }

    return newModel;
  };

  getAnswerComponents = () => {
    if (!hasFormbuilderQuestions(this.props.value)) {
      return;
    }

    const components = this.props.value.children[0].children
      .filter((item) => !item.props.hidden || this.props.isSystemAdmin)
      .map((item) => {
        return this.getAnswerComponent(item);
      });

    return components.filter((x) => x !== undefined);
  };

  getAnswerComponent = (question) => {
    const { type, identifier } = question;
    const componentProps = this.getComponentProps(question);
    const { label, placeholder, inputSub, value, options, validation } =
      componentProps;

    switch (type) {
      case nodeTypes.textInput:
      case nodeTypes.additionalEmailAddress:
      case additionNodeTypes.additionalFirstName:
      case additionNodeTypes.additionalLastName:
        return (
          <FormbuilderText
            onChange={(event, { value }) => this.onChange(value, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.targetAmount:
        return (
          <FormbuilderCurrency
            onChange={this.onNumberChange.bind(this, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            disabled={this.props.disableTargetField}
            validation={validation}
            maxValue={config.MAX_DONATION_VALUE}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.country:
        return (
          <FormbuilderCountry
            onChange={(event, { value }) => this.onChange(value, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            options={options}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.textArea:
        return (
          <FormbuilderTextArea
            onChange={(event, { value }) => this.onChange(value, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.dropdown:
        return (
          <FormbuilderDropdown
            onChange={(event, { value }) => this.onChange(value, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            options={options}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.phone:
      case nodeTypes.additionalPhoneNumber:
        return (
          <FormbuilderPhoneNumber
            onChange={(phone) => this.onChange(phone, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            organizationCountry={this.props.organizationCountry}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.address:
        return (
          <FormbuilderAddress
            onChange={(address) => this.onChange(address, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.date:
        return (
          <FormbuilderDatePicker
            onChange={(date) => this.onChange(date, identifier)}
            label={label}
            placeholder={placeholder}
            inputSub={inputSub}
            value={value}
            validation={validation}
            initialValidation={this.props.initialValidation}
          />
        );

      case nodeTypes.checkbox:
        return (
          <div id="formbuilder-checkbox">
            {' '}
            {this.getCheckboxesByOptions(componentProps, identifier)}{' '}
          </div>
        );

      case nodeTypes.groupDivider:
        return <TemplateHeader as="h3" label={label} />;

      case nodeTypes.consent:
        return <React.Fragment />;

      default:
        return null;
    }
  };

  getCheckboxesByOptions = (componentProps, identifier) => {
    if (!this.hasComponentPropsOptions(componentProps)) {
      return;
    }

    const { checkboxAnswers } = this.state;

    const checkboxes = componentProps.options.map((option, optionIndex) => {
      const label = optionIndex === 0 ? componentProps.label : '';
      const subtext = optionIndex === 0 ? componentProps.inputSub : '';
      const validation = optionIndex === 0 ? componentProps.validation : '';

      return (
        <FormbuilderCheckbox
          label={label}
          identifier={identifier}
          optionindex={optionIndex}
          inputLabel={option.text}
          inputSub={subtext}
          validation={validation}
          checked={checkboxAnswers[identifier][optionIndex]}
          onChange={(event, { checked }) =>
            this.onCheckboxChange(checked, identifier, optionIndex)
          }
        />
      );
    });

    return checkboxes;
  };

  onCheckboxChange = (checked, identifier, optionIndex) => {
    this.setState(
      (prev) => {
        prev.checkboxAnswers[identifier][optionIndex] = checked;
        return {
          checkboxAnswers: prev.checkboxAnswers
        };
      },
      () => {
        const checkboxInfo = this.state.checkboxAnswers[identifier];
        const options = this.getOptionsByIdentifier(identifier);
        const checkboxAnswer = this.getAnswerFromCheckboxInfo(
          checkboxInfo,
          options
        );

        this.props.onChange(
          this.props.path,
          this.getNewQuestionsModel(checkboxAnswer, identifier, checkboxInfo)
        );
      }
    );
  };

  onNumberChange = (identifier, evt) => {
    if (this.props.onChange) {
      const val = parseFloat(evt.target.value);
      this.onChange(val, identifier);
    }
  };

  getOptionsByIdentifier = (identifier) => {
    if (hasFormbuilderQuestions(this.props.value)) {
      const componentProps = this.getComponentProps(
        getFormbuilderQuestions(this.props.value).find(
          (x) => x.identifier === identifier
        )
      );

      if (this.hasComponentPropsOptions(componentProps)) {
        return componentProps.options;
      }
    }
  };

  getComponentProps(question) {
    if (!question) {
      return;
    }

    const { type, props, answer } = question;

    return {
      label: props.title,
      placeholder: props.placeholderText,
      inputSub: props.description,
      value: answer,
      options: this.getOptionsFromString(props.options),
      validation: this.getValidationModel(props, type)
    };
  }

  getValidationModel = (componentProps, componentType) => {
    const needValidation =
      get(this.props, 'validation.formBuilderRequired') === true;

    return {
      required: needValidation ? componentProps.required : false,
      formbuilderAddressRequired: needValidation
        ? componentType === nodeTypes.address && componentProps.required
        : undefined,
      formbuilderAddress: componentType === nodeTypes.address,
      phone:
        componentType === nodeTypes.phone ||
        componentType === nodeTypes.additionalPhoneNumber,
      date: componentType === nodeTypes.date,
      email: componentType === nodeTypes.additionalEmailAddress
    };
  };

  getOptionsFromString(optionsString) {
    if (!optionsString) {
      return;
    }

    const options = optionsString.split('\n').map((item, index) => ({
      value: item,
      text: item,
      index: index
    }));

    return options;
  }

  render() {
    if (!this.props.value) {
      return <div></div>;
    }

    const components = this.getAnswerComponents();

    if (!components || !components.length) {
      return <div></div>;
    }

    return components.map((component) => {
      return component;
    });
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    initialValidation: get(state, 'templateBuilderEx.isEdit'),
    isSystemAdmin: get(state, 'session.isSystemAdmin')
  };
};

export const TemplateFormBuilderAnswers = connect(mapState)(FormBuilderAnswers);
