import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import { Session, Permissions, PermissionValues } from './session';

export type FeatureKey = keyof Permissions;

export interface UseFeatureFlagProps {
  feature: FeatureKey;
}

export function useFeatureFlag({ feature }: UseFeatureFlagProps) {
  const { permissions = {} } = useSelector<RootState, Session>(
    (state) => state.session || {}
  );
  return {
    isEnabled: permissions?.[feature] === PermissionValues.Allow || false
  };
}

export interface FeatureFlagProps extends UseFeatureFlagProps {
  children: React.ReactNode;
}

export const FeatureFlag: React.FunctionComponent<FeatureFlagProps> = ({
  feature,
  children
}) => {
  const featureFlag = useFeatureFlag({ feature });

  return featureFlag.isEnabled ? <>{children}</> : null;
};
