import update from 'immutability-helper';
import get from 'lodash.get';
import * as utility from './bin/utility';

import stringMap from '../internationalisation/data.json';

import { store } from '../store';
import { setLocale } from 'react-redux-i18n';
import * as entityHandlers from './bin/entityHandlers';
import 'core-js/es6/string';
// -------------------------------------------------------
// CONSTANTS

import {
  AUTHENTICATE_SUCCESS,
  AUTHENTICATE_FAILURE,
  RESUME_SESSION_SUCCESS,
  RESUME_SESSION_FAILURE
} from './session';

const FAILURE_SUFFIX = '_FAILURE';
export const TOGGLE_MODAL = 'general/TOGGLE_MODAL';
export const LOCALE_UPDATE = 'general/LOCALE_UPDATE';
export const ERROR_REMOVE = 'general/ERROR_REMOVE';

export const LIST_READY_STATE_CLEAR = 'general/LIST_READY_STATE_CLEAR';
export const CLEAR = 'general/CLEAR';

export const GET_ORGANIZATION_REQUESTED = 'general/GET_ORGANIZATION_REQUESTED';
export const GET_ORGANIZATION_SUCCESS = 'general/GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_FAILURE = 'general/GET_ORGANIZATION_FAILURE';

// -------------------------------------------------------
// ACTIONS

export const toggleModal = (type, show) => ({
  type: TOGGLE_MODAL,
  payload: {
    type,
    show
  }
});

export const changeLocale = (locale) => (dispatch) => {
  dispatch(setLocale(locale));
  dispatch({
    type: LOCALE_UPDATE,
    payload: {
      locale
    }
  });
};

const defaultLocale = 'en-AU';
export const determineLocale = () => {
  return defaultLocale;
};

// -------------------------------------------------------
// REDUCER

const initialState = {
  modals: {
    language: false
  },
  languages: {
    current: determineLocale(),
    data: [
      { key: 'en-AU', icon: 'gb' },
      { key: 'es', icon: 'es' }
    ]
  },
  errorsTimed: [],
  errorsStateful: [],
  generalOrgRecord: utility.Record.getDefaultState()
};

export const clearListsReadyState = () => {
  return {
    type: LIST_READY_STATE_CLEAR
  };
};

export const clear = () => {
  return {
    type: CLEAR
  };
};

export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case TOGGLE_MODAL:
      newState = update(state, {
        modals: {
          [action.payload.type]: { $set: action.payload.show === true }
        }
      });
      break;

    case LOCALE_UPDATE:
      newState = update(state, {
        languages: {
          current: { $set: action.payload.locale }
        }
      });
      break;

    // EXCEPTIONS - Clear Errors.
    // ------------------

    case RESUME_SESSION_SUCCESS:
    case AUTHENTICATE_SUCCESS:
      newState = update(state, {
        unauthorized: { $set: false },
        verification: { $set: false },
        errorsTimed: { $set: [] },
        errorsStateful: { $set: [] }
      });
      break;

    // EXCEPTION HANDLERS
    // ------------------

    case ERROR_REMOVE: {
      newState = update(state, {
        errorsTimed: {
          $popById: action.payload
        }
      });
      break;
    }

    case GET_ORGANIZATION_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_ORGANIZATION_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case AUTHENTICATE_FAILURE:
    case RESUME_SESSION_FAILURE: {
      if (action.type.endsWith(FAILURE_SUFFIX)) {
        return utility.processHttpCodes(
          action,
          [
            {
              start: 400,
              handler: () => {
                return {
                  ...state,
                  unauthorized: true,
                  verification: false
                };
              }
            },
            {
              start: 401,
              handler: () => {
                return {
                  ...state,
                  unauthorized: true
                };
              }
            },
            {
              start: 403,
              handler: () => {
                return {
                  ...state,
                  verification: true
                };
              }
            }
          ],
          () => state
        );
      }
      break;
    }

    default: {
      if (action.type.endsWith(FAILURE_SUFFIX)) {
        return utility.processHttpCodes(
          action,
          [
            // { start: 500, end: 599, handler: () => queueError(state, action, true) },
            {
              start: 401,
              handler: () => {
                return {
                  ...state,
                  unauthorized: true
                };
              }
            },
            {
              start: 403,
              handler: () => {
                return {
                  ...state,
                  verification: true
                };
              }
            }
          ],
          () => state
        );
      }

      return state;
    }
  }

  return newState;
};

const addErrorToQueue = (state, key, id, clear) => {
  const msg = clear
    ? {
        errorsTimed: { $set: [{ key, id }] },
        errorsStateful: { $set: [{ key, id }] }
      }
    : {
        errorsTimed: { $push: [{ key, id }] },
        errorsStateful: { $set: [{ key, id }] }
      };

  return update(state, msg);
};

const queueErrorRemoval = (id) =>
  setTimeout(
    () =>
      store.dispatch({
        type: ERROR_REMOVE,
        payload: id
      }),
    5000
  );

const getErrorKey = (action) => {
  const statusCode = get(action, 'payload.status');
  if (statusCode === 500) {
    return 'errors.general.unknown';
  }

  const errorList = get(action, 'payload.response.error');
  if (errorList && errorList.length > 0) {
    const key = `errors.api.${errorList[0].error}`;
    if (get(stringMap, `${defaultLocale}.${key}`)) {
      return key;
    }
  }

  if (action.type) {
    const key = `errors.action.${action.type.replace('/', '.')}`;
    if (get(stringMap, `${defaultLocale}.${key}`)) {
      return key;
    }
  }

  return 'errors.general.unknown';
};

export const queueError = (state, action, clear) => {
  const id = `error_${new Date().getTime()}`;
  const key = getErrorKey(action);

  const newState = addErrorToQueue(state, key, id, clear);
  queueErrorRemoval(id);
  return newState;
};
