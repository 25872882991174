import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import SectionComponent from '../../Section';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0.7em;
`;
const Label = styled.div`
  margin-left: 0.5em;
  font-weight: bold;
`;
const CheckboxDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;
const ConditionalDiv = styled.div``;

const CheckBoxWithConditionalField = ({
  label,
  value,
  update,
  conditional
}) => (
  <Wrapper>
    <CheckboxDiv>
      <Checkbox
        checked={value}
        onChange={(event, data) => update(data.checked)}
      />
      <Label>{label}</Label>
    </CheckboxDiv>
    <ConditionalDiv>
      {value && <SectionComponent node={conditional} />}
    </ConditionalDiv>
  </Wrapper>
);

CheckBoxWithConditionalField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  update: PropTypes.func,
  conditional: PropTypes.object
};

export default CheckBoxWithConditionalField;
