import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as dashboardActions from '../reducer';
import { isArrayWithItems } from '../../../helpers/arrayHelper';

import clear from '../../../components/clear';
import Spinner from '../../../components/common/spinner';
import BasePage from '../../../components/common/basePageView';
import { HeaderContainer } from './components/header';
import BuilderContainer from './components/builder';
import ChartContainer from './components/chart';
import { SummaryContainer } from './components/summary';

import { dashboardViewTypeConfigurations } from '../constants';

class DashboardCompare extends Component {
  componentDidMount() {
    const { view } = this.props.match.params;
    const configurations = dashboardViewTypeConfigurations[view];
    this.props.init(configurations);
  }

  handleRefreshClick = () => {
    if (isArrayWithItems(this.props.models)) {
      this.props.models.forEach((model, index) => {
        const path = `compare.models.${index}`;
        this.props.getSummaryRecord(
          model.id,
          this.props.configurations.entity,
          this.props.timeBracket,
          model.dateFrom,
          model.dateTo,
          `${path}.summaryRecord`
        );
        this.props.getSummaryDataRecord(
          model.id,
          model.dateFrom,
          model.dateTo,
          this.props.configurations.baseFilter,
          this.props.timeBracket,
          this.props.reportByDateAndMetricType,
          `${path}.summaryDataRecord`
        );
      });
    }
  };

  handleSummaryClick = (value) => {
    this.props.onValueChange('summaryType', value);
  };

  handleReportByDateAndMetricTypeChange = (event, { value }) => {
    this.props.onValueChange('reportByDateAndMetricType', value);

    if (isArrayWithItems(this.props.models)) {
      this.props.models.forEach((model, index) => {
        const path = `compare.models.${index}.summaryDataRecord`;
        this.props.getSummaryDataRecord(
          model.id,
          model.dateFrom,
          model.dateTo,
          this.props.configurations.baseFilter,
          this.props.timeBracket,
          value,
          path
        );
      });
    }
  };

  handleTimeBracketChange = (event, { value }) => {
    this.props.onValueChange('timeBracket', value);

    if (isArrayWithItems(this.props.models)) {
      this.props.models.forEach((model, index) => {
        const path = `compare.models.${index}.summaryDataRecord`;
        this.props.getSummaryDataRecord(
          model.id,
          model.dateFrom,
          model.dateTo,
          this.props.configurations.baseFilter,
          value,
          this.props.reportByDateAndMetricType,
          path
        );
      });
    }
  };

  render() {
    if (!this.props.inited) {
      return <Spinner />;
    }

    return (
      <BasePage>
        <div className="dashboard-compare-container">
          <div className="dashboard-top-area-container">
            <HeaderContainer
              reportByDateAndMetricType={this.props.reportByDateAndMetricType}
              timeBracket={this.props.timeBracket}
              timeBracketOptions={this.props.configurations.getTimeBracketOptions(
                this.props.isSystemAdmin
              )}
              onReportByDateAndMetricTypeChange={
                this.handleReportByDateAndMetricTypeChange
              }
              onTimeBracketChange={this.handleTimeBracketChange}
              onRefreshClick={this.handleRefreshClick}
            />
          </div>
          <div className="dashboard-builder-area-container">
            <BuilderContainer />
          </div>
          {isArrayWithItems(this.props.models) && (
            <div className="dashboard-summary-area-container">
              <SummaryContainer
                models={this.props.models}
                summaryType={this.props.summaryType}
                onSummaryClick={this.handleSummaryClick}
              />
            </div>
          )}
          <div className="dashboard-chart-area-container">
            <ChartContainer />
          </div>
        </div>
      </BasePage>
    );
  }
}

const mapState = ({ dashboard, session }) => {
  return {
    inited: dashboard.inited,
    configurations: dashboard.configurations,
    reportByDateAndMetricType: dashboard.reportByDateAndMetricType,
    timeBracket: dashboard.timeBracket,
    summaryType: dashboard.summaryType,
    models: dashboard.compare.models,
    isSystemAdmin: session.isSystemAdmin
  };
};

const mapDispatch = (dispatch) => {
  return {
    init(configurations) {
      dispatch(dashboardActions.init(configurations));
    },
    getSummaryRecord(id, type, timeBracket, dateFrom, dateTo, path) {
      dispatch(
        dashboardActions.getSummaryRecord(
          id,
          type,
          timeBracket,
          dateFrom,
          dateTo,
          path
        )
      );
    },
    getSummaryDataRecord: (
      id,
      dateFrom,
      dateTo,
      baseFilter,
      timeBracket,
      reportByDateAndMetricType,
      path
    ) => {
      dispatch(
        dashboardActions.getReportSummaryDataRecord(
          id,
          dateFrom,
          dateTo,
          baseFilter,
          timeBracket,
          reportByDateAndMetricType,
          path
        )
      );
    },
    onValueChange: (path, value) => {
      dispatch(dashboardActions.onValueChange(path, value));
    }
  };
};

const DashboardCompareContainer = clear(
  withRouter(connect(mapState, mapDispatch)(DashboardCompare))
);
export default DashboardCompareContainer;
