import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Common } from '../';
import * as templateHelper from './templateHelper';

class ManagedText extends Component {
  render() {
    return (
      <Common.Text
        className={this.props.className}
        size={this.props.size || 'small'}
      >
        {this.props.text}
      </Common.Text>
    );
  }
}

const mapState = (_state, ownProps) => {
  let text = ownProps.text || templateHelper.getTranslation(ownProps.label);
  if (!text) {
    text = ownProps.label;
  }

  return { text };
};

export const TemplateText = connect(mapState)(ManagedText);
