import { RSAA } from 'redux-api-middleware';
import constants from '../constants';
import { Record } from './bin/utility';
import update from '../helpers/update';
import { CLEAR } from './general';

export const UPLOAD_REQUESTED = 'UPLOAD_REQUESTED';
export const UPLOAD_SUCCESS = 'UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'UPLOAD_FAILURE';

export const UPLOAD_REQUESTED_V2 = 'UPLOAD_REQUESTED_V2';
export const UPLOAD_SUCCESS_V2 = 'UPLOAD_SUCCESS_V2';
export const UPLOAD_FAILURE_V2 = 'UPLOAD_FAILURE_V2';

export const UPLOAD_RESET_STATE = 'UPLOAD_RESET_STATE';
export const UPLOAD_REMOVE = 'UPLOAD_REMOVE';

const initialState = {
  record: Record.getDefaultState()
};

const executeUploadHandler = (
  entity,
  type,
  id,
  path,
  file,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure
) => {
  return (dispatch, getState) => {
    const state = getState();

    const form = new FormData();
    form.append('file', file);
    if (id) form.append('id', id);

    const headers = {
      ApiKey: constants.apikey,
      Authorization: `bearer ${state.session.key}`
    };

    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/${entity}/${type}/upload`,
        method: 'POST',
        headers,
        body: form,
        types: [
          {
            type: actionTypeRequest,
            payload: () => ({ path })
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const payload = await res.json();
              return Object.assign(
                {},
                {
                  success: payload.success,
                  data: payload.data
                },
                {
                  entity,
                  type,
                  id,
                  path
                }
              );
            }
          },
          {
            type: actionTypeFailure,
            payload: { path }
          }
        ]
      }
    });
  };
};

export const uploadFile = (entity, type, id, path, file) => {
  return executeUploadHandler(
    entity,
    type,
    id,
    path,
    file,
    UPLOAD_REQUESTED,
    UPLOAD_SUCCESS,
    UPLOAD_FAILURE
  );
};

export const uploadFileV2 = (entity, type, id, path, file) => {
  return executeUploadHandler(
    entity,
    type,
    id,
    path,
    file,
    UPLOAD_REQUESTED_V2,
    UPLOAD_SUCCESS_V2,
    UPLOAD_FAILURE_V2
  );
};

export const uploadRemove = (path) => {
  return {
    type: UPLOAD_REMOVE,
    payload: {
      path
    }
  };
};

const uploadReducer = (state = initialState, action) => {
  let newState;

  switch (action.type) {
    case CLEAR:
    case UPLOAD_RESET_STATE: {
      newState = update.set(newState, 'record', Record.getDefaultState());
      break;
    }

    case UPLOAD_REQUESTED:
    case UPLOAD_REQUESTED_V2:
      newState = uploadRequestedHandler(newState, action);
      break;

    case UPLOAD_SUCCESS: {
      const processingPath = getProcessingPath(action.payload.path);
      newState = update.set(newState, processingPath, false);
      break;
    }

    case UPLOAD_SUCCESS_V2: {
      const processingPath = getProcessingPath(action.payload.path);
      const valuePath = getValuePath(action.payload.path);
      newState = update.set(newState, processingPath, false);
      newState = update.set(newState, valuePath, action.payload.data);
      break;
    }

    case UPLOAD_FAILURE_V2: {
      const processingPath = getProcessingPath(action.payload.path);
      newState = update.set(newState, processingPath, false);
      break;
    }

    case UPLOAD_REMOVE: {
      const valuePath = getValuePath(action.payload.path);
      newState = update.set(newState, valuePath, null);
      break;
    }
    default:
      newState = state;
      break;
  }

  return newState;
};

export const uploadResetState = () => {
  return {
    type: UPLOAD_RESET_STATE
  };
};

export const uploadSuccessHandler = (state, action) => {
  const processingPath = getProcessingPath(action.payload.path);
  const urlPath = getUrlPath(action.payload.path);

  state = update.set(state, processingPath, false);
  state = update.set(state, urlPath, action.payload.data);

  return state;
};

export const uploadRequestedHandler = (state, action) => {
  const path = getProcessingPath(action.payload.path);
  return update.set(state, path, true);
};

export const getProcessingPath = (elementPath) => {
  return `record.state.${elementPath}.processing`;
};

export const getValuePath = (elementPath) => {
  return `record.state.${elementPath}.value`;
};

export const getUrlPath = (elementPath) => {
  return `record.data.${elementPath}`;
};

export default uploadReducer;
