import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import * as templateBuilderActions from '../../modules/template-builder-ex';
import * as generalActions from '../../modules/general';
import * as registerActions from './reducer';

import { Button, Message } from 'semantic-ui-react';
import RegisterTemplate from './templateEx';
import SaveButton from './saveButton';
import SavedEntityModal from '../../components/common/savedEntityModal';
import Text from '../../components/common/text';

import clear from '../../components/clear';
import { Record } from '../../modules/bin/utility';
import { TemplateOptions } from '../../components/template/templateOptions';

import { grecaptcha } from '../../helpers/grecaptcha';

import grassrootzLogo from '../../images/logo.svg';
import './index.css';

class RegisterView extends Component {
  initTemplateBuilderModel = () => {
    const model = new TemplateOptions().setModel({}).get();

    this.props.templateBuilderActions.setTemplateModel(model);
  };

  onValueChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  handleSave = (data) => {
    const tokenCallback = (token) =>
      this.props.registerActions.postOnboarding(data, token);
    grecaptcha(tokenCallback, 'onboarding');
  };

  clearMessages = () => {
    this.props.registerActions.clearRecordMessages('saveRecord');
  };

  getSavedModalContent = () => {
    return (
      <React.Fragment>
        <Text>{I18n.t('template.register.saved-entity-modal.body')}</Text>
      </React.Fragment>
    );
  };

  getSavedModalButtons = () => {
    return (
      <React.Fragment>
        <Button
          className="primary"
          onClick={() => {
            window.open('https://grassrootz.com', '_self');
          }}
        >
          {I18n.t('template.register.saved-entity-modal.buttons.back')}
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.saveRecord);
    const showErrorMessage = get(this.props.saveRecord, 'error.show');
    const showSuccessMessage = get(this.props.saveRecord, 'success.show');
    const successModalRedirectButtons = this.getSavedModalButtons();
    const successModalContent = this.getSavedModalContent();

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    return (
      <React.Fragment>
        <SavedEntityModal
          open={showSuccessMessage}
          header={I18n.t('template.register.saved-entity-modal.header')}
          content={successModalContent}
          buttons={successModalRedirectButtons}
          isEdit={false}
        />

        <div className="register-container">
          <div className="grassrootz-logo">
            <a href="https://grassrootz.com">
              <img src={grassrootzLogo} alt="grassrootz-logo" />
            </a>
          </div>
          <div className="register-inner-container">
            <RegisterTemplate />
            <div className="save-button-container">
              <SaveButton isLoading={isSaving} handleSave={this.handleSave} />
              <Message negative hidden={!showErrorMessage}>
                <p>{I18n.t('template.register.error')}</p>
              </Message>
            </div>
          </div>
          <div className="copyright-container">
            {I18n.t('template.register.copyright', {
              year: new Date().getFullYear()
            })}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ register, templateBuilderEx }) => {
  return {
    saveRecord: register.saveRecord,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    registerActions: bindActionCreators(registerActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const RegisterContainer = clear(connect(mapState, mapDispatch)(RegisterView));
export default RegisterContainer;
