// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as campaignActions from './store/campaignActions';

// Helpers
import get from 'lodash.get';
import { getListWithOrgFilterIfSysAdmin } from '../../helpers/listHelper';
import { hideCondition } from '../../helpers/hideHelper';
import clear from '../../components/clear';
import { Record } from '../../modules/bin/utility';
import { clearRecordMessages } from '../../modules/authentication';

// Components
import { Common } from '../../components';
import PreCreateCampaignContainer from './create/precreate';
import RecordResultMessage from '../../components/common/recordResult';
import Spinner from '../../components/common/spinner';
import BasePage from '../../components/common/basePageView';
import Button from '../../components/common/button';
import { Menu } from 'semantic-ui-react';
import { BackOfficeModal } from '../../components/backoffice/BackOfficeModal';
import { ExportDownloadProvider } from '../export-history';

// Constants
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { i18nKeys } from './constants';
import { gridKeys } from '../../constants/gridKeys';
import { campaignTypes } from './create/templates';

// ----------------------------------------------------------------------

/** A page to view all campaigns in a list */
class CampaignList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      backOfficeModal: {
        open: false
      }
    };
  }

  clearMessages = () => {
    this.props.actionGrid.clearRecordMessages('recordEdit');
    this.props.actionGrid.clearRecordMessages('recordCreate');
  };

  clearActivateRecordMessage = () => {
    this.props.clearActivateRecord('invite.createAccountRecord');
  };

  isContextMenuFundraisersHide = (item) => {
    return !(item.teams.enabled || item.fundraisers.enabled);
  };

  isContextMenuDonationsHide = () => {
    return this.props.isEventAdmin;
  };

  isContextMenuSalesAndTicketsHide = (item) => {
    return this.props.isEventAdmin || !get(item, 'ticketing.enabled');
  };

  isContextMerchandiseHide = (item) => {
    return this.props.isEventAdmin || !get(item, 'merchandise.enabled');
  };

  isContextCloneCampaignHide = (item) => {
    return (
      this.props.isCampaignAdmin ||
      this.props.isEventAdmin ||
      item.type === campaignTypes.event
    );
  };

  showSpinner = () => {
    return (
      this.props.isSessionLoading ||
      Record.isRecordLoading(this.props.createAccountRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord) ||
      Record.isRecordLoading(this.props.exportFileRecord)
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.list) ||
      Record.isRecordLoading(this.props.list.metadata)
    );
  };

  onBackofficeClick = (open, campaign) => {
    this.setState({
      backOfficeModal: {
        open,
        name: `${campaign?.name} (${campaign?.id})`,
        campaignId: campaign?.id
      }
    });
  };

  /**
   * Renders the component
   */
  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;
    const filterButtons = get(this.props, 'list.filterButtons');

    return (
      <>
        <BasePage>
          <RecordResultMessage
            record={this.props.recordEdit}
            onDismiss={this.clearMessages}
          />
          <RecordResultMessage
            record={this.props.recordCreate}
            onDismiss={this.clearMessages}
          />
          <RecordResultMessage
            record={this.props.createAccountRecord}
            onDismiss={this.clearActivateRecordMessage}
          />
          <div>
            <div className="grid-top-block">
              <h1>
                <Translate value="campaign.title" />
              </h1>
              <Panel>
                <PreCreateCampaignContainer />
              </Panel>
            </div>
            {!this.props.isEventAdmin && (
              <Menu
                className="accounts-tab-buttons-group"
                style={{ margin: '0px', borderRight: '0px' }}
              >
                {Object.keys(filterButtons).map((key) => {
                  const item = filterButtons[key];
                  return (
                    <Menu.Item
                      key={item.key}
                      name={item.key}
                      content={I18n.t(item.I18nKey)}
                      active={item.key === this.props.match.params.tab}
                      disabled={this.isChooseTabButtonDisabled()}
                      onClick={() => {
                        this.props.history.push(`/campaigns/${item.key}`);
                      }}
                    />
                  );
                })}
              </Menu>
            )}
            <Grid.Managed
              listKey={this.props.listKey}
              list={getListWithOrgFilterIfSysAdmin(
                this.props.list,
                this.props.isSystemAdmin
              )}
              actions={this.props.actionGrid}
              toggleColumns
              search
              hideColumn={this.hideColumnCondition}
              activeTabFilter={this.props.match.params.tab}
              filtersButton
            >
              {this.props.isExportDataAllowed && (
                <Panel name="right">
                  <ExportDownloadProvider entity="page/Campaign">
                    {({ open }) => (
                      <Button onClick={() => open(this.props.list.request)}>
                        <Translate value="campaign.export" />
                      </Button>
                    )}
                  </ExportDownloadProvider>
                </Panel>
              )}
              <ContextMenu>
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD.key}
                  label={I18n.t('campaign.list.context.dashboard-campaign')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DASHBOARD
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN.key}
                  label={I18n.t('campaign.list.context.edit-campaign')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.EDIT_CAMPAIGN
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE.key}
                  label={I18n.t('campaign.list.context.view-public-page')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_PUBLIC_PAGE
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_SALES.key}
                  label={I18n.t('campaign.list.context.view-sales')}
                  isHiden={this.isContextMenuSalesAndTicketsHide}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_SALES
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_TICKETS.key}
                  label={I18n.t('campaign.list.context.view-tickets')}
                  isHiden={this.isContextMenuSalesAndTicketsHide}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_TICKETS
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_MERCHANDISE.key}
                  label={I18n.t('campaign.list.context.view-merchandise')}
                  isHiden={this.isContextMerchandiseHide}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_MERCHANDISE
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DONATIONS.key}
                  label={I18n.t('campaign.list.context.view-donations')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_DONATIONS
                  }
                  isHiden={this.isContextMenuDonationsHide}
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_FUNDRAISERS.key}
                  label={I18n.t('campaign.list.context.view-fundraisers')}
                  isHiden={this.isContextMenuFundraisersHide}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.VIEW_FUNDRAISERS
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.CAMPAIGN_MENU.CLONE_CAMPAIGN.key}
                  label={I18n.t('campaign.list.context.clone-campaign')}
                  isHiden={this.isContextCloneCampaignHide}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.CAMPAIGN_MENU.CLONE_CAMPAIGN
                  }
                />
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.EVENTS.BACK_OFFICE.key}
                  label={I18n.t(i18nKeys.BACK_OFFICE)}
                  onClick={(_id, item) => this.onBackofficeClick(true, item)}
                  isHiden={() => !this.props.isSystemAdmin}
                />
              </ContextMenu>
            </Grid.Managed>
          </div>
        </BasePage>
        <BackOfficeModal
          open={this.state.backOfficeModal.open}
          setOpen={() => this.onBackofficeClick(false)}
          name={this.state.backOfficeModal.name}
          campaignId={this.state.backOfficeModal.campaignId}
        />
      </>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, authentication, account, session, ...rest }) => {
  const { templates, recordEdit, recordCreate } = campaign;

  const isOrganizationAdmin = get(session, 'isOrganizationAdmin');
  const isEventAdmin = get(session, 'isEventAdmin');
  const isCampaignAdmin = get(session, 'isCampaignAdmin');

  // A reference to the list in state
  const stateGrid = Common.Grid.Managed.mapGridState(
    campaign,
    gridKeys.CAMPAIGNS
  );

  return {
    templates,
    recordEdit,
    recordCreate,
    listKey: stateGrid.key,
    list: stateGrid.list,
    createAccountRecord: authentication.invite.createAccountRecord,
    impersonatedRecord: account.impersonatedRecord,
    exportFileRecord: campaign.exportFileRecord,
    isSystemAdmin: get(session, 'isSystemAdmin'),
    isOrganizationAdmin,
    isCampaignAdmin,
    isEventAdmin,
    isSessionLoading: get(session, 'processing'),
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  const actionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    campaignActions
  );
  const clearActivateRecord = bindActionCreators(clearRecordMessages, dispatch);
  return {
    actionGrid,
    clearActivateRecord
  };
};

/** Connects component to Redux store */
const CampaignPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(CampaignList))
);
export default CampaignPageContainer;
