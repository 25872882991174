import update from '../../helpers/update';
import get from 'lodash.get';

export class TemplateOptions {
  constructor() {
    this.state = {
      isEdit: false,
      data: {},
      state: {}
    };
  }

  get = () => {
    return this.state;
  };

  setModel = (model) => {
    this.state.data = model;
    return this;
  };

  isEdit = () => {
    this.state.isEdit = true;
    return this;
  };

  getValue = (path) => {
    return get(this.state.data, path);
  };

  setValue = (path, value) => {
    this.state.data = update.set(this.state.data, path, value);
    return this;
  };

  hide = (path) => {
    this.state.state = update.set(this.state.state, `${path}.visible`, false);
    return this;
  };

  show = (path) => {
    this.state.state = update.set(this.state.state, `${path}.visible`, true);
    return this;
  };

  setVisible = (path, value) => {
    this.state.state = update.set(this.state.state, `${path}.visible`, !!value);
    return this;
  };

  disable = (path) => {
    this.state.state = update.set(this.state.state, `${path}.disabled`, true);
    return this;
  };
}
