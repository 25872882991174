import React, { Component } from 'react';
import { Form, Button } from 'semantic-ui-react';
import EditableImageUpload from '../common/editableImageUpload';
import { Translate } from 'react-redux-i18n';
class EditableUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ imageError: false });
    }
  }

  imageLoadErrorHandler = (event) => {
    if (!this.state.imageError) {
      this.setState({ imageError: true });
    }
  };

  handleClick = (event) => {
    event.preventDefault();
    return false;
  };

  render() {
    const { label, subtext, required, ...pprops } = this.props;

    const showImage =
      this.props.url && !this.state.imageError ? (
        <img
          alt=""
          className="profile-image"
          onError={this.imageLoadErrorHandler}
          src={this.props.url}
        />
      ) : (
        ''
      );

    const removeBackgroundClass = showImage ? 'remove-background' : '';

    return (
      <Form.Field required={required}>
        <label>{label}</label>
        <EditableImageUpload {...pprops} showImage={showImage}>
          <div className={`default-image-container ${removeBackgroundClass}`}>
            {showImage}
          </div>
          <div className="select-image-text">
            <div className="select-image-text-inner">
              <h4>Select an image from your computer</h4>
              {showImage ? (
                <Button
                  disabled={this.props.disabled}
                  content="Change"
                  onClick={this.handleClick}
                />
              ) : (
                <div>
                  {this.props.format.join(', ')} format. <br />
                  At least {this.props.width} x {this.props.height} pixels
                </div>
              )}
            </div>
          </div>
        </EditableImageUpload>
        {subtext && (
          <div className="input-sub">
            <Translate value={subtext} />
          </div>
        )}
      </Form.Field>
    );
  }
}

export default EditableUpload;
