export const PDFDeliveryType = {
  openInNewTab: 'openInNewTab',
  downloadToDevice: 'downloadToDevice'
};

export const PDFDeliveryTypeOptions = [
  {
    value: PDFDeliveryType.openInNewTab,
    label: 'Open in new browser tab'
  },
  {
    value: PDFDeliveryType.downloadToDevice,
    label: 'Download to device'
  }
];
