export const tapNdonateFilterButtons = [
  {
    key: 'assigned',
    i18nKey: 'terminal.button-group.assigned'
  },
  {
    key: 'unassigned',
    i18nKey: 'terminal.button-group.unassigned'
  }
];
