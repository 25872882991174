import { Record } from '../../modules/bin/utility';
import * as entityHandlers from '../../modules/bin/entityHandlers';
import { localStorageKeys } from '../../constants/localStorageKeys';
import LocalStorage from '../../helpers/localStorageHelper';

export const STRIPE_CONNECTION_REQUESTED = 'stripe/STRIPE_CONNECTION_REQUESTED';
export const STRIPE_CONNECTION_SUCCESS = 'stripe/STRIPE_CONNECTION_SUCCESS';
export const STRIPE_CONNECTION_FAILURE = 'stripe/STRIPE_CONNECTION_FAILURE';
export const ERROR_CLEAR = 'stripe/ERROR_CLEAR';

const initialState = {
  stripeConnectRecord: Record.getDefaultState()
};

const stripeReducer = (state = initialState, action) => {
  let newState = null;
  switch (action.type) {
    case STRIPE_CONNECTION_REQUESTED:
      LocalStorage.removeItem(localStorageKeys.PAYMENT_INFO);
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case STRIPE_CONNECTION_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case STRIPE_CONNECTION_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;
    default:
      newState = state;
      break;
  }

  return newState;
};

export const stripeConnect = (state, scope, code) => {
  const requestModel = {
    state,
    scope,
    code,
    platform: 'Stripe'
  };

  return entityHandlers.createRecord(
    'organization/paymentPlatform',
    STRIPE_CONNECTION_REQUESTED,
    STRIPE_CONNECTION_SUCCESS,
    STRIPE_CONNECTION_FAILURE,
    requestModel,
    null,
    'stripeConnectRecord'
  );
};

export const clearRecordMessages = (recordKey) => ({
  type: ERROR_CLEAR,
  payload: { key: recordKey }
});

export default stripeReducer;
