import React, { Component } from 'react';
import { Form, Dropdown, Message } from 'semantic-ui-react';

class AutocompleteInput extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  search = (data, searchText) => {
    return data;
  };

  getNoData = (data) => {
    return data;
  };

  onBlur = (event, data) => {
    if (data.options.length === 0) {
      this.props.search(event, { searchQuery: '' });
    }
  };

  onFocus = (event, data) => {
    event.target.setAttribute('autocomplete', 'nope');
  };

  clear = () => {
    if (this.props.value || this.props.searchQuery) {
      this.props.onChange({}, {});

      if (this.props.clear) {
        this.props.clear();
      }
    }
    const currentRef = this.props?.myRef?.current || this.myRef?.current;
    if (currentRef) {
      currentRef.clearValue();
    }
  };

  onChange = (e, d) => {
    if (!e) {
      return;
    }

    if (this.props.onChange) {
      this.props.onChange(e, d);
    }

    if (this.props.clearOnChange) {
      this.clear();
    }
  };

  render() {
    const { label, subtext, validation } = this.props;
    const options = this.props.options.filter(item => item).map((item, index) => ({
      key: item.value || index,
      text: item.text,
      value: item.value,
      disabled: this.props.loading,
      description: item.subtext && ` ${item.subtext}`
      //content: <Header content={item.text}
      // 				 subheader={item.subtext}
      // 				 as='h4'
      //				 icon='bullhorn' />,
      //selected: item.value === this.props.value,
      //active: item.value === this.props.value

      //content: `<h4 class="ui header text"><i aria-hidden="true" class="bullhorn icon"></i><div class="content">${item.text}<div class="sub header"${item.subtext}</div></div></h4>`
    }));

    return (
      <Form.Field
        className={this.props.className}
        required={validation && validation.required}
      >
        {label && <label style={{ float: 'left' }}>{label}</label>}
        {!this.props.disabled && (
          <p
            className="Links"
            style={{ float: 'right', cursor: 'pointer' }}
            onClick={this.clear}
          >
            Clear
          </p>
        )}
        <Dropdown
          id={this.props.id}
          name={this.props.name}
          ref={this.props.myRef || this.myRef}
          selection
          search={this.props.loading ? this.getNoData : this.search}
          onSearchChange={this.props.search}
          loading={this.props.loading}
          noResultsMessage={null}
          selectOnNavigation={false}
          onBlur={this.onBlur}
          placeholder={this.props.placeholder}
          options={options}
          searchQuery={this.props.searchQuery}
          disabled={this.props.disabled}
          value={this.props.value}
          onFocus={this.props.onFocus || this.onFocus}
          onChange={this.onChange}
          icon="chevron down"
        />
        {subtext && <div className="input-sub">{subtext}</div>}
        <Message negative hidden={!this.props.validationErrorMessage}>
          <p>{this.props.validationErrorMessage}</p>
        </Message>
      </Form.Field>
    );
  }
}

export default AutocompleteInput;
