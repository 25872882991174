export const getSearchString = (addressModel) => {
  if (!addressModel) {
    return '';
  }

  const { street, suburb, city, state, country, postcode } = addressModel;
  const searchStrings = [street, suburb || city, state, postcode, country];

  return searchStrings.filter((x) => x).join(', ');
};
