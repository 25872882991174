//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from './templateHelper';

//------------Components
import TicketBuilder from '../../components/ticketBuilder/index';

class TemplateTicketBuilder extends Component {
  onChange = (data) => {
    this.props.onChange(this.props.path, data);
  };

  render() {
    const { subtext, disabled, path, validation, isQuestionsEnabled } =
      this.props;
    return (
      <TicketBuilder
        path={path}
        onChange={this.onChange}
        subtext={subtext}
        disabled={disabled}
        validation={validation}
        isQuestionsEnabled={isQuestionsEnabled}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export default connect(mapState)(TemplateTicketBuilder);
