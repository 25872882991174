export const METADATA_REQUESTED = 'account/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'account/METADATA_SUCCESS';
export const METADATA_FAILURE = 'account/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'account/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'account/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'account/LIST_DATA_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'account/TOGGLE_COLUMNS_CHANGE';

export const FUNDRAISERS_METADATA_REQUESTED =
  'account/FUNDRAISERS_METADATA_REQUESTED';
export const FUNDRAISERS_METADATA_SUCCESS =
  'account/FUNDRAISERS_METADATA_SUCCESS';
export const FUNDRAISERS_METADATA_FAILURE =
  'account/FUNDRAISERS_METADATA_FAILURE';

export const FUNDRAISERS_LIST_DATA_REQUESTED =
  'account/FUNDRAISERS_LIST_DATA_REQUESTED';
export const FUNDRAISERS_LIST_DATA_SUCCESS =
  'account/FUNDRAISERS_LIST_DATA_SUCCESS';
export const FUNDRAISERS_LIST_DATA_FAILURE =
  'account/FUNDRAISERS_LIST_DATA_FAILURE';

export const INVITE_ADMIN_PANEL_VISIBILITY_CHANGE =
  'account/INVITE_ADMIN_PANEL_VISIBILITY_CHANGE';
export const INVITE_ADMIN_PARAM_CHANGE = 'account/INVITE_ADMIN_PARAM_CHANGE';
export const INVITE_ADMIN_REQUESTED = 'account/INVITE_ADMIN_REQUESTED';
export const INVITE_ADMIN_SUCCESS = 'account/INVITE_ADMIN_SUCCESS';
export const INVITE_ADMIN_FAILURE = 'account/INVITE_ADMIN_FAILURE';

export const GET_EXPORT_FILE_REQUESTED = 'account/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'account/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'account/GET_EXPORT_FILE_FAILURE';

export const GET_ORGANIZATION_OPTIONS_REQUESTED =
  'account/GET_ORGANIZATION_OPTIONS_REQUESTED';
export const GET_ORGANIZATION_OPTIONS_SUCCESS =
  'account/GET_ORGANIZATION_OPTIONS_SUCCESS';
export const GET_ORGANIZATION_OPTIONS_FAILURE =
  'account/GET_ORGANIZATION_OPTIONS_FAILURE';

export const GET_CAMPAIGN_OPTIONS_REQUESTED =
  'account/GET_CAMPAIGN_OPTIONS_REQUESTED';
export const GET_CAMPAIGN_OPTIONS_SUCCESS =
  'account/GET_CAMPAIGN_OPTIONS_SUCCESS';
export const GET_CAMPAIGN_OPTIONS_FAILURE =
  'account/GET_CAMPAIGN_OPTIONS_FAILURE';

export const GET_EVENT_OPTIONS_REQUESTED =
  'account/GET_EVENT_OPTIONS_REQUESTED';
export const GET_EVENT_OPTIONS_SUCCESS = 'account/GET_EVENT_OPTIONS_SUCCESS';
export const GET_EVENT_OPTIONS_FAILURE = 'account/GET_EVENT_OPTIONS_FAILURE';

export const GET_EVENT_ORGANIZER_OPTIONS_REQUESTED =
  'account/GET_EVENT_ORGANIZER_OPTIONS_REQUESTED';
export const GET_EVENT_ORGANIZER_OPTIONS_SUCCESS =
  'account/GET_EVENT_ORGANIZER_OPTIONS_SUCCESS';
export const GET_EVENT_ORGANIZER_OPTIONS_FAILURE =
  'account/GET_EVENT_ORGANIZER_OPTIONS_FAILURE';

export const ACTIVE_TAB_SET = 'account/ACTIVE_TAB_SET';
export const MESSAGE_CLEAR = 'account/MESSAGE_CLEAR';

export const ON_INVITE_PANEL_VALIDATION_CHANGE =
  'account/ON_INVITE_PANEL_VALIDATION_CHANGE';