import get from 'lodash.get';
import update from './update';
import { funraisningExtraFields } from '../pages/campaign/constants';
import {
  hasFormbuilderQuestions,
  getFormbuilderQuestions,
  getAddressModelFromFormbuilderAnswers,
  getPhoneNumberFromFormbuilderAnswers,
  getCustomFields as getFields
} from './formBuilderHelper';
import { fieldTypes } from '../components/formbuilder/constants';
import { nodeTypes } from '../components/formbuilder/typeDefinitions';
import {
  generalFormBuilderPath,
  customFieldCategory
} from '../constants/formBuilder';

export const getCustomFields = (data) => {
  const formBuilder = get(data, 'template.value.formBuilder');

  if (!formBuilder) {
    return;
  }

  const customFields = {};
  Object.keys(customFieldCategory).forEach((item) => {
    const category = customFieldCategory[item];
    customFields[category] = getCustomFieldCategory(formBuilder[category]);
  });

  return customFields;
};

export const getCustomFieldCategory = (formbuilderModel) => {
  if (!formbuilderModel || !hasFormbuilderQuestions(formbuilderModel)) {
    return;
  }

  const customFields = formbuilderModel.children[0].children.map((item) => {
    if (
      item.type === nodeTypes.address ||
      item.type === nodeTypes.phone ||
      item.props.ignore
    ) {
      return null;
    }

    return {
      identifier: item.identifier,
      type: fieldTypes[item.type] || fieldTypes.textInput,
      label: item.props.title,
      mandatory: item.props.required,
      hidden: item.props.hidden,
      content: getQuestionContent(item, item.type),
      exportLabel: item.props.exportLabel,
      doNotExport: item.props.doNotExport
    };
  });

  if (customFields && customFields.length) {
    // only return data that is NOT nullish (i.e. niether undefined nor null)
    return customFields.filter((x) => x);
  }
};

const getQuestionContent = (questionItem, fieldType) => {
  if (!fieldType || !questionItem) {
    return;
  }

  switch (fieldType) {
    case nodeTypes.dropdown:
    case nodeTypes.checkbox: {
      const options = get(questionItem, 'props.options');

      return options && options.split('\n');
    }

    case nodeTypes.consent: {
      const content =
        get(questionItem, 'props.content') || 'I agree to the {url}';

      return [content];
    }

    default:
      return;
  }
};

const initialPhoneAddressModels = {
  phone: {
    capture: false
  },
  address: {
    capture: false
  }
};

export const getInitialDataCapture = () => {
  let dataCapture = {};

  Object.keys(customFieldCategory).forEach((key) => {
    dataCapture[customFieldCategory[key]] = initialPhoneAddressModels;
  });

  return dataCapture;
};

export const getDataCapture = (data) => {
  const formBuilder = get(data, 'template.value.formBuilder');

  let dataCapture = getInitialDataCapture();

  if (!formBuilder) {
    return dataCapture;
  }

  Object.keys(customFieldCategory).forEach((item) => {
    const category = customFieldCategory[item];

    const captureInfo = getCaptureInfo(formBuilder[category]);
    const fields = getCustomFieldCategory(formBuilder[category]);

    if (captureInfo !== undefined || fields) {
      dataCapture = update.set(dataCapture, `${category.toLowerCase()}`, {
        ...dataCapture[category.toLowerCase()],
        ...captureInfo,
        fields
      });
    }
  });

  return dataCapture;
};
export const getCaptureInfo = (formBuilderModel) => {
  const formBuilderQuestions = getFormbuilderQuestions(formBuilderModel);

  if (!formBuilderQuestions || !formBuilderQuestions.length) {
    return;
  }

  const phoneNumberQuestion = formBuilderQuestions.find(
    (x) => x.type === nodeTypes.phone
  );
  const addressQuestion = formBuilderQuestions.find(
    (x) => x.type === nodeTypes.address
  );
  const dataCapture = {};

  if (phoneNumberQuestion) {
    dataCapture[funraisningExtraFields.PHONE] =
      getDataCaptreByQuestion(phoneNumberQuestion);
  }

  if (addressQuestion) {
    dataCapture[funraisningExtraFields.ADDRESS] =
      getDataCaptreByQuestion(addressQuestion);
  }

  return phoneNumberQuestion || addressQuestion ? dataCapture : undefined;
};

export const getDataCaptreByQuestion = (question) => {
  const isRequired = get(question, 'props.required');

  return {
    capture: true,
    mandatory: isRequired
  };
};

export const getDataWithQuestionFields = (
  data,
  formBuilderPath,
  addressPath = 'address',
  phoneNumberPath = 'phoneNumber'
) => {
  const formbuilder = get(data, formBuilderPath);
  let newData = update.set(data, 'fields', getFields(formbuilder));

  newData = update.set(
    newData,
    addressPath,
    getAddressModelFromFormbuilderAnswers(formbuilder)
  );
  newData = update.set(
    newData,
    phoneNumberPath,
    getPhoneNumberFromFormbuilderAnswers(formbuilder)
  );
  newData = update.set(newData, generalFormBuilderPath, undefined);

  return newData;
};

export const getFullQuestionFields = (
  data,
  formBuilderPath = generalFormBuilderPath
) => {
  const formbuilder = get(data, formBuilderPath);

  let fields = initialPhoneAddressModels;

  if (!formbuilder) {
    return fields;
  }

  fields = update.set(fields, 'fields', getCustomFieldCategory(formbuilder));

  const formBuilderQuestions = getFormbuilderQuestions(formbuilder);

  const addressQuestion = formBuilderQuestions.find(
    (x) => x.type === nodeTypes.address
  );
  const phoneNumberQuestion = formBuilderQuestions.find(
    (x) => x.type === nodeTypes.phone
  );

  if (addressQuestion) {
    fields = update.set(
      fields,
      'address',
      getDataCaptreByQuestion(addressQuestion)
    );
  }
  if (phoneNumberQuestion) {
    fields = update.set(
      fields,
      'phone',
      getDataCaptreByQuestion(phoneNumberQuestion)
    );
  }

  return fields;
};
