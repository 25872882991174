import get from 'lodash.get';
import { Record } from '../constants';

export const getRecordStatus = (props) => {
  const id = get(props, 'record.id');
  const ready = get(props, 'record.ready');
  const paramId = get(props, 'match.params.id');

  if (!id || ready === Record.RECORD_STATUS_NO_DATA)
    return Record.RECORD_STATUS_NO_DATA;

  if (paramId && id !== parseInt(paramId)) {
    return Record.RECORD_STATUS_WRONG_RECORD_LOADED;
  }

  if (paramId && id === parseInt(paramId) && ready === 0) {
    return Record.RECORD_STATUS_READY;
  }

  return Record.RECORD_STATUS_LOADING;
};

export const isLoadingRecord = (props) => {
  return getRecordStatus(props) !== Record.RECORD_STATUS_READY;
};

export const loadRecordIfRequired = (props) => {
  // Passed via routing.
  const paramId = get(props, 'match.params.id');

  const recordStatus = getRecordStatus(props);

  // Load the data if missing.
  if (
    recordStatus === Record.RECORD_STATUS_NO_DATA ||
    recordStatus === Record.RECORD_STATUS_WRONG_RECORD_LOADED
  ) {
    props.getRecord(paramId);
  }
};
