import React, { Component } from 'react';
import Frame from './frame';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  onValueChange,
  activateUser,
  updateAccount,
  clearRecordMessages
} from '../../modules/authentication';
import { getPasswordErrorMessage } from '../../helpers/validationHelper';
import {
  Button,
  Header,
  Container,
  Form as SemanticForm
} from 'semantic-ui-react';
import ValidatedInput from '../common/validatedInput';
import Spinner from '../common/spinner';
import { Record } from '../../modules/bin/utility';
import RecordResultMessage from '../common/recordResult';
import InvalidPassword from './invalidPassword';
import { parseQuery } from '../../helpers/queryHelper';
import get from 'lodash.get';

class Invite extends Component {
  constructor(props) {
    super(props);
    props.activateUser(props.match.params.inviteKey);
  }

  clearMessage = () => {
    this.props.clearRecordMessages('invite.createAccountRecord');
  };

  onCreateAccountClick = () => {
    const { firstName, lastName, password } = this.props.invite;

    this.props.updateAccount(this.props.invite.activateRecord.data.account.id, {
      firstName: firstName,
      lastName: lastName,
      password: password
    });
  };

  isCreateButtonDisabled = (passwordsErrorMessage) => {
    return (
      passwordsErrorMessage !== undefined ||
      this.props.invite.password !== this.props.invite.confirmPassword
    );
  };

  render() {
    const { invite } = this.props;

    if (Record.isError(invite.activateRecord)) {
      this.props.history.push(`/`);
    }

    if (Record.isSuccess(invite.createAccountRecord)) {
      const queryParams = parseQuery(this.props.location.search);

      if (queryParams.onboarding === '1') {
        const organizationId = get(
          invite.createAccountRecord,
          'data.data.organization.id'
        );
        this.props.history.push(
          `/organization/edit/${organizationId}/about?onboarding=1`
        );
      } else {
        this.props.history.push(`/`);
      }
    }
    if (
      Record.isRecordLoading(invite.activateRecord) ||
      Record.isRecordLoading(invite.createAccountRecord)
    ) {
      return <Spinner />;
    }

    const passwordsErrorMessage = getPasswordErrorMessage(
      invite.password,
      invite.confirmPassword
    );

    return (
      <div>
        <RecordResultMessage
          record={invite.createAccountRecord}
          onDismiss={this.clearMessage}
        />
        <Frame>
          <Header textAlign="center" as="h2">
            Create your account
          </Header>
          <SemanticForm>
            <Container>
              <ValidatedInput
                label="First name"
                placeholder="Your first name"
                value={invite.firstName}
                onChange={(e, { value }) =>
                  this.props.onValueChange('invite.firstName', value)
                }
              />

              <ValidatedInput
                label="Last name"
                placeholder="Your last name"
                value={invite.lastName}
                onChange={(e, { value }) =>
                  this.props.onValueChange('invite.lastName', value)
                }
              />

              <ValidatedInput
                placeholder="Enter your password"
                label="Password"
                value={invite.password}
                onChange={(e, { value }) =>
                  this.props.onValueChange('invite.password', value)
                }
                type="password"
                error={passwordsErrorMessage !== undefined}
                errorComponent={
                  <InvalidPassword errors={passwordsErrorMessage} />
                }
              />

              <ValidatedInput
                placeholder="Confirm your password"
                label="Confirm password"
                onChange={(e, { value }) =>
                  this.props.onValueChange('invite.confirmPassword', value)
                }
                value={invite.confirmPassword}
                type="password"
                errorMessage={
                  invite.password !== invite.confirmPassword
                    ? I18n.t('errors.validation.password.not-equal')
                    : ''
                }
              />

              <Button
                primary
                fluid
                disabled={this.isCreateButtonDisabled(passwordsErrorMessage)}
                onClick={this.onCreateAccountClick}
              >
                Create account
              </Button>
            </Container>
          </SemanticForm>
        </Frame>
      </div>
    );
  }
}

const mapStateToProps = ({ authentication, ...state }) => {
  return {
    invite: authentication.invite
  };
};

const mapDispatch = (dispatch) => {
  return {
    onValueChange: bindActionCreators(onValueChange, dispatch),
    activateUser: bindActionCreators(activateUser, dispatch),
    updateAccount: bindActionCreators(updateAccount, dispatch),
    clearRecordMessages: bindActionCreators(clearRecordMessages, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatch)(Invite);
