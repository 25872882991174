import React from 'react';
import {
  getFullImagePathByType,
  imageTypes
} from '../../../../constants/imageUpload';
import Styles from './merchandise-image.module.css';

export function getFullImagePath(value?: string) {
  return value ? getFullImagePathByType(value, imageTypes.LOGO) : undefined;
}

export const ImagePlaceholder: React.FunctionComponent = () => (
  <div className={Styles.ImagePlaceholder} />
);

export const ImageView: React.FunctionComponent<{ path?: string }> = ({
  path
}) =>
  path ? <img className={Styles.ImageView} src={path} /> : <ImagePlaceholder />;
