import React from 'react';
import { I18n } from 'react-redux-i18n';

import get from 'lodash.get';
import { formatDecimalNumber } from '../../../../helpers/converterHelper';
import { isArrayWithItems } from '../../../../helpers/arrayHelper';
import { Record as RecordHelper } from '../../../../modules/bin/utility';

import { Loader } from 'semantic-ui-react';
import { summaryOptions } from '../../constants';

export const SummaryContainer = (props) => {
  const { models, summaryType } = props;
  const isLoading =
    isArrayWithItems(models) &&
    models.some((model) => RecordHelper.isRecordLoading(model.summaryRecord));
  const renderModels = models.filter(
    (model, index, self) =>
      self.findIndex((element) => element.id === model.id) === index
  );

  return (
    <React.Fragment>
      {isLoading && (
        <div className="loading-panel">
          <Loader active inline />
        </div>
      )}
      {!isLoading && (
        <div className="summary-panel">
          <div className="summary-container">
            <div className="summary-item">
              <div className="name">
                {React.Children.toArray(
                  renderModels.map((model) => {
                    return <h2>{model.name}</h2>;
                  })
                )}
              </div>
            </div>
            {React.Children.toArray(
              summaryOptions.map((item) => {
                return (
                  <div
                    className={`summary-item ${
                      summaryType === item.key ? 'active' : ''
                    }`}
                    onClick={props.onSummaryClick.bind({}, item.key)}
                  >
                    {React.Children.toArray(
                      renderModels.map((model) => {
                        const summary = get(model.summaryRecord, 'data.data');
                        const value = formatDecimalNumber(
                          get(summary, item.key),
                          true
                        );
                        return (
                          <h2>
                            {item.prefix}
                            {value}
                          </h2>
                        );
                      })
                    )}
                    {I18n.t(`dashboard.top-area.summary.${item.i18n}`)}
                  </div>
                );
              })
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
