export default class LocalStorage {
  static removeItem(key) {
    localStorage.removeItem(key);
  }

  static setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  static getItem(key) {
    let value = null;

    try {
      value = JSON.parse(localStorage.getItem(key));
    } catch (err) {}

    return value;
  }
}
