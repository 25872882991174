let CustomImageBlotExport;
if (typeof window !== 'undefined') {
  let ReactQuill = require('react-quill');
  let BlockEmbed = ReactQuill.Quill.import('blots/inline');

  CustomImageBlotExport = class CustomImageBlot extends BlockEmbed {};
  CustomImageBlotExport.blotName = 'custom-image';
  CustomImageBlotExport.tagName = 'div';
  CustomImageBlotExport.className = 'content-image-cont-inner';
}
export default CustomImageBlotExport;
