import * as entityHandlers from '../../modules/bin/entityHandlers';
import { Record } from '../../modules/bin/utility';

import { CLEAR } from '../../modules/general';
export const ERROR_CLEAR = 'register/ERROR_CLEAR';
export const CREATE_REQUESTED = 'register/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'register/CREATE_SUCCESS';
export const CREATE_FAILURE = 'register/CREATE_FAILURE';

const initialState = {
  saveRecord: Record.getDefaultState()
};

// State Reducer
const registerReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case CREATE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case CREATE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case CLEAR: {
      newState = initialState;
      break;
    }
    default:
      return newState;
  }

  return newState;
};

export const postOnboarding = (model, verificationToken = null) => {
  return entityHandlers.createRecord(
    '/organization/onboarding',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    model,
    null,
    'saveRecord',
    'v2',
    verificationToken
  );
};

export const clearRecordMessages = (recordKey) => {
  return {
    type: ERROR_CLEAR,
    payload: { key: recordKey }
  };
};

export default registerReducer;
