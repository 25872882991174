import { useEffect } from 'react';
import ReactGA from 'react-ga';
import { useHistory } from 'react-router-dom';
import config from '../../constants';

export const useGoogleAnalytics = () => {
  const history = useHistory();

  useEffect(() => {
    ReactGA.initialize(config.GOOGLE_ANALYTICS_KEY as string);

    const unregisterHistoryListener = history.listen((location) => {
      ReactGA.pageview(location.pathname);
    });

    return () => {
      unregisterHistoryListener();
    };
  }, [history, config.GOOGLE_ANALYTICS_KEY]);

  return null;
};
