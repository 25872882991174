import React, { useState, useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Button, Icon, Modal, Popup, TextArea } from 'semantic-ui-react';

import { Record } from '../../../modules/bin/utility';
import { toggleStripeUrlModal, getStripeChangeLink } from '../store/organizationActions';
import "./stripeUrlModal.css";

export const StripeUrlModal = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.organization.isStripeUrlModalOpen);
  const organizationId = useSelector((state: any) => state.organization.record.data.data.id);
  const stripeLinkChangeRecord = useSelector((state: any) => state.organization.stripeLinkChangeRecord);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const isUrlLoading = Record.isRecordLoading(stripeLinkChangeRecord);

  useEffect(() => {
    if (open) {
      dispatch(getStripeChangeLink(organizationId));
    }
  }, [open]);

  const handleOpen = () => {
    setIsPopupOpen(true);
    setTimeout(() => {
      setIsPopupOpen(false);
    }, 3500);
  };

  return (
    <Modal
      className="stripe-url-modal"
      size="tiny"
      dimmer="inverted"
      closeIcon
      open={open}
    >
      <Modal.Header>
        {I18n.t('template.organisation.content.finance.stripeUrlModal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className="content">
          {I18n.t('template.organisation.content.finance.stripeUrlModal.description')}
        </div>
        <div className="sub-content">
          {I18n.t('template.organisation.content.finance.stripeUrlModal.warning')}
        </div>
        <div className="text-area-container">
          <label>{I18n.t('template.organisation.content.finance.stripeUrlModal.payment-link')}</label>
          <TextArea
            disabled
            value={isUrlLoading
              ? I18n.t('template.organisation.content.finance.stripeUrlModal.loading')
              : stripeLinkChangeRecord.data.data
            }
          />
        </div>
        <div className="stripe-link-section">
          <Popup
            className='stripe-link-section-copy-link'
            trigger={
              <div
                className="item"
                onClick={() => {
                  if (!isUrlLoading) {
                    handleOpen();
                    navigator.clipboard.writeText(stripeLinkChangeRecord.data.data);
                  }
                }}
              >
                <Icon name="copy" />
                <span>
                  {I18n.t('template.organisation.content.finance.stripeUrlModal.copy')}
                </span>
              </div>
            }
            content={I18n.t('template.organisation.content.finance.stripeUrlModal.copied')}
            on="click"
            open={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
          />
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button primary onClick={() => dispatch(toggleStripeUrlModal())}>
          {I18n.t('template.organisation.content.finance.stripeUrlModal.close')}
        </Button>
      </Modal.Actions>
    </Modal>
  );
}
