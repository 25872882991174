import React from 'react';
import { Table, Image } from 'semantic-ui-react';
import {
  getFullImagePathByType,
  imageTypes
} from '../../../../constants/imageUpload';

class OrganisationImage extends React.Component {
  render() {
    const { value } = this.props;
    const image = value ? getFullImagePathByType(value, imageTypes.CELL) : '';

    return (
      <Table.Cell collapsing className="image-cell">
        <Image src={image} />
      </Table.Cell>
    );
  }
}

export default OrganisationImage;
