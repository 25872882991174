//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Table } from 'semantic-ui-react';

//---------------Constants
import { SaleCouponDiscountType } from '../../../collectionBuilder/constants';
import { i18CouponKeys } from '../constants/translation';

class CouponListTable extends Component {
  getDiscountTypeValue = (type, value) => {
    if (type === SaleCouponDiscountType.AsPercentage) {
      return `${value}%`;
    } else {
      return `$${value}`;
    }
  };

  render() {
    const { coupons, editMode, onEdit, i18Key } = this.props;

    return (
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              {I18n.t(
                `${i18Key}.${i18CouponKeys.tableList.columns.discountCode}`
              )}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t(`${i18Key}.${i18CouponKeys.tableList.columns.amount}`)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t(`${i18Key}.${i18CouponKeys.tableList.columns.usedCount}`)}
            </Table.HeaderCell>
            <Table.HeaderCell>
              {I18n.t(`${i18Key}.${i18CouponKeys.tableList.columns.action}`)}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {React.Children.toArray(
            coupons.map((coupon, couponIndex) => {
              const discountTypeValue = this.getDiscountTypeValue(
                coupon.discountType,
                coupon.discountTypeValue
              );

              return React.Children.toArray(
                coupon.instances.map((instance, instanceIndex) => {
                  return (
                    <Table.Row>
                      <Table.Cell>{instance.code}</Table.Cell>
                      <Table.Cell>{discountTypeValue}</Table.Cell>
                      <Table.Cell>{coupon.usedCount}</Table.Cell>
                      <Table.Cell>
                        {!editMode && (
                          <p
                            className="Links edit-coupon-button"
                            onClick={onEdit.bind(
                              this,
                              couponIndex,
                              instanceIndex
                            )}
                          >
                            {I18n.t(
                              `${i18Key}.${i18CouponKeys.tableList.editButton}`
                            )}
                          </p>
                        )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })
              );
            })
          )}
        </Table.Body>
      </Table>
    );
  }
}

export default CouponListTable;
