import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import * as donationActions from '../../donation/store/donationActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import clear from '../../../components/clear';
import { Record } from '../../../modules/bin/utility';

import { Button, Popup, Grid } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import DonationTemplate from '../template';
import BasePage from '../../../components/common/basePageView';
import ReceiptWindowContainer from '../receipt-window';
import RecordResultMessage from '../../../components/common/recordResult';
import { setRedirectUrl } from '../../../modules/session';
import { TemplateOptions } from '../../../components/template/templateOptions';
import {
  pageTypes,
  displayNameKeys,
  displayNameOptions,
  paths
} from '../constants';
import { ONLINE_DONATION_TYPE } from '../../campaign/constants';
import donationType from '../../../constants/donationType';

class EditDonation extends Component {
  constructor(props) {
    super(props);
    props.donationActions.getRecord(props.match.params.id);
  }

  onSave = (data) => {
    this.props.donationActions.updateRecord(data);
  };

  clearMessages = () => {
    this.props.donationActions.clearRecordMessages('saveRecord');
  };

  goToDonationsList = () => {
    let url = '/donations';
    if (this.props.redirectUrl) {
      url = this.props.redirectUrl;
      this.props.setRedirectUrl();
    }
    this.props.history.push(url);
  };

  onReissueClick = () => {
    if (this.props.record.data.data.donor.email) {
      this.props.donationActions.showReceiptConfirmation(
        this.props.record.data.data
      );
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  initTemplateBuilderModel = () => {
    if (!this.props.isTemplateInited) {
      const donation = this.props.record.data.data;

      const model = new TemplateOptions()
        .setModel(donation)
        .isEdit()
        .setValue('customer.lastName', donation.donor.lastName)
        .setValue('customer.firstName', donation.donor.firstName)
        .setValue('customer.email', donation.donor.email)
        .setValue('customer.businessName', donation.donor.businessName)
        .setValue('customer.anonymous', donation.donor.anonymous)
        .hide('issueReceipt')
        .disable('campaignId');

      if (donation.type === ONLINE_DONATION_TYPE) {
        model.disable('amount');
      }

      if (donation.pageType === pageTypes.FUNDRAISER) {
        model
          .setValue('pageId', `fundraiser_${donation.fundraiserId}`)
          .setValue('page', donation.fundraiser)
          .setValue('page.type', 'fundraiser')
          .setValue('page.fundraiserId', donation.fundraiserId);
      }

      if (donation.pageType === pageTypes.TEAM) {
        model
          .setValue('pageId', `team_${donation.teamId}`)
          .setValue('page', donation.team)
          .setValue('page.type', 'team')
          .setValue('page.teamId', donation.teamId);
      }

      if (donation.pageType === pageTypes.COLLECTION) {
        model
          .setValue('pageId', `collection_${donation.collectionId}`)
          .setValue('page', donation.collection)
          .setValue('page.type', 'collection')
          .setValue('page.collectionId', donation.collectionId);
      }

      let displayNameKey = '';
      //Display name for message
      if (donation.donor.anonymous) {
        displayNameKey = displayNameKeys.asAnonymous;
        model.hide('displayName');
      } else if (donation.isCustomDisplayName) {
        displayNameKey = displayNameKeys.asCustomName;
      } else {
        const fullNameOption = displayNameOptions[displayNameKeys.asFullName];
        if (donation.displayNameOption === fullNameOption.displayName) {
          displayNameKey = displayNameKeys.asFullName;
          model.hide('displayName');
        } else {
          displayNameKey = displayNameKeys.asFirstName;
        }
        model.hide('displayName');
      }

      model.setValue('selectedDisplayNameOption', displayNameKey);

      if (donation.subscriptionId) {
        if (!donation.campaignId) {
          model.hide('campaignId');
        }
        model.hide(paths.DONATION_FORMBUILDER);
      }

      this.props.templateBuilderActions.setTemplateModel(model.get());
    }
  };

  renderReIssueReceiptButton = (canReIssueReceipt) => {
    const reIssueReceipt = (
      <div style={{ width: 'fit-content', float: 'right' }}>
        <Button
          disabled={canReIssueReceipt}
          onClick={this.onReissueClick.bind(this)}
        >
          Re-issue receipt
        </Button>
      </div>
    );

    if (!canReIssueReceipt) {
      return reIssueReceipt;
    }

    return (
      <Popup
        content={
          <Translate value="donation.reissue-receipt.disabled-pending" />
        }
        position="bottom center"
        trigger={reIssueReceipt}
      />
    );
  };

  render() {
    const isLoading =
      Record.notStarted(this.props.record) ||
      Record.isRecordLoading(this.props.record);
    const isSaving = Record.isRecordLoading(this.props.saveRecord);
    const isSuccess = Record.isSuccess(this.props.saveRecord);
    const canReIssueReceipt =
      this.props.record && this.props.record.type === donationType.pending;

    if (isLoading) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToDonationsList();
    }

    this.initTemplateBuilderModel();

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />
        <ReceiptWindowContainer />

        <Grid>
          <Grid.Row columns={2} style={{ padding: '0px' }}>
            <Grid.Column width={5}>
              <h1 style={{ display: 'inline-block' }}>
                <Translate value="donation.edit.title" />
              </h1>
            </Grid.Column>
            <Grid.Column width={11}>
              {this.renderReIssueReceiptButton(canReIssueReceipt)}
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <DonationTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

const mapState = ({ donation, templateBuilderEx, session }) => {
  return {
    record: donation.record,
    saveRecord: donation.saveRecord,
    isTemplateInited: templateBuilderEx.inited,
    redirectUrl: session.redirectUrl
  };
};

const mapDispatch = (dispatch) => {
  return {
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    donationActions: bindActionCreators(donationActions, dispatch),
    templateBuilderActions: bindActionCreators(templateBuilderActions, dispatch)
  };
};

const EditDonationContainer = withRouter(
  clear(connect(mapState, mapDispatch)(EditDonation))
);
export default EditDonationContainer;
