import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

import * as generalActions from '../../modules/general';
import * as recurringActions from './reducer';

import { Record } from '../../modules/bin/utility';
import { Button, Modal } from 'semantic-ui-react';

class CancelWindow extends Component {
  onCancelClick = () => {
    this.props.cancelDonation(this.props.cancelRecord.id);
  };

  onCancelConfirmClose = () => {
    this.props.closeCancelConfirmation();
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  render() {
    const subscriptionNumber = get(
      this.props.cancelRecord,
      'subscriptionNumber'
    );

    const isCancelling = Record.isRecordLoading(this.props.saveRecord);
    const isCancelled = this.props.isCancelled;
    if (isCancelled) {
      this.props.generalActions.clearListsReadyState();
      this.props.clearIsCancelled();
    }

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={this.props.cancelConfirmation}
      >
        <Modal.Header>Confirmation required</Modal.Header>

        <Modal.Content>
          <p>
            Are you sure you want to cancel all future payments for subscription{' '}
            {subscriptionNumber} ?
          </p>
          <div
            className="button-wrapper align-right"
            style={{ marginTop: '10px' }}
          >
            <Button
              primary
              onClick={this.onCancelClick}
              style={{ marginRight: '10px' }}
              loading={isCancelling}
              disabled={isCancelling}
            >
              Yes
            </Button>
            <Button onClick={this.onCancelConfirmClose}>No</Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ recurring }) => {
  return {
    saveRecord: recurring.saveRecord,
    cancelRecord: recurring.cancelDonation,
    isCancelled: recurring.isCancelled,
    isDeleted: recurring.isDeleted,
    cancelConfirmation: recurring.cancelConfirmation
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(recurringActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const CancelWindowContainer = connect(mapState, mapDispatch)(CancelWindow);
export default CancelWindowContainer;
