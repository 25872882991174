import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class StringItem extends Component {
  render() {
    const { path, onChange, value } = this.props;
    return (
      <Form.Group grouped>
        <Form.Input path={path} onChange={onChange} value={value} />
      </Form.Group>
    );
  }
}

export default StringItem;
