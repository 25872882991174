import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

import moment from 'moment';

import { hasFormbuilderQuestions } from '../../helpers/formBuilderHelper';
import { getSearchString } from '../formBuilderAnswersComponents/formBuilderAnswersHelper';

import { nodeTypes } from '../formbuilder/typeDefinitions';
import config from '../../constants';
import { countryOptions } from '../../constants/countries';

class FormBuilderView extends Component {
  getFieldComponent = (question) => {
    const { type, props, answer } = question;

    const label = props.title;
    const isRequired = props.required;
    const subtext = props.subtext;

    switch (type) {
      case nodeTypes.date:
        return (
          <Form.Field className="field-section" required={isRequired}>
            <label>{label}</label>
            {answer ? moment(answer).format(config.DEFAULT_DATE_FORMAT) : null}
            <div>{subtext}</div>
          </Form.Field>
        );

      case nodeTypes.checkbox:
        const options = props.options.split('\n');

        return (
          <Form.Field className="field-section" required={isRequired}>
            <label>{label}</label>
            {React.Children.toArray(
              options.map((item) => {
                return <div>{item}</div>;
              })
            )}
            <div>{subtext}</div>
          </Form.Field>
        );

      case nodeTypes.address:
        return (
          <Form.Field className="field-section" required={isRequired}>
            <label>{label}</label>
            {getSearchString(answer)}
            <div>{subtext}</div>
          </Form.Field>
        );

      case nodeTypes.country:
        const country = answer
          ? countryOptions.find((x) => x.value === answer)
          : '';

        return (
          <Form.Field className="field-section" required={isRequired}>
            <label>{label}</label>
            {country ? country.text : answer}
            <div>{subtext}</div>
          </Form.Field>
        );

      default:
        return (
          <Form.Field className="field-section" required={isRequired}>
            <label>{label}</label>
            {answer}
            <div>{subtext}</div>
          </Form.Field>
        );
    }
  };

  getformBuilderFieldsComponents = () => {
    if (!hasFormbuilderQuestions(this.props.value)) {
      return;
    }

    const components = this.props.value.children[0].children
      .filter((item) => !item.props.hidden)
      .map((item) => {
        return this.getFieldComponent(item);
      });

    return components.filter((x) => x);
  };

  render() {
    const formBuilderFields = this.getformBuilderFieldsComponents();

    return (
      <React.Fragment>
        {formBuilderFields &&
          React.Children.toArray(
            formBuilderFields.map((field) => {
              return field;
            })
          )}
      </React.Fragment>
    );
  }
}

export default FormBuilderView;
