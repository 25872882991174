import React from 'react';
import { get } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { Translate } from 'react-redux-i18n';

import { ViewTable } from '../../../components/layouts/viewTable';
import { ViewActionSectionRow } from '../../../components/layouts/viewActionSectionRow';
import { donationI18Keys } from './constants/donationSettings';
import donationType from '../../../constants/donationType';

export const DonationAction = (props) => {
  const {
    id,
    saleId,
    subscriptionId,
    receiptIdentifier,
    donor,
    organization,
    financials,
    type
  } = props.donation;

  const organizationUrl = get(organization, 'urlFull');
  const isRefunded = get(financials, 'refunded');
  const isOnline = type === donationType.Online;
  const isOffline = type === donationType.Offline;
  const isPending = type === donationType.Pending;

  const donorId = get(donor, 'donorId');

  const handleClickViewReissueReceipt = () => {
    props.openModalOnReissueClick({ id, ...donor });
  };

  const getViewAllDonorDonation = () => {
    return `/accounts/detail/${donorId}/donations`;
  };

  const getViewLinkedSalesDetails = () => {
    return `/sales/edit/${saleId}/invoice-information`;
  };

  const getViewLinkedRecurringPayments = () => {
    return `/recurring/detail/${subscriptionId}/donations`;
  };

  const handleClickRefundOrDelete = () => {
    props.openModalOnRefundClick(props.donation);
  };

  const getViewReceiptLink = () => {
    return `${organizationUrl}/receipt/${receiptIdentifier}`;
  };

  return (
    <div className="donation-action-container">
      <ViewTable
        title={
          <Translate value="donation.donation-view.donation-details.actions" />
        }
      >
        <ViewActionSectionRow
          icon="eye"
          title={I18n.t(
            `${donationI18Keys.donationActionI18}.viewReceipt.title`
          )}
          href={getViewReceiptLink()}
          target="_blank"
        />
        <ViewActionSectionRow
          icon="share"
          title={I18n.t(
            `${donationI18Keys.donationActionI18}.reissueReceipt.title`
          )}
          onClick={handleClickViewReissueReceipt}
          isDisabled={isPending}
          disabledMessage={
            <Translate value="donation.reissue-receipt.disabled-pending" />
          }
        />
        {donorId && (
          <ViewActionSectionRow
            icon="eye"
            title={I18n.t(
              `${donationI18Keys.donationActionI18}.viewAllDonorDonation.title`
            )}
            href={getViewAllDonorDonation()}
          />
        )}
        {saleId && (
          <ViewActionSectionRow
            icon="eye"
            title={I18n.t(
              `${donationI18Keys.donationActionI18}.viewLinkedSalesDetails.title`
            )}
            href={getViewLinkedSalesDetails()}
          />
        )}
        {subscriptionId && (
          <ViewActionSectionRow
            icon="eye"
            title={I18n.t(
              `${donationI18Keys.donationActionI18}.viewLinkedRecurringGiving.title`
            )}
            href={getViewLinkedRecurringPayments()}
          />
        )}
        {!isRefunded && (
          <React.Fragment>
            {isOnline && (
              <ViewActionSectionRow
                icon="trash alternate"
                title={I18n.t(
                  `${donationI18Keys.donationActionI18}.refund.title`
                )}
                onClick={handleClickRefundOrDelete}
              />
            )}
            {isOffline && (
              <ViewActionSectionRow
                icon="trash alternate"
                title={I18n.t(
                  `${donationI18Keys.donationActionI18}.delete.title`
                )}
                onClick={handleClickRefundOrDelete}
              />
            )}
          </React.Fragment>
        )}
      </ViewTable>
    </div>
  );
};
