import axios from 'axios';
import config from '../constants/index';

export default class GeolocationService {
  static findLatLonFromIP() {
    return axios.post(
      `https://www.googleapis.com/geolocation/v1/geolocate?key=${config.GOOGLE_MAPS_API_KEY}`,
      {
        considerIp: true
      }
    );
  }
}
