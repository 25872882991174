import get from 'lodash.get';
import update from './update';
import { nodeTypes } from '../components/formbuilder/typeDefinitions';
import { isArrayWithItems } from './arrayHelper';
import { getSearchString } from '../components/formBuilderAnswersComponents/formBuilderAnswersHelper';
import {
  generalFormBuilderPath,
  customFieldCategory,
  oldCustomFieldCategory
} from '../constants/formBuilder';

export const hasFormbuilderQuestions = (formbuilderModel) => {
  if (!formbuilderModel) {
    return false;
  }

  const questions = get(formbuilderModel, 'children.0.children');

  return questions && questions.length;
};

export const getFormbuilderQuestions = (formbuilderModel) => {
  return get(formbuilderModel, 'children.0.children') || [];
};

export const getCustomFieldsWithAnswers = (
  campaignQuestions,
  pageAnswers,
  phoneNumber,
  address,
) => {
  if (!hasFormbuilderQuestions(campaignQuestions)) {
    return;
  }

  if (!isArrayWithItems(pageAnswers) && !phoneNumber && !address) {
    return campaignQuestions;
  }

  const fieldsWithAnswers = getFormbuilderQuestions(campaignQuestions).map(
    (item) => {
      return getFieldWithAnswer(item, pageAnswers, phoneNumber, address);
    }
  );

  return update.set(
    campaignQuestions,
    'children.0.children',
    fieldsWithAnswers
  );
};

export const getCustomFieldsWithReferenceAnswers = (
  campaignQuestions,
  model
) => {
  if (!hasFormbuilderQuestions(campaignQuestions)) {
    return;
  }

  if (!model) {
    return campaignQuestions;
  }

  const fieldsWithAnswers = getFormbuilderQuestions(campaignQuestions).map(
    (item) => {
      return getFieldWithReferenceAnswer(item, model);
    }
  );

  return update.set(
    campaignQuestions,
    'children.0.children',
    fieldsWithAnswers
  );
};

const getFieldWithAnswer = (
  campaignField,
  pageFields,
  phoneNumber,
  address,
) => {
  if (campaignField.type === nodeTypes.phone && phoneNumber) {
    return update.set(campaignField, 'answer', phoneNumber);
  }

  if (campaignField.type === nodeTypes.address && address) {
    return update.set(campaignField, 'answer', mapAddressModel(address));
  }

  const samePageField =
    isArrayWithItems(pageFields) &&
    pageFields.find((x) => x.identifier === campaignField.identifier);

  if (samePageField && samePageField.value) {
    let fieldWithAnswer = null;
    fieldWithAnswer = update.set(campaignField, 'answer', samePageField.value);

    if (campaignField.type === nodeTypes.checkbox) {
      fieldWithAnswer.additionalCheckboxInfo = getAdditionalCheckboxInfo(
        campaignField.props.options,
        samePageField.value
      );
    }

    return fieldWithAnswer;
  }

  return campaignField;
};

const getFieldWithReferenceAnswer = (campaignField, model) => {
  if (!campaignField.props.valueReference) {
    return campaignField;
  }

  const referenceValue = get(model, campaignField.props.valueReference);
  const fieldWithAnswer = update.set(campaignField, 'answer', referenceValue);

  return fieldWithAnswer;
};

const mapAddressModel = (address) => {
  const mappedModel = {
    ...address,
    postCode: address.postcode,
    suburb: address.city
  };

  const searchString = getSearchString(mappedModel);

  return {
    ...mappedModel,
    searchString
  };
};

const getAdditionalCheckboxInfo = (questionOptions, answer) => {
  if (answer) {
    const availableOptions = questionOptions.split('\n');
    // old model contains answer as a string, new in array
    const selectedOptions = isArrayWithItems(answer)
      ? answer[0].split(', ')
      : answer.split(', ');

    return availableOptions.map((option) => {
      return selectedOptions.some((x) => x === option);
    });
  }
};

export const getCustomFields = (formbuilderAnswers) => {
  if (!formbuilderAnswers || !hasFormbuilderQuestions(formbuilderAnswers)) {
    return null;
  }

  const customFields = formbuilderAnswers.children[0].children.map((item) => {
    if (
      item.type === nodeTypes.address ||
      item.type === nodeTypes.phone ||
      item.props.ignore
    ) {
      return null;
    }

    if (item.type === nodeTypes.consent) {
      return {
        identifier: item.identifier,
        value: item.content || 'I agree to the {url}'
      };
    }

    return {
      identifier: item.identifier,
      value: Array.isArray(item.answer) ? item.answer.join(',') : item.answer
    };
  });

  return customFields && customFields.length
    ? customFields.filter((x) => x !== undefined)
    : undefined;
};

export const getAddressModelFromFormbuilderAnswers = (formBuilderAnswers) => {
  if (!formBuilderAnswers || !hasFormbuilderQuestions(formBuilderAnswers)) {
    return undefined;
  }

  const formbuilderQuestions = getFormbuilderQuestions(formBuilderAnswers);
  const address = formbuilderQuestions.find(
    (x) => x.type === nodeTypes.address
  );

  if (!address || !address.answer) {
    return undefined;
  }

  const { suburb, street, state, postCode, country } = address.answer;

  return {
    city: suburb,
    street: street,
    state: state,
    postcode: postCode,
    country: country
  };
};

export const getPhoneNumberFromFormbuilderAnswers = (formBuilderAnswers) => {
  if (!formBuilderAnswers || !hasFormbuilderQuestions(formBuilderAnswers)) {
    return undefined;
  }

  const formbuilderQuestions = getFormbuilderQuestions(formBuilderAnswers);
  const phone = formbuilderQuestions.find((x) => x.type === nodeTypes.phone);

  if (!phone || !phone.answer) {
    return undefined;
  }

  return phone.answer;
};

export const getForbuilderAnswerByReference = (
  formBuilderAnswers,
  valueReference
) => {
  if (!formBuilderAnswers || !hasFormbuilderQuestions(formBuilderAnswers)) {
    return undefined;
  }

  const formbuilderQuestions = getFormbuilderQuestions(formBuilderAnswers);
  const field = formbuilderQuestions.find(
    (x) => x.props.valueReference === valueReference
  );

  if (!field) {
    return undefined;
  }

  return field.answer;
};

export const getFromBuilderInitialData = (key) => {
  return {
    key: 0,
    formKey: key,
    sections: [
      {
        key: 1,
        formKey: key,
        title: 'firstSection',
        fields: [],
        type: 'section'
      }
    ],
    type: 'root'
  };
};

export const needRemapFormbuilder = (formbuilderModel) => {
  if (!formbuilderModel) {
    return false;
  }

  return Object.keys(oldCustomFieldCategory)
    .map((key) => get(formbuilderModel, oldCustomFieldCategory[key]))
    .some((x) => x);
};

export const getNewFormBuilderModel = (formBuilderModel) => {
  let newformBuilderModel = formBuilderModel;

  Object.keys(oldCustomFieldCategory).forEach((key) => {
    const oldFieldCategoryPath = oldCustomFieldCategory[key];
    const oldQuestions = get(newformBuilderModel, oldFieldCategoryPath);

    if (oldQuestions) {
      const newFieldCategoryPath = customFieldCategory[key];

      newformBuilderModel = update.set(
        newformBuilderModel,
        newFieldCategoryPath,
        oldQuestions
      );
      newformBuilderModel = update.set(
        newformBuilderModel,
        oldFieldCategoryPath,
        undefined
      );
    }
  });

  return newformBuilderModel;
};

export const getQuestionsFromModel = (modelWithQuestions, fieldCategoryKey) => {
  if (!modelWithQuestions || !fieldCategoryKey) {
    return;
  }

  return (
    get(
      modelWithQuestions,
      `${generalFormBuilderPath}.${customFieldCategory[fieldCategoryKey]}`
    ) ||
    get(
      modelWithQuestions,
      `${generalFormBuilderPath}.${oldCustomFieldCategory[fieldCategoryKey]}`
    )
  );
};

export const manageFormbuilderGroups = (data, formbuilderKey) => {
  let formBuilder = get(data, formbuilderKey);

  if (formBuilder && hasFormbuilderQuestions(formBuilder)) {
    let groupIdentifier = '';

    formBuilder.children[0].children.forEach((item, index) => {
      if (item.type === nodeTypes.groupDivider) {
        groupIdentifier = item.identifier;
      } else {
        formBuilder = update.set(
          formBuilder,
          `children.0.children.${index}.props.groupIdentifier`,
          groupIdentifier
        );
      }
    });
  }

  return formBuilder;
};
