export const defaultAmountPlaceholders = [20, 50, 100, 150, 150, 500];
export const MAX_DONATION_AMOUNT_ITEMS_COUNT = 6;
export const MAX_SETUP_BUTTON_ITEMS_COUNT = 5;

export const SaleCouponAppliesTo = {
  TotalAmount: 'totalAmount'
};

export const SaleCouponDiscountType = {
  AsPercentage: 'asPercentage',
  AsFixedValue: 'asFixedValue'
};

export const usageLimitType = {
  Unlimited: 'Unlimited',
  ByCount: 'ByCount'
};

export const SaleCouponDiscountTypeArray = [
  {
    value: SaleCouponDiscountType.AsPercentage,
    getLabel: (value) => {
      return 'Percent (%)';
    }
  },
  {
    value: SaleCouponDiscountType.AsFixedValue,
    getLabel: (value) => {
      return `Dollars (${value})`;
    }
  }
];

export const SaleCouponUpperBound = {
  NoLimit: 'noLimit'
};
