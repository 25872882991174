import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as templateHelper from '../../template/templateHelper';
import { stringToArray, arrayToString } from '../../../helpers/arrayHelper';
import * as validationHelper from '../../../helpers/validationHelper';

import { Form } from '../../';
import { TemplateHeader } from '../../template/templateHeader';
import { Message } from 'semantic-ui-react';

import {
  fitnessActivityTypeOptions,
  fitnessMeasureOptions
} from '../../../pages/campaign/constants';
import { campaignContract } from '../../../pages/campaign/create/templates';

class FitnessActivitiesContainer extends Component {
  isFitnessActivityOptionChecked = (option) => {
    return stringToArray(this.props.value).some((item) => item === option);
  };

  handleFitnessActivityOptionChange = (value, evt, { checked }) => {
    const fitnessActivities = stringToArray(this.props.value);

    if (checked) {
      fitnessActivities.push(value);
    } else {
      const fitnessActivityIndex = fitnessActivities.findIndex(
        (item) => item === value
      );

      if (fitnessActivityIndex !== -1) {
        fitnessActivities.splice(fitnessActivityIndex, 1);
      }
    }

    const visibleTargets = this.props.targets.filter((item) =>
      fitnessMeasureOptions.some(
        (option) =>
          item.key === option.value && option.isVisible(fitnessActivities)
      )
    );
    const trackedStatistics = stringToArray(
      this.props.trackedStatistics
    ).filter((item) =>
      fitnessMeasureOptions.some(
        (option) => item === option.value && option.isVisible(fitnessActivities)
      )
    );
    const visibleThermometersToShow = this.props.thermometersToShow.filter(
      (item) =>
        fitnessMeasureOptions.some(
          (option) =>
            item === option.value && option.isVisible(fitnessActivities)
        )
    );
    const visibleLeaderboardsToShow = this.props.leaderboardsToShow.filter(
      (item) =>
        fitnessMeasureOptions.some(
          (option) =>
            item === option.value && option.isVisible(fitnessActivities)
        )
    );

    this.props.onChange(this.props.path, arrayToString(fitnessActivities));
    this.props.onChange(campaignContract.fitnessTargets, visibleTargets);
    this.props.onChange(
      campaignContract.fitnessAllowedTrackedStatistics,
      arrayToString(trackedStatistics)
    );
    this.props.onChange(
      campaignContract.fitnessThermometersToShow,
      visibleThermometersToShow
    );
    this.props.onChange(
      campaignContract.fitnessLeaderboardsToShow,
      visibleLeaderboardsToShow
    );
  };

  getFitnessActivityOptions = () => {
    return (
      <div className="fitnessActivityOptions">
        {fitnessActivityTypeOptions.map((option) => {
          const isChecked = this.isFitnessActivityOptionChecked(option.value);

          return (
            <Form.Checkbox
              key={option.key}
              inputLabel={I18n.t(
                `template.page.content.fitness.fitnessAllowedTypes.options.${option.i18nKey}`
              )}
              checked={isChecked}
              onChange={this.handleFitnessActivityOptionChange.bind(
                this,
                option.value
              )}
            />
          );
        })}
      </div>
    );
  };

  render() {
    const fitnessOptions = this.getFitnessActivityOptions();

    return (
      <React.Fragment>
        <TemplateHeader
          as="h4"
          label="template.page.content.fitness.fitnessAllowedTypes.label"
        />
        {fitnessOptions}
        <Message negative hidden={this.props.isValid}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    targets:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessTargets
      ) || [],
    trackedStatistics: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.fitnessAllowedTrackedStatistics
    ),
    thermometersToShow:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessThermometersToShow
      ) || [],
    leaderboardsToShow:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessLeaderboardsToShow
      ) || [],
    isValid: validationHelper.isValid(ownProps.validation, value),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      value
    )
  };
};

const FitnessActivities = connect(mapState)(FitnessActivitiesContainer);
export default FitnessActivities;
