import React from 'react';
import { map, values, pipe } from 'ramda';
import PropTypes from 'prop-types';

import { Modal, Form, Button } from 'semantic-ui-react';
import WizardRadioFieldWithImage from './wizardComponents/wizardRadioWithImage';

import { componentTypes, nodeTypes } from '../typeDefinitions';
import { I18n } from 'react-redux-i18n';

/**
 * Modal to select Component to be added to the current section.
 * Renders buttons for all possible Component types.
 * @param {boolean} open - the modal is open
 * @param {function()} closeModal - used to close the modal
 * @param {function(type:string)} addComponentToCurrentSection - adds Component to Section.
 * @constructor
 */
class ComponentSelectorModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      componentType: ''
    };
  }

  onTypeChange = (type) => {
    this.setState({ componentType: type });
  };

  onInsertClick = () => {
    this.props.addComponentToCurrentSection(this.state.componentType);
    this.props.closeModal();
    this.setState({ componentType: '' });
  };

  render() {
    const { componentType } = this.state;

    return (
      <div>
        <Modal
          className="wizard"
          size="tiny"
          dimmer="inverted"
          onClose={this.props.closeModal}
          open={this.props.open}
        >
          <Modal.Header>
            <div className="button-wrapper align-right">
              <Button
                icon="close"
                circular
                color="grey"
                compact
                size="mini"
                onClick={this.props.closeModal}
                className="close"
              />
            </div>
            Select a question type
          </Modal.Header>
          <Modal.Content>
            <Form>
              <div className="wizard-items-with-image-container">
                {pipe(
                  map((type) => {
                    let allowAdditionalPhoneNumber = false;

                    if (this.props.isPhoneHidden) {
                      allowAdditionalPhoneNumber = true;
                    }

                    const isDisabled =
                      (nodeTypes.phone === type &&
                        this.props.isPhoneDisabled) ||
                      (nodeTypes.address === type &&
                        this.props.isAddressDisabled) ||
                      (allowAdditionalPhoneNumber
                        ? nodeTypes.additionalPhoneNumber === type &&
                          this.props.isPhoneDisabled
                        : nodeTypes.additionalPhoneNumber === type &&
                          !this.props.isPhoneDisabled);

                    const isHidden =
                      (nodeTypes.phone === type && this.props.isPhoneHidden) ||
                      (nodeTypes.address === type &&
                        this.props.isAddressHidden) ||
                      (nodeTypes.country === type &&
                        !this.props.isCountryAllowed) ||
                      nodeTypes.targetAmount === type;

                    if (isHidden) {
                      return <React.Fragment />;
                    }

                    return (
                      <div className="wizard-item-with-image">
                        <WizardRadioFieldWithImage
                          key={type}
                          value={type}
                          label={I18n.t(`components.types.${type}`)}
                          checked={componentType === type}
                          disabled={isDisabled}
                          onClick={this.onTypeChange}
                        />
                      </div>
                    );
                  }),
                  values
                )(componentTypes)}
              </div>
              <div className="button-wrapper align-center">
                <Button
                  id="insertButton"
                  primary
                  disabled={!componentType}
                  onClick={this.onInsertClick}
                >
                  Insert
                </Button>
              </div>
            </Form>
          </Modal.Content>
        </Modal>
      </div>
    );
  }
}

ComponentSelectorModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  addComponentToCurrentSection: PropTypes.func
};

export default ComponentSelectorModal;
