import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import currencyHelper from '../../../../helpers/currencyHelper';

export const DonationPlatformFeeSectionRow = (props) => {
  const { title, data } = props;

  const currency = !props.displayOrgCurrency
    ? get(data, 'currency')
    : get(data, 'organization.currency');
  const feeDetails = get(data, 'financials.feeDetail');  
  const fee = props.displayOrgCurrency
    ? get(feeDetails, 'fee') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'fee');
  const feeTax = props.displayOrgCurrency
    ? get(feeDetails, 'feeTax') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'feeTax');

  const feeString = currencyHelper.formatCurrency(fee, currency);
  const feeTaxString = currencyHelper.formatCurrency(feeTax, currency);

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${feeString} (includes ${feeTaxString} tax)`}</span>
          <span>Paid by donor</span>
        </div>
      </div>
    </React.Fragment>
  );
};
