import React, { Component } from 'react';
import { Form } from '..';
import * as validationHelper from '../../helpers/validationHelper';

class ValidatedTextArea extends Component {
  state = {
    applyValidation: false
  };

  onBlur = () => {
    this.setState({ applyValidation: true });
  };

  render() {
    let validationErrorMessage = '';
    const { validation, showValidationIcon, error, ...pprops } = this.props;
    if (this.state.applyValidation) {
      validationErrorMessage = validationHelper.getValidationErrorMessage(
        validation,
        pprops.value
      );
    }

    return (
      <Form.TextArea
        {...pprops}
        showValidationIcon={showValidationIcon && this.state.applyValidation}
        onBlur={this.onBlur}
        placeholder={this.props.placeholder}
        validationErrorMessage={validationErrorMessage}
        required={validation && validation.required}
        error={this.state.applyValidation && error}
      />
    );
  }
}

export default ValidatedTextArea;
