import moment from 'moment';

import {
  timeLapse as timeLapseConstant,
  timeLapseOptions
} from '../pages/dashboard/constants';
import config from '../constants';

export const getCurrentDay = () => {
  return new Date();
};

export const substractDays = (day, value) => {
  return day.setDate(day.getDate() - value);
};

export const daysBetween = (date1, date2) => {
  const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date1);
  const secondDate = new Date(date2);
  return Math.round(Math.abs((firstDate - secondDate) / oneDay));
};

export const getTimePeriod = (
  createdAt,
  reportByDateAndMetricType,
  timeLapse,
  timeLapseFrom = null,
  timeLapseTo = null
) => {
  let dateTo = null;
  let dateFrom = null;

  if (timeLapse !== timeLapseConstant[reportByDateAndMetricType].customRange) {
    dateTo = getCurrentDay();
    dateFrom = timeLapseOptions[reportByDateAndMetricType][timeLapse].value
      ? substractDays(
          getCurrentDay(),
          timeLapseOptions[reportByDateAndMetricType][timeLapse].value
        )
      : new Date(createdAt);
  } else {
    dateTo =
      timeLapseTo && moment(timeLapseTo).isValid()
        ? timeLapseTo
        : getCurrentDay();
    dateFrom =
      timeLapseFrom && moment(timeLapseFrom).isValid()
        ? timeLapseFrom
        : new Date(createdAt);
  }

  return {
    dateFrom: moment(dateFrom).format(config.DEFAULT_DATE_FORMAT),
    dateTo: moment(dateTo).format(config.DEFAULT_DATE_FORMAT)
  };
};

export const convertDateAndTimeToDateString = (date, time) => {
  if (!date) {
    return time;
  }

  return `${date}T${time ? time : '00:00'}:00.000`;
};

// Pass in the number of years to go back (default is current year)
export const getCurrentFinancialYear = function (yearsBack) {
  var financialYearStart, financialYearEnd;
  if (moment().quarter() > 2) {
    financialYearStart = moment().month('July').startOf('month'); // this year's July
    financialYearEnd = moment().add(1, 'years').month('June').endOf('month'); // next year's June
  } else {
    financialYearStart = moment()
      .subtract(1, 'years')
      .month('July')
      .startOf('month'); // last year's July
    financialYearEnd = moment().month('June').endOf('month'); // this year's June
  }

  if (!yearsBack) {
    financialYearStart = financialYearStart.subtract(yearsBack, 'years');
    financialYearEnd = financialYearEnd.subtract(yearsBack, 'years');
  }

  return { start: financialYearStart, end: financialYearEnd };
};
