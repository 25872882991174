import React from 'react';
import config from '../../constants';

const InjectReCaptcha = (ComposedComponent) => {
  class hoc extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        inited: false
      };
    }

    handleLoaded = () => {
      this.setState({
        inited: true
      });
    };

    componentDidMount() {
      if (config.recaptcha_site_key) {
        const script = document.createElement('script');
        script.src = `https://www.google.com/recaptcha/api.js?render=${config.recaptcha_site_key}`;
        script.addEventListener('load', this.handleLoaded);
        document.body.appendChild(script);
      }
    }

    render() {
      return (
        <React.Fragment>
          {this.state.inited && <ComposedComponent {...this.props} />}
        </React.Fragment>
      );
    }
  }

  return hoc;
};

export default InjectReCaptcha;
