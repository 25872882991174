import get from 'lodash.get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';
import { TemplatePage } from '../../components/common/pager';
import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';
import { TemplateAutocomplete } from '../../components/template/templateAutocomplete';
import { TemplateCheckbox } from '../../components/template/templateCheckbox';
import { TemplateCurrency } from '../../components/template/templateCurrency';
import { TemplateDropdown } from '../../components/template/templateDropdown';
import { TemplateFormBuilderAnswers } from '../../components/template/templateFormBuilderAnswers';
import { TemplateHeader } from '../../components/template/templateHeader';
import * as templateHelper from '../../components/template/templateHelper';
import { TemplateHorizontalRule } from '../../components/template/templateHorizontalRule';
import { TemplateInput } from '../../components/template/templateInput';
import { TemplateRadioGroup } from '../../components/template/templateRadioGroup';
import { TemplateTextArea } from '../../components/template/templateTextArea';
import {
  generalFormBuilderPath,
  oldCustomFieldCategory
} from '../../constants/formBuilder';
import config from '../../constants/index';
import { pageTypesStrings } from '../../constants/pageTypes';
import { TARGET_VALIDATION } from '../../constants/validationModels';
import { getCustomFieldsWithAnswers } from '../../helpers/formBuilderHelper';
import { reset, searchEntities } from '../../modules/autocomplete';
import { Record } from '../../modules/bin/utility';
import * as templateBuilderActions from '../../modules/template-builder-ex';
import {
  displayNameKeys,
  displayNameOptions,
  donationQuestionFormIds,
  paths
} from './constants';
import { deleteReply, updateReply } from './store/donationActions';
import {
  addDonationFullModel,
  addDonationSimplyfiedModel
} from './validationModels';

class DonationTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.emailValidation = {
      required: false,
      email: true
    };

    this.formBuilderAnswers = {
      formBuilderRequired: true,
      formBuilderAnswers: true
    };

    this.optional = {
      required: false
    };

    this.amount = {
      required: true,
      ...TARGET_VALIDATION
    };

    this.state = { replyAction: 'Edit', currency: props.currency };
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  onCampaignChange = (path, value, item) => {
    if (value) {
      this.props.resetAutocomlite('pageId');
      this.props.showControl('pageId');
      this.props.searchEntities('page', 'pageId', this.getPageRequest(value));
    } else {
      this.props.hideControl('pageId');
    }

    const questions = this.getQuestions(item);
    this.onChange(paths.DONATION_FORMBUILDER, questions);
    this.onChange(paths.CURRENCY, item.organization.currency);
    this.onChange(path, value, item);
  };

  getQuestions = (campaignItem) => {
    const campaignQuestions =
      get(campaignItem, paths.DONATION_FORMBUILDER) ||
      get(
        campaignItem,
        `${generalFormBuilderPath}.${oldCustomFieldCategory.DONATION}`
      );

    return getCustomFieldsWithAnswers(
      campaignQuestions,
      this.props.donationQuestions,
      this.props.phoneNumber,
      this.props.address
    );
  };

  onPageChange = (path, value, item) => {
    if (value) {
      const separatorIndex = value.indexOf('_');
      const id = value.substring(separatorIndex + 1, value.length);
      const type = value.substring(0, separatorIndex);

      if (type === pageTypesStrings.FUNDRAISER) {
        this.props.clearControl(paths.TEAM_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.FUNDRAISER_ID, id);
      } else if (type === pageTypesStrings.TEAM) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.TEAM_ID, id);
      } else if (type === pageTypesStrings.COLLECTION) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.TEAM_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.COLLECTION_ID, id);
      }
    } else {
      this.props.clearControl(paths.FUNDRAISER_ID);
      this.props.clearControl(paths.PAGE_TYPE);
      this.props.clearControl(paths.TEAM_ID);
      this.props.clearControl(paths.COLLECTION_ID);
      this.onChange(paths.PAGE_TYPE, 'campaign');
    }

    this.onChange(path, value);
  };

  getCampaignItem = (item) => {
    if (item.organization) {
      return {
        text: item.name,
        subtext: `${item.organization.name}`,
        value: item.id
      };
    } else {
      return {
        text: item.name,
        value: item.id
      };
    }
  };

  getCampaignRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'status',
          operator: 'EqualTo',
          value: 1
        }
      ]
    };
  };

  getPageItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value:
        item.type +
        '_' +
        (item.fundraiserId || item.teamId || item.collectionId)
    };
  };

  getPageRequest = (campaignId) => {
    if (!this.props.campaignId && !campaignId) {
      return;
    }
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'campaignId',
          operator: 'EqualTo',
          value: campaignId || this.props.campaignId
        },
        {
          key: 'isCollectionOrFundraiserOrTeam',
          operator: 'EqualTo',
          value: 'true'
        }
      ]
    };
  };

  getValidationFields = (key) => {
    return get(this.getValidationModel(), key);
  };

  getValidationModel = () => {
    return this.props.sendEmailCheckboxValue
      ? addDonationFullModel
      : addDonationSimplyfiedModel;
  };

  getDisplayNameOptions = () => {
    return displayNameOptions.map((x) => {
      return {
        key: x.key,
        text: I18n.t(
          `template.donation.content.detail.displayName.options.${x.translation}`
        ),
        value: x.value
      };
    });
  };

  handleDisplayNameOptionChange = (path, value) => {
    // get data
    const displayNameOption = displayNameOptions.find((x) => x.key === value);
    const visOption = displayNameOption.key === displayNameKeys.asCustomName;
    // set state
    this.onChange('customer.anonymous', displayNameOption.isAnonymous);
    this.onChange('displayNameOption', displayNameOption.displayName);
    this.onChange('displayName', null);
    this.props.changeItemVisibility('displayName', visOption, true);
    this.onChange(path, value);
  };

  handleChange = (e, { value }) => {
    this.setState({ replyAction: value });
  };

  handleReplyRadioChange = (path, value) => {
    this.setState({ replyAction: value });
  };

  onReplySaveClick = () => {
    if (this.state.replyAction === 'Edit') {
      this.props.updateReply(this.props.donationId);
    } else if (this.state.replyAction === 'Delete') {
      this.props.deleteReply(this.props.donationId);
      this.props.setResultValue('reply', null);
    }
  };

  onReplyCancelClick = () => {
    this.setState({ replyAction: null });
    this.props.setResultValue('reply.reply', this.props.originalReplyMessage);
  };

  getReplyOptions = () => [
    {
      value: 'Edit',
      label: 'Edit'
    },
    {
      value: 'Delete',
      label: 'Delete'
    }
  ];

  render() {
    const replyOptions = this.getReplyOptions();

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage
          id="donation-detail"
          i18nKey="template.donation.content.detail.header"
        >
          <TemplateHeader
            as="h3"
            label="template.donation.content.detail.header"
          />

          <TemplateAutocomplete
            id="donation-campaign"
            entity="page/campaign"
            path="campaignId"
            selected="campaign"
            label="template.donation.content.detail.campaign.label"
            placeholder="template.donation.content.detail.campaign.placeholder"
            validation={this.required}
            onChange={this.onCampaignChange}
            request={this.getCampaignRequest}
            getItem={this.getCampaignItem}
          />

          <TemplateAutocomplete
            id="donation-page"
            entity="page"
            path="pageId"
            selected="page"
            label="template.donation.content.detail.page.label"
            placeholder="template.donation.content.detail.page.placeholder"
            onChange={this.onPageChange}
            request={this.getPageRequest}
            getItem={this.getPageItem}
          />

          <TemplateCurrency
            id="donation-amount"
            path="amount"
            validation={this.amount}
            label="template.donation.content.detail.amount.label"
            allowDecimal
            onChange={this.onChange}
            currency={this.props.currency}
          />

          <TemplateTextArea
            id="donationComment"
            path="message"
            label="template.donation.content.detail.comment.label"
            validation={this.optional}
            onChange={this.onChange}
            maxLength={config.MAX_DONATION_COMMENT_LENGTH}
          />

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.donation.content.detail.header"
          />

          <TemplateInput
            id={donationQuestionFormIds.DONOR_FIRST_NAME}
            path="customer.firstName"
            label="general.firstName"
            placeholder="general.firstName"
            onChange={this.onChange}
            subtext={I18n.t('errors.validation.max-chars', { maxValue: 25 })}
            validation={{
              ...this.getValidationFields(
                donationQuestionFormIds.DONOR_FIRST_NAME
              ),
              length: 25,
              maxLength: true
            }}
          />

          <TemplateInput
            id={donationQuestionFormIds.DONOR_LAST_NAME}
            path="customer.lastName"
            label="general.lastName"
            placeholder="general.lastName"
            subtext={I18n.t('errors.validation.max-chars', { maxValue: 25 })}
            onChange={this.onChange}
            validation={{
              ...this.getValidationFields(
                donationQuestionFormIds.DONOR_LAST_NAME
              ),
              length: 25,
              maxLength: true
            }}
          />

          <TemplateInput
            id={donationQuestionFormIds.EMAIL}
            path="customer.email"
            label="general.emailAddress"
            placeholder="general.emailAddress"
            subtext={I18n.t('errors.validation.max-chars', { maxValue: 100 })}
            validation={{
              ...this.getValidationFields(donationQuestionFormIds.EMAIL),
              length: 100,
              maxLength: true
            }}
            onChange={this.onChange}
          />

          <TemplateInput
            id="businessName"
            path="customer.businessName"
            label="general.businessName"
            placeholder="general.businessName"
            subtext={I18n.t('errors.validation.max-chars', { maxValue: 100 })}
            validation={{
              ...this.getValidationFields(
                donationQuestionFormIds.DONOR_BUSINESS_NAME
              ),
              length: 100,
              maxLength: true
            }}
            onChange={this.onChange}
          />

          <TemplateFormBuilderAnswers
            id="templateFormBuilderAnswers"
            path={paths.DONATION_FORMBUILDER}
            onChange={this.onChange}
            validation={this.formBuilderAnswers}
          />

          <TemplateDropdown
            id="selectedDisplayNameOption"
            path="selectedDisplayNameOption"
            label="template.donation.content.detail.displayName.label"
            onChange={this.handleDisplayNameOptionChange}
            options={this.getDisplayNameOptions()}
          />

          <TemplateInput
            id="displayName"
            path="displayName"
            label="template.donation.content.detail.displayName.input.label"
            placeholder="template.donation.content.detail.displayName.input.placeholder"
            onChange={this.onChange}
            subtext={I18n.t('errors.validation.max-chars', { maxValue: config.DISPLAY_NAME_MAX_LENGTH })}
            validation={{
              ...this.required,
              length: config.DISPLAY_NAME_MAX_LENGTH,
              maxLength: true
            }}
          />

          <TemplateHorizontalRule path="issueReceipt" />

          <TemplateHeader
            as="h3"
            path="issueReceipt"
            label="template.donation.content.detail.receipt.label"
          />

          <TemplateCheckbox
            id="sendEmail"
            path="issueReceipt"
            inputLabel="template.donation.content.detail.send-email.label"
            subtext="template.donation.content.detail.send-email.subtext"
            onChange={this.onChange}
          />
        </TemplatePage>

        {this.props.isEdit && (
          <TemplatePage
            id="reply"
            i18nKey="template.donation.content.reply.header"
          >
            <div path={'reply'} id="reply">
              <TemplateRadioGroup
                value={this.state.replyAction}
                onChange={this.handleReplyRadioChange}
                options={replyOptions}
              />

              {this.state.replyAction === 'Edit' && (
                <TemplateTextArea
                  id="replyComment"
                  path="reply.reply"
                  label="template.donation.content.reply.comment.label"
                  validation={this.optional}
                  onChange={this.onChange}
                  maxLength={config.MAX_REPLY_COMMENT_LENGTH}
                />
              )}

              <Button
                primary
                onClick={this.onReplySaveClick}
                loading={Record.isRecordLoading(this.props.donationReplyRecord)}
                disabled={
                  !this.state.replyAction ||
                  (!this.props.templateReplyMessage &&
                    this.state.replyAction === 'Edit')
                }
              >
                Save
              </Button>
              <Button
                onClick={this.onReplyCancelClick}
                disabled={!this.state.replyAction}
              >
                Cancel
              </Button>
            </div>
          </TemplatePage>
        )}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  const donationReplyRecord = get(state, `donation.donationReplyRecord`);
  const campaignQuestions = get(
    state,
    `donation.record.data.data.campaign.${paths.DONATION_FORMBUILDER}`
  );
  const donationId = get(state, `donation.record.data.data.id`);
  const originalReplyMessage = get(
    state,
    `donation.record.data.data.reply.reply`
  );
  const templateReplyMessage = templateHelper.getValue(
    state.templateBuilderEx,
    'reply.reply'
  );
  const donationAnswers = get(state, `donation.record.data.data.fields`);
  const phoneNumber = get(state, `donation.record.data.data.phoneNumber`);
  const donationCurrency = get(state, `donation.record.data.data.currency`);
  const campaignCurrency = get(state.templateBuilderEx, 'data.currency');
  const currency = donationCurrency ? donationCurrency : campaignCurrency;
  const address = get(state, `donation.record.data.data.address`);  
  const donationQuestions = getCustomFieldsWithAnswers(
    campaignQuestions,
    donationAnswers,
    phoneNumber,
    address
  );
  const sendEmailCheckboxValue = templateHelper.getValue(
    state.templateBuilderEx,
    'issueReceipt'
  );
  const isEdit = get(state.templateBuilderEx, 'isEdit');

  return {
    campaignId: state.templateBuilderEx.data.campaignId,
    donationQuestions,
    sendEmailCheckboxValue,
    phoneNumber,
    address,
    donationId,
    originalReplyMessage,
    templateReplyMessage,
    donationReplyRecord,
    currency,
    isEdit
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    resetAutocomlite(path) {
      dispatch(reset(path));
    },
    searchEntities(entity, path, request) {
      dispatch(searchEntities(entity, path, request));
    },
    updateReply(id) {
      dispatch(updateReply(id));
    },
    deleteReply(id) {
      dispatch(deleteReply(id));
    }
  };
};

const DonationTemplate = withRouter(
  connect(mapState, mapDispatch)(DonationTemplateView)
);
export default DonationTemplate;
