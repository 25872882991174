import React from 'react';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
  useQueryClient,
  useMutation
} from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../store';
import Constants from '../../../../../constants';
import { searchMerchandise } from './search.query';
import { IMerchandise } from '../merchandise.model';
import { updateMerchandise, updateMerchandiseSku } from './update.mutation';
import { createMerchandise, createMerchandiseSku } from './create.mutation';
import { removeMerchandise, removeMerchandiseSku } from './remove.mutation';
import updater from '../../../../../helpers/update';

const queryClient = new QueryClient();

export const MerchandiseApiProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

const MerchandiseQueries = {
  Search: 'merchandise_search'
};

export function useMerchandiseApi() {
  const queryClient = useQueryClient();
  const { apikey = '' } = Constants;
  const sessionKey = useSelector<RootState, string>(
    (state) => state.session.key
  );
  const campaign = useSelector<RootState, any>(
    (state) => state.templateBuilderEx.data
  );
  const [isSubmitting, setSubmitting] = React.useState(false);

  // merchandise list

  const search = React.useCallback(
    async () =>
      await searchMerchandise(apikey, sessionKey, {
        campaignId: campaign.id
      }),
    [apikey, sessionKey, campaign]
  );

  const { data, error, isLoading } = useQuery(
    MerchandiseQueries.Search,
    search
  );
  // temp sku filter till API is updated for this use case
  const list = (data?.data?.list || [])
    .map((item) => ({
      ...item,
      skus: (item.skus || [])
        .filter((sku) => sku.enabled === true)
        .sort(
          (a: any, b: any) =>
            new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
        )
    }))
    .sort(
      (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

  // merchandise create

  const create = React.useCallback(
    async (item: IMerchandise) => {
      setSubmitting(true);
      const { data, error } = await createMerchandise(apikey, sessionKey, {
        ...item,
        campaignId: campaign.id
      });

      if (data && data.id && !error) {
        const id = data.id;
        const skus = item.skus || [];

        for (let i = 0; i < skus.length; i++) {
          const sku = skus[i];
          await createMerchandiseSku(
            apikey,
            sessionKey,
            id,
            updater.set(sku, 'template.value.index', i)
          );
        }
      }

      setSubmitting(false);
    },
    [apikey, sessionKey, campaign]
  );

  // merchandise update

  const update = React.useCallback(
    async (item: IMerchandise) => {
      if (item?.id) {
        setSubmitting(true);
        const { data, error } = await updateMerchandise(
          apikey,
          sessionKey,
          item.id,
          item
        );

        if (data && data.id && !error) {
          const skus = item.skus || [];
          const current = list.find(({ id }) => id === item.id);
          const currentSkus = current?.skus || [];
          const removeList = currentSkus.filter(
            ({ id }) => !skus.some((sku) => sku.id === id)
          );

          for (const sku of removeList) {
            if (sku.id)
              await removeMerchandiseSku(apikey, sessionKey, item.id, sku.id);
          }

          for (let i = 0; i < skus.length; i++) {
            const sku = skus[i];
            if (sku.id) {
              await updateMerchandiseSku(
                apikey,
                sessionKey,
                item.id,
                sku.id,
                updater.set(sku, 'template.value.index', i)
              );
            } else {
              await createMerchandiseSku(
                apikey,
                sessionKey,
                item.id,
                updater.set(sku, 'template.value.index', i)
              );
            }
          }
        }

        setSubmitting(false);
      }
    },
    [apikey, sessionKey, list]
  );

  // merchandise remove

  const remove = React.useCallback(
    async (item: IMerchandise) => {
      setSubmitting(true);

      const skus = item?.skus || [];
      if (item?.id) {
        for (const sku of skus) {
          if (sku.id)
            await removeMerchandiseSku(apikey, sessionKey, item.id, sku.id);
        }
        await removeMerchandise(apikey, sessionKey, item.id);
      }

      setSubmitting(false);
    },
    [apikey, sessionKey, list]
  );

  // Mutations

  const createMutation = useMutation(create, {
    onSuccess: () => {
      queryClient.invalidateQueries(MerchandiseQueries.Search);
    }
  });

  const updateMutation = useMutation(update, {
    onSuccess: () => {
      queryClient.invalidateQueries(MerchandiseQueries.Search);
    }
  });

  const removeMutation = useMutation(remove, {
    onSuccess: () => {
      queryClient.invalidateQueries(MerchandiseQueries.Search);
    }
  });

  return {
    list,
    loading: isLoading,
    submitting: isSubmitting,
    create: createMutation.mutate,
    update: updateMutation.mutate,
    remove: removeMutation.mutate
  };
}
