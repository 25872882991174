export const eventColumns = {
  NAME: 'name',
  TARGET: 'targetAmount',
  RAISED: 'raisedAmount',
  ORGANIZATIONS: 'organizationsCount',
  ACTIVE_ORGANIZATIONS: 'organizationsActiveCount',
  FUNDRAISERS: 'fundraisersCount',
  ACTIVE_FUNDRAISERS: 'fundraisersActiveCount',
  AVERAGE_DONATIONS: 'averageDonationAmount',
  NUMBER_OF_DONATIONS: 'donationCount',
  DONATIONS_NEEDED: 'additionalDonationsNeededForTarget',
  COUNTRY: 'timeAndPlace.place.country',
  STATE: 'timeAndPlace.place.state',
  YEAR: 'timeAndPlace.time.year'
};

export const i18nKeys = {
  PAGE_TITLE: 'event.title',
  CREATE_BUTTON: 'event.create',
  DASHBOARD_ACTION: 'event.dashboard-action',
  EDIT_ACTION: 'event.edit-action',
  VIEW_ACTION: 'event.view-action',
  ORGANIZATIONS_ACTION: 'event.organizations-action',
  FUNDRAISERS_ACTION: 'event.fundraisers-action',
  DONATIONS_ACTION: 'event.donations-action',
  CLONE_ACTION: 'event.clone-action',
  ACTIVE_INTEGRATION: 'event.active-integration-action',
  VIEW_LABEL: 'event.view.label',
  VIEW_EDIT_BUTTON: 'event.view.edit',
  VIEW_VISIT_BUTTON: 'event.view.visit',
  VIEW_EXPORT_BUTTON: 'event.view.export',
  VIEW_ADD_OFFLINE_DONATION_BUTTTON: 'event.view.add-offline-donation',
  ORGANIZATIONS_TAB: 'event.view.tabs.organizations',
  DONATIONS_TAB: 'event.view.tabs.donations',
  FUNDRAISERS_TAB: 'event.view.tabs.fundraisers',
  BACK_OFFICE: 'components.common.back-office'
};

export const paths = {
  GRID_TAB_FILTER: 'gridTab'
};

export const eventGridTabs = {
  ORGANIZATIONS: {
    key: 0,
    label: 'organizations'
  },
  FUNDRAISERS: {
    key: 1,
    label: 'fundraisers'
  },
  DONATIONS: {
    key: 2,
    label: 'donations'
  }
};

export const ONLINE_DONATION_TYPE = 'online';
export const OFFLINE_DONATION_TYPE = 'offline';

export const eventStatuses = {
  LIVE: {
    description: 'Live',
    value: 1
  },
  COMPLETED: {
    description: 'Complete',
    value: 2
  },
  ARCHIVED: {
    description: 'Archived',
    value: 3
  }
};

export const eventOccurrenceType = {
  DATE: 1,
  DATE_WITH_TIME: 2
};

export const tiersEnum = {
  0: 'primary',
  1: 'secondary',
  2: 'tertiary',
  3: 'quaternary'
};

export const eventAvailableFiltersKeys = [
  'timeAndPlace.place.country',
  'timeAndPlace.place.state',
  'timeAndPlace.time.year',
  'createdAtLocal'
];
export const eventCampaignAvailableFiltersKeys = [
  'targetAmount',
  'raisedAmount',
  'averageDonationAmount',
  'additionalDonationsNeededForTarget',
  'pagesCount',
  'activePagesCount',
  'donationCount',
  'createdAtLocal',
  'type',
  'eventTier'
];
export const eventFundraisingAvailableFilters = [
  'campaignId',
  'teamId',
  'fundraiserId',
  'type',
  'raisedAmount',
  'averageDonationAmount',
  'donationCount',
  'createdAtLocal',
  'isActive'
];
export const eventDonationsAvailableFilters = [
  'campaignId',
  'teamId',
  'fundraiserId',
  'donatedAtLocal',
  'amount',
  'type',
  'financials.refunded',
  'paymentPlatform.transferId'
];
export const eventOrganizationTiersAvailableFilters = ['tier'];

export const externalIntegrationSystem = {
  active: 'active',
  director: 'director',
  registerNow: 'registerNow',
  eventPlus: 'eventPlus',
  onlineEntries: 'onlineEntries',
  pinkPanda: 'pinkPanda',
  raceRoster: 'raceRoster',
  njuko: 'njuko'
};

export const externalIntegrationSystemOptions = [
  {
    value: null,
    text: ''
  },
  {
    value: externalIntegrationSystem.active,
    text: 'Active'
  },
  {
    value: externalIntegrationSystem.director,
    text: 'Director'
  },
  {
    value: externalIntegrationSystem.registerNow,
    text: 'Register Now'
  },
  {
    value: externalIntegrationSystem.eventPlus,
    text: 'Event Plus'
  },
  {
    value: externalIntegrationSystem.onlineEntries,
    text: 'Online Entries'
  },
  {
    value: externalIntegrationSystem.pinkPanda,
    text: 'Pink Panda'
  },
  {
    value: externalIntegrationSystem.raceRoster,
    text: 'Race Roster'
  },
  {
    value: externalIntegrationSystem.njuko,
    text: 'njuko'
  }
];
