import React from 'react';
import Styles from './fundraising-resource-item.module.css';
import { Button, Checkbox, Form } from 'semantic-ui-react';
import { entityTypes } from '../../../../../constants/imageUpload';
import { get } from 'lodash';
import { I18n } from 'react-redux-i18n';
import { FileUploader, ImageUploader } from './fundraising-resource-upload';
import { PDFDeliveryTypeOptions } from '../../../../../components/common/aboutElementsBuilder/constants';

const i18Key = 'template.page.content.fundraising.resources';
const LabelPaths = {
  FileUrlLabel: `${i18Key}.file.label`,
  NameLabel: `${i18Key}.name.label`,
  NamePlaceholder: `${i18Key}.name.placeholder`,
  DescriptionLabel: `${i18Key}.description.label`,
  DescriptionPlaceholder: `${i18Key}.description.placeholder`,
  PlaceholderLabel: `${i18Key}.placeholder.label`,
  DeleteButton: `${i18Key}.buttons.delete`,
  SaveButton: `${i18Key}.buttons.save`
};
const ResourcePaths = {
  Id: 'id',
  Name: 'name',
  Description: 'description',
  Placeholder: 'placeholder',
  ResourceType: 'resourceType',
  Resource: 'resource',
  ResourceDelivryType: 'deliveryType',
  ResourceUrl: 'resource.url'
};

export const ResourceTypeOptions = [
  {
    label: 'Upload file',
    value: 'upload'
  },
  {
    label: 'Add external link',
    value: 'link'
  }
] as const;
export type ResourceTypeOption = (typeof ResourceTypeOptions)[number];
export type ResourceType = ResourceTypeOption['value'];

interface FundraisingResourceEditItemEdit<Value = any> {
  index: number;
  values: Value;
  onChange?(index: number, path: string, values: Value): void;
  onDelete?(index: number): void;
  onClose?(): void;
}

export const FundraisingResourceEditItem: React.FunctionComponent<
  FundraisingResourceEditItemEdit
> = (props) => {
  const resourceType = get<ResourceType>(
    props.values,
    ResourcePaths.ResourceType,
    ResourceTypeOptions[0].value || 'upload'
  );
  const deliveryType = get<string>(
    props.values,
    ResourcePaths.ResourceDelivryType,
    PDFDeliveryTypeOptions[0].value || 'downloadToDevice'
  );
  const resource = get(props.values, ResourcePaths.Resource);

  const handleOnChange = React.useCallback(
    (path: string, value: any) => {
      if (props.onChange) props.onChange(props.index, path, value);
    },
    [props.index, props.onChange]
  );

  const handleOnDelete = React.useCallback(() => {
    if (props.onDelete) props.onDelete(props.index);
  }, [props.index, props.onChange]);

  const handleOnUploadChange = React.useCallback(
    (value: any) => {
      handleOnChange(ResourcePaths.Resource, value);
    },
    [handleOnChange]
  );
  const handleOnResourceUrlChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, { value }) => {
      if (typeof value === 'string') {
        handleOnChange(ResourcePaths.Resource, {
          url: value.replace(new RegExp('[^-A-Za-z0-9+&@#/%?=~_|!:,.;()]'), '')
        });
        return;
      }
      handleOnChange(ResourcePaths.Resource, { url: null });
    },
    [handleOnChange]
  );

  const handleOnResourceTypeChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>, { checked, value }: any) => {
      if (checked) {
        handleOnChange(ResourcePaths.ResourceType, value);
      }
    },
    [handleOnChange]
  );
  const handleOnDeliveryTypeChange = React.useCallback(
    (e: React.FormEvent<HTMLInputElement>, { checked, value }: any) => {
      if (checked) handleOnChange(ResourcePaths.ResourceDelivryType, value);
    },
    [handleOnChange]
  );
  const handleOnPlaceholderChange = React.useCallback(
    (value: any) => {
      handleOnChange(ResourcePaths.Placeholder, value);
    },
    [handleOnChange]
  );
  const handleOnNameChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, { value }) => {
      handleOnChange(ResourcePaths.Name, value?.substr(0, 80));
    },
    [handleOnChange]
  );
  const handleOnDescriptionChange = React.useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>, { value }) => {
      handleOnChange(ResourcePaths.Description, value?.substr(0, 80));
    },
    [handleOnChange]
  );

  return (
    <div className={Styles.ResourceEditItem}>
      <div className={Styles.ResourceEditItemSidebar}>
        <ImageUploader
          entity={entityTypes.CAMPAIGN}
          guid={get(props.values, ResourcePaths.Id) + '-placeholder'}
          value={get(props.values, ResourcePaths.Placeholder)}
          onChange={handleOnPlaceholderChange}
          accept={['.jpeg', '.jpg', '.png']}
        />
      </div>

      <div className={Styles.ResourceEditItemContent}>
        <Form.Field className={Styles.ResourceEditItemType}>
          {ResourceTypeOptions.map(({ label, value }) => (
            <Checkbox
              key={value}
              radio
              label={label}
              value={value}
              checked={value === resourceType}
              onChange={handleOnResourceTypeChange}
            />
          ))}
        </Form.Field>
        {
          {
            upload: (
              <Form.Field>
                <FileUploader
                  entity={entityTypes.CAMPAIGN}
                  guid={get(props.values, ResourcePaths.Id)}
                  value={get(props.values, ResourcePaths.Resource)}
                  onChange={handleOnUploadChange}
                  accept={['.pdf']}
                />
              </Form.Field>
            ),
            link: (
              <Form.Field>
                <Form.Input
                  label={I18n.t(LabelPaths.FileUrlLabel)}
                  value={get(props.values, ResourcePaths.ResourceUrl)}
                  onChange={handleOnResourceUrlChange}
                />
              </Form.Field>
            )
          }[resourceType]
        }

        {resource ? (
          <Form.Field className={Styles.ResourceEditItemType}>
            {PDFDeliveryTypeOptions.map(({ label, value }) => (
              <Checkbox
                key={value}
                radio
                label={label}
                value={value}
                checked={value === deliveryType}
                onChange={handleOnDeliveryTypeChange}
              />
            ))}
          </Form.Field>
        ) : null}

        <Form.Input
          label={I18n.t(LabelPaths.NameLabel)}
          placeholder={I18n.t(LabelPaths.NamePlaceholder)}
          value={get(props.values, ResourcePaths.Name)}
          onChange={handleOnNameChange}
        />
        <Form.TextArea
          label={I18n.t(LabelPaths.DescriptionLabel)}
          placeholder={I18n.t(LabelPaths.DescriptionPlaceholder)}
          value={get(props.values, ResourcePaths.Description)}
          onChange={handleOnDescriptionChange}
        />
        <br />

        <div className={Styles.ResourceEditItemButtons}>
          <Button basic color="red" onClick={handleOnDelete}>
            {I18n.t(LabelPaths.DeleteButton)}
          </Button>
          <Button primary onClick={props.onClose}>
            {I18n.t(LabelPaths.SaveButton)}
          </Button>
        </div>
      </div>
    </div>
  );
};
