import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as templateHelper from './templateHelper';
import { Form as CommonForm } from '..';
import { isArrayWithItems } from '../../helpers/arrayHelper';

class ManagedRadioGroup extends Component {
  onChange = (e, { value }) => {
    this.props.onChange(this.props.path, value);
  };

  getRadioButtons = () => {
    return this.props.options.map((option) => {
      return (
        <CommonForm.Checkbox
          name="templateRadioButton"
          disabled={this.props.disabled}
          inputLabel={option.label}
          value={option.value}
          checked={this.props.value === option.value}
          onChange={this.onChange}
          radio
        />
      );
    });
  };

  render() {
    if (!isArrayWithItems(this.props.options)) {
      return <div></div>;
    }

    return (
      <div className="custom-mediaselector-options" id={this.props.id}>
        {' '}
        {this.getRadioButtons()}{' '}
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateRadioGroup = connect(mapState)(ManagedRadioGroup);
