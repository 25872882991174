//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

//---------------Components
import { Form as CommonForm } from '../../';
import { Form, Input, Checkbox, Icon } from 'semantic-ui-react';

//---------------Constants
import { skuPath } from '../constants/paths';
import { i18SkuKeys } from '../constants/translation';
import { countries } from '../../../constants/countries';

class SkuItem extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };
  }

  getTaxFieldTextDescriptor = () => {
    if (this.props.country) {
      return countries[this.props.country].taxDescriptor;
    }
    return 'default';
  };

  render() {
    const { index, name, description, pricing, inventory, onChange } =
      this.props;
    const i18Key = 'template.merchandise.content.merchandise-detail.skus.sku';
    const taxKey = `price.tax.${this.getTaxFieldTextDescriptor()}.label`;
    return (
      <div>
        <Form className="collection-item sku-item">
          <Form.Field className="item-title-container" required={this.required}>
            <label className="item-title">
              {I18n.t(`${i18Key}.${i18SkuKeys.label}`, { id: `${index + 1}` })}
              <Icon
                name="remove circle"
                className="right-float"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.onDeleteClick(index)}
              />
            </label>
          </Form.Field>

          <div className="sku-item-content">
            <Form.Field required={this.required}>
              <label>{I18n.t(`${i18Key}.${i18SkuKeys.name}`)}</label>
              <Input
                id={`sku-name-${index}`}
                fluid
                value={name}
                onChange={(event, { value }) => {
                  onChange(index, skuPath.name, value);
                }}
              />
            </Form.Field>

            <Form.Field>
              <label>{I18n.t(`${i18Key}.${i18SkuKeys.description}`)}</label>
              <Input
                id={`sku-description-${index}`}
                fluid
                value={description}
                onChange={(event, { value }) => {
                  onChange(index, skuPath.description, value);
                }}
              />
            </Form.Field>

            <Form.Group unstackable>
              <Form.Field width={6} required={this.required}>
                <label>{I18n.t(`${i18Key}.${i18SkuKeys.price}`)}</label>
                <CommonForm.NumberInput
                  id={`sku-price-${index}`}
                  type="tel"
                  inputLabel="$"
                  style={{ margin: '0px' }}
                  value={pricing.price}
                  allowDecimal
                  onChange={(event) => {
                    onChange(index, skuPath.price, event.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Checkbox
                  id={`sku-price-tax-${index}`}
                  className="unstakable-checkbox"
                  disabled={!pricing.price}
                  checked={pricing.hasCountryTax}
                  onChange={(event, { checked }) => {
                    onChange(index, skuPath.tax, checked);
                  }}
                  label={I18n.t(`${i18Key}.${taxKey}`)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Group unstackable>
              <Form.Field width={6}>
                <label>{I18n.t(`${i18Key}.${i18SkuKeys.quantity}`)}</label>
                <CommonForm.NumberInput
                  id={`sku-${index}`}
                  type="tel"
                  style={{ margin: '0px' }}
                  value={inventory.quantity}
                  allowDecimal={false}
                  onChange={(event) => {
                    onChange(index, skuPath.quantity, event.target.value);
                  }}
                />
              </Form.Field>
            </Form.Group>
          </div>
        </Form>
      </div>
    );
  }
}

export default SkuItem;
