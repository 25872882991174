import { AboutElementTypes } from '../../../components/common/aboutElements/aboutElementType';

export const findTextElementIndex = (elements) => {
  return elements.findIndex((element) => {
    return element.type === AboutElementTypes.Text;
  });
};

export const replaceTextAbout = (aboutTemplate, model) => {
  if (aboutTemplate.elements) {
    const textElementIndex = findTextElementIndex(aboutTemplate.elements);

    if (textElementIndex >= 0) {
      let about = aboutTemplate.elements[textElementIndex].value;
      let newTemplate = JSON.parse(JSON.stringify(aboutTemplate));
      if (about) {
        for (var propertyName in model) {
          var pattern = `\\*\\|${propertyName}\\|\\*`;
          var regex = new RegExp(pattern, 'g');
          about = about.replace(regex, model[propertyName] || '');
        }
        newTemplate.elements[textElementIndex].value = about;
        return newTemplate;
      }
    }
  }
  return aboutTemplate;
};

export const removeLastLineTextAbout = (template) => {
  const textElementIndex = findTextElementIndex(template.elements);
  if (textElementIndex >= 0) {
    let about = template.elements[textElementIndex].value;
    let newTemplate = JSON.parse(JSON.stringify(template));

    if (about) {
      const index = about.lastIndexOf('\n\n');
      if (index > 0) {
        about = about.substring(0, index);
      }
      newTemplate.elements[textElementIndex].value = about;
      return newTemplate;
    }
  }
  return template;
};
