//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from '../templateHelper';

//------------Components
import { CollectionBuilder } from '../../collectionBuilder';
import GiftMatchingItem from '../../collectionBuilder/components/giftMatchingItem';

class ManagedGiftMatchingBuilder extends Component {
  getModel = () => {
    return {
      enabled: true,
      name: null,
      multiplier: 1,
      donation: {
        from: null,
        message: null
      },
      mainImagePath: null,
      conditions: []
    };
  };

  getInnerModel = () => {
    return {
      key: null,
      operator: null,
      value: []
    };
  };

  render() {
    const { path, disabled, validation, onChange } = this.props;
    return (
      <CollectionBuilder
        path={path}
        onChange={onChange}
        disabled={disabled}
        validation={validation}
        entity="page"
        i18Key="giftMatching.model"
        model={this.getModel()}
        innerModel={this.getInnerModel()}
        itemType={<GiftMatchingItem />}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateGiftMatchingBuilder = connect(mapState)(
  ManagedGiftMatchingBuilder
);
