import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Common } from '../';

class ManagedHorizontalRule extends Component {
  render() {
    return (
      <Common.HorizontalRule
        style={this.props.style || {}}
        className="template-divider"
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {};
};

export const TemplateHorizontalRule = connect(mapState)(ManagedHorizontalRule);
