import React, { FunctionComponent, useMemo } from "react";
import { useSelector } from "react-redux";
import { I18n } from "react-redux-i18n";
import get from "lodash.get";

import { TemplateDropdown } from "./template/templateDropdown";
import { TemplateHeader } from "./template/templateHeader";
import { TemplateText } from "./template/templateText";
import { TemplateHorizontalRule } from "./template/templateHorizontalRule";
import { InfoBox } from "./common/infoBox";

const paths = {
  event: {
    internationalisation: {
      header: "event.template.page.advanced.multicurrency.header",
      description: "event.template.page.advanced.multicurrency.description",
      donationsLabel: "event.template.page.advanced.multicurrency.donations-label",
    },
    state: {
      donations: "donationSetup.allowAdditionalCurrencies",
    },
  },
  organization: {
    internationalisation: {
      header: "template.organisation.content.advanced.multicurrency.header",
      description: "template.organisation.content.advanced.multicurrency.description",
      donationsLabel: "template.organisation.content.advanced.multicurrency.donations-label",
      salesLabel: "template.organisation.content.advanced.multicurrency.sales-label",
    },
    state: {
      donations: "additionalCurrencies.allowOnDonations",
      sales: "additionalCurrencies.allowOnSales",
    },
  },
  campaign: {
    internationalisation: {
      header: "template.page.content.advanced.multicurrency.header",
      description: "template.page.content.advanced.multicurrency.description",
      infoBox: {
        header: "template.page.content.advanced.multicurrency.info-box.header",
        description: "template.page.content.advanced.multicurrency.info-box.description",
        inheritedSetting: "template.page.content.advanced.multicurrency.info-box.inherited-setting",
      },
      donationsLabel: "template.page.content.advanced.multicurrency.donations-label",
      salesLabel: "template.page.content.advanced.multicurrency.sales-label",
    },
    state: {
      donations: "donationSetup.allowAdditionalCurrencies",
      sales: "ticketing.allowAdditionalCurrencies",
    },
  },
};

const options = [
  {
    value: null,
    text: "Default",
  },
  {
    value: true,
    text: "Enabled",
  },
  {
    value: false,
    text: "Disabled",
  },
];

interface MulticurrencyProps {
  type: "event" | "organization" | "campaign";
  onChange: (path: string, value: any) => void;
}

export const Multicurrency: FunctionComponent<MulticurrencyProps> = (props) => {
  const { type, onChange } = props;

  const event = useSelector((state: any) => get(state, "templateBuilderEx.data.event"));
  const organization = useSelector((state: any) => get(state, "templateBuilderEx.data.organization"));

  const infoBoxContent = useMemo(() => {
    const result = {
      donations: "",
      sales: "",
    };

    if (type === "campaign") {
      if (get(organization, "additionalCurrencies.allowOnDonations") !== null) {
        result.donations = I18n.t(
          paths[type].internationalisation.infoBox.inheritedSetting,
          {
            type: "Donations",
            status: get(organization, "additionalCurrencies.allowOnDonations") ? "enabled" : "disabled",
            entity: "organisation",
          }
        );
      } else if (event && get(event, "features.donationSetup.allowAdditionalCurrencies") !== null) {
        result.donations = I18n.t(
          paths[type].internationalisation.infoBox.inheritedSetting,
          {
            type: "Donations",
            status: get(event, "features.donationSetup.allowAdditionalCurrencies") ? "enabled" : "disabled",
            entity: "MCE",
          }
        );
      }

      if (get(organization, "additionalCurrencies.allowOnSales") !== null) {
        result.sales = I18n.t(
          paths[type].internationalisation.infoBox.inheritedSetting,
          {
            type: "Sales",
            status: get(organization, "additionalCurrencies.allowOnSales") ? "enabled" : "disabled",
            entity: "organisation",
          }
        );
      }
    }

    return result;
  }, [type, event, organization]);

  return (
    <>
      <TemplateHorizontalRule />

      <TemplateHeader
        as="h2"
        label={paths[type].internationalisation.header}
      />

      <TemplateText
        size="small"
        label={paths[type].internationalisation.description}
      />

      {type === "campaign" && (infoBoxContent.donations || infoBoxContent.sales) && (
        <InfoBox header={paths[type].internationalisation.infoBox.header}>
          {I18n.t(paths[type].internationalisation.infoBox.description)}
          {infoBoxContent.donations}
          {infoBoxContent.sales}
        </InfoBox>
      )}

      <TemplateDropdown
        path={paths[type].state.donations}
        label={paths[type].internationalisation.donationsLabel}
        onChange={onChange}
        options={options}
      />

      {!(type === "event" || (type === "campaign" && event)) && (
        <TemplateDropdown
          path={paths[type].state.sales}
          label={paths[type].internationalisation.salesLabel}
          onChange={onChange}
          options={options}
        />
      )}
    </>
  );
};
