import React from 'react';

export interface ThumbnailProps {
  color?: string;
}

export const Thumbnail: React.FunctionComponent<ThumbnailProps> = ({
  color = 'black'
}) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
    />
  </svg>
);

export const PdfIcon: React.FunctionComponent<ThumbnailProps> = ({
  color = 'black'
}) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M20 2H8c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-8.5 7.5c0 .83-.67 1.5-1.5 1.5H9v2H7.5V7H10c.83 0 1.5.67 1.5 1.5v1zm5 2c0 .83-.67 1.5-1.5 1.5h-2.5V7H15c.83 0 1.5.67 1.5 1.5v3zm4-3H19v1h1.5V11H19v2h-1.5V7h3v1.5zM9 9.5h1v-1H9v1zM4 6H2v14c0 1.1.9 2 2 2h14v-2H4V6zm10 5.5h1v-3h-1v3z"
    />
  </svg>
);

export const FileUploadIcon: React.FunctionComponent<ThumbnailProps> = ({
  color = 'black'
}) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M14 2H6c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6zm4 18H6V4h7v5h5v11zM8 15.01l1.41 1.41L11 14.84V19h2v-4.16l1.59 1.59L16 15.01 12.01 11z"
    />
  </svg>
);

export const ImageIcon: React.FunctionComponent<ThumbnailProps> = ({
  color = 'black'
}) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M21 19V5c0-1.1-.9-2-2-2H5c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2zM8.5 13.5l2.5 3.01L14.5 12l4.5 6H5l3.5-4.5z"
    />
  </svg>
);

export const CloseIcon: React.FunctionComponent<ThumbnailProps> = ({
  color = 'black'
}) => (
  <svg viewBox="0 0 24 24">
    <path
      fill={color}
      d="M19 6.41 17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
    />
  </svg>
);
