import React, { Component } from 'react';
import { Form } from '../';
import * as validationHelper from '../../helpers/validationHelper';

class ValidatedCurrency extends Component {
  state = {
    applyValidation: false
  };

  onBlur = () => {
    this.setState({ applyValidation: true });
  };

  render() {
    let validationErrorMessage = '';
    const { validation, showValidationIcon, error, errorMessage, ...pprops } =
      this.props;
    if (this.state.applyValidation) {
      validationErrorMessage =
        validationHelper.getValidationErrorMessage(validation, pprops.value) ||
        errorMessage;
    }

    return (
      <Form.NumberInput
        {...pprops}
        showValidationIcon={showValidationIcon && this.state.applyValidation}
        onBlur={this.onBlur}
        placeholder={this.props.placeholder}
        validationErrorMessage={validationErrorMessage}
        required={validation && validation.required}
        error={this.state.applyValidation && error}
      />
    );
  }
}

export default ValidatedCurrency;
