import React, { Component } from 'react';
import { Form, Message } from 'semantic-ui-react';
import * as validationHelper from '../../helpers/validationHelper';
import './index.css';
import { I18n } from 'react-redux-i18n';

class FormbuilderFieldWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applyValidation: props.initialValidation ? props.initialValidation : false
    };
  }

  onBlur = () => {
    this.setState({
      applyValidation: true
    });

    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  render() {
    const {
      label,
      error,
      required,
      inputSub,
      errorMessage,
      validation,
      value
    } = this.props;
    const validationError =
      this.state.applyValidation &&
      validation &&
      !validationHelper.isValid(validation, value);
    const isError = validationError || error;
    const isRequired = required || (validation && validation.required);
    const requiredStar = isRequired ? (
      <span className="formbuilder-field-required">*</span>
    ) : null;

    return (
      <Form.Field required={isRequired} onBlur={this.onBlur}>
        <span>
          {requiredStar}
          <b>{label}</b>
          <div className="formbuilder-field-description">{inputSub}</div>
        </span>
        {this.props.children}
        {isError && (
          <Message negative>
            <p>
              {validationHelper.getValidationErrorMessage(validation, value) ||
                errorMessage ||
                I18n.t('errors.validation.field-invalid')}
            </p>
          </Message>
        )}
      </Form.Field>
    );
  }
}

export default FormbuilderFieldWrapper;
