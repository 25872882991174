import { HtttpRequestMethods, sendRequest } from "./request";
import { MerchandiseUrls } from "./constants"
import { ApiError, ListPaginated, IMerchandise } from "../merchandise.model";

const getRequestBody = ({ campaignId, pageSize = 75 }: ISearchMerchandiseArgs) => {
  return {
    pageSize: pageSize,
    filters: [
      { key: 'campaignId', operator: 'EqualTo', value: campaignId },
      { key: 'enabled', operator: 'EqualTo', value: true },
      // { key: 'skuEnabled', operator: 'EqualTo', value: true } // wait for api updates
    ],
    includeDataTemplate: true,
    resultType: 'Full'
  };
};

export interface ISearchMerchandiseResult {
  data: ListPaginated<IMerchandise>;
  error: ApiError[];
}

export interface ISearchMerchandiseArgs {
  campaignId?: number;
  page?: number;
  pageSize?: number;
}

export async function searchMerchandise(
  apikey: string,
  sessionKey: string,
  body: ISearchMerchandiseArgs
): Promise<ISearchMerchandiseResult> {
  return sendRequest({
    apikey,
    sessionKey,
    url: MerchandiseUrls.Search,
    method: HtttpRequestMethods.Post,
    body: getRequestBody(body),
  }).then((response) => response.json());
}
