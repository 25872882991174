import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pageActions } from '../reducer';
import EditPageContainer from './edit-page';
import { pageTypes } from '../../../../constants/pageTypes';

const EditCollectionPage = (props) => {
  return (
    <EditPageContainer
      getRecord={props.pageActions.getCollectionRecord}
      updateRecord={props.pageActions.updateCollectionRecord}
      updateStatus={props.pageActions.updateCollectionStatus}
      template={props.template}
      type={pageTypes.COLLECTION}
    />
  );
};

const mapState = ({ campaignPage }) => {
  return {
    template: campaignPage.teamTemplate
  };
};

const mapDispatch = (dispatch) => {
  return {
    pageActions: bindActionCreators(pageActions, dispatch)
  };
};

const EditCollectionPageContainer = connect(
  mapState,
  mapDispatch
)(EditCollectionPage);
export default EditCollectionPageContainer;
