import React from 'react';

const EMBED_URL = 'https://embed.twitch.tv/embed/v1.js';

class TwitchVideo extends React.Component {
  componentDidMount() {
    this.loadScript();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.targetID !== this.props.targetID) {
      const $container = document.getElementById(this.props.targetID);
      if ($container) {
        while ($container.firstChild) {
          $container.removeChild($container.lastChild);
        }
      }

      this.loadScript();
    }
  }

  loadScript = () => {
    const script = document.createElement('script');
    script.setAttribute('src', EMBED_URL);
    script.addEventListener('load', () => {
      new window.Twitch.Embed(this.props.targetID, { ...this.props });
    });
    document.body.appendChild(script);
  };

  render() {
    return (
      <div>
        <div id={this.props.targetID}></div>
      </div>
    );
  }
}

export default TwitchVideo;
