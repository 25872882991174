import React, { useEffect, useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, Modifier, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { stateFromHTML } from 'draft-js-import-html';
import { Form, Message } from 'semantic-ui-react';
import purify from 'dompurify';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { getTranslation, getValue } from '../templateHelper';
import './styles.css';
import { getValidationErrorMessage } from '../../../helpers/validationHelper';

interface ITemplateRichTextEditorProps {
  id: string;
  path: string;
  label: string;
  value?: string;
  validation?: any;
  subtext?: string;
  subtextPosition?: string;
  onChange?: (path: string, html: string) => void;
}

const toolbarConfig = {
  options: ['inline', 'blockType', 'list', 'history']
};

const sanitizedHtml = (html: string): string =>
  purify.sanitize(html, {
    FORBID_CONTENTS: ['figure', 'img'],
    FORBID_TAGS: ['figure', 'img']
  });

const getHtml = (state: EditorState): string =>
  state.getCurrentContent().hasText()
    ? draftToHtml(convertToRaw(state.getCurrentContent()))
    : '';

const getEditorState = (html: string = ''): EditorState => {
  const { contentBlocks, entityMap } = htmlToDraft(sanitizedHtml(html));
  return EditorState.createWithContent(
    ContentState.createFromBlockArray(contentBlocks, entityMap)
  );
};

export const TemplateRichTextEditor = ({
  id,
  path,
  label,
  validation,
  value,
  subtext,
  subtextPosition = 'bottom',
  onChange
}: ITemplateRichTextEditorProps) => {
  // prettier-ignore
  const templateBuilderEx = useSelector((state: RootState) => state.templateBuilderEx);
  // prettier-ignore
  const [validationErrorMessage, setValidationErrorMessage] = useState<undefined | string | string[]>('');
  // prettier-ignore
  const [editorState, setEditorState] = useState(() => getEditorState(value || getValue(templateBuilderEx, path)));

  const onTextChange = (newEditorState: EditorState) => {
    setEditorState(newEditorState);

    if (onChange) {
      onChange(path, getHtml(editorState));
    }
  };

  const handlePaste = (
    _text: string,
    html: string,
    state: EditorState,
    onEditorChange: (state: EditorState) => void
  ) => {
    const blockMap = stateFromHTML(sanitizedHtml(html)).getBlockMap();
    const newState = Modifier.replaceWithFragment(
      state.getCurrentContent(),
      state.getSelection(),
      blockMap,
    );
    onEditorChange(EditorState.push(state, newState, 'insert-fragment'));

    return true;
  };

  useEffect(() => {
    setEditorState(getEditorState(value || getValue(templateBuilderEx, path)));
  }, [value, path]);

  useEffect(() => {
    const html = value || getValue(templateBuilderEx, path);
    const hasText = !!editorState.getCurrentContent().getPlainText().trim();
    const errorMessage = getValidationErrorMessage(
      validation,
      hasText ? html : ''
    );
    setValidationErrorMessage(errorMessage);
  }, [editorState]);

  return (
    <Form.Field id={id} required={validation.required}>
      {label && (
        <label className={`input-sub-${subtextPosition}`}>
          {getTranslation(label)}
        </label>
      )}
      {subtext && subtextPosition === 'top' && (
        <div className={`input-sub input-sub-${subtextPosition}`}>
          {getTranslation(subtext)}
        </div>
      )}
      <Editor
        toolbarClassName="rich-text-editor-toolbar"
        wrapperClassName="rich-text-editor-wrapper"
        editorClassName="rich-text-editor"
        editorState={editorState}
        toolbar={toolbarConfig}
        handlePastedText={handlePaste}
        onEditorStateChange={onTextChange}
        placeholder="Enter your text here..."
      />
      <Message negative hidden={!validationErrorMessage}>
        <p>{validationErrorMessage}</p>
      </Message>
    </Form.Field>
  );
};
