import React from 'react';
import Styles from './merchandise-list.module.css';
import { ISku } from './merchandise.model';
import { getPrice, getQuantity } from './merchandise.helpers';

export const SkuItem: React.FunctionComponent<UseSkuItem> = (props) => (
  <SkuItemView {...useSkuItem(props)} />
);

interface UseSkuItem {
  item: ISku;
}

export function useSkuItem({ item }: UseSkuItem): SkuItemViewProps {
  return {
    title: item.name,
    price: getPrice(item.pricing?.price),
    quantity: getQuantity(item.inventory?.quantity)
  };
}

interface SkuItemViewProps {
  title: string;
  price: string;
  quantity: string;
}

export const SkuItemView: React.FunctionComponent<SkuItemViewProps> = (
  props
) => {
  return (
    <div className={Styles.SkuItem}>
      <div className={Styles.SkuItemTitle}>{props.title}</div>
      <div className={Styles.SkuItemPrice}>{props.price}</div>
      <div className={Styles.SkuItemQuantity}>{props.quantity}</div>
    </div>
  );
};
