import { IColumnData } from '../../../models/table/columnData';

const ViewListPath = 'terminal.list';

export const terminalHeaders = {
  DEVICE: `${ViewListPath}.device`,
  LABEL: `${ViewListPath}.label`,
  MODEL: `${ViewListPath}.model`,
  ORGANIZATION: `${ViewListPath}.organization`,
  CAMPAIGN: `${ViewListPath}.campaign`,
  COLLECTION: `${ViewListPath}.collection`,
  TEAM: `${ViewListPath}.team`,
  FUNDRAISER: `${ViewListPath}.fundraiser`
};

const terminalColumns = {
  serialNumber: 'serialNumber',
  label: 'label',
  model: 'model',
  organization: 'organization.name',
  campaign: 'campaign.name',
  collection: 'collection.name',
  team: 'team.name',
  fundraiser: 'fundraiser.name'
};

export const terminalColumnData: IColumnData[] = [
  {
    header: terminalHeaders.DEVICE,
    path: terminalColumns.serialNumber,
    defaultVisible: false
  },
  {
    header: terminalHeaders.LABEL,
    path: terminalColumns.label,
    defaultVisible: false
  },
  {
    header: terminalHeaders.MODEL,
    path: terminalColumns.model,
    defaultVisible: false
  },
  {
    header: terminalHeaders.ORGANIZATION,
    path: terminalColumns.organization,
    defaultVisible: false
  },
  {
    header: terminalHeaders.CAMPAIGN,
    path: terminalColumns.campaign,
    defaultVisible: false
  },
  {
    header: terminalHeaders.COLLECTION,
    path: terminalColumns.collection,
    defaultVisible: false
  },
  {
    header: terminalHeaders.TEAM,
    path: terminalColumns.team,
    defaultVisible: false
  },
  {
    header: terminalHeaders.FUNDRAISER,
    path: terminalColumns.fundraiser,
    defaultVisible: false
  }
];
