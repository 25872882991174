import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Icon, Image, Loader, Dimmer } from 'semantic-ui-react';
import CSVUploadedImage from '../../images/CSVUploaded.svg';
import * as uploadActions from '../../modules/upload';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import { connect } from 'react-redux';
import './index.css';

class CSVUpload extends Component {
  state = {
    currentFile: null
  };

  onDrop = (files) => {
    if (files && files.length === 1) {
      this.props.uploadFile(
        this.props.entity,
        this.props.uploadType,
        null,
        this.props.path,
        files[0]
      );
      this.setState({ currentFile: files[0] });
    }
  };

  onRemoveFile = () => {
    this.setState({ currentFile: null });
    this.props.uploadResetState();

    if (this.props.onRemoveFile) {
      this.props.onRemoveFile();
    }
  };

  isRemoveButtonVisible = (isRecordLoading) => {
    return (
      !isRecordLoading && this.props.uploadedFileUrl && this.state.currentFile
    );
  };

  render() {
    const isRecordLoading =
      get(this.props, `record.state.${this.props.path}.processing`) === true;

    return (
      <div className="bulk-upload-container">
        <Dropzone
          className="avatar-dropzone-cont"
          accept=".csv"
          onDrop={this.onDrop}
        >
          {!this.props.uploadedFileUrl && (
            <div className="update-uploaded-inner">
              <div className="update-dropzone cropper-add-image-box">
                <div className="update-dropzone-inner">
                  {isRecordLoading && (
                    <Dimmer active inverted>
                      <Loader />
                    </Dimmer>
                  )}
                  {!isRecordLoading && <Icon name="add" />}
                </div>
              </div>
            </div>
          )}
        </Dropzone>
        {this.isRemoveButtonVisible(isRecordLoading) && (
          <div className="bulk-upload-container bulk-upload-with-remove-container">
            <Icon
              className="remove-file-icon"
              color="red"
              name="remove circle"
              size="large"
              onClick={this.onRemoveFile}
            />
            <Image className="bulk-uploaded-picture" src={CSVUploadedImage} />
            <div className="space-container">
              <b>{this.state.currentFile.name}</b>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapState = ({ upload }) => {
  return upload;
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(uploadActions, dispatch)
  };
};

const CSVUploadContainer = connect(mapState, mapDispatch)(CSVUpload);
export default CSVUploadContainer;
