import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { Button, Grid, Dropdown } from 'semantic-ui-react';
import get from 'lodash.get';
import { Record, getUrl } from '../../../../modules/bin/utility';
import RecordResultMessage from '../../../../components/common/recordResult';
import { Common } from '../../../../components';
import { pageActions } from '../reducer';
import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import * as generalActions from '../../../../modules/general';
import { setRedirectUrl } from '../../../../modules/session';
import Spinner from '../../../../components/common/spinner';
import { pageStatuses, pageTypes, fitnessMeasure } from '../../constants';
import { withRouter, Link } from 'react-router-dom';
import { TemplateOptions } from '../../../../components/template/templateOptions';
import FundraiserTemplate from '../templateEx';
import BasePage from '../../../../components/common/basePageView';
import clear from '../../../../components/clear';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import Text from '../../../../components/common/text';
import config from '../../../../constants/index';
import { campaignContract } from '../../create/templates';
import {
  isArrayWithItems,
  stringToArray
} from '../../../../helpers/arrayHelper';
import SavedEntityModal from '../../../../components/common/savedEntityModal';
import { nodeTypes } from '../../../../components/formbuilder/typeDefinitions';
import { isValidDate, parseToDate, parseToTime } from '../../../../helpers/templateHelper';

class EditPage extends Component {
  constructor(props) {
    super(props);
    props.getRecord(props.match.params.id);

    if (props.type === pageTypes.TEAM) {
      props.pageActions.getFundraisersCountByTeamId(props.match.params.id);
    }

    this.state = {
      newStatus: '',
      isConfirmModalOpened: false,
      isSaveModalOpened: false
    };
  }

  componentDidUpdate(prevProps) {
    if (
      Record.isRecordReady(this.props.record) ||
      Record.isRecordReady(this.props.fundraisersCountRecord)
    ) {
      this.initTemplateBuilderModel();
    }

    if (
      Record.isSuccess(this.props.saveRecord) &&
      !Record.isSuccess(prevProps.saveRecord) &&
      !this.state.isSaveModalOpened
    ) {
      this.setState({ isSaveModalOpened: true });
    }
  }

  onValueChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  onSave = (data) => {
    if (data.initialName.toLowerCase() !== data.name.toLowerCase()) {
      data.urlPath = getUrl(data.name);
    }
    this.props.updateRecord(data);
  };

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
    this.props.pageActions.clearRecordMessages('statusRecord');
  };

  onDropdownItemChange = (event, { value }) => {
    if (this.props.record.data.data.status === value) {
      return;
    }

    if (
      this.props.type === pageTypes.TEAM &&
      this.props.fundraisersCountRecord.data
    ) {
      this.setState({
        newStatus: value,
        isConfirmModalOpened: true
      });
    } else {
      this.changePageStatus(value);
    }
  };

  changePageStatus = (newStatus, removeIndividuals = false) => {
    const status = newStatus || this.state.newStatus;
    const editableData = { status: status };

    if (removeIndividuals) {
      editableData.removeAllFundraisers = true;
    }

    this.props.updateStatus(this.props.record.data.data.id, editableData);
    this.onValueChange('status', status);
    this.setState({ isConfirmModalOpened: false });
  };

  goToPagesList = (id) => {
    let url = `/campaigns/view/fundraisers/${id}`;
    if (this.props.redirectUrl) {
      url = this.props.redirectUrl;
      this.props.setRedirectUrl();
    }
    this.props.history.push(url);
  };

  initTemplateBuilderModel = () => {
    if (
      !this.props.isTemplateInited &&
      get(this.props, 'record.data.data.campaign')
    ) {
      // defaults
      let showFitnessDistanceTarget = false;
      let showFitnessTimeTarget = false;
      let showFitnessNumberOfStepsTarget = false;

      let page = this.props.record.data.data;
      const campaign = page.campaign;
      const campaignUrl = get(campaign, 'url');
      const collectionsEnabled = get(campaign, 'allowsCollections');
      const hideTeam =
        this.props.type === pageTypes.TEAM || !campaign.allowsTeams;
      const initialName = get(this.props, 'record.data.data.name');
      const teamName = get(page, 'team.name');
      const collectionName = get(page, 'collection.name');

      const primaryColor =
        get(page, 'template.value.style.primaryColor') ||
        get(campaign, 'template.value.style.primaryColor');
      const secondaryColor =
        get(page, 'template.value.style.secondaryColor') ||
        get(campaign, 'template.value.style.secondaryColor');

      const campaignInvertPrimaryTextColor = get(
        campaign,
        'template.value.style.invertPrimaryTextColor'
      );
      const campaignInvertSecondaryTextColor = get(
        campaign,
        'template.value.style.invertSecondaryTextColor'
      );
      const pageInvertPrimaryTextColor = get(
        page,
        'template.value.style.invertPrimaryTextColor'
      );
      const pageInvertSecondaryTextColor = get(
        page,
        'template.value.style.invertSecondaryTextColor'
      );
      const invertPrimaryTextColor =
        pageInvertPrimaryTextColor !== undefined
          ? pageInvertPrimaryTextColor
          : campaignInvertPrimaryTextColor;
      const invertSecondaryTextColor =
        pageInvertSecondaryTextColor !== undefined
          ? pageInvertSecondaryTextColor
          : campaignInvertSecondaryTextColor;
      const allowCollectionPageManager = get(
        campaign,
        campaignContract.enableCollectionPageManager
      );

      //fitness
      const isFitnessEnabled =
        get(campaign, 'allowsFitnessActivities') &&
        get(campaign, 'template.value.fitnessSetup.allowPageTracking');
      const allowedTrackedStatistics = get(
        campaign,
        'allowedTrackedStatistics'
      );

      if (isFitnessEnabled) {
        const statisticsArray = stringToArray(allowedTrackedStatistics);
        showFitnessDistanceTarget = statisticsArray.some(
          (item) => item === fitnessMeasure.distance
        );
        showFitnessTimeTarget = statisticsArray.some(
          (item) => item === fitnessMeasure.time
        );
        showFitnessNumberOfStepsTarget = statisticsArray.some(
          (item) => item === fitnessMeasure.numberOfSteps
        );
      }

      //custom activities
      const isCustomActivitiesEnabled = get(
        campaign,
        'features.activities.customActivities'
      );
      const isCustomActivitiesThermobarEnabled = get(
        campaign,
        campaignContract.customActivitiesValue1ThermometerEnabled
      );

      const showCustomActivityTarget =
        isCustomActivitiesEnabled && isCustomActivitiesThermobarEnabled;
      const customActivitiesValue1Unit = get(
        campaign,
        campaignContract.customActivitiesValue1Unit
      );

      const model = new TemplateOptions()
        .setModel(this.props.record.data.data)
        .setValue('isTeamEnabled', campaign.allowsTeams)
        .setValue('isFundraiserEnabled', campaign.allowsFundraisers)
        .setValue('teamName', teamName)
        .setValue('collectionName', collectionName)
        .setValue('type', this.props.type)
        .setValue('campaignUrl', campaignUrl)
        .setValue('initialName', initialName)
        .setValue(
          'template.value.style.primaryColor',
          primaryColor || '#FF0000'
        )
        .setValue(
          'template.value.style.secondaryColor',
          secondaryColor || '#0000FF'
        )
        .setValue(
          'template.value.style.invertPrimaryTextColor',
          !!invertPrimaryTextColor
        )
        .setValue(
          'template.value.style.invertSecondaryTextColor',
          !!invertSecondaryTextColor
        )
        .setValue('showFitnessDistanceTarget', showFitnessDistanceTarget)
        .setValue('showFitnessTimeTarget', showFitnessTimeTarget)
        .setValue(
          'showFitnessNumberOfStepsTarget',
          showFitnessNumberOfStepsTarget
        )
        .setValue('showCustomActivityTarget', showCustomActivityTarget)
        .setValue('customActivitiesValue1Unit', customActivitiesValue1Unit)
        .hide('type')
        .isEdit();

      if (hideTeam) {
        model.hide('teamId');
      }

      const manager = this.props.record.data.data.manager;

      if (manager) {
        model.hide('manager.email');
      } else {
        model.hide('manager');
      }

      if (this.props.type === pageTypes.COLLECTION) {
        model.hide('collectionId');
        model.hide('teamId');
        model.hide('isPublic');
        model.hide('fitnessTarget');

        if (!allowCollectionPageManager) {
          model.hide('manager.email');
          model.hide('manager');
        }

        if (!allowCollectionPageManager) {
          model.hide(campaignContract.timeAndPlaceHeader);
          model.hide(campaignContract.allowTimeAndPlace);
        }
      } else {
        model.hide('parentId');
        model.hide('template.value.heroImagePath');
        model.hide('coloursGroup');
        model.hide('coloursInvertionGroup');

        if (this.props.type === pageTypes.TEAM) {
          model.hide(campaignContract.timeAndPlaceHeader);
          model.hide(campaignContract.allowTimeAndPlace);
        }
      }

      if (!collectionsEnabled) {
        model.hide('collectionId');
      }

      //time and place
      
      const timeAndPlace = {
        date: get(page, 'timeAndPlace.time.start'),
        end: get(page, 'timeAndPlace.time.end'),
        includesTime: get(page, 'timeAndPlace.time.includesTime'),
        text: get(page, 'timeAndPlace.time.text'),
        location: get(page, 'timeAndPlace.place.text'),
      }
      const isTimeAndPlaceEnabled = !!(timeAndPlace.date || timeAndPlace.location);
      
      model.setValue(campaignContract.allowTimeAndPlace, isTimeAndPlaceEnabled);
      if (isValidDate(timeAndPlace.date)) {
        model.setValue(campaignContract.date, parseToDate(timeAndPlace.date));
        model.setValue(campaignContract.time, parseToTime(timeAndPlace.date));
      }
      if (isValidDate(timeAndPlace.end)) {
        model.setValue(campaignContract.endDate, parseToDate(timeAndPlace.end));
        model.setValue(campaignContract.endTime, parseToTime(timeAndPlace.end));
      }
      model.setValue(campaignContract.timeExtraDetails, timeAndPlace.text);
      model.setValue(campaignContract.location, timeAndPlace.location);

      model.setVisible(campaignContract.timeAndPlace, isTimeAndPlaceEnabled);
      model.setVisible(campaignContract.timeExtraDetails, isTimeAndPlaceEnabled);
      model.setVisible(campaignContract.location, isTimeAndPlaceEnabled);
      

      if (!showFitnessDistanceTarget) {
        model.hide('fitnessDistanceTarget');
      }
      if (!showFitnessTimeTarget) {
        model.hide('fitnessTimeTarget');
      }
      if (!showFitnessNumberOfStepsTarget) {
        model.hide('fitnessNumberOfStepsTarget');
      }

      if (!showCustomActivityTarget) {
        model.hide('customActivities.value1.target');
      }

      //custom extra fields
      const customExtraFields = get(page, 'template.value.customExtraFields');

      if (isArrayWithItems(customExtraFields)) {
        const coordinatorFirstName = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorFirstName
        );
        if (!coordinatorFirstName) {
          model.hide(campaignContract.coordinatorFirstName);
        }

        const coordinatorLastName = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorLastName
        );
        if (!coordinatorLastName) {
          model.hide(campaignContract.coordinatorLastName);
        }

        const coordinatorEmail = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorEmail
        );
        if (!coordinatorEmail) {
          model.hide(campaignContract.coordinatorEmail);
        }

        const coordinatorPhone = customExtraFields.find(
          (x) => x.key === campaignContract.coordinatorPhone
        );
        if (!coordinatorPhone) {
          model.hide(campaignContract.coordinatorPhone);
        }
      } else {
        model.hide(campaignContract.coordinatorFirstName);
        model.hide(campaignContract.coordinatorLastName);
        model.hide(campaignContract.coordinatorEmail);
        model.hide(campaignContract.coordinatorPhone);
      }

      if (this.props.type === pageTypes.COLLECTION) {
        const questions = get(page, campaignContract.registrationQuestions, {});

        //NOTE: Hide and not validate some formbuilder questions for collections  
        questions.children = questions.children || [];
        questions.children[0] = questions.children[0] || {};
        questions.children[0].children = questions?.children?.[0]?.children?.filter(x => x.type !== nodeTypes.phone && x.type !== nodeTypes.address);

        model.setValue(
          campaignContract.registrationQuestions,
          questions
        );
      }

      this.props.templateBuilderActions.setTemplateModel(model.get());
    }
  };

  onConfirmModalClose = () => {
    this.setState({ isConfirmModalOpened: false });
  };

  onRemoveIndividualsClick = () => {
    this.changePageStatus(null, true);
  };

  getCustomButtonsForConfirmModal = () => {
    return (
      <span>
        <Button onClick={this.onRemoveIndividualsClick}>
          Remove those individuals
        </Button>
        <Button primary onClick={() => this.changePageStatus()}>
          Change to {this.state.newStatus}
        </Button>
      </span>
    );
  };

  getConfirmModalContent = () => {
    const translationOptions = {
      fundraisersCount: this.props.fundraisersCountRecord.data,
      teamName: this.props.record.data.data.name,
      newStatus: this.state.newStatus
    };

    return (
      <Text>
        {' '}
        {I18n.t('campaign.page-status-changing', translationOptions)}
      </Text>
    );
  };

  isRecordLoading = () => {
    return (
      Record.isRecordLoading(this.props.record) ||
      Record.isRecordLoading(this.props.fundraisersCountRecord) ||
      (this.props.linkedCollectionRecord &&
        Record.isRecordLoading(this.props.linkedCollectionRecord)) ||
      (this.props.parentCollectionRecord &&
        Record.isRecordLoading(this.props.parentCollectionRecord))
    );
  };

  getSavedModalContent = () => {
    return (
      <Fragment>
        <Text>
          {I18n.t(`template.page.saved-modal.page-edit-message`).replace(
            '{campaignName}',
            get(this.props, 'record.data.data.name')
          )}
        </Text>
      </Fragment>
    );
  };

  getSavedModalButtons = () => {
    return (
      <Fragment>
        <Button
          className="primary"
          onClick={() => {
            window.open(get(this.props, 'record.data.data.urlFull'), '_blank');
          }}
        >
          {I18n.t('template.page.saved-modal.page-view-button')}
        </Button>
        <Button
          onClick={() =>
            this.goToPagesList(this.props.record.data.data.campaign.id)
          }
        >
          {I18n.t('template.page.saved-modal.page-return-button')}
        </Button>
      </Fragment>
    );
  };

  render() {
    const isLoading = this.isRecordLoading();
    const isStatusLoading = Record.isRecordLoading(this.props.statusRecord);
    const isSaving = Record.isRecordLoading(this.props.saveRecord);

    if (isLoading || !this.props.isTemplateInited) {
      return <Spinner />;
    }

    const campaign = this.props.record.data.data.campaign;
    const pageName = this.props.record.data.data.name;
    const customButtons = this.getCustomButtonsForConfirmModal();
    const content = this.getConfirmModalContent();

    const successModalRedirectButtons = this.getSavedModalButtons();
    const successModalContent = this.getSavedModalContent();

    const dropdownOptions = Object.keys(pageStatuses).map((status) => ({
      text: status.charAt(0).toUpperCase() + status.slice(1),
      value: status.toLowerCase()
    }));

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <RecordResultMessage
          record={this.props.statusRecord}
          onDismiss={this.clearMessages}
        />

        <SavedEntityModal
          open={this.state.isSaveModalOpened}
          content={successModalContent}
          buttons={successModalRedirectButtons}
          pageEditTitle={I18n.t(`template.page.saved-modal.page-title-edit`)}
          onClose={() => this.setState({ isSaveModalOpened: false })}
          isEdit
        />

        <ConfirmationModal
          customButtons={customButtons}
          open={this.state.isConfirmModalOpened}
          onClose={this.onConfirmModalClose}
          content={content}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <Common.PageHeader
                name="Edit page"
                hideImage
                styles={{ marginBottom: '0px' }}
              >
                <Link to={config.DEFAULT_CAMPAIGNS_URL} push>
                  <Common.PageHeader.Breadcrumb label="Campaigns" />
                </Link>
                <Link to={`/campaigns/view/fundraisers/${campaign.id}`} push>
                  <Common.PageHeader.Breadcrumb label={campaign.name} />
                </Link>
                <Common.PageHeader.Breadcrumb label={pageName} />
              </Common.PageHeader>
            </Grid.Column>
            <Grid.Column width={2}>
              <Dropdown
                selection
                style={{ marginBottom: '0px' }}
                options={dropdownOptions}
                loading={isStatusLoading}
                defaultValue={this.props.record.data.data.status}
                onChange={this.onDropdownItemChange}
                icon="chevron down"
              ></Dropdown>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <FundraiserTemplate
          header="template.fundraiser.content.detail.fundraiser-header"
          onSave={this.onSave}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

const mapState = ({ campaignPage, templateBuilderEx, tree, session }) => {
  return {
    record: campaignPage.record,
    saveRecord: campaignPage.saveRecord,
    statusRecord: campaignPage.statusRecord,
    isTemplateInited: templateBuilderEx.inited,
    fundraisersCountRecord: campaignPage.fundraisersCountRecord,
    linkedCollectionRecord: get(tree, 'collectionId.record'),
    parentCollectionRecord: get(tree, 'parentId.record'),
    redirectUrl: session.redirectUrl
  };
};

const mapDispatch = (dispatch) => {
  return {
    pageActions: bindActionCreators(pageActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch)
  };
};

const EditPageContainer = withRouter(
  clear(connect(mapState, mapDispatch)(EditPage))
);
export default EditPageContainer;
