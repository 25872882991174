import React from 'react';

import { I18n } from 'react-redux-i18n';

export const DonationStatusBadgeSectionRow = (props) => {
  const {
    title,
    primaryValue,
    secondaryValue,
    primaryBackgroundColor,
    secondaryBackgroundColor
  } = props;

  return (
    <div className="view-details-content-row">
      <h4>{I18n.t(title)}</h4>
      <span
        className="status-bedge primary"
        style={{ background: primaryBackgroundColor }}
      >
        {primaryValue}
      </span>
      {secondaryValue && (
        <span
          className="status-bedge secondary"
          style={{ background: secondaryBackgroundColor }}
        >
          {secondaryValue}
        </span>
      )}
    </div>
  );
};
