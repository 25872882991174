import config from '../../constants';

export const registerPaths = {
  firstName: 'adminFirstName',
  lastName: 'adminLastName',
  email: 'adminEmail',
  organizationName: 'name',
  organizationCountry: 'country',
  organizationLegalName: 'legalName',
  organizationTaxId: 'taxId',
  termsAndConditions: 'termsAndConditions'
};

export const registerValidationModels = {
  firstName: {
    required: true,
    maxLength: true,
    length: config.ACCOUNT_NAME_MAX_LENGTH,
    path: registerPaths.firstName
  },
  lastName: {
    required: true,
    maxLength: true,
    length: config.ACCOUNT_NAME_MAX_LENGTH,
    path: registerPaths.lastName
  },
  email: {
    required: true,
    email: true,
    maxLength: true,
    length: config.ACCOUNT_EMAIL_MAX_LENGTH,
    path: registerPaths.email
  },
  organizationName: {
    required: true,
    maxLength: true,
    length: config.ORGANIZATION_NAME_MAX_LENGTH,
    path: registerPaths.organizationName
  },
  organizationCountry: {
    required: true,
    path: registerPaths.organizationCountry
  },
  organizationLegalName: {
    required: true,
    maxLength: true,
    length: config.ORGANIZATION_LEGAL_NAME_MAX_LENGTH,
    path: registerPaths.organizationLegalName
  },
  organizationTaxId: {
    required: true,
    maxLength: true,
    length: config.ORGANIZATION_TAX_ID_MAX_LENGTH,
    path: registerPaths.organizationTaxId
  },
  termsAndConditions: {
    required: true,
    path: registerPaths.termsAndConditions
  }
};
