import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { organizationActions } from '../store/organizationActions';
import RecordResultMessage from '../../../components/common/recordResult';
import { Record } from '../../../modules/bin/utility';
import * as generalActions from '../../../modules/general';
import OrganizationTemplate from '../templateEx';
import { TemplateOptions } from '../../../components/template/templateOptions';
import clear from '../../../components/clear';

class CreateOrganization extends Component {
  onValueChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  onSave = (data) => {
    this.props.organizationActions.createOrganization(data);
  };

  goToEditPage = () => {
    this.props.history.push(
      `/organizations/edit/${this.props.saveRecord.data.id}/finance`
    );
  };

  clearMessages = () => {
    this.props.organizationActions.clearRecordMessages('saveRecord');
  };

  initTemplateBuilderModel = () => {
    this.props.templateBuilderActions.setTemplateModel(getOptions());
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.saveRecord);

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    return (
      <div>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToEditPage}
        />
        <h1>
          <Translate value="organization.create" />
        </h1>
        <OrganizationTemplate onSave={this.onSave} isLoading={isSaving} />
      </div>
    );
  }
}

const mapState = ({ organization, templateBuilderEx }) => {
  return {
    template: organization.template,
    saveRecord: organization.saveRecord,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    organizationActions: bindActionCreators(organizationActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const getOptions = () => {
  return new TemplateOptions()
    .setModel({
      template: {
        value: {
          hasDGR: false,
          invertPrimaryTextColor: false,
          invertSecondaryTextColor: false,
          primaryColor: '#FF0000',
          secondaryColor: '#0000FF'
        }
      }
    })
    .hide('paymentPlatforms')
    .hide('crmPlatforms')
    .hide('compliance')
    .hide('template.value.allowCRMIntegration')
    .hide('recurring-giving')
    .hide('template.value.customDomain.domain')
    .hide('publicDashboard')
    .get();
};

const CreateOrganizationContainer = clear(
  connect(mapState, mapDispatch)(CreateOrganization)
);
export default CreateOrganizationContainer;
