import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import {
  Grid as SemanticGrid,
  Form as SemanticForm,
  Container,
  Label,
  Menu
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { Common } from '../..';
import { getState, getValue } from '../templateHelper';
import * as validationHelper from '../../../helpers/validationHelper';
import { withRouter, Link } from 'react-router-dom';
import update from '../../../helpers/update';

class TemplateBuilderEx extends Component {
  constructor(props) {
    super(props);

    const activePageId = this.getActivePageId();
    const visitedPages = { [activePageId]: true };
    this.state = {
      visitedPages
    };
  }

  componentDidUpdate() {
    const activePageId = this.getActivePageId();
    if (!this.state.visitedPages[activePageId]) {
      this.setState((state) => {
        return update(state, {
          visitedPages: { $merge: { [activePageId]: true } }
        });
      });
    }
  }

  componentWillReceiveProps(newProps) {
    if (this.props.match.params.step !== newProps.match.params.step) {
      window && window.scrollTo && window.scrollTo(0, 0);
    }
  }

  getActivePageId = () => {
    const childrenArray = this.getPagesArray();
    let activePage = childrenArray[0].props.id;

    if (this.props.match.params.step) {
      activePage = this.props.match.params.step;
    }

    return activePage;
  };

  onItemValueChange = (path, value) => {
    this.props.setResultValue(path, value);
  };

  getPageContent = () => {
    const pageId = this.getActivePageId();
    const templateBuilder = this.props.templateBuilder;

    // children === TemplatePage components
    return React.Children.map(this.props.children, (child, i) => {
      // if we find needed page
      if (child && child.props.id === pageId) {
        // child.props.children - page components (TemplateInput, TemplateAutocomplete etc.)
        return React.Children.map(child.props.children, (childControl, i) => {
          if (!childControl) return null;
          if (!childControl.props.path || childControl.props.isolated) return childControl;

          const visible = getState(
            templateBuilder,
            childControl.props.path,
            'visible'
          );
          if (visible === false) return null;

          return childControl;
        });
      }
    });
  };

  areChildrenValid = (children) => {
    return React.Children.map(children, child => {
      if (!child || !React.isValidElement(child)) {
        return true;
      }

      if (child.props.validation) {
        const validation = child.props.validation;
        if (Array.isArray(validation)) {
          const isValidChildren = validation.map((item) => {
            return this.isControlValid(item.path, item.validation);
          });

          if (isValidChildren) {
            return !isValidChildren.some(item => item === false);
          }

          return true;
        }

        return this.isControlValid(child.props.path, validation);
      } else if (child.props.children) {
        const isValidChildren = this.areChildrenValid(child.props.children);

        if (isValidChildren) {
          return !isValidChildren.some(item => item === false);
        }

        return true;
      }
    });
  };

  isPageValid = (page) => {
    const isValidResults = this.areChildrenValid(page.props.children);

    if (isValidResults) {
      return !isValidResults.some((item) => item === false);
    }

    return true;
  };

  isControlValid = (path, validation) => {
    const templateBuilder = this.props.templateBuilder;

    const isVisible = getState(templateBuilder, path, 'visible');
    const value = getValue(templateBuilder, path);
    let optionalParam = null;
    if (validation && validation.optionalParamPath) {
      optionalParam = getValue(templateBuilder, validation.optionalParamPath);
    }

    if (isVisible) {
      if (!validationHelper.isValid(validation, value, optionalParam)) {
        return false;
      }
    }

    if (isVisible === undefined && validation) {
      return validationHelper.isValid(validation, value, optionalParam);
    }
  };

  getPagesArray = () => {
    return React.Children.toArray(this.props.children);
  };

  getPagesValidationStatus = () => {
    let statuses = {};
    const templateBuilder = this.props.templateBuilder;

    const childrenArray = this.getPagesArray();

    childrenArray.forEach((item, index) => {
      const visibleTab = getState(templateBuilder, item.props.path, 'visible');
      // if page controls are valid or page is not visible
      statuses[item.props.id] = this.isPageValid(item) || visibleTab === false;
    });

    return statuses;
  };

  getCurrentPage = () => {
    const childrenArray = this.getPagesArray();
    return childrenArray.find(
      (item) => item.props.id === this.getActivePageId()
    );
  };

  getVisibleControls = (children) => {
    const result = React.Children.map(children, child => {
      if (!child || !React.isValidElement(child)) {
        return true;
      }

      if (child.props.isolated) {
        return true;
      } else if (child.props.path) {
        const visible = getState(
          this.props.templateBuilder,
          child.props.path,
          'visible'
        );

        return visible !== false;
      } else if (child.props.children) {
        const visibleControls = this.getVisibleControls(child.props.children);

        if (visibleControls) {
          return !visibleControls.some(item => item === false);
        }

        return true;
      }
    });
    return result;
  }

  getVisiblePages = () => {
    const templateBuilder = this.props.templateBuilder;
    const pagesArray = this.getPagesArray();

    return pagesArray.filter((item) => {
      const visibleTab = getState(templateBuilder, item.props.path, 'visible');

      const visibleControls = this.getVisibleControls(item.props.children);

      // if we have child controls
      // and there is any visible - show step
      // and tab is visible
      if (
        visibleControls &&
        visibleControls.some((x) => x === true) &&
        visibleTab !== false
      ) {
        return true;
      }
      return false;
    });
  };

  getPageInfo() {
    let pageInfo = {};

    const visiblePages = this.getVisiblePages();

    visiblePages.forEach((item, index) => {
      if (item.props.id === this.getActivePageId()) {
        pageInfo.previous =
          index >= 1 ? visiblePages[index - 1].props.id : undefined;
        pageInfo.next =
          index < visiblePages.length - 1
            ? visiblePages[index + 1].props.id
            : undefined;
        return;
      }
    });

    pageInfo.pageStatuses = this.getPagesValidationStatus();
    const page = this.getCurrentPage();
    pageInfo.isCurrentPageValid = page ? this.isPageValid(page) : false;
    pageInfo.isNextButtonDisabled =
      !pageInfo.next || !pageInfo.isCurrentPageValid;
    pageInfo.isPublishDisabled = !this.isAllPreviousTabsValid(
      pageInfo.pageStatuses,
      visiblePages,
      visiblePages.length
    );

    return pageInfo;
  }

  onCancel = () => {
    this.props.onCancel(this.props.templateBuilder.data);
  };

  onSave = () => {
    this.props.onSave(this.props.templateBuilder.data);
  };

  isAllPreviousTabsValid = (pageStatuses, visiblePages, index) => {
    const prevPagesStatuses = visiblePages
      .map((item) => pageStatuses[item.props.id])
      .filter((item, itemIndex) => itemIndex < index);
    return prevPagesStatuses.filter((item) => item === false).length === 0;
  };

  isAllPreviousTabsVisited = (visiblePages, index) => {
    const prevPagesStatuses = visiblePages
      .map((item) => this.state.visitedPages[item.props.id])
      .filter((item, itemIndex) => itemIndex < index);
    return prevPagesStatuses.filter((item) => !item).length === 0;
  };

  isAllTabsValid = (pageStatuses) => {
    return !Object.keys(pageStatuses).some(
      (item) => pageStatuses[item] === false
    );
  };

  getPages = (pageInfo) => {
    const visiblePages = this.getVisiblePages();

    return visiblePages.map((child, index) => {
      if (!child) {
        return null;
      }

      const pageId = child.props.id;
      const isEdit = this.props.templateBuilder.isEdit;
      const isAllPrevValid = this.isAllPreviousTabsValid(
        pageInfo.pageStatuses,
        visiblePages,
        index
      );
      const isAllPrevVisited = !isEdit
        ? this.isAllPreviousTabsVisited(visiblePages, index)
        : true;
      const isCurrentValidAndItemIsNext =
        pageId === pageInfo.next && pageInfo.isCurrentPageValid;
      const isDisabled =
        (!isAllPrevValid && !isCurrentValidAndItemIsNext) || !isAllPrevVisited;
      const pageValid = pageInfo.pageStatuses[child.props.id];
      const pageVisited = this.state.visitedPages[pageId];
      const showCheckmark = !isEdit ? pageValid && pageVisited : pageValid;
      const active = this.getActivePageId() === pageId;

      return (
        <Common.Pager.LinkItem
          id={pageId}
          key={`${index}${pageId}`}
          index={index}
          disabled={isDisabled}
          showCheckmark={showCheckmark}
          active={active}
        >
          {child.props.i18nKey &&
            I18n.t(child.props.i18nKey, child.props.i18nObject)}
        </Common.Pager.LinkItem>
      );
    });
  };

  getPageButtons = (pageInfo) => {
    const tipMessage = (
      <Label pointing="right">
        <Translate value="general.list.explanationLabel" />
      </Label>
    );
    const cancelButtonLabel = this.props.customCancelButtonLabel;
    const cancelButton = (
      <Common.Button
        key="cancelAll"
        id="cancelAll"
        style={{ minWidth: '100px', whiteSpace: 'nowrap' }}
        onClick={this.onCancel.bind(this)}
      >
        {cancelButtonLabel ? cancelButtonLabel : <Translate value="Cancel" />}
      </Common.Button>
    );
    const nextButton = (
      <Common.Button
        key="next"
        id="next"
        primary
        disabled={pageInfo.isNextButtonDisabled}
      >
        <Translate value="general.list.next" />
      </Common.Button>
    );
    return (
      <SemanticGrid columns="equal">
        <SemanticGrid.Row>
          {!pageInfo.previous && this.props.showCancelButton && (
            <SemanticGrid.Column width={4}>{cancelButton}</SemanticGrid.Column>
          )}
          {pageInfo.previous && (
            <SemanticGrid.Column
              className={`${this.props.showCancelButton ? 'cancel-button' : ''
                }`}
              width={4}
            >
              {this.props.showCancelButton && (
                <React.Fragment>{cancelButton}</React.Fragment>
              )}
              <Link to={pageInfo.previous} style={{ display: 'inline-table' }}>
                <Common.Button key="previous">
                  <Translate value="general.list.previous" />
                </Common.Button>
              </Link>
            </SemanticGrid.Column>
          )}
          {pageInfo.next && (
            <SemanticGrid.Column textAlign="right">
              {pageInfo.isNextButtonDisabled && tipMessage}
              {!pageInfo.isNextButtonDisabled && (
                <Link to={pageInfo.next} style={{ display: 'inline-table' }}>
                  {nextButton}
                </Link>
              )}
              {pageInfo.isNextButtonDisabled && nextButton}
              {this.props.templateBuilder.isEdit && (
                <Common.Button
                  key="saveAll"
                  id="saveAll"
                  primary
                  disabled={
                    this.props.isLoading ||
                    pageInfo.isPublishDisabled ||
                    !this.isAllTabsValid(pageInfo.pageStatuses)
                  }
                  loading={this.props.isLoading}
                  style={{ minWidth: '100px' }}
                  onClick={this.onSave.bind(this)}
                >
                  <Translate value="general.list.save-all" />
                </Common.Button>
              )}
            </SemanticGrid.Column>
          )}
          {!pageInfo.next && (
            <SemanticGrid.Column textAlign="right">
              {pageInfo.isPublishDisabled && tipMessage}
              {this.props.customTemplateActionButton &&
                !this.props.templateBuilder.isEdit &&
                this.props.customTemplateActionButton(
                  this.props.isLoading || pageInfo.isPublishDisabled,
                  this.props.isLoading
                )}
              <Common.Button
                key="publish"
                id="publish"
                primary
                disabled={this.props.isLoading || pageInfo.isPublishDisabled}
                loading={this.props.isLoading}
                style={{ minWidth: '100px' }}
                onClick={this.onSave.bind(this)}
              >
                <Translate value="general.list.publish" />
              </Common.Button>
            </SemanticGrid.Column>
          )}
        </SemanticGrid.Row>
      </SemanticGrid>
    );
  };

  render() {
    const pageInfo = this.getPageInfo();

    const pageActions = this.props.pageActions;
    let columns;
    let contentWidth;

    if (!this.props.hideMenu) {
      columns = 2;
      contentWidth = 11;
    }

    return (
      <SemanticGrid className={this.props.className}>
        <SemanticGrid.Row columns={columns}>
          {!this.props.hideMenu && (
            <SemanticGrid.Column width={5}>
              <Menu className="page-content-menu" vertical fluid>
                {this.getPages(pageInfo)}
              </Menu>
              {pageActions}
            </SemanticGrid.Column>
          )}
          <SemanticGrid.Column
            width={contentWidth}
            className="template-content-wrapper"
          >
            <div className="template-content-inner">
              <SemanticForm>
                <Container>{this.getPageContent()}</Container>
              </SemanticForm>
            </div>
            {this.getPageButtons(pageInfo)}
          </SemanticGrid.Column>
        </SemanticGrid.Row>
      </SemanticGrid>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    templateBuilder: state.templateBuilderEx
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const TemplateBuilderContainerEx = withRouter(
  connect(mapState, mapDispatch)(TemplateBuilderEx)
);
export default TemplateBuilderContainerEx;
