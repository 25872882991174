import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as templateHelper from '../../template/templateHelper';
import { stringToArray, arrayToString } from '../../../helpers/arrayHelper';
import * as validationHelper from '../../../helpers/validationHelper';

import { Form } from '../..';
import { TemplateHeader } from '../../template/templateHeader';
import { Message } from 'semantic-ui-react';

import { fitnessMeasureOptions } from '../../../pages/campaign/constants';
import { campaignContract } from '../../../pages/campaign/create/templates';

class FitnessTrackedStatisticsContainer extends Component {
  isFitnessTrackedStatisticChecked = (option) => {
    return stringToArray(this.props.value).some((item) => item === option);
  };

  handleFitnessTrackedStatisticChange = (value, evt, { checked }) => {
    const fitnessTrackedStatistics = stringToArray(this.props.value);

    if (checked) {
      fitnessTrackedStatistics.push(value);
    } else {
      const fitnessTrackedStatisticIndex = fitnessTrackedStatistics.findIndex(
        (item) => item === value
      );

      if (fitnessTrackedStatisticIndex !== -1) {
        fitnessTrackedStatistics.splice(fitnessTrackedStatisticIndex, 1);
      }
    }

    const visibleTargets = this.props.targets.filter((item) =>
      fitnessTrackedStatistics.some((option) => item.key === option)
    );
    const visibleThermometersToShow = this.props.thermometersToShow.filter(
      (item) => fitnessTrackedStatistics.some((option) => item === option)
    );
    const visibleLeaderboardsToShow = this.props.leaderboardsToShow.filter(
      (item) => fitnessTrackedStatistics.some((option) => item === option)
    );

    this.props.onChange(
      campaignContract.fitnessAllowedTrackedStatistics,
      arrayToString(fitnessTrackedStatistics)
    );
    this.props.onChange(campaignContract.fitnessTargets, visibleTargets);
    this.props.onChange(
      campaignContract.fitnessThermometersToShow,
      visibleThermometersToShow
    );
    this.props.onChange(
      campaignContract.fitnessLeaderboardsToShow,
      visibleLeaderboardsToShow
    );
  };

  getFitnessTrackedStatistics = () => {
    return fitnessMeasureOptions.map((option) => {
      const isChecked = this.isFitnessTrackedStatisticChecked(option.value);

      return (
        <React.Fragment>
          {option.isVisible(stringToArray(this.props.activities)) && (
            <Form.Checkbox
              key={option.key}
              inputLabel={I18n.t(
                `template.page.content.fitness.fitnessTrackedStatistics.options.${option.i18nKey}`
              )}
              checked={isChecked}
              onChange={this.handleFitnessTrackedStatisticChange.bind(
                this,
                option.value
              )}
            />
          )}
        </React.Fragment>
      );
    });
  };

  render() {
    const fitnessTrackedStatistics = this.getFitnessTrackedStatistics();

    return (
      <React.Fragment>
        <TemplateHeader
          as="h4"
          label="template.page.content.fitness.fitnessTrackedStatistics.label"
        />
        {fitnessTrackedStatistics}
        <Message negative hidden={this.props.isValid}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(
    state.templateBuilderEx,
    campaignContract.fitnessAllowedTrackedStatistics
  );
  const activities = templateHelper.getValue(
    state.templateBuilderEx,
    campaignContract.fitnessAllowedTypes
  );
  return {
    value: value,
    activities: activities,
    targets:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessTargets
      ) || [],
    thermometersToShow:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessThermometersToShow
      ) || [],
    leaderboardsToShow:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.leaderboardsToShow
      ) || [],
    isValid: validationHelper.isValid(ownProps.validation, {
      allowedTrackedStatistics: value,
      allowedTypes: activities
    }),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      { allowedTrackedStatistics: value, allowedTypes: activities }
    )
  };
};

const FitnessTrackedStatistics = connect(mapState)(
  FitnessTrackedStatisticsContainer
);
export default FitnessTrackedStatistics;
