import React, { Component } from 'react';

// CONSTANTS
import { securitySettingTabs } from '../../../constants';

class Stepper extends Component {
  render() {
    return (
      <div>
        <ul className="progress-bar">
          <li
            className={`${
              this.props.activeTab === securitySettingTabs.QR_CODE
                ? 'active'
                : ''
            }`}
          ></li>
          <li
            className={`${
              this.props.activeTab === securitySettingTabs.VERIFICATION
                ? 'active'
                : ''
            }`}
          ></li>
          <li
            className={`${
              this.props.activeTab === securitySettingTabs.BACK_UP_CODES
                ? 'active'
                : ''
            }`}
          ></li>
        </ul>
      </div>
    );
  }
}

export default Stepper;
