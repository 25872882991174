import ColumnModel from '../../../components/common/grid/columnModel';
import { columnTypes } from '../../../components/common/grid/constants';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import {
  getCampaignClickableColumnForCampaignFundraisers,
  getTeamClickableColumnUrl,
  getFundraiserClickableColumnUrl,
  getCollectionClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';
import { availableFiltersKeys } from '../constants';

export const getDonationGrid = (translationKey: string, pageSize?: number) => {
  return new GridOptions()
    .setPageSize(pageSize)
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('pageType', `${translationKey}.page-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .makeClickable(getTeamClickableColumnUrl, true, false)
        .visibleByDefault(),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel('financials.currency', `${translationKey}.currency`),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials-refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials-refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.phoneNumber',
        `${translationKey}.donor-phone-number`
      ),
    ])
    .orderBy('donatedAtLocal', 'desc')
    .addAvailableFiltersKeys(availableFiltersKeys)
    .getState();
};
