import React, { Component } from 'react';
import * as querystring from 'querystring';
import * as salesforceActions from './reducer';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Record } from '../../modules/bin/utility';
import { Loader } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';

class SalesforceConnection extends Component {
  constructor(props) {
    super(props);
    this.connectWithSalesforce();
  }

  connectWithSalesforce = () => {
    const parsed = querystring.parse(this.props.location.search);
    const code = parsed['?code'];
    const state = parsed['state'];

    if (!state || !code) {
      this.props.history.push('/');
      return;
    }

    this.props.salesforceConnect(state, code);
  };

  render() {
    let text = '';
    if (Record.isRecordLoading(this.props.salesforceConnectRecord)) {
      text = I18n.t('salesforce.connection-message');
    }

    if (Record.isRecordReady(this.props.salesforceConnectRecord)) {
      if (this.props.salesforceConnectRecord.data === 0) {
        text = I18n.t('salesforce.error-message');
        setTimeout(() => this.props.history.push('/'), 3000);
      } else {
        text = I18n.t('salesforce.success-message');
        setTimeout(
          () =>
            this.props.history.push(
              `/organizations/edit/${this.props.salesforceConnectRecord.data}/crm`
            ),
          3000
        );
      }
    }

    return (
      <div>
        <Loader active content={text} />
      </div>
    );
  }
}

const mapState = ({ salesforce }) => {
  return {
    salesforceConnectRecord: salesforce.salesforceConnectRecord
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(salesforceActions, dispatch);
};

export default connect(mapState, mapDispatch)(SalesforceConnection);
