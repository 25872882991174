import '../../helpers/stringExstension';

function CapitalizeText(props) {
  const { children } = props;
  const capitalizeText =
    children && typeof children === 'string' ? children.capitalize() : null;

  return capitalizeText;
}

export default CapitalizeText;
