import {
  ORGANIZATION_ID_FILTER,
  IS_SYSTEM_ADMIN_FILTER
} from '../constants/metadataConstants';
import update from './update';

export const getListWithOrgFilterIfSysAdmin = (
  list,
  isSystemAdmin,
  filter = ORGANIZATION_ID_FILTER
) => {
  if (!isSystemAdmin) {
    return list;
  }
  return addListFilter(list, filter);
};

export const getListWithCustomFilterIfSysAdmin = (
  list,
  isSystemAdmin,
  filter
) => {
  if (!isSystemAdmin) {
    return list;
  }

  return addListFilter(list, filter);
};

export const getListWithFiltersIfEventOrganizerAdmin = (
  list,
  isEventOrganizerAdmin,
  customFilter
) => {
  if (!isEventOrganizerAdmin) {
    return list;
  }

  return addArrayListFilter(list, customFilter);
};

export const getListWithFiltersIfSysAdmin = (
  list,
  isEventOrganizerAdmin,
  customFilter
) => {
  if (!isEventOrganizerAdmin) {
    return list;
  }

  return addArrayListFilter(list, customFilter);
};

export const getListWithSystemAdminFilterIfSysAdmin = (list, isSystemAdmin) => {
  if (!isSystemAdmin) {
    return list;
  }
  return addListFilter(list, IS_SYSTEM_ADMIN_FILTER);
};

export const addListFilter = (list, filterKey) => {
  return update.set(list, 'availableFiltersKeys', [
    filterKey,
    ...list.availableFiltersKeys
  ]);
};

export const addArrayListFilter = (list, filterKeys) => {
  return update(list, {
    availableFiltersKeys: {
      $push: filterKeys
    }
  });
};
