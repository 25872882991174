import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import { Form } from '..';

class TemplateFormBuilderView extends Component {
  render() {
    return <Form.FormBuilderView value={this.props.value} />;
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export default connect(mapState)(TemplateFormBuilderView);
