import update from '../helpers/update';
import { CLEAR } from './general';
import { DELETE_IMPERSONATE_ADMIN_SUCCESS } from './session';
import { listFilterOperator, listFilterPath } from '../constants/aggregate';
import { AnyAction } from '@reduxjs/toolkit';

export const VALUE_SET = 'filter/VALUE_SET';
export const ON_INIT = 'filter/ON_INIT';
export const ON_APPLY = 'filter/ON_APPLY';
export const ON_CLEAR = 'filter/ON_CLEAR';

export const onValueChange = (path: any, value: any) => {
  return {
    type: VALUE_SET,
    payload: {
      path: path,
      value: value
    }
  };
};

export const onInit = (columns: any, listKey: any) => ({
  type: ON_INIT,
  payload: { columns, listKey }
});

export const onApply = (columns: any, listKey: any) => ({
  type: ON_APPLY,
  payload: { columns, listKey }
});

export const onClear = (columns: any, listKey: any) => ({
  type: ON_CLEAR,
  payload: { columns, listKey }
});

const initialState = {};

const filterReducer = (state = initialState, action: AnyAction) => {
  let newState = state;

  switch (action.type) {
    case CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
      newState = initialState;
      break;

    case VALUE_SET:
      newState = update.set(
        newState,
        `${action.payload.path}`,
        action.payload.value
      );
      break;

    case ON_INIT:
    case ON_CLEAR: {
      const { listKey, columns } = action.payload;
      newState = update.set(newState, `${listKey}.inited`, true);
      newState = update.set(newState, `${listKey}.columns`, { ...columns });
      break;
    }

    case ON_APPLY: {
      const { listKey, columns } = action.payload;

      if (!columns) return {};

      columns.forEach(
        (element: { key: any; operator: string; value: any; path: string }) => {
          newState = update.set(
            newState,
            `${listKey}.columns.${element.key}.checked`,
            true
          );

          if (element.operator === listFilterOperator.GREATER_OR_EQUAL_THAN) {
            newState = update.set(
              newState,
              `${listKey}.columns.${element.key}.value.from`,
              element.value
            );
            return;
          }
          if (element.operator === listFilterOperator.LESSER_OR_EQUAL_THAN) {
            newState = update.set(
              newState,
              `${listKey}.columns.${element.key}.value.to`,
              element.value
            );
            return;
          }
          if (element.operator === listFilterOperator.EQUAL_TO) {
            newState = update.set(
              newState,
              `${listKey}.columns.${element.key}.value`,
              mapFilterValue(element.value)
            );
            return;
          }
          if (element.path === listFilterPath.LABEL) {
            newState = update.set(
              newState,
              `${listKey}.columns.${element.key}.label`,
              element.value
            );
            return;
          }
        }
      );
      break;
    }
  }

  return newState;
};

const mapFilterValue = (value: string) => {
  if (value === 'true') {
    return true;
  }
  if (value === 'false') {
    return false;
  }
  return value;
};

export default filterReducer;
