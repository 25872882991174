import { HtttpRequestMethods, sendRequest } from "./request";
import { MerchandiseUrls } from "./constants"
import { IMerchandise, ISku } from "../merchandise.model";

export async function updateMerchandise(
  apikey: string,
  sessionKey: string,
  id: number,
  body: IMerchandise
) {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${MerchandiseUrls.Update}/${id}`,
    method: HtttpRequestMethods.Put,
    body,
  }).then((response) => response.json());
}

export async function updateMerchandiseSku(
  apikey: string,
  sessionKey: string,
  merchandiseId: number,
  id: number,
  body: ISku
) {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${MerchandiseUrls.Update}${merchandiseId}/sku/${id}`,
    method: HtttpRequestMethods.Put,
    body,
  }).then((response) => response.json());
}
