// React / Redux / Related
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// Actions
import * as templateBuilderActions from '../../modules/template-builder-ex';

// Helpers
import get from 'lodash.get';
import { getFromBuilderInitialData } from '../../helpers/formBuilderHelper';

// Components
import TemplateBuilderContainerEx from '../../components/template/builder/indexEx';
import { TemplateAutocomplete } from '../../components/template/templateAutocomplete';
import { TemplateInput } from '../../components/template/templateInput';
import { TemplateTextArea } from '../../components/template/templateTextArea';
import TemplateFormBuilder from '../../components/template/templateFromBuilder';
import { TemplateHeader } from '../../components/template/templateHeader';
import { TemplateCheckbox } from '../../components/template/templateCheckbox';
import TemplateEditableUpload from '../../components/template/templateEditableUpload';
import { TemplateSkuBuilder } from '../../components/template/custom/templateSkuBuilder';
import { TemplateText } from '../../components/template/templateText';
import { TemplateHorizontalRule } from '../../components/template/templateHorizontalRule';
import { TemplatePage } from '../../components/common/pager';

// Constants
import {
  imageFormats,
  entityTypes,
  imageTypes
} from '../../constants/imageUpload';
import { merchandiseContract } from './constants';
import { formBuilderKeys } from '../campaign/constants';
import config from '../../constants';

class MerchandiseTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.formBuilderQuestionsValidation = {
      formBuilderQuestions: true
    };

    this.skuBuilderValidation = {
      skuBuilder: true
    };

    this.descriptionValidation = {
      maxLength: true,
      length: config.MERCHANDISE_DESCRIPTION_MAX_LENGTH
    };

    this.photoPhormats = imageFormats;
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  onOrganizationChange = (path, value, item) => {
    this.props.setResultValue(
      'organization.country',
      get(item, 'address.country')
    );
    this.props.setResultValue(path, value);
  };

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getOrganizationItem = (item) => {
    if (item.paymentPlatforms && item.paymentPlatforms.length === 0) {
      return;
    }

    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  render() {
    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage
          id="merchandise-detail"
          i18nKey="template.merchandise.content.merchandise-detail.header"
        >
          <TemplateAutocomplete
            id={merchandiseContract.organization}
            entity="organization"
            path={merchandiseContract.organization}
            selected="organization"
            label="template.merchandise.content.merchandise-detail.organization.label"
            placeholder="template.merchandise.content.merchandise-detail.organization.placeholder"
            validation={this.required}
            onChange={this.onOrganizationChange}
            request={this.getOrganizationRequest}
            getItem={this.getOrganizationItem}
          />

          <TemplateInput
            id={merchandiseContract.name}
            path={merchandiseContract.name}
            label="template.merchandise.content.merchandise-detail.merchandise-name.label"
            placeholder="template.merchandise.content.merchandise-detail.merchandise-name.label"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateTextArea
            id={merchandiseContract.description}
            path={merchandiseContract.description}
            label="template.merchandise.content.merchandise-detail.merchandise-description.label"
            onChange={this.onChange}
            validation={this.descriptionValidation}
          />

          <TemplateHeader
            as="h5"
            label="template.merchandise.content.merchandise-detail.questions.label"
          />

          <TemplateFormBuilder
            formBuilderKey={formBuilderKeys.MERCHANDISE}
            path={merchandiseContract.formbuilder}
            initialData={getFromBuilderInitialData(formBuilderKeys.MERCHANDISE)}
            onChange={this.onChange}
            validation={this.formBuilderQuestionsValidation}
          />

          <TemplateEditableUpload
            id={merchandiseContract.image}
            path={merchandiseContract.image}
            entity={entityTypes.ORGANIZATION}
            position="left"
            height="350"
            width="350"
            format={this.photoPhormats}
            uploadType={imageTypes.LOGO}
            label="template.merchandise.content.merchandise-detail.merchandise-photo.label"
            subtext="template.merchandise.content.merchandise-detail.merchandise-photo.subtext"
            validation={this.required}
            onChange={this.onChange}
          />

          <TemplateCheckbox
            id={merchandiseContract.enabled}
            path={merchandiseContract.enabled}
            inputLabel="template.merchandise.content.merchandise-detail.enabled.checkbox"
            onChange={this.onChange}
          />

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.merchandise.content.merchandise-detail.skus.header"
          />

          <TemplateText
            label="template.merchandise.content.merchandise-detail.skus.label"
            size="small"
          />

          <TemplateSkuBuilder
            path={merchandiseContract.skus}
            onChange={this.onChange}
            validation={this.skuBuilderValidation}
          />
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  return {};
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const MerchandiseTemplate = withRouter(
  connect(mapState, mapDispatch)(MerchandiseTemplateView)
);
export default MerchandiseTemplate;
