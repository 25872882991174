import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as dashboardActions from './reducer';
import * as generalActions from '../../modules/general';

import moment from 'moment';
import get from 'lodash.get';
import clear from '../../components/clear';
import { Record as RecordHelper } from '../../modules/bin/utility';

import Spinner from '../../components/common/spinner';
import BasePage from '../../components/common/basePageView';
import { SummaryContainer } from './components/summary';
import { HeaderContainer } from './components/header';
import { FilterContainer } from './components/filter';
import GridContainer from './components/grid';
import ChartContainer from './components/chart';
import {
  dashboardAvailableFilterKeys,
  dashboardUrlFilters,
  dashboardUrlFiltersSettings,
  dashboardViewTypeConfigurations,
  timeLapse
} from './constants';
import { getTimePeriod } from '../../helpers/dateAndTimeHelper';
import { listFilterOperator } from '../../constants/aggregate';
import { isObject } from '../../helpers/objectHelper';
import { parseQuery } from '../../helpers/queryHelper';
import { getDashboardCreatedAt } from './helper';
import { ExportDownloadProvider } from '../export-history';

class Dashboard extends Component {
  componentDidMount() {
    const { view } = this.props.match.params;
    const configurations = dashboardViewTypeConfigurations[view];

    this.props.init(configurations);
    this.initFilters();
  }

  componentDidUpdate() {
    if (
      this.props.inited & RecordHelper.notStarted(this.props.modelRecord) &&
      !RecordHelper.isRecordLoading(this.props.modelRecord)
    ) {
      this.loadData();
    }
  }

  initFilters = () => {
    const queryFilters = parseQuery(this.props.location.search);

    Object.keys(queryFilters).forEach((filterKey) => {
      if (dashboardAvailableFilterKeys.some((x) => x === filterKey)) {
        if (dashboardUrlFiltersSettings[filterKey]) {
          this.props.onValueChange(
            dashboardUrlFiltersSettings[filterKey].getPath(),
            queryFilters[filterKey]
          );
        }
      }
    });
  };

  getCreatedAtLocal = () => {
    const entity = this.props.configurations?.entity;
    const modelRecord = this.props.modelRecord?.data?.data;
    return getDashboardCreatedAt(entity, modelRecord);
  };

  loadData = () => {
    const { id } = this.props.match.params;

    const timePeriod = get(this.props, 'timePeriod');
    const dateFrom = get(timePeriod, 'dateFrom') || null;
    const dateTo = get(timePeriod, 'dateTo') || null;

    this.props.getModelRecord(id, this.props.configurations.path);
    this.props.getSummaryRecord(
      id,
      this.props.configurations.entity,
      this.props.timeBracket,
      dateFrom,
      dateTo
    );
  };

  getSummaryData = () => {
    const { id } = this.props.match.params;
    const createdAtLocal = this.getCreatedAtLocal();
    const timePeriod = getTimePeriod(
      createdAtLocal,
      this.props.reportByDateAndMetricType,
      this.props.timeLapse,
      this.props.timeLapseFrom,
      this.props.timeLapseTo
    );

    const request = dashboardActions.getSummaryDataRecordRequest(
      id,
      this.props.configurations.baseFilter,
      timePeriod,
      this.props.timeBracket,
      this.props.reportByDateAndMetricType
    );

    this.props.getSummaryDataRecord(request);
  };

  isAllRecordsReady = () => {
    return RecordHelper.isRecordReady(this.props.modelRecord);
  };

  handleEditClick = () => {
    const { id } = this.props.match.params;
    const url = this.props.configurations.getEditUrl(id);

    this.props.history.push(url);
  };

  getQueryFilter = (key, value) => {
    return {
      key: key,
      value: [value]
    };
  };

  applyQueryFilters = (
    timePeriod,
    timeBracket,
    reportByDateAndMetricType,
    gridTab,
    timeLapse,
    timeLapseFrom,
    timeLapseTo
  ) => {
    let filters = {};

    const id = this.props.match.params.id;

    const summaryTypeFilter = this.getQueryFilter(
      dashboardUrlFilters.SUMMARY_TYPE,
      this.props.summaryType
    );
    const gridTabFilter = this.getQueryFilter(
      dashboardUrlFilters.GRID_TAB,
      gridTab
    );
    const timeLapseFilter = this.getQueryFilter(
      dashboardUrlFilters.TIME_LAPSE,
      timeLapse
    );
    const timeLapseFromFilter = this.getQueryFilter(
      dashboardUrlFilters.TIME_LAPSE_FROM,
      timeLapseFrom
    );
    const timeLapseToFilter = this.getQueryFilter(
      dashboardUrlFilters.TIME_LAPSE_TO,
      timeLapseTo
    );

    if (
      timePeriod.dateFrom ||
      timePeriod.dateTo ||
      timeBracket ||
      reportByDateAndMetricType
    ) {
      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        timeBracket,
        reportByDateAndMetricType
      );

      if (timeLapse === 'customRange') {
        if (timeLapseFrom && !timeLapseTo) {
          filters = [
            ...get(request, 'filters'),
            summaryTypeFilter,
            gridTabFilter,
            timeLapseFilter,
            timeLapseFromFilter
          ];
        } else if (!timeLapseFrom && timeLapseTo) {
          filters = [
            ...get(request, 'filters'),
            summaryTypeFilter,
            gridTabFilter,
            timeLapseFilter,
            null,
            timeLapseToFilter
          ];
        } else if (timeLapseFrom && timeLapseTo) {
          filters = [
            ...get(request, 'filters'),
            summaryTypeFilter,
            gridTabFilter,
            timeLapseFilter,
            timeLapseFromFilter,
            timeLapseToFilter
          ];
        } else {
          filters = [
            ...get(request, 'filters'),
            summaryTypeFilter,
            gridTabFilter,
            timeLapseFilter
          ];
        }
      } else {
        filters = [
          ...get(request, 'filters'),
          summaryTypeFilter,
          gridTabFilter,
          timeLapseFilter
        ];
      }
    }

    this.setQueryFilters(filters);
  };

  setQueryFilters = (filters) => {
    const queryFilters = filters
      .map((filter) => {
        if (!filter.value) {
          return null;
        }

        if (isObject(filter.value)) {
          if (
            filter.key === 'LocalDate' &&
            filter.operator === listFilterOperator.GREATER_OR_EQUAL_THAN
          ) {
            return `${filter.key}[from]=${filter.value}`;
          }
          if (
            filter.key === 'LocalDate' &&
            filter.operator === listFilterOperator.LESSER_OR_EQUAL_THAN
          ) {
            return `${filter.key}[to]=${filter.value}`;
          } else {
            return `${filter.key}=${filter.value}`;
          }
        }
        return null;
      })
      .filter((x) => x)
      .join('&');

    var newUrl =
      window.location.origin +
      window.location.pathname +
      (queryFilters ? `?${queryFilters}` : '');

    window.history.replaceState({ path: newUrl }, '', newUrl);
  };

  handleRefreshClick = () => {
    // this.setQueryFilters([]);
    this.loadData();
    this.getSummaryData();
    this.props.clearListsReadyState();
  };

  getExportRequest = () => {
    const { id } = this.props.match.params;
    const createdAtLocal = this.getCreatedAtLocal();
    const timePeriod = getTimePeriod(
      createdAtLocal,
      this.props.reportByDateAndMetricType,
      this.props.timeLapse,
      this.props.timeLapseFrom,
      this.props.timeLapseTo
    );
    return dashboardActions.getSummaryDataRecordRequest(
      id,
      this.props.configurations.baseFilter,
      timePeriod,
      this.props.timeBracket,
      this.props.reportByDateAndMetricType
    );
  };

  handleReportByDateAndMetricTypeChange = (event, { value }) => {
    if (value !== this.props.reportByDateAndMetricType) {
      this.props.onValueChange('reportByDateAndMetricType', value);

      const id = this.props.match.params.id;
      const newTimeLapse = timeLapse[value].allTime;
      const createdAtLocal = this.getCreatedAtLocal();

      if (this.props.timeLapse === 'customRange') {
        this.props.onValueChange('timeLapseFrom', null);
        this.props.onValueChange('timeLapseTo', null);
      }

      const timePeriod = getTimePeriod(createdAtLocal, value, newTimeLapse);

      this.props.onValueChange('timePeriod', timePeriod);
      this.props.onValueChange('timeLapse', newTimeLapse);

      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        this.props.timeBracket,
        value
      );

      this.applyQueryFilters(
        timePeriod,
        this.props.timeBracket,
        value,
        this.props.gridTab,
        newTimeLapse,
        this.props.timeLapseFrom,
        this.props.timeLapseTo
      );

      this.props.getSummaryDataRecord(request);

      this.props.getSummaryRecord(
        id,
        this.props.configurations.entity,
        this.props.timeBracket,
        timePeriod.dateFrom,
        timePeriod.dateTo
      );
    }
  };

  handleTimeLapseChange = (event, { value }) => {
    if (value !== this.props.timeLapse) {
      this.props.onValueChange('timeLapse', value);

      this.props.onValueChange('timeLapseFrom', null);
      this.props.onValueChange('timeLapseTo', null);

      const id = this.props.match.params.id;
      const createdAtLocal = this.getCreatedAtLocal();
      const timePeriod = getTimePeriod(
        createdAtLocal,
        this.props.reportByDateAndMetricType,
        value
      );

      this.props.onValueChange('timePeriod', timePeriod);

      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType
      );

      this.applyQueryFilters(
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType,
        this.props.gridTab,
        value,
        this.props.timeLapseFrom,
        this.props.timeLapseTo
      );

      this.props.getSummaryDataRecord(request);

      this.props.getSummaryRecord(
        id,
        this.props.configurations.entity,
        this.props.timeBracket,
        timePeriod.dateFrom,
        timePeriod.dateTo
      );
    }
  };

  handleTimeLapseCustomRangeFromChange = (value) => {
    this.props.onValueChange('timeLapseFrom', value);

    if (!value || moment(value).isValid()) {
      const { id } = this.props.match.params;
      const createdAtLocal = this.getCreatedAtLocal();
      const timePeriod = getTimePeriod(
        createdAtLocal,
        this.props.reportByDateAndMetricType,
        this.props.timeLapse,
        value,
        this.props.timeLapseTo
      );

      this.props.onValueChange('timePeriod', timePeriod);

      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType
      );

      this.applyQueryFilters(
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType,
        this.props.gridTab,
        'customRange',
        value,
        this.props.timeLapseTo
      );

      this.props.getSummaryDataRecord(request);

      this.props.getSummaryRecord(
        id,
        this.props.configurations.entity,
        this.props.timeBracket,
        timePeriod.dateFrom,
        timePeriod.dateTo
      );
    }
  };

  handleTimeLapseCustomRangeToChange = (value) => {
    this.props.onValueChange('timeLapseTo', value);

    if (!value || moment(value).isValid()) {
      const id = this.props.match.params.id;
      const createdAtLocal = this.getCreatedAtLocal();
      const timePeriod = getTimePeriod(
        createdAtLocal,
        this.props.reportByDateAndMetricType,
        this.props.timeLapse,
        this.props.timeLapseFrom,
        value
      );

      this.props.onValueChange('timePeriod', timePeriod);

      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType
      );

      this.applyQueryFilters(
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType,
        this.props.gridTab,
        'customRange',
        this.props.timeLapseFrom,
        value
      );

      this.props.getSummaryDataRecord(request);

      this.props.getSummaryRecord(
        id,
        this.props.configurations.entity,
        this.props.timeBracket,
        timePeriod.dateFrom,
        timePeriod.dateTo
      );
    }
  };

  handleTimeBracketChange = (event, { value }) => {
    if (value !== this.props.timeBracket) {
      this.props.onValueChange('timeBracket', value);

      const id = this.props.match.params.id;
      const createdAtLocal = this.getCreatedAtLocal();
      const timePeriod = getTimePeriod(
        createdAtLocal,
        this.props.reportByDateAndMetricType,
        this.props.timeLapse,
        this.props.timeLapseFrom,
        this.props.timeLapseTo
      );

      this.props.onValueChange('timePeriod', timePeriod);

      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        value,
        this.props.reportByDateAndMetricType
      );

      this.applyQueryFilters(
        timePeriod,
        value,
        this.props.reportByDateAndMetricType,
        this.props.gridTab,
        this.props.timeLapse,
        this.props.timeLapseFrom,
        this.props.timeLapseTo
      );

      this.props.getSummaryDataRecord(request);

      this.props.getSummaryRecord(
        id,
        this.props.configurations.entity,
        value,
        timePeriod.dateFrom,
        timePeriod.dateTo
      );
    }
  };

  handleSummaryClick = (value) => {
    this.props.onValueChange('summaryType', value);

    this.applyQueryFilters(
      this.props.timePeriod,
      this.props.timeBracket,
      this.props.reportByDateAndMetricType,
      this.props.gridTab,
      this.props.timeLapse,
      this.props.timeLapseFrom,
      this.props.timeLapseTo
    );
  };

  render() {
    const model = get(this.props, 'modelRecord.data.data');
    const summary = get(this.props, 'summaryRecord.data.data');
    const name = get(model, 'name');

    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    return (
      <BasePage>
        <div className="dashboard-container">
          <ExportDownloadProvider entity="report">
            {({ open }) => (
              <HeaderContainer
                name={name}
                onEditClick={
                  this.props.configurations.getEditUrl
                    ? this.handleEditClick
                    : null
                }
                onRefreshClick={this.handleRefreshClick}
                onExportClick={() => {
                  open(this.getExportRequest());
                }}
                isSystemAdmin={this.props.isSystemAdmin}
              >
                {model?.urlFull && (
                  <a
                    href={model?.urlFull}
                    className="Links"
                    target="_blank"
                    rel="noreferrer"
                  >
                    {model?.urlFull}
                  </a>
                )}
              </HeaderContainer>
            )}
          </ExportDownloadProvider>

          <div className="dashboard-top-area-container">
            <div className="dashboard-top-area">
              <FilterContainer
                reportByDateAndMetricType={this.props.reportByDateAndMetricType}
                timeLapse={this.props.timeLapse}
                timeLapseFrom={this.props.timeLapseFrom}
                timeLapseTo={this.props.timeLapseTo}
                timeBracket={this.props.timeBracket}
                timeBracketOptions={this.props.configurations.getTimeBracketOptions(
                  this.props.isSystemAdmin
                )}
                onReportByDateAndMetricTypeChange={
                  this.handleReportByDateAndMetricTypeChange
                }
                onTimeLapseChange={this.handleTimeLapseChange}
                onTimeLapseFromChange={
                  this.handleTimeLapseCustomRangeFromChange
                }
                onTimeLapseToChange={this.handleTimeLapseCustomRangeToChange}
                onTimeBracketChange={this.handleTimeBracketChange}
              />
              {!RecordHelper.isError(this.props.summaryRecord) && (
                <SummaryContainer
                  isLoading={
                    !RecordHelper.isRecordReady(this.props.summaryRecord)
                  }
                  model={model}
                  summary={summary}
                  disableModelValidation={
                    this.props.configurations.disableModelValidation
                  }
                  summaryType={this.props.summaryType}
                  onSummaryClick={this.handleSummaryClick}
                />
              )}
            </div>
            <div className="dashboard-chart-area">
              <ChartContainer />
            </div>
          </div>
          <div className="dasboard-table-area">
            <GridContainer applyQueryFilters={this.applyQueryFilters} />
          </div>
        </div>
      </BasePage>
    );
  }
}

const mapState = ({ dashboard, session }) => {
  return {
    inited: dashboard.inited,
    reportByDateAndMetricType: dashboard.reportByDateAndMetricType,
    timeLapse: dashboard.timeLapse,
    timeLapseFrom: dashboard.timeLapseFrom,
    timeLapseTo: dashboard.timeLapseTo,
    timePeriod: dashboard.timePeriod,
    timeBracket: dashboard.timeBracket,
    summaryType: dashboard.summaryType,
    configurations: dashboard.configurations,
    modelRecord: dashboard.modelRecord,
    summaryRecord: dashboard.summaryRecord,
    gridTab: dashboard.gridTab,
    isSystemAdmin: session.isSystemAdmin
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(dashboardActions, dispatch),
    ...bindActionCreators(generalActions, dispatch)
  };
};

const DashboardContainer = clear(
  withRouter(connect(mapState, mapDispatch)(Dashboard))
);
export default DashboardContainer;
