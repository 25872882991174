// React / Redux / Related
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n, Translate } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import BasePage from '../../components/common/basePageView';
import Grid from '../../components/common/grid';
import ContextMenu from '../../components/common/grid/contextMenu';
import { gridKeys } from '../../constants/gridKeys';
import { i18nKeys } from '../accounts/constants';
import { RootState } from '../../store';
import { useAppDispatch } from '../../hooks';
import RecordResultMessage from '../../components/common/recordResult';
import { Common } from '../../components';
import Panel from '../../components/common/grid/managed/panel';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import {
  clearWebhookErrors,
  getWebhookListData,
  getWebhookListDataBySearchTerm,
  getWebhookMetadata
} from './store/webhookActions';
import { DeleteWebhookModal } from './delete/DeleteWebhookModal';
import clear from '../../components/clear';
import { IWebhook } from '../../models/Webhook';

const getPanel = (onClick: () => void) => {
  return (
    <Panel name="right">
      <Common.Button id="btnCreateWebhook" primary onClick={onClick}>
        <Translate value="webhooks.page.view.create-action" />
      </Common.Button>
    </Panel>
  );
};

const getContextMenu = (
  onDeleteClick: (id: number, item: IWebhook) => void
) => (
  <ContextMenu>
    <ContextMenu.Item
      key={CONTEXT_MENU_KEYS.WEBHOOK_TABLE.EDIT.key}
      label={I18n.t(i18nKeys.WEBHOOKS_EDIT_ACTION)}
      contextMenuOptions={CONTEXT_MENU_KEYS.WEBHOOK_TABLE.EDIT}
    />
    <ContextMenu.Item
      key={CONTEXT_MENU_KEYS.WEBHOOK_TABLE.DELETE.key}
      label={I18n.t(i18nKeys.WEBHOOKS_DELETE_ACTION)}
      onClick={onDeleteClick}
    />
  </ContextMenu>
);

/** A page to view all events in a list */
const Webhooks = ({ history }: RouteComponentProps) => {
  const dispatch = useAppDispatch();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedWebhook, setSelectedWebhook] = useState<IWebhook>();
  const webhook = useSelector((state: RootState) => state.webhook);
  const session = useSelector((state: RootState) => state.session);
  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          getMetadata: getWebhookMetadata,
          getListData: getWebhookListData,
          clearRecordMessages: clearWebhookErrors,
          getListDataBySearchTerm: getWebhookListDataBySearchTerm
        },
        dispatch
      ),
    [dispatch]
  );

  const onDeleteClick = (_id: number, item: IWebhook) => {
    setSelectedWebhook(item);
    setIsDeleteModalOpen(true);
  };

  const clearMessages = () => {
    boundActionCreators.clearRecordMessages(gridKeys.WEBHOOKS);
  };

  useEffect(() => {
    if (session.isSystemAdmin) {
      webhook.webhooksList.columns.forEach((x) => {
        x.visible = true;
      });
    }
  }, [session.isSystemAdmin]);

  const panel = getPanel(() => history.push(`/webhooks/setup`));
  const contextMenu = getContextMenu(onDeleteClick);

  return (
    <>
      <RecordResultMessage
        record={webhook.record}
        onDismiss={() => dispatch(clearWebhookErrors('record'))}
      />
      <BasePage>
        <RecordResultMessage
          record={webhook.webhooksList}
          onDismiss={clearMessages}
        />
        <h1>
          <Translate value={i18nKeys.WEBHOOKS_TITLE} />
        </h1>

        <Grid.Managed
          listKey={gridKeys.WEBHOOKS}
          list={webhook.webhooksList}
          actions={{ ...boundActionCreators }}
          search
          filtersButton={session.isSystemAdmin}
        >
          {panel}
          {contextMenu}
        </Grid.Managed>
        <DeleteWebhookModal
          webhook={selectedWebhook}
          isOpen={isDeleteModalOpen}
          setIsOpen={setIsDeleteModalOpen}
        />
      </BasePage>
    </>
  );
};

export default clear(withRouter(Webhooks));
