import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import config from '../../constants';

class GeneralRedirect extends Component {
  render() {
    if (
      this.props.session &&
      !this.props.session.unauthorized &&
      this.props.session.organization &&
      this.props.session.isOrganizationAdmin
    ) {
      return (
        <Redirect
          to={`${config.DEFAULT_ORGANIZATION_DASHBOARD_URL}/${this.props.session.organization.id}`}
        />
      );
    }

    if (
      this.props.session &&
      !this.props.session.unauthorized &&
      this.props.session.isEventOrganizerAdmin
    ) {
      return <Redirect to={config.DEFAULT_PROGRAMS_URL} />;
    }

    if (this.props.session && !this.props.session.unauthorized) {
      return <Redirect to={config.DEFAULT_CAMPAIGNS_URL} />;
    }

    return null;
  }
}

const mapState = ({ session }) => ({
  session: session
});

export default connect(mapState, null)(GeneralRedirect);
