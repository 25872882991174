// React / Redux / Related
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { I18n, Translate } from 'react-redux-i18n';
import { useParams, useHistory, withRouter } from 'react-router-dom';

import BasePage from '../../components/common/basePageView';
import Grid from '../../components/common/grid';
import ContextMenu from '../../components/common/grid/contextMenu';
import { gridKeys } from '../../constants/gridKeys';
import { i18nKeys } from '../accounts/constants';
import { RootState } from '../../store';
import { useAppDispatch } from '../../hooks';
import RecordResultMessage from '../../components/common/recordResult';
import { Common } from '../../components';
import Panel from '../../components/common/grid/managed/panel';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import clear from '../../components/clear';
import { ITerminal } from '../../models/Terminal';
import {
  clearTerminalErrors,
  getTerminalListData,
  getTerminalListDataBySearchTerm,
  getTerminalMetadata,
  updateTerminalListData
} from './store/terminalActions';
import {
  AddDevice,
  AssignDevice,
  DeleteDevice,
  UnassignDevice
} from './components';
import { Menu } from 'semantic-ui-react';
import { tapNdonateFilterButtons } from './constants/tapNdonateFilterButtons';
import { RouteParams } from '../../containers/app';
import { terminalHeaders } from './constants';
import { EditDevice } from './components/EditDevice';
import { RecordResultMessageList } from '../../components/common/RecordResultList';

const getSelectedItems = (list: any) => {
  let selectedItems = [] as any[];
  for (const [_key, page] of Object.entries(list?.byPage)) {
    (page as any)?.forEach((x: any) => {
      if (x?.template?.value?.isChecked) {
        selectedItems.push(x);
      }
    });
  }
  return selectedItems;
};

const TapNdonate = () => {
  // hooks
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { tab } = useParams<RouteParams>();
  // states
  const [isUnassignedModalOpen, setIsUnassignedModalOpen] = useState(false);
  const [isAssignedModalOpen, setIsAssignedModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isDeleteModelOpen, setIsDeleteModelOpen] = useState(false);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [terminalList, setTerminalList] = useState<ITerminal[]>([]);
  const [terminal, setTerminal] = useState<ITerminal>({} as ITerminal);
  // stores
  const terminalStore = useSelector((state: RootState) => state.terminal);
  const sessionStore = useSelector((state: RootState) => state.session);
  // defaults
  const isAssignedTab = tab === 'assigned';
  const selectedItems = getSelectedItems(terminalStore.terminalList);
  const recordMessageList = [
    { key: 'recordCreate', data: terminalStore.recordCreate },
    { key: 'terminalList', data: terminalStore.terminalList },
    { key: 'recordEdit', data: terminalStore.recordEdit }
  ];

  const boundActionCreators = useMemo(
    () =>
      bindActionCreators(
        {
          getMetadata: getTerminalMetadata,
          getListData: getTerminalListData,
          clearRecordMessages: clearTerminalErrors,
          getListDataBySearchTerm: getTerminalListDataBySearchTerm,
          updateListData: updateTerminalListData
        },
        dispatch
      ),
    [dispatch]
  );

  const setTerminalListState = (item: any) => {
    if (Array.isArray(item)) {
      setTerminalList(item);
    } else {
      setTerminalList([item]);
    }
  };

  const onUnassignClick = (item: any) => {
    setTerminalListState(item);
    setIsUnassignedModalOpen(true);
  };

  const onAssignClick = (item: any) => {
    setTerminalListState(item);
    setIsAssignedModalOpen(true);
  };

  const onEditClick = (item: any) => {
    setTerminal(item);
    setIsEditOpen(true);
  };

  const onDeleteClick = (item: any) => {
    setTerminal(item);
    setIsDeleteModelOpen(true);
  };

  const onBulkActionClick = () => {
    if (isAssignedTab) {
      onUnassignClick(selectedItems);
    } else {
      onAssignClick(selectedItems);
    }
  };

  useEffect(() => {
    if (sessionStore.isSystemAdmin) {
      terminalStore.terminalList.columns.forEach((x) => {
        x.visible = true;
      });
    }
  }, [sessionStore.isSystemAdmin]);

  useEffect(() => {
    const assignedHeaders = [
      terminalHeaders.ORGANIZATION,
      terminalHeaders.CAMPAIGN,
      terminalHeaders.COLLECTION,
      terminalHeaders.TEAM,
      terminalHeaders.FUNDRAISER
    ];
    // only show organization / campaign for assigned tab
    terminalStore.terminalList.columns
      .filter((x) => assignedHeaders.includes(x.headerKey))
      .forEach((x) => (x.visible = isAssignedTab));
  }, [tab]);

  return (
    <>
      <RecordResultMessageList
        records={recordMessageList}
        onDismiss={(record) => dispatch(clearTerminalErrors(record))}
      />
      <BasePage>
        <div className="tap-n-donate-header">
          <h1>
            <Translate value={i18nKeys.TERMINAL_TITLE} />
          </h1>
          <Common.Button
            content={<Translate value="terminal.add-action" />}
            onClick={() => setIsAddModalOpen(true)}
          />
        </div>
        <Menu className="accounts-tab-buttons-group terminal-tab-group">
          {tapNdonateFilterButtons.map((item) => (
            <Menu.Item
              key={item.key}
              name={item.key}
              content={I18n.t(item.i18nKey)}
              active={item.key === tab}
              onClick={() => {
                history.push(`/tap-n-donate/${item.key}`);
              }}
            />
          ))}
        </Menu>
        <Grid.Managed
          listKey={gridKeys.TERMINALS}
          list={terminalStore.terminalList}
          actions={{ ...boundActionCreators }}
          search
          filtersButton
          searchPlaceholder="Find a device"
          activeTabFilter={tab}
        >
          <Panel name="right">
            <Common.Button
              primary
              disabled={selectedItems.length === 0}
              onClick={onBulkActionClick}
            >
              <Translate
                value={`terminal.${
                  isAssignedTab ? 'unassign' : 'assign-to'
                }-action`}
              />
            </Common.Button>
          </Panel>
          <ContextMenu columnHeader={I18n.t('terminal.list.action')}>
            {!isAssignedTab && (
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.TERMINAL_TABLE.ASSIGN.key}
                label={I18n.t(i18nKeys.TERMINAL_ASSIGN_TO_ACTION)}
                onClick={(_: number, item: any) => onAssignClick(item)}
              />
            )}
            {isAssignedTab && (
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.TERMINAL_TABLE.UNASSIGN.key}
                label={I18n.t(i18nKeys.TERMINAL_UNASSIGN_ACTION)}
                onClick={(_: number, item: any) => onUnassignClick(item)}
              />
            )}
            <ContextMenu.Item
              key={CONTEXT_MENU_KEYS.TERMINAL_TABLE.EDIT.key}
              label={I18n.t(i18nKeys.TERMINAL_EDIT_ACTION)}
              onClick={(_: number, item: any) => onEditClick(item)}
            />
            <ContextMenu.Item
              key={CONTEXT_MENU_KEYS.TERMINAL_TABLE.DELETE.key}
              label={I18n.t(i18nKeys.TERMINAL_DELETE_ACTION)}
              onClick={(_: number, item: any) => onDeleteClick(item)}
            />
          </ContextMenu>
        </Grid.Managed>
        <UnassignDevice
          terminalList={terminalList}
          isOpen={isUnassignedModalOpen}
          setIsOpen={setIsUnassignedModalOpen}
        />
        <AssignDevice
          terminalList={terminalList}
          isOpen={isAssignedModalOpen}
          setIsOpen={setIsAssignedModalOpen}
        />
        <AddDevice isOpen={isAddModalOpen} setIsOpen={setIsAddModalOpen} />
        <EditDevice
          terminal={terminal}
          isOpen={isEditOpen}
          setIsOpen={setIsEditOpen}
        />
        <DeleteDevice
          terminal={terminal}
          isOpen={isDeleteModelOpen}
          setIsOpen={setIsDeleteModelOpen}
        />
      </BasePage>
    </>
  );
};

export default clear(withRouter(TapNdonate));
