import update from './update';

import { isArrayWithItems } from './arrayHelper';

export const giftMatchingConditionUpdater = (
  giftMatching,
  replaceKey,
  searchKey,
  searchOperator
) => {
  if (isArrayWithItems(giftMatching)) {
    giftMatching.forEach((item) => {
      const conditions = item.conditions;
      const giftMatchingIndex = giftMatching.indexOf(item);

      if (isArrayWithItems(conditions)) {
        const condition = conditions.find(
          (x) => x.key === searchKey && x.operator === searchOperator
        );

        const conditionIndex = conditions.indexOf(condition);

        if (condition) {
          giftMatching = update(giftMatching, {
            [giftMatchingIndex]: {
              conditions: {
                [conditionIndex]: {
                  key: { $set: replaceKey }
                }
              }
            }
          });
        }
      }
    });
  }

  return giftMatching;
};
