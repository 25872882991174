import React from 'react';
import Styles from './merchandise.module.css';
import { getMerchandiseModel, IMerchandise } from './merchandise.model';
import { MerchandiseForm } from './merchandise-form';
import { MerchandiseHeaderView } from './merchandise-header';
import { MerchandiseList } from './merchandise-list';
import { MerchandiseApiProvider, useMerchandiseApi } from './api';
import updater from '../../../../helpers/update';

export interface UseMerchandiseBuilderView {
  path?: string; // componenet needs a path for the template page
}

export const MerchandiseBuilderViewStates = {
  CREATE: 'MERCHANDISE_CREATE',
  EDIT: 'MERCHANDISE_EDIT',
  LIST: 'MERCHANDISE_LIST'
};

export function useMerchandiseBuilderView() {
  const [view, setView] = React.useState(MerchandiseBuilderViewStates.LIST);
  const [selected, setSelected] = React.useState<IMerchandise>();
  const { list, create, update, remove, loading, submitting } =
    useMerchandiseApi();

  // change views

  const handleChangeListView = React.useCallback(() => {
    if (view === MerchandiseBuilderViewStates.LIST) return;
    setSelected(undefined);
    setView(MerchandiseBuilderViewStates.LIST);
  }, [view, setView]);

  const handleChangeCreateView = React.useCallback(() => {
    if (view === MerchandiseBuilderViewStates.CREATE) return;
    setSelected(getMerchandiseModel({ index: list.length }));
    setView(MerchandiseBuilderViewStates.CREATE);
  }, [view, setView]);

  const handleChangeEditView = React.useCallback(
    (id: number) => {
      if (
        view === MerchandiseBuilderViewStates.EDIT &&
        selected &&
        selected?.id === id
      )
        return;

      const item = list.find((item: any) => item.id === id);
      const index = list.findIndex((item: any) => item.id === id);

      if (!item || index < 0) return;

      setSelected(updater.set(item, 'template.value.index', index));
      setView(MerchandiseBuilderViewStates.EDIT);
    },
    [view, setView, list, selected, setSelected]
  );

  // submit handlers

  const handleOnCreateSubmit = React.useCallback(
    async (item: IMerchandise) => {
      create(item);
      handleChangeListView();
    },
    [create, handleChangeListView]
  );

  const handleOnUpdateSubmit = React.useCallback(
    async (item: IMerchandise) => {
      update(item);
      handleChangeListView();
    },
    [update, handleChangeListView]
  );

  const handleOnRemoveSubmit = React.useCallback(
    async (item: IMerchandise) => {
      remove(item);
      handleChangeListView();
    },
    [update, handleChangeListView]
  );

  return {
    view,
    loading,
    submitting,
    items: list,
    item: selected,
    onCreate: handleChangeCreateView,
    onEdit: handleChangeEditView,
    onCancel: handleChangeListView,
    onCreateSubmit: handleOnCreateSubmit,
    onUpdateSubmit: handleOnUpdateSubmit,
    onRemoveSubmit: handleOnRemoveSubmit
  };
}

export const MerchandiseBuilderView: React.FunctionComponent<
  UseMerchandiseBuilderView
> = (props) => {
  const {
    view,
    loading,
    submitting,
    items,
    item,
    onCreate,
    onCancel,
    onEdit,
    onCreateSubmit,
    onUpdateSubmit,
    onRemoveSubmit
  } = useMerchandiseBuilderView();

  return (
    <div className={Styles.Merchandise}>
      <MerchandiseHeaderView
        onAddItem={
          view === MerchandiseBuilderViewStates.LIST ? onCreate : undefined
        }
      />
      {
        {
          [MerchandiseBuilderViewStates.LIST]: (
            <MerchandiseList items={items} onEditItem={onEdit} />
          ),
          [MerchandiseBuilderViewStates.CREATE]: (
            <MerchandiseForm
              submitting={submitting}
              item={item}
              onSubmit={onCreateSubmit}
              onCancel={onCancel}
            />
          ),
          [MerchandiseBuilderViewStates.EDIT]: (
            <MerchandiseForm
              submitting={submitting}
              item={item}
              title="Edit item"
              onSubmit={onUpdateSubmit}
              onCancel={onCancel}
              onDelete={onRemoveSubmit}
            />
          )
        }[view]
      }
    </div>
  );
};

export const MerchandiseBuilder: React.FunctionComponent<
  UseMerchandiseBuilderView
> = (props) => {
  return (
    <MerchandiseApiProvider>
      <MerchandiseBuilderView {...props} />
    </MerchandiseApiProvider>
  );
};
