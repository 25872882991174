export type baseOptionsType = {
  name: any;
  organization: { urlPath: any };
  urlPath: any;
  id: any;
};

export const lookupEntities = {
  CampaignLookup: 'page/campaign',
  OrganizationLookup: 'organization',
  FundraiserLookup: 'page/fundraiser',
  TeamLookup: 'page/team',
  EventLookup: 'page/event',
  TicketLookup: 'ticket',
  MerchandiseLookup: 'merchandise'
};

export const fitnessDateRuleOptions = [
  {
    value: null,
    text: '--empty--'
  },
  {
    value: 'applyOnAll',
    text: 'Apply on all'
  },
  {
    value: 'applyOnlyOnCampaignAndHigher',
    text: 'Apply only on campaign and higher'
  },
  {
    value: 'applyOnlyOnChildPages',
    text: 'Apply only on child pages'
  }
];

export const treeLookupEntities = {
  CollectionTreeLookup: 'page/collection'
};

export const applyBaseFilter = {
  [lookupEntities.CampaignLookup]: true,
  [lookupEntities.OrganizationLookup]: false,
  [lookupEntities.FundraiserLookup]: true,
  [lookupEntities.TeamLookup]: true,
  [lookupEntities.EventLookup]: false,
  [lookupEntities.TicketLookup]: false,
  [lookupEntities.MerchandiseLookup]: false
};

export const applyAdditionalFilter = {
  [lookupEntities.CampaignLookup]: false,
  [lookupEntities.OrganizationLookup]: false,
  [lookupEntities.FundraiserLookup]: false,
  [lookupEntities.TeamLookup]: false,
  [lookupEntities.EventLookup]: false,
  [lookupEntities.TicketLookup]: true,
  [lookupEntities.MerchandiseLookup]: true
};

export const baseOptions = {
  [lookupEntities.CampaignLookup]: {
    getItem(item: baseOptionsType) {
      return {
        text: `${item.name} (${item.organization.urlPath}/${item.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.OrganizationLookup]: {
    getItem(item: baseOptionsType) {
      return {
        text: `${item.name} (${item.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.FundraiserLookup]: {
    getItem(item: baseOptionsType) {
      return {
        text: `${item.name} (${item.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.TeamLookup]: {
    getItem(item: baseOptionsType) {
      return {
        text: `${item.name} (${item.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.EventLookup]: {},
  [lookupEntities.TicketLookup]: {},
  [lookupEntities.MerchandiseLookup]: {}
};

export const filterSelectBaseOptions = {
  PageType: [
    {
      value: 'fundraiser',
      text: 'Fundraiser'
    },
    {
      value: 'team',
      text: 'Team'
    },
    {
      value: 'collection',
      text: 'Superteam'
    }
  ]
};

export const NUMBER_TYPES = ['Decimal', 'Int32', 'Int64'];
export const SELECT_TYPES = [
  'DonationType',
  'CampaignType',
  'PageType',
  'AccountStatus',
  'Role',
  'SaleType',
  'DonationSubscriptionStatus',
  'EventAmbassadorStatus',
  'TierType',
  'EventTier'
];
export const LOOKUP_TYPES = [
  'TeamLookup',
  'FundraiserLookup',
  'OrganizationLookup',
  'CampaignLookup',
  'EventLookup',
  'TicketLookup',
  'MerchandiseLookup'
];
export const TREE_LOOKUP_TYPES = ['CollectionTreeLookup'];
export const ORGANIZATION_ID_FILTER = 'organizationId';
export const EVENT_ID_FILTER = 'eventId';
export const OBJECT_ORGANIZATION_ID_FILTER = 'organization.id';
export const IS_EVENT_ADMIN = 'isEventAdmin';
export const IS_EVENT_ORGANIZER_ADMIN = 'isEventOrganizerAdmin';
export const IS_SYSTEM_ADMIN_FILTER = 'isSysAdmin';
