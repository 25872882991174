import get from 'lodash.get';
import update from '../helpers/update';
import UploadImageService from './uploadEditorImage';
import RecordStatuses from '../constants/record';

const INIT = 'cropImage/INIT';
const CLEAR = 'cropImage/CLEAR';
const ON_IMAGE_DROP = 'ON_IMAGE_DROP';
const ON_CROPPER_ERROR = 'ON_CROPPER_ERROR';
const UPLOAD_PHOTO_STARTED = 'cropImage/UPLOAD_PHOTO_STARTED';
const UPLOAD_PHOTO_SUCCESS = 'cropImage/UPLOAD_PHOTO_SUCCESS';
const UPLOAD_PHOTO_FAILED = 'cropImage/UPLOAD_PHOTO_FAILED';
const HANDLE_VALUE_CHANGE = 'cropImage/HANDLE_VALUE_CHANGE';

const initialState = {
  imageDropped: false,
  imagePreview: '',
  fileName: '',
  mainImagePath: '',
  saveRecord: {
    ready: RecordStatuses.RECORD_STATUS_NO_DATA,
    data: ''
  },
  error: false,
  errorMessage: '',
  width: 0,
  height: 0,
  imageUrl: ''
};

const cropImageReducer = (state = {}, action) => {
  let newState = state;

  switch (action.type) {
    case INIT:
    case CLEAR:
      newState = update.set(newState, action.payload, initialState);
      break;

    case ON_IMAGE_DROP:
      newState = update.set(newState, action.payload.path, {
        saveRecord: initialState.saveRecord,
        imageDropped: true,
        ...action.payload.data,
        error: false
      });
      break;

    case UPLOAD_PHOTO_STARTED:
      newState = update.set(newState, `${action.payload.path}.saveRecord`, {
        ready: RecordStatuses.RECORD_STATUS_LOADING
      });
      break;

    case UPLOAD_PHOTO_SUCCESS: {
      const imageUrl = get(state, `${action.payload.path}.imageUrl`);
      newState = update.set(newState, action.payload.path, {
        saveRecord: {
          ready: RecordStatuses.RECORD_STATUS_READY,
          data: action.payload.data
        },
        imageDropped: false,
        mainImagePath: action.payload.data,
        imageUrl: imageUrl
      });
      break;
    }

    case UPLOAD_PHOTO_FAILED:
      newState = update.set(newState, action.payload.path, {
        saveRecord: {
          ready: RecordStatuses.RECORD_STATUS_ERROR
        }
      });
      break;

    case ON_CROPPER_ERROR:
      newState = update.set(newState, action.payload.path, {
        error: true,
        errorMessage: get(action.payload, 'data.errorMessage')
      });
      break;

    case HANDLE_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;
    default:
      return newState;
  }

  return newState;
};

export const clear = (path) => {
  return {
    type: CLEAR,
    payload: path
  };
};

export const cropInit = (path) => {
  return {
    type: INIT,
    payload: path
  };
};

export const onImageDrop = (
  path,
  imagePreview,
  fileName,
  width,
  height,
  imageType
) => {
  return {
    type: ON_IMAGE_DROP,
    payload: {
      path: path,
      data: {
        imagePreview,
        fileName,
        width,
        height,
        imageType
      }
    }
  };
};

export const onCropperError = (path, errorMessage) => {
  return {
    type: ON_CROPPER_ERROR,
    payload: {
      path: path,
      data: {
        errorMessage
      }
    }
  };
};

export const handleValueChange = (path, value) => {
  return {
    type: HANDLE_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const uploadPhotoActions = {
  start: (path) => {
    return {
      type: UPLOAD_PHOTO_STARTED,
      payload: {
        path: path
      }
    };
  },

  success: (componentPath, urlPath) => {
    return {
      type: UPLOAD_PHOTO_SUCCESS,
      payload: {
        path: componentPath,
        data: urlPath
      }
    };
  },

  failed: (path) => {
    return {
      type: UPLOAD_PHOTO_FAILED,
      payload: {
        path: path
      }
    };
  }
};

export const uploadPhoto = (path, entity, type, blob, token) => {
  return (dispatch) => {
    dispatch(uploadPhotoActions.start(path));

    UploadImageService.uploadBlob(entity, type, blob, token)
      .then((response) => {
        dispatch(uploadPhotoActions.success(path, response.data.data));
      })
      .catch((error) => {
        dispatch(uploadPhotoActions.failed(path));
        console.log(error);
      });
  };
};

export default cropImageReducer;
