import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Button, Icon, Popup } from 'semantic-ui-react';

import { ViewTable } from '../../../components/layouts/viewTable';
import { useAppDispatch } from '../../../hooks';
import { Record } from '../../../modules/bin/utility';
import { AppDispatch, RootState } from '../../../store';
import { paths } from '../store/paths';
import { onValueChange } from '../store/ticketingActions';
import '../_styles/ticketCollectPayment.css';

export const TicketCollectPayment = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  
  const recordData = ticketing.record?.data?.data;
  const paymentCollectionStopped = recordData?.financials?.installments?.paymentCollectionStopped;
  const collectManualInstallmentRequestSuccess = Record.isSuccess(ticketing?.manualInstallmentRecord);
  const isCollectButtonVisible = !paymentCollectionStopped ? !collectManualInstallmentRequestSuccess : false;

  const onCollectPaymentClick = () => {
    dispatch(onValueChange(paths.collectPaymentWindowIsOpen, true));
  }

  const onViewPaymentUrlClick = () => {
    dispatch(onValueChange(paths.paymentUrlWindowIsOpen, true));
  }

  return (
    <div className="ticket-action-container">
      <ViewTable
        title={
          <React.Fragment>
            <Translate value="ticketing.edit.collect-payment.header" />
            <Popup
              content={<Translate value="ticketing.edit.collect-payment.header-tooltip" />}
              trigger={<Icon
                name="question circle"
                className="collect-payment-tooltip"
                size="large"
              />}
            />
          </React.Fragment>}
      >
        {
          isCollectButtonVisible ?
            <Button primary onClick={onCollectPaymentClick}>
              <Translate value="ticketing.edit.collect-payment.buttons.collect-payment" />
            </Button> :
            <Button primary onClick={onViewPaymentUrlClick}>
              <Translate value="ticketing.edit.collect-payment.buttons.view-payment-url" />
            </Button>
        }
      </ViewTable>
    </div>
  );
};
