import React from 'react';
import classNames from 'classnames';
import { Form, Checkbox } from 'semantic-ui-react';

export default class WizardRadioField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovering: false };
  }

  handleHover() {
    this.setState({ hovering: !this.state.hovering });
  }

  onCheckboxChange = () => {
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  };

  render() {
    const fieldClassNames = classNames({
      checked: this.props.checked,
      hovered: this.state.hovering
    });

    return (
      <Form.Field
        onMouseEnter={this.handleHover.bind(this)}
        onMouseLeave={this.handleHover.bind(this)}
        className={fieldClassNames}
        onClick={this.onCheckboxChange}
        style={{ cursor: 'pointer' }}
      >
        <Checkbox
          label={this.props.label}
          name={`${this.props.value}wizardRadio`}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.onCheckboxChange}
          radio
        />
        {this.props.children}
      </Form.Field>
    );
  }
}
