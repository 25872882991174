import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { DropdownProps } from 'semantic-ui-react';
import { Form } from '../..';
import { useAppDispatch } from '../../../hooks';
import { Record } from '../../../modules/bin/utility';
import { getCampaignOptions } from '../../../pages/accounts/settings/reducer';
import { RootState } from '../../../store';

interface ICampaignLookupProps extends DropdownProps {
  label?: string;
  name?: string;
  value?: number;
  validation?: any;
  customFilter?: any[];
  customDiplayName?: (data: any) => string;
}

export const CampaignLookup = (props: ICampaignLookupProps) => {
  const dispatch = useAppDispatch();
  const account = useSelector((state: RootState) => state.accountSettings);
  let timer: NodeJS.Timeout | null = null;

  const onCampaignClear = () => {
    dispatch(getCampaignOptions('', props.customFilter));
  };

  const generateCampaignOptions = () => {
    let options = [];
    const { list } = account.campaignOptions?.data;
    if (list) {
      options = list.map((x: { name: any; id: any }) => ({
        text: props.customDiplayName ? props.customDiplayName(x) : x.name,
        value: x.id
      }));
    }

    return options;
  };

  const onCampaignSearch = (_: any, searchTerm: any) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(getCampaignOptions(searchTerm.searchQuery, props.customFilter));
    }, 500);
  };

  useEffect(() => dispatch(getCampaignOptions('', props.customFilter)), []);

  return (
    <Form.Autocomplete
      {...props}
      search={onCampaignSearch}
      options={generateCampaignOptions()}
      loading={Record.isRecordLoading(account.campaignOptions)}
      clear={onCampaignClear}
    />
  );
};
