import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button, Grid } from 'semantic-ui-react';

// LIBS
import { Translate } from 'react-redux-i18n';
import get from 'lodash.get';

// CONSTANTS
import { securitySettingTabs } from '../../../constants';

class BackUpCodesTab extends Component {
  handleNextButtonClick = () => {
    this.props.handleActiveTabChange(securitySettingTabs.FINISH);
  };

  getRecoveryCodes = () => {
    return (
      <Grid>
        {React.Children.toArray(
          this.props.recoveryCodes.map((item) => {
            return <Grid.Column width={8}>{item}</Grid.Column>;
          })
        )}
      </Grid>
    );
  };

  render() {
    const recoveryCodes = this.getRecoveryCodes();

    return (
      <Modal.Content>
        <div className="recovery-codes-container">{recoveryCodes}</div>
        <div className="security-sub-title">
          <h4 className="recovery-codes-title">
            <Translate
              value={
                'account.account-security.activateModal.steps.recoveryCodes.title'
              }
            />
          </h4>
        </div>
        <div className="security-content">
          <Translate
            className="recovery-codes-content"
            value={
              'account.account-security.activateModal.steps.recoveryCodes.content'
            }
          />
        </div>
        <Button
          primary
          className="complete-button"
          onClick={this.handleNextButtonClick}
        >
          <Translate
            value={
              'account.account-security.activateModal.steps.recoveryCodes.button'
            }
          />
        </Button>
        <div className="recovery-codes-sub-container">
          <Translate
            value={
              'account.account-security.activateModal.steps.recoveryCodes.sub-content'
            }
          />
        </div>
      </Modal.Content>
    );
  }
}

const mapState = ({ accountSettings }) => {
  return {
    recoveryCodes: accountSettings.securityApprovalRecord.data,
    activeTab: get(accountSettings, 'MFA.activeTab')
  };
};

export default connect(mapState)(BackUpCodesTab);
