import get from 'lodash.get';
import update from './update';
import { List as ListConstants } from '../constants';

export const isReady = (state, key) => {
  const list = get(state, key);
  if (list) {
    return list.ready === ListConstants.LIST_STATUS_READY;
  }
  return false;
};

export const getListKey = (state, keys) => {
  for (var i = 0; i < keys.length; i++) {
    if (isReady(state, keys[i])) {
      return keys[i];
    }
  }
  return null;
};

export const listReadyStateClear = (state, key) => {
  state = update(state, {
    [key]: {
      ready: { $set: ListConstants.LIST_STATUS_NO_DATA }
    }
  });

  return state;
};
