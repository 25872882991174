//------------React/Redux
import { connect } from 'react-redux';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Message } from 'semantic-ui-react';

//-------------Helpers
import * as validationHelper from '../../../helpers/validationHelper';
import * as templateHelper from '../../template/templateHelper';
import get from 'lodash.get';
import update from '../../../helpers/update';

//-------------Components
import CouponForm from './components/couponForm';
import CouponListTable from './components/couponsListTable';
import { AddItemButton } from '../../collectionBuilder/components/addItemButton';

//-------------Images
import discount_code_icon from './images/discount_coupon_icon.svg';

//-------------Constants
import { i18CouponKeys } from './constants/translation';
import { couponPaths } from './constants/paths';
import { usageLimitType } from '../../collectionBuilder/constants';

export class CouponBuilderContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      addMode: false,
      editMode: false,
      couponIndex: null,
      instanceIndex: null,
      deleteCouponModalOpen: false
    };
  }

  setInitialState = () => {
    this.setState({
      addMode: false,
      editMode: false,
      couponIndex: null,
      instanceIndex: null
    });
  };

  onOpenDeleteCouponModalClick = () => {
    this.setState({
      deleteCouponModalOpen: true
    });
  };

  onCloseDeleteCouponModalClick = () => {
    this.setState({
      deleteCouponModalOpen: false
    });
  };

  handleCouponChange = (fieldKey, value) => {
    let valueCopy = this.getDeepCopy(this.props.couponModel);

    valueCopy = update.set(valueCopy, fieldKey, value);

    this.props.onChange(valueCopy, 'couponModel');
  };

  handleInstanceChange = (innerIndex, fieldKey, value) => {
    const instancesPath = 'instances';

    let valueCopy = this.getDeepCopy(this.props.couponModel);

    const changedValue = update(valueCopy, {
      [instancesPath]: {
        [innerIndex]: {
          [fieldKey]: { $set: value },
          isEdited: { $set: true }
        }
      }
    });

    this.props.onChange(changedValue, 'couponModel');
  };

  onSaveClick = (index) => {
    this.setInitialState();

    let couponModel = this.getDeepCopy(this.props.couponModel);

    if (couponModel.usageLimitValue > 0) {
      couponModel = update.set(
        couponModel,
        couponPaths.usageLimit,
        usageLimitType.ByCount
      );
    } else {
      couponModel = update.set(
        couponModel,
        couponPaths.usageLimit,
        usageLimitType.Unlimited
      );
    }

    if (index || index === 0) {
      let valueCopy = this.getDeepCopy(this.props.value);

      const changedValue = update(valueCopy, {
        [index]: {
          $set: couponModel
        }
      });

      this.props.onChange(changedValue);
    } else {
      let valueCopy = this.props.value
        ? this.getDeepCopy(this.props.value)
        : [];

      valueCopy.push(couponModel);

      this.props.onChange(valueCopy);
    }

    this.onDeleteCouponCopy();
  };

  getDeepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
  };

  onCancelClick = () => {
    this.setInitialState();
    this.onDeleteCouponCopy();
  };

  onEditCouponClick = (couponIndex, instanceIndex) => {
    this.setState({
      editMode: true,
      couponIndex: couponIndex,
      instanceIndex: instanceIndex
    });

    const coupon = this.props.value[couponIndex];

    this.props.onChange(coupon, 'couponModel');
  };

  getCouponItem = () => {
    const couponModel = this.props.couponModel;

    const couponInstance = couponModel.instances[this.state.instanceIndex];
    const couponInstanceIndex = couponModel.instances.indexOf(couponInstance);

    const isValid = validationHelper.isCouponModelValid(this.props.couponModel);
    const errorMessage = validationHelper.getCouponFormErrorMessage(
      this.props.couponModel
    );

    return (
      <CouponForm
        {...couponModel}
        index={this.state.couponIndex}
        currency={this.props.currency}
        instance={couponInstance}
        instanceIndex={couponInstanceIndex}
        i18Key="template.page.content.discounts.coupons.available-coupons.coupon"
        isValid={isValid}
        errorMessage={errorMessage}
        editMode={this.state.editMode}
        onCancel={this.onCancelClick}
        onSave={this.onSaveClick}
        onDelete={this.onDeleteCouponClick}
        handleInstanceChange={this.handleInstanceChange}
        handleCouponChange={this.handleCouponChange}
        deleteCouponModalOpen={this.state.deleteCouponModalOpen}
        onOpenDeleteCouponModal={this.onOpenDeleteCouponModalClick}
        onCloseDeleteCouponModal={this.onCloseDeleteCouponModalClick}
      />
    );
  };

  onAddCouponClick = () => {
    this.setState({
      addMode: true,
      instanceIndex: 0
    });

    this.props.onChange(this.props.model, 'couponModel');
  };

  onDeleteCouponCopy = () => {
    let valueCopy = this.getDeepCopy(this.props.couponModel);

    valueCopy = update(valueCopy, {
      $set: null
    });

    this.props.onChange(valueCopy, 'couponModel');
  };

  onDeleteCouponClick = () => {
    const valueCopy = this.getDeepCopy(this.props.value);
    valueCopy.splice(this.state.couponIndex, 1);
    this.props.onChange(valueCopy);

    this.onDeleteCouponCopy();

    this.setState({
      editMode: false,
      deleteCouponModalOpen: false
    });
  };

  render() {
    const coupons = this.props.value;
    const addMode = this.state.addMode;
    const editMode = this.state.editMode;

    const couponInstances =
      coupons &&
      coupons.some((coupon) => coupon.instances && coupon.instances.length > 0);

    const i18Key =
      'template.page.content.discounts.coupons.available-coupons.coupon';

    return (
      <div className="coupons-container">
        {(addMode || editMode) && this.getCouponItem()}
        {!addMode && !editMode && (
          <div className="coupons-container-title">
            {I18n.t(
              `template.page.content.discounts.${i18CouponKeys.tableList.title}`
            )}
          </div>
        )}
        {!couponInstances && !addMode && (
          <React.Fragment>
            <div className="coupon-empty-state-container">
              <div className="coupon-empty-state-content">
                <img alt="" src={discount_code_icon} />
                <div className="coupon-empty-state-subtext">
                  {I18n.t(`${i18Key}.${i18CouponKeys.couponIcon.subtext}`)}
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
        {couponInstances && !addMode && (
          <div className="coupon-table">
            <CouponListTable
              coupons={coupons}
              editMode={editMode}
              i18Key="template.page.content.discounts"
              onEdit={this.onEditCouponClick}
            />
          </div>
        )}
        {!addMode && !editMode && (
          <AddItemButton
            onClick={this.onAddCouponClick}
            buttonText={I18n.t(`${i18Key}.${i18CouponKeys.addButton}`)}
          />
        )}
        <Message negative hidden={this.props.isValid}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value: value,
    currency: get(state, 'templateBuilderEx.data.organization.currency'),
    isValid: validationHelper.isValid(ownProps.validation, value),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      value
    )
  };
};

export const CouponBuilder = connect(mapState)(CouponBuilderContainer);
