import React from 'react';

import moment from 'moment';
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import config from '../../../constants';
import {
  donationI18Keys,
  getPaymentMethodValue
} from './constants/donationSettings';
import {
  ONLINE_DONATION_TYPE,
  PENDING_DONATION_TYPE
} from '../../campaign/constants';

export const DonationShortDescription = (props) => {
  const { donor, type, subscriptionId, donatedAtLocal, financials, source } =
    props.donation;
  const { isShowEmptyFields } = props;

  const donorEmail = get(donor, 'email');
  const paymentMethod = getPaymentMethodValue(get(financials, 'paymentMethod'));
  const donatedDate = donatedAtLocal
    ? moment(donatedAtLocal).format(
        config.INVERSION_FULL_DATE_TIME_PERIODS_FORMAT
      )
    : 'N/A';

  const isOnlineOrPending =
    type === ONLINE_DONATION_TYPE || type === PENDING_DONATION_TYPE;

  const typeRowValue = subscriptionId
    ? I18n.t(`${donationI18Keys.donationShortDescriptionI18}.recurring.title`)
    : I18n.t(`${donationI18Keys.donationShortDescriptionI18}.one-off.title`);

  return (
    <div className="donation-short-description-container">
      <div className="donation-short-description-row">
        <div className="donation-short-description-column">
          <div className="donation-short-description-label-name">
            {I18n.t(
              `${donationI18Keys.donationShortDescriptionI18}.date.title`
            )}
          </div>
          <div>{donatedDate}</div>
        </div>
        {(donorEmail || isShowEmptyFields) && (
          <React.Fragment>
            <div className="donation-short-description-vertical-line" />
            <div className="donation-short-description-column">
              <div className="donation-short-description-label-name">
                {I18n.t(
                  `${donationI18Keys.donationShortDescriptionI18}.email.title`
                )}
              </div>
              <div>
                <a href={`mailto:${donorEmail}`}>{donorEmail}</a>
              </div>
            </div>
          </React.Fragment>
        )}
        {isOnlineOrPending && (
          <React.Fragment>
            <div className="donation-short-description-vertical-line" />
            <div className="donation-short-description-column">
              <div className="donation-short-description-label-name">
                {I18n.t(
                  `${donationI18Keys.donationShortDescriptionI18}.paymentMethod.title`
                )}
              </div>
              <div>{paymentMethod}</div>
            </div>
            <div className="donation-short-description-vertical-line" />
            <div className="donation-short-description-column">
              <div className="donation-short-description-label-name">
                {I18n.t(
                  `${donationI18Keys.donationShortDescriptionI18}.type.title`
                )}
              </div>
              <div>{typeRowValue}</div>
            </div>
          </React.Fragment>
        )}

        {source && (
          <>
            <div className="donation-short-description-vertical-line" />
            <div className="donation-short-description-column">
              <div className="donation-short-description-label-name">
                {I18n.t(
                  `${donationI18Keys.donationShortDescriptionI18}.origin.title`
                )}
              </div>
              <div>
                {I18n.t(
                  `${donationI18Keys.donationShortDescriptionI18}.origin.${source}`
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
