import React from 'react';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TagsInput } from '../../../components/tagInput';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';

import { paths } from '../constants/template';
import CustomSettings from './eyesOnly/custom-settings';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';

export const getEyesOnlyTab = (props) => {
  return (
    <TemplatePage id="eyes-only" i18nKey="template.page.eyes-only">
      <TemplateHeader
        as="h3"
        label="event.template.page.eyes-only.tags.header"
      />

      <TagsInput path={paths.TAGS} onChange={props.onChange} />

      <TemplateHorizontalRule />

      <TemplateHeader
        as="h3"
        label="event.template.page.eyes-only.general-settings.header"
      />

      <TemplateDropdown
        path={paths.RAISED_TOTAL_TYPE}
        label="event.template.page.eyes-only.general-settings.raisedTotalType.label"
        onChange={props.onChange}
        options={props.raisedTotalTypeOptions}
      />

      <TemplateCheckbox
        path={paths.DISPLAY_FULL_AMOUNT_PRECISION}
        inputLabel="event.template.page.eyes-only.general-settings.displayFullAmountPrecision.label"
        onChange={props.onChange}
      />

      <TemplateHorizontalRule path="template.value.forceRedirectTo" />

      <TemplateHeader
        as="h3"
        path="template.value.forceRedirectTo"
        label="template.fundraiser.content.eyes-only.forceRedirect.header"
      />

      <TemplateInput
        id="template.value.forceRedirectTo"
        path="template.value.forceRedirectTo"
        label="template.fundraiser.content.eyes-only.forceRedirect.label"
        subtext="template.fundraiser.content.eyes-only.forceRedirect.subtext"
        subtextPosition="bottom"
        validation={{
          url: true
        }}
        onChange={props.onChange}
      />

      <CustomSettings />
    </TemplatePage>
  );
};
