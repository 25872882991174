import * as entityHandlers from '../../../modules/bin/entityHandlers';

import { AnyAction } from '@reduxjs/toolkit';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import { columnTypes } from '../../../components/common/grid/constants';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import { listFilterOperator } from '../../../constants/aggregate';
import { gridKeys } from '../../../constants/gridKeys';
import recordConstants from '../../../constants/record';
import { listReadyStateClear } from '../../../helpers/donationHelper';
import update from '../../../helpers/update';
import { Record } from '../../../modules/bin/utility';
import { CLEAR, LIST_READY_STATE_CLEAR } from '../../../modules/general';
import {
    DELETE_IMPERSONATE_ADMIN_REQUESTED,
    DELETE_IMPERSONATE_ADMIN_SUCCESS,
    IMPERSONATE_ADMIN_FAILURE, IMPERSONATE_ADMIN_REQUESTED,
    IMPERSONATE_ADMIN_SUCCESS
} from '../../../modules/session';
import {
    adminColumns, adminsAvailableFilters, allUsersAvailableFilters, donorAvailableFilters, fundraisersAvailableFilters, getPermissionsFromAccount, tabs
} from './../constants';
import { ACTIVE_TAB_SET, FUNDRAISERS_LIST_DATA_FAILURE, FUNDRAISERS_LIST_DATA_REQUESTED, FUNDRAISERS_LIST_DATA_SUCCESS, FUNDRAISERS_METADATA_FAILURE, FUNDRAISERS_METADATA_REQUESTED, FUNDRAISERS_METADATA_SUCCESS, GET_CAMPAIGN_OPTIONS_REQUESTED, GET_CAMPAIGN_OPTIONS_SUCCESS, GET_EVENT_OPTIONS_REQUESTED, GET_EVENT_OPTIONS_SUCCESS, GET_EVENT_ORGANIZER_OPTIONS_REQUESTED, GET_EVENT_ORGANIZER_OPTIONS_SUCCESS, GET_EXPORT_FILE_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_ORGANIZATION_OPTIONS_REQUESTED, GET_ORGANIZATION_OPTIONS_SUCCESS, INVITE_ADMIN_FAILURE, INVITE_ADMIN_PANEL_VISIBILITY_CHANGE, INVITE_ADMIN_PARAM_CHANGE, INVITE_ADMIN_REQUESTED, INVITE_ADMIN_SUCCESS, LIST_DATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, MESSAGE_CLEAR, METADATA_FAILURE, METADATA_REQUESTED, METADATA_SUCCESS, ON_INVITE_PANEL_VALIDATION_CHANGE, TOGGLE_COLUMNS_CHANGE } from './accountTypes';

const getInviteAdminInitialData = () => {
  return {
    isPanelVisible: false,
    orgOptions: Record.getDefaultState(),
    campaignOptions: Record.getDefaultState(),
    eventOptions: Record.getDefaultState(),
    eventOrganizerOptions: Record.getDefaultState(),
    params: {
      firstName: '',
      lastName: '',
      email: '',
      roles: '',
      organizationId: '',
      roleDetail: {
        campaignId: []
      }
    }
  };
};

const i18nAdminsListKey = 'account.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  selectedView: tabs.ADMINS,
  inviteAdminRecord: Record.getDefaultState(),
  inviteAdmin: getInviteAdminInitialData(),
  exportFileRecord: Record.getDefaultState(),
  impersonatedRecord: Record.getDefaultState(),
  [gridKeys.ACCOUNT_ALL_USERS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        adminColumns.ORGANIZATION,
        `${i18nAdminsListKey}.${adminColumns.ORGANIZATION_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.FIRST_NAME,
        `${i18nAdminsListKey}.${adminColumns.FIRST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.LAST_NAME,
        `${i18nAdminsListKey}.${adminColumns.LAST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.EMAIL,
        `${i18nAdminsListKey}.${adminColumns.EMAIL}`
      ).visibleByDefault(),
      new ColumnModel(
        getPermissionsFromAccount,
        `${i18nAdminsListKey}.${adminColumns.PERMISSION}`
      ).visibleByDefault()
    ])
    .orderBy(adminColumns.STATUS, 'desc')
    .addAvailableFiltersKeys(allUsersAvailableFilters)
    .getState(),

  [gridKeys.ACCOUNT_ADMINS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        adminColumns.ORGANIZATION,
        `${i18nAdminsListKey}.${adminColumns.ORGANIZATION_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.FIRST_NAME,
        `${i18nAdminsListKey}.${adminColumns.FIRST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.LAST_NAME,
        `${i18nAdminsListKey}.${adminColumns.LAST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.EMAIL,
        `${i18nAdminsListKey}.${adminColumns.EMAIL}`
      ).visibleByDefault(),
      new ColumnModel(
        getPermissionsFromAccount,
        `${i18nAdminsListKey}.${adminColumns.PERMISSION}`
      ).visibleByDefault()
    ])
    .orderBy(adminColumns.STATUS, 'desc')
    .addFilter(adminColumns.IS_ADMIN, listFilterOperator.EQUAL_TO, true)
    .addAvailableFiltersKeys(adminsAvailableFilters)
    .getState(),

  [gridKeys.ACCOUNT_FUNDRAISERS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        adminColumns.FIRST_NAME,
        `${i18nAdminsListKey}.${adminColumns.FIRST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.LAST_NAME,
        `${i18nAdminsListKey}.${adminColumns.LAST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.EMAIL,
        `${i18nAdminsListKey}.${adminColumns.EMAIL}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.AMOUNT_FUNDRAISED,
        `${i18nAdminsListKey}.${adminColumns.AMOUNT_FUNDRAISED}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        adminColumns.AVERAGE_DONATION_AMOUNT,
        `${i18nAdminsListKey}.${adminColumns.AVERAGE_DONATION_AMOUNT}`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        adminColumns.NUMBER_OF_DONATIONS,
        `${i18nAdminsListKey}.${adminColumns.NUMBER_OF_DONATIONS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        adminColumns.PAGES_COUNT,
        `${i18nAdminsListKey}.${adminColumns.PAGES_COUNT}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        adminColumns.CURRENCY,
        `${i18nAdminsListKey}.${adminColumns.CURRENCY}`
      ).setType(columnTypes.CAPITALIZE_STRING)
    ])
    .orderBy(adminColumns.AMOUNT_FUNDRAISED, 'desc')
    .addAvailableFiltersKeys(fundraisersAvailableFilters)
    .getState(),

  [gridKeys.ACCOUNT_DONORS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        adminColumns.FIRST_NAME,
        `${i18nAdminsListKey}.${adminColumns.FIRST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.LAST_NAME,
        `${i18nAdminsListKey}.${adminColumns.LAST_NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.EMAIL,
        `${i18nAdminsListKey}.${adminColumns.EMAIL}`
      ).visibleByDefault(),
      new ColumnModel(
        adminColumns.AMOUNT_FUNDRAISED,
        `${i18nAdminsListKey}.donors.${adminColumns.AMOUNT_FUNDRAISED}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        adminColumns.AVERAGE_DONATION_AMOUNT,
        `${i18nAdminsListKey}.${adminColumns.AVERAGE_DONATION_AMOUNT}`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        adminColumns.NUMBER_OF_DONATIONS,
        `${i18nAdminsListKey}.${adminColumns.NUMBER_OF_DONATIONS}`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        adminColumns.CURRENCY,
        `${i18nAdminsListKey}.${adminColumns.CURRENCY}`
      ).setType(columnTypes.CAPITALIZE_STRING)
    ])
    .orderBy(adminColumns.AMOUNT_FUNDRAISED, 'desc')
    .addAvailableFiltersKeys(donorAvailableFilters)
    .getState()
};

// State Reducer
const accountReducer = (state = initialState, action: AnyAction) => {
  let newState = state;
  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case METADATA_REQUESTED:
    case FUNDRAISERS_METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
    case FUNDRAISERS_METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
    case FUNDRAISERS_LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
    case FUNDRAISERS_LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
    case FUNDRAISERS_LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case ACTIVE_TAB_SET:
      newState = update(newState, { selectedView: { $set: action.payload } });
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.ACCOUNT_ADMINS);
      newState = listReadyStateClear(newState, gridKeys.ACCOUNT_FUNDRAISERS);
      newState = listReadyStateClear(newState, gridKeys.ACCOUNT_DONORS);
      break;

    case IMPERSONATE_ADMIN_REQUESTED:
    case DELETE_IMPERSONATE_ADMIN_REQUESTED:
    case GET_ORGANIZATION_OPTIONS_REQUESTED:
    case GET_CAMPAIGN_OPTIONS_REQUESTED:
    case GET_EVENT_OPTIONS_REQUESTED:
    case GET_EVENT_ORGANIZER_OPTIONS_REQUESTED:
    case INVITE_ADMIN_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_ORGANIZATION_OPTIONS_SUCCESS:
    case GET_CAMPAIGN_OPTIONS_SUCCESS:
    case GET_EVENT_OPTIONS_SUCCESS:
    case GET_EVENT_ORGANIZER_OPTIONS_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case INVITE_ADMIN_SUCCESS:
      newState = update.set(
        newState,
        'adminsList.ready',
        recordConstants.RECORD_STATUS_NO_DATA
      );
      newState = update.set(
        newState,
        'inviteAdmin',
        getInviteAdminInitialData()
      );
      newState = entityHandlers.getRecordSuccessHandler(newState, action);
      break;

    case ON_INVITE_PANEL_VALIDATION_CHANGE:
      newState = update.set(
        newState,
        'isInvitePanelValid',
        action.payload.value
      );
      break;

    case INVITE_ADMIN_PANEL_VISIBILITY_CHANGE:
      newState = update.set(
        newState,
        'inviteAdmin.isPanelVisible',
        action.payload
      );
      break;

    case INVITE_ADMIN_PARAM_CHANGE:
      newState = update.set(
        newState,
        `inviteAdmin.params.${action.payload.path}`,
        action.payload.value
      );
      break;

    case MESSAGE_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case IMPERSONATE_ADMIN_SUCCESS:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
      // set default state to load updated data
      newState = initialState;
      break;

    case FUNDRAISERS_METADATA_FAILURE:
    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case GET_EXPORT_FILE_FAILURE:
    case IMPERSONATE_ADMIN_FAILURE:
    case INVITE_ADMIN_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;
  }

  return newState;
};

export default accountReducer;
