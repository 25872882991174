import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';

class FormbuilderCheckbox extends React.Component {
  render() {
    const { checked, inputLabel, onChange, label, validation } = this.props;
    const requiredClass = validation && validation.required ? 'required' : '';

    return (
      <Form.Field>
        {label && (
          <div className={`field ${requiredClass}`}>
            <label>{label}</label>
          </div>
        )}
        <Checkbox label={inputLabel} checked={checked} onChange={onChange} />
      </Form.Field>
    );
  }
}

export default FormbuilderCheckbox;
