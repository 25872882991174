import React, { Component } from 'react';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';

class HtmlEditor extends Component {
  onHtmlChange(value) {
    if (this.props.onChange) {
      value === '<p><br></p>'
        ? this.props.onChange('')
        : this.props.onChange(value);
    }
  }

  render() {
    /*
     * Quill modules to attach to editor
     * See https://quilljs.com/docs/modules/ for complete options
     */
    const modules = {
      toolbar: [
        [{ header: '1' }, { header: '2' }, { font: [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
          { list: 'ordered' },
          { list: 'bullet' },
          { indent: '-1' },
          { indent: '+1' }
        ],
        ['link', 'image', 'video'],
        ['clean']
      ],
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false
      }
    };
    /*
     * Quill editor formats
     * See https://quilljs.com/docs/formats/
     */
    const formats = [
      'header',
      'font',
      'size',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'video'
    ];

    return (
      <ReactQuill
        className={this.props.className}
        value={this.props.value || ''}
        onChange={this.onHtmlChange.bind(this)}
        onBlur={this.props.onBlur}
        modules={modules}
        formats={formats}
      />
    );
  }
}

export default HtmlEditor;
