import { RSAA } from 'redux-api-middleware';
import constants from '../constants';
import { ACTIVATE_USER_SUCCESS } from './authentication';
import * as entityHandlers from '../modules/bin/entityHandlers';
import { Record } from './bin/utility';
import get from 'lodash.get';
import { localStorageKeys } from '../constants/localStorageKeys';
import { GET_ORGANIZATION_SUCCESS } from './general';
import update from '../helpers/update';
import { verificationVariant } from '../constants/multiFactorAuth';

const HANDLE_VALUE_CHANGE = 'session/HANDLE_VALUE_CHANGE';

export const AUTHENTICATE_REQUESTED = 'session/AUTHENTICATE_REQUESTED';
export const AUTHENTICATE_SUCCESS = 'session/AUTHENTICATE_SUCCESS';
export const AUTHENTICATE_FAILURE = 'session/AUTHENTICATE_FAILURE';

export const RESUME_SESSION_NOT_POSSIBLE =
  'session/RESUME_SESSION_NOT_POSSIBLE';
export const RESUME_SESSION_REQUESTED = 'session/RESUME_SESSION_REQUESTED';
export const RESUME_SESSION_SUCCESS = 'session/RESUME_SESSION_SUCCESS';
export const RESUME_SESSION_FAILURE = 'session/RESUME_SESSION_FAILURE';

export const SESSION_EXPIRED = 'session/SESSION_EXPIRED';

export const SESSION_REDIRECT_URL = 'session/REDIRECT_URL';

export const PING_SESSION_REQUESTED = 'session/PING_SESSION_REQUESTED';
export const PING_SESSION_SUCCESS = 'session/PING_SESSION_SUCCESS';
export const PING_SESSION_FAILURE = 'session/PING_SESSION_FAILURE';
export const PING_CLEAR = 'session/PING_CLEAR';

export const IMPERSONATE_ADMIN_REQUESTED =
  'account/IMPERSONATE_ADMIN_REQUESTED';
export const IMPERSONATE_ADMIN_SUCCESS = 'account/IMPERSONATE_ADMIN_SUCCESS';
export const IMPERSONATE_ADMIN_FAILURE = 'account/IMPERSONATE_ADMIN_FAILURE';

export const IMPERSONATE_FUNDRAISER_REQUESTED =
  'account/IMPERSONATE_FUNDRAISER_REQUESTED';
export const IMPERSONATE_FUNDRAISER_SUCCESS =
  'account/IMPERSONATE_FUNDRAISER_SUCCESS';
export const IMPERSONATE_FUNDRAISER_FAILURE =
  'account/IMPERSONATE_FUNDRAISER_FAILURE';

export const DELETE_IMPERSONATE_ADMIN_REQUESTED =
  'account/DELETE_IMPERSONATE_ADMIN_REQUESTED';
export const DELETE_IMPERSONATE_ADMIN_SUCCESS =
  'account/DELETE_IMPERSONATE_ADMIN_SUCCESS';
export const DELETE_IMPERSONATE_ADMIN_FAILURE =
  'account/DELETE_IMPERSONATE_ADMIN_FAILURE';

export const LOG_OUT_REQUESTED = 'session/LOG_OUT_REQUESTED';
export const LOG_OUT_SUCCESS = 'session/LOG_OUT_SUCCESS';
export const LOG_OUT_FAILURE = 'session/LOG_OUT_FAILURE';

interface SessionAccount {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
  hasMfaActive: boolean;
}

export interface Session {
  processing: boolean;
  pingProcessing: boolean;
  isSystemAdmin: boolean;
  isAdmin: boolean;
  isCampaignAdmin: boolean;
  isEventAdmin: boolean;
  isEventOrganizerAdmin: boolean;
  isFundraiser: boolean;
  isOrganizationAdmin: boolean;
  key?: any;
  account?: SessionAccount;
  organization?: any;
  campaigns?: any;
  expiry: any;
  impersonated: boolean;
  impersonator: any;
  refresh: any;
  error: any;
  errorList: any[];
  unauthorized: boolean;
  needGeneralRedirect: boolean;
  loginForm: any;
  logOutRecord: any;
  redirectUrl?: string;
  permissions?: Permissions;
}

export enum PermissionValues {
  Allow = 'allow',
  Deny = 'deny'
}

export interface Permissions {
  exportData?: (typeof PermissionValues)[keyof typeof PermissionValues];
}

const initialState: Session = {
  processing: true,
  pingProcessing: false,
  isOrganizationAdmin: false,
  isSystemAdmin: false,
  isAdmin: false,
  isCampaignAdmin: false,
  isEventAdmin: false,
  isEventOrganizerAdmin: false,
  isFundraiser: false,
  key: undefined,
  account: undefined,
  organization: undefined,
  expiry: undefined,
  impersonated: false,
  impersonator: undefined,
  refresh: undefined,
  error: undefined,
  errorList: [],
  unauthorized: true,
  needGeneralRedirect: false,
  loginForm: {
    email: undefined,
    password: undefined,
    remember: false,
    authCode: undefined,
    recoveryCode: undefined
  },
  logOutRecord: Record.getDefaultState(),
  redirectUrl: undefined
};

export default (state = initialState, action: any) => {
  let newState = state;
  switch (action.type) {
    case HANDLE_VALUE_CHANGE:
      newState = update.set(state, action.payload.path, action.payload.value);
      break;

    case RESUME_SESSION_REQUESTED:
    case AUTHENTICATE_REQUESTED:
      newState = {
        ...state,
        ...initialState,
        loginForm: {
          ...state.loginForm
        },
        error: false,
        processing: true,
        pingProcessing: state.pingProcessing
      };
      break;

    case PING_SESSION_SUCCESS:
    case RESUME_SESSION_SUCCESS:
    case AUTHENTICATE_SUCCESS:
    case ACTIVATE_USER_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS: {
      const isSystemAdmin = get(action.payload, 'data.isSystemAdmin');
      const isOrganizationAdmin = get(
        action.payload,
        'data.isOrganizationAdmin'
      );
      const isCampaignAdmin = get(action.payload, 'data.isCampaignAdmin');
      const isEventAdmin = get(action.payload, 'data.isEventAdmin');
      const isEventOrganizerAdmin = get(
        action.payload,
        'data.isEventOrganizerAdmin'
      );

      newState = {
        ...state,
        ...action.payload.data,
        processing: false,
        error: false,
        unauthorized: false,
        needGeneralRedirect: isCampaignAdmin || isEventAdmin,
        isCampaignAdmin: !isOrganizationAdmin && isCampaignAdmin,
        isAdmin:
          isSystemAdmin ||
          isOrganizationAdmin ||
          isCampaignAdmin ||
          isEventAdmin ||
          isEventOrganizerAdmin
      };

      if (action.payload.data && action.payload.data !== 'undefined') {
        localStorage.setItem('session', JSON.stringify(action.payload.data));
      } else {
        localStorage.removeItem('session');
      }

      break;
    }
    case RESUME_SESSION_NOT_POSSIBLE:
    case RESUME_SESSION_FAILURE:
    case AUTHENTICATE_FAILURE: {
      const loginForm =
        action.payload && action.payload.status === 403
          ? { ...state.loginForm }
          : { ...initialState.loginForm };

      newState = {
        ...state,
        ...initialState,
        loginForm: loginForm,
        processing: false,
        error: true,
        errorList: get(action.payload, 'error'),
        key: null
      };
      break;
    }

    case PING_SESSION_FAILURE:
      newState = {
        ...state,
        pingProcessing: true,
        key: null
      };
      break;

    case LOG_OUT_SUCCESS:
    case LOG_OUT_FAILURE:
      newState = {
        ...state,
        ...initialState,
        processing: false,
        key: null
      };
      break;

    case SESSION_EXPIRED:
      return {
        ...state,
        unauthorized: true
      };

    case SESSION_REDIRECT_URL:
      return {
        ...state,
        redirectUrl: action.payload.data.redirectUrl
      };

    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
      const sessionModel = buildSessionModelFromImpersonatedModel(state);
      newState = {
        ...sessionModel,
        processing: false,
        needGeneralRedirect: false
      };
      localStorage.setItem('session', JSON.stringify(sessionModel));
      break;

    case LOG_OUT_REQUESTED:
      localStorage.removeItem('session');
      localStorage.removeItem(localStorageKeys.PAYMENT_INFO);
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_ORGANIZATION_SUCCESS:
      if (state.isOrganizationAdmin) {
        const paymentPlatforms = get(
          action,
          'payload.data.data.paymentPlatforms'
        );
        const orgId = get(action, 'payload.data.data.id');
        const isConnected = paymentPlatforms && paymentPlatforms.length !== 0;
        localStorage.setItem(
          localStorageKeys.PAYMENT_INFO,
          JSON.stringify({
            isConnected,
            orgId
          })
        );
      }

      break;

    case PING_CLEAR:
      newState = {
        ...state,
        pingProcessing: false
      };
      break;

    default:
      return state;
  }

  return newState;
};

const buildSessionModelFromImpersonatedModel = (session: Session) => {
  return {
    ...session,
    account: session.impersonator,
    impersonated: false,
    impersonator: null,
    unauthorized: false,
    isSystemAdmin: true,
    isOrganizationAdmin: false,
    isCampaignAdmin: false,
    isEventAdmin: false,
    isFundraiser: false,
    isAdmin: true
  };
};

export const setRedirectUrl = (redirectUrl: string) => ({
  type: SESSION_REDIRECT_URL,
  payload: { data: { redirectUrl } }
});

export const mockAuthenticate = (_email: string, _password: string) => ({
  type: AUTHENTICATE_SUCCESS,
  payload: { data: { key: 'mockKey' } }
});

export const authenticate = (
  email: string,
  password: string,
  remember = false,
  verificationToken = null,
  verificationCode = null,
  verificationMethod = null
) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/session/login`,
        method: 'POST',
        headers: {
          ApiKey: constants.apikey,
          'Content-Type': 'application/json',
          Verification: verificationToken,
          otp:
            verificationMethod === verificationVariant.authCode
              ? verificationCode
              : '',
          otpRecoveryCode:
            verificationMethod === verificationVariant.recoveryCode
              ? verificationCode
              : ''
        },
        body: JSON.stringify({ email, password, remember }),
        types: [
          {
            type: AUTHENTICATE_REQUESTED
          },
          {
            type: AUTHENTICATE_SUCCESS
          },
          {
            type: AUTHENTICATE_FAILURE,
            payload: async (_action: any, _state: any, res: any) => {
              const json = await res.json();
              return {
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const resumeSession = (verificationToken = null) => {
  return (dispatch: any, getState: any) => {
    const localStorageSession = localStorage['session'];
    const session =
      localStorageSession && localStorageSession !== 'undefined'
        ? JSON.parse(localStorageSession)
        : null;

    if (session && session.key) {
      const state = getState();
      dispatch({
        [RSAA]: {
          endpoint: `${constants.baseApiHost}/api/v2/session`,
          method: 'GET',
          headers: {
            ApiKey: constants.apikey,
            Authorization: `bearer ${session.key}`,
            'Content-Type': 'application/json',
            Verification: verificationToken
          },
          types: [
            RESUME_SESSION_REQUESTED,
            RESUME_SESSION_SUCCESS,
            RESUME_SESSION_FAILURE
          ]
        }
      });
    } else {
      dispatch({ type: RESUME_SESSION_NOT_POSSIBLE });
    }
  };
};

export const impersonateAdmin = (id: any, verificationToken = null) => {
  return entityHandlers.createRecord(
    'session/impersonate',
    IMPERSONATE_ADMIN_REQUESTED,
    IMPERSONATE_ADMIN_SUCCESS,
    IMPERSONATE_ADMIN_FAILURE,
    { accountId: id },
    null,
    'impersonatedRecord',
    'v2/',
    verificationToken
  );
};

export const impersonateFundraiser = (id: any, verificationToken = null) => {
  return entityHandlers.createRecord(
    'session/impersonate/inactive',
    IMPERSONATE_FUNDRAISER_REQUESTED,
    IMPERSONATE_FUNDRAISER_SUCCESS,
    IMPERSONATE_FUNDRAISER_FAILURE,
    { accountId: id },
    null,
    'impersonatedFundraiserRecord',
    'v2/',
    verificationToken
  );
};

export const logOut = (verificationToken = null) => {
  return entityHandlers.createRecord(
    'session/logout',
    LOG_OUT_REQUESTED,
    LOG_OUT_SUCCESS,
    LOG_OUT_FAILURE,
    null,
    null,
    'logOutRecord',
    'v2/',
    verificationToken
  );
};

export const deleteImpersonation = (verificationToken = null) => {
  const endpoint = `${constants.baseApiHost}/api/v2/session/impersonate`;
  const recordKey = 'impersonatedRecord';
  return (dispatch: any, getState: any) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: endpoint,
        method: 'DELETE',
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json',
          Verification: verificationToken
        },
        types: [
          {
            type: DELETE_IMPERSONATE_ADMIN_REQUESTED,
            payload: (_action: any, _state: any) => ({ key: recordKey })
          },
          {
            type: DELETE_IMPERSONATE_ADMIN_SUCCESS,
            payload: async (_action: any, _state: any) => {
              return {
                key: recordKey
              };
            }
          },
          {
            type: DELETE_IMPERSONATE_ADMIN_FAILURE,
            payload: async (_action: any, _state: any, res: any) => {
              const json = await res.json();
              return {
                key: recordKey,
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const refreshSession = (
  accountId: any,
  code: any,
  verificationToken = null,
  verificationCode = null,
  verificationMethod = null
) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    dispatch({
      [RSAA]: {
        endpoint: `${constants.baseApiHost}/api/v2/session/refresh`,
        method: 'POST',
        headers: {
          ApiKey: constants.apikey,
          'Content-Type': 'application/json',
          Verification: verificationToken,
          otp:
            verificationMethod === verificationVariant.authCode
              ? verificationCode
              : '',
          otpRecoveryCode:
            verificationMethod === verificationVariant.recoveryCode
              ? verificationCode
              : ''
        },
        body: JSON.stringify({
          accountId: accountId,
          code: code
        }),
        types: [
          {
            type: RESUME_SESSION_REQUESTED
          },
          {
            type: RESUME_SESSION_SUCCESS
          },
          {
            type: RESUME_SESSION_FAILURE,
            payload: async (_action: any, _state: any, res: any) => {
              const json = await res.json();
              return {
                status: res.status,
                ...json
              };
            }
          }
        ]
      }
    });
  };
};

export const pingSession = (verificationToken = null) => {
  return (dispatch: any, getState: any) => {
    const state = getState();
    if (state.session.key) {
      dispatch({
        [RSAA]: {
          endpoint: `${constants.baseApiHost}/api/v2/session/ping`,
          method: 'GET',
          headers: {
            ApiKey: constants.apikey,
            Authorization: `bearer ${state.session.key}`,
            'Content-Type': 'application/json',
            Verification: verificationToken
          },
          types: [
            PING_SESSION_REQUESTED,
            PING_SESSION_SUCCESS,
            PING_SESSION_FAILURE
          ]
        }
      });
    }
  };
};

export const pingClear = () => {
  return {
    type: PING_CLEAR
  };
};

export const handleValueChange = (path: any, value: any) => {
  return {
    type: HANDLE_VALUE_CHANGE,
    payload: {
      path: path,
      value: value
    }
  };
};
