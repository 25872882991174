import React, { useState, useEffect } from "react";
import { Translate } from "react-redux-i18n";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Button, Form, Loader } from "semantic-ui-react";
import axios from "axios";
import moment from "moment";
import get from "lodash.get";

import CurrencyFormatter from "../../../../components/common/grid/cells/currencyFormatter";

import config from "../../../../constants";

import { getEventAmbassadorRecord } from "../../store/vipProgramActions";

export const PartOneInfo = (props) => {
  const {
    dateReceived,
    fundraiserRaisedAmount,
    fundraiserUrlFull,
    fundraiserId,
    fundraiserName,
    eventName,
    eventAmbassadorId,
    eventAmbassadorProgramName
  } = props;

  const session = useSelector(state => get(state, "session"));
  const eventAmbassador = useSelector(state => get(state, "program.eventAmbassadorRecord.data.data"));

  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalOptions, setModalOptions] = useState([]);
  const [selectedFundraiser, setSelectedFundraiser] = useState(fundraiserId);

  const dispatch = useDispatch();

  useEffect(() => {
    if (modalOpen) {
      (async () => {
        setModalLoading(true);
        const result = await axios.post(
          `${config.baseApiHost}/api/v3/page/search`,
          {
            page: 1,
            pageSize: 10,
            resultType: "Full",
            filters: [
              {
                key: "campaignId",
                operator: "EqualTo",
                value: [get(eventAmbassador, "campaignId")]
              },
              {
                key: "isActive",
                operator: "EqualTo",
                value: ["true"]
              },
              {
                key: "accountId",
                operator: "EqualTo",
                value: [get(eventAmbassador, "accountId")]
              },
              {
                key: "fundraiserId",
                operator: "NotEqualTo",
                value: [get(eventAmbassador, "fundraiserId")]
              },
              {
                key: "type",
                operator: "EqualTo",
                value: ["fundraiser"]
              }
            ]
          },
          {
            headers: {
              ApiKey: config.apikey,
              Authorization: `bearer ${session.key}`,
            },
          }
        );

        if (result.data.success) {
          setSelectedFundraiser(fundraiserId);
          setModalOptions([
            {
              value: fundraiserId,
              text: `(Current page) - ${fundraiserName} (${fundraiserId}) - $${fundraiserRaisedAmount}`,
            },
            ...result.data.data.list.map(item => ({
              value: item.fundraiserId,
              text: `${item.name} (${item.fundraiserId}) - $${item.raisedAmount}`,
            })),
          ]);
        }

        setModalLoading(false);
      })();
    }
  }, [modalOpen]);

  const handleChangeFundraiser = async () => {
    setModalLoading(true);

    const result = await axios.put(
      `${config.baseApiHost}/api/v2/eventAmbassador/${eventAmbassadorId}/fundraiser`,
      { fundraiserId: selectedFundraiser, },
      {
        headers: {
          ApiKey: config.apikey,
          Authorization: `bearer ${session.key}`,
        },
      }
    );

    if (result.data.success) {
      setModalOpen(false);
      dispatch(getEventAmbassadorRecord(eventAmbassadorId));
    }

    setModalLoading(false);
  };

  return (
    <>
      <div className="template-content-container">
        <div className="template-name">
          <Translate value="applications.template.page.partOne.event-name.label" />
          <span>{eventName}</span>
        </div>

        <div className="template-name">
          <Translate value="applications.template.page.partOne.program-name.label" />
          <span>{eventAmbassadorProgramName}</span>
        </div>

        <div className="template-raised-amount">
          <Translate value="applications.template.page.partOne.raised-amount-name.label" />
          <CurrencyFormatter value={fundraiserRaisedAmount} />
        </div>

        <div className="template-link">
          <span>
            <Translate value="applications.template.page.partOne.fundraiser-page-name.label" />
            {session.isSystemAdmin && (
              <a
                style={{
                  cursor: "pointer",
                }}
                onClick={() => setModalOpen(true)}
              >
                &nbsp;(edit)
              </a>
            )}
            :&nbsp;
          </span>
          <a href={fundraiserUrlFull} target="_blank" rel="noreferrer">
            {fundraiserUrlFull}
          </a>
        </div>

        <div className="template-date">
          <Translate value="applications.template.page.partOne.date-received.label" />
          {dateReceived
            ? moment(dateReceived).format(
              config.DEFAULT_FULL_DATE_TIME_PERIODS_FORMAT
            )
            : null}
        </div>
      </div>
      <Modal
        className="wizard"
        dimmer="inverted"
        size="tiny"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
      >
        <Modal.Header>
          <Translate value="applications.template.page.partOne.change-page-modal.header" />
        </Modal.Header>
        <Modal.Content>
          <div
            style={{
              textAlign: "center",
            }}
          >
            <Translate
              style={{
                marginBottom: "2rem",
                display: "flex",
                flexDirection: "column",
              }}
              value="applications.template.page.partOne.change-page-modal.description"
            />
            {modalLoading ? (
              <Loader
                active
                inline
              />
            ) : (
              <Form.Dropdown
                selection
                value={selectedFundraiser}
                options={modalOptions}
                onChange={(event, data) => setSelectedFundraiser(data.value)}
              />
            )}
          </div>
          <div
            style={{
              marginTop: "2rem",
              textAlign: "center",
            }}
          >
            <Button
              primary
              disabled={modalLoading}
              onClick={handleChangeFundraiser}
            >
              <Translate value="applications.template.page.partOne.change-page-modal.confirm" />
            </Button>
            <Button
              style={{
                width: 95,
              }}
              onClick={() => setModalOpen(false)}
            >
              <Translate value="applications.template.page.partOne.change-page-modal.close" />
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    </>
  );
};
