import update from '../helpers/update';
import get from 'lodash.get';
import { CLEAR } from './general';
import * as entityHandlers from '../modules/bin/entityHandlers';
import AutocompleteService from './autocompleteService';

export const AUTOCOMPLETE_REQUESTED = 'AUTOCOMPLETE_REQUESTED';
export const AUTOCOMPLETE_SUCCESS = 'AUTOCOMPLETE_SUCCESS';
export const AUTOCOMPLETE_FAILURE = 'AUTOCOMPLETE_FAILURE';

export const AUTOCOMPLETE_SELECTED_ITEM_SET = 'AUTOCOMPLETE_SELECTED_ITEM_SET';
export const AUTOCOMPLETE_RESET = 'AUTOCOMPLETE_RESET';
export const AUTOCOMPLETE_OUTER_CLEAR = 'AUTOCOMPLETE_OUTER_CLEAR';

export const AUTOCOMPLETE_SEARCH_CHANGE = 'AUTOCOMPLETE_SEARCH_CHANGE';

const initialState = {};

export const autocompleteOuterClear = (path, searchString = '') => {
  return {
    type: AUTOCOMPLETE_OUTER_CLEAR,
    payload: {
      path,
      searchString
    }
  };
};

export const searchEntitiesActions = {
  start: (path) => {
    return {
      type: AUTOCOMPLETE_REQUESTED,
      payload: {
        path: path,
        timestamp: new Date()
      }
    };
  },

  success: (data, path) => {
    return {
      type: AUTOCOMPLETE_SUCCESS,
      payload: {
        data: data,
        timestamp: new Date(),
        path: path
      }
    };
  },

  failed: () => {
    return {
      type: AUTOCOMPLETE_FAILURE
    };
  }
};

export const extendedSearchEntities = (entity, path, request, token) => {
  return (dispatch) => {
    dispatch(searchEntitiesActions.start(path));

    AutocompleteService.searchEntities(entity, token, request)
      .then((firstPageResponse) => {
        const totalCount = get(firstPageResponse, 'data.data.totalCount');
        const pageSize = get(firstPageResponse, 'data.data.pageSize');
        const isMoreRecords = totalCount > pageSize;

        if (isMoreRecords) {
          request = update.set(request, 'page', 2);

          AutocompleteService.searchEntities(entity, token, request)
            .then((secondPageResponse) => {
              const firstPageList = get(firstPageResponse, 'data.data.list');
              const secondPageList = get(secondPageResponse, 'data.data.list');
              const totalList = [...firstPageList, ...secondPageList];

              const secondPageData = update.set(
                secondPageResponse,
                'data.data.list',
                totalList
              );

              dispatch(
                searchEntitiesActions.success(secondPageData.data.data, path)
              );
            })
            .catch((error) => {
              dispatch(searchEntitiesActions.failed(path));
              console.log(error);
            });
        } else {
          dispatch(
            searchEntitiesActions.success(firstPageResponse.data.data, path)
          );
        }
      })
      .catch((error) => {
        dispatch(searchEntitiesActions.failed(path));
        console.log(error);
      });
  };
};

export const searchEntities = (entity, path, request) =>
  entityHandlers.searchEntities(
    entity,
    path,
    request,
    AUTOCOMPLETE_REQUESTED,
    AUTOCOMPLETE_SUCCESS,
    AUTOCOMPLETE_FAILURE
  );

export const setSelectedItem = (path, item) => {
  return {
    type: AUTOCOMPLETE_SELECTED_ITEM_SET,
    payload: {
      path,
      item
    }
  };
};

export const reset = (path) => {
  return {
    type: AUTOCOMPLETE_RESET,
    payload: {
      path
    }
  };
};

export const onSearchChange = (path, searchString) => {
  return {
    type: AUTOCOMPLETE_SEARCH_CHANGE,
    payload: {
      path,
      searchString
    }
  };
};

const autocompleteReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case CLEAR: {
      newState = {};
      break;
    }

    case AUTOCOMPLETE_OUTER_CLEAR: {
      newState = update.set(newState, `${action.payload.path}`, {});
      newState = update.set(
        newState,
        `${action.payload.path}.searchString`,
        action.payload.searchString
      );
      newState = update.set(
        newState,
        `${action.payload.path}.outerClear`,
        true
      );
      break;
    }

    case AUTOCOMPLETE_RESET: {
      newState = update.set(newState, `${action.payload.path}`, {});
      newState = update.set(
        newState,
        `${action.payload.path}.searchString`,
        ''
      );
      break;
    }

    case AUTOCOMPLETE_REQUESTED: {
      newState = update.set(newState, `${action.payload.path}.loading`, true);
      newState = update.set(
        newState,
        `${action.payload.path}.timestamp`,
        action.payload.timestamp
      );
      break;
    }

    case AUTOCOMPLETE_SUCCESS: {
      if (
        action.payload.timestamp >=
        get(newState, `${action.payload.path}.timestamp`)
      ) {
        newState = update.set(
          newState,
          `${action.payload.path}.loading`,
          false
        );
        newState = update.set(
          newState,
          `${action.payload.path}.data`,
          action.payload.data
        );
        newState = update.set(
          newState,
          `${action.payload.path}.outerClear`,
          false
        );
      }
      break;
    }

    case AUTOCOMPLETE_SEARCH_CHANGE: {
      newState = update.set(
        newState,
        `${action.payload.path}.searchString`,
        action.payload.searchString
      );
      break;
    }

    case AUTOCOMPLETE_SELECTED_ITEM_SET: {
      newState = update.set(
        newState,
        `${action.payload.path}.selected`,
        action.payload.item
      );
      break;
    }
    default:
      return newState;
  }

  return newState;
};

export default autocompleteReducer;
