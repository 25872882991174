import {
    fieldTypes,
    fieldViewTypes,
    nodeTypes,
    fieldViewTypesValidation,
    fieldsVisibility,
    additionNodeTypes
} from './typeDefinitions';

const specificationOptions = [
    {
        text: 'Text',
        value: 'textInput'
    },
    {
        text: 'Additional first name',
        value: 'additionalFirstName'
    },
    {
        text: 'Additional last name',
        value: 'additionalLastName'
    }
];

const titleField = {
    fieldName: fieldTypes.title,
    viewType: fieldViewTypes.text,
    validation: fieldViewTypesValidation.title,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const descriptionField = {
    fieldName: fieldTypes.description,
    viewType: fieldViewTypes.text,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const placeholderTextField = {
    fieldName: fieldTypes.placeholderText,
    viewType: fieldViewTypes.text,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const requiredField = {
    fieldName: fieldTypes.required,
    viewType: fieldViewTypes.checkbox,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const optOutField = {
    fieldName: fieldTypes.optOut,
    viewType: fieldViewTypes.checkbox,
    visibility: fieldsVisibility.isSystemAdminOnly.key,
    path: 'props',
    onChange: (editNodeFunction, value, node) => {
        editNodeFunction({
            ...node,
            answer: value ? 'accepted' : null,
            props: {
                ...node.props,
                optOut: value,
            }
        }, node);
    },
};
const optionsField = {
    fieldName: fieldTypes.options,
    viewType: fieldViewTypes.textArea,
    validation: fieldViewTypesValidation.options,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const linkField = {
    fieldName: fieldTypes.link,
    viewType: fieldViewTypes.text,
    validation: fieldViewTypesValidation.link,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const contentField = {
    fieldName: fieldTypes.content,
    viewType: fieldViewTypes.textArea,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const hiddenField = {
    fieldName: fieldTypes.hidden,
    viewType: fieldViewTypes.checkbox,
    visibility: fieldsVisibility.isSystemAdminOnly.key,
    path: 'props'
};
const specificationField = {
    fieldName: fieldTypes.specification,
    viewType: fieldViewTypes.dropdown,
    visibility: fieldsVisibility.isSystemAdminOnly.key,
    options: specificationOptions,
    path: ''
};
const groupDividerTitleField = {
    fieldName: fieldTypes.title,
    viewType: fieldViewTypes.text,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};
const doNotExport = {
    fieldName: fieldTypes.doNotExport,
    viewType: fieldViewTypes.checkbox,
    visibility: fieldsVisibility.isSystemAdminOnly.key,
    path: 'props'
};
const exportLabel = {
    fieldName: fieldTypes.exportLabel,
    viewType: fieldViewTypes.text,
    validation: fieldViewTypesValidation.exportLabel,
    visibility: fieldsVisibility.visible.key,
    path: 'props'
};

const componentDescriptors = {
    [nodeTypes.textInput]: [
        titleField,
        descriptionField,
        placeholderTextField,
        specificationField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.targetAmount]: [titleField, descriptionField, requiredField],
    [nodeTypes.checkbox]: [
        titleField,
        descriptionField,
        optionsField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.date]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.textArea]: [
        titleField,
        descriptionField,
        placeholderTextField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.dropdown]: [
        titleField,
        descriptionField,
        optionsField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.address]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        doNotExport
    ],
    [nodeTypes.country]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        doNotExport
    ],
    [nodeTypes.phone]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        doNotExport
    ],
    [nodeTypes.groupDivider]: [
        groupDividerTitleField,
        exportLabel,
        descriptionField,
        doNotExport
    ],
    [nodeTypes.additionalPhoneNumber]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        doNotExport
    ],
    [nodeTypes.additionalEmailAddress]: [
        titleField,
        descriptionField,
        exportLabel,
        requiredField,
        doNotExport
    ],
    [additionNodeTypes.additionalFirstName]: [
        titleField,
        descriptionField,
        placeholderTextField,
        specificationField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [additionNodeTypes.additionalLastName]: [
        titleField,
        descriptionField,
        placeholderTextField,
        specificationField,
        exportLabel,
        requiredField,
        hiddenField,
        doNotExport
    ],
    [nodeTypes.consent]: [
        titleField,
        linkField,
        contentField,
        exportLabel,
        requiredField,
        optOutField,
        hiddenField,
        doNotExport
    ]
};

// eslint-disable-next-line import/prefer-default-export
export { componentDescriptors };
