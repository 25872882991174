export const ITEM_TYPES = {
  STRING: 'String',
  BOOLEAN: 'Boolean',
  DATETIME: 'DateTime',
  DATE: 'Date',
  NUMBER: 'Number',
  SELECT: 'Select',
  LOOKUP: 'Lookup',
  TREE_LOOKUP: 'TreeLookup'
};

export const UNKNOWN_TYPE_EXCEPTION = 'Unknown type for filter component.';

export const SEARCH_TEXT_KEY = 'searchText';

export const DEFAULT_TIME = {
  hours: 23,
  minutes: 59,
  seconds: 59,
  milliseconds: 999
};
