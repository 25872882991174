import React from 'react';
import { TextArea } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import FormbuilderFieldWrapper from '../../../../formBuilderAnswersComponents/formbuilderFieldWrapper';

const TextAreaField = ({ label, value, update, fieldName, validation }) => (
  <FormbuilderFieldWrapper label={label} validation={validation} value={value}>
    <TextArea
      placeholder={value}
      value={value}
      onChange={(e) => update(e.target.value)}
      id={`${fieldName}Id`}
    />
  </FormbuilderFieldWrapper>
);

TextAreaField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  update: PropTypes.func
};

export default TextAreaField;
