import React, { Component } from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

class Button extends Component {
  render() {
    const { children, ...props } = this.props;
    return <SemanticButton {...props}>{children}</SemanticButton>;
  }
}

export default Button;
