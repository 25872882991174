export const recurringAvailableFiltersKeys = [
  'startedAtLocal',
  'amount',
  'raisedAmount',
  'status'
];

export const recurringContract = {
  organization: 'organizationId',
  amount: 'amount',
  firstName: 'donor.firstName',
  lastName: 'donor.lastName',
  email: 'donor.email',
  frequency: 'frequency',
  donationCountLimit: 'donationCountLimit',
  payment: 'payment',
  coverFee: 'payment.coverFee',
  fee: 'payment.fee'
};

export const donationRecurringFrequency = {
  daily: 'daily',
  weekly: 'weekly',
  monthly: 'monthly'
};

export const donationRecurringFrequencyOptions = (isSystemAdmin = false) => [
  ...(isSystemAdmin ? [{
    value: donationRecurringFrequency.daily,
    text: 'Daily',
    devMode: false
  }] : []),
  {
    value: donationRecurringFrequency.weekly,
    text: 'Weekly',
    devMode: false
  },
  {
    value: donationRecurringFrequency.monthly,
    text: 'Monthly',
    devMode: false
  }
];

export const installmentCountOptions = Array.apply(null, Array(9)).map(
  (_, x) => {
    const value = x + 2;
    return {
      value,
      text: value.toString()
    };
  }
);

export const donationCountBottomLimit = 3;
export const donationCountTopLimit = 24;
export const donationCountLimitStep = 3;

export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm a';
