import React from 'react';
import { connect } from 'react-redux';
import * as templateHelper from '../../template/templateHelper';
import { TemplateCheckbox } from '../../template/templateCheckbox';

export class CoverFeeView extends React.Component {
  componentDidMount() {
    if (this.props.amount) {
      this.changePaymentFee(this.props.value);
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.amount !== prevProps.amount) {
      this.changePaymentFee(this.props.value);
    }
  }

  changePaymentFee = (coverFee) => {
    if (coverFee) {
      const amount = this.props.amount ? this.props.amount : 0;
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.props.recalculatePaymentFee(
          this.props.primaryPath,
          amount,
          coverFee
        );
      }, 500);
    }
  };

  onCoverProcessingFeeToggle = () => {
    const coverFee = !this.props.value;
    this.changePaymentFee(coverFee);
    this.props.onChange(this.props.path, coverFee);
  };

  render() {
    const { id, path, inputLabel } = this.props;
    return (
      <TemplateCheckbox
        id={id}
        path={path}
        inputLabel={inputLabel}
        onChange={this.onCoverProcessingFeeToggle}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

const CoverFee = connect(mapState)(CoverFeeView);
export default CoverFee;
