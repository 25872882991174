import React, { useEffect } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { useParams } from 'react-router-dom';
import { Modal } from 'semantic-ui-react';
import { Common } from '../../../components';
import { RouteParams } from '../../../containers/app';
import { useAppDispatch } from '../../../hooks';
import { ITerminal, ITerminalMetadata } from '../../../models/Terminal';
import { RootState } from '../../../store';
import {
  defaultTerminalListSearch,
  updateTerminal
} from '../store/terminalActions';
import { assignedFilter, unassignedFilter } from '../store/terminalReducer';
import { DeviceInput } from './DeviceInput';

interface IEditDeviceProps {
  terminal: ITerminal;
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

export const EditDevice = ({
  terminal,
  isOpen,
  setIsOpen
}: IEditDeviceProps) => {
  // hooks
  const dispatch = useAppDispatch();
  const terminalReducer = useSelector((state: RootState) => state.terminal);
  const [inputState, setInputState] = useState<ITerminalMetadata | null>();
  const { tab } = useParams<RouteParams>();
  // states
  const [isLoading, setIsLoading] = useState(false);
  // defaults
  const isAssignedTab = tab === 'assigned';

  const onSaveClick = () => {
    setIsLoading(true);
    if (inputState) {
      dispatch(updateTerminal(inputState));
    } else {
      setIsOpen(false);
      setInputState(null);
    }
  };

  useEffect(() => {
    if (
      isOpen &&
      isLoading &&
      (terminalReducer.record.success.show || terminalReducer.record.error)
    ) {
      setTimeout(() => {
        const filter = isAssignedTab ? assignedFilter : unassignedFilter;
        setIsLoading(false);
        setIsOpen(false);
        setInputState(null);
        dispatch(defaultTerminalListSearch(filter));
      }, 100);
    }
  }, [terminalReducer, terminalReducer.record.error]);

  return (
    <Modal open={isOpen} size="tiny">
      <Modal.Content>
        <h3 className="modal-title">
          <Translate value="terminal.edit-modal.title" />
        </h3>
        <DeviceInput inputData={terminal} onChange={setInputState} />
      </Modal.Content>
      <Modal.Actions>
        <Common.Button
          disabled={isLoading}
          onClick={() => {
            setIsOpen(false);
            setInputState(null);
          }}
          content={<Translate value="terminal.edit-modal.cancel" />}
        />
        <Common.Button
          primary
          disabled={!inputState?.serialNumber}
          loading={isLoading}
          content={<Translate value="terminal.edit-modal.save" />}
          onClick={onSaveClick}
        />
      </Modal.Actions>
    </Modal>
  );
};
