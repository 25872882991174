import React from 'react';
import { Icon } from 'semantic-ui-react';

const AccountStatus = (props) => {
  let color = 'green';
  if (props.value === 'invited') {
    color = 'orange';
  }
  return (
    <span className="status">
      <Icon color={color} name="circle" />
      <span className="capitalize">{props.value}</span>
    </span>
  );
};

export default AccountStatus;
