import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';

import * as templateHelper from '../../template/templateHelper';
import { stringToArray } from '../../../helpers/arrayHelper';

import { Form } from '../../';
import { TemplateHeader } from '../../template/templateHeader';

import { fitnessMeasureOptions } from '../../../pages/campaign/constants';
import { campaignContract } from '../../../pages/campaign/create/templates';

class FitnessLeaderboardsToShowContainer extends Component {
  isFitnessLeaderboardsToShowOptionChecked = (option) => {
    return this.props.value.some((item) => item === option);
  };

  handleFitnessLeaderboardsToShowOptionChange = (value, evt, { checked }) => {
    const fitnessLeaderboardsToShow = cloneDeep(this.props.value);

    if (checked) {
      fitnessLeaderboardsToShow.push(value);
    } else {
      const fitnessLeaderboardsToShowIndex =
        fitnessLeaderboardsToShow.findIndex((item) => item === value);

      if (fitnessLeaderboardsToShowIndex !== -1) {
        fitnessLeaderboardsToShow.splice(fitnessLeaderboardsToShowIndex, 1);
      }
    }

    this.props.onChange(this.props.path, fitnessLeaderboardsToShow);
  };

  getFitnessLeaderboardsToShowOptions = () => {
    return fitnessMeasureOptions.map((option) => {
      const isChecked = this.isFitnessLeaderboardsToShowOptionChecked(
        option.value
      );

      const isVisible =
        option.isVisible(stringToArray(this.props.activities)) &&
        stringToArray(this.props.trackedStatistics).some(
          (item) => item === option.value
        );

      return (
        <React.Fragment>
          {isVisible && (
            <Form.Checkbox
              key={option.key}
              inputLabel={I18n.t(
                `template.page.content.fitness.fitnessLeaderboardsToShow.options.${option.i18nKey}`
              )}
              checked={isChecked}
              onChange={this.handleFitnessLeaderboardsToShowOptionChange.bind(
                this,
                option.value
              )}
            />
          )}
        </React.Fragment>
      );
    });
  };

  render() {
    const fitnessLeaderboardsToShowOptions =
      this.getFitnessLeaderboardsToShowOptions();

    return (
      <React.Fragment>
        <TemplateHeader
          as="h4"
          label="template.page.content.fitness.fitnessLeaderboardsToShow.label"
        />
        {fitnessLeaderboardsToShowOptions}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      templateHelper.getValue(state.templateBuilderEx, ownProps.path) || [],
    activities: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.fitnessAllowedTypes
    ),
    trackedStatistics: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.fitnessAllowedTrackedStatistics
    )
  };
};

const FitnessLeaderboardsToShow = connect(mapState)(
  FitnessLeaderboardsToShowContainer
);
export default FitnessLeaderboardsToShow;
