
export interface IHeaderOptions {
  apikey: string;
  sessionKey?: string;
}

export function buildHeaders({
  apikey,
  sessionKey
}: IHeaderOptions) {
  const headers: HeadersInit = {
    ApiKey: apikey,
    'Content-Type': 'application/json',
  }
  if (sessionKey) headers["Authorization"] = `bearer ${sessionKey}`;

  return headers;
}

export enum HtttpRequestMethods {
  Post = "POST",
  Put = "PUT",
  Get = "GET",
  Delete = "DELETE",
  Patch = "PATCH",
  Head = "HEAD"
}

export interface IRequestArgs<Values> extends IHeaderOptions {
  url: string;
  method?: HtttpRequestMethods;
  body?: Values;
}

export async function sendRequest<Values>({
  url,
  apikey,
  sessionKey,
  method = HtttpRequestMethods.Get,
  body
}: IRequestArgs<Values>) {
  return fetch(url, {
    method,
    headers: buildHeaders({ apikey, sessionKey }),
    body: body ? JSON.stringify(body) : null,
  });
}
