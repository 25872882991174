//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from '../templateHelper';
import get from 'lodash.get';

//------------Components
import { CollectionBuilder } from '../../collectionBuilder';
import SkuItem from '../../collectionBuilder/components/skuItem';

class ManagedSkuBuilder extends Component {
  getModel = () => {
    return {
      name: '',
      description: '',
      pricing: {
        price: 0,
        hasCountryTax: false
      },
      inventory: {
        quantity: 0
      }
    };
  };
  render() {
    const { path, disabled, validation, onChange } = this.props;
    return (
      <CollectionBuilder
        path={path}
        onChange={onChange}
        disabled={disabled}
        validation={validation}
        entity="merchandise"
        i18Key="merchandise-detail.skus"
        model={this.getModel()}
        itemType={<SkuItem country={this.props.country} />}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    country: get(state, 'templateBuilderEx.data.organization.country'),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateSkuBuilder = connect(mapState)(ManagedSkuBuilder);
