import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import get from 'lodash.get';
import { cloneDeep } from 'lodash';

import { Grid, Dropdown } from "semantic-ui-react";

import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import * as programActions from '../../store/vipProgramActions';
import { formBuilderActions } from '../../../../components/formbuilder/store';

import { Record } from '../../../../modules/bin/utility';
import BasePage from '../../../../components/common/basePageView';
import clear from '../../../../components/clear';
import { TemplateOptions } from '../../../../components/template/templateOptions';
import ProgramTemplate from '../templateEx';
import Spinner from '../../../../components/common/spinner';
import RecordResultMessage from '../../../../components/common/recordResult';
import { ChangeAvailabilityModal } from '../../changeAvailabilityModal';

import { paths } from '../constants/template';
import { programStatuses } from '../../constants';
import { generalFormBuilderPath } from '../../../../constants/formBuilder';
import config from '../../../../constants';

class EditProgram extends Component {
  constructor(props) {
    super(props);
    const campaignId = get(props, 'match.params.campaignId');

    props.programActions.getCampaignAmbassadorProgramByCampaignRecord(
      campaignId
    );
  }

  onSave = (data) => {
    this.props.programActions.updateProgramRecord(data);
  };

  onCancel = (data) => {
    this.props.templateBuilderActions.clearItems(data);
    this.goToVipProgramsView();
  };

  initTemplate = () => {
    let campaignAmbassadorProgram = cloneDeep(
      get(this.props, 'campaignAmbassadorProgramRecord.data.data')
    );

    const applicationsAdditionalQuestionsFormBuilder = get(
      campaignAmbassadorProgram,
      `${generalFormBuilderPath}.additional`
    );
    const templateOptions = new TemplateOptions();

    templateOptions.hide(paths.EVENT_AMBASSADOR_PROGRAM_ID);
    templateOptions.hide(paths.ORGANIZATION_ID);
    templateOptions.hide(paths.VIP_PLACES_ALLOCATED);

    templateOptions.setModel(campaignAmbassadorProgram);

    templateOptions.isEdit();

    this.props.formBuilderActions.setFormBuilderModel(
      applicationsAdditionalQuestionsFormBuilder
    );
    this.props.templateBuilderActions.setTemplateModel(
      templateOptions.getWithRule()
    );
  };

  isAllRecordsReady = () => {
    return Record.isRecordReady(this.props.campaignAmbassadorProgramRecord);
  };

  clearMessages = () => {
    this.props.programActions.clearRecordMessages('editProgramRecord');
  };

  goToVipProgramsView = () => {
    this.props.history.push(config.DEFAULT_PROGRAMS_URL);
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const isSaving = Record.isRecordLoading(this.props.editProgramRecord);

    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }

    const currentStatus = get(this.props.campaignAmbassadorProgramRecord, "data.data.availability.newEntries") ? "applications_open" : "applications_closed";

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.editProgramRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToVipProgramsView}
        />

        <Grid>
          <Grid.Row
            style={{
              paddingBottom: 0,
            }}
          >
            <Grid.Column
              width={13}
              style={{
                display: "flex",
                alignItems: "flex-end",
              }}
            >
              <h1>
                <Translate value="eventPrograms.template.edit-header" />
              </h1>
            </Grid.Column>
            {this.props.isSystemAdmin && (
              <Grid.Column width={2}>
                <Dropdown
                  style={{
                    marginBottom: 0,
                  }}
                  id="program-availability-statuses"
                  selection
                  options={Object.keys(programStatuses).map(status => ({
                    text: programStatuses[status].description,
                    value: status.toLowerCase()
                  }))}
                  value={currentStatus}
                  onChange={(event, { value }) => {
                    if (value === currentStatus) return;
                    this.props.programActions.toggleChangeAvailabilityModal(
                      currentStatus === "applications_open" ? "CloseApplications" : "OpenApplications",
                      get(this.props.campaignAmbassadorProgramRecord, "data.data.id")
                    );
                  }}
                  loading={isSaving}
                  icon="chevron down"
                ></Dropdown>
              </Grid.Column>
            )}
          </Grid.Row>
        </Grid>

        <ProgramTemplate
          onSave={this.onSave}
          onCancel={this.onCancel}
          isLoading={isSaving}
        />

        <ChangeAvailabilityModal />
      </BasePage>
    );
  }
}

const mapState = ({ program, templateBuilderEx, session }) => {
  return {
    campaignAmbassadorProgramRecord: program.campaignAmbassadorProgramRecord,
    editProgramRecord: program.editProgramRecord,
    isTemplateInited: templateBuilderEx.inited,
    isSystemAdmin: session.isSystemAdmin,
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

const EditProgramContainer = clear(connect(mapState, mapDispatch)(EditProgram));
export default EditProgramContainer;
