import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { getAvatarImagePath } from '../../helpers/imageUrlHelper';
import { Loader } from 'semantic-ui-react';

import classNames from 'classnames';

class Upload extends Component {
  onDrop = (files, rejected) => {
    if (this.props.onDrop) {
      this.props.onDrop(files);
    }
  };

  state = {
    imageError: false
  };

  imageLoadErrorHandler = (event) => {
    if (!this.state.imageError) {
      this.setState({ imageError: true });
    }
  };

  getClasses(position) {
    let classes = {
      dropzoneClass: classNames('dropzone', { [`dropzone-${position}`]: true }),
      loaderClass: classNames('image-loader')
    };

    return classes;
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ imageError: false });
    }
  }

  getAccept = () => {
    if (this.props.format) {
      return this.props.format
        .map((item) => 'image/' + item.toLowerCase())
        .join(',');
    } else {
      return 'image/*';
    }
  };

  render() {
    const classes = this.getClasses(this.props.position);
    let showImage =
      this.props.url && !this.state.imageError ? (
        <img
          alt=""
          className="profile-image"
          onError={this.imageLoadErrorHandler}
          src={getAvatarImagePath(this.props.url)}
        />
      ) : (
        ''
      );

    if (this.props.uploading) {
      showImage = (
        <div className="image-loader-container">
          <Loader className={classes.loaderClass} active inline="centered" />
        </div>
      );
    }

    return (
      <Dropzone
        accept={this.getAccept()}
        className={classes.dropzoneClass}
        onDrop={this.onDrop}
      >
        <div className="default-image-container">{showImage}</div>
        <div className="select-image-text">
          <div className="select-image-text-inner">
            <h4>Select an image from your computer</h4>
            {this.props.format.join(' or ')} format. <br />
            At least {this.props.width} x {this.props.height} pixels
          </div>
        </div>
      </Dropzone>
    );
  }
}

export default Upload;
