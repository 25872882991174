import React, { Fragment, useEffect, useState } from 'react';
import { Form, Card, Button } from 'semantic-ui-react';
import { I18n, Translate } from 'react-redux-i18n';
import ValidatedInput from '../../components/common/validatedInput';
import { TemplateAutocomplete } from '../../components/template/templateAutocomplete';
import { isValid } from '../../helpers/validationHelper';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../store';
import { useAppDispatch } from '../../hooks';
import {
  clearWebhookErrors,
  createWebhook,
  updateWebhook
} from './store/webhookActions';
import SavedEntityModal from '../../components/common/savedEntityModal';
import Text from '../../components/common/text';
import { Record } from '../../modules/bin/utility';
import RecordResultMessage from '../../components/common/recordResult';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { TemplateDropdown } from '../../components/template/templateDropdown';
import {
  notificationEntityOptions,
  notificationEventTypeOptions
} from './constants';
import { IWebhook } from '../../models/Webhook';

interface WebhookInputProps extends RouteComponentProps {
  inputType: 'setup' | 'edit';
  webhook?: IWebhook;
}

const ModalContent = ({
  path,
  endPoint
}: {
  path: string;
  endPoint: string;
}) => (
  <Fragment>
    <Text style={{ textAlign: 'center', wordWrap: 'break-word' }}>
      {I18n.t(`${path}.saved-modal.create-message`, { endPoint })}
    </Text>
  </Fragment>
);

const ModalButtons = ({
  path,
  onBackClick
}: {
  path: string;
  onBackClick: () => void;
}) => (
  <Fragment>
    <Button onClick={onBackClick}>
      {I18n.t(`${path}.saved-modal.return-button`)}
    </Button>
  </Fragment>
);

const findEnumValue = (data: any[], text: string) =>
  data.find((e) => {
    return (
      text &&
      (e.locale || e.text).toLowerCase().replace(/\s/g, '') ===
        text.toString().toLowerCase()
    );
  }) || ({} as any);

const WebhookInput = ({ history, inputType }: WebhookInputProps) => {
  const session = useSelector((state: RootState) => state.session);
  const webhook = useSelector((state: RootState) => state.webhook);
  const dispatch: AppDispatch = useAppDispatch();
  const [endPoint, setEndPoint] = useState('');
  const [organization, setOrganization] = useState<any>();
  const [type, setType] = useState('');
  const [entity, setEntity] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const typeLabelPath = `webhooks.page.${inputType}`;
  const inputLabelPath = 'webhooks.page.input';
  const orgName = organization ? organization.name : undefined;
  const entityOption = findEnumValue(notificationEntityOptions, entity);
  const typeOption = findEnumValue(notificationEventTypeOptions, type);

  const onBackClick = () => {
    history.push(`/webhooks`);
  };

  const getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  const getOrganizationItem = (item: any) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  const onSaveClick = () => {
    setIsLoading(true);
    const data = {
      subscriberEndpoint: endPoint,
      organizationId: organization.id,
      singleType: type,
      singleEntity: entity
    };

    if (inputType === 'setup') {
      dispatch(createWebhook(data));
    } else {
      const record = webhook.record.data.data;
      dispatch(updateWebhook(record.id, data));
    }
  };

  useEffect(() => {
    // prettier-ignore
    const isFormValid = organization && organization.id > 0 && endPoint && isValid({ url: true }, endPoint);
    setIsButtonDisabled(!isFormValid);
  }, [endPoint, organization]);

  useEffect(() => {
    if (session.organization) {
      setOrganization(session.organization);
    }
  }, [session.organization]);

  useEffect(() => {
    const isSuccess = Record.isSuccess(webhook.recordCreate);
    const isLoading = Record.isRecordLoading(webhook.recordCreate);
    setShowModal(showModal || isSuccess);
    setIsLoading(isLoading);
  }, [webhook.recordCreate]);

  useEffect(() => {
    const isSuccess = Record.isSuccess(webhook.record);
    if (!isSuccess) return;
    const record = webhook.record.data.data;
    if (record) {
      setEndPoint(record.subscriberEndpoint);
      setOrganization(record.organization);
      setType(record.singleType);
      setEntity(record.singleEntity);
      return;
    }
  }, [webhook.record]);

  useEffect(() => {
    const isSuccess = Record.isSuccess(webhook.recordEdit);
    const isLoading = Record.isRecordLoading(webhook.recordEdit);
    setShowModal(showModal || isSuccess);
    setIsLoading(isLoading);
  }, [webhook.recordEdit]);

  return (
    <div>
      <RecordResultMessage
        record={webhook.recordCreate}
        onDismiss={() => dispatch(clearWebhookErrors('record'))}
      />
      <RecordResultMessage
        record={webhook.recordEdit}
        onDismiss={() => dispatch(clearWebhookErrors('record'))}
      />
      <div className="general-conatiner">
        <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
          <div className="detail-container">
            <Card.Content>
              <Form>
                <Form.Field width={15}>
                  {session.isOrganizationAdmin && (
                    <ValidatedInput
                      value={orgName}
                      label={I18n.t(`${inputLabelPath}.organization`)}
                      disabled
                      type="text"
                    />
                  )}
                  {session.isSystemAdmin && (
                    <TemplateAutocomplete
                      entity="organization"
                      searchString={orgName}
                      label={`${inputLabelPath}.organization`}
                      onChange={(_e: any, _v: any, selected: any) =>
                        setOrganization(selected)
                      }
                      request={getOrganizationRequest}
                      getItem={getOrganizationItem}
                      validation={{ required: true }}
                    />
                  )}
                  <Form.Field width={15}>
                    <ValidatedInput
                      value={endPoint}
                      placeholder={I18n.t(`${inputLabelPath}.endpoint`)}
                      label={I18n.t(`${inputLabelPath}.endpoint`)}
                      onChange={(_event: any, { value }: any) =>
                        setEndPoint(value)
                      }
                      type="text"
                      validation={{ required: true, url: true }}
                    />
                  </Form.Field>
                  <TemplateDropdown
                    id="webhook-entity"
                    label={`${inputLabelPath}.entity`}
                    onChange={(_: unknown, value: string) => setEntity(value)}
                    options={notificationEntityOptions}
                    value={entityOption.value}
                    clearable
                  />
                  <TemplateDropdown
                    id="webhook-type"
                    label={`${inputLabelPath}.type`}
                    onChange={(_: unknown, value: string) => setType(value)}
                    options={notificationEventTypeOptions}
                    value={typeOption.value}
                    clearable
                  />
                </Form.Field>
              </Form>
              <div className="bottom-space"></div>
            </Card.Content>
          </div>
        </Card>
        <Button
          primary
          style={{ minWidth: '100px' }}
          floated="right"
          disabled={isButtonDisabled}
          loading={isLoading}
          onClick={onSaveClick}
        >
          <Translate value={'account.update-password.save'} />
        </Button>
        <Button floated="right" onClick={onBackClick}>
          <Translate value={'account.update-password.cancel'} />
        </Button>
      </div>
      <SavedEntityModal
        open={showModal}
        content={<ModalContent path={typeLabelPath} endPoint={endPoint} />}
        buttons={
          <ModalButtons path={typeLabelPath} onBackClick={onBackClick} />
        }
      />
    </div>
  );
};

export default withRouter(WebhookInput);
