import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as programActions from '../../../pages/vipProgram/store/vipProgramActions';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';

import { getSetupTab } from './templateTabs/setup';
import { getApplicationQuestionsTab } from './templateTabs/applicationQuestions';
import { getEmailsTab } from './templateTabs/emails';
import { getTermsAndConditionsTab } from './templateTabs/termsAndConditions';

import { TARGET_VALIDATION } from '../../../constants/validationModels';
import { paths } from './constants/template';
import config from '../../../constants';

class VipProgramTemplateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddressHidden: false,
      isPhoneHidden: true,
      isCountryAllowed: true,
    };

    this.required = {
      required: true
    };

    this.programNameValidation = {
      required: true,
      maxLength: true,
      length: config.VIP_PRORGAM_PROGRAM_NAME_MAX_LENGTH
    };

    this.participantNameValidation = {
      maxLength: true,
      length: config.VIP_PROGRAM_PARTICIPANT_NAME_MAX_LENGTH
    };

    this.requestValidation = {
      optionalRecord: true
    };

    this.dateValidation = {
      required: true,
      date: true
    };

    this.formBuilderQuestionsValidation = {
      formbuilderQuestionsRequired: true,
      formBuilderQuestions: true
    };

    this.optional = {
      required: false
    };

    this.target = {
      required: false,
      ...TARGET_VALIDATION
    };

    this.urlValidation = {
      url: true
    };

    this.waiverBuilderValidation = {
      waiverBuilder: true
    };
  }

  onChange = (path, value, _) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  getRecordRequest = (value) => {
    this.props.programActions.getEventAmbassadorProgramRecord(value);
  };

  clearRecord = (record) => {
    this.props.programActions.clearRecord(record);
  };

  getEventRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'status',
          operator: 'EqualTo',
          value: 1
        }
      ],
      includeDataTemplate: true
    };
  };

  onEventChange = (path, value, _) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  getEventItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  onAllowTermsAndConditionsChange = (path, value) => {
    this.props.templateBuilderActions.changeItemVisibility(
      paths.TERMS_AND_CONDITIONS_TITLE,
      value,
      true
    );
    this.props.templateBuilderActions.changeItemVisibility(
      paths.TERMS_AND_CONDITIONS_LINK,
      value,
      true
    );

    this.onChange(path, value);
  };

  onAllowWaiversChange = (path, value) => {
    this.props.templateBuilderActions.changeItemVisibility(
      paths.WAIVERS,
      value,
      true
    );

    this.onChange(path, value);
  };

  render() {
    const setupTab = getSetupTab({
      onChange: this.onChange,
      required: this.required,
      programNameValidation: this.programNameValidation,
      participantNameValidation: this.participantNameValidation,
      getRecordRequest: this.getRecordRequest,
      clearRecord: this.clearRecord,
      getEventRequest: this.getEventRequest,
      onEventChange: this.onEventChange,
      getEventItem: this.getEventItem,
      dateValidation: this.dateValidation,
      optional: this.optional,
      target: this.target,
      requestValidation: this.requestValidation
    });
    const applicationQuestionsTab = getApplicationQuestionsTab({
      onChange: this.onChange,
      required: this.required,
      formBuilderQuestionsValidation: this.formBuilderQuestionsValidation,
      isAddressHidden: this.state.isAddressHidden,
      isPhoneHidden: this.state.isPhoneHidden,
      isCountryAllowed: this.state.isCountryAllowed
    });
    const emailsTab = getEmailsTab({
      onChange: this.onChange,
      optional: this.optional
    });
    const termsAndConditionsTab = getTermsAndConditionsTab({
      onChange: this.onChange,
      required: this.required,
      urlValidation: this.urlValidation,
      waiverBuilderValidation: this.waiverBuilderValidation,
      onAllowTermsAndConditionsChange: this.onAllowTermsAndConditionsChange,
      onAllowWaiversChange: this.onAllowWaiversChange
    });

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        showCancelButton
        onCancel={this.props.onCancel}
        isLoading={this.props.isLoading}
      >
        {setupTab}
        {applicationQuestionsTab}
        {emailsTab}
        {termsAndConditionsTab}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapDispatch = (dispatch) => {
  return {
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    programActions: bindActionCreators(programActions, dispatch)
  };
};

const VipProgramTemplate = withRouter(
  connect(null, mapDispatch)(VipProgramTemplateView)
);
export default VipProgramTemplate;
