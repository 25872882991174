import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as templateHelper from './templateHelper';
import { treeActions } from '../../modules/tree';
import { Form, Dropdown, List, Loader } from 'semantic-ui-react';
import { Record } from '../../modules/bin/utility';
import get from 'lodash.get';
import { isArrayWithItems } from '../../helpers/arrayHelper';

class ManagedDropdownTree extends Component {
  constructor(props) {
    super(props);

    if (!this.props.record) {
      this.props.initRecord(this.props.path);
    }

    if (this.props.value && !this.props.selected) {
      this.props.setSelectedItem(this.props.value, this.props.path);
    }
  }

  componentDidUpdate() {
    if (Record.notStarted(this.props.record)) {
      this.props.getData(this.props.path);
    }
    if (
      !Record.isRecordLoading(this.props.record) &&
      this.props.value !== get(this.props, 'selected.id')
    ) {
      this.props.setSelectedItem(this.props.value, this.props.path);
    }
  }

  onTreeItemClick = (item) => {
    this.props.onChange(item, this.props.path);
    this.props.setSelectedItem(item.id, this.props.path, item);
  };

  getTreeItems = (data) => {
    if (isArrayWithItems(data)) {
      return data.map((item) => {
        if (this.props.source === item.id) {
          return <React.Fragment />;
        }
        return (
          <List.Item key={item.id}>
            <List.Icon size="mini" />
            <List.Content className="tree-item">
              <List.Header onClick={this.onTreeItemClick.bind(this, item)}>
                {item.name}
              </List.Header>
              {item.childs && item.childs.length !== 0 && (
                <List.List>{this.getTreeItems(item.childs)}</List.List>
              )}
            </List.Content>
          </List.Item>
        );
      });
    }
  };

  handleClear = () => {
    if (this.props.value) {
      this.props.onChange(null, this.props.path);
      this.props.clear(this.props.path);
    }
  };

  render() {
    const isLoading =
      this.props.record && Record.isRecordLoading(this.props.record);
    const name = get(this.props, 'selected.name');

    return (
      <Form.Field
        className="tree-select"
        id={this.props.id}
        required={this.props.validation && this.props.validation.required}
      >
        {this.props.label && (
          <label style={{ float: 'left' }}>{this.props.label}</label>
        )}
        <p
          className="Links"
          style={{ float: 'right', cursor: 'pointer' }}
          onClick={this.handleClear}
        >
          Clear
        </p>
        <Dropdown loading={isLoading} selection text={name}>
          <Dropdown.Menu>
            {isLoading && <Loader active size="mini" />}
            {!isLoading && <List>{this.getTreeItems(this.props.data)}</List>}
          </Dropdown.Menu>
        </Dropdown>
        {this.props.subtext && (
          <div className="input-sub">{this.props.subtext}</div>
        )}
      </Form.Field>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = ownProps.value
    ? ownProps.value
    : templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value,
    source: templateHelper.getValue(state.templateBuilderEx, ownProps.source),
    selected: get(state.tree, `${ownProps.path}.selected`),
    record: get(state.tree, `${ownProps.path}.record`),
    data: get(state.tree, `${ownProps.path}.record.data`),
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext)
  };
};

const mapDispatch = (dispatch) => {
  return {
    setSelectedItem(id, path, item) {
      dispatch(treeActions.setSelectedItem(id, path, item));
    },
    clear(path) {
      dispatch(treeActions.clear(path));
    },
    initRecord(path) {
      dispatch(treeActions.initRecord(path));
    }
  };
};

export const TemplateDropdownTree = connect(
  mapState,
  mapDispatch
)(ManagedDropdownTree);
