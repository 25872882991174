import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';

import AddressAutocomplete from '../formBuilderAnswersComponents/addressComponents/addressAutocomplete';

import * as templateHelper from './templateHelper';

class ManagedAddressAutocomplete extends Component {
  render() {
    const {
      value,
      onChange,
      removePlaceholder,
      disableOnBlur,
      allowFilteringByCities
    } = this.props;

    const label = templateHelper.getTranslation(this.props.label);
    const subtext = templateHelper.getTranslation(this.props.subtext);

    return (
      <Form.Field>
        {label && <label>{label}</label>}
        <div>
          <AddressAutocomplete
            onChange={onChange}
            removePlaceholder={removePlaceholder}
            value={value}
            disableOnBlur={disableOnBlur}
            allowFilteringByCities={allowFilteringByCities}
          />
        </div>
        {subtext && <div className="input-sub input-sub-bottom">{subtext}</div>}
      </Form.Field>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export const TemplateAddressAutocomplete = connect(mapState)(
  ManagedAddressAutocomplete
);
