import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';
import moment from 'moment';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as programActions from '../store/vipProgramActions';
import { formBuilderActions } from '../../../components/formbuilder/store';

import update from '../../../helpers/update';
import { Record } from '../../../modules/bin/utility';

import VipProgramTemplate from '../create/templateEx';
import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import clear from '../../../components/clear';
import Spinner from '../../../components/common/spinner';

import {
  customFieldCategory,
  generalFormBuilderPath
} from '../../../constants/formBuilder';
import { TemplateOptions } from '../../../components/template/templateOptions';
import { paths } from '../create/constants/template';
import config from '../../../constants';

class EditVipProgram extends Component {
  constructor(props) {
    super(props);
    const eventId = get(props, 'match.params.eventId');

    props.programActions.getEventAmbassadorProgramByEventRecord(eventId);
  }

  onSave = (data) => {
    this.props.programActions.updateRecord(data);
  };

  onCancel = (data) => {
    this.props.templateBuilderActions.clearItems(data);
    this.goToVipProgramsView();
  };

  isAllRecordsReady = () => {
    return Record.isRecordReady(this.props.eventAmbassadorProgramRecord);
  };

  initTemplate = () => {
    let eventAmbassadorProgram = cloneDeep(
      get(this.props, 'eventAmbassadorProgramRecord.data.data')
    );

    const applicationsPartOneFormBuilder = get(
      eventAmbassadorProgram,
      `${generalFormBuilderPath}.${customFieldCategory.ENTRY}`
    );
    const applicationsPartTwoFormBuilder = get(
      eventAmbassadorProgram,
      `${generalFormBuilderPath}.${customFieldCategory.CONFIRMATION}`
    );
    const templateOptions = new TemplateOptions();

    templateOptions.hide(paths.EVENT);

    //date
    const applicationsOpenDate = get(
      eventAmbassadorProgram,
      paths.DATE_APPLICATIONS_OPEN
    );
    if (applicationsOpenDate && moment(applicationsOpenDate).isValid()) {
      eventAmbassadorProgram = update.set(
        eventAmbassadorProgram,
        paths.DATE_APPLICATIONS_OPEN,
        moment(applicationsOpenDate).format(config.DEFAULT_DATE_FORMAT)
      );
    }

    const applicationsCloseDate = get(
      eventAmbassadorProgram,
      paths.DATE_APPLICATIONS_CLOSE
    );
    if (applicationsOpenDate && moment(applicationsOpenDate).isValid()) {
      eventAmbassadorProgram = update.set(
        eventAmbassadorProgram,
        paths.DATE_APPLICATIONS_CLOSE,
        moment(applicationsCloseDate).format(config.DEFAULT_DATE_FORMAT)
      );
    }

    const claimsCloseDate = get(
      eventAmbassadorProgram,
      paths.DATE_CLAIMS_CLOSE
    );
    if (claimsCloseDate && moment(claimsCloseDate).isValid()) {
      eventAmbassadorProgram = update.set(
        eventAmbassadorProgram,
        paths.DATE_CLAIMS_CLOSE,
        moment(claimsCloseDate).format(config.DEFAULT_DATE_FORMAT)
      );
    }

    //terms and conditions && waivers
    const termsAndConditionsEnabled = get(
      eventAmbassadorProgram,
      paths.TERMS_AND_CONDITIONS_ENABLED
    );
    const waiversEnabled = get(eventAmbassadorProgram, paths.WAIVERS_ENABLED);

    if (!termsAndConditionsEnabled) {
      templateOptions.hide(paths.TERMS_AND_CONDITIONS_TITLE);
      templateOptions.hide(paths.TERMS_AND_CONDITIONS_LINK);
    }

    if (!waiversEnabled) {
      templateOptions.hide(paths.WAIVERS);
    }

    templateOptions.setModel(eventAmbassadorProgram);

    templateOptions.isEdit();

    this.props.formBuilderActions.setFormBuilderModel(
      applicationsPartOneFormBuilder
    );
    this.props.formBuilderActions.setFormBuilderModel(
      applicationsPartTwoFormBuilder
    );
    this.props.templateBuilderActions.setTemplateModel(
      templateOptions.getWithRule()
    );
  };

  clearMessages = () => {
    this.props.programActions.clearRecordMessages('recordEdit');
  };

  goToVipProgramsView = () => {
    this.props.history.push(config.DEFAULT_PROGRAMS_URL);
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const eventAmbassadorProgram = get(
      this.props.eventAmbassadorProgramRecord,
      'data.data'
    );
    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }

    const isSaving = Record.isRecordLoading(this.props.recordEdit);

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToVipProgramsView}
        />

        <h1>
          <Translate value="programs.template.edit-header" />{' '}
          {eventAmbassadorProgram ? eventAmbassadorProgram.name : ''}
        </h1>
        <VipProgramTemplate
          onSave={this.onSave}
          onCancel={this.onCancel}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

const mapState = ({ program, templateBuilderEx }) => {
  return {
    eventAmbassadorProgramRecord: program.eventAmbassadorProgramRecord,
    recordEdit: program.recordEdit,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

const EditVipProgramContainer = clear(
  connect(mapState, mapDispatch)(EditVipProgram)
);
export default EditVipProgramContainer;
