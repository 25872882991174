import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import get from 'lodash.get';
import { cloneDeep } from 'lodash';

import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import * as programActions from '../../store/vipProgramActions';

import { Record } from '../../../../modules/bin/utility';
import BasePage from '../../../../components/common/basePageView';
import clear from '../../../../components/clear';
import { TemplateOptions } from '../../../../components/template/templateOptions';
import ProgramTemplate from '../templateEx';
import Spinner from '../../../../components/common/spinner';
import RecordResultMessage from '../../../../components/common/recordResult';

import { paths } from '../constants/template';
import config from '../../../../constants';

class CreateProgram extends Component {
  constructor(props) {
    super(props);
    const eventId = get(props, 'match.params.eventId');

    props.programActions.getEventAmbassadorProgramByEventRecord(eventId);
  }

  onSave = (data) => {
    this.props.programActions.createProgramRecord(data);
  };

  onCancel = (data) => {
    const eventId = get(this.props, 'match.params.eventId');

    this.props.templateBuilderActions.clearItems(data);

    if (this.props.isCampaignAdmin || this.props.isOrganizationAdmin) {
      this.props.history.push(config.DEFAULT_PROGRAMS_URL);
    }

    this.props.history.push(`/vip-program/event/${eventId}/programs`);
  };

  initTemplate = () => {
    let eventAmbassadorProgram = cloneDeep(
      get(this.props, 'eventAmbassadorProgramRecord.data.data')
    );
    const templateOptions = new TemplateOptions();

    templateOptions.hide(paths.BANNER);
    templateOptions.hide(paths.INTRO_TEXT);
    templateOptions.hide(paths.APPLICATION_ADDITIONAL);
    templateOptions.hide(paths.EDIT_PROGRAM_HEADER);
    templateOptions.hide(paths.EDIT_PROGRAM_SUBHEADER);
    templateOptions.hide(paths.EDIT_PROGRAM_APPLICATION_QUESTIONS_TAB);

    if (eventAmbassadorProgram) {
      templateOptions.setValue(
        paths.EVENT_AMBASSADOR_PROGRAM_ID,
        eventAmbassadorProgram.id
      );
      templateOptions.setValue(paths.EVENT, eventAmbassadorProgram);
    }

    this.props.templateBuilderActions.setTemplateModel(templateOptions.get());
  };

  isAllRecordsReady = () => {
    return Record.isRecordReady(this.props.eventAmbassadorProgramRecord);
  };

  clearMessages = () => {
    this.props.programActions.clearRecordMessages('createProgramRecord');
  };

  goToVipProgramsView = () => {
    const record = get(this.props, 'createProgramRecord.data');

    if (this.props.isCampaignAdmin || this.props.isOrganizationAdmin) {
      this.props.history.push(config.DEFAULT_PROGRAMS_URL);
    }

    this.props.history.push(`/vip-program/event/${record.eventId}/programs`);
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const isSaving = Record.isRecordLoading(this.props.createProgramRecord);

    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.createProgramRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToVipProgramsView}
        />

        <h1>
          <Translate value="eventPrograms.template.create-header" />
        </h1>
        <ProgramTemplate
          onSave={this.onSave}
          onCancel={this.onCancel}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

const mapState = ({ program, session, templateBuilderEx }) => {
  return {
    eventAmbassadorProgramRecord: program.eventAmbassadorProgramRecord,
    createProgramRecord: program.createProgramRecord,

    isTemplateInited: templateBuilderEx.inited,
    event: templateBuilderEx.data.event,

    isCampaignAdmin: session.isCampaignAdmin,
    isOrganizationAdmin: session.isOrganizationAdmin
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(templateBuilderActions, dispatch)
  };
};

const CreateProgramContainer = clear(
  connect(mapState, mapDispatch)(CreateProgram)
);
export default CreateProgramContainer;
