import { get } from 'lodash';
import { templateKeys } from '../../../constants/templateKeys';
import { getDataCapture } from '../../../helpers/dataCaptureHelper';
import { getFullTimeObject } from '../../../helpers/templateHelper';
import { campaignContract } from '../../campaign/create/templates';
import update from '../../../helpers/update';
import { eventOccurrenceType, eventStatuses, tiersEnum } from '../constants';
import { BULK_UPLOAD_DIALOG_TOGGLE, BULK_UPLOAD_STATUS_CHANGE, TOGGLE_COLUMNS_CHANGE, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, GET_ACTIVE_INTEGRATION_REQUESTED, GET_ACTIVE_INTEGRATION_SUCCESS, GET_ACTIVE_INTEGRATION_FAILURE, UPDATE_ACTIVE_INTEGRATION_REQUESTED, UPDATE_ACTIVE_INTEGRATION_SUCCESS, UPDATE_ACTIVE_INTEGRATION_FAILURE, GRID_TAB_CHANGE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAILURE, GET_REQUESTED, GET_SUCCESS, GET_FAILURE, UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAILURE, ERROR_CLEAR, ON_PRIORITY_CHANGE, UPDATE_EVENT_STATUS_REQUESTED, UPDATE_EVENT_STATUS_SUCCESS, UPDATE_EVENT_STATUS_FAILURE, CLONE_REQUESTED, CLONE_SUCCESS, CLONE_FAILURE } from './eventTypes';
import * as entityHandlers from '../../../modules/bin/entityHandlers';

export const toggleBulkUploadDialog = () => ({
  type: BULK_UPLOAD_DIALOG_TOGGLE,
});

export const changeBulkUploadStatus = (newStatus: string, error?: boolean) => ({
  type: BULK_UPLOAD_STATUS_CHANGE,
  payload: {
    value: {
      message: newStatus,
      error,
    },
  },
});

export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );

export const getMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'page/event',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined
) =>
  entityHandlers.getListData(
    'page/event',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );

export const getListDataBySearchTerm = (listKey: any, id: any, request: any, searchTerm: any) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getListData(listKey, id, newRequest, true);
};

export const getEventActiveIntegration = (eventId: any) =>
  entityHandlers.getRecord(
    'EventActiveIntegration',
    GET_ACTIVE_INTEGRATION_REQUESTED,
    GET_ACTIVE_INTEGRATION_SUCCESS,
    GET_ACTIVE_INTEGRATION_FAILURE,
    eventId,
    'activeIntegration'
  );

export const updateEventActiveIntegration = (eventId: any, data: any) =>
  entityHandlers.updateRecord(
    'EventActiveIntegration',
    UPDATE_ACTIVE_INTEGRATION_REQUESTED,
    UPDATE_ACTIVE_INTEGRATION_SUCCESS,
    UPDATE_ACTIVE_INTEGRATION_FAILURE,
    eventId,
    data,
    null,
    'activeIntegration'
  );

  export const onGridTabChange = (gridTab: any) => {
    return {
      type: GRID_TAB_CHANGE,
      payload: gridTab
    };
  };
  

  
  export const getOrganizationMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
      'page/campaign',
      listKey,
      METADATA_REQUESTED,
      METADATA_SUCCESS,
      METADATA_FAILURE
    );
  export const getOrganizationListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
  ) =>
    entityHandlers.getListData(
      'page/campaign',
      listKey,
      id,
      LIST_DATA_REQUESTED,
      LIST_DATA_SUCCESS,
      LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData
    );
  export const getOrganizationListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getOrganizationListData(listKey, id, newRequest, true);
  };
  

  
  export const getDonationMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
      'donation',
      listKey,
      METADATA_REQUESTED,
      METADATA_SUCCESS,
      METADATA_FAILURE
    );
  export const getDonationListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
  ) =>
    entityHandlers.getListData(
      'donation',
      listKey,
      id,
      LIST_DATA_REQUESTED,
      LIST_DATA_SUCCESS,
      LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData
    );
  export const getDonationListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getDonationListData(listKey, id, newRequest, true);
  };
  

  
  export const getFundraiserMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
      'page',
      listKey,
      METADATA_REQUESTED,
      METADATA_SUCCESS,
      METADATA_FAILURE
    );
  export const getFundraiserListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
  ) =>
    entityHandlers.getListData(
      'page',
      listKey,
      id,
      LIST_DATA_REQUESTED,
      LIST_DATA_SUCCESS,
      LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData,
      'v3'
    );
  export const getFundraiserListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getFundraiserListData(listKey, id, newRequest, true);
  };
  

  
  export const getOrganizationTierMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
      'organization',
      listKey,
      METADATA_REQUESTED,
      METADATA_SUCCESS,
      METADATA_FAILURE
    );
  export const getOrganizationTierListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined,
    version = 'v3'
  ) =>
    entityHandlers.getListData(
      'organization',
      listKey,
      id,
      LIST_DATA_REQUESTED,
      LIST_DATA_SUCCESS,
      LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData,
      version
    );
  export const getOrganizationTierListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getOrganizationTierListData(listKey, id, newRequest, true);
  };
  

  
  // export const getExportFile = (entity: any, request: any, fileName: string | undefined, version: string | undefined) => {
  //   return entityHandlers.getExportFile(
  //     entity,
  //     GET_EXPORT_FILE_REQUESTED,
  //     GET_EXPORT_FILE_SUCCESS,
  //     GET_EXPORT_FILE_FAILURE,
  //     request,
  //     'exportFileRecord',
  //     fileName,
  //     version
  //   );
  // };
  
  export const createRecord = (record: any) =>
    entityHandlers.createRecord(
      'page/event',
      CREATE_REQUESTED,
      CREATE_SUCCESS,
      CREATE_FAILURE,
      record,
      (state: any, data: any) => {
        const dataCapture = getDataCapture(data);
        const time = getFullTimeObject(data, true);
  
        data = update.set(
          data,
          'timeAndPlace.time.start',
          time ? (time.start === 'Invalid date' ? null : time.start) : null
        );
        data = update.set(
          data,
          'timeAndPlace.time.end',
          time ? (time.end === 'Invalid date' ? null : time.end) : null
        );
        data = update.set(
          data,
          'timeAndPlace.time.includesTime',
          time ? time.includesTime : null
        );
  
        if (!time) {
          data = update.set(data, 'timeAndPlace.time.year', null);
          data = update.set(data, 'timeAndPlace.time.text', null);
          data = update.set(data, 'timeAndPlace.place.country', '');
          data = update.set(data, 'timeAndPlace.place.city', '');
          data = update.set(data, 'timeAndPlace.place.state', '');
          data = update.set(data, 'timeAndPlace.place.text', null);
        }
  
        data = update.set(data, 'template.key', templateKeys.EVENT);
        data = update.set(data, 'dataCapture', dataCapture);
  
        if (time && time.includesTime) {
          data = update.set(
            data,
            'timeAndPlace.time.occurrenceType',
            eventOccurrenceType.DATE_WITH_TIME
          );
        } else {
          data = update.set(
            data,
            'timeAndPlace.time.occurrenceType',
            eventOccurrenceType.DATE
          );
        }
  
        data = update.unset(data, campaignContract.allowTimeAndPlace);
  
        const tiers = get(data, 'template.value.tiers') || {};
  
        const templateTiers = getEventTiersModel(tiers);
        data = update.set(data, 'template.value.tiers', templateTiers);
  
        const defaultTierAmounts = getDefaultTierAmounts(tiers);
        data = update.set(data, 'defaultTierAmounts', defaultTierAmounts);

        // copy tiers to tiersData array
        const tierData = Object.values(tiers)?.filter((x: any) => x?.enabled)?.map(item => update(item, { $unset: ['enabled'] }));
        data = update.set(data, 'template.value.tierData', tierData);
  
        //TODO: remove after adding component on UI
        data = update.set(data, 'microblogs.enabled', true);
  
        return update(data, {
          status: { $set: eventStatuses.LIVE.description.toLowerCase() }
        });
      },
      'recordCreate'
    );
  
  export const getRecord = (id: any) =>
    entityHandlers.getRecord(
      'page/event',
      GET_REQUESTED,
      GET_SUCCESS,
      GET_FAILURE,
      id
    );
  
  export const updateRecord = (record: { id: any; }) =>
    entityHandlers.updateRecord(
      'page/event',
      UPDATE_REQUESTED,
      UPDATE_SUCCESS,
      UPDATE_FAILURE,
      record.id,
      record,
      (state: any, data: any) => {
        const dataCapture = getDataCapture(data);
        const time = getFullTimeObject(data, true);
  
        data = update.set(
          data,
          'timeAndPlace.time.start',
          time ? (time.start === 'Invalid date' ? null : time.start) : null
        );
        data = update.set(
          data,
          'timeAndPlace.time.end',
          time ? (time.end === 'Invalid date' ? null : time.end) : null
        );
        data = update.set(
          data,
          'timeAndPlace.time.includesTime',
          time ? time.includesTime : null
        );
  
        if (!time) {
          data = update.set(data, 'timeAndPlace.time.year', null);
          data = update.set(data, 'timeAndPlace.time.text', null);
          data = update.set(data, 'timeAndPlace.place.country', '');
          data = update.set(data, 'timeAndPlace.place.city', '');
          data = update.set(data, 'timeAndPlace.place.state', '');
          data = update.set(data, 'timeAndPlace.place.text', null);
        }
  
        data = update.set(data, 'template.key', templateKeys.EVENT);
        data = update.set(data, 'dataCapture', dataCapture);
  
        if (time && time.includesTime) {
          data = update.set(
            data,
            'timeAndPlace.time.occurrenceType',
            eventOccurrenceType.DATE_WITH_TIME
          );
        } else {
          data = update.set(
            data,
            'timeAndPlace.time.occurrenceType',
            eventOccurrenceType.DATE
          );
        }
  
        data = update.unset(data, campaignContract.allowTimeAndPlace);
  
        const tiers = get(data, 'template.value.tiers') || {};
  
        const templateTiers = getEventTiersModel(tiers);
        data = update.set(data, 'template.value.tiers', templateTiers);
  
        const defaultTierAmounts = getDefaultTierAmounts(tiers);
        data = update.set(data, 'defaultTierAmounts', defaultTierAmounts);

        // copy tiers to tiersData array
        const tierData = Object.values(tiers)?.filter((x: any) => x?.enabled)?.map(item => update(item, { $unset: ['enabled'] }));
        data = update.set(data, 'template.value.tierData', tierData);
        
        return data;
      },
      'recordEdit'
    );
  
  export const clearRecordMessages = (recordKey: any) => ({
    type: ERROR_CLEAR,
    payload: { key: recordKey }
  });
  
  export const onPriorityChange = (value: any, id: any) => {
    return {
      type: ON_PRIORITY_CHANGE,
      payload: {
        value: value,
        id: id
      }
    };
  };
  
  export const updateEventStatus = (id: any, editableData: any) => {
    return entityHandlers.patchRecord(
      'page/event',
      UPDATE_EVENT_STATUS_REQUESTED,
      UPDATE_EVENT_STATUS_SUCCESS,
      UPDATE_EVENT_STATUS_FAILURE,
      id,
      editableData,
      'statusRecord'
    );
  };
  
  const getEventTiersModel = (tiers: { [x: string]: any; }) => {
    if (tiers) {
      const templateTiers = {} as any;
      Object.keys(tiers).forEach((tierKey) => {
        const tier = tiers[tierKey];
        if (tier) {
        // @ts-ignore
          const key = tiersEnum[tierKey];
          templateTiers[tierKey] = {
            ...tier,
            key: key
          };
        }
      });
      return templateTiers;
    }
  };
  
  const getDefaultTierAmounts = (tiers: {}) => {
    if (tiers) {
      const tiersTargets = {} as any;
      Object.keys(tiers).forEach((tierKey) => {
        const target = get(tiers, `${tierKey}.target`);
  
        if (target) {
        // @ts-ignore
          const tierName = tiersEnum[tierKey];
          tiersTargets[tierName] = target;
        }
      });
  
      return tiersTargets;
    }
  };
  
  export const clearRecordCloneMessages = () => ({
    type: ERROR_CLEAR,
    payload: { key: 'recordClone' }
  });
  
  export const cloneRecord = (id: any, data: any) =>
    entityHandlers.createRecord(
      `page/event/${id}/copy`,
      CLONE_REQUESTED,
      CLONE_SUCCESS,
      CLONE_FAILURE,
      data,
      (_state: any, data: any) => {
        const time = getFullTimeObject(data, true);
        const allowTimeAndPlace = get(data, campaignContract.allowTimeAndPlace);
        if (allowTimeAndPlace !== undefined) {
          data = update.set(data, 'timeAndPlace.time', time ? time : null);
          // uses the same funtionality as edit/create event i.e. explicitly check that time === 'Invalid date'
          data = update.set(
            data,
            'timeAndPlace.time.start',
            time ? (time.start === 'Invalid date' ? null : time.start) : null
          );
          data = update.set(
            data,
            'timeAndPlace.time.end',
            time ? (time.end === 'Invalid date' ? null : time.end) : null
          );
          data = update.set(
            data,
            'timeAndPlace.time.includesTime',
            time ? time.includesTime : null
          );
        }
  
        data = update.unset(data, 'occurrence');
        data = update.unset(data, 'timeAndPlace.date');
        data = update.unset(data, 'template.timeAndPlace');
        return entityHandlers.getPatchModel(data);
      },
      'recordClone'
    );

  
