import React from 'react';

interface TemplateListProps<Value extends any = any> {
  path: string;
  values: Array<Value>;
  onChange: (path: string, value: Value) => void;
  children: (props: TemplateListRenderProps) => React.ReactNode;
}

interface TemplateListRenderProps<Value extends any = any> {
  index: number;
  value?: Value;
  onChange: (value: Value) => void;
}

export const TemplateList: React.FunctionComponent<TemplateListProps> = ({
  path,
  values = [],
  onChange,
  children
}) => {
  const handleOnChange = React.useCallback(
    (index: number, value: any) => {
      const items = Array.isArray(values) ? values : [];
      items[index] = value;
      onChange(path, items);
    },
    [onChange, values]
  );

  return (
    <>
      {values?.map?.((value, index) => (
        <React.Fragment key={index}>
          {children({
            index,
            value,
            onChange: (value) => handleOnChange(index, value)
          })}
        </React.Fragment>
      ))}
    </>
  );
};
