import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../';
import * as templateHelper from './templateHelper';

import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import * as handlers from '../../modules/autocomplete';
import OptionalLabel from '../common/optionalLabel';

class ManagedSearch extends Component {
  onValueChange(event, obj) {
    if (this.props.onChange) {
      this.props.onChange(
        this.props.path,
        obj.result.title?.trim(),
        obj.result
      );
    }
  }

  search = (event, searchTerm) => {
    this.props.onChange(this.props.path, searchTerm.value);

    let request = this.props.request();
    request.text = searchTerm.value;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.searchEntities(
        this.props.searchEntity,
        this.props.path,
        request
      );
    }, 500);
  };

  render() {
    const results = getOptions(this.props.data);
    const label =
      this.props.validation && this.props.validation.required === false ? (
        <OptionalLabel>{this.props.label}</OptionalLabel>
      ) : (
        this.props.label
      );
    return (
      <Form.Search
        label={label}
        inputLabel={this.props.inputLabel}
        subtext={this.props.subtext}
        value={this.props.value || ''}
        onChange={this.onValueChange.bind(this)}
        required={this.props.required}
        search={this.search.bind(this)}
        results={results}
        loading={this.props.loading}
        validation={this.props.validation}
      />
    );
  }
}

const getOptions = (data) => {
  let results = [];
  if (data) {
    results = data.list.map((x) => {
      return {
        title: x.email,
        description: `${x.firstName} ${x.lastName}`,
        item: x
      };
    });
  }

  return results;
};

const mapState = (state, ownProps) => {
  // Get the label and subtext from data store.
  const label = templateHelper.getTranslation(ownProps.label);
  const subtext = templateHelper.getTranslation(ownProps.subtext);
  const inputLabel = templateHelper.getTranslation(ownProps.inputLabel);

  const path = ownProps.path;
  const searchEntity = ownProps.entity;

  const loading = get(state, `autocomplete.${path}.loading`);
  const data = get(state, `autocomplete.${path}.data`);
  const value = templateHelper.getValue(state.templateBuilderEx, path);

  return {
    label,
    subtext,
    inputLabel,
    data,
    loading,
    path,
    searchEntity,
    value
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(handlers, dispatch);
};

export const TemplateSearch = connect(mapState, mapDispatch)(ManagedSearch);
