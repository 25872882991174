import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as donationActions from './store/donationActions';
import { bindActionCreators } from 'redux';
import { Button, Modal } from 'semantic-ui-react';
import { Record } from '../../modules/bin/utility';

class ReceiptWindow extends Component {
  onReissueReceiptConfirmClose = () => {
    this.props.hideReceiptConfirmation();
  };

  onReissueContextClick = (id, item) => {
    this.props.showReceiptConfirmation(item);
  };

  onReissueReceiptClick = () => {
    this.props.reissueReceipt(this.props.receiptDonation.id);
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  render() {
    const isIssuing = Record.isRecordLoading(this.props.saveRecord);

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={this.props.reissueReceiptConfirmation}
      >
        <Modal.Header>{I18n.t('donation.reissue-receipt.title')}</Modal.Header>

        <Modal.Content>
          {I18n.t('donation.reissue-receipt.question')}
          <div
            className="button-wrapper align-right"
            style={{ marginTop: '10px' }}
          >
            <Button
              primary
              onClick={this.onReissueReceiptClick}
              style={{ marginRight: '10px' }}
              loading={isIssuing}
              disabled={isIssuing}
            >
              Yes
            </Button>
            <Button onClick={this.onReissueReceiptConfirmClose}>No</Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ donation, session, account }) => {
  return {
    saveRecord: donation.saveRecord,
    reissueReceiptConfirmation: donation.reissueReceiptConfirmation,
    receiptDonation: donation.receiptDonation
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(donationActions, dispatch);
};

const ReceiptWindowContainer = connect(mapState, mapDispatch)(ReceiptWindow);
export default ReceiptWindowContainer;
