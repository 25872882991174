import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Record } from '../../../modules/bin/utility';
import { Button, Modal } from 'semantic-ui-react';

import * as ticketingActions from '../store/ticketingActions';
import * as generalActions from '../../../modules/general';
import { grecaptcha } from '../../../helpers/grecaptcha';

class RefundTicketWindow extends Component {
  onRefundClick = () => {
    if (this.props.path === 'ticket') {
      const tokenCallback = (token) =>
        this.props.refundTicket(this.props.refundRecord.id, token);
      grecaptcha(tokenCallback, "SaleUpdate");
    }

    if (this.props.path === 'merchandise')
      this.props.refundMerchandise(this.props.refundRecord.id);
  };

  onRefundConfirmClose = () => {
    this.props.closeRefundConfirmation(this.props.path);
  };

  render() {
    const email = this.props.refundRecord?.customer?.email;
    const amount = this.props.refundRecord?.price;
    const name = this.props.refundRecord?.[this.props.path === 'merchandise' ? 'merchandiseSku' : 'ticket']?.name;
    const alreadyRefunded = this.props.refundRecord?.financials?.returned;
    const isRefunding = Record.isRecordLoading(this.props.saveRecord);
    const isOpen = this.props.refundConfirmation;

    const isRefunded = this.props.isRefunded;
    if (isRefunded) {
      this.props.generalActions.clearListsReadyState();
      this.props.clearIsRefunded(this.props.path);
    }

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={isOpen}
      >
        <Modal.Header>
          {I18n.t(`${this.props.path}.modals.refund-ticket.title`)}
        </Modal.Header>
        {!alreadyRefunded && (
          <Modal.Content>
            {I18n.t(`${this.props.path}.modals.refund-ticket.content`, {
              amount: amount,
              email: email,
              name: name
            })}
            <div className="button-wrapper align-right margin-top-10">
              <Button
                className="stackable-btn"
                primary
                onClick={this.onRefundClick}
                loading={isRefunding}
                disabled={isRefunding}
              >
                Yes
              </Button>
              <Button onClick={this.onRefundConfirmClose}>No</Button>
            </div>
          </Modal.Content>
        )}
        {alreadyRefunded && (
          <Modal.Content>
            {I18n.t(`${this.props.path}.modals.refund-ticket.warn-message`, {
              amount: amount
            })}
            <div className="button-wrapper align-right margin-top-10">
              <Button primary onClick={this.onRefundConfirmClose}>
                Ok
              </Button>
            </div>
          </Modal.Content>
        )}
      </Modal>
    );
  }
}

const mapState = ({ ticketing }, props) => {
  const { path = 'ticket' } = props;
  return {
    path,
    isRefunded: ticketing?.[path]?.isRefunded,
    saveRecord: ticketing?.saveRecord,
    refundRecord: ticketing?.[path]?.refundRecord,
    refundConfirmation: ticketing?.[path]?.refundConfirmation
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(ticketingActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const RefundTicketWindowContainer = connect(
  mapState,
  mapDispatch
)(RefundTicketWindow);
export default RefundTicketWindowContainer;
