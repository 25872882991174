import { Message } from 'semantic-ui-react';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { hasTextEntryInResources } from '../template/templateHelper';

const timer = undefined;

class RecordResultMessage extends Component {
  componentWillUnmount() {
    // clearTimeout(timer);
  }

  setDismissTimer = () => {
    if (!timer) {
      clearTimeout(timer);
      this.timer = setTimeout(() => {
        if (this.props.onDismiss) {
          this.props.onDismiss();
        }
      }, 5000);
    }
  };

  getTranslation = (key) => {
    if (!key) {
      return I18n.t('general.general-error-message');
    }

    const fullKey = 'error-messages.' + key;

    return hasTextEntryInResources(fullKey)
      ? I18n.t(fullKey)
      : `Error occured with ${key}, error type: ${I18n.t(key)}.`;
  };

  render() {
    // TODO: move to data.json
    if (this.props.record?.error?.show) {
      let content = [];
      if (this.props.record.error.errorKeys?.length > 0) {
        content = this.props.record.error.errorKeys.map((element) => {
          return this.getTranslation(element);
        });
      } else if (this.props.record.error?.title) {
        content = [this.props.record.error?.title];
      } else {
        content = [I18n.t('general.internal-server-error')];
      }

      this.setDismissTimer();
      return (
        <Message
          className="record-result"
          error
          attached="bottom"
          onDismiss={this.props.onDismiss}
          list={content}
        />
      );
    } else if (this.props.record.success && this.props.record.success.show) {
      if (this.props.redirectOnSuccess) {
        this.props.redirectOnSuccess();
      }

      const { customSuccessMessage } = this.props;

      this.setDismissTimer();
      return (
        <Message
          className="record-result"
          info
          attached="bottom"
          onDismiss={this.props.onDismiss}
          content={customSuccessMessage || I18n.t('general.changes-saved')}
        />
      );
    }
    return null;
  }
}

export default RecordResultMessage;
