import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import classNames from 'classnames';
import { Button, Accordion, Grid, Checkbox } from 'semantic-ui-react';

import * as filterActions from '../../../modules/filter';

import get from 'lodash.get';
import update from '../../../helpers/update';
import { encodeUnsupportedCharacters } from '../../../helpers/replaceCharacter';
import { isObject } from '../../../helpers/objectHelper';

import { ITEM_TYPES, SEARCH_TEXT_KEY } from './filtersButtonConstants';
import {
  getFilterColumns,
  getCheckedFilter,
  getFilterItem
} from './filterHelper';

import { archivedFilters } from '../../../pages/vipProgram/constants';

class FiltersButtonComponent extends Component {
  state = {
    activeIndex: -1,
    showContext: false,
    columns: [],
    isApplied: true
  };

  onButtonClick = () => {
    this.setState((prevState) => ({
      showContext: !prevState.showContext
    }));
  };

  onFilterChecked = (_event, { path }) => {
    const oldValue = get(this.props.columns, `${path}.checked`);

    this.props.onValueChange(
      `${this.props.listKey}.columns.${path}.checked`,
      !oldValue
    );

    if (this.state.isApplied) {
      this.setState({
        isApplied: false
      });
    }
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      this.state.showContext
    ) {
      this.changeListVisibility();
    }
  };

  changeListVisibility = () => {
    this.setState((prevState) => {
      return {
        showContext: !prevState.showContext
      };
    });
  };

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  onChange = (event, target) => {
    this.props.onValueChange(
      `${this.props.listKey}.columns.${target.path}`,
      target.value
    );

    if (this.state.isApplied) {
      this.setState({
        isApplied: false
      });
    }
  };

  handleChange = (path, date) => {
    this.props.onValueChange(`${this.props.listKey}.columns.${path}`, date);

    if (this.state.isApplied) {
      this.setState({
        isApplied: false
      });
    }
  };

  onApplyClick = () => {
    const checkedFilters = [];
    const { availableFilters, metadata } = this.props;
    const filterColumns = getFilterColumns(availableFilters, metadata)
      .map((x) => {
        const filterData = get(this.props.columns, `${x.key}`);

        return filterData.checked ? { ...filterData, key: x.key } : undefined;
      })
      .filter((x) => x !== undefined);

    filterColumns.forEach((column) => {
      const checkedFilter = getCheckedFilter(column);

      checkedFilters.push(...checkedFilter);
    });

    if (!this.props.disableQueryFilters) {
      this.setQueryFilters(filterColumns);
    }

    this.props.applyFilters(checkedFilters, this.props.searchText);

    this.setState({
      isApplied: true
    });
  };

  setQueryFilters = (filterColumns) => {
    const filters = [
      ...filterColumns,
      {
        key: SEARCH_TEXT_KEY,
        value: this.props.searchText
      }
    ];
    const queryFilters = filters
      .map((filter) => {
        if (!filter.value && filter.type !== ITEM_TYPES.BOOLEAN) {
          return null;
        }

        if (isObject(filter.value)) {
          if (filter.value.from && filter.value.to) {
            return `${filter.key}[from]=${filter.value.from}&${filter.key}[to]=${filter.value.to}`;
          }
          if (filter.value.from) {
            return `${filter.key}[from]=${filter.value.from}`;
          }
          if (filter.value.to) {
            return `${filter.key}[to]=${filter.value.to}`;
          }
        } else {
          if (filter.label) {
            return `${filter.key}=${filter.value}&${
              filter.key
            }[label]=${encodeUnsupportedCharacters(filter.label)}`;
          } else {
            return `${filter.key}=${filter.value}`;
          }
        }
        return null;
      })
      .filter((x) => x)
      .join('&');

    var newurl =
      window.location.origin +
      window.location.pathname +
      (queryFilters ? `?${queryFilters}` : '');

    window.history.replaceState({ path: newurl }, '', newurl);
  };

  initData = (columns) => {
    this.props.onInit(this.getInitialColumnsModel(columns), this.props.listKey);
    this.props.onApply(this.props.userFilters, this.props.listKey);
  };

  getInitialColumnsModel = (columns) => {
    let columnsModel = {};

    columns.forEach((column) => {
      const initedData = {
        checked: false,
        type: column.dataType
      };

      if (column.dataType === ITEM_TYPES.BOOLEAN) {
        initedData.value = false;
      }

      columnsModel = update.set(columnsModel, column.key, initedData);
    });

    return columnsModel;
  };

  onClearClick = () => {
    const { availableFilters, metadata } = this.props;
    const initialColumns = this.getInitialColumnsModel(
      getFilterColumns(availableFilters, metadata)
    );

    this.props.onClear(initialColumns, this.props.listKey);

    this.setState({
      isApplied: false
    });
  };

  getFilter = () => {
    if (Array.isArray(this.props.filter)) {
      return this.props.filter?.filter((x) => !archivedFilters.includes(x.key));
    }
    return this.props.filter;
  };

  render() {
    const { availableFilters, metadata } = this.props;
    const filterColumns = getFilterColumns(availableFilters, metadata);

    if (!this.props.inited && filterColumns) {
      this.initData(filterColumns);
    }

    const filterForms = filterColumns?.map((column) =>
      getFilterItem(
        this.props.columns,
        column,
        this.props.url,
        this.getFilter(),
        this.props.additionalFilters,
        this.props.filterLookupBaseOptions,
        this.props.listKey,
        false,
        this.onChange,
        this.handleChange
      )
    );

    const contextClass = classNames({
      open: this.state.showContext,
      context: true,
      'filter-context': true
    });

    return (
      <div className="filter" ref={this.setWrapperRef}>
        <Button onClick={this.onButtonClick} disabled={!filterColumns}>
          Filter
        </Button>
        {this.state.showContext && (
          <div className={contextClass}>
            <Accordion styled className="filter-context-accordeon">
              <Grid
                verticalAlign="middle"
                className="context-header"
                columns={3}
              >
                <Grid.Column textAlign="left">
                  <Button onClick={this.onClearClick}>Clear</Button>
                </Grid.Column>
                <Grid.Column textAlign="center">
                  <strong>Filter</strong>
                </Grid.Column>
                <Grid.Column textAlign="right">
                  <Button
                    id="filter-button"
                    primary={!this.state.isApplied}
                    onClick={this.onApplyClick}
                  >
                    Apply
                  </Button>
                </Grid.Column>
              </Grid>
              {filterForms.map((item, index) => {
                const checked = get(this.props.columns, `${item.key}.checked`);

                return (
                  <div key={index}>
                    <Accordion.Title
                      active={checked}
                      style={{ width: '100%' }}
                      onClick={this.onFilterChecked}
                      path={item.key}
                      index={index}
                    >
                      <Checkbox label={item.label} checked={checked} />
                    </Accordion.Title>
                    <Accordion.Content
                      active={checked}
                      content={item.content}
                    />
                  </div>
                );
              })}
            </Accordion>
          </div>
        )}
      </div>
    );
  }
}

const mapState = ({ filter }, { listKey }) => {
  const columns = get(filter, `${listKey}.columns`);
  const inited = get(filter, `${listKey}.inited`);

  return {
    columns,
    inited
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(filterActions, dispatch);
};

export const FiltersButton = connect(
  mapState,
  mapDispatch
)(FiltersButtonComponent);
