import React, { Component } from 'react';
import { cloneDeep } from 'lodash';
import Grid from '../';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import update from 'immutability-helper';
import { withRouter } from 'react-router';

import ContextMenu from '../contextMenu';
import ButtonGroup from './buttonGroup';
import Panel from './panel';
import CenterPanel from './centerPanel';
import { FiltersButton, AdvancedFilter, SEARCH_TEXT_KEY } from '../../filter';

import { convertFiltersToV3 } from '../../../../helpers/filterHelper';
import { parseQuery } from '../../../../helpers/queryHelper';
import { decodeUnsupportedCharacters } from '../../../../helpers/replaceCharacter';
import { isArrayWithItems } from '../../../../helpers/arrayHelper';

import { Record } from '../../../../modules/bin/utility';
import SettingsManager, {
  SETTINGS_KEYS
} from '../../../../modules/bin/settingsManager';

import { List as ListConstants } from '../../../../constants';
import {
  listFilterOperator,
  listFilterPath
} from '../../../../constants/aggregate';
import { DEFAULT_TIME } from '../constants';
import { adminColumns } from '../../../../pages/accounts/constants';

class ManagedGrid extends Component {
  constructor(props) {
    super(props);
    this.currentPaginationPage = 1;
    if (!this.props.list) return;
    this.baseFilters = [...this.props.list.baseFilters];
    this.initToggleColumnsFromStorage();
    if (this.props.filter) {
      if (Array.isArray(this.props.filter)) {
        this.baseFilters = [...this.props.filter];
      } else if (this.props.filter) {
        this.baseFilters.push(this.props.filter);
      }
    }
  }

  componentDidMount() {
    // Load the column metadata & first grid data if missing.
    this.initialiseFilters();
    this.initialisePaginationPage();
    this.initialise();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const prevListReady = get(this.props, 'list.ready');
    const nextlistReady = get(nextProps, 'list.ready');

    if (
      this.props &&
      nextProps &&
      prevListReady !== nextlistReady &&
      (this.props.listKey !== nextProps.listKey ||
        nextlistReady === ListConstants.LIST_STATUS_NO_DATA)
    ) {
      this.initialiseFilters();
      this.initialise(nextProps);
      return true;
    }

    if (
      this.props.allowBaseFiltersUpdates &&
      this.props.filter !== nextProps.filter
    ) {
      this.baseFilters = [...nextProps.filter];
    }

    if (this.props.activeTabFilter !== nextProps.activeTabFilter) {
      this.onTabFilterChanged(nextProps.activeTabFilter);
      return true;
    }

    if (this.props.list?.byPage !== nextProps.list?.byPage) {
      return true;
    }

    // Determine if the state has changed by timestamp.
    if (
      this.props &&
      this.props.list &&
      this.props.list.timestamp &&
      nextProps &&
      nextProps.list &&
      nextProps.list.timestamp
    ) {
      return this.props.list.timestamp < nextProps.list.timestamp;
    }

    return true;
  }

  getCurrentTabFilter(filterButtons) {
    if (this.props.activeTabFilter) {
      const filterButton = filterButtons.find(
        (item) => item.key === this.props.activeTabFilter
      );
      if (filterButton) {
        return filterButton.filter;
      }
    }
    return filterButtons[0].filter;
  }

  initialise(currentProps) {
    const { filterButtons } = this.props.list;
    if (filterButtons && filterButtons.length && !currentProps) {
      this.currentTabFilter = this.getCurrentTabFilter(filterButtons);
    }

    const props = currentProps || this.props;

    // Get the current state
    const metadataReady = get(props, 'list.metadata.ready');
    const listReady = get(props, 'list.ready');

    // Get a key to the list in the state.
    const listKey = get(props, 'listKey');

    const objectId = get(props, 'id');
    const listObjectId = get(props, 'list.id');

    // Load the column metadata if missing.
    if (
      listKey &&
      metadataReady === ListConstants.LIST_METADATA_STATUS_NO_DATA
    ) {
      props.actions.getMetadata(listKey);
    }

    // Load the grid's data if missing.
    if (
      listKey &&
      (listObjectId !== objectId ||
        listReady === ListConstants.LIST_STATUS_NO_DATA)
    ) {
      let request = this.getRequest(props);
      request = update.set(request, 'text', this.searchText);

      props.actions.getListData(listKey, objectId, request, true);
    }
  }

  initialiseFilters = () => {
    const queryFilters = parseQuery(this.props.location.search);
    const availableFiltersKeys = get(this.props, 'list.availableFiltersKeys');

    const filters = [];
    let searchText = '';

    Object.keys(queryFilters).forEach((filterKey) => {
      const filterName = filterKey
        .replace('[from]', '')
        .replace('[to]', '')
        .replace('[label]', '');
      if (availableFiltersKeys.some((x) => x === filterName)) {
        if (filterKey.match(/.*\[from\]/)) {
          filters.push({
            key: filterName,
            operator: listFilterOperator.GREATER_OR_EQUAL_THAN,
            value: queryFilters[filterKey]
          });
          return;
        }
        if (filterKey.match(/.*\[to\]/)) {
          let value = queryFilters[filterKey];

          if (filterName === adminColumns.CREATED_AT_LOCAL) {
            value = `${value} ${DEFAULT_TIME}`;
          }
          filters.push({
            key: filterName,
            operator: listFilterOperator.LESSER_OR_EQUAL_THAN,
            value: value
          });
          return;
        }
        if (filterKey.match(/.*\[label\]/)) {
          filters.push({
            key: filterName,
            value: decodeUnsupportedCharacters(queryFilters[filterKey]),
            path: listFilterPath.LABEL
          });
          return;
        }

        filters.push({
          key: filterName,
          operator: listFilterOperator.EQUAL_TO,
          value: queryFilters[filterKey]
        });
      }
      if (filterName === SEARCH_TEXT_KEY) {
        searchText = queryFilters[filterKey];
      }
    });

    this.userFilters = filters;
    this.searchText = searchText;
  };

  initialisePaginationPage = () => {
    const queryFilters = parseQuery(this.props.location.search);

    if (queryFilters && queryFilters.page) {
      this.currentPaginationPage = parseInt(queryFilters.page);
    }
  };

  applyFilters = (filters, searchText, ignoreBaseFilter) => {
    this.userFilters = filters;

    let request = this.getRequest(this.props, null, ignoreBaseFilter);
    request = update.set(request, 'text', searchText);
    request = update.set(request, 'page', 1);

    this.props.actions.getListData(
      this.props.listKey,
      this.props.id,
      request,
      true
    );
  };

  getRequestFilters = (ignoreBaseFilter = []) => {
    const requestFilters = [];

    if (this.baseFilters) {
      const baseFilters = this.baseFilters.filter(
        (bf) => !ignoreBaseFilter.includes(bf.key)
      );
      requestFilters.push(...baseFilters);
    }

    if (this.userFilters) {
      requestFilters.push(...this.userFilters.filter((x) => x.operator));
    }

    if (this.currentTabFilter) {
      requestFilters.push(this.currentTabFilter);
    }

    if (this.props.searchVersion === 'v3') {
      return convertFiltersToV3(requestFilters);
    }

    return requestFilters;
  };

  getRequest(currentProps, req, ignoreBaseFilter) {
    const props = currentProps || this.props;

    let request = req || JSON.parse(JSON.stringify(get(props, 'list.request')));

    const filters = this.getRequestFilters(ignoreBaseFilter);

    request = update.set(request, 'filters', filters);
    request = update.set(request, 'page', this.currentPaginationPage);

    return request;
  }

  getColumns() {
    return this.props.list.columns.filter((item) => {
      if (this.props.hideColumn) {
        return this.props.hideColumn(item) !== true;
      }
      return true;
    });
  }

  generateColumns(currentProps) {
    const props = currentProps || this.props;

    // Get the current state
    const metadataReady = get(props, 'list.metadata.ready');

    // Only process if the column metadata has loaded.
    if (metadataReady === ListConstants.LIST_METADATA_STATUS_READY) {
      const columns = this.getColumns();

      // Ensure the column order is present.
      if (columns) {
        // Get an array of key for sortable columns.
        const sortableList = get(props, 'list.metadata.sort');

        // Map all columns into React Column elements.
        const result = columns
          .filter((x) => x.visible)
          .map((columnItem) => {
            const sortable = sortableList.includes(columnItem.path);
            let align = 'left';
            switch (columnItem.dataType) {
              case 'int32':
              case 'decimal':
                align = 'right';
                break;
              default:
                align = 'left';
            }

            return (
              <Grid.Column
                key={columnItem.path}
                id={columnItem.path}
                uniqueId={columnItem.uniqueId}
                headerText={
                  columnItem.headerKey && I18n.t(columnItem.headerKey)
                }
                list={props.list}
                datatype={columnItem.type}
                sortable={sortable}
                styles={columnItem.styles}
                width={columnItem.width}
                customFormatter={columnItem.customFormatter}
                textAlign={align}
                linkInfo={columnItem.linkInfo}
              />
            );
          });

        // Add the context menu if possible.
        const contextMenu = this.generateContextMenu(props);
        if (contextMenu) result.push(contextMenu);

        return result;
      }
    }

    // Return an empty set of columns if metadata is missing.
    return [];
  }

  generateContextMenu(_currentProps) {
    // Has Buttons specified via children tags
    if (this.props.children) {
      const children = []
        .concat(this.props.children)
        .filter((item) => item.type === ContextMenu);
      if (children.length > 0) {
        const contextMenu = children[0];
        return contextMenu;
      }
    }
  }

  getData(currentProps) {
    const props = currentProps || this.props;

    // Get the current state
    const metadataReady = get(props, 'list.metadata.ready');

    // Only process if the column metadata has loaded.
    if (metadataReady === ListConstants.LIST_METADATA_STATUS_READY) {
      const page = get(props, 'list.request.page');
      const byPageList = get(props, 'list.byPage');

      if (page && byPageList && byPageList[page]) {
        return byPageList[page];
      }
    }

    return [];
  }

  onSearch(value) {
    let request = this.getRequest(this.props);
    request = update.set(request, 'page', 1);

    this.props.actions.getListDataBySearchTerm(
      this.props.listKey,
      this.props.id,
      request,
      value
    );

    if (!this.props.disableQueryFilters) {
      this.setQuerySearch(value);
    }
  }

  setQuerySearch = (value) => {
    let queryFilters = parseQuery(window.location.search);

    queryFilters[SEARCH_TEXT_KEY] = value;
    const queryString = Object.keys(queryFilters)
      .filter((filterKey) => queryFilters[filterKey])
      .map((filterKey) => `${filterKey}=${queryFilters[filterKey]}`)
      .join('&');

    var newurl =
      window.location.origin +
      window.location.pathname +
      (queryString ? `?${queryString}` : '');

    window.history.replaceState({ path: newurl }, '', newurl);
  };

  onSortClick(key) {
    // Ensure the sort event has provided a key
    if (!key) throw new Error('Cannot sort without a key.');

    // Esnure the state contains a request object -- used for manging list data.
    const request = get(this.props, 'list.request');
    const columns = get(this.props, 'list.metadata.columns');
    if (!request) throw new Error('Cannot sort without a valid request.');

    // Only process if the list is in a ready state - ignore if currently loading.
    if (get(this.props, 'list.ready') === ListConstants.LIST_STATUS_READY) {
      const columnItem = columns.find((column) => column.key === key);
      const columnIsDateTimeOrDecimal =
        columnItem !== null &&
        (columnItem.dataType === 'Decimal' ||
          columnItem.dataType === 'DateTime');
      // Determine if the sort direction needs to be toggled or is a new sort field.
      const direction =
        request && request.orderBy && request.orderBy.key === key
          ? request.orderBy.direction === 'asc'
            ? 'desc'
            : 'asc'
          : columnIsDateTimeOrDecimal
          ? 'desc'
          : 'asc';

      // Create an updated request with the new Ordering instructions
      const msg = this.getRequest(
        this.props,
        update(request, {
          orderBy: { $set: { key, direction } }
        })
      );

      // Dispatch the request & invalidate any cached list data.
      this.props.actions.getListData(
        this.props.listKey,
        this.props.id,
        msg,
        true
      );
    }
  }

  onMultiSelectClick(item, checked) {
    const list = cloneDeep(this.props?.list);
    let data;
    const page = list?.request?.page;
    const byPageList = list?.byPage;

    if (page && byPageList && byPageList[page]) {
      data = byPageList[page];
    } else {
      return;
    }

    if (item.isHeader) {
      // update entire list
      for (let i = 0; i < data.length; i++) {
        data[i].template = { value: { isChecked: checked } };
      }
      list.isMultiSelectChecked = checked;
    } else {
      if (!checked) {
        list.isMultiSelectChecked = false;
      }
      const foundItem = data.find((x) => x.id === item.id);
      // update single item
      foundItem.template = { value: { isChecked: checked } };
      const allSelected = data.every((x) => x.template?.value.isChecked);
      if (allSelected) {
        list.isMultiSelectChecked = true;
      }
    }

    this.props.actions.updateListData(list);
  }

  onPageChange = (page) => {
    // Esnure the state contains a request object -- used for manging list data.
    const request = get(this.props, 'list.request');
    if (!request) throw new Error('Cannot page without a valid request.');

    // Only process if the list is in a ready state - ignore if currently loading.
    if (get(this.props, 'list.ready') === ListConstants.LIST_STATUS_READY) {
      this.currentPaginationPage = page;

      // Create an updated request with the new Ordering instructions
      const msg = this.getRequest(
        this.props,
        update(request, {
          page: { $set: page }
        })
      );

      this.updateUrlOnPageChange(page);

      // Get the data cache if available.
      const byPageList = get(this.props, 'state.list.byPage') || {};

      // Dispatch the request -- do not invalidate any cached list data.
      this.props.actions.getListData(
        this.props.listKey,
        this.props.id,
        msg,
        false,
        byPageList
      );
    }
  };

  updateUrlOnPageChange(page) {
    const pageQueryPart = `page=${page}`;

    const searchQueryPart = window.location.search
      .replace('?', '')
      .split('&')
      .filter((x) => !x.startsWith('page'))
      .join('&');
    const newurl = `${window.location.origin}${window.location.pathname}${
      searchQueryPart ? `?${searchQueryPart}&` : '?'
    }${pageQueryPart}`;

    window.history.replaceState({ path: newurl }, '', newurl);
  }

  getColumnsFromStorage(listKey) {
    let columnsFromStorage = null;
    try {
      columnsFromStorage = SettingsManager.getSettingsByKey(
        listKey,
        SETTINGS_KEYS.TOGGLE_COLUMNS
      );
    } catch (e) {
      SettingsManager.removeSetting(listKey, SETTINGS_KEYS.TOGGLE_COLUMNS);
      return columnsFromStorage;
    }

    if (columnsFromStorage) {
      return columnsFromStorage;
    }
  }

  getUpdatedColumns(gridColumns, storageColumns) {
    const updatedColumns = gridColumns.map((gridColumn) => {
      const storageColumn = storageColumns.find(
        (x) => x.path === gridColumn.path
      );

      return storageColumn && storageColumn.visible !== gridColumn.visible
        ? update.set(gridColumn, 'visible', storageColumn.visible)
        : gridColumn;
    });

    return updatedColumns;
  }

  initToggleColumnsFromStorage = () => {
    const listKey = this.props.listKey;
    const gridColumns = get(this.props, 'list.columns');
    const storageColumns = this.getColumnsFromStorage(listKey);
    const hasGridAndLocalStorageColumns =
      isArrayWithItems(gridColumns) && isArrayWithItems(storageColumns);

    if (hasGridAndLocalStorageColumns) {
      const updatedColumns = this.getUpdatedColumns(
        gridColumns,
        storageColumns
      );
      this.onToggleColumnsChange(updatedColumns);
    }
  };

  onToggleColumnsChange = (columns) => {
    SettingsManager.setSetting(
      this.props.listKey,
      SETTINGS_KEYS.TOGGLE_COLUMNS,
      columns
    );
    this.props.actions.toggleColumnsChange(this.props.listKey, columns);
  };

  onTabFilterChanged(key) {
    // Ensure the filter button event has provided a key
    if (!key) throw new Error('Cannot filter without a key.');

    // Esnure the state contains a request object -- used for manging list data.
    const request = get(this.props, 'list.request');
    if (!request) throw new Error('Cannot filter without a valid request.');

    // Open first page every time filter is changed.
    request.page = 1;

    // Get the filter buttons from the
    const filterButtons = get(this.props, 'list.filterButtons');
    const listReady = get(this.props, 'list.ready');

    const foundButtons = filterButtons.filter((item) => item.key === key);
    // Only process if the list is in a ready state - ignore if currently loading.
    if (
      filterButtons &&
      foundButtons.length &&
      listReady === ListConstants.LIST_STATUS_READY
    ) {
      const firstElementIndex = 0;
      // Get the selected filter button
      const filterItem = foundButtons[firstElementIndex];

      this.currentTabFilter = filterItem.filter;

      const msg = this.getRequest(this.props);

      // Dispatch the request & invalidate any cached list data.
      this.props.actions.getListData(
        this.props.listKey,
        this.props.id,
        msg,
        true
      );
    }
  }

  getPanel(_currentProps, key) {
    if (this.props.children) {
      const panel = []
        .concat(this.props.children)
        .find((item) => item.type === Panel && item.props.name === key);
      return panel;
    }

    return null;
  }

  getCenterPanel = () => {
    if (this.props.children) {
      const panel = []
        .concat(this.props.children)
        .find((item) => item.type === CenterPanel);
      return panel;
    }

    return null;
  };

  render() {
    // Determine if the metadata has loaded and whether all data to render the grid has loaded.
    const metadataReady =
      get(this.props, 'list.metadata.ready') ===
      ListConstants.LIST_METADATA_STATUS_READY;
    const isLoading =
      Record.isRecordLoading(this.props.list) ||
      Record.isRecordLoading(this.props.list.metadata);
    const isErrorOccured =
      Record.isError(this.props.list) ||
      Record.isError(this.props.list.metadata);

    const metadata = get(this.props, 'list.metadata');

    // Get paging information & determine the number of pages.
    const page = get(this.props, 'list.request.page') || 1;
    const pageSize = get(this.props, 'list.request.pageSize');
    const totalCount = get(this.props, 'list.totalCount');
    const pageTotal =
      pageSize && totalCount ? Math.ceil(totalCount / pageSize) : null;

    // Gets the free text search
    const searchText = get(this.props, 'list.request.text') || '';

    // Gets the sort key and direction from state.
    const sortKey = get(this.props, 'list.request.orderBy.key');
    const sortDirection = get(this.props, 'list.request.orderBy.direction');

    // Add the context menu if possible.
    //const contextMenu = this.generateContextMenu(this.props);

    // Generates the Column elements based on the state.
    const columns = this.generateColumns(this.props);

    // get grid columns data from state
    const columnsData = this.getColumns();

    // Gets the data from the state.
    const data = this.getData(this.props);

    // Gets the components that appear to the right of the search.
    const rightPanel = this.getPanel(this.props, 'right');

    const centerPanel = this.getCenterPanel();

    const availableFiltersKeys = get(this.props, 'list.availableFiltersKeys');
    const filterProps = {
      metadata: metadata,
      filter: this.props.filter,
      userFilters: this.userFilters,
      applyFilters: this.applyFilters,
      applyAggregates: this.props.applyAggregates,
      disableQueryFilters: this.props.disableQueryFilters,
      listKey: this.props.listKey,
      searchText: searchText,
      searchVersion: this.props.searchVersion,
      availableFilters: availableFiltersKeys,
      additionalFilters: this.props.additionalFilters,
      url: this.props.url,
      filterLookupBaseOptions: this.props.filterLookupBaseOptions
    };

    const CustomGridOptions = this.props.customGridOptions;

    return (
      <div className="managed-grid">
        <div className="grid-options">
          {this.props.additionalGridActions && (
            <div className="additional-actions">
              {this.props.additionalGridActions}
            </div>
          )}
          {this.props.search && (
            <Grid.Search
              value={searchText}
              placeholder={this.props.searchPlaceholder}
              disabled={isLoading}
              onSearch={this.onSearch.bind(this)}
            />
          )}
          {this.props.toggleColumns && (
            <Grid.ToggleColumns
              shortDescription={this.props.shortDescription}
              columns={columnsData}
              onToggleColumnsChage={this.onToggleColumnsChange}
            />
          )}
          {this.props.filtersButton && <FiltersButton {...filterProps} />}
          {rightPanel}
        </div>
        {CustomGridOptions && (
          <div className="grid-options">
            <CustomGridOptions {...filterProps} />
          </div>
        )}
        {centerPanel}
        {this.props.advancedFilterButton && <AdvancedFilter {...filterProps} />}
        <Grid
          data={data}
          initialised={metadataReady}
          loading={isLoading}
          isErrorOccured={isErrorOccured}
          sort={sortKey}
          direction={sortDirection}
          onSortClick={this.onSortClick.bind(this)}
          onMultiSelectClick={this.onMultiSelectClick.bind(this)}
          list={this.props?.list}
          columnRender={this.props.columnRender}
          customColumn={this.props.customColumn}
        >
          {columns}
        </Grid>
        {!this.props.hidePagination && pageTotal && (
          <Grid.Pagination
            recordCount={totalCount}
            page={page}
            total={pageTotal}
            onPageChange={this.onPageChange}
          />
        )}
      </div>
    );
  }
}

const mapGridState = (state, listKey) => {
  const list = get(state, listKey);

  return {
    key: listKey,
    timestamp: list.timestamp,
    list
  };
};

const bindGridActions = (dispatch, actionCreators) => {
  return bindActionCreators(actionCreators, dispatch);
};

ManagedGrid.ContextMenu = ContextMenu;
ManagedGrid.ButtonGroup = ButtonGroup;
ManagedGrid.Panel = Panel;
ManagedGrid.CenterPanel = CenterPanel;

ManagedGrid.mapGridState = mapGridState;
ManagedGrid.bindGridActions = bindGridActions;

export default withRouter(ManagedGrid);
