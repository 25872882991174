import { donationQuestionFormIds } from './constants';

export const addDonationFullModel = {
  [donationQuestionFormIds.DONOR_FIRST_NAME]: {
    required: true
  },
  [donationQuestionFormIds.DONOR_LAST_NAME]: {
    required: true
  },
  [donationQuestionFormIds.EMAIL]: {
    required: true,
    email: true
  }
};

export const addDonationSimplyfiedModel = {
  [donationQuestionFormIds.EMAIL]: {
    email: true
  }
};
