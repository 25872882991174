import { I18n } from "react-redux-i18n";

const MerchandiseItemTranslationPath =
  'template.merchandise.content.merchandise-detail';

const MerchandiseItemTranslationPaths = {
  Name: `${MerchandiseItemTranslationPath}.merchandise-name.label`,
  NamePlaceholder: `${MerchandiseItemTranslationPath}.merchandise-name.placeholder`,
  Description: `${MerchandiseItemTranslationPath}.merchandise-description.label`,
  ImgUpload: `${MerchandiseItemTranslationPath}.image.upload`,
  ImgChange: `${MerchandiseItemTranslationPath}.image.change`,
  DeleteModalHeader: `${MerchandiseItemTranslationPath}.delete-modal.header`,
  DeleteModalDescription: `${MerchandiseItemTranslationPath}.delete-modal.description`,
  DeleteModalCancel: `${MerchandiseItemTranslationPath}.delete-modal.btn-cancel`,
  DeleteModalDelete: `${MerchandiseItemTranslationPath}.delete-modal.btn-delete`,
  SalesTaxLabel: `${MerchandiseItemTranslationPath}.sales-tax.label`,

};

export const MerchandiseItemLabels = {
  Name: I18n.t(MerchandiseItemTranslationPaths.Name),
  NamePlaceholder: I18n.t(MerchandiseItemTranslationPaths.NamePlaceholder),
  Description: I18n.t(MerchandiseItemTranslationPaths.Description),
  ImgUpload: I18n.t(MerchandiseItemTranslationPaths.ImgUpload),
  ImgChange: I18n.t(MerchandiseItemTranslationPaths.ImgChange),
  DeleteModalHeader: I18n.t(MerchandiseItemTranslationPaths.DeleteModalHeader),
  DeleteModalDescription: I18n.t(MerchandiseItemTranslationPaths.DeleteModalDescription),
  DeleteModalCancel: I18n.t(MerchandiseItemTranslationPaths.DeleteModalCancel),
  DeleteModalDelete: I18n.t(MerchandiseItemTranslationPaths.DeleteModalDelete),
  SalesTaxLabel: I18n.t(MerchandiseItemTranslationPaths.SalesTaxLabel),
};

const SkuItemTranslationPaths = {
  Header: `${MerchandiseItemTranslationPath}.skus.header`,
  Name: `${MerchandiseItemTranslationPath}.skus.sku.name.label`,
  NamePlaceholder: `${MerchandiseItemTranslationPath}.skus.sku.name.placeholder`,
  Price: `${MerchandiseItemTranslationPath}.skus.sku.price.label`,
  PriceTooltip: `${MerchandiseItemTranslationPath}.skus.sku.price.tooltip`,
  PriceFree: `${MerchandiseItemTranslationPath}.skus.sku.price.free`,
  Quantity: `${MerchandiseItemTranslationPath}.skus.sku.quantity.label`,
  QuantityTooltip: `${MerchandiseItemTranslationPath}.skus.sku.quantity.tooltip`,
  QuantityUnlimited: `${MerchandiseItemTranslationPath}.skus.sku.quantity.unlimited`,
  BtnAdd: `${MerchandiseItemTranslationPath}.skus.buttons.add`,
  BtnEdit: `${MerchandiseItemTranslationPath}.skus.buttons.edit`,
  BtnSave: `${MerchandiseItemTranslationPath}.skus.buttons.save`,
  BtnCancel: `${MerchandiseItemTranslationPath}.skus.buttons.cancel`,
  BtnDelete: `${MerchandiseItemTranslationPath}.skus.buttons.delete`,
};

export const SkuItemLabels = {
  Header: I18n.t(SkuItemTranslationPaths.Header),
  Name: I18n.t(SkuItemTranslationPaths.Name),
  NamePlaceholder: I18n.t(SkuItemTranslationPaths.NamePlaceholder),
  Price: I18n.t(SkuItemTranslationPaths.Price),
  PriceTooltip: I18n.t(SkuItemTranslationPaths.PriceTooltip),
  PriceFree: I18n.t(SkuItemTranslationPaths.PriceFree),
  Quantity: I18n.t(SkuItemTranslationPaths.Quantity),
  QuantityTooltip: I18n.t(SkuItemTranslationPaths.QuantityTooltip),
  QuantityUnlimited: I18n.t(SkuItemTranslationPaths.QuantityUnlimited),
  BtnAdd: I18n.t(SkuItemTranslationPaths.BtnAdd),
  BtnEdit: I18n.t(SkuItemTranslationPaths.BtnEdit),
  BtnSave: I18n.t(SkuItemTranslationPaths.BtnSave),
  BtnCancel: I18n.t(SkuItemTranslationPaths.BtnCancel),
  BtnDelete: I18n.t(SkuItemTranslationPaths.BtnDelete),
};

export enum InventoryStates {
  SoldOut = 'SOLD_OUT',
  Remaining = 'REMAINING'
}

export const InventoryRemainingAmount = 10;

export const MerchandiseConstants = {
  MERCHANDISE_NAME_MAX_LENGTH: 80,
  MERCHANDISE_DESCRIPTION_MAX_LENGTH: 280
};

export const SkuConstants = {
  SKU_NAME_MAX_LENGTH: 80,
  SKU_DESCRIPTION_MAX_LENGTH: 280
};

export const MerchandiseValidation = {
  Name: {
    required: {
      value: true,
      message: 'Please provide a name'
    },
    maxLength: {
      value: MerchandiseConstants.MERCHANDISE_NAME_MAX_LENGTH,
      message: `Name must be less than ${MerchandiseConstants.MERCHANDISE_NAME_MAX_LENGTH} characters`
    }
  },
  Description: {
    maxLength: {
      value: MerchandiseConstants.MERCHANDISE_DESCRIPTION_MAX_LENGTH,
      message: `Description must be less than ${MerchandiseConstants.MERCHANDISE_DESCRIPTION_MAX_LENGTH} characters`
    }
  }
};

export const SkuValidation = {
  Name: {
    required: {
      value: true,
      message: 'Please provide a name'
    },
    maxLength: {
      value: SkuConstants.SKU_NAME_MAX_LENGTH,
      message: `Name must be less than ${SkuConstants.SKU_NAME_MAX_LENGTH} characters`
    }
  },
  Description: {
    maxLength: {
      value: SkuConstants.SKU_DESCRIPTION_MAX_LENGTH,
      message: `Description must be less than ${SkuConstants.SKU_DESCRIPTION_MAX_LENGTH} characters`
    }
  }
};
