export const METADATA_REQUESTED = 'organization/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'organization/METADATA_SUCCESS';
export const METADATA_FAILURE = 'organization/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'organization/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'organization/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'organization/LIST_DATA_FAILURE';

export const CREATE_REQUESTED = 'organization/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'organization/CREATE_SUCCESS';
export const CREATE_FAILURE = 'organization/CREATE_FAILURE';

export const GET_REQUESTED = 'organization/GET_REQUESTED';
export const GET_SUCCESS = 'organization/GET_SUCCESS';
export const GET_FAILURE = 'organization/GET_FAILURE';

export const UPDATE_REQUESTED = 'organization/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'organization/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'organization/UPDATE_FAILURE';

export const ERROR_CLEAR = 'organization/ERROR_CLEAR';

export const TOGGLE_COLUMNS_CHANGE = 'organization/TOGGLE_COLUMNS_CHANGE';

export const GET_STRIPE_LINK_REQUESTED =
  'organization/GET_STRIPE_LINK_REQUESTED';
export const GET_STRIPE_LINK_SUCCESS = 'organization/GET_STRIPE_LINK_SUCCESS';
export const GET_STRIPE_LINK_FAILURE = 'organization/GET_STRIPE_LINK_FAILURE';

export const TOGGLE_STRIPE_URL_MODAL = 'organization/TOGGLE_STRIPE_URL_MODAL';

export const GET_SALESFORCE_LINK_REQUESTED =
  'organization/GET_SALESFORCE_LINK_REQUESTED';
export const GET_SALESFORCE_LINK_SUCCESS =
  'organization/GET_SALESFORCE_LINK_SUCCESS';
export const GET_SALESFORCE_LINK_FAILURE =
  'organization/GET_SALESFORCE_LINK_FAILURE';

export const GET_EXPORT_FILE_REQUESTED =
  'organization/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'organization/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'organization/GET_EXPORT_FILE_FAILURE';
