import React from 'react';
import get from 'lodash.get';
import ValidatedInput from '../../common/validatedInput';
import {
  addresFields,
  addressFieldsValidation
} from '../../../constants/formBuilder';
import { getSearchString } from '../formBuilderAnswersHelper';

class AddressManualInputs extends React.Component {
  onChange = (path, value) => {
    const newValue = {
      ...this.props.value,
      [path]: value
    };

    const searchString = getSearchString(newValue);

    this.props.onChange({
      ...newValue,
      searchString: searchString
    });
  };

  render() {
    const { validation, value } = this.props;
    return (
      <div>
        <ValidatedInput
          validation={{
            ...validation,
            ...addressFieldsValidation[addresFields.STREET]
          }}
          label="STREET"
          onChange={(event, { value }) =>
            this.onChange(addresFields.STREET, value)
          }
          value={get(value, addresFields.STREET)}
        />

        <ValidatedInput
          validation={{
            ...validation,
            ...addressFieldsValidation[addresFields.SUBURB]
          }}
          label="SUBURB"
          onChange={(event, { value }) =>
            this.onChange(addresFields.SUBURB, value)
          }
          value={get(value, addresFields.SUBURB)}
        />

        <ValidatedInput
          validation={{
            ...validation,
            ...addressFieldsValidation[addresFields.STATE]
          }}
          label="STATE"
          onChange={(event, { value }) =>
            this.onChange(addresFields.STATE, value)
          }
          value={get(value, addresFields.STATE)}
        />

        <ValidatedInput
          validation={{
            ...validation,
            ...addressFieldsValidation[addresFields.POSTCODE]
          }}
          label="POST CODE"
          onChange={(event, { value }) =>
            this.onChange(addresFields.POSTCODE, value)
          }
          value={get(value, addresFields.POSTCODE)}
        />

        <ValidatedInput
          validation={{
            ...validation,
            ...addressFieldsValidation[addresFields.COUNTRY]
          }}
          label="COUNTRY"
          onChange={(event, { value }) =>
            this.onChange(addresFields.COUNTRY, value)
          }
          value={get(value, addresFields.COUNTRY)}
        />
      </div>
    );
  }
}

export default AddressManualInputs;
