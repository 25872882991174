import React from 'react';
import { Button, Dropdown } from 'semantic-ui-react';
import { FeatureFlag } from '../../modules/featureflags';
import { I18n } from 'react-redux-i18n';

type exportVersion = 'v2' | 'v3' | 'v4';

interface IExportDataButtonProps {
  featureFlag?: boolean;
  versions?: exportVersion[];
  onExportClick: (version: string) => void;
}

export const ExportDataButton = ({
  featureFlag = false,
  versions = ['v2', 'v3', 'v4'],
  onExportClick
}: IExportDataButtonProps) => {
  const Wrapper: React.ComponentType<any> = featureFlag
    ? FeatureFlag
    : React.Fragment;

  const handleExportClick = (version: string) => {
    onExportClick(version);
  };

  if (versions.length === 1) {
    return (
      <Wrapper feature="exportData">
        <Button onClick={() => handleExportClick(versions[0])}>Export</Button>
      </Wrapper>
    );
  }

  return (
    <Wrapper feature="exportData">
      <Button className="export-btn">
        <Dropdown className="export-dropdown" text="Export">
          <Dropdown.Menu>
            {versions.map((version) => (
              <Dropdown.Item
                key={version}
                onClick={() => handleExportClick(version)}
              >
                {I18n.t(`dashboard.table-area.export.${version}`)}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </Button>
    </Wrapper>
  );
};
