import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import * as recurringActions from '../reducer';

import { Container } from 'semantic-ui-react';
import Grid from '../../../components/common/grid';
import { Common } from '../../../components';
import ContextMenu from '../../../components/common/grid/contextMenu';
import RefundWindowContainer from './refund-window';

import get from 'lodash.get';
import { hideCondition } from '../../../helpers/hideHelper';

import { gridKeys } from '../../../constants/gridKeys';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';

class DonationsTable extends Component {
  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  onRefundContextClick = (id, item) => {
    this.props.showRefundConfirmation(item);
  };

  render() {
    const baseFilter = {
      key: 'donationSubscriptionId',
      operator: 'EqualTo',
      value: this.props.match.params.id
    };

    return (
      <Container className="donations-container">
        <RefundWindowContainer />
        <Grid.Managed
          listKey={gridKeys.RECURRING_DONATIONS}
          list={this.props.list}
          actions={this.props.donationActionGrid}
          search
          toggleColumns
          filter={baseFilter}
          hideColumn={this.hideColumnCondition}
        >
          <ContextMenu>
            <ContextMenu.Item
              key={CONTEXT_MENU_KEYS.RECURRING.VIEW_PAGE_DONATIONS.key}
              label={I18n.t('recurring.donations.list.context.refund-donation')}
              onClick={this.onRefundContextClick}
            />
          </ContextMenu>
        </Grid.Managed>
      </Container>
    );
  }
}

const mapState = ({ recurring, session }) => {
  const list = get(recurring, `${gridKeys.RECURRING_DONATIONS}`);

  return {
    list: list,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin')
  };
};

const mapDispatch = (dispatch) => {
  var donationTabActions = {
    getMetadata: recurringActions.getDonationsMetadata,
    getListData: recurringActions.getDonationsListData,
    getListDataBySearchTerm: recurringActions.getDonationsListDataBySearchTerm,
    toggleColumnsChange: recurringActions.toggleColumnsChange
  };

  const donationActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    donationTabActions
  );

  return {
    donationActionGrid,
    showRefundConfirmation: (item) => {
      dispatch(recurringActions.showRefundConfirmation(item));
    }
  };
};

const RecurringDetailDonationsContainer = withRouter(
  connect(mapState, mapDispatch)(DonationsTable)
);
export default RecurringDetailDonationsContainer;
