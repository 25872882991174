import React, { Component } from 'react';
import { Input, Icon } from 'semantic-ui-react';

class Search extends Component {
  render() {
    const { children, ...props } = this.props;
    return (
      <Input icon placeholder="Search..." {...props}>
        {children}
        <input />
        <Icon name="search" />
      </Input>
    );
  }
}

export default Search;
