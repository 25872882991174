import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import get from 'lodash.get';

import {
  getCompareModelSearchOptions,
  handleCompareModelAdd,
  handleCompareModelRemove,
  getReportSummaryDataRecord,
  getSummaryRecord
} from '../../reducer';
import { Record as RecordHelper } from '../../../../modules/bin/utility';
import * as validationHelper from '../../../../helpers/validationHelper';

import { Form, Button, Message } from 'semantic-ui-react';
import { Form as CommonForm } from '../../../../components';
import DatePickerInput from '../../../../components/form/datePicker';
import { TagItem } from '../../../../components/tagInput/tagItem';

import { dashboardCompareMaxSize } from '../../constants';
import config from '../../../../constants';

class BuilderView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      model: {
        id: null,
        name: '',
        dateFrom: null,
        dateTo: null
      }
    };

    this.required = {
      required: true
    };

    this.dateValidation = {
      date: true
    };

    this.dashboardBuilderValidation = {
      dashboardBuilder: true
    };
  }

  componentDidMount() {
    this.props.getCompareModelSearchOptions(
      this.props.configurations.getSearchRequest(''),
      this.props.configurations.path
    );
  }

  handleSearchModelChange = (event, obj) => {
    const selectedOption =
      obj.options &&
      obj.options.length &&
      obj.options.find((option) => option.value === obj.value);
    const { list } = this.props.compareModelSearchOptions.data;
    const item =
      list && list.find((x) => x.id === get(selectedOption, 'value'));

    this.setState((state) => {
      return {
        model: {
          ...state.model,
          id: get(selectedOption, 'value'),
          name: get(selectedOption, 'text') || '',
          dateFrom: moment(new Date(get(item, 'createdAtLocal'))).format(
            config.DEFAULT_DATE_FORMAT
          ),
          dateTo: moment(new Date()).format(config.DEFAULT_DATE_FORMAT)
        }
      };
    });
  };

  onSearchModelStringChange = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getCompareModelSearchOptions(
        this.props.configurations.getSearchRequest(searchTerm.searchQuery),
        this.props.configurations.path
      );
    }, 500);
  };

  getCompareModelSearchOptions = () => {
    let options = [];
    const { list } = this.props.compareModelSearchOptions.data;
    if (list) {
      options = list.map((x) => this.getSearchModelItem(x));
    }

    return options;
  };

  getSearchModelItem = (item) => {
    return {
      text: item.name,
      value: item.id,
      createdAtLocal: item.createdAtLocal
    };
  };

  clearSearchModel = () => {
    this.props.getCompareModelSearchOptions(
      this.props.configurations.getSearchRequest(''),
      this.props.configurations.path
    );
  };

  onDateFromChange = (value) => {
    this.setState((state) => {
      return {
        model: {
          ...state.model,
          dateFrom: value
        }
      };
    });
  };

  onDateToChange = (value) => {
    this.setState((state) => {
      return {
        model: {
          ...state.model,
          dateTo: value
        }
      };
    });
  };

  isCompareModelAddButtonDisabled = () => {
    return false;
  };

  onCompareModelAddClick = () => {
    const index = this.props.models.length;
    const path = `compare.models.${index}`;
    const model = this.state.model;

    this.props.handleCompareModelAdd(this.state.model);

    this.props.getSummaryRecord(
      model.id,
      this.props.configurations.entity,
      this.props.timeBracket,
      model.dateFrom,
      model.dateTo,
      `${path}.summaryRecord`
    );
    this.props.getSummaryDataRecord(
      model.id,
      model.dateFrom,
      model.dateTo,
      this.props.configurations.baseFilter,
      this.props.timeBracket,
      this.props.reportByDateAndMetricType,
      `${path}.summaryDataRecord`
    );

    this.setState({
      model: {
        id: null,
        name: '',
        dateFrom: null,
        dateTo: null
      }
    });
  };

  onCompareModelDeleteClick = (index) => {
    this.props.handleCompareModelRemove(index);
  };

  getCompareModels = () => {
    const items = get(this.props, 'models') || [];

    const renderItems = items.map((item, index) => {
      return (
        <TagItem
          index={index}
          label={`${item.name} (${item.dateFrom} - ${item.dateTo})`}
          onDelete={this.onCompareModelDeleteClick}
        />
      );
    });

    return renderItems;
  };

  render() {
    const compareModelSearchOptions = this.getCompareModelSearchOptions();

    const validationModel = {
      model: this.state.model,
      validatedModels: this.props.models
    };
    const isValid = validationHelper.isValid(
      this.dashboardBuilderValidation,
      validationModel
    );
    const validationErrorMessage = validationHelper.getValidationErrorMessage(
      this.dashboardBuilderValidation,
      validationModel
    );

    const isAddBtnDisabled =
      !isValid || this.props.models.length === dashboardCompareMaxSize;

    const compareModels = this.getCompareModels();

    return (
      <div className="dashboard-builder-area-inner-container">
        <div className="models-list-container tags-container">
          <Form className="builder-form">
            <CommonForm.Autocomplete
              className="builder-form-input"
              label={I18n.t(
                `dashboard.compare.builder.form.model.${this.props.configurations.entity}`
              )}
              value={this.state.model.id}
              onChange={this.handleSearchModelChange}
              search={this.onSearchModelStringChange}
              options={compareModelSearchOptions}
              loading={RecordHelper.isRecordLoading(
                this.props.compareModelSearchOptions
              )}
              validation={this.required}
              clear={this.clearSearchModel}
            />

            <DatePickerInput
              className="builder-form-input date-from"
              label={I18n.t('dashboard.compare.builder.form.dateFrom')}
              value={this.state.model.dateFrom}
              onChange={this.onDateFromChange}
              required
              validation={this.dateValidation}
            />

            <DatePickerInput
              className="builder-form-input date-to"
              label={I18n.t('dashboard.compare.builder.form.dateTo')}
              value={this.state.model.dateTo}
              onChange={this.onDateToChange}
              required
              validation={this.dateValidation}
            />

            <Form.Field className="builder-form-input add-btn">
              <Button
                disabled={isAddBtnDisabled}
                onClick={this.onCompareModelAddClick}
              >
                {I18n.t('dashboard.compare.builder.form.add')}
              </Button>
            </Form.Field>
          </Form>
          <div className="tags-list-container">{compareModels}</div>
          <Message negative hidden={!validationErrorMessage}>
            <p>{validationErrorMessage}</p>
          </Message>
        </div>
      </div>
    );
  }
}

const mapState = ({ dashboard }) => {
  return {
    reportByDateAndMetricType: dashboard.reportByDateAndMetricType,
    timeBracket: dashboard.timeBracket,
    configurations: dashboard.configurations,
    models: dashboard.compare.models,
    compareModelSearchOptions: dashboard.compareModelSearchOptionsRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    getCompareModelSearchOptions(request, path) {
      dispatch(getCompareModelSearchOptions(request, path));
    },
    handleCompareModelAdd(item) {
      dispatch(handleCompareModelAdd(item));
    },
    handleCompareModelRemove(index) {
      dispatch(handleCompareModelRemove(index));
    },
    getSummaryRecord(id, type, timeBracket, dateFrom, dateTo, path) {
      dispatch(getSummaryRecord(id, type, timeBracket, dateFrom, dateTo, path));
    },
    getSummaryDataRecord: (
      id,
      dateFrom,
      dateTo,
      baseFilter,
      timeBracket,
      reportByDateAndMetricType,
      path
    ) => {
      dispatch(
        getReportSummaryDataRecord(
          id,
          dateFrom,
          dateTo,
          baseFilter,
          timeBracket,
          reportByDateAndMetricType,
          path
        )
      );
    }
  };
};

const BuilderContainer = connect(mapState, mapDispatch)(BuilderView);
export default BuilderContainer;
