import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { Common } from '../../components';
import { grecaptcha } from '../../helpers/grecaptcha';
import { clear } from '../../modules/general';
import { refreshSession } from '../../modules/session';
import withMfaCheck from '../../components/withMfaCheck';

class SecureRoute extends Component {
  componentDidUpdate() {
    if (
      !this.props.verification &&
      !this.props.session.processing &&
      this.props.session.error
    ) {
      const storageSession = localStorage.getItem('session');

      if (storageSession === 'undefined') {
        localStorage.removeItem('session');
      } else {
        const session = storageSession && JSON.parse(storageSession);
        if (session && session.refresh) {
          const tokenCallback = (token) =>
            this.props.refreshSession(
              session.account.id,
              session.refresh,
              token
            );
          grecaptcha(tokenCallback, 'SessionRefresh');
        }
      }
    }
  }

  render() {
    if (this.props.session.processing) {
      return <Common.Spinner />;
    }

    if (this.props.session && this.props.session.key !== null) {
      return <Route {...this.props} />;
    }

    return this.props.children;
  }
}

const mapState = ({ session, general }) => ({
  session: session,
  verification: general.verification
});

const mapDispatch = (dispatch) => {
  return {
    clear() {
      dispatch(clear());
    },
    refreshSession(accountId, refreshCode, verificationToken = null) {
      dispatch(refreshSession(accountId, refreshCode, verificationToken));
    }
  };
};

export default withMfaCheck(connect(mapState, mapDispatch)(SecureRoute));
