import React, { Component, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { Form, Message, Search } from 'semantic-ui-react';

import { getValidationErrorMessage } from '../../helpers/validationHelper';

interface ISearchInputPorps {
  value?: string;
  label?: string;
  subtext?: string;
  required?: boolean;
  loading?: boolean;
  onChange?: (event: any, data: any) => void;
  search?: (event: any, data: any) => void;
  results?: any[] | Record<string, any>;
  validation?: boolean;
}

const SearchInput = ({
  value,
  label,
  subtext,
  required,
  loading,
  onChange,
  search,
  results,
  validation
}: ISearchInputPorps) => {
  let validationErrorMessage = [] as string[] | string | undefined;
  if (validation) {
    validationErrorMessage = getValidationErrorMessage(validation, value);
  }

  return (
    <Form.Field required={required}>
      {label && <label>{label}</label>}
      <Search
        fluid
        loading={loading}
        onResultSelect={onChange}
        onSearchChange={search}
        results={results}
        value={value}
        validationErrorMessage={validationErrorMessage}
        showNoResults={false}
      />
      <AnimateHeight
        animateOpacity
        duration={300}
        height={!!validationErrorMessage ? 'auto' : 0}
      >
        <Message
          warning
          style={{ margin: '14px 0px 14px 0px', display: 'block' }}
        >
          <p>{validationErrorMessage}</p>
        </Message>
      </AnimateHeight>
      {subtext && <div className="input-sub">{subtext}</div>}
    </Form.Field>
  );
};

export default SearchInput;
