import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Accordion, Icon, Button, Label } from 'semantic-ui-react';
import { getFilterColumns, getFilterItem } from './filterHelper';
import { fundraiserAvailableAdvancedFilters } from '../../../pages/fundraisers/constants';
import { ITEM_TYPES } from './filtersButtonConstants';
import { ISearchFilter } from '../../../models/search/v3/searchFilter';
import { listFilterOperator } from '../../../constants/aggregate';
import AnimateHeight from 'react-animate-height';

interface Ifilter {
  key: string;
  values: {
    displayValue?: string;
    value: any;
  }[];
}

interface ISharedAdvancedFilterProps {
  filter: any;
  listKey: any;
  additionalFilters: any;
  url: string;
  filterLookupBaseOptions: any;
}

interface IAdvancedFilterItemProps extends ISharedAdvancedFilterProps {
  column: any;
  setFilter: (filter: Ifilter) => void;
}

interface IAdvancedFilterProps extends ISharedAdvancedFilterProps {
  metadata: any;
  availableFilters: any;
  searchText: string;
  applyFilters: (filters: ISearchFilter[], searchText: string) => void;
}

const AdvancedFilterItem = (props: IAdvancedFilterItemProps) => {
  const [filter, setFilter] = useState<Ifilter>({
    key: props.column.key,
    values: []
  });

  const onAddEvent = (event: any, value: any) => {
    if (!value) return;
    // for some silly reason lookup calls on change twice. Once for label, then for value
    // lookup assumes that it's 1:1 ratio of data being saved, but in this instances it's 1:many i.e. arrary of data
    // as it's called twice there's no way to determine if it's for the same filter or a new one
    if (item?.type === ITEM_TYPES.LOOKUP) {
      // to cater for this we need to check the target text does NOT match the value
      if (event.target.textContent === value) return;
      // check if item already exists in list
      const existingItem = filter.values.find((f) => f.value === value);
      if (existingItem) return;
      filter.values = [
        ...filter.values,
        { value, displayValue: event.target.textContent }
      ];
    } else {
      // add new value
      filter.values = [...filter.values, { value }];
    }
    // set parent and component states
    setFilter({ ...filter });
    props.setFilter(filter);
  };

  const onRemoveEvent = (value: string) => {
    // remove value i.e. filter out
    filter.values = filter.values.filter((f) => f.value !== value);
    // set parent and component states
    setFilter({ ...filter });
    props.setFilter(filter);
  };

  const item = getFilterItem(
    props.filter?.listKey?.columns,
    props.column,
    props.url,
    props.filter,
    props.additionalFilters,
    props.filterLookupBaseOptions,
    props.listKey,
    true,
    // @ts-ignore
    (event, data) => {
      onAddEvent(event, data.value);
    },
    onAddEvent
  );

  // prettier-ignore
  if (!item || fundraiserAvailableAdvancedFilters.every((x) => x !== item.key)) {
    return null;
  }

  return (
    <div>
      <div>
        <div
          style={{
            position: item.type === ITEM_TYPES.LOOKUP ? 'absolute' : undefined
          }}
        >
          {item.label}
        </div>
        <div>{item.content}</div>
      </div>
      <div>
        {filter.values.map((filter) => (
          <Label
            color="blue"
            style={{ marginTop: '10px', cursor: 'pointer' }}
            onClick={() => onRemoveEvent(filter.value)}
          >
            {filter.displayValue || filter.value}
            <Icon name="delete" color="grey" link />
          </Label>
        ))}
      </div>
    </div>
  );
};

export const AdvancedFilter = (props: IAdvancedFilterProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [canApply, setCanApply] = useState(false);
  const [filters, setFilters] = useState<Ifilter[]>([]);

  const setFilter = (filter: Ifilter) => {
    const index = filters.findIndex((x) => x.key === filter.key);
    if (index === -1) {
      setFilters([...filters, filter]);
      return;
    }
    filters[index].values = filter.values;
    setFilters([...filters]);
  };

  const onApply = () => {
    const searchFilters = filters
      .map((filter) => {
        return {
          key: filter.key,
          operator: listFilterOperator.IN_LIST,
          value: filter.values.map((x) => x.value)
        };
      })
      .filter((x) => x.value.length > 0);
    props.applyFilters(searchFilters, props.searchText);
  };

  useEffect(() => {
    if (!filters || filters.every((x) => x.values.length === 0)) {
      return;
    }
    setCanApply(true);
  }, [filters]);

  const filterColumns = getFilterColumns(
    props.availableFilters,
    props.metadata
  );

  return (
    <Accordion>
      <Accordion.Title
        style={{ width: 'fit-content', marginLeft: 'auto', color: '#70cadb' }}
        active={isExpanded}
        index={0}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        Advanced search
        <Icon name={`angle ${isExpanded ? 'up' : 'down'}`} />
      </Accordion.Title>
      <Accordion.Content active>
        <AnimateHeight
          animateOpacity
          duration={300}
          height={isExpanded ? 'auto' : 0}
        >
          {filterColumns?.map((column: any, index: number) => (
            <AdvancedFilterItem
              key={index}
              column={column}
              setFilter={setFilter}
              {...props}
            />
          ))}
          <div style={{ display: 'flex', paddingTop: '10px' }}>
            <Button
              style={{ marginLeft: 'auto' }}
              primary={canApply}
              disabled={!canApply}
              onClick={onApply}
            >
              Apply
            </Button>
          </div>
        </AnimateHeight>
      </Accordion.Content>
    </Accordion>
  );
};
