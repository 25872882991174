import React from 'react';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { TemplateFormBuilderAnswers } from '../../../components/template/templateFormBuilderAnswers';
import TemplateEditableUpload from '../../../components/template/templateEditableUpload';

import { getMerchandiseContract, templateTabs } from '../ticketingContracts';
import { ticketingValidationPaths } from '../validationModels';
import {
  imageTypes,
  imageFormats,
  entityTypes
} from '../../../constants/imageUpload';

export const getMerchandiseTab = (props) => {
  const {
    id,
    index,
    number,
    onChange,
    getCampaignRequest,
    getCampaignItem,
    required,
    optional,
    formValidation,
    formBuilderAnswers
  } = props;
  const merchandiseContract = getMerchandiseContract(index);

  return (
    <TemplatePage
      id={templateTabs.MERCHANDISE.getTabName(id)}
      i18nKey="ticketing.edit.merchandise.header"
      i18nObject={{ index: number + 1 }}
    >
      <TemplateHeader as="h3" label="ticketing.edit.merchandise.label" />

      <TemplateAutocomplete
        id={`merchandise-${index}-campaignName`}
        entity="page/campaign"
        path={merchandiseContract.campaignId}
        selected="campaign"
        label="ticketing.edit.merchandise.campaign.label"
        placeholder="ticketing.edit.merchandise.campaign.placeholder"
        validation={required}
        onChange={onChange}
        request={getCampaignRequest}
        getItem={getCampaignItem}
      />

      <TemplateInput
        id={`merchandise-${index}-name`}
        path={merchandiseContract.merchandiseName}
        label="ticketing.edit.merchandise.name.label"
        placeholder="ticketing.edit.merchandise.name.placeholder"
        onChange={onChange}
        validation={optional}
      />

      <TemplateCurrency
        id={`merchandise-${index}-amount`}
        path={merchandiseContract.amount}
        validation={optional}
        label="ticketing.edit.merchandise.amount.label"
        allowDecimal
        onChange={onChange}
      />

      <TemplateEditableUpload
        id={merchandiseContract.merchandiseImage}
        path={merchandiseContract.merchandiseImage}
        entity={entityTypes.ORGANIZATION}
        position="left"
        height="350"
        width="350"
        format={imageFormats}
        uploadType={imageTypes.LOGO}
        label="ticketing.edit.merchandise.photo.label"
        validation={optional}
        onChange={onChange}
      />

      <TemplateInput
        id={`merchandise-${index}-firstName`}
        path={merchandiseContract.firstName}
        label="ticketing.edit.invoice-information.first-name.label"
        placeholder="ticketing.edit.invoice-information.first-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.firstName]}
      />

      <TemplateInput
        id={`merchandise-${index}-lastName`}
        path={merchandiseContract.lastName}
        label="ticketing.edit.invoice-information.last-name.label"
        placeholder="ticketing.edit.invoice-information.last-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.lastName]}
      />

      <TemplateInput
        id={`merchandise-${index}-email`}
        path={merchandiseContract.email}
        label="ticketing.edit.invoice-information.email.label"
        placeholder="ticketing.edit.invoice-information.email.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.email]}
      />

      <TemplateFormBuilderAnswers
        id={`merchandise-${index}-templateFormBuilderAnswers`}
        path={merchandiseContract.formbuilder}
        onChange={onChange}
        validation={formBuilderAnswers}
      />
    </TemplatePage>
  );
};
