import React, { useEffect, useState } from 'react';
import { TemplateHeader } from '../../template/templateHeader';
import { TemplateHorizontalRule } from '../../template/templateHorizontalRule';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as templateHelper from '../../template/templateHelper';
import { FormField } from 'semantic-ui-react';

function getInitialFieldsState(fields) {
  if (fields) {
    return fields.reduce((prev, next) => {
      prev[next.label] = next.value;
      return prev;
    }, {});
  }
  return {};
}

function CustomSection(props) {
  const [fields, setFields] = useState(
    getInitialFieldsState(props.campaignFieldValues)
  );

  useEffect(() => {
    const fieldsExist = Object.keys(fields).length > 0;
    if (fieldsExist) {
      const results = Object.keys(fields).map((label) => ({
        label,
        value: fields[label]
      }));
      props.setResultValue('template.value.customSetup.fields', results);
    }
  }, [fields]);

  const onChange = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFields({ ...fields, [name]: value });
  };

  return props.campaignFields && props.campaignFields.length > 0 ? (
    <div>
      <TemplateHorizontalRule />
      <TemplateHeader as="h3" label="Custom" />

      {props.campaignFields.map((campaignField, index) => {
        let defaultValue = '';
        if (
          props.campaignFieldValues &&
          props.campaignFieldValues[index] &&
          props.campaignFieldValues[index].label === campaignField.label
        ) {
          defaultValue = props.campaignFieldValues[index].value;
        }

        return (
          <FormField key={campaignField.label}>
            <label>{campaignField.label}</label>
            <input
              onChange={onChange}
              name={campaignField.label}
              defaultValue={defaultValue}
            />
          </FormField>
        );
      })}
    </div>
  ) : null;
}

const mapState = (state) => {
  return {
    campaignFields: templateHelper.getValue(
      state.templateBuilderEx,
      'organization.template.value.campaignFields'
    ),

    campaignFieldValues: templateHelper.getValue(
      state.templateBuilderEx,
      'template.value.customSetup.fields'
    )
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

export default connect(mapState, mapDispatch)(CustomSection);
