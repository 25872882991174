import { Record } from '../../modules/bin/utility';
import {
  getRecordRequestedHandler,
  getRecordSuccessHandler,
  crudErrorHandler,
  getSearchRecord,
} from '../../modules/bin/entityHandlers';

export const CAMPAIGNS_REQUESTED = 'beamer/CAMPAIGNS_REQUESTED';
export const CAMPAIGNS_SUCCESS = 'beamer/CAMPAIGNS_SUCCESS';
export const CAMPAIGNS_FAILURE = 'beamer/CAMPAIGNS_FAILURE';

const initialState = {
  beamerCampaigns: Record.getDefaultState(),
};

const beamerReducer = (state = initialState, action: any) => {
  let newState = state;
  switch (action.type) {
    case CAMPAIGNS_REQUESTED:
      newState = getRecordRequestedHandler(state, action);
      break;

    case CAMPAIGNS_SUCCESS:
      newState = getRecordSuccessHandler(state, action);
      break;

    case CAMPAIGNS_FAILURE:
      newState = crudErrorHandler(state, action);
      break;

    default:
      return newState;
  }

  return newState;
};

export const getBeamerCampaigns = () => {
  return getSearchRecord(
    "/page/campaign",
    CAMPAIGNS_REQUESTED,
    CAMPAIGNS_SUCCESS,
    CAMPAIGNS_FAILURE,
    {
      page: 1,
      pageSize: 50,
      text: "",
      resultType: "Full",
      tags: {
        event: ["beamer-updates"],
      },
      baseObjectsOnly: true,
    },
    'beamerCampaigns',
    'v3'
  );
};

export default beamerReducer;
