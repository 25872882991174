import React from 'react';
import classNames from 'classnames';
import { Form, Checkbox, Icon } from 'semantic-ui-react';

export default class WizardRadioField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovering: false };
  }

  handleHover() {
    this.setState({ hovering: !this.state.hovering });
  }

  onCheckboxChange = () => {
    if (this.props.onClick) {
      this.props.onClick(this.props.value);
    }
  };

  render() {
    const fieldClassNames = classNames({
      checked: this.props.checked,
      hovered: this.state.hovering
    });

    const icon = (type) => {
      switch (type) {
        case 'textInput':
          return 'comment outline';
        case 'textArea':
          return 'align left';
        case 'checkbox':
          return 'checkmark box';
        case 'dropdown':
          return 'dropdown';
        case 'date':
          return 'calendar outline';
        case 'consent':
          return 'shield';
        case 'phoneNumber':
          return 'phone';
        case 'address':
          return 'marker';
        case 'country':
          return 'world';
        case 'additionalPhoneNumber':
          return 'phone';
        case 'additionalEmailAddress':
          return 'envelope outline';
        case 'groupDivider':
          return 'object group outline';
        default:
      }
    };

    return (
      <Form.Field
        id={`${this.props.value}SelectItem`}
        onMouseEnter={this.handleHover.bind(this)}
        onMouseLeave={this.handleHover.bind(this)}
        className={fieldClassNames}
        onClick={this.onCheckboxChange}
        style={{ cursor: 'pointer' }}
        disabled={this.props.disabled}
      >
        <div className="wizard-item-image">
          <Icon name={icon(this.props.value)} size="large" />
        </div>
        <Checkbox
          label={
            <label>
              <div>{this.props.label}</div>
            </label>
          }
          name={`${this.props.value}wizardRadio`}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.onCheckboxChange}
          radio
        />
        {this.props.children}
      </Form.Field>
    );
  }
}
