import * as entityHandlers from '../../modules/bin/entityHandlers';
import update from '../../helpers/update';
import { CLEAR } from '../../modules/general';
import { gridKeys } from '../../constants/gridKeys';
import { getFundraiserGrid } from './gridConfiguration';
import { Record } from '../../modules/bin/utility';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS,
  IMPERSONATE_ADMIN_SUCCESS
} from '../../modules/session';

export const METADATA_REQUESTED = 'fundraiser/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'fundraiser/METADATA_SUCCESS';
export const METADATA_FAILURE = 'fundraiser/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'fundraiser/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'fundraiser/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'fundraiser/LIST_DATA_FAILURE';

export const GET_EXPORT_FILE_REQUESTED = 'fundraiser/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'fundraiser/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'fundraiser/GET_EXPORT_FILE_FAILURE';

export const ERROR_CLEAR = 'fundraiser/ERROR_CLEAR';

export const TOGGLE_COLUMNS_CHANGE = 'fundraiser/TOGGLE_COLUMNS_CHANGE';

export const toggleColumnsChange = (listKey, toggleColumns) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );

export const getMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'page',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'page',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );
export const getListDataBySearchTerm = (listKey, id, request, searchTerm) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getListData(listKey, id, newRequest, true);
};

// export const getExportFile = (entity, request, fileName, version) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName,
//     version
//   );
// };

const i18nFundraiserListKey = 'fundraiser.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  exportFileRecord: Record.getDefaultState(),
  [gridKeys.FUNDRAISERS]: getFundraiserGrid(i18nFundraiserListKey)
};

// State Reducer
const fundraiserReducer = (state = initialState, action) => {
  let newState;
  switch (action.type) {
    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS:
    case CLEAR: {
      newState = initialState;
      break;
    }
    default:
      newState = state;
      break;
  }

  return newState;
};

export default fundraiserReducer;
