import React from 'react';
import { TemplatePage } from '../../../components/common/pager';
import { paths } from '../constants/template';
import { entityTypes } from '../../../constants/imageUpload';
import { TemplateAbout } from '../../../components/template/templateAbout';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateToogle } from '../../../components/template/templateToggle';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { I18n } from 'react-redux-i18n';
import { Multicurrency } from '../../../components/multicurrency';

export const getAdvancedTab = (props) => {
  const { onChange, ticketingValidation, urlValidation, isSystemAdmin } = props;

  return (
    <TemplatePage id="advanced" i18nKey="event.template.page.advanced.title">
      <TemplateHeader
        as="h3"
        label="event.template.page.advanced.ticketing.header"
        path={paths.TICKETING}
      />

      <TemplateCheckbox
        id={paths.TICKETING}
        path={paths.TICKETING}
        inputLabel="event.template.page.advanced.ticketing.checkbox"
        onChange={onChange}
      />

      <TemplateToogle path={paths.TICKETING} validation={ticketingValidation}>
        <TemplateInput
          id="purchaseTicketUrl"
          path={paths.PURCHASE_TICKET_URL}
          label="event.template.page.advanced.ticketing.label"
          subtext="event.template.page.advanced.ticketing.subtext"
          subtextPosition="bottom"
          onChange={onChange}
          validation={urlValidation}
        />

        <TemplateInput
          path={paths.TICKET_BUTTON_TEXT}
          label="event.template.page.advanced.ticketing.button-label"
          subtext="event.template.page.advanced.ticketing.button-subtext"
          subtextPosition="bottom"
          onChange={onChange}
        />
      </TemplateToogle>

      <TemplateHeader
        as="h3"
        label="event.template.page.advanced.microblogs.header"
        path={paths.ALLOW_MICROBLOGS}
      />

      <TemplateCheckbox
        id={paths.ALLOW_MICROBLOGS}
        path={paths.ALLOW_MICROBLOGS}
        inputLabel="event.template.page.advanced.microblogs.checkbox"
        onChange={onChange}
      />

      <TemplateHeader
        as="h3"
        label="event.template.page.advanced.achievements.header"
        path={paths.ALLOW_ACHIEVEMETNS}
      />

      <TemplateCheckbox
        id={paths.ALLOW_ACHIEVEMETNS}
        path={paths.ALLOW_ACHIEVEMETNS}
        inputLabel="event.template.page.advanced.achievements.checkbox"
        onChange={onChange}
      />

      <TemplateHeader
        as="h3"
        label="event.template.page.advanced.header"
        path="defaultAboutHeader"
      />

      <TemplateHeader
        as="h5"
        label="event.template.page.advanced.default-fundraiser-about.header"
        path={paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES}
      />

      <TemplateAbout
        id="advancedEventDefaultFundraiser"
        path={paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES}
        label="event.template.page.advanced.default-fundraiser-about.label"
        subtext="event.template.page.advanced.default-fundraiser-about.subtext"
        onChange={onChange}
        entity={entityTypes.EVENT}
      />

      <TemplateHeader
        as="h5"
        label="event.template.page.advanced.default-team-about.header"
        path={paths.DEFAULT_TEXT_FOR_TEAM_PAGES}
      />

      <TemplateAbout
        id="advancedEventDefaultTeam"
        path={paths.DEFAULT_TEXT_FOR_TEAM_PAGES}
        label="event.template.page.advanced.default-team-about.label"
        subtext="event.template.page.advanced.default-team-about.subtext"
        onChange={onChange}
        entity={entityTypes.EVENT}
      />

      {isSystemAdmin && (
        <Multicurrency
          type="event"
          onChange={onChange}
        />
      )}

      {/* Special effects */}

      <TemplateHorizontalRule />
      <TemplateHeader
        as="h3"
        label="template.page.content.eyes-only.special-effects.header"
      />
      <TemplateCheckbox
        inputLabel="template.page.content.eyes-only.special-effects.checkbox-enable-confetti"
        path={paths.SPECIAL_EFFECTS_CONFETTI_ENABLED}
        onChange={onChange}
      />
      <div style={{
        marginTop: "-1.5rem"
      }}>
        <p style={{
          marginBottom: "0"
        }}>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description")}</p>
        <ul style={{
          marginTop: "0"
        }}>
          <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.0")}</li>
          {/* <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.1")}</li> */}
          <li>{I18n.t("template.page.content.eyes-only.special-effects.checkbox-enable-confetti-description-list.2")}</li>
        </ul>
      </div>
    </TemplatePage>
  );
};
