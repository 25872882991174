import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

import * as generalActions from '../../../modules/general';
import * as recurringActions from '../reducer';

import { Record } from '../../../modules/bin/utility';
import { Button, Modal } from 'semantic-ui-react';
import { grecaptcha } from '../../../helpers/grecaptcha';

class RefundWindow extends Component {
  onRefundClick = () => {
    const tokenCallback = (token) =>
      this.props.refundDonation(this.props.refundRecord.id, token);
    grecaptcha(tokenCallback, "DonationUpdate");
  };

  onRefundConfirmClose = () => {
    this.props.closeRefundConfirmation();
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  render() {
    const amount = get(this.props.refundRecord, 'amount');

    const alreadyRefunded = get(this.props.refundRecord, 'financials.refunded');
    const isRefunding = Record.isRecordLoading(this.props.saveRecord);

    const warnMessage = `Donation $${amount} already refunded`;

    const isRefunded = this.props.isRefunded;
    if (isRefunded) {
      this.props.generalActions.clearListsReadyState();
      this.props.clearIsRefunded();
    }

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={this.props.refundConfirmation}
      >
        <Modal.Header>Refund confirmation</Modal.Header>

        {!alreadyRefunded && (
          <Modal.Content>
            Are you sure you want to refund ${amount}?
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button
                primary
                onClick={this.onRefundClick}
                style={{ marginRight: '10px' }}
                loading={isRefunding}
                disabled={isRefunding}
              >
                Yes
              </Button>
              <Button onClick={this.onRefundConfirmClose}>No</Button>
            </div>
          </Modal.Content>
        )}
        {alreadyRefunded && (
          <Modal.Content>
            {warnMessage}
            <div
              className="button-wrapper align-right"
              style={{ marginTop: '10px' }}
            >
              <Button primary onClick={this.onRefundConfirmClose}>
                Ok
              </Button>
            </div>
          </Modal.Content>
        )}
      </Modal>
    );
  }
}

const mapState = ({ recurring }) => {
  return {
    saveRecord: recurring.saveRecord,
    refundRecord: recurring.refundDonation,
    isRefunded: recurring.isRefunded,
    isDeleted: recurring.isDeleted,
    refundConfirmation: recurring.refundConfirmation
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(recurringActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const RefundWindowContainer = connect(mapState, mapDispatch)(RefundWindow);
export default RefundWindowContainer;
