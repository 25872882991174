import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Modal, Icon, Button } from 'semantic-ui-react';

// LIBS
import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';

// REDUCERS
import { handleValueChange, securityModalChange } from '../reducer';
import { logOut } from '../../../../modules/session';

// COMPONENTS
import StartTab from './tabs/startTab';
import QRCodeTab from './tabs/qrCodeTab';
import VerificationTab from './tabs/verificationTab';
import BackUpCodesTab from './tabs/backUpCodesTab';
import FinishTab from './tabs/finishTab';
import Stepper from './stepper/stepper';
import { grecaptcha } from '../../../../helpers/grecaptcha';

// CONSTANTS
import { securitySettingTabs } from '../../constants';

class SecurityModalWrapper extends Component {
  handleActiveTabChange = (newTab) => {
    this.props.handleValueChange('MFA.activeTab', newTab);
  };

  handleCloseModal = () => {
    this.props.securityModalChange(false);
  };

  onLogOutClick = () => {
    grecaptcha(this.props.logOut, 'SessionOperation');
    // need to refresh as the previous api calls expired due to the BE returning 401
    // this is expected as the BE only allows for minimal actions during force MFA
    setTimeout(() => window.location.reload(), 2000);
    this.setState({ isLoading: true });
  };

  render() {
    const showStepper =
      this.props.activeTab === securitySettingTabs.QR_CODE ||
      this.props.activeTab === securitySettingTabs.VERIFICATION ||
      this.props.activeTab === securitySettingTabs.BACK_UP_CODES;

    return (
      <Modal
        className="security-modal-container"
        size="tiny"
        dimmer="inverted"
        open={this.props.isOpen}
        onClose={!this.props.forceMfa && this.handleCloseModal}
      >
        <Modal.Header>
          <h3 className="security-modal-title">
            <Translate value={'account.account-security.activateModal.title'} />
          </h3>
        </Modal.Header>
        {!this.props.forceMfa && (
          <Icon
            name="close"
            className="security-modal-close-icon"
            size="large"
            onClick={this.handleCloseModal}
          />
        )}
        {showStepper && <Stepper activeTab={this.props.activeTab} />}
        {this.props.activeTab === securitySettingTabs.START && (
          <StartTab
            handleActiveTabChange={this.handleActiveTabChange}
            forceMfa={this.props.forceMfa}
          />
        )}
        {this.props.activeTab === securitySettingTabs.QR_CODE && (
          <QRCodeTab handleActiveTabChange={this.handleActiveTabChange} />
        )}
        {this.props.activeTab === securitySettingTabs.VERIFICATION && (
          <VerificationTab handleActiveTabChange={this.handleActiveTabChange} />
        )}
        {this.props.activeTab === securitySettingTabs.BACK_UP_CODES && (
          <BackUpCodesTab handleActiveTabChange={this.handleActiveTabChange} />
        )}
        {this.props.activeTab === securitySettingTabs.FINISH && <FinishTab />}
        {this.props.forceMfa && (
          <Button
            className="security-modal-logout"
            onClick={this.onLogOutClick}
            loading={this.state?.isLoading}
          >
            {I18n.t('general.log-out')}
          </Button>
        )}
      </Modal>
    );
  }
}

const mapState = ({ accountSettings }) => {
  return {
    activeTab: get(accountSettings, 'MFA.activeTab'),
    isOpen: get(accountSettings, 'MFA.open'),
    forceMfa: get(accountSettings, 'MFA.forceMfa')
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleValueChange: (path, value) => {
      dispatch(handleValueChange(path, value));
    },
    securityModalChange: (open) => {
      dispatch(securityModalChange(open));
    },
    logOut(verificationToken = null) {
      dispatch(logOut(verificationToken));
    }
  };
};

const SecurityModalWrapperContainer = connect(
  mapState,
  mapDispatch
)(SecurityModalWrapper);
export default SecurityModalWrapperContainer;
