import React from 'react';
import { I18n } from 'react-redux-i18n';

import Panel from '../../../../components/common/grid/managed/panel';
import { Common } from '../../../../components';
import { Dropdown } from 'semantic-ui-react';

import {
  reportTimelineBracketOptions,
  reportByDateAndMetricTypeOptions
} from '../../constants';

export const HeaderContainer = (props) => {
  const { timeBracket, reportByDateAndMetricType } = props;

  const timeBracketRenderOptions = props.timeBracketOptions.map((item) => {
    return {
      key: item,
      value: item,
      text: reportTimelineBracketOptions[item].text
    };
  });

  return (
    <div className="grid-top-block">
      <h1>{I18n.t('dashboard.compare.panel.header')}</h1>
      <Panel>
        <Dropdown
          selection
          options={reportByDateAndMetricTypeOptions}
          value={reportByDateAndMetricType}
          onChange={props.onReportByDateAndMetricTypeChange}
          icon="chevron down"
        />
        {timeBracketRenderOptions.length > 1 && (
          <Dropdown
            selection
            options={timeBracketRenderOptions}
            value={timeBracket}
            onChange={props.onTimeBracketChange}
            icon="chevron down"
          />
        )}
        <Common.Button onClick={props.onRefreshClick} id="refresh">
          {I18n.t('dashboard.compare.panel.refresh.button')}
        </Common.Button>
      </Panel>
    </div>
  );
};
