import React, { Component } from 'react';
import { Button, Form } from 'semantic-ui-react';
import '../../semantic/dist/components/list.css';
import { I18n } from 'react-redux-i18n';
import classNames from 'classnames';

class ToggleColumns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showContext: false
    };

    this.options = [];
    this.toggleColumnsButtonId = require('uuid').v4();
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  changeListVisibility = () => {
    this.setState((prevState) => {
      return {
        showContext: !prevState.showContext
      };
    });
  };

  onToggle = (index) => {
    let options = [...this.props.columns];

    if (
      options.filter((x) => x.visible).length <= 1 &&
      options[index].visible
    ) {
      return this.props.columns;
    }

    options[index].visible = !options[index].visible;
    this.props.onToggleColumnsChage(options);
  };

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  };

  handleClickOutside = (event) => {
    if (
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      event.target.id !== this.toggleColumnsButtonId
    ) {
      this.changeListVisibility();
    }
  };

  render() {
    const { columns } = this.props;
    const disableButon = !columns;

    const contextClass = classNames({
      open: this.state.showContext,
      context: true,
      'toggle-columns-context': true
    });

    return (
      <div className="toggle-columns">
        <Button
          id={this.toggleColumnsButtonId}
          onClick={this.changeListVisibility}
          disabled={disableButon}
        >
          {this.props.shortDescription ? 'Columns' : 'Toggle columns'}
        </Button>
        {this.state.showContext && (
          <div className={contextClass} ref={this.setWrapperRef}>
            <div>
              <Form>
                <Form.Group grouped>
                  {columns.map((item, index) => (
                    <Form.Checkbox
                      key={index}
                      name={item.path}
                      checked={item.visible}
                      onChange={() => this.onToggle(index)}
                      label={I18n.t(`${item.headerKey}`)}
                    />
                  ))}
                </Form.Group>
              </Form>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ToggleColumns;
