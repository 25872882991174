import React from 'react';
import { get, set } from 'lodash';
import { useSelector } from 'react-redux';
import { Button, Form, Label, Popup } from 'semantic-ui-react';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { RootState } from '../../../../store';
import { campaignContract } from '../templates';
import Styles from './fundraising-resources.module.css';
import { v4 } from 'uuid';
import { I18n } from 'react-redux-i18n';
import { useCopyToClipboard } from './hooks';
import {
  FundraisingResourceEditItem,
  FundraisingResourceItem,
  ResourceType
} from './components';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult
} from 'react-beautiful-dnd';

const i18Key = 'template.page.content.fundraising.resources';
const LabelPaths = {
  HeaderText: `${i18Key}.header.text`,
  HeaderDescription: `${i18Key}.header.description`,
  CopyButtonText: `${i18Key}.copy-button.text`,
  AddButtonText: `${i18Key}.buttons.create`,
  ResourcesButtonTextInput: `${i18Key}.resources-button-input.text`,
  ResourcesButtonTextDescription: `${i18Key}.resources-button-input.description`
};

export interface IResourceUpload {
  url: string;
  file?: any;
}
export interface IResource {
  id: string;
  type: 'pdf' | 'url';
  resourceType?: ResourceType;
  deliveryType?: string;
  placeholder?: IResourceUpload;
  resource?: IResourceUpload;
  name?: string;
  description?: string;
}

interface FundraisingResourcesViewProps<Value = any> {
  path?: string; // componenet needs a path for the template page
  onChange?(path: string, values: Value): void;
  isSystemAdmin?: boolean;
}

interface FundraisingResourceEditState<Values = any> {
  index: number;
  values: Values;
}

export const FundraisingResourcesView: React.FunctionComponent<
  FundraisingResourcesViewProps
> = (props) => {
  const [editState, setEditState] =
    React.useState<FundraisingResourceEditState | null>();
  const campaign = useSelector<RootState, any>(
    (state) => state?.templateBuilderEx?.data
  );
  const url = campaign.urlFull ? `${campaign.urlFull}/resources` : '';
  const values = get<any[]>(
    campaign,
    campaignContract.fundraisersResourcesItems,
    []
  );
  const resourcesPageButtonText = get(
    campaign,
    campaignContract.fundraisersResourcesPageButtonText
  );
  React.useEffect(() => {
    if (typeof resourcesPageButtonText !== 'string') {
      props.onChange?.(
        campaignContract.fundraisersResourcesPageButtonText,
        'Fundraising resources'
      );
    }
  }, []);

  const { copy } = useCopyToClipboard(url);

  const handleOnEdit = React.useCallback(
    (index: number, values: any) => {
      setEditState({
        index,
        values
      });
    },
    [setEditState]
  );

  const handleOnChange = React.useCallback(
    (index: number, path: string, value: any) => {
      if (!editState || index !== editState.index) return;
      setEditState((state: any) => ({
        ...state,
        values: set(state.values, path, value)
      }));
    },
    [editState, setEditState]
  );

  const handleOnSave = React.useCallback(() => {
    if (props.onChange && editState) {
      props.onChange(
        `${campaignContract.fundraisersResourcesItems}.${editState.index}`,
        editState.values
      );
    }
    setEditState(null);
  }, [editState, setEditState, props.onChange]);

  const handleOnDelete = React.useCallback(
    (index: number) => {
      if (!editState || index !== editState.index) return;

      if (props.onChange) {
        const items = JSON.parse(JSON.stringify(values || []));
        items.splice(index, 1);
        props.onChange(campaignContract.fundraisersResourcesItems, items);
      }
      setEditState(null);
    },
    [editState, setEditState, props.onChange, values]
  );

  const handleOnAdd = React.useCallback(() => {
    const item: IResource = {
      id: v4(),
      type: 'pdf',
      deliveryType: 'downloadToDevice',
      resourceType: 'upload'
    };
    const items = JSON.parse(JSON.stringify(values || [])) as any[];
    setEditState({ index: items.length, values: item });
    if (props.onChange) {
      props.onChange(campaignContract.fundraisersResourcesItems, [
        ...items,
        item
      ]);
    }
  }, [editState, setEditState, props.onChange, values]);

  const handleOnDragEnd = React.useCallback(
    (result: DropResult) => {
      if (!result.destination) return;

      // reorder list
      const items = JSON.parse(JSON.stringify(values || []));
      const [removed] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, removed);

      // if editing the dragged item
      if (
        editState?.index === result.source.index &&
        typeof result.destination?.index === 'number'
      ) {
        setEditState(
          (state) =>
            ({
              ...state,
              index: result.destination?.index
            } as any)
        );
      }

      if (props.onChange) {
        props.onChange(campaignContract.fundraisersResourcesItems, items);
      }
    },
    [editState, setEditState, values, props.onChange]
  );

  const handleResourcesPageButtonTextChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, { value }) => {
      props.onChange?.(
        campaignContract.fundraisersResourcesPageButtonText,
        value
      );
    },
    [props.onChange]
  );

  return (
    <div className={Styles.ResourcesContainer}>
      <div className={Styles.ResourcesHeader}>
        <TemplateHeader as="h3" label={LabelPaths.HeaderText} />
        <div>{I18n.t(LabelPaths.HeaderDescription)}</div>
        {url ? (
          <Popup
            basic
            content="Copied"
            on="click"
            position="top center"
            trigger={
              <Button as="div" labelPosition="left" onClick={copy}>
                <Label as="a" basic pointing="right" style={{ flex: 1 }}>
                  {url}
                </Label>
                <Button icon>{I18n.t(LabelPaths.CopyButtonText)}</Button>
              </Button>
            }
          />
        ) : null}
        <div>
          {props.isSystemAdmin ? (
            <>
              <Form.Field>
                <Form.Input
                  label={I18n.t(LabelPaths.ResourcesButtonTextInput)}
                  value={resourcesPageButtonText}
                  onChange={handleResourcesPageButtonTextChange}
                />
              </Form.Field>
              <br />
            </>
          ) : null}
          <Button primary disabled={!!editState} onClick={handleOnAdd}>
            {I18n.t(LabelPaths.AddButtonText)}
          </Button>
        </div>
      </div>

      {editState ? (
        <FundraisingResourceEditItem
          {...editState}
          onClose={handleOnSave}
          onChange={handleOnChange}
          onDelete={handleOnDelete}
        />
      ) : null}

      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="resources-list">
          {(provided, snapshot) => (
            <div
              className={Styles.ResourcesList}
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {values
                ? values.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={item.id}
                      index={index}
                    >
                      {(providedDragable, snapshotDragable) => (
                        <div
                          ref={providedDragable.innerRef}
                          {...providedDragable.draggableProps}
                          {...providedDragable.dragHandleProps}
                        >
                          <FundraisingResourceItem
                            key={item.id}
                            item={item}
                            onEdit={
                              !editState
                                ? () => handleOnEdit(index, item)
                                : undefined
                            }
                          />
                        </div>
                      )}
                    </Draggable>
                  ))
                : null}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
};

export const FundraisingResourcesBuilder: React.FunctionComponent<
  FundraisingResourcesViewProps
> = (props) => {
  return <FundraisingResourcesView {...props} />;
};
