import React, { useEffect, useState } from 'react';
import SettingsManager, {
  SETTINGS_KEYS
} from '../../../modules/bin/settingsManager';
import { get } from 'lodash';
import {
  getCheckedFilter,
  getFilterColumns
} from '../../../components/common/filter/filterHelper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store';
import { archivedFilters } from '../constants';

const getValue = () =>
  SettingsManager.getSettingsByKey(SETTINGS_KEYS.FILTER, 'displayArchived');

interface ICustomGridOptionsProps {
  applyFilters: (
    filters: any,
    searchText: string,
    ignoreBaseFilter: string[]
  ) => void;
  applyAggregates?: () => void;
  listKey: string;
  filter: any;
  metadata: any;
  availableFilters: string[];
  searchText: string;
}

export const CustomGridOptions = (props: ICustomGridOptionsProps) => {
  const [campaignEnabled, setCampaignEnabled] = useState(getValue());
  const filterState = useSelector((state: RootState) => state.filter);

  useEffect(() => {
    function storageEventHandler() {
      setCampaignEnabled(getValue() === true);
    }
    // Hook up the event handler
    window.addEventListener('storage', storageEventHandler);
    return () => {
      // Remove the handler when the component unmounts
      window.removeEventListener('storage', storageEventHandler);
    };
  }, []);

  const onArchiveClick = () => {
    SettingsManager.setSetting(
      SETTINGS_KEYS.FILTER,
      'displayArchived',
      !campaignEnabled
    );

    const checkedFilters: any[] = [];
    const { availableFilters, metadata } = props;
    const columns = get(filterState, `${props.listKey}.columns`);
    const filterColumns = getFilterColumns(availableFilters, metadata)
      ?.map((x: any) => {
        const filterData: any = get(columns, `${x.key}`);
        return filterData?.checked ? { ...filterData, key: x.key } : undefined;
      })
      .filter((x: any) => x !== undefined);

    filterColumns?.forEach((column: any) => {
      const checkedFilter = getCheckedFilter(column);

      checkedFilters.push(...checkedFilter);
    });

    const ignoreBaseFilters = !campaignEnabled ? archivedFilters : [];
    props.applyFilters(checkedFilters, props.searchText, ignoreBaseFilters);
    props.applyAggregates && props.applyAggregates();
  };

  return (
    <p className="Links" style={{ cursor: 'pointer' }} onClick={onArchiveClick}>
      {campaignEnabled ? 'Hide archived' : 'Display archived'}
    </p>
  );
};
