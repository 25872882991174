import React, { useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Dropdown, Form, Input } from 'semantic-ui-react';

import { Common } from '../../../components';
import { tapNdonateModels } from '../constants/tapNdonateModels';
import { ITerminalMetadata } from '../../../models/Terminal';

interface IDeviceInputProps {
  inputData?: ITerminalMetadata;
  confirmValue?: string;
  onConfirm?: (data: ITerminalMetadata) => void;
  onChange?: (data: ITerminalMetadata) => void;
}

export const DeviceInput = ({
  inputData,
  confirmValue,
  onConfirm,
  onChange
}: IDeviceInputProps) => {
  const [canSave, setCanSave] = useState(false);
  const [inputState, setInputState] = useState<ITerminalMetadata>({
    id: inputData?.id || Math.random(),
    serialNumber: inputData?.serialNumber || '',
    label: inputData?.label,
    model: inputData?.model || tapNdonateModels[0].value,
    organizationId: inputData?.organizationId,
    campaignId: inputData?.campaignId
  });

  const onConfirmClick = () => {
    const newTerminal = {
      id: inputState.id || Math.random(),
      serialNumber: inputState.serialNumber,
      label: inputState.label,
      model: inputState.model,
      organizationId: inputState.organizationId,
      campaignId: inputState.campaignId
    };
    onConfirm && onConfirm(newTerminal);
    setInputState({
      id: Math.random(),
      serialNumber: '',
      label: '',
      model: tapNdonateModels[0].value
    });
  };

  const onSerialChange = (data: any) => {
    const model = tapNdonateModels.find((x) => x.value === inputState?.model);
    if (model) {
      setCanSave(model.regex.test(data.value));
    }
    onInputchange(null, data);
  };

  const onInputchange = (_event: any, data: any) => {
    const newTerminal = {
      ...inputState,
      [data.name]: data.value
    };
    onChange && onChange(newTerminal);
    setInputState(newTerminal);
  };

  return (
    <Form className="terminal-input-form device-input">
      <Form.Field width={16} required>
        <label>
          <Translate value="terminal.add-modal.id" />
        </label>
        <Translate value="terminal.add-modal.id-description" />

        <Input
          name="serialNumber"
          placeholder={I18n.t(
            `terminal.add-modal.placeholders.${inputState.model}`
          )}
          value={inputState.serialNumber}
          onChange={(_, data) => onSerialChange(data)}
        />
      </Form.Field>
      <Form className="row">
        <Form.Field width={8}>
          <label>
            <Translate value="terminal.add-modal.label" />
          </label>
          <Input
            name="label"
            value={inputState.label}
            onChange={onInputchange}
          />
        </Form.Field>
        <Form.Field width={8}>
          <label>
            <Translate value="terminal.add-modal.model" />
          </label>
          <Dropdown
            selection
            name="model"
            defaultValue={inputState.model}
            onChange={onInputchange}
            options={tapNdonateModels.map((option) => ({
              key: option.value,
              text: option.value,
              value: option.value
            }))}
          />
        </Form.Field>
      </Form>
      {onConfirm && confirmValue && (
        <Common.Button
          primary
          className="device-input-button"
          disabled={!canSave}
          content={<Translate value={confirmValue} />}
          onClick={onConfirmClick}
        />
      )}
    </Form>
  );
};
