import React, { Component } from 'react';
import { Button } from 'semantic-ui-react';
import Spinner from './spinner';
import ErrorPanel from './errorPanel';
import { Link } from 'react-router-dom';
import RecordResultMessage from './recordResult';
import * as successActions from '../../modules/success';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

class BasePageView extends Component {
  clearMessages = () => {
    this.props.clearMessages();
  };

  render() {
    if (this.props.isLoading) {
      return <Spinner />;
    } else if (this.props.isLoadingError) {
      return (
        <ErrorPanel title="Oops! Could not load page data, please try later again...">
          <Link to={this.props.backLink} push>
            <Button primary>{this.props.backLinkTitle}</Button>
          </Link>
        </ErrorPanel>
      );
    } else {
      return (
        <div>
          <RecordResultMessage
            record={this.props.record}
            onDismiss={this.clearMessages}
          />
          {this.props.children}
        </div>
      );
    }
  }
}

const mapState = (state, ownProps) => {
  return {
    record: state.success.record
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(successActions, dispatch);
};

const BasePage = connect(mapState, mapDispatch)(BasePageView);
export default BasePage;
