import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import { MerchandiseItemLabels, SkuItemLabels } from './merchandise.constants';
import Styles from './merchandise.module.css';

export interface MerchandiseDeleteButtonProps {
  onSubmit?: () => void;
}

export function useMerchandiseDeleteButton(
  props: MerchandiseDeleteButtonProps
) {
  const [open, setOpen] = React.useState<boolean>(false);

  const handleOpen = React.useCallback(() => setOpen(true), [setOpen]);

  const handleClose = React.useCallback(() => setOpen(false), [setOpen]);

  const handleSubmit = React.useCallback(() => {
    handleClose();
    if (props.onSubmit) props.onSubmit();
  }, [props.onSubmit, handleClose]);

  return {
    open,
    onOpen: handleOpen,
    onClose: handleClose,
    onSubmit: handleSubmit
  };
}

export const MerchandiseDeleteButton: React.FunctionComponent<
  MerchandiseDeleteButtonProps
> = (props) => {
  const { onSubmit, ...args } = useMerchandiseDeleteButton(props);

  return (
    <Modal
      {...args}
      size="tiny"
      trigger={
        <Button basic color="red">
          {SkuItemLabels.BtnDelete}
        </Button>
      }
    >
      <Modal.Header className={Styles.ModalHeader}>
        {MerchandiseItemLabels.DeleteModalHeader}
      </Modal.Header>
      <Modal.Content className={Styles.ModalContent}>
        <Modal.Description className={Styles.ModalDescription}>
          {MerchandiseItemLabels.DeleteModalDescription}
        </Modal.Description>
        <Modal.Actions className={Styles.ModalButtons}>
          <Button basic onClick={args.onClose}>
            {MerchandiseItemLabels.DeleteModalCancel}
          </Button>
          <Button color="red" onClick={onSubmit}>
            {MerchandiseItemLabels.DeleteModalDelete}
          </Button>
        </Modal.Actions>
      </Modal.Content>
    </Modal>
  );
};
