import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { Record } from '../../../modules/bin/utility';

import clear from '../../../components/clear';
import EventTemplate from '../templateEx';
import RecordResultMessage from '../../../components/common/recordResult';
import { paths } from '../constants/template';
import { getDefaultFundraiserAndTeamAboutElements } from '../../../helpers/pageAboutHelper';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as eventActions from '../store/eventActions';
import config from '../../../constants/index';
import BasePage from '../../../components/common/basePageView';
import {
  individualAboutDefaultText,
  teamAboutDefaultText
} from '../../campaign/constants';
import { campaignContract } from '../../campaign/create/templates';
import { template } from './template';

class EventCreate extends Component {
  onSave = (data) => {
    this.props.createRecord(data);
  };

  initTemplate = () => {
    let model = template();

    //fitness
    model.hide(campaignContract.fitnessAllowedTypes);
    model.hide(campaignContract.fitnessTargets);
    model.hide('fitnessFundraiserDates');
    model.hide('fitnessFundraiserDistanceTarget');
    model.hide('fitnessFundraiserTimeTarget');
    model.hide('fitnessFundraiserNumberOfStepsTarget');
    model.hide('fitnessTeamDistanceTarget');
    model.hide('fitnessTeamTimeTarget');
    model.hide('fitnessTeamNumberOfStepsTarget');
    model.hide(campaignContract.fitnessAllowedTrackedStatistics);
    model.hide(campaignContract.fitnessThermometersToShow);
    model.hide(campaignContract.fitnessLeaderboardsToShow);
    model.hide(campaignContract.fitnessAllowedPlatforms);
    model.hide(campaignContract.allowPageTracking);
    model.hide('fitnessSetup');

    // time and place
    model.hide('dateAndTime');
    model.hide('timeAndPlace.time.year');
    model.hide('eventLocation');
    model.hide(campaignContract.timeDetails);
    model.hide(campaignContract.place);

    // custom activities fields
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_NAME);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_UNIT);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_LEADERBOARD_ENABLED);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED);
    model.hide(paths.CUSTOM_ACTIVITIES_VALUE1_SVG);

    const defaultFundraiserAbout = getDefaultFundraiserAndTeamAboutElements(
      individualAboutDefaultText
    );
    model.setValue(
      paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES,
      defaultFundraiserAbout
    );

    const defaultTeamAbout =
      getDefaultFundraiserAndTeamAboutElements(teamAboutDefaultText);
    model.setValue(paths.DEFAULT_TEXT_FOR_TEAM_PAGES, defaultTeamAbout);

    this.props.setTemplateModel(model.get());
  };

  clearMessages = () => {
    this.props.clearRecordMessages('recordCreate');
  };

  goToEventsView = () => {
    this.props.history.push(config.DEFAULT_EVENTS_URL);
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.recordCreate);
    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }
    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToEventsView}
        />

        <h1>
          <Translate value="event.template.create-header" />
        </h1>
        <EventTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

const mapState = ({ event, templateBuilderEx }) => {
  return {
    recordCreate: event.recordCreate,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(eventActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch)
  };
};

const CreateEventContainer = clear(connect(mapState, mapDispatch)(EventCreate));
export default CreateEventContainer;
