import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Loader, Form } from 'semantic-ui-react';

import * as templateHelper from './templateHelper';

class ManagedDynamicText extends Component {
  render() {
    const { loading, value, text, className, visible } = this.props;
    const style = {
      marginLeft: '10px'
    };
    return (
      <Form.Field className={className}>
        {visible !== false && (
          <React.Fragment>
            <span style={style}>{text}</span>
            {loading && <Loader active inline size="tiny" />}
            {!loading && <span>{value}</span>}
          </React.Fragment>
        )}
      </Form.Field>
    );
  }
}

const mapState = (state, ownProps) => {
  const text = templateHelper.getTranslation(ownProps.label);

  return {
    text,
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    visible: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'visible'
    )
  };
};

export const TemplateDynamicText = connect(mapState)(ManagedDynamicText);
