import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';

// LIBS
import QRCode from 'qrcode.react';
import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';

// REDUCERS
import { getQRCode } from '../../reducer';

// HELPERS
import { Record } from '../../../../../modules/bin/utility';
import { grecaptcha } from '../../../../../helpers/grecaptcha';

// COMPONENTS
import Spinner from '../../../../../components/common/spinner';

// CONSTANTS
import { securitySettingTabs } from '../../../constants';

class QRCodeTab extends Component {
  constructor(props) {
    super(props);
    const tokenCallback = (token) =>
      this.props.getQRCode(this.props.accountId, token);
    grecaptcha(tokenCallback, 'AccountMultiFactor');
  }

  handleNextButtonClick = () => {
    this.props.handleActiveTabChange(securitySettingTabs.VERIFICATION);
  };

  render() {
    const showQRCode = Record.isRecordReady(this.props.qrCodeRecord);
    const otpauth = get(this.props.qrCodeRecord, 'data');

    return (
      <Modal.Content>
        {!showQRCode && <Spinner />}
        {showQRCode && <QRCode value={otpauth} />}
        <h3
          className={`security-sub-title ${
            !showQRCode ? 'add-spinner-spacing' : ''
          }`}
        >
          <Translate
            value={'account.account-security.activateModal.steps.qrCode.title'}
          />
        </h3>
        <div className="security-content">
          <span className="qr-code-content">
            <Translate
              value={
                'account.account-security.activateModal.steps.qrCode.content'
              }
            />
          </span>
        </div>
        <Button
          primary
          className="next-button"
          onClick={this.handleNextButtonClick}
          disabled={!showQRCode}
        >
          <Translate
            value={'account.account-security.activateModal.steps.qrCode.button'}
          />
        </Button>
      </Modal.Content>
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  const accountId = get(session, 'account.id');
  return {
    accountId: accountId,
    qrCodeRecord: accountSettings.qrCodeRecord,
    activeTab: get(accountSettings, 'MFA.activeTab')
  };
};

const mapDispatch = (dispatch) => {
  return {
    getQRCode: (accountId, verificationToken) => {
      dispatch(getQRCode(accountId, verificationToken));
    }
  };
};

export default connect(mapState, mapDispatch)(QRCodeTab);
