import React from 'react';
import ViewIconItem from '../../../components/layouts/viewIconItem';
import { pageTypesStrings } from '../../../constants/pageTypes';

interface IILinkedEntity {
  type: pageTypesStrings;
  imageUrl?: string;
  data?: {
    id: string | number;
    name: string;
    amount: string | number;
    urlFull: string;
  };
}

interface ILinkedEntitiesProps {
  entities: IILinkedEntity[];
}

export const LinkedEntities = ({ entities }: ILinkedEntitiesProps) => {
  return (
    <>
      {entities?.map((entity, index) => (
        <ViewIconItem
          key={`${index}-${entity.type}`}
          type={entity.type}
          data={entity.data}
          imageUrl={entity.imageUrl}
        />
      ))}
    </>
  );
};
