import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import * as accountActions from './store/accountActions';
import { setRedirectUrl } from '../../modules/session';
import { Common } from '../../components';
import ContextMenu from '../../components/common/grid/contextMenu';
import { Button, Menu, Table } from 'semantic-ui-react';
import AccountStatus from './status';
import './index.css';
import { tabs, i18nKeys, adminColumns } from './constants';
import { bindActionCreators } from 'redux';
import { Record } from '../../modules/bin/utility';
import Spinner from '../../components/common/spinner';
import InviteAdmin from './inviteAdmin';
import RecordResultMessage from '../../components/common/recordResult';
import clear from '../../components/clear';
import { impersonateAdmin } from '../../modules/session';
import * as generalActions from '../../modules/general';
import { gridKeys } from '../../constants/gridKeys';
import get from 'lodash.get';
import { hideCondition } from '../../helpers/hideHelper';
import {
  getListWithCustomFilterIfSysAdmin,
  getListWithOrgFilterIfSysAdmin,
  getListWithSystemAdminFilterIfSysAdmin
} from '../../helpers/listHelper';
import { withRouter } from 'react-router-dom';
import BasePage from '../../components/common/basePageView';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import config from '../../constants';
import {
  IS_EVENT_ADMIN,
  IS_EVENT_ORGANIZER_ADMIN
} from '../../constants/metadataConstants';
import { FeatureFlag } from '../../modules/featureflags';
import { grecaptcha } from '../../helpers/grecaptcha';
import { ExportDownloadProvider } from '../export-history';

class AccountsPage extends Component {
  componentDidMount() {
    this.props.setView(this.props.match.params.tab);
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.needGeneralRedirect) {
      this.generalRedirect();
      return false;
    }

    return true;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.tab !== this.props.match.params.tab) {
      this.props.clearListsState();
      this.props.setView(this.props.match.params.tab);
    }
  }

  handleMenuItemClick = (e, { name }) => {
    this.props.history.push(`/accounts/${name}`);
  };

  isViewSelected(view) {
    return this.props.selectedView === view;
  }

  onToggleColumnsChage = (toggleColumns) => {
    this.props.toggleColumnsChage(toggleColumns);
  };

  getExportValues = () => {
    if (this.isViewSelected(tabs.ALL_USERS)) {
      return this.props.allUsersList.request;
    }
    if (this.isViewSelected(tabs.ADMINS)) {
      return this.props.adminslist.request;
    }
    if (this.isViewSelected(tabs.FUNDRAISERS)) {
      return this.props.fundraiserslist.request;
    }
    if (this.isViewSelected(tabs.DONORS)) {
      return this.props.donorsList.request;
    }
    return {};
  };

  clearMessages = () => {
    this.props.clearRecordMessages('inviteAdminRecord');
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.adminslist) ||
      Record.isRecordLoading(this.props.fundraiserslist) ||
      Record.isRecordLoading(this.props.donorsList) ||
      (this.props.isSystemAdmin &&
        Record.isRecordLoading(this.props.allUsersList))
    );
  };

  getTabsButtons = () => {
    const isAllUsersView = this.isViewSelected(tabs.ALL_USERS);
    const isAdminView = this.isViewSelected(tabs.ADMINS);
    const isFundraisersView = this.isViewSelected(tabs.FUNDRAISERS);
    const isDonorsView = this.isViewSelected(tabs.DONORS);

    const isChooseTabButtonDisabled = this.isChooseTabButtonDisabled();

    return (
      <Menu
        className="accounts-tab-buttons-group"
        style={{ margin: '0px', borderRight: '0px' }}
      >
        {this.props.isSystemAdmin && (
          <Menu.Item
            name={tabs.ALL_USERS}
            active={isAllUsersView}
            disabled={isChooseTabButtonDisabled}
            onClick={this.handleMenuItemClick}
          >
            <Translate value={i18nKeys.ALL_USERS_TAB_TITLE} />
          </Menu.Item>
        )}
        <Menu.Item
          name={tabs.ADMINS}
          active={isAdminView}
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.ADMINS_TAB_TITLE} />
        </Menu.Item>
        <Menu.Item
          name={tabs.FUNDRAISERS}
          active={isFundraisersView}
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.FUNDRAISERS_TAB_TITLE} />
        </Menu.Item>
        <Menu.Item
          name={tabs.DONORS}
          active={isDonorsView}
          disabled={isChooseTabButtonDisabled}
          onClick={this.handleMenuItemClick}
        >
          <Translate value={i18nKeys.DONORS_TAB_TITLE} />
        </Menu.Item>
      </Menu>
    );
  };

  onInvitePanelDismiss = () => {
    this.props.changeInviteAdminPanelVisibility(false);
  };

  onInvitePanelOpen = () => {
    this.props.changeInviteAdminPanelVisibility(true);
  };

  getPanel = () => {
    const { Grid } = Common;
    const { Panel } = Grid.Managed;

    let entity = 'account';
    const view = this.props.selectedView;
    if (view === 'fundraisers') {
      entity += '/fundraiser';
    } else if (view === 'donors') {
      entity += '/donor';
    }

    return (
      <Panel name="right">
        <FeatureFlag feature="exportData">
          <ExportDownloadProvider entity={entity} version="v3">
            {({ open }) => (
              <Button onClick={() => open(this.getExportValues())}>
                Export
              </Button>
            )}
          </ExportDownloadProvider>
        </FeatureFlag>
        {this.isViewSelected(tabs.ADMINS) && (
          <Button
            id="inviteAdminButton"
            primary
            onClick={this.onInvitePanelOpen}
          >
            Invite admin
          </Button>
        )}
      </Panel>
    );
  };

  /**
   * Custom column renderer for Grid.Managed
   * @param {string} column - column metadata
   * @param {string} value - column value to be displayed
   */
  columnRender(column, value) {
    if (column.id === adminColumns.STATUS) {
      return (
        <Table.Cell>
          <AccountStatus value={value} />
        </Table.Cell>
      );
    }
    if (column.id === adminColumns.ROLES) {
      return (
        <Table.Cell>
          <span className="capitalize">{value}</span>
        </Table.Cell>
      );
    }
  }

  onClickImpersonate = (id) => {
    const tokenCallback = (token) => this.props.impersonateAdmin(id, token);
    grecaptcha(tokenCallback, 'SessionImpersonate');
  };

  isSysAdmin = (item) => {
    return item.isSysAdmin;
  };

  getContextMenu = (isAdminView = false) => {
    const canImpersonate = isAdminView && this.props.isSystemAdmin;
    const params = {
      selectedView: this.props.selectedView
    };

    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.ACCOUNT.VIEW_PAGE.key}
          label={I18n.t(i18nKeys.VIEW_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.ACCOUNT.VIEW_PAGE}
          params={params}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.ACCOUNT.EDIT_PAGE.key}
          label={I18n.t(i18nKeys.EDIT_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.ACCOUNT.EDIT_PAGE}
          onClick={() =>
            this.props.setRedirectUrl(this.props.history.location.pathname)
          }
          params={params}
        />
        {canImpersonate && (
          <ContextMenu.Item
            key={CONTEXT_MENU_KEYS.ACCOUNT.IMPERSONATE.key}
            label={I18n.t(i18nKeys.IMPERSONATE_ACTION)}
            onClick={this.onClickImpersonate}
            isHiden={this.isSysAdmin}
          />
        )}
      </ContextMenu>
    );
  };

  getInviteAdminPanel = () => {
    const { Grid } = Common;
    const { CenterPanel } = Grid.Managed;
    return (
      <CenterPanel>
        <InviteAdmin onDismissClick={this.onInvitePanelDismiss} />
      </CenterPanel>
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  generalRedirect = () => {
    this.props.history.push(config.DEFAULT_CAMPAIGNS_URL);
  };

  render() {
    if (
      Record.isRecordLoading(this.props.exportFileRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord)
    ) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const panel = this.getPanel();
    const tabsButtons = this.getTabsButtons();
    const isAllUsersView = this.isViewSelected(tabs.ALL_USERS);
    const isAdminView = this.isViewSelected(tabs.ADMINS);
    const isFundraisersView = this.isViewSelected(tabs.FUNDRAISERS);
    const isDonorsView = this.isViewSelected(tabs.DONORS);
    const contextMenu = this.getContextMenu(isAdminView);
    const centerPanel = this.getInviteAdminPanel();

    const allUserListWithEventAdmin = getListWithCustomFilterIfSysAdmin(
      this.props.allUsersList,
      this.props.isSystemAdmin,
      IS_EVENT_ADMIN
    );
    const allUserListWithEventOrganizerAdmin =
      getListWithCustomFilterIfSysAdmin(
        allUserListWithEventAdmin,
        this.props.isSystemAdmin,
        IS_EVENT_ORGANIZER_ADMIN
      );
    const allUsersListWithSystemAdminFilter =
      getListWithSystemAdminFilterIfSysAdmin(
        allUserListWithEventOrganizerAdmin,
        this.props.isSystemAdmin
      );
    const allUsersListWithOrgFilter = getListWithOrgFilterIfSysAdmin(
      allUsersListWithSystemAdminFilter,
      this.props.isSystemAdmin
    );

    const adminUserListWithEventAdmin = getListWithCustomFilterIfSysAdmin(
      this.props.adminslist,
      this.props.isSystemAdmin,
      IS_EVENT_ADMIN
    );
    const adminUserListWithEventOrganizerAdmin =
      getListWithCustomFilterIfSysAdmin(
        adminUserListWithEventAdmin,
        this.props.isSystemAdmin,
        IS_EVENT_ORGANIZER_ADMIN
      );
    const adminListWithSystemAdminFilter =
      getListWithSystemAdminFilterIfSysAdmin(
        adminUserListWithEventOrganizerAdmin,
        this.props.isSystemAdmin
      );
    const adminListWithOrgFilter = getListWithOrgFilterIfSysAdmin(
      adminListWithSystemAdminFilter,
      this.props.isSystemAdmin
    );

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.inviteAdminRecord}
          onDismiss={this.clearMessages}
        />
        <RecordResultMessage
          record={this.props.impersonatedRecord}
          onDismiss={() => this.props.clearRecordMessages('impersonatedRecord')}
        />
        <h1>
          <Translate value={i18nKeys.ACCOUNT_TITLE} />
        </h1>
        {tabsButtons}
        {isAllUsersView && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_ALL_USERS}
            list={allUsersListWithOrgFilter}
            actions={this.props.allUsersActionGrid}
            search
            toggleColumns
            columnRender={this.columnRender}
            filtersButton
            hideColumn={this.hideColumnCondition}
          >
            {panel}
            {centerPanel}
            {contextMenu}
          </Grid.Managed>
        )}
        {isAdminView && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_ADMINS}
            list={adminListWithOrgFilter}
            actions={this.props.adminsActionGrid}
            search
            toggleColumns
            columnRender={this.columnRender}
            filtersButton
            hideColumn={this.hideColumnCondition}
          >
            {panel}
            {centerPanel}
            {contextMenu}
          </Grid.Managed>
        )}
        {isFundraisersView && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_FUNDRAISERS}
            list={this.props.fundraiserslist}
            actions={this.props.fundraisersActionGrid}
            search
            toggleColumns
            filtersButton
            hideColumn={this.hideColumnCondition}
          >
            {panel}
            {contextMenu}
          </Grid.Managed>
        )}
        {isDonorsView && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_DONORS}
            list={this.props.donorsList}
            actions={this.props.donorsActionGrid}
            search
            toggleColumns
            columnRender={this.columnRender}
            filtersButton
            hideColumn={this.hideColumnCondition}
          >
            {panel}
            {contextMenu}
          </Grid.Managed>
        )}
      </BasePage>
    );
  }
}

const mapState = ({ account, session }) => {
  const allUsersList = get(account, gridKeys.ACCOUNT_ALL_USERS);
  const donorsList = get(account, gridKeys.ACCOUNT_DONORS);
  const adminsList = get(account, gridKeys.ACCOUNT_ADMINS);
  const fundraisersList = get(account, gridKeys.ACCOUNT_FUNDRAISERS);

  return {
    allUsersList: allUsersList,
    donorsList: donorsList,
    adminslist: adminsList,
    fundraiserslist: fundraisersList,
    selectedView: account.selectedView,
    exportFileRecord: account.exportFileRecord,
    inviteAdminRecord: account.inviteAdminRecord,
    isSystemAdmin: session.isSystemAdmin,
    impersonatedRecord: account.impersonatedRecord,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    needGeneralRedirect: get(session, 'needGeneralRedirect')
  };
};

const mapDispatch = (dispatch) => {
  var allUsersActions = {
    getMetadata: accountActions.getMetadata,
    getListData: accountActions.getListData,
    getListDataBySearchTerm: accountActions.getListDataBySearchTerm,
    toggleColumnsChange: accountActions.toggleColumnsChange
  };

  var adminsActions = {
    getMetadata: accountActions.getMetadata,
    getListData: accountActions.getListData,
    getListDataBySearchTerm: accountActions.getListDataBySearchTerm,
    toggleColumnsChange: accountActions.toggleColumnsChange
  };

  var fundraisersActions = {
    getMetadata: accountActions.getFundraiserMetadata,
    getListData: accountActions.getFundraiserListData,
    getListDataBySearchTerm: accountActions.getFundraiserListDataBySearchTerm,
    toggleColumnsChange: accountActions.toggleColumnsChange
  };

  var donorsActions = {
    getMetadata: accountActions.getDonorMetadata,
    getListData: accountActions.getDonorListData,
    getListDataBySearchTerm: accountActions.getDonorListDataBySearchTerm,
    toggleColumnsChange: accountActions.toggleColumnsChange
  };

  const allUsersActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    allUsersActions
  );
  const adminsActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    adminsActions
  );
  const fundraisersActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    fundraisersActions
  );
  const donorsActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    donorsActions
  );

  return {
    allUsersActionGrid,
    adminsActionGrid,
    fundraisersActionGrid,
    donorsActionGrid,
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    setView(view) {
      dispatch(accountActions.setView(view));
    },
    changeInviteAdminPanelVisibility(visible) {
      dispatch(accountActions.changeInviteAdminPanelVisibility(visible));
    },
    clearRecordMessages(recordKey) {
      dispatch(accountActions.clearRecordMessages(recordKey));
    },
    clearListsState() {
      dispatch(generalActions.clearListsReadyState());
    },
    impersonateAdmin(id, verificationToken = null) {
      dispatch(impersonateAdmin(id, verificationToken));
    }
  };
};

const AdminsListContainer = clear(
  withRouter(connect(mapState, mapDispatch)(AccountsPage))
);
export default AdminsListContainer;
