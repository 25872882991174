import update from '../helpers/update';
import get from 'lodash.get';

import * as entityHandlers from '../modules/bin/entityHandlers';
import { Record } from './bin/utility';
import { CLEAR as GENERAL_CLEAR } from '../modules/general';

export const GET_TREE_DATA_REQUESTED = 'tree/GET_TREE_DATA_REQUESTED';
export const GET_TREE_DATA_SUCCESS = 'tree/GET_TREE_DATA_SUCCESS';
export const GET_TREE_DATA_FAILURE = 'tree/GET_TREE_DATA_FAILURE';

export const SET_SELECTED_ITEM = 'tree/SET_SELECTED_ITEM';
export const CLEAR = 'tree/CLEAR';

export const INIT_RECORD = 'tree/INIT_RECORD';

const initialState = {
  record: Record.getDefaultState(),
  selected: null
};

const treeReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case INIT_RECORD: {
      //Init unique record for the path
      newState = update.set(
        newState,
        `${action.payload.path}.record`,
        Record.getDefaultState()
      );
      break;
    }
    case GET_TREE_DATA_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(newState, action);
      break;

    case GET_TREE_DATA_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(
        newState,
        `${action.payload.key}.data`,
        action.payload.data.data
      );
      break;

    case GET_TREE_DATA_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case SET_SELECTED_ITEM:
      let selectedItem = action.payload.item;
      if (!selectedItem) {
        selectedItem = findTreeElement(
          action.payload.id,
          get(state, `${action.payload.path}.record.data`)
        );
      }
      newState = update.set(
        state,
        `${action.payload.path}.selected`,
        selectedItem
      );
      break;

    case CLEAR:
      newState = update.set(state, `${action.payload.path}.selected`, null);
      break;

    case GENERAL_CLEAR: {
      newState = initialState;
      break;
    }

    default:
      break;
  }

  return newState;
};
export default treeReducer;

export const treeActions = {
  getTreeRecord: (entity, url, path) => {
    return entityHandlers.getAggregate(
      GET_TREE_DATA_REQUESTED,
      GET_TREE_DATA_SUCCESS,
      GET_TREE_DATA_FAILURE,
      `${entity}/tree/${url}`,
      `${path}.record`
    );
  },
  setSelectedItem: (id, path, item = null) => {
    return {
      type: SET_SELECTED_ITEM,
      payload: {
        id,
        item,
        path
      }
    };
  },
  clear: (path) => {
    return {
      type: CLEAR,
      payload: {
        path
      }
    };
  },
  initRecord: (path) => {
    return {
      type: INIT_RECORD,
      payload: {
        path
      }
    };
  }
};

const findTreeElement = (id, data) => {
  if (data && data.length) {
    for (let i = 0; i < data.length; i++) {
      const element = data[i];

      if (element.id === id) {
        return element;
      }

      if (element.childs && element.childs.length) {
        const childResult = findTreeElement(id, element.childs);
        if (childResult) {
          return childResult;
        }
      }
    }
  }
};
