import React, { Component } from 'react';

import { I18n } from 'react-redux-i18n';
import { Form, Modal, Button, Checkbox } from 'semantic-ui-react';

import { Record } from '../../../../modules/bin/utility';

class ApplicationConfirmationModal extends Component {
  render() {
    const {
      open,
      showDisableEmailCheckbox,
      disableAutomaticEmail,
      handleConfirm,
      translationKey,
      handleClose,
      record,
      handleModalStateChange
    } = this.props;

    const isLoading = Record.isRecordLoading(record);

    return (
      <Modal
        className="application-confirmation-modal"
        size="tiny"
        dimmer="inverted"
        open={open}
      >
        <Modal.Header>
          <label>{I18n.t(`${translationKey}.title`)}</label>
        </Modal.Header>
        <Modal.Content className="application-confirmation-modal-content">
          <span>{I18n.t(`${translationKey}.content`)}</span>
          {showDisableEmailCheckbox && (
            <Form.Field className="disable-automatic-email-checkbox">
              <Checkbox
                checked={disableAutomaticEmail}
                onChange={(event, { checked }) => {
                  handleModalStateChange(
                    'applicationConfirmationModal.disableAutomaticEmail',
                    checked
                  );
                }}
                label={I18n.t(
                  `${translationKey}.disable-automatic-email.checkbox`
                )}
              />
            </Form.Field>
          )}
          <div className="application-confirmation-modal-buttons">
            <Button
              primary
              className="accept-button"
              onClick={handleConfirm}
              loading={isLoading}
            >
              {I18n.t(`${translationKey}.accept-button`)}
            </Button>
            <Button onClick={handleClose}>
              {I18n.t(`${translationKey}.decline-button`)}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default ApplicationConfirmationModal;
