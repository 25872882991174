import moment from 'moment';
import update from '../../helpers/update';
import { Record } from '../../modules/bin/utility';
import * as entityHandlers from '../../modules/bin/entityHandlers';
import { listReadyStateClear } from '../../helpers/donationHelper';

import { gridKeys } from '../../constants/gridKeys';
import { CLEAR, LIST_READY_STATE_CLEAR } from '../../modules/general';
import { summaryType, reportTimelineBracket } from './constants';
import {
  getLastDonationsGrid,
  getTopDonationsGrid,
  getLastFundraisersGrid,
  getTopFundraisersGrid,
  getLastSalesGrid,
  getTopCampaignsGrid
} from './gridConfiguration';
import { getCampaignsGrid } from '../campaign/gridConfiguration';
import { getSalesGrid, getTicketsGrid } from '../ticketing/gridConfiguration';
import { getDonationGrid } from '../donation/gridConfiguration';
import { getFundraiserGrid } from '../fundraisers/gridConfiguration';
import { listFilterOperator } from '../../constants/aggregate';
import { queryMap } from '../../constants/queryMap';
import config from '../../constants';
import get from 'lodash.get';

const INIT = 'dashboard/INIT';

const ON_VALUE_CHANGE = 'dashboard/ON_VALUE_CHANGE';

const GET_REQUESTED = 'dashboard/GET_REQUESTED';
const GET_SUCCESS = 'dashboard/GET_SUCCESS';
const GET_FAILURE = 'dashboard/GET_FAILURE';

const GET_SEARCH_REQUESTED = 'dashboard/GET_SEARCH_REQUESTED';
const GET_SEARCH_SUCCESS = 'dashboard/GET_SEARCH_SUCCESS';
const GET_SEARCH_FAILURE = 'dashboard/GET_SEARCH_FAILURE';

const METADATA_REQUESTED = 'dashboard/METADATA_REQUESTED';
const METADATA_SUCCESS = 'dashboard/METADATA_SUCCESS';
const METADATA_FAILURE = 'dashboard/METADATA_FAILURE';

const LIST_DATA_REQUESTED = 'dashboard/LIST_DATA_REQUESTED';
const LIST_DATA_SUCCESS = 'dashboard/LIST_DATA_SUCCESS';
const LIST_DATA_FAILURE = 'dashboard/LIST_DATA_FAILURE';

const GET_EXPORT_FILE_REQUESTED = 'dashboard/GET_EXPORT_FILE_REQUESTED';
const GET_EXPORT_FILE_SUCCESS = 'dashboard/GET_EXPORT_FILE_SUCCESS';
const GET_EXPORT_FILE_FAILURE = 'dashboard/GET_EXPORT_FILE_FAILURE';

const ON_COMPARE_MODEL_ADD = 'dashboard/ON_COMPARE_MODEL_ADD';
const ON_COMPARE_MODEL_REMOVE = 'dashboard/ON_COMPARE_MODEL_REMOVE';

const i18nCampaignListKey = 'campaign.list.header';
const i18nSalesListKey = 'sale.list.header';
const i18nTicketListKey = 'ticket.list.header';
const i18nFundraisersListKey = 'fundraiser.list.header';
const i18nDonationListKey = 'donation.list.header';

const initialState = {
  inited: false,
  reportByDateAndMetricType: null,
  timeLapse: null,
  timeBracket: null,
  timePeriod: {
    dateFrom: null,
    dateTo: null
  },
  summaryType: summaryType.raisedAmount,
  modelRecord: Record.getDefaultState(),
  summaryRecord: Record.getDefaultState(),
  summaryDataRecord: Record.getDefaultState(),
  [gridKeys.DASHBOARD_CAMPAIGNS]: getCampaignsGrid(
    i18nCampaignListKey,
    [{ path: 'averageDonationAmount' }, { path: 'donationCount' }],
    20
  ),
  [gridKeys.DASHBOARD_SALES]: getSalesGrid(i18nSalesListKey, 20),
  [gridKeys.DASHBOARD_TICKETS]: getTicketsGrid(i18nTicketListKey, 20),
  // prettier-ignore
  [gridKeys.DASHBOARD_FUNDRAISERS]: getFundraiserGrid(i18nFundraisersListKey, 20),
  [gridKeys.DASHBOARD_DONATIONS]: getDonationGrid(i18nDonationListKey, 20),
  [gridKeys.DASHBOARD_TOP_CAMPAIGNS]: getTopCampaignsGrid(
    i18nFundraisersListKey
  ),
  [gridKeys.DASHBOARD_LAST_DONATIONS]:
    getLastDonationsGrid(i18nDonationListKey),
  [gridKeys.DASHBOARD_TOP_DONATIONS]: getTopDonationsGrid(i18nDonationListKey),
  [gridKeys.DASHBOARD_LAST_FUNDRAISERS]: getLastFundraisersGrid(
    i18nFundraisersListKey
  ),
  [gridKeys.DASHBOARD_TOP_FUNDRAISERS]: getTopFundraisersGrid(
    i18nFundraisersListKey
  ),
  [gridKeys.DASHBOARD_LAST_SALES]: getLastSalesGrid(i18nSalesListKey),
  exportFileRecord: Record.getDefaultState(),
  gridTab: null,
  compare: {
    models: []
  },
  compareModelSearchOptionsRecord: Record.getDefaultState()
};

const mapRequest = (request) => {
  const transformedFilters = [];
  for (const filter of request.filters) {
    if (queryMap.has(filter.key)) {
      const key = queryMap.get(filter.key);
      transformedFilters.push({ ...filter, key });
    } else {
      transformedFilters.push(filter);
    }
  }

  return {
    ...request,
    filters: transformedFilters
  };
};

export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case INIT: {
      const {
        defaultReportByDateAndMetricType,
        defaultTimeLapse,
        defaultTimeBracket,
        getDefaultGridTab,
        ...configurations
      } = action.payload.configurations;
      newState = {
        ...state,
        inited: true,
        reportByDateAndMetricType: defaultReportByDateAndMetricType,
        timeLapse: defaultTimeLapse,
        timeBracket: defaultTimeBracket,
        gridTab:
          getDefaultGridTab &&
          getDefaultGridTab(
            action.payload.isEventAdmin,
            action.payload.isEventOrganizerAdmin
          ),
        configurations: configurations
      };
      break;
    }

    case CLEAR:
      newState = initialState;
      break;

    case GET_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getSuccessHandler(state, action);
      break;

    case GET_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(state, action.payload.path, action.payload.value);
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(
        newState,
        gridKeys.DASHBOARD_TOP_CAMPAIGNS
      );
      newState = listReadyStateClear(
        newState,
        gridKeys.DASHBOARD_LAST_DONATIONS
      );
      newState = listReadyStateClear(
        newState,
        gridKeys.DASHBOARD_TOP_DONATIONS
      );
      newState = listReadyStateClear(
        newState,
        gridKeys.DASHBOARD_LAST_FUNDRAISERS
      );
      newState = listReadyStateClear(
        newState,
        gridKeys.DASHBOARD_TOP_FUNDRAISERS
      );
      newState = listReadyStateClear(newState, gridKeys.DASHBOARD_LAST_SALES);
      break;

    case GET_SEARCH_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SEARCH_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_SEARCH_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ON_COMPARE_MODEL_ADD:
      newState = update(newState, {
        compare: {
          models: {
            $push: [
              {
                ...action.payload.item,
                summaryRecord: Record.getDefaultState(),
                summaryDataRecord: Record.getDefaultState()
              }
            ]
          }
        }
      });
      break;

    case ON_COMPARE_MODEL_REMOVE:
      newState = update(newState, {
        compare: {
          models: { $splice: [[action.payload.index, 1]] }
        }
      });
      break;

    default:
      break;
  }
  return newState;
};

export const onValueChange = (path, value) => {
  return {
    type: ON_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const getCampaignMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'page/campaign',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getCampaignListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'page/campaign',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getCampaignListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getCampaignListData(listKey, id, newRequest, true);
};

export const getDonationMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'donation',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getDonationListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'donation',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getDonationListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getDonationListData(listKey, id, newRequest, true);
};

export const getFundraiserMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'page',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getFundraiserListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'page',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );
export const getFundraiserListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getFundraiserListData(listKey, id, newRequest, true);
};

export const getTicketMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'saleDetail',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getTicketListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'saleDetail',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );

export const getTicketListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getTicketListData(listKey, id, newRequest, true);
};

export const getSaleMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'sale',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getSaleListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'sale',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getSaleListDataBySearchTerm = (
  listKey,
  id,
  request,
  searchTerm
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getSaleListData(listKey, id, newRequest, true);
};

// export const getExportFile = (entity, request, fileName, version) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     mapRequest(request),
//     'exportFileRecord',
//     fileName,
//     version
//   );
// };

export const getSummaryRecord = (
  id,
  type,
  timeBracket,
  dateFrom = null,
  dateTo = null,
  path = 'summaryRecord'
) => {
  const formattedDateFrom =
    dateFrom && moment(new Date(dateFrom)).format(config.DEFAULT_DATE_FORMAT);
  const formattedDateTo =
    dateTo && moment(new Date(dateTo)).format(config.DEFAULT_DATE_FORMAT);

  const dateQuery =
    dateFrom && dateTo
      ? `dateFrom=${formattedDateFrom}&dateTo=${formattedDateTo}`
      : '';
  const onlineQuery =
    timeBracket === reportTimelineBracket.DefaultOnline ||
    timeBracket === reportTimelineBracket.TimeToDateOnline
      ? `onlineOnly=true`
      : '';

  let query = id;
  if (dateQuery) {
    query += `?${dateQuery}`;
  }
  if (onlineQuery) {
    query += query.includes('?') ? `&${onlineQuery}` : `?${onlineQuery}`;
  }

  return entityHandlers.getRecord(
    `report/summary/${type}`,
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    query,
    path,
    'v3'
  );
};

export const getSummaryDataRecord = (request) => {
  return entityHandlers.getSearchRecord(
    'report/day',
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    mapRequest(request),
    'summaryDataRecord',
    'v3'
  );
};

export const getReportSummaryDataRecord = (
  id,
  dateFrom,
  dateTo,
  baseFilter,
  timeBracket,
  reportByDateAndMetricType,
  path
) => {
  const request = {
    filters: [
      {
        key: 'LocalDate',
        operator: listFilterOperator.GREATER_OR_EQUAL_THAN,
        value: [moment(new Date(dateFrom)).format(config.DEFAULT_DATE_FORMAT)]
      },
      {
        key: 'LocalDate',
        operator: listFilterOperator.LESSER_OR_EQUAL_THAN,
        value: [moment(new Date(dateTo)).format(config.DEFAULT_DATE_FORMAT)]
      },
      {
        key: baseFilter,
        operator: listFilterOperator.EQUAL_TO,
        value: [`${id}`]
      },
      {
        key: 'timeBracket',
        operator: listFilterOperator.EQUAL_TO,
        value: [timeBracket]
      },
      {
        key: 'frequencyType',
        operator: listFilterOperator.EQUAL_TO,
        value: [reportByDateAndMetricType]
      }
    ],
    orderBy: {
      key: 'LocalDate',
      direction: 'asc'
    }
  };

  return entityHandlers.getSearchRecord(
    'report/day',
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    request,
    path,
    'v3'
  );
};

export const getModelRecord = (id, path) =>
  entityHandlers.getRecord(
    path,
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    id,
    'modelRecord'
  );

export const getCompareModelSearchOptions = (request, path) => {
  return entityHandlers.getListData(
    path,
    'compareModelSearchOptionsRecord',
    null,
    GET_SEARCH_REQUESTED,
    GET_SEARCH_SUCCESS,
    GET_SEARCH_FAILURE,
    request
  );
};

export const handleCompareModelAdd = (item) => {
  return {
    type: ON_COMPARE_MODEL_ADD,
    payload: { item }
  };
};

export const handleCompareModelRemove = (index) => {
  return {
    type: ON_COMPARE_MODEL_REMOVE,
    payload: { index }
  };
};

export const init = (configurations) => {
  return (dispatch, getState) => {
    const state = getState();

    const isEventAdmin = get(state, 'session.isEventAdmin');
    const isEventOrganizerAdmin = get(state, 'session.isEventOrganizerAdmin');

    dispatch({
      type: INIT,
      payload: {
        configurations,
        isEventAdmin,
        isEventOrganizerAdmin
      }
    });
  };
};

export const getSummaryDataRecordRequest = (
  id,
  baseFilter,
  timePeriod,
  timeBracket,
  reportByDateAndMetricType
) => {
  return {
    filters: [
      {
        key: 'LocalDate',
        operator: listFilterOperator.GREATER_OR_EQUAL_THAN,
        value: [timePeriod.dateFrom]
      },
      {
        key: 'LocalDate',
        operator: listFilterOperator.LESSER_OR_EQUAL_THAN,
        value: [timePeriod.dateTo]
      },
      {
        key: baseFilter,
        operator: listFilterOperator.EQUAL_TO,
        value: [`${id}`]
      },
      {
        key: 'timeBracket',
        operator: listFilterOperator.EQUAL_TO,
        value: [timeBracket]
      },
      {
        key: 'frequencyType',
        operator: listFilterOperator.EQUAL_TO,
        value: [reportByDateAndMetricType]
      }
    ],
    orderBy: {
      key: 'LocalDate',
      direction: 'asc'
    }
  };
};
