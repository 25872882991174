/* eslint-disable no-use-before-define */
import { map, cond, prop, equals, pipe, anyPass, omit } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

import { nodeTypes } from './formBuilderHelpers';

const mapFromRoot = (node) => ({
  key: node.identifier,
  formKey: node.formKey,
  sections: map(mapFromFormBuilder, node.children),
  type: node.type
});

const mapFromSection = (node) => ({
  key: node.identifier,
  formKey: node.formKey,
  title: node.props.title,
  fields: map(mapFromFormBuilder, node.children),
  type: node.type
});

const mapFromFieldWithoutConditional = (node) => ({
  key: node.identifier,
  formKey: node.formKey,
  type: node.type,
  ...node.props
});

const cloneSection = (node, fields, key) => {
  node.formKey = key;
  const ticketField = fields?.find((x) => x.identifier === node.identifier);
  node.identifier = uuidv4();
  if (ticketField) ticketField.identifier = node.identifier;
  node.children.forEach((child) => cloneSection(child, fields, key));
};

/**
 * This function calls different functions for different node types.
 * The called functions usually call this function again to process the children of the node.
 * The exceptions are the components without conditional support.
 * This way the whole tree will be processed recursively.
 * @param {Object} a node of a FormBuilder.
 * @return {Object} the more-human-readable representation of node.
 */
const mapFromFormBuilder = cond([
  [pipe(prop('type'), equals(nodeTypes.root)), mapFromRoot],
  [pipe(prop('type'), equals(nodeTypes.section)), mapFromSection],
  [
    pipe(
      prop('type'),
      anyPass([
        equals(nodeTypes.date),
        equals(nodeTypes.textArea),
        equals(nodeTypes.textInput),
        equals(nodeTypes.phone),
        equals(nodeTypes.address),
        equals(nodeTypes.country),
        equals(nodeTypes.checkbox),
        equals(nodeTypes.dropdown),
        equals(nodeTypes.consent),
        equals(nodeTypes.groupDivider),
        equals(nodeTypes.additionalEmailAddress),
        equals(nodeTypes.additionalPhoneNumber),
        equals(nodeTypes.targetAmount)
      ])
    ),
    mapFromFieldWithoutConditional
  ]
]);

const mapToRoot = (node) => ({
  mapKey: node.key,
  formKey: node.formKey,
  children: map(mapToFormBuilder, node.sections),
  props: {},
  type: node.type
});

const mapToSection = (node) => ({
  mapKey: node.key,
  formKey: node.formKey,
  props: { title: node.title },
  children: map(mapToFormBuilder, node.fields),
  type: node.type
});

const mapToFieldWithoutConditional = (node) => {
  return {
    mapKey: node.key,
    formKey: node.formKey,
    type: node.type,
    props: omit(['key', 'formKey', 'type', 'children'], node),
    children: []
  };
};
/**
 * This function is very similar to mapFromFormBuilder but works in the other direction.
 * @param {Object} node The more-human-readable representation of a FormBuilder node.
 * @return {Object} The internal representation of the input node.
 */
const mapToFormBuilder = cond([
  [pipe(prop('type'), equals(nodeTypes.root)), mapToRoot],
  [pipe(prop('type'), equals(nodeTypes.section)), mapToSection],
  [
    pipe(
      prop('type'),
      anyPass([
        equals(nodeTypes.date),
        equals(nodeTypes.textArea),
        equals(nodeTypes.textInput),
        equals(nodeTypes.phone),
        equals(nodeTypes.address),
        equals(nodeTypes.country),
        equals(nodeTypes.checkbox),
        equals(nodeTypes.dropdown),
        equals(nodeTypes.consent),
        equals(nodeTypes.groupDivider),
        equals(nodeTypes.additionalEmailAddress),
        equals(nodeTypes.additionalPhoneNumber),
        equals(nodeTypes.targetAmount)
      ])
    ),
    mapToFieldWithoutConditional
  ]
]);

export { mapFromFormBuilder, mapToFormBuilder, cloneSection };
