import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { SaleCouponDiscountType } from '../../../components/collectionBuilder/constants';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';

import { ViewTable } from '../../../components/layouts/viewTable';
import { ICouponCode } from '../../../models/CouponCode';
import { RootState } from '../../../store';

const generateCouponDisplayName = (coupon: ICouponCode) => {
  let value = coupon.discountTypeValue;

  if (coupon.discountType === SaleCouponDiscountType.AsPercentage) {
    value += '%';
  } else if (coupon.discountType === SaleCouponDiscountType.AsFixedValue) {
    value = `$${value}`;
  }

  return `${coupon.couponCode} (${value} off)`;
};

export const TicketCouponCodes = () => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const relatedData = ticketing.relatedRecord?.data?.data;

  if (relatedData?.couponCodes?.length > 0) {
    return (
      <div className="ticket-action-container">
        <ViewTable
          title={<Translate value="ticketing.edit.coupon-codes.header" />}
        >
          <ViewSectionRow
            title="ticketing.edit.coupon-codes.code-list.label"
            data={relatedData.couponCodes.map((coupon: ICouponCode) => (
              <div key={coupon.couponCode}>
                {generateCouponDisplayName(coupon)}
              </div>
            ))}
          />
        </ViewTable>
      </div>
    );
  }

  return null;
};
