import React from 'react';
import EditableUpload from '../../../components/form/editableUpload';
import { imageTypes } from '../../../constants/imageUpload';
import {
  imageFormats,
  getFullImagePathByType
} from '../../../constants/imageUpload';

class AboutImageUpload extends React.Component {
  render() {
    return (
      <div>
        <EditableUpload
          path={this.props.guid}
          format={imageFormats}
          height={500}
          width={1500}
          position="top"
          entity={this.props.entity}
          disableCropping={this.props.disableCropping}
          uploadType={imageTypes.ASSET}
          url={getFullImagePathByType(this.props.value, imageTypes.ASSET)}
          onSuccess={this.props.onValueChange}
        />
      </div>
    );
  }
}

export default AboutImageUpload;
