// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { RouteComponentProps, withRouter } from 'react-router-dom';

// Actions
import * as eventActions from './store/eventActions';

// Components
import BasePage from '../../components/common/basePageView';
import clear from '../../components/clear';
import Grid from '../../components/common/grid';
import ContextMenu from '../../components/common/grid/contextMenu';
import Panel from '../../components/common/grid/managed/panel';
import Button from '../../components/common/button';
import { Common } from '../../components';
import { Menu } from 'semantic-ui-react';
import RecordResultMessage from '../../components/common/recordResult';
import Spinner from '../../components/common/spinner';
import { BackOfficeModal } from '../../components/backoffice/BackOfficeModal';

// Constants
import { gridKeys } from '../../constants/gridKeys';
import { i18nKeys } from './constants';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';

// Helpers
import get from 'lodash.get';
import { Record } from '../../modules/bin/utility';
import { IEvent } from '../../models/Event';

// ----------------------------------------------------------------------

interface EventPageProps extends RouteComponentProps {
  isEventAdmin: boolean;
  isEventOrganizerAdmin: boolean;
  isSystemAdmin: boolean;
  isSessionLoading: boolean;
  list: any;
  actionGrid: any;
  impersonatedRecord: any;
  createAccountRecord: any;
  recordEdit: any;
  recordCreate: any;
  match: any;
}

interface IEventPageState {
  backOfficeModal: {
    open: boolean;
    name?: string;
    eventId?: number;
  };
}

/** A page to view all events in a list */
class EventPage extends Component<EventPageProps, IEventPageState> {
  constructor(props: EventPageProps | Readonly<EventPageProps>) {
    super(props);
    this.state = {
      backOfficeModal: {
        open: false
      }
    };
  }

  onCreateClick = () => {
    this.props.history.push('/events/create/setup');
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.list) ||
      Record.isRecordLoading(this.props.list.metadata)
    );
  };

  getPanel = () => {
    if (!this.props.isEventAdmin && !this.props.isEventOrganizerAdmin) {
      return (
        <Panel name="right">
          <Button id="btnCreateEvent" primary onClick={this.onCreateClick}>
            <Translate value={i18nKeys.CREATE_BUTTON} />
          </Button>
        </Panel>
      );
    }
    return <React.Fragment />;
  };

  isEditContextMenuItemHidden = () => {
    return this.props.isEventAdmin || this.props.isEventOrganizerAdmin;
  };

  isDonationsContextMenuItemHidden = () => {
    return this.props.isEventAdmin || this.props.isEventOrganizerAdmin;
  };

  isFunraiserContextMenuHidden = () => {
    return this.props.isEventOrganizerAdmin;
  };

  isEventCloneHidden = () => {
    return this.props.isEventOrganizerAdmin;
  };

  isActiveIntegrationMenuHiddenn = (item: any) => {
    return (
      !this.props.isSystemAdmin ||
      item?.external?.system !== 'active' ||
      item?.status !== 'live'
    );
  };

  onBackofficeClick = (open: boolean, event?: IEvent) => {
    this.setState({
      backOfficeModal: {
        open,
        name: `${event?.name} (${event?.id})`,
        eventId: event?.id
      }
    });
  };

  getContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.VIEW_DASHBOARD.key}
          label={I18n.t(i18nKeys.DASHBOARD_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.VIEW_DASHBOARD}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.EDIT.key}
          label={I18n.t(i18nKeys.EDIT_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.EDIT}
          isHiden={this.isEditContextMenuItemHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.VIEW.key}
          label={I18n.t(i18nKeys.VIEW_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.VIEW}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.VIEW_ORGANIZATIONS.key}
          label={I18n.t(i18nKeys.ORGANIZATIONS_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.VIEW_ORGANIZATIONS}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.VIEW_DONATIONS.key}
          label={I18n.t(i18nKeys.DONATIONS_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.VIEW_DONATIONS}
          isHiden={this.isDonationsContextMenuItemHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.VIEW_FUNDRAISERS.key}
          label={I18n.t(i18nKeys.FUNDRAISERS_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.VIEW_FUNDRAISERS}
          isHiden={this.isFunraiserContextMenuHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.ACTIVE_INTEGRATION.key}
          label={I18n.t(i18nKeys.ACTIVE_INTEGRATION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.ACTIVE_INTEGRATION}
          isHiden={this.isActiveIntegrationMenuHiddenn}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.CLONE_EVENT.key}
          label={I18n.t(i18nKeys.CLONE_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.EVENTS.CLONE_EVENT}
          isHiden={this.isEventCloneHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.EVENTS.BACK_OFFICE.key}
          label={I18n.t(i18nKeys.BACK_OFFICE)}
          onClick={(_id: string, item: IEvent) =>
            this.onBackofficeClick(true, item)
          }
          isHiden={() => !this.props.isSystemAdmin}
        />
      </ContextMenu>
    );
  };

  clearMessages = () => {
    this.props.actionGrid.clearRecordMessages('recordEdit');
    this.props.actionGrid.clearRecordMessages('recordCreate');
  };

  showSpinner = () => {
    return (
      this.props.isSessionLoading ||
      Record.isRecordLoading(this.props.createAccountRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord)
    );
    // || Record.isRecordLoading(this.props.exportFileRecord);
  };

  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const panel = this.getPanel();
    const contextMenu = this.getContextMenu();
    const filterButtons = get(this.props, 'list.filterButtons');

    return (
      <>
        <BasePage>
          <RecordResultMessage
            record={this.props.recordEdit}
            onDismiss={this.clearMessages}
          />
          <RecordResultMessage
            record={this.props.recordCreate}
            onDismiss={this.clearMessages}
          />

          <h1>
            <Translate value={i18nKeys.PAGE_TITLE} />
          </h1>
          <Menu
            className="accounts-tab-buttons-group"
            style={{ margin: '0px', borderRight: '0px' }}
          >
            {Object.keys(filterButtons).map((key) => {
              const item = filterButtons[key];
              return (
                <Menu.Item
                  key={item.key}
                  name={item.key}
                  content={I18n.t(item.I18nKey)}
                  active={item.key === this.props.match.params.tab}
                  disabled={this.isChooseTabButtonDisabled()}
                  onClick={() => {
                    this.props.history.push(`/events/${item.key}`);
                  }}
                />
              );
            })}
          </Menu>
          <Grid.Managed
            listKey={gridKeys.EVENTS}
            list={this.props.list}
            actions={this.props.actionGrid}
            filtersButton
            search
            toggleColumns
            activeTabFilter={this.props.match.params.tab}
          >
            {panel}
            {contextMenu}
          </Grid.Managed>
        </BasePage>
        <BackOfficeModal
          open={this.state.backOfficeModal.open}
          setOpen={() => this.onBackofficeClick(false)}
          name={this.state.backOfficeModal.name}
          eventId={this.state.backOfficeModal.eventId}
        />
      </>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ event, authentication, account, session }: any) => {
  // @ts-ignore
  const stateGrid = Common.Grid.Managed.mapGridState(event, gridKeys.EVENTS);
  return {
    listKey: stateGrid.key,
    list: stateGrid.list,
    recordEdit: event.recordEdit,
    recordCreate: event.recordCreate,
    createAccountRecord: authentication.invite.createAccountRecord,
    impersonatedRecord: account.impersonatedRecord,
    isSessionLoading: session?.processing,
    isSystemAdmin: session?.isSystemAdmin,
    isEventAdmin: session?.isEventAdmin,
    isEventOrganizerAdmin: session?.isEventOrganizerAdmin
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch: any) => {
  var actions = {
    getMetadata: eventActions.getMetadata,
    getListData: eventActions.getListData,
    getListDataBySearchTerm: eventActions.getListDataBySearchTerm,
    toggleColumnsChange: eventActions.toggleColumnsChange,
    onGridTabChange: eventActions.onGridTabChange
  };

  // @ts-ignore
  const actionGrid = Grid.Managed.bindGridActions(dispatch, actions);

  return {
    actionGrid
  };
};

/** Connects component to Redux store */
// @ts-ignore
const EventPageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(EventPage))
);
export default EventPageContainer;
