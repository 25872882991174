import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid as SemanticGrid } from 'semantic-ui-react';
import { Common } from '../../';
import { I18n } from 'react-redux-i18n';

class Pagination extends Component {
  static propTypes = {
    recordCount: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    total: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired
  };

  onPreviousClick = () => {
    this.onPageClick({
      action: 'previous',
      page: this.props.page - 1
    });
  };

  onNextClick = () => {
    this.onPageClick({
      action: 'next',
      page: this.props.page + 1
    });
  };

  onPreviousFastClick = () => {
    this.onPageClick({
      action: 'previous',
      page: this.props.page - 3
    });
  };

  onNextFastClick = () => {
    this.onPageClick({
      action: 'next',
      page: this.props.page + 3
    });
  };

  onPageClick = (msg) => {
    this.props.onPageChange(msg.page);
  };

  render() {
    const showPrevious = this.props.page !== 1;
    const showPreviousFast = this.props.page > 3;
    const showNext = this.props.page < this.props.total;
    const showNextFast = this.props.page < this.props.total - 2;

    return (
      <SemanticGrid className="grid-list-pagination">
        <SemanticGrid.Row columns={2}>
          <SemanticGrid.Column
            className="grid-list-pagination-records"
            verticalAlign="middle"
          >
            {this.props.recordCount}{' '}
            {this.props.recordCount === 1
              ? I18n.t('general.list.record')
              : I18n.t('general.list.records')}
          </SemanticGrid.Column>
          <SemanticGrid.Column
            className="grid-list-pagination-buttons"
            textAlign="right"
          >
            {showPreviousFast && (
              <Common.Button
                key="previous-fast"
                basic
                className="fast-back"
                onClick={this.onPreviousFastClick}
                icon="chevron left"
              />
            )}
            {showPrevious && (
              <Common.Button
                key="previous"
                basic
                onClick={this.onPreviousClick}
              >
                {this.props.page - 1}
              </Common.Button>
            )}
            <Common.Button key="current" primary active>
              {this.props.page}
            </Common.Button>
            {showNext && (
              <Common.Button key="next" basic onClick={this.onNextClick}>
                {this.props.page + 1}
              </Common.Button>
            )}
            {showNextFast && (
              <Common.Button
                key="next-fast"
                basic
                className="fast-forward"
                onClick={this.onNextFastClick}
                icon="chevron right"
              />
            )}
          </SemanticGrid.Column>
        </SemanticGrid.Row>
      </SemanticGrid>
    );
  }
}

export default Pagination;
