import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import FormbuilderFieldWrapper from '../../../../formBuilderAnswersComponents/formbuilderFieldWrapper';
import PropTypes from 'prop-types';

const DropdownField = ({ label, value, update, fieldName, options }) => {
  return (
    <FormbuilderFieldWrapper label={label} value={value}>
      <Dropdown
        value={value}
        onChange={(event, data) => {
          update(data.value);
        }}
        options={options}
        selection
        placeholder="Select..."
        icon="chevron down"
        id={`${fieldName}Id`}
      />
    </FormbuilderFieldWrapper>
  );
};

DropdownField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  update: PropTypes.func,
  options: PropTypes.array
};

export default DropdownField;
