import React from 'react';
import {
  Button,
  Dimmer,
  Header,
  Icon,
  Loader,
  Pagination,
  Table
} from 'semantic-ui-react';
import { DownloadStatus, DownloadStatusType } from './models';
import { useExportHistoryApi } from './use-export-history';

export interface ExportHistoryPageProps {}

export const ExportHistoryPage: React.FC<ExportHistoryPageProps> = ({}) => {
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const exportHistoryApi = useExportHistoryApi({
    page: currentPage,
    pageSize: 20
  });

  const asyncExport = new URLSearchParams(window.location.search).get('export');
  if (asyncExport) {
    try {
      // to do - add auto download logic
    } catch (e) {
      console.error('Export Error!', e);
    }
  }

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          gap: '1rem',
          flexWrap: 'wrap'
        }}
      >
        <Header
          as="h1"
          content="Export History"
          subheader="Exports will expire after 24 hours."
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '0.25rem',
            flexShrink: 0
          }}
        >
          <Icon name="clock outline" />
          <div>Last updated {exportHistoryApi.timer}</div>
        </div>
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Date</Table.HeaderCell>
            <Table.HeaderCell width={10}>Filters</Table.HeaderCell>
            <Table.HeaderCell width={2}>Records</Table.HeaderCell>
            <Table.HeaderCell width={2}>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {exportHistoryApi.loading && (
            <Dimmer active inverted>
              <Loader />
            </Dimmer>
          )}
          {exportHistoryApi.list.map((item, index) => (
            <ExportHistoryRecord
              key={index}
              date={item.date}
              text={item.text}
              total={item.total}
              status={item.status}
              onDownload={() => exportHistoryApi.download(item)}
            />
          ))}
        </Table.Body>

        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan="4" textAlign="right">
              <Pagination
                size="mini"
                ellipsisItem={false}
                activePage={exportHistoryApi.page}
                onPageChange={(e, { activePage }) => {
                  setCurrentPage((activePage as number) || 1);
                }}
                totalPages={exportHistoryApi.totalPages}
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    </>
  );
};

export interface ExportHistoryRecordProps {
  text?: string;
  date?: string;
  total?: string;
  status?: DownloadStatusType;
  onDownload?: () => void;
}

export const ExportHistoryRecord: React.FC<ExportHistoryRecordProps> = ({
  status = DownloadStatus.Deleted,
  date = '',
  text = '',
  total = 0,
  onDownload
}) => {
  switch (status) {
    case 'queued':
      return (
        <Table.Row disabled>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>Queued</Table.Cell>
        </Table.Row>
      );
    case 'inProgress':
      return (
        <Table.Row>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>
            <Loader active inline size="mini" /> In Progress
          </Table.Cell>
        </Table.Row>
      );
    case 'ready':
      return (
        <Table.Row>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>
            <Button
              icon
              basic
              compact
              size="mini"
              color="blue"
              onClick={onDownload}
            >
              <Icon name="download" /> Download
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    case 'deleted':
    case 'expired':
      return (
        <Table.Row>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>Expired</Table.Cell>
        </Table.Row>
      );
    case 'error':
      return (
        <Table.Row>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>Error</Table.Cell>
        </Table.Row>
      );
    default:
      return (
        <Table.Row>
          <Table.Cell>{date}</Table.Cell>
          <Table.Cell>{text}</Table.Cell>
          <Table.Cell>{total}</Table.Cell>
          <Table.Cell>Unknown</Table.Cell>
        </Table.Row>
      );
  }
};
