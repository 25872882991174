import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import { bindActionCreators } from 'redux';
import * as accountSettingsActions from './reducer';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as templateHelper from '../../../components/template/templateHelper';
import { Record } from '../../../modules/bin/utility';
import { autocompleteOuterClear } from '../../../modules/autocomplete';
import { isArrayWithItems } from '../../../helpers/arrayHelper';

import { Button, Card, Form } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import RecordResultMessage from '../../../components/common/recordResult';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateOptions } from '../../../components/template/templateOptions';
import { TagItem } from '../../../components/tagInput/tagItem';

import { i18nKeys, paths } from '../constants';
import {
  roleOptions,
  roles,
  roleConfigurations
} from '../../../constants/roles';

class EyesOnly extends Component {
  constructor(props) {
    super(props);

    const linkedCampaigns = get(
      this.props.accountDetail,
      'roleDetail.campaignId'
    );
    if (isArrayWithItems(linkedCampaigns)) {
      this.props.accountSettingsActions.getLinkedCampaigns(linkedCampaigns);
    }

    const linkedEvents = get(this.props.accountDetail, 'roleDetail.eventId');
    if (isArrayWithItems(linkedEvents)) {
      this.props.accountSettingsActions.getLinkedEvents(linkedEvents);
    }

    const linkedEventOrganizers = get(
      this.props.accountDetail,
      'roleDetail.eventOrganizerId'
    );
    if (isArrayWithItems(linkedEventOrganizers)) {
      this.props.accountSettingsActions.getLinkedEventOrganizers(
        linkedEventOrganizers
      );
    }
  }

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getOrganizationItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  handleOrganizationChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_ORGANIZATION,
      item
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_ORGANIZATION_ID,
      value
    );
  };

  getCampaignRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'status',
          operator: 'EqualTo',
          value: 1
        }
      ],
      includeDataTemplate: true
    };
  };

  getEventRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'status',
          operator: 'EqualTo',
          value: 1
        }
      ],
      includeDataTemplate: true
    };
  };

  getEventOrganizerRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getCampaignItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  getEventItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  getEventOrganizerItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value: item.id
    };
  };

  handleCampaignChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_CAMPAIGN,
      item
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_CAMPAIGN_ID,
      value
    );
  };

  handleEventChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT,
      item
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ID,
      value
    );
  };

  handleEventOrganizerChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ORGANIZER,
      item
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ORGANIZER_ID,
      value
    );
  };

  onLinkedCampaignAdd = () => {
    this.props.accountSettingsActions.handleLinkedCampaignAdd(
      this.props.linkedCampaign
    );

    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_CAMPAIGN,
      null
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_CAMPAIGN_ID,
      null
    );
    this.props.clearAutocomplete(paths.PROMOTE_ADMIN_CAMPAIGN_ID);
  };

  onLinkedEventAdd = () => {
    this.props.accountSettingsActions.handleLinkedEventAdd(
      this.props.linkedEvent
    );

    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT,
      null
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ID,
      null
    );
    this.props.clearAutocomplete(paths.PROMOTE_ADMIN_EVENT_ID);
  };

  onLinkedEventOrganizerAdd = () => {
    this.props.accountSettingsActions.handleLinkedEventOrganizerAdd(
      this.props.linkedEventOrganizer
    );

    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ORGANIZER,
      null
    );
    this.props.templateBuilderActions.setResultValue(
      paths.PROMOTE_ADMIN_EVENT_ORGANIZER_ID,
      null
    );
    this.props.clearAutocomplete(paths.PROMOTE_ADMIN_EVENT_ORGANIZER_ID);
  };

  onLinkedCampaignDelete = (index) => {
    this.props.accountSettingsActions.handleLinkedCampaignRemove(index);
  };

  onLinkedEventDelete = (index) => {
    this.props.accountSettingsActions.handleLinkedEventRemove(index);
  };

  onLinkedEventOrganizerDelete = (index) => {
    this.props.accountSettingsActions.handleLinkedEventOrganizerRemove(index);
  };

  getLinkedCampaignItems = () => {
    const items = get(this.props, 'linkedCampaigns') || [];

    const renderItems = items.map((item, index) => {
      return (
        <TagItem
          index={index}
          label={item}
          onDelete={this.onLinkedCampaignDelete}
        />
      );
    });

    return renderItems;
  };

  getLinkedEventItems = () => {
    const items = get(this.props, 'linkedEvents') || [];
    const renderItems = items.map((item, index) => {
      return (
        <TagItem
          index={index}
          label={item}
          onDelete={this.onLinkedEventDelete}
        />
      );
    });

    return renderItems;
  };

  getLinkedEventOrganizerItems = () => {
    const items = get(this.props, 'linkedEventOrganizers') || [];
    const renderItems = items.map((item, index) => {
      return (
        <TagItem
          index={index}
          label={item}
          onDelete={this.onLinkedEventOrganizerDelete}
        />
      );
    });

    return renderItems;
  };

  handlePromoteClick = () => {
    if (this.props.promotionRole === roles.ORGANIZATION_ADMIN) {
      this.props.accountSettingsActions.promoteAdmin(
        this.props.accountDetail.id,
        this.props.organizationId
      );
    }

    if (this.props.promotionRole === roles.ORGANIZATION_ADMIN_RESTRICTED) {
      this.props.accountSettingsActions.promoteAdmin(
        this.props.accountDetail.id,
        this.props.organizationId,
        { restrictExports: true }
      );
    }

    if (this.props.promotionRole === roles.CAMPAIGN_ADMIN) {
      const campaignId =
        get(this.props.accountDetail, 'roleDetail.campaignId') || [];
      const campaignIdList = `?campaignIdList=${campaignId.join(
        '&campaignIdList='
      )}`;
      this.props.accountSettingsActions.promoteAdmin(
        this.props.accountDetail.id,
        campaignIdList
      );
    }

    if (this.props.promotionRole === roles.EVENT_ADMIN) {
      const eventId = get(this.props.accountDetail, 'roleDetail.eventId') || [];
      const eventIdList = `?eventIdList=${eventId.join('&eventIdList=')}`;
      this.props.accountSettingsActions.promoteAdmin(
        this.props.accountDetail.id,
        eventIdList
      );
    }

    if (this.props.promotionRole === roles.EVENT_ORGANIZER_ADMIN) {
      const eventOrganizerId =
        get(this.props.accountDetail, 'roleDetail.eventOrganizerId') || [];
      const eventOrganizerIdList = `?eventOrganizerIdList=${eventOrganizerId.join(
        '&eventOrganizerIdList='
      )}`;
      this.props.accountSettingsActions.promoteAdmin(
        this.props.accountDetail.id,
        eventOrganizerIdList
      );
    }
  };

  handleDemoteClick = () => {
    this.props.accountSettingsActions.demoteAdmin(this.props.accountDetail.id);
  };

  handlePromoteSuccess = () => {
    if (this.props.promotionRole === roles.ORGANIZATION_ADMIN) {
      this.props.accountSettingsActions.handleValueChange(
        paths.IS_ORG_ADMIN,
        true
      );
    }

    if (this.props.promotionRole === roles.CAMPAIGN_ADMIN) {
      this.props.accountSettingsActions.handleValueChange(
        paths.IS_CAMPAIGN_ADMIN,
        true
      );
    }

    if (this.props.promotionRole === roles.EVENT_ADMIN) {
      this.props.accountSettingsActions.handleValueChange(
        paths.IS_EVENT_ADMIN,
        true
      );
    }

    if (this.props.promotionRole === roles.EVENT_ORGANIZER_ADMIN) {
      this.props.accountSettingsActions.handleValueChange(
        paths.IS_EVENT_ORGANIZER_ADMIN,
        true
      );
    }

    this.props.accountSettingsActions.clearRecord('promoteRecord');
    this.props.templateBuilderActions.changeItemStatus(
      paths.PROMOTE_ADMIN_ROLE,
      true
    );
  };

  handleDemoteSuccess = () => {
    if (this.props.promotionRole === roles.ORGANIZATION_ADMIN) {
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_ORGANIZATION,
        null
      );
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_ORGANIZATION_ID,
        null
      );
      this.props.clearAutocomplete(paths.PROMOTE_ADMIN_ORGANIZATION_ID);
    }

    if (this.props.promotionRole === roles.CAMPAIGN_ADMIN) {
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_CAMPAIGN,
        null
      );
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_CAMPAIGN_ID,
        null
      );
    }

    if (this.props.promotionRole === roles.EVENT_ADMIN) {
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_EVENT,
        null
      );
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_EVENT_ID,
        null
      );
    }

    if (this.props.promotionRole === roles.EVENT_ORGANIZER_ADMIN) {
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_EVENT_ORGANIZER,
        null
      );
      this.props.templateBuilderActions.setResultValue(
        paths.PROMOTE_ADMIN_EVENT_ORGANIZER_ID,
        null
      );
    }

    this.props.accountSettingsActions.clearRecord('demoteRecord');
    this.props.templateBuilderActions.changeItemStatus(
      paths.PROMOTE_ADMIN_ROLE,
      false
    );
  };

  isPromoteBtnLoading = () => {
    return Record.isRecordLoading(this.props.promoteRecord);
  };

  isDemoteBtnLoading = () => {
    return Record.isRecordLoading(this.props.demoteRecord);
  };

  onChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  getPromoteButton = () => {
    if (
      this.props.isOrgAdmin ||
      this.props.isCampaignAdmin ||
      this.props.isEventAdmin ||
      this.props.isEventOrganizerAdmin
    ) {
      return (
        <Form.Field className="promote-btn">
          <Button
            primary
            loading={this.isDemoteBtnLoading()}
            onClick={this.handleDemoteClick}
          >
            <Translate value={i18nKeys.DEMOTE} />
          </Button>
        </Form.Field>
      );
    } else {
      const roleConfiguration = roleConfigurations[this.props.promotionRole];
      const isDisabled =
        roleConfiguration &&
        roleConfiguration.isPromoteButtonDisabled(
          this.props.accountDetail,
          this.props.organizationId
        );
      return (
        <Form.Field className="promote-btn">
          <Button
            primary
            loading={this.isPromoteBtnLoading()}
            disabled={this.props.promotionRole ? isDisabled : true}
            onClick={this.handlePromoteClick}
          >
            <Translate value={i18nKeys.PROMOTE} />
          </Button>
        </Form.Field>
      );
    }
  };

  isAllRecordsReady = () => {
    return (
      !Record.isRecordLoading(this.props.linkedCampaignsRecord) &&
      !Record.isRecordLoading(this.props.linkedEventsRecord) &&
      !Record.isRecordLoading(this.props.linkedEventOrganizersRecord)
    );
  };

  onSaveClick = () => {
    const { id, roleDetail } = this.props.accountDetail;
    this.props.accountSettingsActions.updateRecord(id, { roleDetail });
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const isSuccess = Record.isSuccess(this.props.recordEdit);
    if (isSuccess) {
      this.props.goToPagesList();
    }

    const isPromoteSuccess = Record.isSuccess(this.props.promoteRecord);
    if (isPromoteSuccess) {
      this.handlePromoteSuccess();
    }

    const isDemoteSuccess = Record.isSuccess(this.props.demoteRecord);
    if (isDemoteSuccess) {
      this.handleDemoteSuccess();
    }

    if (!this.props.isTemplateInited) {
      const account = this.props.accountDetail;

      const model = new TemplateOptions().setModel(account);

      if (this.props.isOrgAdmin) {
        model.setValue(paths.PROMOTE_ADMIN_ROLE, account.roleDetail.restrictExports ? roles.ORGANIZATION_ADMIN_RESTRICTED : roles.ORGANIZATION_ADMIN);
        model.disable(paths.PROMOTE_ADMIN_ROLE);
      }

      if (this.props.isCampaignAdmin) {
        model.setValue(paths.PROMOTE_ADMIN_ROLE, roles.CAMPAIGN_ADMIN);
        model.disable(paths.PROMOTE_ADMIN_ROLE);
      }

      if (this.props.isEventAdmin) {
        model.setValue(paths.PROMOTE_ADMIN_ROLE, roles.EVENT_ADMIN);
        model.disable(paths.PROMOTE_ADMIN_ROLE);
      }

      if (this.props.isEventOrganizerAdmin) {
        model.setValue(paths.PROMOTE_ADMIN_ROLE, roles.EVENT_ORGANIZER_ADMIN);
        model.disable(paths.PROMOTE_ADMIN_ROLE);
      }

      this.props.templateBuilderActions.setTemplateModel(model.getWithRule());
    }

    const promoteButton = this.getPromoteButton();
    const linkedCampaigns = this.getLinkedCampaignItems();
    const linkedEvents = this.getLinkedEventItems();
    const linkedEventOrganizers = this.getLinkedEventOrganizerItems();

    const roleConfiguration = roleConfigurations[this.props.promotionRole];
    const validationErrorMessage =
      roleConfiguration &&
      roleConfiguration.getValidationErrorMessage(this.props);

    return (
      <div>
        <RecordResultMessage
          record={this.props.promoteRecord}
          onDismiss={this.props.accountSettingsActions.clearPromoteRecord}
        />
        <RecordResultMessage
          record={this.props.demoteRecord}
          onDismiss={this.props.accountSettingsActions.clearDemoteRecord}
        />

        <div className="general-conatiner">
          <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
            <div className="detail-container">
              <Card.Content>
                <div>
                  <div className="template-heading">
                    <TemplateHeader
                      as="h3"
                      label={i18nKeys.PROMOTE_AND_DEMOTE_HEADER}
                    />
                  </div>
                  <Form>
                    <div className="promotion-container">
                      <TemplateDropdown
                        id={paths.PROMOTE_ADMIN_ROLE}
                        className="promote-organization"
                        path={paths.PROMOTE_ADMIN_ROLE}
                        label={i18nKeys.PROMOTE_ROLE_LABEL}
                        onChange={this.onChange}
                        options={roleOptions}
                      />
                      {promoteButton}
                    </div>

                    {(this.props.promotionRole === roles.ORGANIZATION_ADMIN || 
                      this.props.promotionRole === roles.ORGANIZATION_ADMIN_RESTRICTED) && (
                      <div className="promotion-container">
                        <TemplateAutocomplete
                          key="promoate-organization-admin"
                          disabled={this.props.isOrgAdmin}
                          className="promote-organization"
                          entity="organization"
                          label={i18nKeys.PROMOTE_ADMIN_ORGANIZATION_TITLE}
                          placeholder={
                            i18nKeys.PROMOTE_ADMIN_ORGANIZATION_PLACEHOLDER
                          }
                          path={paths.PROMOTE_ADMIN_ORGANIZATION_ID}
                          selected="organization"
                          onChange={this.handleOrganizationChange}
                          request={this.getOrganizationRequest}
                          getItem={this.getOrganizationItem}
                        />
                      </div>
                    )}

                    {this.props.promotionRole === roles.CAMPAIGN_ADMIN && (
                      <React.Fragment>
                        <div className="promotion-container">
                          <TemplateAutocomplete
                            key="promoate-campaign-admin"
                            className="promote-organization"
                            entity="page/campaign"
                            label={i18nKeys.PROMOTE_ADMIN_LINKED_CAMPAIGN_TITLE}
                            placeholder={
                              i18nKeys.PROMOTE_ADMIN_LINKED_CAMPAIGN_PLACEHOLDER
                            }
                            path={paths.PROMOTE_ADMIN_CAMPAIGN_ID}
                            selected="campaign"
                            onChange={this.handleCampaignChange}
                            request={this.getCampaignRequest}
                            getItem={this.getCampaignItem}
                            validationErrorMessage={validationErrorMessage}
                          />

                          <Form.Field className="promote-btn">
                            <Button
                              disabled={
                                !get(this.props.linkedCampaign, 'id') ||
                                validationErrorMessage
                              }
                              onClick={this.onLinkedCampaignAdd}
                            >
                              {I18n.t(i18nKeys.LINKED_CAMPAIGN_ADD_BUTTON)}
                            </Button>
                          </Form.Field>
                        </div>
                      </React.Fragment>
                    )}

                    {this.props.promotionRole === roles.EVENT_ADMIN && (
                      <React.Fragment>
                        <div className="promotion-container">
                          <TemplateAutocomplete
                            key="promoate-event-admin"
                            className="promote-organization"
                            entity="page/event"
                            label={i18nKeys.PROMOTE_ADMIN_LINKED_EVENT_TITLE}
                            placeholder={
                              i18nKeys.PROMOTE_ADMIN_LINKED_EVENT_PLACEHOLDER
                            }
                            path={paths.PROMOTE_ADMIN_EVENT_ID}
                            selected="event"
                            onChange={this.handleEventChange}
                            request={this.getEventRequest}
                            getItem={this.getEventItem}
                            validationErrorMessage={validationErrorMessage}
                          />

                          <Form.Field className="promote-btn">
                            <Button
                              disabled={
                                !get(this.props.linkedEvent, 'id') ||
                                validationErrorMessage
                              }
                              onClick={this.onLinkedEventAdd}
                            >
                              {I18n.t(i18nKeys.LINKED_EVENT_ADD_BUTTON)}
                            </Button>
                          </Form.Field>
                        </div>
                      </React.Fragment>
                    )}

                    {this.props.promotionRole ===
                      roles.EVENT_ORGANIZER_ADMIN && (
                      <React.Fragment>
                        <div className="promotion-container">
                          <TemplateAutocomplete
                            key="promoate-event-organizer-admin"
                            className="promote-organization"
                            entity="EventOrganizer"
                            label={
                              i18nKeys.PROMOTE_ADMIN_LINKED_EVENT_ORGANIZER_TITLE
                            }
                            placeholder={
                              i18nKeys.PROMOTE_ADMIN_LINKED_EVENT_ORGANIZER_PLACEHOLDER
                            }
                            path={paths.PROMOTE_ADMIN_EVENT_ORGANIZER_ID}
                            selected="eventOrganizer"
                            onChange={this.handleEventOrganizerChange}
                            request={this.getEventOrganizerRequest}
                            getItem={this.getEventOrganizerItem}
                            validationErrorMessage={validationErrorMessage}
                          />

                          <Form.Field className="promote-btn">
                            <Button
                              disabled={
                                !get(this.props.linkedEventOrganizer, 'id') ||
                                validationErrorMessage
                              }
                              onClick={this.onLinkedEventOrganizerAdd}
                            >
                              {I18n.t(
                                i18nKeys.LINKED_EVENT_ORGANIZER_ADD_BUTTON
                              )}
                            </Button>
                          </Form.Field>
                        </div>
                      </React.Fragment>
                    )}

                    {this.props.promotionRole === roles.CAMPAIGN_ADMIN && (
                      <div className="tags-container">
                        <div className="tags-list-container">
                          {linkedCampaigns}
                        </div>
                      </div>
                    )}

                    {this.props.promotionRole === roles.EVENT_ADMIN && (
                      <div className="tags-container">
                        <div className="tags-list-container">
                          {linkedEvents}
                        </div>
                      </div>
                    )}

                    {this.props.promotionRole ===
                      roles.EVENT_ORGANIZER_ADMIN && (
                      <div className="tags-container">
                        <div className="tags-list-container">
                          {linkedEventOrganizers}
                        </div>
                      </div>
                    )}
                  </Form>
                </div>
                <div className="bottom-space"></div>
              </Card.Content>
            </div>
          </Card>
          {(this.props.isCampaignAdmin || this.props.isEventAdmin) && (
            <Button
              primary
              floated="right"
              id="accountSettingsSaveButton"
              loading={this.props.isSaving}
              onClick={this.onSaveClick}
              style={{ minWidth: '100px' }}
            >
              <Translate value={i18nKeys.SAVE} />
            </Button>
          )}
          <Button floated="right" onClick={this.props.onCancel}>
            <Translate value={i18nKeys.CANCEL} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapState = ({ accountSettings, templateBuilderEx }) => {
  const account = accountSettings.record.data.data;
  return {
    accountDetail: account,

    recordEdit: accountSettings.recordEdit,
    isSaving: Record.isRecordLoading(accountSettings.recordEdit),

    isSysAdmin: account.isSysAdmin,
    isOrgAdmin: account.isOrgAdmin,
    isCampaignAdmin: account.isCampaignAdmin,
    isEventAdmin: account.isEventAdmin,
    isEventOrganizerAdmin: account.isEventOrganizerAdmin,

    isTemplateInited: templateBuilderEx.inited,
    promoteRecord: accountSettings.promoteRecord,
    demoteRecord: accountSettings.demoteRecord,

    organizationId: templateHelper.getValue(
      templateBuilderEx,
      paths.PROMOTE_ADMIN_ORGANIZATION_ID
    ),
    linkedCampaign: templateHelper.getValue(
      templateBuilderEx,
      paths.PROMOTE_ADMIN_CAMPAIGN
    ),
    linkedEvent: templateHelper.getValue(
      templateBuilderEx,
      paths.PROMOTE_ADMIN_EVENT
    ),
    linkedEventOrganizer: templateHelper.getValue(
      templateBuilderEx,
      paths.PROMOTE_ADMIN_EVENT_ORGANIZER
    ),
    promotionRole: templateHelper.getValue(
      templateBuilderEx,
      paths.PROMOTE_ADMIN_ROLE
    ),

    linkedCampaignsRecord: accountSettings.linkedCampaignsRecord,
    linkedCampaigns: accountSettings.linkedCampaigns,

    linkedEventsRecord: accountSettings.linkedEventsRecord,
    linkedEvents: accountSettings.linkedEvents,

    linkedEventOrganizersRecord: accountSettings.linkedEventOrganizersRecord,
    linkedEventOrganizers: accountSettings.linkedEventOrganizers
  };
};

const mapDispatch = (dispatch) => {
  return {
    accountSettingsActions: bindActionCreators(
      accountSettingsActions,
      dispatch
    ),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    clearAutocomplete(path) {
      dispatch(autocompleteOuterClear(path));
    }
  };
};

export default withRouter(connect(mapState, mapDispatch)(EyesOnly));
