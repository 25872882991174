import React, { useEffect } from 'react';
import { Translate } from 'react-redux-i18n';
import { useParams } from 'react-router-dom';

import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import clear from '../../../components/clear';
import BasePageView from '../../../components/common/basePageView';
import { VerticalMenu } from '../../../components/common/VerticalMenu';
import { webhookEditVertical } from '../../../constants/webhook';
import { RouteParams } from '../../../containers/app';
import { useAppDispatch } from '../../../hooks';
import { i18nKeys } from '../../accounts/constants';
import WebhookInputProps from '../WebhookInput';
import { getWebhook } from '../store/webhookActions';

const EditWebhook = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParams>();

  useEffect(() => {
    dispatch(getWebhook(id));
  }, [id]);

  return (
    <BasePageView backLink={`/webhooks`} backLinkTitle="Back to accounts">
      <h1>
        <Translate value={i18nKeys.WEBHOOKS_EDIT} />
      </h1>
      <Grid>
        <GridRow>
          <GridColumn width={5}>
            <VerticalMenu menuItems={webhookEditVertical} />
          </GridColumn>
          <GridColumn width={11}>
            <WebhookInputProps inputType="edit" />
          </GridColumn>
        </GridRow>
      </Grid>
    </BasePageView>
  );
};

export default clear(EditWebhook);
