import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import DatePickerInput from '../form/datePicker';

class DatePicker extends Component {
  onValueChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.path, value);
    }
  };

  render() {
    const label = templateHelper.getTranslation(this.props.label);
    const subtext = templateHelper.getTranslation(this.props.subtext, this.props.subtextTranslationObject);
    const placeholder = templateHelper.getTranslation(this.props.placeholder);

    const { validation, value, optionalParam } = this.props;

    return (
      <DatePickerInput
        id={this.props.id}
        className={this.props.className}
        label={label}
        subtext={subtext}
        value={value || ''}
        placeholder={placeholder}
        onChange={this.onValueChange}
        onClear={this.props.onClear}
        validation={validation}
        required={validation && validation.required}
        templateOptionalParam={optionalParam}
        minDate={this.props.minDate}
        maxDate={this.props.maxDate}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    optionalParam: templateHelper.getValue(
      state.templateBuilderEx,
      ownProps.validation?.optionalParamPath
    )
  };
};

export const TemplateDatePicker = connect(mapState)(DatePicker);
