interface Map {
  [key: string]: string | undefined
}
export const currencyLocaleMap : Map = {
  "AUD": "en-AU",
  "NZD": "en-NZ",
  "JPY": "ja-JP",
  "USD": "en-US",
  "EUR": "en-IE",
  "GBP": "en-GB",
  "CNY": "zh-ZH",
  "CAD": "en-CA",
  "CHF": "it-CH",
  "HKD": "en-HK",
  "SGD": "en-SG",
  "SEK": "en-SE",
  "KRW": "en-KR",
  "INR": "en-IN",
  "MXN": "es-MX",
  "TWD": "en-TW",
  "ZAR": "en-ZA",
  "BRL": "en-BR",
  "DKK": "en-DK",
  "IDR": "id-ID",
  "LBP": "en-LB",
  "THB": "th-TH",
};
