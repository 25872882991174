import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import get from 'lodash.get';

import Button from '../../components/common/button';

import { isAllFieldsValid } from '../../helpers/validationHelper';

import { registerValidationModels } from './constants';

class SaveButtonContainer extends Component {
  isAllFieldsValid = () => {
    const fieldsWithValidationModels = Object.keys(
      registerValidationModels
    ).map((item) => {
      return {
        validationModel: registerValidationModels[item],
        value: get(this.props.model, registerValidationModels[item].path)
      };
    });

    return isAllFieldsValid(fieldsWithValidationModels);
  };

  render() {
    return (
      <Button
        primary
        loading={this.props.isLoading}
        disabled={!this.isAllFieldsValid()}
        onClick={() => this.props.handleSave(this.props.model)}
        type="button"
      >
        <Translate value="template.register.save-button" />
      </Button>
    );
  }
}

const mapState = ({ templateBuilderEx }) => {
  return {
    model: templateBuilderEx.data
  };
};

const SaveButton = connect(mapState)(SaveButtonContainer);
export default SaveButton;
