import React, { Component } from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';

import * as templateBuilderActions from '../../../modules/template-builder-ex';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateText } from '../../../components/template/templateText';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import { TemplateTimePicker } from '../../../components/template/templateTimePicker';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { List, Form } from 'semantic-ui-react';

import config from '../../../constants';
import { getUrl } from '../../../modules/bin/utility';
import { campaignContract } from '../create/templates';

class CampaignCloneTemplateView extends Component {
  constructor(props) {
    super(props);

    this.requiredMaxLength = {
      required: true,
      maxLength: true,
      length: config.URL_PATH_MAX_LENGTH
    };

    this.dateValidation = {
      date: true
    };

    this.timeDetailsValidation = {
      maxLength: true,
      length: config.TIME_DETAILS_MAX_LENGTH
    };
  }

  onChange = (path, value) => {
    this.props.setResultValue(path, value);
  };

  onCampaignChange = (path, value, item) => {
    this.onChange('urlPath', getUrl(value));
    this.onChange(path, value, item);
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  onAllowTimeAndPlaceChange = (path, value) => {
    this.props.changeItemVisibility('dateAndTime', value, false);
    this.props.changeItemVisibility(campaignContract.timeDetails, value, true);
    this.props.changeItemVisibility(campaignContract.place, value, true);

    if (!value) {
      this.onChange(campaignContract.date, null);
      this.onChange(campaignContract.time, null);
      this.onChange(campaignContract.endDate, null);
      this.onChange(campaignContract.endTime, null);
    }

    this.onChange(path, value);
  };

  onIncludeCampaignAdmins = (path, value) => {
    this.setState({
      ...this.state,
      includeCampaignAdmins: value,
    });
  };

  onNewOrg = (path, value) => {
    this.onChange(path, value);
  };
  onNewUrl = (path, value) => {
    this.onChange(path, value);
  };

  getOrganizationRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  getOrganizationItem = (item) => {
    return {
      text: `${item.name} (id#${item.id} - url: ${item.urlPath})`,
      value: item.id
    };
  };

  onOrgSelect = (org) => {
    if (org?.paymentPlatforms?.length === 0) {
      this.setState({
        orgErrorMessage: 'Organisations with payment platforms are required'
      });
      this.onChange(campaignContract.newOrgId, null);
      return;
    } else if (org?.id === this.props.campaign.organization.id) {
      this.setState({
        orgErrorMessage:
          'Selected organization is the same as the current organization'
      });
      this.onChange(campaignContract.newOrgId, null);
      return;
    }
    this.setState({ orgErrorMessage: null });
    this.onChange(campaignContract.newOrg, org);
    this.onChange(campaignContract.newOrgId, org?.id);
  };

  render() {
    const allowNewUrlPath = get(
      this.props.template,
      campaignContract.allowNewUrlPath
    );
    const allowNewOrg = get(this.props.template, campaignContract.allowNewOrg);
    return (
      <TemplateBuilderContainerEx
        onSave={(...args) => this.props.onSave(...args, this.state?.includeCampaignAdmins)}
        isLoading={this.props.isLoading}
      >
        <TemplatePage id="setup" i18nKey="template.page.setup">
          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="template.page.content.setup-clone.header"
            />

            <TemplateText
              label="template.page.content.setup-clone.subheader"
              size="small"
            />
          </div>

          {this.props.isSystemAdmin && (
            <>
              <TemplateHorizontalRule />

              <TemplateCheckbox
                id="includeCampaignAdmins"
                inputLabel="template.page.content.setup-clone.include-campaign-admins.checkbox"
                value={this.state?.includeCampaignAdmins}
                onChange={this.onIncludeCampaignAdmins}
              />

              <TemplateCheckbox
                id="cloneDifferentOrg"
                path={campaignContract.allowNewOrg}
                inputLabel="template.page.content.setup-clone.new-org.checkbox"
                onChange={this.onNewOrg}
              />
            </>
          )}

          {/* needs to be highest level for validation to work.... */}
          {this.props.isSystemAdmin && allowNewOrg && (
            <TemplateAutocomplete
              id="clone-organization"
              entity="organization"
              path={campaignContract.newOrgId}
              label="template.page.content.setup-clone.new-org.list"
              placeholder="template.donation.content.detail.organization.placeholder"
              onChange={(_e, _v, org) => this.onOrgSelect(org)}
              request={this.getOrganizationRequest}
              getItem={this.getOrganizationItem}
              validationErrorMessage={this.state?.orgErrorMessage || ''}
              validation={{ required: true }}
            />
          )}

          {this.props.isSystemAdmin && (
            <TemplateCheckbox
              id="cloneDifferentUrl"
              path={campaignContract.allowNewUrlPath}
              inputLabel="template.page.content.setup-clone.new-url.checkbox"
              onChange={this.onNewUrl}
            />
          )}

          {/* needs to be highest level for validation to work.... */}
          {this.props.isSystemAdmin && allowNewUrlPath && (
            <TemplateInput
              id={campaignContract.newUrlPath}
              path={campaignContract.newUrlPath}
              label="template.page.content.setup-clone.new-url.label"
              subtext="template.page.content.setup-clone.new-url.subtext"
              subtextPosition="bottom"
              validation={this.requiredMaxLength}
              onChange={this.onChange}
            />
          )}

          {this.props.isSystemAdmin && <TemplateHorizontalRule />}

          <TemplateInput
            id="setupCampaignName"
            path={campaignContract.name}
            label="template.page.content.setup-clone.campaign-name.label"
            subtext="template.page.content.setup-clone.campaign-name.subtext"
            placeholder="template.page.content.setup-clone.campaign-name.label"
            validation={this.requiredMaxLength}
            onChange={this.onCampaignChange}
          />

          <TemplateHorizontalRule path="timeAndPlace" />

          <TemplateHeader
            as="h3"
            path="timeAndPlace"
            label="template.page.content.setup-clone.time-and-place.header"
          />

          <TemplateCheckbox
            id="fundraisersFundraising"
            path={campaignContract.allowTimeAndPlace}
            inputLabel="template.page.content.setup-clone.time-and-place.checkbox.label"
            onChange={this.onAllowTimeAndPlaceChange}
          />

          <Form.Group
            widths="equal"
            path="dateAndTime"
            validation={[
              this.getChildValidation(campaignContract.date, {
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              })
            ]}
          >
            <TemplateDatePicker
              id="setupDate"
              path={campaignContract.date}
              label="template.page.content.setup-clone.time-and-place.date.label"
              subtext="template.page.content.setup-clone.time-and-place.date.subtext"
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.time, null)}
              validation={{
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              }}
            />

            <TemplateTimePicker
              id="setupTime"
              path={campaignContract.time}
              label="template.page.content.setup-clone.time-and-place.time.label"
              subtext="template.page.content.setup-clone.time-and-place.time.subtext"
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group widths="equal" path="dateAndTime">
            <TemplateDatePicker
              id="setupDate"
              path={campaignContract.endDate}
              label="template.page.content.setup-clone.time-and-place.endDate.label"
              subtext="template.page.content.setup-clone.time-and-place.endDate.subtext"
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.endTime, null)}
              validation={{
                endDateRange: true,
                optionalParamPath: campaignContract.date
              }}
            />

            <TemplateTimePicker
              id="setupTime"
              path={campaignContract.endTime}
              label="template.page.content.setup-clone.time-and-place.endTime.label"
              subtext="template.page.content.setup-clone.time-and-place.endTime.subtext"
              onChange={this.onChange}
            />
          </Form.Group>

          <TemplateInput
            id="setupTimeDetails"
            path={campaignContract.timeDetails}
            label="template.page.content.setup-clone.time-and-place.time-details.label"
            placeholder="template.page.content.setup-clone.time-and-place.time-details.placeholder"
            validation={this.timeDetailsValidation}
            onChange={this.onChange}
          />

          <TemplateInput
            id="setupLocation"
            path={campaignContract.place}
            label="template.page.content.setup-clone.time-and-place.place.label"
            subtext="template.page.content.setup-clone.time-and-place.place.subtext"
            validation={this.locationValidation}
            onChange={this.onChange}
          />

          <TemplateHorizontalRule path="timeAndPlace" />

          <TemplateHeader
            as="h4"
            label="template.page.content.setup-clone.notes.header"
          />

          <List bulleted>
            <List.Item className="padding-left-style">
              {I18n.t('template.page.content.setup-clone.notes.sentence-1')}
            </List.Item>
            <List.Item className="padding-left-style">
              {I18n.t('template.page.content.setup-clone.notes.sentence-2')}
            </List.Item>
            <List.Item className="padding-left-style">
              {I18n.t('template.page.content.setup-clone.notes.sentence-3')}
            </List.Item>
            <List.Item className="padding-left-style">
              {I18n.t('template.page.content.setup-clone.notes.sentence-4')}
            </List.Item>
            <List.Item className="padding-left-style">
              {I18n.t('template.page.content.setup-clone.notes.sentence-5')}
            </List.Item>
          </List>
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = ({ session, campaign, templateBuilderEx }) => {
  return {
    campaign: campaign.record.data.data,
    template: templateBuilderEx.data || {},
    isSystemAdmin: session?.isSystemAdmin
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const CampaignCloneTemplate = withRouter(
  connect(mapState, mapDispatch)(CampaignCloneTemplateView)
);
export default CampaignCloneTemplate;
