/* eslint-disable no-useless-escape */
import React from 'react';

import { v4 as uuidv4 } from 'uuid';
import url from 'url';
import get from 'lodash.get';

import TextInput from '../../form/input';
import { Icon } from 'semantic-ui-react';
import TwitchVideo from '../twitchVideo';

class AboutVideoAdd extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validationErrorMessage: null,
      inputValue: props.value
    };
  }

  componentDidMount() {
    if (this.props.value) {
      const match = this.props.value.match(
        /^(?:(https?):\/\/)?(?:www\.)?twitch\.tv\/(videos\/)?([a-z,(\d+),_]*)/
      );
      if (match) {
        this.setState({
          type: 'twitch',
          targetID: uuidv4(),
          videoId: match[2] ? match[3] : null,
          channelId: !match[2] ? match[3] : null
        });
      }
    }
  }

  onVideoAdded = (event) => {
    const value = event.target.value;
    let match = value.match(
      /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
    );

    if (match) {
      const parsedUrl = url.parse(value, true);

      const startParam = get(parsedUrl, 'query.start');
      const tParam = get(parsedUrl, 'query.t');
      const endParam = get(parsedUrl, 'query.end');

      this.setState({
        type: 'youtube',
        videoURLIsValid: null
      });

      const startParamString =
        tParam || startParam ? `&start=${tParam || startParam}` : '';
      const endParamString = endParam ? `&end=${endParam}` : '';

      this.props.onValueChange(
        `https://www.youtube.com/embed/${match[5]}?autoplay=0${startParamString}${endParamString}`
      );
    }
    if (
      (match = value.match(
        /^(?:(https?):\/\/)?(?:www\.)?(player\.)?vimeo\.com\/(video\/)?(\d+)/
      ))
    ) {
      // eslint-disable-line no-cond-assign
      this.setState({
        type: 'vimeo',
        videoURLIsValid: null
      });
      this.props.onValueChange(
        (match[1] || 'https') + '://player.vimeo.com/video/' + match[4] + '/'
      );
    }
    if (
      (match = value.match(
        /^(?:(https?):\/\/)?(?:www\.)?twitch\.tv\/(videos\/)?([a-z,(\d+),_]*)/
      ))
    ) {
      // eslint-disable-line no-cond-assign
      this.setState({
        type: 'twitch',
        targetID: uuidv4(),
        videoURLIsValid: null,
        videoId: match[2] ? match[3] : null,
        channelId: !match[2] ? match[3] : null
      });

      this.props.onValueChange(value);
    }
    if (
      (match = value.match(
        /^(?:(https?):\/\/)?(?:www\.)?twitch\.tv\/(videos\/)?([a-z,(\d+),_]*)/
      ))
    ) {
      // eslint-disable-line no-cond-assign
      this.setState({
        type: 'twitch',
        targetID: uuidv4(),
        videoURLIsValid: null,
        videoId: match[2] ? match[3] : null,
        channelId: !match[2] ? match[3] : null
      });

      this.props.onValueChange(value);
    }

    if (value.length > 0) {
      this.setState({
        validationErrorMessage: 'Video URL is invalid'
      });
    } else {
      this.setState({
        validationErrorMessage: null
      });
    }
  };

  onRemove = () => {
    this.props.onValueChange('');
    this.setState({
      validationErrorMessage: null,
      inputValue: ''
    });
  };

  onInputChange = (event, { value }) => {
    this.setState({ inputValue: value });
  };

  render() {
    const videoPresent = this.props.value && this.props.value.length > 0;

    return (
      <div>
        {
          // !videoPresent &&
          <TextInput
            id="aboutVideoURL"
            validationErrorMessage={
              !videoPresent && this.state.validationErrorMessage
            }
            label="Video URL"
            onBlur={this.onVideoAdded}
            onChange={this.onInputChange}
            subtext="Copy and paste the URL of any public Youtube, Twitch or Vimeo video."
            value={this.state.inputValue}
          />
        }
        {videoPresent && this.state.type !== 'twitch' && (
          <iframe
            title="About-Video-Add"
            style={{ minHeight: '300px', width: '100%' }}
            src={this.props.value}
          ></iframe>
        )}
        {videoPresent && this.state.type === 'twitch' && (
          <TwitchVideo
            width="100%"
            height={300}
            targetID={this.state.targetID}
            video={this.state.videoId}
            channel={this.state.channelId}
            autoplay={false}
          />
        )}
        {videoPresent && this.props.onRemove && (
          <div style={{ textAlign: 'right', color: 'rgb(238, 45, 42)' }}>
            <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
              <Icon color="red" name="remove" />
              Remove video
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default AboutVideoAdd;
