import React, { useState } from 'react';
import constants from '../../constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  HtttpRequestMethods,
  sendRequest
} from '../campaign/create/merchandise/api/request';
import { ApiError } from '../campaign/create/merchandise/merchandise.model';
import { ExportModal } from './export-modal';
import { parseFilters } from './models';
import SettingsManager, {
  SETTINGS_KEYS
} from '../../modules/bin/settingsManager';
import { Translate } from 'react-redux-i18n';
import { Message } from 'semantic-ui-react';

export type ApiVersions = 'v2' | 'v3' | 'v3';
export type ExportDownloadEntities =
  | 'report'
  | 'Account'
  | 'page/Campaign'
  | 'page'
  | 'donation'
  | 'Organization'
  | 'SaleDetail'
  | 'donationSubscription'
  | 'EventAmbassadorProgram';

export interface ExportDownloadRenderProps<T extends object = object> {
  submitting: boolean;
  open: (args: T) => void;
}

export interface ExportDownloadProviderProps {
  entity: ExportDownloadEntities;
  version?: ApiVersions;
  children: (props: ExportDownloadRenderProps) => React.ReactNode;
}

export interface CreateExportDownloadResult<T extends object = object> {
  data: T;
  error: ApiError[];
}

export interface CreateExportDownloadArgs<T extends object = object> {
  version?: ApiVersions;
  entity: ExportDownloadEntities;
  body?: T;
}

export async function createExportDownload<T extends object = object>(
  apikey: string,
  sessionKey: string,
  { version = 'v3', entity, body }: CreateExportDownloadArgs<T>
): Promise<CreateExportDownloadResult> {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${constants.baseApiHost}/api/${version}/${entity}/export/async`,
    method: HtttpRequestMethods.Post,
    body
  }).then((response) => response.json());
}

export const ExportDownloadProvider: React.FunctionComponent<
  ExportDownloadProviderProps
> = ({ children, entity, version }) => {
  const apiKey = constants.apikey || '';
  const sessionKey = useSelector<RootState, string>(
    (state) => state?.session?.key || ''
  );
  const [isSubmitting, setSubmitting] = React.useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [values, setValues] = React.useState(null);
  const [message, setMessage] = useState<React.JSX.Element | null>(null);

  const handleOnClose = React.useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const handleOnCreateExport = React.useCallback(
    async (body = {}) => {
      setSubmitting(true);
      await createExportDownload(apiKey, sessionKey, {
        entity,
        version,
        body
      });
      setIsOpen(false);
      setSubmitting(false);
      setMessage(
        <Translate
          value="account.export-history.queued"
          dangerousHTML
          {...{ link: '/export-history' }}
        />
      );
      setTimeout(() => {
        setMessage(null);
      }, 5000);
    },
    [setSubmitting, setMessage, setIsOpen]
  );

  const handleSubmitExport = React.useCallback(() => {
    handleOnCreateExport(values);
  }, [values, handleOnCreateExport]);

  const open = React.useCallback(
    (args = {}) => {
      const newArgs = {
        ...args,
        filters: parseFilters(args?.filters)
      };

      const exportSettings = SettingsManager.getSettingsByKey(
        SETTINGS_KEYS.EXPORT,
        'hideModal'
      );

      if (exportSettings) {
        handleOnCreateExport(newArgs);
      } else {
        setValues(newArgs);
        setIsOpen(true);
      }
    },
    [setValues, setIsOpen, handleOnCreateExport]
  );

  return (
    <>
      {message && (
        <div className="snackbar-container">
          <Message positive>
            <Message.Header> {message}</Message.Header>
          </Message>
        </div>
      )}
      {isOpen ? (
        <ExportModal
          submitting={isSubmitting}
          onSubmit={handleSubmitExport}
          onClose={handleOnClose}
        />
      ) : null}
      {children({ open, submitting: isSubmitting })}
    </>
  );
};
