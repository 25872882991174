import React from 'react';

import { TemplatePage } from '../../../../components/common/pager';
import { TemplateWaiverBuilder } from '../../../../components/template/custom/templateWaiverBuilder';
import { TemplateCheckbox } from '../../../../components/template/templateCheckbox';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateInput } from '../../../../components/template/templateInput';
import { TemplateText } from '../../../../components/template/templateText';

import { paths } from '../constants/template';

export const getTermsAndConditionsTab = (props) => {
  const {
    required,
    onChange,
    waiverBuilderValidation,
    onAllowTermsAndConditionsChange,
    onAllowWaiversChange
  } = props;

  return (
    <TemplatePage
      id="termsAndConditions"
      i18nKey="programs.template.page.termsAndConditions.title"
    >
      {/* TODO: re-add once signature API changes have been implemented */}
      {/* <TemplateHeader
        as="h2"
        label="programs.template.page.termsAndConditions.signature-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.termsAndConditions.signature-section.subheader"
      />

      <TemplateCheckbox
        id={paths.SIGNATURE_VERIFICATION_ENABLED}
        path={paths.SIGNATURE_VERIFICATION_ENABLED}
        inputLabel="programs.template.page.termsAndConditions.signature-section.checkbox"
        onChange={onChange}
      /> */}

      <TemplateHeader
        as="h2"
        label="programs.template.page.termsAndConditions.terms-and-conditions-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.termsAndConditions.terms-and-conditions-section.subheader"
      />

      <TemplateCheckbox
        id={paths.TERMS_AND_CONDITIONS_ENABLED}
        path={paths.TERMS_AND_CONDITIONS_ENABLED}
        inputLabel="programs.template.page.termsAndConditions.terms-and-conditions-section.checkbox"
        onChange={onAllowTermsAndConditionsChange}
      />

      <TemplateInput
        id="setupTermsAndConditionsTitle"
        path={paths.TERMS_AND_CONDITIONS_TITLE}
        label="programs.template.page.termsAndConditions.terms-and-conditions-section.title-name.label"
        subtext="programs.template.page.termsAndConditions.terms-and-conditions-section.title-name.subtext"
        subtextPosition="top"
        onChange={onChange}
      />

      <TemplateInput
        id="setupTermsAndConditionsLink"
        path={paths.TERMS_AND_CONDITIONS_LINK}
        // TODO - Determine if 'urlValidation' is needed
        // validation={...} was called twice and casuing issues during build
        // The last value used should override any previous values so not an issue, just need clarification
        // validation={urlValidation}
        label="programs.template.page.termsAndConditions.terms-and-conditions-section.information-name.label"
        subtext="programs.template.page.termsAndConditions.terms-and-conditions-section.information-name.subtext"
        subtextPosition="top"
        onChange={onChange}
        validation={required}
      />

      <TemplateHeader
        as="h2"
        label="template.page.content.termsAndConditions.waivers.header"
      />

      <TemplateText
        size="small"
        label="template.page.content.termsAndConditions.waivers.label"
      />

      <TemplateCheckbox
        id={paths.WAIVERS_ENABLED}
        path={paths.WAIVERS_ENABLED}
        inputLabel="template.page.content.termsAndConditions.waivers.checkbox"
        onChange={onAllowWaiversChange}
      />

      <TemplateWaiverBuilder
        id="waivers"
        path={paths.WAIVERS}
        validation={waiverBuilderValidation}
        onChange={onChange}
      />
    </TemplatePage>
  );
};
