import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash.get';

import { CardElement } from 'react-stripe-elements';
import { Form, Message } from 'semantic-ui-react';

export class CardView extends React.Component {
  onCardChange = (card) => {
    const model = {
      ...this.props.value,
      ...card
    };
    this.props.onChange(this.props.path, model);
  };

  render() {
    return (
      <Form.Field>
        <CardElement
          className="card-element"
          id="card"
          onChange={this.onCardChange}
          hidePostalCode
        />
        <Message negative hidden={!this.props.errorMessage}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </Form.Field>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: get(state.templateBuilderEx, `data.${ownProps.path}`),
    errorMessage: get(
      state.templateBuilderEx,
      `data.${ownProps.path}.error.message`
    )
  };
};

const mapDispatch = (dispatch) => {
  return {};
};

const Card = connect(mapState, mapDispatch)(CardView);
export default Card;
