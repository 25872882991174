export const requiredField = {
  required: true
};

export enum activeIntegrationType {
  fundraiser = 'fundraiserSetup',
  donation = 'donationSetup'
}

// prettier-ignore
export const fundraiserKey = {
  question: 'event.template.page.active-integration.fundraiser.columns.question',
  answer: 'event.template.page.active-integration.fundraiser.columns.answer',
  instruction: 'event.template.page.active-integration.fundraiser.columns.instruction',
  campaign: 'event.template.page.active-integration.fundraiser.columns.campaign',
  // enabled: 'event.template.page.active-integration.fundraiser.columns.enabled',
  created: 'event.template.page.active-integration.fundraiser.columns.created',
};

export const fundraiserColumns = [
  { header: fundraiserKey.question, value: 'question' },
  { header: fundraiserKey.answer, value: 'answer' },
  { header: fundraiserKey.instruction, value: 'instruction' },
  { header: fundraiserKey.campaign, value: 'campaignId' },
  // { header: fundraiserKey.enabled, value: 'enabled' },
  { header: fundraiserKey.created, value: 'created' }
];

// prettier-ignore
export const donationKey = {
  productName: 'event.template.page.active-integration.donation.columns.product-name',
  price: 'event.template.page.active-integration.donation.columns.price',
  instruction: 'event.template.page.active-integration.donation.columns.instruction',
  campaign: 'event.template.page.active-integration.donation.columns.campaign',
  // enabled: 'event.template.page.active-integration.donation.columns.enabled',
  created: 'event.template.page.active-integration.donation.columns.created',
};

export const donationColumns = [
  { header: donationKey.productName, value: 'productName' },
  { header: donationKey.price, value: 'price' },
  { header: donationKey.instruction, value: 'instruction' },
  { header: donationKey.campaign, value: 'campaignId' },
  // { header: donationKey.enabled, value: 'enabled' },
  { header: donationKey.created, value: 'created' }
];
