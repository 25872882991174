import React from 'react';
import { Table, Checkbox } from 'semantic-ui-react';

class AssociatedCheckbox extends React.Component {
  render() {
    const { disabled, defaultChecked, onChange } = this.props;
    return (
      <Table.Cell collapsing className="associated-cell">
        <Checkbox
          disabled={disabled}
          checked={defaultChecked}
          onChange={onChange}
        />
      </Table.Cell>
    );
  }
}

export default AssociatedCheckbox;
