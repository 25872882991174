enum notificationEntity {
  organization = 1,
  campaign,
  team,
  fundraiser,
  event,
  donation,
  session,
  accessKey,
  microblog,
  ticket,
  sale,
  merchandise,
  account,
  collection,
  donationSubscription,
  fitnessActivity,
  fitnessPlatformLink,
  customActivity,
  eventAmbassador,
  eventAmbassadorProgram,
  campaignAmbassadorProgram,
  eventOrganizer
}

export default notificationEntity;
