import React, { Component } from 'react';

import { Translate } from 'react-redux-i18n';

import ApplicationConfirmationModal from './applicationConfirmationModal';
import Button from '../../../../components/common/button';

import { eventAmbassadorStatus } from '../../constants';
import DigitalSignatureModal from './digitalSignatureModal';

class ApplicationPageActions extends Component {
  onViewSignatureClick() {
    this.props.handleModalStateChange.bind(
      this,
      'isDigitalSignatureModalOpen',
      true
    )();
    this.props.handleViewSignature();
  }

  render() {
    const {
      status,
      isAcceptModalOpen,
      isDigitalSignatureModalOpen,
      handleModalStateChange,
      isRejectModalOpen,
      handleReject,
      handleAccept,
      secureSignatureRecord,
      record
    } = this.props;

    return (
      <div className="application-actions-container">
        <h5>
          <Translate value="applications.application-actions.title" />
        </h5>
        <div className="application-actions-content">
          <div className="status-title">
            <Translate value="applications.application-actions.status-name.label" />
          </div>
          <div className="status-value">{status}</div>
        </div>
        <div className="actions-buttons-container">
          {isAcceptModalOpen && (
            <ApplicationConfirmationModal
              open={isAcceptModalOpen}
              showDisableEmailCheckbox={false}
              translationKey={
                'applications.application-actions.application-confirmation-modal.accept-modal'
              }
              handleConfirm={handleAccept}
              handleClose={handleModalStateChange.bind(
                this,
                'applicationConfirmationModal.isAcceptModalOpen',
                false
              )}
              record={record}
            />
          )}
          {isRejectModalOpen && (
            <ApplicationConfirmationModal
              open={isRejectModalOpen}
              showDisableEmailCheckbox
              disableAutomaticEmail={this.props.disableAutomaticEmail}
              translationKey={
                'applications.application-actions.application-confirmation-modal.reject-modal'
              }
              handleModalStateChange={handleModalStateChange}
              handleConfirm={handleReject}
              handleClose={handleModalStateChange.bind(
                this,
                'applicationConfirmationModal.isRejectModalOpen',
                false
              )}
              record={record}
            />
          )}
          {isDigitalSignatureModalOpen && (
            <DigitalSignatureModal
              open={isDigitalSignatureModalOpen}
              translationKey={
                'applications.application-actions.digital-signature-modal'
              }
              handleModalStateChange={handleModalStateChange}
              handleClose={handleModalStateChange.bind(
                this,
                'isDigitalSignatureModalOpen',
                false
              )}
              record={secureSignatureRecord}
            />
          )}
          {status === eventAmbassadorStatus.CLAIMED && (
            <div>
              <Button
                className="view-digital-signature-button"
                onClick={() => this.onViewSignatureClick()}
              >
                <Translate value="applications.application-actions.buttons.view-digital-signature" />
              </Button>
            </div>
          )}
          {status === eventAmbassadorStatus.RECEIVED && (
            <Button
              className="accept-button"
              onClick={handleModalStateChange.bind(
                this,
                'applicationConfirmationModal.isAcceptModalOpen',
                true
              )}
            >
              <Translate value="applications.application-actions.buttons.accept" />
            </Button>
          )}
          {status !== eventAmbassadorStatus.REJECTED && (
            <Button
              className="reject-button"
              onClick={handleModalStateChange.bind(
                this,
                'applicationConfirmationModal.isRejectModalOpen',
                true
              )}
            >
              <Translate value="applications.application-actions.buttons.reject" />
            </Button>
          )}
        </div>
      </div>
    );
  }
}

export default ApplicationPageActions;
