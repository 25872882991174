import { Record } from '../../modules/bin/utility';
import {
  crudClearErrorHandler,
  getRecordRequestedHandler,
  getRecordSuccessHandler,
  crudErrorHandler,
  createRecord
} from '../../modules/bin/entityHandlers';

export const ERROR_CLEAR = 'backOffice/ERROR_CLEAR';
export const PROCESS_REQUESTED = 'backOffice/PROCESS_REQUESTED';
export const PROCESS_SUCCESS = 'backOffice/PROCESS_SUCCESS';
export const PROCESS_FAILURE = 'backOffice/PROCESS_FAILURE';

interface IBackofficeRequest {
  campaignId?: number;
  eventId?: number;
  emailJourneyForcePageCreated: boolean;
  emailJourneyForceJoinWithADate: boolean;
}

const initialState = {
  record: Record.getDefaultState()
};

// State Reducer
const backofficeReducer = (state = initialState, action: any) => {
  let newState = state;
  switch (action.type) {
    case ERROR_CLEAR:
      newState = crudClearErrorHandler(state, action.payload.key);
      break;

    case PROCESS_REQUESTED:
      newState = getRecordRequestedHandler(state, action);
      break;

    case PROCESS_SUCCESS:
      newState = getRecordSuccessHandler(state, action);
      break;

    case PROCESS_FAILURE:
      newState = crudErrorHandler(state, action);
      break;

    default:
      return newState;
  }

  return newState;
};

export const proccessBackOfficeRequest = (
  model: IBackofficeRequest,
  type: 'event' | 'campaign'
) => {
  return createRecord(
    `/page/${type}/backoffice`,
    PROCESS_REQUESTED,
    PROCESS_SUCCESS,
    PROCESS_FAILURE,
    model,
    null,
    'record',
    'v2'
  );
};

export const clearRecordMessages = () => {
  return {
    type: ERROR_CLEAR,
    payload: { key: 'record' }
  };
};

export default backofficeReducer;
