import React, { Component } from 'react';

import { Dropdown } from 'semantic-ui-react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';

import { countryOptions } from '../../constants/countries';

class FormbuilderCountry extends Component {
  render() {
    const {
      onChange,
      value,
      disabled,
      placeholder,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;

    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <Dropdown
          fluid
          search
          selection
          disabled={disabled}
          options={countryOptions}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderCountry;
