import React, { ReactNode } from 'react';
import { I18n } from 'react-redux-i18n';

import Panel from '../../../components/common/grid/managed/panel';
import { Common } from '../../../components';
import { FeatureFlag } from '../../../modules/featureflags';

interface IHeaderContainerProps {
  name: string;
  isSystemAdmin?: boolean;
  children: ReactNode;
  onRefreshClick: () => void;
  onEditClick: () => void;
  onExportClick: () => void;
}

export const HeaderContainer = ({
  name,
  isSystemAdmin,
  children,
  onRefreshClick,
  onEditClick,
  onExportClick
}: IHeaderContainerProps) => {
  return (
    <div className="grid-top-block-container">
      <div className="grid-top-block" style={{ marginBottom: '0px' }}>
        <h1>{name}</h1>
        <Panel>
          <Common.Button onClick={onRefreshClick} id="refresh">
            {I18n.t('dashboard.panel.refresh.button')}
          </Common.Button>
          {isSystemAdmin && onEditClick && (
            <Common.Button onClick={onEditClick} id="edit">
              {I18n.t('dashboard.panel.edit.button')}
            </Common.Button>
          )}
          {isSystemAdmin && (
            <FeatureFlag feature="exportData">
              <Common.Button onClick={onExportClick} id="export">
                {I18n.t('dashboard.panel.export.button')}
              </Common.Button>
            </FeatureFlag>
          )}
        </Panel>
      </div>
      {children}
    </div>
  );
};
