import config from '../../constants';
import { roles } from '../../constants/roles';

export const tabs = {
  ALL_USERS: 'all',
  ADMINS: 'admins',
  FUNDRAISERS: 'fundraisers',
  DONORS: 'donors',
  DONATIONS: 'donations',
  FUNDRAISING: 'fundraising'
};

export const adminColumns = {
  EMAIL: 'email',
  ACCOUNT_LAST_NAME: 'account.lastName',
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  PERMISSION: 'permission',
  ORGANIZATION_NAME: 'organizationName',
  ORGANIZATION: 'organization.name',
  ROLES: 'roles',
  STATUS: 'status',
  LAST_LOGIN: 'lastLoggedInAt',
  CREATED_AT: 'createdAt',
  CREATED_AT_LOCAL: 'createdAtLocal',
  IS_DONOR: 'isDonor',
  IS_FUNDRAISER: 'isFundraiser',
  IS_ADMIN: 'isAdmin',
  IS_ORG_ADMIN: 'isOrgAdmin',
  ACCOUNT_ID: 'accountId',
  PAGES_COUNT: 'pagesCount',
  AMOUNT_FUNDRAISED: 'raisedAmount',
  ORGANIZATION_ID: 'organizationId',
  ADMIN_ID: 'id',
  AVERAGE_DONATION_AMOUNT: 'averageDonationAmount',
  NUMBER_OF_DONATIONS: 'donationCount',
  CURRENCY: 'currency'
};

export const donationsColumns = {
  DATE: 'donatedAt',
  AMOUNT: 'amount'
};

export const fundraisingColumns = {
  NAME: 'name',
  CAMPAIGN: 'campaignName',
  RAISED_AMOUNT: 'raisedAmount',
  AVERAGE_DONATION: 'averageDonationAmount',
  TOTAL_DONATIONS: 'donationCount'
};

export const settingsMenuItems = {
  ACCOUNT_DETAILS: 'account_detail',
  UPDATE_PASSWORD: 'update_passowrd',
  ACCOUNT_SECURITY: 'account_security',
  CAMPAIGN_ADMIN_LIST: 'campaign_admin_list',
  EYES_ONLY: 'eyes_only',
  EMAIL_NOTIFICATIONS: 'email_notifications'
};

export const securitySettingTabs = {
  START: 'start',
  QR_CODE: 'qr_code',
  VERIFICATION: 'verification_code',
  BACK_UP_CODES: 'back_up_codes',
  FINISH: 'finish'
};

export const securityAuthLinks = {
  APP_STORE: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
  PLAY_STORE:
    'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2'
};

export const i18nKeys = {
  ACCOUNT_TITLE: 'account.title',
  EDIT_ACTION: 'account.edit-action',
  VIEW_ACTION: 'account.view-action',
  IMPERSONATE_ACTION: 'account.impersonate-action',
  ACTION_HEADER: 'account.list.header.action',
  ALL_USERS_TAB_TITLE: 'account.tabs.all-users-title',
  ADMINS_TAB_TITLE: 'account.tabs.admins-title',
  FUNDRAISERS_TAB_TITLE: 'account.tabs.fundraisers-title',
  DONORS_TAB_TITLE: 'account.tabs.donors-title',
  DONATIONS_TAB_TITLE: 'account.tabs.donations-title',
  FUNDRAISING_TAB_TITLE: 'account.tabs.fundraising-title',
  ACCOUNT_SETTINGS_TITLE: 'account.settings.title',
  ACCOUNT_DETAILS_ITEM: 'account.settings.accountDetails',
  ACCOUNT_SECURITY_ITEM: 'account.settings.security',
  EMAIL_NOTIFICATIONS_ITEM: 'account.settings.emailNotifications',
  UPDATE_PASSWORD_ITEM: 'account.settings.updatePassword',
  EYES_ONLY_ITEM: 'account.settings.eyes-only',
  CAMPAIGN_ADMIN_LIST: 'account.settings.campaignAdminList',
  FIRST_NAME: 'account.settings.firstName',
  LAST_NAME: 'account.settings.lastName',
  EMAIL: 'account.settings.email',
  PASSWORD: 'account.settings.password',
  SAVE: 'account.settings.save',
  CANCEL: 'account.settings.cancel',
  PROMOTE: 'account.settings.promote',
  DEMOTE: 'account.settings.demote',
  SELECT_IMAGE_TEXT: 'account.settings.selectImageText',
  IMAGE_FORMAT_TEXT: 'account.settings.imageFormatText',
  ALLOWED_PIXELS_TEXT: 'account.settings.allowedPixelsText',
  PROMOTE_ADMIN_ORGANIZATION_TITLE: 'account.eyes-only.organization.label',
  PROMOTE_ADMIN_ORGANIZATION_PLACEHOLDER:
    'account.eyes-only.organization.placeholder',
  LINKED_CAMPAIGN_ADD_BUTTON: 'account.eyes-only.linkedCampaign.add-button',
  LINKED_EVENT_ADD_BUTTON: 'account.eyes-only.linkedEvent.add-button',
  LINKED_EVENT_ORGANIZER_ADD_BUTTON:
    'account.eyes-only.linkedEventOrganizer.add-button',
  PROMOTE_ADMIN_LINKED_CAMPAIGN_TITLE: 'account.eyes-only.linkedCampaign.label',
  PROMOTE_ADMIN_LINKED_CAMPAIGN_PLACEHOLDER:
    'account.eyes-only.linkedCampaign.placeholder',
  PROMOTE_ADMIN_LINKED_EVENT_TITLE: 'account.eyes-only.linkedEvent.label',
  PROMOTE_ADMIN_LINKED_EVENT_PLACEHOLDER:
    'account.eyes-only.linkedEvent.placeholder',
  PROMOTE_ADMIN_LINKED_EVENT_ORGANIZER_TITLE:
    'account.eyes-only.linkedEventOrganizer.label',
  PROMOTE_ADMIN_LINKED_EVENT_ORGANIZER_PLACEHOLDER:
    'account.eyes-only.linkedEventOrganizer.placeholder',
  PROMOTE_AND_DEMOTE_HEADER: 'account.eyes-only.promoteAndDemote.header',
  PROMOTE_ROLE_LABEL: 'account.eyes-only.promoteAndDemote.promoteRole.label',
  WEBHOOKS_TITLE: 'webhooks.page.view.title',
  WEBHOOKS_CREATE: 'webhooks.page.setup.title',
  WEBHOOKS_EDIT: 'webhooks.page.edit.title',
  WEBHOOKS_VIEW_ACTION: 'webhooks.page.view.view-action',
  WEBHOOKS_EDIT_ACTION: 'webhooks.page.view.edit-action',
  WEBHOOKS_DELETE_ACTION: 'webhooks.page.view.delete-action',
  TERMINAL_TITLE: 'terminal.title',
  TERMINAL_UNASSIGN_ACTION: 'terminal.unassign-action',
  TERMINAL_ASSIGN_TO_ACTION: 'terminal.assign-to-action',
  TERMINAL_EDIT_ACTION: 'terminal.edit-action',
  TERMINAL_DELETE_ACTION: 'terminal.delete-action'
};

export const paths = {
  FIRST_NAME: 'record.data.data.firstName',
  ACTIVE_ITEM: 'activeItem',
  PASSWORD: 'password',
  LAST_NAME: 'record.data.data.lastName',
  EMAIL: 'record.data.data.email',
  TOTOAL_AMOUNT_DONATED_VALUE: 'totalAmountDonated.value',
  TOTOAL_AMOUNT_FUNDRAISED_VALUE: 'totalAmountFundraised.value',
  TOTOAL_AMOUNT_DONATED_ERROR: 'totalAmountDonated.error',
  TOTOAL_AMOUNT_FUNDRAISED_ERROR: 'totalAmountFundraised.error',
  PHOTO_PATH: 'record.data.data.photoPath',
  UPDATE_RECORD_STATE: 'updateRecordState',
  UPLOAD_IMAGE_STATE: 'uploadImageState',
  INVITE_ADMIN_FIRST_NAME: 'firstName',
  INVITE_ADMIN_LAST_NAME: 'lastName',
  INVITE_ADMIN_EMAIL: 'email',
  INVITE_ADMIN_ROLE: 'roles',
  INVITE_ADMIN_ORGANIZATION: 'organizationId',
  INVITE_ADMIN_CAMPAIGN: 'roleDetail.campaignId',
  INVITE_ADMIN_EVENT: 'roleDetail.eventId',
  INVITE_ADMIN_EVENT_ORGANIZER: 'roleDetail.eventOrganizerId',
  INVITE_ADMIN_RESTRICT_EXPORTS: 'roleDetail.restrictExports',
  PROMOTE_ADMIN_ORGANIZATION: 'organization',
  PROMOTE_ADMIN_ORGANIZATION_ID: 'organizationId',
  PROMOTE_ADMIN_CAMPAIGN: 'campaign',
  PROMOTE_ADMIN_EVENT: 'event',
  PROMOTE_ADMIN_CAMPAIGN_ID: 'campaignId',
  PROMOTE_ADMIN_EVENT_ID: 'eventId',
  PROMOTE_ADMIN_EVENT_ORGANIZER: 'eventOrganizer',
  PROMOTE_ADMIN_EVENT_ORGANIZER_ID: 'eventOrganizerId',
  IS_ORG_ADMIN: 'record.data.data.isOrgAdmin',
  IS_CAMPAIGN_ADMIN: 'record.data.data.isCampaignAdmin',
  IS_EVENT_ADMIN: 'record.data.data.isEventAdmin',
  IS_EVENT_ORGANIZER_ADMIN: 'record.data.data.isEventOrganizerAdmin',
  PROMOTE_ADMIN_ROLE: 'promoteRole',
  ORG_ADMIN_DONATION_SUBSCRIPTION_RECEIVED: 'orgAdminDonationSubscriptionReceived',
  ORG_ADMIN_DAILY_SUMMARY_NOTIFICATION: 'orgAdminDailySummaryNotification',
  ORG_ADMIN_DONATION_RECEIVED: 'orgAdminDonationReceived',
  ORG_ADMIN_SALE_CREATED: 'orgAdminSaleCreated',
  ORG_ADMIN_PAGE_CREATED: 'orgAdminPageCreated',
  EMAIL_NOTIFICATIONS: 'emailNotifications'
};

export const fieldNames = {
  RAISED_AMOUNT: 'RaisedAmount',
  AMOUNT: 'Amount'
};

export const upload = {
  AVATAR_TYPE: 'avatar',
  ACCOUNT_ENTITY: 'account'
};

export const getInviteAdminValidation = (role: number) => {
  return {
    ORGANIZATION: {
      required: role === roles.ORGANIZATION_ADMIN,
      path: paths.INVITE_ADMIN_ORGANIZATION
    },
    CAMPAIGN: {
      required: role === roles.CAMPAIGN_ADMIN,
      path: paths.INVITE_ADMIN_CAMPAIGN
    },
    EVENT: {
      required: role === roles.EVENT_ADMIN,
      path: paths.INVITE_ADMIN_EVENT
    },
    EVENT_ORGANIZER: {
      required: role === roles.EVENT_ORGANIZER_ADMIN,
      path: paths.INVITE_ADMIN_EVENT_ORGANIZER
    },
    EMAIL: {
      required: true,
      email: true,
      maxLength: true,
      length: config.ACCOUNT_EMAIL_MAX_LENGTH,
      path: paths.INVITE_ADMIN_EMAIL
    },
    ROLE: {
      required: true,
      path: paths.INVITE_ADMIN_ROLE
    },
    FIRST_NAME: {
      required: true,
      maxLength: true,
      length: config.ACCOUNT_NAME_MAX_LENGTH,
      path: paths.INVITE_ADMIN_FIRST_NAME
    },
    LAST_NAME: {
      required: true,
      maxLength: true,
      length: config.ACCOUNT_NAME_MAX_LENGTH,
      path: paths.INVITE_ADMIN_LAST_NAME
    }
  };
};

export const allUsersAvailableFilters = ['isOrgAdmin', 'isCampaignAdmin'];
export const adminsAvailableFilters = ['isOrgAdmin', 'isCampaignAdmin'];
export const fundraisersAvailableFilters = [
  'pagesCount',
  'raisedAmount',
  'donationCount',
  'averageDonationAmount',
  'activePagesCount'
];
export const donorAvailableFilters = [
  'donationCount',
  'raisedAmount',
  'averageDonationAmount',
  'currency'
];

export const FUNDRAISER_ENTITY = 'account/fundraiser';
export const DONOR_ENTITY = 'account/donor';

export const getTabFromAccountTypes = (account: any) => {
  if (account.isOrgAdmin || account.isSysAdmin) return tabs.ADMINS;

  if (account.isFundraiser) return tabs.FUNDRAISERS;

  return tabs.DONORS;
};

export const permissionMap = new Map([
  ['isCampaignAdmin', (account: any) => 'Campaign Admin'],
  ['isEventAdmin', (account: any) => 'Event Admin'],
  ['isEventOrganizerAdmin', (account: any) => 'Event Organizer Admin'],
  ['isOrgAdmin', (account: any) => account.roleDetail.restrictExports ? 'Org Admin (Restricted)' : 'Organisation Admin' ],
  ['isSysAdmin', (account: any) => 'System Admin']
]);

export const getPermissionsFromAccount = (account: any) =>
  // loop permissionMap and check the permissions are true against the account object
  // for each true value, return a single string of the permission map value with ',' delim
  Array.from(permissionMap.keys())
  .reduce((result, key) => {
      const mapValue = permissionMap.get(key);
      if (account[key] && mapValue) result.push(mapValue(account));
      return result;
    }, [] as string[])
    .join(', ');

export const accountsUrl = {
  [tabs.ALL_USERS]: {
    url: 'all',
    recordUrl: '',
    type: 'all',
    view: 'fundraising'
  },
  [tabs.ADMINS]: {
    url: 'admin',
    recordUrl: '',
    type: 'admin',
    view: 'fundraising'
  },
  [tabs.FUNDRAISERS]: {
    url: 'fundraiser',
    recordUrl: 'fundraiser',
    type: 'fundraiser',
    view: 'fundraising'
  },
  [tabs.DONORS]: {
    url: 'donor',
    recordUrl: 'donor',
    type: 'donor',
    view: 'donations'
  }
};

export const countryCode = {
  au: 'AU',
  nz: 'NZ'
};

export const subscriptionOptions = {
  in: 'in',
  out: 'out'
};

export const countryCodeOptions = Object.values(countryCode).map((code) => {
  return {
    value: code,
    text: code
  };
});
