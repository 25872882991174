import React from 'react';
import { Icon } from 'semantic-ui-react';

const RecurringStatus = (props) => {
  let color = 'green';
  if (props.value === 'cancelled') {
    color = 'red';
  }
  return (
    <span className="status">
      <Icon color={color} name="circle" />
      <span className="capitalize">{props.value}</span>
    </span>
  );
};

export default RecurringStatus;
