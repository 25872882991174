import { RSAA } from 'redux-api-middleware';
import get from 'lodash.get';
import update from '../../helpers/update';
import * as entityHandlers from './entityHandlers';

import {
  COMPLETE_CREATE_CAMPAIGN_REQUESTED,
  COMPLETE_CREATE_CAMPAIGN_SUCCESS,
  COMPLETE_CREATE_CAMPAIGN_FAILURE
} from '../../pages/campaign/store/campaignTypes';
import {
  COUPON_CREATE_REQUESTED,
  COUPON_CREATE_SUCCESS,
  COUPON_CREATE_FAILURE,
  TICKET_CREATE_REQUESTED,
  TICKET_CREATE_SUCCESS,
  TICKET_CREATE_FAILURE
} from '../../pages/ticketing/store/ticketingTypes';
import {
  CUSTOM_EMAIL_SETTING_CREATE_REQUESTED,
  CUSTOM_EMAIL_SETTING_CREATE_SUCCESS,
  CUSTOM_EMAIL_SETTING_CREATE_FAILURE
} from '../../pages/donation/store/donationTypes';
import constants from '../../constants';
import recordConstants from '../../constants/record';
import { getFullQuestionFields } from '../../helpers/dataCaptureHelper';
import { templateKeys } from '../../constants/templateKeys';
import { campaignContract } from '../../pages/campaign/create/templates';
import { ticketIssuingStyle } from '../../pages/campaign/constants';
import { manageFormbuilderGroups } from '../../helpers/formBuilderHelper';
import { generalFormBuilderPath } from '../../constants/formBuilder';

export const campaignErrorHandler = (state, action, recordKey = null) => {
  const errors = (action.payload.errors || []);
  let errorKeys = [];

  errors.forEach((element) => {
    const error = get(element, 'payload.error');

    if (error) {
      error.forEach((item) => errorKeys.push(item));
    }
  });

  return entityHandlers.updateRecordState(
    state,
    recordKey || action.payload.key,
    {
      ready: { $set: recordConstants.RECORD_STATUS_ERROR },
      error: {
        show: { $set: true },
        errorKeys: { $set: errorKeys }
      }
    }
  );
};

export const executeTicketRequest = (
  endpoint,
  method,
  model,
  dispatch,
  state
) => {
  return dispatch({
    [RSAA]: {
      endpoint,
      method,
      headers: {
        ApiKey: constants.apikey,
        Authorization: `bearer ${state.session.key}`,
        'Content-Type': 'application/json'
      },
      body: model ? JSON.stringify(model) : null,
      types: [
        TICKET_CREATE_REQUESTED,
        {
          type: TICKET_CREATE_SUCCESS,
          payload: async (action, state, res) => {
            if (method === 'POST') {
              const data = await res.json();

              return {
                newTicket: data.data
              };
            }
          }
        },
        {
          type: TICKET_CREATE_FAILURE,
          payload: {
            error: ['ticketing.ticket.failed'],
            status: 400
          }
        }
      ]
    }
  });
};

export const manageTicketsRequest = (campaign, data, dispatch, state) => {
  const deafultEndpoint = `${constants.baseApiHost}/api/v2/ticket`;

  const availableTickets = data.availableTickets || [];
  let tickets = data.tickets || [];

  tickets = tickets.map((ticket, index) => {
    return update.set(ticket, 'template.value.sequence', index);
  });

  const newTickets = tickets.filter((ticket) => !ticket.id);
  const editTickets = tickets.filter((ticket) =>
    availableTickets.some((availableTicket) => availableTicket === ticket.id)
  );
  const deleteTickets = availableTickets.filter(
    (availableTicket) =>
      !tickets.some((ticket) => ticket.id === availableTicket)
  );

  let promises = [];

  const isTicketingEnabled = get(
    campaign,
    `data.${campaignContract.allowTicketing}`
  );
  const isPurchaseTicketUrlEnabled = get(
    campaign,
    `data.${campaignContract.simpleTicketingEnabled}`
  );
  const issuingStyle = get(
    campaign,
    `data.${campaignContract.ticketIssuingStyle}`
  );

  if (isTicketingEnabled && !isPurchaseTicketUrlEnabled) {
    newTickets.forEach((ticket) => {
      if (issuingStyle === ticketIssuingStyle.GroupedToBuyer) {
        ticket = update.set(ticket, generalFormBuilderPath, null);
      }
      ticket = update.set(
        ticket,
        generalFormBuilderPath,
        manageFormbuilderGroups(ticket, generalFormBuilderPath)
      );
      ticket = update.set(ticket, 'fields', getFullQuestionFields(ticket));
      ticket = update.set(ticket, 'template.key', templateKeys.TICKET);

      promises.push(
        executeTicketRequest(
          deafultEndpoint,
          'POST',
          {
            ...ticket,
            enabled: true,
            campaignId: campaign.data.id
          },
          dispatch,
          state
        )
      );
    });

    editTickets.forEach((ticket) => {
      if (issuingStyle === ticketIssuingStyle.GroupedToBuyer) {
        ticket = update.set(ticket, generalFormBuilderPath, null);
      }
      ticket = update.set(
        ticket,
        generalFormBuilderPath,
        manageFormbuilderGroups(ticket, generalFormBuilderPath)
      );
      ticket = update.set(ticket, 'fields', getFullQuestionFields(ticket));

      promises.push(
        executeTicketRequest(
          `${deafultEndpoint}/${ticket.id}`,
          'PUT',
          {
            ...ticket,
            enabled: true,
            campaignId: campaign.data.id
          },
          dispatch,
          state
        )
      );
    });

    deleteTickets.forEach((ticket) => {
      promises.push(
        executeTicketRequest(
          `${deafultEndpoint}/${ticket}`,
          'DELETE',
          null,
          dispatch,
          state
        )
      );
    });
  }

  return promises;
};

export const executeCouponRequest = (
  endpoint,
  method,
  model,
  dispatch,
  state
) => {
  return dispatch({
    [RSAA]: {
      endpoint,
      method,
      headers: {
        ApiKey: constants.apikey,
        Authorization: `bearer ${state.session.key}`,
        'Content-Type': 'application/json'
      },
      body: model ? JSON.stringify(model) : null,
      types: [
        COUPON_CREATE_REQUESTED,
        COUPON_CREATE_SUCCESS,
        {
          type: COUPON_CREATE_FAILURE,
          payload: {
            error: ['ticketing.coupon.failed'],
            status: 400
          }
        }
      ]
    }
  });
};

export const executemailSettingRequest = (
  endpoint,
  method,
  model,
  dispatch,
  state
) => {
  return dispatch({
    [RSAA]: {
      endpoint,
      method,
      headers: {
        ApiKey: constants.apikey,
        Authorization: `bearer ${state.session.key}`,
        'Content-Type': 'application/json'
      },
      body: model ? JSON.stringify(model) : null,
      types: [
        CUSTOM_EMAIL_SETTING_CREATE_REQUESTED,
        CUSTOM_EMAIL_SETTING_CREATE_SUCCESS,
        {
          type: CUSTOM_EMAIL_SETTING_CREATE_FAILURE,
          payload: {
            error: ['donations.customEmailSetting.failed'],
            status: 400
          }
        }
      ]
    }
  });
};

export const manageCouponsRequest = (campaign, data, dispatch, state) => {
  const defaultEndpoint = `${constants.baseApiHost}/api/v2/saleCoupon`;
  const defaultV3Endopint = `${constants.baseApiHost}/api/v3/saleCoupon`;

  const availableCoupons = data.availableCoupons || [];
  const availableCouponInstances = data.availableCouponInstances || [];
  let coupons = data.coupons || [];

  const newCoupons = coupons.filter((coupon) => !coupon.id);
  const editCoupons = coupons.filter((coupon) =>
    availableCoupons.some((availableCoupon) => availableCoupon === coupon.id)
  );
  const deleteCoupons = availableCoupons.filter(
    (availableCoupon) =>
      !coupons.some((coupon) => coupon.id === availableCoupon)
  );

  let promises = [];
  const isCouponsEnabled = get(
    campaign,
    `data.${campaignContract.allowCoupons}`
  );

  if (isCouponsEnabled) {
    if (newCoupons.length > 0) {
      newCoupons.forEach((coupon) => {
        const newCoupon = update.set(
          coupon,
          'firstInstance.code',
          coupon.instances[0].code
        );

        promises.push(
          executeCouponRequest(
            defaultV3Endopint,
            'POST',
            {
              ...newCoupon,
              enabled: true,
              campaignId: campaign.data.id
            },
            dispatch,
            state
          )
        );
      });
    }

    editCoupons.forEach((coupon) => {
      promises.push(
        executeCouponRequest(
          `${defaultEndpoint}/${coupon.id}`,
          'PUT',
          {
            ...coupon,
            enabled: true,
            campaignId: campaign.data.id
          },
          dispatch,
          state
        )
      );
    });
    manageExistingCouponsInstanceRequests(
      editCoupons,
      availableCouponInstances,
      promises,
      dispatch,
      state
    );

    deleteCoupons.forEach((coupon) => {
      promises.push(
        executeCouponRequest(
          `${defaultEndpoint}/${coupon}`,
          'DELETE',
          null,
          dispatch,
          state
        )
      );
    });
  }

  return promises;
};

export const manageEmailSettingRequest = (campaign, dispatch, state) => {
  const data = campaign?.data;
  const donationReceipt = data?.template?.value?.donationReceipt;
  const promises = [];

  if (
    donationReceipt &&
    (donationReceipt?.bannerImageUrl || donationReceipt?.thankYouMessage)
  ) {
    promises.push(
      executemailSettingRequest(
        `${constants.baseApiHost}/api/v2/emailcustomsetting`,
        'PUT',
        {
          campaignid: data?.id,
          type: 'donationReceipt',
          overrideImagePath: donationReceipt?.bannerImageUrl,
          additionalText: donationReceipt?.thankYouMessage
        },
        dispatch,
        state
      )
    );
  }

  return promises;
};

const manageExistingCouponsInstanceRequests = (
  coupons,
  availableCouponInstances,
  promises,
  dispatch,
  state
) => {
  const defaultEndpoint = `${constants.baseApiHost}/api/v2/saleCoupon`;

  if (
    coupons.some((coupon) => coupon.instances && coupon.instances.length > 0)
  ) {
    coupons.forEach((coupon) => {
      if (coupon.instances && coupon.instances.length > 0) {
        coupon.instances.forEach((instance) => {
          if (instance.id) {
            if (instance.isEdited) {
              promises.push(
                executeCouponRequest(
                  `${defaultEndpoint}/${coupon.id}/instance/${instance.id}`,
                  'PUT',
                  {
                    code: instance.code
                  },
                  dispatch,
                  state
                )
              );
            }
          } else {
            promises.push(
              executeCouponRequest(
                `${defaultEndpoint}/${coupon.id}/instance`,
                'POST',
                {
                  code: instance.code
                },
                dispatch,
                state
              )
            );
          }
        });
      }
    });
  }

  let instancesToDelete = [];
  coupons.forEach((coupon) => {
    if (coupon.instances && coupon.instances.length > 0) {
      coupon.instances.forEach((instance) => {
        instancesToDelete.push(instance.id);
      });
    }
  });
  instancesToDelete = availableCouponInstances.filter(
    (available) =>
      !instancesToDelete.some((instance) => instance === available.instanceId)
  );

  if (instancesToDelete.length > 0) {
    instancesToDelete.forEach((instance) => {
      promises.push(
        executeCouponRequest(
          `${defaultEndpoint}/${instance.couponId}/instance/${instance.instanceId}`,
          'DELETE',
          undefined,
          dispatch,
          state
        )
      );
    });
  }
};

export const manageCampaignAdditionalRequests = (
  campaign,
  data,
  dispatch,
  state
) => {
  const ticketsPromises = manageTicketsRequest(campaign, data, dispatch, state);
  const couponsPromises = manageCouponsRequest(campaign, data, dispatch, state);
  // prettier-ignore
  const emailSettingPromise = manageEmailSettingRequest(campaign, dispatch, state);

  const promises = [
    ...ticketsPromises,
    ...couponsPromises,
    ...emailSettingPromise
  ];

  dispatch({
    type: COMPLETE_CREATE_CAMPAIGN_REQUESTED,
    payload: { key: 'recordComplete' }
  });

  Promise.all(promises).then((values) => {
    const errors = values.filter((value) => value?.error === true);
    if (!errors.length) {
      dispatch({
        type: COMPLETE_CREATE_CAMPAIGN_SUCCESS,
        payload: { data: values, key: 'recordComplete' }
      });
    } else {
      dispatch({
        type: COMPLETE_CREATE_CAMPAIGN_FAILURE,
        payload: { key: 'recordComplete', errors: errors, status: 400 }
      });
    }
  });
};

export const executeCampaignRequest = (
  endpoint,
  method,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  data,
  beforeExecuteCallback,
  key
) => {
  return (dispatch, getState) => {
    const state = getState();
    const msg = !data
      ? null
      : beforeExecuteCallback
      ? beforeExecuteCallback(state, data)
      : data;

    dispatch({
      [RSAA]: {
        endpoint,
        method,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json'
        },
        body: msg ? JSON.stringify(msg) : null,
        types: [
          {
            type: actionTypeRequest,
            payload: async (action, state, res) => {
              return {
                key
              };
            }
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const json = await res.json();
              await manageCampaignAdditionalRequests(
                json,
                msg,
                dispatch,
                state
              );
              return {
                ...json,
                key
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                ...json,
                key,
                status: res.status
              };
            }
          }
        ]
      }
    });
  };
};

export const createCampaignRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/'
) =>
  executeCampaignRequest(
    `${constants.baseApiHost}/api/${version}${entity}`,
    'POST',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey
  );

export const updateCampaignRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/'
) =>
  executeCampaignRequest(
    `${constants.baseApiHost}/api/${version}${entity}/${id}`,
    'PUT',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey
  );
