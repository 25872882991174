export const getDashboardCreatedAt = (entity: string, modelRecord: any) => {
  let createdAtLocal = modelRecord?.createdAt;
  if (entity === 'series') {
    // when series we want to grab the earliest date based on campaign.createdAt
    const seriesInstances = modelRecord?.instances;
    const earliestDate = seriesInstances
      ?.filter((item: any) => item.campaign && item.campaign.createdAt)
      ?.map((item: any) => new Date(item.campaign.createdAt))
      ?.sort((a: number, b: number) => a - b)
      ?.shift();

    if (earliestDate) {
      createdAtLocal = earliestDate;
    }
  }
  return createdAtLocal;
};
