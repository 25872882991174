import { nodeTypes, getParent } from './formBuilderHelpers';
/**
 * The state in the store describes the created form.
 * Each  value for a given key in the state is a tree describing the form for that given key.
 * This way we can handle multiple FormBuilders in one single page application.
 * The tree which describes one form has the following structure:
 * Each node of the tree has a type propety.
 * The type propety can be 'root' 'section' or a component type ( for ex.: 'date' ).
 * Each node has a 'children' propety which is an array of nodes.
 * The root node has 'section's as its children, the 'section's have component type childrens.
 * Some component types have one section as its children for conditional support.
 * Example for that is the 'checkbox' component.
 * */
import { CLEAR } from '../../modules/general';

/**
 * We only use two actions to modify our store: EDIT_NODE and ADD_FORMBUILDER.
 * The ADD_FORMBUILDER action can add another key / FormBuilder to the store.
 * The EDIT_NODE action can edit a node in the tree of a FormBuilder.
 * @type {{EDIT_NODE: string, ADD_FORMBUILDER: string}}
 */
export const actionTypes = {
  EDIT_NODE: 'EDIT_NODE',
  ADD_FORMBUILDER: 'ADD_FORMBUILDER',
  CLEAR: 'form-builder/CLEAR',
  FORM_BUILDER_MODEL_SET: 'form-builder/FORM_BUILDER_MODEL_SET'
};
/**
 *
 * @param {Object} node The modified node with the updated propeties.
 * @param {Object} oldNode The old node which should be changed in the updated tree.
 * @param {Object} root The root node of the tree.
 * @param {number} depth Helps with debugging.
 * @returns {Object} root After recursively bubbling up in the tree it will return the updated root.
 */
function updateNode(node, oldNode, root, depth = 0) {
  if (node.type === nodeTypes.root) {
    return { [node.formKey]: node };
  } // Bubble up changes to root node
  const parent = getParent(oldNode, root);
  const newParent = {
    ...parent,
    children: parent.children.map((i) => (i === oldNode ? node : i))
  }; // Replace the child in the parent
  return updateNode(newParent, parent, root, depth + 1);
}

/**
 * The reducer handles our actions according to the description in the top of our page.
 */
export const formBuilders = (state = {}, action) => {
  switch (action.type) {
    case actionTypes.ADD_FORMBUILDER:
      return Object.assign({}, state, { [action.key]: action.newForm });
    case actionTypes.EDIT_NODE:
      return {
        ...state,
        ...updateNode(action.node, action.oldNode, state[action.node.formKey])
      };
    case actionTypes.FORM_BUILDER_MODEL_SET:
      if (action.payload && action.payload.formKey) {
        return {
          ...state,
          [action.payload.formKey]: action.payload
        };
      }
      return state;
    case CLEAR: {
      return {};
    }
    default:
      return state;
  }
};

export const formBuilderActions = {
  setFormBuilderModel: (model) => ({
    type: actionTypes.FORM_BUILDER_MODEL_SET,
    payload: model
  }),

  clear: () => ({
    type: actionTypes.CLEAR
  })
};
