//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

//---------------Components
import { Modal, Form, Checkbox, Button, Icon } from 'semantic-ui-react';

//---------------Constants
import {
  AboutElementTypes,
  AboutElementStringTypes,
  AboutElementIcons
} from '../constants/aboutElementType';
import { i18Keys } from '../constants/translation';

class CreateAboutElementModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutElementType: null
    };
  }

  onChange = (value) => {
    this.setState({
      aboutElementType: value
    });
  };

  onClose = () => {
    this.setState({
      aboutElementType: null
    });
    this.props.onClose();
  };

  onSumbit = () => {
    this.props.onAboutElementAdd(this.state.aboutElementType);
    this.onClose();
  };

  render() {
    const i18Key = 'template.page.content.about-elements';

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        onClose={this.onClose}
        open={this.props.open}
      >
        <Modal.Header>
          <div className="button-wrapper align-right">
            <Button
              icon="close"
              circular
              color="grey"
              compact
              size="mini"
              onClick={this.onClose}
              className="close"
            />
          </div>
          {I18n.t(`${i18Key}.${i18Keys.ADD_NEW_ELEMENT_MODAL_TITLE}`)}
        </Modal.Header>
        <Modal.Content>
          <Form>
            <div className="wizard-items-with-image-container">
              {Object.keys(AboutElementTypes).map((item, index) => {
                const type = AboutElementTypes[item];
                return (
                  <div
                    key={`${type}-${index}`}
                    className="wizard-item-with-image"
                  >
                    <Form.Field
                      onClick={this.onChange.bind(this, type)}
                      style={{ cursor: 'pointer' }}
                      disabled={this.props.disabled}
                    >
                      <div className="wizard-item-image">
                        <Icon name={AboutElementIcons[type]} size="large" />
                      </div>
                      <Checkbox
                        label={
                          <label>
                            <div>{AboutElementStringTypes[type]}</div>
                          </label>
                        }
                        value={type}
                        checked={this.state.aboutElementType === type}
                        onChange={this.onChange.bind(this, type)}
                        radio
                      />
                    </Form.Field>
                  </div>
                );
              })}
            </div>
            <div className="button-wrapper align-center">
              <Button
                primary
                disabled={this.state.aboutElementType === null}
                onClick={this.onSumbit}
                type="button"
              >
                {I18n.t(`${i18Key}.${i18Keys.ADD_NEW_ELEMENT_MODAL_BUTTON}`)}
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

export default CreateAboutElementModal;
