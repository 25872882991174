import React from 'react';

import { TemplatePage } from '../../../../components/common/pager';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateText } from '../../../../components/template/templateText';
import { TemplateTextArea } from '../../../../components/template/templateTextArea';

import { paths } from '../constants/template';

export const getEmailsTab = (props) => {
  const { onChange, optional } = props;

  return (
    <TemplatePage id="emails" i18nKey="programs.template.page.emails.title">
      <TemplateHeader
        as="h2"
        label="programs.template.page.emails.application-received-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.emails.application-received-section.subheader"
      />

      <TemplateTextArea
        id="setupVipProgramSescription"
        path={paths.APPLICATION_RECEIVED}
        label="programs.template.page.emails.application-received-section.label"
        validation={optional}
        onChange={onChange}
        maxLength={400}
      />

      <TemplateHeader
        as="h2"
        label="programs.template.page.emails.application-accepted-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.emails.application-accepted-section.subheader"
      />

      <TemplateTextArea
        id="setupVipProgramSescription"
        path={paths.APPLICATION_ACCEPTED}
        label="programs.template.page.emails.application-accepted-section.label"
        validation={optional}
        onChange={onChange}
        maxLength={400}
      />

      <TemplateHeader
        as="h2"
        label="programs.template.page.emails.application-rejected-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.emails.application-rejected-section.subheader"
      />

      <TemplateTextArea
        id="setupVipProgramSescription"
        path={paths.APPLICATION_REJECTED}
        label="programs.template.page.emails.application-rejected-section.label"
        validation={optional}
        onChange={onChange}
        maxLength={400}
      />
    </TemplatePage>
  );
};
