import { unsupportedCharacters } from '../constants/unsupportedCharacters';

export const encodeUnsupportedCharacters = (value) => {
  let result = value;

  if (value) {
    unsupportedCharacters.forEach((item) => {
      result = value.replaceAll(item.value, item.replacable);
    });
  }

  return result;
};

export const decodeUnsupportedCharacters = (value) => {
  let result = value;

  if (value) {
    unsupportedCharacters.forEach((item) => {
      result = result.replaceAll(item.replacable, item.value);
    });
  }

  return result;
};
