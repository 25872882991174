import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';

class ConfirmationModal extends Component {
  onSubmitClick = () => {
    this.onClose();
    this.props.onSubmit();
  };

  onClose = () => {
    this.props.onClose();
  };

  getStandartButtons = () => {
    return (
      <span>
        <Button onClick={this.onClose}>Cancel</Button>
        <Button primary content="Confirm" onClick={this.onSubmitClick} />
      </span>
    );
  };

  render() {
    const { open, title, hideIcon, content, customButtons } = this.props;
    const buttons = customButtons || this.getStandartButtons();

    return (
      <Modal className="wizard" size="tiny" dimmer="inverted" open={open}>
        <Modal.Header>
          {!hideIcon && (
            <div className="button-wrapper align-right">
              <Button
                icon="close"
                circular
                color="grey"
                compact
                size="mini"
                className="close"
                onClick={this.onClose}
              />
            </div>
          )}
          {title || 'Confirmation'}
        </Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>{buttons}</Modal.Actions>
      </Modal>
    );
  }
}

export default ConfirmationModal;
