import React, { Component } from 'react';
import { Dropdown, Input } from 'semantic-ui-react';

class Lookup extends Component {
  state = {};

  handleItemClick(event, { value, text }) {
    this.setState({ value, text });
  }

  render() {
    const { value, text } = this.state;
    return (
      <Dropdown
        placeholder="Select..."
        value={value}
        text={text}
        icon="none"
        fluid
        selection
        labeled
        className="lookup"
      >
        <Dropdown.Menu>
          <Input icon="search" iconPosition="right" className="search" fluid />
          {this.props.data.map((option, idx) => (
            <Dropdown.Item
              key={idx}
              onClick={this.handleItemClick.bind(this)}
              {...option}
            />
          ))}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default Lookup;
