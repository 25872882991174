//---------------React/Redux
import React from 'react';

//---------------Components
import { Icon } from 'semantic-ui-react';
import TextInput from '../../../form/input';

class AboutGoogleMap extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      validationErrorMessage: null,
      inputValue: props.value
    };
  }

  onMapAdded = (event) => {
    const url = event.target.value;
    const match = url.match(
      /https:\/\/www\.google\.com\/maps(\/.*)*\/embed\?[^"]+/
    );

    if (match) {
      this.props.onValueChange(url);
    }

    if (url.length > 0) {
      this.setState({
        validationErrorMessage: 'Google map URL is invalid'
      });
    } else {
      this.setState({
        validationErrorMessage: null
      });
    }
  };

  onRemove = () => {
    this.props.onValueChange('');
    this.setState({
      validationErrorMessage: null,
      inputValue: ''
    });
  };

  onInputChange = (event, { value }) => {
    this.setState({ inputValue: value });
  };

  render() {
    const mapPresent = this.props.value && this.props.value.length > 0;

    return (
      <div>
        <TextInput
          id="aboutGoogleMapURL"
          validationErrorMessage={
            !mapPresent && this.state.validationErrorMessage
          }
          label="Google map URL"
          onBlur={this.onMapAdded}
          onChange={this.onInputChange}
          subtext="Copy and paste link to any public embed Google map"
          value={this.state.inputValue}
        />
        {mapPresent && (
          <iframe
            title="About-Google-Map"
            style={{ minHeight: '300px', width: '100%' }}
            src={this.props.value}
          ></iframe>
        )}
        {mapPresent && this.props.onRemove && (
          <div style={{ textAlign: 'right', color: 'rgb(238, 45, 42)' }}>
            <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
              <Icon color="red" name="remove" />
              Remove
            </span>
          </div>
        )}
      </div>
    );
  }
}

export default AboutGoogleMap;
