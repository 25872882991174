import RecordConstants from './record';
import ListConstants from './list';

export const Record = RecordConstants;
export const List = ListConstants;

const config = {
  baseApiHost: process.env.REACT_APP_BASE_API_HOST || '',
  apikey: process.env.REACT_APP_API_KEY,
  storagePath: process.env.REACT_APP_STORAGE_HOST,
  DOMAIN_URL: process.env.REACT_APP_DOMAIN_URL,
  contactUsUrl: process.env.REACT_APP_CONTACT_US_URL,
  node_env: process.env.REACT_APP_NODE_ENV,
  recaptcha_site_key: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
  fundraiserLoginUrl: process.env.REACT_APP_FUNDRAISER_LOGIN_URL,
  maxImageSize: 10,
  maxPDFSize: 10,
  MIN_PASSWORD_LENGTH: 9,
  MAX_PASSWORD_LENGTH: 50,
  GOOGLE_MAPS_API_KEY: 'AIzaSyCOZsK7j24u2j4OLDpMLYkIwrSi_SN7_8A',
  GOOGLE_MAPS_STARTING_SEARCH_RADIUS: 20000,
  DEFAULT_ORGANIZATION_DASHBOARD_URL: '/organizations/dashboard',
  DEFAULT_CAMPAIGNS_URL: '/campaigns/live',
  DEFAULT_FUNDRAISERS_URL: '/fundraisers/active',
  DEFAULT_PROGRAMS_URL: '/vip-program/programs',
  DEFAULT_SYS_ADMIN_ACCOUNTS_URL: '/accounts/all',
  DEFAULT_ACCOUNTS_URL: '/accounts/admins',
  DEFAULT_DONATIONS_URL: '/donations',
  DEFAULT_RECURRING_URL: '/recurring/active',
  DEFAULT_EVENTS_URL: '/events/live',
  DEFAULT_TAP_N_DONATE_URL: '/tap-n-donate/assigned',
  MAX_TARGET: 999999999.99,
  MAX_DONATION_VALUE: 999999.99,
  CLARITY_KEY: process.env.REACT_APP_CLARITY_KEY,
  GOOGLE_ANALYTICS_KEY: process.env.REACT_APP_GOOGLE_ANALYTICS_KEY,
  GOOGLE_TAG_MANAGER_ID: process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID,
  STRIPE_PUBLIC_KEY: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
  MAX_DONATION_COMMENT_LENGTH: 300,
  MAX_REPLY_COMMENT_LENGTH: 280,
  MAX_PERCENT_VALUE: 100,
  TICKET_TITLE_MAX_LENGTH: 80,
  TICKET_STREAM_MAX_LENGTH: 40,
  DISPLAY_NAME_MAX_LENGTH: 40,
  TICKET_DESCRIPTION_MAX_LENGTH: 280,
  URL_PATH_MAX_LENGTH: 80,
  COUPON_KEY_MAX_LENGTH: 80,
  COUPON_DISCOUNT_CODE_MAX_LENGTH: 20,
  TAGS_MAX_LENGTH: 60,
  JOB_TITLE_MAX_LENGTH: 60,
  CURRENCY_MAX_LENGTH: 3,
  MERCHANDISE_DESCRIPTION_MAX_LENGTH: 280,
  ORGANIZATION_NAME_MAX_LENGTH: 100,
  ORGANIZATION_LEGAL_NAME_MAX_LENGTH: 100,
  ORGANIZATION_TAX_ID_MAX_LENGTH: 20,
  CUSTOM_ACTIVITIES_LABEL_LENGTH: 20,
  ACCOUNT_NAME_MAX_LENGTH: 25,
  ACCOUNT_EMAIL_MAX_LENGTH: 100,
  EVENT_NAME_MAX_LENGTH: 80,
  VIP_PROGRAM_INTRO_TEXT_MAX_LENGTH: 500,
  VIP_PRORGAM_PROGRAM_NAME_MAX_LENGTH: 50,
  VIP_PROGRAM_PARTICIPANT_NAME_MAX_LENGTH: 20,
  GIFTMATCHINGBUILDER_NAME_MAX_LENGTH: 40,
  GIFTMATCHINGBUILDER_DONATION_FROM_MAX_LENGTH: 40,
  GIFTMATCHINGBUILDER_DONATION_MESSAGE_MAX_LENGTH: 280,
  FORMBUILDER_TITLE_MAX_LENGTH: 100,
  FORMBUILDER_QUESTIONS_MAX_LENGTH: 5,
  FORMBUILDER_OPTIONS_MAX_LENGTH: 900,
  FORMBUILDER_EXPORT_LABEL_MAX_LENGTH: 90,
  FORMBUILDER_ADDRESS: {
    STREET_MAX_LENGTH: 100,
    CITY_MAX_LENGTH: 50,
    STATE_MAX_LENGTH: 50,
    POST_CODE_MAX_LENGTH: 10,
    COUNTRY_MAX_LENGTH: 50
  },
  PING_DELAY: 900000,
  PING_INFORMATION_DELAY: 180000,
  DEFAULT_FULL_DATE_FORMAT: 'YYYY/MM/DD HH:mm',
  DEFAULT_FULL_DATE_TIME_PERIODS_FORMAT: 'YYYY/MM/DD h:mm:ss a',
  INVERSION_FULL_DATE_TIME_PERIODS_FORMAT: 'ddd, DD MMM YYYY, hh:mm a',
  DEFAULT_DATE_FORMAT: 'YYYY/MM/DD',
  DAY_MINUS_MONTH_YEAR_FORMAT: 'DD-MMM YYYY',
  DAY_MONTH_YEAR_FORMAT: 'DD MMM YYYY',
  FULL_DATE_TIME: 'YYYY/MM/DD HH:mm:ss.SSS',
  DEFAULT_TIME_FORMAT: 'HH:mm',
  TIME_DETAILS_MAX_LENGTH: 100,
  LOCATION_MAX_LENGTH: 100,
  PDF_FILE_DESCRIPTION_MAX_LENGTH: 100,
  TERMS_AND_CONDITIONS: 'https://grassrootz.com/terms-conditions',
  VERIFICATION_CODE_LENGTH: 6
};

export const utmCodes = {
  utm_medium: 'QR',
  utm_source: 'Grassrootz',
  utm_campaign: 'Dashboard'
};

export default config;
