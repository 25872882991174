import React from 'react';
import RecordResultMessage from './recordResult';

interface IRecordResult {
  key: string;
  data: any;
}

interface IRecordResultMessageListProps {
  records: IRecordResult[];
  onDismiss?: (record: string) => void;
}

export const RecordResultMessageList = ({
  records,
  onDismiss
}: IRecordResultMessageListProps) => {
  return (
    <>
      {records.map((record) => (
        <RecordResultMessage
          record={record.data}
          onDismiss={() => onDismiss && onDismiss(record.key)}
        />
      ))}
    </>
  );
};
