import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Form } from '../..';
import { TemplateInput } from '../../template/templateInput';
import { buttonSetupPaths } from '../constants/paths';

const buttonTypes = [
  {
    text: 'Regular Button',
    value: 'regularButton'
  },
  {
    text: 'Facebook Share',
    value: 'FacebookShare'
  }
];

const REGULAR_BUTTON = 'regularButton';

function ButtonSetup({
  pageTypes,
  specialType,
  link,
  text,
  openInNewWindow,
  visibility,
  campaignType,
  isTeamsEnabled,
  isCollectionsEnabled,
  isFundraisersEnabled,
  index,
  onChange,
  onDeleteClick,
  oneOfPageTypesIsEnabled
}) {
  const session = useSelector((state) => state.session);
  const isSystemAdmin = session.isSystemAdmin;
  specialType = specialType || REGULAR_BUTTON;
  const isRegularButton = specialType === REGULAR_BUTTON;

  const pageTypeOptions = [];

  if (isFundraisersEnabled) {
    pageTypeOptions.push({
      text: 'Fundraiser',
      value: 'fundraiser'
    });
  }

  if (isTeamsEnabled) {
    pageTypeOptions.push({
      text: 'Team',
      value: 'team'
    });
  }

  // Should hide the campaign type when the campaign type is event and visibility is private
  // or isSystemAdmin
  if ((campaignType !== 'event' && visibility !== 'private') || isSystemAdmin) {
    pageTypeOptions.push({
      text: 'Campaign',
      value: 'campaign'
    });
  }

  if (isCollectionsEnabled) {
    pageTypeOptions.push({
      text: 'Superteam',
      value: 'collection'
    });
  }

  // hide any additional buttons for events that were creted by an admin
  // i.e. user is not admin AND pageTypes contains campaign
  if (
    campaignType === 'event' &&
    !isSystemAdmin &&
    pageTypes.some((x) => x === 'campaign')
  ) {
    return null;
  }

  return (
    <div className="collection-container">
      <div className="ui form collection-item">
        <div className="collection-item-title-wrapper">
          <h4 className="item-title">Button {index + 1} Setup</h4>
          <button className="icon-button" onClick={() => onDeleteClick(index)}>
            <Icon name="remove circle" className="right-float" size="large" />
          </button>
        </div>

        <div className="custom item-content">
          <Form.Dropdown
            label="Button Type"
            options={buttonTypes}
            subtext="The type of the button you would like to add in your page"
            value={specialType}
            validation={{
              required: true
            }}
            onChange={(_, object) => {
              onChange(index, buttonSetupPaths.specialType, object.value);
            }}
          />

          {isRegularButton && (
            <TemplateInput
              label="Link"
              value={link}
              subtext="Allow you to add the link of the page for your button"
              onChange={(_, value) => {
                onChange(index, buttonSetupPaths.link, value);
              }}
              validation={{
                required: true
              }}
            />
          )}

          <TemplateInput
            label="Text"
            subtext="Allow you to set the custom button text"
            value={text}
            onChange={(_, value) => {
              if (value.length <= 20) {
                onChange(index, buttonSetupPaths.text, value);
              }
            }}
            validation={{
              required: true
            }}
          />

          {isRegularButton && (
            <Form.Checkbox
              inputLabel="Open in a new window?"
              subtext="Allow the button link to open in a new window"
              checked={openInNewWindow}
              onChange={() => {
                onChange(
                  index,
                  buttonSetupPaths.openInNewWindow,
                  !openInNewWindow
                );
              }}
            />
          )}

          {oneOfPageTypesIsEnabled && (
            <Form.Checkbox
              checked={visibility === 'public'}
              subtext="When this is checked, this setting will allow the current button to display to everyone. When this is unchecked, this button will be displayed ONLY to logged in fundraisers"
              inputLabel="Public"
              onChange={() => {
                onChange(
                  index,
                  buttonSetupPaths.visibility,
                  visibility === 'public' ? 'private' : 'public'
                );
              }}
            />
          )}

          <div className="field">
            <label>Where would you like your button to appear?</label>
            <span className="input-sub">
              Allow you to choose the place you would like the buttons to
              display
            </span>

            {pageTypeOptions.map(({ value, text }) => {
              return (
                <Form.Checkbox
                  key={value}
                  inputLabel={text}
                  checked={pageTypes.includes(value)}
                  onChange={() => {
                    const currnetValueIncludesInPageTypes =
                      pageTypes.indexOf(value) > -1;
                    const newValues = currnetValueIncludesInPageTypes
                      ? pageTypes.filter((pt) => pt !== value)
                      : [...pageTypes, value];
                    onChange(index, buttonSetupPaths.pageTypes, newValues);
                  }}
                />
              );
            })}
            {pageTypes.length === 0 && (
              <div className="ui negative message">
                <p>Please tick the place where the button should be appeared</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ButtonSetup;
