import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as recurringActions from './reducer';
import { organizationActions } from '../organization/store/organizationActions';

import get from 'lodash.get';
import { Record } from '../../modules/bin/utility';
import { I18n } from 'react-redux-i18n';

import { Modal, Button, Loader } from 'semantic-ui-react';

class MyUrlModalContainer extends Component {
  componentDidUpdate() {
    if (this.props.open && Record.notStarted(this.props.organizationRecord)) {
      this.props.getOrganizationRecord(get(this.props, 'organization.id'));
    }
  }

  onClose = () => {
    this.props.hideMyUrlModal();
  };

  getPublicUrl = () => {
    const urlFull = get(this.props.organizationRecord, 'data.data.urlFull');
    return `${urlFull}/recurring/new`;
  };

  render() {
    const isRecordReady = Record.isSuccess(this.props.organizationRecord);
    const url = this.getPublicUrl();

    return (
      <Modal
        className="wizard info-modal"
        size="tiny"
        dimmer="inverted"
        onClose={this.onClose}
        open={this.props.open}
      >
        <Modal.Header>
          <div className="button-wrapper align-right">
            <Button
              icon="close"
              circular
              color="grey"
              compact
              size="mini"
              onClick={this.onClose}
              className="close"
            />
          </div>
        </Modal.Header>
        <Modal.Content>
          <div>
            {!isRecordReady && <Loader active inline className="centered" />}
            {isRecordReady && (
              <React.Fragment>
                <div>{I18n.t('recurring.panel.my-url.modal.content')}</div>
                <br />
                <b className="important-block">
                  <a target="_blank" href={url} rel="noreferrer">
                    {url}
                  </a>
                </b>
              </React.Fragment>
            )}
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ recurring, session, organization }) => {
  return {
    open: recurring.showMyUrlModal,
    organization: session.organization,
    organizationRecord: organization.record
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    hideMyUrlModal() {
      dispatch(recurringActions.changeMyURLModalVisibility(false));
    },
    getOrganizationRecord(id) {
      dispatch(organizationActions.getOrganizationRecord(id));
    }
  };
};

const MyUrlModal = connect(mapState, mapDispatch)(MyUrlModalContainer);
export default MyUrlModal;
