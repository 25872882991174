import React, { Component } from 'react';

class DistanceFormatter extends Component {
  render() {
    if (!this.props.value || !this.props.value[0]) {
      return null;
    }

    return <div>{this.props.value[0]}km</div>;
  }
}

export default DistanceFormatter;
