import React from 'react';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import TemplateEditableUpload from '../../../components/template/templateEditableUpload';

import { paths } from '../constants/template';
import {
  entityTypes,
  iconFormats,
  imageTypes
} from '../../../constants/imageUpload';
import config from '../../../constants';

export const getCustomActivitiesTab = (props) => {
  const {
    customActivitiesValue1Unit,
    onChange,
    onEnableCustomActivitiesChange,
    onEnableCustomActivitiesThermometerChange
  } = props;

  const customActivitiesLabelMaxLength = {
    required: true,
    maxLength: true,
    length: config.CUSTOM_ACTIVITIES_LABEL_LENGTH
  };

  return (
    <TemplatePage
      id="custom-activities"
      i18nKey="template.page.custom-activities"
    >
      <TemplateHeader
        as="h3"
        label="event.template.page.custom-activities.header"
      />

      <TemplateCheckbox
        path={paths.CUSTOM_ACTIVITIES_ENABLED}
        inputLabel="event.template.page.custom-activities.enable"
        onChange={onEnableCustomActivitiesChange}
      />

      <TemplateInput
        path={paths.CUSTOM_ACTIVITIES_VALUE1_NAME}
        label="event.template.page.custom-activities.value1.name"
        validation={customActivitiesLabelMaxLength}
        onChange={onChange}
      />

      <TemplateInput
        path={paths.CUSTOM_ACTIVITIES_VALUE1_UNIT}
        label="event.template.page.custom-activities.unit"
        // validation={customActivitiesLabelMaxLength}
        onChange={onChange}
      />

      <TemplateCheckbox
        path={paths.CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED}
        inputLabel="event.template.page.custom-activities.enableThermometer"
        onChange={onEnableCustomActivitiesThermometerChange}
      />

      <TemplateCurrency
        id="setupCampaignTarget"
        className="padding-left-style"
        path={paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD}
        inputLabel={customActivitiesValue1Unit || ' '}
        label="event.template.page.custom-activities.value1.campaignTarget"
        onChange={onChange}
      />

      <TemplateCurrency
        id="setupFundraiserDefaultTarget"
        className="padding-left-style"
        path={paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD}
        inputLabel={customActivitiesValue1Unit || ' '}
        label="event.template.page.custom-activities.value1.fundraiserDefaultTarget"
        onChange={onChange}
      />

      <TemplateCurrency
        id="setupTeamDefaultTarget"
        className="padding-left-style"
        path={paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD}
        inputLabel={customActivitiesValue1Unit || ' '}
        label="event.template.page.custom-activities.value1.teamDefaultTarget"
        onChange={onChange}
      />

      <TemplateEditableUpload
        id="setupThermometerSvg"
        path={paths.CUSTOM_ACTIVITIES_VALUE1_SVG}
        entity={entityTypes.CAMPAIGN}
        position="left"
        height="150"
        width="150"
        format={iconFormats}
        uploadType={imageTypes.LOGO}
        disableCropping
        label="event.template.page.custom-activities.svg"
        onChange={onChange}
      />

      <TemplateCheckbox
        path={paths.CUSTOM_ACTIVITIES_VALUE1_LEADERBOARD_ENABLED}
        inputLabel="event.template.page.custom-activities.enableLeaderboard"
        onChange={onChange}
      />
    </TemplatePage>
  );
};
