import React, { Component } from 'react';
import { connect } from 'react-redux';

// REDUCERS
import { handleValueChange } from '../reducer';

// COMPONENTS
import VerificationModalWrapper from './verificationModalWrapper';

// CONSTANTS
import { verificationVariant } from '../../../../constants/multiFactorAuth';

class SaveSecurityModal extends Component {
  handleSecurityModalChange = () => {
    this.props.handleValueChange('accountVerification.open');
  };

  handleVerificationClick = (securityVariant) => {
    const { authCode, recoveryCode } = this.props.accountVerification;
    const verificationCode =
      securityVariant === verificationVariant.authCode
        ? authCode
        : recoveryCode;

    this.props.handleVerificationClick(securityVariant, verificationCode);
  };

  render() {
    return (
      <VerificationModalWrapper
        verificationTitle={'Verification Form'}
        verificationButton={'Save Changes'}
        isLoading={this.props.isLoading}
        verificationModalChange={this.handleSecurityModalChange}
        handleVerificationClick={this.handleVerificationClick}
      />
    );
  }
}

const mapState = ({ accountSettings }) => {
  return {
    accountVerification: accountSettings.accountVerification
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleValueChange: (path, value) => {
      dispatch(handleValueChange(path, value));
    }
  };
};

export default connect(mapState, mapDispatch)(SaveSecurityModal);
