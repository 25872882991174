import React from 'react';
import reactCSS from 'reactcss';
import { SketchPicker } from 'react-color';

class ColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = { displayColorPicker: false };
  }

  onColorClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  onCoverClose() {
    this.setState({ displayColorPicker: false });
  }

  onColorChange(color) {
    if (this.props.onChange) {
      this.props.onChange(color.hex);
    }
  }

  formatColor(value) {
    return value && value.r && isNaN(parseInt(value.r, 10)) === false
      ? `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`
      : value;
  }

  render() {
    const color =
      this.formatColor(this.props.value) ||
      this.formatColor(this.props.default);

    const styles = reactCSS({
      default: {
        color: {
          width: '170px',
          height: '30px',
          borderRadius: '2px',
          background: color
        },
        swatch: {
          padding: '7px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        popover: {
          position: 'absolute',
          zIndex: '2'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.onColorClick.bind(this)}>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker && (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.onCoverClose.bind(this)} />
            <SketchPicker
              color={color}
              onChange={this.onColorChange.bind(this)}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ColorPicker;
