import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { get } from "lodash";
import { generalFormBuilderPath, oldCustomFieldCategory, fieldCategoryKey } from "../../../constants/formBuilder";
import { getDataWithQuestionFields } from "../../../helpers/dataCaptureHelper";
import { listReadyStateClear } from "../../../helpers/donationHelper";
import { hasFormbuilderQuestions, getQuestionsFromModel, getCustomFieldsWithAnswers } from "../../../helpers/formBuilderHelper";
import { CLEAR, LIST_READY_STATE_CLEAR } from "../../../modules/general";
import { IMPERSONATE_ADMIN_SUCCESS, DELETE_IMPERSONATE_ADMIN_SUCCESS } from "../../../modules/session";
import { paths } from "../constants";
import { METADATA_REQUESTED, METADATA_SUCCESS, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, METADATA_FAILURE, TOGGLE_COLUMNS_CHANGE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, CREATE_REQUESTED, CREATE_TRANSFER_DONATION_REQUESTED, GET_REQUESTED, UPDATE_REQUESTED, REFUND_REQUESTED, RECEIPT_REQUESTED, DELETE_REQUESTED, DELETE_DONATION_REPLY_REQUESTED, GET_CAMPAIGN_REQUESTED, GET_PAGE_REQUESTED, GET_ORGANIZATION_REQUESTED, UPDATE_DONATION_REPLY_REQUESTED, REFUND_SUCCESS, DELETE_SUCCESS, REFUND_FAILURE, DELETE_FAILURE, RECEIPT_FAILURE, RECEIPT_SUCCESS, RECEIPT_MESSAGE_ERROR, CREATE_SUCCESS, UPDATE_SUCCESS, CREATE_TRANSFER_DONATION_SUCCESS, GET_CAMPAIGN_SUCCESS, GET_PAGE_SUCCESS, GET_ORGANIZATION_SUCCESS, GET_SUCCESS, CREATE_FAILURE, CREATE_TRANSFER_DONATION_FAILURE, UPDATE_FAILURE, GET_FAILURE, GET_CAMPAIGN_FAILURE, GET_PAGE_FAILURE, GET_ORGANIZATION_FAILURE, UPDATE_DONATION_REPLY_FAILURE, DELETE_DONATION_REPLY_FAILURE, DELETE_DONATION_REPLY_SUCCESS, UPDATE_DONATION_REPLY_SUCCESS, ERROR_CLEAR, REFUND_CONFIRMATION_SHOW, REFUND_CONFIRMATION_HIDE, RECEIPT_CONFIRMATION_SHOW, RECEIPT_CONFIRMATION_HIDE, IS_DELETED_CLEAR, IS_REFUNDED_CLEAR } from "./donationTypes";
import { Record } from '../../../modules/bin/utility';
import { gridKeys } from '../../../constants/gridKeys';
import { getDonationGrid } from '../gridConfiguration'
 
const i18nDonationListKey = 'donation.list.header';
const initialState = {
  timestamp: new Date().getTime(),
  exportFileRecord: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  transferDonationRecord: Record.getDefaultState(),
  donationReplyRecord: Record.getDefaultState(),
  record: Record.getDefaultState(),
  organizationRecord: Record.getDefaultState(),
  [gridKeys.DONATIONS]: getDonationGrid(i18nDonationListKey),
};

// State Reducer
export default (state = initialState, action: { type: any; payload: { data: { reply: any; }; key: string | undefined; }; }) => {
  let newState = state;

  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case CREATE_REQUESTED:
    case CREATE_TRANSFER_DONATION_REQUESTED:
    case GET_REQUESTED:
    case UPDATE_REQUESTED:
    case REFUND_REQUESTED:
    case RECEIPT_REQUESTED:
    case DELETE_REQUESTED:
    case DELETE_DONATION_REPLY_REQUESTED:
    case GET_CAMPAIGN_REQUESTED:
    case GET_PAGE_REQUESTED:
    case GET_ORGANIZATION_REQUESTED:
    case UPDATE_DONATION_REPLY_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case REFUND_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'isRefunded', true);
      newState = update.set(newState, 'refundConfirmation', false);

      if (
        newState.record.data &&
        newState.record.data.data &&
        newState.record.data.data.financials
      ) {
        newState = update.set(
          newState,
          'record.data.data.financials.refunded',
          true
        );
      }
      break;

    case DELETE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'refundConfirmation', false);
      newState = update.set(newState, 'isDeleted', true);

      if (
        newState.record.data &&
        newState.record.data.data &&
        newState.record.data.data.financials
      ) {
        newState = update.set(
          newState,
          'record.data.data.financials.refunded',
          true
        );
      }
      break;

    case REFUND_FAILURE:
    case DELETE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'refundConfirmation', false);
      break;

    case RECEIPT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'reissueReceiptConfirmation', false);
      break;

    case RECEIPT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'reissueReceiptConfirmation', false);
      break;

    case RECEIPT_MESSAGE_ERROR:
      newState = entityHandlers.messageErrorHandler(state, action, [
        'receipt.absentEmail'
      ]);
      break;

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case CREATE_TRANSFER_DONATION_SUCCESS:
    case GET_CAMPAIGN_SUCCESS:
    case GET_PAGE_SUCCESS:
    case GET_ORGANIZATION_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      const oldQuestionsPath = `${generalFormBuilderPath}.${oldCustomFieldCategory.DONATION}`;
      const oldQuestions = get(
        newState,
        `record.data.data.${oldQuestionsPath}`
      );

      if (hasFormbuilderQuestions(oldQuestions)) {
        const modelWithNewQuestions = getDataWithQuestionFields(
          get(newState, 'record.data.data'),
          oldQuestionsPath,
          'donor.address',
          'donor.phoneNumber'
        );
        newState = update.set(
          newState,
          'record.data.data',
          modelWithNewQuestions
        );
      }

      const questionsBaseModelPath = get(newState, 'record.data.data.event')
        ? 'event'
        : 'campaign';
      const phoneNumber = get(newState, 'record.data.data.donor.phoneNumber');
      const address = get(newState, 'record.data.data.donor.address');
      const questions = getQuestionsFromModel(
        get(newState, `record.data.data.${questionsBaseModelPath}`),
        fieldCategoryKey.DONATION
      );
      const donationFields = get(newState, 'record.data.data.fields');
      const customFieldsWithAnswers = getCustomFieldsWithAnswers(
        questions,
        donationFields,
        phoneNumber,
        address
      );

      newState = update.set(
        newState,
        `record.data.data.${paths.DONATION_FORMBUILDER}`,
        customFieldsWithAnswers
      );
      break;

    case CREATE_FAILURE:
    case CREATE_TRANSFER_DONATION_FAILURE:
    case UPDATE_FAILURE:
    case GET_FAILURE:
    case GET_CAMPAIGN_FAILURE:
    case GET_PAGE_FAILURE:
    case GET_ORGANIZATION_FAILURE:
    case UPDATE_DONATION_REPLY_FAILURE:
    case DELETE_DONATION_REPLY_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;
    case DELETE_DONATION_REPLY_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'record.data.data.reply.reply', null);
      break;
    case UPDATE_DONATION_REPLY_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(
        newState,
        'record.data.data.reply.reply',
        action.payload.data.reply
      );
      break;
    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case IMPERSONATE_ADMIN_SUCCESS:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
      newState = initialState;
      break;

    case REFUND_CONFIRMATION_SHOW:
      newState = update.set(newState, 'refundDonation', action.payload);
      newState = update.set(newState, 'refundConfirmation', true);
      break;

    case REFUND_CONFIRMATION_HIDE:
      newState = update.set(newState, 'refundConfirmation', false);
      break;

    case RECEIPT_CONFIRMATION_SHOW:
      newState = update.set(newState, 'receiptDonation', action.payload);
      newState = update.set(newState, 'reissueReceiptConfirmation', true);
      break;

    case RECEIPT_CONFIRMATION_HIDE:
      newState = update.set(newState, 'reissueReceiptConfirmation', false);
      break;

    case IS_DELETED_CLEAR:
      newState = update.set(newState, 'isDeleted', false);
      break;

    case IS_REFUNDED_CLEAR:
      newState = update.set(newState, 'isRefunded', false);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.DONATIONS);
      break;

    default:
      return state;
  }

  return newState;
};