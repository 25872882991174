import React from 'react';
import {
  Form,
  Input,
  Message,
  Icon,
  InputOnChangeData
} from 'semantic-ui-react';

interface TextInput {
  id?: string;
  name?: string;
  label?: string;
  inputLabel?: string;
  subtext?: string;
  placeholder?: string;
  subtextPosition?: 'bottom' | 'top';
  validationErrorMessage?: string;
  required?: boolean;
  value?: string;
  showValidationIcon?: boolean;
  error?: boolean;
  errorComponent?: any;
  disabled?: boolean;
  onBlur?: () => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    data: InputOnChangeData
  ) => void;
}

const TextInput = ({
  id,
  name,
  label,
  inputLabel,
  subtext,
  placeholder,
  subtextPosition = 'bottom',
  validationErrorMessage,
  required,
  value,
  showValidationIcon,
  error,
  errorComponent,
  disabled,
  onBlur,
  onChange,
  ...pprops
}: TextInput) => {
  const getValidationIcon = (validationErrorMessage?: string) => {
    return validationErrorMessage ? (
      <Icon name="remove circle" size="large" color="red" />
    ) : (
      <Icon name="check circle" size="large" color="green" />
    );
  };

  const validationIcon = showValidationIcon
    ? getValidationIcon(validationErrorMessage)
    : null;

  return (
    <Form.Field required={required} id={id}>
      {label && (
        <label className={`input-sub-${subtextPosition}`}>{label}</label>
      )}
      {subtext && subtextPosition === 'top' && (
        <div className={`input-sub input-sub-${subtextPosition}`}>
          {subtext}
        </div>
      )}
      <Input
        name={name}
        label={inputLabel}
        value={value}
        disabled={disabled}
        placeholder={placeholder}
        onBlur={onBlur}
        onChange={onChange}
        icon={validationIcon}
        {...pprops}
      />
      {subtext && subtextPosition !== 'top' && (
        <div className={`input-sub input-sub-${subtextPosition}`}>
          {subtext}
        </div>
      )}
      <Message negative hidden={!validationErrorMessage && !error}>
        {errorComponent ? (
          <div>{errorComponent}</div>
        ) : (
          <p>{validationErrorMessage}</p>
        )}
      </Message>
    </Form.Field>
  );
};

export default TextInput;
