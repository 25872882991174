import { routerMiddleware } from 'connected-react-router';
import { configureStore } from '@reduxjs/toolkit';
import { apiMiddleware } from 'redux-api-middleware';
import thunk from 'redux-thunk';
import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore
} from 'react-redux-i18n';
import { createBrowserHistory } from 'history';
import * as generalActions from './modules/general';

import translationsObject from './internationalisation/data.json';
import createRootReducer from './modules/reducers';

export const history = createBrowserHistory();

const preloadedState = {};
const middleware = [thunk, apiMiddleware, routerMiddleware(history)];
const enhancers = [];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.devToolsExtension;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

export const store = configureStore({
  reducer: createRootReducer(history),
  preloadedState,
  middleware,
  enhancers,
});


syncTranslationWithStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

store.dispatch(loadTranslations(translationsObject));
store.dispatch(setLocale(generalActions.determineLocale()));
