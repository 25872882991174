import React from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { FormBuilderSectionRow } from '../../../components/layouts/formBuilderSectionRow';

import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { isArrayWithItems } from '../../../helpers/arrayHelper';
import { displayAddress } from '../../../helpers/templateHelper';
import { RootState } from '../../../store';
import { unknownEntityMap, entityMap } from '../store/ticketingActions';
import { LinkedEntities } from './LinkedEntities';

interface IBillingInformationTableProps {
  showEmptyFields: boolean;
}

export const BillingInformationTable = ({
  showEmptyFields
}: IBillingInformationTableProps) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const recordData = ticketing.record?.data?.data;
  const relatedData = ticketing.relatedRecord?.data?.data;

  const buildEntities = () => {
    const campaign = relatedData?.campaign;
    const relatedFundraisers = relatedData?.fundraisers?.filter(
      (x: any) => x.saleDetailId === null
    );
    // prettier-ignore
    const donations = unknownEntityMap(relatedData?.donations, pageTypesStrings.DONATION);
    // prettier-ignore
    const donationSubs = unknownEntityMap(relatedData?.donationSubscriptions, pageTypesStrings.DONATION_SUB);
    // prettier-ignore
    const fundraisers = entityMap(relatedFundraisers, pageTypesStrings.FUNDRAISER, campaign);
    // prettier-ignore
    const teams = entityMap(relatedData?.teams, pageTypesStrings.TEAM, campaign);

    return [...donations, ...fundraisers, ...teams, ...donationSubs];
  };

  return (
    <div style={{ marginTop: 15 }}>
      <ViewTable
        title={
          <Translate value="ticketing.edit.invoice-information.billing.label" />
        }
      >
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.invoice-information.first-name.label"
          data={recordData?.customer?.firstName}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.invoice-information.last-name.label"
          data={recordData?.customer?.lastName}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.invoice-information.email.label"
          data={recordData?.customer?.email}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.invoice-information.business-name.label"
          data={recordData?.customer?.businessName}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.ticket.address.label"
          data={displayAddress(recordData?.customer?.address)}
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.ticket.phone-number.label"
          data={recordData?.customer?.phoneNumber}
        />
        {isArrayWithItems(recordData?.fields) &&
          React.Children.toArray(
            recordData.fields.map((field: any) => (
              <FormBuilderSectionRow
                isShowEmptyFields={showEmptyFields}
                field={field}
              />
            ))
          )}
        <LinkedEntities entities={buildEntities()} />
      </ViewTable>
    </div>
  );
};
