/* eslint-disable no-useless-escape */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { v4 as uuidv4 } from 'uuid';
import get from 'lodash.get';
import url from 'url';

import YouTube from 'react-youtube';
import Vimeo from '@u-wave/react-vimeo';
import TwitchVideo from '../../common/twitchVideo';

import * as templateHelper from '../templateHelper';
import { Icon } from 'semantic-ui-react';
import ValidatedInput from '../../common/validatedInput';

class CustomVideoSelector extends Component {
  onValueChange(evt, obj) {
    if (this.props.onChange) {
      this.props.onChange(this.props.path, obj.value);
    }
  }

  onRemove = () => {
    if (this.props.onChange) {
      this.props.onChange(this.props.path, '');
    }
  };

  determineType() {
    if (this.props.value) {
      var youtubeResult = this.props.value.match(
        /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/
      );

      if (youtubeResult) {
        const parsedUrl = url.parse(this.props.value, true);

        const code = youtubeResult[5];

        const startParam = get(parsedUrl, 'query.start');
        const tParam = get(parsedUrl, 'query.t');
        const endParam = get(parsedUrl, 'query.end');

        return {
          type: 'youtube',
          code: code,
          opts: {
            playerVars: {
              start: tParam || startParam,
              end: endParam
            }
          }
        };
      }

      var vimeoResult = this.props.value.match(
        /(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i
      );
      if (vimeoResult) {
        return {
          type: 'vimeo',
          code: url
            .parse(this.props.value)
            .path.slice(1)
            .replace(/video\//, '')
            .replace(/\//, '')
        };
      }

      var twitchResult = this.props.value.match(
        /^(?:(https?):\/\/)?(?:www\.)?twitch\.tv\/videos\/(\d+)/i
      );
      if (twitchResult) {
        return {
          type: 'twitch',
          code: url.parse(this.props.value).path.slice(1).split(/\//)[1],
          targetID: uuidv4()
        };
      }
    }
    return null;
  }

  render() {
    const config = this.determineType();

    return (
      <div>
        <ValidatedInput
          id={this.props.id}
          label={this.props.label}
          inputLabel={this.props.inputLabel}
          subtextPosition={this.props.subtextPosition || 'bottom'}
          subtext={this.props.subtext}
          value={this.props.value || ''}
          style={this.props.style || {}}
          validation={
            this.props.validation || {
              url: true,
              required: true
            }
          }
          onChange={this.onValueChange.bind(this)}
        />
        {config && config.type === 'youtube' && (
          <YouTube videoId={config.code} opts={config.opts} />
        )}
        {config && config.type === 'vimeo' && <Vimeo video={config.code} />}
        {config && config.type === 'twitch' && (
          <TwitchVideo
            width={600}
            height={300}
            targetID={config.targetID}
            video={config.code}
            autoplay={false}
          />
        )}
        {this.props.value && config && (
          <div style={{ textAlign: 'right', color: 'rgb(238, 45, 42)' }}>
            <span style={{ cursor: 'pointer' }} onClick={this.onRemove}>
              <Icon color="red" name="remove" />
              Remove video
            </span>
          </div>
        )}
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    subtext: templateHelper.getTranslation(ownProps.subtext)
  };
};

export const TemplateCustomVideoSelector =
  connect(mapState)(CustomVideoSelector);
