import React from 'react';
import { Icon, Popup, SemanticICONS } from 'semantic-ui-react';

interface IViewActionSectionRowProps {
  icon: SemanticICONS;
  title: string;
  onClick?: () => void;
  target?: string;
  href?: string;
  isDisabled?: boolean;
  disabledMessage?: string;
}

const generateContent = () => {};

export const ViewActionSectionRow = ({
  icon,
  title,
  onClick,
  target,
  href,
  isDisabled,
  disabledMessage
}: IViewActionSectionRowProps) => {
  let aProps = {
    onClick,
    href,
    target
  } as any;

  if (isDisabled) {
    aProps = {};
  }

  const content = (
    <div
      className={`donation-action-section-row ${isDisabled ? 'disabled' : ''}`}
    >
      <Icon name={icon} />
      <a {...aProps}>{title}</a>
    </div>
  );

  if (isDisabled && disabledMessage) {
    return (
      <Popup
        content={disabledMessage}
        trigger={content}
        position="bottom center"
      />
    );
  }

  return content;
};
