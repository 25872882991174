import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import get from 'lodash.get';
import DatePickerInput from '../../form/datePicker';
import { I18n } from 'react-redux-i18n';

class DateTimeItem extends Component {
  render() {
    const { path, onChange, value } = this.props;
    return (
      <Form.Group grouped>
        <DatePickerInput
          onChange={(value) => onChange(`${path}.from`, value)}
          value={get(value, 'from') || ''}
          label={I18n.t('filters.componentsLabel.fromDate')}
        />

        <DatePickerInput
          onChange={(value) => onChange(`${path}.to`, value)}
          value={get(value, 'to') || ''}
          label={I18n.t('filters.componentsLabel.toDate')}
        />
      </Form.Group>
    );
  }
}

export default DateTimeItem;
