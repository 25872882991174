import React from 'react';
import get from 'lodash.get';
import Dropzone from 'react-dropzone';
import Styles from './fundraising-resource-upload.module.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as uploadActions from '../../../../../modules/upload';
import { imageTypes } from '../../../../../constants/imageUpload';
import { CloseIcon, FileUploadIcon } from './thumbnail-image';
import { getImagePath } from '../../../../../helpers/imageUrlHelper';
import { Button, Loader, Popup } from 'semantic-ui-react';
import { bytesToSize } from '../../../../../helpers/converterHelper';
import { useCopyToClipboard } from '../hooks';

interface FileUploadRenderProps {
  values: any;
  loading: boolean;
  onRemove: (e: React.MouseEvent<HTMLElement>) => void;
  onDrop: (files: any[]) => void;
}

interface FileUploadProviderProps {
  guid: string;
  entity: string;
  value?: any;
  onChange?: (value: any) => void;
  children: (props: FileUploadRenderProps) => React.ReactNode;
}

interface FileUploadProviderState {
  processing?: boolean;
  fileUrl?: string;
}

interface FileUploadProviderActions {
  uploadFileV2: (
    entity: string,
    type: string,
    id: string | number | null,
    path: string,
    file: any
  ) => void;
  uploadRemove: (path: string) => void;
}

const FileUploadProvider: React.FunctionComponent<
  FileUploadProviderProps & FileUploadProviderActions & FileUploadProviderState
> = (props) => {
  const [state, setState] = React.useState<any>(props.value || {});
  const [loading, setLoading] = React.useState<boolean>(false);

  React.useEffect(() => {
    setState(props.value);
  }, [props.value]);

  React.useEffect(() => {
    if (!props.fileUrl) return;

    setState((current: any) => {
      const next = { ...current, url: `${props.fileUrl}` };
      if (props.onChange) props.onChange(next);
      return next;
    });
  }, [props.fileUrl]);

  React.useEffect(() => {
    setLoading(!!props.processing || (state.file && !state.url));
  }, [props.processing, state?.file, state?.url]);

  const onDrop = React.useCallback(
    (files: File[]) => {
      if (files && files.length === 1) {
        const file = files[0];
        props.uploadFileV2(
          props.entity,
          imageTypes.ASSET,
          null,
          props.guid,
          file
        );
        setState((current: any) => {
          const next = {
            ...current,
            file: {
              name: file.name,
              type: file.type,
              size: file.size,
              lastModified: file.lastModified
            }
          };
          if (props.onChange) props.onChange(next);
          return next;
        });
      }
    },
    [props, setState]
  );

  const onRemove = React.useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      e.preventDefault();
      e.stopPropagation();
      setState({});
      props.uploadRemove(props.guid);
      if (props.onChange) props.onChange(null);
    },
    [props, setState]
  );

  return (
    <>
      {props.children({
        values: state,
        loading,
        onDrop,
        onRemove
      })}
    </>
  );
};

const mapState = (
  { upload }: any,
  ownProps: FileUploadProviderProps
): FileUploadProviderState => {
  return {
    processing: get(upload, `record.state.${ownProps.guid}.processing`),
    fileUrl: get(upload, `record.state.${ownProps.guid}.value`)
  };
};

const mapDispatch = (dispatch: any): FileUploadProviderActions => {
  return {
    ...bindActionCreators(uploadActions as any, dispatch)
  };
};

const ResourceUploadProvider = connect(
  mapState,
  mapDispatch
)(FileUploadProvider);

interface FileUploaderProps {
  guid: string;
  entity: string;
  accept: any;
  value?: any;
  onChange?: (value: any) => void;
}

export const FileUploader: React.FunctionComponent<FileUploaderProps> = (
  props
) => {
  return (
    <ResourceUploadProvider {...props}>
      {({ onDrop, onRemove, values, loading }) => (
        <Dropzone
          accept={props.accept}
          onDrop={onDrop}
          style={{}}
          disabled={loading}
        >
          {values?.file ? (
            <div className={Styles.ResourceUpload}>
              <div className={Styles.ResourceUploadClose} onClick={onRemove}>
                <CloseIcon />
              </div>
              <div className={Styles.ResourceUploadContent}>
                <div className={Styles.ResourceUploadImage}>
                  <FileUploadIcon color="#b7b7b7" />
                </div>
                <table className={Styles.ResourceUploadTable}>
                  <tr>
                    <th className={Styles.ResourceUploadTableHeader}>
                      File URL
                    </th>
                    <td>
                      {loading ? (
                        <Loader active inline size="tiny" />
                      ) : (
                        <FileLink url={values.url} />
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th className={Styles.ResourceUploadTableHeader}>
                      File size
                    </th>
                    <td>{bytesToSize(values.file.size)}</td>
                  </tr>
                </table>
              </div>
            </div>
          ) : (
            <Button primary>Upload file</Button>
          )}
        </Dropzone>
      )}
    </ResourceUploadProvider>
  );
};

interface FileLinkProps {
  url: string;
}

export const FileLink: React.FunctionComponent<FileLinkProps> = ({ url }) => {
  const resourceUrl = getImagePath(url);
  const { copy } = useCopyToClipboard(resourceUrl);
  return (
    <Popup
      basic
      content="Copied"
      on="click"
      position="top center"
      trigger={
        <div
          className={Styles.ResourceUploadFileLink}
          onClick={(e) => {
            e.stopPropagation();
            copy();
          }}
        >
          {resourceUrl}
        </div>
      }
    />
  );
};

export const ImageUploader: React.FunctionComponent<FileUploaderProps> = (
  props
) => {
  return (
    <ResourceUploadProvider {...props}>
      {({ onDrop, values, loading }) => {
        return (
          <Dropzone
            accept={props.accept}
            onDrop={onDrop}
            style={{}}
            disabled={loading}
          >
            {values?.file ? (
              <div className={Styles.ResourceThumbnail}>
                {loading ? (
                  <Loader active inline size="tiny" />
                ) : (
                  <img
                    className={Styles.ResourceThumbnailImage}
                    src={values.url ? getImagePath(values.url) : ''}
                  />
                )}
                <div className={Styles.ResourceThumbnailText}>
                  Change thumbnail
                </div>
              </div>
            ) : (
              <div className={Styles.ResourceThumbnail}>
                <div className={Styles.ResourceThumbnailPlaceholder}>
                  <FileUploadIcon color="#b7b7b7" />
                </div>
                <div className={Styles.ResourceThumbnailText}>
                  Upload thumbnail
                </div>
              </div>
            )}
          </Dropzone>
        );
      }}
    </ResourceUploadProvider>
  );
};
