import get from 'lodash.get';

export const getCampaignClickableColumnUrl = (item) => {
  return item.urlFull;
};

export const getPaymentClickableColumnUrl = (item) => {
  return `https://dashboard.stripe.com/connect/accounts/${item.accountKey}/activity`;
};

export const getCampaignClickableColumnForCampaignTicketing = (item) => {
  return get(item, 'sale.campaign.urlFull');
};

export const getCampaignClickableColumnForCampaignFundraisers = (item) => {
  return get(item, 'campaign.urlFull');
};

export const getCollectionClickableColumnUrl = (item) => {
  return get(item, 'collection.urlFull');
};

export const getTeamClickableColumnUrl = (item) => {
  return get(item, 'team.urlFull');
};

export const getFundraiserClickableColumnUrl = (item) => {
  return get(item, 'fundraiser.urlFull');
};
