import { IInventory } from './merchandise.model';
import { InventoryRemainingAmount, InventoryStates, SkuItemLabels } from './merchandise.constants';
import numbro from 'numbro';

export const isNumeric = (n?: any) => !isNaN(parseFloat(n)) && isFinite(n);

export function formatCurrency(value?: string | number | null) {
  return numbro(isNumeric(value) ? value : 0).format({
    thousandSeparated: true,
    optionalMantissa: true,
    mantissa: 2
  });
}

export function getPrice(value?: string | number | null) {
  return isNumeric(value) ? `$${formatCurrency(value)}` : SkuItemLabels.PriceFree;
}

export function formatQuantity(value?: string | number | null) {
  return numbro(isNumeric(value) ? value : 0).format({
    thousandSeparated: true,
    mantissa: 0
  });
}

export function getQuantity(value?: string | number | null) {
  return value && isNumeric(value) ? formatQuantity(value) : SkuItemLabels.QuantityUnlimited;
}

export function getInventoryQuantity(inventory?: IInventory | null) {
  const quantity = inventory?.quantity;
  if (!quantity || !isNumeric(quantity)) return SkuItemLabels.QuantityUnlimited;
  return formatQuantity(quantity);
}

export function getInventoryState(inventory?: IInventory): InventoryStates | null {
  if (inventory?.soldOut) return InventoryStates.SoldOut;
  if (inventory?.remaining && inventory?.remaining < InventoryRemainingAmount)
    return InventoryStates.Remaining;
  return null;
}
