import { OFFLINE_DONATION_TYPE } from '../campaign/constants';

export const saleShippingType = {
  Default: 'default',
  Express: 'express'
};

export const saleShippingTypeOptions = [
  {
    value: saleShippingType.Default,
    label: 'Default'
  },
  {
    value: saleShippingType.Express,
    label: 'Express'
  }
];

export const saleTypeSettings = {
  CANCELLED: {
    title: 'Cancelled',
    backgroundColor: '#da3030'
  },
  REFUNDED: {
    title: 'Refunded',
    backgroundColor: '#afb0b4'
  },
  PARTIALLY_REFUNDED: {
    title: 'Partially Refunded',
    backgroundColor: '#afb0b4'
  },
  OFFLINE: {
    title: 'Offline',
    backgroundColor: '#afb0b4'
  },
  SUCCESS: {
    title: 'Successful',
    backgroundColor: '#56b58b'
  }
};

export const refundSaleValues = {
  CANCELLED_AND_REFUNDED: 'Cancelled and refunded',
  CANCELLED: 'Cancelled',
  REFUNDED: 'Refunded'
};

export const installmentRefundSaleValues = {
  CANCELLED_BEFORE_COMPLETION: 'cancelledBeforeCompletion',
  INSTALLMETNS_FULL_PAID: 'installmentsFullyPaid'
};

export const refundSaleOptionsI18nKeys = {
  CANCEL_OR_REFUND: {
    label: 'ticket.modals.refund-sale.options.cancel-or-refund.label',
    subtext: 'ticket.modals.refund-sale.options.cancel-or-refund.subtext'
  },
  CANCEL: {
    label: 'ticket.modals.refund-sale.options.cancel.label',
    subtext: 'ticket.modals.refund-sale.options.cancel.subtext'
  },
  REFUND: {
    label: 'ticket.modals.refund-sale.options.refund.label',
    subtext: 'ticket.modals.refund-sale.options.refund.subtext'
  }
};

export const refundSaleOptions = [
  {
    value: refundSaleValues.CANCELLED_AND_REFUNDED,
    label: refundSaleOptionsI18nKeys.CANCEL_OR_REFUND.label,
    subtext: refundSaleOptionsI18nKeys.CANCEL_OR_REFUND.subtext,
    partialRefund: true,
    disabled: (financials, donationType) => {
      const { returned: cancelled, refunded } = financials;
      return refunded || cancelled || donationType === OFFLINE_DONATION_TYPE;
    }
  },
  {
    value: refundSaleValues.CANCELLED,
    label: refundSaleOptionsI18nKeys.CANCEL.label,
    subtext: refundSaleOptionsI18nKeys.CANCEL.subtext,
    disabled: (financials) => {
      const { returned: cancelled } = financials;
      return cancelled;
    }
  },
  {
    value: refundSaleValues.REFUNDED,
    label: refundSaleOptionsI18nKeys.REFUND.label,
    subtext: refundSaleOptionsI18nKeys.REFUND.subtext,
    partialRefund: true,
    disabled: (financials, donationType) => {
      const { refunded } = financials;
      return refunded || donationType === OFFLINE_DONATION_TYPE;
    }
  }
];

export const gridTabs = {
  SALES: 'sales',
  TICKETS: 'tickets',
  MERCHANDISE: 'merchandise'
};

export const i18nKeys = {
  SALES_TAB_TITLE: 'ticketing.tabs.sales-title',
  TICKETS_TAB_TITLE: 'ticketing.tabs.tickets-title',
  MERCHANDISE_TAB_TITLE: 'ticketing.tabs.merchandise-title'
};

export const defaultDetails = [{ quantity: 1, ticket: {} }];

export const salesAvailableFilters = [
  'campaignId',
  'amount',
  'soldAtLocal',
  'type',
  'financials.returned',
  'financials.installments.lastPaymentFailed',
  'financials.installments.paymentCompleted'
];

export const ticketsAvailableFilters = [
  'sale.campaignId',
  'ticketId',
  'soldAtLocal',
  'sale.type',
  'financials.returned'
];


export const merchandiseAvailableFilters = [
  'sale.campaignId',
  'merchandiseId',
  'soldAtLocal',
  'sale.type',
  'financials.returned'
];

export const paymentManualType = "manual";
