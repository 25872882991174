import React from 'react';
import { I18n } from 'react-redux-i18n';

const ColouredMark = (props) => {
  return (
    <div
      className="coloured-mark"
      style={{ backgroundColor: `${props.color}` }}
    >
      <div className="coloured-mark-section">
        <div
          className={`coloured-mark-content ${
            props.marginBottom ? 'page-icon-margin-bottom' : ''
          }`}
        >
          <span className="mark-title-name">{I18n.t(props.title)}</span>
          <span className="mark-sub-title">{I18n.t(props.subTitle)}</span>
        </div>
        <div className="coloured-mark-img">
          <img alt="" src={props.svg} />
        </div>
      </div>
      <div className="coloured-mark-sub-section">
        <span className="mark-amount-value">{props.value}</span>
      </div>
    </div>
  );
};

export default ColouredMark;
