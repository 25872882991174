//---------------React/Redux
import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';

//---------------Components
import { Form, Icon, Dropdown, Message } from 'semantic-ui-react';
import { Form as CommonForm } from '../../';
import ValidatedInput from '../../common/validatedInput';
import GiftMatchingConditionItem from './giftMatchingConditionItem';
import GiftMatchingConditionPageItem from './giftMatchingConditionPageItem';

//---------------Helpers
import * as templateHelper from '../../../components/template/templateHelper';
import update from '../../../helpers/update';

//---------------Constants
import { giftMatchingPath } from '../constants/paths';
import { i18GiftMatchingKeys } from '../constants/translation';
import {
  maxMultiplierValue,
  giftMatchingFields,
  campaignGiftMatchingConditionTypeSettings,
  campaignGiftMatchingConditionOperator,
  campaignGiftMatchingConditions,
  giftMatchingFieldsValidation
} from '../../../constants/giftMatching';
import {
  entityTypes,
  imageFormats,
  imageTypes,
  getFullImagePathByType
} from '../../../constants/imageUpload';

class GiftMatchingItemContainer extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };
  }

  getMultiplierOptions = () => {
    let options = [];

    for (var i = 1; i <= maxMultiplierValue; i++) {
      options.push({
        key: `multiplier-${i}`,
        text: `${i}`,
        value: i
      });
    }

    return options;
  };

  handleConditionChange = (key, checked) => {
    if (checked) {
      const allowedOperators =
        campaignGiftMatchingConditionTypeSettings[key].allowedOperators;
      const newCondition = {
        key: key,
        operator: allowedOperators[0],
        value:
          allowedOperators[0] === campaignGiftMatchingConditionOperator.Between
            ? [null, null]
            : [null]
      };

      const newArray = update(this.props.conditions, {
        $push: [newCondition]
      });
      this.props.onChange(
        this.props.index,
        giftMatchingPath.conditions,
        newArray
      );
    } else {
      const conditionIndex = this.props.conditions.findIndex(
        (condition) => condition.key === key
      );

      if (conditionIndex !== -1) {
        const newArray = update(this.props.conditions, {
          $splice: [[conditionIndex, 1]]
        });
        this.props.onChange(
          this.props.index,
          giftMatchingPath.conditions,
          newArray
        );
      }
    }
  };

  handleConditionValueChange = (valueIndex, key, value) => {
    const conditionIndex = this.props.conditions.findIndex(
      (condition) => condition.key === key
    );

    if (conditionIndex !== -1) {
      const newValue = update(this.props.conditions[conditionIndex].value, {
        [valueIndex]: {
          $set: value
        }
      });

      this.props.onChange(
        this.props.index,
        `${giftMatchingPath.conditions}.${conditionIndex}.value`,
        newValue
      );
    }
  };

  handleConditionOperatorChange = (key, value) => {
    const conditionIndex = this.props.conditions.findIndex(
      (condition) => condition.key === key
    );

    if (conditionIndex !== -1) {
      const newCondition = update(this.props.conditions[conditionIndex], {
        operator: { $set: value },
        value: {
          $set:
            value === campaignGiftMatchingConditionOperator.Between
              ? [null, null]
              : [null]
        }
      });

      this.props.onChange(
        this.props.index,
        `${giftMatchingPath.conditions}.${conditionIndex}`,
        newCondition
      );
    }
  };

  render() {
    const {
      index,
      name,
      multiplier,
      donation,
      mainImagePath,
      conditions,
      donationMatchedCount,
      donationMatchedTotalAmount,
      onChange,
      isPoolEntry
    } = this.props;
    const i18Key = 'template.page.content.giftMatching.model';

    const imagePath = `${this.props.path}.${index}.${giftMatchingPath.image}`;
    const imageUrl = mainImagePath
      ? getFullImagePathByType(mainImagePath, imageTypes.LOGO)
      : '';

    const conditionValidationErrorMessage =
      conditions.length === 0
        ? I18n.t('errors.validation.gift-matching-conditions-required')
        : '';

    return (
      <div>
        <Form
          className="collection-item gift-matching-item"
          disabled={isPoolEntry}
        >
          <Form.Field className="item-title-container" required={this.required}>
            <label className="item-title">
              {I18n.t(`${i18Key}.${i18GiftMatchingKeys.label}`, {
                id: `${index + 1}`
              })}
              <Icon
                name="remove circle"
                className="right-float"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.onDeleteClick(index)}
              />
            </label>
          </Form.Field>

          <div className="gift-matching-item-content">
            <Message
              warning
              hidden={!isPoolEntry}
              style={{
                margin: '14px 0px 14px 0px',
                display: isPoolEntry ? 'block' : 'none'
              }}
            >
              <Translate value="template.page.content.giftMatching.isPoolEntry.label" />
            </Message>

            <ValidatedInput
              label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.name.label}`)}
              subtext={I18n.t(`${i18Key}.${i18GiftMatchingKeys.name.subtext}`)}
              value={name}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.name.placeholder}`
              )}
              onChange={(event, { value }) => {
                onChange(index, giftMatchingPath.name, value);
              }}
              required={this.required}
              validation={giftMatchingFieldsValidation[giftMatchingFields.NAME]}
            />

            <Form.Field required={this.required}>
              <label>
                {I18n.t(`${i18Key}.${i18GiftMatchingKeys.multiplier.label}`)}
              </label>
              <Dropdown
                options={this.getMultiplierOptions()}
                onChange={(event, { value }) => {
                  onChange(index, giftMatchingPath.multiplier, value);
                }}
                value={multiplier}
                placeholder={I18n.t(
                  `${i18Key}.${i18GiftMatchingKeys.multiplier.placeholder}`
                )}
                selection
                icon="chevron down"
              />
              <div className={`input-sub input-sub-bottom`}>
                {I18n.t(`${i18Key}.${i18GiftMatchingKeys.multiplier.subtext}`)}
              </div>
            </Form.Field>

            <ValidatedInput
              label={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationFrom.label}`
              )}
              subtext={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationFrom.subtext}`
              )}
              value={donation && donation.from}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationFrom.placeholder}`
              )}
              onChange={(event, { value }) => {
                onChange(index, giftMatchingPath.donationFrom, value);
              }}
              validation={
                giftMatchingFieldsValidation[giftMatchingFields.DONATION_FROM]
              }
            />

            <ValidatedInput
              label={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationMessage.label}`
              )}
              subtext={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationMessage.subtext}`
              )}
              value={donation && donation.message}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.donationMessage.placeholder}`
              )}
              onChange={(event, { value }) => {
                onChange(index, giftMatchingPath.donationMessage, value);
              }}
              validation={
                giftMatchingFieldsValidation[
                  giftMatchingFields.DONATION_MESSAGE
                ]
              }
            />

            <CommonForm.EditableUpload
              label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.image.label}`)}
              subtext={`${i18Key}.${i18GiftMatchingKeys.image.subtext}`}
              url={imageUrl}
              position="left"
              height="350"
              width="350"
              format={imageFormats}
              uploadType={imageTypes.LOGO}
              entity={entityTypes.CAMPAIGN}
              path={imagePath}
              onSuccess={(value) => {
                onChange(index, giftMatchingPath.image, value);
              }}
            />

            {/* // Conditions */}
            <Form.Field>
              <label>
                {I18n.t(`${i18Key}.${i18GiftMatchingKeys.conditions.label}`)}
              </label>
            </Form.Field>

            {React.Children.toArray(
              campaignGiftMatchingConditions.map((item) => {
                const condition = this.props.conditions.find(
                  (el) => el.key === item
                );
                return (
                  <GiftMatchingConditionItem
                    _key={item}
                    i18Key={i18Key}
                    condition={condition}
                    timezone={this.props.timezone}
                    donationMatchedCount={donationMatchedCount}
                    donationMatchedTotalAmount={donationMatchedTotalAmount}
                    handleConditionChange={this.handleConditionChange}
                    handleConditionValueChange={this.handleConditionValueChange}
                    handleConditionOperatorChange={
                      this.handleConditionOperatorChange
                    }
                  />
                );
              })
            )}

            <GiftMatchingConditionPageItem
              index={index}
              i18Key={i18Key}
              conditions={conditions}
              onChange={onChange}
              handleConditionValueChange={this.handleConditionValueChange}
            />

            <Message negative hidden={!conditionValidationErrorMessage}>
              <p>{conditionValidationErrorMessage}</p>
            </Message>
          </div>
        </Form>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    timezone: templateHelper.getValue(
      state.templateBuilderEx,
      'organization.timezone'
    )
  };
};

const GiftMatchingItem = connect(mapState)(GiftMatchingItemContainer);
export default GiftMatchingItem;
