import React, { Component } from 'react';
import '../grid.css';

class CenterPanel extends Component {
  render() {
    return <div className="center-panel">{this.props.children}</div>;
  }
}

export default CenterPanel;
