import { listFilterAggregate, listFilterOperator } from "../../../../constants/aggregate";
import update from '../../../../helpers/update';
import * as entityHandlers from '../../../../modules/bin/entityHandlers';
import { ACTIVE_TAB_SET, DETAIL_STORE_CLEAR, DONATIONS_LIST_DATA_FAILURE, DONATIONS_LIST_DATA_REQUESTED, DONATIONS_LIST_DATA_SUCCESS, DONATIONS_METADATA_FAILURE, DONATIONS_METADATA_REQUESTED, DONATIONS_METADATA_SUCCESS, DONATIONS_TOGGLE_COLUMNS_CHANGE, DONATION_SUMMARY_MODAL_OPEN, DONATION_SUMMARY_REPORT_FAILURE, DONATION_SUMMARY_REPORT_REQUESTED, DONATION_SUMMARY_REPORT_SUCCESS, ERROR_CLEAR, FUNDRAISING_LIST_DATA_FAILURE, FUNDRAISING_LIST_DATA_REQUESTED, FUNDRAISING_LIST_DATA_SUCCESS, FUNDRAISING_METADATA_FAILURE, FUNDRAISING_METADATA_REQUESTED, FUNDRAISING_METADATA_SUCCESS, FUNDRAISING_TOGGLE_COLUMNS_CHANGE, GET_DETAIL_FAILURE, GET_DETAIL_REQUESTED, GET_DETAIL_SUCCESS, ON_VALUE_CHANGE, TOTAL_AMOUNT_DONATED_FAILURE, TOTAL_AMOUNT_DONATED_REQUESTED, TOTAL_AMOUNT_DONATED_SUCCESS, TOTAL_AMOUNT_FUNDRAISED_FAILURE, TOTAL_AMOUNT_FUNDRAISED_REQUESTED, TOTAL_AMOUNT_FUNDRAISED_SUCCESS } from "./accountDetailTypes";
import { fieldNames } from '../../constants';

export const getDonationsMetadata = (listKey: any) => {
    return entityHandlers.getMetadata(
      'donation',
      listKey,
      DONATIONS_METADATA_REQUESTED,
      DONATIONS_METADATA_SUCCESS,
      DONATIONS_METADATA_FAILURE
    );
  };
  
  export const getDonationsListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: any
  ) => {
    return entityHandlers.getListData(
      'donation',
      listKey,
      id,
      DONATIONS_LIST_DATA_REQUESTED,
      DONATIONS_LIST_DATA_SUCCESS,
      DONATIONS_LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData
    );
  };
  
  export const getDonationsListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getDonationsListData(listKey, id, newRequest, true);
  };
  
  export const donationsToggleColumnsChange = (
    listKey: any,
    toggleColumns: any
  ) =>
    entityHandlers.toggleColumnsChange(
      listKey,
      toggleColumns,
      DONATIONS_TOGGLE_COLUMNS_CHANGE
    );
  
  export const getFundraisingMetadata = (listKey: any) => {
    return entityHandlers.getMetadata(
      'page',
      listKey,
      FUNDRAISING_METADATA_REQUESTED,
      FUNDRAISING_METADATA_SUCCESS,
      FUNDRAISING_METADATA_FAILURE
    );
  };
  
  export const getFundraisingListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: any
  ) => {
    return entityHandlers.getListData(
      'page',
      listKey,
      id,
      FUNDRAISING_LIST_DATA_REQUESTED,
      FUNDRAISING_LIST_DATA_SUCCESS,
      FUNDRAISING_LIST_DATA_FAILURE,
      request,
      invalidate,
      cachedData
    );
  };
  
  export const getFundraisingListDataBySearchTerm = (
    listKey: any,
    id: any,
    request: any,
    searchTerm: any
  ) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getFundraisingListData(listKey, id, newRequest, true);
  };
  
  export const fundraisingToggleColumnsChange = (
    listKey: any,
    toggleColumns: any
  ) =>
    entityHandlers.toggleColumnsChange(
      listKey,
      toggleColumns,
      FUNDRAISING_TOGGLE_COLUMNS_CHANGE
    );
  
  export const getRecord = (id: any, view: any) => {
    let url = 'account';
    if (view) {
      url = `${url}/${view}`;
    }
    return entityHandlers.getRecord(
      url,
      GET_DETAIL_REQUESTED,
      GET_DETAIL_SUCCESS,
      GET_DETAIL_FAILURE,
      id,
      'detailRecord'
    );
  };
  
  export const getTotalAmountDonated = (accountId: any) => {
    let aggregateUrl = `donation/aggregate`
      // @ts-ignore
      .addFieldName(fieldNames.AMOUNT)
      .addFieldAggregate(listFilterAggregate.SUM)
      .addFilter('AccountId', listFilterOperator.EQUAL_TO, accountId, 0);
  
    return entityHandlers.getAggregate(
      TOTAL_AMOUNT_DONATED_REQUESTED,
      TOTAL_AMOUNT_DONATED_SUCCESS,
      TOTAL_AMOUNT_DONATED_FAILURE,
      aggregateUrl,
      'totalDonatedRecord'
    );
  };
  
  export const getTotalAmountFundraised = (accountId: any) => {
    let aggregateUrl = `page/aggregate`
      // @ts-ignore
      .addFieldName(fieldNames.RAISED_AMOUNT)
      .addFieldAggregate(listFilterAggregate.SUM)
      .addFilter('accountId', listFilterOperator.EQUAL_TO, accountId, 0);
  
    return entityHandlers.getAggregate(
      TOTAL_AMOUNT_FUNDRAISED_REQUESTED,
      TOTAL_AMOUNT_FUNDRAISED_SUCCESS,
      TOTAL_AMOUNT_FUNDRAISED_FAILURE,
      aggregateUrl,
      'totalFundraisingRecord'
    );
  };
  
  export const createDonationSummaryReport = (
    email: string,
    country: string,
    dateFrom: string,
    dateTo: string
  ) => {
    const data = {
      email: email,
      country: country,
      dateFrom: dateFrom,
      dateTo: dateTo
    };
  
    return entityHandlers.createRecord(
      'account/donation/report',
      DONATION_SUMMARY_REPORT_REQUESTED,
      DONATION_SUMMARY_REPORT_SUCCESS,
      DONATION_SUMMARY_REPORT_FAILURE,
      data,
      null,
      'donationSummaryRecord'
    );
  };
  
  export const clearStore = () => {
    return {
      type: DETAIL_STORE_CLEAR
    };
  };
  
  export const onValueChange = (path: any, value: any) => {
    return {
      type: ON_VALUE_CHANGE,
      payload: {
        path,
        value
      }
    };
  };
  
  export const donationSummaryModalOpen = (open: any) => {
    return {
      type: DONATION_SUMMARY_MODAL_OPEN,
      payload: { open: open }
    };
  };
  
  export const clearRecordMessages = (key: any) => ({
    type: ERROR_CLEAR,
    payload: { key: key }
  });
  
  export const setView = (view: any) => {
    return {
      type: ACTIVE_TAB_SET,
      payload: view
    };
  };