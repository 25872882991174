import React, { Component } from 'react';
import { Form, Card, Button } from 'semantic-ui-react';
import get from 'lodash.get';
import * as accountSettingsActions from './reducer';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Record } from '../../../modules/bin/utility';
import RecordResultMessage from '../../../components/common/recordResult';
import { withRouter } from 'react-router-dom';
import ValidatedInput from '../../../components/common/validatedInput';
import { getPasswordErrorMessage } from '../../../helpers/validationHelper';
import InvalidPassword from '../../../components/authentication/invalidPassword';
import SaveSecurityModal from './MFA/saveSecurityModal';

class UpdatePassword extends Component {
  constructor(props) {
    super(props);
    this.props.clearRecordChangePasswordMessages();
  }
  onNewPasswordChange = (_event, { value }) => {
    this.props.newPasswordChange(value);
  };

  onConfirmPasswordChange = (_event, { value }) => {
    this.props.confirmPasswordChange(value);
  };

  saveChanges = (verificationVariant = null, verificationCode = null) => {
    this.props.updateRecordPassword(
      this.props.id,
      { password: this.props.newPassword },
      verificationVariant,
      verificationCode
    );
  };

  onSaveClick = () => {
    if (this.props.hasMfaActive) {
      this.props.handleValueChange('accountVerification.open', true);
    } else {
      this.saveChanges();
    }
  };

  isSaveButtonDisabled = (passwordErrorMessage) => {
    return (
      passwordErrorMessage !== undefined ||
      this.props.newPassword !== this.props.confirmPassword
    );
  };

  getConfirmPasswordErrorMessage = (password, confirmPassword) => {
    return password !== confirmPassword
      ? I18n.t('errors.validation.password.not-equal')
      : '';
  };

  /**
   * Clear all record messages
   */
  clearMessages = () => {
    this.props.clearRecordChangePasswordMessages();
  };

  handleUpdateError = () => {
    if (this.props.accountVerification.open) {
      this.props.verificationModalClear();
      this.props.clearRecord('recordChangePassword');
    }
  };

  render() {
    const { confirmPassword, newPassword } = this.props;
    const passwordErrorMessage = getPasswordErrorMessage(
      newPassword,
      confirmPassword
    );

    const isSuccess = Record.isSuccess(this.props.recordChangePassword);
    const isError = Record.isError(this.props.recordChangePassword);
    const isLoading = Record.isRecordLoading(this.props.recordChangePassword);

    if (isSuccess) {
      this.props.goToPagesList();
    }

    if (isError) {
      this.handleUpdateError();
    }

    return (
      <div>
        <RecordResultMessage
          record={this.props.recordChangePassword}
          onDismiss={this.clearMessages}
        />
        <SaveSecurityModal
          isLoading={isLoading}
          handleVerificationClick={this.saveChanges}
        />
        <div className="general-conatiner">
          <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
            <div className="detail-container">
              <Card.Content>
                <Form>
                  <Form.Field width={15}>
                    <ValidatedInput
                      value={newPassword}
                      placeholder="New password"
                      label="New password"
                      onChange={this.onNewPasswordChange}
                      type="password"
                      error={passwordErrorMessage !== undefined}
                      errorComponent={
                        <InvalidPassword errors={passwordErrorMessage} />
                      }
                    />
                  </Form.Field>
                  <Form.Field width={15}>
                    <ValidatedInput
                      value={confirmPassword}
                      placeholder="Confirm password"
                      label="Confirm password"
                      type="password"
                      onChange={this.onConfirmPasswordChange}
                      errorMessage={this.getConfirmPasswordErrorMessage(
                        newPassword,
                        confirmPassword
                      )}
                    />
                  </Form.Field>
                </Form>
                <div className="bottom-space"></div>
              </Card.Content>
            </div>
          </Card>
          <Button
            primary
            style={{ minWidth: '100px' }}
            floated="right"
            disabled={this.isSaveButtonDisabled(passwordErrorMessage)}
            loading={this.props.isLoading}
            onClick={this.onSaveClick}
          >
            <Translate value={'account.update-password.save'} />
          </Button>
          <Button floated="right" onClick={this.props.onCancel}>
            <Translate value={'account.update-password.cancel'} />
          </Button>
        </div>
      </div>
    );
  }
}

const mapState = ({ accountSettings }) => {
  const { confirmPassword, newPassword } = accountSettings;
  const id = accountSettings.record.data.data.id;
  return {
    recordChangePassword: accountSettings.recordChangePassword,
    accountVerification: accountSettings.accountVerification,
    hasMfaActive: get(accountSettings.record.data.data, 'hasMfaActive'),
    isLoading: Record.isRecordLoading(accountSettings.recordChangePassword),
    confirmPassword,
    newPassword,
    id
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(accountSettingsActions, dispatch);
};

export default withRouter(connect(mapState, mapDispatch)(UpdatePassword));
