import React from 'react';

import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';

import { isArrayWithItems } from '../../../helpers/arrayHelper';

import { ViewTable } from '../../../components/layouts/viewTable';
import { FormBuilderSectionRow } from '../../../components/layouts/formBuilderSectionRow';
import { DonationAddressSectionRow } from './components/donationAddressSectionRow';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { DonationSectionBooleanRow } from './components/donationSectionBooleanRow';

export const DonationAdditionalInformation = (props) => {
  const { fields, donor, displayName } = props.donation;
  const { isShowEmptyFields } = props;

  const phoneNumber = get(donor, 'phoneNumber');
  const address = get(donor, 'address');
  const newsletterOption = get(donor, 'newsletter');
  const anonymous = get(donor, 'anonymous');

  return (
    <React.Fragment>
      <div className="donation-additional-information">
        <ViewTable
          title={
            <Translate value="donation.donation-view.donation-details.additional-information" />
          }
        >
          <ViewSectionRow
            data={phoneNumber}
            title="donation.donation-view.donation-details.phone-number"
            isShowEmptyFields={isShowEmptyFields}
          />
          <DonationAddressSectionRow
            title="donation.donation-view.donation-details.address"
            data={address}
            isShowEmptyFields={isShowEmptyFields}
          />
          <DonationSectionBooleanRow
            title="donation.donation-view.donation-details.news-letter"
            data={newsletterOption}
          />
          {isArrayWithItems(fields) &&
            React.Children.toArray(
              fields.map((field) => (
                <FormBuilderSectionRow
                  isShowEmptyFields={isShowEmptyFields}
                  field={field}
                />
              ))
            )}
          <DonationSectionBooleanRow
            title="donation.donation-view.donation-details.anonymous-donation"
            data={anonymous}
          />
          <ViewSectionRow
            title="donation.donation-view.donation-details.display-name"
            data={displayName}
            isShowEmptyFields={isShowEmptyFields}
          />
        </ViewTable>
      </div>
    </React.Fragment>
  );
};
