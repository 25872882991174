/* eslint-disable no-extend-native */
String.prototype.capitalize = function () {
  const capitalizedString = `${this.charAt(0).toUpperCase()}${this.slice(1)}`;
  const words = capitalizedString.match(/[A-Z][a-z]+/g);

  if (!words && this) {
    return capitalizedString;
  }

  return (
    words.length && `${words[0]} ${words.slice(1).join(' ').toLowerCase()}`
  );
};

export const toTitleCase = (text) => {
  if (typeof text === 'string') {
    const result = text.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }
  return text;
};
