import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import ValidatedInput from '../common/validatedInput';

class ManagedInput extends Component {
  onValueChange(evt, obj) {
    if (this.props.onChange) {
      // Apply regex pattern to data.
      if (this.props.pattern && obj.value && obj.value.length > 0) {
        const match = obj.value.match(this.props.pattern);
        if (!match) return;
      }

      this.props.onChange(this.props.path, obj.value);
    }
  }

  render() {
    const subtextTranslationObject = this.props.subtextTranslationObject;
    const label =
      templateHelper.getTranslation(this.props.label) || this.props.label;
    const subtext =
      templateHelper.getTranslation(
        this.props.subtext,
        subtextTranslationObject
      ) || this.props.subtext;
    const inputLabel = templateHelper.getTranslation(this.props.inputLabel);
    const placeholder = templateHelper.getTranslation(this.props.placeholder);

    const { className, validation, value, showValidationIcon, type } = this.props;

    return (
      <ValidatedInput
        id={this.props.id}
        className={className}
        label={label}
        inputLabel={inputLabel}
        subtextPosition={this.props.subtextPosition || 'bottom'}
        subtext={subtext}
        value={value || ''}
        pattern={this.props.pattern}
        style={this.props.style || {}}
        placeholder={placeholder}
        onChange={this.onValueChange.bind(this)}
        required={validation && validation.required}
        validation={validation}
        showValidationIcon={showValidationIcon}
        type={type}
        disabled={this.props.disabled}
        initialValidation={this.props.initialValidation}
        errorComponent={this.props.errorComponent}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    ) || ownProps.disabled
  };
};

export const TemplateInput = connect(mapState)(ManagedInput);
