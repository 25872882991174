//-------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

//-------Helpers
import * as templateHelper from '../template/templateHelper';
import * as validationHelper from '../../helpers/validationHelper';
import { getUrl } from '../../modules/bin/utility';
import update from '../../helpers/update';

//-------Components
import { TagItem } from './tagItem';
import { Form, Button } from 'semantic-ui-react';
import TextInput from '../form/input';

//-------Constants
import { I18TranslationKeys } from './constants';

//-------Other
import './index.css';

class TagsInputContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tagName: ''
    };

    this.validationModel = {
      tagsBuilder: true
    };
  }

  onChange = (value) => {
    this.props.onChange(this.props.path, value);
  };

  onTagNameChange = (event) => {
    this.setState({
      tagName: event.target.value
    });
  };

  getItems = () => {
    const items = this.props.value;

    if (!items) {
      return null;
    }

    const renderItems = items.map((item, index) => {
      return (
        <TagItem index={index} label={item} onDelete={this.onDeleteClick} />
      );
    });

    return renderItems;
  };

  onAddItemClick = () => {
    let valueCopy = this.props.value || [];

    valueCopy = update(valueCopy, {
      $push: [getUrl(this.state.tagName)]
    });

    this.setState({
      tagName: ''
    });

    this.onChange(valueCopy);
  };

  onDeleteClick = (index) => {
    let valueCopy = this.props.value;

    valueCopy = update(valueCopy, {
      $splice: [[index, 1]]
    });

    this.onChange(valueCopy);
  };

  isAddButtonDisabled = () => {
    const isValid = validationHelper.isValid(this.validationModel, {
      tags: this.props.value,
      tagName: getUrl(this.state.tagName)
    });
    return !(this.state.tagName !== '' && isValid);
  };

  render() {
    const tags = this.getItems();
    const validationErrorMessage = validationHelper.getValidationErrorMessage(
      this.validationModel,
      { tags: this.props.value, tagName: getUrl(this.state.tagName) }
    );

    return (
      <div className="tags-container">
        <Form>
          <Form.Group unstackable>
            <Form.Field width={8}>
              <TextInput
                id="tag-input"
                value={this.state.tagName}
                placeholder={I18n.t(I18TranslationKeys.inputLabel)}
                onChange={this.onTagNameChange}
                validationErrorMessage={validationErrorMessage}
              />
            </Form.Field>
            <Form.Field width={8}>
              <Button
                id="add-tag-btn"
                disabled={this.isAddButtonDisabled()}
                onClick={this.onAddItemClick}
              >
                {I18n.t(I18TranslationKeys.addButton)}
              </Button>
            </Form.Field>
          </Form.Group>
        </Form>
        <div className="tags-list-container">{tags}</div>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value
  };
};

export const TagsInput = connect(mapState)(TagsInputContainer);
