import React from 'react';

const InvalidPassword = (props) => {
  if (props.errors === undefined) {
    return null;
  }

  return (
    <div>
      Invalid password:
      {props.errors.map((error, index) => {
        return <li key={index}>{error}</li>;
      })}
    </div>
  );
};

export default InvalidPassword;
