import React from 'react';
import NumberFormat from 'react-number-format';

export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

export const formatDecimalNumber = (number, thousandSeparator = false) => {
  if (!number) {
    return 0;
  }

  const fractionsToUse = Number.isInteger(number) ? 0 : 2;
  const fixedNumber = number.toFixed(fractionsToUse);

  return thousandSeparator ? numberWithCommas(fixedNumber) : fixedNumber;
};

export const numberWithCommas = (number) => {
  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const toCurrency = (amount, defaultDispaly) => {
  if (!amount && defaultDispaly) {
    return defaultDispaly;
  }

  return (
    <NumberFormat
      className="label"
      value={(amount || 0).toFixed(2)}
      displayType={'text'}
      thousandSeparator
      prefix={'$'}
      decimalScale={2}
    />
  );
};
