import get from 'lodash.get';
import * as validationHelper from '../helpers/validationHelper';

export const roles = {
  ORGANIZATION_ADMIN: 4,
  CAMPAIGN_ADMIN: 32,
  EVENT_ADMIN: 64,
  EVENT_ORGANIZER_ADMIN: 128,
  ORGANIZATION_ADMIN_RESTRICTED: 256
};

export const userRolesStrings = {
  systemAdmin: 'systemAdmin',
  organizationAdmin: 'organizationAdmin',
  eventAdmin: 'eventAdmin',
  campaignAdmin: 'campaignAdmin',
  eventOrganizerAdmin: 'eventOrganizerAdmin'
};

export const roleOptions = [
  {
    text: 'Org admin',
    value: roles.ORGANIZATION_ADMIN,
    isVisible: (isSystemAdmin) => {
      return true;
    }
  },
  {
    text: 'Org Admin (Restricted)',
    value: roles.ORGANIZATION_ADMIN_RESTRICTED,
    isVisible: (isSystemAdmin) => {
      return true;
    }
  },
  {
    text: 'Campaign admin',
    value: roles.CAMPAIGN_ADMIN,
    isVisible: (isSystemAdmin) => {
      return true;
    }
  },
  {
    text: 'Event admin',
    value: roles.EVENT_ADMIN,
    isVisible: (isSystemAdmin) => {
      return isSystemAdmin;
    }
  },
  {
    text: 'Event Organizer admin',
    value: roles.EVENT_ORGANIZER_ADMIN,
    isVisible: (isSystemAdmin) => {
      return isSystemAdmin;
    }
  }
];

export const roleConfigurations = {
  [roles.ORGANIZATION_ADMIN]: {
    isPromoteButtonDisabled: (accountDetail, organizationId) => {
      return !organizationId;
    },
    getValidationErrorMessage: (props) => {
      return null;
    }
  },
  [roles.CAMPAIGN_ADMIN]: {
    isPromoteButtonDisabled: (accountDetail, organizationId) => {
      const campaignId = get(accountDetail, 'roleDetail.campaignId') || [];
      return campaignId.length === 0;
    },
    getValidationErrorMessage: (props) => {
      const tags = get(props.accountDetail, 'roleDetail.campaignId') || [];
      const tagName = get(props.linkedCampaign, 'id');

      return validationHelper.isUniqueValue(tags, tagName)
        ? ''
        : 'Campaign with such id already exists';
    }
  },
  [roles.EVENT_ADMIN]: {
    isPromoteButtonDisabled: (accountDetail, organizationId) => {
      const eventId = get(accountDetail, 'roleDetail.eventId') || [];
      return eventId.length === 0;
    },
    getValidationErrorMessage: (props) => {
      const tags = get(props.accountDetail, 'roleDetail.eventId') || [];
      const tagName = get(props.linkedEvent, 'id');

      return validationHelper.isUniqueValue(tags, tagName)
        ? ''
        : 'Event with such id already exists';
    }
  },
  [roles.EVENT_ORGANIZER_ADMIN]: {
    isPromoteButtonDisabled: (accountDetail, organizationId) => {
      const eventOrganizerId =
        get(accountDetail, 'roleDetail.eventOrganizerId') || [];
      return eventOrganizerId.length === 0;
    },
    getValidationErrorMessage: (props) => {
      const tags =
        get(props.accountDetail, 'roleDetail.eventOrganizerId') || [];
      const tagName = get(props.linkedEventOrganizer, 'id');

      return validationHelper.isUniqueValue(tags, tagName)
        ? ''
        : 'Event Organizer with such id already exists';
    }
  }
};
