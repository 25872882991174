import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { I18n } from 'react-redux-i18n';

class Search extends Component {
  static defaultProps = {
    value: '',
    placeholder: 'general.search'
  };

  static propTypes = {
    value: PropTypes.string,
    placeholder: PropTypes.string
  };

  constructor(props) {
    super();

    this.timerId = null;
    this.state = { value: props.value };
  }

  hasValueChanged(currentProps, nextProps) {
    return currentProps && nextProps
      ? currentProps.value !== nextProps.value
      : null;
  }

  componentWillReceiveProps(nextProps) {
    const hasValueChanged = this.hasValueChanged(this.props, nextProps);

    if (hasValueChanged === true) this.setState({ value: nextProps.value });
  }

  executeSearch(value) {
    if (this.props.onSearch) {
      this.props.onSearch(value);
    }
  }

  onSearchChange(event, data) {
    this.setState({ value: data.value });

    if (this.timerId) clearTimeout(this.timerId);

    this.timerId = setTimeout(() => this.executeSearch(data.value), 500);
  }

  render() {
    return (
      <div>
        <Input
          icon="search"
          disabled={this.props.disabled}
          className="grid-search"
          placeholder={I18n.t(this.props.placeholder)}
          value={this.state.value}
          onChange={this.onSearchChange.bind(this)}
        />
      </div>
    );
  }
}

export default Search;
