import React, { Component } from 'react';

import { Divider } from 'semantic-ui-react';

class HorizontalRule extends Component {
  render() {
    return <Divider {...this.props} />;
  }
}

export default HorizontalRule;
