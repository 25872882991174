import React, { Component } from 'react';
import Cells from './cells';
import get from 'lodash.get';
import { Table } from 'semantic-ui-react';
import CellLinkWrapper from './cellLinkWrapper';

class RowCell extends Component {
  render() {
    const target = get(this.props, 'linkInfo.isNewTab') ? '_blank' : null;
    const url = get(this.props, 'linkInfo.url');
    const isUrlInternal = get(this.props, 'linkInfo.isUrlInternal');
    const cells = url ? (
      <CellLinkWrapper isUrlInternal={isUrlInternal} url={url} target={target}>
        {Cells.Formatter(this.props.data, this.props)}
      </CellLinkWrapper>
    ) : (
      Cells.Formatter(this.props.data, this.props)
    );
    const sortedClass = this.props.sorted === true ? 'sort-active' : '';
    return (
      <Table.Cell
        style={{ padding: this.props.styles ? '0px' : '' }}
        className={sortedClass}
        collapsing
      >
        {cells}
      </Table.Cell>
    );
  }
}

export default RowCell;
