import React from 'react';
import { Translate } from 'react-redux-i18n';

import { Grid, GridColumn, GridRow } from 'semantic-ui-react';
import BasePageView from '../../../components/common/basePageView';
import { VerticalMenu } from '../../../components/common/VerticalMenu';
import { webhookCreateVertical } from '../../../constants/webhook';
import { i18nKeys } from '../../accounts/constants';
import WebhookInputProps from '../WebhookInput';

const CreateWebhook = () => {
  return (
    <BasePageView backLink={`/webhooks`} backLinkTitle="Back to accounts">
      <h1>
        <Translate value={i18nKeys.WEBHOOKS_CREATE} />
      </h1>
      <Grid>
        <GridRow>
          <GridColumn width={5}>
            <VerticalMenu menuItems={webhookCreateVertical} />
          </GridColumn>
          <GridColumn width={11}>
            <WebhookInputProps inputType="setup" />
          </GridColumn>
        </GridRow>
      </Grid>
    </BasePageView>
  );
};

export default CreateWebhook;
