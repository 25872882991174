import * as entityHandlers from '../modules/bin/entityHandlers';
import * as campaignEntityHandlers from '../modules/bin/campaignEntityHandlers';

import {
  CREATE_CAMPAIGN_SUCCESS,
  CLONE_SUCCESS,
  UPDATE_CAMPAIGN_STATUS_SUCCESS,
  CREATE_CAMPAIGN_FAILURE,
  CLONE_FAILURE,
  LIST_DATA_FAILURE,
  METADATA_FAILURE
} from '../pages/campaign/store/campaignTypes';
import {
  CREATE_SUCCESS as CREATE_EVENT_SUCCESS,
  UPDATE_SUCCESS as UPDATE_EVENT_SUCCESS,
  UPDATE_EVENT_STATUS_SUCCESS,
  LIST_DATA_FAILURE as EVENT_LIST_DATA_FAILURE,
  METADATA_FAILURE as EVENT_METADATA_FAILURE
} from '../pages/event/store/eventTypes';
import {
  LIST_DATA_FAILURE as ORGANIZATIONS_LIST_DATA_FAILURE,
  METADATA_FAILURE as ORGANIZATION_METADATA_FAILURE,
  CREATE_SUCCESS as CREATE_ORGANIZATION_SUCCESS,
  UPDATE_SUCCESS as UPDATE_ORGANIZATION_SUCCESS,
  CREATE_FAILURE as CREATE_ORGANIZATION_FAILURE,
  UPDATE_FAILURE as UPDATE_ORGANIZATION_FAILURE
} from '../pages/organization/store/organizationTypes';
import {
  CREATE_SUCCESS as CREATE_DONATION_SUCCESS,
  UPDATE_SUCCESS as UPDATE_DONATION_SUCCESS,
  REFUND_SUCCESS,
  RECEIPT_SUCCESS,
  DELETE_SUCCESS,
  LIST_DATA_FAILURE as DONATIONS_LIST_DATA_FAILURE,
  METADATA_FAILURE as DONATIONS_METADATA_FAILURE
} from '../pages/donation/store/donationTypes';
import {
  CREATE_SUCCESS as CREATE_PAGE_SUCCESS,
  UPDATE_SUCCESS as UPDATE_PAGE_SUCCESS,
  UPDATE_STATUS_SUCCESS,
  BULK_UPLOAD_SUCCESS,
  BULK_UPLOAD_FAILURE
} from '../pages/campaign/page/reducer';
import {
  SAVE_SETTINGS_SUCCESS,
  UPDATE_PASSWORD_SUCCESS,
  PROMOTE_ADMIN_SUCCESS,
  DEMOTE_ADMIN_SUCCESS,
  SAVE_SETTINGS_FAILURE,
  UPDATE_PASSWORD_FAILURE,
  PROMOTE_ADMIN_FAILURE,
  DEMOTE_ADMIN_FAILURE
} from '../pages/accounts/settings/reducer';
import { Record } from './bin/utility';
import {
  LIST_DATA_FAILURE as ACCOUNT_LIST_FAILURE,
  FUNDRAISERS_LIST_DATA_FAILURE,
  FUNDRAISERS_METADATA_FAILURE,
  METADATA_FAILURE as ACCOUNTS_METADATA_FAILURE
} from '../pages/accounts/store/accountTypes';
import {
  DONATIONS_LIST_DATA_FAILURE as ACCOUNT_DONATIONS_LIST_DATA_FAILURE,
  FUNDRAISING_LIST_DATA_FAILURE as ACCOUNT_FUNDRAISING_LIST_DATA_FAILURE,
  DONATIONS_METADATA_FAILURE as ACCOUNT_DONATIONS_METADATA_FAILURE,
  FUNDRAISING_METADATA_FAILURE
} from '../pages/accounts/detail/store/accountDetailTypes';
import {
  SALE_REFUND_SUCCESS,
  SALE_REFUND_AND_CANCEL_SUCCESS,
  SALE_REFUND_FAILURE,
  SALE_RECEIPT_SUCCESS,
  SALE_RECEIPT_FAILURE,
  TICKET_REFUND_SUCCESS,
  TICKET_REFUND_FAILURE,
  TICKET_RECEIPT_SUCCESS,
  TICKET_RECEIPT_FAILURE,
  CREATE_SUCCESS as TICKETING_CREATE_SUCCESS,
  CREATE_FAILURE as TICKETING_CREATE_FAILURE,
  UPDATE_SUCCESS as TICKETING_UPDATE_SUCCESS,
  UPDATE_FAILURE as TICKETING_UPDATE_FAILURE
} from '../pages/ticketing/store/ticketingTypes';
import {
  CREATE_MERCHANDISE_SUCCESS,
  CREATE_MERCHANDISE_FAILURE
} from '../pages/merchandise/reducer';
import {
  CREATE_SUCCESS as RECURRING_CREATE_SUCCESS,
  CREATE_FAILURE as RECURRING_CREATE_FAILURE,
  UPDATE_SUCCESS as RECURRING_UPDATE_SUCCESS,
  UPDATE_FAILURE as RECURRING_UPDATE_FAILURE,
  REFUND_SUCCESS as RECURRING_REFUND_SUCCESS,
  REFUND_FAILURE as RECURRING_REFUND_FAILURE
} from '../pages/recurring/reducer';
import {
  CREATE_SUCCESS as VIP_PROGRAM_CREATE_SUCCESS,
  CREATE_FAILURE as VIP_PROGRAM_CREATE_FAILURE,
  UPDATE_SUCCESS as VIP_PROGRAM_UPDATE_SUCCESS,
  UPDATE_FAILURE as VIP_PROGRAM_UPDATE_FAILURE,
  UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS as EAP_UPDATE_STATUS_SUCCESS,
  UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE as EAP_UPDATE_STATUS_FAILURE
} from '../pages/vipProgram/store/vipProgramTypes';

export const ERROR_CLEAR = 'success/ERROR_CLEAR';

export const clearMessages = () => ({ type: ERROR_CLEAR });

const initialState = {
  record: Record.getDefaultState()
};

const successReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case CREATE_CAMPAIGN_SUCCESS:
    case CLONE_SUCCESS:
    case CREATE_DONATION_SUCCESS:
    case CREATE_PAGE_SUCCESS:
    case UPDATE_DONATION_SUCCESS:
    case UPDATE_PAGE_SUCCESS:
    case SAVE_SETTINGS_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case PROMOTE_ADMIN_SUCCESS:
    case DEMOTE_ADMIN_SUCCESS:
    case UPDATE_STATUS_SUCCESS:
    case UPDATE_CAMPAIGN_STATUS_SUCCESS:
    case REFUND_SUCCESS:
    case DELETE_SUCCESS:
    case RECEIPT_SUCCESS:
    case CREATE_EVENT_SUCCESS:
    case UPDATE_EVENT_SUCCESS:
    case UPDATE_EVENT_STATUS_SUCCESS:
    case BULK_UPLOAD_SUCCESS:
    case SALE_REFUND_SUCCESS:
    case SALE_REFUND_AND_CANCEL_SUCCESS:
    case SALE_RECEIPT_SUCCESS:
    case TICKET_REFUND_SUCCESS:
    case TICKET_RECEIPT_SUCCESS:
    case TICKETING_UPDATE_SUCCESS:
    case TICKETING_CREATE_SUCCESS:
    case CREATE_MERCHANDISE_SUCCESS:
    case RECURRING_CREATE_SUCCESS:
    case RECURRING_UPDATE_SUCCESS:
    case RECURRING_REFUND_SUCCESS:
    case CREATE_ORGANIZATION_SUCCESS:
    case UPDATE_ORGANIZATION_SUCCESS:
    case VIP_PROGRAM_CREATE_SUCCESS:
    case VIP_PROGRAM_UPDATE_SUCCESS:
    case EAP_UPDATE_STATUS_SUCCESS:
      newState = entityHandlers.getSuccessHandler(state, action, 'record');
      break;

    case CLONE_FAILURE:
    case BULK_UPLOAD_FAILURE:
    case LIST_DATA_FAILURE:
    case EVENT_LIST_DATA_FAILURE:
    case ORGANIZATIONS_LIST_DATA_FAILURE:
    case DONATIONS_LIST_DATA_FAILURE:
    case DONATIONS_METADATA_FAILURE:
    case ACCOUNT_LIST_FAILURE:
    case FUNDRAISERS_LIST_DATA_FAILURE:
    case ACCOUNT_DONATIONS_LIST_DATA_FAILURE:
    case ACCOUNT_FUNDRAISING_LIST_DATA_FAILURE:
    case METADATA_FAILURE:
    case ACCOUNT_DONATIONS_METADATA_FAILURE:
    case FUNDRAISING_METADATA_FAILURE:
    case FUNDRAISERS_METADATA_FAILURE:
    case ACCOUNTS_METADATA_FAILURE:
    case EVENT_METADATA_FAILURE:
    case ORGANIZATION_METADATA_FAILURE:
    case SALE_REFUND_FAILURE:
    case SALE_RECEIPT_FAILURE:
    case TICKET_REFUND_FAILURE:
    case TICKET_RECEIPT_FAILURE:
    case TICKETING_UPDATE_FAILURE:
    case TICKETING_CREATE_FAILURE:
    case RECURRING_CREATE_FAILURE:
    case RECURRING_UPDATE_FAILURE:
    case SAVE_SETTINGS_FAILURE:
    case UPDATE_PASSWORD_FAILURE:
    case PROMOTE_ADMIN_FAILURE:
    case DEMOTE_ADMIN_FAILURE:
    case RECURRING_REFUND_FAILURE:
    case CREATE_ORGANIZATION_FAILURE:
    case UPDATE_ORGANIZATION_FAILURE:
    case VIP_PROGRAM_CREATE_FAILURE:
    case VIP_PROGRAM_UPDATE_FAILURE:
    case EAP_UPDATE_STATUS_FAILURE:
      newState = entityHandlers.crudErrorHandler(newState, action, 'record');
      break;

    case CREATE_MERCHANDISE_FAILURE:
    case CREATE_CAMPAIGN_FAILURE:
      newState = campaignEntityHandlers.campaignErrorHandler(
        newState,
        action,
        'record'
      );
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(state, 'record');
      break;
    default:
      return newState;
  }

  return newState;
};

export default successReducer;
