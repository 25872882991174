import React, { Component } from 'react';
import config from '../../constants';

class TermsAndConditions extends Component {
  render() {
    const label = 'I have read and agree to the Grassrootz ';
    const linkLabel = 'terms and conditions';
    const termsAndConditionsUrl = config.TERMS_AND_CONDITIONS;
    return (
      <span>
        {label}
        <a href={termsAndConditionsUrl} target="_blank" rel="noreferrer">
          {linkLabel}
        </a>
      </span>
    );
  }
}

export default TermsAndConditions;
