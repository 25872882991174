import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import * as dashboardActions from '../reducer';
import { Record as RecordHelper } from '../../../modules/bin/utility';
import { Loader } from 'semantic-ui-react';

import { summaryOptions } from '../constants';
import { getDashboardCreatedAt } from '../helper';
import { isArrayWithItems } from '../../../helpers/arrayHelper';
import { getTimePeriod } from '../../../helpers/dateAndTimeHelper';

am4core.useTheme(am4themes_animated);

class ChartView extends Component {
  componentDidMount() {
    const { id } = this.props.match.params;

    const predefinedTimePeriod = get(this.props, 'timePeriod');
    const timePeriodDateFrom = get(predefinedTimePeriod, 'dateFrom');
    const timePeriodDateTo = get(predefinedTimePeriod, 'dateTo');

    if (timePeriodDateFrom || timePeriodDateTo) {
      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        predefinedTimePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType
      );

      this.props.getSummaryDataRecord(request);
    } else {
      const timePeriod = getTimePeriod(
        this.props.createdAtLocal,
        this.props.reportByDateAndMetricType,
        this.props.timeLapse,
        this.props.timeLapseFrom,
        this.props.timeLapseTo
      );
      const request = dashboardActions.getSummaryDataRecordRequest(
        id,
        this.props.configurations.baseFilter,
        timePeriod,
        this.props.timeBracket,
        this.props.reportByDateAndMetricType
      );

      this.props.getSummaryDataRecord(request);
    }
    this.getChart();
  }

  componentDidUpdate() {
    this.getChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  getChart = () => {
    if (this.isAllRecordsReady()) {
      const summaryData =
        get(this.props.summaryDataRecord, 'data.data.list') || [];

      if (isArrayWithItems(summaryData)) {
        let chart = am4core.create('dashboard-chart', am4charts.XYChart);
        chart.paddingRight = 20;

        let chartData = [];

        for (let i = 0; i < summaryData.length; i++) {
          chartData.push({
            date: summaryData[i].date,
            name: `name - ${i}`,
            value: summaryData[i][this.props.summaryType]
          });
        }

        chart.data = chartData;

        let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
        dateAxis.renderer.grid.template.location = 0.0;
        dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy');

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.tooltip.disabled = true;
        valueAxis.renderer.minWidth = 35;

        let series = chart.series.push(new am4charts.LineSeries());
        series.dataFields.dateX = 'date';
        series.dataFields.valueY = 'value';
        series.strokeWidth = 2;

        const summaryOption = summaryOptions.find(
          (item) => item.key === this.props.summaryType
        );
        series.tooltipText = `${summaryOption.prefix}{valueY.value}`;

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';

        const $chartContainer = document.getElementById('dashboard-chart');
        if ($chartContainer) {
          const $amChartLogo = $chartContainer
            .getElementsByTagName('svg')[0]
            .getElementsByTagName('title')[0];
          if ($amChartLogo) {
            $amChartLogo.parentElement.style.visibility = 'hidden';
          }
        }

        this.chart = chart;
      }
    }
  };

  isAllRecordsReady = () => {
    return RecordHelper.isRecordReady(this.props.summaryDataRecord);
  };

  render() {
    const isLoading = !this.isAllRecordsReady();

    const summaryData =
      get(this.props.summaryDataRecord, 'data.data.list') || [];
    const noData = !isArrayWithItems(summaryData);

    return (
      <React.Fragment>
        {!isLoading && !noData && <div id="dashboard-chart" />}
        {isLoading && (
          <div className="warning-container">
            <Loader active inline />
          </div>
        )}
        {!isLoading && noData && (
          <div className="warning-container no-items">
            {I18n.t('dashboard.top-area.chart.no-items')}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ dashboard }) => {
  const entity = dashboard.configurations?.entity;
  const modelRecord = dashboard.modelRecord?.data?.data;

  return {
    configurations: dashboard.configurations,
    createdAtLocal: getDashboardCreatedAt(entity, modelRecord),
    reportByDateAndMetricType: dashboard.reportByDateAndMetricType,
    timeLapse: dashboard.timeLapse,
    timeLapseFrom: dashboard.timeLapseFrom,
    timeLapseTo: dashboard.timeLapseTo,
    timePeriod: dashboard.timePeriod,
    timeBracket: dashboard.timeBracket,
    summaryType: dashboard.summaryType,
    summaryDataRecord: dashboard.summaryDataRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    getSummaryDataRecord: (request) => {
      dispatch(dashboardActions.getSummaryDataRecord(request));
    },
    onValueChange: (path, value) => {
      dispatch(dashboardActions.onValueChange(path, value));
    }
  };
};

const ChartContainer = withRouter(connect(mapState, mapDispatch)(ChartView));
export default ChartContainer;
