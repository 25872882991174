import { generalFormBuilderPath } from '../../constants/formBuilder';

export const merchandiseAvailableFiltersKeys = [
  'createdAtLocal',
  'enabled',
  'salesTotalAmount'
];

export const merchandiseContract = {
  organization: 'organizationId',
  name: 'name',
  description: 'description',
  formbuilder: `${generalFormBuilderPath}`,
  image: 'mainImagePath',
  enabled: 'enabled',
  skus: 'skus'
};
