import React, { Component } from 'react';
import { Modal, Header, Button } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';
import CSVUploadContainer from './csvUpload';
import './index.css';
import { entityTypes, imageTypes } from '../../constants/imageUpload';

class BulkUploadModal extends Component {
  render() {
    const {
      isOpened,
      title,
      description,
      onClose,
      path,
      uploadedFileUrl,
      onRemoveFile,
      onUploadClick,
      isUploadRecordLoading
    } = this.props;
    const isUploadDisabled = !uploadedFileUrl || isUploadRecordLoading;

    return (
      <Modal
        open={isOpened}
        size="tiny"
        onClose={!isUploadRecordLoading && onClose}
        closeIcon={!isUploadRecordLoading}
      >
        <Modal.Content>
          <Header content={title} textAlign="center" />
          {description}
          <div className="space-container">
            <CSVUploadContainer
              entity={entityTypes.CAMPAIGN}
              uploadType={imageTypes.BALK_DATA}
              path={path}
              uploadedFileUrl={uploadedFileUrl}
              onRemoveFile={onRemoveFile}
            />
          </div>
          <Button
            primary
            loading={isUploadRecordLoading}
            onClick={onUploadClick}
            disabled={isUploadDisabled}
          >
            {I18n.t('general.bulk-upload.upload-button')}
          </Button>
        </Modal.Content>
      </Modal>
    );
  }
}

export default BulkUploadModal;
