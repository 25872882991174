export const i18nKeys = {
  PAGE_TITLE: 'fundraiser.title'
};

export const fundraiserAvailableFilters = [
  'campaignId',
  'teamId',
  'fundraiserId',
  'type',
  'raisedAmount',
  'averageDonationAmount',
  'donationCount',
  'createdAtLocal'
];

// Temp solution until we can dynamically set the advanced filter
// idealy there should be no restrictions to what we show
export const fundraiserAvailableAdvancedFilters = [
  'campaignId',
  'teamId',
  'fundraiserId',
  'organization.id',
  'organizationId',
  'sale.campaignId'
];
