import React from 'react';
import { Table } from 'semantic-ui-react';

class OrganisationName extends React.Component {
  render() {
    const { value, errorMessage } = this.props;

    return (
      <Table.Cell collapsing className="validation-string">
        <div className="organisation-name">
          {value}
          {errorMessage && <div className="error">{errorMessage}</div>}
        </div>
      </Table.Cell>
    );
  }
}

export default OrganisationName;
