import React from 'react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';
import AddressAutocomplete from './addressComponents/addressAutocomplete';
import './index.css';
import AddressManualInputs from './addressComponents/addressManualInputs';

class FormbuilderAddress extends React.Component {
  state = {
    showAdditionalComponents: false
  };

  onLinkCLick = () => {
    this.setState((prev) => {
      return {
        showAdditionalComponents: !prev.showAdditionalComponents
      };
    });
  };

  render() {
    const {
      value,
      onChange,
      label,
      validation,
      error,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;
    const { showAdditionalComponents } = this.state;
    const errorMessage = value ? 'Please select a full address' : null;
    const fieldValidation = showAdditionalComponents ? undefined : validation;
    const isManualInputsRequired = validation && validation.required;

    return (
      <FormbuilderFieldWrapper
        label={label}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        validation={fieldValidation}
        initialValidation={initialValidation}
      >
        {showAdditionalComponents === false && (
          <div>
            <AddressAutocomplete onChange={onChange} value={value} />
            <p
              className="Links formbuilder-address-link"
              onClick={this.onLinkCLick}
            >
              Can't find your address?
            </p>
          </div>
        )}
        {showAdditionalComponents === true && (
          <div>
            <AddressManualInputs
              value={value}
              validation={{ required: isManualInputsRequired }}
              onChange={onChange}
            />
            <p
              className="Links formbuilder-address-link"
              onClick={this.onLinkCLick}
            >
              Back to automatic lookup
            </p>
          </div>
        )}
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderAddress;
