import React from 'react';
import { ExportHistoryPage } from './export-history';
import { ExportHistoryApiProvider } from './use-export-history';

interface ExportHistoryDashboardProps {}

export const ExportHistoryDashboard: React.FunctionComponent<
  ExportHistoryDashboardProps
> = (props) => {
  return (
    <ExportHistoryApiProvider>
      <ExportHistoryPage />
    </ExportHistoryApiProvider>
  );
};

export * from './export-provider';
