import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';

import { get } from "lodash";
import { RSAA } from "redux-api-middleware";
import config from "../../../constants";
import { pageTypesStrings } from "../../../constants/pageTypes";
import { getDataWithQuestionFields } from "../../../helpers/dataCaptureHelper";
import { paths } from "../constants";

import { IS_DELETED_CLEAR, IS_REFUNDED_CLEAR, ERROR_CLEAR, RECEIPT_MESSAGE_ERROR, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, TOGGLE_COLUMNS_CHANGE, CREATE_TRANSFER_DONATION_REQUESTED, CREATE_TRANSFER_DONATION_SUCCESS, CREATE_TRANSFER_DONATION_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAILURE, UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAILURE, REFUND_REQUESTED, REFUND_SUCCESS, REFUND_FAILURE, UPDATE_DONATION_REPLY_REQUESTED, UPDATE_DONATION_REPLY_SUCCESS, UPDATE_DONATION_REPLY_FAILURE, DELETE_DONATION_REPLY_REQUESTED, DELETE_DONATION_REPLY_SUCCESS, DELETE_DONATION_REPLY_FAILURE, RECEIPT_REQUESTED, RECEIPT_SUCCESS, RECEIPT_FAILURE, GET_REQUESTED, GET_SUCCESS, GET_FAILURE, GET_CAMPAIGN_REQUESTED, GET_CAMPAIGN_SUCCESS, GET_CAMPAIGN_FAILURE, GET_ORGANIZATION_REQUESTED, GET_ORGANIZATION_SUCCESS, GET_ORGANIZATION_FAILURE, GET_PAGE_REQUESTED, GET_PAGE_SUCCESS, GET_PAGE_FAILURE, RECEIPT_CONFIRMATION_SHOW, RECEIPT_CONFIRMATION_HIDE, REFUND_CONFIRMATION_SHOW, REFUND_CONFIRMATION_HIDE, DELETE_REQUESTED, DELETE_SUCCESS, DELETE_FAILURE } from "./donationTypes";

export const clearIsDeleted = () => ({ type: IS_DELETED_CLEAR });

export const clearIsRefunded = () => ({ type: IS_REFUNDED_CLEAR });

export const clearRecordMessages = (record: any) => ({
    type: ERROR_CLEAR,
    payload: { key: record }
});

export const getReceiptMessageError = (record: any) => ({
    type: RECEIPT_MESSAGE_ERROR,
    payload: { key: record }
});

export const getMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'donation',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );

export const getListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'donation',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData,
        'v3'
    );

// export const getExportFile = (request: any, fileName: string | undefined, version: string | undefined) => {
//     return entityHandlers.getExportFile(
//         'donation',
//         GET_EXPORT_FILE_REQUESTED,
//         GET_EXPORT_FILE_SUCCESS,
//         GET_EXPORT_FILE_FAILURE,
//         request,
//         'exportFileRecord',
//         fileName,
//         version
//     );
// };

export const getListDataBySearchTerm = (listKey: any, id: any, request: any, searchTerm: any) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getListData(listKey, id, newRequest, true);
};

export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
    entityHandlers.toggleColumnsChange(
        listKey,
        toggleColumns,
        TOGGLE_COLUMNS_CHANGE
    );

export const createTransferDonation = (data: { id: any; }) => {
    const requestModel = getTransferDonationRequestModel(data);

    return entityHandlers.createRecord(
        `donation/${data.id}/transfer`,
        CREATE_TRANSFER_DONATION_REQUESTED,
        CREATE_TRANSFER_DONATION_SUCCESS,
        CREATE_TRANSFER_DONATION_FAILURE,
        requestModel,
        null,
        'transferDonationRecord'
    );
};

export const createRecord = (record: any) =>
    entityHandlers.createRecord(
        'donation',
        CREATE_REQUESTED,
        CREATE_SUCCESS,
        CREATE_FAILURE,
        record,
        (state: any, data: any) => {
            data = update.set(data, 'template.key', 'donation-default');
            data = update.set(data, 'type', 'offline');

            data = getDataWithQuestionFields(
                data,
                paths.DONATION_FORMBUILDER,
                'customer.address',
                'customer.phoneNumber'
            );

            return update(data, {
                organisationId: { $set: get(state, 'session.organization.id') }
            });
        },
        'saveRecord'
    );

export const updateRecord = (record: { id: any; }) =>
    entityHandlers.updateRecord(
        'donation',
        UPDATE_REQUESTED,
        UPDATE_SUCCESS,
        UPDATE_FAILURE,
        record.id,
        record,
        (state: any, data: any) => {
            data = getDataWithQuestionFields(
                data,
                paths.DONATION_FORMBUILDER,
                'customer.address',
                'customer.phoneNumber'
            );

            return update(data, {
                organisationId: { $set: get(state, 'session.organization.id') }
            });
        },
        'saveRecord'
    );

export const refundDonation = (id: any, verificationToken?: string) => {
    return entityHandlers.executeRecordRequest(
        `${config.baseApiHost}/api/v2/donation/${id}/refund`,
        'POST',
        REFUND_REQUESTED,
        REFUND_SUCCESS,
        REFUND_FAILURE,
        'saveRecord',
        null,
        verificationToken || null,
    );
};

export const updateReply = (id: any) => {
    return entityHandlers.executeRequest(
        `${config.baseApiHost}/api/v2/donation/${id}/reply`,
        'PUT',
        UPDATE_DONATION_REPLY_REQUESTED,
        UPDATE_DONATION_REPLY_SUCCESS,
        UPDATE_DONATION_REPLY_FAILURE,
        {},
        (state: any, data: any) => {
            const reply = get(state, 'templateBuilderEx.data.reply');

            return reply;
        },
        'donationReplyRecord'
    );
};

export const deleteReply = (id: any) => {
    return entityHandlers.deleteRecordByPath(
        'donation',
        'reply',
        DELETE_DONATION_REPLY_REQUESTED,
        DELETE_DONATION_REPLY_SUCCESS,
        DELETE_DONATION_REPLY_FAILURE,
        id,
        'donationReplyRecord'
    );
};

export const reissueReceipt = (id: any) => {
    return entityHandlers.executeRecordRequest(
        `${config.baseApiHost}/api/v2/donation/${id}/receipt`,
        'POST',
        RECEIPT_REQUESTED,
        RECEIPT_SUCCESS,
        RECEIPT_FAILURE,
        'saveRecord'
    );
};

export const getRecord = (id: any) =>
    entityHandlers.getRecord(
        'donation',
        GET_REQUESTED,
        GET_SUCCESS,
        GET_FAILURE,
        id
    );

export const getCampaignRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/campaign',
        GET_CAMPAIGN_REQUESTED,
        GET_CAMPAIGN_SUCCESS,
        GET_CAMPAIGN_FAILURE,
        id
    );

export const getOrganizationRecord = (id: any) =>
    entityHandlers.getRecord(
        'organization',
        GET_ORGANIZATION_REQUESTED,
        GET_ORGANIZATION_SUCCESS,
        GET_ORGANIZATION_FAILURE,
        id,
        'organizationRecord'
    );

export const getPageRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/fundraiser',
        GET_PAGE_REQUESTED,
        GET_PAGE_SUCCESS,
        GET_PAGE_FAILURE,
        id
    );

export const getTeamPageRecord = (id: any) =>
    entityHandlers.getRecord(
        'page/team',
        GET_PAGE_REQUESTED,
        GET_PAGE_SUCCESS,
        GET_PAGE_FAILURE,
        id
    );

export const showReceiptConfirmation = (item: any) => {
    return { type: RECEIPT_CONFIRMATION_SHOW, payload: item };
};

export const hideReceiptConfirmation = () => {
    return { type: RECEIPT_CONFIRMATION_HIDE };
};

export const showRefundConfirmation = (item: any) => {
    return { type: REFUND_CONFIRMATION_SHOW, payload: item };
};

export const closeRefundConfirmation = () => {
    return { type: REFUND_CONFIRMATION_HIDE };
};

export const deleteOfflineDonation = (id: any) => {
    const endpoint = `${config.baseApiHost}/api/v2/donation/${id}`;
    const recordKey = 'saveRecord';

    return (dispatch: (arg0: { "@@redux-api-middleware/RSAA": { endpoint: string; method: string; headers: { ApiKey: string | undefined; Authorization: string; 'Content-Type': string; }; types: ({ type: string; payload: (action: any, state: any) => { key: string; }; } | { type: string; payload: (action: any, state: any, res: any) => Promise<any>; })[]; }; }) => void, getState: () => any) => {
        const state = getState();
        dispatch({
            [RSAA]: {
                endpoint: endpoint,
                method: 'DELETE',
                headers: {
                    ApiKey: config.apikey,
                    Authorization: `bearer ${state.session.key}`,
                    'Content-Type': 'application/json'
                },
                types: [
                    {
                        type: DELETE_REQUESTED,
                        payload: (action: any, state: any) => ({ key: recordKey })
                    },
                    {
                        type: DELETE_SUCCESS,
                        payload: async (action: any, state: any) => {
                            const payload = id;
                            return {
                                key: recordKey,
                                data: payload
                            };
                        }
                    },
                    {
                        type: DELETE_FAILURE,
                        payload: async (action: any, state: any, res: { json: () => any; status: any; }) => {
                            const json = await res.json();
                            return {
                                key: recordKey,
                                status: res.status,
                                ...json
                            };
                        }
                    }
                ]
            }
        });
    };
};

export const getTransferDonationRequestModel = (data: any) => {
    const campaignId = get(data, 'campaignId');
    const pageType = get(data, 'pageType');

    const fundraiserId = get(data, 'fundraiserId');
    const teamId = get(data, 'teamId');
    const collectionId = get(data, 'collectionId');

    if (
        pageType === pageTypesStrings.FUNDRAISER &&
        fundraiserId !== get(data, 'fundraiser.id')
    ) {
        return {
            campaignId: campaignId,
            fundraiserId: fundraiserId
        };
    }

    if (pageType === pageTypesStrings.TEAM && teamId !== get(data, 'team.id')) {
        return {
            campaignId: campaignId,
            teamId: teamId
        };
    }

    if (
        pageType === pageTypesStrings.COLLECTION &&
        collectionId !== get(data, 'collection.id')
    ) {
        return {
            campaignId: campaignId,
            collectionId: collectionId
        };
    }

    return {
        campaignId: campaignId
    };
};
