import React, { ReactNode } from 'react';
import { I18n } from 'react-redux-i18n';
import { Button, Form } from 'semantic-ui-react';
import { requiredField } from '../../constants/from';

export interface IDataFormChildProps {
  canDelete?: boolean;
  onSave: (formData?: any, updateIndex?: any) => void;
}

interface IDataFormProps extends IDataFormChildProps {
  title: string;
  children: ReactNode;
  canSave: boolean;
  editMode: boolean;
  onCancel: () => void;
  onDelete: () => void;
}

export const DataForm = ({
  title,
  children,
  canSave,
  editMode,
  canDelete,
  onSave,
  onCancel,
  onDelete
}: IDataFormProps) => {
  return (
    <Form className="collection-item coupon-item">
      <Form.Field className="item-title-container" required={requiredField}>
        <label className="item-title">{I18n.t(title)}</label>
      </Form.Field>
      <div className="coupon-item-content">
        {children}
        <div className="coupon-item-buttons">
          <Button
            primary
            className="save-coupon-button"
            disabled={!canSave}
            onClick={onSave}
          >
            {I18n.t('event.template.page.active-integration.save')}
          </Button>
          <Button
            className="cancel-coupon-button"
            style={{
              border: `${editMode ? '1px solid #c8c8c8' : 'none'}`
            }}
            onClick={onCancel}
          >
            {I18n.t('event.template.page.active-integration.cancel')}
          </Button>
          {canDelete && (
            <Button
              negative
              className="delete-coupon-button"
              onClick={onDelete}
            >
              {I18n.t('event.template.page.active-integration.delete')}
            </Button>
          )}
        </div>
      </div>
    </Form>
  );
};
