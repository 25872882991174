import React, { Component } from 'react';
import RecordResultMessage from '../../../components/common/recordResult';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { Record } from '../../../modules/bin/utility';
import * as generalActions from '../../../modules/general';
import { setRedirectUrl } from '../../../modules/session';
import { TemplateOptions } from '../../../components/template/templateOptions';
import * as donationActions from '../../donation/store/donationActions';
import DonationTemplate from '../template';
import BasePage from '../../../components/common/basePageView';
import clear from '../../../components/clear';
import { withRouter } from 'react-router-dom';
import { pageTypesStrings } from '../../../constants/pageTypes';
import Spinner from '../../../components/common/spinner';
import get from 'lodash.get';
import { pageTypes, paths, displayNameKeys } from '../constants';

class CreateOfflineDonation extends Component {
  constructor(props) {
    super(props);
    if (this.props.match.params.id) {
      if (this.props.match.params.pageType === pageTypes.FUNDRAISER) {
        props.donationActions.getPageRecord(this.props.match.params.id);
      }
      if (this.props.match.params.pageType === pageTypes.TEAM) {
        props.donationActions.getTeamPageRecord(this.props.match.params.id);
      }
    } else if (this.props.match.params.campaignId) {
      props.donationActions.getCampaignRecord(
        this.props.match.params.campaignId
      );
    }
  }

  onSave = (data) => {
    this.props.donationActions.createRecord(data);
  };

  getReturnUrl = () => {
    const { isCampaignAdmin, isEventAdmin } = this.props;
    const { campaignId } = this.props.match.params;
    let url = '/donations';
    if (this.props.redirectUrl) {
      url = this.props.redirectUrl;
      this.props.setRedirectUrl();
    } else if (isEventAdmin) {
      url = `/campaigns/view/fundraisers/${campaignId}`;
    } else if (isCampaignAdmin) {
      url = `/campaigns/view/donations/${campaignId}`;
    }

    this.props.history.push(url);
  };

  initTemplateBuilderModel = () => {
    const campaignId = parseInt(this.props.match.params.campaignId);
    const pageType = this.props.match.params.pageType;
    const id = parseInt(this.props.match.params.id);
    const campaignRecord = this.props.campaign;
    const formBuilder = get(campaignRecord, paths.DONATION_FORMBUILDER);
    const model = new TemplateOptions()
      .setValue('pageType', 'campaign')
      .setValue('selectedDisplayNameOption', displayNameKeys.asFirstName)
      .hide('displayName');

    // check if needs to pre fill campaign
    if (campaignId) {
      model.setValue('campaignId', campaignId);
      // check if needs to pre fill page
      if (pageType) {
        model.setValue('pageType', pageType);
        if (pageType === pageTypesStrings.FUNDRAISER) {
          model.setValue('fundraiserId', id);
        }
        if (pageType === pageTypesStrings.TEAM) {
          model.setValue('teamId', id);
        }
        model.setValue('pageId', pageType + '_' + id);
      }
    } else {
      model.hide('pageId');
    }

    // allows preselecting page and campaign in autocomplete select
    model.setValue('page', this.props.page);
    model.setValue('campaign', this.props.campaign);

    if (formBuilder) {
      model.setValue(paths.DONATION_FORMBUILDER, formBuilder);
    }

    this.props.templateBuilderActions.setTemplateModel(model.get());
  };

  clearMessages = () => {
    this.props.donationActions.clearRecordMessages('saveRecord');
  };

  render() {
    if (Record.isRecordLoading(this.props.record)) {
      return <Spinner />;
    }

    const isLoading = Record.isRecordLoading(this.props.saveRecord);
    const isSuccess = Record.isSuccess(this.props.saveRecord);
    if (isSuccess) {
      this.getReturnUrl();
    }

    if (!this.props.isTemplateInited) {
      const campaignId = parseInt(this.props.match.params.campaignId);
      if (campaignId) {
        if (Record.isRecordReady(this.props.record)) {
          this.initTemplateBuilderModel();
        }
      } else {
        this.initTemplateBuilderModel();
      }
    }

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <h1>
          <Translate value="donation.create.title" />
        </h1>

        <DonationTemplate onSave={this.onSave} isLoading={isLoading} />
      </BasePage>
    );
  }
}

const mapState = ({ donation, templateBuilderEx, session }) => {
  let campaign;
  let page;
  if (get(donation.record.data, 'data.campaign')) {
    campaign = donation.record.data.data.campaign;
    page = donation.record.data.data;
  } else if (get(donation.record.data, 'data')) {
    campaign = donation.record.data.data;
  }
  return {
    record: donation.record,
    campaign: campaign,
    page: page,
    saveRecord: donation.saveRecord,
    isTemplateInited: templateBuilderEx.inited,
    isEventAdmin: get(session, 'isEventAdmin'),
    isCampaignAdmin: get(session, 'isCampaignAdmin'),
    redirectUrl: session.redirectUrl
  };
};

const mapDispatch = (dispatch) => {
  return {
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    donationActions: bindActionCreators(donationActions, dispatch),
    templateBuilderActions: bindActionCreators(templateBuilderActions, dispatch)
  };
};

const CreateOfflineDonationContainer = withRouter(
  clear(connect(mapState, mapDispatch)(CreateOfflineDonation))
);
export default CreateOfflineDonationContainer;
