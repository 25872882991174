import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';
import { getAvatarImagePath } from '../../helpers/imageUrlHelper';

class PageHeader extends Component {
  onImageLoadError(event) {
    event.target.src = '';
  }

  render() {
    if (this.props.loading === true) {
      return (
        <div className="empty-header-container">
          <div className="empty-logo" />
          <div className="empty-header" />
          <div className="empty-breadcrumb" />
        </div>
      );
    }

    return (
      <div
        className={`page-header-container${
          this.props.hideImage ? ' no-image' : ''
        }`}
        style={this.props.styles}
      >
        <div className="page-header-left-panel">
          {!this.props.hideImage && (
            <div className="page-header-logo">
              <Image
                src={getAvatarImagePath(this.props.image)}
                onError={this.onImageLoadError}
                avatar
                size="medium"
              />
            </div>
          )}
          <h1 className="page-header-title">{this.props.name}</h1>
          <div className="page-breadcrumbs-cont">
            {this.props.children &&
              this.props.children.map((item, idx) => {
                if (item) {
                  return (
                    <span>
                      {item}
                      {idx !== this.props.children.length - 1 ? (
                        <i
                          aria-hidden="true"
                          className="primary chevron right icon"
                          style={{ paddingLeft: '5px', fontSize: '10px' }}
                        ></i>
                      ) : null}
                    </span>
                  );
                }
                return null;
              })
            }
            {this.props.afterBreadcrumbs}
          </div>
        </div>
        {this.props.headerRightPanel}
      </div>
    );
  }
}

class Breadcrumb extends Component {
  render() {
    const { label, ...pprops } = this.props;

    return this.props.onClick ? (
      <a {...pprops}>{label}</a>
    ) : (
      <span>{label}</span>
    );
  }
}

PageHeader.Breadcrumb = Breadcrumb;
export default PageHeader;
