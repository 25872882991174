/* eslint-disable no-template-curly-in-string */
import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import {
  getCampaignClickableColumnUrl,
  getTeamClickableColumnUrl,
  getCampaignClickableColumnForCampaignFundraisers,
  getFundraiserClickableColumnUrl,
  getCollectionClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';
import { listFilterOperator } from '../../../constants/aggregate';
import {
  eventColumns,
  eventAvailableFiltersKeys,
  eventCampaignAvailableFiltersKeys,
  eventFundraisingAvailableFilters,
  eventDonationsAvailableFilters,
  eventOrganizationTiersAvailableFilters
} from '../constants';
import { dependentOrganisationsGridColumns } from '../../../components/template/dependentOrganisations/constants';
import { PRIVACY_COLUMN } from '../../campaign/constants';
import { pageTypesStrings } from '../../../constants/pageTypes';

export const getEventsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        eventColumns.NAME,
        `${translationKey}.${eventColumns.NAME}`
      )
        .visibleByDefault()
        .makeClickable(getCampaignClickableColumnUrl, true, false),
      new ColumnModel(
        eventColumns.TARGET,
        `${translationKey}.${eventColumns.TARGET}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        eventColumns.RAISED,
        `${translationKey}.${eventColumns.RAISED}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        eventColumns.ORGANIZATIONS,
        `${translationKey}.${eventColumns.ORGANIZATIONS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        eventColumns.ACTIVE_ORGANIZATIONS,
        `${translationKey}.${eventColumns.ACTIVE_ORGANIZATIONS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        eventColumns.FUNDRAISERS,
        `${translationKey}.${eventColumns.FUNDRAISERS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        eventColumns.ACTIVE_FUNDRAISERS,
        `${translationKey}.${eventColumns.ACTIVE_FUNDRAISERS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        eventColumns.AVERAGE_DONATIONS,
        `${translationKey}.${eventColumns.AVERAGE_DONATIONS}`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        eventColumns.NUMBER_OF_DONATIONS,
        `${translationKey}.${eventColumns.NUMBER_OF_DONATIONS}`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        eventColumns.DONATIONS_NEEDED,
        `${translationKey}.${eventColumns.DONATIONS_NEEDED}`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        eventColumns.COUNTRY,
        `${translationKey}.${eventColumns.COUNTRY}`
      ).setType(columnTypes.STRING),
      new ColumnModel(
        eventColumns.STATE,
        `${translationKey}.${eventColumns.STATE}`
      ).setType(columnTypes.STRING),
      new ColumnModel(
        eventColumns.YEAR,
        `${translationKey}.${eventColumns.YEAR}`
      ).setType(columnTypes.INT32)
    ])
    .addFilterButton('live', 'event.button-group.live', {
      key: 'status',
      operator: 'EqualTo',
      value: 1
    })
    .addFilterButton('complete', 'event.button-group.complete', {
      key: 'status',
      operator: 'EqualTo',
      value: 2
    })
    .addFilterButton('archived', 'event.button-group.archived', {
      key: 'status',
      operator: 'EqualTo',
      value: 3
    })
    .addAvailableFiltersKeys(eventAvailableFiltersKeys)
    .orderBy(eventColumns.RAISED, 'desc')
    .getState();
};

export const getEventCampaignsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('name', `${translationKey}.name`)
        .visibleByDefault()
        .makeClickable(getCampaignClickableColumnUrl, true, false),
      new ColumnModel('type', `${translationKey}.campaign-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('targetAmount', `${translationKey}.goalAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.donations-required`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'pagesCount',
        `${translationKey}.fundraising-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'activePagesCount',
        `${translationKey}.active-fundraising-pages`
      ).setType(columnTypes.INT32),
      new ColumnModel('organization.currency', `${translationKey}.currency`),
      new ColumnModel('eventTier', `${translationKey}.eventTier`)
    ])
    .addContextMenuItem(
      'viewCampaign',
      'campaign.list.context.view-campaign',
      '/campaigns/view/${this.id}'
    )
    .addContextMenuItem(
      'editCampaign',
      'campaign.list.context.edit-campaign',
      '/campaigns/edit/${this.id}'
    )
    .addFilterButton('live', 'campaign.button-group.live', {
      key: 'status',
      operator: 'EqualTo',
      value: 1
    })
    .addFilterButton('complete', 'campaign.button-group.complete', {
      key: 'status',
      operator: 'EqualTo',
      value: 2
    })
    .addFilterButton('archived', 'campaign.button-group.archived', {
      key: 'status',
      operator: 'EqualTo',
      value: 3
    })
    .orderBy('raisedAmount', 'desc')
    .addAvailableFiltersKeys(eventCampaignAvailableFiltersKeys)
    .getState();
};

export const getEventDonationsGrid = (translationKey, clickableColumnsInfo) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('pageType', `${translationKey}.page-type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel(
        'donatedAtLocal',
        `${translationKey}.donationDateTimeLocal`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials-refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor-email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.phoneNumber',
        `${translationKey}.donor-phone-number`
      ),
      new ColumnModel('financials.currency', `${translationKey}.currency`)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .addAvailableFiltersKeys(eventDonationsAvailableFilters)
    .getState();
};

export const getEventFundraisingGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel(
        'createdAtLocal',
        `${translationKey}.createdAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('phoneNumber', `${translationKey}.phoneNumber`),
      new ColumnModel('status', `${translationKey}.status`).visibleByDefault(),
      new ColumnModel(PRIVACY_COLUMN, `${translationKey}.${PRIVACY_COLUMN}`),
      new ColumnModel(
        'newsletterOptIn',
        `${translationKey}.newsletterOptIn`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.neededAverageCount`
      ).setType(columnTypes.INT32)
    ])
    .orderBy('name', 'asc')
    .addFilter('type', listFilterOperator.IN_LIST, [
      pageTypesStrings.FUNDRAISER,
      pageTypesStrings.TEAM,
      pageTypesStrings.COLLECTION
    ])
    .addAvailableFiltersKeys(eventFundraisingAvailableFilters)
    .getState();
};

export const getTiersGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        dependentOrganisationsGridColumns.ASSOCIATED,
        ''
      ).visibleByDefault(),
      new ColumnModel(
        dependentOrganisationsGridColumns.IMAGE,
        ''
      ).visibleByDefault(),
      new ColumnModel(
        dependentOrganisationsGridColumns.NAME,
        `${translationKey}.name`
      ).visibleByDefault(),
      new ColumnModel(
        dependentOrganisationsGridColumns.PRIORITY,
        `${translationKey}.priority`
      ).visibleByDefault()
    ])
    .orderBy('name', 'asc')
    .addAvailableFiltersKeys(eventOrganizationTiersAvailableFilters)
    .getState();
};
