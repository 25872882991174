import * as entityHandlers from '../../../modules/bin/entityHandlers';
import update from '../../../helpers/update';
import { templateKeys } from "../../../constants/templateKeys";
import { TOGGLE_STRIPE_URL_MODAL, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAILURE, ERROR_CLEAR, GET_REQUESTED, GET_SUCCESS, GET_FAILURE, UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAILURE, TOGGLE_COLUMNS_CHANGE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, GET_STRIPE_LINK_REQUESTED, GET_STRIPE_LINK_SUCCESS, GET_STRIPE_LINK_FAILURE, GET_SALESFORCE_LINK_REQUESTED, GET_SALESFORCE_LINK_SUCCESS, GET_SALESFORCE_LINK_FAILURE } from './organizationTypes';

export const organizationActions = {
    createOrganization: (record: any) =>
        entityHandlers.createRecord(
            'organization',
            CREATE_REQUESTED,
            CREATE_SUCCESS,
            CREATE_FAILURE,
            record,
            (state: any, data: any) => {
                data = update.set(data, 'template.key', templateKeys.ORGANIZATION);

                return data;
            },
            'saveRecord'
        ),

    clearRecordMessages: (recordKey: any) => ({
        type: ERROR_CLEAR,
        payload: { key: recordKey }
    }),

    getOrganizationRecord: (
        id: any,
        requestedAction = GET_REQUESTED,
        successAction = GET_SUCCESS,
        failureAction = GET_FAILURE,
        recordKey = 'record'
    ) => {
        return entityHandlers.getRecord(
            'organization',
            requestedAction,
            successAction,
            failureAction,
            id,
            recordKey
        );
    },

    updateOrganizationRecord: (record: { id: any; }) =>
        entityHandlers.updateRecord(
            'organization',
            UPDATE_REQUESTED,
            UPDATE_SUCCESS,
            UPDATE_FAILURE,
            record.id,
            record,
            null,
            'updateRecord'
        )
};

export const toggleStripeUrlModal = () => ({
    type: TOGGLE_STRIPE_URL_MODAL,
    payload: { key: "isStripeUrlModalOpen" },
});


export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
    entityHandlers.toggleColumnsChange(
        listKey,
        toggleColumns,
        TOGGLE_COLUMNS_CHANGE
    );

// export const getExportFile = (entity: any, request: any, fileName: any) => {
//     return entityHandlers.getExportFile(
//         entity,
//         GET_EXPORT_FILE_REQUESTED,
//         GET_EXPORT_FILE_SUCCESS,
//         GET_EXPORT_FILE_FAILURE,
//         request,
//         'exportFileRecord',
//         fileName
//     );
// };

export const getMetadata = (listKey: any) =>
    entityHandlers.getMetadata(
        'organization',
        listKey,
        METADATA_REQUESTED,
        METADATA_SUCCESS,
        METADATA_FAILURE
    );
export const getListData = (
    listKey: any,
    id: any,
    request: any,
    invalidate = false,
    cachedData?: undefined
) =>
    entityHandlers.getListData(
        'organization',
        listKey,
        id,
        LIST_DATA_REQUESTED,
        LIST_DATA_SUCCESS,
        LIST_DATA_FAILURE,
        request,
        invalidate,
        cachedData
    );
export const getListDataBySearchTerm = (listKey: any, id: any, request: object, searchTerm: any) => {
    const newRequest = update(request, { text: { $set: searchTerm } });
    return getListData(listKey, id, newRequest, true);
};
export const getStripeLink = (organizationId: any) =>
    entityHandlers.getRecord(
        'organization',
        GET_STRIPE_LINK_REQUESTED,
        GET_STRIPE_LINK_SUCCESS,
        GET_STRIPE_LINK_FAILURE,
        `paymentplatform/setup?platform=Stripe&organizationId=${organizationId}&setupType=ConnectOnboarding`,
        'stripeLinkRecord'
    );
export const getStripeReconnectLink = (organizationId: any) =>
    entityHandlers.getRecord(
        'organization',
        GET_STRIPE_LINK_REQUESTED,
        GET_STRIPE_LINK_SUCCESS,
        GET_STRIPE_LINK_FAILURE,
        `paymentplatform/setup?platform=Stripe&organizationId=${organizationId}&setupType=OAuth&isReconnect=true`,
        'stripeLinkRecord'
    );
export const getStripeChangeLink = (organizationId: any) =>
    entityHandlers.getRecord(
        'organization',
        GET_STRIPE_LINK_REQUESTED,
        GET_STRIPE_LINK_SUCCESS,
        GET_STRIPE_LINK_FAILURE,
        `paymentplatform/setup?platform=Stripe&organizationId=${organizationId}&setupType=OAuth&isReconnect=false`,
        'stripeLinkChangeRecord'
    );
export const getSalesforceLink = (organizationId: any) =>
    entityHandlers.getRecord(
        'organization',
        GET_SALESFORCE_LINK_REQUESTED,
        GET_SALESFORCE_LINK_SUCCESS,
        GET_SALESFORCE_LINK_FAILURE,
        `crm/setup?platform=Salesforce&organizationId=${organizationId}&setupType=default&type=crm`,
        'salesforceLinkRecord'
    );
