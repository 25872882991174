import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Button, Card, Form } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import * as accountSettingsActions from './reducer';
import Spinner from '../../../components/common/spinner';
import { i18nKeys, paths, upload } from '../constants';
import * as uploadActions from '../../../modules/upload';
import { Record } from '../../../modules/bin/utility';
import RecordResultMessage from '../../../components/common/recordResult';
import ValidatedInput from '../../../components/common/validatedInput';
import { accountSettingsValidationModels } from './validationModels';
import { isAllFieldsValid } from '../../../helpers/validationHelper';
import get from 'lodash.get';
import { withRouter } from 'react-router-dom';
import { imageFormats } from '../../../constants/imageUpload';
import EditableUpload from '../../../components/form/editableUpload';
import {
  entityTypes,
  imageTypes,
  getFullImagePathByType
} from '../../../constants/imageUpload';
import SaveSecurityModal from './MFA/saveSecurityModal';

class AccountDetails extends Component {
  constructor(props) {
    super(props);
    props.accountSettingsActions.clearRecordEditMessages();
  }

  firstNameChange = (event, { value }) => {
    this.props.accountSettingsActions.firstNameChange(value);
  };

  lastNameChange = (event, { value }) => {
    this.props.accountSettingsActions.lastNameChange(value);
  };

  emailChange = (event, { value }) => {
    this.props.accountSettingsActions.emailChange(value);
  };

  onDrop = (files) => {
    if (files && files.length > 0) {
      this.props.uploadActions.uploadFile(
        upload.ACCOUNT_ENTITY,
        upload.AVATAR_TYPE,
        null,
        paths.PHOTO_PATH,
        files[0]
      );
    }
  };

  saveChanges = (verificationVariant = null, verificationCode = null) => {
    const { id, mainImagePath, firstName, lastName, email } =
      this.props.accountDetail;

    this.props.accountSettingsActions.updateRecord(
      id,
      { firstName, lastName, email, mainImagePath },
      verificationVariant,
      verificationCode
    );
  };

  onSaveClick = () => {
    const { hasMfaActive } = this.props.accountDetail;

    if (hasMfaActive) {
      this.props.accountSettingsActions.handleValueChange(
        'accountVerification.open',
        true
      );
    } else {
      this.saveChanges();
    }
  };

  /**
   * Clear all record messages
   */
  clearMessages = () => {
    this.props.accountSettingsActions.clearRecordEditMessages();
  };

  isAllFieldsValid = () => {
    const fieldsWithValidationModels = Object.keys(
      accountSettingsValidationModels
    ).map((item) => {
      return {
        validationModel: accountSettingsValidationModels[item],
        value: this.props.accountDetail[item]
      };
    });

    return isAllFieldsValid(fieldsWithValidationModels);
  };

  handleUpdateError = () => {
    if (this.props.accountVerification.open) {
      this.props.accountSettingsActions.verificationModalClear();
      this.props.accountSettingsActions.clearRecord('recordEdit');
    }
  };

  render() {
    const isSuccess = Record.isSuccess(this.props.recordEdit);
    const isError = Record.isError(this.props.recordEdit);
    const isLoading = Record.isRecordLoading(this.props.recordEdit);

    if (isSuccess) {
      this.props.goToPagesList();
    }

    if (isError) {
      this.handleUpdateError();
    }

    if (this.props.accountDetail) {
      const accountDetail = this.props.accountDetail;

      return (
        <div>
          <RecordResultMessage
            record={this.props.recordEdit}
            onDismiss={this.clearMessages}
          />
          <SaveSecurityModal
            isLoading={isLoading}
            handleVerificationClick={this.saveChanges}
          />
          <div className="general-conatiner">
            <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
              <div className="detail-container">
                <Card.Content>
                  <h4>Profile photo</h4>
                  <div style={{ width: '93.75%' }}>
                    <EditableUpload
                      path="accountImage"
                      url={getFullImagePathByType(
                        accountDetail.mainImagePath,
                        imageTypes.LOGO
                      )}
                      width="350"
                      height="350"
                      format={imageFormats}
                      position="left"
                      entity={entityTypes.ACCOUNT}
                      uploadType={imageTypes.LOGO}
                      onSuccess={
                        this.props.accountSettingsActions.accountImageChange
                      }
                    />
                  </div>
                  <br />
                  <Form>
                    <Form.Field width={15}>
                      <ValidatedInput
                        id="accountSettingsFirstName"
                        label={I18n.t(i18nKeys.FIRST_NAME)}
                        value={accountDetail.firstName}
                        placeholder={I18n.t(i18nKeys.FIRST_NAME)}
                        onChange={this.firstNameChange}
                        validation={accountSettingsValidationModels.firstName}
                      />
                    </Form.Field>
                    <Form.Field width={15}>
                      <ValidatedInput
                        label={I18n.t(i18nKeys.LAST_NAME)}
                        value={accountDetail.lastName}
                        id="accountSettingsLastName"
                        placeholder={I18n.t(i18nKeys.LAST_NAME)}
                        onChange={this.lastNameChange}
                        validation={accountSettingsValidationModels.lastName}
                      />
                    </Form.Field>
                    <Form.Field width={15}>
                      <ValidatedInput
                        value={accountDetail.email}
                        placeholder={I18n.t(i18nKeys.EMAIL)}
                        onChange={this.emailChange}
                        label={I18n.t(i18nKeys.EMAIL)}
                        showValidationIcon
                        validation={accountSettingsValidationModels.email}
                      />
                    </Form.Field>
                  </Form>
                  <div className="bottom-space"></div>
                </Card.Content>
              </div>
            </Card>
            <Button
              primary
              floated="right"
              id="accountSettingsSaveButton"
              loading={this.props.isSaving}
              onClick={this.onSaveClick}
              style={{ minWidth: '100px' }}
              disabled={!this.isAllFieldsValid()}
            >
              <Translate value={i18nKeys.SAVE} />
            </Button>
            <Button floated="right" onClick={this.props.onCancel}>
              <Translate value={i18nKeys.CANCEL} />
            </Button>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Spinner />
        </div>
      );
    }
  }
}

const mapState = ({ accountSettings, upload }) => {
  const uploading = get(
    upload,
    uploadActions.getProcessingPath(paths.PHOTO_PATH)
  );
  return {
    accountDetail: accountSettings.record.data.data,
    recordEdit: accountSettings.recordEdit,
    accountVerification: accountSettings.accountVerification,
    isSaving: Record.isRecordLoading(accountSettings.recordEdit),
    isImageLoading: uploading
  };
};

const mapDispatch = (dispatch) => {
  return {
    accountSettingsActions: bindActionCreators(
      accountSettingsActions,
      dispatch
    ),
    uploadActions: bindActionCreators(uploadActions, dispatch)
  };
};

export default withRouter(connect(mapState, mapDispatch)(AccountDetails));
