import config from '.';

export const campaignGiftMatchingConditionType = {
  GiftMatchingTotal: 'giftMatchingTotal',
  CampaignTotal: 'campaignTotal',
  PageFundraiser: 'pageFundraiser',
  PageCollection: 'pageCollection',
  PageTeam: 'pageTeam',
  StartDateTime: 'startDateTime',
  EndDateTime: 'endDateTime',
  DonationCount: 'donationCount',
  DonationAmount: 'donationAmount'
};

export const campaignGiftMatchingConditionOperator = {
  GreaterOrEqualThan: 'greaterOrEqualThan',
  LesserOrEqualThan: 'lesserOrEqualThan',
  EqualTo: 'equalTo',
  Between: 'between'
};

export const giftMatchingFields = {
  NAME: 'name',
  DONATION_FROM: 'donationFrom',
  DONATION_MESSAGE: 'donationMessage'
};

export const giftMatchingFieldsValidation = {
  [giftMatchingFields.NAME]: {
    required: true,
    maxLength: true,
    length: config.GIFTMATCHINGBUILDER_NAME_MAX_LENGTH
  },
  [giftMatchingFields.DONATION_FROM]: {
    maxLength: true,
    length: config.GIFTMATCHINGBUILDER_DONATION_FROM_MAX_LENGTH
  },
  [giftMatchingFields.DONATION_MESSAGE]: {
    maxLength: true,
    length: config.GIFTMATCHINGBUILDER_DONATION_MESSAGE_MAX_LENGTH
  }
};

export const campaignGiftMatchingConditionTypeOptions = [
  {
    key: campaignGiftMatchingConditionType.GiftMatchingTotal,
    text: 'Gift Matching Total',
    value: campaignGiftMatchingConditionType.GiftMatchingTotal
  },
  {
    key: campaignGiftMatchingConditionType.CampaignTotal,
    text: 'Campaign Total',
    value: campaignGiftMatchingConditionType.CampaignTotal
  },
  {
    key: campaignGiftMatchingConditionType.PageFundraiser,
    text: 'Page Fundraiser',
    value: campaignGiftMatchingConditionType.PageFundraiser
  },
  {
    key: campaignGiftMatchingConditionType.PageTeam,
    text: 'Page Team',
    value: campaignGiftMatchingConditionType.PageTeam
  },
  {
    key: campaignGiftMatchingConditionType.PageCollection,
    text: 'Page Superteam',
    value: campaignGiftMatchingConditionType.PageCollection
  },
  {
    key: campaignGiftMatchingConditionType.StartDateTime,
    text: 'Date/Time',
    value: campaignGiftMatchingConditionType.StartDateTime
  },
  {
    key: campaignGiftMatchingConditionType.EndDateTime,
    text: 'Date/Time',
    value: campaignGiftMatchingConditionType.EndDateTime
  },
  {
    key: campaignGiftMatchingConditionType.DonationCount,
    text: 'Donation Count',
    value: campaignGiftMatchingConditionType.DonationCount
  },
  {
    key: campaignGiftMatchingConditionType.DonationAmount,
    text: 'Donation Amount',
    value: campaignGiftMatchingConditionType.DonationAmount
  }
];

export const campaignGiftMatchingConditionOperatorOptions = [
  {
    key: campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
    text: 'Greater Or Equal Than',
    value: campaignGiftMatchingConditionOperator.GreaterOrEqualThan
  },
  {
    key: campaignGiftMatchingConditionOperator.LesserOrEqualThan,
    text: 'Lesser Or Equal Than',
    value: campaignGiftMatchingConditionOperator.LesserOrEqualThan
  },
  {
    key: campaignGiftMatchingConditionOperator.EqualTo,
    text: 'Equal To',
    value: campaignGiftMatchingConditionOperator.EqualTo
  },
  {
    key: campaignGiftMatchingConditionOperator.Between,
    text: 'Between',
    value: campaignGiftMatchingConditionOperator.Between
  }
];

export const condtionIputType = {
  number: 'number',
  date: 'date',
  select: 'select'
};

export const campaignGiftMatchingConditionTypeSettings = {
  [campaignGiftMatchingConditionType.GiftMatchingTotal]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.LesserOrEqualThan],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.EqualTo,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.number
  },
  [campaignGiftMatchingConditionType.CampaignTotal]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.LesserOrEqualThan],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.EqualTo,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.number
  },
  [campaignGiftMatchingConditionType.PageFundraiser]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    allowedInstanceOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    inputType: condtionIputType.select
  },
  [campaignGiftMatchingConditionType.PageTeam]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    allowedInstanceOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    inputType: condtionIputType.select
  },
  [campaignGiftMatchingConditionType.PageCollection]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    allowedInstanceOperators: [campaignGiftMatchingConditionOperator.EqualTo],
    inputType: condtionIputType.select
  },
  [campaignGiftMatchingConditionType.StartDateTime]: {
    allowedOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan
    ],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.EqualTo,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.date
  },
  [campaignGiftMatchingConditionType.EndDateTime]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.LesserOrEqualThan],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.EqualTo,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.date
  },
  [campaignGiftMatchingConditionType.DonationCount]: {
    allowedOperators: [campaignGiftMatchingConditionOperator.LesserOrEqualThan],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.EqualTo,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.number
  },
  [campaignGiftMatchingConditionType.DonationAmount]: {
    allowedOperators: [
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.Between,
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan
    ],
    allowedInstanceOperators: [
      campaignGiftMatchingConditionOperator.GreaterOrEqualThan,
      campaignGiftMatchingConditionOperator.LesserOrEqualThan,
      campaignGiftMatchingConditionOperator.Between
    ],
    inputType: condtionIputType.number
  }
};

export const campaignGiftMatchingConditions = [
  campaignGiftMatchingConditionType.StartDateTime,
  campaignGiftMatchingConditionType.EndDateTime,
  campaignGiftMatchingConditionType.GiftMatchingTotal,
  campaignGiftMatchingConditionType.CampaignTotal,
  campaignGiftMatchingConditionType.DonationCount,
  campaignGiftMatchingConditionType.DonationAmount
];

export const maxMultiplierValue = 5;

export const getPageTypeOptions = (collectionGeneralName) => [
  {
    key: campaignGiftMatchingConditionType.PageFundraiser,
    text: 'Fundraiser',
    value: campaignGiftMatchingConditionType.PageFundraiser
  },
  {
    key: campaignGiftMatchingConditionType.PageTeam,
    text: 'Team',
    value: campaignGiftMatchingConditionType.PageTeam
  },
  {
    key: campaignGiftMatchingConditionType.PageCollection,
    text: collectionGeneralName || 'Superteam',
    value: campaignGiftMatchingConditionType.PageCollection
  }
];
