export const fieldTypes = {
  textInput: 'Text',
  date: 'Date',
  checkbox: 'MultipleChoice',
  consent: 'MultipleChoice',
  dropdown: 'SingleChoice',
  country: 'SingleChoice',
  additionalPhoneNumber: 'AdditionalPhoneNumber',
  additionalEmailAddress: 'AdditionalEmailAddress',
  additionalFirstName: 'AdditionalFirstName',
  additionalLastName: 'AdditionalLastName'
};
