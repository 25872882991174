import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import { merchandiseAvailableFiltersKeys } from '../constants';

export const getMerchandiseGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization.name`
      ).visibleByDefault(),
      new ColumnModel('name', `${translationKey}.name`).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('salesTotalAmount', `${translationKey}.salesTotalAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('enabled', `${translationKey}.enabled`).setType(
        columnTypes.BOOLEAN
      )
    ])
    .orderBy('createdAtLocal', 'desc')
    .addAvailableFiltersKeys(merchandiseAvailableFiltersKeys)
    .getState();
};
