import ColumnModel from '../../../components/common/grid/columnModel';
import { columnTypes } from '../../../components/common/grid/constants';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import { listFilterOperator } from '../../../constants/aggregate';
import { pageTypesStrings } from '../../../constants/pageTypes';
import {
  getCampaignClickableColumnForCampaignFundraisers,
  getTeamClickableColumnUrl,
  getFundraiserClickableColumnUrl,
  getCollectionClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';
import { PRIVACY_COLUMN } from '../../campaign/constants';
import { fundraiserAvailableFilters } from '../constants';

export const getFundraiserGrid = (
  translationKey: string,
  pageSize?: number
) => {
  return new GridOptions()
    .setPageSize(pageSize)
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('type', `${translationKey}.page-type`)
        .setType(columnTypes.CAPITALIZE_STRING)
        .visibleByDefault(),
      new ColumnModel('fundraiser.name', `${translationKey}.fundraiser-name`)
        .visibleByDefault()
        .makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .visibleByDefault()
        .makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('targetAmount', `${translationKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'collection.name',
        `${translationKey}.collection-name`
      ).makeClickable(getCollectionClickableColumnUrl, true, false),
      new ColumnModel(
        'createdAtLocal',
        `${translationKey}.createdAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('account.firstName', `${translationKey}.first-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.lastName', `${translationKey}.last-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('account.email', `${translationKey}.email`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel('phoneNumber', `${translationKey}.phoneNumber`),
      new ColumnModel('status', `${translationKey}.status`),
      new ColumnModel(PRIVACY_COLUMN, `${translationKey}.${PRIVACY_COLUMN}`),
      new ColumnModel(
        'newsletterOptIn',
        `${translationKey}.newsletterOptIn`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'averageDonationAmount',
        `${translationKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${translationKey}.donationCount`
      ).setType(columnTypes.INT32),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${translationKey}.neededAverageCount`
      ).setType(columnTypes.INT32)
    ])
    .orderBy('raisedAmount', 'desc')
    .addFilter('type', listFilterOperator.IN_LIST, [
      pageTypesStrings.FUNDRAISER,
      pageTypesStrings.TEAM,
      pageTypesStrings.COLLECTION
    ])
    .addFilterButton('active', 'fundraiser.button-group.active', {
      key: 'isActive',
      operator: 'EqualTo',
      value: true
    })
    .addFilterButton('inactive', 'fundraiser.button-group.inactive', {
      key: 'isActive',
      operator: 'EqualTo',
      value: false
    })
    .addAvailableFiltersKeys(fundraiserAvailableFilters)
    .getState();
};
