import { TemplateOptions } from '../../../components/template/templateOptions';
import { getDefaultAboutElements } from '../../../helpers/pageAboutHelper';
import update from '../../../helpers/update';
import { paths } from '../constants/template';

const getDefaultModel = () => {
  const model = update.set({}, paths.DEFAULT_AMOUNTS, [
    { isDefault: true, amount: 30, description: '', image: '' },
    { isDefault: false, amount: 50, description: '', image: '' },
    { isDefault: false, amount: 75, description: '', image: '' },
    { isDefault: false, amount: 100, description: '', image: '' }
  ]);

  return model;
};

export const template = () => {
  return new TemplateOptions()
    .setModel(getDefaultModel())
    .setValue(paths.SPECIAL_EFFECTS_CONFETTI_ENABLED, false)
    .setValue(paths.EVENT_ABOUT, getDefaultAboutElements())
    .setValue(paths.CAMPAIGN_ABOUT, getDefaultAboutElements())
    .setValue(paths.FUNDRAISERS_ENABLED, true)
    .setValue(paths.TEAMS_ENABLED, true)
    .setValue(paths.ALLOW_FUNDRAISER_SELF_SIGN_UP, true)
    .setValue(paths.ALLOW_TEAM_SELF_SIGN_UP, true)
    .setValue(paths.DEFAULT_TEXT_FOR_TEAM_PAGES, getDefaultAboutElements())
    .setValue(
      paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES,
      getDefaultAboutElements()
    )
    .setValue(paths.INCLUDE_FEE, true)
    .setValue(paths.ALLOW_RECURRING_GIVING, true)
    .setValue(paths.DONOR_ALIAS, 'Donors')
    .setValue(paths.ALLOW_ACHIEVEMETNS, true)
    .setValue(paths.ALLOW_MICROBLOGS, true)
    .setValue(paths.INVERT_PRIMARY_COLOR, false)
    .setValue(paths.INVERT_SECONDARY_COLOR, false);
};
