//---------------React/Redux
import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import AnimateHeight from 'react-animate-height';

//---------------Components
import { Form as CommonForm } from '../..';
import {
  Form,
  Input,
  Checkbox,
  Icon,
  Dropdown,
  Button
} from 'semantic-ui-react';
import { TemplateHeader } from '../../template/templateHeader';
import TemplateFormBuilder from '../../template/templateFromBuilder';
import DatePickerInput from '../../form/datePicker';

//---------------Helpers
import { getFromBuilderInitialData } from '../../../helpers/formBuilderHelper';

//---------------Constants
import { i18Keys } from '../translation';
import { countries } from '../../../constants/countries';
import { paths } from '../template';
import { TicketTypeOptions } from '../../../constants/ticketTypes';
import { eachTicketholderFundraises } from '../../../pages/campaign/constants';
import ConfirmationModal from '../../common/confirmationModal';

const TicketRenderComponent = (props: any) => {
  const {
    index,
    name,
    country,
    pricing,
    stream,
    inventory,
    isUnlimited,
    isFree,
    formBuilderKey,
    description,
    type,
    onChange,
    onPriceChange,
    onFreeTicketChange,
    path,
    connectDragSource,
    isQuestionsEnabled,
    isSystemAdmin,
    availableUntil,
    availableFrom,
    isExpanded,
    onCloneTicketClick,
    purchaseFundraisingStyle,
    template,
  } = props;

  const required = {
    required: true
  };

  const formBuilderQuestionsValidation = {
    formBuilderQuestions: true
  };

  const getTaxFieldTextDescriptor = () => {
    if (country) {
      // @ts-ignore
      return countries[country].taxDescriptor;
    }
    return 'default';
  };

  const updateExpanded = () => {
    onChange(index, paths.isExpanded, !isExpanded);
  };

  const getName = (index: number) => {
    let nameSuffix = '';
    if (name) {
      nameSuffix = ` - (${name})`;
    }
    return ` ${index + 1}${nameSuffix}`;
  };

  const i18Key =
    'template.page.content.ticketing.tickets.available-tickets.ticket';
  const taxKey = `price.tax.${getTaxFieldTextDescriptor()}.label`;

  return connectDragSource(
    <div>
      <Form className="ticket-item">
        <Form.Field className="ticket-item-title-container" required={required}>
          <label
            className={`ticket-item-title-label${
              !isExpanded ? '-collapsed' : ''
            }`}
            onClick={updateExpanded}
          >
            {I18n.t(`${i18Key}.${i18Keys.ITEM_HEADER}`, {
              id: getName(index)
            })}
          </label>
          <Icon
            name="remove circle"
            className="right-float"
            size="large"
            style={{ cursor: 'pointer' }}
            onClick={() => props.onDeleteClick(index)}
          />
        </Form.Field>

        <AnimateHeight
          animateOpacity
          duration={300}
          height={isExpanded ? 'auto' : 0}
        >
          <div className="ticket-item-content">
            {isSystemAdmin && (
              <Form.Field required={required}>
                <label>{I18n.t(`${i18Key}.${i18Keys.ITEM_TYPE_LABEL}`)}</label>
                <Dropdown
                  id={`ticket-type-${index}`}
                  options={TicketTypeOptions}
                  onChange={(_, { value }) => {
                    onChange(index, paths.type, value);
                  }}
                  value={type}
                  selection
                  placeholder="Select..."
                  icon="chevron down"
                />
              </Form.Field>
            )}

            <Form.Field required={required}>
              <label>{I18n.t(`${i18Key}.${i18Keys.ITEM_TITLE_LABEL}`)}</label>
              <Input
                id={`ticket-title-${index}`}
                fluid
                value={name}
                onChange={(_, { value }) => {
                  onChange(index, paths.name, value);
                }}
              />
            </Form.Field>

            {isSystemAdmin && (
              <Form.Field>
                <label>
                  {I18n.t(`${i18Key}.${i18Keys.ITEM_STREAM_LABEL}`)}
                </label>
                <Input
                  id={`ticket-title-${index}`}
                  fluid
                  value={stream}
                  onChange={(_, { value }) => {
                    onChange(index, paths.stream, value);
                  }}
                />
              </Form.Field>
            )}

            <Form.Group unstakable>
              <Form.Field width={6} required={required}>
                <label>{I18n.t(`${i18Key}.${i18Keys.ITEM_PRICE_LABEL}`)}</label>
                <CommonForm.NumberInput
                  id={`ticket-price-${index}`}
                  disabled={isFree}
                  type="tel"
                  inputLabel="$"
                  style={{ margin: '0px' }}
                  value={pricing.price}
                  allowDecimal
                  onChange={(event: any) => {
                    onPriceChange(index, paths.price, event.target.value);
                  }}
                />
              </Form.Field>
              <Form.Field width={10}>
                <Checkbox
                  id={`ticket-isFree-${index}`}
                  className="unstakable-checkbox"
                  checked={isFree === true}
                  onChange={(_, { checked }) => {
                    onFreeTicketChange(index, paths.price, checked);
                  }}
                  label={I18n.t(`${i18Key}.${i18Keys.ITEM_PRICE_FREE}`)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <Checkbox
                id={`ticket-price-tax-${index}`}
                disabled={!pricing.price}
                checked={pricing.hasCountryTax}
                onChange={(event, { checked }) => {
                  onChange(index, paths.tax, checked);
                }}
                label={I18n.t(`${i18Key}.${taxKey}`)}
              />
            </Form.Field>

            <Form.Group unstakable>
              <Form.Field width={6}>
                <label>
                  {I18n.t(`${i18Key}.${i18Keys.ITEM_QUANTITY_LABEL}`)}
                </label>
                <CommonForm.NumberInput
                  id={`ticket-${index}`}
                  disabled={isUnlimited}
                  type="tel"
                  style={{ margin: '0px' }}
                  value={inventory.quantity}
                  allowDecimal={false}
                  onChange={(event: any) => {
                    onChange(index, paths.quantity, event.target.value);
                  }}
                />
              </Form.Field>

              <Form.Field width={10}>
                <Checkbox
                  id={`ticket-isUnlimited-${index}`}
                  className="unstakable-checkbox"
                  checked={isUnlimited === true}
                  onChange={(_, { checked }) => {
                    onChange(index, paths.quantity, checked ? null : 0);
                  }}
                  label={I18n.t(`${i18Key}.${i18Keys.ITEM_QUANTITY_UNLIMITED}`)}
                />
              </Form.Field>
            </Form.Group>

            <Form.Field>
              <label>
                {I18n.t(`${i18Key}.${i18Keys.ITEM_DESCRIPTION_LABEL}`)}
              </label>
              <Input
                id={`ticket-description-${index}`}
                fluid
                value={description}
                onChange={(_, { value }) => {
                  onChange(index, paths.description, value);
                }}
              />
            </Form.Field>

            <Form.Field>
              <DatePickerInput
                id={`ticket-availableFrom-${index}`}
                label={I18n.t(`${i18Key}.${i18Keys.ITEM_AVAILABLE_FROM_LABEL}`)}
                subtext={I18n.t(
                  `${i18Key}.${i18Keys.ITEM_AVAILABLE_FROM_SUBTEXT}`
                )}
                value={availableFrom || ''}
                onChange={(value: any) => {
                  onChange(index, paths.availableFrom, value);
                }}
              />
            </Form.Field>

            <Form.Field>
              <DatePickerInput
                id={`ticket-availableUntil-${index}`}
                label={I18n.t(
                  `${i18Key}.${i18Keys.ITEM_AVAILABLE_UNTIL_LABEL}`
                )}
                subtext={I18n.t(
                  `${i18Key}.${i18Keys.ITEM_AVAILABLE_UNTIL_SUBTEXT}`
                )}
                value={availableUntil || ''}
                onChange={(value: any) => {
                  onChange(index, paths.availableUntil, value);
                }}
              />
            </Form.Field>

            {template?.value?.hasOwnProperty('useAsTargetAmount') && 
              purchaseFundraisingStyle === eachTicketholderFundraises && (
                <Form.Field>
                  <label>
                    {I18n.t(
                      `${i18Key}.${i18Keys.ITEM_FUNDRAISER_TARGET_AMOUNT_LABEL}`
                    )}
                  </label>
                  <CommonForm.NumberInput
                    id={`ticket-FundraiserTargetAmount-${index}`}
                    subtext={I18n.t(
                      `${i18Key}.${i18Keys.ITEM_FUNDRAISER_TARGET_AMOUNT_SUBTEXT}`
                    )}
                    type="tel"
                    style={{ margin: '0px' }}
                    value={template?.value?.useAsTargetAmount}
                    allowDecimal={false}
                    onChange={(event: any) => {
                      onChange(
                        index,
                        paths.useAsTargetAmount,
                        event.target.value
                      );
                    }}
                  />
                </Form.Field>
              )}

            {isQuestionsEnabled && (
              <Form.Field>
                <TemplateHeader
                  as="h5"
                  label={I18n.t(`${i18Key}.${i18Keys.ITEM_QUESTIONS_LABEL}`)}
                />

                <TemplateFormBuilder
                  formBuilderKey={formBuilderKey}
                  path={path}
                  initialData={getFromBuilderInitialData(formBuilderKey)}
                  validation={formBuilderQuestionsValidation}
                  onChange={(_: any, value: any) => {
                    onChange(index, paths.formbuilder, value);
                  }}
                />
              </Form.Field>
            )}

            {onCloneTicketClick && (
              <Button
                className="clone-button"
                as="div"
                basic
                onClick={() => onCloneTicketClick(index)}
              >
                <Translate value="components.common.clone" />
              </Button>
            )}
          </div>
        </AnimateHeight>
      </Form>
    </div>
  );
};

export default TicketRenderComponent;
