import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import CurrencyHelper from '../../../../helpers/currencyHelper';

export const DonationPlatformSilentFeeSectionRow = (props) => {
  const { title, data } = props;

  const currency = !props.displayOrgCurrency
    ? get(data, 'currency')
    : get(data, 'organization.currency');
  const feeDetails = get(data, 'financials.feeDetail');
  const silentFee = props.displayOrgCurrency
    ? get(feeDetails, 'silentFee') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'silentFee');
  const silentFeeTax = props.displayOrgCurrency
    ? get(feeDetails, 'silentFeeTax') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'silentFeeTax');

  const silentFeeString = CurrencyHelper.formatCurrency(silentFee, currency);
  const silentFeeTaxString = CurrencyHelper.formatCurrency(silentFeeTax, currency);

  const silentFeePercentage = get(feeDetails, 'silentFeePercentage');

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${silentFeeString} (includes ${silentFeeTaxString} tax)`}</span>
          {silentFeePercentage !== 0 &&
            silentFeePercentage !== null &&
            silentFeePercentage > 0 && (
              <span>{`${silentFeePercentage * 100}% mandatory`}</span>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};
