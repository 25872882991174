import React from "react";

import { Menu } from "semantic-ui-react";

import "./tabs.css";

interface TabsProps {
    buttons: {
        key: string;
        label: string;
    }[];
    selectedTab: string;
    onChangeSelectedTab: (newSelectedTab: string) => void;
}

export const Tabs: React.FC<TabsProps> = (props) => {
    return (
        <Menu className="tabs">
            {props.buttons.map((item) => {
                return (
                    <Menu.Item
                        key={item.key}
                        content={item.label}
                        active={item.key === props.selectedTab}
                        onClick={() => {
                            if (item.key !== props.selectedTab) props.onChangeSelectedTab(item.key);
                        }}
                    />
                );
            })}
        </Menu>
    );
};
