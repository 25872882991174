import React, { Component } from 'react';
import { Button as SemanticButton } from 'semantic-ui-react';

class ButtonGroup extends Component {
  render() {
    const { children, ...props } = this.props;
    return <SemanticButton.Group {...props}>{children}</SemanticButton.Group>;
  }
}

export default ButtonGroup;
