import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import CurrencyHelper from '../../../../helpers/currencyHelper';

export const DonationEventOrganizerFeeSectionRow = (props) => {
  const { title, data } = props;

  const feeDetails = get(data, 'financials.feeDetail');
  const currency = !props.displayOrgCurrency
    ? get(data, 'currency')
    : get(data, 'organization.currency');
  const eventOrganizerFee = props.displayOrgCurrency
    ? get(feeDetails, 'eventOrganizerFee') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'eventOrganizerFee');
  const eventOrganizerFeeTax = props.displayOrgCurrency
    ? get(feeDetails, 'eventOrganizerFeeTax') * get(data, 'financials.exchangeRate')
    : get(feeDetails, 'eventOrganizerFeeTax');
  const eventOrganizerFeePercentage = get(
    feeDetails,
    'eventOrganizerFeePercentage'
  );

  const eventOrganizerFeeString = `${CurrencyHelper.formatCurrency(
    eventOrganizerFee,
    currency
  )}`;
  const eventOrganizerFeeTaxString = `${CurrencyHelper.formatCurrency(
    eventOrganizerFeeTax,
    currency
  )}`;

  const eventOrganizerFeePercentageString = `${CurrencyHelper.roundToDecimal(eventOrganizerFeePercentage * 100, 2)}%`;

  return (
    <React.Fragment>
      <div className="view-details-content-row column">
        <h4>{I18n.t(title)}</h4>
        <div className="column-content">
          <span>{`${eventOrganizerFeeString} (includes ${eventOrganizerFeeTaxString} tax)`}</span>
          {eventOrganizerFeePercentage !== 0 &&
            eventOrganizerFeePercentage > 0 && (
              <span>{`${eventOrganizerFeePercentageString} mandatory`}</span>
            )}
        </div>
      </div>
    </React.Fragment>
  );
};
