import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import get from 'lodash.get';
import * as sessionActions from '../modules/session';
import config from '../constants';
import { grecaptcha } from '../helpers/grecaptcha';

const ping = (ComposedComponent) => {
  class hoc extends React.Component {
    constructor(props) {
      super(props);

      this.pingProcessing = false;
      this.currentPingDelay = null;
      this.intervalId = null;
    }

    componentDidMount() {
      this.pingHandler();
    }

    componentDidUpdate() {
      this.pingHandler();
    }

    pingHandler = () => {
      if (this.isSessionAlive() && !this.pingProcessing) {
        this.pingProcessing = true;
        setTimeout(this.pingSession, config.PING_DELAY);
        this.logPingExecute();
      }
    };

    pingSession = () => {
      grecaptcha(this.props.pingSession, 'SessionOperation');

      console.log(`[PING INFO] : Ping executed`);
      this.pingProcessing = false;
      this.logPingStop();
      this.pingHandler();
    };

    logPingExecute = () => {
      this.currentPingDelay = config.PING_DELAY;
      console.log(`[PING INFO] : Ping inited`);
      console.log(
        `[PING INFO] : Ping delay : ${this.millisToMinutesAndSeconds(
          config.PING_DELAY
        )}`
      );
      this.intervalId = setInterval(
        this.logPingInfo,
        config.PING_INFORMATION_DELAY
      );
    };

    logPingInfo = () => {
      this.currentPingDelay -= config.PING_INFORMATION_DELAY;
      console.log(
        `[PING INFO] : Ping delay : ${this.millisToMinutesAndSeconds(
          this.currentPingDelay
        )}`
      );
    };

    logPingStop = () => {
      clearInterval(this.intervalId);
      this.intervalId = null;
    };

    millisToMinutesAndSeconds = (millis) => {
      var minutes = Math.floor(millis / 60000);
      var seconds = ((millis % 60000) / 1000).toFixed(0);
      return minutes + ':' + (seconds < 10 ? '0' : '') + seconds;
    };

    isSessionAlive = () => {
      return this.props.sessionKey !== null;
    };

    render() {
      return <ComposedComponent />;
    }
  }

  return withRouter(connect(mapState, mapDispatch)(hoc));
};

const mapState = (state, props) => {
  return {
    sessionKey: get(state, 'session.key')
  };
};

const mapDispatch = (dispatch) => {
  return {
    pingSession: (verificationToken = null) => {
      dispatch(sessionActions.pingSession(verificationToken));
    }
  };
};

export default ping;
