import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// import { AppContainer } from 'react-hot-loader';
import PropTypes from 'prop-types';

import { formBuilders } from './store';
import FormBuilderComponent from './components/FormBuilder';
import { mapFromFormBuilder, mapToFormBuilder } from './formMappers';

const FormBuilderPage = ({
  formBuilderKey,
  dataCallback,
  form,
  subtext,
  isAddressHidden,
  isPhoneHidden,
  isCountryAllowed,
  formQuestionsMaxLength,
  disabled
}) => (
  <FormBuilderComponent
    formBuilderKey={formBuilderKey}
    dataCallback={dataCallback}
    form={form}
    subtext={subtext}
    isAddressHidden={isAddressHidden}
    isPhoneHidden={isPhoneHidden}
    isCountryAllowed={isCountryAllowed}
    formQuestionsMaxLength={formQuestionsMaxLength}
    disabled={disabled}
  />
);

FormBuilderPage.propTypes = {
  formBuilderKey: PropTypes.string,
  dataCallback: PropTypes.func,
  initialData: PropTypes.object
};

export {
  FormBuilderPage as default,
  formBuilders,
  mapFromFormBuilder,
  mapToFormBuilder
};
