import React, { Component } from 'react';

import { Translate } from 'react-redux-i18n';
import { Modal, Button } from 'semantic-ui-react';

class EntityModal extends Component {
  render() {
    return (
      <Modal size="tiny" dimmer="inverted" closeIcon open={this.props.open}>
        <Modal.Header>{this.props.title}</Modal.Header>
        <Modal.Content>
          {this.props.children}
          <ul>
            {this.props.errors &&
              Object.keys(this.props.errors).map((key) =>
                this.props.errors[key].map((item) => {
                  const itemKey = `${this.props.errorKeyRoot}.${key}.${item}`;
                  return (
                    <li>
                      <Translate value={itemKey} />
                    </li>
                  );
                })
              )}
          </ul>
          <div className="button-wrapper align-right">
            <Button primary onClick={() => this.props.onClose()}>
              <Translate value="general.close" />
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

export default EntityModal;
