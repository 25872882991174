import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import { organizationActions } from '../../pages/organization/store/organizationActions';
import * as generalActions from '../../modules/general';
import { refreshSession } from '../../modules/session';

import get from 'lodash.get';
import { Record } from '../../modules/bin/utility';
import LocalStorage from '../../helpers/localStorageHelper';

import { Container, Message } from 'semantic-ui-react';
import Navigation from '../../containers/navigation';
import GeneralErrorModal from './generalError';
import SessionErrorModal from './sessionError';
import SessionVerificationModal from './sessionVerification';
import Spinner from '../common/spinner';

import { localStorageKeys } from '../../constants/localStorageKeys';
import { grecaptcha } from '../../helpers/grecaptcha';
class General extends Component {
  needLoadOrgInfo = (paymentInfo) => {
    return (
      this.props.orgId &&
      this.props.session.isOrganizationAdmin &&
      (!paymentInfo || !this.isPaymentInfoOrgIdCorrect(paymentInfo))
    );
  };

  isPaymentInfoOrgIdCorrect = (paymentInfo) => {
    const paymentInfoOrgId = paymentInfo && paymentInfo.orgId;

    return paymentInfoOrgId === this.props.orgId;
  };

  showSpinner = () => {
    return (
      Record.isRecordLoading(this.props.session.logOutRecord) ||
      Record.isRecordLoading(this.props.generalOrgRecord)
    );
  };

  handleSessionRefresh = (accountId, code) => {
    const tokenCallback = (token) => this.props.refreshSession(accountId, code, token);
    grecaptcha(tokenCallback, 'SessionRefresh');
  };

  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const paymentInfo = LocalStorage.getItem(localStorageKeys.PAYMENT_INFO);

    if (this.needLoadOrgInfo(paymentInfo)) {
      this.props.getOrganizationRecord(this.props.orgId);
    }

    const isNotConnected = get(paymentInfo, 'isConnected') === false;

    return (
      <div>
        <SessionErrorModal
          show={
            !this.props.verification &&
            !this.props.forceMfa &&
            (this.props.unauthorized || !this.props.isAdmin)
          }
          session={this.props.session}
          onSessionRefresh={this.handleSessionRefresh}
        />
        <SessionVerificationModal show={this.props.verification} />
        <GeneralErrorModal errors={this.props.errors} />
        {this.isPaymentInfoOrgIdCorrect(paymentInfo) && (
          <Message
            className="record-result"
            info
            attached="bottom"
            hidden={
              !this.props.session.isOrganizationAdmin ||
              (this.props.session.isOrganizationAdmin && !isNotConnected)
            }
          >
            To create a campaign you must{' '}
            <Link to={`/organizations/edit/${this.props.orgId}/finance`} push>
              link a payment gateway
            </Link>
          </Message>
        )}
        <header>
          <Navigation />
        </header>
        <Container style={{ marginTop: '8em' }}>
          {this.props.children}
        </Container>
      </div>
    );
  }
}

const mapState = ({ general, session, accountSettings }) => {
  const orgId = get(session, 'organization.id');

  return {
    errors: (general && general.errorsStateful) || [],
    unauthorized: general.unauthorized,
    verification: general.verification,
    forceMfa: accountSettings?.MFA?.forceMfa,
    session,
    orgId,
    generalOrgRecord: general.generalOrgRecord,
    isAdmin: session.isAdmin
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(generalActions, dispatch),
    refreshSession(accountId, code, verificationToken = null) {
      dispatch(refreshSession(accountId, code, verificationToken));
    },
    getOrganizationRecord(id) {
      dispatch(
        organizationActions.getOrganizationRecord(
          id,
          generalActions.GET_ORGANIZATION_REQUESTED,
          generalActions.GET_ORGANIZATION_SUCCESS,
          generalActions.GET_ORGANIZATION_FAILURE,
          'generalOrgRecord'
        )
      );
    }
  };
};

export default connect(mapState, mapDispatch)(General);
