export const WEBHOOK_API_PATH = 'organizationWebhookSubscriber';

export const WEBHOOKS_LIST_DATA_REQUESTED = 'webhooks/REQUESTED';
export const WEBHOOKS_LIST_DATA_SUCCESS = 'webhooks/SUCCESS';
export const WEBHOOKS_LIST_DATA_FAILURE = 'webhooks/FAILURE';

export const WEBHOOKS_METADATA_REQUESTED = 'webhooks/METADATA_REQUESTED';
export const WEBHOOKS_METADATA_SUCCESS = 'webhooks/METADATA_SUCCESS';
export const WEBHOOKS_METADATA_FAILURE = 'webhooks/METADATA_FAILURE';

export const WEBHOOKS_CREATE_REQUESTED = 'webhooks/WEBHOOKS_CREATE_REQUESTED';
export const WEBHOOKS_CREATE_SUCCESS = 'webhooks/WEBHOOKS_CREATE_SUCCESS';
export const WEBHOOKS_CREATE_FAILURE = 'webhooks/WEBHOOKS_CREATE_FAILURE';

export const WEBHOOK_UPDATE_REQUESTED = 'webhooks/WEBHOOK_UPDATE_REQUESTED';
export const WEBHOOK_UPDATE_SUCCESS = 'webhooks/WEBHOOK_UPDATE_SUCCESS';
export const WEBHOOK_UPDATE_FAILURE = 'webhooks/WEBHOOK_UPDATE_FAILURE';

export const WEBHOOKS_DELETE_REQUESTED = 'webhooks/WEBHOOKS_DELETE_REQUESTED';
export const WEBHOOKS_DELETE_SUCCESS = 'webhooks/WEBHOOKS_DELETE_SUCCESS';
export const WEBHOOKS_DELETE_FAILURE = 'webhooks/WEBHOOKS_DELETE_FAILURE';

export const WEBHOOKS_GET_REQUESTED = 'webhooks/WEBHOOKS_GET_REQUESTED';
export const WEBHOOKS_GET_SUCCESS = 'webhooks/WEBHOOKS_GET_SUCCESS';
export const WEBHOOKS_GET_FAILURE = 'webhooks/WEBHOOKS_GET_FAILURE';

export const WEBHOOKS_MESSAGE_CLEAR = 'webhooks/MESSAGE_CLEAR';
