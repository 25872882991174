import React from 'react';
import { Input } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import FormbuilderFieldWrapper from '../../../../formBuilderAnswersComponents/formbuilderFieldWrapper';

class TextField extends React.Component {
  componentDidMount() {
    if (this.TextFieldInputRef && this.props.autoFocus) {
      const value = get(this.TextFieldInputRef, 'props.value');

      this.TextFieldInputRef.focus();

      if (
        value !== null &&
        value !== undefined &&
        this.TextFieldInputRef.inputRef &&
        Object.isExtensible(this.TextFieldInputRef.inputRef.selectionStart)
      ) {
        this.TextFieldInputRef.inputRef.selectionStart = value.length;
        this.TextFieldInputRef.inputRef.selectionEnd = value.length;
      }
    }
  }

  render() {
    const { label, value, update, fieldName, validation } = this.props;

    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        value={value}
      >
        <Input
          placeholder={value}
          value={value}
          onChange={(e) => update(e.target.value)}
          id={`${fieldName}Id`}
          ref={(input) => {
            this.TextFieldInputRef = input;
          }}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

TextField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  update: PropTypes.func
};

export default TextField;
