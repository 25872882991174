import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import TimePicker from 'rc-time-picker';

import moment from 'moment';

import config from '../../constants';

import 'rc-time-picker/assets/index.css';

class TimePickerInput extends Component {
  componentDidMount() {
    const date = this.props.value;
    if (date && moment(date).isValid()) {
      setTimeout(() => {
        this.props.onChange(moment(date).format(config.DEFAULT_TIME_FORMAT));
      }, 0);
    }
  }

  onChange = (date) => {
    this.props.onChange(
      moment(date).isValid() ? date.format(config.DEFAULT_TIME_FORMAT) : date
    );
  };

  getSelectedValue = (value) => {
    if (value) {
      return moment(value, config.DEFAULT_TIME_FORMAT);
    }
  };

  render() {
    const { value, placeholder, required, label, subtext, id } = this.props;
    const subtextPosition = this.props.subtextPosition || 'bottom';
    const selectedValue = this.getSelectedValue(value);
    return (
      <Form.Field required={required}>
        {label && (
          <label className={`input-sub-${subtextPosition || 'bottom'}`}>
            {label}
          </label>
        )}
        {subtext && subtextPosition === 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
        <TimePicker
          id={id}
          autoComplete="off"
          value={selectedValue}
          placeholder={placeholder}
          showSecond={false}
          onChange={this.onChange}
        />

        {subtext && subtextPosition !== 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
      </Form.Field>
    );
  }
}

export default TimePickerInput;
