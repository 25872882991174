import React, { SyntheticEvent } from 'react';

export const eventCampaignDisplayValue = (x: any) =>
  x ? `${x.organization.name} - ${x.organization.url}` : '';

export const getDispalyInstruction = (types: any[], value: string) => {
  return types.find((x) => x.value === value)?.text || '';
};

export const generateLink = (campaign: any) => {
  return campaign ? (
    <a className="Links" target="_blank" href={campaign.urlFull}>
      {`${eventCampaignDisplayValue(campaign)} - ${campaign.id}`}
    </a>
  ) : (
    ''
  );
};

export const compareStrings = (value1: string, value2: string) => {
  return value1?.toLowerCase()?.trim() === value2?.toLowerCase()?.trim();
};

export const onDataFieldChange = (
  event:
    | React.ChangeEvent<HTMLInputElement>
    | SyntheticEvent<HTMLElement, Event>,
  data: any
) => {
  let newProperty = {};
  if (data) {
    newProperty = {
      [data.name]: data.value
    };
  } else if (event) {
    newProperty = {
      // @ts-ignore
      [event.target.name]: event.target.value
    };
  }

  return newProperty;
};

export const extractCampaign = (id: number, textContent: string) => {
  const dataSplit = textContent?.split(' - ');
  let organization = {};
  if (dataSplit && dataSplit.length >= 2) {
    organization = { name: dataSplit[0], url: dataSplit[1] };
  }
  return {
    id,
    organization
  };
};
