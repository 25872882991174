import React, { Component } from 'react';
import { Header as SemanticHeader } from 'semantic-ui-react';

class Header extends Component {
  render() {
    const { children, ...pprops } = this.props;
    return <SemanticHeader {...pprops}>{children}</SemanticHeader>;
  }
}

export default Header;
