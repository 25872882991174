import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import AnimateHeight from 'react-animate-height';
import { I18n } from 'react-redux-i18n';
import { Button, Modal, Checkbox, Form, Accordion } from 'semantic-ui-react';

import * as ticketingActions from '../../reducer';
import * as generalActions from '../../../../modules/general';

import { Record } from '../../../../modules/bin/utility';
import { Form as CommonForm } from '../../../../components/index';

import { refundSaleOptions } from '../../constants';
import { AppDispatch, RootState } from '../../../../store';
import { useAppDispatch } from '../../../../hooks';
import { CustomRefund } from './CustomRefund';
import { InstallmentRefund } from './InstallmentRefund';

interface IRefundOption {
  value: string;
  label: string;
  subtext: string;
  partialRefund?: boolean;
  disabled: (financials: any, donationType: any) => void;
}

interface IRefundOptionsProps {
  selectedOption: string;
  setSelectedOption: (value: string) => void;
  setIsRefundOptionValid: (value: boolean) => void;
  setOnRefundCallback: (func: () => void) => void;
}

const RefundOptions = ({
  selectedOption,
  setSelectedOption,
  setIsRefundOptionValid,
  setOnRefundCallback
}: IRefundOptionsProps) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  // prettier-ignore
  const { sale: { refundRecord} } = ticketing;
  const refundedAmount = refundRecord?.financials?.refundedAmount || 0;
  const salesAmount = refundRecord?.amount || 0;
  const maxValue = salesAmount - refundedAmount;
  const minValue = 1;
  const [activeIndex, setActiveIndex] = useState(-1);

  const installments =
    refundRecord?.financials?.installments?.payments.sort(
      (a: any, b: any) => a.paidAt - b.paidAt
    ) || [];

  return (
    <Accordion
      fluid
      activeIndex={activeIndex}
      panels={refundSaleOptions.map((option: IRefundOption, i) => ({
        key: i,
        title: (
          <Accordion.Title>
            <CommonForm.Checkbox
              inputLabel={I18n.t(option.label)}
              subtext={I18n.t(option.subtext)}
              disabled={option.disabled(
                refundRecord?.financials,
                refundRecord?.type
              )}
              value={option.value}
              checked={selectedOption === option.value}
              onChange={() => {
                setSelectedOption(option.value);
                const accordionIndex = option.partialRefund ? i : -1;
                // only allow input that allows for partial refunds
                setActiveIndex(accordionIndex);
                if (accordionIndex === -1) {
                  setIsRefundOptionValid(true);
                }
              }}
              radio
            />
          </Accordion.Title>
        ),
        content: (_: unknown, { key, active }: any) => (
          <div key={key} style={{ marginTop: -30, marginBottom: 30 }}>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={active ? 'auto' : 0}
            >
              {installments.length === 0 && (
                <CustomRefund
                  selectedOption={selectedOption}
                  maxValue={maxValue}
                  minValue={minValue}
                  setIsRefundOptionValid={setIsRefundOptionValid}
                  setOnRefundCallback={setOnRefundCallback}
                />
              )}
              {installments.length > 0 && (
                <InstallmentRefund
                  selectedOption={selectedOption}
                  installments={installments}
                  setIsRefundOptionValid={setIsRefundOptionValid}
                  setOnRefundCallback={setOnRefundCallback}
                />
              )}
            </AnimateHeight>
          </div>
        )
      }))}
    />
  );
};

const RefundSaleWindow = () => {
  const [selectedOption, setSelectedOption] = useState<string>('');
  const [confirmCheckbox, setConfirmCheckbox] = useState(false);
  const [isRefundOptionValid, setIsRefundOptionValid] = useState(false);
  const [onRefundCallback, setOnRefundCallback] = useState<() => void>();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const dispatch: AppDispatch = useAppDispatch();
  // prettier-ignore
  const { saveRecord, sale } = ticketing;
  const { isRefunded, refundRecord, refundConfirmation } = sale;
  const financials = refundRecord?.financials;
  const isRefunding = Record.isRecordLoading(saveRecord);

  const setInitialState = () => {
    setSelectedOption('');
    setConfirmCheckbox(false);
  };

  const onRefundConfirmClose = () => {
    dispatch(ticketingActions.closeRefundConfirmation('sale'));
    setInitialState();
  };

  if (isRefunded) {
    dispatch(generalActions.clearListsReadyState());
    dispatch(ticketingActions.clearIsRefunded('sale'));
    setInitialState();
  }

  return (
    <Modal
      className="wizard sale-refund-modal"
      size="small"
      dimmer="inverted"
      closeIcon
      open={!!refundConfirmation}
    >
      <Modal.Header>{I18n.t('ticket.modals.refund-sale.title')}</Modal.Header>
      <Modal.Content>
        <div className="sale-refund-modal-content">
          {I18n.t('ticket.modals.refund-sale.content', {
            receiptNumber: refundRecord?.receiptNumber,
            firstName: refundRecord?.customer?.firstName,
            lastName: refundRecord?.customer?.lastName
          })}
        </div>
        {financials && (
          <RefundOptions
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
            setIsRefundOptionValid={setIsRefundOptionValid}
            setOnRefundCallback={setOnRefundCallback}
          />
        )}
        <Form.Field className="sale-refund-modal-confirm">
          <Checkbox
            checked={confirmCheckbox}
            onChange={(_, { checked }) => setConfirmCheckbox(!!checked)}
            label={I18n.t('ticket.modals.refund-sale.confirm-checkbox.label')}
          />
        </Form.Field>
        <div className="safe-refund-modal-buttons">
          <Button
            primary
            disabled={
              !onRefundCallback ||
              !selectedOption ||
              !confirmCheckbox ||
              !isRefundOptionValid ||
              isRefunding
            }
            loading={isRefunding}
            onClick={() => onRefundCallback && onRefundCallback()}
          >
            {I18n.t('ticket.modals.refund-sale.buttons.confirm')}
          </Button>
          <Button onClick={onRefundConfirmClose}>
            {I18n.t('ticket.modals.refund-sale.buttons.decline')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};

export default RefundSaleWindow;
