//---------------React/Redux
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { findDOMNode } from 'react-dom';

//---------------Components
import AboutElementRenderContainer from './aboutElementRenderContainer';

const Types = {
  COMPONENT: 'aboutElement'
};

const componentSource = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index
    };
  },
  canDrag(props) {
    return props.disabled ? false : true;
  }
};

const componentTarget = {
  hover(props, monitor, component) {
    const dragIndex = monitor.getItem().index;
    const hoverIndex = props.index;

    // Don't replace items with themselves
    if (dragIndex === hoverIndex) {
      return;
    }

    // Determine rectangle on screen
    const hoverBoundingRect = findDOMNode(component).getBoundingClientRect();

    // Get vertical middle
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

    // Determine mouse position
    const clientOffset = monitor.getClientOffset();

    // Get pixels to the top
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    // Only perform the move when the mouse has crossed half of the items height
    // When dragging downwards, only move when the cursor is below 50%
    // When dragging upwards, only move when the cursor is above 50%
    // Dragging downwards
    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }

    // Dragging upwards
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    // Time to actually perform the action
    props.moveAboutElement(dragIndex, hoverIndex);

    // Note: we're mutating the monitor item here!
    // Generally it's better to avoid mutations,
    // but it's good here for the sake of performance
    // to avoid expensive index searches.
    monitor.getItem().index = hoverIndex;
  }
};

function collectTarget(connectTarget) {
  return {
    connectDropTarget: connectTarget.dropTarget()
  };
}

function collectSource(connectSource, monitor) {
  return {
    connectDragSource: connectSource.dragSource(),
    isDragging: monitor.isDragging()
  };
}

class AboutElementContainer extends Component {
  render() {
    const { connectDropTarget, ...pprops } = this.props;
    return connectDropTarget(
      <div>
        <AboutElementRenderContainer {...pprops} />
      </div>
    );
  }
}

export default connect(
  null,
  null
)(
  DropTarget(
    Types.COMPONENT,
    componentTarget,
    collectTarget
  )(
    DragSource(
      Types.COMPONENT,
      componentSource,
      collectSource
    )(AboutElementContainer)
  )
);
