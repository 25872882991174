import { generalFormBuilderPath } from '../../../../constants/formBuilder';

export const paths = {
  EVENT: 'event',
  EVENT_AMBASSADOR_PROGRAM: 'eventAmbassadorProgram',
  EVENT_AMBASSADOR_PROGRAM_ID: 'eventAmbassadorProgramId',
  ORGANIZATION_ID: 'organizationId',
  VIP_PLACES_ALLOCATED: 'availableSeats',
  NEW_VIP_PLACES_ALLOCATED: 'newAvailableSeats',
  BANNER: 'mainImagePath',
  INTRO_TEXT: 'description',
  APPLICATION_ADDITIONAL: `${generalFormBuilderPath}.additional`,
  EDIT_PROGRAM_HEADER: 'edit-program-header',
  EDIT_PROGRAM_SUBHEADER: 'edit-program-subheader',
  EDIT_PROGRAM_APPLICATION_QUESTIONS_TAB: 'edit-program-applicationQuestions'
};
