//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

//---------------Components
import { Form, Input, Icon } from 'semantic-ui-react';

//---------------Constants
import { waiverPaths } from '../constants/paths';
import { i18WaiverKeys } from '../constants/translation';

class WaiverItem extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };
  }

  render() {
    const { index, title, link, onChange } = this.props;
    const i18Key =
      'template.page.content.termsAndConditions.waivers.available-waivers.waiver';

    return (
      <div>
        <Form className="collection-item waiver-item">
          <Form.Field className="item-title-container">
            <label className="item-title">
              {I18n.t(`${i18Key}.${i18WaiverKeys.label}`, {
                id: `${index + 1}`
              })}
              <Icon
                name="remove circle"
                className="right-float"
                size="large"
                style={{ cursor: 'pointer' }}
                onClick={() => this.props.onDeleteClick(index)}
              />
            </label>
          </Form.Field>
          <div className="waiver-item-content">
            <Form.Field>
              <label>{I18n.t(`${i18Key}.title.${i18WaiverKeys.label}`)}</label>
              <div className="waiver-subtext">
                {I18n.t(`${i18Key}.title.${i18WaiverKeys.subtext}`)}
              </div>
              <Input
                id={`waiver-name-${index}`}
                fluid
                value={title}
                onChange={(event, { value }) => {
                  onChange(index, waiverPaths.title, value);
                }}
              />
            </Form.Field>
            <Form.Field required={this.required}>
              <label>
                {I18n.t(`${i18Key}.information.${i18WaiverKeys.label}`)}
              </label>
              <div className="waiver-subtext">
                {I18n.t(`${i18Key}.information.${i18WaiverKeys.subtext}`)}
              </div>
              <Input
                id={`waiver-name-${index}`}
                fluid
                value={link}
                onChange={(event, { value }) => {
                  onChange(index, waiverPaths.link, value);
                }}
              />
            </Form.Field>
          </div>
        </Form>
      </div>
    );
  }
}

export default WaiverItem;
