import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment';
import {
  Form,
  Checkbox,
  Dropdown,
  Popup,
  DropdownProps
} from 'semantic-ui-react';

import * as ticketingActions from '../../reducer';
import config from '../../../../constants';
import { refundSaleValues } from '../../constants';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../../../hooks';
import { RootState, AppDispatch } from '../../../../store';
import { grecaptcha } from '../../../../helpers/grecaptcha';

interface IInstallmentRefundProps {
  selectedOption: string;
  installments: any[];
  setIsRefundOptionValid: (value: boolean) => void;
  setOnRefundCallback: (func: () => void) => void;
}

const getInstallmentText = (item: any, index: number) =>
  `Payment ${index + 1}: $${item.amount.toFixed(2)}`;

const getInstallmentContent = (item: any, index: number) => {
  const content = getInstallmentText(item, index);

  if (item.refunded) {
    return (
      <b>
        {content}
        {item.refunded && ' (refunded)'}
      </b>
    );
  }

  return content;
};

export const InstallmentRefund = ({
  selectedOption,
  installments,
  setIsRefundOptionValid,
  setOnRefundCallback
}: IInstallmentRefundProps) => {
  const availableInstallments = installments.filter((x: any) => !x.refunded);
  const [installmentId, setInstallmentId] = useState<number | string>('');
  const [refundBalance, setRefundBalance] = useState(true);
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const dispatch: AppDispatch = useAppDispatch();
  // prettier-ignore
  const { sale: { refundRecord} } = ticketing;
  // prettier-ignore
  const salesAmount = availableInstallments?.reduce((sum, item) => sum + item.amount, 0);

  // prettier-ignore
  const onRefundClick = useCallback(() => {
    return () => {
      const installmentIds = refundBalance ? availableInstallments.map((x) => x.id) : [installmentId];
      if (selectedOption === refundSaleValues.CANCELLED_AND_REFUNDED) {
        dispatch(ticketingActions.cancelAndRefundInstallment(refundRecord.id, installmentIds));
      }
      else if (selectedOption === refundSaleValues.CANCELLED) {
        const tokenCallback = (token: string) =>
          dispatch(ticketingActions.cancelInstallment(refundRecord.id, token));
        grecaptcha(tokenCallback, "SaleInstallment");
      }
      else if (selectedOption === refundSaleValues.REFUNDED) {
        dispatch(ticketingActions.refundInstallment(refundRecord.id, installmentIds));
      }
    }
  }, [selectedOption, refundBalance]);

  useEffect(() => {
    if (availableInstallments?.length > 0)
      setInstallmentId(availableInstallments[0].id);
  }, [installments]);

  useEffect(() => {
    setOnRefundCallback(onRefundClick);
  }, [selectedOption, refundBalance]);

  useEffect(() => {
    setIsRefundOptionValid(true);
  }, []);

  const onInstallmentClick = (
    _: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => {
    const isAvailable = availableInstallments.find(
      (x: any) => x.id === data.value
    );
    if (isAvailable) {
      setInstallmentId(Number(data.value));
      setRefundBalance(false);
    }
  };

  return (
    <Form.Group className="donation-amounts refund-installment-container">
      <Form.Field>
        <Checkbox
          checked={refundBalance}
          onChange={(_, data) => setRefundBalance(!!data.checked)}
          radio
          label={`Refund balance $${salesAmount.toFixed(2)}`}
        />
      </Form.Field>
      <Form.Field className="refund-installment-dropdown">
        <Checkbox
          radio
          checked={!refundBalance}
          onChange={(_, data) => setRefundBalance(!data.checked)}
          label={'Refund Installments'}
        />
        <Dropdown
          value={installmentId}
          options={installments.map((x: any, i: number) => ({
            text: getInstallmentText(x, i),
            value: x.id,
            content: getInstallmentContent(x, i)
          }))}
          onChange={onInstallmentClick}
          selection
          icon="chevron down"
        />
      </Form.Field>
    </Form.Group>
  );
};
