import React from 'react';
import { connect } from 'react-redux';
import {
  Header,
  Form,
  Container,
  Grid,
  Input,
  Message
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import ValidatedInput from '../common/validatedInput';
import { authenticate, handleValueChange } from '../../modules/session';
import config from '../../constants';
import { hasTextEntryInResources } from '../template/templateHelper';
import './authentication.css';
import { grecaptcha } from '../../helpers/grecaptcha';

class LoginForm extends React.Component {
  onAuthenticate() {
    const { email, password, remember } = this.props.session.loginForm;
    const tokenCallback = (token) =>
      this.props.authenticate(email, password, remember, token);
    grecaptcha(tokenCallback, 'login');
  }

  onChange(key, e) {
    this.props.handleValueChange(`loginForm.${key}`, e.target.value);
  }

  onChangeCheckbox(key, e, target) {
    this.props.handleValueChange(`loginForm.${key}`, target.checked);
  }

  isError = () => {
    const session = this.props.session;
    return session.error && session.errorList && session.errorList.length > 0;
  };

  getErrorTranslation = () => {
    const errorList = get(this.props, 'session.errorList');
    if (errorList.length > 1) {
      return errorList.map((item, index) => {
        const currentErrorKey = get(
          this.props,
          `session.errorList.${index}.error`
        );
        const fullKey = `errors.api.${currentErrorKey}`;
        if (hasTextEntryInResources(fullKey)) {
          return I18n.t(fullKey);
        }

        return I18n.t('errors.api.general', {
          errorKey: currentErrorKey,
          fullKey: I18n.t(fullKey)
        });
      });
    }

    const errorKey = get(this.props, 'session.errorList.0.error');
    const fullKey = `errors.api.${errorKey}`;

    if (hasTextEntryInResources(fullKey)) {
      return [I18n.t(fullKey)];
    }

    const generalAuthErrorMessageKey =
      'errors.action.session.AUTHENTICATE_FAILURE';
    return [I18n.t(generalAuthErrorMessageKey)];
  };

  render() {
    const { email, password } = this.props.session.loginForm;

    return (
      <div>
        <div>
          <Header as="h2" textAlign="center">
            {this.props.header}
          </Header>
          <Form onSubmit={() => this.onAuthenticate()}>
            <Container className="login-container">
              <ValidatedInput
                placeholder="name@emailaddress.com"
                name="email"
                value={email}
                onChange={(e) => this.onChange('email', e)}
                label="Email"
              />
            </Container>
            <Form.Field>
              <label>Password</label>
              <Input
                placeholder="Enter your password"
                name="password"
                autoFocus
                type="password"
                autoComplete="off"
                value={password}
                onChange={(e) => this.onChange('password', e)}
              />
            </Form.Field>
            <Grid columns={2} stackable>
              <Grid.Column textAlign="left">
                <Form.Checkbox
                  label="Remember me"
                  onChange={(e, target) =>
                    this.onChangeCheckbox('remember', e, target)
                  }
                />
              </Grid.Column>
              <Grid.Column textAlign="right" id="forgot-password">
                <Link to="/forgot">Forgot password?</Link>
              </Grid.Column>
            </Grid>
            <Form.Button primary fluid className="login-submit login-button">
              Log in
            </Form.Button>
          </Form>
          <Container textAlign="center" className="login-container">
            <p>
              <strong>Don’t have an account?</strong>{' '}
              <a href={config.contactUsUrl}>Contact us.</a>
            </p>
          </Container>
          {this.isError() && (
            <Message error list={this.getErrorTranslation()} />
          )}
        </div>
        <hr className="authentication-divider" />
        <Container textAlign="center">
          <p>
            <strong>Are you a fundraiser?</strong>
            <a href={config.fundraiserLoginUrl}> Log in here</a>
          </p>
        </Container>
      </div>
    );
  }
}

const mapState = ({ session, authentication }) => {
  return {
    session: session,
    updatePasswordRecord: authentication.updatePasswordRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    authenticate(email, password, remember, verificationToken = null) {
      dispatch(authenticate(email, password, remember, verificationToken));
    },
    handleValueChange(path, value) {
      dispatch(handleValueChange(path, value));
    }
  };
};

const LoginFormContainer = connect(mapState, mapDispatch)(LoginForm);
export default LoginFormContainer;
