import React, { Component } from 'react';
import { connect } from 'react-redux';

// LIBS
import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

// REDUCERS
import { disableSecurity, disableSecurityModalChange } from '../reducer';

// COMPONENTS
import VerificationModalWrapper from './verificationModalWrapper';

// CONSTANTS
import { verificationVariant } from '../../../../constants/multiFactorAuth';
import { grecaptcha } from '../../../../helpers/grecaptcha';

class DisableSecurityModal extends Component {
  handleDisableButtonClick = (securityVariant) => {
    const { authCode, recoveryCode } = this.props.accountVerification;
    const verificationCode =
      securityVariant === verificationVariant.authCode
        ? authCode
        : recoveryCode;

    const tokenCallback = (token) =>
      this.props.disableSecurity(
        this.props.accountId,
        token,
        verificationCode,
        securityVariant
      );
    grecaptcha(tokenCallback, 'AccountMultiFactor');
  };

  handleDisableModalChange = (open) => {
    this.props.disableSecurityModalChange(open);
  };

  render() {
    return (
      <VerificationModalWrapper
        verificationTitle={
          'account.account-security.disableModal.disable.title'
        }
        verificationContent={
          'account.account-security.disableModal.disable.content'
        }
        verificationButton={
          'account.account-security.disableModal.disable.button'
        }
        verificationError={I18n.t('security.disable-tab-error-message')}
        verificationRecord={this.props.disableSecurityRecord}
        verificationModalChange={this.handleDisableModalChange}
        handleVerificationClick={this.handleDisableButtonClick}
      />
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  const accountId = get(session, 'account.id');

  return {
    accountId: accountId,
    accountVerification: accountSettings.accountVerification,
    disableSecurityRecord: accountSettings.disableSecurityRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    disableSecurityModalChange: (open) => {
      dispatch(disableSecurityModalChange(open));
    },
    disableSecurity: (
      accountId,
      verificationToken,
      verificationCode,
      verificationMethod
    ) => {
      dispatch(
        disableSecurity(
          accountId,
          verificationToken,
          verificationCode,
          verificationMethod
        )
      );
    }
  };
};

export default connect(mapState, mapDispatch)(DisableSecurityModal);
