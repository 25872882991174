import { currencyLocaleMap } from "../constants/currencyLocaleMap";

export default class CurrencyHelper {
  static formatCurrency = (value: number, code: string = "AUD") => {
    const hasDecimals = value % 1 !== 0;
    return new Intl.NumberFormat(
      currencyLocaleMap[code],
      {
        style: "currency",
        currency: code,
        minimumFractionDigits: hasDecimals ? 2 : 0,
        maximumFractionDigits: 2,
      }
    ).format(value);
  }

  static roundToDecimal = (value: number, decimalPlaces: number) => {
    return Math.round(value * Math.pow(10, decimalPlaces)) / Math.pow(10, decimalPlaces);
  }
}
