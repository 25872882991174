import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { pageActions } from '../reducer';
import EditPageContainer from './edit-page';
import { pageTypes } from '../../../../constants/pageTypes';
import '../../../../components/common/grid/grid.css';

const EditFundraiserPage = (props) => {
  return (
    <EditPageContainer
      getRecord={props.pageActions.getFundraiserRecord}
      updateRecord={props.pageActions.updateFundraiserRecord}
      updateStatus={props.pageActions.updateFundraiserStatus}
      template={props.template}
      type={pageTypes.FUNDRAISER}
    />
  );
};

const mapState = ({ campaignPage }) => {
  return {
    template: campaignPage.fundraiserTemplate
  };
};

const mapDispatch = (dispatch) => {
  return {
    pageActions: bindActionCreators(pageActions, dispatch)
  };
};

const EditFundraiserPageContainer = connect(
  mapState,
  mapDispatch
)(EditFundraiserPage);
export default EditFundraiserPageContainer;
