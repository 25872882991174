import React from 'react';

import { getFromBuilderInitialData } from '../../../../helpers/formBuilderHelper';

import { TemplatePage } from '../../../../components/common/pager';
import TemplateFormBuilder from '../../../../components/template/templateFromBuilder';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateText } from '../../../../components/template/templateText';

import { formBuilderKeys } from '../../../campaign/constants';
import { paths } from '../constants/template';

export const getApplicationQuestionsTab = (props) => {
  const {
    onChange,
    formBuilderQuestionsValidation,
    isAddressHidden,
    isPhoneHidden,
    isCountryAllowed
  } = props;

  return (
    <TemplatePage
      id="applicationQuestions"
      i18nKey="programs.template.page.applicationQuestions.title"
    >
      <TemplateHeader
        as="h2"
        label="programs.template.page.applicationQuestions.part-one-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.applicationQuestions.part-one-section.subheader"
      />

      <TemplateFormBuilder
        formBuilderKey={formBuilderKeys.ENTRY}
        path={paths.APPLICATION_ENTRY}
        initialData={getFromBuilderInitialData(formBuilderKeys.ENTRY)}
        onChange={onChange}
        validation={formBuilderQuestionsValidation}
        isAddressHidden={isAddressHidden}
        isPhoneHidden={isPhoneHidden}
        isCountryAllowed={isCountryAllowed}
      />

      <TemplateHeader
        as="h2"
        label="programs.template.page.applicationQuestions.part-two-section.header"
      />

      <TemplateText
        size="small"
        label="programs.template.page.applicationQuestions.part-two-section.subheader"
      />

      <TemplateFormBuilder
        formBuilderKey={formBuilderKeys.CONFIRMATION}
        path={paths.APPLICATION_CONFIRMATION}
        isPhoneHidden={isPhoneHidden}
        initialData={getFromBuilderInitialData(formBuilderKeys.CONFIRMATION)}
        onChange={onChange}
        validation={formBuilderQuestionsValidation}
        isCountryAllowed={isCountryAllowed}
      />
    </TemplatePage>
  );
};
