import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';

import * as templateBuilderActions from '../../modules/template-builder-ex';

import { Form } from 'semantic-ui-react';
import { TemplateHeader } from '../../components/template/templateHeader';
import { TemplateText } from '../../components/template/templateText';
import { TemplateInput } from '../../components/template/templateInput';
import { TemplateDropdown } from '../../components/template/templateDropdown';
import { TemplateCheckbox } from '../../components/template/templateCheckbox';
import TermsAndConditions from '../../components/common/termsAndConditions';

import { countries } from '../../constants/countries';
import { registerPaths, registerValidationModels } from './constants';

class RegisterTemplateView extends Component {
  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  getCountryOptions = () => {
    return Object.keys(countries).map((key) => {
      return { text: countries[key].label, value: key };
    });
  };

  handleCountryFocus = (evt) => {
    if (evt.target.getAttribute('autocomplete') === 'off') {
      evt.target.setAttribute('autocomplete', 'no');
    }
  };

  getTaxFieldTextDescriptor = () => {
    if (this.props.organizationCountry) {
      return countries[this.props.organizationCountry].taxDescriptor;
    }
    return countries.AU.taxDescriptor;
  };

  render() {
    return (
      <div className="template-content-wrapper register">
        <div className="template-content-inner">
          <Form>
            <div className="ui container">
              <div className="template-heading">
                <TemplateHeader as="h3" label="template.register.label" />
                <TemplateText label="template.register.subtext" size="small" />
              </div>

              <TemplateHeader
                as="h4"
                label="template.register.form.details.label"
              />

              <Form.Group widths="equal">
                <TemplateInput
                  path={registerPaths.firstName}
                  label="template.register.form.details.firstName"
                  validation={registerValidationModels.firstName}
                  onChange={this.onChange}
                />

                <TemplateInput
                  path={registerPaths.lastName}
                  label="template.register.form.details.lastName"
                  validation={registerValidationModels.lastName}
                  onChange={this.onChange}
                />
              </Form.Group>

              <TemplateInput
                path={registerPaths.email}
                label="template.register.form.details.email"
                validation={registerValidationModels.email}
                onChange={this.onChange}
              />

              <TemplateHeader
                as="h4"
                label="template.register.form.organization-details.label"
              />

              <TemplateInput
                path={registerPaths.organizationName}
                label="template.register.form.organization-details.name.label"
                subtext="template.register.form.organization-details.name.subtext"
                validation={registerValidationModels.organizationName}
                onChange={this.onChange}
              />

              <TemplateDropdown
                path={registerPaths.organizationCountry}
                search
                label="template.register.form.organization-details.country.label"
                inputLabel="template.register.form.organization-details.country.subtext"
                validation={registerValidationModels.organizationCountry}
                onChange={this.onChange}
                getOptions={this.getCountryOptions}
                onFocus={this.handleCountryFocus}
              />

              <TemplateInput
                path={registerPaths.organizationLegalName}
                label="template.register.form.organization-details.legalName.label"
                subtext="template.register.form.organization-details.legalName.subtext"
                validation={registerValidationModels.organizationLegalName}
                onChange={this.onChange}
              />

              <TemplateInput
                path={registerPaths.organizationTaxId}
                label={`template.register.form.organization-details.taxID.${this.getTaxFieldTextDescriptor()}.label`}
                subtext={`template.register.form.organization-details.taxID.${this.getTaxFieldTextDescriptor()}.subtext`}
                validation={registerValidationModels.organizationTaxId}
                onChange={this.onChange}
              />

              <TemplateCheckbox
                path={registerPaths.termsAndConditions}
                inputLabelComponent={{
                  children: <TermsAndConditions />
                }}
                onChange={this.onChange}
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}

const mapState = (state) => {
  return {
    organizationCountry: get(state.templateBuilderEx, 'data.country')
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

const RegisterTemplate = connect(mapState, mapDispatch)(RegisterTemplateView);
export default RegisterTemplate;
