import './app.css';
import React, { Component } from 'react';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import SecureRoute from '../secureRoute';
import {
  Layouts,
  Authentication,
  Components,
  FormBuilder
} from '../../components';
import * as sessionActions from '../../modules/session';
import AdminsListContainer from '../../pages/accounts';
import AccountDetailPageContainer from '../../pages/accounts/detail';
import AccountSettingsPageContainer from '../../pages/accounts/settings';
import TapNdonateContainer from '../../pages/tapNdonate/TapNdonate';
import CampaignPageContainer from '../../pages/campaign';
import CreateCampaignContainer from '../../pages/campaign/create';
import EditCampaignContainer from '../../pages/campaign/edit';
import ViewCampaignContainer from '../../pages/campaign/view';
import EditTeamPageContainer from '../../pages/campaign/page/edit/edit-team';
import EditFundraiserPageContainer from '../../pages/campaign/page/edit/edit-fundraiser';
import CreatePageContainer from '../../pages/campaign/page/create';
import OrganizationPageContainer from '../../pages/organization';
import CreateOrganizationContainer from '../../pages/organization/create';
import EditOrganizationContainer from '../../pages/organization/edit';
import CreateOfflineDonationContainer from '../../pages/donation/create';
import StripeConnector from '../../pages/stripe';
import SalesforceConnection from '../../pages/salesforce/salesforce-connection';
import Invite from '../../components/authentication/invite';
import DonationsListContainer from '../../pages/donation';
import DonationViewPage from '../../pages/donation/view';
import FundraiserViewContainer from '../../pages/campaign/view/fundraiser-view';
import TeamViewContainer from '../../pages/campaign/view/team-view';
import EditDonationContainer from '../../pages/donation/edit';
import EventPageContainer from '../../pages/event';
import ViewEventContainer from '../../pages/event/view';
import CreateEventContainer from '../../pages/event/create';
import EditEventContainer from '../../pages/event/edit';
import CloneEventContainer from '../../pages/event/clone';
import EditTicketingContainer from '../../pages/ticketing/edit';
import ViewTicketingContainer from '../../pages/ticketing/view';
import CreateTicketingContainer from '../../pages/ticketing/create';
import MerchandisePageContainer from '../../pages/merchandise';
import CreateMerchandiseContainer from '../../pages/merchandise/create';
import EditMerchandiseContainer from '../../pages/merchandise/edit';
import EditCollectionPageContainer from '../../pages/campaign/page/edit/edit-collection';
import CollectionViewContainer from '../../pages/campaign/view/collection-view';
import RecurringPageContainer from '../../pages/recurring';
import CreateRecurringDonationContainer from '../../pages/recurring/create';
import EditRecurringDonationContainer from '../../pages/recurring/edit';
import RecurringDonationViewPageContainer from '../../pages/recurring/view';
import CloneCampaignContainer from '../../pages/campaign/clone';
import SalesListContainer from '../../pages/ticketing';
import FundraiserPageContainer from '../../pages/fundraisers';
import DashboardContainer from '../../pages/dashboard/index';
import DashboardCompareContainer from '../../pages/dashboard/compare/index';
import RegisterContainer from '../../pages/register';
import GeneralRedirect from '../redirect/general';
import config from '../../constants/index';
import ping from '../../components/ping';
import InjectReCaptcha from '../../components/common/injectReCaptcha';
import PrivateRoute from './privateRoute/privateRoute';
import { userRolesStrings } from '../../constants/roles';
import EventAmbassadorViewPageContainer from '../../pages/vipProgram/view/event-view';
import CampaignAmbassadorViewPageContainer from '../../pages/vipProgram/view/campaign-view';
import ProgramViewPageContainer from '../../pages/vipProgram/view';
import CreateVipProgramContainer from '../../pages/vipProgram/create';
import EditVipProgramContainer from '../../pages/vipProgram/edit';
import CreateProgramContainer from '../../pages/vipProgram/program/create';
import EditProgramContainer from '../../pages/vipProgram/program/edit';
import EditApplicationContainer from '../../pages/vipProgram/application/edit';
import TransferDonationContainer from '../../pages/donation/transferDonation';
import Webhooks from '../../pages/webhooks';
import CreateWebhook from '../../pages/webhooks/create';
import EditWebhook from '../../pages/webhooks/edit';
import { ActiveIntegrationContainer } from '../../pages/event/activeIntegration';
import { grecaptcha } from '../../helpers/grecaptcha';
import { ExportHistoryDashboard } from '../../pages/export-history';

interface AppProps {
  isSystemAdmin: boolean;
  isOrganizationAdmin: boolean;
  isEventAdmin: boolean;
  isCampaignAdmin: boolean;
  isEventOrganizerAdmin: boolean;
  resumeSession: (token?: any) => void;
}

export interface RouteParams {
  menu: string;
  id: string;
  resetKey: string;
  inviteKey: string;
  tab: string;
  operation: string;
  step: string;
  eventId: string;
  template1: string;
  template2: string;
  view: string;
  campaignId: string;
  pageType: string;
  saleId: string;
}

class App extends Component<AppProps> {
  componentDidUpdate() {
    window.scroll(0, 0);
  }

  componentDidMount() {
    grecaptcha(this.props.resumeSession, 'login');
  }

  getUserRoles = () => {
    const userRoles = [];
    const {
      isSystemAdmin,
      isOrganizationAdmin,
      isEventAdmin,
      isCampaignAdmin,
      isEventOrganizerAdmin
    } = this.props;
    if (isSystemAdmin) {
      userRoles.push('systemAdmin');
    }
    if (isOrganizationAdmin) {
      userRoles.push('organizationAdmin');
    }
    if (isEventAdmin) {
      userRoles.push('eventAdmin');
    }
    if (isCampaignAdmin) {
      userRoles.push('campaignAdmin');
    }
    if (isEventOrganizerAdmin) {
      userRoles.push('eventOrganizerAdmin');
    }

    return userRoles;
  };

  render() {
    const { isSystemAdmin } = this.props;
    const userRoles = this.getUserRoles();

    return (
      <div>
        <Switch>
          <Route path="/components" component={Components} />
          <Route path="/formbuilder" component={FormBuilder} />
          <Route path="/login" component={Authentication.Login} />
          <Route path="/forgot" component={Authentication.ForgotPassword} />
          <Route
            path="/reset/:resetKey"
            component={Authentication.ResetPassword}
          />
          <Route path="/invite/:inviteKey" component={Invite} />
          <Route path="/welcome" component={RegisterContainer} />
          <SecureRoute>
            <Layouts.General>
              <Switch>
                <Route path="/stripe/:operation" component={StripeConnector} />
                <Route
                  path="/salesforce/connect"
                  component={SalesforceConnection}
                />
                <PrivateRoute
                  exact
                  path="/tap-n-donate/:tab"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={TapNdonateContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={CampaignPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={ProgramViewPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/event/:eventId/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={EventAmbassadorViewPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/campaign/:campaignId/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={CampaignAmbassadorViewPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/create/:step?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={CreateVipProgramContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/edit/:eventId/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={EditVipProgramContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/:eventId/add-organization/:step?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={CreateProgramContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/campaign/edit/:campaignId/:step?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={EditProgramContainer}
                />
                <PrivateRoute
                  exact
                  path="/vip-program/vip/:id/:step?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={EditApplicationContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/create/:template1/:template2?/step/:step?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={CreateCampaignContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={EditCampaignContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/clone/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={CloneCampaignContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/view/:view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={ViewCampaignContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/:campaignId/fundraiser/view/:view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={FundraiserViewContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/:campaignId/team/view/:view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={TeamViewContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/:campaignId/collection/view/:view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={CollectionViewContainer}
                />
                <PrivateRoute
                  exact
                  path="/campaigns/:id/page/create/:teamId?/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={CreatePageContainer}
                />
                {/* below is usually used for campaign but can be used dynamically eslewhere */}
                <PrivateRoute
                  exact
                  path="/:menu/collection/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={EditCollectionPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/:menu/team/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={EditTeamPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/:menu/fundraiser/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={EditFundraiserPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/organizations"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={OrganizationPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/organizations/create/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={CreateOrganizationContainer}
                />
                <PrivateRoute
                  exact
                  path="/organizations/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={EditOrganizationContainer}
                />
                <PrivateRoute
                  exact
                  path="/organization/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={EditOrganizationContainer}
                />
                <PrivateRoute
                  exact
                  path="/accounts/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={AdminsListContainer}
                />
                <PrivateRoute
                  exact
                  path="/accounts/detail/:id/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={AccountDetailPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/accounts/settings/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={AccountSettingsPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/export-history"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={ExportHistoryDashboard}
                />
                <PrivateRoute
                  exact
                  path="/webhooks"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={Webhooks}
                />
                <PrivateRoute
                  exact
                  path="/webhooks/setup"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={CreateWebhook}
                />
                <PrivateRoute
                  exact
                  path="/webhooks/edit/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={EditWebhook}
                />
                <PrivateRoute
                  exact
                  path="/donations"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={DonationsListContainer}
                />
                <PrivateRoute
                  exact
                  path="/donation/:id/transfer/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={TransferDonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/donations/create/:campaignId?/:pageType?/:id?/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={CreateOfflineDonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/donations/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin
                  ]}
                  component={EditDonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/donations/view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={DonationViewPage}
                />
                <PrivateRoute
                  exact
                  path="/events/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={EventPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/events/view/:view/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={ViewEventContainer}
                />
                <PrivateRoute
                  exact
                  path="/events/create/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={CreateEventContainer}
                />
                <PrivateRoute
                  exact
                  path="/events/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={EditEventContainer}
                />
                <PrivateRoute
                  exact
                  path="/events/clone/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={CloneEventContainer}
                />
                <PrivateRoute
                  exact
                  path="/events/active-integration/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={ActiveIntegrationContainer}
                />
                <PrivateRoute
                  exact
                  path="/sales/:tab?"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin
                  ]}
                  component={SalesListContainer}
                />
                <PrivateRoute
                  exact
                  path="/fundraisers/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventAdmin
                  ]}
                  component={FundraiserPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/sales/view/:saleId/"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin
                  ]}
                  component={ViewTicketingContainer}
                />
                <PrivateRoute
                  exact
                  path="/sales/edit/:saleId/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin
                  ]}
                  component={EditTicketingContainer}
                />
                <PrivateRoute
                  exact
                  path="/sales/create/:campaignId?/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.campaignAdmin
                  ]}
                  component={CreateTicketingContainer}
                />
                <PrivateRoute
                  exact
                  path="/merchandise"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={MerchandisePageContainer}
                />
                <PrivateRoute
                  exact
                  path="/merchandise/create/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={CreateMerchandiseContainer}
                />
                <PrivateRoute
                  exact
                  path="/merchandise/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={EditMerchandiseContainer}
                />
                <PrivateRoute
                  exact
                  path="/recurring/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={RecurringPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/recurring/create/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={CreateRecurringDonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/recurring/edit/:id/:step"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={EditRecurringDonationContainer}
                />
                <PrivateRoute
                  exact
                  path="/recurring/detail/:id/:tab"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin
                  ]}
                  component={RecurringDonationViewPageContainer}
                />
                <PrivateRoute
                  exact
                  path="/:view/dashboard/compare"
                  userRoles={userRoles}
                  allowedRoles={[userRolesStrings.systemAdmin]}
                  component={DashboardCompareContainer}
                />
                <PrivateRoute
                  exact
                  path="/:view/dashboard/:id"
                  userRoles={userRoles}
                  allowedRoles={[
                    userRolesStrings.systemAdmin,
                    userRolesStrings.organizationAdmin,
                    userRolesStrings.eventAdmin,
                    userRolesStrings.campaignAdmin,
                    userRolesStrings.eventOrganizerAdmin
                  ]}
                  component={DashboardContainer}
                />
                <Route exact path="/" component={GeneralRedirect} />
                <Route
                  exact
                  path="/campaigns"
                  component={() => (
                    <Redirect to={config.DEFAULT_CAMPAIGNS_URL} />
                  )}
                />
                <Route
                  exact
                  path="/fundraisers"
                  component={() => (
                    <Redirect to={config.DEFAULT_FUNDRAISERS_URL} />
                  )}
                />
                <Route
                  exact
                  path="/events"
                  component={() => <Redirect to={config.DEFAULT_EVENTS_URL} />}
                />
                <Route
                  exact
                  path="/tap-n-donate"
                  component={() => (
                    <Redirect to={config.DEFAULT_TAP_N_DONATE_URL} />
                  )}
                />
                <Route
                  exact
                  path="/recurring"
                  component={() => (
                    <Redirect to={config.DEFAULT_RECURRING_URL} />
                  )}
                />
                <Route
                  exact
                  path="/accounts"
                  component={() => (
                    <Redirect
                      to={
                        isSystemAdmin
                          ? config.DEFAULT_SYS_ADMIN_ACCOUNTS_URL
                          : config.DEFAULT_ACCOUNTS_URL
                      }
                    />
                  )}
                />
              </Switch>
            </Layouts.General>
          </SecureRoute>
        </Switch>
      </div>
    );
  }
}

const mapState = ({ session }: any) => {
  return {
    isSystemAdmin: session.isSystemAdmin,
    isOrganizationAdmin: session.isOrganizationAdmin,
    isEventAdmin: session.isEventAdmin,
    isCampaignAdmin: session.isCampaignAdmin,
    isEventOrganizerAdmin: session.isEventOrganizerAdmin
  };
};

const mapDispatch = (dispatch: any) => {
  // @ts-ignore
  return bindActionCreators(sessionActions, dispatch);
};

// slowly convert this from class component to functional componenet
export default InjectReCaptcha(
  ping(withRouter(connect(mapState, mapDispatch)(App)))
);
