if (!ReadableStream.prototype[Symbol.asyncIterator]) {
  console.log("polyfilled");
  ReadableStream.prototype[Symbol.asyncIterator] = async function* () {
    const reader = this.getReader();
    try {
      while (true) {
        const {done, value} = await reader.read();
        if (done) return;
        yield value;
      }
    }
    finally {
      reader.releaseLock();
    }
  };
}
