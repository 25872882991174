import React, { ReactNode } from 'react';

interface IViewTableProps {
  title: string | ReactNode;
  children: ReactNode;
}

export const ViewTable = ({ title, children }: IViewTableProps) => {
  return (
    <div className="view-table">
      <div className="view-table-title">{title}</div>
      <div className="view-table-content">{children}</div>
    </div>
  );
};
