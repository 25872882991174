import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import { Form } from '..';

class ManagedInputView extends Component {
  render() {
    const label = templateHelper.getTranslation(this.props.label);
    const subtext = templateHelper.getTranslation(this.props.subtext);

    const { validation, value } = this.props;

    return (
      <Form.InputView
        label={label}
        subtext={subtext}
        value={value}
        required={validation && validation.required}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export const TemplateInputView = connect(mapState)(ManagedInputView);
