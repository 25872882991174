import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import PropTypes from 'prop-types';

import withDnDContext from '../HTML5BackendSingleton';
import { createDefaultForm } from '../formBuilderHelpers';
import {
  addNewForm as addNewFormCreator,
  addChildNode as addChildNodeCreator
} from '../actionCreators';
import { mapToFormBuilder } from '../formMappers';
import SectionComponent from './Section';

/**
 * The structure of this node module is the following:
 * A FormBuilder has multiple Sections ( renders one selected ).
 * A Section has multiple Components ( renders all of them ).
 * A Component has multiple Fields ( renders all of them ).
 * A Field can have a conditional Section ( renders if selected ).
 * This structure is mapped from the node tree of the redux store.
 * This component also:
 * Renders SectionModal to rearrange the Sections.
 * Renders Button to open SectionModal.
 * Renders Dropdown to select the current Section.
 */
export class FormBuilder extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * On mount FormBuilder adds the initialData describing the node tree to the redux store.
   * If initialData is not provided it creates a default form and adds that to the store.
   */
  componentWillMount = () => {
    const { formBuilderKey, addNewForm } = this.props;
    if (this.props.form) {
      // setCounter(findMaxKey(mapToFormBuilder(initialData)) + 1);
      // addNewForm(
      //   mapToFormBuilder(initialData),
      //   formBuilderKey,
      // );
    } else {
      addNewForm(createDefaultForm(formBuilderKey), formBuilderKey);
    }
  };

  /**
   * If the form is changed, then call the external callback function.
   * If the initialData is changed then update the store.
   */
  componentWillReceiveProps = (nextProps) => {
    const { formBuilderKey, initialData, form } = nextProps;
    if (this.props.form !== form && this.props.dataCallback) {
      this.props.dataCallback(form);
    }
    if (this.props.initialData !== initialData) {
      this.props.addNewForm(mapToFormBuilder(initialData), formBuilderKey);
    }
  };
  /**
   * This function returns the first Section of the root node, if it has one.
   * @return {?Object} the node which describes the first Section.
   */
  getSection = () => {
    const { form } = this.props;
    const { currentSection } = this.state;
    return (
      form &&
      (form.children.find(
        (i) => currentSection !== undefined && i.identifier === currentSection
      ) ||
        form.children[0])
    );
  };

  render = () => {
    const {
      form,
      disabled,
      isAddressHidden,
      isPhoneHidden,
      isCountryAllowed,
      formQuestionsMaxLength
    } = this.props;
    const section = this.getSection();
    if (!form) {
      return <div>Loading!</div>;
    }

    return (
      <div className="form-builder" id={this.props.formBuilderKey}>
        {form && section && (
          <SectionComponent
            sectionKey={this.props.formBuilderKey}
            disabled={disabled}
            isAddressHidden={isAddressHidden}
            isPhoneHidden={isPhoneHidden}
            isCountryAllowed={isCountryAllowed}
            formQuestionsMaxLength={formQuestionsMaxLength}
            key={section.identifier}
            node={section}
          />
        )}
        {this.props.subtext && (
          <div className="input-sub">{this.props.subtext}</div>
        )}
      </div>
    );
  };
}

FormBuilder.propTypes = {
  formBuilderKey: PropTypes.string,
  dataCallback: PropTypes.func,
  initialData: PropTypes.object,
  form: PropTypes.object,
  addNewForm: PropTypes.func,
  addChildNode: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: state.formBuilders[ownProps.formBuilderKey]
  };
};

const mapDispatchToProps = (dispatch) => ({
  addNewForm: bindActionCreators(addNewFormCreator, dispatch),
  addChildNode: bindActionCreators(addChildNodeCreator, dispatch)
});

const withoutContext = connect(mapStateToProps, mapDispatchToProps);
export default withoutContext(withDnDContext(FormBuilder));
export { withoutContext };
