import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';

// @ts-ignore
import { Form, Input, Checkbox, Icon, Grid } from 'semantic-ui-react';

import DonationImageUpload from '../../common/donationElements/donationImageUpload';
import { Form as CommonForm } from '../../';

import {
  entityTypes,
  imageFormats,
  imageTypes
} from '../../../constants/imageUpload';
import { i18DonationAmountKeys } from '../constants/translation';
import { donationAmountPaths } from '../constants/paths';
import { defaultAmountPlaceholders } from '../constants';

interface DefaultAmountItemProps {
  isDefault?: boolean;
  description?: string;
  amount?: any;
  index?: any;
  path?: string;
  onChange?: (v1?: any, v2?: any, v3?: any) => void;
  onDeleteClick?: (v?: any) => void;
  parser?: (s?: string) => any;
}

class DefaultAmountItem extends Component {
  props: DefaultAmountItemProps;

  constructor(props: DefaultAmountItemProps) {
    super(props);
    this.props = {
      ...props
    };
  }

  render() {
    const {
      isDefault,
      description,
      amount,
      onChange,
      index,
      onDeleteClick,
      parser = parseInt
    } = this.props;
    const validation = {
      required: true
    };
    const imagePath = `${this.props.path}.${index}.${donationAmountPaths.image}`;
    const i18nKey =
      'template.page.content.donation.default-donation-amounts.item';
    const placeholder = defaultAmountPlaceholders[index];

    return (
      <Form className={`collection-item donation-amounts-item`}>
        <Form.Field required={validation}>
          <label className="item-title">
            {I18n.t(`${i18nKey}.${i18DonationAmountKeys.label}`, {
              id: index + 1
            })}
            <Icon
              name="remove circle"
              className="right-float"
              size="large"
              style={{ cursor: 'pointer' }}
              onClick={() => onDeleteClick && onDeleteClick(index)}
            />
          </label>
        </Form.Field>

        <Grid>
          <Grid.Row columns={2}>
            <Grid.Column width={3} className="donation-amount-item-image">
              <DonationImageUpload
                id={`defaultAmount${index}Image`}
                path={imagePath}
                entity={entityTypes.CAMPAIGN}
                position="left"
                format={imageFormats}
                uploadType={imageTypes.LOGO}
                onSuccess={(value: any) => {
                  onChange && onChange(index, donationAmountPaths.image, value);
                }}
              />
            </Grid.Column>
            <Grid.Column width={13}>
              <Form.Group unstakable>
                <Form.Field width={6} required={validation}>
                  <label>
                    {I18n.t(`${i18nKey}.${i18DonationAmountKeys.amount}`)}
                  </label>
                  <CommonForm.NumberInput
                    id={`donation-amount-${index}`}
                    type="tel"
                    inputLabel="$"
                    style={{ margin: '0px' }}
                    value={amount}
                    placeholder={placeholder}
                    allowDecimal
                    onChange={(event: { target: { value: string } }) => {
                      onChange &&
                        onChange(
                          index,
                          donationAmountPaths.amount,
                          parser(event.target.value)
                        );
                    }}
                  />
                </Form.Field>
                <Form.Field width={10}>
                  <span>
                    <Checkbox
                      radio
                      checked={isDefault === true}
                      onChange={(_event: any, { checked }: any) => {
                        onChange &&
                          onChange(
                            index,
                            donationAmountPaths.isDefault,
                            checked
                          );
                      }}
                      label={I18n.t(
                        `${i18nKey}.${i18DonationAmountKeys.isDefault}`
                      )}
                    />
                  </span>
                </Form.Field>
              </Form.Group>
              <Form.Field>
                <label>
                  {I18n.t(`${i18nKey}.${i18DonationAmountKeys.description}`)}
                </label>
                <Input
                  fluid
                  value={description}
                  onChange={(_event: any, { value }: any) => {
                    onChange &&
                      onChange(index, donationAmountPaths.description, value);
                  }}
                />
              </Form.Field>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Form>
    );
  }
}

export default DefaultAmountItem;
