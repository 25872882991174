import config from '../../../constants';

export const accountSettingsValidationModels = {
  email: {
    email: true,
    required: true,
    maxLength: true,
    length: config.ACCOUNT_EMAIL_MAX_LENGTH
  },
  firstName: {
    required: true,
    maxLength: true,
    length: config.ACCOUNT_NAME_MAX_LENGTH
  },
  lastName: {
    required: true,
    maxLength: true,
    length: config.ACCOUNT_NAME_MAX_LENGTH
  }
};
