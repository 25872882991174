import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { TemplateDropdownTree } from '../../template/templateTree';
import { treeActions } from '../../../modules/tree';
import get from 'lodash.get';

class TreeLookupItem extends Component {
  handleValueChange = (item, path) => {
    this.props.onChange(path, get(item, 'id'));
  };

  getTreeData = () => {
    this.props.getTreeData(this.props.entity, this.props.url, this.props.path);
  };

  render() {
    return (
      <Form.Group grouped>
        <TemplateDropdownTree
          value={this.props.value}
          path={this.props.path}
          getData={this.getTreeData}
          onChange={this.handleValueChange}
        />
      </Form.Group>
    );
  }
}

const mapState = () => {
  return {};
};

const mapDispatch = (dispatch) => {
  return {
    getTreeData(entity, url, path) {
      dispatch(treeActions.getTreeRecord(entity, url, path));
    }
  };
};

export default connect(mapState, mapDispatch)(TreeLookupItem);
