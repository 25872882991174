import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import { Record as RecordConstants } from '../../../constants';
import * as campaignActions from '../store/campaignActions';
import { Common } from '../../../components';
import get from 'lodash.get';
import * as pageHelper from '../../../helpers/pageHelper';
import { Link } from 'react-router-dom';
import * as generalActions from '../../../modules/general';
import { pageActions } from '../page/reducer';
import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import clear from '../../../components/clear';
import ListsViewContainer from './listsview';
import { gridKeys } from '../../../constants/gridKeys';
import config from '../../../constants/index';

class CampaignView extends Component {
  constructor(props) {
    super(props);
    pageHelper.loadRecordIfRequired(props);

    const campaignFundraisersOrdering = get(
      props,
      'location.state.campaignFundraisersOrdering'
    );

    if (campaignFundraisersOrdering) {
      props.listOrderingChange(
        gridKeys.CAMPAIGN_FUNDRAISERS,
        campaignFundraisersOrdering
      );
    }
  }

  onCreatePage = () => {
    const paramId = get(this.props, 'match.params.id');
    this.props.history.push(`/campaigns/${paramId}/page/create/page-detail`);
  };

  onEditClick() {
    const paramId = get(this.props, 'match.params.id');
    this.props.history.push(`/campaigns/edit/${paramId}/setup`);
  }

  onVisitCampaignClick() {
    const url = get(this.props, 'record.data.data.urlFull');
    window.open(url, '_blank');
  }

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
  };

  render() {
    const campaignId = get(this.props, 'match.params.id');
    let recordData = null;
    const baseFilter = {
      key: 'campaignId',
      operator: 'EqualTo',
      value: campaignId
    };

    const hasLoadedRecord = this.props.record.ready;

    if (hasLoadedRecord === RecordConstants.RECORD_STATUS_READY) {
      recordData = this.props.record.data.data;
    }

    const headerData =
      hasLoadedRecord !== RecordConstants.RECORD_STATUS_READY
        ? { loaded: false, id: null, name: null }
        : {
            loaded: true,
            id: recordData.id,
            name: recordData.name,
            image: recordData.mainImagePath
          };

    const isFundraisersAvaialable = recordData
      ? recordData.teams.enabled || recordData.fundraisers.enabled
      : false;
    const isTicketingAvailable = recordData
      ? get(recordData, 'ticketing.enabled')
      : false;
    const isMerchandisingAvailable = recordData
      ? get(recordData, 'merchandise.enabled')
      : false;

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />
        <div>
          <Common.PageHeader
            loading={!headerData.loaded}
            name={headerData.name}
            image={headerData.image}
            headerRightPanel={
              <div className="page-header-right-panel">
                <Common.Button onClick={this.onEditClick.bind(this)}>
                  <Translate value="campaign.list.view.edit-campaign" />
                </Common.Button>
                <Common.Button onClick={this.onVisitCampaignClick.bind(this)}>
                  <Translate value="campaign.list.view.visit-campaign" />
                </Common.Button>
                {isFundraisersAvaialable && (
                  <Common.Button
                    id="btnCreatePage"
                    primary
                    onClick={this.onCreatePage}
                  >
                    <Translate value="campaign.list.view.create-page" />
                  </Common.Button>
                )}
              </div>
            }
          >
            <Link to={config.DEFAULT_CAMPAIGNS_URL} push>
              <Common.PageHeader.Breadcrumb label="Campaigns" />
            </Link>
            <Common.PageHeader.Breadcrumb label={headerData.name} />
          </Common.PageHeader>
        </div>

        <ListsViewContainer
          isTicketingAvailable={isTicketingAvailable}
          isMerchandisingAvailable={isMerchandisingAvailable}
          showFundraisers={isFundraisersAvaialable}
          baseFilter={baseFilter}
          filterPath="/campaigns/view"
          salesGridKey={gridKeys.CAMPAIGN_SALES}
          ticketsGridKey={gridKeys.CAMPAIGN_TICKETS}
          merchandiseGridKey={gridKeys.CAMPAIGN_MERCHANDISE}
          fundraisersGridKey={gridKeys.CAMPAIGN_FUNDRAISERS}
          donationsGridKey={gridKeys.CAMPAIGN_DONATIONS}
          shortDescription
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, campaignPage }) => {
  const record = campaign.record;
  const saveRecord = campaignPage.saveRecord;
  return {
    record,
    saveRecord
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(campaignActions, dispatch);
  dispatchers.generalActions = bindActionCreators(generalActions, dispatch);
  dispatchers.pageActions = bindActionCreators(pageActions, dispatch);
  return dispatchers;
};

/** Connects component to Redux store */
const ViewCampaignContainer = clear(
  connect(mapState, mapDispatch)(CampaignView)
);
export default ViewCampaignContainer;
