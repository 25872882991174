import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';

const CheckboxField = ({ label, value, update, fieldName }) => (
  <Form.Field>
    <Checkbox
      checked={value}
      onChange={(event, data) => update(data.checked)}
      label={label}
      id={`${fieldName}Id`}
    />
  </Form.Field>
);

CheckboxField.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool,
  update: PropTypes.func
};

export default CheckboxField;
