import { connect } from 'react-redux';
import React from 'react';
import { DragSource, DropTarget } from 'react-dnd';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';

import { reorderNodes, deleteNode } from '../../actionCreators';
import { sameParent } from '../../formBuilderHelpers';
import DeleteComponentModal from './DeleteComponentModal';
import RenderComponent from './RenderComponent';

const Types = {
  COMPONENT: 'component',
  SECTION: 'section'
};
const componentTarget = {
  canDrop() {
    return true;
  },
  hover(props, monitor) {
    const componentDragged = monitor.getItem().node;
    const componentDraggedTo = props.node;
    const parent = sameParent(
      componentDraggedTo,
      componentDragged,
      props.formRoot
    );
    if (componentDragged === componentDraggedTo || !parent) {
      // If it is the same component, or the components have different parents
      return;
    } // Don't reorder parent's child order if it is the same element & only same section dnd.
    props.putChildren(componentDraggedTo, componentDragged, props.formRoot);
  },
  drop() {}
};
const componentSource = {
  beginDrag(props) {
    props.unSetActiveComponent();
    return { node: props.node };
  },
  endDrag() {},
  canDrag(props) {
    return props.disabled ? false : true;
  }
};

function collectTarget(connectTarget, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDropTarget: connectTarget.dropTarget(),
    // You can ask the monitor about the current drag state:
    isOver: monitor.isOver(),
    isOverCurrent: monitor.isOver({ shallow: true }),
    canDrop: monitor.canDrop(),
    itemType: monitor.getItemType()
  };
}

function collectSource(connectSource, monitor) {
  return {
    // Call this function inside render()
    // to let React DnD handle the drag events:
    connectDragSource: connectSource.dragSource(),
    // You can ask the monitor about the current drag state:
    isDragging: monitor.isDragging()
  };
}

/**
 * This is the entry point for a Component.
 * It handles ReactDND integration mostly.
 * Renders RenderComponent which renders the Component visuals.
 * Renders DeleteComponentModal to delete the node from the FormBuilder tree.
 * Renders Label to handle the opening of DeleteComponentModal.
 */

class Component extends React.Component {
  constructor(props) {
    super(props);
    this.state = { deleteModalOpen: false };
  }
  deleteComponent = (possibleParentNode, node) => {
    this.props.deleteNode(possibleParentNode, node);
  };

  openDeleteModal = () => {
    this.setState({ deleteModalOpen: true });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModalOpen: false });
  };

  render = () => {
    const {
      node,
      connectDragSource,
      connectDropTarget,
      active,
      setActiveComponent,
      unSetActiveComponent,
      formRoot,
      isCurrentNodeValid,
      disabled,
      sectionKey
    } = this.props;
    const { openDeleteModal, closeDeleteModal, deleteComponent } = this;
    const { deleteModalOpen } = this.state;

    const fieldProps = {
      connectDragSource,
      node,
      active,
      setActiveComponent,
      unSetActiveComponent,
      openDeleteModal,
      isCurrentNodeValid,
      disabled,
      sectionKey
    };

    const modalProps = {
      formRoot,
      node,
      deleteComponent,
      open: deleteModalOpen,
      closeModal: closeDeleteModal
    };
    return (
      <div className="form-builder-field-wrapper">
        <DeleteComponentModal {...modalProps} />
        {connectDropTarget(
          <div>
            <RenderComponent {...fieldProps} />
          </div>
        )}
      </div>
    );
  };
}

Component.propTypes = {
  node: PropTypes.object,
  connectDragSource: PropTypes.func,
  connectDropTarget: PropTypes.func,
  active: PropTypes.bool,
  setActiveComponent: PropTypes.func,
  unSetActiveComponent: PropTypes.func,
  formRoot: PropTypes.object,
  deleteNode: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    formRoot: state.formBuilders[ownProps.node.formKey]
  };
};

const mapDispatchToProps = (dispatch) => ({
  putChildren: bindActionCreators(reorderNodes, dispatch),
  deleteNode: bindActionCreators(deleteNode, dispatch)
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  DropTarget(
    Types.COMPONENT,
    componentTarget,
    collectTarget
  )(DragSource(Types.COMPONENT, componentSource, collectSource)(Component))
);

export { Component as ComponentWithoutWrappers };
