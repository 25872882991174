import { HtttpRequestMethods, sendRequest } from "./request";
import { MerchandiseUrls } from "./constants"
import { IMerchandise, ISku, ApiError } from "../merchandise.model";

export interface CreateMerchandiseResult {
  data: IMerchandise;
  error: ApiError[];
}

export async function createMerchandise(
  apikey: string,
  sessionKey: string,
  body: IMerchandise
): Promise<CreateMerchandiseResult> {
  return sendRequest({
    apikey,
    sessionKey,
    url: MerchandiseUrls.Create,
    method: HtttpRequestMethods.Post,
    body,
  }).then((response) => response.json());
}

export async function createMerchandiseSku(
  apikey: string,
  sessionKey: string,
  merchandiseId: number,
  body: ISku
): Promise<CreateMerchandiseResult> {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${MerchandiseUrls.Create}${merchandiseId}/sku`,
    method: HtttpRequestMethods.Post,
    body,
  }).then((response) => response.json());
}
