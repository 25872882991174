import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../';
import * as templateHelper from './templateHelper';

import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import * as handlers from '../../modules/autocomplete';
import OptionalLabel from '../common/optionalLabel';

export class ManagedAutocomplete extends Component {
  constructor(props) {
    super(props);

    // Get the label and subtext from data store.
    this.path = this.props.path;
    this.label = templateHelper.getTranslation(props.label);
    this.inputLabel = templateHelper.getTranslation(props.inputLabel);
    this.subtext = templateHelper.getTranslation(props.subtext);
    this.searchEntity = props.entity;
    props.setSelectedItem(props.path, props.selected);

    if (this.props.initialChange) {
      props.onChange(props.path, props.value, props.selected);
    }
  }

  onValueChange = (evt, obj) => {
    const selected = this.getSelectedItem(obj.value);
    this.props.setSelectedItem(this.props.path, this.props.selected);
    this.props.onSearchChange(this.props.path, '');
    this.props.onChange(this.props.path, obj.value, selected);
  };

  clear = () => {
    this.onSearchChange({}, { searchQuery: '' });
  };

  getSelectedItem = (value) => {
    let list = get(this.props.data, 'list');
    // if we have list loaded
    if (list) {
      // get selected element
      return list.find((x) => x[this.props.returnField || "id"] === value);
    } else if (this.props.selected) {
      // if we have no list loaded but seleted element
      return this.props.selected;
    }
  };

  componentDidMount() {
    if (!this.props.selected && !this.props.data) {
      const request = this.props.request();
      request &&
        this.props.extendedSearchEntities(
          this.searchEntity,
          this.path,
          request,
          this.props.sessionKey
        );
    }
  }

  componentDidUpdate() {
    if (this.props.outerClear) {
      const request = this.props.request();
      request &&
        this.props.extendedSearchEntities(
          this.searchEntity,
          this.path,
          request,
          this.props.sessionKey
        );
    }
  }

  onSearchChange = (event, { searchQuery }) => {
    this.props.onSearchChange(this.props.path, searchQuery);

    const request = this.props.request();
    request.text = searchQuery;

    clearTimeout(this.timer);

    this.timer = setTimeout(() => {
      this.props.extendedSearchEntities(
        this.searchEntity,
        this.path,
        request,
        this.props.sessionKey
      );
    }, 500);
  };

  render() {
    const options = getOptions(
      this.props,
      this.props.data,
      this.props.selected
    );
    const label =
      this.props.validation && this.props.validation.required === false ? (
        <OptionalLabel>{this.label}</OptionalLabel>
      ) : (
        this.label
      );

    return (
      <Form.Autocomplete
        className={this.props.className}
        id={this.props.id}
        label={label}
        inputLabel={this.inputLabel}
        subtext={this.subtext}
        value={this.props.value}
        onChange={this.onValueChange.bind(this)}
        validation={this.props.validation}
        placeholder={this.props.placeholder}
        disabled={this.props.disabled}
        search={this.onSearchChange}
        searchQuery={this.props.searchString}
        options={options}
        clear={this.clear}
        loading={this.props.loading}
        validationErrorMessage={this.props.validationErrorMessage}
      />
    );
  }
}

const getOptions = (config, data, selected) => {
  if (!config.getItem) {
    throw new Error(
      'Autocomplete should have getItem function to get data for each element'
    );
  }

  let options = [];

  if (data) {
    options = data.list
      .map((x) => config.getItem(x))
      .filter((x) => x !== undefined && x !== null);
  }

  if (selected) {
    const selectedOption = config.getItem(selected);
    if (
      (config.searchString === undefined || config.searchString === null) &&
      config.value === selectedOption.value
    ) {
      config.onSearchChange(config.path, selectedOption.text);
    }
  }

  return options;
};

const mapState = (state, ownProps) => {
  const loading =
    get(state, `autocomplete.${ownProps.path}.loading`) || ownProps.loading;
  const data = get(state, `autocomplete.${ownProps.path}.data`);
  const searchString =
    ownProps.searchString ||
    get(state, `autocomplete.${ownProps.path}.searchString`);
  const outerClear = get(state, `autocomplete.${ownProps.path}.outerClear`);

  const placeholder = templateHelper.getTranslation(ownProps.placeholder);
  return {
    sessionKey: state.session.key,
    loading,
    data,
    placeholder,
    searchString,
    outerClear,
    disabled:
      ownProps.disabled ||
      templateHelper.getState(
        state.templateBuilderEx,
        ownProps.path,
        'disabled'
      ),
    selected: templateHelper.getValue(
      state.templateBuilderEx,
      ownProps.selected
    ),
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(handlers, dispatch);
};

export const TemplateAutocomplete = connect(
  mapState,
  mapDispatch
)(ManagedAutocomplete);
