export const ticketingValidationPaths = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email'
};

export const ticketingValidationFullModel = {
  [ticketingValidationPaths.firstName]: {
    required: true
  },
  [ticketingValidationPaths.lastName]: {
    required: true
  },
  [ticketingValidationPaths.email]: {
    required: true,
    email: true
  }
};

export const ticketingValidationSimplyfiedModel = {
  [ticketingValidationPaths.firstName]: {
    required: false
  },
  [ticketingValidationPaths.lastName]: {
    required: false
  },
  [ticketingValidationPaths.email]: {
    required: false,
    email: true
  }
};
