import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { I18n, Translate } from 'react-redux-i18n';

import * as fundraiserActions from './reducer';
import { setRedirectUrl } from '../../modules/session';
import { hideCondition } from '../../helpers/hideHelper';
import clear from '../../components/clear';
import { Record } from '../../modules/bin/utility';
import CapitalizeText from '../../components/common/capitalizeText';
import get from 'lodash.get';

import { Common } from '../../components';
import BasePage from '../../components/common/basePageView';
import ContextMenu from '../../components/common/grid/contextMenu';
import { Table, Menu } from 'semantic-ui-react';
import Spinner from '../../components/common/spinner';

import { gridKeys } from '../../constants/gridKeys';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { pageTypesStrings, superteam } from '../../constants/pageTypes';
import {
  privacyStrings,
  PRIVACY_COLUMN,
  TYPE_COLUMN
} from '../campaign/constants';
import { i18nKeys } from './constants';
import { fundraisersBaseOptions } from './metadataConstants';
import { RootState } from '../../store';
import { ExportDataButton } from '../../components/template/templateExportButton';
import { ExportDownloadProvider } from '../export-history';

class FundraiserPage extends Component<any> {
  hideColumnCondition = (item: any) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  isFundraisersContextMenuItemHidden = (item: any) => {
    return item.type === pageTypesStrings.FUNDRAISER;
  };

  isDonationsContextMenuItemHidden = () => {
    return this.props.isEventAdmin;
  };

  onToggleColumnsChange = (toggleColumns: any) => {
    this.props.toggleColumnsChange(toggleColumns);
  };

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.list) ||
      Record.isRecordLoading(this.props.list.metadata)
    );
  };

  getPanel = () => {
    const { Panel } = Common.Grid.Managed as any;
    return (
      <Panel name="right">
        <ExportDownloadProvider entity="page" version="v3">
          {({ open }) => (
            <ExportDataButton
              featureFlag
              versions={['v2', 'v3']}
              onExportClick={(version) => {
                if (version === 'v3') {
                  open({
                    layout: 'Extended',
                    ...(this.props.list?.request || {})
                  });
                } else {
                  open(this.props.list?.request);
                }
              }}
            />
          )}
        </ExportDownloadProvider>
      </Panel>
    );
  };

  getContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.FUNDRAISERS.EDIT_PAGE.key}
          label={I18n.t('fundraiser.list.context.edit-page')}
          contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.EDIT_PAGE}
          onClick={() =>
            this.props.setRedirectUrl(this.props.history.location.pathname)
          }
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_PUBLIC.key}
          label={I18n.t('fundraiser.list.context.view-public-page')}
          contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_PUBLIC}
          // isHiden={this.isViewContextMenuItemHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_DONATIONS.key}
          label={I18n.t('fundraiser.list.context.view-page-donations')}
          contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_DONATIONS}
          isHiden={this.isDonationsContextMenuItemHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_FUNDRAISERS.key}
          label={I18n.t('fundraiser.list.context.view-page-fundraisers')}
          isHiden={this.isFundraisersContextMenuItemHidden}
          contextMenuOptions={CONTEXT_MENU_KEYS.FUNDRAISERS.VIEW_FUNDRAISERS}
        />
      </ContextMenu>
    );
  };

  columnRender(column: any, value: any) {
    if (column.id === PRIVACY_COLUMN) {
      const text =
        value === true ? privacyStrings.PUBLIC : privacyStrings.PRIVATE;
      return <Table.Cell>{text}</Table.Cell>;
    } else if (column.id === TYPE_COLUMN) {
      return (
        <Table.Cell>
          <CapitalizeText>
            {value === pageTypesStrings.COLLECTION ? superteam : value}
          </CapitalizeText>
        </Table.Cell>
      );
    }
  }

  render() {
    if (Record.isRecordLoading(this.props.exportFileRecord)) {
      return <Spinner />;
    }

    const panel = this.getPanel();
    const contextMenu = this.getContextMenu();
    const filterButtons = get(this.props, 'list.filterButtons');

    return (
      <BasePage>
        <h1>
          <Translate value={i18nKeys.PAGE_TITLE} />
        </h1>
        {!this.props.isEventAdmin && (
          <Menu
            className="accounts-tab-buttons-group"
            style={{ margin: '0px', borderRight: '0px' }}
          >
            {Object.keys(filterButtons).map((key) => {
              const item = filterButtons[key];
              return (
                <Menu.Item
                  key={item.key}
                  name={item.key}
                  content={I18n.t(item.I18nKey)}
                  active={item.key === this.props.match.params.tab}
                  disabled={this.isChooseTabButtonDisabled()}
                  onClick={() => {
                    this.props.history.push(`/fundraisers/${item.key}`);
                  }}
                />
              );
            })}
          </Menu>
        )}

        <Common.Grid.Managed
          listKey={gridKeys.FUNDRAISERS}
          list={this.props.list}
          actions={this.props.listActions}
          search
          advancedFilterButton
          searchVersion="v3"
          toggleColumns
          filtersButton
          activeTabFilter={this.props.match.params.tab}
          hideColumn={this.hideColumnCondition}
          baseOptions={fundraisersBaseOptions}
          columnRender={this.columnRender}
        >
          {panel}
          {contextMenu}
        </Common.Grid.Managed>
      </BasePage>
    );
  }
}

const mapState = ({ fundraiser, session }: RootState) => {
  const exportFileRecord = fundraiser.exportFileRecord;
  const fundraiserGrid = (Common.Grid.Managed as any).mapGridState(
    fundraiser,
    gridKeys.FUNDRAISERS
  );

  const isOrganizationAdmin = get(session, 'isOrganizationAdmin');
  const isEventAdmin = get(session, 'isEventAdmin');

  return {
    exportFileRecord,
    list: fundraiserGrid.list,
    listKey: fundraiserGrid.listKey,
    isOrganizationAdmin,
    isEventAdmin
  };
};

const mapDispatch = (dispatch: Dispatch<any, any>) => {
  var actions = {
    getMetadata: fundraiserActions.getMetadata,
    getListData: fundraiserActions.getListData,
    getListDataBySearchTerm: fundraiserActions.getListDataBySearchTerm,
    toggleColumnsChange: fundraiserActions.toggleColumnsChange
  };

  const listActions = (Common.Grid.Managed as any).bindGridActions(
    dispatch,
    actions
  );

  return {
    listActions,
    fundraiserActions: bindActionCreators(fundraiserActions as any, dispatch),
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch)
  };
};

const FundraiserPageContainer = clear(
  connect(mapState, mapDispatch)(FundraiserPage)
);
export default FundraiserPageContainer;
