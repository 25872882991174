import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';
import {
  getCampaignClickableColumnForCampaignFundraisers,
  getCampaignClickableColumnForCampaignTicketing
} from '../../../helpers/publicSiteUrlHelper';
import {
  salesAvailableFilters,
  ticketsAvailableFilters,
  merchandiseAvailableFilters
} from '../constants';
import { instalmentFormatter } from '../ticketingContracts';

export const getSalesGrid = (translationKey, pageSize) => {
  return new GridOptions()
    .setPageSize(pageSize)
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`)
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('receiptNumber', `${translationKey}.receiptNumber`),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAt',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('type', `${translationKey}.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel('status', `${translationKey}.status`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phone-number`
      ),
      new ColumnModel(
        'financials.installments',
        `${translationKey}.instalments`,
        instalmentFormatter
      )
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(salesAvailableFilters)
    .getState();
};

export const getTicketsGrid = (translationKey, pageSize) => {
  return new GridOptions()
    .setPageSize(pageSize)
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization.name`
      ),
      new ColumnModel(
        'sale.campaign.name',
        `${translationKey}.sale.campaign.name`
      )
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignTicketing,
          true,
          false
        ),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'ticket.name',
        `${translationKey}.ticket.name`
      ).visibleByDefault(),
      new ColumnModel('price', `${translationKey}.price`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'sale.receiptNumber',
        `${translationKey}.sale.receiptNumber`
      ),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel('uniqueNumber', `${translationKey}.uniqueNumber`),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAtLocal',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('sale.type', `${translationKey}.sale.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phoneNumber`
      )
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(ticketsAvailableFilters)
    .getState();
};

export const getMerchandiseGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization.name`
      ),
      new ColumnModel(
        'sale.campaign.name',
        `${translationKey}.sale.campaign.name`
      )
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignTicketing,
          true,
          false
        ),
      new ColumnModel('soldAtLocal', `${translationKey}.soldAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'merchandiseSku.name',
        `${translationKey}.name`
      ).visibleByDefault(),
      new ColumnModel('price', `${translationKey}.price`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'sale.receiptNumber',
        `${translationKey}.sale.receiptNumber`
      ),
      new ColumnModel(
        'financials.refundedAmount',
        `${translationKey}.financials.refundedAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel('uniqueNumber', `${translationKey}.uniqueNumber`),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.isRefunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.refundedAtLocal',
        `${translationKey}.financials.refundedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.returned',
        `${translationKey}.financials.returned`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel(
        'financials.returnedAtLocal',
        `${translationKey}.financials.returnedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel(
        'financials.currency',
        `${translationKey}.financials.currency`
      ),
      new ColumnModel('sale.type', `${translationKey}.sale.type`).setType(
        columnTypes.CAPITALIZE_STRING
      ),
      new ColumnModel(
        'customer.firstName',
        `${translationKey}.customer.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.lastName',
        `${translationKey}.customer.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.email',
        `${translationKey}.customer.email`
      ).visibleByDefault(),
      new ColumnModel(
        'customer.phoneNumber',
        `${translationKey}.customer.phoneNumber`
      )
    ])
    .orderBy('soldAtLocal', 'desc')
    .addAvailableFiltersKeys(merchandiseAvailableFilters)
    .getState();
};
