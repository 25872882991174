import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Modal, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import WizardRadioField from './wizardRadio';
import {
  changeParentCreateWindowVisible,
  selectParentTemplate
} from '../../store/campaignActions';

class CreateParentModal extends Component {
  onChange = (value) => {
    this.props.selectTemplate(value);
  };

  onClose = () => {
    this.props.closeWindow();
    this.props.clearTemplate();
  };

  getParentTemplates() {
    return this.props.data.parents.map((item) =>
      Object.assign({}, { key: item }, this.props.data.items[item])
    );
  }

  getExamples() {
    return this.props.data.examples;
  }

  render() {
    const data = this.getParentTemplates();
    const examples = this.getExamples();
    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        onClose={this.onClose}
        open={this.props.open}
      >
        <Modal.Header>
          <div className="button-wrapper align-right">
            <Button
              icon="close"
              circular
              color="grey"
              compact
              size="mini"
              onClick={this.onClose}
              className="close"
            />
          </div>
          <Translate value="campaign.create.modal1.title" />
        </Modal.Header>
        <Modal.Content>
          <Form id="campaign-type-form-parrent">
            {data.map((item) => {
              const example = examples.filter((d) => d.key === item.key);
              return (
                <WizardRadioField
                  key={item.key}
                  value={item.key}
                  label={I18n.t(`campaign.create.list.${item.key}.title`)}
                  checked={this.props.value === item.key}
                  onChange={this.onChange}
                >
                  <div className="input-sub">
                    {I18n.t(`campaign.create.list.${item.key}.description`)}
                    <br />
                    {example && example.length > 0 && example[0].url && (
                      <a
                        href={example[0].url}
                        onClick={(event) => event.stopPropagation()}
                        target={'_blank'}
                        rel="noreferrer"
                      >
                        <Translate value="general.view-example" />
                      </a>
                    )}
                  </div>
                </WizardRadioField>
              );
            })}
            <div className="button-wrapper align-right">
              <Button
                primary
                disabled={this.props.value === null}
                onClick={this.props.onCommand}
                type="button"
              >
                <Translate value="general.list.next" />
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ campaign }) => {
  return {
    data: campaign.templates.metadata,
    open: campaign.parentWindowVisible,
    value: campaign.parentTemplate
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    closeWindow() {
      dispatch(changeParentCreateWindowVisible(false));
    },
    selectTemplate(value) {
      dispatch(selectParentTemplate(value));
    },
    clearTemplate() {
      dispatch(selectParentTemplate(null));
    }
  };
};

const CreateParentModalContainer = connect(
  mapState,
  mapDispatch
)(CreateParentModal);
export default CreateParentModalContainer;
