import React, { Component } from 'react';
import Frame from './frame';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authenticationActions from '../../modules/authentication';
import * as validationHelper from '../../helpers/validationHelper';

import {
  Button,
  Header,
  Container,
  Form as SemanticForm
} from 'semantic-ui-react';

import ValidatedInput from '../common/validatedInput';
import { Record } from '../../modules/bin/utility';

class ForgotPassword extends Component {
  handleSubmit = (e) => {
    this.props.authenticationActions.forgotPassword(this.props.reset.email);
  };

  handleChange = (e, { value }) =>
    this.props.authenticationActions.resetEmailChange(value);

  render() {
    const { forgotPasswordRecord } = this.props;
    const { emailValidation, email } = this.props.reset;

    const isLoading = Record.isRecordLoading(forgotPasswordRecord);

    const isResetButtonDisabled = !validationHelper.isValid(
      emailValidation,
      email
    );
    const isForgotPasswordRecordReady =
      Record.isRecordReady(forgotPasswordRecord);

    return (
      <Frame>
        {!isForgotPasswordRecordReady && (
          <div>
            <Header textAlign="center" as="h2">
              Reset your password
            </Header>
            <Container textAlign="center">
              <p>
                No worries! Simply enter your email address and we’ll send you
                an email so you can reset it.{' '}
              </p>
            </Container>
            <SemanticForm>
              <Container>
                <ValidatedInput
                  validation={emailValidation}
                  placeholder="name@emailaddress.com"
                  label="Email"
                  name="email"
                  showValidationIcon
                  onChange={this.handleChange}
                  value={email}
                />
                <Button
                  primary
                  fluid
                  onClick={this.handleSubmit}
                  loading={isLoading}
                  disabled={isResetButtonDisabled || isLoading}
                >
                  Reset password
                </Button>
              </Container>
            </SemanticForm>
          </div>
        )}
        {isForgotPasswordRecordReady && (
          <div>
            <Header textAlign="center" as="h2">
              All done!
            </Header>
            <Container textAlign="center">
              <p>
                {/* eslint-disable */}
                We’ve sent an email with instructions to <a href="#">{email}</a>
                . If you don’t see it, try checking your spam folder.
              </p>
            </Container>
            <Button primary fluid as="a" href="/login">
              Return to log in
            </Button>
          </div>
        )}
      </Frame>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    forgotPasswordRecord: authentication.forgotPasswordRecord,
    reset: authentication.reset
  };
};

const mapDispatch = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatch)(ForgotPassword);
