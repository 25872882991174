import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import querystring from 'querystring';

import Frame from './frame';
import { Common } from '../';
import LoginForm from './loginForm';
import RecordResultMessage from '../common/recordResult';
import SessionVerificationModal from '../layouts/sessionVerification';

import { authenticate, mockAuthenticate } from '../../modules/session';
import { clearRecordMessages } from '../../modules/authentication';

class Login extends Component {
  clearMessages = () => {
    this.props.clearRecordMessages('updatePasswordRecord');
  };

  render() {
    // If authenticated, then redirect to home or return url param.
    if (this.props.session && this.props.session.key !== null) {
      if (
        window &&
        window.location.search &&
        window.location.search.length > 1
      ) {
        const q = querystring.decode(window.location.search.slice(1));
        if (q.returnUrl) {
          return <Redirect to={q.returnUrl} />;
        }
      }

      return <Redirect to="/" />;
    }

    // Render Login Panel
    return this.props.session && this.props.session.processing ? (
      <Common.Spinner />
    ) : (
      <Frame error={this.props.error}>
        <RecordResultMessage
          record={this.props.updatePasswordRecord}
          onDismiss={this.clearMessages}
        />
        <SessionVerificationModal show={this.props.verification} />
        <LoginForm header="Login to your account" />
      </Frame>
    );
  }
}

const mapStateToProps = ({ general, session, authentication }) => {
  return {
    error:
      general.errorsTimed && general.errorsTimed.length > 0
        ? general.errorsTimed[0]
        : null,
    verification: general.verification,
    updatePasswordRecord: authentication.updatePasswordRecord,
    session: session
  };
};

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      authenticate,
      mockAuthenticate,
      clearRecordMessages
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Login);
