import React from 'react';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { TemplateFormBuilderAnswers } from '../../../components/template/templateFormBuilderAnswers';
import { getTicketContract, templateTabs } from '../ticketingContracts';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { ticketingValidationPaths } from '../validationModels';

export const getTicketTab = (props) => {
  const {
    id,
    index,
    number,
    onChange,
    onTicketChange,
    getCampaignRequest,
    getCampaignItem,
    getTicketsRequest,
    getTicketItem,
    required,
    optional,
    formValidation,
    formBuilderAnswers
  } = props;
  const ticketContract = getTicketContract(index);

  return (
    <TemplatePage
      id={templateTabs.TICKET.getTabName(id)}
      i18nKey="ticketing.edit.ticket.header"
      i18nObject={{ index: number + 1 }}
    >
      <TemplateHeader as="h3" label="ticketing.edit.ticket.label" />

      <TemplateAutocomplete
        id={`ticket-${index}-campaignName`}
        entity="page/campaign"
        path={ticketContract.campaignId}
        selected="campaign"
        label="ticketing.edit.ticket.campaign.label"
        placeholder="ticketing.edit.ticket.campaign.placeholder"
        validation={required}
        onChange={onChange}
        request={getCampaignRequest}
        getItem={getCampaignItem}
      />

      <TemplateAutocomplete
        id={`ticket-${index}-ticketName`}
        entity="ticket"
        path={ticketContract.ticketId}
        selected="ticket"
        label="ticketing.edit.ticket.ticket.label"
        placeholder="ticketing.edit.ticket.ticket.placeholder"
        validation={required}
        onChange={(path, value, item) => {
          onTicketChange(path, value, item, index);
        }}
        request={getTicketsRequest}
        getItem={getTicketItem}
      />

      <TemplateInput
        id={`ticket-${index}-name`}
        path={ticketContract.ticketName}
        label="ticketing.edit.ticket.name.label"
        placeholder="ticketing.edit.ticket.name.placeholder"
        onChange={onChange}
        validation={optional}
      />

      <TemplateCurrency
        id={`ticket-${index}-amount`}
        path={ticketContract.amount}
        validation={optional}
        label="ticketing.edit.ticket.amount.label"
        allowDecimal
        onChange={onChange}
      />

      <TemplateInput
        id={`ticket-${index}-firstName`}
        path={ticketContract.firstName}
        label="ticketing.edit.invoice-information.first-name.label"
        placeholder="ticketing.edit.invoice-information.first-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.firstName]}
      />

      <TemplateInput
        id={`ticket-${index}-lastName`}
        path={ticketContract.lastName}
        label="ticketing.edit.invoice-information.last-name.label"
        placeholder="ticketing.edit.invoice-information.last-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.lastName]}
      />

      <TemplateInput
        id={`ticket-${index}-email`}
        path={ticketContract.email}
        label="ticketing.edit.invoice-information.email.label"
        placeholder="ticketing.edit.invoice-information.email.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.email]}
      />

      <TemplateFormBuilderAnswers
        id={`ticket-${index}-templateFormBuilderAnswers`}
        path={ticketContract.formbuilder}
        onChange={onChange}
        validation={formBuilderAnswers}
      />

      <TemplateCheckbox
        id={`ticket-${index}-issue-invoice`}
        path={ticketContract.issueInvoice}
        inputLabel="ticketing.edit.ticket.issue-invoice.label"
        subtext="ticketing.edit.ticket.issue-invoice.subtext"
        onChange={onChange}
      />
    </TemplatePage>
  );
};
