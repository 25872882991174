//---------------React/Redux
import { off } from 'process';
import React, { ReactNode, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { I18n } from 'react-redux-i18n';
import { Button, Table } from 'semantic-ui-react';

interface IListTableColumn {
  header: string;
  value: string;
}

interface IListTableProps {
  columns: IListTableColumn[];
  data: any[];
  disableAction: boolean;
  onEdit: (item: any, index: number) => void;
}

export const ListTable = ({
  columns,
  data,
  disableAction,
  onEdit
}: IListTableProps) => {
  return (
    <Table>
      <Table.Header>
        <Table.Row>
          {columns.map((column: IListTableColumn) => (
            <Table.HeaderCell>{I18n.t(column.header)}</Table.HeaderCell>
          ))}
          <Table.HeaderCell>{I18n.t('list-table.action')}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {(!data || data.length === 0) && (
          <Table.Row>
            <Table.Cell colSpan={columns.length + 1} textAlign="center">
              {I18n.t('list-table.no-data')}
            </Table.Cell>
          </Table.Row>
        )}
        {data?.length > 0 &&
          data.map((item, itemIndex) => {
            if (item.hidden) return null;
            return (
              <Table.Row>
                {columns.map((_: IListTableColumn, cellIndex: number) => (
                  <Table.Cell>{item.data[cellIndex]}</Table.Cell>
                ))}
                <Table.Cell
                  disabled={disableAction}
                  style={{ opacity: disableAction ? 0.5 : 1 }}
                >
                  <p
                    className="Links edit-coupon-button"
                    onClick={() => onEdit(item, itemIndex)}
                  >
                    {I18n.t('list-table.edit-action')}
                  </p>
                </Table.Cell>
              </Table.Row>
            );
          })}
      </Table.Body>
    </Table>
  );
};
