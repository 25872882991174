import React from 'react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';

class FormbuilderPhoneNumber extends React.Component {
  render() {
    const {
      onChange,
      value,
      placeholder,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;
    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <PhoneInput
          id="formbuilder-phone"
          onChange={onChange}
          country="AU"
          value={value}
          placeholder={placeholder}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderPhoneNumber;
