export const TERMINAL_API_PATH = 'terminal';

export const TERMINAL_LIST_DATA_REQUESTED = 'terminal/REQUESTED';
export const TERMINAL_LIST_DATA_SUCCESS = 'terminal/SUCCESS';
export const TERMINAL_LIST_DATA_FAILURE = 'terminal/FAILURE';

export const TERMINAL_METADATA_REQUESTED = 'terminal/METADATA_REQUESTED';
export const TERMINAL_METADATA_SUCCESS = 'terminal/METADATA_SUCCESS';
export const TERMINAL_METADATA_FAILURE = 'terminal/METADATA_FAILURE';

export const TERMINAL_CREATE_REQUESTED = 'terminal/TERMINAL_CREATE_REQUESTED';
export const TERMINAL_CREATE_SUCCESS = 'terminal/TERMINAL_CREATE_SUCCESS';
export const TERMINAL_CREATE_FAILURE = 'terminal/TERMINAL_CREATE_FAILURE';

export const TERMINAL_UPDATE_REQUESTED = 'terminal/TERMINAL_UPDATE_REQUESTED';
export const TERMINAL_UPDATE_SUCCESS = 'terminal/TERMINAL_UPDATE_SUCCESS';
export const TERMINAL_UPDATE_FAILURE = 'terminal/TERMINAL_UPDATE_FAILURE';

export const TERMINAL_DELETE_REQUESTED = 'terminal/TERMINAL_DELETE_REQUESTED';
export const TERMINAL_DELETE_SUCCESS = 'terminal/TERMINAL_DELETE_SUCCESS';
export const TERMINAL_DELETE_FAILURE = 'terminal/TERMINAL_DELETE_FAILURE';

export const TERMINAL_GET_REQUESTED = 'terminal/TERMINAL_GET_REQUESTED';
export const TERMINAL_GET_SUCCESS = 'terminal/TERMINAL_GET_SUCCESS';
export const TERMINAL_GET_FAILURE = 'terminal/TERMINAL_GET_FAILURE';

export const TERMINAL_MESSAGE_CLEAR = 'terminal/MESSAGE_CLEAR';

export const TERMINAL_SET_LIST_DATA = 'terminal/SET_LIST_DATA';

export const TERMINAL_SEARCH_OPTIONS_REQUESTED =
  'terminal/TERMINAL_SEARCH_OPTIONS_REQUESTED';
export const TERMINAL_SEARCH_OPTIONS_SUCCESS =
  'terminal/TERMINAL_SEARCH_OPTIONS_SUCCESS';
export const TERMINAL_SEARCH_OPTIONS_FAILURE =
  'terminal/TERMINAL_SEARCH_OPTIONS_FAILURE';
