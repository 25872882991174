import { AboutElementTypes } from '../components/common/aboutElements/aboutElementType';
import { v4 as uuidv4 } from 'uuid';
import get from 'lodash.get';
import { pageTypes } from '../pages/campaign/constants';
import { replaceTextAbout } from '../pages/campaign/page/mergeHelper';
import { hasArrayDuplicates } from './arrayHelper';

export const getDefaultAboutElements = () => {
  return {
    createdAt: Date.now(),
    elements: [
      {
        guid: uuidv4(),
        value: '',
        type: AboutElementTypes.Image
      },
      {
        guid: uuidv4(),
        value: '',
        type: AboutElementTypes.Text
      }
    ]
  };
};

export const getDefaultFundraiserAndTeamAboutElements = (text) => {
  return {
    createdAt: Date.now(),
    elements: [
      {
        guid: uuidv4(),
        value: '',
        type: AboutElementTypes.Image
      },
      {
        guid: uuidv4(),
        value: text ? text : '',
        type: AboutElementTypes.Text
      }
    ]
  };
};

export const getDefaultThankYouElements = () => {
  return {
    createdAt: Date.now(),
    elements: [
      {
        guid: uuidv4(),
        value: '',
        type: AboutElementTypes.Text
      },
      {
        guid: uuidv4(),
        value: '',
        type: AboutElementTypes.Video
      }
    ]
  };
};

export const getAboutValidation = () => {
  return {
    required: true,
    validateAboutElements: true
  };
};

export const areAboutElementsEqual = (about1, about2) => {
  if (!about1 || !about2) {
    return false;
  }

  const strAbout1 = JSON.stringify(about1.elements);
  const strAbout2 = JSON.stringify(about2.elements);

  return hasArrayDuplicates([strAbout1, strAbout2]);
};

export const aboutHandler = {
  [pageTypes.FUNDRAISER]: {
    getAbout(
      about,
      fundraiserDefault,
      teamDefault,
      collectionDefault,
      campaignDefault,
      amount,
      organisationName,
      firstName,
      teamName,
      collectionName,
      model
    ) {
      if (
        !about &&
        fundraiserDefault &&
        typeof fundraiserDefault.elements !== 'undefined'
      ) {
        return getAboutText(
          fundraiserDefault,
          amount,
          organisationName,
          firstName,
          teamName,
          collectionName,
          model
        );
      }

      if (!about) {
        return getDefaultFundraiserAndTeamAboutElements();
      }

      if (
        fundraiserDefault &&
        typeof fundraiserDefault.elements !== 'undefined'
      ) {
        const oldDefaultAbout = getAboutText(
          fundraiserDefault,
          amount,
          organisationName,
          firstName,
          teamName,
          collectionName
        );
        if (areAboutElementsEqual(about, oldDefaultAbout)) {
          const newDefaultAbout = getAboutText(
            fundraiserDefault,
            amount,
            organisationName,
            firstName,
            teamName,
            collectionName,
            model
          );
          return newDefaultAbout;
        }
      }

      return about;
    },
    isAboutElementDefault(aboutElement, fundraiserDefault, state) {
      if (
        aboutElement &&
        fundraiserDefault &&
        typeof fundraiserDefault.elements !== 'undefined'
      ) {
        const amount = get(state, 'targetAmount');
        const organisationName = get(state, 'organization.name');
        const firstName = get(state, 'manager.firstName');
        const teamName = get(state, 'teamName');
        const collectionName = get(state, 'collectionName');

        return areAboutElementsEqual(
          aboutElement,
          getAboutText(
            fundraiserDefault,
            amount,
            organisationName,
            firstName,
            teamName,
            collectionName
          )
        );
      }

      return false;
    }
  },
  [pageTypes.TEAM]: {
    getAbout(
      about,
      fundraiserDefault,
      teamDefault,
      collectionDefault,
      campaignDefault,
      amount,
      organisationName,
      firstName,
      teamName,
      collectionName,
      model
    ) {
      if (
        !about &&
        teamDefault &&
        typeof teamDefault.elements !== 'undefined'
      ) {
        return getAboutText(
          teamDefault,
          amount,
          organisationName,
          firstName,
          teamName,
          collectionName,
          model
        );
      }

      if (!about) {
        return getDefaultFundraiserAndTeamAboutElements();
      }

      if (teamDefault && typeof teamDefault.elements !== 'undefined') {
        const oldDefaultAbout = getAboutText(
          teamDefault,
          amount,
          organisationName,
          firstName,
          teamName,
          collectionName
        );
        if (areAboutElementsEqual(about, oldDefaultAbout)) {
          const newDefaultAbout = getAboutText(
            teamDefault,
            amount,
            organisationName,
            firstName,
            teamName,
            collectionName,
            model
          );
          return newDefaultAbout;
        }
      }

      return about;
    },
    isAboutElementDefault(aboutElement, teamDefault, state) {
      if (
        aboutElement &&
        teamDefault &&
        typeof teamDefault.elements !== 'undefined'
      ) {
        const amount = get(state, 'targetAmount');
        const organisationName = get(state, 'organization.name');
        const teamName = get(state, 'name');
        const collectionName = get(state, 'collectionName');
        const firstName = get(state, 'manager.firstName');

        return areAboutElementsEqual(
          aboutElement,
          getAboutText(
            teamDefault,
            amount,
            organisationName,
            firstName,
            teamName,
            collectionName
          )
        );
      }

      return false;
    }
  },
  [pageTypes.COLLECTION]: {
    getAbout(
      about,
      fundraiserDefault,
      teamDefault,
      collectionDefault,
      campaignDefault,
      amount,
      organisationName,
      firstName,
      teamName,
      model
    ) {
      if (
        !about &&
        collectionDefault &&
        typeof collectionDefault.elements !== 'undefined'
      ) {
        return getAboutText(
          collectionDefault,
          amount,
          organisationName,
          firstName,
          teamName,
          model
        );
      }

      if (!about) {
        return getDefaultFundraiserAndTeamAboutElements();
      }

      if (collectionDefault && typeof collectionDefault.elements !== 'undefined') {
        const oldDefaultAbout = getAboutText(
          collectionDefault,
          amount,
          organisationName,
          firstName,
          teamName
        );
        if (areAboutElementsEqual(about, oldDefaultAbout)) {
          const newDefaultAbout = getAboutText(
            collectionDefault,
            amount,
            organisationName,
            firstName,
            teamName,
            model
          );
          return newDefaultAbout;
        }
      }

      return about;
    },
    isAboutElementDefault(aboutElement, teamDefault, state) {
      if (
        aboutElement &&
        teamDefault &&
        typeof teamDefault.elements !== 'undefined'
      ) {
        const amount = get(state, 'targetAmount');
        const organisationName = get(state, 'organization.name');
        const teamName = get(state, 'name');
        const firstName = get(state, 'manager.firstName');

        return areAboutElementsEqual(
          aboutElement,
          getAboutText(
            teamDefault,
            amount,
            organisationName,
            firstName,
            teamName
          )
        );
      }

      return false;
    }
  }
};

export const getAboutText = (
  template,
  amount,
  organisationName,
  firstName,
  teamName,
  collectionName,
  model
) => {
  if (!template) {
    return;
  }

  return replaceTextAbout(
    template,
    model || {
      amount: amount,
      organisationName: organisationName,
      firstName: firstName,
      teamName: teamName,
      collectionName: collectionName
    }
  );
};
