import { campaignStatuses } from '../pages/campaign/constants';
import { eventStatuses } from '../pages/event/constants';

export const isChangeStatusValid = (prevStatus, newStatus) => {
  return (
    campaignStatuses[prevStatus.toUpperCase()].value <
    campaignStatuses[newStatus.toUpperCase()].value
  );
};

export const isChangeEventStatusValid = (prevStatus, newStatus) => {
  return (
    eventStatuses[prevStatus.toUpperCase()].value <
    eventStatuses[newStatus.toUpperCase()].value
  );
};
