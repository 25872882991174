import React from 'react';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import { ViewActionSectionRow } from '../../../components/layouts/viewActionSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import { useAppDispatch } from '../../../hooks';
import { RootState } from '../../../store';
import ReceiptSaleWindowContainer from '../modals/receiptSaleWindow';
import RefundSaleWindow from '../modals/refundSaleWindow/RefundSaleWindow';
import { showReceiptConfirmation, showRefundConfirmation } from '../reducer';

export const TicketActions = () => {
  const dispatch = useAppDispatch();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const recordData = ticketing.record?.data?.data;

  const onReissueClick = () => {
    dispatch(showReceiptConfirmation(recordData, 'sale'));
  };

  const refundOrCancelClick = () => {
    dispatch(showRefundConfirmation(recordData, 'sale'));
  };

  return (
    <>
      <ReceiptSaleWindowContainer />
      <RefundSaleWindow />
      <div className="ticket-action-container">
        <ViewTable
          title={
            <Translate value="donation.donation-view.donation-details.actions" />
          }
        >
          <ViewActionSectionRow
            icon="eye"
            title={I18n.t('ticketing.edit.buttons.reissue')}
            onClick={() => onReissueClick()}
          />
          <ViewActionSectionRow
            icon="trash alternate"
            title={I18n.t('ticketing.edit.buttons.refund')}
            onClick={() => refundOrCancelClick()}
          />
        </ViewTable>
      </div>
    </>
  );
};
