import React, { Component } from 'react';
import { Form, TextArea, Message, Icon } from 'semantic-ui-react';

class TextAreaInput extends Component {
  getValidationIcon(validationErrorMessage) {
    return validationErrorMessage ? (
      <Icon name="remove circle" size="large" color="red" />
    ) : (
      <Icon name="check circle" size="large" color="green" />
    );
  }

  render() {
    const {
      label,
      inputLabel,
      subtext,
      subtextPosition = 'bottom',
      validationErrorMessage,
      required,
      value,
      showValidationIcon,
      error,
      errorComponent,
      ...pprops
    } = this.props;
    const validationIcon = showValidationIcon
      ? this.getValidationIcon(validationErrorMessage)
      : null;

    return (
      <Form.Field required={required}>
        {label && (
          <label className={`input-sub-${subtextPosition}`}>{label}</label>
        )}
        {subtext && subtextPosition === 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
        <TextArea
          label={inputLabel}
          value={value}
          {...pprops}
          placeholder={this.props.placeholder}
          onBlur={this.props.onBlur}
          icon={validationIcon}
        />

        {subtext && subtextPosition !== 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
        <Message negative hidden={!validationErrorMessage && !error}>
          {errorComponent ? (
            <div>{errorComponent}</div>
          ) : (
            <p>{validationErrorMessage}</p>
          )}
        </Message>
      </Form.Field>
    );
  }
}

export default TextAreaInput;
