import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from '../';
import * as templateHelper from './templateHelper';
import { I18n } from 'react-redux-i18n';

class ManagedDropdown extends Component {
  constructor(props) {
    super(props);

    if (this.props.initialChange) {
      props.onChange(props.path, props.value);
    }
  }

  onValueChange(evt, obj) {
    this.props.onChange(this.props.path, obj.value, obj);
  }

  render() {
    // Get any regex rule if it exists.
    let options = this.props.options
      ? this.props.options
      : this.props.getOptions().map((item) => {
          return {
            text: I18n.t(item.text),
            value: item.value,
            icon: item.icon
          };
        });

    if (this.props.clearable && !options.find((x) => x.value === '')) {
      options.unshift({
        text: '-- empty --',
        value: ''
      });
    }

    const value = this.props.value;

    return (
      <Form.Dropdown
        id={this.props.id}
        style={this.props.style}
        className={this.props.className}
        label={this.props.label}
        defaultLabel={this.props.defaultLabel}
        inputLabel={this.props.inputLabel}
        subtext={this.props.subtext}
        value={value !== undefined || value !== null ? value : ''}
        onChange={this.onValueChange.bind(this)}
        required={this.props.validation && this.props.validation.required}
        options={options}
        search={this.props.search}
        onFocus={this.props.onFocus}
        disabled={this.props.disabled}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    inputLabel: templateHelper.getTranslation(ownProps.inputLabel),
    defaultLabel: templateHelper.getTranslation(ownProps.defaultLabel),
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateDropdown = connect(mapState)(ManagedDropdown);
