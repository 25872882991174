const MAX_WIDTH = 526;
const MAX_HEIGHT = 500;

export const getCropperSize = (imageWidth, imageHeight) => {
  if (imageWidth <= MAX_WIDTH && imageHeight <= MAX_HEIGHT) {
    return {
      width: imageWidth,
      height: imageHeight
    };
  }
  if (imageWidth >= imageHeight) {
    return {
      width: MAX_WIDTH,
      height: (MAX_WIDTH * imageHeight) / imageWidth
    };
  }
  return {
    width: (MAX_HEIGHT * imageWidth) / imageHeight,
    height: MAX_HEIGHT
  };
};

export const convertImageAndDrop = (path, file, onDrop, disableCropping) => {
  const image = new Image();
  image.onload = () => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (disableCropping) {
        onDrop(
          path,
          file.preview,
          file.name,
          image.width,
          image.height,
          file.type
        );
      } else {
        const cropperSize = getCropperSize(image.width, image.height);
        onDrop(
          path,
          file.preview,
          file.name,
          cropperSize.width,
          cropperSize.height,
          file.type
        );
      }
    };
  };
  image.src = file.preview;
};
