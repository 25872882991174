export const DONATIONS_METADATA_REQUESTED =
  'account-detail/DONATIONS_METADATA_REQUESTED';
export const DONATIONS_METADATA_SUCCESS =
  'account-detail/DONATIONS_METADATA_SUCCESS';
export const DONATIONS_METADATA_FAILURE =
  'account-detail/DONATIONS_METADATA_FAILURE';
export const DONATIONS_LIST_DATA_REQUESTED =
  'account-detail/DONATIONS_LIST_DATA_REQUESTED';
export const DONATIONS_LIST_DATA_SUCCESS =
  'account-detail/DONATIONS_LIST_DATA_SUCCESS';
export const DONATIONS_LIST_DATA_FAILURE =
  'account-detail/DONATIONS_LIST_DATA_FAILURE';
export const DONATIONS_TOGGLE_COLUMNS_CHANGE =
  'account-detail/DONATIONS_COLUMNS_CHANGE';

export const FUNDRAISING_METADATA_REQUESTED =
  'account-detail/FUNDRAISING_METADATA_REQUESTED';
export const FUNDRAISING_METADATA_SUCCESS =
  'account-detail/FUNDRAISING_METADATA_SUCCESS';
export const FUNDRAISING_METADATA_FAILURE =
  'account-detail/FUNDRAISING_METADATA_FAILURE';
export const FUNDRAISING_LIST_DATA_REQUESTED =
  'account-detail/FUNDRAISING_LIST_DATA_REQUESTED';
export const FUNDRAISING_LIST_DATA_SUCCESS =
  'account-detail/FUNDRAISING_LIST_DATA_SUCCESS';
export const FUNDRAISING_LIST_DATA_FAILURE =
  'account-detail/FUNDRAISING_LIST_DATA_FAILURE';
export const FUNDRAISING_TOGGLE_COLUMNS_CHANGE =
  'account-detail/TOGGLE_COLUMNS_CHANGE';

export const GET_DETAIL_REQUESTED = 'account-detail/GET_REQUESTED';
export const GET_DETAIL_SUCCESS = 'account-detail/GET_SUCCESS';
export const GET_DETAIL_FAILURE = 'account-detail/GET_FAILURE';

export const DONATION_SUMMARY_REPORT_REQUESTED =
  'account-detail/DONATION_SUMMARY_REPORT_REQUESTED';
export const DONATION_SUMMARY_REPORT_SUCCESS =
  'account-detail/DONATION_SUMMARY_REPORT_SUCCESS';
export const DONATION_SUMMARY_REPORT_FAILURE =
  'account-detail/DONATION_SUMMARY_REPORT_FAILURE';

export const TOTAL_AMOUNT_DONATED_REQUESTED =
  'account-detail/TOTAL_AMOUNT_DONATED_REQUESTED';
export const TOTAL_AMOUNT_DONATED_SUCCESS =
  'account-detail/TOTAL_AMOUNT_DONATED_SUCCESS';
export const TOTAL_AMOUNT_DONATED_FAILURE =
  'account-detail/TOTAL_AMOUNT_DONATED_FAILURE';

export const TOTAL_AMOUNT_FUNDRAISED_REQUESTED =
  'account-detail/TOTAL_AMOUNT_FUNDRAISED_REQUESTED';
export const TOTAL_AMOUNT_FUNDRAISED_SUCCESS =
  'account-detail/TOTAL_AMOUNT_FUNDRAISED_SUCCESS';
export const TOTAL_AMOUNT_FUNDRAISED_FAILURE =
  'account-detail/TOTAL_AMOUNT_FUNDRAISED_FAILURE';

export const DETAIL_STORE_CLEAR = 'account-detail/STORE_CLEAR';
export const ERROR_CLEAR = 'account-detail/ERROR_CLEAR';
export const ON_VALUE_CHANGE = 'account-detail/ON_VALUE_CHANGE';
export const DONATION_SUMMARY_MODAL_OPEN =
  'account-detail/DONATION_SUMMARY_MODAL_OPEN';

export const ACTIVE_TAB_SET = 'account-detail/ACTIVE_TAB_SET';