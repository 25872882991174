import { ReactNode } from 'react';
import { columnTypes } from './constants';

export class ColumnModel {
  column: {
    visible: boolean;
    width: number;
    fixed: boolean;
    // TODO - remove datatype or type from model once we determine which is needed
    // dataType is ONLY used in the constructor but type is used in setType(...)
    dataType: string;
    type: string;
    styles: {};
    uniqueId: string;
    path?: string | ((data?: any) => ReactNode);
    headerKey?: string;
    customFormatter?: (data: any) => any;
    linkInfo: {
      initial: boolean;
      isUrlInternal: boolean;
      getUrl?: () => string;
      isNewTab: boolean;
    };
  };

  constructor(
    path?: string | ((data?: any) => ReactNode),
    headerKey?: string,
    customFormatter?: (data: any) => any
  ) {
    this.column = {
      visible: false,
      width: 1,
      fixed: false,
      dataType: columnTypes.STRING,
      type: columnTypes.STRING,
      styles: {},
      uniqueId: '',
      path,
      headerKey,
      customFormatter,
      linkInfo: {
        initial: true,
        isUrlInternal: true,
        getUrl: undefined,
        isNewTab: false
      }
    };
  }

  setUniqueId(id: string) {
    this.column.uniqueId = id;
    return this;
  }

  setWidth(width: number) {
    this.column.width = width;
    return this;
  }

  setStyles(styles: object) {
    this.column.styles = styles;
    return this;
  }

  setFixed() {
    this.column.fixed = true;
    return this;
  }

  setType(type: string) {
    this.column.type = type;
    return this;
  }

  makeClickable(getUrl: () => string, isNewTab = false, isUrlInternal = true) {
    if (!getUrl) {
      return this;
    }

    this.column.linkInfo = {
      ...this.column.linkInfo,
      getUrl,
      isUrlInternal,
      isNewTab
    };

    return this;
  }

  setVisible(visible: boolean) {
    this.column.visible = visible;
    return this;
  }

  visibleByDefault() {
    this.column.visible = true;
    return this;
  }

  getColumn() {
    return this.column;
  }
}

export default ColumnModel;
