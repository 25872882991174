import { AnyAction } from '@reduxjs/toolkit';

import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import ColumnModel from '../../../components/common/grid/columnModel';
import {
  webhookColumnData,
  webhookDefaultOrderBy,
  webhooksAvailableFiltersKeys
} from '../../../constants/webhook';
import {
  WEBHOOKS_METADATA_REQUESTED,
  WEBHOOKS_METADATA_SUCCESS,
  WEBHOOKS_LIST_DATA_REQUESTED,
  WEBHOOKS_LIST_DATA_SUCCESS,
  WEBHOOKS_LIST_DATA_FAILURE,
  WEBHOOKS_MESSAGE_CLEAR,
  WEBHOOKS_METADATA_FAILURE,
  WEBHOOKS_CREATE_REQUESTED,
  WEBHOOKS_CREATE_SUCCESS,
  WEBHOOKS_CREATE_FAILURE,
  WEBHOOK_UPDATE_REQUESTED,
  WEBHOOK_UPDATE_SUCCESS,
  WEBHOOK_UPDATE_FAILURE,
  WEBHOOKS_DELETE_FAILURE,
  WEBHOOKS_DELETE_SUCCESS,
  WEBHOOKS_DELETE_REQUESTED,
  WEBHOOKS_GET_FAILURE,
  WEBHOOKS_GET_REQUESTED,
  WEBHOOKS_GET_SUCCESS
} from './webhookTypes';
import { Record } from '../../../modules/bin/utility';
import { CLEAR } from '../../../modules/general';

const initialState = {
  timestamp: new Date().getTime(),
  // prettier-ignore
  webhooksList: new GridOptions()
    .addColumns(webhookColumnData.map((x) => new ColumnModel(x.path, x.header, x.customFormatter).setVisible(x.defaultVisible)))
    .orderBy(webhookDefaultOrderBy.path, webhookDefaultOrderBy.order)
    .addAvailableFiltersKeys(webhooksAvailableFiltersKeys)
    .getState(),
  record: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  recordEdit: Record.getDefaultState()
};

// State Reducer
const webhookReducer = (state = initialState, action: AnyAction) => {
  let newState = state;
  switch (action.type) {
    case CLEAR:
    case WEBHOOKS_DELETE_SUCCESS:
      newState = initialState;
      break;

    case WEBHOOKS_METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case WEBHOOKS_METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case WEBHOOKS_LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case WEBHOOKS_GET_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case WEBHOOKS_LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case WEBHOOKS_LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case WEBHOOKS_MESSAGE_CLEAR:
      // prettier-ignore
      newState = entityHandlers.crudClearErrorHandler(state, action.payload.key);
      break;

    case WEBHOOKS_METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case WEBHOOKS_CREATE_REQUESTED:
    case WEBHOOK_UPDATE_REQUESTED:
    case WEBHOOKS_DELETE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case WEBHOOKS_CREATE_SUCCESS:
    case WEBHOOK_UPDATE_SUCCESS:
    case WEBHOOKS_GET_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case WEBHOOKS_CREATE_FAILURE:
    case WEBHOOKS_DELETE_FAILURE:
    case WEBHOOK_UPDATE_FAILURE:
    case WEBHOOKS_GET_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;
  }

  return newState;
};

export default webhookReducer;
