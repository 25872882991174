import React from 'react';
import { Translate } from 'react-redux-i18n';
import { DonationSubscriptionSettingsReceiptTypes, DonationSubscriptionSettingsTypes } from "../../../components/template/useDonationSubscriptionSettings";

const SettingsTypeViews = {
  [DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL]: (
    <Translate value="recurring.recurring-detail.behaviour.settings.type.each-donation" />
  ),
  [DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL_FIRST_RECEIPT]: (
    <Translate value="recurring.recurring-detail.behaviour.settings.type.first-donation" />
  )
}

const SettingsReceiptViews = {
  [DonationSubscriptionSettingsReceiptTypes.SEND_ALL]: (
    <Translate value="recurring.recurring-detail.behaviour.settings.receipt.all-donations" />
  ),
  [DonationSubscriptionSettingsReceiptTypes.SEND_ON_FIRST_DONATION_ONLY]: (
    <Translate value="recurring.recurring-detail.behaviour.settings.receipt.first-donation" />
  ),
  [DonationSubscriptionSettingsReceiptTypes.NO_RECEIPTS]: (
    <Translate value="recurring.recurring-detail.behaviour.settings.receipt.no-receipts" />
  )
}

export const ReceiptBehaviour = ({ campaign, settingsType, settingsReceipt }) => {
  if (!campaign && Object.keys(SettingsTypeViews).includes(settingsType)) {
    return SettingsTypeViews[settingsType];
  }
  else if (Object.keys(SettingsReceiptViews).includes(settingsReceipt)) {
    return SettingsReceiptViews[settingsReceipt];
  }
  return null;
}
