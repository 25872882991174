import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { TicketingStatus } from '../components';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import { toCurrency } from '../../../helpers/converterHelper';
import { useAppDispatch } from '../../../hooks';
import { AppDispatch, RootState } from '../../../store';
import { getCampaignRecord } from '../../donation/store/donationActions';
import { DonationFinancialsPlatformSectionRow } from '../../donation/view/components/donationFinancialsPlatformSectionRow';
import { OFFLINE_SALE_TYPE } from '../../campaign/constants';
import { getOrgRecord } from '../store/ticketingActions';

import CurrencyHelper from '../../../helpers/currencyHelper';
import { Record } from '../../../modules/bin/utility';

interface ITicketInvoiceTableProps {
  showEmptyFields: boolean;
  displayOrgCurrency: boolean;
}

export const TicketPaymentTable = ({
  showEmptyFields,
  displayOrgCurrency,
}: ITicketInvoiceTableProps) => {
  const dispatch: AppDispatch = useAppDispatch();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const session = useSelector((state: RootState) => state.session);
  const [isOrganizationLoaded, setIsOrganizationLoaded] = useState(false);
  const recordData = ticketing.record?.data?.data;
  const customPaymentPlatform = ticketing.relatedRecord?.data?.data?.paymentPlatform;
  const currency = displayOrgCurrency ? recordData?.organization.currency : recordData?.currency;
  const feeDetails = recordData?.financials?.feeDetail;
  const organization = Record.isRecordReady(ticketing.organizationRecord)
    ? ticketing.organizationRecord.data.data
    : {};

  let amount = recordData?.amount;
  let stripeFee = feeDetails?.paymentPlatformFee + feeDetails?.paymentPlatformFeeTax;
  let { fee, feeTax, saleTax } = feeDetails;

  if (displayOrgCurrency) {
    const exchangeRate = recordData?.financials.exchangeRate;

    amount *= exchangeRate;
    stripeFee *= exchangeRate;
    fee *= exchangeRate;
    feeTax *= exchangeRate;
    saleTax *= exchangeRate;
  }

  const type = recordData.type;
  const isOffline = type == OFFLINE_SALE_TYPE;

  const organizationPlatformsProps = useMemo(() => {
    const paymentPlatforms = organization.paymentPlatforms;

    if (paymentPlatforms) {
      return { paymentPlatforms };
    }

    return [];
  }, [organization]);

  const getOrganizationRecord = () => {
    const sale = ticketing.record.data.data;
    const organizationId = sale.organizationId;

    setIsOrganizationLoaded(true);
    dispatch(getOrgRecord(organizationId));
  };

  return (
    <ViewTable
      title={
        <Translate value="ticketing.edit.invoice-information.payment.label" />
      }
    >
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.amount.label"
        data={CurrencyHelper.formatCurrency(amount, currency)}
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.stripe.label"
        data={CurrencyHelper.formatCurrency(stripeFee, currency)}
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.tax.label"
        data={
          <>
            {CurrencyHelper.formatCurrency(fee, currency)} ({CurrencyHelper.formatCurrency(feeTax, currency)})
          </>
        }
      />
      <ViewSectionRow
        isShowEmptyFields={showEmptyFields}
        title="ticketing.edit.invoice-information.fee.sales-tax.label"
        data={CurrencyHelper.formatCurrency(saleTax, currency)}
      />
      {!isOffline && (
        <DonationFinancialsPlatformSectionRow
          title="ticketing.edit.invoice-information.gateway.label"
          data={recordData?.financials}
          isPaymentPlatformsLoaded={isOrganizationLoaded}
          getPaymentPlatforms={getOrganizationRecord}
          isSystemAdmin={session.isSystemAdmin}
          customPaymentPlatform={customPaymentPlatform}
          {...organizationPlatformsProps}
        />
      )}
    </ViewTable>
  );
};
