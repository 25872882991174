export const TOGGLE_COLUMNS_CHANGE = 'campaign/TOGGLE_COLUMNS_CHANGE';
export const LIST_ORDERING_CHANGE = 'campaign/LIST_ORDERING_CHANGE';

export const METADATA_REQUESTED = 'campaign/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'campaign/METADATA_SUCCESS';
export const METADATA_FAILURE = 'campaign/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'campaign/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'campaign/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'campaign/LIST_DATA_FAILURE';

export const CREATE_REQUESTED = 'campaign/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'campaign/CREATE_SUCCESS';
export const CREATE_FAILURE = 'campaign/CREATE_FAILURE';

export const CLONE_REQUESTED = 'campaign/CLONE_REQUESTED';
export const CLONE_SUCCESS = 'campaign/CLONE_SUCCESS';
export const CLONE_FAILURE = 'campaign/CLONE_FAILURE';

export const GET_REQUESTED = 'campaign/GET_REQUESTED';
export const GET_SUCCESS = 'campaign/GET_SUCCESS';
export const GET_FAILURE = 'campaign/GET_FAILURE';

export const UPLOAD_REQUESTED = 'campaign/UPLOAD_REQUESTED';
export const UPLOAD_SUCCESS = 'campaign/UPLOAD_SUCCESS';
export const UPLOAD_FAILURE = 'campaign/UPLOAD_FAILURE';

export const TOTAL_AMOUNT_FUNDRAISERS_REQUESTED =
  'campaign/TOTAL_AMOUNT_FUNDRAISERS_REQUESTED';
export const TOTAL_AMOUNT_FUNDRAISERS_SUCCESS =
  'campaign/TOTAL_AMOUNT_FUNDRAISERS_SUCCESS';
export const TOTAL_AMOUNT_FUNDRAISERS_FAILURE =
  'campaign/TOTAL_AMOUNT_FUNDRAISERS_FAILURE';

export const TOTAL_AMOUNT_TEAMS_REQUESTED =
  'campaign/TOTAL_AMOUNT_TEAMS_REQUESTED';
export const TOTAL_AMOUNT_TEAMS_SUCCESS = 'campaign/TOTAL_AMOUNT_TEAMS_SUCCESS';
export const TOTAL_AMOUNT_TEAMS_FAILURE = 'campaign/TOTAL_AMOUNT_TEAMS_FAILURE';

export const TOTAL_AMOUNT_COLLECTIONS_REQUESTED =
  'campaign/TOTAL_AMOUNT_COLLECTIONS_REQUESTED';
export const TOTAL_AMOUNT_COLLECTIONS_SUCCESS =
  'campaign/TOTAL_AMOUNT_COLLECTIONS_SUCCESS';
export const TOTAL_AMOUNT_COLLECTIONS_FAILURE =
  'campaign/TOTAL_AMOUNT_COLLECTIONS_FAILURE';

export const UPDATE_CAMPAIGN_STATUS_REQUESTED =
  'campaign/UPDATE_CAMPAIGN_STATUS_REQUESTED';
export const UPDATE_CAMPAIGN_STATUS_SUCCESS =
  'campaign/UPDATE_CAMPAIGN_STATUS_SUCCESS';
export const UPDATE_CAMPAIGN_STATUS_FAILURE =
  'campaign/UPDATE_CAMPAIGN_STATUS_FAILURE';

export const GET_EXPORT_FILE_REQUESTED = 'campaign/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'campaign/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'campaign/GET_EXPORT_FILE_FAILURE';

export const RESULT_SET = 'campaign/RESULT_SET';
export const RECORD_CLEAR = 'campaign/RECORD_CLEAR';
export const ERROR_CLEAR = 'campaign/ERROR_CLEAR';
export const GRID_TAB_CHANGE = 'campaign/GRID_TAB_CHANGE';
export const CREATE_WINDOW_SET_VISIBLE_STATUS =
  'campaign/CREATE_WINDOW_SET_VISIBLE_STATUS';
export const TEMPLATE_CHANGE = 'campaign/TEMPLATE_CHANGE';

export const CAMPAIGN_CLEAR = 'campaign/CLEAR';
export const ON_VALUE_CHANGE = 'campaign/ON_VALUE_CHANGE';

export const CREATE_CAMPAIGN_SUCCESS = 'campaign/CREATE_CAMPAIGN_SUCCESS';
export const CREATE_CAMPAIGN_FAILURE = 'campaign/CREATE_CAMPAIGN_FAILURE';

export const COMPLETE_CREATE_CAMPAIGN_REQUESTED =
  'campaign/COMPLETE_CREATE_CAMPAIGN_REQUESTED';
export const COMPLETE_CREATE_CAMPAIGN_SUCCESS =
  'campaign/COMPLETE_CREATE_CAMPAIGN_SUCCESS';
export const COMPLETE_CREATE_CAMPAIGN_FAILURE =
  'campaign/COMPLETE_CREATE_CAMPAIGN_FAILURE';

export const ADD_CUSTOM_CAMPAIGN_OPTION = 'campaign/ADD_CUSTOM_CAMPAIGN_OPTION';
