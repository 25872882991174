import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';

import newImage from '../../images/new-campaigns.svg';

import './savedEntityModal.css';

class SavedEntityModal extends Component {
  onClose = () => {
    if (this.props.onClose) {
      this.props.onClose();
    }
  };

  render() {
    const {
      open,
      header,
      content,
      buttons,
      customContent,
      isEdit,
      pageEditTitle
    } = this.props;
    const title = isEdit
      ? pageEditTitle || I18n.t(`template.page.saved-modal.title-edit`)
      : I18n.t(`template.page.saved-modal.title-create`);
    return (
      <Modal
        className="post-save wizard"
        size="tiny"
        dimmer="inverted"
        open={open}
      >
        <Modal.Header>{header || title}</Modal.Header>
        <Modal.Content>
          {!isEdit && <img src={newImage} alt="Cute Rocket" />}
          {content}
          <div className="button-content">{buttons}</div>
          {customContent}
          {isEdit && (
            <div className="link-content">
              <p
                className="Links"
                style={{ cursor: 'pointer' }}
                onClick={this.onClose}
              >
                {I18n.t('template.page.saved-modal.edit-button')}
              </p>
            </div>
          )}
        </Modal.Content>
      </Modal>
    );
  }
}

export default SavedEntityModal;
