import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CustomImageBlot from './custom-image-blot';
import CustomImageContainerBlot from './custom-image-container-blot';
import VideoContainerBlot from './custom-video-container-blot';
import CustomVideoBlot from './custom-video-blot';
import { entityTypes, imageTypes } from '../../../constants/imageUpload';
import {
  createVideoElement,
  createImageElement
} from '../../../helpers/quillHelper';
import './quill.css';
import { Progress, Form, Message } from 'semantic-ui-react';
import UploadImageService from '../../../modules/uploadEditorImage';

class HtmlEditor_With_Image_Upload extends React.Component {
  state = {
    imageUploadProgress: 0,
    showImageUploadProgress: false
  };

  constructor(props) {
    super(props);
    if (typeof window !== 'undefined') {
      let Quill = ReactQuill.Quill;
      var Parchment = Quill.import('parchment');
      var Block = Parchment.query('block');
      Block.tagName = 'DIV';
      Quill.register(Block, true);
      Quill.register(CustomImageBlot);
      Quill.register(CustomImageContainerBlot);
      Quill.register(CustomVideoBlot);
      Quill.register(VideoContainerBlot);
    }
  }

  onUploadStatusChange = (progress) => {
    if (progress >= 100) {
      this.setState({
        showImageUploadProgress: false,
        imageUploadProgress: progress
      });
    } else {
      this.setState({
        showImageUploadProgress: true,
        imageUploadProgress: progress
      });
    }
  };

  onHtmlChange = (value) => {
    if (this.reactQuillRef) {
      const contents = this.reactQuillRef.getEditor().getContents().ops;
      let alphanumericCharactersExist = contents.some((elem) => {
        return /[\w]+/.test(elem.insert);
      });
      let empty = !alphanumericCharactersExist;
      if (this.props.onChange) {
        this.props.onChange(empty ? '' : value);
      }
    }
  };

  insertImageAsHtml(editor, imgPath) {
    let range = editor.getSelection(true);
    editor.insertEmbed(
      range.index,
      'custom-image-container',
      createImageElement(imgPath)
    );
    editor.setSelection(range.index + 1);
  }

  imageHandler = () => {
    const editor = this.reactQuillRef.getEditor();
    const token = this.props.sessionKey;
    let fileInput = editor.container.querySelector('input.ql-image[type=file]');
    //creating file input in case one does not exist already
    if (fileInput === null) {
      fileInput = document.createElement('input');
      fileInput.setAttribute('type', 'file');
      fileInput.setAttribute(
        'accept',
        'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
      );
      fileInput.classList.add('ql-image');
      fileInput.addEventListener('change', () => {
        if (
          fileInput.files !== null &&
          fileInput.files.length &&
          fileInput.files[0] !== null
        ) {
          UploadImageService.upload(
            entityTypes.PAGE,
            imageTypes.ASSET,
            fileInput.files[0],
            token,
            this.onUploadStatusChange
          ).then((response) => {
            this.insertImageAsHtml(editor, response.data.data);
            fileInput.value = '';
          });
        }
      });
      editor.container.appendChild(fileInput);
      fileInput.hidden = true;
    }
    fileInput.click();
  };

  videoHandler = function () {
    let url = prompt('Enter video URL');
    //getting caret location
    let range = this.quill.getSelection(true);
    //inserting embeded element
    this.quill.insertEmbed(
      range.index,
      'custom-video-container',
      createVideoElement(url)
    );
    //setting the caret at position after inserted element
    this.quill.setSelection(range.index + 1);
  };

  modules = {
    toolbar: {
      container: [['bold'], ['link', 'image', 'video']],
      handlers: {
        image: this.imageHandler,
        video: this.videoHandler
      }
    }
  };

  render() {
    return (
      <Form.Field
        required={this.props.validation && this.props.validation.required}
      >
        <label>{this.props.label}</label>
        <ReactQuill
          ref={(el) => {
            this.reactQuillRef = el;
          }}
          modules={this.modules}
          value={this.props.value || ''}
          onChange={this.onHtmlChange}
        />
        {this.state.showImageUploadProgress && (
          <Progress
            indicating
            percent={this.state.imageUploadProgress}
            size="small"
            label="Uploading image"
          />
        )}
        {this.props.subtext && (
          <div className="input-sub">{this.props.subtext}</div>
        )}
        <Message negative hidden={!this.props.validationErrorMessage}>
          <p>{this.props.validationErrorMessage}</p>
        </Message>
      </Form.Field>
    );
  }
}

export default HtmlEditor_With_Image_Upload;
