import React from 'react';

import { Translate } from 'react-redux-i18n';

import { Modal } from 'semantic-ui-react';
import LoginForm from '../authentication/loginForm';

interface SessionErrorModal {
  show: boolean;
  session: any;
  onSessionRefresh: (id: any, refresh: any) => void;
}

const SessionErrorModal = ({
  show,
  session,
  onSessionRefresh
}: SessionErrorModal) => {
  if (show) {
    if (session.refresh) {
      onSessionRefresh(session.account.id, session.refresh);
      return null;
    }

    return (
      <Modal size="tiny" dimmer="blurring" closeIcon open>
        <Modal.Content>
          <LoginForm
            header={<Translate value="general.session-expired-header" />}
          />
        </Modal.Content>
      </Modal>
    );
  }
  return <div></div>;
};

export default SessionErrorModal;
