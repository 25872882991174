import { updateListItem } from '../../../modules/bin/entityHandlers';
import update from '../../../helpers/update';

export const updateRecord = (state, key, itemId, model) => {
  if (key) {
    const newModel = updateListItem(state, itemId, key, model);
    if (newModel) {
      state = update(state, { [key]: newModel });
    }
  }
  return state;
};

export const updatePriorityRecord = (state, key, itemId, value) => {
  return updateRecord(state, key, itemId, {
    priority: { $set: value }
  });
};
