import get from 'lodash.get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import clear from '../../../components/clear';
import BasePageView from '../../../components/common/basePageView';
import { Record } from '../../../modules/bin/utility';
import { getTabFromAccountTypes } from '../constants';
import AccountDetailHeaderContainer from './header';
import * as accountActions from './store/accountDetailActions';
import AccountDetailDonationsContainer from './table';
;

class AccountDetailPage extends Component {
  componentDidUpdate() {
    if (Record.isSuccess(this.props.impersonatedFundraiserRecord)) {
      const code = get(this.props.impersonatedFundraiserRecord, 'data');
      const impersonatedFundraiserUrlFull = get(
        this.props,
        'impersonatedFundraiserUrlFull'
      );

      window.open(
        `${impersonatedFundraiserUrlFull}/impersonate?code=${code}`,
        '_blank'
      );

      this.props.accountActions.clearRecordMessages(
        'impersonatedFundraiserRecord'
      );
    }
  }

  componentDidMount() {
    const accountId = this.props.match.params.id;
    this.props.accountActions.clearStore();
    this.props.accountActions.getTotalAmountDonated(accountId);
    this.props.accountActions.getTotalAmountFundraised(accountId);
    this.props.accountActions.getRecord(accountId);
  }

  getAccountsTabUrl() {
    if (this.props.record.data.data) {
      const account = this.props.record.data.data;
      return getTabFromAccountTypes(account);
    }
  }

  render() {
    return (
      <BasePageView
        isLoading={this.props.isLoading}
        isLoadingError={this.props.isLoadingError}
        backLink={`/accounts/${this.getAccountsTabUrl()}`}
        backLinkTitle="Back to accounts"
      >
        <div className="account-detail">
          <AccountDetailHeaderContainer />
          <AccountDetailDonationsContainer />
        </div>
      </BasePageView>
    );
  }
}

const mapState = ({ accountDetail, account }) => {
  return {
    isLoading:
      Record.isRecordLoading(accountDetail.detailRecord) ||
      Record.isRecordLoading(accountDetail.totalDonatedRecord) ||
      Record.isRecordLoading(accountDetail.totalFundraisingRecord) ||
      Record.isRecordLoading(accountDetail.impersonatedFundraiserRecord),
    isLoadingError: Record.isRecordLoadingError(accountDetail.detailRecord),
    record: accountDetail.detailRecord,
    impersonatedFundraiserRecord: accountDetail.impersonatedFundraiserRecord,
    impersonatedFundraiserUrlFull:
      accountDetail.impersonateFundraiser.fundraiserUrlFull
  };
};

const mapDispatch = (dispatch) => {
  return {
    accountActions: bindActionCreators(accountActions, dispatch)
  };
};

const AccountDetailPageContainer = withRouter(
  clear(connect(mapState, mapDispatch)(AccountDetailPage))
);
export default AccountDetailPageContainer;
