import React, { Component } from 'react';

import NumberFormat from 'react-number-format';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';

class FormbuilderCurrency extends Component {
  isAllowed = (values) => {
    const { floatValue } = values;
    if (this.props.maxValue)
      return floatValue ? floatValue <= this.props.maxValue : true;
    return true;
  };

  render() {
    const {
      onChange,
      value,
      disabled,
      inputLabel,
      placeholder,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;

    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <div className={`ui ${disabled ? 'disabled' : ''} labeled input`}>
          <div className={`ui label`}>{inputLabel || '$'}</div>
          <NumberFormat
            allowNegative={false}
            decimalScale={2}
            disabled={disabled}
            value={value || ''}
            isAllowed={this.isAllowed}
            onChange={onChange}
            placeholder={placeholder}
          />
        </div>
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderCurrency;
