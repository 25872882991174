import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Common } from '../../components';
import get from 'lodash.get';
import * as donationActions from './store/donationActions';
import { bindActionCreators } from 'redux';
import { Button } from 'semantic-ui-react';

import Panel from '../../components/common/grid/managed/panel';
import { Record } from '../../modules/bin/utility';
import Spinner from '../../components/common/spinner';
import RecordResultMessage from '../../components/common/recordResult';
import BasePage from '../../components/common/basePageView';
import clear from '../../components/clear';
import ContextMenu from '../../components/common/grid/contextMenu';
import RefundWindowContainer from './refund-window';
import ReceiptWindowContainer from './receipt-window';
import {
  ONLINE_DONATION_TYPE,
  OFFLINE_DONATION_TYPE,
  PLEDGE_DONATION_TYPE
} from '../campaign/constants';
import { gridKeys } from '../../constants/gridKeys';
import { hideCondition } from '../../helpers/hideHelper';
import { getListWithOrgFilterIfSysAdmin } from '../../helpers/listHelper';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { filterLookupBaseOptions } from './metadataConstants';
import { setRedirectUrl } from '../../modules/session';
import donationType from '../../constants/donationType';
import { ExportDataButton } from '../../components/template/templateExportButton';
import { ExportDownloadProvider } from '../export-history';

class DonationList extends Component {
  onViewContextClick = (id, _item) => {
    this.props.history.push(`/donations/view/${id}`);
  };

  onRefundContextClick = (_id, item) => {
    this.props.showRefundConfirmation(item);
  };

  onReissueContextClick = (_id, item) => {
    if (item.donor.email) {
      this.props.showReceiptConfirmation(item);
    } else {
      this.props.getReceiptMessageError('saveRecord');
    }
  };

  isReIssueReceiptDisabled = (item) => {
    return item?.type === donationType.Pending;
  };

  onTransferDonationContextClick = (id, _item) => {
    this.props.history.push(`/donation/${id}/transfer/setup`);
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  onAddOfflineDonation = () => {
    this.props.history.push('/donations/create/donation-detail');
  };

  hideRefundButton = (item) => {
    return item.type !== ONLINE_DONATION_TYPE;
  };

  hideDeleteButton = (item) => {
    return (
      item.type !== OFFLINE_DONATION_TYPE && item.type !== PLEDGE_DONATION_TYPE
    );
  };

  hideTransferDonationButton = (item) => {
    if (this.props.isSystemAdmin) return false;
    return item.financials.refunded || item.eventId !== null;
  };

  hideViewButton = (_item) => {
    return !this.props.isSystemAdmin;
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  render() {
    if (
      Record.isRecordLoading(this.props.exportFileRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord)
    ) {
      return <Spinner />;
    }

    const { Grid } = Common;

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <RefundWindowContainer />
        <ReceiptWindowContainer />

        <div>
          <div className="grid-top-block">
            <h1>
              <Translate value="donation.title" />
            </h1>
          </div>
          <Grid.Managed
            listKey={gridKeys.DONATIONS}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.list,
              this.props.isSystemAdmin
            )}
            toggleColumns
            actions={this.props.actionGrid}
            search
            filtersButton
            searchVersion="v3"
            advancedFilterButton
            hideColumn={this.hideColumnCondition}
            filterLookupBaseOptions={filterLookupBaseOptions}
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    featureFlag
                    onExportClick={(version) => {
                      let additionalData = {};
                      if (version === 'v3') {
                        additionalData.layout = 'Extended';
                      } else if (version === 'v4') {
                        additionalData.layout = 'FundraiserData';
                      }

                      open({
                        ...additionalData,
                        ...(this.props.list.request || {})
                      });
                    }}
                  />
                )}
              </ExportDownloadProvider>
              <Button
                onClick={this.onAddOfflineDonation}
                id="add-offline-donation"
              >
                <Translate value="campaign.list.view.add-offline-donation" />
              </Button>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DONATIONS.VIEW_DONATION}
              />
              <ContextMenu.Item
                id="editDonationContextMenu"
                key={CONTEXT_MENU_KEYS.DONATIONS.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={CONTEXT_MENU_KEYS.DONATIONS.EDIT_DONATION}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundContextClick}
                isDisabled={this.hideRefundButton}
                disabledPopupProps={{
                  content: (
                    <Translate value="donation.list.context.refund-donation-disabled" />
                  ),
                  position: 'bottom center'
                }}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.hideDeleteButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueContextClick}
                isDisabled={this.isReIssueReceiptDisabled}
                disabledPopupProps={{
                  content: (
                    <Translate value="donation.reissue-receipt.disabled-pending" />
                  ),
                  position: 'bottom center'
                }}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.DONATIONS.TRANSFER_DONATION.key}
                label={I18n.t('donation.list.context.transfer-donation')}
                onClick={this.onTransferDonationContextClick}
                isHiden={this.hideTransferDonationButton}
              />
            </ContextMenu>
          </Grid.Managed>
        </div>
      </BasePage>
    );
  }
}

const mapState = ({ donation, session, account }) => {
  const list = get(donation, gridKeys.DONATIONS);

  return {
    list: list,
    exportFileRecord: donation.exportFileRecord,
    impersonatedRecord: account.impersonatedRecord,
    saveRecord: donation.saveRecord,
    refundRecord: donation.refundDonation,
    refundConfirmation: donation.refundConfirmation,
    reissueReceiptConfirmation: donation.reissueReceiptConfirmation,
    receiptDonation: donation.receiptDonation,
    isSystemAdmin: session.isSystemAdmin,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin')
  };
};

const mapDispatch = (dispatch) => {
  const actionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    donationActions
  );
  return {
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    actionGrid,
    ...bindActionCreators(donationActions, dispatch)
  };
};

const DonationsListContainer = clear(
  connect(mapState, mapDispatch)(DonationList)
);
export default DonationsListContainer;
