import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { cloneDeep } from 'lodash';

import * as templateHelper from '../../template/templateHelper';
import * as validationHelper from '../../../helpers/validationHelper';
import { stringToArray } from '../../../helpers/arrayHelper';

import { Form } from '../..';
import { TemplateHeader } from '../../template/templateHeader';
import { Message } from 'semantic-ui-react';

import {
  fitnessMeasure,
  fitnessMeasureOptions,
  fitnessMeasureUnit
} from '../../../pages/campaign/constants';
import { campaignContract } from '../../../pages/campaign/create/templates';

class FitnessThermometersToShowContainer extends Component {
  isFitnessThermometersToShowOptionChecked = (option) => {
    return this.props.value.some((item) => item === option);
  };

  isDefaultFundraiserAndTeamTargetsVisible = (option, visible) => {
    const show = [];
    const hide = [];

    if (option === fitnessMeasure.distance) {
      if (visible && this.props.isPageTrackingAllowed) {
        show.push(
          ...['fitnessFundraiserDistanceTarget', 'fitnessTeamDistanceTarget']
        );
      }
      if (!visible || !this.props.isPageTrackingAllowed) {
        hide.push(
          ...['fitnessFundraiserDistanceTarget', 'fitnessTeamDistanceTarget']
        );
      }
    }

    if (option === fitnessMeasure.time) {
      if (visible && this.props.isPageTrackingAllowed) {
        show.push(...['fitnessFundraiserTimeTarget', 'fitnessTeamTimeTarget']);
      }

      if (!visible || !this.props.isPageTrackingAllowed) {
        hide.push(...['fitnessFundraiserTimeTarget', 'fitnessTeamTimeTarget']);
      }
    }

    if (option === fitnessMeasure.numberOfSteps) {
      if (visible && this.props.isPageTrackingAllowed) {
        show.push(
          ...[
            'fitnessFundraiserNumberOfStepsTarget',
            'fitnessTeamNumberOfStepsTarget'
          ]
        );
      }

      if (!visible || !this.props.isPageTrackingAllowed) {
        hide.push(
          ...[
            'fitnessFundraiserNumberOfStepsTarget',
            'fitnessTeamNumberOfStepsTarget'
          ]
        );
      }
    }

    if (option === fitnessMeasure.reps) {
      if (visible && this.props.isPageTrackingAllowed) {
        show.push(...['fitnessFundraiserRepsTarget', 'fitnessTeamRepsTarget']);
      }

      if (!visible || !this.props.isPageTrackingAllowed) {
        hide.push(...['fitnessFundraiserRepsTarget', 'fitnessTeamRepsTarget']);
      }
    }

    show.forEach((x) => this.props.onChangeItemVisibility(x, true, true));
    hide.forEach((x) => this.props.onChangeItemVisibility(x, false, false));
  };

  handleFitnessThermometersToShowOptionChange = (value, evt, { checked }) => {
    const fitnessThermometersToShow = cloneDeep(this.props.value);
    const fitnessTargets = cloneDeep(this.props.targets);
    const fitnessTargetsIndex = fitnessTargets.findIndex(
      (item) => item.key === value
    );

    if (checked) {
      fitnessThermometersToShow.push(value);

      if (fitnessTargetsIndex === -1) {
        fitnessTargets.push({
          key: value,
          target: null,
          fundraiserDefaultTarget: null,
          teamDefaultTarget: null
        });
      } else {
        fitnessTargets[fitnessTargetsIndex].target = null;
      }
    } else {
      const fitnessThermometersToShowIndex =
        fitnessThermometersToShow.findIndex((item) => item === value);

      if (fitnessThermometersToShowIndex !== -1) {
        fitnessThermometersToShow.splice(fitnessThermometersToShowIndex, 1);
      }
      if (fitnessTargetsIndex !== -1) {
        fitnessTargets[fitnessTargetsIndex].target = null;
      }
    }

    this.props.onChange(
      campaignContract.fitnessThermometersToShow,
      fitnessThermometersToShow
    );
    this.props.onChange(this.props.path, fitnessTargets);
  };

  handleTargetChange = (option, evt) => {
    let fitnessTargets = cloneDeep(this.props.targets);
    const fitnessTargetIndex = fitnessTargets.findIndex(
      (item) => item.key === option
    );

    if (fitnessTargetIndex !== -1) {
      fitnessTargets[fitnessTargetIndex].target = evt.target.value;
    }

    this.props.onChange(this.props.path, fitnessTargets);
  };

  getFitnessThermometersToShowOptions = () => {
    return fitnessMeasureOptions.map((option) => {
      const isChecked = this.isFitnessThermometersToShowOptionChecked(
        option.value
      );
      const fitnessTarget = this.props.targets.find(
        (item) => item.key === option.value
      );
      const isVisible =
        option.isVisible(stringToArray(this.props.activities)) &&
        stringToArray(this.props.trackedStatistics).some(
          (item) => item === option.value
        );

      this.isDefaultFundraiserAndTeamTargetsVisible(option.value, isVisible);

      return (
        <React.Fragment
          key={option.key}
        >
          {isVisible && (
            <React.Fragment>
              <Form.Checkbox
                key={option.key}
                inputLabel={I18n.t(
                  `template.page.content.fitness.fitnessThermometersToShow.options.${option.i18nKey}`
                )}
                checked={isChecked}
                onChange={this.handleFitnessThermometersToShowOptionChange.bind(
                  this,
                  option.value
                )}
              />
              {isChecked && (
                <div className={'padding-left-style'}>
                  <Form.NumberInput
                    style={{ width: '184px' }}
                    label={I18n.t(
                      `template.page.content.fitness.fitnessThermometersToShow.targets.${option.i18nKey}`
                    )}
                    inputLabel={fitnessMeasureUnit[option.value]}
                    required
                    onChange={this.handleTargetChange.bind(this, option.value)}
                    value={fitnessTarget ? fitnessTarget.target : ''}
                  />
                </div>
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      );
    });
  };

  getIsValid = () => {
    let isValid = true;
    if (this.props.value && this.props.value.length > 0) {
      const optionalParam =
        templateHelper.getValue(
          this.props.state.templateBuilderEx,
          this.props.validation.optionalParamPath
        ) || [];

      isValid = validationHelper.isValid(
        this.props.validation,
        this.props.targets,
        optionalParam
      );
    }
    return isValid;
  };

  render() {
    const fitnessThermometersToShowOptions =
      this.getFitnessThermometersToShowOptions();

    const isValid = this.getIsValid();

    return (
      <React.Fragment>
        <TemplateHeader
          as="h4"
          label="template.page.content.fitness.fitnessThermometersToShow.label"
        />
        {fitnessThermometersToShowOptions}
        {!isValid && (
          <Message negative hidden={isValid}>
            <p>{this.props.errorMessage}</p>
          </Message>
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const targets =
    templateHelper.getValue(state.templateBuilderEx, ownProps.path) || [];
  const activities = templateHelper.getValue(
    state.templateBuilderEx,
    campaignContract.fitnessAllowedTypes
  );

  return {
    value:
      templateHelper.getValue(
        state.templateBuilderEx,
        campaignContract.fitnessThermometersToShow
      ) || [],
    targets: targets,
    isPageTrackingAllowed: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.allowPageTracking
    ),
    activities: activities,
    trackedStatistics: templateHelper.getValue(
      state.templateBuilderEx,
      campaignContract.fitnessAllowedTrackedStatistics
    ),
    validation: ownProps.validation,
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      targets
    ),
    state: state
  };
};

const FitnessThermometersToShow = connect(mapState)(
  FitnessThermometersToShowContainer
);
export default FitnessThermometersToShow;
