//---------------React/Redux
import React from 'react';

//---------------Components
import { Form } from 'semantic-ui-react';
import { AboutElementTypes } from '../constants/aboutElementType';
import AboutVideoAdd from './aboutVideoAdd';
import AboutImageUpload from './aboutImageUpload';
import AboutPDFUpload from './aboutPDFUpload';
import AboutImageGalleryUpload from './aboutImageGalleryUpload';
import AboutGoogleMap from './aboutGoogleMap';

export class AboutElement extends React.Component {
  state = {
    prevValue: ''
  };

  onRemove = () => {
    this.props.onChange(this.props.index, '');
  };

  onElementValueChanged = (value) => {
    this.props.onChange(this.props.index, value);
  };

  onTextChanged = (event) => {
    this.props.onChange(this.props.index, event.target.value);
  };

  render() {
    return (
      <div className="custom-mediaselector-container">
        {this.props.type === AboutElementTypes.Text && (
          <Form.Field>
            <textarea
              id={this.props.id}
              value={this.props.value}
              onChange={this.onTextChanged}
            ></textarea>
          </Form.Field>
        )}
        {this.props.type === AboutElementTypes.Image && (
          <AboutImageUpload
            value={this.props.value}
            guid={this.props.guid}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
            sessionKey={this.props.sessionKey}
            entity={this.props.entity}
          />
        )}
        {this.props.type === AboutElementTypes.Video && (
          <AboutVideoAdd
            value={this.props.value}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
          />
        )}
        {this.props.type === AboutElementTypes.PDF && (
          <AboutPDFUpload
            value={this.props.value}
            guid={this.props.guid}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
            sessionKey={this.props.sessionKey}
            entity={this.props.entity}
          />
        )}
        {this.props.type === AboutElementTypes.ImageGallery && (
          <AboutImageGalleryUpload
            value={this.props.value}
            guid={this.props.guid}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
            sessionKey={this.props.sessionKey}
            entity={this.props.entity}
          />
        )}
        {this.props.type === AboutElementTypes.GoogleMap && (
          <AboutGoogleMap
            value={this.props.value}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
          />
        )}
      </div>
    );
  }
}
export default AboutElement;
