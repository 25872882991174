import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';

import * as ticketingActions from '../store/ticketingActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { formBuilderActions } from '../../../components/formbuilder/store';

import clear from '../../../components/clear';
import { Record } from '../../../modules/bin/utility';
import update from '../../../helpers/update';

import BasePage from '../../../components/common/basePageView';
import RecordResultMessage from '../../../components/common/recordResult';
import { TemplateOptions } from '../../../components/template/templateOptions';
import Spinner from '../../../components/common/spinner';

import { saleContract, getTicketContract } from '../ticketingContracts';
import TicketingTemplate from '../template';
import { campaignContract } from '../../campaign/create/templates';
import { defaultDetails } from '../constants';
import { grecaptcha } from '../../../helpers/grecaptcha';

class CreateTicketing extends Component {
  constructor(props) {
    super(props);
    const campaignId = this.props.match.params.campaignId;
    if (campaignId) {
      props.getCampaignRecord(campaignId);
    }
  }

  onSave = (data) => {
    const tokenCallback = (token) =>
      this.props.createRecord(data, token);
    grecaptcha(tokenCallback, "Sale");
  };

  goToSales = () => {
    this.props.history.push(`/sales`);
  };

  initTemplateBuilderModel = () => {
    const campaignId = parseInt(this.props.match.params.campaignId);
    const campaign = get(this.props, 'record.data.data');
    const formBuilder = get(campaign, saleContract.formbuilder);

    if (!this.props.isTemplateInited) {
      let sale = {};
      sale = update.set(sale, saleContract.details, defaultDetails);

      const model = new TemplateOptions()
        .setModel(sale)
        .setValue(saleContract.oneClickDonating, false)
        .setValue(saleContract.oneClickFundraising, false)
        .hide(saleContract.amount)
        .hide(saleContract.shippingInformation)
        .hide(saleContract.shippingCost)
        .hide(saleContract.shippingType)
        .hide('messageOfSupportHeader');

      if (campaignId) {
        model.disable(saleContract.campaignId);
        model.setValue(saleContract.campaignId, campaignId);
        model.setValue('campaign', campaign);

        const purchaseFundraisingIsMandatory = get(
          campaign,
          campaignContract.purchaseFundraisingIsMandatory
        );
        if (purchaseFundraisingIsMandatory) {
          model.setValue(saleContract.oneClickFundraising, true);
        }
      }

      if (sale.details && sale.details.length > 0) {
        for (let index = 0; index < sale.details.length; index++) {
          const ticketContract = getTicketContract(index);
          model.setValue(ticketContract.campaignId, campaignId);
          model.hide(ticketContract.campaignId);
          model.hide(ticketContract.amount);
          model.hide(ticketContract.ticketName);
        }
      }

      if (formBuilder) {
        model.setValue(saleContract.formbuilder, formBuilder);
      }

      this.props.templateBuilderActions.setTemplateModel(model.get());
    }
  };

  isLoading = () => {
    const campaignId = this.props.match.params.campaignId;

    const isCampaignLoading = Record.isRecordLoading(this.props.record);
    const isCampaignNotStarted = campaignId
      ? Record.notStarted(this.props.record)
      : false;

    return isCampaignLoading || isCampaignNotStarted;
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  render() {
    const isLoading = this.isLoading();
    const isSaving = Record.isRecordLoading(this.props.saveRecord);
    const isSuccess = Record.isSuccess(this.props.saveRecord);

    if (isLoading) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToSales();
    }

    if (!this.props.isTemplateInited) {
      const campaignId = parseInt(this.props.match.params.campaignId);
      if (campaignId) {
        if (Record.isRecordReady(this.props.record)) {
          this.initTemplateBuilderModel();
        }
      } else {
        this.initTemplateBuilderModel();
      }
    }

    return (
      <div className="sale-detail">
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />
        <h1>
          <Translate value="ticketing.create.title" />
        </h1>
        <BasePage>
          <TicketingTemplate onSave={this.onSave} isLoading={isSaving} />
        </BasePage>
      </div>
    );
  }
}

const mapState = ({ ticketing, templateBuilderEx }) => {
  return {
    record: get(ticketing, 'record'),
    saveRecord: get(ticketing, 'saveRecord'),
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(ticketingActions, dispatch);
  dispatchers.templateBuilderActions = bindActionCreators(
    templateBuilderActions,
    dispatch
  );
  dispatchers.formBuilderActions = bindActionCreators(
    formBuilderActions,
    dispatch
  );

  return dispatchers;
};

const CreateTicketingContainer = withRouter(
  clear(connect(mapState, mapDispatch)(CreateTicketing))
);
export default CreateTicketingContainer;
