let VideoContainerBlotExport;

if (typeof document !== 'undefined') {
  let ReactQuill = require('react-quill');
  let BlockEmbed = ReactQuill.Quill.import('blots/inline');

  VideoContainerBlotExport = class VideoContainerBlot extends BlockEmbed {
    static create(value) {
      let node = super.create();
      if (value.className) {
        node.appendChild(value);
      }
      return node;
    }

    static value(domNode) {
      return domNode;
    }
  };
  VideoContainerBlotExport.blotName = 'custom-video-container';
  VideoContainerBlotExport.tagName = 'div';
  VideoContainerBlotExport.className = 'content-video-cont';
}

export default VideoContainerBlotExport;
