import React from 'react';
import GeneralFormatter from './generalFormatter';
import DateTimeFormatter from './dateTimeFormatter';
import DateFormatter from './dateFormatter';
import CurrencyFormatter from './currencyFormatter';
import NumberFormatter from './numberFormatter';
import { columnTypes } from '../constants';
import CapitalizeText from '../../capitalizeText';
import DistanceFormatter from './distanceFormatter';
import TimeFormatter from './timeFormatter';
import BadgeFormatter from './badgeFormatter';
import '../../../../helpers/stringExstension';

const Formatter = (value, props) => {
  if (props.customFormatter) {
    return <GeneralFormatter value={props.customFormatter(value)} {...props} />;
  }

  switch (props.datatype) {
    case columnTypes.DATETIME:
      return <DateTimeFormatter value={value} {...props} />;

    case columnTypes.DATE:
      return <DateFormatter value={value} {...props} />;

    case columnTypes.DECIMAL:
      // TODO: Review decimal formatter
      return <CurrencyFormatter value={value} {...props} />;

    case columnTypes.INT32:
      return <NumberFormatter value={value} {...props} />;

    case columnTypes.BOOLEAN:
      const text =
        value !== null &&
        value !== undefined &&
        typeof value === 'boolean' &&
        value === true
          ? 'Yes'
          : 'No';

      return <GeneralFormatter value={text} {...props} />;

    case columnTypes.CAPITALIZE_STRING:
      return <CapitalizeText>{value}</CapitalizeText>;

    case columnTypes.DISTANCE:
      return <DistanceFormatter value={value} {...props} />;

    case columnTypes.TIME:
      return <TimeFormatter value={value} {...props} />;

    case columnTypes.BADGE:
      return <BadgeFormatter value={value} {...props} />;

    default:
      return <GeneralFormatter value={value} {...props} />;
  }
};

export default {
  Formatter,
  GeneralFormatter,
  DateTimeFormatter,
  DateFormatter,
  CurrencyFormatter
};
