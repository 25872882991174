import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, Form, Message } from 'semantic-ui-react';
import { Form as CommonForm } from '../../../../components';
import { CampaignLookup } from '../../../../components/campaign/lookup/CampaignLookup';
import DateFormatter from '../../../../components/common/grid/cells/dateFormatter';
import { ListTable } from '../../../../components/template/listTable/ListTable';
import { resetObject } from '../../../../helpers/objectHelper';
import {
  IActiveIntegrationFundraiser,
  IActiveIntegrationFundraiserSetup
} from '../../../../models/ActiveIntegration';
import { RootState } from '../../../../store';
import {
  fundraiserColumns,
  fundraiserKey,
  requiredField
} from '../../constants/from';
import {
  compareStrings,
  eventCampaignDisplayValue,
  extractCampaign,
  generateLink,
  getDispalyInstruction,
  onDataFieldChange
} from '../helper';
import { DataForm, IDataFormChildProps } from './DataForm';

export const fundraiserIntructionTypes = [
  {
    text: 'Preselected Campaign',
    value: 'preselectedCampaign'
  },
  {
    text: 'Add Account And Invite To Fundraise',
    value: 'addAccountAndInviteToFundraise'
  }
];

const generateFundraiserValues = (data?: any[]) =>
  data?.map((x) => ({
    hidden: !x.enabled,
    data: [
      x.question,
      x.answer,
      getDispalyInstruction(fundraiserIntructionTypes, x.instruction),
      generateLink(x.campaign),
      <DateFormatter value={x.createdAt} />
    ]
  })) || [];

interface IFundraiserDataFormProps extends IDataFormChildProps {
  eventId: string | number;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  onDelete: (index: number) => void;
}

export const FundraiserDataForm = ({
  eventId,
  showForm,
  setShowForm,
  onSave,
  onDelete
}: IFundraiserDataFormProps) => {
  const [formData, setFormData] = useState({} as IActiveIntegrationFundraiser);
  const template = useSelector((state: RootState) => state.templateBuilderEx);
  const activeIntegration = (template?.data as any)?.activeIntegration;

  const [canSave, setCanSave] = useState(false);
  const [uniqueError, setUniqueError] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [showCampaign, setShowCampaign] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  // prettier-ignore
  const fundraiserSetup = activeIntegration?.fundraiserSetup as IActiveIntegrationFundraiserSetup;

  const onCampaignChange = (event?: any, data?: any) => {
    let campaignData = data;
    if (!data?.value || !event?.target) {
      campaignData = { name: 'campaignId', value: null };
    }
    onChange(event, campaignData);
  };

  const onChange = (event: any, data: any) => {
    let uniqueErrorTemp = false;
    const newFormData = {
      ...formData,
      ...onDataFieldChange(event, data)
    };

    // prettier-ignore
    const showCampaignTemp = newFormData.instruction === fundraiserIntructionTypes[0].value;

    if (newFormData.question && newFormData.answer) {
      const matchedDonations = fundraiserSetup?.settings?.filter(
        (x, i) =>
          i !== itemIndex &&
          x.enabled &&
          compareStrings(x.question, newFormData.question) &&
          compareStrings(x.answer, newFormData.answer)
      );
      uniqueErrorTemp = matchedDonations?.length > 0;
    }

    let canSaveTemp = !!(
      !uniqueErrorTemp &&
      newFormData.question &&
      newFormData.answer &&
      newFormData.instruction
    );

    if (showCampaignTemp && !newFormData.campaignId) {
      canSaveTemp = false;
    }

    if (data?.name === 'campaignId') {
      newFormData.campaign = extractCampaign(
        data.value,
        event?.target?.textContent
      );
    }

    setUniqueError(uniqueErrorTemp);
    setFormData(newFormData);
    setShowCampaign(showCampaignTemp);
    setCanSave(canSaveTemp);
  };

  const setStates = (value: boolean) => {
    setShowForm(value);
    setCanSave(value);
    setCanDelete(value);
  };

  const onAddClick = () => {
    setStates(false);
    onSave(
      {
        question: formData.question,
        answer: formData.answer,
        instruction: formData.instruction,
        campaignId: formData.campaignId,
        enabled: true,
        campaign: formData.campaign,
        createdAt: new Date()
      },
      itemIndex
    );
  };

  const onDeleteClick = () => {
    setStates(false);
    onDelete(itemIndex);
  };

  useEffect(() => {
    // prettier-ignore
    const showCampaignTemp = formData.instruction === fundraiserIntructionTypes[0].value;
    setShowCampaign(showCampaignTemp);
    if (!showCampaignTemp) {
      onCampaignChange();
    }
  }, [showForm, formData.instruction]);

  return (
    <>
      <ListTable
        columns={fundraiserColumns}
        data={generateFundraiserValues(fundraiserSetup?.settings)}
        disableAction={showForm}
        onEdit={(_, itemIndex) => {
          setStates(true);
          setItemIndex(itemIndex);
          setFormData(fundraiserSetup?.settings[itemIndex]);
        }}
      />
      <AnimateHeight
        animateOpacity
        duration={300}
        height={showForm ? 'auto' : 0}
      >
        <DataForm
          title="event.template.page.active-integration.new-header"
          canSave={canSave}
          editMode={false}
          canDelete={canDelete}
          onSave={onAddClick}
          onDelete={onDeleteClick}
          onCancel={() => setShowForm(false)}
        >
          <Form.Field required={requiredField}>
            <label>{I18n.t(fundraiserKey.question)}</label>
            <CommonForm.Input
              name="question"
              value={formData.question}
              required
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field required={requiredField}>
            <label>{I18n.t(fundraiserKey.answer)}</label>
            <CommonForm.TextArea
              name="answer"
              value={formData.answer}
              required
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field required={requiredField}>
            <label>{I18n.t(fundraiserKey.instruction)}</label>
            <CommonForm.Dropdown
              name="instruction"
              options={fundraiserIntructionTypes}
              hasEmptyOption
              value={formData.instruction}
              validation={{
                required: true
              }}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={showCampaign ? 'auto' : 0}
            >
              <CampaignLookup
                label={I18n.t(fundraiserKey.campaign)}
                validation={requiredField}
                customFilter={[
                  { key: 'eventId', operator: 'EqualTo', value: eventId }
                ]}
                customDiplayName={eventCampaignDisplayValue}
                name="campaignId"
                value={formData.campaignId}
                onChange={onCampaignChange}
              />
            </AnimateHeight>
          </Form.Field>
          <Message
            hidden={!uniqueError}
            negative
            content={
              <p>
                <Translate value="event.template.page.active-integration.fundraiser.unique-error" />
              </p>
            }
          />
        </DataForm>
      </AnimateHeight>
      <AnimateHeight
        animateOpacity
        duration={300}
        height={!showForm ? 'auto' : 0}
      >
        <Button
          className="add-item-container"
          onClick={() => {
            let newData = resetObject(formData);
            newData.answer = '';
            const settings = fundraiserSetup?.settings?.filter(
              (x) => x.enabled
            );
            if (settings?.length > 0) {
              newData.question = settings[settings.length - 1].question;
            }
            setFormData(newData as IActiveIntegrationFundraiser);
            setItemIndex(-1);
            setShowForm(true);
          }}
        >
          {I18n.t('list-table.add-action')}
        </Button>
      </AnimateHeight>
    </>
  );
};
