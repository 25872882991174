import { useEffect } from 'react';

export const useZendesk = () => {
  useEffect(() => {
    const zendeskKey = process.env.REACT_APP_ZENDESK_KEY;
    const importScript = document.createElement('script');
    importScript.id = 'ze-snippet';
    importScript.src = `https://static.zdassets.com/ekr/snippet.js?key=${zendeskKey}`;
    document.body.appendChild(importScript);

    return () => {
      const scriptElement = document.getElementById('ze-snippet');
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [process.env.REACT_APP_ZENDESK_KEY]);

  return null;
};
