import React from 'react';
import * as fileDownload from 'react-file-download';
import { List } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';
import { CSV_BULK_TEMPLATE } from '../constants';
import './index.css';

const BulkUploadDescription = (props) => {
  const downloadTemplate = () => {
    fileDownload(CSV_BULK_TEMPLATE, 'CSV_Template.csv');
  };
  const sentences = Array.from({ length: 9 }, (_, i) => i + 3);

  return (
    <div>
      <List as="ol">
        <List.Item as="li">
          {I18n.t('general.bulk-upload.page.description.sentence-1')}
          <span
            className="Links bulk-template-download"
            onClick={downloadTemplate}
          >
            CSV template
          </span>
        </List.Item>
        <List.Item as="li">
          {I18n.t('general.bulk-upload.page.description.sentence-2')}
          <List bulleted>
            {sentences.map(index => (
              <List.Item key={index}>
                {I18n.t(`general.bulk-upload.page.description.sentence-${index}`)}
              </List.Item>
            ))}
          </List>
        </List.Item>
        <List.Item as="li">
          {I18n.t('general.bulk-upload.page.description.sentence-12')}
        </List.Item>
      </List>
      <p>{I18n.t('general.bulk-upload.page.note')}</p>
    </div>
  );
};

export default BulkUploadDescription;
