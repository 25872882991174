import React from 'react';
import { IInventory, IMerchandise } from './merchandise.model';
import Styles from './merchandise-list.module.css';
import {
  getInventoryQuantity,
  formatQuantity,
  getInventoryState
} from './merchandise.helpers';
import { ImageView } from './merchandise-image';
import { InventoryStates, SkuItemLabels } from './merchandise.constants';
import { Label } from 'semantic-ui-react';

export const MerchandiseItem: React.FunctionComponent<UseMerchandiseItem> = (
  props
) => {
  return <MerchandiseItemView {...useMerchandiseItem(props)} />;
};

interface UseMerchandiseItem {
  item: IMerchandise;
  onEditItem?: (id: number) => void;
}

export function useMerchandiseItem({
  item,
  onEditItem
}: UseMerchandiseItem): MerchandiseItemViewProps {
  const onEdit = React.useCallback(() => {
    if (onEditItem && typeof item?.id === 'number') onEditItem(item.id);
  }, [item.id, onEditItem]);

  return {
    title: item.name,
    description: item.description,
    imageSrc: item.mainImageUrl,
    state: getInventoryState(item?.inventory),
    inventory: item.inventory,
    onEdit
  };
}

interface MerchandiseItemViewProps {
  state?: InventoryStates | null;
  title: string;
  description?: string;
  inventory?: IInventory;
  imageSrc?: string;
  onEdit?: () => void;
}

export const MerchandiseItemView: React.FunctionComponent<
  MerchandiseItemViewProps
> = (props) => {
  return (
    <div className={Styles.Item}>
      <div className={Styles.Sidebar}>
        <ImageView path={props.imageSrc} />
        <a className={Styles.EditButton} onClick={props.onEdit}>
          {SkuItemLabels.BtnEdit}
        </a>
      </div>
      <div className={Styles.ItemDetails}>
        <div className={Styles.ItemHeader}>
          <div className={Styles.ItemTitle}>{props.title}</div>
          <div className={Styles.ItemQuantity}>
            {props.state
              ? {
                  [InventoryStates.SoldOut]: <Label>Sold out</Label>,
                  [InventoryStates.Remaining]: (
                    <Label color="orange">
                      {formatQuantity(props.inventory?.remaining)} in stock
                    </Label>
                  )
                }[props.state]
              : null}
          </div>
        </div>
        <div className={Styles.Description}>
          {formatQuantity(props.inventory?.sold)} sold of{' '}
          {getInventoryQuantity(props.inventory)}
        </div>
        <div className={Styles.Description}>{props.description}</div>
      </div>
    </div>
  );
};
