import React from 'react';
import ReactCodeInput from 'react-verification-code-input';

import { Record } from '../../modules/bin/utility';

import config from '../../constants';
import { verificationVariant } from '../../constants/multiFactorAuth';

class VerificationForm extends React.Component {
  constructor(props) {
    super(props);

    this.reactCodeInputRef = React.createRef();
  }

  componentDidUpdate = (prevProps) => {
    this.handleSecurityApprovalError(prevProps);
  };

  clearVerificationCode = () => {
    this.props.handleValueChange(`${this.props.path}.authCode`, null);
    this.reactCodeInputRef.current.__clearvalues__();
  };

  handleSecurityApprovalError = (prevProps) => {
    if (this.props.verificationRecord) {
      const isPrevRecordError = Record.isError(prevProps.verificationRecord);
      const isCurrentRecordError = Record.isError(
        this.props.verificationRecord
      );

      if (isCurrentRecordError && isPrevRecordError !== isCurrentRecordError) {
        this.clearVerificationCode();
      }
    }
  };

  handleValueChange = (value) => {
    this.props.handleValueChange(`${this.props.path}.authCode`, value);
  };

  handleVerificationVariantChange = () => {
    this.props.handleVerificationVariantChange(
      verificationVariant.recoveryCode
    );
    this.props.handleValueChange(`${this.props.path}.authCode`, null);
  };

  render() {
    return (
      <React.Fragment>
        <ReactCodeInput
          className="verification-input"
          title="2FA Code"
          autoFocus
          fields={config.VERIFICATION_CODE_LENGTH}
          fieldWidth={58}
          onChange={this.handleValueChange}
          ref={this.reactCodeInputRef}
        />
        <p className="alternative-variant">
          <p className="Links" onClick={this.handleVerificationVariantChange}>
            Use a recovery code instead
          </p>
        </p>
      </React.Fragment>
    );
  }
}

export default VerificationForm;
