import React from 'react';
import { Button, Checkbox, Icon, Input, TextArea } from 'semantic-ui-react';
import {
  entityTypes,
  imageFormats,
  imageTypes
} from '../../../../constants/imageUpload';
import EditableImageUpload from '../../../../components/common/editableImageUpload';
import Styles from './merchandise.module.css';
import { getSkuModel, IMerchandise } from './merchandise.model';
import {
  useForm,
  FormProvider,
  useFieldArray,
  useFormContext,
  Controller
} from 'react-hook-form';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import {
  MerchandiseItemLabels,
  MerchandiseValidation,
  SkuItemLabels,
  SkuValidation
} from './merchandise.constants';
import { getFullImagePath, ImageView } from './merchandise-image';
import { ErrorLabel, Label } from './components';
import { MerchandiseDeleteButton } from './merchandise-delete-button';

interface MerchandiseFormProps {
  item?: IMerchandise;
  title?: string;
  submitting?: boolean;
  onCancel?: () => void;
  onDelete?: (item: IMerchandise) => void;
  onSubmit?: (item: IMerchandise) => void;
}

export const MerchandiseForm: React.FunctionComponent<MerchandiseFormProps> = ({
  item,
  title = 'Add item',
  submitting,
  onCancel,
  onDelete,
  onSubmit = () => {}
}) => {
  const methods = useForm({
    defaultValues: item,
    mode: 'onBlur'
  });
  const handleSubmit = React.useCallback(methods.handleSubmit(onSubmit), [
    methods.handleSubmit,
    onSubmit
  ]);

  const list = useFieldArray({
    control: methods.control,
    name: 'skus'
  });

  const handleAddSku = React.useCallback(
    () => list.append(getSkuModel()),
    [list.append]
  );

  const handleRemoveSku = React.useCallback(list.remove, [list.remove]);

  const handleDeleteItem = React.useCallback(() => {
    if (item && onDelete) onDelete(item);
  }, [item, onDelete]);

  const isValid = methods.formState.isValid;

  return (
    <FormProvider {...methods}>
      <div className={Styles.MerchandiseItemHeader}>{title}</div>
      <MerchandiseItemEditView />
      <div className={Styles.SkuHeader}>{SkuItemLabels.Header}</div>
      <div className={Styles.SkuItemHeader}>
        <Label required>{SkuItemLabels.Name}</Label>
        <Label info={SkuItemLabels.PriceTooltip}>{SkuItemLabels.Price}</Label>
        <Label info={SkuItemLabels.QuantityTooltip}>
          {SkuItemLabels.Quantity}
        </Label>
        <Label />
      </div>
      <div className={Styles.SkuItemEdit}>
        {list.fields.map((field, index) => (
          <SkuItemEditView
            key={field.id}
            index={index}
            onDelete={index === 0 ? undefined : () => handleRemoveSku(index)}
          />
        ))}
      </div>
      <div className={Styles.SkuButtonBar}>
        <Button basic onClick={handleAddSku}>
          {SkuItemLabels.BtnAdd}
        </Button>
      </div>
      <div className={Styles.MerchandiseItemButtonBar}>
        <div>
          {onDelete ? (
            <MerchandiseDeleteButton onSubmit={handleDeleteItem} />
          ) : null}
        </div>
        <div>
          <Button disabled={submitting} onClick={onCancel}>
            {SkuItemLabels.BtnCancel}
          </Button>
          <Button
            primary
            loading={submitting}
            disabled={!isValid}
            onClick={handleSubmit}
          >
            {SkuItemLabels.BtnSave}
          </Button>
        </div>
      </div>
    </FormProvider>
  );
};

export const MerchandiseItemEditView: React.FunctionComponent = () => {
  const { control, setValue } = useFormContext();

  return (
    <>
      <div className={Styles.MerchandiseItemEdit}>
        <Controller
          name="mainImagePath"
          render={({ field }) => (
            <EditableImageUpload
              path={field.name}
              entity={entityTypes.ORGANIZATION}
              position="left"
              height="350"
              width="350"
              format={imageFormats}
              uploadType={imageTypes.LOGO}
              onSuccess={(url: string) => setValue(field.name, url)}
              {...field}
            >
              <div className={Styles.MerchandiseItemEditImageContainer}>
                <ImageView path={getFullImagePath(field.value)} />
                {field.value ? (
                  <div>{MerchandiseItemLabels.ImgChange}</div>
                ) : (
                  <div>{MerchandiseItemLabels.ImgUpload}</div>
                )}
              </div>
            </EditableImageUpload>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={MerchandiseValidation.Name}
          render={({ field, fieldState: { error } }) => (
            <>
              <Label required>{MerchandiseItemLabels.Name}</Label>
              <Input
                type="text"
                fluid
                placeholder={MerchandiseItemLabels.NamePlaceholder}
                {...field}
                error={!!error}
              />
              <ErrorLabel>{error?.message}</ErrorLabel>
            </>
          )}
        />
        <Controller
          name="description"
          rules={MerchandiseValidation.Description}
          render={({ field, fieldState: { error } }) => (
            <>
              <Label>{MerchandiseItemLabels.Description}</Label>
              <TextArea fluid {...field} />
              {error ? <ErrorLabel>{error?.message}</ErrorLabel> : null}
            </>
          )}
        />
        <Controller
          name="hasCountryTax"
          rules={MerchandiseValidation.Description}
          render={({
            field: { value, onChange, ...field },
            fieldState: { error }
          }) => (
            <>
              <Checkbox
                label={MerchandiseItemLabels.SalesTaxLabel}
                {...field}
                checked={value}
                onChange={(e: any, { checked }: any) => onChange(checked)}
              />
              {error ? <ErrorLabel>{error?.message}</ErrorLabel> : null}
            </>
          )}
        />
      </div>
    </>
  );
};

interface SkuItemEditViewProps {
  index: number;
  onDelete?: () => void;
}

export const SkuItemEditView: React.FunctionComponent<SkuItemEditViewProps> = ({
  index,
  onDelete
}) => {
  const { control, setValue } = useFormContext();

  return (
    <>
      <Controller
        name={`skus.${index}.name`}
        control={control}
        rules={SkuValidation.Name}
        render={({ field, fieldState: { error } }) => (
          <div>
            <Input
              type="text"
              fluid
              placeholder={SkuItemLabels.NamePlaceholder}
              {...field}
              error={!!error}
            />
            {error ? <ErrorLabel>{error?.message}</ErrorLabel> : null}
          </div>
        )}
      />
      <Controller
        name={`skus.${index}.pricing.price`}
        control={control}
        render={({
          field: { onChange, name, ...field },
          fieldState: { error }
        }) => (
          <>
            <NumberFormat
              name={name}
              decimalScale={2}
              prefix="$ "
              {...field}
              onValueChange={(values: NumberFormatValues) => {
                setValue(name, values?.value ? values.floatValue : null);
              }}
            />
            {error ? <ErrorLabel>{error?.message}</ErrorLabel> : null}
          </>
        )}
      />
      <Controller
        name={`skus.${index}.inventory.quantity`}
        control={control}
        render={({
          field: { onChange, name, ...field },
          fieldState: { error }
        }) => (
          <>
            <NumberFormat
              name={name}
              decimalScale={0}
              {...field}
              onValueChange={(values: NumberFormatValues) => {
                setValue(name, values?.value ? parseInt(values.value) : null);
              }}
            />
            {error ? <ErrorLabel>{error?.message}</ErrorLabel> : null}
          </>
        )}
      />
      {onDelete ? (
        <div className={Styles.SkuButtonDelete}>
          <Icon className="close icon large" onClick={onDelete} />
        </div>
      ) : (
        <div />
      )}
    </>
  );
};
