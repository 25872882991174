import React from 'react';
import { TemplatePage } from '../../../components/common/pager';
import { paths } from '../constants/template';
import { formBuilderKeys } from '../../campaign/constants';
import { I18n } from 'react-redux-i18n';
import { getFromBuilderInitialData } from '../../../helpers/formBuilderHelper';
import TemplateFormBuilder from '../../../components/template/templateFromBuilder';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateDefaultAmounts } from '../../../components/template/custom/templateDefaultAmounts';
import {
  entityTypes,
  imageFormats,
  imageTypes
} from '../../../constants/imageUpload';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateCustomMediaSelector } from '../../../components/template/custom/templateCustomMediaSelector';
import { TemplateCustomVideoSelector } from '../../../components/template/custom/templateCustomVideoSelector';
import TemplateEditableUploadContainer from '../../../components/template/templateEditableUpload';
import { TemplateText } from '../../../components/template/templateText';

export const getDonationsTab = (props) => {
  const {
    onChange,
    required,
    formBuilderQuestionsValidation,
    defaultAmountsValidation
  } = props;

  return (
    <TemplatePage id="donations" i18nKey="event.template.page.donations.title">
      <div className="template-heading">
        <TemplateHeader as="h3" label="event.template.page.donations.title" />
      </div>

      <TemplateHeader
        as="h5"
        label="event.template.page.donations.default-donation-amounts.label"
      />

      <TemplateText
        label="event.template.page.donations.default-donation-amounts.subtext"
        size="small"
      />

      <TemplateDefaultAmounts
        id="donationsEventDefaultAmounts"
        path={paths.DEFAULT_AMOUNTS}
        validation={defaultAmountsValidation}
        onChange={onChange}
      />

      <TemplateInput
        id="donationsEventDonorCall"
        path={paths.DONOR_ALIAS}
        label="event.template.page.donations.donor-call.label"
        onChange={onChange}
      />

      <TemplateHorizontalRule />
      <TemplateInput
        id="donationsEventPageText"
        path={paths.DONATION_TEXT}
        label="event.template.page.donations.donation-text.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateHeader
        as="h5"
        label="event.template.page.donations.data-capture.label"
      />

      <TemplateFormBuilder
        formBuilderKey={formBuilderKeys.EVENT_DONAIONS}
        path={paths.DONATION_QUESTIONS}
        initialData={getFromBuilderInitialData(formBuilderKeys.EVENT_DONAIONS)}
        onChange={onChange}
        validation={formBuilderQuestionsValidation}
        subtext={I18n.t('event.template.page.donations.data-capture.subtext')}
      />

      <TemplateInput
        id="donationEventThankYouMessage"
        path={paths.THANK_YOU_MESSAGE}
        label="event.template.page.donations.thank-you-message.label"
        onChange={onChange}
      />

      <TemplateHeader
        as="h5"
        label="event.template.page.donations.thank-you-media.heading"
      />

      <TemplateCustomMediaSelector
        onChange={onChange}
        path={paths.THANK_YOU_MEDIA_TYPE}
      >
        <TemplateCustomVideoSelector
          id="donationVideo"
          label="Video URL"
          radioLabel="event.template.page.donations.thank-you-media.video-radio-label"
          path={paths.THANK_YOU_VIDEO}
          subtext="event.template.page.donations.thank-you-media.video-subtext"
          onChange={onChange}
        />

        <TemplateEditableUploadContainer
          id="donationImage"
          path={paths.THANK_YOU_IMAGE}
          radioLabel="event.template.page.donations.thank-you-media.image-radio-label"
          entity={entityTypes.EVENT}
          position="top"
          height="350"
          width="1500"
          format={imageFormats}
          disableCropping
          uploadType={imageTypes.THANK_YOU}
          onChange={onChange}
        />
      </TemplateCustomMediaSelector>
    </TemplatePage>
  );
};
