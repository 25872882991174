import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button, Card } from 'semantic-ui-react';

// LIBS
import { Translate } from 'react-redux-i18n';

// REDUCERS
import { emailNotificationChange, getEmailNotificationSettings, saveEmailNotifications } from './reducer';

// COMPONENTS
import { Form } from '../../../components';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateText } from '../../../components/template/templateText';
import { i18nKeys, paths, subscriptionOptions } from '../constants';
import { Record } from '../../../modules/bin/utility';

class EmailNotifications extends Component {
  
  componentDidMount() {
    const instanceTypes = `${[
      paths.ORG_ADMIN_DAILY_SUMMARY_NOTIFICATION,
      paths.ORG_ADMIN_PAGE_CREATED,
      paths.ORG_ADMIN_SALE_CREATED,
      paths.ORG_ADMIN_DONATION_RECEIVED,
      paths.ORG_ADMIN_DONATION_SUBSCRIPTION_RECEIVED
    ].join("&instanceTypes=")}`;

    this.props.getEmailNotificationSettings(this.props.accountDetail.id, instanceTypes, null);
  }

  onSaveClick = () => {
    const data = {
      campaignIds: this.props.accountDetail.isCampaignAdmin ? this.props.accountDetail.roleDetail.campaignId : [],
      data: Object.keys(this.props.emailNotifications).map(key => ({
        instancetype: key,
        opt: this.props.emailNotifications[key] ? subscriptionOptions.in : subscriptionOptions.out
      }))
    };

    if (this.props.accountDetail.isCampaignAdmin) {
      data['campaignIds'] = this.props.accountDetail.roleDetail.campaignId;
    } else if (this.props.accountDetail.isOrgAdmin) {
      data['organizationId'] = this.props.accountDetail.organizationId
    }

    this.props.saveEmailNotifications(this.props.accountDetail.id, data);
  }
  
  render() {
    return (
      <React.Fragment>
        <div className="general-conatiner">
          <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
            <div className="detail-container">
              <Card.Content>
                <TemplateHeader
                  as="h3"
                  label="account.email-notifications.header"
                />
                
                <TemplateText
                  size="small"
                  label="account.email-notifications.subheader"
                />
                
                <Form.Checkbox
                  className="bold-label-checkbox"
                  checked={this.props.emailNotifications[paths.ORG_ADMIN_DAILY_SUMMARY_NOTIFICATION]}
                  onChange={(_, { checked }) => {
                    this.props.emailNotificationChange({ path: paths.ORG_ADMIN_DAILY_SUMMARY_NOTIFICATION, value: checked });
                  }}
                  inputLabel={{
                    children: <React.Fragment>
                      <TemplateHeader
                        as="h5"
                        label="account.email-notifications.daily-summary.label"
                      />
                      <TemplateText
                        size="small"
                        label="account.email-notifications.daily-summary.sublabel"
                      />
                    </React.Fragment>
                  }}
                />

                <TemplateHeader
                  as="h5"
                  label="account.email-notifications.platform-notifications.header"
                  sub
                />

                <TemplateText
                  size="small"
                  label="account.email-notifications.platform-notifications.subheader"
                />

                <Form.Checkbox
                  checked={this.props.emailNotifications[paths.ORG_ADMIN_SALE_CREATED]}
                  onChange={(_, { checked }) => {
                    this.props.emailNotificationChange({ path: paths.ORG_ADMIN_SALE_CREATED, value: checked });
                  }}
                  className="margin-bottom-10"
                  inputLabel={{
                    children: <TemplateHeader
                      as="h5"
                      label="account.email-notifications.platform-notifications.sale"
                    />
                  }}
                />

                <Form.Checkbox
                  checked={this.props.emailNotifications[paths.ORG_ADMIN_DONATION_RECEIVED]}
                  onChange={(_, { checked }) => {
                    this.props.emailNotificationChange({ path: paths.ORG_ADMIN_DONATION_RECEIVED, value: checked });
                  }}
                  className="margin-bottom-10"
                  inputLabel={{
                    children: <TemplateHeader
                      as="h5"
                      label="account.email-notifications.platform-notifications.donation"
                    />
                  }}
                />

                <Form.Checkbox
                  onChange={(_, { checked }) => {
                    this.props.emailNotificationChange({ path: paths.ORG_ADMIN_PAGE_CREATED, value: checked });
                  }}
                  checked={this.props.emailNotifications[paths.ORG_ADMIN_PAGE_CREATED]}
                  className="margin-bottom-10"
                  inputLabel={{
                    children: <TemplateHeader
                      as="h5"
                      label="account.email-notifications.platform-notifications.page-created"
                    />
                  }}
                />
                
                <Form.Checkbox
                  onChange={(_, { checked }) => {
                    this.props.emailNotificationChange({ path: paths.ORG_ADMIN_DONATION_SUBSCRIPTION_RECEIVED, value: checked });
                  }}
                  checked={this.props.emailNotifications[paths.ORG_ADMIN_DONATION_SUBSCRIPTION_RECEIVED]}
                  className="margin-bottom-10"
                  inputLabel={{
                    children: <TemplateHeader
                      as="h5"
                      label="account.email-notifications.platform-notifications.recurring"
                    />
                  }}
                />
                <div className='bottom-space'></div>
              </Card.Content>
            </div>
          </Card>
          {(
            <Button
              primary
              floated="right"
              id="accountSettingsSaveButton"
              loading={this.props.isSaving}
              onClick={() => this.onSaveClick()}
              style={{ minWidth: '100px' }}
            >
              <Translate value={i18nKeys.SAVE} />
            </Button>
          )}
          <Button floated="right" onClick={this.props.onCancel}>
            <Translate value={i18nKeys.CANCEL} />
          </Button>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ accountSettings }) => {
  const account = accountSettings.record.data.data;
  
  return {
    accountDetail: account,
    isSaving: Record.isRecordLoading(accountSettings.emailNotificationsRecord),
    emailNotifications: accountSettings.emailNotifications
  };
};

const mapDispatch = (dispatch) => {
  return {
    emailNotificationChange: (payload) => {
      dispatch(emailNotificationChange(payload));
    },
    saveEmailNotifications: (id, data) => {
      dispatch(saveEmailNotifications(id, data));
    },
    getEmailNotificationSettings: (id, instanceTypes, verificationToken) => {
      dispatch(getEmailNotificationSettings(id, instanceTypes, verificationToken));
    }
  };
};

export default connect(mapState, mapDispatch)(EmailNotifications);
