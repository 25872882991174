import React, { useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, Form, Message } from 'semantic-ui-react';
import { Form as CommonForm } from '../../../../components';
import { CampaignLookup } from '../../../../components/campaign/lookup/CampaignLookup';
import { ListTable } from '../../../../components/template/listTable/ListTable';
import {
  IActiveIntegrationDonation,
  IActiveIntegrationDonationSetup
} from '../../../../models/ActiveIntegration';
import DateFormatter from '../../../../components/common/grid/cells/dateFormatter';
import {
  donationColumns,
  donationKey,
  requiredField
} from '../../constants/from';
import { DataForm, IDataFormChildProps } from './DataForm';
import {
  compareStrings,
  eventCampaignDisplayValue,
  extractCampaign,
  generateLink,
  getDispalyInstruction,
  onDataFieldChange
} from '../helper';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../store';
import AnimateHeight from 'react-animate-height';
import { resetObject } from '../../../../helpers/objectHelper';

export const donationIntructionTypes = [
  {
    text: 'Fundraiser Or Campaign',
    value: 'fundraiserOrCampaign'
  }
];

interface IDonationDataFormProps extends IDataFormChildProps {
  eventId: string | number;
  showForm: boolean;
  setShowForm: (value: boolean) => void;
  onDelete: (index: number) => void;
}

const generateDonationValues = (data?: any[]) =>
  data?.map((x) => ({
    hidden: !x.enabled,
    data: [
      x.productName,
      x.unitPrice,
      getDispalyInstruction(donationIntructionTypes, x.instruction),
      generateLink(x.campaign),
      <DateFormatter value={x.createdAt} />
    ]
  })) || [];

export const DonationDataForm = ({
  eventId,
  showForm,
  setShowForm,
  onSave,
  onDelete
}: IDonationDataFormProps) => {
  const [formData, setFormData] = useState({} as IActiveIntegrationDonation);
  const template = useSelector((state: RootState) => state.templateBuilderEx);
  const activeIntegration = (template?.data as any)?.activeIntegration;

  const [canSave, setCanSave] = useState(false);
  const [priceError, setPriceError] = useState(false);
  const [productNameError, setProductNameError] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [itemIndex, setItemIndex] = useState(-1);

  // prettier-ignore
  const donationSetup = activeIntegration?.donationSetup as IActiveIntegrationDonationSetup;

  const onCampaignChange = (event?: any, data?: any) => {
    let campaignData = data;
    if (!data?.value || !event?.target) {
      campaignData = { name: 'campaignId', value: null };
    }
    onChange(event, campaignData);
  };

  const onChange = (event: any, data: any) => {
    let priceErrorTemp = false;
    let poductNameErrorTemp = false;
    const newFormData = {
      ...formData,
      ...onDataFieldChange(event, data)
    };

    if (newFormData.productName) {
      const matchedDonations = donationSetup?.settings?.filter(
        (x, i) =>
          i !== itemIndex &&
          x.enabled &&
          compareStrings(x.productName, newFormData.productName)
      );
      poductNameErrorTemp = matchedDonations?.length > 0;
    }

    const canSaveTemp = !!(
      !poductNameErrorTemp &&
      newFormData.productName &&
      newFormData.unitPrice > 0 &&
      newFormData.instruction &&
      newFormData.campaignId
    );

    if (Number(newFormData.unitPrice) === 0) {
      priceErrorTemp = true;
    }

    if (data?.name === 'campaignId') {
      newFormData.campaign = extractCampaign(
        data.value,
        event?.target?.textContent
      );
    }

    setProductNameError(poductNameErrorTemp);
    setPriceError(priceErrorTemp);
    setFormData(newFormData);
    setCanSave(canSaveTemp);
  };

  const setStates = (value: boolean) => {
    setShowForm(value);
    setCanSave(value);
    setCanDelete(value);
  };

  const onAddClick = () => {
    setStates(false);
    onSave(
      {
        productName: formData.productName,
        unitPrice: formData.unitPrice,
        instruction: formData.instruction,
        campaignId: formData.campaignId,
        enabled: true,
        campaign: formData.campaign,
        createdAt: new Date()
      },
      itemIndex
    );
  };

  const onDeleteClick = () => {
    setStates(false);
    onDelete(itemIndex);
  };

  return (
    <>
      <ListTable
        columns={donationColumns}
        data={generateDonationValues(donationSetup?.settings)}
        disableAction={showForm}
        onEdit={(_, itemIndex) => {
          setStates(true);
          setItemIndex(itemIndex);
          setFormData(donationSetup?.settings[itemIndex]);
        }}
      />
      <AnimateHeight
        animateOpacity
        duration={300}
        height={showForm ? 'auto' : 0}
      >
        <DataForm
          title="event.template.page.active-integration.new-header"
          canSave={canSave}
          editMode={false}
          onSave={onAddClick}
          canDelete={canDelete}
          onDelete={onDeleteClick}
          onCancel={() => setShowForm(false)}
        >
          <Form.Field required={requiredField}>
            <label>{I18n.t(donationKey.productName)}</label>
            <CommonForm.Input
              name="productName"
              value={formData.productName}
              required
              onChange={onChange}
            />
            <Message
              hidden={!productNameError}
              negative
              content={
                <p>
                  <Translate value="event.template.page.active-integration.donation.product-name-error" />
                </p>
              }
            />
          </Form.Field>
          <Form.Field required={requiredField}>
            <label>{I18n.t(donationKey.price)}</label>
            <CommonForm.NumberInput
              name="unitPrice"
              value={formData.unitPrice}
              required
              allowDecimal
              onChange={onChange}
            />
            <Message
              hidden={!priceError}
              negative
              content={
                <p>
                  <Translate value="event.template.page.active-integration.donation.min-value-error" />
                </p>
              }
            />
          </Form.Field>
          <Form.Field required={requiredField}>
            <label>{I18n.t(donationKey.instruction)}</label>
            <CommonForm.Dropdown
              name="instruction"
              options={donationIntructionTypes}
              hasEmptyOption
              value={formData.instruction}
              validation={{
                required: true
              }}
              onChange={onChange}
            />
          </Form.Field>
          <Form.Field>
            <CampaignLookup
              label={I18n.t(donationKey.campaign)}
              validation={requiredField}
              customFilter={[
                { key: 'eventId', operator: 'EqualTo', value: eventId }
              ]}
              customDiplayName={eventCampaignDisplayValue}
              name="campaignId"
              value={formData.campaignId}
              onChange={onCampaignChange}
            />
          </Form.Field>
        </DataForm>
      </AnimateHeight>
      <AnimateHeight
        animateOpacity
        duration={300}
        height={!showForm ? 'auto' : 0}
      >
        <Button
          className="add-item-container"
          onClick={() => {
            const newData = resetObject(formData);
            newData.productName = '';
            setFormData(newData as IActiveIntegrationDonation);
            setItemIndex(-1);
            setShowForm(true);
          }}
        >
          {I18n.t('list-table.add-action')}
        </Button>
      </AnimateHeight>
    </>
  );
};
