import {
  getCampaignClickableColumnForCampaignFundraisers,
  getTeamClickableColumnUrl,
  getFundraiserClickableColumnUrl
} from '../../../helpers/publicSiteUrlHelper';

import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';

import { recurringAvailableFiltersKeys } from '../constants';

export const getRecurringGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('startedAtLocal', `${translationKey}.startedAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'donor.email',
        `${translationKey}.donor.email`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.firstName',
        `${translationKey}.donor.firstName`
      ).visibleByDefault(),
      new ColumnModel(
        'donor.lastName',
        `${translationKey}.donor.lastName`
      ).visibleByDefault(),
      new ColumnModel(
        'subscriptionNumber',
        `${translationKey}.subscriptionNumber`
      ).visibleByDefault(),

      new ColumnModel(
        'campaign.name',
        `${translationKey}.campaign-name`
      ).makeClickable(
        getCampaignClickableColumnForCampaignFundraisers,
        true,
        false
      ),
      new ColumnModel('team.name', `${translationKey}.team-name`)
        .makeClickable(getTeamClickableColumnUrl, true, false)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.name',
        `${translationKey}.fundraiser-name`
      ).makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('collection.name', `${translationKey}.collection-name`),
      new ColumnModel('raisedAmount', `${translationKey}.raisedAmount`).setType(
        columnTypes.DECIMAL
      ),
      new ColumnModel('status', `${translationKey}.status`).setType(
        columnTypes.CAPITALIZE_STRING
      )
    ])
    .orderBy('startedAtLocal', 'desc')
    .addFilterButton('active', 'recurring.button-group.active', {
      key: 'status',
      operator: 'EqualTo',
      value: 1
    })
    .addFilterButton('cancelled', 'recurring.button-group.cancelled', {
      key: 'status',
      operator: 'EqualTo',
      value: 2
    })
    .addAvailableFiltersKeys(recurringAvailableFiltersKeys)
    .getState();
};

export const getRecurringDonationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'donationSubscription.organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('donatedAtLocal', `${translationKey}.donatedAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('amount', `${translationKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('financials.fee', `${translationKey}.financials.fee`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${translationKey}.financials.refunded`
      )
        .setType(columnTypes.BOOLEAN)
        .visibleByDefault()
    ])
    .orderBy('donatedAtUtc', 'desc')
    .addAvailableFiltersKeys([])
    .getState();
};
