import React, { Component } from 'react';
import { Image } from 'semantic-ui-react';

import hero from '../../images/hero.jpg';
import avatar from '../../images/avatar.png';
import placeholder from '../../images/placeholder.png';

let classNames = require('classnames');

class UploadC extends Component {
  render() {
    const uploadClasses = classNames({
      'hero-inner': this.props.hero,
      'avatar-inner': this.props.avatar,
      'upload-inner': true
    });
    const previewImage = this.props.hero
      ? hero
      : this.props.avatar
      ? avatar
      : placeholder;
    return (
      <div className={uploadClasses}>
        <div className="preview-box">
          <div className="preview-box-inner">
            <Image src={previewImage} />
          </div>
        </div>
        <div className="upload-description">
          <p>
            <strong>Select an image from your computer</strong>
          </p>
          <ul>
            {this.props.format && <li>{this.props.format} format</li>}
            {this.props.width && this.props.height && (
              <li>
                At least {this.props.width} x {this.props.height} pixels
              </li>
            )}
          </ul>
        </div>
      </div>
    );
  }
}

export default UploadC;
