import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Button } from 'semantic-ui-react';

// LIBS
import { Translate } from 'react-redux-i18n';

// REDUCERS
import { securityModalChange } from '../../reducer';

// IMAGES
import mfa_monitor_icon from '../images/mfa_hand_icon.png';

// CONSTANTS
import config from '../../../../../constants';

class FinishTab extends Component {
  handleCloseModal = () => {
    if (this.props.forceMfa) {
      window.location.reload();
    } else {
      this.props.securityModalChange(false, true);
    }
  };

  render() {
    return (
      <Modal.Content>
        <div className="finish-tab-set-up-container">
          <img alt="" src={mfa_monitor_icon} />
        </div>
        <h4 className="security-sub-title">
          <Translate
            value={'account.account-security.activateModal.steps.finish.title'}
          />
        </h4>
        <div className="security-content">
          <span className="finish-tab-content">
            <Translate
              value={
                'account.account-security.activateModal.steps.finish.content'
              }
            />
            <a target="_blank" href={config.contactUsUrl} rel="noreferrer">
              <Translate
                value={
                  'account.account-security.activateModal.steps.finish.supportTeamLink'
                }
              />
            </a>
            .
          </span>
        </div>
        <Button
          primary
          className="finish-button"
          onClick={this.handleCloseModal}
        >
          <Translate
            value={'account.account-security.activateModal.steps.finish.button'}
          />
        </Button>
      </Modal.Content>
    );
  }
}

const mapState = ({ accountSettings }) => ({
  forceMfa: accountSettings?.MFA?.forceMfa
});

const mapDispatch = (dispatch) => ({
  securityModalChange: (open, finish) => {
    dispatch(securityModalChange(open, finish));
  }
});

export default connect(mapState, mapDispatch)(FinishTab);
