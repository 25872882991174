export const listFilterAggregate = {
  SUM: 'Sum',
  AVERAGE: 'Average',
  MIN: 'Min',
  MAX: 'Max',
  COUNT: 'Count'
};

export enum listFilterOperator {
  EQUAL_TO = 'EqualTo',
  GREATER_THAN = 'GreaterThan',
  GREATER_OR_EQUAL_THAN = 'GreaterOrEqualThan',
  LESSER_THAN = 'LesserThan',
  LESSER_OR_EQUAL_THAN = 'LesserOrEqualThan',
  IN_LIST = 'InList',
  IS_NULL = 'IsNull'
}

export const listFilterPath = {
  LABEL: 'label'
};
