import React from 'react';
import reactCSS from 'reactcss';
import { Icon } from 'semantic-ui-react';
import { Common } from '../';

class CustomColorPicker extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayColorPicker: false,
      color: props.value
    };
  }

  onColorClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  onCoverClose() {
    this.setState({ displayColorPicker: false });
  }

  onColorChange(color) {
    if (this.props.onChange) {
      this.props.onChange(color.hex);
    }
  }

  onCircleClick(item) {
  }

  formatColor(value) {
    return value && value.r && isNaN(parseInt(value.r, 10)) === false
      ? `rgba(${value.r}, ${value.g}, ${value.b}, ${value.a})`
      : value;
  }

  render() {
    const { EditableInput } = require('react-color/lib/components/common');
    const color =
      this.formatColor(this.props.value) ||
      this.formatColor(this.props.default);

    const styles = reactCSS({
      default: {
        color: {
          background: color
        },
        swatch: {
          padding: '7px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer'
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px'
        }
      }
    });
    const defaultColors = this.props.defaultColors || [
      '#f5a623',
      '#b8e986',
      '#4a90e2',
      '#f54823',
      '#8d86e9',
      '#e24a9e'
    ];
    const renderColorCircle = (item) => {
      const iconColorCSS = { backgroundColor: item };
      return (
        <div className="color-circle-cont">
          <Icon
            key={item}
            value={item}
            style={iconColorCSS}
            circular
            name="chevron down"
            className="color-circle"
            onClick={this.onCircleClick.bind(this)}
          />
        </div>
      );
    };

    return (
      <div className="color-picker">
        <Common.Button
          key="pickerButton"
          onClick={this.onColorClick.bind(this)}
          basic
          className="picker-button"
        >
          <Icon
            style={styles.color}
            circular
            name="chevron down"
            className="color-circle"
          />
          <div className="picker-hex">{this.props.value}</div>
          {this.state.displayColorPicker && (
            <div className="picker-button-popover">
              <div
                style={styles.cover}
                onClick={this.onCoverClose.bind(this)}
              />
              <div className="context-cont">
                <div className="context-field">
                  <div className="context-label">Enter hex code</div>
                  <div className="picker-input-cont">
                    <EditableInput
                      value={color}
                      onChange={this.onColorChange.bind(this)}
                    />
                  </div>
                </div>
                <div className="context-field">
                  <div className="context-label">Or choose a colour</div>
                  <div className="picker-colors-cont">
                    {defaultColors.map((item) => renderColorCircle(item))}
                  </div>
                </div>
              </div>
            </div>
          )}
        </Common.Button>
      </div>
    );
  }
}

export default CustomColorPicker;
