import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Message } from 'semantic-ui-react';

import * as templateHelper from './templateHelper';
import * as validationHelper from '../../helpers/validationHelper';

import FormBuilderPage from '../../components/formbuilder/index';

class TemplateFormBuilder extends Component {
  onFormbuilderDataChange = (data) => {
    this.props.onChange(this.props.path, data);
  };

  render() {
    const {
      validation,
      formBuilderKey,
      subtext,
      isAddressHidden,
      isPhoneHidden,
      isCountryAllowed
    } = this.props;

    const isValid = validation.formbuilderQuestionsRequired
      ? validationHelper.isValid(validation, this.props.value)
      : true;
    const errorMessage = validation.formbuilderQuestionsRequired
      ? validationHelper.getValidationErrorMessage(validation, this.props.value)
      : '';

    return (
      <React.Fragment>
        <FormBuilderPage
          formBuilderKey={formBuilderKey}
          dataCallback={this.onFormbuilderDataChange}
          subtext={subtext}
          isAddressHidden={isAddressHidden}
          isPhoneHidden={isPhoneHidden}
          isCountryAllowed={isCountryAllowed}
          formQuestionsMaxLength={validation.length}
          disabled={this.props.disabled}
        />
        <Message negative hidden={isValid || !errorMessage}>
          <p>{errorMessage}</p>
        </Message>
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export default connect(mapState)(TemplateFormBuilder);
