import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store';

const PrivateRoute = (props: any) => {
  const generalStore = useSelector((state: RootState) => state.general);
  const forceRedirect = !props.userRoles.some((userRole: string) =>
    props.allowedRoles.some((role: string) => role === userRole)
  );

  if (generalStore.unauthorized !== false) {
    return <Route path={props.path} component={() => null} />;
  } else if (forceRedirect) {
    return <Route component={() => <Redirect to="/" />} />;
  }

  return <Route path={props.path} component={props.component} />;
};

export default PrivateRoute;
