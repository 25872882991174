import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { i18nReducer } from 'react-redux-i18n';

import configReducer from './config';
import sessionReducer from './session';
import generalReducer from './general';
import campaignReducer from '../pages/campaign/store/campaignReducer';
import donationReducer from '../pages/donation/store/donationReducer';
import accountReducer from '../pages/accounts/store/accountReducer';
import accountDetailReducer from '../pages/accounts/detail/store/accountDetailReducer';
import accountSettingsReducer from '../pages/accounts/settings/reducer';
import uploadReducer from './upload';
import autocompleteReducer from './autocomplete';
import campaignPageReducer from '../pages/campaign/page/reducer';
import organizationReducer from '../pages/organization/store/organizationReducer';
import authenticationReducer from './authentication';
import templateBuilderReducerEx from './template-builder-ex';
import stripeReducer from '../pages/stripe/reducer';
import { formBuilders } from '../components/formbuilder';
import filterReducer from './filter';
import successReducer from './success';
import cropImageReducer from './cropImageReducer';
import eventReducer from '../pages/event/store/eventReducer';
import ticketingReducer from '../pages/ticketing/store/ticketingReducer';
import programReducer from '../pages/vipProgram/store/vipProgramReducer';
import mercandiseReducer from '../pages/merchandise/reducer';
import recurringReducer from '../pages/recurring/reducer';
import fundraiserReducer from '../pages/fundraisers/reducer';
import treeReducer from './tree';
import cardReducer from './card';
import dashboardReducer from '../pages/dashboard/reducer';
import giftMatchingReducer from '../components/collectionBuilder/components/giftMatchingReducer';
import salesforceReducer from '../pages/salesforce/reducer';
import registerReducer from '../pages/register/reducer';
import { History } from 'history';
import webhookReducer from '../pages/webhooks/store/webhookReducer';
import terminalReducer from '../pages/tapNdonate/store/terminalReducer';
import backofficeReducer from '../components/backoffice/reducer';
import beamerReducer from '../hooks/beamer/reducer';

const createRootReducer = (history: History<unknown>) =>
  combineReducers({
    router: connectRouter(history),
    config: configReducer,
    general: generalReducer,
    session: sessionReducer,
    account: accountReducer,
    accountDetail: accountDetailReducer,
    accountSettings: accountSettingsReducer,
    webhook: webhookReducer,
    terminal: terminalReducer,
    register: registerReducer,
    event: eventReducer,
    organization: organizationReducer,
    campaign: campaignReducer,
    campaignPage: campaignPageReducer,
    fundraiser: fundraiserReducer,
    dashboard: dashboardReducer,
    ticketing: ticketingReducer,
    donation: donationReducer,
    merchandise: mercandiseReducer,
    recurring: recurringReducer,
    program: programReducer,
    templateBuilderEx: templateBuilderReducerEx,
    autocomplete: autocompleteReducer,
    filter: filterReducer,
    upload: uploadReducer,
    cropImage: cropImageReducer,
    tree: treeReducer,
    card: cardReducer,
    giftMatching: giftMatchingReducer,
    formBuilders: formBuilders,
    backoffice: backofficeReducer,
    authentication: authenticationReducer,
    stripe: stripeReducer,
    salesforce: salesforceReducer,
    beamer: beamerReducer,
    success: successReducer,
    i18n: i18nReducer
  });

export default createRootReducer;
