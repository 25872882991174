// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as eventActions from '../store/eventActions';
import * as donationActions from '../../donation/store/donationActions';
import * as generalActions from '../../../modules/general';
import { setRedirectUrl } from '../../../modules/session';

// Components
import { Common } from '../../../components';
import RecordResultMessage from '../../../components/common/recordResult';
import RefundWindowContainer from '../../donation/refund-window';
import ReceiptWindowContainer from '../../donation/receipt-window';
import { Menu } from 'semantic-ui-react';
import { ExportDataButton } from '../../../components/template/templateExportButton';
import { ExportDownloadProvider } from '../../export-history';

// Helpers
import { Record } from '../../../modules/bin/utility';
import get from 'lodash.get';

// Constants
import {
  eventGridTabs,
  i18nKeys,
  ONLINE_DONATION_TYPE,
  OFFLINE_DONATION_TYPE
} from '../constants';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';
import { getListWithOrgFilterIfSysAdmin } from '../../../helpers/listHelper';
import { PLEDGE_DONATION_TYPE } from '../../campaign/constants';

class ListsView extends Component {
  /**
   * Changes the grid page between donations and fundraisers
   * @param {int} idx - the tab number to be shown
   */
  onFilterButtonClick(idx) {
    const paramId = get(this.props, 'match.params.id');
    this.props.generalActions.clearListsReadyState();
    this.props.history.push(`${this.props.filterPath}/${idx}/${paramId}`);
  }

  isChooseTabButtonDisabled = () => {
    return (
      Record.isRecordLoading(this.props.donationList) ||
      Record.isRecordLoading(this.props.fundraiserList) ||
      Record.isRecordLoading(this.props.organizationList)
    );
  };

  getTabs = () => {
    return (
      <Menu
        className="accounts-tab-buttons-group"
        style={{ margin: '0px', borderRight: '0px' }}
      >
        <Menu.Item
          id="0"
          active={this.props.gridTab === eventGridTabs.ORGANIZATIONS.label}
          onClick={this.onFilterButtonClick.bind(
            this,
            eventGridTabs.ORGANIZATIONS.label
          )}
          disabled={this.isChooseTabButtonDisabled()}
        >
          <Translate value={i18nKeys.ORGANIZATIONS_TAB} />
        </Menu.Item>
        {!this.props.isEventAdmin && !this.props.isEventOrganizerAdmin && (
          <Menu.Item
            id="1"
            active={this.props.gridTab === eventGridTabs.DONATIONS.label}
            onClick={this.onFilterButtonClick.bind(
              this,
              eventGridTabs.DONATIONS.label
            )}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value={i18nKeys.DONATIONS_TAB} />
          </Menu.Item>
        )}
        {!this.props.isEventOrganizerAdmin && (
          <Menu.Item
            id="2"
            active={this.props.gridTab === eventGridTabs.FUNDRAISERS.label}
            onClick={this.onFilterButtonClick.bind(
              this,
              eventGridTabs.FUNDRAISERS.label
            )}
            disabled={this.isChooseTabButtonDisabled()}
          >
            <Translate value={i18nKeys.FUNDRAISERS_TAB} />
          </Menu.Item>
        )}
      </Menu>
    );
  };

  clearMessages = () => {
    this.props.donationActions.clearRecordMessages('saveRecord');
  };

  //--------Organizations tab--------

  isCampaignContextMenuItemHide = (item) => {
    return !(item.teams.enabled || item.fundraisers.enabled);
  };

  //--------Donations tab--------
  onViewContextClick = (id, item) => {
    this.props.history.push(`/donations/view/${id}`);
  };

  onRefundDonationContextClick = (id, item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  hideRefundButton = (item) => {
    return item.type !== ONLINE_DONATION_TYPE;
  };

  hideDeleteButton = (item) => {
    return (
      item.type !== OFFLINE_DONATION_TYPE && item.type !== PLEDGE_DONATION_TYPE
    );
  };

  onReissueDonationContextClick = (id, item) => {
    if (item.donor.email) {
      this.props.donationActions.showReceiptConfirmation(item);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  hideViewButton = (item) => {
    return !this.props.isSystemAdmin;
  };

  //--------Fundraisers tab--------

  isFundraiserContextMenuItemHide = (item) => {
    return item.type === pageTypesStrings.FUNDRAISER;
  };

  isDonationsContextMenuItemHidden = () => {
    return this.props.isEventAdmin || this.props.isEventOrganizerAdmin;
  };

  render() {
    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;

    const tabs = this.getTabs();

    const { gridTab, donationSaveRecord } = this.props;

    return (
      <div>
        <RecordResultMessage
          record={donationSaveRecord}
          onDismiss={this.clearMessages}
        />
        <RefundWindowContainer />
        <ReceiptWindowContainer />
        {tabs}
        {gridTab === eventGridTabs.ORGANIZATIONS.label && (
          <Grid.Managed
            id="organizationList"
            listKey={this.props.organizationListKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.organizationList,
              this.props.isSystemAdmin
            )}
            actions={this.props.organizationListActions}
            search
            filter={this.props.baseFilter}
            toggleColumns
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider entity="page/campaign" version="v3">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v3']}
                      onExportClick={(_) =>
                        open(this.props.organizationList.request)
                      }
                    />
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            <ContextMenu>
              {!this.props.isEventOrganizerAdmin && (
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_CAMPAIGN.key}
                  label={I18n.t('campaign.list.context.edit-campaign')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_CAMPAIGN
                  }
                />
              )}
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PUBLIC_PAGE.key}
                label={I18n.t('campaign.list.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PUBLIC_PAGE
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_DONATIONS.key}
                label={I18n.t('campaign.list.context.view-donations')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_DONATIONS
                }
                isHiden={this.isDonationsContextMenuItemHidden}
              />
              {!this.props.isEventOrganizerAdmin && (
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_FUNDRAISERS.key}
                  label={I18n.t('campaign.list.context.view-fundraisers')}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_FUNDRAISERS
                  }
                  isHiden={this.isCampaignContextMenuItemHide}
                />
              )}
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === eventGridTabs.DONATIONS.label && (
          <Grid.Managed
            id="donationList"
            listKey={this.props.donationListKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.donationList,
              this.props.isSystemAdmin
            )}
            actions={this.props.donationListActions}
            search
            filter={this.props.baseFilter}
            toggleColumns
            filtersButton
          >
            <Panel name="right">
              <ExportDownloadProvider entity="donation" version="v3">
                {({ open }) => (
                  <ExportDataButton
                    onExportClick={(version) => {
                      let additionalData = {};
                      if (version === 'v3') {
                        additionalData.layout = 'Extended';
                      } else if (version === 'v4') {
                        additionalData.layout = 'FundraiserData';
                      }

                      open({
                        ...additionalData,
                        ...(this.props.donationList.request || {})
                      });
                    }}
                  />
                )}
              </ExportDownloadProvider>
            </Panel>
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_DONATION
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_DONATION
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundDonationContextClick}
                isHiden={this.hideRefundButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundDonationContextClick}
                isHiden={this.hideDeleteButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueDonationContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {gridTab === eventGridTabs.FUNDRAISERS.label && (
          <Grid.Managed
            id="fundraisersList"
            listKey={this.props.fundraiserListKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.fundraiserList,
              this.props.isSystemAdmin
            )}
            columnRender={this.columnRender}
            actions={this.props.fundraiserListActions}
            search
            searchVersion="v3"
            filter={this.props.baseFilter}
            toggleColumns
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider entity="page" version="v3">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v2', 'v3']}
                      onExportClick={(version) => {
                        let additionalData = {};
                        if (version === 'v3') {
                          additionalData.layout = 'Extended';
                        }

                        open({
                          ...additionalData,
                          ...(this.props.fundraiserList.request || {})
                        });
                      }}
                    />
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            <ContextMenu>
              {!this.props.isEventOrganizerAdmin && (
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_PAGE.key}
                  label={I18n.t('campaign.list.view.context.edit-page')}
                  onClick={() =>
                    this.props.setRedirectUrl(
                      this.props.history.location.pathname
                    )
                  }
                  contextMenuOptions={CONTEXT_MENU_KEYS.EVENTDETAILS.EDIT_PAGE}
                />
              )}
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.NAVIGATE_TO_PUBLIC.key}
                label={I18n.t('campaign.list.view.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.NAVIGATE_TO_PUBLIC
                }
              />
              {!this.props.isEventOrganizerAdmin && (
                <ContextMenu.Item
                  key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PAGE_DONATIONS.key}
                  label={I18n.t(
                    'campaign.list.view.context.view-page-donations'
                  )}
                  contextMenuOptions={
                    CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PAGE_DONATIONS
                  }
                  isHiden={this.isDonationsContextMenuItemHidden}
                />
              )}
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PAGE_FUNDRAISERS.key}
                label={I18n.t(
                  'campaign.list.view.context.view-page-fundraisers'
                )}
                onClick={this.onViewPageFundraisersContextClick}
                isHiden={this.isFundraiserContextMenuItemHide}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.EVENTDETAILS.VIEW_PAGE_FUNDRAISERS
                }
              />
            </ContextMenu>
          </Grid.Managed>
        )}
      </div>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ event, donation, session }, ownProps) => {
  const donationGrid = Common.Grid.Managed.mapGridState(
    event,
    ownProps.donationsGridKey
  );
  const fundraiserGrid = Common.Grid.Managed.mapGridState(
    event,
    ownProps.fundraisersGridKey
  );
  const organizationGrid = Common.Grid.Managed.mapGridState(
    event,
    ownProps.organizationsGridKey
  );

  return {
    gridTab: get(ownProps, 'match.params.view'),
    donationSaveRecord: donation.saveRecord,

    // The list data for event organizations
    organizationListKey: organizationGrid.key,
    organizationTimestamp: organizationGrid.timestamp,
    organizationList: organizationGrid.list,

    // The list data for event donations
    donationListKey: donationGrid.key,
    donationTimestamp: donationGrid.timestamp,
    donationList: donationGrid.list,

    // The list data for event fundraisers
    fundraiserListKey: fundraiserGrid.key,
    fundraiserTimestamp: fundraiserGrid.timestamp,
    fundraiserList: fundraiserGrid.list,

    isSystemAdmin: get(session, 'isSystemAdmin'),
    isEventAdmin: get(session, 'isEventAdmin'),
    isEventOrganizerAdmin: get(session, 'isEventOrganizerAdmin'),
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  // Maps the actions to the properties.
  const dispatchers = bindActionCreators(eventActions, dispatch);

  // Maps the required functions for the managed list to a prop.
  dispatchers.organizationListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: eventActions.getOrganizationMetadata,
      getListData: eventActions.getOrganizationListData,
      getListDataBySearchTerm: eventActions.getOrganizationListDataBySearchTerm,
      toggleColumnsChange: eventActions.toggleColumnsChange,
      onGridTabChange: eventActions.onGridTabChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.donationListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: eventActions.getDonationMetadata,
      getListData: eventActions.getDonationListData,
      getListDataBySearchTerm: eventActions.getDonationListDataBySearchTerm,
      toggleColumnsChange: eventActions.toggleColumnsChange
    }
  );

  // Maps the required functions for the managed list to a prop.
  dispatchers.fundraiserListActions = Common.Grid.Managed.bindGridActions(
    dispatch,
    {
      getMetadata: eventActions.getFundraiserMetadata,
      getListData: eventActions.getFundraiserListData,
      getListDataBySearchTerm: eventActions.getFundraiserListDataBySearchTerm,
      toggleColumnsChange: eventActions.toggleColumnsChange
    }
  );

  dispatchers.generalActions = bindActionCreators(generalActions, dispatch);
  dispatchers.donationActions = bindActionCreators(donationActions, dispatch);
  dispatchers.setRedirectUrl = bindActionCreators(setRedirectUrl, dispatch);

  return dispatchers;
};

const ListsViewContainer = withRouter(
  connect(mapState, mapDispatch)(ListsView)
);
export default ListsViewContainer;
