export const templateKeys = {
  FUNDRAISER_PAGE: 'fundraiser-default',
  TEAM_PAGE: 'team-default',
  COLLECTION_PAGE: 'collection-default',
  CAMPAIGN: 'campaign-default',
  ORGANIZATION: 'org-default',
  EVENT: 'event-default',
  TEAM_TEMPLATE: 'team-template',
  FUNDRAISER_TEMPLATE: 'fundraiser-template',
  TICKET: 'ticket-default',
  MERCHANDISE: 'merchandise-default',
  VIP_PROGRAM: 'vip-program-default',
  PROGRAM: 'program-default'
};
