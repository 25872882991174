import React from 'react';
import { I18n } from 'react-redux-i18n';
import { useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import get from 'lodash.get';
import { useParams } from 'react-router-dom';

import { formatDecimalNumber } from '../../../helpers/converterHelper';
import { summaryOptions } from '../constants';
import { RootState } from '../../../store';
import { RouteParams } from '../../../containers/app';

interface ISummaryContainerProps {
  isLoading: boolean;
  summary: any;
  summaryType: string;
  model: any;
  disableModelValidation: boolean;
  onSummaryClick: (s: string) => void;
}

export const SummaryContainer = ({
  isLoading,
  summary,
  summaryType,
  model,
  disableModelValidation,
  onSummaryClick
}: ISummaryContainerProps) => {
  const session = useSelector((state: RootState) => state.session);
  let { view } = useParams<RouteParams>();

  return (
    <React.Fragment>
      {isLoading && (
        <div className="loading-panel">
          <Loader active inline />
        </div>
      )}
      {!isLoading && (
        <div className="summary-panel">
          <div className="summary-container">
            {React.Children.toArray(
              summaryOptions.map((item) => {
                const value = formatDecimalNumber(get(summary, item.key), true);
                return (
                  <React.Fragment>
                    {((disableModelValidation &&
                      !item.overrideModelValidation) ||
                      item.isVisible(model, session, view)) && (
                      <div
                        className={`summary-item${
                          summaryType === item.key ? ' active' : ''
                        }`}
                        onClick={onSummaryClick.bind({}, item.key)}
                      >
                        <h2>
                          {item.prefix}
                          {value}
                        </h2>
                        {I18n.t(`dashboard.top-area.summary.${item.i18n}`)}
                      </div>
                    )}
                  </React.Fragment>
                );
              })
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
