import React, { Component } from 'react';

class Text extends Component {
  render() {
    const { children } = this.props;
    return <p {...this.props}>{children}</p>;
  }
}

export default Text;
