import React, { Component } from 'react';
import moment from 'moment';

class DateFormatter extends Component {
  render() {
    if (!this.props.value) {
      return null;
    }

    return <div>{moment(this.props.value).format('DD MMMM YYYY')}</div>;
  }
}

export default DateFormatter;
