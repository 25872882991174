import React, { useEffect, useState } from 'react';
import { Modal, Header, Button, Checkbox } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';

import './index.css';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Record } from '../../modules/bin/utility';
import { clearRecordMessages, proccessBackOfficeRequest } from './reducer';
import { useAppDispatch } from '../../hooks';
import RecordResultMessage from '../common/recordResult';

interface IBackOfficeModalProps {
  open: boolean;
  name?: string;
  eventId?: number;
  campaignId?: number;
  setOpen: (value: boolean) => void;
}

export const BackOfficeModal = ({
  name,
  open,
  eventId,
  campaignId,
  setOpen
}: IBackOfficeModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [pageCreated, setPageCreated] = useState(false);
  const [joinedCampaignWithDate, setJoinedCampaignWithDate] = useState(false);
  const dispatch = useAppDispatch();
  const { record } = useSelector((state: RootState) => state.backoffice);

  const onSubmit = () => {
    const type = eventId ? 'event' : 'campaign';
    const data = {
      eventId,
      campaignId,
      emailJourneyForcePageCreated: pageCreated,
      emailJourneyForceJoinWithADate: joinedCampaignWithDate
    };
    dispatch(proccessBackOfficeRequest(data, type));
  };

  useEffect(() => {
    if (open) {
      setDisabled(false);
      setJoinedCampaignWithDate(false);
      setPageCreated(false);
    }
  }, [open]);

  useEffect(() => {
    const newIsLoading = Record.isRecordLoading(record);
    const hasErrored = Record.isError(record);
    if (isLoading && !newIsLoading && !hasErrored) {
      setDisabled(true);
    }
    setIsLoading(newIsLoading);
  }, [record]);

  return (
    <Modal
      open={open}
      size="tiny"
      onClose={() => setOpen(false)}
      closeIcon={!isLoading}
    >
      <Modal.Content>
        <Header
          content={I18n.t('components.backoffice.title', { name })}
          textAlign="center"
        />
        <RecordResultMessage
          record={record}
          onDismiss={() => dispatch(clearRecordMessages())}
        />
        <div className="backoffice-model-container">
          <Checkbox
            label={I18n.t('components.backoffice.joined-campaign-with-date')}
            name="wizardRadio"
            checked={joinedCampaignWithDate}
            onChange={(_, data) =>
              !disabled && setJoinedCampaignWithDate(!!data.checked)
            }
            disabled={disabled}
          />
          <Checkbox
            label={I18n.t('components.backoffice.page-created')}
            name="wizardRadio"
            checked={pageCreated}
            onClick={(_, data) => !disabled && setPageCreated(!!data.checked)}
            disabled={disabled}
          />
          <Button
            primary
            style={{ marginTop: '20px' }}
            loading={isLoading}
            onClick={onSubmit}
            disabled={disabled}
          >
            {I18n.t('components.backoffice.submit')}
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
