export const couponPaths = {
  name: 'name',
  firstInstance: 'firstInstance.code',
  allowOtherCouponsInSameSale: 'allowOtherCouponsInSameSale',
  instanceCode: 'code',
  discountTypeValue: 'discountTypeValue',
  discountType: 'discountType',
  usageLimit: 'usageLimit',
  usageLimitValue: 'usageLimitValue'
};
