// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';

// Actions
import * as merchandiseActions from './reducer';

// Helpers
import get from 'lodash.get';
import { getListWithOrgFilterIfSysAdmin } from '../../helpers/listHelper';
import { hideCondition } from '../../helpers/hideHelper';
import clear from '../../components/clear';
import { Record } from '../../modules/bin/utility';
import { clearRecordMessages } from '../../modules/authentication';
import { ExportDownloadProvider } from '../export-history';
import { ExportDataButton } from '../../components/template/templateExportButton';

// Components
import { Common } from '../../components';
import RecordResultMessage from '../../components/common/recordResult';
import Spinner from '../../components/common/spinner';
import BasePage from '../../components/common/basePageView';

// Constants
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import { gridKeys } from '../../constants/gridKeys';

// ----------------------------------------------------------------------

/** A page to view all MERCHANDISE in a list */
class MerchandiseList extends Component {
  clearMessages = () => {
    this.props.actionGrid.clearRecordMessages('recordEdit');
    this.props.actionGrid.clearRecordMessages('recordCreate');
  };

  showSpinner = () => {
    return (
      this.props.isSessionLoading ||
      Record.isRecordLoading(this.props.createAccountRecord) ||
      Record.isRecordLoading(this.props.impersonatedRecord) ||
      Record.isRecordLoading(this.props.exportFileRecord)
    );
  };

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  onCreateMerchandiseClick = () => {
    this.props.history.push(`/merchandise/create/merchandise-detail`);
  };
  /**
   * Renders the component
   */
  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const { Panel, ContextMenu } = Grid.Managed;

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
        />
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
        />
        <RecordResultMessage
          record={this.props.createAccountRecord}
          onDismiss={this.clearActivateRecordMessage}
        />
        <div>
          <div className="grid-top-block">
            <h1>
              <Translate value="merchandise.title" />
            </h1>
            <Common.Button
              id="create-merchandise"
              primary
              onClick={this.onCreateMerchandiseClick}
            >
              <Translate value="merchandise.button.create-merchandise" />
            </Common.Button>
          </div>
          <Grid.Managed
            listKey={this.props.listKey}
            list={getListWithOrgFilterIfSysAdmin(
              this.props.list,
              this.props.isSystemAdmin
            )}
            actions={this.props.actionGrid}
            toggleColumns
            search
            hideColumn={this.hideColumnCondition}
            filtersButton
          >
            {this.props.isExportDataAllowed && (
              <Panel name="right">
                <ExportDownloadProvider entity="saleDetail">
                  {({ open }) => (
                    <ExportDataButton
                      versions={['v3']}
                      onExportClick={() => open(this.props.list.request)}
                    />
                  )}
                </ExportDownloadProvider>
              </Panel>
            )}
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.MERCHANDISE.EDIT.key}
                label={I18n.t('merchandise.list.context.edit-merchandise')}
                contextMenuOptions={CONTEXT_MENU_KEYS.MERCHANDISE.EDIT}
              />
            </ContextMenu>
          </Grid.Managed>
        </div>
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ merchandise, authentication, account, session }) => {
  const { recordEdit, recordCreate } = merchandise;

  // A reference to the list in state
  const stateGrid = Common.Grid.Managed.mapGridState(
    merchandise,
    gridKeys.MERCHANDISE
  );
  return {
    recordEdit,
    recordCreate,
    listKey: stateGrid.key,
    list: stateGrid.list,
    createAccountRecord: authentication.invite.createAccountRecord,
    impersonatedRecord: account.impersonatedRecord,
    exportFileRecord: merchandise.exportFileRecord,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    isSystemAdmin: get(session, 'isSystemAdmin'),
    isSessionLoading: get(session, 'processing'),
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  const actionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    merchandiseActions
  );
  const clearActivateRecord = bindActionCreators(clearRecordMessages, dispatch);
  return {
    actionGrid,
    clearActivateRecord
  };
};

/** Connects component to Redux store */
const MerchandisePageContainer = clear(
  withRouter(connect(mapState, mapDispatch)(MerchandiseList))
);
export default MerchandisePageContainer;
