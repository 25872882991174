import constants, { utmCodes } from '../constants';
import { appendQueryParam } from './queryHelper';

export const getImagePath = (url) => {
  return `${constants.storagePath.replace('{0}', url)}`;
};

export const getQRCodeImageUrl = (urlFull, logo) => {
  const base64Code = window.btoa(appendQueryParam(urlFull, utmCodes));

  if (urlFull && logo) {
    return getImagePath(`qr?b=${base64Code}&logo=${logo}`);
  }

  if (urlFull && !logo) {
    return getImagePath(`qr?b=${base64Code}`);
  }

  return '';
};

export const getSmallHeroImagePath = (url) => {
  return url ? `${getImagePath(url)}?w=850&keepFormat=false` : '';
};

export const getAvatarImagePath = (url) => {
  return url ? `${getImagePath(url)}?w=200` : '';
};

export const getAboutImageUrl = (url) => {
  return url ? `${getImagePath(url)}?w=850` : '';
};

export const getGridCellImageUrl = (url) => {
  return url ? `${getImagePath(url)}?w=25` : '';
};
