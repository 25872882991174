import { generalFormBuilderPath } from '../../constants/formBuilder';

export const paths = {
  name: 'name',
  price: 'pricing.price',
  tax: 'pricing.hasCountryTax',
  quantity: 'inventory.quantity',
  description: 'description',
  type: 'type',
  stream: 'stream',
  formbuilder: generalFormBuilderPath,
  availableUntil: 'availableUntil',
  useAsTargetAmount: 'template.value.useAsTargetAmount',
  availableFrom: 'availableFrom',
  isExpanded: 'template.value.isExpanded'
};
