import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as recurringActions from '../reducer';
import { Record } from '../../../modules/bin/utility';
import clear from '../../../components/clear';

import BasePageView from '../../../components/common/basePageView';
import RecurringDonationDetailHeaderContainer from './header';
import RecurringDetailDonationsContainer from './table';

import config from '../../../constants';

class RecurringDonationViewPage extends Component {
  componentDidMount() {
    const recurringId = this.props.match.params.id;
    this.props.getRecurringDonationRecord(recurringId);
  }

  render() {
    return (
      <BasePageView
        isLoading={this.props.isLoading}
        isLoadingError={this.props.isLoadingError}
        backLink={config.DEFAULT_RECURRING_URL}
        backLinkTitle="Back to recurring donations"
      >
        <div className="recurring-detail">
          <RecurringDonationDetailHeaderContainer />
          <RecurringDetailDonationsContainer />
        </div>
      </BasePageView>
    );
  }
}

const mapState = ({ recurring }) => {
  return {
    isLoading: Record.isRecordLoading(recurring.record),
    isLoadingError: Record.isRecordLoadingError(recurring.record),
    record: recurring.record
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(recurringActions, dispatch);
};

const RecurringDonationViewPageContainer = withRouter(
  clear(connect(mapState, mapDispatch)(RecurringDonationViewPage))
);
export default RecurringDonationViewPageContainer;
