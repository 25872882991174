import React, { ChangeEvent, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Message,
  MessageHeader,
  MessageContent,
  Button,
}  from 'semantic-ui-react';
import { get } from 'lodash';
import fileDownload from "js-file-download";

import { eventPriority } from '../../../../components/template/dependentOrganisations/constants';
import { toggleBulkUploadDialog, changeBulkUploadStatus } from '../../store/eventActions';
import { EVENT_ORGANIZATIONS_CSV_TEMPLATE } from './constants';

import "../../../../polyfills/asyncIteratorPolyfill";

import "./csvBulkUpload.css";

export interface CSVBulkUploadProps {
  listPath: string;
  onChange: (path: string, value: any) => void;
}

type associatedOrganization = {
  id: number,
  name?: string,
  priority: string,
};

export const CSVBulkUpload: React.FC<CSVBulkUploadProps> = (props) => {
  const visible = useSelector(state => get(state, "event.bulkOrgUpload.dialogVisible"));
  const tiers = useSelector(state => get(state, "templateBuilderEx.data.template.value.tiers"));
  const associatedOrganizations: associatedOrganization[] = useSelector(
    state => get(state, `templateBuilderEx.data.${props.listPath}`) || []
  );
  
  const dispatch = useDispatch();

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleCSVUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    event.persist();
    if (event.target.files?.length && event.target.files[0].type === "text/csv") {
      const fileStream = event.target.files[0].stream();
      let csvString = "";
      for await (const chunk of fileStream) {
        csvString += new TextDecoder().decode(chunk as Buffer);
      }

      const orgsCopy: associatedOrganization[] = JSON.parse(JSON.stringify(associatedOrganizations));
      const associatedOrgIds = (associatedOrganizations || []).map(({ id }) => id);
      const validPriorities = eventPriority.map(({ key }) => key);
      const defaultPriority = Object.keys(tiers || {}).reduce(
        (defaultTier, currentTierKey, index) =>
          // Hacky, but .reduce will never execute if tiers === undefined as Object.keys({}) will return an empty array
          (tiers![currentTierKey] as { enabled: boolean; }).enabled ? eventPriority[index].key : defaultTier,
        eventPriority[0].key
      );

      let addedNum = 0;
      let updatedNum = 0;
      csvString.split("\n").forEach(row => {
        if (!row.length) {
          return;
        }

        const columns = row.trim().split(",");
        if (isNaN(Number(columns[0]))) return;
        const id = parseInt(columns[0], 10);

        if (!associatedOrgIds.includes(id)) {
          switch (columns.length) {
            case 1:
              orgsCopy.push({
                id,
                priority: defaultPriority,
              });
              break;

            case 2:
              if (validPriorities.includes(columns[1].toLowerCase())) {
                orgsCopy.push({
                  id,
                  priority: columns[1].toLowerCase(),
                });
              } else {
                orgsCopy.push({
                  id,
                  name: columns[1],
                  priority: defaultPriority,
                });
              }
              break;

            default:
              orgsCopy.push({
                id,
                name: columns[1],
                priority: validPriorities.includes(columns[2].toLowerCase()) ? columns[2].toLowerCase() : defaultPriority,
              });
          }
          addedNum++;
        } else {
          const orgIndex = orgsCopy.findIndex(org => org.id === id);
          switch (columns.length) {
            case 1:
              break;

            case 2:
              if (validPriorities.includes(columns[1].toLowerCase())) {
                orgsCopy[orgIndex].priority = columns[1].toLowerCase();
                updatedNum++;
              }
              break;

            default:
              if (validPriorities.includes(columns[2].toLowerCase())) {
                orgsCopy[orgIndex].priority = columns[2].toLowerCase();
                updatedNum++;
              }
          }
        }
      });

      props.onChange(props.listPath, orgsCopy);
      dispatch(toggleBulkUploadDialog());

      let statusMessage = "Success! ";
      if (addedNum === 1) statusMessage += `${addedNum} organisation was added and `;
      else statusMessage += `${addedNum} organisations were added and `;
      if (updatedNum === 1) statusMessage += `${updatedNum} organisation was updated.`;
      else statusMessage += `${updatedNum} organisations were updated.`;
      statusMessage += "\nChanges are only committed when saving the event.";
      dispatch(changeBulkUploadStatus(statusMessage));
    } else {
      dispatch(changeBulkUploadStatus("Upload failed. Please try again.", true));
    }
  };

  if (!visible) return null;
  return (
    <Message
      className="bulk-upload"
      onDismiss={() => {
        dispatch(toggleBulkUploadDialog());
        dispatch(changeBulkUploadStatus(""));
      }}
    >
      <div className="button-container">
        <Button
          primary
          className="upload-button"
          onClick={() => hiddenFileInput.current?.click()}
        >
          Upload from CSV
        </Button>
        <input
          type="file"
          accept=".csv"
          ref={hiddenFileInput}
          style={{ display: "none" }}
          onChange={handleCSVUpload}
        />
        <span>.csv spreadsheets accepted</span>
      </div>
      <div className="text-container">
        <MessageHeader style={{ marginBottom: "0.25rem" }}>
          Import charities
        </MessageHeader>
        <MessageContent>
          <span
            className="Links"
            style={{ cursor: "pointer" }}
            onClick={() => fileDownload(EVENT_ORGANIZATIONS_CSV_TEMPLATE, "template.csv")}
          >
            Download the CSV template
          </span>
          &nbsp;and add the charities you wish to add to
          this event. Column A: Organisation ID, Column B: Organisation name (optional),
          Column C: Tier (if applicable). Attach your CSV file and click "Upload".
        </MessageContent>
      </div>
    </Message>
  );
};
