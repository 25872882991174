import { lookupEntities } from '../../constants/metadataConstants';

export const filterLookupBaseOptions = {
  [lookupEntities.FundraiserLookup]: {
    getItem(item) {
      return {
        text: `${item.name} (${item.campaign.urlPath}/${item.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.TeamLookup]: {
    getItem(item) {
      return {
        text: `${item.name} (${item.campaign.urlPath}/${item.urlPath})`,
        value: item.id
      };
    }
  }
};
