import React, { useState } from "react";
import { Form, Message } from "semantic-ui-react";
import { I18n } from "react-redux-i18n";
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import moment from "moment";

import config from "../../../constants";
import { DATE_MASK } from "../../../constants/masks";
import * as validationHelper from "../../../helpers/validationHelper";

import "react-datepicker/dist/react-datepicker.css";
import "./datePickerInput.css";

interface DatePickerInputProps {
  value: string;
  id?: string;
  className?: string;
  label?: string;
  placeholder?: string;
  subtext?: string;
  subtextPosition?: "top" | "bottom";
  required?: boolean;
  minDate?: Date;
  maxDate?: Date;
  validation?: any;
  initialValidation?: boolean;
  templateOptionalParam?: any;
  error?: any;
  errorMessage?: string;
  onChange: (date: string) => void;
  onClear?: () => void;
}

export const DatePickerInput: React.FC<DatePickerInputProps> = props => {
  const {
    value,
    subtextPosition = "bottom",
    required = false,
    initialValidation = false,
  } = props;
  const [doValidation, setDoValidation] = useState(initialValidation);

  const validationError = doValidation
    && props.validation
    && !validationHelper.isValid(props.validation, value, props.templateOptionalParam);
  const isError = validationError || props.error;

  const onChange = (date: Date) => {
    if (moment(date).isValid()) {
      props.onChange(moment(date).format(config.DEFAULT_DATE_FORMAT));
    } else if (date === null) {
      props.onChange(date);
      if (props.onClear) props.onClear();
    }
  };

  return (
    <Form.Field
      className={props.className}
      required={required}
      onBlur={() => setDoValidation(true)}
    >
      {props.label && (
        <label>
          {props.label}
        </label>
      )}
      {props.subtext && subtextPosition === "top" && (
        <div className={`input-sub input-sub-${subtextPosition}`}>
          {props.subtext}
        </div>
      )}
      <DatePicker
        id={props.id}
        showIcon
        isClearable
        toggleCalendarOnIconClick
        showYearDropdown
        showMonthDropdown
        dropdownMode="select"
        selected={value && moment(value).isValid() ? new Date(value) : null}
        value={moment(value).format("DD/MM/YYYY")}
        placeholderText={props.placeholder}
        dateFormat="dd/MM/yyyy"
        minDate={props.minDate}
        maxDate={props.maxDate}
        customInput={
          <MaskedInput
            mask={DATE_MASK}
            pattern="[0-9]*"
            inputMode="numeric"
          />
        }
        icon={
          <svg xmlns="http://www.w3.org/2000/svg" height="47px" width="47px" viewBox="0 0 24 24" fill="#5C5C5C">
            <path d="M0 0h24v24H0V0z" fill="none"/>
            <path d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V10h16v11zm0-13H4V5h16v3z"/>
          </svg>
        }
        onChange={onChange}
      />
      {props.subtext && subtextPosition === "bottom" && (
        <div className={`input-sub input-sub-${subtextPosition}`}>
          {props.subtext}
        </div>
      )}
      {isError && (
        <Message negative>
          <p>
            {
              validationHelper.getValidationErrorMessage(props.validation, value, props.templateOptionalParam)
                || props.errorMessage
                || I18n.t("errors.validation.field-invalid")
            }
          </p>
        </Message>
      )}
    </Form.Field>
  );
};
