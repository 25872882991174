import React, { Component } from 'react';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { Loader, Message, Icon } from 'semantic-ui-react';
import get from 'lodash.get';
import constants from '../../../constants';
import {
  onImageDrop,
  uploadPhoto,
  clear,
  cropInit,
  onCropperError
} from '../../../modules/cropImageReducer';
import { Record } from '../../../modules/bin/utility';
import { IMAGE_TOO_BIG_ERROR_MESSAGE } from '../../../constants/customErrorMessages';
import { convertImageAndDrop } from '../../../helpers/imageCropperHelper';
import CropperModal from './cropperModal';

import classNames from 'classnames';

import 'canvas-toBlob';
import 'cropperjs/dist/cropper.css';
import './index.css';

const bytesInMegabyte = 1048576;

class EditableImageUpload extends Component {
  constructor(props) {
    super(props);
    props.cropInit(props.path);
  }

  onDrop = (files) => {
    if (files && files.length === 1) {
      if (this.props.disableCropping) {
        convertImageAndDrop(
          this.props.path,
          files[0],
          this.props.onImageDrop,
          this.props.disableCropping
        );

        this.props.uploadPhoto(
          this.props.path,
          this.props.entity,
          this.props.uploadType,
          files[0],
          this.props.sessionKey
        );
      } else if (files[0].size < constants.maxImageSize * bytesInMegabyte) {
        convertImageAndDrop(this.props.path, files[0], this.props.onImageDrop);
      } else {
        this.props.onCropperError(this.props.path, IMAGE_TOO_BIG_ERROR_MESSAGE);
      }
    }
  };

  getClasses(position) {
    const classes = {
      dropzoneClass: classNames('dropzone', { [`dropzone-${position}`]: true }),
      loaderClass: classNames('image-loader')
    };

    return classes;
  }

  getStyleForRemoveButton = (imagePosition) => {
    return this.removeButtonStyles[imagePosition];
  };

  removeButtonStyles = {
    left: {
      left: '140px'
    },
    top: {
      right: '17px'
    }
  };

  getAcceptedImageTypes = (formats) => {
    if (!formats) {
      return 'image/*';
    }

    return formats.map((item) => `image/${item.toLowerCase()}`).join(',');
  };

  isLoading = () => {
    return (
      this.props.saveRecord && Record.isRecordLoading(this.props.saveRecord)
    );
  };

  onRemoveImageClick = () => {
    this.props.onSuccess('');
  };

  render() {
    if (this.props.saveRecord && Record.isRecordReady(this.props.saveRecord)) {
      this.props.onSuccess(this.props.mainImagePath, this.props.imageUrl);
      this.props.clear(this.props.path);
    }

    const classes = this.getClasses(this.props.position);

    let showImage = this.props.showImage;

    if (this.props.uploading) {
      showImage = (
        <div className="image-loader-container">
          <Loader className={classes.loaderClass} active inline="centered" />
        </div>
      );
    }

    return (
      <div>
        {showImage && (
          <div className="remove-image-button-container">
            {!this.props.disabled && (
              <Icon
                className="remove-image-button"
                name="remove circle"
                size="big"
                style={this.getStyleForRemoveButton(this.props.position)}
                onClick={this.onRemoveImageClick}
              />
            )}
          </div>
        )}
        <Dropzone
          disabled={this.props.disabled}
          accept={this.getAcceptedImageTypes(this.props.format)}
          className={classes.dropzoneClass}
          onDrop={this.onDrop}
        >
          {this.props.children}
        </Dropzone>
        {this.props.isError && (
          <Message
            content={this.props.errorMessage}
            style={{ color: '#9f3a38' }}
          />
        )}
        {!this.props.isError && !this.props.disableCropping && (
          <CropperModal
            path={this.props.path}
            entity={this.props.entity}
            uploadType={this.props.uploadType}
            showImageUrl={this.props.showImageUrl}
          />
        )}
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const path = ownProps.path;

  return {
    imageDropped: get(state, `cropImage.${path}.imageDropped`),
    imagePreview: get(state, `cropImage.${path}.imagePreview`),
    fileName: get(state, `cropImage.${path}.fileName`),
    saveRecord: get(state, `cropImage.${path}.saveRecord`),
    mainImagePath: get(state, `cropImage.${path}.mainImagePath`),
    isError: get(state, `cropImage.${path}.error`),
    errorMessage: get(state, `cropImage.${path}.errorMessage`),
    imageType: get(state, `cropImage.${path}.imageType`),
    imageUrl: get(state, `cropImage.${path}.imageUrl`),
    sessionKey: state.session.key
  };
};

const mapDispatch = (dispatch) => {
  return {
    onImageDrop: (path, blob, filename, width, height, imageType) => {
      dispatch(onImageDrop(path, blob, filename, width, height, imageType));
    },
    uploadPhoto(path, entity, type, file, token, updateProgressBarFunc) {
      dispatch(
        uploadPhoto(path, entity, type, file, token, updateProgressBarFunc)
      );
    },
    clear(path) {
      dispatch(clear(path));
    },
    cropInit(path) {
      dispatch(cropInit(path));
    },
    onCropperError(path, errorMessage) {
      dispatch(onCropperError(path, errorMessage));
    }
  };
};

const EditableImageUploadContainer = connect(
  mapState,
  mapDispatch
)(EditableImageUpload);
export default EditableImageUploadContainer;
