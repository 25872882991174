import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as templateHelper from '../../../components/template/templateHelper';
import { Icon, Grid } from 'semantic-ui-react';
import * as templateActions from '../../../modules/template-builder-ex';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

class TemplatePageManager extends Component {
  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={14}>
            <h3>Page manager</h3>
            <h4 style={{ margin: '0px' }}>
              {this.props.firstName && `${this.props.firstName}`}{' '}
              {this.props.lastName && `${this.props.lastName}`}
            </h4>
            <Icon name="mail" />
            {this.props.email}
          </Grid.Column>
          <Grid.Column width={1}>
            <p className="Links clear-link" onClick={this.props.onChange}>
              change
            </p>
          </Grid.Column>
          <Grid.Column width={1}>
            <p className="Links clear-link" onClick={this.props.onClear}>
              clear
            </p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row></Grid.Row>
      </Grid>
    );
  }
}

const mapState = (state, ownProps) => {
  const manager = templateHelper.getValue(
    state.templateBuilderEx,
    ownProps.path
  );

  return {
    firstName: get(manager, 'firstName'),
    lastName: get(manager, 'lastName'),
    email: get(manager, 'email'),
    path: ownProps.path
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateActions, dispatch)
  };
};

export default connect(mapState, mapDispatch)(TemplatePageManager);
