import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Common } from '../';

class ColorPicker extends Component {
  render() {
    const { id, label, subtext, required, ...pprops } = this.props;

    return (
      <Form.Field id={id} required={required}>
        <label>{label}</label>
        <Common.ColorPicker {...pprops} />
        {subtext && <div className="input-sub">{subtext}</div>}
      </Form.Field>
    );
  }
}

export default ColorPicker;
