// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import moment from 'moment';

// Actions
import * as eventActions from '../store/eventActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as generalActions from '../../../modules/general';
import { formBuilderActions } from '../../../components/formbuilder/store';

// Helpers
import clear from '../../../components/clear';
import { Record } from '../../../modules/bin/utility';
import { cloneDeep } from 'lodash';
import get from 'lodash.get';
import {
  remapAndUnset,
  setTimeAndPlaceDateTime
} from '../../../helpers/templateHelper';
import update from '../../../helpers/update';
import { isChangeEventStatusValid } from '../../../helpers/statusWorkflowHelper';
import { getDefaultAboutElements } from '../../../helpers/pageAboutHelper';

// Components
import EventTemplate from '../templateEx';
import { TemplateOptions } from '../../../components/template/templateOptions';
import Spinner from '../../../components/common/spinner';
import RecordResultMessage from '../../../components/common/recordResult';
import { Grid, Dropdown } from 'semantic-ui-react';
import BasePage from '../../../components/common/basePageView';
import ConfirmationModal from '../../../components/common/confirmationModal';
import EntityModal from '../../../components/errors/entityModal';
import Text from '../../../components/common/text';

// Constants
import config from '../../../constants/index';
import {
  generalFormBuilderPath,
  customFieldCategory
} from '../../../constants/formBuilder';
import { eventStatuses } from '../constants';
import { paths } from '../constants/template';
import { campaignContract } from '../../campaign/create/templates';
import { getSearchString } from '../../../components/formBuilderAnswersComponents/formBuilderAnswersHelper';

// ----------------------------------------------------------------------

class EventEdit extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;

    props.eventActions.getRecord(id);

    this.state = {
      isConfirmModalOpened: false,
      isErrorModalOpened: false,
      newStatus: ''
    };
  }

  onSave = (data) => {
    this.props.eventActions.updateRecord(data);
  };

  clearMessages = () => {
    this.props.eventActions.clearRecordMessages('recordEdit');
    this.props.eventActions.clearRecordMessages('statusRecord');
  };

  goToEventsView = () => {
    this.props.history.push(config.DEFAULT_EVENTS_URL);
  };

  onDropdownItemChange = (event, { value }) => {
    const status = this.getEventStatus();
    if (value === status) {
      return;
    }

    if (isChangeEventStatusValid(status, value)) {
      this.setState({
        newStatus: value,
        isConfirmModalOpened: true
      });
    } else {
      this.setState({
        newStatus: value,
        isErrorModalOpened: true
      });
    }
  };

  updateEventStatus = (newStatus) => {
    const status = newStatus || this.state.newStatus;
    this.props.eventActions.updateEventStatus(this.props.record.data.data.id, {
      status: status
    });
    this.props.templateBuilderActions.setResultValue(
      paths.EVENT_STATUS,
      status
    );
  };

  isAllRecordsReady = () => {
    return Record.isRecordReady(this.props.record);
  };

  initTemplateBuilderModel = () => {
    let event = cloneDeep(get(this.props, 'record.data.data'));

    const donationsFormBuilder = get(
      event,
      `${generalFormBuilderPath}.${customFieldCategory.DONATION}`
    );
    const fundraisersFormBuilder = get(
      event,
      `${generalFormBuilderPath}.${customFieldCategory.FUNDRAISING}`
    );
    const templateOptions = new TemplateOptions();
    const fundraisersEnabled = get(event, paths.FUNDRAISERS_ENABLED);
    const teamsEnabled = get(event, paths.TEAMS_ENABLED);
    const eventAbout = get(event, paths.EVENT_ABOUT);
    const campaignAbout = get(event, paths.CAMPAIGN_ABOUT);
    const defaultTextForTeamPages = get(
      event,
      paths.DEFAULT_TEXT_FOR_TEAM_PAGES
    );
    const defaultTextForFundraisingPages = get(
      event,
      paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES
    );
    const invertPrimaryTextColor = get(event, paths.INVERT_PRIMARY_COLOR);
    const invertSecondaryTextColor = get(event, paths.INVERT_SECONDARY_COLOR);

    //Re-maping old paths
    event = remapAndUnset(event, 'template.value.ticketing', paths.TICKETING);
    event = remapAndUnset(
      event,
      'template.value.purchaseTicketUrl',
      paths.PURCHASE_TICKET_URL
    );

    if (!fundraisersEnabled && !teamsEnabled) {
      templateOptions.hide('defaultAboutHeader');
    }

    if (event && event.organizations && event.organizations.length > 0) {
      event.organizations.forEach((item, index) =>
        templateOptions.disable(`${paths.ORGANIZATIONS}.${index}`)
      );
    }

    //date
    const startDate = get(event, paths.START_DATE);
    if (startDate && moment(startDate).isValid()) {
      event = update.set(
        event,
        paths.START_DATE,
        moment(startDate).format(config.DEFAULT_DATE_FORMAT)
      );
    }

    const endDate = get(event, paths.END_DATE);
    if (endDate && moment(endDate).isValid()) {
      event = update.set(
        event,
        paths.END_DATE,
        moment(endDate).format(config.DEFAULT_DATE_FORMAT)
      );
    }

    //fitness
    const isFitnessEnabled = get(event, campaignContract.fitnessEnabled);
    if (!isFitnessEnabled) {
      event = update.set(event, campaignContract.fitnessEnabled, false);

      templateOptions.hide(campaignContract.fitnessAllowedTypes);
      templateOptions.hide(campaignContract.fitnessTargets);
      templateOptions.hide('fitnessFundraiserDistanceTarget');
      templateOptions.hide('fitnessFundraiserTimeTarget');
      templateOptions.hide('fitnessFundraiserNumberOfStepsTarget');
      templateOptions.hide('fitnessTeamDistanceTarget');
      templateOptions.hide('fitnessTeamTimeTarget');
      templateOptions.hide('fitnessTeamNumberOfStepsTarget');
      templateOptions.hide(campaignContract.fitnessAllowedPlatforms);
      templateOptions.hide(campaignContract.allowPageTracking);
      templateOptions.hide(campaignContract.fitnessAllowedTrackedStatistics);
      templateOptions.hide(campaignContract.fitnessThermometersToShow);
      templateOptions.hide(campaignContract.fitnessLeaderboardsToShow);
      templateOptions.hide('fitnessSetup');
    }

    // prettier-ignore
    const allowTracking = event?.template?.value?.fitnessSetup?.allowPageTracking;
    if (!allowTracking) {
      templateOptions.hide('fitnessFundraiserDates');
    }

    // time and place
    const startDateTime = get(event, 'timeAndPlace.time.start');
    const endDateTime = get(event, 'timeAndPlace.time.end');
    const includesTime = get(event, 'timeAndPlace.time.includesTime');
    const timeDetails = get(event, 'timeAndPlace.time.text');
    const place = get(event, 'timeAndPlace.place.text');
    const year = get(event, 'timeAndPlace.time.year');

    const country = get(event, 'timeAndPlace.place.country');
    const state = get(event, 'timeAndPlace.place.state');
    const city = get(event, 'timeAndPlace.place.city');

    event = update.set(event, 'eventLocation.suburb', city);
    event = update.set(event, 'eventLocation.country', country);
    event = update.set(event, 'eventLocation.state', state);

    const eventLocation = get(event, 'eventLocation');
    const searchString = getSearchString(eventLocation);

    const isTimeAndPlaceEnabled = !!(
      startDateTime ||
      endDateTime ||
      timeDetails ||
      year ||
      place ||
      searchString
    );

    if (!isTimeAndPlaceEnabled) {
      templateOptions.hide('dateAndTime');
      templateOptions.hide('timeAndPlace.time.year');
      templateOptions.hide('eventLocation');
      templateOptions.hide(campaignContract.timeDetails);
      templateOptions.hide(campaignContract.place);
    } else {
      event = update.set(
        event,
        campaignContract.allowTimeAndPlace,
        isTimeAndPlaceEnabled
      );

      event = update.set(event, 'eventLocation.searchString', searchString);
      // prettier-ignore
      const timeAndPlace = setTimeAndPlaceDateTime(event, startDateTime, endDateTime, includesTime);
      event = update.set(event, 'timeAndPlace', timeAndPlace);
    }

    //custom activities
    const customActivitiesEnabled = get(event, paths.CUSTOM_ACTIVITIES_ENABLED);
    if (!customActivitiesEnabled) {
      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_LEADERBOARD_ENABLED);
      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED);

      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_NAME);
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD
      );
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD
      );
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD
      );
      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_SVG);
      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_UNIT);
    }

    const customActivitiesValue1ThermometerEnabled = get(
      event,
      paths.CUSTOM_ACTIVITIES_VALUE1_THERMOMETER_ENABLED
    );
    if (customActivitiesEnabled && !customActivitiesValue1ThermometerEnabled) {
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_CAMPAIGN_TARGET_FIELD
      );
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_FUNDRAISER_DEFAULT_TARGER_FIELD
      );
      templateOptions.hide(
        paths.CUSTOM_ACTIVITIES_VALUE1_TEAM_DEFAULT_TARGET_FIELD
      );
      templateOptions.hide(paths.CUSTOM_ACTIVITIES_VALUE1_SVG);
    }

    templateOptions.setModel(event);

    if (!eventAbout) {
      templateOptions.setValue(paths.EVENT_ABOUT, getDefaultAboutElements());
    }

    if (!campaignAbout) {
      templateOptions.setValue(paths.CAMPAIGN_ABOUT, getDefaultAboutElements());
    }

    if (!defaultTextForTeamPages) {
      templateOptions.setValue(
        paths.DEFAULT_TEXT_FOR_TEAM_PAGES,
        getDefaultAboutElements()
      );
    }

    if (!defaultTextForFundraisingPages) {
      templateOptions.setValue(
        paths.DEFAULT_TEXT_FOR_FUNDRAISING_PAGES,
        getDefaultAboutElements()
      );
    }

    //invertion colours
    if (!invertPrimaryTextColor) {
      templateOptions.setValue(paths.INVERT_PRIMARY_COLOR, false);
    }
    if (!invertSecondaryTextColor) {
      templateOptions.setValue(paths.INVERT_SECONDARY_COLOR, false);
    }

    templateOptions.isEdit();

    this.props.formBuilderActions.setFormBuilderModel(donationsFormBuilder);
    this.props.formBuilderActions.setFormBuilderModel(fundraisersFormBuilder);
    this.props.templateBuilderActions.setTemplateModel(
      templateOptions.getWithRule()
    );
  };

  getEventStatus = () => {
    const savedStatus = get(this.props, 'statusRecord.data.data.status');
    const initedStatus = get(this.props, 'record.data.data.status');
    return savedStatus || initedStatus;
  };

  onModalClose = () => {
    this.setState({ isConfirmModalOpened: false, isErrorModalOpened: false });
  };

  getConfirmModalContent = () => {
    const { pagesCount, name } = this.props.record.data.data;
    const status = this.getEventStatus();
    const translationOptions = {
      pagesCount,
      eventName: name,
      newStatus: this.state.newStatus,
      oldStatus: status
    };
    //the campaign has associated fundraisers
    if (this.props.record.data.data.pagesCount) {
      return (
        <Text>
          {I18n.t(
            `event.changing-status-message.${status}.associated`,
            translationOptions
          )}
          {I18n.t(`event.changing-status-message.${status}.default`)}
          <b> {I18n.t(`event.changing-status-message.${status}.bold`)}</b>
        </Text>
      );
    }
    return (
      <Text>
        {I18n.t(`event.changing-status-message.${status}.default`)}
        <b> {I18n.t(`event.changing-status-message.${status}.bold`)}</b>
      </Text>
    );
  };

  getErrorModalContent = () => {
    const translationOptions = {
      newStatus: this.state.newStatus,
      oldStatus: this.getEventStatus()
    };

    return (
      <Text>
        {I18n.t('event.cannot-change-status-message', translationOptions)}
      </Text>
    );
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const event = this.props.record.data.data;
    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    const dropdownOptions = Object.keys(eventStatuses).map((status) => ({
      text: eventStatuses[status].description,
      value: status.toLowerCase()
    }));
    const confirmModalContent = this.getConfirmModalContent();
    const errorModalContent = this.getErrorModalContent();

    const currentStatus = this.props.statusRecord.data.data
      ? this.props.statusRecord.data.data.status
      : this.props.record.data.data.status;
    const isStatusLoading = Record.isRecordLoading(this.props.statusRecord);
    const isSaving = Record.isRecordLoading(this.props.recordEdit);

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToEventsView}
        />

        <RecordResultMessage
          record={this.props.statusRecord}
          onDismiss={this.clearMessages}
        />

        <ConfirmationModal
          open={this.state.isConfirmModalOpened}
          content={confirmModalContent}
          onClose={this.onModalClose}
          onSubmit={() => this.updateEventStatus()}
        />

        <EntityModal
          open={this.state.isErrorModalOpened}
          title="Warning"
          onClose={this.onModalClose}
        >
          {errorModalContent}
        </EntityModal>

        <Grid>
          <Grid.Row>
            <Grid.Column width={13}>
              <h1>
                <Translate value="event.template.edit-header" />
                {event.name}
              </h1>
            </Grid.Column>
            <Grid.Column width={2}>
              <Dropdown
                id="event-statuses"
                selection
                options={dropdownOptions}
                value={currentStatus}
                onChange={this.onDropdownItemChange}
                loading={isStatusLoading}
                icon="chevron down"
              ></Dropdown>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <EventTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ event, templateBuilderEx }) => {
  return {
    record: event.record,
    statusRecord: event.statusRecord,
    recordEdit: event.recordEdit,
    isTemplateInited: templateBuilderEx.inited
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    eventActions: bindActionCreators(eventActions, dispatch),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

/** Connects component to Redux store */
const EditEventContainer = clear(connect(mapState, mapDispatch)(EventEdit));
export default EditEventContainer;
