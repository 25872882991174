import React from 'react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';
import './index.css';
import DatePickerInput from '../form/datePicker';

class FormbuilderDatePicker extends React.Component {
  render() {
    const {
      value,
      placeholder,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      onChange,
      initialValidation
    } = this.props;
    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <DatePickerInput
          id="formbuilder-datepicker"
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderDatePicker;
