import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import TimePickerInput from '../form/timePicker';

class TimePicker extends Component {
  onValueChange = (value) => {
    if (this.props.onChange) {
      this.props.onChange(this.props.path, value);
    }
  };

  render() {
    const label = templateHelper.getTranslation(this.props.label);
    const subtext = templateHelper.getTranslation(this.props.subtext, this.props.subtextTranslationObject);
    const placeholder = templateHelper.getTranslation(this.props.placeholder);

    const { validation, value } = this.props;

    return (
      <TimePickerInput
        id={this.props.id}
        label={label}
        subtext={subtext}
        value={value || ''}
        placeholder={placeholder}
        onChange={this.onValueChange}
        required={validation && validation.required}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export const TemplateTimePicker = connect(mapState)(TimePicker);
