import React from 'react';
import { I18n } from 'react-redux-i18n';

import { pageTypesStrings } from '../../constants/pageTypes';
import { toCurrency } from '../../helpers/converterHelper';

interface IViewCardSectionProps {
  type: pageTypesStrings;
  data?: {
    id: string | number;
    name: string;
    amount: string | number;
    urlFull: string;
  };
  imageUrl?: string;
  hideId?: boolean;
  className?: string;
}

export const ViewIconItem = ({
  type,
  data,
  imageUrl,
  hideId,
  className
}: IViewCardSectionProps) => {
  const getUrl = (id: string | number) => {
    switch (type) {
      case pageTypesStrings.CAMPAIGN:
        return `/campaigns/edit/${id}/setup`;
      case pageTypesStrings.DONATION_SUB:
        return `/recurring/detail/${id}/donations`;
      case pageTypesStrings.DONATION:
        return `/donations/view/${id}`;
      default:
        return `/campaigns/${type}/edit/${id}/page-detail`;
    }
  };

  return (
    <React.Fragment>
      {data && (
        <div className="view-details-content-row page">
          {imageUrl && <img alt="" src={imageUrl} />}
          <div className="page-content">
            <h4>{I18n.t(`donation.donation-view.donation-details.${type}`)}</h4>
            <a href={data.urlFull} target="_blank" rel="noreferrer">
              {data.name}
            </a>
            {data.amount && toCurrency(data.amount)}
            {!hideId && (
              <span>
                ID:
                <a href={getUrl(data.id)}>{data.id}</a>
              </span>
            )}
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default ViewIconItem;
