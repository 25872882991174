import config from '../../constants';

export const organizationColumns = {
  NAME: 'name',
  AMOUNT_RAISED: 'raisedAmount',
  AMOUNT_RAISED_ONLINE: 'raisedAmountOnlineOnly',
  CAMPAIGNS_TOTAL: 'campaignCount',
  CAMPAIGNS_ACTIVE: 'campaignLiveCount',
  PAYMENT_CONNECTED: 'paymentPlatforms',
  CURRENCY: 'currency',
  COUNTRY: 'address.country',
  PAYMENT_PLATFORM: 'paymentPlatformLink'
};

export const i18nKeys = {
  PAGE_TITLE: 'organization.title',
  DASHBOARD_ACTION: 'organization.dashboard-action',
  EDIT_ACTION: 'organization.edit-action',
  VIEW_ACTION: 'organization.view-action'
};

export const stripeName = 'stripe';

export const stripeLink = 'https://stripe.com/au';

export const australianOrgDetailComponents = ['template.value.hasDGR'];

export const complianceComponents = [
  'compliance',
  'compliance.alternateTaxId1',
  'compliance.alternateTaxId2',
  'compliance.authorizedPerson.name',
  'compliance.authorizedPerson.title',
  'compliance.authorizedPerson.signatureImagePath'
];

export const orgDetailComponents = [
  'organizationDetilHeader',
  'name',
  'legalName',
  'urlPath',
  'taxId',
  'template.value.hasDGR',
  'address.city',
  'address.street',
  'address.state',
  'address.postcode',
  'timezone'
];

export const organizationAboutValidationModels = {
  country: {
    required: true
  },
  name: {
    required: true,
    maxLength: true,
    length: config.ORGANIZATION_NAME_MAX_LENGTH
  },
  legalName: {
    required: true,
    maxLength: true,
    length: config.ORGANIZATION_NAME_MAX_LENGTH
  },
  subdomain: {
    required: true,
    maxLength: true,
    length: config.URL_PATH_MAX_LENGTH
  },
  abn: {
    required: true
  },
  street: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.STREET_MAX_LENGTH
  },
  city: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.CITY_MAX_LENGTH
  },
  state: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.STATE_MAX_LENGTH
  },
  postCode: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.POST_CODE_MAX_LENGTH
  },
  timeZone: {
    required: true
  }
};

export const organizationComplianceValidationModels = {
  complianceAuthorizedPersonName: {
    required: true
  },
  complianceAuthorizedPersonTitle: {
    required: true,
    maxLength: true,
    length: config.JOB_TITLE_MAX_LENGTH
  },
  complianceAuthorizedPersonSignature: {
    required: true
  }
};
