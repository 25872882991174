import React, { Component } from 'react';
import { Form, Message } from 'semantic-ui-react';
import NumberFormat from 'react-number-format';

class NumberInput extends Component {
  isAllowed = (values) => {
    const { floatValue } = values;
    if (this.props.maxValue)
      return floatValue ? floatValue <= this.props.maxValue : true;
    return true;
  };

  render() {
    const {
      className,
      name,
      label,
      inputLabel,
      subtext,
      subtextPosition = 'bottom',
      required,
      allowDecimal,
      value,
      style,
      showValidationIcon,
      validationErrorMessage,
      error,
      errorComponent,
      ...pprops
    } = this.props;

    return (
      <Form.Field className={className} required={required}>
        {label && (
          <label className={`input-sub-${subtextPosition}`}>{label}</label>
        )}
        {subtext && subtextPosition === 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
        <div className={`ui labeled input`} style={style}>
          {inputLabel && <div className={`ui label`}>{inputLabel}</div>}
          <NumberFormat
            name={name}
            allowNegative={false}
            decimalScale={allowDecimal ? 2 : 0}
            value={value}
            isAllowed={this.isAllowed}
            {...pprops}
          />
        </div>
        {subtext && subtextPosition !== 'top' && (
          <div className={`input-sub input-sub-${subtextPosition}`}>
            {subtext}
          </div>
        )}
        <Message negative hidden={!validationErrorMessage && !error}>
          {errorComponent ? (
            <div>{errorComponent}</div>
          ) : (
            <p>{validationErrorMessage}</p>
          )}
        </Message>
      </Form.Field>
    );
  }
}

export default NumberInput;
