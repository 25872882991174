export const BULK_UPLOAD_DIALOG_TOGGLE = 'event/BULK_UPLOAD_DIALOG_TOGGLE';
export const BULK_UPLOAD_STATUS_CHANGE = 'event/BULK_UPLOAD_STATUS_CHANGE';

export const METADATA_REQUESTED = 'event/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'event/METADATA_SUCCESS';
export const METADATA_FAILURE = 'event/METADATA_FAILURE';

export const CREATE_REQUESTED = 'event/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'event/CREATE_SUCCESS';
export const CREATE_FAILURE = 'event/CREATE_FAILURE';

export const CLONE_REQUESTED = 'event/CLONE_REQUESTED';
export const CLONE_SUCCESS = 'event/CLONE_SUCCESS';
export const CLONE_FAILURE = 'event/CLONE_FAILURE';

export const GET_REQUESTED = 'event/GET_REQUESTED';
export const GET_SUCCESS = 'event/GET_SUCCESS';
export const GET_FAILURE = 'event/GET_FAILURE';

export const UPDATE_REQUESTED = 'event/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'event/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'event/UPDATE_FAILURE';

export const LIST_DATA_REQUESTED = 'event/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'event/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'event/LIST_DATA_FAILURE';

export const GET_EXPORT_FILE_REQUESTED = 'event/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'event/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'event/GET_EXPORT_FILE_FAILURE';

export const GET_ACTIVE_INTEGRATION_REQUESTED =
  'event/GET_ACTIVE_INTEGRATION_REQUESTED';
export const GET_ACTIVE_INTEGRATION_SUCCESS =
  'event/GET_ACTIVE_INTEGRATION_SUCCESS';
export const GET_ACTIVE_INTEGRATION_FAILURE =
  'event/GET_ACTIVE_INTEGRATION_FAILURE';

export const UPDATE_ACTIVE_INTEGRATION_REQUESTED =
  'event/UPDATE_ACTIVE_INTEGRATION_REQUESTED';
export const UPDATE_ACTIVE_INTEGRATION_SUCCESS =
  'event/UPDATE_ACTIVE_INTEGRATION_SUCCESS';
export const UPDATE_ACTIVE_INTEGRATION_FAILURE =
  'event/UPDATE_ACTIVE_INTEGRATION_FAILURE';

export const DELETE_ACTIVE_INTEGRATION_REQUESTED =
  'event/DELETE_ACTIVE_INTEGRATION_REQUESTED';
export const DELETE_ACTIVE_INTEGRATION_SUCCESS =
  'event/DELETE_ACTIVE_INTEGRATION_SUCCESS';
export const DELETE_ACTIVE_INTEGRATION_FAILURE =
  'event/DELETE_ACTIVE_INTEGRATION_FAILURE';
export const ERROR_CLEAR = 'event/ERROR_CLEAR';

export const TOGGLE_COLUMNS_CHANGE = 'event/TOGGLE_COLUMNS_CHANGE';
export const GRID_TAB_CHANGE = 'event/GRID_TAB_CHANGE';

export const ON_VALUE_CHANGE = 'event/ON_VALUE_CHANGE';
export const ON_PRIORITY_CHANGE = 'event/ON_PRIORITY_CHANGE';

export const UPDATE_EVENT_STATUS_REQUESTED =
  'event/UPDATE_EVENT_STATUS_REQUESTED';
export const UPDATE_EVENT_STATUS_SUCCESS = 'event/UPDATE_EVENT_STATUS_SUCCESS';
export const UPDATE_EVENT_STATUS_FAILURE = 'event/UPDATE_EVENT_STATUS_FAILURE';
