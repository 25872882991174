import React, { Component } from 'react';
import { Table, Checkbox } from 'semantic-ui-react';
import SortIcon from './sortIcon';

class HeaderCell extends Component {
  onSortClick = () => {
    if (this.props.onSortClick) {
      this.props.onSortClick(this.props.id);
    }
  };

  onMultiSelectClick = (event, data) => {
    if (this.props.onMultiSelectClick) {
      this.props.onMultiSelectClick(event, data);
    }
  };

  renderHeader() {
    // prettier-ignore
    const { headerText, hasMultiSelect, isMultiSelectChecked, sortable, sort } = this.props;
    if (headerText) {
      return sortable === true ? (
        <p className="sort-header" onClick={() => this.onSortClick()}>
          {headerText}
          <SortIcon active={!!sort} direction={sort} />
        </p>
      ) : (
        <span>{headerText}</span>
      );
    } else if (hasMultiSelect) {
      return (
        <Checkbox
          id="checkbox-header"
          onChange={this.onMultiSelectClick}
          checked={isMultiSelectChecked}
        />
      );
    }
    return null;
  }

  render() {
    const { width, datatype } = this.props;
    const style =
      datatype === 'int32' || datatype === 'decimal'
        ? { textAlign: 'right' }
        : {};

    return (
      <Table.HeaderCell width={width || 1} collapsing style={style}>
        {this.renderHeader()}
      </Table.HeaderCell>
    );
  }
}

export default HeaderCell;
