import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Table, Loader, Checkbox } from 'semantic-ui-react';
import { Translate } from 'react-redux-i18n';

import ManagedGrid from './managed';
import ContextMenu from './contextMenu';
import Column from './column';
import HeaderCell from './headerCell';
import RowCell from './rowCell';

import Search from './search';
import Pagination from './pagination';
import get from 'lodash.get';
import update from '../../../helpers/update';

import './grid.css';
import ToggleColumns from '../toggleColumns';

const multiSelectId = 'custom-multi-select';

class Grid extends Component {
  state = {};

  static defaultProps = {
    initialised: false,
    loading: false,
    data: []
  };

  static propTypes = {
    initialised: PropTypes.bool,
    loading: PropTypes.bool,
    data: PropTypes.array.isRequired
  };

  componentDidMount() {
    this.initScroll();
  }

  componentWillReceiveProps(newProps) {
    this.initScroll();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.children.length !== prevProps.children.length) {
      this.onToggleChange();
    }
    if (prevProps.loading) {
      if (this.scrollBox.scrollWidth > this.scrollBox.offsetWidth) {
        this.onToggleChange();
      }
    }
  }

  onToggleChange = () => {
    this.setFixedPosition(
      this.scrollBox.scrollLeft,
      this.scrollBox.scrollWidth,
      this.scrollBox.offsetWidth
    );
  };

  handleScroll = (event) => {
    this.setFixedPosition(
      event.target.scrollLeft,
      event.target.scrollWidth,
      event.target.offsetWidth
    );
  };

  onSortClick = (key) => {
    if (this.props.onSortClick) {
      this.props.onSortClick(key);
    }
  };

  onMultiSelectClick = (item, checked) => {
    if (this.props.onMultiSelectClick) {
      this.props.onMultiSelectClick(item, checked);
    }
  };

  setFixedPosition = (left, scrollWidth, width) => {
    if (left > 0) {
      if (!this.state.fixedLeftPosition) {
        this.setState({ fixedLeftPosition: true });
      }
    } else {
      if (this.state.fixedLeftPosition) {
        this.setState({ fixedLeftPosition: false });
      }
    }
    if (left + width + 1 > scrollWidth) {
      if (this.state.fixedRightPosition) {
        this.setState({ fixedRightPosition: false });
      }
    } else {
      if (!this.state.fixedRightPosition) {
        this.setState({ fixedRightPosition: true });
      }
    }
  };

  initScroll = () => {
    if (this.props.initialised && !this.state.scrollInited) {
      this.scrollBox.addEventListener('scroll', this.handleScroll);
      this.setFixedPosition(
        this.scrollBox.scrollLeft,
        this.scrollBox.scrollWidth,
        this.scrollBox.offsetWidth
      );
      this.setState({ scrollInited: true });
    }
  };

  createContextMenuEntry(contextMenu, gridItem) {
    if (contextMenu) {
      return (
        <ContextMenu.Impl.Container>
          {React.Children.toArray(contextMenu.props.children).map((item) => (
            <ContextMenu.Impl.Item
              {...item.props}
              key={item.key}
              id={gridItem.id}
              disabled={this.props.loading || item.props.disabled}
              item={gridItem}
            />
          ))}
        </ContextMenu.Impl.Container>
      );
    }
  }

  getLinkInfo = (col, item) => {
    if (!col || !item) {
      return;
    }

    const linkInfo = get(col, 'linkInfo');
    if (linkInfo) {
      if (linkInfo.getUrl) {
        return update.set(col.linkInfo, 'url', linkInfo.getUrl(item));
      }
      if (!linkInfo.initial) {
        throw new 'Please specify get url for clickable column'();
      }
    }
  };

  //define getAdditionalData function as used some lines below
  getAdditionalData = (col, item) => {
    if (!col || !item) {
      return;
    }
    if (col.datatype === 'decimal')
    {
      return { currency: get(item, 'currency') };
    }
  };


  render() {
    // prettier-ignore
    const { children, data, list, loading, initialised, sort } = this.props;
    const hasMultiSelect = !!list?.hasMultiSelect;
    const getDataFromRowItem = (key, item) => {
      if (typeof key === 'function') {
        return key(item);
      } else if (key === multiSelectId) {
        return (
          <Checkbox
            id={`checkbox-row-${item.id}`}
            onChange={(_, { checked }) =>
              this.onMultiSelectClick(item, checked)
            }
            checked={!!item?.template?.value?.isChecked}
          />
        );
      }
      return get(item, key);
    };

    const getColumnMapFromChildrenProps = (columns) =>
      columns
        ? columns
            .filter((item) => item.type === Column)
            .map((item) => item.props)
        : [];

    const getContextMenuFromChildrenProps = (columns) =>
      columns.find((item) => item.type === ContextMenu);

    const renderHeaderCell = (item) => {
      const sort =
        item.id && item.sortable && this.props.sort === item.id
          ? this.props.direction
          : null;
      return (
        <HeaderCell
          key={item.id}
          id={item.id}
          sort={sort}
          {...item}
          onSortClick={this.onSortClick}
          onMultiSelectClick={(_, { checked }) =>
            this.onMultiSelectClick(item, checked)
          }
        />
      );
    };

    const columns = getColumnMapFromChildrenProps(children);
    if (columns.length > 0 && hasMultiSelect) {
      const isMultiSelectChecked = !!list.isMultiSelectChecked;
      columns.unshift({
        id: multiSelectId,
        isHeader: true,
        hasMultiSelect: true,
        isMultiSelectChecked
      });
    }

    const contextMenu = getContextMenuFromChildrenProps(children);
    const fixedLeftPosition = this.state.fixedLeftPosition;
    const fixedRightPosition = this.state.fixedRightPosition;
    const isLoading = !initialised || loading;
    return (
      <div style={{ minHeight: '224px', position: 'relative', clear: 'both' }}>
        {isLoading && !this.props.isErrorOccured && (
          <div className="outer">
            <div className="overlay"></div>
            <div className="middle-table">
              <div className="middle-container">
                <Loader active inline="centered" size="small">
                  <Translate value="general.list.loading" />
                </Loader>
              </div>
            </div>
          </div>
        )}
        {
          <div
            className="grid list-grid table-body"
            ref={(e) => (this.scrollBox = e)}
          >
            {fixedLeftPosition && (
              <Table fixed className="fixed-columns fixed-left">
                <Table.Header>
                  <Table.Row>
                    {columns.map((item, idx) =>
                      idx === 0 ? renderHeaderCell(item) : ''
                    )}
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {data.map((item, idx) => (
                    <Table.Row key={idx}>
                      {columns.map((col, idx) => {
                        if (idx === 0) {
                          const colData = getDataFromRowItem(col.id, item);

                          if (this.props.columnRender) {
                            const customRenderCell = this.props.columnRender(
                              col,
                              colData,
                              item
                            );
                            if (customRenderCell) {
                              return customRenderCell;
                            }
                          }

                          return (
                            <RowCell
                              key={`${col.id}-${idx}`}
                              data={colData}
                              styles={col.styles}
                              {...col}
                            />
                          );
                        }
                        return null;
                      })}
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            {
              <Table fixed collapsing>
                <Table.Header>
                  <Table.Row>
                    {columns.map((item) => renderHeaderCell(item))}
                    {contextMenu && (
                      <Table.HeaderCell width={3}>
                        {contextMenu?.props?.columnHeader}
                      </Table.HeaderCell>
                    )}
                    {this.props.customColumn &&
                      this.props.customColumn().getHeader()}
                  </Table.Row>
                </Table.Header>
                {data.length > 0 && (
                  <Table.Body>
                    {data.map((item, idx) => (
                      <Table.Row key={idx}>
                        {columns.map((col, idx) => {
                          const isActiveSort = col.id === sort;
                          const colData = getDataFromRowItem(col.id, item);
                          if (this.props.columnRender) {
                            const customRenderCell = this.props.columnRender(
                              col,
                              colData,
                              item
                            );
                            if (customRenderCell) {
                              return customRenderCell;
                            }
                          }

                          return (
                            <RowCell
                              key={`${col.id}-${idx}`}
                              data={colData}
                              sorted={isActiveSort}
                              styles={col.styles}
                              {...col}
                              additionalData={this.getAdditionalData(col, item)}
                              linkInfo={this.getLinkInfo(col, item)}
                            />
                          );
                        })}
                        {this.props.customColumn &&
                          this.props.customColumn().getColumn(item)}
                        {contextMenu && (
                          <Table.Cell
                            collapsing
                            className="context-cell"
                            style={{ textAlign: 'right' }}
                          >
                            {
                              this.createContextMenuEntry(
                                contextMenu,
                                item
                              ) /* React.cloneElement(contextMenu, { id: item.id }) */
                            }
                          </Table.Cell>
                        )}
                      </Table.Row>
                    ))}
                  </Table.Body>
                )}
              </Table>
            }
            {fixedRightPosition && contextMenu && (
              <Table fixed className="fixed-columns fixed-right">
                <Table.Body>
                  {data.map((item, idx) => (
                    <Table.Row key={idx}>
                      {
                        <Table.Cell
                          collapsing
                          className="context-cell"
                          style={{ textAlign: 'right' }}
                        >
                          {
                            this.createContextMenuEntry(
                              contextMenu,
                              item
                            ) /* React.cloneElement(contextMenu, { id: item.id }) */
                          }
                        </Table.Cell>
                      }
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
            {data.length === 0 && !isLoading && (
              <div className="no-data">
                <div className="middle-table">
                  <div className="middle-container">No data</div>
                </div>
              </div>
            )}
          </div>
        }
      </div>
    );
  }
}

Grid.ContextMenu = ContextMenu;
Grid.Column = Column;
Grid.Search = Search;
Grid.ToggleColumns = ToggleColumns;
Grid.Pagination = Pagination;

Grid.Managed = ManagedGrid;

export default Grid;
