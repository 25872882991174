import config from '../../../../constants';

export const donationSummaryModalFields = {
  CURRENCY: 'currency'
};

export const donationSummaryModalValidation = {
  [donationSummaryModalFields.CURRENCY]: {
    required: true,
    maxLength: true,
    length: config.CURRENCY_MAX_LENGTH
  }
};
