import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';

// prettier-ignore
// get env vars and their default values
const enableCrashReporting = process.env.REACT_APP_RAYGUN_ENABLE_CRASH_REPORTING || true;
const enablePulse = process.env.REACT_APP_RAYGUN_ENABLE_PULSE || false;
const raygunApiKey = process.env.REACT_APP_RAYGUN_APY_KEY || '';

export const useRaygun = () => {
  const session = useSelector((state: RootState) => state.session);
  useEffect(() => {
    // @ts-ignore
    if (!session.account || !raygunApiKey) return;
    window.rg4js('setUser', {
      identifier: session.account.id,
      isAnonymous: false,
      firstName: session.account.firstName,
      fullName: `${session.account.firstName} ${session.account.lastName}`
    });
  }, [session]);
  useEffect(() => {
    if (!raygunApiKey) return;
    window.rg4js('apiKey', raygunApiKey);
    window.rg4js('enableCrashReporting', enableCrashReporting);
    window.rg4js('enablePulse', enablePulse);
  }, []);
};
