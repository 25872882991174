import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { Message } from 'semantic-ui-react';

import * as templateHelper from '../template/templateHelper';
import * as validationHelper from '../../helpers/validationHelper';
import update from '../../helpers/update';

import './index.css';

class CollectionBuilderContainer extends Component {
  onChange = (value) => {
    this.props.onChange(this.props.path, value);
  };

  onItemChange = (index, fieldKey, value) => {
    const newItems = this.getNewItems(index, fieldKey, value);

    this.onChange(newItems);
  };

  getNewItems(index, fieldKey, value) {
    let valueCopy = this.props.value;

    if (fieldKey === 'isDefault') {
      const prevDefault = valueCopy.findIndex(
        (item) => item.isDefault === true
      );
      valueCopy = update.set(valueCopy, `${prevDefault}.isDefault`, false);
    }

    valueCopy = update.set(valueCopy, `${index}.${fieldKey}`, value);

    return valueCopy;
  }

  onInnerItemChange = (outerIndex, ownIndex, fieldKey, value) => {
    const instancesPath = this.props.instancesPath;
    const changedValue = update(this.props.value, {
      [outerIndex]: {
        [instancesPath]: {
          [ownIndex]: {
            [fieldKey]: { $set: value },
            isEdited: { $set: true }
          }
        }
      }
    });
    this.onChange(changedValue);
  };

  getItems = () => {
    const items = this.props.value;
    const path = this.props.path;

    if (!items) {
      return null;
    }

    const renderItems = items.map((item, index) => {
      let children = [];
      const instancesPath = this.props.instancesPath;
      if (item[instancesPath] && item[instancesPath].length > 0) {
        children = this.getInnerItems(item[instancesPath], index);
      }
      children.push(
        <div
          key={index}
          className="add-item-container"
          onClick={() => this.onAddInnerItemClick(index)}
        >
          <span className="add-item-text">
            {I18n.t(
              `template.${this.props.entity}.content.${this.props.i18Key}.instance.add-new-item`
            )}
          </span>
        </div>
      );

      return React.cloneElement(this.props.itemType, {
        index,
        path,
        ...item,
        onChange: this.onItemChange,
        onDeleteClick: this.onDeleteClick,
        children: children
      });
    });

    return renderItems;
  };

  getInnerItems = (items, outerIndex) => {
    const renderItems = items.map((item, index) => {
      const { key, ...innerProps } = item;
      return React.cloneElement(this.props.innerItemBuilderType, {
        index,
        outerIndex,
        _key: key,
        ...innerProps,
        onChange: this.onInnerItemChange,
        onDeleteClick: this.onDeleteInnerItemClick
      });
    });

    return renderItems;
  };

  onAddItemClick = () => {
    if (
      this.props.value &&
      this.props.value.length === this.props.maxItemsCount
    ) {
      return;
    }

    let valueCopy = this.props.value || [];
    valueCopy = update(valueCopy, {
      $push: [this.props.model]
    });

    this.onChange(valueCopy);
  };

  onAddInnerItemClick = (outerIndex) => {
    const instancesPath = this.props.instancesPath;
    const instances = this.props.value[outerIndex][instancesPath];
    const innerModel = this.props.innerModel;

    let valueCopy;
    if (instances) {
      valueCopy = update(this.props.value, {
        [outerIndex]: {
          [instancesPath]: {
            $push: [innerModel]
          }
        }
      });
    } else {
      valueCopy = update(this.props.value, {
        [outerIndex]: {
          [instancesPath]: {
            $set: [innerModel]
          }
        }
      });
    }

    this.onChange(valueCopy);
  };

  onDeleteClick = (index) => {
    let valueCopy = this.props.value;
    const isDefaultRemoving = valueCopy[index].isDefault === true;
    valueCopy = update(valueCopy, {
      $splice: [[index, 1]]
    });
    if (isDefaultRemoving && valueCopy.length) {
      valueCopy = update.set(valueCopy, '0.isDefault', true);
    }
    this.onChange(valueCopy);
  };

  onDeleteInnerItemClick = (outerIndex, ownIndex) => {
    const instancesPath = this.props.instancesPath;
    const updatedValue = update(this.props.value, {
      [outerIndex]: {
        [instancesPath]: {
          $splice: [[ownIndex, 1]]
        }
      }
    });

    this.onChange(updatedValue);
  };

  render() {
    const showAddButton =
      !this.props.value ||
      !this.props.maxItemsCount ||
      this.props.value.length < this.props.maxItemsCount;
    const items = this.getItems();

    return (
      <div className="collection-container" disabled={this.props.disabled}>
        {items}
        {showAddButton && (
          <div className="add-item-container" onClick={this.onAddItemClick}>
            <span className="add-item-text">
              {I18n.t(
                `template.${this.props.entity}.content.${this.props.i18Key}.add-new-item`
              )}
            </span>
          </div>
        )}
        <Message negative hidden={this.props.isValid}>
          <p>{this.props.errorMessage}</p>
        </Message>
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value: value,
    isValid: validationHelper.isValid(ownProps.validation, value),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      value
    ),
    instancesPath: ownProps.instancesPath || 'instances'
  };
};

export const CollectionBuilder = connect(mapState)(CollectionBuilderContainer);
