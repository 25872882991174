// React / Redux / Related
import React, { Component, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { I18n } from 'react-redux-i18n';

// Actions
import * as campaignActions from '../store/campaignActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';

// Helpers
import RecordResultMessage from '../../../components/common/recordResult';
import { Record } from '../../../modules/bin/utility';
import clear from '../../../components/clear';
import { cloneDeep } from 'lodash';
import get from 'lodash.get';
import moment from 'moment';
import update from '../../../helpers/update';

// Components
import CampaignCloneTemplate from './template';
import BasePage from '../../../components/common/basePageView';
import Spinner from '../../../components/common/spinner';

// Constants
import config from '../../../constants/index';
import { campaignContract, templates } from '../create/templates';
import SavedEntityModal from '../../../components/common/savedEntityModal';
import Text from '../../../components/common/text';
import { dashboardViewTypeConfigurations } from '../../../pages/dashboard/constants';

// ----------------------------------------------------------------------

/** Clone the campaign **/
class CampaignClone extends Component {
  constructor(props) {
    super(props);

    const { id } = props.match.params;
    props.getRecord(id);

    this.state = {
      isSavedModalOpened: false,
      isSavedOperationFinished: false
    };
  }

  onSave(data, includeCampaignAdmins) {
    const { id } = this.props.match.params;
    this.props.cloneRecord(id, data, includeCampaignAdmins);
    this.setState({ isSavedOperationFinished: true });
  }

  clearMessages = () => {
    this.props.clearRecordCloneMessages();
  };

  goToCampaignView = () => {
    //this.props.history.push(config.DEFAULT_CAMPAIGNS_URL);
    if (this.state.isSavedOperationFinished === true)
      this.setState({
        isSavedModalOpened: true,
        isSavedOperationFinished: false
      });
  };

  isAllRecordsReady = () => {
    return Record.isRecordReady(this.props.record);
  };

  getSavedModalContent = () => {
    return (
      <Fragment>
        <Text>
          {I18n.t(`template.page.saved-modal.campaign-create-message`).replace(
            '{campaignName}',
            get(this.props, 'recordClone.data.name')
          )}
        </Text>
      </Fragment>
    );
  };
  getSavedModalButtons = () => {
    return (
      <Fragment>
        <Button
          className="primary"
          onClick={() => {
            window.open(get(this.props, 'recordClone.data.urlFull'), '_blank');
          }}
        >
          {I18n.t('template.page.saved-modal.campaign-view-button')}
        </Button>
        <Button
          onClick={() => this.props.history.push(config.DEFAULT_CAMPAIGNS_URL)}
        >
          {I18n.t('template.page.saved-modal.campaign-return-button')}
        </Button>
      </Fragment>
    );
  };

  getCustomContent = () => {
    const data = get(this.props, 'recordClone.data');
    return (
      <div className="link-content">
        <p
          className="Links"
          style={{ cursor: 'pointer' }}
          onClick={() =>
            this.props.history.push(
              dashboardViewTypeConfigurations.campaigns.getEditUrl(data.id)
            )
          }
        >
          {I18n.t('template.page.saved-modal.edit-new-campaign-button')}
        </p>
      </div>
    );
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const campaign = cloneDeep(get(this.props, 'record.data.data'));

    if (!this.props.isTemplateInited) {
      const templateOptions = templates[campaign.type.toLowerCase()]();

      let model = {};

      //time and place
      const date = get(campaign, 'timeAndPlace.time.start');
      const end = get(campaign, 'timeAndPlace.time.end');
      const timeDetails = get(campaign, 'timeAndPlace.time.text');
      const includesTime = get(campaign, 'timeAndPlace.time.includesTime');
      const place = get(campaign, 'timeAndPlace.place.text');

      const isTimeAndPlaceEnabled = !!(date || timeDetails || place);

      if (!isTimeAndPlaceEnabled) {
        templateOptions.hide('timeAndPlace');
        templateOptions.hide('dateAndTime');
        templateOptions.hide(campaignContract.allowTimeAndPlace);
        templateOptions.hide(campaignContract.place);
        templateOptions.hide(campaignContract.timeDetails);
      } else {
        model = update.set(
          model,
          campaignContract.allowTimeAndPlace,
          isTimeAndPlaceEnabled
        );
        model = update.set(
          model,
          'timeAndPlace',
          get(campaign, 'timeAndPlace')
        );

        if (date && moment(date).isValid()) {
          model = update.set(
            model,
            campaignContract.date,
            moment(date).format(config.DEFAULT_DATE_FORMAT)
          );
          model = update.set(
            model,
            campaignContract.time,
            includesTime
              ? moment(date).format(config.DEFAULT_TIME_FORMAT)
              : null
          );
        }
        if (end && moment(end).isValid()) {
          model = update.set(
            model,
            campaignContract.endDate,
            moment(end).format(config.DEFAULT_DATE_FORMAT)
          );
          model = update.set(
            model,
            campaignContract.endTime,
            includesTime
              ? moment(end).format(config.DEFAULT_TIME_FORMAT)
              : null
          );
        }
      }

      model = update.set(model, campaignContract.allowCoupons, false);

      templateOptions.setModel(model);
      this.props.setTemplateModel(templateOptions.getWithRule());
    }

    const isSaving = Record.isRecordLoading(this.props.recordClone);
    const successModalRedirectButtons = this.getSavedModalButtons();
    const customContent = this.getCustomContent();
    const successModalContent = this.getSavedModalContent();

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordClone}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToCampaignView}
        />

        <SavedEntityModal
          open={this.state.isSavedModalOpened}
          content={successModalContent}
          buttons={successModalRedirectButtons}
          onClose={this.onModalClose}
          customContent={customContent}
          isEdit={false}
        />

        <h1>
          <Translate value="campaign.clone.title" />
        </h1>
        <CampaignCloneTemplate
          onSave={this.onSave.bind(this)}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, templateBuilderEx }) => {
  const record = campaign.record || {};
  return {
    record,
    recordClone: campaign.recordClone,
    isTemplateInited: templateBuilderEx.inited
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(campaignActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch)
  };
};

/** Connects component to Redux store */
const CloneCampaignContainer = clear(
  connect(mapState, mapDispatch)(CampaignClone)
);
export default CloneCampaignContainer;
