import React from 'react';
import ColouredMark from '../../../components/common/colouredMark';
import { colouredMarks } from '../constants';

export const getColouredMarks = (metrics) => {
  return (
    <React.Fragment>
      {React.Children.toArray(
        metrics && colouredMarks.map((item) => (
          <ColouredMark
            {...item}
            svg={item.icon}
            value={item.getValue(metrics)}
          />
        ))
      )}
    </React.Fragment>
  );
};
