import React, { Component } from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { Translate } from 'react-redux-i18n';

export default class GeneralErrorModal extends Component {
  render() {
    return (
      <Modal
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={this.props.errors.length > 0}
      >
        <Modal.Header>
          <Translate value="errors.general.title" />
        </Modal.Header>
        <Modal.Content>
          {this.props.errors &&
            this.props.errors.map((item) => (
              <p>
                <Translate value={item.key} />
              </p>
            ))}
          <div className="button-wrapper align-right">
            <Button primary onClick={() => (window.location.href = '/')}>
              <Translate value="general.reload" />
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}
