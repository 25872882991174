import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import { Record as RecordConstants } from '../../../constants';
import * as campaignActions from '../store/campaignActions';
import { Common } from '../../../components';
import get from 'lodash.get';
import { Link, withRouter } from 'react-router-dom';
import * as generalActions from '../../../modules/general';
import RecordResultMessage from '../../../components/common/recordResult';
import { pageActions } from '../page/reducer';
import ListsViewContainer from './listsview';
import clear from '../../../components/clear';
import { gridKeys } from '../../../constants/gridKeys';
import config from '../../../constants/index';
import BasePage from '../../../components/common/basePageView';

class FundraiserView extends Component {
  constructor(props) {
    super(props);
    const paramId = get(props, 'match.params.id');
    props.getFundraiserRecord(paramId);
  }

  onEditClick() {
    const paramId = get(this.props, 'match.params.id');
    this.props.history.push(
      `/campaigns/fundraiser/edit/${paramId}/page-detail`
    );
  }

  onVisitPageClick() {
    const linkToPublicSite = get(this.props, 'record.data.data.urlFull');
    window.open(linkToPublicSite, '_blank');
  }

  clearMessages = () => {
    this.props.pageActions.clearRecordMessages('saveRecord');
  };

  render() {
    let recordData = null;

    const fundraiserId = get(this.props, 'match.params.id');
    const baseFilter = {
      key: 'fundraiserId',
      operator: 'EqualTo',
      value: fundraiserId
    };

    const hasLoadedRecord = this.props.record.ready;

    if (hasLoadedRecord === RecordConstants.RECORD_STATUS_READY) {
      recordData = this.props.record.data.data;
    }

    const headerData =
      hasLoadedRecord !== RecordConstants.RECORD_STATUS_READY
        ? { loaded: false, id: null, name: null }
        : {
            loaded: true,
            id: recordData.id,
            name: recordData.name,
            image:
              recordData.mainImagePath ||
              get(recordData, 'campaign.mainImagePath') ||
              get(recordData, 'event.mainImagePath') ||
              get(recordData, 'organization.mainImagePath'),
            team: recordData.team,
            campaignId: recordData.campaign.id,
            campaignName: recordData.campaign.name
          };

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />
        <div>
          <Common.PageHeader
            loading={!headerData.loaded}
            name={headerData.name}
            image={headerData.image}
            headerRightPanel={
              <div className="page-header-right-panel">
                <Common.Button onClick={this.onEditClick.bind(this)}>
                  <Translate value="campaign.list.view.context.edit-page" />
                </Common.Button>
                <Common.Button onClick={this.onVisitPageClick.bind(this)}>
                  <Translate value="campaign.list.view.context.view-public-page" />
                </Common.Button>
              </div>
            }
          >
            <Link to={config.DEFAULT_CAMPAIGNS_URL} push>
              <Common.PageHeader.Breadcrumb label="Campaigns" />
            </Link>
            <Link
              to={`/campaigns/view/donations/${headerData.campaignId}`}
              push
            >
              <Common.PageHeader.Breadcrumb label={headerData.campaignName} />
            </Link>
            {headerData.team && (
              <Link
                to={`/campaigns/${headerData.campaignId}/team/view/donations/${headerData.team.id}`}
                push
              >
                <Common.PageHeader.Breadcrumb label={headerData.team.name} />
              </Link>
            )}
            <Common.PageHeader.Breadcrumb label={headerData.name} />
          </Common.PageHeader>
        </div>

        <ListsViewContainer
          isTicketingAvailable={false}
          showFundraisers={false}
          baseFilter={baseFilter}
          filterPath={`/campaigns/${headerData.campaignId}/fundraiser/view`}
          viewType="fundraiser"
          salesGridKey={gridKeys.CAMPAIGN_SALES}
          ticketsGridKey={gridKeys.CAMPAIGN_TICKETS}
          merchandiseGridKey={gridKeys.CAMPAIGN_MERCHANDISE}
          fundraisersGridKey={gridKeys.PAGE_FUNDRAISERS}
          donationsGridKey={gridKeys.FUNDRAISER_DONATIONS}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ campaign, campaignPage }) => {
  const record = campaign.record;
  const saveRecord = campaignPage.saveRecord;

  return {
    record,
    saveRecord
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(campaignActions, dispatch);
  dispatchers.generalActions = bindActionCreators(generalActions, dispatch);
  dispatchers.pageActions = bindActionCreators(pageActions, dispatch);
  return dispatchers;
};

/** Connects component to Redux store */
const FundraiserViewContainer = withRouter(
  clear(connect(mapState, mapDispatch)(FundraiserView))
);
export default FundraiserViewContainer;
