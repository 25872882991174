import React, { Component } from 'react';
import { Dropdown, Popup } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

class ContextMenu extends Component {}

class ContextMenuItem extends Component {}

// ---------------------------
// Implementation

class ContextMenuImpl extends Component {
  render() {
    return (
      <Dropdown
        floating
        icon="ellipsis horizontal"
        pointing="top right"
        className="dot-context"
      >
        <Dropdown.Menu className="menu_custom">
          {this.props.children}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

class ContextMenuItemImpl extends Component {
  onContextClick() {
    if (this.props.onClick) {
      this.props.onClick(this.props.id, this.props.item);
    }
  }

  render() {
    const {
      id,
      item,
      params,
      contextMenuOptions,
      url,
      target,
      disabledPopupProps,
      isDisabled,
      isHiden,
      ...pprops
    } = this.props;

    const label = typeof this.props.label === "function" ? this.props.label(id, item) : this.props.label;

    const disabled =
      this.props.disabled || (isDisabled ? isDisabled(item) : false);

    if (isHiden && isHiden(item)) {
      return null;
    }

    const menuProps = {
      disabled,
      onClick: this.onContextClick.bind(this)
    };

    if (disabled && disabledPopupProps) {
      return (
        <Popup
          {...disabledPopupProps}
          trigger={
            <div
              role="option"
              aria-selected
              className="item"
              style={{ opacity: 0.45 }}
              aria-disabled="true"
            >
              {label}
            </div>
          }
        />
      );
    }

    if (contextMenuOptions) {
      if (!contextMenuOptions.getUrl) {
        return (
          <Dropdown.Item
            {...menuProps}
            as={'a'}
          >
            {label}
          </Dropdown.Item>
        );
      } else if (contextMenuOptions.target) {
        return (
          <Dropdown.Item
            {...menuProps}
            target={contextMenuOptions.target}
            as={'a'}
            href={contextMenuOptions.getUrl(item, params)}
          >
            {label}
          </Dropdown.Item>
        );
      } else {
        return (
          <Dropdown.Item
            {...menuProps}
            target={contextMenuOptions.target}
            as={Link}
            to={contextMenuOptions.getUrl(item, params)}
          >
            {label}
          </Dropdown.Item>
        );
      }
    }

    return (
      <Dropdown.Item {...pprops} {...menuProps}>
        {label}
      </Dropdown.Item>
    );
  }
}

// ---------------------------

ContextMenu.Impl = {};
ContextMenu.Impl.Container = ContextMenuImpl;
ContextMenu.Impl.Item = ContextMenuItemImpl;

ContextMenu.Item = ContextMenuItem;
export default ContextMenu;
