/*global google*/
import React from 'react';
import get from 'lodash.get';
import PlacesAutocomplete, {
  geocodeByPlaceId
} from 'react-places-autocomplete';
import {
  addresFields,
  cityGeocodingKeys,
  cityFields,
  geocodingKeys
} from '../../../constants/formBuilder';
import GeolocationService from '../../../modules/geolocationService';
import config from '../../../constants/index';
import { getSearchString } from '../formBuilderAnswersHelper';

class AddressAutocomplete extends React.Component {
  state = {
    searchOptions: {}
  };

  onBlur = () => {
    const selectedSearchString = getSearchString(this.props.value);
    if (
      !this.props.disableOnBlur &&
      selectedSearchString !== get(this.props, 'value.searchString')
    ) {
      this.props.onChange({
        ...this.props.value,
        searchString: selectedSearchString
      });
    }
  };

  findLatLonFromIP = () => {
    GeolocationService.findLatLonFromIP().then((response) => {
      if (response) {
        var lat = response.data.location.lat;
        var lng = response.data.location.lng;
        this.initGooglePlacesSearchOptions(lat, lng);
      }
    });
  };

  initGooglePlacesSearchOptions = (lat, lng) => {
    this.setState({
      searchOptions: {
        radius: config.GOOGLE_MAPS_STARTING_SEARCH_RADIUS,
        location: new google.maps.LatLng(lat, lng),
        types: this.props.allowFilteringByCities ? ['(cities)'] : undefined
      }
    });
  };

  componentDidMount() {
    if (navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          var lat = position.coords.latitude;
          var lng = position.coords.longitude;
          this.initGooglePlacesSearchOptions(lat, lng);
        },
        () => {
          this.findLatLonFromIP();
        }
      );
    }
  }

  handleChange = (address) => {
    this.props.onChange({
      ...this.props.value,
      searchString: address
    });
  };

  getAddressInfo(geocodeModel, address) {
    const addressInfo = {
      searchString: address
    };

    if (!geocodeModel) {
      return addressInfo;
    }

    Object.keys(addresFields).forEach((field) => {
      const gecodingKey = geocodingKeys[field];
      const fieldPath = addresFields[field];
      let geocodeValue = geocodeModel[gecodingKey];

      if (
        fieldPath === addresFields.STREET &&
        geocodeModel[geocodingKeys.STREET_NUMBER]
      ) {
        const subpremise = geocodeModel[geocodingKeys.SUBPREMISE]
          ? `${geocodeModel[geocodingKeys.SUBPREMISE]}/`
          : '';

        geocodeValue = `${subpremise}${
          geocodeModel[geocodingKeys.STREET_NUMBER]
        } ${geocodeValue}`;
      }

      addressInfo[fieldPath] = geocodeValue;
    });

    return addressInfo;
  }

  handleSelect = (address, placeId) => {
    geocodeByPlaceId(placeId).then((results) => {
      const geocodeModel = {};
      const components = get(results, '0.address_components');

      if (components && components.length) {
        results[0].address_components.forEach((item) => {
          if (this.props.allowFilteringByCities) {
            geocodeModel[item.types.join(' ')] = item.short_name;
          } else {
            geocodeModel[item.types.join(' ')] = item.long_name;
          }
        });

        const addressInfo = this.getAddressInfo(geocodeModel, address);
        const cityInfo = this.getCityInfo(geocodeModel, address);

        if (this.props.allowFilteringByCities) {
          this.props.onChange(cityInfo);
        } else {
          this.props.onChange(addressInfo);
        }
      }
    });
  };

  getCityInfo(geocodeModel, address) {
    const addressInfo = {
      searchString: address
    };

    if (!geocodeModel) {
      return addressInfo;
    }

    Object.keys(cityFields).forEach((field) => {
      const gecodingKey = cityGeocodingKeys[field];
      const fieldPath = cityFields[field];
      let geocodeValue = geocodeModel[gecodingKey];

      addressInfo[fieldPath] = geocodeValue;
    });

    return addressInfo;
  }

  render() {
    const searchString = get(this.props, 'value.searchString') || '';

    return (
      <PlacesAutocomplete
        value={searchString}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={this.state.searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div onBlur={this.onBlur}>
            <input
              id="formbuilder-address"
              {...getInputProps({
                placeholder: `${
                  this.props.removePlaceholder ? '' : 'Search Places ...'
                }`,
                className: 'location-search-input'
              })}
            />
            <div className="autocomplete-dropdown-container">
              {suggestions.map((suggestion) => {
                const className = suggestion.active
                  ? 'suggestion-item--active suggestion-item'
                  : 'suggestion-item';

                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
}

export default AddressAutocomplete;
