import React from 'react';

import { I18n } from 'react-redux-i18n';

export const DonationSectionBooleanRow = (props) => {
  const { title, data } = props;

  return (
    <React.Fragment>
      <div className="view-details-content-row">
        <h4>{I18n.t(title)}</h4>
        {data && <span>Yes</span>}
        {!data && <span>No</span>}
      </div>
    </React.Fragment>
  );
};
