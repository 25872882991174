const ON_VALUE_CHANGE = 'ticketing/ON_VALUE_CHANGE';

export const SALE_REFUND_REQUESTED = 'ticketing/SALE_REFUND_REQUESTED';
export const SALE_REFUND_SUCCESS = 'ticketing/SALE_REFUND_SUCCESS';
export const SALE_REFUND_FAILURE = 'ticketing/SALE_REFUND_FAILURE';

export const SALE_REFUND_AND_CANCEL_REQUESTED =
  'ticketing/SALE_REFUND_AND_CANCEL_REQUESTED';
export const SALE_REFUND_AND_CANCEL_SUCCESS =
  'ticketing/SALE_REFUND_AND_CANCEL_SUCCESS';
export const SALE_REFUND_AND_CANCEL_FAILURE =
  'ticketing/SALE_REFUND_AND_CANCEL_FAILURE';

export const TICKET_REFUND_REQUESTED = 'ticketing/TICKET_REFUND_REQUESTED';
export const TICKET_REFUND_SUCCESS = 'ticketing/TICKET_REFUND_SUCCESS';
export const TICKET_REFUND_FAILURE = 'ticketing/TICKET_REFUND_FAILURE';

export const MERCHANDISE_REFUND_REQUESTED = 'merchandise/MERCHANDISE_REFUND_REQUESTED';
export const MERCHANDISE_REFUND_SUCCESS = 'merchandise/MERCHANDISE_REFUND_SUCCESS';
export const MERCHANDISE_REFUND_FAILURE = 'merchandise/MERCHANDISE_REFUND_FAILURE';

export const REFUND_CONFIRMATION_SHOW = 'ticketing/REFUND_CONFIRMATION_SHOW';
export const REFUND_CONFIRMATION_HIDE = 'ticketing/REFUND_CONFIRMATION_HIDE';

export const RECEIPT_CONFIRMATION_SHOW = 'ticketing/RECEIPT_CONFIRMATION_SHOW';
export const RECEIPT_CONFIRMATION_HIDE = 'ticketing/RECEIPT_CONFIRMATION_HIDE';

export const SALE_RECEIPT_REQUESTED = 'ticketing/SALE_RECEIPT_REQUESTED';
export const SALE_RECEIPT_SUCCESS = 'ticketing/SALE_RECEIPT_SUCCESS';
export const SALE_RECEIPT_FAILURE = 'ticketing/SALE_RECEIPT_FAILURE';

export const TICKET_RECEIPT_REQUESTED = 'ticketing/TICKET_RECEIPT_REQUESTED';
export const TICKET_RECEIPT_SUCCESS = 'ticketing/TICKET_RECEIPT_SUCCESS';
export const TICKET_RECEIPT_FAILURE = 'ticketing/TICKET_RECEIPT_FAILURE';

export const GET_SALE_REQUESTED = 'ticketing/GET_SALE_REQUESTED';
export const GET_SALE_SUCCESS = 'ticketing/GET_SALE_SUCCESS';
export const GET_SALE_FAILURE = 'ticketing/GET_SALE_FAILURE';

export const CREATE_MANUAL_INSTALLMENT_REQUESTED = 'ticketing/CREATE_MANUAL_INSTALLMENT_REQUESTED';
export const CREATE_MANUAL_INSTALLMENT_SUCCESS = 'ticketing/CREATE_MANUAL_INSTALLMENT_SUCCESS';
export const CREATE_MANUAL_INSTALLMENT_FAILURE = 'ticketing/CREATE_MANUAL_INSTALLMENT_FAILURE';

// prettier-ignore
export const GET_SALE_RELATED_REQUESTED = 'ticketing/GET_SALE_RELATED_REQUESTED';
export const GET_SALE_RELATED_SUCCESS = 'ticketing/GET_SALE_RELATED_SUCCESS';
export const GET_SALE_RELATED_FAILURE = 'ticketing/GET_SALE_RELATED_FAILURE';

export const GET_TICKETS_REQUESTED = 'ticketing/GET_TICKETS_REQUESTED';
export const GET_TICKETS_SUCCESS = 'ticketing/GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'ticketing/GET_TICKETS_FAILURE';

export const CREATE_REQUESTED = 'ticketing/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'ticketing/CREATE_SUCCESS';
export const CREATE_FAILURE = 'ticketing/CREATE_FAILURE';

export const UPDATE_REQUESTED = 'ticketing/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'ticketing/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'ticketing/UPDATE_FAILURE';

export const GET_CAMPAIGN_REQUESTED = 'ticketing/GET_CAMPAIGN_REQUESTED';
export const GET_CAMPAIGN_SUCCESS = 'ticketing/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'ticketing/GET_CAMPAIGN_FAILURE';

export const GET_AVAILABLE_TICKETS_REQUESTED =
  'ticketing/GET_AVAILABLE_TICKETS_REQUESTED';
export const GET_AVAILABLE_TICKETS_SUCCESS =
  'ticketing/GET_AVAILABLE_TICKETS_SUCCESS';
export const GET_AVAILABLE_TICKETS_FAILURE =
  'ticketing/GET_AVAILABLE_TICKETS_FAILURE';

export const COUPON_CREATE_REQUESTED = 'ticketing/COUPON_CREATE_REQUESTED';
export const COUPON_CREATE_SUCCESS = 'ticketing/COUPON_CREATE_SUCCESS';
export const COUPON_CREATE_FAILURE = 'ticketing/COUPON_CREATE_FAILURE';

export const TICKET_CREATE_REQUESTED = 'ticketing/TICKET_CREATE_REQUESTED';
export const TICKET_CREATE_SUCCESS = 'ticketing/TICKET_CREATE_SUCCESS';
export const TICKET_CREATE_FAILURE = 'ticketing/TICKET_CREATE_FAILURE';

export const METADATA_REQUESTED = 'ticketing/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'ticketing/METADATA_SUCCESS';
export const METADATA_FAILURE = 'ticketing/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'ticketing/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'ticketing/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'ticketing/LIST_DATA_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'ticketing/TOGGLE_COLUMNS_CHANGE';
export const ACTIVE_TAB_SET = 'ticketing/ACTIVE_TAB_SET';

export const IS_REFUNDED_CLEAR = 'ticketing/IS_REFUNDED_CLEAR';
export const ERROR_CLEAR = 'ticketing/ERROR_CLEAR';
export const RECEIPT_MESSAGE_ERROR = 'ticketing/RECEIPT_MESSAGE_ERROR';

export const GET_EXPORT_FILE_REQUESTED = 'ticketing/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'ticketing/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'ticketing/GET_EXPORT_FILE_FAILURE';

export const GET_ORG_REQUESTED =
  'ticketing/GET_ORG_REQUESTED';
export const GET_ORG_SUCCESS =
  'ticketing/GET_ORG_SUCCESS';
export const GET_ORG_FAILURE =
  'ticketing/GET_ORG_FAILURE';
