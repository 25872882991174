export const pageTypes = {
  FUNDRAISER: '1',
  TEAM: '2',
  COLLECTION: '3'
};

export enum pageTypesStrings {
  FUNDRAISER = 'fundraiser',
  DONATION = 'donation',
  TEAM = 'team',
  COLLECTION = 'collection',
  DONATION_SUB = 'donation-subscription',
  CAMPAIGN = 'campaign',
  EVENT = 'event'
}

export const superteam = 'superteam';
