import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import mfa_monitor_icon from '../images/mfa_monitor.png';
import google_play_icon from '../images/google_play_icon.jpg';
import apple_store_icon from '../images/apple_store_icon.png';
import { securitySettingTabs, securityAuthLinks } from '../../../constants';

const StartTab = ({ handleActiveTabChange, forceMfa }) => {
  const session = useSelector((state) => state.session);
  const organization =
    session?.organization ||
    session?.campaigns?.find((x) => x.organization?.hasMfaActive)?.organization;

  const playStoreAuthLink = securityAuthLinks.PLAY_STORE;
  const appStoreAuthLink = securityAuthLinks.APP_STORE;

  const handleNextButtonClick = () => {
    handleActiveTabChange(securitySettingTabs.QR_CODE);
  };

  return (
    <Modal.Content>
      <div className="start-tab-monitor-container">
        <img alt="" src={mfa_monitor_icon} />
      </div>
      {forceMfa && (
        <div className="security-content">
          <span className="start-tab-content">
            <Translate
              value={'account.account-security.activateModal.forceMfa'}
              orgName={organization?.name}
            />
          </span>
        </div>
      )}
      <h3 className="security-sub-title">
        <Translate
          value={'account.account-security.activateModal.steps.start.title'}
        />
      </h3>
      <div className="security-content">
        <span className="start-tab-content">
          <Translate
            value={'account.account-security.activateModal.steps.start.content'}
          />
          <a target="_blank" href={playStoreAuthLink} rel="noreferrer">
            <Translate
              value={
                'account.account-security.activateModal.steps.start.playStoreLink'
              }
            />
          </a>
          <Translate
            value={
              'account.account-security.activateModal.steps.start.linkSeparator'
            }
          />
          <a target="_blank" href={appStoreAuthLink} rel="noreferrer">
            <Translate
              value={
                'account.account-security.activateModal.steps.start.appStoreLink'
              }
            />
          </a>
          .
        </span>
        <div className="start-tab-sub-images">
          <a target="_blank" href={playStoreAuthLink} rel="noreferrer">
            <img alt="" className="google-play-img" src={google_play_icon} />
          </a>
          <a target="_blank" href={appStoreAuthLink} rel="noreferrer">
            <img alt="" className="app-store-img" src={apple_store_icon} />
          </a>
        </div>
        <div className="get-started-button-container">
          <Button
            primary
            className="next-button"
            onClick={handleNextButtonClick}
          >
            <Translate
              value={
                'account.account-security.activateModal.steps.start.button'
              }
            />
          </Button>
        </div>
      </div>
    </Modal.Content>
  );
};

export default StartTab;
