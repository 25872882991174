import * as entityHandlers from '../../../modules/bin/entityHandlers';

import update from '../../../helpers/update';
import { CLEAR } from '../../../modules/general';
import { Record } from '../../../modules/bin/utility';

export const GET_FUNDRAISER_OPTIONS_REQUESTED =
  'giftMatching/GET_FUNDRAISER_OPTIONS_REQUESTED';
export const GET_FUNDRAISER_OPTIONS_SUCCESS =
  'giftMatching/GET_FUNDRAISER_OPTIONS_SUCCESS';
export const GET_FUNDRAISER_OPTIONS_FAILURE =
  'giftMatching/GET_FUNDRAISER_OPTIONS_FAILURE';

export const GET_TEAM_OPTIONS_REQUESTED =
  'giftMatching/GET_TEAM_OPTIONS_REQUESTED';
export const GET_TEAM_OPTIONS_SUCCESS = 'giftMatching/GET_TEAM_OPTIONS_SUCCESS';
export const GET_TEAM_OPTIONS_FAILURE = 'giftMatching/GET_TEAM_OPTIONS_FAILURE';

export const GET_COLLECTION_OPTIONS_REQUESTED =
  'giftMatching/GET_COLLECTION_OPTIONS_REQUESTED';
export const GET_COLLECTION_OPTIONS_SUCCESS =
  'giftMatching/GET_COLLECTION_OPTIONS_SUCCESS';
export const GET_COLLECTION_OPTIONS_FAILURE =
  'giftMatching/GET_COLLECTION_OPTIONS_FAILURE';

export const MESSAGE_CLEAR = 'giftMatching/MESSAGE_CLEAR';

export const clearRecordMessages = (record) => ({
  type: MESSAGE_CLEAR,
  payload: { key: record }
});

export const getFundraiserOptions = (
  path,
  campaignId,
  searchText,
  fundraiserId
) => {
  let filters = [
    {
      key: 'status',
      operator: 'EqualTo',
      value: [1]
    },
    {
      key: 'campaignId',
      operator: 'EqualTo',
      value: [campaignId]
    },
    {
      key: 'type',
      operator: 'EqualTo',
      value: ['fundraiser']
    }
  ];

  if (fundraiserId) {
    filters.push({
      key: 'fundraiserId',
      operator: 'InList',
      value: [fundraiserId]
    });
  }

  const request = {
    page: 1,
    pageSize: 20,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: filters
  };

  return entityHandlers.getListData(
    'page',
    path,
    null,
    GET_FUNDRAISER_OPTIONS_REQUESTED,
    GET_FUNDRAISER_OPTIONS_SUCCESS,
    GET_FUNDRAISER_OPTIONS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

export const getTeamOptions = (path, campaignId, searchText, teamId) => {
  let filters = [
    {
      key: 'status',
      operator: 'EqualTo',
      value: [1]
    },
    {
      key: 'campaignId',
      operator: 'EqualTo',
      value: [campaignId]
    },
    {
      key: 'type',
      operator: 'EqualTo',
      value: ['team']
    }
  ];

  if (teamId) {
    filters.push({
      key: 'teamId',
      operator: 'InList',
      value: [teamId]
    });
  }

  const request = {
    page: 1,
    pageSize: 20,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: filters
  };

  return entityHandlers.getListData(
    'page',
    path,
    null,
    GET_TEAM_OPTIONS_REQUESTED,
    GET_TEAM_OPTIONS_SUCCESS,
    GET_TEAM_OPTIONS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

export const getCollectionOptions = (
  path,
  campaignId,
  searchText,
  collectionId
) => {
  let filters = [
    {
      key: 'status',
      operator: 'EqualTo',
      value: [1]
    },
    {
      key: 'campaignId',
      operator: 'EqualTo',
      value: [campaignId]
    },
    {
      key: 'type',
      operator: 'EqualTo',
      value: ['collection']
    }
  ];

  if (collectionId) {
    filters.push({
      key: 'collectionId',
      operator: 'InList',
      value: [collectionId]
    });
  }

  const request = {
    page: 1,
    pageSize: 20,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: filters
  };

  return entityHandlers.getListData(
    'page',
    path,
    null,
    GET_COLLECTION_OPTIONS_REQUESTED,
    GET_COLLECTION_OPTIONS_SUCCESS,
    GET_COLLECTION_OPTIONS_FAILURE,
    request,
    false,
    null,
    'v3'
  );
};

const initialState = {};

// State Reducer
const giftMatchingReducer = (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case GET_FUNDRAISER_OPTIONS_REQUESTED:
    case GET_TEAM_OPTIONS_REQUESTED:
    case GET_COLLECTION_OPTIONS_REQUESTED:
      newState = update.set(
        state,
        action.payload.key,
        Record.getDefaultState()
      );
      newState = entityHandlers.getRecordRequestedHandler(newState, action);
      break;

    case GET_FUNDRAISER_OPTIONS_SUCCESS:
    case GET_TEAM_OPTIONS_SUCCESS:
    case GET_COLLECTION_OPTIONS_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case MESSAGE_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;
    default:
      return newState;
  }

  return newState;
};

export default giftMatchingReducer;
