import React from 'react';

import { TemplatePage } from '../../../../components/common/pager';
import TemplateDependentOrganisations from '../../../../components/template/templateDependentOrganisations';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateText } from '../../../../components/template/templateText';
import { paths } from '../../constants/template';

import { CSVBulkUpload } from './csvBulkUpload';

export interface OrganizationsTabProps {
  isTiersEnabled: boolean;
  tierNames?: string[];
  onChange: (path: string, value: any) => void;
}

export const getOrganizationsTab = (props: OrganizationsTabProps) => {
  return (
    <TemplatePage
      id="organisations"
      i18nKey="event.template.page.oragnisations.title"
    >
      <div className="template-heading">
        <TemplateHeader
          as="h3"
          label="event.template.page.oragnisations.charities.title"
        />
        <TemplateText
          label="event.template.page.oragnisations.charities.label"
          size="small"
        />
      </div>
      <CSVBulkUpload
        listPath={paths.ORGANIZATIONS}
        onChange={props.onChange}

      />
      <TemplateDependentOrganisations
        path={paths.ORGANIZATIONS}
        onChange={props.onChange}
        isTiersEnabled={props.isTiersEnabled}
        tiersNames={props.tierNames}
      />
    </TemplatePage>
  );
};
