import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import { useAppDispatch } from '../../../hooks';
import { RootState } from '../../../store';
import { Record } from '../../../modules/bin/utility';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../../containers/app';
import { TemplatePage } from '../../../components/common/pager';
import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateText } from '../../../components/template/templateText';
import { setResultValue } from '../../../modules/template-builder-ex';
import { DonationDataForm } from './form/DonationDataForm';
import { FundraiserDataForm } from './form/FundraiserDataForm';
import {
  clearRecordMessages,
  getEventActiveIntegration,
  updateEventActiveIntegration
} from '../store/eventActions';
import { IActiveIntegration } from '../../../models/ActiveIntegration';
import { TemplateInput } from '../../../components/template/templateInput';
import { activeIntegrationType } from '../constants/from';

const hasEnabledSettings = (data: any) => {
  return data?.settings?.filter((x: any) => x.enabled).length === 0;
};

export const ActiveIntegrationContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<RouteParams>();
  const [isLoading, setIsLoading] = useState(false);
  const [currentForm, setCurrentForm] = useState('');
  const event = useSelector((state: RootState) => state.event);
  const template = useSelector((state: RootState) => state.templateBuilderEx);
  const activeIntegration = (template?.data as any)?.activeIntegration;
  const isFundraiserDisabled = hasEnabledSettings(
    event?.activeIntegration?.data?.fundraiserSetup
  );
  const isDonationDisabled = hasEnabledSettings(
    event?.activeIntegration?.data?.donationSetup
  );

  const onCheckboxChange = (path: string, value: any) => {
    dispatch(setResultValue(path, value));
  };

  const onPublish = ({
    activeIntegration
  }: {
    activeIntegration: IActiveIntegration;
  }) => {
    dispatch(
      updateEventActiveIntegration(id, {
        phoneNumberColumnName: activeIntegration.phoneNumberColumnName,
        fundraiserSetup: activeIntegration.fundraiserSetup,
        donationSetup: activeIntegration.donationSetup
      })
    );
  };

  const onSave = (
    type: string,
    item: IActiveIntegration,
    updateIndex?: number
  ) => {
    let newData = activeIntegration[type];
    if (!newData) {
      newData = {
        enabled: true,
        settings: []
      };
    }

    if (updateIndex && updateIndex > -1) {
      newData.settings[updateIndex] = item;
    } else {
      newData.settings = [...(newData.settings || []), item];
    }

    dispatch(setResultValue(`activeIntegration.${type}`, newData));
  };

  const onDelete = (type: string, itemIndex: number) => {
    let newData = activeIntegration[type];
    newData.settings[itemIndex].enabled = false;
    // check to see if all items have been deleted, if so set to false
    const allDeleted = hasEnabledSettings(newData);
    if (allDeleted) {
      newData.enabled = false;
    }
    dispatch(setResultValue(`activeIntegration.${type}`, newData));
  };

  useEffect(() => {
    // prettier-ignore
    dispatch(setResultValue('activeIntegration', event.activeIntegration?.data));
    const isError = Record.isError(event.activeIntegration);
    setIsLoading(!isError && Record.isRecordLoading(event.activeIntegration));
  }, [event.activeIntegration]);

  useEffect(() => {
    dispatch(getEventActiveIntegration(id));
  }, []);

  return (
    <BasePage isLoading={isLoading}>
      <RecordResultMessage
        record={event?.activeIntegration}
        onDismiss={() => dispatch(clearRecordMessages('activeIntegration'))}
      />

      <TemplateBuilderContainerEx
        hideMenu
        onSave={onPublish}
        isLoading={isLoading}
      >
        <TemplatePage id="setup">
          <div className="template-heading">
            <TemplateHeader
              as="h3"
              label="event.template.page.active-integration.header"
            />

            {event?.activeIntegration?.data?.event?.name && (
              <TemplateText
                text={event?.activeIntegration.data.event.name}
                size="small"
              />
            )}
          </div>

          <TemplateHeader
            as="h3"
            label="event.template.page.active-integration.fundraiser.header"
          />

          <TemplateCheckbox
            id="fundraising-enabled-checkbox"
            path="activeIntegration.fundraiserSetup.enabled"
            inputLabel="event.template.page.active-integration.fundraiser.enable-checkbox-label"
            onChange={onCheckboxChange}
            disabled={isFundraiserDisabled}
          />

          <FundraiserDataForm
            eventId={id}
            showForm={currentForm === activeIntegrationType.fundraiser}
            setShowForm={(show) =>
              setCurrentForm(show ? activeIntegrationType.fundraiser : '')
            }
            onSave={(item, updateIndex) => {
              onSave(activeIntegrationType.fundraiser, item, updateIndex);
            }}
            onDelete={(itemIndex) => {
              onDelete(activeIntegrationType.fundraiser, itemIndex);
            }}
          />

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="event.template.page.active-integration.donation.header"
          />

          <TemplateCheckbox
            id="donation-enabled-checkbox"
            path="activeIntegration.donationSetup.enabled"
            inputLabel="event.template.page.active-integration.donation.enable-checkbox-label"
            onChange={onCheckboxChange}
            disabled={isDonationDisabled}
          />

          <DonationDataForm
            eventId={id}
            showForm={currentForm === activeIntegrationType.donation}
            setShowForm={(show) =>
              setCurrentForm(show ? activeIntegrationType.donation : '')
            }
            onSave={(item, updateIndex) => {
              onSave(activeIntegrationType.donation, item, updateIndex);
            }}
            onDelete={(itemIndex) => {
              onDelete(activeIntegrationType.donation, itemIndex);
            }}
          />

          <TemplateHorizontalRule />

          <TemplateInput
            id="fundraising-enabled-checkbox"
            path="activeIntegration.phoneNumberColumnName"
            label="event.template.page.active-integration.phone-number.label"
            onChange={onCheckboxChange}
          />
        </TemplatePage>
      </TemplateBuilderContainerEx>
    </BasePage>
  );
};
