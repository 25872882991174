import { I18n } from 'react-redux-i18n';

interface IDonationSubscriptionSettingsOptions {
  text: string;
  value: number;
}

const donationSubscriptionSettingsOptions: IDonationSubscriptionSettingsOptions[] = [{
  text: " - ",
  value: 0
}, {
  text: I18n.t("template.organisation.content.donation-forms.receipt.options.first-donation-only"),
  value: 1
}, {
  text: I18n.t("template.organisation.content.donation-forms.receipt.options.all-donations"),
  value: 2
}]

export enum DonationSubscriptionSettingsTypes {
  ALL_DONATIONS_AS_NORMAL = "allDonationsAsNormal",
  ALL_DONATIONS_AS_NORMAL_FIRST_RECEIPT = "allDonationsAsNormalFirstReceipt"
}

export enum DonationSubscriptionSettingsReceiptTypes {
  SEND_ALL = "sendAll",
  SEND_ON_FIRST_DONATION_ONLY = "sendOnFirstDonationOnly",
  NO_RECEIPTS = "noReceipts"
}

export interface IDonationSubscriptionSettingsValues {
  type: DonationSubscriptionSettingsTypes;
  receipt: DonationSubscriptionSettingsReceiptTypes;
}

const donationSubscriptionSettingsValues: Array<IDonationSubscriptionSettingsValues | null> = [
  null,
  { type: DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL_FIRST_RECEIPT, receipt: DonationSubscriptionSettingsReceiptTypes.SEND_ON_FIRST_DONATION_ONLY },
  { type: DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL, receipt: DonationSubscriptionSettingsReceiptTypes.SEND_ALL }
]

function getDonationSubscriptionSettingsValue(value?: number | null): IDonationSubscriptionSettingsValues | null {
  if (!value || typeof value !== "number") return null;
  return donationSubscriptionSettingsValues[value];
}

function parseToDonationSubscriptionSettingsValue(value?: IDonationSubscriptionSettingsValues | null): number {
  if (!value?.type || !value?.receipt) return 0;
  if (value?.type === DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL_FIRST_RECEIPT && value?.receipt === DonationSubscriptionSettingsReceiptTypes.SEND_ON_FIRST_DONATION_ONLY) return 1;
  if (value?.type === DonationSubscriptionSettingsTypes.ALL_DONATIONS_AS_NORMAL && value?.receipt === DonationSubscriptionSettingsReceiptTypes.SEND_ALL) return 2;
  return 0;
}

interface UseDonationSubscriptionSettingsProps {
  value: IDonationSubscriptionSettingsValues | null;
  onChange: (path: string, value: IDonationSubscriptionSettingsValues | null) => void
}

interface UseDonationSubscriptionSettingsApi {
  value: number;
  onChange: (path: string, value: number) => void;
  getOptions: () => IDonationSubscriptionSettingsOptions[];
}

export function useDonationSubscriptionSettings({ value, onChange }: UseDonationSubscriptionSettingsProps): UseDonationSubscriptionSettingsApi {

  const handleOnChange = (path: string, value: number) => {
    onChange(path, getDonationSubscriptionSettingsValue(value));
  };

  const handleGetOptions = () => donationSubscriptionSettingsOptions;

  return {
    value: parseToDonationSubscriptionSettingsValue(value),
    onChange: handleOnChange,
    getOptions: handleGetOptions
  }
}
