import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

import * as validationHelper from '../../../helpers/validationHelper';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { reset, searchEntities } from '../../../modules/autocomplete';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';

import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';

import { TARGET_VALIDATION } from '../../../constants/validationModels';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { paths } from './constants/template';

class TransferDonationTemplateView extends Component {
  constructor(props) {
    super(props);

    this.disableSameCampaign = {
      required: true,
      disableSameCampaign: true,
      campaignId: this.props.donationCampaignId
    };

    this.required = {
      required: true
    };

    this.customCheck = {
      sameEntity: true
    };

    this.amount = {
      required: true,
      ...TARGET_VALIDATION
    };
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  onPageChange = (path, value, item) => {
    if (value) {
      const separatorIndex = value.indexOf('_');
      const id = value.substring(separatorIndex + 1, value.length);
      const type = value.substring(0, separatorIndex);

      if (type === pageTypesStrings.FUNDRAISER) {
        this.props.clearControl(paths.TEAM_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.FUNDRAISER_ID, id);
      } else if (type === pageTypesStrings.TEAM) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.COLLECTION_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.TEAM_ID, id);
      } else if (type === pageTypesStrings.COLLECTION) {
        this.props.clearControl(paths.FUNDRAISER_ID);
        this.props.clearControl(paths.TEAM_ID);
        this.onChange(paths.PAGE_TYPE, type);
        this.onChange(paths.COLLECTION_ID, id);
      }
    } else {
      this.props.clearControl(paths.FUNDRAISER_ID);
      this.props.clearControl(paths.PAGE_TYPE);
      this.props.clearControl(paths.TEAM_ID);
      this.props.clearControl(paths.COLLECTION_ID);
      this.onChange(paths.PAGE_TYPE, 'campaign');
    }

    this.onChange(path, value);
  };

  getPageRequest = (campaignId) => {
    if (!this.props.selectedCampaignId && !campaignId) {
      return;
    }

    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'campaignId',
          operator: 'EqualTo',
          value: campaignId || this.props.selectedCampaignId
        },
        {
          key: 'isCollectionOrFundraiserOrTeam',
          operator: 'EqualTo',
          value: 'true'
        }
      ]
    };
  };

  getPageItem = (item) => {
    return {
      text: item.name,
      subtext: item.urlPath,
      value:
        item.type +
        '_' +
        (item.fundraiserId || item.teamId || item.collectionId)
    };
  };

  onCampaignChange = (path, value, item) => {
    const isCampaignValid = validationHelper.isValid(
      this.disableSameCampaign,
      value
    );

    if (value && isCampaignValid) {
      this.props.resetAutocomlite(paths.PAGE_ID);
      this.props.showControl(paths.PAGE_ID);
      this.props.searchEntities(
        paths.PAGE,
        paths.PAGE_ID,
        this.getPageRequest(value)
      );
    } else {
      this.props.hideControl(paths.PAGE_ID);
    }

    this.onChange(path, value, item);
  };

  getCampaignItem = (item) => {
    if (item.organization) {
      return {
        text: item.name,
        subtext: `${item.organization.name}`,
        value: item.id
      };
    } else {
      return {
        text: item.name,
        value: item.id
      };
    }
  };

  getCampaignRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        { key: 'status', operator: 'EqualTo', value: 1 },
        {
          key: 'organizationId',
          operator: 'EqualTo',
          value: this.props.donationOrganizationId
        }
      ]
    };
  };

  render() {
    const validationErrorMessage = validationHelper.getValidationErrorMessage(
      this.disableSameCampaign,
      this.props.selectedCampaignId
    );

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage id="setup" i18nKey="template.page.setup">
          <div className="donation-details">
            <TemplateHeader
              as="h3"
              label="template.page.content.donation.transfer-donation.donationDetailsSection.header"
            />

            <div className="donation-details-content">
              <Translate value="template.page.content.donation.transfer-donation.donationDetailsSection.content" />
            </div>
          </div>

          <TemplateInput
            id="donorFirstName"
            path={paths.CUSTOMER_FIRST_NAME}
            label="general.firstName"
            placeholder="general.firstName"
          />

          <TemplateInput
            id="donorLastName"
            path={paths.CUSTOMER_LAST_NAME}
            label="general.lastName"
            placeholder="general.lastName"
          />

          <TemplateInput
            id="donorEmail"
            path={paths.CUSTOMER_EMAIL}
            label="general.emailAddress"
            placeholder="general.emailAddress"
          />

          <TemplateCurrency
            id="donation-amount"
            path={paths.TARGET_AMOUNT}
            validation={this.amount}
            label="template.donation.content.detail.amount.label"
            allowDecimal
          />

          <TemplateHorizontalRule />

          <TemplateHeader
            as="h3"
            label="template.page.content.donation.transfer-donation.donationCampaignDetails.header"
          />

          <TemplateAutocomplete
            id="donation-campaign"
            entity="page/campaign"
            path={paths.CAMPAIGN_ID}
            selected="campaign"
            label="template.donation.content.detail.campaign.label"
            placeholder="template.donation.content.detail.campaign.placeholder"
            validation={this.disableSameCampaign}
            onChange={this.onCampaignChange}
            request={this.getCampaignRequest}
            getItem={this.getCampaignItem}
            subtext={
              'template.page.content.donation.transfer-donation.campaign-autocomplete-subtext'
            }
            validationErrorMessage={validationErrorMessage}
          />

          <TemplateAutocomplete
            id="donation-page"
            entity="page"
            path={paths.PAGE_ID}
            selected="page"
            label="template.donation.content.detail.page.label"
            placeholder="template.donation.content.detail.page.placeholder"
            onChange={this.onPageChange}
            request={this.getPageRequest}
            getItem={this.getPageItem}
          />

          <TemplateHorizontalRule />

          <div className="notes-details">
            <div className="notes-details-title">
              <Translate value="template.page.content.donation.transfer-donation.donationNotes.header" />
            </div>
            <div className="list-details">
              <li>
                <Translate value="template.page.content.donation.transfer-donation.donationNotes.list-items.item-1" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation.donationNotes.list-items.item-2" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation.donationNotes.list-items.item-3" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation.donationNotes.list-items.item-4" />
              </li>
              <li>
                <Translate value="template.page.content.donation.transfer-donation.donationNotes.list-items.item-5" />
              </li>
            </div>
          </div>
        </TemplatePage>
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  return {
    donationCampaignId: state.donation.record.data.data.campaignId,
    donationOrganizationId: state.donation.record.data.data.organizationId,
    selectedCampaignId: state.templateBuilderEx.data.campaignId
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    resetAutocomlite(path) {
      dispatch(reset(path));
    },
    searchEntities(entity, path, request) {
      dispatch(searchEntities(entity, path, request));
    }
  };
};

const TransferDonationTemplate = connect(
  mapState,
  mapDispatch
)(TransferDonationTemplateView);
export default TransferDonationTemplate;
