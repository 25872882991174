//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from './templateHelper';
import get from 'lodash.get';

//------------Components
import AboutElementsBuilder from '../common/aboutElementsBuilder';

class TemplateAboutElementsBuilder extends Component {
  onChange = (data) => {
    this.props.onChange(this.props.path, data);
  };

  render() {
    const { label, subtext, disabled, path } = this.props;
    return (
      <AboutElementsBuilder
        path={path}
        label={label}
        subtext={subtext}
        sessionKey={this.props.sessionKey}
        entity={this.props.entity}
        onChange={this.onChange}
        disabled={disabled}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    sessionKey: state.session.key,
    initialValidation: get(state, 'templateBuilderEx.isEdit'),
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export default connect(mapState)(TemplateAboutElementsBuilder);
