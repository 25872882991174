export const parseQuery = (queryString) => {
  var query = {};
  if (queryString) {
    var pairs = (
      queryString[0] === '?' ? queryString.slice(1) : queryString
    ).split('&');

    for (var i = 0; i < pairs.length; i++) {
      var pair = pairs[i].split('=');
      query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
    }
  }
  return query;
};

export const appendQueryParam = (url, obj) => {
  const str = [];
  let hasExistingParams = false;
  Object.keys(obj).forEach((v) => {
    if (obj[v]) {
      str.push(`${v}=${obj[v]}`);
    }
  });

  try {
    const urlTemp = new URL(url);
    hasExistingParams = !!urlTemp.search;
  } catch (e) {
    hasExistingParams = url.includes('?');
  }

  return `${url}${hasExistingParams ? '&' : '?'}${str.join('&')}`;
};
