import React, { Component } from 'react';
import { connect } from 'react-redux';

import { cardActions } from '../../modules/card';
import * as templateHelper from './templateHelper';
import config from '../../constants';

import { StripeProvider, Elements } from 'react-stripe-elements';
import { Message, Loader } from 'semantic-ui-react';
import MakePaymentForm from '../common/donateElements/checkoutForm';

class ManagedCard extends Component {
  _mounted = false;
  state = {
    stripe: null
  };

  componentDidMount() {
    this._mounted = true;
    if (typeof window !== 'undefined') {
      const stripeJs = document.createElement('script');
      stripeJs.src = 'https://js.stripe.com/v3/';
      stripeJs.async = true;
      stripeJs.onload = () => {
        if (this._mounted) {
          const stripe = window.Stripe(config.STRIPE_PUBLIC_KEY, {
            stripeAccount: this.props.accountKey
          });
          this.setState({
            stripe: stripe
          });
        }
      };
      stripeJs.onerror = () => {
        this.setState({
          stripe: null
        });
      };
      document.body && document.body.appendChild(stripeJs);
    } else {
      this.setState({
        stripe: null
      });
    }
    window.scrollTo(0, 0);
  }

  componentWillUnmount() {
    this._mounted = false;
    this.props.onChange(`${this.props.path}.complete`, null);
    this.props.onChange(`${this.props.path}.error`, null);
    this.props.clear();
  }

  render() {
    return (
      <div>
        {!this._mounted && <Loader active inline className="centered" />}
        {this.state.stripe && (
          <StripeProvider stripe={this.state.stripe}>
            <Elements>
              <MakePaymentForm
                required={
                  this.props.validation && this.props.validation.required
                }
                label={this.props.label}
                path={this.props.path}
                donor={this.props.donor}
                onChange={this.props.onChange}
                makePayment={this.props.makePayment}
              />
            </Elements>
          </StripeProvider>
        )}
        {!this.state.stripe && this._mounted && (
          <Message negative>
            <Message.Header>
              We're sorry. Card can not be connected at the time.
            </Message.Header>
            <p className="error">
              Our team is already working on fixing the issue.
            </p>
          </Message>
        )}
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label)
  };
};

const mapDispatch = (dispatch, ownProps) => {
  return {
    clear: () => {
      dispatch(cardActions.clear(ownProps.path));
    }
  };
};

const TemplateCard = connect(mapState, mapDispatch)(ManagedCard);
export default TemplateCard;
