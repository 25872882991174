import React from 'react';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

export const DonationAddressSectionRow = (props) => {
  const { title, data, isShowEmptyFields } = props;

  const city = get(data, 'city');
  const state = get(data, 'state');
  const street = get(data, 'street');
  const postCode = get(data, 'postcode');

  const isAddressExist = city || state || street || postCode;

  return (
    <React.Fragment>
      {(isAddressExist || isShowEmptyFields) && (
        <div className="view-details-content-row column">
          <h4>{I18n.t(title)}</h4>
          <div className="column-content">
            <span>{street}</span>
            <span>{state}</span>
            <span>
              {city} {postCode}
            </span>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
