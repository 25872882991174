// React / Redux / Related
import React, { Component, Fragment } from 'react';
import { Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import * as generalActions from '../../../modules/general';
import { I18n } from 'react-redux-i18n';

import { Message } from 'semantic-ui-react';

// Actions
import * as campaignActions from '../store/campaignActions';

// Helpers
import get from 'lodash.get';
import RecordResultMessage from '../../../components/common/recordResult';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { Record } from '../../../modules/bin/utility';
import Spinner from '../../../components/common/spinner';
import { templates, campaignContract } from './templates';
import CampaignTemplate from './templateEx';
import { formBuilderActions } from '../../../components/formbuilder/store';
import BasePage from '../../../components/common/basePageView';
import clear from '../../../components/clear';
import { organizationActions } from '../../organization/store/organizationActions';
import {
  getDefaultAboutElements,
  getDefaultFundraiserAndTeamAboutElements,
  getDefaultThankYouElements
} from '../../../helpers/pageAboutHelper';
import config from '../../../constants/index';
import {
  ticketIssuingStyle,
  joiningExperience,
  paymentFeeCalculation,
  donationType
} from '../constants';
import SavedEntityModal from '../../../components/common/savedEntityModal';
import Text from '../../../components/common/text';
import { isArrayWithItems } from '../../../helpers/arrayHelper';
import { FitnessContractValues, FitnessContract } from "./fitness/constants"

// ----------------------------------------------------------------------

/** Create a new campaign **/
class CampaignCreate extends Component {
  constructor(props) {
    super(props);

    if (props.isOrganizationAdmin) {
      props.getOrganizationRecord(get(props, 'organization.id'));
    }
  }

  onSave(data) {
    this.props.createRecord(data);
  }

  clearMessages = () => {
    this.props.clearRecordCreateMessages();
  };

  onErrorClose() {
    this.props.clearError();
  }

  getTemplateOptions(key) {
    return templates[key]();
  }

  getSavedModalContent = () => {
    const couponAndTicketModelErrors = this.props.campaignRequestErrors.errors;
    const showErrors = isArrayWithItems(couponAndTicketModelErrors);

    const ticketError =
      couponAndTicketModelErrors &&
      couponAndTicketModelErrors.find(
        (item) => item.payload.error[0] === 'ticketing.ticket.failed'
      );
    const couponError =
      couponAndTicketModelErrors &&
      couponAndTicketModelErrors.find(
        (item) => item.payload.error[0] === 'ticketing.coupon.failed'
      );

    return (
      <Fragment>
        {showErrors && (
          <div className="save-modal-error">
            {ticketError && (
              <Message negative>
                {I18n.t(`error-messages.${ticketError.payload.error[0]}`)}
              </Message>
            )}
            {couponError && (
              <Message negative>
                {I18n.t(`error-messages.${couponError.payload.error[0]}`)}
              </Message>
            )}
          </div>
        )}
        {!showErrors && (
          <Text>
            {I18n.t(
              `template.page.saved-modal.campaign-create-message`
            ).replace(
              '{campaignName}',
              get(this.props, 'recordCreate.data.name')
            )}
          </Text>
        )}
      </Fragment>
    );
  };

  getSavedModalButtons = () => {
    return (
      <Fragment>
        <Button
          className="primary"
          onClick={() => {
            window.open(get(this.props, 'recordCreate.data.urlFull'), '_blank');
          }}
        >
          {I18n.t('template.page.saved-modal.campaign-view-button')}
        </Button>
        <Button
          onClick={() => this.props.history.push(config.DEFAULT_CAMPAIGNS_URL)}
        >
          {I18n.t('template.page.saved-modal.campaign-return-button')}
        </Button>
      </Fragment>
    );
  };

  render() {
    if (
      this.props.isOrganizationAdmin &&
      !Record.isSuccess(this.props.organizationRecord)
    ) {
      return <Spinner />;
    }

    const isLoading =
      Record.isRecordLoading(this.props.recordCreate) ||
      Record.isRecordLoading(this.props.recordComplete);

    const templateKey =
      this.props.match.params.template2 || this.props.match.params.template1;

    if (!this.props.isTemplateInited) {
      const options = this.getTemplateOptions(templateKey);

      const about = options.getValue(campaignContract.about);
      if (!about || typeof about.elements === 'undefined') {
        options.setValue(campaignContract.about, getDefaultAboutElements());
      }

      const defaultFundraiserAbout = options.getValue(
        campaignContract.defaultFundraiserAbout
      );
      options.setValue(
        campaignContract.defaultFundraiserAbout,
        getDefaultFundraiserAndTeamAboutElements(defaultFundraiserAbout)
      );

      const defaultTeamAbout = options.getValue(
        campaignContract.defaultTeamAbout
      );
      options.setValue(
        campaignContract.defaultTeamAbout,
        getDefaultFundraiserAndTeamAboutElements(defaultTeamAbout)
      );

      const defaultCollectionAbout = options.getValue(
        campaignContract.defaultCollectionAbout
      );
      options.setValue(
        campaignContract.defaultCollectionAbout,
        getDefaultFundraiserAndTeamAboutElements(defaultCollectionAbout)
      );

      const defaultThankYouElements = options.getValue(
        campaignContract.ticketingThankYou
      );
      options.setValue(
        campaignContract.ticketingThankYou,
        getDefaultThankYouElements(defaultThankYouElements)
      );
      options.setValue(
        campaignContract.ticketIssuingStyle,
        ticketIssuingStyle.GroupedToBuyer
      );
      options.setValue(campaignContract.simpleTicketingEnabled, false);
      options.setValue(
        campaignContract.paymentFeeCalculation,
        paymentFeeCalculation.onTopOfTicketPrice
      );
      options.hide(campaignContract.purchaseTicketUrl);

      if (this.props.isOrganizationAdmin) {
        const orgPrimaryColor = get(
          this.props.organizationRecord,
          'data.data.template.value.primaryColor'
        );
        const orgSecondaryColor = get(
          this.props.organizationRecord,
          'data.data.template.value.secondaryColor'
        );

        options.hide('organizationId');
        options.setValue(
          campaignContract.primaryColor,
          orgPrimaryColor || '#FF0000'
        );
        options.setValue(
          campaignContract.secondaryColor,
          orgSecondaryColor || '#0000FF'
        );
        options.setValue('organizationId', this.props.organization.id);

        const paymentPlatformId = get(
          this.props,
          'organizationRecord.data.data.paymentPlatforms.0.id'
        );

        if (paymentPlatformId) {
          options.setValue('organizationPaymentPlatformId', paymentPlatformId);
        }
      }
      options.setValue(campaignContract.invertPrimaryTextColor, false);
      options.setValue(campaignContract.invertSecondaryTextColor, false);

      options.hide(campaignContract.couponsInformation);
      options.hide(campaignContract.coupons);

      //collections
      options.setValue(
        campaignContract.joiningExperience,
        joiningExperience.adminOnly
      );
      options.hide(campaignContract.collectionsGeneralName);
      options.hide(campaignContract.allowCollectionDonations);
      options.hide(campaignContract.allowCollectionSales);
      options.hide(campaignContract.enableCollectionPageManager);
      options.hide(campaignContract.joiningExperience);
      options.hide(campaignContract.isCollectionRequired);
      options.hide(campaignContract.disableCollectionsPublicPages);

      //recurring donations
      options.setValue(
        campaignContract.allowedDonationTypes,
        donationType.recurringAndOnceOff
      );
      options.setValue(campaignContract.disableDonationRecurringGiving, false);
      options.setValue(campaignContract.disableDonationOnceOffGiving, false);
      options.hide('recurring-donation-info-onlyOnceOff');
      options.hide('recurring-donation-info-onlyRecurring');

      options.setValue(
        campaignContract.allowedSaleDonationTypes,
        donationType.recurringAndOnceOff
      );
      options.setValue(campaignContract.disableSaleRecurringGiving, false);
      options.setValue(campaignContract.disableSaleOnceOffGiving, false);
      options.hide('recurring-sale-info-onlyOnceOff');
      options.hide('recurring-sale-info-onlyRecurring');

      // fundraising resources
      options.hide(campaignContract.fundraisersResourcesPage);
      options.setValue(campaignContract.fundraisersResourcesPageEnabled, false);

      //merchandise
      options.setValue(campaignContract.allowMerchandise, false);
      options.hide(campaignContract.merchandiseTab);
      options.hide(campaignContract.merchandise);

      //terms, conditions & waivers
      options.hide(campaignContract.termsAndConditionSetupTab);
      options.hide(campaignContract.termsAndConditionsCheckbox);
      options.hide(campaignContract.singleUserRegistrationMode);

      //fitness
      FitnessContractValues.forEach((path) => {
        if (path === FitnessContract.fitnessEnabled) return;
        options.hide(path);
      });

      //time and place
      options.hide('dateAndTime');
      options.hide(campaignContract.timeDetails);
      options.hide(campaignContract.place);

      //custom extra fields
      options.hide(campaignContract.coordinatorFirstName);
      options.hide(campaignContract.coordinatorLastName);
      options.hide(campaignContract.coordinatorEmail);
      options.hide(campaignContract.coordinatorPhone);

      // custom activities fields
      options.hide(campaignContract.customActivitiesValue1Name);
      options.hide(campaignContract.customActivitiesValue1Unit);
      options.hide(campaignContract.customActivitiesValue1CampaignTargetField);
      options.hide(
        campaignContract.customActivitiesValue1FundraiserDefaultTargetField
      );
      options.hide(
        campaignContract.customActivitiesValue1TeamDefaultTargetField
      );
      options.hide(campaignContract.customActivitiesValue1LeaderBoardEnabled);
      options.hide(campaignContract.customActivitiesValue1ThermometerEnabled);
      options.hide(campaignContract.customActivitiesValue1Svg);

      // prettier-ignore
      if (this.props.isSystemAdmin && !!options.getValue(campaignContract.allowCustomUrlCheckbox)) {
        options.show(campaignContract.customUrlCheckboxPath);
        // prettier-ignore
        const urlPathEnabled = get(this.props.organizationRecord, campaignContract.customUrlCheckboxPath);
        if (urlPathEnabled === undefined) {
          options.setValue(campaignContract.customUrlCheckboxPath, true);
          options.hide(campaignContract.customUrlPath);
        } else if (!urlPathEnabled) {
          // if use campaign url un-ticked, show URL Path
          options.show(campaignContract.customUrlPath);
        }
      }

      if (this.props?.record?.data?.data?.ticketing?.installments?.enabled) {
        options.show(campaignContract.installmentCountOptions);
        options.show(campaignContract.ticketInstallmentFrequency);
        options.show(campaignContract.ticketInstallmentNewSalesUntil);
      } else {
        options.hide(campaignContract.installmentCountOptions);
        options.hide(campaignContract.ticketInstallmentFrequency);
        options.hide(campaignContract.ticketInstallmentNewSalesUntil);
      }

      // donation setup
      // always hide for create
      options.hide(campaignContract.returnUrl);

      this.props.setTemplateModel(options.getWithRule());
    }

    // Get an errors if they exist.
    const successModalRedirectButtons = this.getSavedModalButtons();
    const successModalContent = this.getSavedModalContent();

    return (
      <BasePage>
        {!isLoading && (
          <RecordResultMessage
            record={this.props.recordCreate}
            onDismiss={this.clearMessages}
          />
        )}

        <SavedEntityModal
          open={this.props.saveModalOpen}
          content={successModalContent}
          buttons={successModalRedirectButtons}
          onClose={this.onModalClose}
          isEdit={false}
        />

        <h1>
          <Translate value="campaign.create.title" />
        </h1>

        <CampaignTemplate
          onSave={this.onSave.bind(this)}
          isLoading={isLoading}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({
  general,
  campaign,
  templateBuilderEx,
  session,
  organization
}) => {
  const lang = general.languages.current;
  const templates = campaign.templates;
  const record = campaign.record || {};

  return {
    lang,
    templates,
    record,
    recordCreate: campaign.recordCreate,
    saveModalOpen: campaign.saveModalOpen,
    campaignRequestErrors: campaign.campaignRequestErrors,
    recordComplete: campaign.recordComplete,
    isTemplateInited: templateBuilderEx.inited,
    isOrganizationAdmin: session.isOrganizationAdmin,
    isSystemAdmin: session.isSystemAdmin,
    organization: session.organization,
    organizationRecord: organization.record
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(campaignActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch),
    ...bindActionCreators(generalActions, dispatch),
    getOrganizationRecord: bindActionCreators(
      organizationActions.getOrganizationRecord,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

/** Connects component to Redux store */
const CreateCampaignContainer = clear(
  connect(mapState, mapDispatch)(CampaignCreate)
);
export default CreateCampaignContainer;
