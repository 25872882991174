import { formatQuantity } from '../../campaign/create/merchandise/merchandise.helpers';
import getValue from 'lodash.get';
import moment from 'moment';
import { Filter, FilterOperatorType } from './filter';

export const DownloadStatus = {
  // an export that a user has requested to download but the download has not started
  Queued: 'queued',
  // an export that is in the process of being downloaded
  InProgress: 'inProgress',
  // has downloaded successfully and is still within the expiry timeframe
  Ready: 'ready',
  // the timeframe has expired for this export and the link is no longer available
  Deleted: 'deleted',
  Expired: 'expired',
  // an error occurred while generating the export
  Error: 'error'
} as const;
export type DownloadStatusKey = keyof typeof DownloadStatus;
export type DownloadStatusType = (typeof DownloadStatus)[DownloadStatusKey];

export const ExportHistoryNames = ['saleDetail'] as const;
export type ExportHistoryType = (typeof ExportHistoryNames)[number];

export interface IExportHistory {
  id?: number;
  completed?: boolean;
  deleted?: boolean;
  entity?: string;
  resourceUrl?: string;
  resultCount?: number;
  timezone?: string | null;
  createdAtLocal?: string;
  createdAt?: string;
}

export class ExportHistory {
  private _values: IExportHistory;
  private _filters: Filter[];

  constructor(values: IExportHistory) {
    this._values = values;
    this._filters = this._getFilters;
  }

  private get _getFilters(): Filter[] {
    const filters = getValue(this._values, 'filters.filters');
    if (Array.isArray(filters)) {
      return (filters as []).map((item) => new Filter(item));
    }
    return [];
  }

  get id(): number {
    return getValue(this._values, 'id', -1);
  }

  get filters(): Filter[] {
    return this._filters;
  }

  get entity(): string {
    return getValue(this._values, 'entity', '');
  }

  get resourceUrl(): string {
    return getValue(this._values, 'resourceUrl', '');
  }

  get total(): string {
    return formatQuantity(getValue(this._values, 'resultCount', ''));
  }

  get status(): DownloadStatusType {
    return getValue(this._values, 'status') || DownloadStatus.Queued;
  }

  get date(): string {
    const date = getValue(this._values, 'createdAt', '');
    if (typeof date === 'string') {
      const d = moment(date).parseZone();
      if (moment().diff(d, 'days') > 1) {
        return moment(d).format('DD/MM/YYYY');
      }
      return moment(d).fromNow();
    }
    return '';
  }

  get text(): string {
    const entity = getValue(this._values, 'entity', '');
    switch (entity) {
      case 'sale': {
        return 'Sales';
      }
      case 'saleDetail': {
        const ticket = this._hasFilterValue('ticket') ? ', Tickets' : '';
        const merchandise = this._hasFilterValue('merchandise')
          ? ', Merchandise'
          : '';
        const dates = this._soldAtLocalRangeText;
        const amounts = this._amountRangeText;
        return `Sales${ticket}${merchandise}${dates}${amounts}`;
      }
      case 'organization': {
        const dates = this._soldAtLocalRangeText;
        const amounts = this._amountRangeText;
        return `Organizations${dates}${amounts}`;
      }
      case 'campaign': {
        const dates = this._soldAtLocalRangeText;
        const amounts = this._raisedAmountRangeText;
        return `Campaigns${dates}${amounts}`;
      }
      case 'page': {
        const dates = this._soldAtLocalRangeText;
        const amounts = this._amountRangeText;
        let active = '';
        // TODO: add status for /campaigns/live, /campaigns/complete & /campaigns/archived
        if (this._hasFilterKey('isActive')) {
          active +=
            this.getFilter('isActive', 'equalTo')?.value[0] === 'true'
              ? ' - active'
              : ' - inactive';
        }
        return `Fundraising pages${active}${dates}${amounts}`;
      }
      case 'report': {
        const dates = this._localDateRangeText;
        const amounts = this._amountRangeText;
        const organization = this._organizationId;
        return `Reports${organization}${dates}${amounts}`;
      }
      case 'event':
      case 'eventAmbassadorProgram': {
        return `VIP programs`;
      }
      case 'eventAmbassador': {
        // no status in filter
        let response = 'VIP applications';

        if (this._hasFilterValue('Received')) {
          response += ' - pending approval';
        } else if (
          this._hasFilterValue('Accepted') &&
          this._hasFilterValue('Claimed')
        ) {
          response += ' - accepted and claimed';
        }

        return response;
      }
      case 'donationSubscription': {
        return 'Recurring giving';
      }
      case 'donation': {
        return 'Donations';
      }
      case 'account': {
        return 'Accounts';
      }
      default:
        return 'download';
    }
  }

  getFilter(key: string, operator: FilterOperatorType): Filter | undefined {
    return this._filters?.find?.(
      (item) => item.isKey(key) && item.isOperator(operator)
    );
  }

  getRangeFilterText(key: string): string {
    const before = this.getFilter(key, 'lesserOrEqualThan');
    const after = this.getFilter(key, 'greaterOrEqualThan');
    if (before && after) {
      return `, ${after.date} - ${before.date}`;
    }
    if (before) {
      return `, to ${before.date}`;
    }
    if (after) {
      return `, from ${after.date}`;
    }
    return '';
  }

  private get _organizationId(): string {
    const filter = this.getFilter('organizationId', 'equalTo');
    if (filter) {
      return ` for organization,`;
    }
    return '';
  }

  private get _localDateRangeText(): string {
    return this.getRangeFilterText('LocalDate');
  }

  private get _soldAtLocalRangeText(): string {
    return this.getRangeFilterText('soldAtLocal');
  }

  private get _amountRangeText(): string {
    return this.getRangeFilterText('amount');
  }

  private get _raisedAmountRangeText(): string {
    return this.getRangeFilterText('raisedAmount');
  }

  private _hasFilterValue(type: string): boolean {
    return !!this._filters?.find?.((item) => item.isFilterValue(type));
  }

  private _hasFilterKey(key: string): boolean {
    return !!this._filters?.find?.((item) => item.key === key);
  }
}
