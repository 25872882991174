import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';

class FormbuilderDropdown extends React.Component {
  render() {
    const {
      value,
      onChange,
      placeholder,
      options,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;

    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <Dropdown
          id="formbuilder-dropdown"
          selection
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          options={options}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderDropdown;
