//---------------React/Redux
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import get from 'lodash.get';

//---------------Actions
import * as uploadActions from '../../../../modules/upload';
import { bytesToSize } from '../../../../helpers/converterHelper';

//-------------Helpers
import update from '../../../../helpers/update';
import * as validationHelper from '../../../../helpers/validationHelper';

//---------------Components
import {
  Message,
  Icon,
  Image,
  Loader,
  Dimmer,
  Form,
  Input
} from 'semantic-ui-react';
import Dropzone from 'react-dropzone';
import { TemplateRadioGroup } from '../../../template/templateRadioGroup';

//---------------Constansts
import { imageTypes } from '../../../../constants/imageUpload';
import { PDFDeliveryType, PDFDeliveryTypeOptions } from '../constants';
import config from '../../../../constants';

//---------------Other
import PDFUploadedImage from '../../../../images/PDFUploaded.png';

class AboutPDFUploadContainer extends React.Component {
  state = {
    currentFile: null
  };

  onDrop = (files) => {
    if (files && files.length === 1) {
      this.props.uploadFileV2(
        this.props.entity,
        imageTypes.ASSET,
        null,
        this.props.guid,
        files[0]
      );
      this.setState({ currentFile: files[0] });
    }
  };

  onRemoveFile = () => {
    this.setState({ currentFile: null });
    this.props.uploadRemove(this.props.guid);
    this.props.onValueChange(null);
  };

  isRemoveButtonVisible = (isRecordLoading) => {
    return !isRecordLoading && this.props.value;
  };

  getPDFObject = () => {
    return {
      fileUrl: this.props.fileUrl,
      name: this.state.currentFile.name,
      size: this.state.currentFile.size,
      description: this.state.currentFile.name,
      deliveryType: PDFDeliveryType.openInNewTab
    };
  };

  handleValueChange = (path, value) => {
    const valueCopy = update.set(this.props.value, path, value);
    this.props.onValueChange(valueCopy);
  };

  render() {
    const { processing, fileUrl } = this.props;
    if (!processing && fileUrl) {
      this.props.onValueChange(this.getPDFObject());
      this.props.uploadRemove(this.props.guid);
    }

    const descriptionValidationModel = {
      maxLength: true,
      length: config.PDF_FILE_DESCRIPTION_MAX_LENGTH
    };
    const isDescriptionValid = validationHelper.isValid(
      descriptionValidationModel,
      get(this.props.value, 'description')
    );
    const descriptionErrorMessage = validationHelper.getValidationErrorMessage(
      descriptionValidationModel,
      get(this.props.value, 'description')
    );

    return (
      <div className="pdf-upload-container">
        {!this.props.value && (
          <div className="pdf-upload-inner-container">
            <Dropzone
              className="avatar-dropzone-cont"
              accept=".pdf"
              onDrop={this.onDrop}
            >
              <div className="update-uploaded-inner">
                <div className="update-dropzone cropper-add-image-box">
                  <div className="update-dropzone-inner">
                    {processing && (
                      <Dimmer active inverted>
                        <Loader />
                      </Dimmer>
                    )}
                    {!processing && <Icon name="add" />}
                  </div>
                </div>
              </div>
            </Dropzone>
            <div className="select-image-text">
              <div className="select-image-text-inner">
                <h4>Select a PDF file from your computer</h4>
                File size must not exceed {config.maxPDFSize}MB
              </div>
            </div>
          </div>
        )}
        {this.isRemoveButtonVisible(processing) && (
          <React.Fragment>
            <div className="pdf-upload-inner-container">
              <div className="pdf-upload-container pdf-upload-with-remove-container">
                <Icon
                  className="pdf-remove-file-icon"
                  color="red"
                  name="remove circle"
                  size="large"
                  onClick={this.onRemoveFile}
                />
                <Image
                  className="pdf-uploaded-picture"
                  src={PDFUploadedImage}
                />
              </div>
              <div className="select-image-text">
                <div className="select-image-text-inner">
                  <h4>{this.props.value.name}</h4>
                  {bytesToSize(this.props.value.size)}
                </div>
              </div>
            </div>
            <div className="pdf-upload-info-container">
              <Form.Field>
                <label>Delivery type</label>
                <TemplateRadioGroup
                  value={this.props.value.deliveryType}
                  path="deliveryType"
                  onChange={this.handleValueChange}
                  options={PDFDeliveryTypeOptions}
                />
              </Form.Field>
              <Form.Field>
                <label>Description</label>
                <Input
                  fluid
                  value={this.props.value.description}
                  onChange={(event, { value }) => {
                    this.handleValueChange('description', value);
                  }}
                />
                <Message negative hidden={isDescriptionValid}>
                  <p>{descriptionErrorMessage}</p>
                </Message>
              </Form.Field>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapState = ({ upload }, ownProps) => {
  return {
    processing: get(upload, `record.state.${ownProps.guid}.processing`),
    fileUrl: get(upload, `record.state.${ownProps.guid}.value`)
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(uploadActions, dispatch)
  };
};

const AboutPDFUpload = connect(mapState, mapDispatch)(AboutPDFUploadContainer);
export default AboutPDFUpload;
