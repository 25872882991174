import {
  getAvatarImagePath,
  getSmallHeroImagePath,
  getAboutImageUrl,
  getGridCellImageUrl
} from '../helpers/imageUrlHelper';

export const entityTypes = {
  CAMPAIGN: 'campaign',
  FUNDRAISER: 'fundraiser',
  TEAM: 'team',
  PAGE: 'page',
  ORGANIZATION: 'organization',
  ACCOUNT: 'account',
  EVENT: 'event'
};

export const imageTypes = {
  LOGO: 'logo',
  HERO_IMAGE: 'heroImage',
  AVATAR: 'avatar',
  ASSET: 'asset',
  THANK_YOU: 'thankYou',
  CELL: 'cell',
  BALK_DATA: 'bulkData',
  PDF: 'pdf'
};

export const imageAspectRatio = {
  [imageTypes.AVATAR]: 1,
  [imageTypes.LOGO]: 1,
  [imageTypes.HERO_IMAGE]: 4.29
};

export const resizerMethods = {
  [imageTypes.AVATAR]: getAvatarImagePath,
  [imageTypes.LOGO]: getAvatarImagePath,
  [imageTypes.HERO_IMAGE]: getSmallHeroImagePath,
  [imageTypes.ASSET]: getAboutImageUrl,
  [imageTypes.THANK_YOU]: getAboutImageUrl,
  [imageTypes.CELL]: getGridCellImageUrl
};

export const getFullImagePathByType = (imagePath, uploadType) => {
  const resizerMethod = resizerMethods[uploadType];
  if (resizerMethod && imagePath) {
    return resizerMethod(imagePath);
  }
  return '';
};

export const imageFormats = ['JPEG', 'PNG', 'JPG'];

export const iconFormats = ['SVG+XML'];
