import React, { Component } from 'react';
import { Translate } from 'react-redux-i18n';

import logo from '../../images/logo.svg';
import './authentication.css';

class Frame extends Component {
  render() {
    const footerText = `© ${new Date().getFullYear()} Grassrootz`;

    return (
      <div className="auth-page">
        <div className="logo-cont">
          <img src={logo} className="logo" alt="logo" />
        </div>
        <div>
          {this.props.error && (
            <div
              key={this.props.error.id}
              id={this.props.error.id}
              className="authentication-error hide-me"
            >
              <Translate value={this.props.error.key} />
            </div>
          )}
          <div className="authentication-frame">
            <div className="authentication-inner">{this.props.children}</div>
          </div>
        </div>
        <div className="copyright">
          <p>{footerText}</p>
        </div>
      </div>
    );
  }
}

export default Frame;
