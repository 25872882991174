import Constants from '../../../../../constants';

const BaseUrl = `${Constants.baseApiHost}/api/v2/Merchandise/`

export const MerchandiseUrls = {
  Search: `${BaseUrl}search`,
  Create: BaseUrl,
  Update: BaseUrl,
  Remove: BaseUrl,
}
