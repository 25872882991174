import React, { useState } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, Checkbox, Modal } from 'semantic-ui-react';
import SettingsManager, {
  SETTINGS_KEYS
} from '../../modules/bin/settingsManager';

interface ExportModalProps {
  submitting?: boolean;
  onClose: () => void;
  onSubmit: () => void;
}

export const ExportModal: React.FC<ExportModalProps> = ({
  submitting,
  onClose,
  onSubmit
}) => {
  const [hideModal, setHideModal] = useState(false);

  const onSubmitClick = () => {
    if (hideModal) {
      SettingsManager.setSetting(SETTINGS_KEYS.EXPORT, 'hideModal', hideModal);
    }
    onSubmit();
  };

  return (
    <Modal
      open
      closeIcon
      size="small"
      onClose={onClose}
      style={{
        textAlign: 'center'
      }}
    >
      <Modal.Header
        style={{
          textAlign: 'center'
        }}
      >
        Data file export
      </Modal.Header>
      <Modal.Content
        style={{
          textAlign: 'center',
          paddingTop: 0
        }}
      >
        <Modal.Description>
          <Translate
            value="account.export-history.modal.description"
            dangerousHTML
            {...{ link: '/export-history' }}
          />
        </Modal.Description>
        <Modal.Description>
          <Checkbox
            style={{ paddingTop: '20px' }}
            label={I18n.t('account.export-history.modal.checkbox')}
            onChange={(_, data) => setHideModal(!!data.checked)}
          />
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions
        style={{
          textAlign: 'center'
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button primary loading={submitting} onClick={onSubmitClick}>
          Queue export
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
