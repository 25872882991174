//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Helpers
import * as templateHelper from '../templateHelper';
import update from '../../../helpers/update';
import get from 'lodash.get';

//------------Components
import { CouponBuilder } from '../discountCouponBuilder';

//------------Constants
import {
  SaleCouponUpperBound,
  SaleCouponDiscountType,
  SaleCouponAppliesTo
} from '../../collectionBuilder/constants';
import config from '../../../constants';
import { couponPaths } from '../discountCouponBuilder/constants/paths';

class ManagedCouponBuilder extends Component {
  onChange = (data, path) => {
    const couponName = get(data, 'name');

    if (couponName && couponName.trim().length > config.COUPON_KEY_MAX_LENGTH) {
      data = update.set(
        data,
        couponPaths.name,
        couponName.substring(0, config.COUPON_KEY_MAX_LENGTH)
      );
    }

    this.props.onChange(path || this.props.path, data);
  };

  getModel = () => {
    return {
      name: `Coupon for ${this.props.entityName}`,
      enabled: true,
      allowOtherCouponsInSameSale: false,
      appliesTo: SaleCouponAppliesTo.TotalAmount,
      discountType: SaleCouponDiscountType.AsPercentage,
      upperBound: SaleCouponUpperBound.NoLimit,
      discountTypeValue: 0,
      instances: [
        {
          code: '',
          enable: false
        }
      ]
    };
  };

  render() {
    const { path, disabled, validation } = this.props;

    return (
      <CouponBuilder
        path={path}
        model={this.getModel()}
        onChange={this.onChange}
        disabled={disabled}
        couponModel={this.props.couponModel}
        validation={validation}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

export const TemplateCouponBuilder = connect(mapState)(ManagedCouponBuilder);
