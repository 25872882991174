import React, { Component } from 'react';
import { Form, Dropdown } from 'semantic-ui-react';

class BooleanItem extends Component {
  render() {
    const { path, value, onChange } = this.props;
    const options = [
      {
        text: 'No',
        value: false
      },
      {
        text: 'Yes',
        value: true
      }
    ];

    return (
      <Form.Group grouped>
        <Dropdown
          fluid
          options={options}
          path={path}
          onChange={onChange}
          value={value}
          selection
          placeholder="Select..."
          icon="chevron down"
        />
      </Form.Group>
    );
  }
}

export default BooleanItem;
