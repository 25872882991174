import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Menu } from 'semantic-ui-react';

interface MenuItem {
  name: string;
  description: string;
  isActive: boolean;
  onClick?: () => void;
}

interface VerticalMenuProps {
  menuItems: MenuItem[];
}

export const VerticalMenu = ({ menuItems }: VerticalMenuProps) => {
  return (
    <Menu vertical fluid>
      {menuItems.map((x) => (
        <Menu.Item name={x.name} active={x.isActive} as="a" onClick={x.onClick}>
          <Translate value={x.description} />
        </Menu.Item>
      ))}
    </Menu>
  );
};
