/*  eslint-disable jsx-a11y/anchor-is-valid */
/*  eslint-disable jsx-a11y/no-static-element-interactions */
/*  eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Button, Icon, Form } from 'semantic-ui-react';
import { Translate, I18n } from 'react-redux-i18n';
import { head } from 'ramda';
import PropTypes from 'prop-types';
import get from 'lodash.get';
import AnimateHeight from 'react-animate-height';

import Text from './componentFields/Text';
import TextArea from './componentFields/TextArea';
import Dropdown from './componentFields/Dropdown';
import Checkbox from './componentFields/Checkbox';
import CheckboxWithConditional from './componentFields/CheckboxWithConditional';

import { componentDescriptors } from '../../descriptors';
import { fieldViewTypes, fieldsVisibility } from '../../typeDefinitions';
import { updateNode } from '../../formBuilderHelpers';
import { editNode } from '../../actionCreators';

const translate = I18n.t.bind(I18n);
/**
 * This function renders one Field with the given viewType.
 * It passes props to the rendered Field.
 * @param {string} viewType - type of the Field.
 * @param {Object} props - the props of the Field
 * @return the rendered Field.
 */
const renderField = (viewType, props) => {
    let retVal;
    switch (viewType) {
        case fieldViewTypes.text:
            retVal = <Text {...props} />;
            break;
        case fieldViewTypes.checkbox:
            retVal = <Checkbox {...props} />;
            break;
        case fieldViewTypes.checkboxWithConditional:
            retVal = <CheckboxWithConditional {...props} />;
            break;
        case fieldViewTypes.textArea:
            retVal = <TextArea {...props} />;
            break;
        case fieldViewTypes.dropdown:
            retVal = <Dropdown {...props} />;
            break;
        default:
            break;
    }
    return retVal;
};

/**
 * This function renders the visuals for a Component.
 * Renders the title and a label to open up Component for editing.
 * If opened:
 * Renders all Fields for the Component
 * Renders Button for exiting edit mode.
 * Renders label for deleting Component.
 * @param {Object} node - the descriptor of the Component from FormBuilder tree.
 * @param {function} connectDragSource - provided by ReactDND
 * @param {boolean} active - is the Component in edit mode / opened.
 * @param {function(mapKey:number)} setActiveComponent - open Component for editing
 * @param {function()} unSetActiveComponent - close Component / exit editing.
 * @param {function()} openDeleteModal - open modal to delete component.
 * @param {function(new:Object, old:Object)} editNodeAction - edit/replace the node.
 */
const RenderComponent = ({
    node,
    connectDragSource,
    active,
    setActiveComponent,
    unSetActiveComponent,
    openDeleteModal,
    editNodeAction,
    isCurrentNodeValid,
    disabled,
    isSystemAdmin,
    sectionKey
}) => {
    const onCollapseClick = () => {
        if (disabled) {
            return;
        }
        if (active) {
            unSetActiveComponent();
        } else {
            setActiveComponent(node.identifier);
        }
    };

    const generateFieldNameKey = (field) => {
        const keys = [sectionKey, node.type, field.fieldName];
        return keys.join('-').toLowerCase();
    };

    return (
        <div className={`form-builder-field ${active ? 'open' : ''}`}>
            {connectDragSource(
                <div className="field-header" onClick={onCollapseClick}>
                    <Icon name="block layout" />
                    <strong>{node.props.title}</strong>

                    <Button basic compact as="a" floated="right" disabled={disabled}>
                        <Icon name={`angle ${active ? 'up' : 'down'}`} />
                    </Button>
                </div>
            )}

            <AnimateHeight animateOpacity duration={300} height={active ? 'auto' : 0}>
                <div className="field-body">
                    <Form>
                        {componentDescriptors[node.type].map(
                            (field, index) =>
                                fieldsVisibility[field.visibility].isVisible(isSystemAdmin) &&
                                    renderField(field.viewType, {
                                        label: translate(
                                            `components.${node.type}.${field.fieldName}`
                                        ),
                                        fieldName: generateFieldNameKey(field),
                                        value: get(node, `${field.path}[${field.fieldName}]`),
                                        update: Object.hasOwn(field, 'onChange')
                                            ? (value) => field.onChange(editNodeAction, value, node)
                                            : (value) => {
                                            updateNode(
                                                editNodeAction,
                                                field.fieldName,
                                                value,
                                                node,
                                                field.path
                                            )
                                        },
                                        key: `${node.type}-${index}`,
                                        // autoFocus: index === 0,
                                        conditional: head(node.children),
                                        validation: field.validation,
                                        options: field.options
                                    })
                        )}
                    </Form>
                    <Button
                        id="doneButton"
                        onClick={unSetActiveComponent}
                        disabled={!isCurrentNodeValid}
                    >
                        <Translate value="components.common.done" />
                    </Button>
                    {!node.props.isForced && (
                        <Button
                            negative
                            basic
                            className="delete-button"
                            onClick={openDeleteModal}
                        >
                            <Translate value="components.common.delete" />
                        </Button>
                    )}
                </div>
            </AnimateHeight>
        </div>
    );
};

RenderComponent.propTypes = {
    node: PropTypes.object,
    connectDragSource: PropTypes.func,
    active: PropTypes.bool,
    setActiveComponent: PropTypes.func,
    unSetActiveComponent: PropTypes.func,
    openDeleteModal: PropTypes.func,
    editNodeAction: PropTypes.func,
    isSystemAdmin: PropTypes.bool
};

const mapStateToProps = (state) => {
    return {
        isSystemAdmin: get(state, 'session.isSystemAdmin')
    };
};

const mapDispatchToProps = (dispatch) => ({
    editNodeAction: bindActionCreators(editNode, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(RenderComponent);
