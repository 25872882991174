import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import get from 'lodash.get';

import { Record } from '../../../modules/bin/utility';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as programActions from '../store/vipProgramActions';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import { getSetupTab } from './templateTabs/setup';
import { getApplicationQuestionsTab } from './templateTabs/applicationQuestions';
import { getVipPlacesTab } from './templateTabs/vipPlaces';
import { reset, searchEntities } from '../../../modules/autocomplete';

import { TARGET_VALIDATION } from '../../../constants/validationModels';
import { paths } from './constants/template';
import config from '../../../constants';

class ProgramTemplateView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAddressHidden: true,
      isPhoneHidden: true,
      isCountryAllowed: true,
      availableSeats: this.props.availableSeats,
    };

    this.required = {
      required: true
    };

    this.target = {
      required: true,
      ...TARGET_VALIDATION
    };

    this.optional = {
      required: false
    };

    this.formBuilderQuestionsValidation = {
      formbuilderQuestionsMaxLength: true,
      length: config.FORMBUILDER_QUESTIONS_MAX_LENGTH,
      formBuilderQuestions: true
    };
  }

  onChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  onChangeAvailableSeats = (newAvailableSeats) => {
    this.setState({
      ...this.state,
      availableSeats: newAvailableSeats,
    })
  }

  getEventAmbassadorProgramRequest = () => {
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [],
      includeDataTemplate: true
    };
  };

  onEventAmbassadorProgramChange = (path, value, item) => {
    if (item) {
      this.props.resetAutocomlite(paths.ORGANIZATION_ID);
      this.props.templateBuilderActions.showControl(paths.ORGANIZATION_ID);
      this.props.templateBuilderActions.setResultValue(
        paths.ORGANIZATION_ID,
        null
      );
      this.props.templateBuilderActions.setResultValue('eventId', item.eventId);
      this.props.templateBuilderActions.setResultValue('event', item);
      this.props.searchEntities(
        'page/campaign',
        paths.ORGANIZATION_ID,
        this.getOrganizationRequest(item.eventId)
      );
    }
    // `.../vip-program/{id}/add-organization/setup` seems to be the only place this is triggered. Just in case
    // commenting the below out instead of deleting so we can review the changes later.
    // else {
    //     this.props.templateBuilderActions.hideControl(paths.ORGANIZATION_ID);
    // }

    this.props.templateBuilderActions.setResultValue(path, value);
  };

  getEventAmbassadorProgramItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  getOrganizationRequest = (id) => {
    const paramsEventId = get(this.props, 'match.params.eventId');
    const eventId = get(this.props, 'eventId');

    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      includeDataTemplate: true,
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: [
        {
          key: 'eventId',
          operator: 'EqualTo',
          value: id || eventId || paramsEventId
        }
      ]
    };
  };

  onOrganizationChange = (path, value, item) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  getOrganizationItem = (item) => {
    if (item.organization) {
      return {
        text: item.organization.name,
        value: item.organization.id
      };
    }
  };

  render() {
    const urlLink = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data.urlFull'
    );

    const setupTab = getSetupTab({
      onChange: this.onChange,
      required: this.required,
      target: this.target,
      optional: this.optional,
      getEventAmbassadorProgramRequest: this.getEventAmbassadorProgramRequest,
      getEventAmbassadorProgramItem: this.getEventAmbassadorProgramItem,
      onEventAmbassadorProgramChange: this.onEventAmbassadorProgramChange,
      getOrganizationRequest: this.getOrganizationRequest,
      onOrganizationChange: this.onOrganizationChange,
      getOrganizationItem: this.getOrganizationItem,
      urlLink: urlLink
    });
    const applciationQuestionsTab = getApplicationQuestionsTab({
      onChange: this.onChange,
      formBuilderQuestionsValidation: this.formBuilderQuestionsValidation,
      isAddressHidden: this.state.isAddressHidden,
      isPhoneHidden: this.state.isPhoneHidden,
      isCountryAllowed: this.state.isCountryAllowed
    });
    const vipPlacesTab = getVipPlacesTab({
      availableSeats: this.state.availableSeats,
      campaignAmbassadorProgramRecordId: get(this.props.campaignAmbassadorProgramRecord, "data.data.id"),
      isUpdateAvailableSeatsLoading: this.props.isUpdateAvailableSeatsLoading,
      onChangeAvailableSeats: this.onChangeAvailableSeats,
      onUpdateAvailableSeats: this.props.programActions.updateProgramSeats,
    });

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        showCancelButton
        onCancel={this.props.onCancel}
        isLoading={this.props.isLoading}
      >
        {setupTab}
        {applciationQuestionsTab}
        {this.props.isSystemAdmin && vipPlacesTab}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = ({ program, templateBuilderEx, session }) => {
  return {
    campaignAmbassadorProgramRecord: program.campaignAmbassadorProgramRecord,
    organizationId: templateBuilderEx.data.organizationId,
    eventId: templateBuilderEx.data.eventId,
    availableSeats: templateBuilderEx.data.metrics?.availableCount || null,
    isUpdateAvailableSeatsLoading: Record.isRecordLoading(program.editProgramSeats),
    isSystemAdmin: get(session, 'isSystemAdmin'),
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    resetAutocomlite(path) {
      dispatch(reset(path));
    },
    searchEntities(entity, path, request) {
      dispatch(searchEntities(entity, path, request));
    }
  };
};

const ProgramTemplate = withRouter(
  connect(mapState, mapDispatch)(ProgramTemplateView)
);
export default ProgramTemplate;
