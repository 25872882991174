import React from 'react';
import { IMerchandise } from './merchandise.model';
import Styles from './merchandise-list.module.css';
import { MerchandiseItem } from './merchandise-list-item';
import { SkuItem } from './merchandise-list-sku-item';

interface MerchandiseListProps {
  items?: IMerchandise[];
  onEditItem?: (id: number) => void;
}

export const MerchandiseList: React.FunctionComponent<MerchandiseListProps> = ({
  items = [],
  onEditItem
}) => {
  return (
    <div className={Styles.List}>
      {items.map((item: IMerchandise, index: number) => (
        <React.Fragment key={index}>
          <MerchandiseItem item={item} onEditItem={onEditItem} />
          {item.skus.map((sku: any, idx: number) => (
            <SkuItem key={idx} item={sku} />
          ))}
        </React.Fragment>
      ))}
    </div>
  );
};
