import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SecurityModalWrapperContainer from '../pages/accounts/settings/MFA/securityModalWrapper';
import { RootState } from '../store';
import { useAppDispatch } from '../hooks';
import {
  handleValueChange,
  securityModalChange
} from '../pages/accounts/settings/reducer';

const withMfaCheck = <P extends object>(
  WrappedComponent: React.ComponentType<P>
) => {
  return (props: P) => {
    const dispatch = useAppDispatch();

    const { account, organization, campaigns } = useSelector(
      (state: RootState) => state.session
    );
    const { MFA } = useSelector((state: RootState) => state.accountSettings);

    useEffect(() => {
      if (!account || (!organization && !campaigns)) {
        return;
      }

      const linkedMfaActive =
        organization?.hasMfaActive ||
        campaigns?.some((x: any) => x?.organization?.hasMfaActive);

      // we want to force the user to sign up for MFA if the organization has MFA active AND the user has NOT setup MFA
      if (!MFA.open && !account?.hasMfaActive && linkedMfaActive) {
        dispatch(securityModalChange(true));
        dispatch(handleValueChange('MFA.forceMfa', true));
      }
    }, [account?.hasMfaActive, organization, campaigns, MFA?.open]);

    return (
      <>
        <SecurityModalWrapperContainer />
        <WrappedComponent {...props} />
      </>
    );
  };
};

// Don't really need a HOC, but using to keep consistent with architecture
export default withMfaCheck;
