import React from 'react';
import { Translate } from 'react-redux-i18n';

import { TemplatePage } from '../../../../components/common/pager';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateInputView } from '../../../../components/template/templateInputView';
import TemplateViewContainer from '../../../../components/template/templateViewContainer';
import TemplateFormBuilderView from '../../../../components/template/templateFormBuilderView';

import { PartOneInfo } from '../components/partOneInfo';
import Button from '../../../../components/common/button';

import { paths } from '../constants/template';

export const getPartOneViewTab = (props) => {
  const {
    required,
    email,
    formBuilderAnswers,
    dateReceived,
    fundraiserRaisedAmount,
    fundraiserUrlFull,
    fundraiserId,
    fundraiserName,
    eventName,
    eventAmbassadorId,
    eventAmbassadorProgramName,
    onEditClick,
    isApplicationPartTwoVisible,
    isEditButtonDisabled
  } = props;

  const userInfoValidation = [
    {
      path: paths.FIRST_NAME,
      validation: required
    },
    {
      path: paths.LAST_NAME,
      validation: required
    },
    {
      path: paths.EMAIL,
      validation: email
    }
  ];

  return (
    <TemplatePage
      id="part-one"
      i18nKey={`${
        isApplicationPartTwoVisible
          ? 'applications.template.page.partOne.application-details-title'
          : 'applications.template.page.partOne.title'
      }`}
    >
      <TemplateHeader
        as="h2"
        label="applications.template.page.partOne.header-name.label"
      />

      <PartOneInfo
        dateReceived={dateReceived}
        fundraiserRaisedAmount={fundraiserRaisedAmount}
        fundraiserUrlFull={fundraiserUrlFull}
        fundraiserId={fundraiserId}
        fundraiserName={fundraiserName}
        eventName={eventName}
        eventAmbassadorId={eventAmbassadorId}
        eventAmbassadorProgramName={eventAmbassadorProgramName}
      />

      <TemplateHeader
        as="h5"
        label="applications.template.page.partOne.account-details-name.label"
      />

      <TemplateViewContainer validation={userInfoValidation}>
        <TemplateInputView
          label="applications.template.page.partOne.first-name.label"
          path={paths.FIRST_NAME}
          validation={required}
        />
        <TemplateInputView
          label="applications.template.page.partOne.last-name.label"
          path={paths.LAST_NAME}
          validation={required}
        />
        <TemplateInputView
          label="applications.template.page.partOne.email-name.label"
          path={paths.EMAIL}
          validation={email}
        />
      </TemplateViewContainer>

      <TemplateHeader
        as="h5"
        label="applications.template.page.partOne.event-questions-name.label"
      />

      <TemplateViewContainer
        path={paths.APPLICATION_ENTRY}
        validation={formBuilderAnswers}
      >
        <TemplateFormBuilderView path={paths.APPLICATION_ENTRY} />
      </TemplateViewContainer>

      <TemplateHeader
        as="h5"
        path={paths.OGRANIZATION_QUESTIONS_HEADER}
        label="applications.template.page.partOne.organization-questions-name.label"
      />

      <TemplateViewContainer
        path={paths.APPLICATION_ADDITIONAL}
        validation={formBuilderAnswers}
      >
        <TemplateFormBuilderView
          path={paths.APPLICATION_ADDITIONAL}
          validation={formBuilderAnswers}
        />
      </TemplateViewContainer>

      <TemplateHeader
        as="h2"
        path={paths.PART_TWO_HEADER}
        label="applications.template.page.partOne.part-two-header.label"
      />

      <TemplateViewContainer
        path={paths.APPLICATION_CONFIRMATION}
        validation={formBuilderAnswers}
      >
        <TemplateFormBuilderView
          path={paths.APPLICATION_CONFIRMATION}
          validation={formBuilderAnswers}
        />
      </TemplateViewContainer>

      <div path={paths.EDIT_BUTTON} className="template-button-container">
        <Button disabled={isEditButtonDisabled} onClick={onEditClick}>
          <Translate value="applications.template.page.partOne.edit-button-name.label" />
        </Button>
        <div className="template-button-subtext">
          <Translate value="applications.template.page.partOne.application-information-name.label" />
        </div>
      </div>
    </TemplatePage>
  );
};
