import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import config from '../../constants';
import { RootState } from '../../store';
import { Session } from "../../modules/session";
import { IRecord, Record } from '../../modules/bin/utility';
import { getBeamerCampaigns } from './reducer';

export interface BeamerConfig {
  product_id?: string;
  bottom?: number;
  lazy?: boolean;
  user_email?: string;
  user_firstname?: string;
  user_lastname?: string;
  filter?: string;
}

const buildFilterString = (session: Session, beamerCampaigns: IRecord) => {
  let result = "";

  result = result.concat(`env-${config.node_env}`);

  if (session.isSystemAdmin) {
    result = result.concat(";sys-admin");
  } else if (session.isOrganizationAdmin) {
    result = result.concat(";org-admin-all;", `org-admin-${session.organization.country};`);
    (beamerCampaigns.data.data.list as any[]).forEach(campaign => {
      result = result.concat(
        `${campaign.event.urlPath}-all;`,
        `${campaign.event.urlPath}-${campaign.eventTier};`,
        campaign.hasAmbassadorProgram ? `${campaign.event.urlPath}-vip-program` : ""
      );
    });
  }

  return result.toLowerCase();
};

export const useBeamer = () => {
  const session = useSelector(state => (state as RootState).session);
  const beamerCampaigns = useSelector(state => (state as RootState).beamer.beamerCampaigns);
  const dispatch = useDispatch();

  useEffect(() => {
    window.beamer_config = {
      product_id: process.env.REACT_APP_BEAMER_PRODUCT_ID,
      bottom: 72,
      lazy: true,
    };

    const importScript = document.createElement('script');
    importScript.id = "beamer-snippet";
    importScript.src = "https://app.getbeamer.com/js/beamer-embed.js";
    importScript.defer = true;
    document.body.appendChild(importScript);

    return () => {
      const scriptElement = document.getElementById("beamer-snippet");
      if (scriptElement) {
        document.body.removeChild(scriptElement);
      }
    };
  }, [process.env.REACT_APP_BEAMER_PRODUCT_ID]);

  useEffect(() => {
    if (window.Beamer) {
      if (!session.unauthorized) {
        dispatch(getBeamerCampaigns());
      } else {
        window.Beamer.destroy();
      }
    }
  }, [session.account]);

  useEffect(() => {
    if (
      window.Beamer &&
      !session.unauthorized &&
      Record.isSuccess(beamerCampaigns)
    ) {
      window.beamer_config = {
        ...window.beamer_config,
        user_email: session.account?.email,
        user_firstname: session.account?.firstName,
        user_lastname: session.account?.lastName,
        filter: buildFilterString(session, beamerCampaigns),
      }
      window.Beamer.init();
    }
  }, [beamerCampaigns.ready]);

  return null;
};
