import update from './update';
import get from 'lodash.get';
import moment from 'moment';
import config from '../constants';

export const mergeTemplate = (template, newContract, newKey) => {
  let newTemplate = {
    template: {
      key: newKey,
      value: {}
    }
  };

  for (const path in newContract) {
    const element = get(template, newContract[path]);
    if (element) {
      newTemplate = update.set(newTemplate, newContract[path], element);
    }
  }

  return newTemplate.template;
};

export const displayAddress = (address = {}) => {
  const { street, city, state, postcode, country } = address;
  return [street, city, state, postcode, country].filter((x) => x).join(', ');
};

export const remapAndUnset = (model, oldPath, newPath) => {
  const oldValue = get(model, oldPath);
  if (oldValue !== undefined) {
    model = update.set(model, newPath, oldValue);
    model = update.unset(model, oldPath);
  }
  return model;
};

export const formatTimeAndDate = (date, time) => moment(`${date}  ${time ? time : ''}`).format(
  time ? config.DEFAULT_FULL_DATE_FORMAT : config.DEFAULT_DATE_FORMAT
)

export function isValidDate(date) {
  return date && moment(date).isValid();
}

export function parseToDate(date) {
  return moment(date).format(config.DEFAULT_DATE_FORMAT)
}

export function parseToTime(date) {
  return moment(date).format(config.DEFAULT_TIME_FORMAT)
}

export const getFullTimeObject = (data, isEvent) => {
  const template = get(data, 'template.value');

  if (!template) {
    return null;
  }

  const allowTimeAndPlace = get(template, 'timeAndPlace.enabled');

  if (!isEvent) {
    const date = get(template, 'timeAndPlace.date');
    const time = get(template, 'timeAndPlace.time') || '';

    const endDate = get(template, 'timeAndPlace.endDate');
    const endTime = get(template, 'timeAndPlace.endTime') || '';
    let text = get(template, 'timeAndPlace.text');

    if (!text) {
      text = get(data, 'timeAndPlace.time.text');
    }

    if (!allowTimeAndPlace || !date) {
      return null;
    }

    const startDateAndTime = date
      ? moment(`${date}  ${time ? time : ''}`)
      : null;
    const endDateAndTime = endDate
      ? moment(`${endDate}  ${endTime ? endTime : ''}`)
      : null;

    return {
      start: startDateAndTime.format(
        time ? config.DEFAULT_FULL_DATE_FORMAT : config.DEFAULT_DATE_FORMAT
      ),
      end: endDateAndTime
        ? endDateAndTime.format(
            endTime
              ? config.DEFAULT_FULL_DATE_FORMAT
              : config.DEFAULT_DATE_FORMAT
          )
        : null,
      includesTime: date && (!!time || !!(endDate && endTime)),
      text
    };
  } else {
    const startDate = get(data, 'timeAndPlace.date.start');
    const endDate = get(data, 'timeAndPlace.date.end');

    const startTime = get(data, 'timeAndPlace.time.start') || '';
    const endTime = get(data, 'timeAndPlace.time.end') || '';

    if (!allowTimeAndPlace || (!startDate && !endDate)) {
      return null;
    }

    const startDateAndTime = moment(`${startDate}  ${startTime}`);
    const endDateAndTime = moment(`${endDate}  ${endTime}`);

    return {
      start: startDateAndTime.format(config.DEFAULT_FULL_DATE_FORMAT),
      end: endDateAndTime.format(config.DEFAULT_FULL_DATE_FORMAT),
      includesTime: !!startTime || !!endTime
    };
  }
};

// during the creation, edit and clone of an event the DateTime are extracted from timeAndPlace.time.start
// and timeAndPlace.time.end. They need to be extracted into date and times as they're seperate inputs
// the logic is a bit convuluted, at the  time it was easier to go with the flow than re-create it
// this logic is no called in all 3 places instead of code being duplicated
export const setTimeAndPlaceDateTime = (
  obj,
  startDate,
  endDate,
  includesTime
) => {
  let newObj = obj;
  const setDateTime = (path, dateString, format) => {
    try {
      if (dateString) {
        newObj = update.set(newObj, path, moment(dateString).format(format));
      }
    } catch (error) {
      console.log(error);
    }
  };

  setDateTime('timeAndPlace.date.start', startDate, config.DEFAULT_DATE_FORMAT);
  setDateTime('timeAndPlace.time.start', startDate, config.DEFAULT_TIME_FORMAT);

  setDateTime('timeAndPlace.date.end', endDate, config.DEFAULT_DATE_FORMAT);
  setDateTime('timeAndPlace.time.end', endDate, config.DEFAULT_TIME_FORMAT);

  if (!includesTime) {
    newObj = update.set(newObj, 'timeAndPlace.time.start', null);
    newObj = update.set(newObj, 'timeAndPlace.time.end', null);
  }

  return get(newObj, 'timeAndPlace');
};
