import React from 'react';
import { Form, Message } from 'semantic-ui-react';
import { AboutElementTypes } from './aboutElementType';
import AboutVideoAdd from './aboutVideoAdd';
import AboutImageUpload from './aboutImageUpload';

export class AboutElement extends React.Component {
  state = {
    prevValue: ''
  };

  onRemove = () => {
    this.props.onChange('', this.props.type, this.props.guid);
  };

  onElementValueChanged = (value) => {
    this.props.onChange(value, this.props.type, this.props.guid);
  };

  onElementTypeSelected = (type) => {
    if (type !== this.props.type) {
      this.props.onChange(this.state.prevValue, type, this.props.guid);
      this.setState({ prevValue: this.props.value });
    }
  };

  onTextChanged = (event) => {
    this.props.onChange(event.target.value, this.props.type, this.props.guid);
  };

  render() {
    const notTextElement =
      this.props.type === AboutElementTypes.Image ||
      this.props.type === AboutElementTypes.Video;

    return (
      <div className="custom-mediaselector-container">
        {this.props.type === AboutElementTypes.Text && (
          <Form.Field
            required={this.props.validation && this.props.validation.required}
          >
            <label>{this.props.label}</label>
            <textarea
              id={this.props.id}
              value={this.props.value}
              onChange={this.onTextChanged}
            ></textarea>
            {this.props.subtext && (
              <div className="input-sub">{this.props.subtext}</div>
            )}
          </Form.Field>
        )}
        {notTextElement && (
          <Form.Field>
            <label>{this.props.mediaLabel}</label>
            <div className="custom-mediaselector-options">
              <Form.Checkbox
                label="Video"
                checked={this.props.type === AboutElementTypes.Video}
                onChange={() => {
                  this.onElementTypeSelected(AboutElementTypes.Video);
                }}
                radio
              />
              <Form.Checkbox
                label="Image"
                checked={this.props.type === AboutElementTypes.Image}
                onChange={() => {
                  this.onElementTypeSelected(AboutElementTypes.Image);
                }}
                radio
              />
            </div>
          </Form.Field>
        )}
        {this.props.type === AboutElementTypes.Image && (
          <AboutImageUpload
            value={this.props.value}
            guid={this.props.guid}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
            disableCropping={this.props.disableCropping}
            sessionKey={this.props.sessionKey}
            entity={this.props.entity}
          />
        )}
        {this.props.type === AboutElementTypes.Video && (
          <AboutVideoAdd
            value={this.props.value}
            onValueChange={this.onElementValueChanged}
            onRemove={this.onRemove}
          />
        )}
        <Message negative hidden={!this.props.validationErrorMessage}>
          <p>{this.props.validationErrorMessage}</p>
        </Message>
      </div>
    );
  }
}
export default AboutElement;
