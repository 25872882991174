import { lookupEntities } from '../../constants/metadataConstants';

export const filterLookupBaseOptions = {
  [lookupEntities.TicketLookup]: {
    getItem(item) {
      return {
        text: `${item.name} (${item.campaign.urlPath})`,
        value: item.id
      };
    }
  },
  [lookupEntities.MerchandiseLookup]: {
    getItem(item) {
      return {
        text: item.name,
        value: item.id
      };
    }
  }
};
