import { ColumnModel } from '../../../components/common/grid/columnModel';
import { columnTypes } from '../../../components/common/grid/constants';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import { gridKeys } from '../../../constants/gridKeys';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { Record } from '../../../modules/bin/utility';
import { CLEAR } from '../../../modules/general';
import { organizationColumns } from './../constants';
import {
  CREATE_FAILURE,
  CREATE_REQUESTED,
  CREATE_SUCCESS,
  ERROR_CLEAR,
  GET_EXPORT_FILE_FAILURE,
  GET_EXPORT_FILE_REQUESTED,
  GET_EXPORT_FILE_SUCCESS,
  GET_FAILURE,
  GET_REQUESTED,
  GET_SALESFORCE_LINK_FAILURE,
  GET_SALESFORCE_LINK_REQUESTED,
  GET_SALESFORCE_LINK_SUCCESS,
  GET_STRIPE_LINK_FAILURE,
  GET_STRIPE_LINK_REQUESTED,
  GET_STRIPE_LINK_SUCCESS,
  GET_SUCCESS,
  LIST_DATA_FAILURE,
  LIST_DATA_REQUESTED,
  LIST_DATA_SUCCESS,
  METADATA_FAILURE,
  METADATA_REQUESTED,
  METADATA_SUCCESS,
  TOGGLE_COLUMNS_CHANGE,
  TOGGLE_STRIPE_URL_MODAL,
  UPDATE_FAILURE,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS
} from './organizationTypes';

const i18nOrganizationListKey = 'organization.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  exportFileRecord: Record.getDefaultState(),
  [gridKeys.ORGANIZATIONS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        organizationColumns.NAME,
        `${i18nOrganizationListKey}.${organizationColumns.NAME}`
      ).visibleByDefault(),
      new ColumnModel(
        organizationColumns.AMOUNT_RAISED,
        `${i18nOrganizationListKey}.${organizationColumns.AMOUNT_RAISED}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        organizationColumns.AMOUNT_RAISED_ONLINE,
        `${i18nOrganizationListKey}.${organizationColumns.AMOUNT_RAISED_ONLINE}`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        organizationColumns.CAMPAIGNS_TOTAL,
        `${i18nOrganizationListKey}.${organizationColumns.CAMPAIGNS_TOTAL}`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        organizationColumns.CAMPAIGNS_ACTIVE,
        `${i18nOrganizationListKey}.${organizationColumns.CAMPAIGNS_ACTIVE}`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        organizationColumns.PAYMENT_CONNECTED,
        `${i18nOrganizationListKey}.${organizationColumns.PAYMENT_CONNECTED}`
      ).visibleByDefault(),
      new ColumnModel(
        organizationColumns.CURRENCY,
        `${i18nOrganizationListKey}.${organizationColumns.CURRENCY}`
      ).visibleByDefault(),
      new ColumnModel(
        organizationColumns.COUNTRY,
        `${i18nOrganizationListKey}.${organizationColumns.COUNTRY}`
      ).visibleByDefault(),
      new ColumnModel(
        organizationColumns.PAYMENT_CONNECTED,
        `${i18nOrganizationListKey}.${organizationColumns.PAYMENT_PLATFORM}`
      )
        .visibleByDefault()
        .setUniqueId(organizationColumns.PAYMENT_PLATFORM)
    ])
    .orderBy(organizationColumns.AMOUNT_RAISED_ONLINE, 'desc')
    .getState(),
  record: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  updateRecord: Record.getDefaultState(),
  statusRecord: Record.getDefaultState(),
  stripeLinkRecord: Record.getDefaultState(),
  stripeLinkReconnectRecord: Record.getDefaultState(),
  stripeLinkChangeRecord: Record.getDefaultState(),
  salesforceLinkRecord: Record.getDefaultState(),
  isStripeUrlModalOpen: false,
};

// State Reducer
const organizationReducer = (
  state = initialState,
  action: { type: any; payload: { key: any } }
) => {
  let newState = state;
  switch (action.type) {
    case TOGGLE_STRIPE_URL_MODAL:
      newState = {
        ...state,
        [action.payload.key]: !state[action.payload.key],
      };
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case GET_STRIPE_LINK_REQUESTED:
    case GET_SALESFORCE_LINK_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
    case UPDATE_SUCCESS:
    case GET_STRIPE_LINK_SUCCESS:
    case GET_SALESFORCE_LINK_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case CREATE_FAILURE:
    case GET_FAILURE:
    case UPDATE_FAILURE:
    case GET_STRIPE_LINK_FAILURE:
    case GET_SALESFORCE_LINK_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case GET_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
      newState = entityHandlers.getSuccessHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case CLEAR: {
      newState = initialState;
      break;
    }
    default:
      return newState;
  }

  return newState;
};

export default organizationReducer;
