import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store, history, RootState } from './store';
import App from './containers/app';
import 'core-js/fn/array/includes';
import './index.css';
import './semantic/dist/semantic.css';
import 'react-tippy/dist/tippy.css';
import {
  useRaygun,
  useGoogleAnalytics,
  useGoogleTagManager,
  useZendesk,
  useBeamer,
} from './hooks';
import './css';
import { useClarity } from './hooks/clarity/useClarity';

// TODO - add this  to app once converted from class to functional component
const AppWrapper = () => {
  // Replacing the functions with hooks provides better organisation, encapsulation, lifecycle management,
  // access to component context and code reusability.
  useRaygun();
  useGoogleAnalytics();
  useGoogleTagManager();
  useClarity();

  useZendesk();
  useBeamer();
  useEffect(() => {
    if (window.zE) {
      window.zE("webWidget:on", "open", () => {
        if (window.Beamer) {
          const selector = document.getElementById("beamerSelector") as HTMLElement;
          selector.style.visibility = "hidden";
        }
      });

      window.zE("webWidget:on", "close", () => {
        if (window.Beamer) {
          const selector = document.getElementById("beamerSelector") as HTMLElement;
          selector.style.visibility = "visible";
        }
      });
    }
  }, [window.zE, window.Beamer]);

  return (
    <div>
      <App />
    </div>
  );
};

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <AppWrapper />
    </ConnectedRouter>
  </Provider>,
  document.querySelector('#root')
);
