import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button, Message } from 'semantic-ui-react';

// LIBS
import ReactCodeInput from 'react-verification-code-input';
import get from 'lodash.get';
import { Translate, I18n } from 'react-redux-i18n';

//REDUCERS
import { getSecurityAppoval } from '../../reducer';

// HELPERS
import { Record } from '../../../../../modules/bin/utility';
import { grecaptcha } from '../../../../../helpers/grecaptcha';

// CONSTANTS
import { securitySettingTabs } from '../../../constants';
import config from '../../../../../constants';

class VerificationTab extends Component {
  constructor(props) {
    super(props);
    this.reactCodeInputRef = React.createRef();

    this.state = {
      googleAuthCode: ''
    };
  }

  componentDidUpdate = (prevProps) => {
    this.handleSecurityApprovalError(prevProps);
    this.handleSecurityApprovalSuccess(prevProps);
  };

  handleSecurityApprovalSuccess = (prevProps) => {
    const isPrevRecordSuccess = Record.isRecordReady(
      prevProps.securityApprovalRecord
    );
    const isCurrentRecordSuccess = Record.isRecordReady(
      this.props.securityApprovalRecord
    );

    if (
      isCurrentRecordSuccess &&
      isPrevRecordSuccess !== isCurrentRecordSuccess
    ) {
      this.props.handleActiveTabChange(securitySettingTabs.BACK_UP_CODES);
    }
  };

  handleSecurityApprovalError = (prevProps) => {
    const isPrevRecordError = Record.isError(prevProps.securityApprovalRecord);
    const isCurrentRecordError = Record.isError(
      this.props.securityApprovalRecord
    );

    if (isCurrentRecordError && isPrevRecordError !== isCurrentRecordError) {
      this.clearVerificationCode();
    }
  };

  handleValueChange = (value) => {
    this.setState({
      googleAuthCode: value
    });
  };

  clearVerificationCode = () => {
    this.setState({
      googleAuthCode: ''
    });
    this.reactCodeInputRef.current.__clearvalues__();
  };

  handleNextButtonClick = () => {
    const tokenCallback = (token) =>
      this.props.getSecurityAppoval(
        this.props.accountId,
        this.state.googleAuthCode,
        token
      );
    grecaptcha(tokenCallback, 'AccountMultiFactor');
  };

  render() {
    const isRecordError = Record.isError(this.props.securityApprovalRecord);
    const isRecordLoading = Record.isRecordLoading(
      this.props.securityApprovalRecord
    );
    const verificationCodeMaxLength =
      this.state.googleAuthCode.length < config.VERIFICATION_CODE_LENGTH;

    return (
      <Modal.Content>
        <Form onSubmit={this.handleNextButtonClick}>
          <ReactCodeInput
            className={`verification-code ${
              isRecordError ? 'remove-verification-code-bottom-padding' : ''
            }`}
            fields={config.VERIFICATION_CODE_LENGTH}
            fieldWidth={58}
            onChange={this.handleValueChange}
            ref={this.reactCodeInputRef}
          />
          {isRecordError && (
            <Message negative>
              <p>{I18n.t('security.security-approval')}</p>
            </Message>
          )}
          <div className="security-sub-title">
            <h4 className="verification-code-title">
              <Translate
                value={
                  'account.account-security.activateModal.steps.verificationCode.title'
                }
              />
            </h4>
          </div>
          <div className="security-content">
            <span className="verification-code-content">
              <Translate
                value={
                  'account.account-security.activateModal.steps.verificationCode.content'
                }
              />
            </span>
          </div>
          <Button
            primary
            className="next-button"
            loading={isRecordLoading}
            disabled={verificationCodeMaxLength}
          >
            <Translate
              value={
                'account.account-security.activateModal.steps.verificationCode.button'
              }
            />
          </Button>
        </Form>
      </Modal.Content>
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  const accountId = get(session, 'account.id');

  return {
    accountId: accountId,
    securityApprovalRecord: accountSettings.securityApprovalRecord,
    activeTab: get(accountSettings, 'MFA.activeTab')
  };
};

const mapDispatch = (dispatch) => {
  return {
    getSecurityAppoval: (accountId, code) => {
      dispatch(getSecurityAppoval(accountId, code));
    }
  };
};

export default connect(mapState, mapDispatch)(VerificationTab);
