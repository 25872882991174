import React from 'react';
import { get } from 'lodash';
import moment from 'moment';
import { I18n } from 'react-redux-i18n';
import { Form } from 'semantic-ui-react';
import config from '../../../constants';
import { listFilterOperator } from '../../../constants/aggregate';
import {
  filterSelectBaseOptions,
  lookupEntities,
  applyBaseFilter,
  applyAdditionalFilter,
  baseOptions,
  treeLookupEntities,
  NUMBER_TYPES,
  LOOKUP_TYPES,
  SELECT_TYPES,
  TREE_LOOKUP_TYPES
} from '../../../constants/metadataConstants';
import BooleanItem from './booleanItem';
import DateTimeItem from './dateTimeItem';
import {
  DEFAULT_TIME,
  ITEM_TYPES,
  UNKNOWN_TYPE_EXCEPTION
} from './filtersButtonConstants';
import LookupItem from './lookupItem';
import NumberItem from './numberItem';
import StringItem from './stringItem';
import TreeLookupItem from './treeLookupItem';
import DropdownItem from './dropdownItem';

const getFilter = (key: string, operator: string, value: any) => {
  return {
    key: key,
    operator: operator,
    value: value
  };
};

export const getFilterColumns = (availableFilters: string[], metadata: any) => {
  if (!availableFilters || !metadata.columns) {
    return null;
  }

  const filterColumns = availableFilters.map((filter) => {
    return metadata.columns.find((x: any) => x.key === filter);
  });

  return filterColumns.filter((x) => x !== undefined);
};

export const getCheckedFilter = (column: any) => {
  const checkedFilter: any[] = [];
  const itemType = getItemType(column.type);
  switch (itemType) {
    case ITEM_TYPES.STRING:
    case ITEM_TYPES.BOOLEAN:
      checkedFilter.push(
        getFilter(column.key, listFilterOperator.EQUAL_TO, column.value)
      );
      break;

    case ITEM_TYPES.LOOKUP:
    case ITEM_TYPES.TREE_LOOKUP:
    case ITEM_TYPES.SELECT:
      if (column.value) {
        checkedFilter.push(
          getFilter(column.key, listFilterOperator.EQUAL_TO, column.value)
        );
      }
      break;

    case ITEM_TYPES.NUMBER:
      if (column.value) {
        if (column.value.from) {
          checkedFilter.push(
            getFilter(
              column.key,
              listFilterOperator.GREATER_OR_EQUAL_THAN,
              column.value.from
            )
          );
        }

        if (column.value.to) {
          checkedFilter.push(
            getFilter(
              column.key,
              listFilterOperator.LESSER_OR_EQUAL_THAN,
              column.value.to
            )
          );
        }
      }
      break;
    case ITEM_TYPES.DATETIME:
      if (column.value) {
        if (column.value.from) {
          checkedFilter.push(
            getFilter(
              column.key,
              listFilterOperator.GREATER_OR_EQUAL_THAN,
              column.value.from
            )
          );
        }

        if (column.value.to) {
          const valueTo = moment(column.value.to)
            .set({
              hours: DEFAULT_TIME.hours,
              minutes: DEFAULT_TIME.minutes,
              seconds: DEFAULT_TIME.seconds,
              millisecond: DEFAULT_TIME.milliseconds
            })
            .format(config.FULL_DATE_TIME);

          checkedFilter.push(
            getFilter(
              column.key,
              listFilterOperator.LESSER_OR_EQUAL_THAN,
              valueTo
            )
          );
        }
      }
      break;
    default:
      return checkedFilter;
  }

  return checkedFilter;
};

const getItemType = (columnType: string) => {
  if (
    columnType === 'String' ||
    columnType === 'Boolean' ||
    columnType === 'DateTime'
  ) {
    return columnType;
  }

  if (NUMBER_TYPES.some((x) => x === columnType)) {
    return ITEM_TYPES.NUMBER;
  }

  if (SELECT_TYPES.some((x) => x === columnType)) {
    return ITEM_TYPES.SELECT;
  }

  if (LOOKUP_TYPES.some((x) => x === columnType)) {
    return ITEM_TYPES.LOOKUP;
  }

  if (TREE_LOOKUP_TYPES.some((x) => x === columnType)) {
    return ITEM_TYPES.TREE_LOOKUP;
  }

  throw new Error(UNKNOWN_TYPE_EXCEPTION);
};

export const getFilterItem = (
  filterData: any,
  column: any,
  url: any,
  filter: any,
  additionalFilters: any,
  filterLookupBaseOptions: any,
  listKey: string,
  clearOnChange: boolean,
  onChange: (event: any, target: any) => void,
  handleChange: (path: any, date: any) => void
) => {
  let content = null;
  const itemType = getItemType(column.dataType);
  const defaultProps = {
    path: `${column.key}.value`,
    value: get(filterData, `${column.key}.value`),
    onChange: onChange
  };

  switch (itemType) {
    case ITEM_TYPES.STRING:
      content = <StringItem {...defaultProps} />;
      break;

    case ITEM_TYPES.BOOLEAN:
      content = <BooleanItem {...defaultProps} />;
      break;

    case ITEM_TYPES.NUMBER:
      content = <NumberItem {...defaultProps} />;
      break;

    case ITEM_TYPES.DATETIME:
      content = <DateTimeItem {...defaultProps} onChange={handleChange} />;
      break;

    case ITEM_TYPES.DATE:
      content = <DateTimeItem {...defaultProps} onChange={handleChange} />;
      break;

    case ITEM_TYPES.SELECT: {
      const options =
        // @ts-ignore
        filterSelectBaseOptions[column.dataType] ||
        column.additionalData.map((x: any) => ({
          value: x.value,
          text: x.key
        }));
      content = <DropdownItem {...defaultProps} options={options} />;
      break;
    }

    case ITEM_TYPES.LOOKUP:
      // @ts-ignore
      const entity = lookupEntities[column.dataType];
      const customFilter = applyBaseFilter[entity] ? filter : null;
      const customAdditionalFilters = applyAdditionalFilter[entity]
        ? additionalFilters
        : null;
      const filterBaseOptions = {
        ...baseOptions,
        ...filterLookupBaseOptions
      };
      const getLookupItem = filterBaseOptions[entity].getItem;
      content = (
        <LookupItem
          {...defaultProps}
          path={`${column.key}`}
          filter={customFilter}
          // @ts-ignore
          entity={entity}
          label={get(filterData, `${column.key}.label`)}
          additionalFilters={customAdditionalFilters}
          getLookupItem={getLookupItem}
          clearOnChange={clearOnChange}
          hasEmptyOption
        />
      );
      break;

    case ITEM_TYPES.TREE_LOOKUP: {
      // @ts-ignore
      const entity = treeLookupEntities[column.dataType];

      content = (
        <TreeLookupItem
          {...defaultProps}
          entity={entity}
          onChange={handleChange}
          url={url}
          value={get(filterData, `${column.key}.value`)}
        />
      );
      break;
    }

    default:
      return null;
  }

  return {
    key: column.key,
    label: I18n.t(`filters.${listKey}.${column.key}`),
    type: itemType,
    content: <Form>{content}</Form>
  };
};
