// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

// Semantic UI
import { Button } from 'semantic-ui-react';

// Actions
import * as eventActions from '../../pages/event/store/eventActions';

// Helpers
import get from 'lodash.get';
import * as templateHelper from './templateHelper';
import { isArrayWithItems } from '../../helpers/arrayHelper';

// Components
import { ExportDownloadProvider } from '../../pages/export-history';
import { Common } from '../';
import PriorityDropdown from './dependentOrganisations/components/priorityDropdown';
import OrganisationImage from './dependentOrganisations/components/organisationImage';
import AssociatedCheckbox from './dependentOrganisations/components/associatedCheckbox';

// Constants
import { gridKeys } from '../../constants/gridKeys';
import {
  eventPriority,
  dependentOrganisationsGridColumns,
  errorMessages,
  eventTierLabel
} from './dependentOrganisations/constants';
import OrganisationName from './dependentOrganisations/components/organizationName';

class DependentOrganisations extends Component {
  constructor(props) {
    super(props);

    const eventOrganizations = this.getEventOrganizations();

    this.baseFilters = this.getBaseFilters(eventOrganizations);
  }

  getBaseFilters = (organizations) => {
    const tierFilterChecked = get(this.props, 'tierFilter.checked');
    const tierFilterValue = get(this.props, 'tierFilter.value');

    if (tierFilterChecked && tierFilterValue) {
      return [
        {
          key: 'id',
          operator: 'InList',
          value: isArrayWithItems(organizations) ? organizations : [0]
        }
      ];
    }

    return [];
  };

  onAssociatedChange = (event, element, itemProps) => {
    if (element.checked === true) {
      const tiers = this.props.tiers;
      const priority = itemProps.priority
        ? itemProps.priority
        : Object.keys(tiers).reduce(
            (defaultTier, currentTierKey, index) => tiers[currentTierKey].enabled ? eventPriority[index].key : defaultTier,
            eventPriority[0].key
          );
      this.addAssociatedOrganization(itemProps.id, priority);
    } else {
      this.deleteAssociatedOrganization(itemProps.id);
    }
  };

  addAssociatedOrganization = (id, priority) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    valueCopy.push({ id: id, priority: priority });
    this.onChange(valueCopy);
  };

  deleteAssociatedOrganization = (id) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    const index = valueCopy.findIndex((element) => element.id === id);
    valueCopy.splice(index, 1);
    this.onChange(valueCopy);
  };

  associatedPriorityChange = (value, id) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    const index = valueCopy.findIndex((element) => element.id === id);
    valueCopy[index].priority = value;
    this.onChange(valueCopy);
  };

  onChange = (value) => {
    this.props.onChange(this.props.path, value);
  };

  getDeepCopy = (value) => {
    if (!value) {
      return [];
    }
    return JSON.parse(JSON.stringify(value));
  };

  getPriorityOptions = () => {
    const options = eventPriority.map((item, index) => {
      return {
        key: item.key,
        value: item.key,
        text: get(this.props, `tiersNames.${index}`) || item.label
      };
    });

    return options;
  };

  onPriorityChange = (event, element, id) => {
    this.props.onPriorityChange(element.value, id);
    const value = this.props.value;

    if (value && value.some((element) => element.id === id)) {
      this.associatedPriorityChange(element.value, id);
    }
  };

  isAssociated = (id) => {
    const value = this.props.value;
    return value ? value.some((element) => element.id === id) : false;
  };

  isDisabled = (props) => {
    const value = this.props.value;
    const state = this.props.templateState;

    if (this.isPaymentPlatformMissing(props)) {
      return true;
    }

    return value
      ? value.some(
          (element, index) => element.id === props.id && get(state, index)
        )
      : false;
  };

  isPaymentPlatformMissing = (props) => {
    return !props.paymentPlatforms || !props.paymentPlatforms.length;
  };

  getDefaultOption = (id) => {
    if (this.props.value) {
      const item = this.props.value.find((element) => element.id === id);
      if (item) {
        return item.priority;
      }
    }
    
    const tiers = this.props.tiers;
    return Object.keys(tiers)
      .reduce(
        (defaultTier, currentTierKey, index) => tiers[currentTierKey].enabled ? eventPriority[index].key : defaultTier,
        eventPriority[0].key
      );
  };

  getEventOrganizations = () => {
    const organizations = get(this.props, 'eventOrganizations');
    const tierFilterValue = get(this.props.tierFilter, 'value');

    if (tierFilterValue && organizations) {
      const eventOrganizations = organizations
        .filter((item) => item.priority === tierFilterValue)
        .map((item) => item.id)
        .filter((item) => item);

      return eventOrganizations;
    }

    return [];
  };

  getTierDropDownOptions = () => {
    const options = this.getPriorityOptions();

    const newOptions = options.map((item) => {
      const defaultTierName = eventTierLabel[item.text];

      if (defaultTierName) {
        return {
          key: `${item.value.capitalize()} - ${
            eventTierLabel[item.text].label
          }`,
          value: item.value
        };
      }

      return {
        key: `${item.value.capitalize()} - (${item.text})`,
        value: item.value
      };
    });

    return newOptions;
  };

  getTierMetaDataColumn = (list) => {
    let valueCopy = this.getDeepCopy(list);

    const options = this.getTierDropDownOptions();

    const metadata = get(valueCopy, 'metadata.columns');

    if (metadata) {
      const newColumn = {
        key: 'tier',
        dataType: 'TierType',
        additionalData: options
      };

      metadata.push(newColumn);

      return valueCopy;
    }

    return list;
  };

  columnRender = (column, value, itemProps) => {
    const key = `${column.id}-${itemProps.id}`;
    if (column.id === dependentOrganisationsGridColumns.ASSOCIATED) {
      return (
        <AssociatedCheckbox
          key={key}
          onChange={(event, value) => {
            this.onAssociatedChange(event, value, itemProps);
          }}
          defaultChecked={this.isAssociated(itemProps.id)}
          disabled={this.isDisabled(itemProps)}
        />
      );
    }

    if (column.id === dependentOrganisationsGridColumns.IMAGE) {
      return <OrganisationImage key={key} value={value} />;
    }

    if (column.id === dependentOrganisationsGridColumns.NAME) {
      const error = this.isPaymentPlatformMissing(itemProps);
      return (
        <OrganisationName
          key={key}
          value={value}
          errorMessage={error && errorMessages.paymentPlatformMissing}
        />
      );
    }

    if (column.id === dependentOrganisationsGridColumns.PRIORITY) {
      const options = this.getPriorityOptions();

      return (
        <PriorityDropdown
          disabled={!this.props.isTiersEnabled}
          key={key}
          value={value}
          id={itemProps.id}
          onPriorityChange={this.onPriorityChange}
          getDefaultOption={this.getDefaultOption}
          options={options}
        />
      );
    }
  };

  render() {
    const { Grid } = Common;

    const eventOrganizations = this.getEventOrganizations();

    this.baseFilters = this.getBaseFilters(eventOrganizations);

    return (
      <Grid.Managed
        key={this.props.value.length}
        listKey={gridKeys.ORGANIZATIONS_TIER}
        list={this.getTierMetaDataColumn(this.props.list)}
        actions={this.props.listActions}
        additionalGridActions={(
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <Button
                disabled={this.props.bulkUploadVisible}
                onClick={() => {
                  this.props.listActions.onShowBulkUpload();
                  this.props.listActions.changeBulkUploadStatus("");
                }}
              >
                Bulk upload
              </Button>
              {this.props.isEdit && (
                <ExportDownloadProvider
                  entity="page/Campaign"
                  version="v3"
                >
                  {({ open }) => (
                    <Button
                      onClick={() => open({
                        layout: "EventBulkDownload",
                        filters: [{
                          key: "eventId",
                          operator: "equalTo",
                          value: this.props.eventId,
                        }],
                      })}
                    >
                      Export existing
                    </Button>
                  )}
                </ExportDownloadProvider>
              )}
            </div>
            {this.props.bulkUploadStatusMessage && (
              <span
                style={{
                  marginTop: "0.75rem",
                  whiteSpace: "pre",
                  overflow: "visible",
                  color: this.props.bulkUploadError ? "red" : "#33b3cb",
                }}
              >
                {this.props.bulkUploadStatusMessage}
              </span>
            )}
          </div>
        )}
        search
        searchVersion="v3"
        filter={this.baseFilters}
        allowBaseFiltersUpdates
        disableQueryFilters
        filtersButton
        toggleColumns={false}
        columnRender={this.columnRender}
      ></Grid.Managed>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ event, templateBuilderEx, filter }, ownProps) => {
  const list = get(event, gridKeys.ORGANIZATIONS_TIER);
  const value = templateHelper.getValue(templateBuilderEx, ownProps.path) || [];
  const templateState = get(templateBuilderEx, `state.${ownProps.path}`);
  const eventOrganizations = get(templateBuilderEx, `data.organizations`);
  const tiers = get(templateBuilderEx, `data.template.value.tiers`) || {};
  const tierFilter = get(filter, `${gridKeys.ORGANIZATIONS_TIER}.columns.tier`);
  const bulkUploadVisible = get(event, "bulkOrgUpload.dialogVisible");
  const bulkUploadStatusMessage = get(event, "bulkOrgUpload.statusMessage");
  const bulkUploadError = get(event, "bulkOrgUpload.error");
  const eventId = get(templateBuilderEx, "data.id");
  const isEdit = get(templateBuilderEx, "isEdit");

  return {
    list,
    eventOrganizations,
    tiers,
    value,
    templateState,
    tierFilter,
    bulkUploadVisible,
    bulkUploadStatusMessage,
    bulkUploadError,
    eventId,
    isEdit,
  };
};

/** Connects component to Redux store */
const mapDispatch = (dispatch) => {
  var actions = {
    getMetadata: eventActions.getOrganizationTierMetadata,
    getListData: eventActions.getOrganizationTierListData,
    getListDataBySearchTerm:
      eventActions.getOrganizationTierListDataBySearchTerm,
    onShowBulkUpload: eventActions.toggleBulkUploadDialog,
    changeBulkUploadStatus: eventActions.changeBulkUploadStatus,
  };

  const listActions = Common.Grid.Managed.bindGridActions(dispatch, actions);
  const onPriorityChange = bindActionCreators(
    eventActions.onPriorityChange,
    dispatch
  );

  return {
    listActions,
    onPriorityChange,
  };
};

const TemplateDependentOrganisations = connect(
  mapState,
  mapDispatch
)(DependentOrganisations);
export default TemplateDependentOrganisations;
