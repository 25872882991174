import config from '../constants';

export const grecaptcha = (callback: Function, action: string) => {
  if (config.recaptcha_site_key && window.grecaptcha) {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.recaptcha_site_key, { action })
        .then((token: string) => {
          callback(token);
        });
    });
  } else {
    callback();
  }
};
