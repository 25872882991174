import React, { Component } from 'react';
import moment from 'moment';

class DateTimeFormatter extends Component {
  render() {
    if (!this.props.value) {
      return null;
    }

    return <div>{moment(this.props.value).format('YYYY/MM/DD LT')}</div>;
  }
}

export default DateTimeFormatter;
