/* eslint-disable no-plusplus */
import { assocPath } from 'ramda';
import { v4 } from 'uuid';

import { nodeTypes } from './typeDefinitions';
import { I18n } from 'react-redux-i18n';

let componentCounter = 0;
const addChildrenToNode = (parent, children) => {
  parent.children.push(children);
};
const setCounter = (InNum) => {
  componentCounter = Math.max(componentCounter, InNum);
};
const createRootNode = (
  formKey,
  props = {},
  children = [],
  type = nodeTypes.root,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});
const createSection = (
  formKey,
  props = {
    title: 'new Section'
  },
  children = [],
  type = nodeTypes.section,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createTextInput = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.textInput}`),
    description: '',
    placeholderText: '',
    required: false
  },
  children = [],
  type = nodeTypes.textInput,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createTextArea = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.textArea}`),
    description: '',
    placeholderText: '',
    required: false
  },
  children = [],
  type = nodeTypes.textArea,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createDate = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.date}`),
    description: '',
    placeholderText: '',
    minimumValue: '',
    maximumValue: '',
    required: false
  },
  children = [],
  type = nodeTypes.date,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createCheckbox = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.checkbox}`),
    description: '',
    options: '',
    required: false
  },
  children = [],
  type = nodeTypes.checkbox,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createDropdown = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.dropdown}`),
    description: '',
    options: '',
    required: false
  },
  children = [],
  type = nodeTypes.dropdown,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createConsent = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.consent}`),
    link: '',
    content: '',
    required: false,
    optOut: false,
  },
  children = [],
  type = nodeTypes.consent,
  identifier = v4(),
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createPhone = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.phone}`),
    description: '',
    required: false
  },
  children = [],
  type = nodeTypes.phone,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createAddress = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.address}`),
    description: '',
    required: false
  },
  children = [],
  type = nodeTypes.address,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createCountry = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.country}`),
    description: '',
    required: false
  },
  children = [],
  type = nodeTypes.country,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createAdditionalPhoneNumber = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.additionalPhoneNumber}`),
    description: '',
    required: false
  },
  children = [],
  type = nodeTypes.additionalPhoneNumber,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createAdditionalEmailAddress = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.additionalEmailAddress}`),
    description: '',
    required: false
  },
  children = [],
  type = nodeTypes.additionalEmailAddress,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});

const createGroupDivider = (
  formKey,
  props = {
    title: I18n.t(`components.types.${nodeTypes.groupDivider}`),
    required: false
  },
  children = [],
  type = nodeTypes.groupDivider,
  identifier = v4()
) => ({
  formKey,
  children,
  type,
  props,
  identifier
});
/**
 * This function creates default nodes for component types.
 * All of the called functions create an Object which is the descriptor of the created component.
 * @param {string} formKey The name of the FormBuilder which will use the component.
 * @param {string} type the type of the component to be created.
 * @return {Object} The node which describes the given component.
 */
const createDefaultComponentNode = (formKey, type) => {
  switch (type) {
    case nodeTypes.textInput:
      return createTextInput(formKey);
    case nodeTypes.textArea:
      return createTextArea(formKey);
    case nodeTypes.date:
      return createDate(formKey);
    case nodeTypes.checkbox:
      return createCheckbox(formKey);
    case nodeTypes.dropdown:
      return createDropdown(formKey);
    case nodeTypes.consent:
      return createConsent(formKey);
    case nodeTypes.phone:
      return createPhone(formKey);
    case nodeTypes.address:
      return createAddress(formKey);
    case nodeTypes.country:
      return createCountry(formKey);
    case nodeTypes.additionalEmailAddress:
      return createAdditionalEmailAddress(formKey);
    case nodeTypes.additionalPhoneNumber:
      return createAdditionalPhoneNumber(formKey);
    case nodeTypes.groupDivider:
      return createGroupDivider(formKey);
    default:
      return undefined;
  }
};

const createDefaultSection = (formKey) => createSection(formKey);

/**
 * This function returns the parent of a given node.
 * It will recursively call itself while going deeper into the tree (DFS).
 * If one of the possibleParentNodes for a given depth is a parent of the node
 * then it will return that node.
 * @param {Object} node The node with the unknown parent
 * @param {Object} possibleParentNode one ancestor of the node in the tree.
 * @return {?Object} The parent of the node in the tree.
 */
const getParent = (node, possibleParentNode) => {
  if (possibleParentNode.children.indexOf(node) !== -1)
    return possibleParentNode;
  if (!possibleParentNode.children.length) return undefined; // If we are at a leaf
  return possibleParentNode.children
    .map((child) => getParent(node, child))
    .filter((i) => i !== undefined)[0];
};

/**
 * This function returns the parent of the nodes if they have the same parent
 * @param {Object} firstNode A node in the tree.
 * @param {Object} secondNode Another node in the tree.
 * @param {Object} rootNode The root parent of the two nodes.
 * @return {?Object} The parent node of the two nodes.
 */
const sameParent = (firstNode, secondNode, rootNode) => {
  const firstNodeParent = getParent(firstNode, rootNode);
  const secondNodeParent = getParent(secondNode, rootNode);
  return firstNodeParent === secondNodeParent ? firstNodeParent : undefined;
};

/**
 * This function creates a default form to test with.
 * Should be updated to be more functional.
 * AddChildrenToNode is impure.
 * The new function should create the node from the bottom up.
 * @param {string} formKey name of the form to be created.
 */
const createDefaultForm = (formKey) => {
  const root = createRootNode(formKey);
  const firstSection = createSection(formKey, {
    title: 'firstSection'
  });
  // const secondSection = createSection(formKey, {
  //   title: 'secondSection',
  // });
  // const textInput1 = createTextInput(formKey);
  // const textInput2 = createTextInput(formKey, {
  //   title: 'textInput2',
  //   description: 'Second!',
  //   placeholderText: 'placeholder',
  //   charachterLimit: 'charachter limit',
  //   required: false,
  // });
  // const textInput3 = createTextInput(formKey, {
  //   title: 'cica',
  //   description: 'cica!',
  //   placeholderText: 'placeholder',
  //   charachterLimit: 'charachter limit',
  //   required: false,
  // });
  // const textInput4 = createTextInput(formKey, {
  //   title: 'cirmany',
  //   description: 'cirmany!',
  //   placeholderText: 'placeholder',
  //   charachterLimit: 'charachter limit',
  //   required: false,
  // });
  // addChildrenToNode(firstSection, textInput1);
  // addChildrenToNode(firstSection, textInput2);
  // addChildrenToNode(firstSection, textInput3);
  // addChildrenToNode(secondSection, textInput4);

  addChildrenToNode(root, firstSection);
  //addChildrenToNode(root, secondSection);

  return root;
};
/**
 * This changes a propety in the node's prop propety.
 * The prop propety describes all of the node's data except is type and its mapping key.
 * An example for be the title for a 'section' node.
 * @param {function(newNode: Object, oldNode: Object)} editNodeFunction the node updater function.
 * @param {string} fieldName The name of the field which should be updated in the node's props.
 * @param fieldValue The new value of the field.
 * @param {Object} The node that the function will update.
 */
const updateNode = (editNodeFunction, fieldName, fieldValue, node, path) => {
    const fieldPath = [path, fieldName].filter((item) => item);
    editNodeFunction(assocPath(fieldPath, fieldValue, node), node);
};
/**
 * This function finds the highes mapping key in the FormBuilder tree.
 * @param {Object} node The root node of the FormBuilder tree.
 * @return {number} The maximum of the mapping keys in the tree.
 */

export {
  createDefaultForm,
  sameParent,
  getParent,
  nodeTypes,
  createDefaultComponentNode,
  createDefaultSection,
  updateNode,
  setCounter
};
