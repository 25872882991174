import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import React from 'react';
import { Button } from 'semantic-ui-react';
import { assocPath } from 'ramda';
import { Translate } from 'react-redux-i18n';
import PropTypes from 'prop-types';
import get from 'lodash.get';

import Component from './formComponents/Component';
import ComponentSelectorModal from './ComponentSelectorModal';
import { createDefaultComponentNode, nodeTypes } from '../formBuilderHelpers';
import {
  addChildNode as addChildNodeCreator,
  editNode as editNodeCreator
} from '../actionCreators';
import { componentDescriptors } from '../descriptors';
import * as validationHelper from '../../../helpers/validationHelper';

/**
 * This React Component describes a Section in the FormBuilder.
 * It is used in the main view, and in components where the conditional feature is enabled.
 * Renders one section in the FormBuilder.
 * Renders ComponentSelectorModal for adding new components to the current section.
 * Renders button to open ComponentSelectorModal.
 * Renders section title, which changes to an input field on double click ( to edit the title ).
 * Renders all of the child Components of the Section.
 * It stores activeComponent in it's state to open one Component for editing.
 */
export class Section extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeComponent: undefined,
      componentAddModalOpen: false,
      sectionTitleEdit: false,
      isForced: false
    };
  }
  setSectionTitleEdit = () => {
    this.setState({ sectionTitleEdit: true });
  };
  setActiveComponent = (activeComponent) => {
    this.setState({ activeComponent });
  };

  unSetSectionTitleEdit = () => {
    this.setState({ sectionTitleEdit: false });
  };

  unSetActiveComponent = () => {
    this.setState({ activeComponent: undefined });
  };

  onQuestionTypeChange = (type) => {
    this.questionType = type;
  };

  closeComponentModal = () => {
    this.setState({ componentAddModalOpen: false });
  };
  /**
   * This function creates a new Component with the given type
   * and then calls addChildNode to add the newly created Component to the Section.
   * @param {string} componentType - the type of the Component to be added.
   */
  addComponentToCurrentSection = (componentType) => {
    const { addChildNode, node } = this.props;
    const currentSection = node;
    const newComponent = createDefaultComponentNode(
      node.formKey,
      componentType
    );
    newComponent && addChildNode(currentSection, newComponent, node.formKey);
    newComponent && this.setActiveComponent(newComponent.identifier);
  };
  /**
   * This function calls editNode with a node which title is changed.
   * @param {Object} event - the event emitted by the input field, containing the new title.
   */
  editTitle = (event) => {
    this.props.editNode(
      assocPath(['props', 'title'], event.target.value, this.props.node),
      this.props.node
    );
  };

  render = () => {
    const { node, disabled, isSystemAdmin } = this.props;
    const isAddressDisabled = node.children.some(
      (x) => x.type === nodeTypes.address
    );
    const isPhoneDisabled = node.children.some(
      (x) => x.type === nodeTypes.phone
    );

    const isQuestionsValidList = node.children.map((x) => {
      const isQuestionValid = componentDescriptors[x.type].every((descriptor) =>
        validationHelper.isValid(
          descriptor.validation,
          get(x, `props.${descriptor.fieldName}`)
        )
      );
      return {
        id: x.identifier,
        isValid: isQuestionValid
      };
    });

    const isAllQuestionsValid = isQuestionsValidList.every((x) => x.isValid);
    const showAddButton = this.props.formQuestionsMaxLength
      ? node.children.length < this.props.formQuestionsMaxLength
      : true;

    return (
      <div className="section-cont">
        {
          //<Divider/>
        }
        <ComponentSelectorModal
          open={this.state.componentAddModalOpen}
          closeModal={this.closeComponentModal}
          addComponentToCurrentSection={this.addComponentToCurrentSection}
          formKey={node.formKey}
          isAddressDisabled={isAddressDisabled}
          isPhoneDisabled={isPhoneDisabled}
          isAddressHidden={this.props.isAddressHidden}
          isPhoneHidden={this.props.isPhoneHidden}
          isCountryAllowed={this.props.isCountryAllowed}
          isSystemAdmin={isSystemAdmin}
        />
        {node.children &&
          node.children
            .filter((item) => !item.props.hidden || isSystemAdmin)
            .map((i, index) => {
              const isForced = i.props.isForced;

              if (isForced && !this.state.isForced) {
                this.setState({
                  isForced: true,
                  activeComponent: i.identifier
                });
              }

              return (
                <Component
                  disabled={disabled}
                  node={i}
                  sectionKey={`${this.props.sectionKey}-${index}`}
                  key={i.identifier}
                  active={i.identifier === this.state.activeComponent}
                  setActiveComponent={this.setActiveComponent}
                  unSetActiveComponent={this.unSetActiveComponent}
                  isCurrentNodeValid={
                    isQuestionsValidList.find((x) => x.id === i.identifier)
                      .isValid
                  }
                />
              );
            })}

        {showAddButton && (
          <Button
            basic
            fluid
            className="add-button"
            onClick={() => this.setState({ componentAddModalOpen: true })}
            disabled={!isAllQuestionsValid || disabled}
          >
            <Translate value="components.section.addQuestion" />
          </Button>
        )}
      </div>
    );
  };
}

Section.propTypes = {
  addChildNode: PropTypes.func,
  editNode: PropTypes.func,
  node: PropTypes.object,
  isSystemAdmin: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    form: state.formBuilders[ownProps.node.formKey],
    isSystemAdmin: get(state, 'session.isSystemAdmin')
  };
};

const mapDispatchToProps = (dispatch) => ({
  addChildNode: bindActionCreators(addChildNodeCreator, dispatch),
  editNode: bindActionCreators(editNodeCreator, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Section);
