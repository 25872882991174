import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import { Form as CommonForm } from '../../';
import { connect } from 'react-redux';
import get from 'lodash.get';
import { bindActionCreators } from 'redux';
import * as autocompleteActions from '../../../modules/autocomplete';
import { isArrayWithItems } from '../../../helpers/arrayHelper';

class LookupItem extends Component {
  // the below is assuming that the onchange event is expecting a label and a value type
  // so it calls onChange twice with value and label paths
  onValueChange = (options, evt, { value }) => {
    const item = options.find((x) => x.value === value);

    this.props.onChange(evt, {
      value,
      path: `${this.props.path}.value`
    });

    this.props.onChange(evt, {
      value: item ? item.text : null,
      path: `${this.props.path}.label`
    });
  };

  search = (event, searchTerm) => {
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.searchEntities(
        this.props.entity,
        this.props.path,
        this.getLookupRequest(searchTerm.searchQuery)
      );
    }, 500);
  };

  getBaseFilters = () => {
    const baseFilter = this.props.filter;
    if (!baseFilter) {
      return [];
    }

    return isArrayWithItems(baseFilter) ? baseFilter : [baseFilter];
  };

  getLookupRequest = (searchText) => {
    let filters = this.getBaseFilters();
    filters = filters.concat(this.props.additionalFilters);
    return {
      page: 1,
      pageSize: 10,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: filters,
      text: searchText
    };
  };

  onFocus = () => {
    if (
      !this.props.value &&
      ((!this.props.searchString && !this.props.options) ||
        !this.props.options.length)
    ) {
      this.search(null, { searchQuery: '' });
    }
  };

  getItem = (item) => {
    return {
      text: item.name,
      value: item.id
    };
  };

  getOptions = (data) => {
    if (!data || !data.list || !data.list.length) {
      return;
    }

    const options = data.list.map((item) => {
      return this.props.getLookupItem
        ? this.props.getLookupItem(item)
        : this.getItem(item);
    });

    return options;
  };

  getDefaultOptions = () => {
    if (this.props.value && this.props.label) {
      return [
        {
          text: this.props.label,
          value: this.props.value
        }
      ];
    }

    return [];
  };

  render() {
    const { value, lookupData } = this.props;
    const options = this.getOptions(lookupData) || this.getDefaultOptions();
    if (this.props.hasEmptyOption) options.unshift({});

    return (
      <Form.Group grouped>
        <CommonForm.Autocomplete
          value={value}
          onChange={this.onValueChange.bind({}, options)}
          placeholder="Select..."
          search={this.search.bind(this)}
          options={options}
          loading={this.props.isLoading}
          onFocus={this.onFocus}
          clearOnChange={this.props.clearOnChange}
        />
      </Form.Group>
    );
  }
}

const mapState = ({ autocomplete }, { filter, path }) => {
  const isLoading = get(autocomplete, `${path}.loading`);
  const lookupData = get(autocomplete, `${path}.data`);

  return {
    isLoading,
    lookupData,
    filter
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(autocompleteActions, dispatch);
};

export default connect(mapState, mapDispatch)(LookupItem);
