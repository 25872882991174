import React from 'react';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Button, Modal } from 'semantic-ui-react';
import { useAppDispatch } from '../../../hooks';
import { AppDispatch, RootState } from '../../../store';
import { paths } from '../store/paths';
import * as ticketingActions from '../store/ticketingActions';
import '../_styles/modals/collectPaymentWindow.css';
import { useParams } from 'react-router-dom';
import { RouteParams } from '../../../containers/app';

import CurrencyHelper from '../../../helpers/currencyHelper';

export const CollectPaymentWindow = () => {
  const dispatch: AppDispatch = useAppDispatch();
  const { saleId } = useParams<RouteParams>();
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const customer = ticketing?.record?.data?.data?.customer;
  const recordData = ticketing.record?.data?.data;
  const installments = recordData?.financials?.installments;
  const amount = installments?.payments?.[0]?.amount || 0;
  const count = installments?.count || 0;
  const paidCount = installments?.paidCount || 0;
  const currency = recordData?.currency;

  const onClose = () => {
    dispatch(
      ticketingActions.onValueChange(paths.collectPaymentWindowIsOpen, false)
    );
  };

  const onContinue = () => {
    dispatch(ticketingActions.createManualInstallmentRecord(saleId));
  };

  const isOpen = ticketing?.sale?.collectPaymentWindow?.isOpen;
  const translationOptions = {
    amount: CurrencyHelper.formatCurrency(amount * (count - paidCount), currency),
    displayName: `${customer.firstName} ${customer.lastName}`
  };

  return (
    <Modal
      className="wizard collect-payment-window"
      size="tiny"
      dimmer="inverted"
      closeIcon
      open={isOpen}
      onClose={onClose}
    >
      <Modal.Header>
        {I18n.t('ticketing.edit.collect-payment.collect-modal.title')}
      </Modal.Header>
      <Modal.Content>
        <div className="content">
          <Translate
            value="ticketing.edit.collect-payment.collect-modal.content"
            dangerousHTML
            {...translationOptions}
          />
        </div>
        <div className="sub-content">
          {I18n.t('ticketing.edit.collect-payment.collect-modal.sub-content')}
        </div>
      </Modal.Content>
      <Modal.Actions>
        <div>
          <Button floated="left" onClick={onClose}>
            Cancel
          </Button>
          <Button primary onClick={onContinue}>
            Continue
          </Button>
        </div>
      </Modal.Actions>
    </Modal>
  );
};
