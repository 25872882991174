import { I18n } from 'react-redux-i18n';
import stringMap from '../../internationalisation/data.json';
import get from 'lodash.get';

const defaultStringMap = (() => {
  return stringMap[Object.keys(stringMap)[0]];
})();

export const getConfigurationEntry = (template, key) => {
  if (template) {
    if (template.form) {
      const pageKeyList = Object.keys(template.form);
      for (let i = 0; i < pageKeyList.length; i++) {
        const elementList = template.form[pageKeyList[i]];
        const element = elementList.find((item) => item.key === key);
        if (element) return element;
      }
    } else {
      const element = template.find((item) => item.key === key);
      if (element) return element;
    }
  }
  return null;
};

export const getText = (configItem, key) => {
  if (configItem && configItem.text && configItem.text[key]) {
    const exists = hasTextEntryInResources(configItem.text[key]);
    if (exists) {
      return I18n.t(configItem.text[key]);
    }
  }
  return null;
};

export const getValue = (state, path) => {
  return get(state, `data.${path}`);
};

export const getState = (state, path, key) => {
  return get(state, `state.${path}.${key}`);
};

export const getTranslation = (key, translationObject) => {
  const exists = hasTextEntryInResources(key);
  if (exists) {
    return I18n.t(key, translationObject);
  }
  return null;
};

export const getFromState = (
  state,
  entity,
  path,
  key,
  recordKey = 'record'
) => {
  return get(state, `${entity}.${recordKey}.state.${path}.${key}`);
};

export const hasTextEntryInResources = (key) => {
  return !!get(defaultStringMap, key);
};
