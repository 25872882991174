import React from 'react';
import { connect } from 'react-redux';

import {
  authenticate,
  handleValueChange,
  refreshSession
} from '../../modules/session';

import { Modal, Form, Button } from 'semantic-ui-react';
import VerificationForm from '../authentication/verificationForm';
import RecoveryForm from '../authentication/recoveryForm';

import config from '../../constants';
import { verificationVariant } from '../../constants/multiFactorAuth';
import { grecaptcha } from '../../helpers/grecaptcha';

class SessionVerificationModalContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      variant: verificationVariant.authCode,
      disabled: false
    };
  }

  handleVerifyButtonClick = () => {
    const { email, password } = this.props.loginForm;

    if (this.state.disabled === false) {
      this.setState({
        disabled: true
      });
    }

    if (email && password) {
      this.handleAuthenticate();
    } else {
      this.handleSessionRefresh();
    }
  };

  handleAuthenticate = () => {
    const { email, password, remember, authCode, recoveryCode } =
      this.props.loginForm;

    const verificationCode =
      this.state.variant === verificationVariant.authCode
        ? authCode
        : recoveryCode;

    const tokenCallback = (token) =>
      this.props.authenticate(
        email,
        password,
        remember,
        token,
        verificationCode,
        this.state.variant
      );
    grecaptcha(tokenCallback, 'login');
  };

  handleSessionRefresh = () => {
    const storageSession = localStorage.getItem('session');
    const session = storageSession && JSON.parse(storageSession);

    const { authCode, recoveryCode } = this.props.loginForm;
    const verificationCode =
      this.state.variant === verificationVariant.authCode
        ? authCode
        : recoveryCode;

    if (session && session.refresh) {
      const tokenCallback = (token) =>
        this.props.refreshSession(
          session.account.id,
          session.refresh,
          token,
          verificationCode,
          this.state.variant
        );
      grecaptcha(tokenCallback, 'SessionRefresh');
    }
  };

  handleVerificationVariantChange = (value) => {
    if (this.state.variant !== value) {
      this.setState({
        variant: value
      });
    }
  };

  render() {
    if (this.props.show) {
      return (
        <Modal
          className="session-verification-modal"
          size="tiny"
          dimmer="blurring"
          open
        >
          <Modal.Content>
            <Form onSubmit={this.handleVerifyButtonClick}>
              <div className="verification-form">
                <h1 className="variant-title">Verify your identify</h1>
                <div className="variant-description">
                  Verify using the 2-Factor app set up in your phone
                </div>
                <br />
                {this.state.variant === verificationVariant.authCode && (
                  <VerificationForm
                    path="loginForm"
                    handleValueChange={this.props.handleValueChange}
                    handleVerificationVariantChange={
                      this.handleVerificationVariantChange
                    }
                  />
                )}
                {this.state.variant === verificationVariant.recoveryCode && (
                  <RecoveryForm
                    path="loginForm"
                    value={this.props.loginForm.recoveryCode}
                    handleValueChange={this.props.handleValueChange}
                    handleVerificationVariantChange={
                      this.handleVerificationVariantChange
                    }
                  />
                )}
                <div className="verification-action-button">
                  <Button
                    primary
                    loading={this.props.isSessionLoading}
                    disabled={
                      this.props.isSessionLoading || this.state.disabled
                    }
                  >
                    Verify
                  </Button>
                </div>
                <br />
                <div className="verification-contact-us">
                  Need help? Contact our{' '}
                  <a href={config.contactUsUrl}>Support team</a>
                </div>
              </div>
            </Form>
          </Modal.Content>
        </Modal>
      );
    }
    return <div></div>;
  }
}

const mapState = ({ session }) => {
  return {
    isSessionLoading: session.processing,
    loginForm: session.loginForm
  };
};

const mapDispatch = (dispatch) => {
  return {
    authenticate(
      email,
      password,
      remember,
      verificationToken,
      verificationCode,
      verificationVariant
    ) {
      dispatch(
        authenticate(
          email,
          password,
          remember,
          verificationToken,
          verificationCode,
          verificationVariant
        )
      );
    },
    refreshSession(
      accountId,
      refreshCode,
      verificationToken,
      verificationCode,
      verificationVariant
    ) {
      dispatch(
        refreshSession(
          accountId,
          refreshCode,
          verificationToken,
          verificationCode,
          verificationVariant
        )
      );
    },
    handleValueChange(path, value) {
      dispatch(handleValueChange(path, value));
    }
  };
};

const SessionVerificationModal = connect(
  mapState,
  mapDispatch
)(SessionVerificationModalContainer);
export default SessionVerificationModal;
