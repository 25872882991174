import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Common } from '../';
import { I18n } from 'react-redux-i18n';

class ManagedHeader extends Component {
  render() {
    return (
      <Common.Header {...this.props} as={this.props.as || 'h1'}>
        {this.props.text}
      </Common.Header>
    );
  }
}

const mapState = (state, ownProps) => {
  const { as, label } = ownProps;

  // Get the text from data store.
  const text = I18n.t(label);

  return { text, as };
};

export const TemplateHeader = connect(mapState)(ManagedHeader);
