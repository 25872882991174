import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as templateHelper from '../template/templateHelper';
import { TemplateHorizontalRule } from '../template/templateHorizontalRule';

import { TemplateHeader } from '../template/templateHeader';
import { Form as CommonForm } from '../';

import { paymentFeeCalculation } from '../../pages/campaign/constants';
import { TemplateText } from '../template/templateText';

class PaymentFeeCalculationContainer extends Component {
  onChange = (e, { value }) => {
    this.props.onChange(this.props.path, value);
  };

  render() {
    return (
      <div className="ui container payment-fee-calculation">
        <TemplateHorizontalRule />

        <TemplateHeader
          as="h3"
          label="template.page.content.ticketing.paymentFeeCalculation.header"
        />

        <TemplateText label="template.page.content.ticketing.paymentFeeCalculation.description" />

        <CommonForm.Checkbox
          name="templateRadioButton"
          inputLabel={I18n.t(
            'template.page.content.ticketing.paymentFeeCalculation.options.onTopOfTicketPrice.label'
          )}
          value={paymentFeeCalculation.onTopOfTicketPrice}
          checked={
            this.props.value === paymentFeeCalculation.onTopOfTicketPrice
          }
          onChange={this.onChange}
          radio
        />

        <TemplateText label="template.page.content.ticketing.paymentFeeCalculation.options.onTopOfTicketPrice.description" />

        <CommonForm.Checkbox
          name="templateRadioButton"
          inputLabel={I18n.t(
            'template.page.content.ticketing.paymentFeeCalculation.options.constantTicketPricing.label'
          )}
          value={paymentFeeCalculation.constantTicketPricing}
          checked={
            this.props.value === paymentFeeCalculation.constantTicketPricing
          }
          onChange={this.onChange}
          radio
        />

        <TemplateText label="template.page.content.ticketing.paymentFeeCalculation.options.constantTicketPricing.description" />
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

const PaymentFeeCalculation = connect(mapState)(PaymentFeeCalculationContainer);
export default PaymentFeeCalculation;
