import React from 'react';

import moment from 'moment';
import { I18n } from 'react-redux-i18n';

import { Dropdown } from 'semantic-ui-react';
import DatePickerInput from '../../../components/form/datePicker';

import {
  timeLapse as timeLapseConstant,
  timeLapseOptions,
  reportTimelineBracketOptions,
  reportByDateAndMetricTypeOptions
} from '../constants';

export const FilterContainer = (props) => {
  const {
    reportByDateAndMetricType,
    timeLapse,
    timeLapseFrom,
    timeLapseTo,
    timeBracket
  } = props;

  const timeLapseRenderOptions = Object.keys(
    timeLapseOptions[reportByDateAndMetricType]
  ).map((item) => {
    return {
      key: item,
      value: item,
      text: timeLapseOptions[reportByDateAndMetricType][item].text
    };
  });

  const timeBracketRenderOptions = props.timeBracketOptions.map((item) => {
    return {
      key: item,
      value: item,
      text: reportTimelineBracketOptions[item].text
    };
  });

  const timeLapseFromError =
    timeLapseFrom &&
    moment(timeLapseFrom).isValid() &&
    moment() < moment(timeLapseFrom);
  const timeLapseToError =
    timeLapseTo &&
    moment(timeLapseTo).isValid() &&
    moment() < moment(timeLapseTo);
  const timeLapseError =
    timeLapseFrom &&
    timeLapseTo &&
    moment(timeLapseFrom).isValid() &&
    moment(timeLapseTo).isValid() &&
    moment(timeLapseFrom) > moment(timeLapseTo);

  return (
    <div className="filter-container">
      <div className="filter-inner-container">
        <h2>{I18n.t('dashboard.top-area.filter.header')}</h2>

        <div className="ui form filters-group">
          <Dropdown
            selection
            options={reportByDateAndMetricTypeOptions}
            value={reportByDateAndMetricType}
            onChange={props.onReportByDateAndMetricTypeChange}
            icon="chevron down"
          />

          {timeBracketRenderOptions.length > 1 && (
            <Dropdown
              selection
              options={timeBracketRenderOptions}
              value={timeBracket}
              onChange={props.onTimeBracketChange}
              icon="chevron down"
            />
          )}

          <Dropdown
            selection
            options={timeLapseRenderOptions}
            value={timeLapse}
            onChange={props.onTimeLapseChange}
            icon="chevron down"
          />

          {timeLapse ===
            timeLapseConstant[reportByDateAndMetricType].customRange && (
            <React.Fragment>
              <DatePickerInput
                className={`builder-form-input date-from ${
                  timeLapseFromError || timeLapseError ? 'error' : ''
                }`}
                placeholder="From"
                value={timeLapseFrom}
                onChange={props.onTimeLapseFromChange}
                maxDate={timeLapseTo ? moment(timeLapseTo) : moment()}
              />

              <DatePickerInput
                className={`builder-form-input date-to ${
                  timeLapseToError || timeLapseError ? 'error' : ''
                }`}
                placeholder="To"
                value={timeLapseTo}
                onChange={props.onTimeLapseToChange}
                minDate={timeLapseFrom ? moment(timeLapseFrom) : null}
                maxDate={moment()}
              />
            </React.Fragment>
          )}
        </div>
      </div>
    </div>
  );
};
