import React, { Component } from 'react';
import get from 'lodash.get';
import {
  Item,
  Grid,
  GridColumn,
  Container,
  Breadcrumb,
  Button,
  Header
} from 'semantic-ui-react';
import GridRow from 'semantic-ui-react/dist/commonjs/collections/Grid/GridRow';
import { Link, withRouter } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';

import { donationSummaryModalOpen } from './store/accountDetailActions';
import { impersonateAdmin } from '../../../modules/session';

import { Record } from '../../../modules/bin/utility';
import { formatDecimalNumber } from '../../../helpers/converterHelper';
import { getAvatarImagePath } from '../../../helpers/imageUrlHelper';

import AccountStatus from '../status';
import Spinner from '../../../components/common/spinner';
import DonationSummaryModalContainer from './donationSummaryModal/donationSummaryModal';

import config from '../../../constants';
import { grecaptcha } from '../../../helpers/grecaptcha';
import { getTabFromAccountTypes } from '../constants';

class DetailHeader extends Component {
  getAccountsTabUrl() {
    if (this.props.accountDetail) {
      const account = this.props.accountDetail;
      return `/accounts/${getTabFromAccountTypes(account)}`;
    }
  }

  generalRedirect = () => {
    this.props.history.push(config.DEFAULT_CAMPAIGNS_URL);
  };

  handleImpersonateClick = () => {
    const tokenCallback = (token) =>
      this.props.impersonateAdmin(this.props.accountDetail.id, token);
    grecaptcha(tokenCallback, 'SessionImpersonate');
  };

  donationSummaryModalOpen = () => {
    this.props.donationSummaryModalOpen(true);
  };

  render() {
    if (Record.isRecordLoading(this.props.impersonatedRecord)) {
      return <Spinner />;
    }

    if (this.props.needGeneralRedirect) {
      this.generalRedirect();
    }

    const accountDetail = this.props.accountDetail;
    const accountName = `${accountDetail.firstName} ${accountDetail.lastName}`;
    const accountSettingsUrl = `/accounts/settings/${this.props.match.params.id}`;
    const totalAmountFundraised = this.props.totalAmountFundraised.error.show
      ? 'N/A'
      : `$${formatDecimalNumber(this.props.totalAmountFundraised.data, true)}`;
    const totalAmountDonated = this.props.totalAmountDonated.error.show
      ? 'N/A'
      : `$${formatDecimalNumber(this.props.totalAmountDonated.data, true)}`;
    const isSystemAdmin = this.props.isSystemAdmin;
    const canImpersonate =
      !this.props.impersonated &&
      !this.props.isOrganizationAdmin &&
      accountDetail.isAdmin;
    const isSourceVisible =
      !this.props.isOrganizationAdmin && !accountDetail.isOrgAdmin;

    const email = get(accountDetail, 'email');

    return (
      <Grid>
        <DonationSummaryModalContainer email={email} />
        <GridRow>
          <GridColumn width={8}>
            <Item.Group>
              <Item>
                <Item.Image
                  size="tiny"
                  src={getAvatarImagePath(accountDetail.mainImagePath)}
                  avatar
                  className="account-photo"
                />
                <Item.Content>
                  <Item.Header as="a">
                    <h1>{accountName}</h1>
                  </Item.Header>
                  <AccountStatus value={accountDetail.status} />
                  <Item.Extra>
                    <Breadcrumb size="large">
                      <Breadcrumb.Section>
                        <Link to={this.getAccountsTabUrl()}>Accounts</Link>
                      </Breadcrumb.Section>
                      <Breadcrumb.Divider icon="right angle" />
                      <Breadcrumb.Section active>
                        {accountName}
                      </Breadcrumb.Section>
                    </Breadcrumb>
                  </Item.Extra>
                </Item.Content>
              </Item>
            </Item.Group>
          </GridColumn>
          <GridColumn width={8}>
            <Container textAlign="right">
              {canImpersonate && (
                <Button onClick={this.handleImpersonateClick}>
                  {' '}
                  <Translate value="account.account-detail.impersonate" />
                </Button>
              )}
              <Link to={accountSettingsUrl}>
                <Button primary>
                  <Translate value="account.account-detail.editAccount" />
                </Button>
              </Link>
              {isSystemAdmin && (
                <Button onClick={this.donationSummaryModalOpen}>
                  {' '}
                  <Translate value="account.account-detail.donationSummary" />
                </Button>
              )}
            </Container>
          </GridColumn>
        </GridRow>
        <GridRow className="margin-top-30 label-container">
          <GridColumn width={6}>
            <Header as="h4">
              <Translate value="account.account-detail.email" />
            </Header>
            <span className="label">{accountDetail.email}</span>
          </GridColumn>
          {isSourceVisible && (
            <GridColumn width={6}>
              <Header as="h4">
                <Translate value="account.account-detail.source" />
              </Header>
              <span className="label capitalize">{accountDetail.source}</span>
            </GridColumn>
          )}
        </GridRow>
        {/*
        <GridRow className="label-container">
          <GridColumn width={6}>
            <Header as="h4">
              <Translate value="account.account-detail.totalAmountFundraised" />
            </Header>
            <span className="label">{totalAmountFundraised}</span>
          </GridColumn>
          <GridColumn width={6}>
            <Header as="h4">
              <Translate value="account.account-detail.totalAmountDonated" />
            </Header>
            <span className="label">{totalAmountDonated}</span>
          </GridColumn>
        </GridRow>
        */}
      </Grid>
    );
  }
}

const mapState = ({ accountDetail, session, account }) => {
  return {
    accountDetail: accountDetail.detailRecord.data.data,
    totalAmountDonated: accountDetail.totalDonatedRecord,
    totalAmountFundraised: accountDetail.totalFundraisingRecord,
    impersonated: session.impersonated,
    accountId: get(session, 'account.id'),
    impersonatedRecord: account.impersonatedRecord,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    isSystemAdmin: get(session, 'isSystemAdmin'),
    needGeneralRedirect: get(session, 'needGeneralRedirect')
  };
};

const mapDispatch = (dispatch) => {
  return {
    impersonateAdmin(id, verificationToken = null) {
      dispatch(impersonateAdmin(id, verificationToken));
    },
    donationSummaryModalOpen(open) {
      dispatch(donationSummaryModalOpen(open));
    }
  };
};

const AccountDetailHeaderContainer = withRouter(
  connect(mapState, mapDispatch)(DetailHeader)
);
export default AccountDetailHeaderContainer;
