//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Components
import { CollectionBuilder } from '../../collectionBuilder';

//------------Helpers
import { MAX_SETUP_BUTTON_ITEMS_COUNT } from '../../collectionBuilder/constants';
import ButtonSetup from '../../collectionBuilder/components/setupButtonItem';

import { bindActionCreators } from 'redux';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { getValue } from '../templateHelper';
import { TemplateText } from '../templateText';
import { TemplateHeader } from '../templateHeader';
import { TemplateHorizontalRule } from '../templateHorizontalRule';

class ManagedSetupButtons extends Component {
  getModel = () => {
    return {
      text: null,
      link: null,
      openInNewWindow: false,
      visibility: this.props.oneOfPageTypesIsEnabled ? 'private' : 'public',
      pageTypes: []
    };
  };

  getCustomTabVsibility = () => {
    const isCampaignTypeEvent = this.props.campaignType === 'event';
    if (this.props.campaignType === 'giveNow') {
      return false;
    }

    // if the campaign type is event and
    // fundraising is disabled and
    // collections is disabled and
    // teams is disabled,
    // we do not want to show the custom tab
    return !(
      isCampaignTypeEvent &&
      !this.props.isFundraisersEnabled &&
      !this.props.isCollectionsEnabled &&
      !this.props.isTeamsEnabled
    );
  };

  componentDidMount() {
    const { path, onChange, pageButtons } = this.props;
    // Below sets any buttons visibility to public when it's null
    // a bug was introduce which didn't set the visibility correctly and the below resolves this dirty data
    if (pageButtons?.some((x) => x.visibility === null)) {
      pageButtons.forEach((button) => {
        if (button?.visibility === null) {
          button.visibility = 'public';
        }
      });
      onChange(path, pageButtons);
    }
  }

  render() {
    const { path, disabled, validation, onChange } = this.props;
    const showCustomTab = this.getCustomTabVsibility();

    return showCustomTab ? (
      <React.Fragment>
        <TemplateHorizontalRule />
        <TemplateHeader as="h3" label="Additional buttons" />

        <TemplateText
          label="This allows you to configure buttons that will appear on your campaign pages or on fundraising pages created by your supporters. Buttons will appear below the default buttons (Donation, Ticketing and Fundraising) on each page. Up to 5 buttons may be added per campaign."
          size="small"
        />

        <CollectionBuilder
          path={path}
          disabled={disabled}
          onChange={onChange}
          validation={validation}
          maxItemsCount={MAX_SETUP_BUTTON_ITEMS_COUNT}
          model={this.getModel()}
          entity="page"
          i18Key="advanced.default-button-setup"
          itemType={
            <ButtonSetup
              campaignType={this.props.campaignType}
              isTeamsEnabled={this.props.isTeamsEnabled}
              isCollectionsEnabled={this.props.isCollectionsEnabled}
              isFundraisersEnabled={this.props.isFundraisersEnabled}
              oneOfPageTypesIsEnabled={this.props.oneOfPageTypesIsEnabled}
            />
          }
        ></CollectionBuilder>
      </React.Fragment>
    ) : null;
  }
}

const mapState = (state) => {
  const isTeamsEnabled = getValue(state.templateBuilderEx, 'teams.enabled');

  const isCollectionsEnabled = getValue(
    state.templateBuilderEx,
    'collections.enabled'
  );

  const isFundraisersEnabled = getValue(
    state.templateBuilderEx,
    'fundraisers.enabled'
  );

  return {
    pageButtons: getValue(
      state.templateBuilderEx,
      'template.value.customSetup.pageButtons'
    ),
    campaignType: getValue(state.templateBuilderEx, 'type'),
    isTeamsEnabled,
    isCollectionsEnabled,
    isFundraisersEnabled,
    // prettier-ignore
    oneOfPageTypesIsEnabled: isFundraisersEnabled || isCollectionsEnabled || isTeamsEnabled
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

export const TemplateSetupButtons = connect(
  mapState,
  mapDispatch
)(ManagedSetupButtons);
