export const columnTypes = {
  DATETIME: 'datetime',
  DATE: 'date',
  DECIMAL: 'decimal',
  INT32: 'int32',
  STRING: 'string',
  BOOLEAN: 'boolean',
  CAPITALIZE_STRING: 'capitalizeString',
  DISTANCE: 'distance',
  TIME: 'time',
  BADGE: 'badge'
};

export const DEFAULT_TIME = '23:59:59.999';
