import React from 'react';
import {
  Form,
  Dropdown,
  DropdownProps,
  DropdownItemProps
} from 'semantic-ui-react';

interface IDropdownItemProps {
  path: string;
  value: boolean | number | string | (boolean | number | string)[];
  options?: DropdownItemProps[];
  onChange: (
    event: React.SyntheticEvent<HTMLElement>,
    data: DropdownProps
  ) => void;
}

const DropdownItem = ({
  path,
  onChange,
  value,
  options
}: IDropdownItemProps) => {
  return (
    <Form.Group grouped>
      <Dropdown
        fluid
        options={options}
        path={path}
        onChange={onChange}
        value={value}
        selection
        placeholder="Select..."
        icon="chevron down"
      />
    </Form.Group>
  );
};

export default DropdownItem;
