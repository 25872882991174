import React, { Component } from 'react';
import { Input } from 'semantic-ui-react';
import FormbuilderFieldWrapper from './formbuilderFieldWrapper';

class FormbuilderText extends Component {
  render() {
    const {
      onChange,
      value,
      type,
      placeholder,
      label,
      validation,
      error,
      errorMessage,
      onBlur,
      inputSub,
      popup,
      initialValidation
    } = this.props;

    return (
      <FormbuilderFieldWrapper
        label={label}
        validation={validation}
        error={error}
        errorMessage={errorMessage}
        onBlur={onBlur}
        inputSub={inputSub}
        popup={popup}
        value={value}
        initialValidation={initialValidation}
      >
        <Input
          id="formbuilder-text"
          onChange={onChange}
          value={value}
          type={type}
          placeholder={placeholder}
        />
      </FormbuilderFieldWrapper>
    );
  }
}

export default FormbuilderText;
