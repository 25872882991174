import notificationEntity from '../../constants/notificationEntity';
import notificationEventType from '../../constants/notificationEventType';

export const notificationEntityOptions = [
  {
    value: notificationEntity.organization,
    text: 'Organisation',
    locale: 'Organization'
  },
  {
    value: notificationEntity.campaign,
    text: 'Campaign'
  },
  {
    value: notificationEntity.team,
    text: 'Team'
  },
  {
    value: notificationEntity.fundraiser,
    text: 'Fundraiser'
  },
  {
    value: notificationEntity.event,
    text: 'Event'
  },
  {
    value: notificationEntity.donation,
    text: 'Donation'
  },
  {
    value: notificationEntity.session,
    text: 'Session'
  },
  {
    value: notificationEntity.accessKey,
    text: 'Access Key'
  },
  {
    value: notificationEntity.microblog,
    text: 'Microblog'
  },
  {
    value: notificationEntity.ticket,
    text: 'Ticket'
  },
  {
    value: notificationEntity.sale,
    text: 'Sale'
  },
  {
    value: notificationEntity.merchandise,
    text: 'Merchandise'
  },
  {
    value: notificationEntity.account,
    text: 'Account'
  },
  {
    value: notificationEntity.collection,
    text: 'Superteam'
  },
  {
    value: notificationEntity.donationSubscription,
    text: 'Donation Subscription'
  },
  {
    value: notificationEntity.fitnessActivity,
    text: 'Fitness Activity'
  },
  {
    value: notificationEntity.fitnessPlatformLink,
    text: 'Fitness Platform Link'
  },
  {
    value: notificationEntity.customActivity,
    text: 'Custom Activity'
  },
  {
    value: notificationEntity.eventAmbassador,
    text: 'Event Ambassador'
  },
  {
    value: notificationEntity.eventAmbassadorProgram,
    text: 'Event Ambassador Program'
  },
  {
    value: notificationEntity.campaignAmbassadorProgram,
    text: 'Campaign Ambassador Program'
  },
  {
    value: notificationEntity.eventOrganizer,
    text: 'Event Organiser',
    locale: 'Event Organizer'
  }
];

export const notificationEventTypeOptions = [
  {
    value: notificationEventType.created,
    text: 'Created'
  },
  {
    value: notificationEventType.deleted,
    text: 'Deleted'
  },
  {
    value: notificationEventType.updated,
    text: 'Updated'
  }
];
