import React from 'react';
import Constants from '../../constants';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { ExportHistory } from './models/export-history';
import {
  downloadExportHistory,
  ISearchExportHistoryArgs,
  searchExportHistory
} from './api';
import moment from 'moment';

const ExportHistoryQueries = {
  Search: 'export_history_search'
};
const queryClient = new QueryClient();

export const ExportHistoryApiProvider: React.FunctionComponent<{
  children: React.ReactNode;
}> = ({ children }) => (
  <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
);

export function useExportHistoryApi(args: ISearchExportHistoryArgs = {}) {
  const { apikey = '' } = Constants;
  const sessionKey = useSelector<RootState, string>(
    (state) => state.session.key
  );

  const search = React.useCallback(
    async () => await searchExportHistory(apikey, sessionKey, args),
    [apikey, sessionKey, args]
  );

  const download = React.useCallback(
    async (item: ExportHistory) =>
      await downloadExportHistory(apikey, sessionKey, {
        id: item.id
      }),
    [apikey, sessionKey]
  );

  const { data, refetch, isLoading, dataUpdatedAt } = useQuery(
    [ExportHistoryQueries.Search, args.page],
    search,
    { enabled: false }
  );
  const list = (data?.data?.list || []).map((item) => new ExportHistory(item));
  const totalCount = data?.data?.totalCount || 0;
  const page = data?.data?.page || 1;
  const pageSize = data?.data?.pageSize || 50;
  const totalPages = Math.ceil(totalCount / pageSize);

  const timer = React.useRef(0);
  const [timeSince, setTimeSince] = React.useState('');

  const setCallbackTimer = React.useCallback(
    (minutes: number, callback: () => void) => {
      const currentTime = Date.now();
      if (currentTime > timer.current + minutes * 60 * 1000) {
        timer.current = currentTime;
        callback();
      }
      setTimeSince(moment(moment(timer.current).parseZone()).fromNow());
    },
    [timer, setTimeSince]
  );

  React.useEffect(() => {
    const tick = (ms = 0) => {
      setTimeout(() => {
        setCallbackTimer(2, () => {
          refetch();
        });
        tick(1000);
      }, ms);
    };
    tick();
  }, []);

  return {
    list,
    page,
    pageSize,
    totalPages,
    loading: isLoading,
    download,
    lastUpdate: dataUpdatedAt,
    timer: timeSince
  };
}
