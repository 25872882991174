import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as generalActions from '../modules/general';
import * as sessionActions from '../modules/session';
import get from 'lodash.get';

const clear = (ComposedComponent) => {
  class hoc extends React.Component {
    constructor(props) {
      super(props);
      if (!this.props.pingProcessing) {
        props.clear();
      }
      if (this.props.sessionKey && this.props.pingProcessing) {
        this.props.pingClear();
      }
    }

    render() {
      return <ComposedComponent {...this.props} />;
    }
  }

  return connect(mapState, mapDispatch)(hoc);
};

const mapState = (state) => {
  return {
    sessionKey: get(state, 'session.key'),
    pingProcessing: get(state, 'session.pingProcessing')
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(generalActions, dispatch),
    ...bindActionCreators(sessionActions, dispatch)
  };
};

export default clear;
