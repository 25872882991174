import moment from 'moment';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import CapitalizeText from '../../../components/common/capitalizeText';

import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { ViewTable } from '../../../components/layouts/viewTable';
import config from '../../../constants';
import { RootState } from '../../../store';
import { paymentManualType } from '../constants';

import CurrencyHelper from '../../../helpers/currencyHelper';

interface ITicketInstallmentTableProps {
  showEmptyFields: boolean;
  displayOrgCurrency: boolean;
}

interface IInstallmentPayment {
  label: string;
  paidAtLocal: Date;
  type?: string;
  refunded?: boolean;
}

export const TicketInstallmentTable = ({
  showEmptyFields,
  displayOrgCurrency,
}: ITicketInstallmentTableProps) => {
  const ticketing = useSelector((state: RootState) => state.ticketing);
  const recordData = ticketing.record?.data?.data;
  const installments = recordData?.financials?.installments;
  const currency = displayOrgCurrency ? recordData?.organization.currency : recordData?.currency;

  let paidAmount = installments?.paidAmount;
  let amount = recordData?.amount;

  if (displayOrgCurrency) {
    const exchangeRate = recordData?.financials.exchangeRate;

    paidAmount *= exchangeRate;
    amount *= exchangeRate;
  }

  if (!recordData?.financials?.installments?.enabled) {
    return null;
  }


  const installmentsPayments = installments?.payments || [];
  const records: Array<IInstallmentPayment> = installmentsPayments
    .map((item: any): IInstallmentPayment => ({
      type: item.type,
      paidAtLocal: moment(item.paidAtLocal).toDate(),
      label: CurrencyHelper.formatCurrency(
        displayOrgCurrency ? item.amount * item.exchangeRate : item.amount,
        displayOrgCurrency ? currency : item.currency
      ),
    }))
    .sort(
      (a: IInstallmentPayment, b: IInstallmentPayment) =>
        a.paidAtLocal.getTime() - b.paidAtLocal.getTime()
    );

  return (
    <div className="ticket-action-container">
      <ViewTable
        title={<Translate value="ticketing.edit.installments.header" />}
      >
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.installments.frequency.label"
          data={
            <>
              <CapitalizeText>{installments?.frequency}</CapitalizeText>
            </>
          }
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.installments.total-paid.label"
          data={
            <>
              {CurrencyHelper.formatCurrency(paidAmount, currency)} (out of{' '}
              {CurrencyHelper.formatCurrency(amount, currency)})
            </>
          }
        />
        <ViewSectionRow
          isShowEmptyFields={showEmptyFields}
          title="ticketing.edit.installments.payments-made.label"
          data={
            <>
              {installments?.paidCount || 0} out of {installments?.count || 0}
              {records.map((item: IInstallmentPayment, index: number) => {
                if (item.type === paymentManualType)
                  return (
                    <InstallmentPaymentView
                      key={index}
                      item={{
                        ...item,
                        label: I18n.t(
                          'ticketing.edit.installments.payments-made.manualPaymentReceived'
                        )
                      }}
                    />
                  );
                return <InstallmentPaymentView key={index} item={item} />;
              })}
              <InstallmentPaymentCancelledView item={installments} />
            </>
          }
        />
      </ViewTable>
    </div>
  );
};

const InstallmentPaymentView: React.FunctionComponent<{
  item: IInstallmentPayment;
}> = ({ item }) => {
  return (
    <div>
      {moment(item.paidAtLocal).format(config.DAY_MONTH_YEAR_FORMAT)} -{' '}
      {item.label}{' '}
      {item.refunded && (
        <>
          - <b>refunded</b>
        </>
      )}
    </div>
  );
};

const InstallmentPaymentCancelledView: React.FunctionComponent<{
  item: any;
}> = ({ item }) => {
  return !item?.paymentCollectionStoppedAtAtLocal ? null : (
    <div>
      {moment(item.paymentCollectionStoppedAtAtLocal).format(
        config.DAY_MONTH_YEAR_FORMAT
      )}{' '}
      -{' '}
      {I18n.t(
        'ticketing.edit.installments.payments-made.installmentsCancelled'
      )}
    </div>
  );
};
