import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form } from 'semantic-ui-react';
import AboutElement from '../common/aboutElements/aboutElement';
import * as templateHelper from './templateHelper';
import * as validationHelper from '../../helpers/validationHelper';
import OptionalLabel from '../common/optionalLabel';
import get from 'lodash.get';

class AboutContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      applyValidation: props.initialValidation ? props.initialValidation : false
    };
  }

  onBlur = () => {
    this.setState({ applyValidation: true });
  };

  onChangeCampaingAbout = (value, type, guid) => {
    const index = this.props.value.elements.findIndex(
      (obj) => obj.guid === guid
    );
    let newValue = JSON.parse(JSON.stringify(this.props.value));
    newValue.elements[index] = {
      guid: guid,
      value: value,
      type: type
    };
    this.props.onChange(this.props.path, newValue);
  };

  render() {
    const { id, subtext, mediaLabel, value, disableCropping, validation } =
      this.props;

    const label =
      this.props.required === false ? (
        <OptionalLabel>{this.props.label}</OptionalLabel>
      ) : (
        this.props.label
      );

    let validationErrorMessage = '';

    if (this.state.applyValidation && validation) {
      validationErrorMessage = validationHelper.getValidationErrorMessage(
        validation,
        value
      );
    }
    const about = this.props.value;

    return (
      <div className="field">
        {about &&
          about.elements.map((element) => {
            return (
              <Form.Field key={element.guid}>
                <AboutElement
                  id={id}
                  guid={element.guid}
                  type={element.type}
                  value={element.value}
                  label={label}
                  subtext={subtext}
                  mediaLabel={mediaLabel}
                  onChange={this.onChangeCampaingAbout}
                  disableCropping={disableCropping}
                  validation={validation}
                  onBlur={this.onBlur}
                  validationErrorMessage={validationErrorMessage}
                  sessionKey={this.props.sessionKey}
                  entity={this.props.entity}
                />
              </Form.Field>
            );
          })}
      </div>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label),
    mediaLabel: templateHelper.getTranslation(ownProps.mediaLabel),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    sessionKey: state.session.key,
    initialValidation: get(state, 'templateBuilderEx.isEdit')
  };
};

export const TemplateAbout = connect(mapState)(AboutContainer);
