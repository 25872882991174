import get from 'lodash.get';
import update from 'immutability-helper';

const SETTINGS_KEY = 'APP_SETTINGS';

export const SETTINGS_KEYS = {
  TOGGLE_COLUMNS: 'TOGGLE_COLUMNS',
  ACTIVE_TAB: 'ACTIVE_TAB',
  FILTER: 'FILTER',
  EXPORT: 'EXPORT'
};

const getSettingPath = (key, settingName) => {
  return `${key}.${settingName}`;
};

const getSettings = () => {
  return JSON.parse(localStorage.getItem(SETTINGS_KEY));
};

const saveSettings = (settings) => {
  localStorage.setItem(SETTINGS_KEY, JSON.stringify(settings));
  window.dispatchEvent(new Event('storage'));
};

export default class SettingsManager {
  static getSettingsByKey = (key, settingName) => {
    return get(getSettings(), getSettingPath(key, settingName));
  };

  static getAllSettings = () => {
    return getSettings();
  };

  static setSetting = (key, settingName, data) => {
    const newSettings = update.set(
      getSettings(),
      getSettingPath(key, settingName),
      data
    );
    saveSettings(newSettings);
  };

  static removeSetting = (key, settingName) => {
    const settings = getSettings();
    delete settings[getSettingPath(key, settingName)];
    saveSettings(SETTINGS_KEY, settings);
  };

  static removeAllSettings = () => {
    localStorage.removeItem(SETTINGS_KEY);
    window.dispatchEvent(new Event('storage'));
  };
}
