export const TicketTypes = {
  Participant: 'participant',
  CustomFieldsOnly: 'customFieldsOnly'
};

export const TicketTypeOptions = [
  {
    key: TicketTypes.Participant,
    text: 'Participant',
    value: TicketTypes.Participant
  },
  {
    key: TicketTypes.CustomFieldsOnly,
    text: 'Custom Fields Only',
    value: TicketTypes.CustomFieldsOnly
  }
];
