import React from 'react';
import get from 'lodash.get';
import { connect } from 'react-redux';
import { Button, Modal, Header, Form, Input, Message } from 'semantic-ui-react';
import Cropper from 'react-cropper';
import {
  uploadPhoto,
  clear,
  handleValueChange
} from '../../../modules/cropImageReducer';
import { Record } from '../../../modules/bin/utility';
import { imageAspectRatio } from '../../../constants/imageUpload';
import * as validationHelper from '../../../helpers/validationHelper';

class ImageCropperModal extends React.Component {
  onCancelClick = () => {
    this.props.clear(this.props.path);
  };

  onConfirmClick = () => {
    this.refs.cropper.getCroppedCanvas().toBlob((blob) => {
      blob.filename = this.props.fileName;
      this.props.uploadPhoto(
        this.props.path,
        this.props.entity,
        this.props.uploadType,
        blob,
        this.props.sessionKey
      );
    }, this.props.imageType);
  };

  onMaximizeCropBoxClick = () => {
    const maximazeCropData = {
      width: this.props.imageWidth,
      height: this.props.imageHeight
    };

    this.refs.cropper.setCropBoxData(maximazeCropData);
  };

  onRotateClick = () => {
    this.refs.cropper.rotate(90);
  };

  render() {
    const currentAspectRatio = imageAspectRatio[this.props.uploadType];

    const urlValidationModel = {
      url: true
    };
    const isImageUrlValid = validationHelper.isValid(
      urlValidationModel,
      this.props.imageUrl
    );
    const imageUrlValidationMessage =
      validationHelper.getValidationErrorMessage(
        urlValidationModel,
        this.props.imageUrl
      );

    return (
      <Modal
        open={this.props.imageDropped}
        size="tiny"
        style={{ width: 'auto' }}
      >
        <Modal.Content>
          <Header content="Crop image" textAlign="center" />
          <div
            className="avatar-dropzone-cont cropping-state"
            style={{ textAlign: '-webkit-center' }}
          >
            <div
              className="image-dropzone avatar-dropzone"
              style={{ display: 'table-cell' }}
            >
              <div className="logo-overlapping image-cont border-padded">
                <Cropper
                  className="logo-box-inner"
                  checkCrossOrigin={false}
                  ref="cropper"
                  src={this.props.imagePreview}
                  aspectRatio={currentAspectRatio}
                  guides={false}
                  zoomable={false}
                  style={{
                    height: `${this.props.imageHeight}px`,
                    width: `${this.props.imageWidth}px`
                  }}
                />
              </div>
            </div>
          </div>
          {this.props.showImageUrl && (
            <Form.Field style={{ marginTop: '10px' }}>
              <label>URL</label>
              <Input
                fluid
                value={this.props.imageUrl}
                onChange={(event, { value }) => {
                  this.props.handleValueChange(
                    `${this.props.path}.imageUrl`,
                    value
                  );
                }}
              />
              <Message negative hidden={isImageUrlValid}>
                <p>{imageUrlValidationMessage}</p>
              </Message>
            </Form.Field>
          )}
          <Header as="h2" icon textAlign="center">
            <Header.Content>
              <Button
                style={{ margin: '0px 5px 0px 5px', color: '#fff' }}
                primary
                className="action"
                onClick={this.onConfirmClick}
                loading={Record.isRecordLoading(this.props.saveRecord)}
                disabled={
                  !isImageUrlValid ||
                  Record.isRecordLoading(this.props.saveRecord)
                }
              >
                Confirm
              </Button>
              <Button
                style={{ margin: '0px 5px 0px 5px' }}
                content="Cancel"
                onClick={this.onCancelClick}
                disabled={Record.isRecordLoading(this.props.saveRecord)}
              />
              <Button
                style={{ margin: '0px 5px 0px 5px' }}
                className="action"
                content="Expand selection"
                onClick={this.onMaximizeCropBoxClick}
                disabled={Record.isRecordLoading(this.props.saveRecord)}
              ></Button>
              <Button
                style={{ margin: '0px 5px 0px 5px' }}
                className="action"
                content="Rotate"
                onClick={this.onRotateClick}
                disabled={Record.isRecordLoading(this.props.saveRecord)}
              ></Button>
            </Header.Content>
          </Header>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = (state, ownProps) => {
  const path = ownProps.path;

  return {
    imageDropped: get(state, `cropImage.${path}.imageDropped`),
    imagePreview: get(state, `cropImage.${path}.imagePreview`),
    fileName: get(state, `cropImage.${path}.fileName`),
    imageType: get(state, `cropImage.${path}.imageType`),
    imageWidth: get(state, `cropImage.${path}.width`),
    imageHeight: get(state, `cropImage.${path}.height`),
    saveRecord: get(state, `cropImage.${path}.saveRecord`),
    imageUrl: get(state, `cropImage.${path}.imageUrl`),
    sessionKey: state.session.key
  };
};

const mapDispatch = (dispatch) => {
  return {
    clear(path) {
      dispatch(clear(path));
    },
    uploadPhoto(key, entity, type, file, token) {
      dispatch(uploadPhoto(key, entity, type, file, token));
    },
    handleValueChange(path, value) {
      dispatch(handleValueChange(path, value));
    }
  };
};

const ImageCropperModalContainer = connect(
  mapState,
  mapDispatch
)(ImageCropperModal);
export default ImageCropperModalContainer;
