import { get } from "lodash";
import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { listFilterAggregate, listFilterOperator } from "../../../constants/aggregate";
import { templateKeys } from "../../../constants/templateKeys";
import { getDataCapture } from "../../../helpers/dataCaptureHelper";
import { getForbuilderAnswerByReference, getCustomFields, getAddressModelFromFormbuilderAnswers, getPhoneNumberFromFormbuilderAnswers } from "../../../helpers/formBuilderHelper";
import { eventAmbassadorStatus } from "../constants";
import { TOGGLE_CHANGE_AVAILABILITY_MODAL, LIST_ORDERING_CHANGE, HANDLE_VALUE_CHANGE, RECORD_CLEAR, ERROR_CLEAR, GET_EVENT_ORGANIZER_REQUESTED, GET_EVENT_ORGANIZER_SUCCESS, GET_EVENT_ORGANIZER_FAILURE, GET_EVENT_AMBASSADOR_REQUESTED, GET_EVENT_AMBASSADOR_SUCCESS, GET_EVENT_AMBASSADOR_FAILURE, GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED, GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS, GET_EVENT_AMBASSADOR_PROGRAM_FAILURE, GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED, GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS, GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE, TOGGLE_COLUMNS_CHANGE, ACTIVE_TAB_SET, METADATA_REQUESTED, METADATA_SUCCESS, METADATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, LIST_DATA_FAILURE, GET_TOTAL_COUNT_PROGRAMS_REQUESTED, GET_TOTAL_COUNT_PROGRAMS_SUCCESS, GET_TOTAL_COUNT_PROGRAMS_FAILURE, GET_TOTAL_COUNT_APPLICATIONS_REQUESTED, GET_TOTAL_COUNT_APPLICATIONS_SUCCESS, GET_TOTAL_COUNT_APPLICATIONS_FAILURE, GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED, GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS, GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS, GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, CREATE_FAILURE, UPDATE_REQUESTED, UPDATE_SUCCESS, UPDATE_FAILURE, GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED, GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS, GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE, UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED, UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS, UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_EXPORT_FILE_FAILURE } from "./vipProgramTypes";
import { paths } from "../application/constants/template";
import SettingsManager, { SETTINGS_KEYS } from "../../../modules/bin/settingsManager";
import constants from '../../../constants';

export { updateProgramAvailability } from "./updateProgramAvailability";

export const toggleChangeAvailabilityModal = (
  type: "OpenApplications" | "CloseApplications",
  itemId?: any,
) => {
  return {
    type: TOGGLE_CHANGE_AVAILABILITY_MODAL,
    payload: {
      key: type,
      value: itemId,
    }
  };
};

export const listOrderingChange = (listKey: any, ordering: any) => {
  return {
    type: LIST_ORDERING_CHANGE,
    payload: {
      value: ordering,
      listKey: listKey
    }
  };
};

export const handleValueChange = (path: any, value: any) => {
  return {
    type: HANDLE_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const clearRecord = (record: any) => ({
  type: RECORD_CLEAR,
  payload: { key: record }
});

export const clearRecordMessages = (recordKey: any) => ({
  type: ERROR_CLEAR,
  payload: { key: recordKey }
});

export const getEventOrganizerRecord = (id: any) =>
  entityHandlers.getRecord(
    'EventOrganizer',
    GET_EVENT_ORGANIZER_REQUESTED,
    GET_EVENT_ORGANIZER_SUCCESS,
    GET_EVENT_ORGANIZER_FAILURE,
    id,
    'eventOrganizerRecord'
  );
export const getEventAmbassadorRecord = (id: any) =>
  entityHandlers.getRecord(
    'EventAmbassador',
    GET_EVENT_AMBASSADOR_REQUESTED,
    GET_EVENT_AMBASSADOR_SUCCESS,
    GET_EVENT_AMBASSADOR_FAILURE,
    id,
    'eventAmbassadorRecord'
  );
export const getEventAmbassadorProgramByEventRecord = (id: any) =>
  entityHandlers.getRecord(
    'EventAmbassadorProgram/event',
    GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED,
    GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS,
    GET_EVENT_AMBASSADOR_PROGRAM_FAILURE,
    id,
    'eventAmbassadorProgramRecord'
  );
export const getCampaignAmbassadorProgramByCampaignRecord = (id: any) =>
  entityHandlers.getRecord(
    'CampaignAmbassadorProgram/campaign',
    GET_CAMPAIGN_AMBASSADOR_PROGRAM_REQUESTED,
    GET_CAMPAIGN_AMBASSADOR_PROGRAM_SUCCESS,
    GET_CAMPAIGN_AMBASSADOR_PROGRAM_FAILURE,
    id,
    'campaignAmbassadorProgramRecord'
  );

export const getEventAmbassadorProgramRecord = (id: any) =>
  entityHandlers.getRecord(
    'EventAmbassadorProgram/event',
    GET_EVENT_AMBASSADOR_PROGRAM_REQUESTED,
    GET_EVENT_AMBASSADOR_PROGRAM_SUCCESS,
    GET_EVENT_AMBASSADOR_PROGRAM_FAILURE,
    id,
    'eventAmbassadorProgramRequestRecord'
  );

export const toggleColumnsChange = (listKey: any, toggleColumns: any) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );

export const setView = (view: any) => {
  return { type: ACTIVE_TAB_SET, payload: view };
};

export const getProgramsMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'EventAmbassadorProgram',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getProgramsListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined
) =>
  entityHandlers.getListData(
    'EventAmbassadorProgram',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getProgramsListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: object,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getProgramsListData(listKey, id, newRequest, true);
};

export const getCampaignProgramsMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'CampaignAmbassadorProgram',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getCampaignProgramsListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined
) =>
  entityHandlers.getListData(
    'CampaignAmbassadorProgram',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getCampaignProgramsListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: object,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getCampaignProgramsListData(listKey, id, newRequest, true);
};

export const getApplicationsMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'EventAmbassador',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getApplicationsListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined
) =>
  entityHandlers.getListData(
    'EventAmbassador',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getApplicationsListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: object,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getApplicationsListData(listKey, id, newRequest, true);
};

export const getPendingApprovalProgramsMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'EventAmbassador',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getPendingApprovalProgramsListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined
) =>
  entityHandlers.getListData(
    'EventAmbassador',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getPendingApprovalProgramsListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: object,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getPendingApprovalProgramsListData(listKey, id, newRequest, true);
};

export const getVIPsMetadata = (listKey: any) =>
  entityHandlers.getMetadata(
    'EventAmbassador',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getVIPsListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: undefined,
  version = 'v3'
) =>
  entityHandlers.getListData(
    'EventAmbassador',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    version
  );
export const getVIPsListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: object,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getVIPsListData(listKey, id, newRequest, true);
};

const addArchiveFilter = (aggregateUrl: any) => {
  const displayArchived = SettingsManager.getSettingsByKey(
    SETTINGS_KEYS.FILTER,
    'displayArchived'
  );

  const key = aggregateUrl.includes('EventAmbassadorProgram') ? 'event.status' : 'campaign.status';

  if (!displayArchived) {
    return aggregateUrl.addFilter(key, listFilterOperator.EQUAL_TO, 'live', 0);
  }
  return aggregateUrl;
}

export const getTotalCountPrograms = (entity: any, path: any, value: any) => {
  let aggregateUrl = null;

  aggregateUrl = entity
    .addFieldName('')
    .addFieldAggregate(listFilterAggregate.COUNT);

  if (path && value) {
    aggregateUrl = entity
      .addFieldName('')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter(path, listFilterOperator.EQUAL_TO, value, 0);
  }

  aggregateUrl = addArchiveFilter(aggregateUrl);

  return entityHandlers.getAggregate(
    GET_TOTAL_COUNT_PROGRAMS_REQUESTED,
    GET_TOTAL_COUNT_PROGRAMS_SUCCESS,
    GET_TOTAL_COUNT_PROGRAMS_FAILURE,
    aggregateUrl,
    'totalCountProgramsRecord'
  );
};

export const getTotalCountApplications = (entity: { addFieldName: (arg0: string) => { (): any; new(): any; addFieldAggregate: { (arg0: string): { (): any; new(): any; addFilter: { (arg0: any, arg1: listFilterOperator, arg2: any, arg3: number): any; new(): any; }; }; new(): any; }; }; }, path: any, value: any) => {
  let aggregateUrl = null;

  aggregateUrl = entity
    .addFieldName('')
    .addFieldAggregate(listFilterAggregate.COUNT);

  if (path && value) {
    aggregateUrl = entity
      .addFieldName('')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter(path, listFilterOperator.EQUAL_TO, value, 0);
  }

  aggregateUrl = addArchiveFilter(aggregateUrl);

  return entityHandlers.getAggregate(
    GET_TOTAL_COUNT_APPLICATIONS_REQUESTED,
    GET_TOTAL_COUNT_APPLICATIONS_SUCCESS,
    GET_TOTAL_COUNT_APPLICATIONS_FAILURE,
    aggregateUrl,
    'totalCountApplicationsRecord'
  );
};

export const getTotalCountPendingApprovalProgramsByReceivedStatus = (
  entity: { addFieldName: (arg0: string) => { (): any; new(): any; addFieldAggregate: { (arg0: string): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): any; new(): any; }; }; new(): any; }; }; new(): any; }; }; },
  path: any,
  value: any
) => {
  let aggregateUrl = null;

  aggregateUrl = entity
    .addFieldName('')
    .addFieldAggregate(listFilterAggregate.COUNT)
    .addFilter(
      'status',
      listFilterOperator.EQUAL_TO,
      eventAmbassadorStatus.RECEIVED,
      0
    );

  // @ts-ignore
  if ((path, value)) {
    aggregateUrl = entity
      .addFieldName('')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter(path, listFilterOperator.EQUAL_TO, value, 0)
      .addFilter(
        'status',
        listFilterOperator.EQUAL_TO,
        eventAmbassadorStatus.RECEIVED,
        1
      );
  }

  aggregateUrl = addArchiveFilter(aggregateUrl);

  return entityHandlers.getAggregate(
    GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_REQUESTED,
    GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_SUCCESS,
    GET_TOTAL_COUNT_PENDING_APPROVAL_PROGRAMS_BY_RECEIVED_STATUS_FAILURE,
    aggregateUrl,
    'totalCountPendingApprovalProgramsByReceivedStatusRecord'
  );
};

export const getTotalCountVIPProgramsByClaimedStatus = (
  entity: { addFieldName: (arg0: string) => { (): any; new(): any; addFieldAggregate: { (arg0: string): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): any; new(): any; }; }; new(): any; }; }; new(): any; }; }; },
  path: any,
  value: any
) => {
  let aggregateUrl = null;

  aggregateUrl = entity
    .addFieldName('')
    .addFieldAggregate(listFilterAggregate.COUNT)
    .addFilter(
      'status',
      listFilterOperator.EQUAL_TO,
      eventAmbassadorStatus.CLAIMED,
      0
    );

  // @ts-ignore
  if ((path, value)) {
    aggregateUrl = entity
      .addFieldName('')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter(path, listFilterOperator.EQUAL_TO, value, 0)
      .addFilter(
        'status',
        listFilterOperator.EQUAL_TO,
        eventAmbassadorStatus.CLAIMED,
        1
      );
  }

  aggregateUrl = addArchiveFilter(aggregateUrl);

  return entityHandlers.getAggregate(
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_REQUESTED,
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_SUCCESS,
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_CLAIMED_STATUS_FAILURE,
    aggregateUrl,
    'totalCountVIPProgramsByClaimedStatusRecord'
  );
};

export const getTotalCountVIPProgramsByAcceptedStatus = (
  entity: { addFieldName: (arg0: string) => { (): any; new(): any; addFieldAggregate: { (arg0: string): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): { (): any; new(): any; addFilter: { (arg0: string, arg1: listFilterOperator, arg2: string, arg3: number): any; new(): any; }; }; new(): any; }; }; new(): any; }; }; },
  path: any,
  value: any
) => {
  let aggregateUrl = null;

  aggregateUrl = entity
    .addFieldName('')
    .addFieldAggregate(listFilterAggregate.COUNT)
    .addFilter(
      'status',
      listFilterOperator.EQUAL_TO,
      eventAmbassadorStatus.ACCEPTED,
      0
    );

  // @ts-ignore
  if ((path, value)) {
    aggregateUrl = entity
      .addFieldName('')
      .addFieldAggregate(listFilterAggregate.COUNT)
      .addFilter(path, listFilterOperator.EQUAL_TO, value, 0)
      .addFilter(
        'status',
        listFilterOperator.EQUAL_TO,
        eventAmbassadorStatus.ACCEPTED,
        1
      );
  }

  aggregateUrl = addArchiveFilter(aggregateUrl);

  return entityHandlers.getAggregate(
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_REQUESTED,
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_SUCCESS,
    GET_TOTAL_COUNT_VIP_PROGRAMS_BY_ACCEPTED_STATUS_FAILURE,
    aggregateUrl,
    'totalCountVIPProgramsByAcceptedStatusRecord'
  );
};

export const createRecord = (record: any) =>
  entityHandlers.createRecord(
    'EventAmbassadorProgram',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record,
    (state: any, data: any) => {
      const dataCapture = getDataCapture(data);

      data = update.set(data, 'template.key', templateKeys.VIP_PROGRAM);
      data = update.set(data, 'dataCapture', dataCapture);

      return data;
    },
    'recordCreate'
  );

export const updateRecord = (record: { id: any; }) =>
  entityHandlers.updateRecord(
    'EventAmbassadorProgram',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record.id,
    record,
    (state: any, data: any) => {
      const dataCapture = getDataCapture(data);

      data = update.set(data, 'template.key', templateKeys.VIP_PROGRAM);
      data = update.set(data, 'dataCapture', dataCapture);

      return data;
    },
    'recordEdit'
  );

export const createProgramRecord = (record: { eventAmbassadorProgramId: any; }) =>
  entityHandlers.updateRecord(
    'EventAmbassadorProgram',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record.eventAmbassadorProgramId,
    record,
    (state: any, data: any) => {
      const organizations = [
        {
          id: data.organizationId,
          availableSeats: data.availableSeats
        }
      ];

      data = update(data, {
        event: {
          organizations: {
            $set: organizations
          }
        }
      });

      return data.event;
    },
    'createProgramRecord'
  );

export const updateProgramRecord = (record: { id: any; }) =>
  entityHandlers.updateRecord(
    'CampaignAmbassadorProgram',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record.id,
    record,
    (state: any, data: any) => {
      const dataCapture = getDataCapture(data) as any;

      data = update.set(data, 'template.key', templateKeys.PROGRAM);
      data = update.set(
        data,
        'dataCapture.fields',
        dataCapture.additional.fields
      );

      return data;
    },
    'editProgramRecord'
  );

export const updateProgramSeats = (record: { id: any, newAvailableSeats: number }) =>
  entityHandlers.executeRequest(
    `${constants.baseApiHost}/api/v2/CampaignAmbassadorProgram/${record.id}/seats`,
    'PUT',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    { availableSeats: record.newAvailableSeats },
    null,
    'editProgramSeats',
  );

export const updateApplicationRecord = (record: { id: any; }) =>
  entityHandlers.updateRecord(
    'EventAmbassador',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record.id,
    record,
    (state: any, data: any) => {
      const entryFormbuilder = get(data, paths.APPLICATION_ENTRY);
      const additionalFormBuilder = get(data, paths.APPLICATION_ADDITIONAL);
      const confirmationFormBuilder = get(data, paths.APPLICATION_CONFIRMATION);

      const targetAmount = getForbuilderAnswerByReference(
        entryFormbuilder,
        'targetAmount'
      );

      const entryFormbuilderFields = getCustomFields(entryFormbuilder) || [];
      const additionalFormBuilderFields =
        getCustomFields(additionalFormBuilder) || [];
      const fields = [
        ...entryFormbuilderFields,
        ...additionalFormBuilderFields
      ];

      const address = getAddressModelFromFormbuilderAnswers(
        confirmationFormBuilder
      );
      const phoneNumber = getPhoneNumberFromFormbuilderAnswers(
        entryFormbuilder
      );

      data = update.set(data, 'fields', fields);
      data = update.set(data, 'address', address);
      data = update.set(data, 'phoneNumber', phoneNumber);

      data = update.set(data, 'targetAmount', targetAmount);

      if (
        data.status === eventAmbassadorStatus.CLAIMED ||
          data.status === eventAmbassadorStatus.RESCINDED
      ) {
        data = update(data, {
          fields: { $push: getCustomFields(confirmationFormBuilder) }
        });
      }

      return data;
    },
    'editApplicationRecord'
  );

export const getSecureSignature = (id: any) =>
  entityHandlers.getRecord(
    `upload/secure`,
    GET_EVENT_AMBASSADOR_SIGNATURE_REQUESTED,
    GET_EVENT_AMBASSADOR_SIGNATURE_SUCCESS,
    GET_EVENT_AMBASSADOR_SIGNATURE_FAILURE,
    id,
    'secureSignatureRecord'
  );

export const updateEventAmbassadorStatus = (
  id: any,
  status: any,
  disableAutomaticEmail = false
) =>
  entityHandlers.updateRecord(
    'EventAmbassador',
    UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_REQUESTED,
    UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_SUCCESS,
    UPDATE_EVENT_AMBASSADOR_PROGRAM_STATUS_FAILURE,
    `${id}/status?newStatus=${status}&disableAutomaticEmail=${disableAutomaticEmail}`,
    {},
    (state: any, data: any) => {
      return data;
    },
    'editEventAmbassadorStatusRecord'
  );

// export const getExportFile = (entity: any, request: any, fileName: any, version: any) => {
//     return entityHandlers.getExportFile(
//         entity,
//         GET_EXPORT_FILE_REQUESTED,
//         GET_EXPORT_FILE_SUCCESS,
//         GET_EXPORT_FILE_FAILURE,
//         request,
//         'exportFileRecord',
//         fileName,
//         version
//     );
// };
