import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Modal, Form, Checkbox, Button, Flag } from 'semantic-ui-react';
import classnames from 'classnames';

class LanguageModal extends Component {
  onClose() {
    if (this.props.onClose) {
      this.props.onClose();
    }
  }

  onChange(value) {
    if (this.props.onChange) {
      this.props.onChange(value);
    }
  }

  render() {
    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        onClose={this.onClose.bind(this)}
        open={this.props.open}
      >
        <Modal.Header>
          <Translate value="general.language.modal.title" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            {this.props.languages &&
              this.props.languages.map((item) => (
                <LanguageRadioField
                  key={item.key}
                  value={item.key}
                  icon={item.icon}
                  label={I18n.t(`general.language.${item.key}`)}
                  checked={this.props.value === item.key}
                  onChange={this.onChange.bind(this)}
                />
              ))}
            <div className="button-wrapper align-right">
              <Button primary onClick={this.onClose.bind(this)}>
                <Translate value="general.close" />
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

class LanguageRadioField extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hovering: false };
  }

  handleHover() {
    this.setState({ hovering: !this.state.hovering });
  }

  onCheckboxChange() {
    if (this.props.onChange) {
      this.props.onChange(this.props.value);
    }
  }

  render() {
    const fieldClassNames = classnames({
      checked: this.props.checked,
      hovered: this.state.hovering
    });

    const label = (
      <span>
        <Flag name={this.props.icon} />
        <span>{this.props.label}</span>
      </span>
    );

    return (
      <Form.Field
        onMouseEnter={this.handleHover.bind(this)}
        onMouseLeave={this.handleHover.bind(this)}
        className={fieldClassNames}
      >
        <Checkbox
          name={`languageRadio`}
          value={this.props.value}
          checked={this.props.checked}
          onChange={this.onCheckboxChange.bind(this)}
          radio
        />
        <div style={{ paddingLeft: '14px', display: 'inline-block' }}>
          {label}
        </div>
      </Form.Field>
    );
  }
}

export default LanguageModal;
