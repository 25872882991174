import { AnyAction } from '@reduxjs/toolkit';

import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { GridOptions } from '../../../components/common/grid/gridOptions';
import ColumnModel from '../../../components/common/grid/columnModel';
import { terminalColumnData } from '../constants';
import {
  TERMINAL_METADATA_REQUESTED,
  TERMINAL_METADATA_SUCCESS,
  TERMINAL_LIST_DATA_REQUESTED,
  TERMINAL_LIST_DATA_SUCCESS,
  TERMINAL_LIST_DATA_FAILURE,
  TERMINAL_MESSAGE_CLEAR,
  TERMINAL_METADATA_FAILURE,
  TERMINAL_CREATE_REQUESTED,
  TERMINAL_CREATE_SUCCESS,
  TERMINAL_CREATE_FAILURE,
  TERMINAL_UPDATE_REQUESTED,
  TERMINAL_UPDATE_SUCCESS,
  TERMINAL_UPDATE_FAILURE,
  TERMINAL_DELETE_FAILURE,
  TERMINAL_DELETE_SUCCESS,
  TERMINAL_DELETE_REQUESTED,
  TERMINAL_GET_FAILURE,
  TERMINAL_GET_REQUESTED,
  TERMINAL_GET_SUCCESS,
  TERMINAL_SEARCH_OPTIONS_REQUESTED,
  TERMINAL_SEARCH_OPTIONS_SUCCESS,
  TERMINAL_SET_LIST_DATA
} from './terminalTypes';
import { Record } from '../../../modules/bin/utility';
import { CLEAR } from '../../../modules/general';
import { tapNdonateAvailableFilters } from '../constants/tapNdonateAvailableFilters';
import { listFilterOperator } from '../../../constants/aggregate';
import update from '../../../helpers/update';

export const assignedFilter = {
  key: 'organizationId',
  operator: listFilterOperator.GREATER_THAN,
  value: -1
};

export const unassignedFilter = {
  key: 'organizationId',
  operator: listFilterOperator.IS_NULL,
  value: 0
};

const initialState = {
  timestamp: new Date().getTime(),
  // prettier-ignore
  terminalList: new GridOptions()
    .addColumns(terminalColumnData.map((x) => new ColumnModel(x.path, x.header, x.customFormatter).setVisible(x.defaultVisible)))
    .addFilterButton('assigned', 'terminal.button-group.assigned', assignedFilter)
    .addFilterButton('unassigned', 'terminal.button-group.unassigned', unassignedFilter)
    .addAvailableFiltersKeys(tapNdonateAvailableFilters)
    .setMultiSelect(true)
    .getState(),
  record: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  orgOptions: Record.getDefaultState(),
  campaignOptions: Record.getDefaultState(),
  fundraiserOptions: Record.getDefaultState(),
  teamOptions: Record.getDefaultState(),
  collectionOptions: Record.getDefaultState()
};

// State Reducer
const terminalReducer = (state = initialState, action: AnyAction) => {
  let newState = state;
  switch (action.type) {
    case CLEAR:
    case TERMINAL_DELETE_SUCCESS:
      newState = initialState;
      break;

    case TERMINAL_METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case TERMINAL_METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case TERMINAL_LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case TERMINAL_GET_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case TERMINAL_LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      newState = update.set(
        newState,
        'terminalList.isMultiSelectChecked',
        false
      );
      break;

    case TERMINAL_LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case TERMINAL_MESSAGE_CLEAR:
      // prettier-ignore
      newState = entityHandlers.crudClearErrorHandler(state, action.payload.key);
      break;

    case TERMINAL_METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case TERMINAL_CREATE_REQUESTED:
    case TERMINAL_UPDATE_REQUESTED:
    case TERMINAL_DELETE_REQUESTED:
    case TERMINAL_SEARCH_OPTIONS_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case TERMINAL_CREATE_SUCCESS:
    case TERMINAL_UPDATE_SUCCESS:
    case TERMINAL_GET_SUCCESS:
    case TERMINAL_SEARCH_OPTIONS_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case TERMINAL_CREATE_FAILURE:
    case TERMINAL_DELETE_FAILURE:
    case TERMINAL_UPDATE_FAILURE:
    case TERMINAL_GET_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case TERMINAL_SET_LIST_DATA:
      newState = update.set(newState, 'terminalList', action.payload);
      break;
  }

  return newState;
};

export default terminalReducer;
