import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import * as generalActions from '../../../modules/general';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as recurringDonationActions from '../reducer';
import { organizationActions } from '../../organization/store/organizationActions';

import { Record } from '../../../modules/bin/utility';
import clear from '../../../components/clear';
import get from 'lodash.get';

import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import RecurringDonationTemplate from '../template';
import Spinner from '../../../components/common/spinner';

import { TemplateOptions } from '../../../components/template/templateOptions';
import config from '../../../constants';
import { recurringContract, donationRecurringFrequency } from '../constants';
import { cardActions } from '../../../modules/card';

class CreateRecurringDonation extends Component {
  constructor(props) {
    super(props);

    if (props.isOrganizationAdmin) {
      props.getOrganizationRecord(get(props, 'organization.id'));
    }
  }

  onSave = () => {
    this.props.setPaymentRequest(recurringContract.payment);
  };

  goToRecurringDonationsList = (id) => {
    this.props.history.push(config.DEFAULT_RECURRING_URL);
  };

  initTemplateBuilderModel = () => {
    const model = new TemplateOptions();

    if (this.props.isOrganizationAdmin) {
      model.hide(recurringContract.organization);
      const organization = this.props.organizationRecord.data.data;

      const organizationId = organization.id;
      model.setValue(recurringContract.organization, organizationId);

      if (
        organization &&
        organization.paymentPlatforms &&
        organization.paymentPlatforms.length !== 0
      ) {
        model.setValue(
          'accountKey',
          organization.paymentPlatforms[0].accountKey
        );
      }
      model.setValue('primaryPath', organization.urlPath);
    }

    model.setValue(
      recurringContract.frequency,
      donationRecurringFrequency.monthly
    );
    model.disable(recurringContract.frequency);
    model.setValue(recurringContract.donationCountLimit, '');
    model.disable(recurringContract.donationCountLimit);
    model.setValue(recurringContract.coverFee, true);

    this.props.templateBuilderActions.setTemplateModel(model.get());
  };

  clearMessages = () => {
    this.props.recurringDonationActions.clearRecordMessages('recordCreate');
  };

  render() {
    const isLoading =
      Record.isRecordLoading(this.props.recordCreate) ||
      this.props.paymentInProgress;
    const isSuccess = Record.isSuccess(this.props.recordCreate);

    if (
      this.props.isOrganizationAdmin &&
      !Record.isSuccess(this.props.organizationRecord)
    ) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToRecurringDonationsList();
    }

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
        />

        <h1>
          <Translate value="recurring-donation.create.title" />
        </h1>

        <RecurringDonationTemplate onSave={this.onSave} isLoading={isLoading} />
      </BasePage>
    );
  }
}

const mapState = ({
  recurring,
  templateBuilderEx,
  session,
  organization,
  card
}) => {
  return {
    recordCreate: recurring.recordCreate,
    paymentInProgress: get(
      card,
      `${recurringContract.payment}.paymentInProgress`
    ),
    isTemplateInited: templateBuilderEx.inited,
    organization: session.organization,
    isOrganizationAdmin: session.isOrganizationAdmin,
    organizationRecord: organization.record
  };
};

const mapDispatch = (dispatch) => {
  return {
    generalActions: bindActionCreators(generalActions, dispatch),
    recurringDonationActions: bindActionCreators(
      recurringDonationActions,
      dispatch
    ),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    getOrganizationRecord: bindActionCreators(
      organizationActions.getOrganizationRecord,
      dispatch
    ),
    setPaymentRequest: (path) => {
      dispatch(cardActions.setPaymentRequest(path));
    }
  };
};

const CreateRecurringDonationContainer = withRouter(
  clear(connect(mapState, mapDispatch)(CreateRecurringDonation))
);
export default CreateRecurringDonationContainer;
