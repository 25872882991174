/* eslint-disable no-useless-escape */
import { I18n } from 'react-redux-i18n';
import moment from 'moment';
import { isString } from 'lodash';
import get from 'lodash.get';
import { isValidNumber } from 'libphonenumber-js';

import {
  hasFormbuilderQuestions,
  getFormbuilderQuestions
} from './formBuilderHelper';
import {
  hasArrayDuplicates,
  stringToArray,
  isArrayWithItems
} from './arrayHelper';

import {
  addresFields,
  addressFieldsValidation
} from '../constants/formBuilder';
import { nodeTypes } from '../components/formbuilder/typeDefinitions';
import config from '../constants/index';
import { activityTypeMeasures, DONATION_VIDEO, SkuConstants } from '../pages/campaign/constants';
import { SaleCouponDiscountType } from '../components/collectionBuilder/constants';
import { AboutElementTypes } from '../components/common/aboutElementsBuilder/constants/aboutElementType';
import { daysBetween } from './dateAndTimeHelper';
import {
  campaignGiftMatchingConditionOperator,
  campaignGiftMatchingConditionType
} from '../constants/giftMatching';

import { MerchandiseConstants } from '../pages/campaign/constants';

export const isValid = (validationModel, value, optionalParam) => {
  if (!validationModel) {
    return true;
  }

  if (
    validationModel.startDateRange &&
    !isStartDateRangeValid(value, optionalParam)
  ) {
    return false;
  }

  if (
    validationModel.endDateRange &&
    !isEndDateRangeValid(value, optionalParam)
  ) {
    return false;
  }

  if (validationModel.required && !isRequiredValid(value)) {
    return false;
  }

  if (validationModel.email && !isEmailValid(value)) {
    return false;
  }

  if (validationModel.defaultAmounts && !isDefaultAmountsValid(value)) {
    return false;
  }

  if (
    validationModel.defaultSetupButtons &&
    !isDefaultSetupButtonsValid(value)
  ) {
    return false;
  }

  if (
    validationModel.formBuilderRequired &&
    !isFormBuilderRequiredValid(value)
  ) {
    return false;
  }

  if (
    validationModel.formBuilderAnswers &&
    !isFormBuilderAnswersValid(value, validationModel)
  ) {
    return false;
  }

  if (
    validationModel.formBuilderQuestions &&
    !isFormBuilderQuestionsValid(value)
  ) {
    return false;
  }

  if (
    validationModel.formbuilderQuestionsRequired &&
    !isFormbuilderQuestionsRequiredValid(value)
  ) {
    return false;
  }

  if (
    validationModel.formbuilderQuestionsMaxLength &&
    value &&
    !isMaxLengthValid(value.children[0].children, validationModel.length)
  ) {
    return false;
  }

  if (
    validationModel.formbuilderAddressRequired &&
    !isFormbuilderAddressRequiredValid(value)
  ) {
    return false;
  }

  if (validationModel.formbuilderAddress && !isFormbuilderAddressValid(value)) {
    return false;
  }

  if (validationModel.optionalRecord && !isRecordValid(value)) {
    return false;
  }

  if (validationModel.formbuilderOptions && !isFormbuilderOptionsValid(value)) {
    return false;
  }

  if (validationModel.validateAboutElements && validationModel.required) {
    const elementValid = (element) => {
      return element && element.value && element.value.trim();
    };
    return value.elements && value.elements.some(elementValid);
  }

  if (validationModel.url && !isUrlValid(value)) {
    return false;
  }

  if (
    validationModel.webTracking &&
    !isWebTrackingValid(value, validationModel)
  ) {
    return false;
  }

  if (validationModel.domainUrl && !isDomainUrlValid(value)) {
    return false;
  }

  if (validationModel.phone && !isPhoneValid(value)) {
    return false;
  }

  if (validationModel.date && !isDateStringValid(value)) {
    return false;
  }

  if (
    validationModel.amount &&
    !isAmountValid(value, validationModel.maxValue, validationModel.required)
  ) {
    return false;
  }

  if (validationModel.ticketBuilder && !isTicketBuilderValid(value)) {
    return false;
  }


  if (validationModel.merchandising && !isMerchandiseBuilderValid(value)) {
    return false;
  }

  if (validationModel.termsAndConditionSetupTab && !isTermsAndConditionsValid(value)) {
    return false;
  }

  if (
    validationModel.maxLength &&
    !isMaxLengthValid(value, validationModel.length)
  ) {
    return false;
  }

  if (validationModel.couponBuilder && !isCouponBuilderValid(value)) {
    return false;
  }

  if (validationModel.skuBuilder && !isSkuBuilderValid(value)) {
    return false;
  }

  if (validationModel.waiverBuilder && !isWaiverBuilderValid(value)) {
    return false;
  }

  if (validationModel.tagsBuilder && !isTagsBuilderValid(value)) {
    return false;
  }

  if (validationModel.card && !isCardValid(value)) {
    return false;
  }

  if (
    validationModel.disableSameCampaign &&
    !isCampaignValid(value, validationModel.campaignId)
  ) {
    return false;
  }

  if (validationModel.fitnessActivities && !isFitnessActivitiesValid(value)) {
    return false;
  }

  if (
    validationModel.fitnessTrackedStatistics &&
    !isFitnessTrackedStatisticsValid(value)
  ) {
    return false;
  }

  if (
    validationModel.fitnessTargets &&
    !isFitnessTargetsValid(value, optionalParam)
  ) {
    return false;
  }

  if (validationModel.aboutBuilder && !isAboutBuilderValid(value)) {
    return false;
  }

  if (validationModel.dashboardBuilder && !isDashboardBuilderValid(value)) {
    return false;
  }

  if (
    validationModel.giftMatchingBuilder &&
    !isGiftMatchingBuilderValid(value)
  ) {
    return false;
  }

  if (validationModel.password && !isPasswordValid(value)) {
    return false;
  }

  if (validationModel.customMediaSelector &&
    value === DONATION_VIDEO &&
    optionalParam &&
    !isUrlValid(optionalParam)) {
    return false;
  }

  return true;
};

export const getValidationErrorMessage = (
  validationModel,
  value,
  optionalParam
) => {
  if (!validationModel) {
    return '';
  }

  if (validationModel.startDateRange) {
    if (!isDateStringValid(value)) {
      return I18n.t('errors.validation.date');
    }

    if (!isStartDateRangeValid(value, optionalParam)) {
      return I18n.t('errors.validation.startDateRange');
    }
  }

  if (validationModel.endDateRange) {
    if (!optionalParam && value) {
      return I18n.t('errors.validation.startDateRangeDoesntExist');
    }

    if (!isDateStringValid(value)) {
      return I18n.t('errors.validation.date');
    }

    if (!isEndDateRangeValid(value, optionalParam)) {
      return I18n.t('errors.validation.endDateRange');
    }
  }

  if (validationModel.required && !isRequiredValid(value)) {
    return I18n.t('errors.validation.required');
  }

  if (validationModel.email && !isEmailValid(value)) {
    return I18n.t('errors.validation.email');
  }

  if (validationModel.emailWarning && !isEmailValid(value)) {
    return I18n.t('errors.validation.email');
  }

  if (validationModel.defaultAmounts && !isDefaultAmountsValid(value)) {
    return getDefaultAmountsErrorMessage(value);
  }

  if (
    validationModel.defaultSetupButtons &&
    !isDefaultSetupButtonsValid(value)
  ) {
    return getDefaultButtonSetupErrorMessage(value);
  }

  if (validationModel.url && !isUrlValid(value)) {
    return I18n.t('errors.validation.url');
  }

  if (
    validationModel.pattern &&
    !isPatternValid(value, validationModel.pattern)
  ) {
    return I18n.t(validationModel.errorMessegeKey);
  }

  if (
    validationModel.disableSameCampaign &&
    !isCampaignValid(value, validationModel.campaignId)
  ) {
    return I18n.t('errors.validation.donation-transfer-invalid-campaign');
  }

  if (validationModel.phone && !isPhoneValid(value)) {
    return I18n.t('errors.validation.phone');
  }

  if (validationModel.date && !isDateStringValid(value)) {
    return I18n.t('errors.validation.date');
  }

  if (
    validationModel.amount &&
    !isAmountValid(value, validationModel.maxValue, validationModel.required)
  ) {
    return I18n.t('errors.validation.amount', {
      maxValue: validationModel.maxValue
    });
  }

  if (validationModel.ticketBuilder && !isTicketBuilderValid(value)) {
    return getTicketBuilderErrorMessage(value);
  }

  if (validationModel.merchandising && !isMerchandiseBuilderValid(value)) {
    return getMerchandiseBuilderErrorMessage(value);
  }

  if (validationModel.termsAndConditionSetupTab && !isTermsAndConditionsValid(value)) {
    return getTermsAndConditionSetupBuilderError(value);
  }

  if (validationModel.waiverBuilder && !isWaiverBuilderValid(value)) {
    return getWaiverBuilderErrorMessage(value);
  }

  if (validationModel.couponBuilder && !isCouponBuilderValid(value)) {
    return getCouponBuilderErrorMessage(value);
  }

  if (validationModel.skuBuilder && !isSkuBuilderValid(value)) {
    return getSkuBuilderErrorMessage(value);
  }

  if (validationModel.tagsBuilder && !isTagsBuilderValid(value)) {
    return getTagsBuilderErrorMessage(value);
  }

  if (validationModel.fitnessActivities && !isFitnessActivitiesValid(value)) {
    return getFitnessActivitiesErrorMessage(value);
  }

  if (
    validationModel.fitnessTrackedStatistics &&
    !isFitnessTrackedStatisticsValid(value)
  ) {
    return getFitnessTrackedStatisticsErrorMessage(value);
  }

  if (validationModel.fitnessTargets && !isFitnessTargetsValid(value)) {
    return getFitnessTargetsErrorMessage(value);
  }

  if (validationModel.domainUrl && !isDomainUrlValid(value)) {
    return I18n.t('errors.validation.domainUrl');
  }

  if (
    validationModel.formbuilderQuestionsMaxLength &&
    value &&
    !isMaxLengthValid(value.children[0].children, validationModel.length)
  ) {
    return I18n.t('errors.validation.form-builder-questions-max-length', {
      maxValue: validationModel.length
    });
  }

  if (validationModel.dashboardBuilder && !isDashboardBuilderValid(value)) {
    return getDashboardBuilderErrorMessage(value);
  }

  if (
    validationModel.giftMatchingBuilder &&
    !isGiftMatchingBuilderValid(value)
  ) {
    return I18n.t('errors.validation.giftMatchingBuilder');
    // return getGiftMatchingBuilderErrorMessage(value);
  }

  if (
    validationModel.formbuilderQuestionsRequired &&
    !isFormbuilderQuestionsRequiredValid(value)
  ) {
    return I18n.t('errors.validation.form-builder-question-required');
  }

  if (
    validationModel.maxLength &&
    !isMaxLengthValid(value, validationModel.length)
  ) {
    return I18n.t('errors.validation.too-long', {
      maxValue: validationModel.length
    });
  }

  if (validationModel.password && !isPasswordValid(value)) {
    return getPasswordErrorMessage(value);
  }

  if (validationModel.optionalRecord && !isRecordValid(value)) {
    return I18n.t('errors.validation.event-ambassador-data-invalid');
  }

  return '';
};

export const isAllFieldsValid = (fieldsWithValidationModels) => {
  const validationResults = fieldsWithValidationModels.map((item) => {
    return isValid(item.validationModel, item.value);
  });

  return validationResults.filter((item) => item === false).length === 0;
};

export const isCampaignValid = (value, campaignId) => {
  if (value) {
    return value !== campaignId;
  }

  return true;
};

export const isStartDateRangeValid = (value, optionalParam) => {
  if (!value && !optionalParam) {
    return true;
  }

  if (
    value &&
    optionalParam &&
    isDateStringValid(value) &&
    isDateStringValid(optionalParam)
  ) {
    return new Date(value) <= new Date(optionalParam);
  }

  return !optionalParam && value;
};

export const isEndDateRangeValid = (value, optionalParam) => {
  if (!value && !optionalParam) {
    return true;
  } else if (
    value &&
    optionalParam &&
    isDateStringValid(value) &&
    isDateStringValid(optionalParam)
  ) {
    return new Date(value) >= new Date(optionalParam);
  }

  return optionalParam && !value;
};

export const isRequiredValid = (value) => {
  if (isString(value)) {
    return !!value.trim();
  }

  if (Array.isArray(value)) {
    return value.length > 0;
  }

  return !!value;
};

export const isMaxLengthValid = (value, maxLength) => {
  if (value) {
    return value.length <= maxLength;
  }

  return true;
};

export const isEmailValid = (email) => {
  if (email) {
    const emailRegex =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(email.toLowerCase());
  }
  return true;
};

export const getDefaultAmountsErrorMessage = (defaultAmounts) => {
  if (!isDefaultAmountsRequiredValid(defaultAmounts)) {
    return I18n.t('errors.validation.default-amounts-required');
  }

  if (!isDefaultAmountsDescriptionsValid(defaultAmounts)) {
    return I18n.t('errors.validation.default-amounts-descriptions');
  }

  if (
    !isDefaultAmountsAmountsValid(
      defaultAmounts,
      config.MAX_DONATION_VALUE,
      true
    )
  ) {
    return I18n.t('errors.validation.default-amounts-amounts', {
      maxValue: config.MAX_DONATION_VALUE
    });
  }

  if (!isDefaultAmountsImagesValid(defaultAmounts)) {
    return I18n.t('errors.validation.default-amounts-images');
  }

  return '';
};

export const getDefaultButtonSetupErrorMessage = (setupButtons) => {
  if (!isDefaultSetupButtonsTextRequiredValid(setupButtons)) {
    return I18n.t('errors.validation.default-button-setup-text-required');
  }

  if (!isDefaultSetupButtonsLinkValid(setupButtons)) {
    return I18n.t('errors.validation.default-button-setup-link-required');
  }

  if (!isDefaultSetupButtonsPageTypeRequiredValid(setupButtons)) {
    return I18n.t('errors.validation.default-button-setup-page-type-required');
  }

  return '';
};

export const getTicketBuilderErrorMessage = (tickets) => {
  if (!isTicketsRequiredValid(tickets)) {
    return I18n.t('errors.validation.tickets-required');
  }

  if (!isTicketsTitleValid(tickets)) {
    return I18n.t('errors.validation.tickets-title');
  }

  if (!isTicketsStreamValid(tickets)) {
    return I18n.t('errors.validation.tickets-stream');
  }

  if (!isTicketsDescriptionValid(tickets)) {
    return I18n.t('errors.validation.tickets-description');
  }

  if (!isTicketsPriceRequiredValid(tickets)) {
    return I18n.t('errors.validation.tickets-price-required');
  }

  if (!isTicketsPriceValid(tickets, config.MAX_DONATION_VALUE, true)) {
    return I18n.t('errors.validation.tickets-price', {
      maxValue: config.MAX_DONATION_VALUE
    });
  }

  if (!isTicketsAvailableUntilValid(tickets)) {
    return I18n.t('errors.validation.tickets-available-until');
  }

  return '';
};

export const getWaiverBuilderErrorMessage = (waivers) => {
  if (!isWaiverLinkValid(waivers)) {
    return I18n.t('errors.validation.url');
  }

  if (!isWaiverLinkRequired(waivers)) {
    return I18n.t('errors.validation.required');
  }

  return '';
};

export const getCouponBuilderErrorMessage = (coupons) => {
  if (!isCouponsRequiredValid(coupons)) {
    return I18n.t('errors.validation.coupons-required');
  }

  if (!isTicketsTitleValid(coupons)) {
    return I18n.t('errors.validation.coupons-title');
  }

  if (!isCouponsInstancesRequired(coupons)) {
    return I18n.t('errors.validation.coupon-code');
  }

  if (!isCouponsDiscountValueValid(coupons)) {
    return I18n.t('errors.validation.coupons-discount');
  }

  return '';
};

export const getCouponFormErrorMessage = (coupon) => {
  if (!isCouponInstancesRequired(coupon)) {
    return I18n.t('errors.validation.coupon-code');
  }

  if (!isInstancesMaxLengthValid(coupon)) {
    return I18n.t('errors.validation.too-long', {
      maxValue: config.COUPON_DISCOUNT_CODE_MAX_LENGTH
    });
  }

  if (!isCouponDiscountValueValid(coupon)) {
    return I18n.t('errors.validation.coupons-discount');
  }

  return '';
};

export const getSkuBuilderErrorMessage = (skus) => {
  if (!isSkusTitleValid(skus)) {
    return I18n.t('errors.validation.skus-title');
  }

  if (!isSkusPriceRequiredValid(skus)) {
    return I18n.t('errors.validation.skus-price-required');
  }

  if (!isSkusPriceValid(skus, config.MAX_DONATION_VALUE, true)) {
    return I18n.t('errors.validation.skus-price', {
      maxValue: config.MAX_DONATION_VALUE
    });
  }

  return '';
};

export const getTagsBuilderErrorMessage = ({ tags, tagName }) => {
  const isTagNameValid = isMaxLengthValid(tagName, config.TAGS_MAX_LENGTH);
  const isUniqueTagName = isUniqueValue(tags, tagName);

  if (!isTagNameValid) {
    return I18n.t('errors.validation.too-long', {
      maxValue: config.TAGS_MAX_LENGTH
    });
  }

  if (!isUniqueTagName) {
    return I18n.t('errors.validation.tags-taken');
  }

  return '';
};

export const getFitnessActivitiesErrorMessage = (targets) => {
  if (!isFitnessActivitiesValid(targets)) {
    return I18n.t('errors.validation.fitness-activities-required');
  }

  return '';
};

export const getFitnessTrackedStatisticsErrorMessage = ({
  allowedTrackedStatistics,
  allowedTypes
}) => {
  if (!isFitnessTrackedStatisticsRequiredValid(allowedTrackedStatistics)) {
    return I18n.t('errors.validation.fitness-tracked-statistics-required');
  }

  if (
    !isFitnessTrackedStatisticsForActivitiesValid(
      allowedTrackedStatistics,
      allowedTypes
    )
  ) {
    return I18n.t(
      'errors.validation.fitness-tracked-statistics-for-activities'
    );
  }

  return '';
};

export const getFitnessTargetsErrorMessage = (targets) => {
  if (!isFitnessTargetsRequiredValid(targets)) {
    return I18n.t('errors.validation.fitness-targets-required');
  }

  if (!isFitnessTargetsAmountValid(targets, config.MAX_TARGET)) {
    return I18n.t('errors.validation.fitness-targets-amount', {
      maxValue: config.MAX_TARGET
    });
  }

  return '';
};

export const getGiftMatchingBuilderErrorMessage = (model) => {
  if (isArrayWithItems(model)) {
    if (!isGiftMatchingNamesValid(model)) {
      return I18n.t('errors.validation.gift-matching-names-required');
    }
    if (!isGiftMatchingMultipliersValid(model)) {
      return I18n.t('errors.validation.gift-matching-multipliers-required');
    }

    if (!isGiftMatchingConditionsRequiredValid(model)) {
      return I18n.t('errors.validation.gift-matching-conditions-required');
    }

    if (!isGiftMatchingConditionsKeyValid(model)) {
      return I18n.t('errors.validation.gift-matching-conditions-key-required');
    }
    if (!isGiftMatchingConditionsOperatorValid(model)) {
      return I18n.t(
        'errors.validation.gift-matching-conditions-operator-required'
      );
    }

    if (!isGiftMatchingConditionsValueValid(model)) {
      return I18n.t('errors.validation.gift-matching-conditions-value-invalid');
    }
  }
  return '';
};

export const isTicketBuilderValid = (tickets) => {
  const ticketsRequiredValid = isTicketsRequiredValid(tickets);
  const ticketsTitleValid = isTicketsTitleValid(tickets);
  const ticketsStreamValid = isTicketsStreamValid(tickets);
  const ticketsDescriptionValid = isTicketsDescriptionValid(tickets);
  const ticketsPriceRequiredValid = isTicketsPriceRequiredValid(tickets);
  const ticketsPriceValid = isTicketsPriceValid(
    tickets,
    config.MAX_DONATION_VALUE,
    true
  );
  const ticketsAvailableUntilValid = isTicketsAvailableUntilValid(tickets);

  return (
    ticketsRequiredValid &&
    ticketsTitleValid &&
    ticketsStreamValid &&
    ticketsDescriptionValid &&
    ticketsPriceRequiredValid &&
    ticketsPriceValid &&
    ticketsAvailableUntilValid
  );
};

export const isTicketsRequiredValid = (tickets) => {
  return tickets && tickets.length > 0;
};

export const isTicketsTitleValid = (tickets) => {
  if (!tickets) {
    return true;
  }

  return tickets.every(
    (x) =>
      x.name.trim().length &&
      x.name.trim().length < config.TICKET_TITLE_MAX_LENGTH
  );
};

export const isTicketsStreamValid = (tickets) => {
  if (!tickets) {
    return true;
  }

  return tickets.every((x) =>
    isMaxLengthValid(x.stream, config.TICKET_STREAM_MAX_LENGTH)
  );
};

export const isTicketsDescriptionValid = (tickets) => {
  if (!tickets) {
    return true;
  }

  //TODO: Use trim() before API call otherwise it will throw an error
  return tickets.every(
    (ticket) =>
      !ticket.description ||
      ticket.description.trim().length < config.TICKET_DESCRIPTION_MAX_LENGTH
  );
};

export const isTicketsPriceRequiredValid = (tickets) => {
  if (!tickets) {
    return true;
  }

  return tickets.every((x) => x.pricing.price === null || x.pricing.price);
};

export const isTicketsPriceValid = (tickets, maxValue, isRequired) => {
  if (!tickets) {
    return true;
  }

  return tickets.every(
    (x) =>
      x.pricing.price === null ||
      isAmountValid(x.pricing.price, maxValue, isRequired)
  );
};

export const isTicketsAvailableUntilValid = (tickets) => {
  if (!tickets) {
    return true;
  }

  return tickets.every((x) => isDateStringValid(x.availableUntil));
};

/// Terms and Conditions

export const getTermsAndConditionSetupBuilderError = (termsAndConditions) => {
  if (!isTermsAndConditionsValid(termsAndConditions)) return getTermsAndConditionsError(termsAndConditions);
  return;
};

export const isTermsAndConditionsValid = (termsAndConditions = {}) => {
  if (!termsAndConditions) return true;

  if (termsAndConditions.allowTermsAndConditions) {
    if (!termsAndConditions.htmlContent) return false;
  }
  if (termsAndConditions.allowWaivers) return isWaiverSetupBuilderValid(termsAndConditions.waivers);
  return true;
};

export const getTermsAndConditionsError = (termsAndConditions = {}) => {
  if (termsAndConditions.allowTermsAndConditions) {
    if (!termsAndConditions.htmlContent) return I18n.t('template.page.content.termsAndConditionSetup.terms.required');
  }
  if (termsAndConditions.allowWaivers) return getWaiverBuilderError(termsAndConditions.waivers);
  return;
};

/// Terms and Conditions - Waivers

const isWaiverSetupBuilderValid = (waivers) => {
  if (!waivers || !waivers.length) return true;
  return !waivers.some(item => !isWaiverValid(item));
};

const isWaiverValid = (waiver) => {
  if (!waiver.htmlContent) return false;
  return true;
};

const getWaiverBuilderError = (waivers) => {
  if (!waivers || !waivers.length) return;
  const validationFunctions = [getWaiverBuilderUrlError];
  for (const validate of validationFunctions) {
    for (const item of waivers) {
      const message = validate(item);
      if (message) return message;
    }
  }
  return;
};
const getWaiverBuilderUrlError = (waiver) => {
  if (!waiver.htmlContent) return I18n.t('template.page.content.termsAndConditionSetup.waiver.required');
  return;
};

/// Merchandise

export const isMerchandiseSkuValid = (sku) => {
  if (!sku.name || sku.name.length > SkuConstants.SKU_NAME_MAX_LENGTH) return false;
  if (sku.description && sku.description.length > SkuConstants.SKU_DESCRIPTION_MAX_LENGTH) return false;
  if (!sku.pricing || !sku.pricing.price) return false;
  return true;
};

export const isMerchandiseSkuNameValid = (sku) => {
  if (!sku.name) return 'Merchandise sku requires a name!';
  if (sku.name.length > SkuConstants.SKU_NAME_MAX_LENGTH)
    return `Merchandise sku name is too long max: ${SkuConstants.SKU_NAME_MAX_LENGTH}!`;
  return;
};

export const isMerchandiseSkuDescriptionValid = (sku) => {
  if (
    sku.description &&
    sku.description.length > SkuConstants.SKU_DESCRIPTION_MAX_LENGTH
  ) return `Merchandise sku description is too long max: ${SkuConstants.SKU_DESCRIPTION_MAX_LENGTH}!`;
  return;
};

export const isMerchandiseSkuPriceValid = (sku) => {
  if (!sku.pricing || !sku.pricing.price)
    return 'Merchandise sku requires a price!';
  return;
};

export const getMerchandiseSkuBuilderError = (sku) => {
  const validationFunctions = [isMerchandiseSkuNameValid, isMerchandiseSkuDescriptionValid, isMerchandiseSkuPriceValid];

  for (const validate of validationFunctions) {
    const message = validate(sku);
    if (message) return message;
  }

  return;
};

export const isMerchandiseValid = (merchandise = {}) => {
  if (!merchandise.name || merchandise.name.length > MerchandiseConstants.MERCHANDISE_NAME_MAX_LENGTH) return false;
  if (merchandise.description && merchandise.description.length > MerchandiseConstants.MERCHANDISE_DESCRIPTION_MAX_LENGTH) return false;
  if (!merchandise.mainImageUrl && !merchandise.mainImagePath) return false;
  if (!merchandise.skus && !merchandise.skus.length) return true;
  return !merchandise.skus.some(item => !isMerchandiseSkuValid(item));
};

export const isMerchandiseBuilderValid = (merchandising = []) => {
  if (!Array.isArray(merchandising)) return true;
  if (!merchandising.length) return true;
  return !merchandising.some(item => !isMerchandiseValid(item));
};

export const isMerchandiseNameValid = (merchandise) => {
  if (!merchandise.name) return 'Merchandise requires a name!';
  if (merchandise.name.length > MerchandiseConstants.SKU_NAME_MAX_LENGTH)
    return `Merchandise name is too long max: ${MerchandiseConstants.MERCHANDISE_NAME_MAX_LENGTH}!`;
  return;
};

export const isMerchandiseDescriptionValid = (merchandise) => {
  if (
    merchandise.description &&
    merchandise.description.length > MerchandiseConstants.MERCHANDISE_DESCRIPTION_MAX_LENGTH
  ) return `Merchandise description is too long max: ${MerchandiseConstants.MERCHANDISE_DESCRIPTION_MAX_LENGTH}!`;
  return;
};

export const isMerchandiseImageValid = (merchandise) => {
  if (!merchandise.mainImageUrl && !merchandise.mainImagePath)
    return 'Merchandise requires an image!';
  return;
};

export const getMerchandiseBuilderError = (merchandise) => {
  const validationFunctions = [isMerchandiseNameValid, isMerchandiseDescriptionValid, isMerchandiseImageValid];
  for (const validate of validationFunctions) {
    const message = validate(merchandise);
    if (message) return message;
  }

  if (!merchandise.skus && !merchandise.skus.length) return;
  for (const sku of merchandise.skus) {
    const message = getMerchandiseSkuBuilderError(sku);
    if (message) return message;
  }
};

export const getMerchandiseBuilderErrorMessage = (merchandising) => {
  if (!merchandising && !merchandising.length) return;
  for (const merchandise of merchandising) {
    const message = getMerchandiseBuilderError(merchandise);
    if (message) return message;
  }
};


export const isCouponModelValid = (coupon) => {
  const couponTitleValid = isCouponsTitleValid(coupon);
  const instancesValid = isCouponInstancesRequired(coupon);
  const instancesMaxLengthValid = isInstancesMaxLengthValid(coupon);

  const couponDiscountValuesAreValid = isCouponDiscountValueValid(coupon);

  return (
    couponTitleValid &&
    instancesValid &&
    instancesMaxLengthValid &&
    couponDiscountValuesAreValid
  );
};

export const isCouponBuilderValid = (coupons) => {
  const couponsRequiredValid = isCouponsRequiredValid(coupons);

  return couponsRequiredValid && coupons.every((x) => isCouponModelValid(x));
};

const isCouponsInstancesRequired = (coupons) => {
  let isValid = true;

  isValid = coupons.every((x) =>
    x.instances.every((x) => isRequiredValid(x.code))
  );

  return isValid;
};

const isCouponInstancesRequired = (coupon) => {
  let isValid = true;

  isValid = coupon.instances.every((x) => isRequiredValid(x.code));

  return isValid;
};

const isInstancesMaxLengthValid = (coupon) => {
  let isValid = true;

  isValid = coupon.instances.every((x) =>
    isMaxLengthValid(x.code, config.COUPON_DISCOUNT_CODE_MAX_LENGTH)
  );

  return isValid;
};

export const isCouponsRequiredValid = (coupons) => {
  return coupons && coupons.length > 0;
};

export const isCouponsTitleValid = (coupon) => {
  if (!coupon) {
    return true;
  }

  //TODO: Use trim() before API call otherwise it will throw an error
  return (
    coupon.name.trim().length &&
    coupon.name.trim().length <= config.COUPON_KEY_MAX_LENGTH
  );
};

export const isCouponsDiscountValueValid = (coupons) => {
  if (!coupons) {
    return false;
  }

  return coupons.every(
    (x) =>
      !isNaN(parseInt(x.discountTypeValue)) &&
      x.discountTypeValue <= 100 &&
      x.discountTypeValue > 0
  );
};

export const isCouponDiscountValueValid = (coupon) => {
  if (!coupon) {
    return true;
  }

  const discountType = coupon.discountType;

  const isNan = !isNaN(parseInt(coupon.discountTypeValue));

  if (discountType === SaleCouponDiscountType.AsFixedValue) {
    return isNan;
  }

  return (
    isNan && coupon.discountTypeValue <= 100 && coupon.discountTypeValue > 0
  );
};

export const isSkuBuilderValid = (skus) => {
  const skusTitleValid = isSkusTitleValid(skus);
  const skusPriceRequiredValid = isSkusPriceRequiredValid(skus);
  const skusPriceValid = isSkusPriceValid(
    skus,
    config.MAX_DONATION_VALUE,
    true
  );

  return skusTitleValid && skusPriceRequiredValid && skusPriceValid;
};

export const isWaiverLinkValid = (waiver) => {
  if (!waiver) {
    return true;
  }

  return waiver.every((x) => isUrlValid(x.link));
};

export const isWaiverLinkRequired = (waiver) => {
  if (!waiver) {
    return true;
  }

  return waiver.every((x) => isRequiredValid(x.link));
};

export const isWaiverBuilderValid = (waiver) => {
  const waiverLinkValid = isWaiverLinkValid(waiver);
  const waiverLinkRequired = isWaiverLinkRequired(waiver);

  return waiverLinkValid && waiverLinkRequired;
};

export const isSkusTitleValid = (skus) => {
  if (!skus) {
    return true;
  }

  //TODO: Use trim() before API call otherwise it will throw an error
  return skus.every(
    (x) =>
      x.name.trim().length &&
      x.name.trim().length < config.COUPON_KEY_MAX_LENGTH
  );
};

export const isSkusPriceRequiredValid = (skus) => {
  if (!skus) {
    return true;
  }

  return skus.every((x) => x.pricing.price);
};

export const isSkusPriceValid = (skus, maxValue, isRequired) => {
  if (!skus) {
    return true;
  }

  return skus.every((x) =>
    isAmountValid(x.pricing.price, maxValue, isRequired)
  );
};

export const isTagsBuilderValid = ({ tags, tagName }) => {
  const isTagNameValid = isMaxLengthValid(tagName, config.TAGS_MAX_LENGTH);
  const isUniqueTagName = isUniqueValue(tags, tagName);

  return isTagNameValid && isUniqueTagName;
};

export const isFitnessActivitiesValid = (activities) => {
  const activitiesArray = stringToArray(activities);
  return activitiesArray && activitiesArray.length > 0;
};

export const isFitnessTrackedStatisticsValid = ({
  allowedTrackedStatistics,
  allowedTypes
}) => {
  const fitnessTrackedStatisticsRequiredValid =
    isFitnessTrackedStatisticsRequiredValid(allowedTrackedStatistics);
  const fitnessTrackedStatisticsForActivitiesValid =
    isFitnessTrackedStatisticsForActivitiesValid(
      allowedTrackedStatistics,
      allowedTypes
    );

  return (
    fitnessTrackedStatisticsRequiredValid &&
    fitnessTrackedStatisticsForActivitiesValid
  );
};

export const isFitnessTrackedStatisticsRequiredValid = (statistics) => {
  const statisticsArray = stringToArray(statistics);
  return statisticsArray && statisticsArray.length > 0;
};

export const isFitnessTrackedStatisticsForActivitiesValid = (
  statistics,
  activities
) => {
  const statisticsArray = stringToArray(statistics);
  const activitiesArray = stringToArray(activities);
  return activitiesArray.every((option) =>
    statisticsArray.some(
      (item) =>
        activityTypeMeasures?.[option]?.findIndex(
          (x) => x?.toLowerCase() === item?.toLowerCase()
        ) >= 0
    )
  );
};

export const isFitnessTargetsValid = (targets, optionalParam) => {
  const visibleTargets = optionalParam
    ? targets.filter((x) => optionalParam.some((v) => v === x.key))
    : targets;

  const fitnessTargetsRequiredValid =
    isFitnessTargetsRequiredValid(visibleTargets);
  const fitnessTargetsAmountValid = isFitnessTargetsAmountValid(
    visibleTargets,
    config.MAX_TARGET
  );

  return fitnessTargetsRequiredValid && fitnessTargetsAmountValid;
};

export const isFitnessTargetsRequiredValid = (targets) => {
  if (!targets) {
    return true;
  }

  return targets.every((x) => x.target);
};

export const isFitnessTargetsAmountValid = (targets, maxValue) => {
  if (!targets) {
    return true;
  }

  return targets.every((x) => isAmountValid(x.target, maxValue, true));
};

export const isUniqueValue = (array, value) => {
  if (!array || !array.length) {
    return true;
  }

  let items = array.map((item) => {
    if (isString(item)) {
      return item.toLowerCase();
    }
    return item;
  });

  const valueCopy = isString(value) ? value.toLowerCase() : value;

  items = [...items, valueCopy];

  return !hasArrayDuplicates(items);
};

export const isDefaultAmountsValid = (defaultAmounts) => {
  const requiredValid = isDefaultAmountsRequiredValid(defaultAmounts);
  const descriptionsValid = isDefaultAmountsDescriptionsValid(defaultAmounts);
  const amountsValid = isDefaultAmountsAmountsValid(
    defaultAmounts,
    config.MAX_DONATION_VALUE,
    true
  );
  const imagesValid = isDefaultAmountsImagesValid(defaultAmounts);

  return requiredValid && descriptionsValid && amountsValid && imagesValid;
};

export const isDefaultSetupButtonsValid = (setupButtons) => {
  const linkValid = isDefaultSetupButtonsLinkValid(setupButtons);
  const textValid = isDefaultSetupButtonsTextRequiredValid(setupButtons);
  const pageTypesValid =
    isDefaultSetupButtonsPageTypeRequiredValid(setupButtons);

  return textValid && linkValid && pageTypesValid;
};

const isDefaultAmountsDescriptionsValid = (defaultAmounts) => {
  if (!defaultAmounts) {
    return true;
  }

  return (
    defaultAmounts.every((x) => x.description) ||
    defaultAmounts.every((x) => !x.description)
  );
};

const isDefaultAmountsImagesValid = (defaultAmounts) => {
  if (!defaultAmounts) {
    return true;
  }

  return (
    defaultAmounts.every((x) => x.image) ||
    defaultAmounts.every((x) => !x.image)
  );
};

const isDefaultAmountsAmountsValid = (defaultAmounts, maxValue, isRequired) => {
  if (!defaultAmounts) {
    return true;
  }

  return defaultAmounts.every((x) =>
    isAmountValid(x.amount, maxValue, isRequired)
  );
};

const isDefaultAmountsRequiredValid = (defaultAmounts) => {
  if (!defaultAmounts) {
    return true;
  }

  return defaultAmounts.every((x) => x.amount);
};

const isDefaultSetupButtonsTextRequiredValid = (setupButtons) => {
  if (!setupButtons) {
    return true;
  }

  return setupButtons.every((x) => x.text);
};

const isDefaultSetupButtonsPageTypeRequiredValid = (setupButtons) => {
  if (!setupButtons) {
    return true;
  }

  return setupButtons.every((x) => x.pageTypes && x.pageTypes.length);
};

const isDefaultSetupButtonsLinkValid = (setupButtons) => {
  if (!setupButtons) {
    return true;
  }

  const CAMPAIGN_URL_TAG = '{campaignUrl}';
  const REGULAR_BUTTON = 'regularButton';

  return setupButtons
    .filter((x) => x.specialType === REGULAR_BUTTON)
    .every(
      (x) => x.link && (isUrlValid(x.link) || x.link === CAMPAIGN_URL_TAG)
    );
};

export const isFormBuilderRequiredValid = (formBuilderModel) => {
  if (!hasFormbuilderQuestions(formBuilderModel)) {
    return true;
  }

  const validationResults = getFormbuilderQuestions(formBuilderModel).map(
    (item) => {
      if (item.type === nodeTypes.consent) {
        return true;
      }

      if (
        item.props.required &&
        (!item.answer || (isString(item.answer) && !item.answer.trim()))
      ) {
        return false;
      }
      return true;
    }
  );

  return validationResults.every((x) => x === true);
};

export const isFormBuilderAnswersValid = (
  formBuilderModel,
  validationModel
) => {
  if (!hasFormbuilderQuestions(formBuilderModel)) {
    return true;
  }

  const validationResults = getFormbuilderQuestions(formBuilderModel).map(
    (item) => {
      if (
        item.type === nodeTypes.address &&
        ((validationModel.formBuilderRequired &&
          item.props.required &&
          !isFormbuilderAddressRequiredValid(item.answer)) ||
          !isFormbuilderAddressValid(item.answer))
      ) {
        return false;
      }

      if (item.type === nodeTypes.phone && !isPhoneValid(item.answer)) {
        return false;
      }

      if (
        item.type === nodeTypes.additionalPhoneNumber &&
        !isPhoneValid(item.answer)
      ) {
        return false;
      }

      if (
        item.type === nodeTypes.additionalEmailAddress &&
        !isEmailValid(item.answer)
      ) {
        return false;
      }

      if (
        item.type === nodeTypes.date &&
        !isDateStringValid(
          item.answer
            ? moment(item.answer).format(config.DEFAULT_DATE_FORMAT)
            : null
        )
      ) {
        return false;
      }

      return true;
    }
  );

  return validationResults.every((x) => x === true);
};

export const isFormbuilderAddressRequiredValid = (value) => {
  if (!value) {
    return false;
  }

  const addressFieldsValidations = Object.keys(addresFields).map((field) => {
    const fieldPath = addresFields[field];
    return isRequiredValid(value[fieldPath]);
  });

  return addressFieldsValidations.every((x) => x === true);
};

export const isFormbuilderAddressValid = (value) => {
  if (!value) {
    return true;
  }

  const addressFieldsValidations = Object.keys(addresFields).map((field) => {
    const fieldPath = addresFields[field];
    const maxLength = get(addressFieldsValidation, `${fieldPath}.length`);
    return isMaxLengthValid(value[fieldPath], maxLength);
  });

  return addressFieldsValidations.every((x) => x === true);
};

export const isFormbuilderQuestionsRequiredValid = (value) => {
  if (!value) {
    return true;
  }

  const questions = get(value, 'children.0.children');

  return questions && questions.length > 0;
};

export const isFormbuilderQuestionsMaxLengthValid = (value) => {
  if (!value) {
    return true;
  }

  const questions = get(value, 'children.0.children');

  return (
    questions &&
    isMaxLengthValid(questions, config.FORMBUILDER_QUESTIONS_MAX_LENGTH)
  );
};

export const isRecordValid = (value) => {
  if (value) {
    return false;
  }

  return true;
};

export const isFormBuilderQuestionsValid = (value) => {
  if (!value || !hasFormbuilderQuestions(value)) {
    return true;
  }

  let isValid = true;
  const formbuilderQuestions = getFormbuilderQuestions(value);

  formbuilderQuestions.forEach((question) => {
    const title = get(question, 'props.title');
    const exportLabel = get(question, 'props.exportLabel');

    if (
      !title ||
      !title.trim() ||
      !isMaxLengthValid(title, config.FORMBUILDER_TITLE_MAX_LENGTH)
    ) {
      isValid = false;
      return;
    }

    if (isExportLabelMaxLengthValid(exportLabel)) {
      isValid = false;
      return;
    }

    if (
      question.type === nodeTypes.checkbox ||
      question.type === nodeTypes.dropdown
    ) {
      const options = get(question, 'props.options');

      if (!options || !isFormbuilderOptionsValid(options)) {
        isValid = false;
        return;
      }
    }

    if (question.type === nodeTypes.consent) {
      const link = get(question, 'props.link');

      if (!isUrlValid(link)) {
        isValid = false;
        return;
      }
    }
  });

  return isValid;
};

export const isExportLabelMaxLengthValid = (value) => {
  return !isMaxLengthValid(value, config.FORMBUILDER_EXPORT_LABEL_MAX_LENGTH);
};

export const isFormbuilderOptionsValid = (value) => {
  if (!value) {
    return true;
  }

  const optionsArray = value.split('\n');

  return (
    isMaxLengthValid(value, config.FORMBUILDER_OPTIONS_MAX_LENGTH) &&
    !hasArrayDuplicates(optionsArray) &&
    optionsArray.every((x) => {
      return x && x.trim();
    })
  );
};

export const isPhoneValid = (phone) => {
  if (phone) {
    const result = isValidNumber(phone);
    if (result === false) {
      if (phone.length === 12 && phone.startsWith('+614')) {
        return true;
      }
    }
    return result;
  }

  return true;
};

export const isDateStringValid = (date) => {
  if (date) {
    const dateRegex = /\d\d\d\d\/\d\d\/\d\d/;
    return (
      dateRegex.test(date) && moment(date, config.DEFAULT_DATE_FORMAT).isValid()
    );
  }

  return true;
};

export const isUrlValid = (url) => {
  if (url) {
    var urlRegex =
      /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,}(:[0-9]{1,5})?(\/.*)?$/gm;
    return urlRegex.test(url.toLowerCase());
  }

  return true;
};

export const isWebTrackingValid = (value, validationModel) => {
  return value.every((x) => {
    return isPatternValid(x.value, validationModel?.[x.key]?.pattern) === true;
  });
};

export const isPatternValid = (value, pattern) => {
  if (!value) {
    return true;
  }

  return new RegExp(pattern).test(value);
};

export const isDomainUrlValid = (url) => {
  if (url) {
    var urlRegex =
      /^[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
    return urlRegex.test(url.toLowerCase());
  }

  return true;
};

export const isAmountValid = (value, maxValue, isRequired) => {
  if (!isRequired && !value) {
    return true;
  }

  const isNumberPositive = !isNaN(value) && value > 0;

  if (!isNumberPositive) {
    return false;
  }

  if (maxValue) {
    return isValueLessThanMaxValue(value, maxValue);
  }

  return true;
};

export const isCardValid = (value) => {
  if (value) {
    return value.complete;
  }
  return true;
};

const isValueLessThanMaxValue = (value, maxValue) => {
  return value <= maxValue;
};

export const isPasswordValid = (password) => {
  const isPasswordRequiredValid = isRequiredValid(password);
  const isMinLengthValid = isPasswordMinLengthValid(password);
  const isMaxLengthValid = isPasswordMaxLengthValid(password);
  const isLovercaseLetterValid = isPasswordLovercaseLetterValid(password);
  const isUppercaseLetterValid = isPasswordUppercaseLetterValid(password);
  const isDigitValid = isPasswordDigitValid(password);

  return (
    isPasswordRequiredValid &&
    isMinLengthValid &&
    isMaxLengthValid &&
    isLovercaseLetterValid &&
    isUppercaseLetterValid &&
    isDigitValid
  );
};

export const isPasswordMinLengthValid = (password) => {
  if (password) {
    return password.length >= config.MIN_PASSWORD_LENGTH;
  }

  return true;
};

export const isPasswordMaxLengthValid = (password) => {
  if (password) {
    return password.length < config.MAX_PASSWORD_LENGTH;
  }

  return true;
};

export const isPasswordLovercaseLetterValid = (password) => {
  if (password) {
    const lovercaseRegex = /^(?=.*[a-z])/;
    if (!lovercaseRegex.test(password)) {
      return false;
    }
  }

  return true;
};

export const isPasswordUppercaseLetterValid = (password) => {
  if (password) {
    const uppercaseRegex = /^(?=.*[A-Z])/;
    if (!uppercaseRegex.test(password)) {
      return false;
    }
  }

  return true;
};

export const isPasswordDigitValid = (password) => {
  if (password) {
    const digitRegex = /^(?=.*[0-9])/;
    if (!digitRegex.test(password)) {
      return false;
    }
  }

  return true;
};

export const getPasswordErrorMessage = (password) => {
  const errors = [];

  if (!isRequiredValid(password)) {
    errors.push(I18n.t('errors.validation.password.cant-be-empty'));
    return errors;
  }

  if (!isPasswordMinLengthValid(password)) {
    errors.push(
      I18n.t('errors.validation.password.too-short').replace(
        '{0}',
        config.MIN_PASSWORD_LENGTH
      )
    );
  }

  if (!isPasswordMaxLengthValid(password)) {
    errors.push(
      I18n.t('errors.validation.password.too-long').replace(
        '{0}',
        config.MAX_PASSWORD_LENGTH
      )
    );
  }

  if (!isPasswordLovercaseLetterValid(password)) {
    errors.push(
      I18n.t('errors.validation.password.doesnt-have-lowercase-letter')
    );
  }

  if (!isPasswordUppercaseLetterValid(password)) {
    errors.push(
      I18n.t('errors.validation.password.doesnt-have-uppercase-letter')
    );
  }

  if (!isPasswordDigitValid(password)) {
    errors.push(I18n.t('errors.validation.password.doesnt-have-digit'));
  }

  if (errors.length > 0) {
    return errors;
  }

  return undefined;
};

export const isAboutBuilderValid = (about) => {
  if (about && about.elements) {
    return about.elements.every((element) => {
      if (element.type === AboutElementTypes.PDF && element.value) {
        const isFileDescriptionValid = isMaxLengthValid(
          element.value.description,
          config.PDF_FILE_DESCRIPTION_MAX_LENGTH
        );

        return isFileDescriptionValid;
      }

      return true;
    });
  }

  return true;
};

export const isDashboardBuilderValid = ({ model, validatedModels }) => {
  const isRequiredValid = isDashboardBuilderRequiredValid(model);
  const isTimeLapseRequiredValid =
    isDashboardBuilderTimeLapseRequiredValid(model);
  // const isTimeLapseEqualValid = isDashboardBuilderTimeLapseEqualValid(model, validatedModels);

  return isRequiredValid && isTimeLapseRequiredValid; //&& isTimeLapseEqualValid;
};

export const isDashboardBuilderRequiredValid = (model) => {
  return model.id && model.name && model.dateTo && model.dateFrom;
};

export const isDashboardBuilderTimeLapseRequiredValid = (model) => {
  const firstDate = new Date(model.dateTo);
  const secondDate = new Date(model.dateFrom);

  if (firstDate > secondDate) {
    const diffDays = daysBetween(model.dateTo, model.dateFrom);
    return diffDays > 0;
  }

  return false;
};

export const isDashboardBuilderTimeLapseEqualValid = (
  model,
  validatedModels
) => {
  if (isArrayWithItems(validatedModels)) {
    const diffDays = daysBetween(model.dateTo, model.dateFrom);
    const validatedDiffDays = daysBetween(
      validatedModels[0].dateTo,
      validatedModels[0].dateFrom
    );
    return diffDays === validatedDiffDays;
  }

  return true;
};

export const getDashboardBuilderErrorMessage = ({ model, validatedModels }) => {
  if (!isDashboardBuilderRequiredValid(model)) {
    return '';
  }

  if (!isDashboardBuilderTimeLapseRequiredValid(model)) {
    return I18n.t('errors.validation.dashboard-builder-timeLapse-required');
  }

  // if (!isDashboardBuilderTimeLapseEqualValid(model, validatedModels)) {
  //     return I18n.t('errors.validation.dashboard-builder-timeLapse-equal');
  // }

  return '';
};

export const isGiftMatchingBuilderValid = (model) => {
  if (isArrayWithItems(model)) {
    const isNamesValid = isGiftMatchingNamesValid(model);
    const isMultipliersValid = isGiftMatchingMultipliersValid(model);
    const isDonationFromNamesValid =
      isGiftMatchingDonationFromNamesValid(model);
    const isDonationMessagesValid = isGiftMatchingDonationMessagesValid(model);

    const isConditionsRequiredValid =
      isGiftMatchingConditionsRequiredValid(model);

    const isConditionsKeyValid = isGiftMatchingConditionsKeyValid(model);
    const isConditionsOperatorValid =
      isGiftMatchingConditionsOperatorValid(model);

    const isConditionsValueValid = isGiftMatchingConditionsValueValid(model);

    return (
      isNamesValid &&
      isMultipliersValid &&
      isDonationFromNamesValid &&
      isDonationMessagesValid &&
      isConditionsRequiredValid &&
      isConditionsKeyValid &&
      isConditionsOperatorValid &&
      isConditionsValueValid
    );
  }
  return true;
};

export const isGiftMatchingNamesValid = (model) => {
  const isGiftNamesRequiredValid = model.every((x) => isRequiredValid(x.name));
  const isGiftNamesMaxLengthValid = model.every((x) =>
    isMaxLengthValid(x.name, config.GIFTMATCHINGBUILDER_NAME_MAX_LENGTH)
  );

  return isGiftNamesRequiredValid && isGiftNamesMaxLengthValid;
};

export const isGiftMatchingMultipliersValid = (model) => {
  return model.every((x) => isRequiredValid(x.multiplier));
};

export const isGiftMatchingDonationFromNamesValid = (model) => {
  return model.every((x) =>
    isMaxLengthValid(
      x.donation.from,
      config.GIFTMATCHINGBUILDER_DONATION_FROM_MAX_LENGTH
    )
  );
};

export const isGiftMatchingDonationMessagesValid = (model) => {
  return model.every((x) =>
    isMaxLengthValid(
      x.donation.message,
      config.GIFTMATCHINGBUILDER_DONATION_MESSAGE_MAX_LENGTH
    )
  );
};

export const isGiftMatchingConditionsRequiredValid = (model) => {
  return model.every((x) => isArrayWithItems(x.conditions));
};

export const isGiftMatchingConditionsKeyValid = (model) => {
  return model.every(
    (x) =>
      isArrayWithItems(x.conditions) &&
      x.conditions.every((y) => isRequiredValid(y.key))
  );
};

export const isGiftMatchingConditionsOperatorValid = (model) => {
  return model.every(
    (x) =>
      isArrayWithItems(x.conditions) &&
      x.conditions.every((y) => isRequiredValid(y.operator))
  );
};

export const isGiftMatchingConditionsValueValid = (model) => {
  return model.every(
    (x) =>
      isArrayWithItems(x.conditions) &&
      x.conditions.every((y) => {
        const isBetween =
          y.operator === campaignGiftMatchingConditionOperator.Between;
        const isDate =
          y.key === campaignGiftMatchingConditionType.StartDateTime ||
          y.key === campaignGiftMatchingConditionType.EndDateTime;

        if (isBetween ? y.value.length !== 2 : y.value.length !== 1) {
          return false;
        }

        if (
          isBetween
            ? !(isRequiredValid(y.value[0]) && isRequiredValid(y.value[1]))
            : !isRequiredValid(y.value[0])
        ) {
          return false;
        }

        if (isDate) {
          if (
            isBetween
              ? !(
                isGiftMatchingDateValid(y.value[0]) &&
                isGiftMatchingDateValid(y.value[1])
              )
              : !isGiftMatchingDateValid(y.value[0])
          ) {
            return false;
          }
        }

        if (isBetween) {
          if (
            isDate
              ? moment(
                y.value[0],
                config.DEFAULT_FULL_DATE_FORMAT
              ).isSameOrAfter(
                moment(y.value[1], config.DEFAULT_FULL_DATE_FORMAT)
              )
              : parseInt(y.value[0], 10) >= parseInt(y.value[1], 10)
          ) {
            return false;
          }
        }

        return true;
      })
  );
};

export const isGiftMatchingDateValid = (date) => {
  const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

  if (timePattern.test(date)) {
    return false;
  }

  return moment(date, config.DEFAULT_FULL_DATE_FORMAT).isValid();
};
