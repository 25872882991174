import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Modal, Form, Button, Message, Icon } from 'semantic-ui-react';

// LIBS
import { Translate } from 'react-redux-i18n';

// REDUCERS
import { handleValueChange, verificationModalClear } from '../reducer';

// COMPONENTS
import VerificationForm from '../../../../components/authentication/verificationForm';
import RecoveryForm from '../../../../components/authentication/recoveryForm';
import { Record } from '../../../../modules/bin/utility';

// CONSTANTS
import { verificationVariant } from '../../../../constants/multiFactorAuth';
import config from '../../../../constants';

class VerificationModalWrapper extends Component {
  constructor(props) {
    super(props);

    this.state = {
      variant: verificationVariant.authCode
    };
  }

  handleVerificationVariantChange = (value) => {
    if (this.state.variant !== value) {
      this.setState({
        variant: value
      });
    }
  };

  handleCloseModal = () => {
    this.setState({
      variant: verificationVariant.authCode
    });
    this.props.verificationModalClear();
  };

  isVerificationCodeValid = () => {
    if (this.state.variant === verificationVariant.authCode) {
      return (
        this.props.model.authCode &&
        this.props.model.authCode.length === config.VERIFICATION_CODE_LENGTH
      );
    }

    if (this.state.variant === verificationVariant.recoveryCode) {
      return !!this.props.model.recoveryCode;
    }
  };

  render() {
    const isRecordError =
      this.props.verificationRecord &&
      Record.isError(this.props.verificationRecord);
    const isRecordLoading =
      this.props.verificationRecord &&
      Record.isRecordLoading(this.props.verificationRecord);
    const isLoading = this.props.isLoading || isRecordLoading;

    const isVerificationCodeValid = this.isVerificationCodeValid();

    return (
      <Modal
        className="session-verification-modal"
        size="tiny"
        dimmer="inverted"
        open={this.props.model.open}
        onClose={this.handleCloseModal}
      >
        <Icon
          name="close"
          className="security-modal-close-icon"
          size="large"
          onClick={this.handleCloseModal}
        />
        <Modal.Content>
          <Form
            onSubmit={this.props.handleVerificationClick.bind(
              this,
              this.state.variant
            )}
          >
            <div className="verification-form">
              <h1 className="variant-title">
                <Translate value={this.props.verificationTitle} />
              </h1>
              <br />
              {this.state.variant === verificationVariant.authCode && (
                <VerificationForm
                  path="accountVerification"
                  verificationRecord={this.props.verificationRecord}
                  handleValueChange={this.props.handleValueChange}
                  handleVerificationVariantChange={
                    this.handleVerificationVariantChange
                  }
                />
              )}
              {this.state.variant === verificationVariant.recoveryCode && (
                <RecoveryForm
                  path="accountVerification"
                  value={this.props.model.recoveryCode}
                  verificationRecord={this.props.verificationRecord}
                  handleValueChange={this.props.handleValueChange}
                  handleVerificationVariantChange={
                    this.handleVerificationVariantChange
                  }
                />
              )}
              {isRecordError && (
                <Message negative>
                  <p>{this.props.verificationError}</p>
                </Message>
              )}
              {this.props.verificationContent && (
                <span className="disable-tab-content">
                  <Translate value={this.props.verificationContent} />
                </span>
              )}
              <div className="verification-action-button">
                <Button
                  primary
                  className="disable-button"
                  loading={this.props.isLoading || isRecordLoading}
                  disabled={!isVerificationCodeValid || isLoading}
                >
                  <Translate value={this.props.verificationButton} />
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ accountSettings }) => {
  return {
    model: accountSettings.accountVerification
  };
};

const mapDispatch = (dispatch) => {
  return {
    handleValueChange: (path, value) => {
      dispatch(handleValueChange(path, value));
    },
    verificationModalClear: () => {
      dispatch(verificationModalClear());
    }
  };
};

export default connect(mapState, mapDispatch)(VerificationModalWrapper);
