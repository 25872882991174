import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';
import get from 'lodash.get';

class NumberItem extends Component {
  render() {
    const { path, onChange, value } = this.props;
    return (
      <Form.Group>
        <Form.Input
          width={8}
          path={`${path}.from`}
          type="number"
          placeholder="From"
          onChange={onChange}
          value={get(value, 'from') || ''}
        />

        <Form.Input
          width={8}
          path={`${path}.to`}
          type="number"
          placeholder="To"
          onChange={onChange}
          value={get(value, 'to') || ''}
        />
      </Form.Group>
    );
  }
}

export default NumberItem;
