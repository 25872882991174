import React from 'react';
import NumberFormat from 'react-number-format';

import currencyHelper from '../../../../helpers/currencyHelper';

interface CurrencyFormatterProps {
  value: number;
  truncate?: boolean;
  fixed?: number;
  additionalData?: any;
}

const CurrencyFormatter = ({
  value,
  truncate,
  additionalData,
  fixed = 2
}: CurrencyFormatterProps) => {

  let number = Math.round(value * 100) / 100;
  if (truncate) {
    number = Math.floor(number);
  }

  const formattedValue = currencyHelper.formatCurrency(number, additionalData?.currency);

  const formattedDisplay = additionalData?.currency ? `${additionalData?.currency} ${formattedValue}` : formattedValue;

  return (
    <div className="currency-formatter">
      {formattedDisplay}
    </div>
  );
};

export default CurrencyFormatter;
