export class GridOptions {
  state: {
    timestamp: number;
    ready: number;
    /* EMPTY */ totalCount: null;
    metadata: {
      ready: number;
      /* EMPTY */ columns: null;
      columnMap: null;
      filters: null;
      toggle: null;
      sort: null;
    };
    columns: any[];
    contextMenu: any[];
    filterButtons: any[];
    availableFiltersKeys: any[];
    baseFilters: any[];
    request: {
      page: number;
      pageSize: number;
      text: string;
      resultType: string;
      orderBy?: {};
      filters: any[];
      baseObjectsOnly: boolean;
    };
    byPage: {};
    hasMultiSelect?: boolean;
  };

  constructor() {
    this.state = {
      timestamp: new Date().getTime(),
      ready: -1 /* EMPTY */,
      totalCount: null,
      metadata: {
        ready: -1 /* EMPTY */,
        columns: null,
        columnMap: null,
        filters: null,
        toggle: null,
        sort: null
      },
      columns: [],
      contextMenu: [],
      filterButtons: [],
      availableFiltersKeys: [],
      baseFilters: [],
      request: {
        page: 1,
        pageSize: 50,
        text: null || '',
        resultType: 'Full',
        filters: [],
        baseObjectsOnly: true
      },
      byPage: {}
    };
  }

  getState = () => {
    return this.state;
  };

  addColumn = (columnModel: { getColumn: () => any }) => {
    this.state.columns.push(columnModel.getColumn());
    return this;
  };

  addColumns = (columns: any[]) => {
    columns.forEach((element: { getColumn: () => any }) => {
      this.state.columns.push(element.getColumn());
    });
    return this;
  };

  setPageSize = (size = 50) => {
    this.state.request.pageSize = size;

    return this;
  };

  orderBy = (key: string, direction: string) => {
    this.state.request.orderBy = {
      key,
      direction
    };

    return this;
  };

  addFilter = (
    key: string,
    operator: string,
    value: string | boolean | string[]
  ) => {
    this.state.baseFilters.push({
      key,
      operator,
      value
    });

    return this;
  };

  addContextMenuItem = (key: string, I18nKey: string, route: string) => {
    this.state.contextMenu.push({
      key,
      I18nKey,
      route
    });

    return this;
  };

  addFilterButton = (
    key: string,
    I18nKey: string,
    filter: { key: string; operator: string; value: any }
  ) => {
    this.state.filterButtons.push({
      key,
      I18nKey,
      filter
    });

    return this;
  };

  addAvailableFiltersKeys = (availableFiltersKeys: string[]) => {
    this.state.availableFiltersKeys.push(...availableFiltersKeys);

    return this;
  };

  setMultiSelect = (value: boolean) => {
    this.state.hasMultiSelect = value;
    return this;
  };
}
