import getValue from 'lodash.get';
import numbro from 'numbro';
import moment from 'moment';

// filter operators
export const FilterOperators = [
  'equalTo',
  'inList',
  'greaterOrEqualThan',
  'lesserOrEqualThan'
] as const;
export type FilterOperatorType = (typeof FilterOperators)[number];

export type FilterValueV2Type = string | number;
export type FilterValueV3Type = Array<string | number>;

export function convertV2toV3Filter(
  filter?: FilterValueV2Type | FilterValueV3Type
): FilterValueV3Type {
  if (Array.isArray(filter)) return filter;
  if (typeof filter === 'string' || typeof filter === 'number' || typeof filter === 'boolean') return [filter];
  return [];
}

export function parseFilters<Values extends IExportFilter = IExportFilter>(
  values: Values[] = []
): IExportFilter[] {
  return values?.map((filter) => ({
    ...filter,
    value: convertV2toV3Filter(filter.value)
  }));
}

export interface IExportFilter {
  key?: string;
  operator?: FilterOperatorType;
  value?: FilterValueV2Type | FilterValueV3Type;
}

export class Filter<Values extends IExportFilter = IExportFilter> {
  private _values: Values;

  constructor(values: Values) {
    this._values = values;
  }

  get key(): string {
    return getValue<object, string, string>(this._values, 'key', '');
  }
  get operator(): string {
    return getValue<object, string, string>(this._values, 'operator', '');
  }
  get value(): string[] {
    return getValue<object, string, string[]>(this._values, 'value', []);
  }

  toJSON(): object {
    return {
      key: this.key,
      operator: this.operator,
      value: this.value
    };
  }

  toString(): string {
    return JSON.stringify(this.toJSON());
  }

  get amount(): string {
    return this.isKey('amount')
      ? numbro(this.value?.[0]).formatCurrency({
          thousandSeparated: true,
          optionalMantissa: true,
          mantissa: 2
        })
      : '$0';
  }
  get date(): string {
    return this.isKey('soldAtLocal') || this.isKey('LocalDate')
      ? moment(this.value?.[0]).format('DD MMM YYYY')
      : '';
  }
  get boolean(): boolean {
    return this.value?.[0] === 'true';
  }

  isKey(key: string): boolean {
    return this.key === key;
  }
  isOperator(operator: FilterOperatorType): boolean {
    return this.operator === operator;
  }
  hasValue(value: string): boolean {
    return !!this.value?.find?.((item) => item === value);
  }

  isFilterValue(value: string): boolean {
    switch (value) {
      case 'ticket':
      case 'merchandise':
      case 'Online':
        return (
          this.isKey('type') &&
          this.isOperator('equalTo') &&
          this.hasValue(value)
        );
      case 'Received':
      case 'Accepted':
      case 'Claimed':
        return (
          this.isKey('status') &&
          (this.isOperator('equalTo') || this.isOperator('inList')) &&
          this.hasValue(value)
        );
      default:
        return false;
    }
  }
}
