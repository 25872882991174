//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Message } from 'semantic-ui-react';

//---------------Components
import { Form, Button, Checkbox } from 'semantic-ui-react';
import { Form as CommonForm } from '../../..';
import ConfirmationModal from '../../../common/confirmationModal';

//---------------Constants
import { SaleCouponDiscountTypeArray } from '../../../collectionBuilder/constants';
import { couponPaths } from '../constants/paths';
import { i18CouponKeys } from '../constants/translation';

class CouponForm extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };
  }

  deleteModalCustomButtons = () => {
    const i18DeleteModalKey =
      'template.page.content.discounts.deleteCouponModal';

    return (
      <div className="delete-coupon-modal-buttons">
        <Button primary onClick={this.props.onDelete}>
          {I18n.t(
            `${i18DeleteModalKey}.${i18CouponKeys.deleteCouponModal.acceptButton}`
          )}
        </Button>
        <Button onClick={this.props.onCloseDeleteCouponModal}>
          {I18n.t(
            `${i18DeleteModalKey}.${i18CouponKeys.deleteCouponModal.declineButton}`
          )}
        </Button>
      </div>
    );
  };

  render() {
    const {
      index,
      currency,
      instance,
      usageLimitValue,
      discountType,
      usedCount,
      isValid,
      errorMessage,
      instanceIndex,
      allowOtherCouponsInSameSale,
      onCancel,
      deleteCouponModalOpen,
      onOpenDeleteCouponModal,
      onSave,
      i18Key,
      discountTypeValue,
      handleCouponChange,
      handleInstanceChange,
      editMode
    } = this.props;

    const isCodeRedeemed = usedCount > 0;

    const i18DeleteModalKey =
      'template.page.content.discounts.deleteCouponModal';
    const deleteModalCustomButtons = this.deleteModalCustomButtons();

    return (
      <div>
        <ConfirmationModal
          open={deleteCouponModalOpen}
          hideIcon
          customButtons={deleteModalCustomButtons}
          title={I18n.t(
            `${i18DeleteModalKey}.${i18CouponKeys.deleteCouponModal.title}`
          )}
          content={I18n.t(
            `${i18DeleteModalKey}.${i18CouponKeys.deleteCouponModal.content}`
          )}
        />

        <Form className="collection-item coupon-item">
          <Form.Field className="item-title-container" required={this.required}>
            <label className="item-title">
              {I18n.t(
                `${i18Key}.${
                  editMode
                    ? i18CouponKeys.label.editTitle
                    : i18CouponKeys.label.addTitle
                }`
              )}
            </label>
          </Form.Field>
          <div className="coupon-item-content">
            <Form.Field required={this.required}>
              <label>
                {I18n.t(`${i18Key}.${i18CouponKeys.instanceCode.label}`)}
              </label>
              <CommonForm.Input
                id="coupon-name"
                fluid
                disabled={isCodeRedeemed}
                subtext={I18n.t(
                  `${i18Key}.${i18CouponKeys.instanceCode.subtext}`
                )}
                value={instance.code || ''}
                required
                onChange={(event, { value }) => {
                  handleInstanceChange(
                    instanceIndex,
                    couponPaths.instanceCode,
                    value.toUpperCase()
                  );
                }}
              />
            </Form.Field>
            <Form.Field
              required={this.required}
              className="coupon-discount-type-options-container"
            >
              <label>
                {I18n.t(`${i18Key}.${i18CouponKeys.discountTypeValue}`)}
              </label>
              <div className="coupon-discount-type-options">
                <CommonForm.NumberInput
                  id="coupon-discount-type-value"
                  type="tel"
                  style={{ margin: '0px' }}
                  value={discountTypeValue}
                  allowDecimal={false}
                  onChange={(event) => {
                    handleCouponChange(
                      couponPaths.discountTypeValue,
                      event.target.value
                    );
                  }}
                />
                {React.Children.toArray(
                  SaleCouponDiscountTypeArray.map((option) => {
                    return (
                      <CommonForm.Checkbox
                        inputLabel={option.getLabel(currency)}
                        value={discountType}
                        checked={discountType === option.value}
                        onChange={(event) => {
                          handleCouponChange(
                            couponPaths.discountType,
                            option.value
                          );
                        }}
                        radio
                      />
                    );
                  })
                )}
              </div>
            </Form.Field>
            <Form.Field className="coupon-usage-limit-container">
              {isCodeRedeemed && (
                <label>
                  {I18n.t(
                    `${i18Key}.${i18CouponKeys.usageLimitValue.redeemedLabel.partOneText}`
                  )}{' '}
                  <span className="used-count-value">{usedCount}</span>{' '}
                  {I18n.t(
                    `${i18Key}.${i18CouponKeys.usageLimitValue.redeemedLabel.partTwoText}`
                  )}
                </label>
              )}
              {!isCodeRedeemed && (
                <label>
                  {I18n.t(`${i18Key}.${i18CouponKeys.usageLimitValue.label}`)}
                </label>
              )}
              <CommonForm.NumberInput
                id="coupon-usage-limit-value"
                type="tel"
                style={{ margin: '0px' }}
                value={usageLimitValue}
                allowDecimal={false}
                onChange={(event) => {
                  handleCouponChange(
                    couponPaths.usageLimitValue,
                    event.target.value
                  );
                }}
              />
              <label className="coupon-usage-limit-value-subtext">
                {I18n.t(`${i18Key}.${i18CouponKeys.usageLimitValue.subtext}`)}
              </label>
            </Form.Field>
            <Form.Field className="coupon-allowOtherCouponsInSameSale">
              <Checkbox
                id="coupon-allowOtherCouponsInSameSale"
                checked={allowOtherCouponsInSameSale}
                onChange={(event, { checked }) => {
                  handleCouponChange(
                    couponPaths.allowOtherCouponsInSameSale,
                    checked
                  );
                }}
                label={I18n.t(
                  `${i18Key}.${i18CouponKeys.allowOtherCouponsInSameSale}`
                )}
              />
            </Form.Field>
            <div className="coupon-item-buttons">
              <Button
                primary
                className="save-coupon-button"
                disabled={!isValid}
                onClick={onSave.bind(this, index)}
              >
                {I18n.t(`${i18Key}.${i18CouponKeys.saveButton}`)}
              </Button>
              <Button
                className="cancel-coupon-button"
                style={{
                  border: `${editMode ? '1px solid #c8c8c8' : 'none'}`
                }}
                onClick={onCancel}
              >
                {I18n.t(`${i18Key}.${i18CouponKeys.cancelButton}`)}
              </Button>
              {editMode && (
                <Button
                  className="delete-coupon-button"
                  onClick={onOpenDeleteCouponModal}
                >
                  {I18n.t(`${i18Key}.${i18CouponKeys.deleteButton}`)}
                </Button>
              )}
            </div>
            <Message negative hidden={isValid}>
              <p>{errorMessage}</p>
            </Message>
          </div>
        </Form>
      </div>
    );
  }
}

export default CouponForm;
