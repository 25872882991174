export const donationAmountPaths = {
  image: 'image',
  amount: 'amount',
  isDefault: 'isDefault',
  description: 'description'
};

export const buttonSetupPaths = {
  specialType: 'specialType',
  link: 'link',
  text: 'text',
  openInNewWindow: 'openInNewWindow',
  visibility: 'visibility',
  pageTypes: 'pageTypes'
};

export const waiverPaths = {
  title: 'title',
  link: 'link'
};

export const skuPath = {
  name: 'name',
  description: 'description',
  quantity: 'inventory.quantity',
  price: 'pricing.price',
  tax: 'pricing.hasCountryTax'
};

export const giftMatchingPath = {
  name: 'name',
  multiplier: 'multiplier',
  donationFrom: 'donation.from',
  donationMessage: 'donation.message',
  image: 'mainImagePath',
  key: 'key',
  operator: 'operator',
  value: 'value',
  conditions: 'conditions'
};
