import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate, I18n } from 'react-redux-i18n';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as generalActions from '../../../modules/general';
import { clearRecordMessages } from '../../stripe/reducer';
import { organizationActions } from '../store/organizationActions';

import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import clear from '../../../components/clear';
import update from '../../../helpers/update';

import Spinner from '../../../components/common/spinner';
import RecordResultMessage from '../../../components/common/recordResult';
import OrganizationTemplate from '../templateEx';

import { TemplateOptions } from '../../../components/template/templateOptions';
import { parseQuery } from '../../../helpers/queryHelper';
import { countries } from '../../../constants/countries';

class EditOrganization extends Component {
  constructor(props) {
    super(props);
    if (Record.notStarted(this.props.record)) {
      props.organizationActions.getOrganizationRecord(props.match.params.id);
    }

    props.generalActions.clear();

    this.state = {};
  }

  onValueChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(
      path,
      value,
      'updateRecord'
    );
  };

  onSave = (record) => {
    this.props.organizationActions.updateOrganizationRecord(record);
  };

  goToOrganisationsView = () => {
    this.props.history.push('/organizations');
  };

  initTemplateBuilderModel = () => {
    let organization = this.props.record.data.data;

    //invertion colours
    const invertPrimaryTextColor = get(
      organization,
      'template.value.invertPrimaryTextColor'
    );
    if (!invertPrimaryTextColor) {
      organization = update.set(
        organization,
        'template.value.invertPrimaryTextColor',
        false
      );
    }
    const invertSecondaryTextColor = get(
      organization,
      'template.value.invertSecondaryTextColor'
    );
    if (!invertSecondaryTextColor) {
      organization = update.set(
        organization,
        'template.value.invertSecondaryTextColor',
        false
      );
    }
    const model = new TemplateOptions().setModel(organization);

    const defaultAmounts = get(
      organization,
      'template.value.recurringDonationSetup.defaultAmounts'
    );
    if (!defaultAmounts) {
      model.setValue('template.value.recurringDonationSetup.defaultAmounts', [
        { isDefault: true, amount: 30, description: '', image: '' },
        { isDefault: false, amount: 50, description: '', image: '' },
        { isDefault: false, amount: 75, description: '', image: '' },
        { isDefault: false, amount: 100, description: '', image: '' }
      ]);
    }

    const useAsCustomOrganization = get(
      organization,
      'template.value.customDomain.useAsCustomOrganization'
    );
    if (!useAsCustomOrganization) {
      model.hide('template.value.customDomain.domain');
    }

    const allowCRMIntegration = get(
      organization,
      'template.value.allowCRMIntegration'
    );
    if (!allowCRMIntegration) {
      model.hide('crmPlatforms');
    }

    const country = get(organization, 'address.country');
    if (countries[country] !== countries.NZ) {
      model.hide('compliance');
    }

    const queryParams = parseQuery(this.props.location.search);
    if (queryParams.onboarding === 1) {
      model.setValue('isOnboarding', true);
      model.hide('recurring-giving');
      model.hide('advanced');
      model.hide('paymentPlatforms');
    } else {
      model.isEdit();
    }

    this.props.templateBuilderActions.setTemplateModel(model.getWithRule());
  };

  clearMessages = () => {
    this.props.organizationActions.clearRecordMessages('updateRecord');
    this.props.clearStripeRecordMessages('stripeConnectRecord');
  };

  handleOnSaveSuccess = () => {
    if (this.props.isOnboarding) {
      this.props.organizationActions.clearRecordMessages('updateRecord');
      this.props.templateBuilderActions.showControl('paymentPlatforms');
      this.props.history.push(
        `/organizations/edit/${this.props.match.params.id}/finance`
      );
    } else {
      this.props.isOrganizationAdmin && this.goToOrganisationsView();
    }
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.updateRecord);

    if (!Record.isRecordReady(this.props.record)) {
      return <Spinner />;
    }

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }

    return (
      <div>
        <RecordResultMessage
          record={this.props.updateRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.handleOnSaveSuccess}
        />

        <RecordResultMessage
          record={this.props.stripeConnectRecord}
          onDismiss={this.clearMessages}
          customSuccessMessage={I18n.t(
            'template.organisation.content.finance.stripe-success-message'
          )}
        />

        <h1>
          <Translate
            value={
              this.props.isOnboarding
                ? 'organization.organizationProfile'
                : 'organization.edit'
            }
          />
        </h1>
        <OrganizationTemplate onSave={this.onSave} isLoading={isSaving} />
      </div>
    );
  }
}

const mapState = ({ organization, templateBuilderEx, stripe, session }) => {
  return {
    template: organization.template,
    record: organization.record,
    updateRecord: organization.updateRecord,
    isTemplateInited: templateBuilderEx.inited,
    stripeConnectRecord: stripe.stripeConnectRecord,
    isOrganizationAdmin: session.isOrganizationAdmin,
    isOnboarding: templateBuilderEx.data.isOnboarding
  };
};

const mapDispatch = (dispatch) => {
  return {
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    organizationActions: bindActionCreators(organizationActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    clearStripeRecordMessages: bindActionCreators(clearRecordMessages, dispatch)
  };
};
const EditOrganizationContainer = clear(
  connect(mapState, mapDispatch)(EditOrganization)
);
export default EditOrganizationContainer;
