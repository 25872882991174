import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';

import * as accountSettingsActions from './reducer';

import VerticalMenu from './menu';
import AccountDetails from './detail';
import UpdatePassword from './updatePassword';
import EyesOnly from './eyesOnly';
import Security from './security';
import BasePageView from '../../../components/common/basePageView';
import { Grid, GridRow, GridColumn } from 'semantic-ui-react';

import { Record } from '../../../modules/bin/utility';
import clear from '../../../components/clear';
import get from 'lodash.get';

import { settingsMenuItems, i18nKeys } from '../constants';

import '../../../semantic/src/definitions/elements/image.less';
import EmailNotifications from './emailNotifications';

class AccountSettingsPage extends Component {
  componentDidUpdate(props) {
    if (this.props.match.params.id !== props.match.params.id) {
      this.props.getRecord(this.props.match.params.id);
    }
  }

  componentDidMount() {
    this.props.getRecord(this.props.match.params.id);
  }

  getSettingView() {
    switch (this.props.activeItem) {
      case settingsMenuItems.ACCOUNT_DETAILS:
        return (
          <AccountDetails
            onCancel={this.onCancel}
            goToPagesList={this.goToPagesList}
          />
        );
      case settingsMenuItems.UPDATE_PASSWORD:
        return (
          <div>
            <UpdatePassword
              onCancel={this.onCancel}
              goToPagesList={this.goToPagesList}
            />
          </div>
        );
      case settingsMenuItems.ACCOUNT_SECURITY:
        return (
          <div>
            <Security
              onCancel={this.onCancel}
              goToPagesList={this.goToPagesList}
            />
          </div>
        );
      case settingsMenuItems.EMAIL_NOTIFICATIONS:
        return (
          <EmailNotifications
            onCancel={this.onCancel} 
            goToPagesList={this.goToPagesList} />
        );
      case settingsMenuItems.EYES_ONLY:
        return (
          <EyesOnly
            onCancel={this.onCancel}
            goToPagesList={this.goToPagesList}
          />
        );
      default:
        return null;
    }
  }

  goToPagesList = () => {
    if (!this.props.isCampaignAdmin && !this.props.isEventAdmin) {
      this.props.history.push(
        `/accounts/${this.props.isSystemAdmin ? 'all' : 'admins'}`
      );
    }
  };

  onCancel = () => {
    this.goToPagesList();
  };

  render() {
    return (
      <BasePageView
        isLoading={this.props.isLoading}
        isLoadingError={this.props.isLoadingError}
        backLink={`/accounts/admins`}
        backLinkTitle="Back to accounts"
      >
        <h1>
          <Translate value={i18nKeys.ACCOUNT_SETTINGS_TITLE} />
        </h1>
        <Grid>
          <GridRow>
            <GridColumn width={5}>
              <VerticalMenu />
            </GridColumn>
            <GridColumn width={11}>{this.getSettingView()}</GridColumn>
          </GridRow>
        </Grid>
      </BasePageView>
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  const isSystemAdmin = get(session, 'isSystemAdmin');
  const isEventAdmin = get(session, 'isEventAdmin');

  return {
    isLoading: Record.isRecordLoading(accountSettings.record),
    isLoadingError: Record.isRecordLoadingError(accountSettings.record),
    activeItem: accountSettings.activeItem,
    accountData: accountSettings.record.data.data,
    isSystemAdmin,
    isEventAdmin
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(accountSettingsActions, dispatch);
};

const AccountSettingsPageContainer = withRouter(
  clear(connect(mapState, mapDispatch)(AccountSettingsPage))
);
export default AccountSettingsPageContainer;
