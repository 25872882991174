import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';

import { isArrayWithItems } from '../../../../helpers/arrayHelper';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

import { Record as RecordHelper } from '../../../../modules/bin/utility';
import { Loader } from 'semantic-ui-react';

import { summaryOptions, chartColors } from '../../constants';

am4core.useTheme(am4themes_animated);

class ChartView extends Component {
  componentDidMount() {
    this.getChart();
  }

  componentDidUpdate() {
    this.getChart();
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  getChart = () => {
    if (this.isAllRecordsReady()) {
      if (
        isArrayWithItems(this.props.models) &&
        this.props.models.some((model) =>
          isArrayWithItems(get(model.summaryDataRecord, 'data.data.list'))
        )
      ) {
        let chart = am4core.create('dashboard-chart', am4charts.XYChart);
        chart.paddingRight = 20;

        let chartData = [];
        this.props.models.forEach((model, index) => {
          const summaryData = get(model.summaryDataRecord, 'data.data.list');
          for (let i = 0; i < summaryData.length; i++) {
            chartData.push({
              [`date${index}`]: summaryData[i].date,
              [`value${index}`]: summaryData[i][this.props.summaryType]
            });
          }
        });

        chart.data = chartData;

        this.props.models.forEach((model, index) => {
          let dateAxis = chart.xAxes.push(new am4charts.DateAxis());
          dateAxis.renderer.grid.template.location = 0.0;
          dateAxis.periodChangeDateFormats.setKey('month', '[bold]yyyy');
          dateAxis.renderer.labels.template.fill = am4core.color(
            chartColors[index]
          );
          dateAxis.renderer.grid.template.strokeOpacity = 0.07;

          let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
          valueAxis.tooltip.disabled = true;
          valueAxis.renderer.minWidth = 35;

          let series = chart.series.push(new am4charts.LineSeries());
          series.name = model.name;
          series.dataFields.dateX = `date${index}`;
          series.dataFields.valueY = `value${index}`;
          series.strokeWidth = 2;
          series.fill = am4core.color(chartColors[index]);
          series.stroke = am4core.color(chartColors[index]);
          series.xAxis = dateAxis;

          const summaryOption = summaryOptions.find(
            (item) => item.key === this.props.summaryType
          );
          series.tooltipText = `${summaryOption.prefix}{valueY.value}`;
        });

        chart.cursor = new am4charts.XYCursor();
        chart.cursor.behavior = 'none';

        chart.legend = new am4charts.Legend();
        chart.legend.parent = chart.plotContainer;
        chart.legend.zIndex = 100;

        const $chartContainer = document.getElementById('dashboard-chart');
        if ($chartContainer) {
          const $amChartLogo = $chartContainer
            .getElementsByTagName('svg')[0]
            .getElementsByTagName('title')[0];
          if ($amChartLogo) {
            $amChartLogo.parentElement.style.visibility = 'hidden';
          }
        }

        this.chart = chart;
      }
    }
  };

  isAllRecordsReady = () => {
    if (isArrayWithItems(this.props.models)) {
      return this.props.models.every((model) =>
        RecordHelper.isRecordReady(model.summaryDataRecord)
      );
    }
    return true;
  };

  render() {
    const isLoading = !this.isAllRecordsReady();
    const noData =
      !isArrayWithItems(this.props.models) ||
      this.props.models.every(
        (model) =>
          !isArrayWithItems(get(model.summaryDataRecord, 'data.data.list'))
      );

    return (
      <React.Fragment>
        {!isLoading && !noData && <div id="dashboard-chart" />}
        {isLoading && (
          <div className="warning-container">
            <Loader active inline />
          </div>
        )}
        {!isLoading && noData && (
          <div className="warning-container no-items">
            {I18n.t('dashboard.top-area.chart.no-items')}
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapState = ({ dashboard }) => {
  return {
    summaryType: dashboard.summaryType,
    models: dashboard.compare.models
  };
};

const ChartContainer = connect(mapState, null)(ChartView);
export default ChartContainer;
