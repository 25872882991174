import React, { useEffect, useState } from 'react';
import { I18n } from 'react-redux-i18n';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Loader } from 'semantic-ui-react';

import * as stripeActions from './reducer';
import { Record } from '../../modules/bin/utility';
import { RootState } from '../../store';
import { RouteParams } from '../../containers/app';

const StripeConnector = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { operation } = useParams<RouteParams>();
  const [message, setMessage] = useState(I18n.t('stripe.connection-message'));

  const stripeConnectRecord = useSelector(
    (state: RootState) => state.stripe.stripeConnectRecord
  );

  const setMessageTimeout = (path: string, url: string, timeout = 10000) => {
    setMessage(I18n.t(path));
    setTimeout(() => history.push(url), timeout);
  };

  useEffect(() => {
    if (operation === 'connect' || operation === 'onboard') {
      connectWithStripe();
      return;
    } else if (operation === 'error') {
      setMessageTimeout('stripe.error-message', '/');
    } else {
      history.push('/');
    }
  }, []);

  useEffect(() => {
    if (Record.isRecordReady(stripeConnectRecord)) {
      if (stripeConnectRecord.data === 0) {
        setMessageTimeout('stripe.error-message', '/');
      } else {
        setMessageTimeout(
          'stripe.success-message',
          `/organizations/edit/${stripeConnectRecord.data}/finance`,
          3500
        );
      }
    }
  }, [stripeConnectRecord]);

  const connectWithStripe = () => {
    const { stripeConnect } = stripeActions;
    const searchParams = new URLSearchParams(location.search);
    const state = searchParams.get('state');
    const code = searchParams.get('code');
    const scope = searchParams.get('scope');

    // // old method where all 3 objects are available
    // if (!state || !code || !scope) {
    //   history.push('/');
    //   return;
    // }
    // // continue if only state is available

    dispatch(stripeConnect(state, scope, code));
  };

  return (
    <div>
      <Loader active content={message} />
    </div>
  );
};

export default StripeConnector;
