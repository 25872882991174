import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from 'semantic-ui-react';

import EditableImageUpload from '../editableImageUpload';
import * as templateHelper from '../../../components/template/templateHelper';
import { getFullImagePathByType } from '../../../constants/imageUpload';

class DonationImageUploadContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ imageError: false });
    }
  }

  imageLoadErrorHandler = (event) => {
    if (!this.state.imageError) {
      this.setState({ imageError: true });
    }
  };

  render() {
    const { ...pprops } = this.props;

    const showImage =
      this.props.url && !this.state.imageError ? (
        <img
          alt=""
          className="profile-image"
          onError={this.imageLoadErrorHandler}
          src={this.props.url}
        />
      ) : (
        ''
      );

    return (
      <Form.Field className="donation-amount-item-image-container">
        <EditableImageUpload {...pprops} showImage={showImage}>
          <div className="default-image-container">{showImage}</div>
          <div className="default-image-container-label">
            <p className="Links">{showImage ? 'Change' : 'Choose'}</p>
          </div>
        </EditableImageUpload>
      </Form.Field>
    );
  }
}

const mapState = (state, ownProps) => {
  const imagePath = templateHelper.getValue(
    state.templateBuilderEx,
    ownProps.path
  );
  const url = imagePath
    ? getFullImagePathByType(imagePath, ownProps.uploadType)
    : '';

  // Component configuration
  const path = ownProps.path;
  const format = ownProps.format;
  const position = ownProps.position;
  const uploadType = ownProps.uploadType;

  return { url, format, position, uploadType, path };
};

const DonationImageUpload = connect(mapState)(DonationImageUploadContainer);
export default DonationImageUpload;
