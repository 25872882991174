import {
  HtttpRequestMethods,
  sendRequest
} from '../../campaign/create/merchandise/api/request';
import { ExportHistoryUrls } from './constants';
import { exportFileNames } from '../../../constants/exportFiles';

export interface IDeleteExportHistoryArgs {
  id: number;
}

export async function downloadExportHistory(
  apikey: string,
  sessionKey: string,
  { id }: IDeleteExportHistoryArgs
): Promise<{}> {
  return sendRequest({
    apikey,
    sessionKey,
    url: `${ExportHistoryUrls.Download}${id}`,
    method: HtttpRequestMethods.Get,
    body: null
  }).then(async (response) => {
    const results = await response.json();
    const resourceUrl = results?.data?.resourceUrl;
    if (resourceUrl) {
      const link = document.createElement?.('a');
      link.href = resourceUrl;
      link.target = '_blank';
      link.setAttribute?.('download', exportFileNames.DEFAULT_NAME);
      document.body.appendChild?.(link);
      link?.click?.();
      link?.parentNode?.removeChild?.(link);
    }
    return {};
  });
}
