import config from '../constants';

export const extractVideoUrl = (url) => {
  let match =
    url.match(
      /^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtube\.com\/watch.*v=([a-zA-Z0-9_-]+)/
    ) ||
    url.match(/^(?:(https?):\/\/)?(?:(?:www|m)\.)?youtu\.be\/([a-zA-Z0-9_-]+)/);
  if (match) {
    return (
      (match[1] || 'https') +
      '://www.youtube.com/embed/' +
      match[2] +
      '?showinfo=0'
    );
  }
  if ((match = url.match(/^(?:(https?):\/\/)?(?:www\.)?vimeo\.com\/(\d+)/))) {
    // eslint-disable-line no-cond-assign
    return (
      (match[1] || 'https') + '://player.vimeo.com/video/' + match[2] + '/'
    );
  }
  return url;
};

export const createImageElement = (src) => {
  let wrapper = document.createElement('DIV');
  wrapper.className = 'content-image-cont-inner';
  let imageNode = document.createElement('IMG');
  imageNode.setAttribute(
    'src',
    `${config.storagePath.replace('{0}', src)}?w=500`
  );
  wrapper.appendChild(imageNode);
  return wrapper;
};

export const createVideoElement = (url) => {
  let wrapper = document.createElement('DIV');
  wrapper.className = 'content-video-cont-inner';
  let ifrm = document.createElement('iframe');
  ifrm.setAttribute('src', extractVideoUrl(url));
  ifrm.setAttribute('frameborder', '0');
  wrapper.appendChild(ifrm);

  return wrapper;
};
