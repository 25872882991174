import { get } from 'lodash';
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import { Button, Image, Modal } from 'semantic-ui-react';
import { getImagePath } from '../../../../helpers/imageUrlHelper';

class DigitalSignatureModal extends Component {
  render() {
    const { open, handleClose, record, translationKey } = this.props;
    const imagePath = get(record.data, 'data');
    const url = imagePath && getImagePath(imagePath);

    return (
      <Modal
        className="digital-signature-modal"
        size="tiny"
        dimmer="inverted"
        open={open}
      >
        <Modal.Header>
          <label>{I18n.t(`${translationKey}.title`)}</label>
        </Modal.Header>
        <Modal.Content image>
          <Image size="medium" centered src={url} wrapped />
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={handleClose}>
            {I18n.t(`${translationKey}.cancel`)}
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default DigitalSignatureModal;
