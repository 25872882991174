import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import get from 'lodash.get';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { treeActions } from '../../../modules/tree';
import { autocompleteOuterClear } from '../../../modules/autocomplete';

import { isArrayWithItems } from '../../../helpers/arrayHelper';
import {
  getAboutValidation,
  aboutHandler
} from '../../../helpers/pageAboutHelper';
import update from '../../../helpers/update';
import * as templateHelper from '../../../components/template/templateHelper';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import { TemplateDropdownTree } from '../../../components/template/templateTree';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateDropdown } from '../../../components/template/templateDropdown';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import TemplateEditableUpload from '../../../components/template/templateEditableUpload';
import { TemplateSearch } from '../../../components/template/templateSearch';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import TemplatePageManager from './templatePageManager';
import { TagsInput } from '../../../components/tagInput';
import { TemplateFormBuilderAnswers } from '../../../components/template/templateFormBuilderAnswers';
import { TemplateAbout } from '../../../components/template/templateAbout';
import { TemplateColorPicker } from '../../../components/template/templateColorPicker';
import TemplateAboutElementsBuilder from '../../../components/template/templateAboutElementsBuilder';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import { TemplateTimePicker } from '../../../components/template/templateTimePicker';
import { Form } from 'semantic-ui-react';

import {
  imageTypes,
  entityTypes,
  imageFormats
} from '../../../constants/imageUpload';
import { campaignContract } from '../create/templates';
import { TARGET_VALIDATION } from '../../../constants/validationModels';
import {
  pageTypes,
  FORMBUILDER_REGISTRATION_PATH,
  pageTypeStrings,
  invertionTextColourOptions,
  fitnessMeasureUnit,
  fitnessMeasure
} from '../constants';
import config from '../../../constants';

class FundraiserTemplateView extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.requiredMaxLength = {
      required: true,
      maxLength: true,
      length: config.URL_PATH_MAX_LENGTH
    };

    this.optional = {
      required: false,
      emailWarning: true
    };

    this.target = {
      required: false,
      ...TARGET_VALIDATION
    };

    this.formBuilderAnswers = {
      formBuilderRequired: true,
      formBuilderAnswers: true
    };

    this.aboutBuilderValidation = {
      aboutBuilder: true
    };

    this.photoPhormats = imageFormats;

    this.aboutValidation = getAboutValidation();

    this.optionalUrlValidation = {
      url: true
    };

    this.locationValidation = {
      maxLength: true,
      length: config.LOCATION_MAX_LENGTH
    };

    this.dateValidation = {
      date: true
    };

    this.timeDetailsValidation = {
      maxLength: true,
      length: config.TIME_DETAILS_MAX_LENGTH
    };

    this.replaceTextAbout();
  }

  onChange = (path, value, item) => {
    this.props.setResultValue(path, value);
  };

  getManagerRequest = () => {
    return {
      page: 1,
      pageSize: 3,
      resultType: 'Full',
      orderBy: {
        key: 'status',
        direction: 'asc'
      },
      filters: []
    };
  };

  getTeamRequest = () => {
    let filters = [
      {
        key: 'campaignId',
        operator: 'EqualTo',
        value: this.props.campaignId
      },
      {
        key: 'status',
        operator: 'EqualTo',
        value: 1
      }
    ];
    if (this.props.collectionId) {
      filters.push({
        key: 'collectionId',
        operator: 'EqualTo',
        value: this.props.collectionId
      });
    }
    return {
      page: 1,
      pageSize: 75,
      resultType: 'Full',
      orderBy: {
        key: 'name',
        direction: 'asc'
      },
      filters: filters
    };
  };

  getTeamItem = (item) => {
    return {
      text: item.name,
      subtext: `${item.urlPath}`,
      value: item.id
    };
  };

  getTypeOptions = () => {
    let array = [];

    if (this.props.isFundraiserEnabled) {
      array.push({
        text: 'template.fundraiser.content.detail.page-type.individual',
        value: pageTypes.FUNDRAISER,
        icon: 'user'
      });
    }

    if (this.props.isTeamEnabled) {
      array.push({
        text: 'template.fundraiser.content.detail.page-type.team',
        value: pageTypes.TEAM,
        icon: 'users'
      });
    }

    if (this.props.isCollectionsEnabled) {
      array.push({
        text: 'template.fundraiser.content.detail.page-type.collection',
        value: pageTypes.COLLECTION,
        icon: 'sitemap'
      });
    }

    return array;
  };

  showPageComponentByDefault = () => {
    this.props.changeItemVisibility('manager.email', true, false);
    this.props.changeItemVisibility('isPublic', true, false);

    this.props.clearTreeItem('parentId');
    this.props.hideControl('parentId');

    this.props.clearTreeItem('collectionId');
    this.props.changeItemVisibility(
      'collectionId',
      this.props.isCollectionsEnabled,
      true
    );

    this.props.changeItemVisibility(
      'template.value.heroImagePath',
      false,
      true
    );
    this.props.changeItemVisibility('coloursGroup', false, false);
    this.props.changeItemVisibility('coloursInvertionGroup', false, false);

    this.props.changeItemVisibility(
      'fitnessDistanceTarget',
      this.props.showFitnessDistanceTarget,
      true
    );
    this.props.changeItemVisibility(
      'fitnessTimeTarget',
      this.props.showFitnessTimeTarget,
      true
    );
    this.props.changeItemVisibility(
      'fitnessNumberOfStepsTarget',
      this.props.showFitnessNumberOfStepsTarget,
      true
    );

    this.props.changeItemVisibility(
      'customActivities.value1.target',
      this.props.showCustomActivityTarget,
      true
    );
  };

  onFundraiserSelect = () => {
    if (this.props.isTeamEnabled) {
      this.props.showControl('teamId');
    }
    this.showPageComponentByDefault();
    this.changeTimeAndPlaceVisibility(true);
  };

  onTeamSelect = () => {
    this.props.hideControl('teamId');
    this.showPageComponentByDefault();
    this.changeTimeAndPlaceVisibility(false);
  };

  onCollectionSelect = () => {
    this.props.hideControl('teamId');

    if (this.props.enableCollectionPageManager) {
      // this.props.showControl(campaignContract.allowTimeAndPlace);
    } else {
      this.props.hideControl('manager.email');
    }

    this.changeTimeAndPlaceVisibility(true);

    this.props.hideControl('isPublic');

    this.props.clearTreeItem('parentId');
    this.props.showControl('parentId');

    this.props.clearTreeItem('collectionId');
    this.props.hideControl('collectionId');

    this.props.changeItemVisibility('template.value.heroImagePath', true, true);
    this.props.changeItemVisibility('coloursGroup', true, false);
    this.props.changeItemVisibility('coloursInvertionGroup', true, false);

    this.props.changeItemVisibility(
      'fitnessDistanceTarget',
      this.props.showFitnessDistanceTarget,
      true
    );
    this.props.changeItemVisibility(
      'fitnessTimeTarget',
      this.props.showFitnessTimeTarget,
      true
    );
    this.props.changeItemVisibility(
      'fitnessNumberOfStepsTarget',
      this.props.showFitnessNumberOfStepsTarget,
      true
    );

    this.props.hideControl('customActivities.value1.target');
  };

  onTypeChange = (path, value) => {
    if (value === pageTypes.FUNDRAISER) {
      this.onFundraiserSelect();
    } else if (value === pageTypes.TEAM) {
      this.onTeamSelect();
    } else {
      this.onCollectionSelect();
    }

    const teamName =
      value === pageTypes.TEAM ? this.props.name : this.props.teamName;
    const collectionName =
      value === pageTypes.COLLECTION
        ? this.props.name
        : this.props.collectionName;

    this.props.setResultValue(
      'template.value.about',
      aboutHandler[value].getAbout(
        null,
        this.props.fundraiserDefault,
        this.props.teamDefault,
        this.props.collectionDefault,
        this.props.campaignDefault,
        this.props.amount,
        this.props.organisationName,
        this.props.managerName,
        teamName,
        collectionName
      )
    );

    this.onChange(path, value);
  };

  changeTimeAndPlaceVisibility = (value) => {
    this.props.changeItemVisibility(
      campaignContract.allowTimeAndPlace,
      value,
      true
    );
    this.props.changeItemVisibility('dateAndTime', false, true);
    this.props.changeItemVisibility(campaignContract.timeAndPlace, false, true);
    this.props.changeItemVisibility(campaignContract.timeExtraDetails, false, true);
    this.props.changeItemVisibility(campaignContract.location, false, true);
  };

  replaceTextAbout = (model) => {
    if (this.props.type) {
      this.props.setResultValue(
        'template.value.about',
        aboutHandler[this.props.type].getAbout(
          this.props.about,
          this.props.fundraiserDefault,
          this.props.teamDefault,
          this.props.collectionDefault,
          this.props.campaignDefault,
          this.props.amount,
          this.props.organisationName,
          this.props.managerName,
          this.props.teamName,
          this.props.collectionName,
          model
        )
      );
    }
  };

  onPageNameChange = (path, value) => {
    this.onChange(path, value);
    this.replaceTextAbout({
      amount: this.props.amount,
      organisationName: this.props.organisationName,
      firstName: this.props.managerName,
      teamName:
        this.props.type === pageTypes.TEAM ? value : this.props.teamName,
      collectionName:
        this.props.type === pageTypes.COLLECTION
          ? value
          : this.props.collectionName
    });
  };

  onTargetAmountChange = (path, value) => {
    this.onChange(path, value);
    this.replaceTextAbout({
      amount: value,
      organisationName: this.props.organisationName,
      firstName: this.props.managerName,
      teamName: this.props.teamName,
      collectionName: this.props.collectionName
    });
  };

  onFitnessTargetAmountChange = (key, value) => {
    const targetIndex = isArrayWithItems(this.props.fitnessTargets)
      ? this.props.fitnessTargets.findIndex((item) => item.key === key)
      : -1;

    if (targetIndex !== -1) {
      this.onChange(
        'fitnessSetup.targets',
        update(this.props.fitnessTargets, {
          [targetIndex]: {
            target: {
              $set: value
            }
          }
        })
      );
    } else {
      const newTarget = {
        key: key,
        target: value
      };

      if (isArrayWithItems(this.props.fitnessTargets)) {
        this.onChange(
          'fitnessSetup.targets',
          update(this.props.fitnessTargets, {
            $push: [newTarget]
          })
        );
      } else {
        this.onChange(
          'fitnessSetup.targets',
          update(this.props.fitnessTargets, {
            $set: [newTarget]
          })
        );
      }
    }
  };

  onPageManagerChange = (path, value, obj) => {
    if (obj) {
      this.onChange('manager.firstName', obj.item.firstName);

      this.replaceTextAbout({
        amount: this.props.amount,
        organisationName: this.props.organisationName,
        firstName: obj.item.firstName,
        teamName: this.props.teamName,
        collectionName: this.props.collectionName
      });
    }
    this.onChange(path, value);
  };

  clearManager = () => {
    this.props.clearControl('manager.email');
    this.changeManagerControlsVisibility();
  };

  changeManagerControlsVisibility = () => {
    this.props.changeItemVisibility('manager.email', true, false);
    this.props.changeItemVisibility('manager', false, false);
  };

  changeManager = () => {
    this.changeManagerControlsVisibility();
  };

  onParentCollectionChange = (item, path) => {
    const collectionId = get(item, 'id');
    this.onChange(path, collectionId);
  };

  onCollectionChange = (item, path) => {
    const collectionId = get(item, 'id');
    this.onChange(path, collectionId);
    this.onChange('collectionName', get(item, 'name'));
    this.onChange('teamId', null);
    this.props.clearAutocomplete('teamId');

    this.replaceTextAbout({
      amount: this.props.amount,
      organisationName: this.props.organisationName,
      firstName: this.props.managerName,
      teamName: this.props.teamName,
      collectionName: get(item, 'name')
    });
  };

  onTeamChange = (path, value, item) => {
    this.onChange(path, value);
    this.onChange('teamName', get(item, 'name'));
    this.onChange('collectionId', get(item, 'collectionId'));

    this.replaceTextAbout({
      amount: this.props.amount,
      organisationName: this.props.organisationName,
      firstName: this.props.managerName,
      teamName: get(item, 'name'),
      collectionName: this.props.collectionName
    });
  };

  getChildValidation = (path, validation) => {
    return {
      path,
      validation
    };
  };

  getCollectionTreeData = (path) => {
    this.props.getCollectionTree(this.props.campaignUrl, path);
  };

  onAllowTimeAndPlaceChange = (path, value) => {
    this.props.changeItemVisibility('dateAndTime', value, false);
    this.props.changeItemVisibility(campaignContract.timeAndPlace, value, true);
    this.props.changeItemVisibility(campaignContract.timeExtraDetails, value, true);
    this.props.changeItemVisibility(campaignContract.location, value, true);
    this.onChange(path, value);
  };

  getAboutElementsWidget = () => {
    if (this.props.type === pageTypes.COLLECTION) {
      return (
        <TemplateAboutElementsBuilder
          id="detailStory"
          path="template.value.about"
          label="template.fundraiser.content.detail.story.label"
          subtext="template.fundraiser.content.detail.story.subtext"
          onChange={this.onChange}
          entity={entityTypes.PAGE}
          validation={this.aboutBuilderValidation}
        />
      );
    } else {
      return (
        <TemplateAbout
          id="detailStory"
          validation={this.aboutValidation}
          path="template.value.about"
          label="template.fundraiser.content.detail.story.label"
          subtext="template.fundraiser.content.detail.story.subtext"
          onChange={this.onChange}
          entity={entityTypes.PAGE}
        />
      );
    }
  };

  handleCustomExtraFieldChange = (path, value) => {
    let customExtraFields = this.props.customExtraFields;

    const fieldIndex = customExtraFields.findIndex((x) => x.key === path);

    if (fieldIndex !== -1) {
      this.props.setResultValue(
        'template.value.customExtraFields',
        update(customExtraFields, {
          [fieldIndex]: {
            value: { $set: value }
          }
        })
      );
    }
  };

  getCustomExtraFieldValue = (path) => {
    const customExtraFields = this.props.customExtraFields;
    const fieldIndex = customExtraFields.findIndex((x) => x.key === path);

    if (fieldIndex !== -1) {
      return customExtraFields[fieldIndex].value;
    }

    return '';
  };

  render() {
    const aboutElementsWidget = this.getAboutElementsWidget();

    const isTargetsAvailable = isArrayWithItems(this.props.fitnessTargets);
    const fitnessDistanceTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.distance
      );
    const fitnessTimeTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.time
      );
    const fitnessNumberOfStepsTarget =
      isTargetsAvailable &&
      this.props.fitnessTargets.find(
        (target) => target.key === fitnessMeasure.numberOfSteps
      );

    return (
      <TemplateBuilderContainerEx
        onSave={this.props.onSave}
        isLoading={this.props.isLoading}
      >
        <TemplatePage
          id="page-detail"
          i18nKey="template.fundraiser.content.detail.header"
        >
          <TemplateHeader as="h3" label={this.props.header} />

          <TemplateInput
            id="detailPageName"
            path="name"
            label="template.fundraiser.content.detail.page-name.label"
            placeholder="template.fundraiser.content.detail.page-name.placeholder"
            subtext="template.fundraiser.content.detail.page-name.subtext"
            validation={this.requiredMaxLength}
            onChange={this.onPageNameChange}
          />

          <TemplateDropdown
            id="detailType"
            path="type"
            label="template.fundraiser.content.detail.page-type.label"
            validation={this.required}
            onChange={this.onTypeChange}
            getOptions={this.getTypeOptions}
          />

          <TemplateDropdownTree
            getData={this.getCollectionTreeData}
            label="template.fundraiser.content.detail.parent-collection.label"
            onChange={this.onParentCollectionChange}
            path="parentId"
            source="id"
          />

          <TemplateDropdownTree
            getData={this.getCollectionTreeData}
            label="template.fundraiser.content.detail.collection.label"
            subtext={`template.fundraiser.content.detail.collection.subtext.${pageTypeStrings[this.props.type]
              }`}
            onChange={this.onCollectionChange}
            path="collectionId"
          />

          <TemplateAutocomplete
            id="detailTeam"
            entity="page/team"
            path="teamId"
            selected="team"
            label="template.fundraiser.content.detail.team.label"
            placeholder="template.fundraiser.content.detail.team.placeholder"
            onChange={this.onTeamChange}
            request={this.getTeamRequest}
            getItem={this.getTeamItem}
          />

          <TemplateCurrency
            id="detailFundraisingTarget"
            path="targetAmount"
            validation={this.target}
            label="template.fundraiser.content.detail.fundraising-target.label"
            subtext="template.fundraiser.content.detail.fundraising-target.subtext"
            onChange={this.onTargetAmountChange}
          />

          <TemplateCurrency
            id="detailFitnessDistanceTarget"
            path="fitnessDistanceTarget"
            validation={this.target}
            inputLabel={fitnessMeasureUnit.distance}
            value={fitnessDistanceTarget ? fitnessDistanceTarget.target : ''}
            label="template.fundraiser.content.detail.fitness-distance-target.label"
            subtext="template.fundraiser.content.detail.fitness-distance-target.subtext"
            onChange={(path, value) =>
              this.onFitnessTargetAmountChange(fitnessMeasure.distance, value)
            }
          />

          <TemplateCurrency
            id="detailFitnessTimeTarget"
            path="fitnessTimeTarget"
            validation={this.target}
            inputLabel={fitnessMeasureUnit.time}
            value={fitnessTimeTarget ? fitnessTimeTarget.target : ''}
            label="template.fundraiser.content.detail.fitness-time-target.label"
            subtext="template.fundraiser.content.detail.fitness-time-target.subtext"
            onChange={(path, value) =>
              this.onFitnessTargetAmountChange(fitnessMeasure.time, value)
            }
          />

          <TemplateCurrency
            id="detailFitnessNumberOfStepsTarget"
            path="fitnessNumberOfStepsTarget"
            validation={this.target}
            inputLabel={fitnessMeasureUnit.numberOfSteps}
            value={
              fitnessNumberOfStepsTarget
                ? fitnessNumberOfStepsTarget.target
                : ''
            }
            label="template.fundraiser.content.detail.fitness-numberOfSteps-target.label"
            subtext="template.fundraiser.content.detail.fitness-numberOfSteps-target.subtext"
            onChange={(path, value) =>
              this.onFitnessTargetAmountChange(
                fitnessMeasure.numberOfSteps,
                value
              )
            }
          />

          <TemplateCurrency
            id="customActivitiesValue1Target"
            path="customActivities.value1.target"
            validation={this.target}
            inputLabel={this.props.customActivitiesValue1Unit || ' '}
            label="template.fundraiser.content.detail.customActivitiesValue1Target.label"
            subtext="template.fundraiser.content.detail.customActivitiesValue1Target.subtext"
            onChange={this.onChange}
          />

          <TemplateFormBuilderAnswers
            id="templateFormBuilderAnswers"
            pageType={this.props.type}
            path={FORMBUILDER_REGISTRATION_PATH}
            onChange={this.onChange}
            validation={this.formBuilderAnswers}
          />

          {aboutElementsWidget}

          <TemplateEditableUpload
            id="detailCampaignPhoto"
            path="mainImagePath"
            entity={entityTypes.PAGE}
            position="left"
            height="350"
            width="350"
            format={this.photoPhormats}
            uploadType={imageTypes.LOGO}
            label="template.fundraiser.content.detail.page-image.label"
            subtext="template.fundraiser.content.detail.page-image.subtext"
            onChange={this.onChange}
          />

          <TemplateEditableUpload
            id="detailHeroImage"
            path="template.value.heroImagePath"
            entity={entityTypes.PAGE}
            position="top"
            height="350"
            width="1500"
            format={this.photoPhormats}
            uploadType={imageTypes.HERO_IMAGE}
            label="template.fundraiser.content.detail.hero-image.label"
            subtext="template.fundraiser.content.detail.hero-image.subtext"
            onChange={this.onChange}
          />

          <Form.Group widths="equal" path="coloursGroup">
            <TemplateColorPicker
              id="setupPrimaryColour"
              path="template.value.style.primaryColor"
              label="template.fundraiser.content.detail.colour-primary.label"
              subtext="template.fundraiser.content.detail.colour-primary.subtext"
              onChange={this.onChange}
            />

            <TemplateColorPicker
              id="setupSecondaryColour"
              path="template.value.style.secondaryColor"
              label="template.fundraiser.content.detail.colour-secondary.label"
              subtext="template.fundraiser.content.detail.colour-secondary.subtext"
              onChange={this.onChange}
            />
          </Form.Group>
          <Form.Group widths="equal" path="coloursInvertionGroup">
            <TemplateDropdown
              id="setupInvertPrimaryTextColor"
              path="template.value.style.invertPrimaryTextColor"
              label="template.fundraiser.content.detail.invert-colors.invertPrimaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />

            <TemplateDropdown
              id="setupInvertSecondaryTextColor"
              path="template.value.style.invertSecondaryTextColor"
              label="template.fundraiser.content.detail.invert-colors.invertSecondaryTextColor.label"
              onChange={this.onChange}
              options={invertionTextColourOptions}
            />
          </Form.Group>

          <TemplateSearch
            id="detailPageManager"
            path="manager.email"
            entity="account"
            validation={this.optional}
            request={this.getManagerRequest}
            label="template.fundraiser.content.detail.page-manager.label"
            subtext="template.fundraiser.content.detail.page-manager.subtext"
            onChange={this.onPageManagerChange}
          />

          <TemplatePageManager
            id="templatePageManager"
            path="manager"
            onChange={this.changeManager}
            onClear={this.clearManager}
          />

          <TemplateCheckbox
            id="detailPublic"
            path="isPublic"
            inputLabel="template.fundraiser.content.detail.public.label"
            subtext="template.fundraiser.content.detail.public.subtext"
            onChange={this.onChange}
          />

          <TemplateInput
            path={campaignContract.coordinatorFirstName}
            label="template.page.content.setup.time-and-place.coordinatorFirstName.label"
            subtext="template.page.content.setup.time-and-place.coordinatorFirstName.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorFirstName
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorLastName}
            label="template.page.content.setup.time-and-place.coordinatorLastName.label"
            subtext="template.page.content.setup.time-and-place.coordinatorLastName.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorLastName
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorEmail}
            label="template.page.content.setup.time-and-place.coordinatorEmail.label"
            subtext="template.page.content.setup.time-and-place.coordinatorEmail.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorEmail
            )}
          />

          <TemplateInput
            path={campaignContract.coordinatorPhone}
            label="template.page.content.setup.time-and-place.coordinatorPhone.label"
            subtext="template.page.content.setup.time-and-place.coordinatorPhone.subtext"
            onChange={this.handleCustomExtraFieldChange}
            value={this.getCustomExtraFieldValue(
              campaignContract.coordinatorPhone
            )}
          />
        </TemplatePage>
        <TemplatePage
          id="advanced"
          i18nKey="template.fundraiser.content.advanced.header"
        >
          <TemplateHeader
            as="h3"
            label="template.fundraiser.content.advanced.tags.header"
          />

          <TagsInput path="tags" onChange={this.onChange} />

          <TemplateHorizontalRule path={campaignContract.allowTimeAndPlace} />
          <TemplateHeader
            as="h3"
            path={campaignContract.allowTimeAndPlace}
            label="template.fundraiser.content.advanced.time-and-place.header"
          />

          <TemplateCheckbox
            id={campaignContract.allowTimeAndPlace}
            path={campaignContract.allowTimeAndPlace}
            inputLabel="template.fundraiser.content.advanced.time-and-place.checkbox.label"
            onChange={this.onAllowTimeAndPlaceChange}
            translationObject={{ entity: pageTypeStrings[this.props.type] }}
          />

          <Form.Group
            widths="equal"
            path={campaignContract.timeAndPlace}
            validation={[
              this.getChildValidation(campaignContract.date, {
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              })
            ]}
          >
            <TemplateDatePicker
              id={campaignContract.date}
              path={campaignContract.date}
              label="template.fundraiser.content.advanced.time-and-place.date.label"
              subtext="template.fundraiser.content.advanced.time-and-place.date.subtext"
              subtextTranslationObject={{
                entity: pageTypeStrings[this.props.type]
              }}
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.time, null)}
              validation={{
                startDateRange: true,
                optionalParamPath: campaignContract.endDate
              }}
            />

            <TemplateTimePicker
              id={campaignContract.time}
              path={campaignContract.time}
              label="template.fundraiser.content.advanced.time-and-place.time.label"
              subtext="template.fundraiser.content.advanced.time-and-place.time.subtext"
              subtextTranslationObject={{
                entity: pageTypeStrings[this.props.type]
              }}
              onChange={this.onChange}
            />
          </Form.Group>

          <Form.Group widths="equal" path={campaignContract.timeAndPlace}>
            <TemplateDatePicker
              id={campaignContract.endDate}
              path={campaignContract.endDate}
              label="template.fundraiser.content.advanced.time-and-place.endDate.label"
              subtext="template.fundraiser.content.advanced.time-and-place.endDate.subtext"
              subtextTranslationObject={{
                entity: pageTypeStrings[this.props.type]
              }}
              onChange={this.onChange}
              onClear={() => this.onChange(campaignContract.endTime, null)}
              validation={{
                endDateRange: true,
                optionalParamPath: campaignContract.date
              }}
            />

            <TemplateTimePicker
              id={campaignContract.endTime}
              path={campaignContract.endTime}
              label="template.fundraiser.content.advanced.time-and-place.endTime.label"
              subtext="template.fundraiser.content.advanced.time-and-place.endTime.subtext"
              subtextTranslationObject={{
                entity: pageTypeStrings[this.props.type]
              }}
              onChange={this.onChange}
            />
          </Form.Group>

          <TemplateInput
            id={campaignContract.timeExtraDetails}
            path={campaignContract.timeExtraDetails}
            label="template.fundraiser.content.advanced.time-and-place.time-details.label"
            placeholder="template.fundraiser.content.advanced.time-and-place.time-details.placeholder"
            validation={this.timeDetailsValidation}
            onChange={this.onChange}
          />

          <TemplateInput
            id={campaignContract.location}
            path={campaignContract.location}
            label="template.fundraiser.content.advanced.time-and-place.place.label"
            subtext="template.fundraiser.content.advanced.time-and-place.place.subtext"
            subtextTranslationObject={{
              entity: pageTypeStrings[this.props.type]
            }}
            validation={this.locationValidation}
            onChange={this.onChange}
          />
        </TemplatePage>
        {this.props.isSystemAdmin && (
          <TemplatePage id="eyes-only" i18nKey="template.page.eyes-only">
            <TemplateHeader
              as="h3"
              path="template.value.forceRedirectTo"
              label="template.fundraiser.content.eyes-only.forceRedirect.header"
            />

            <TemplateInput
              id="template.value.forceRedirectTo"
              path="template.value.forceRedirectTo"
              label="template.fundraiser.content.eyes-only.forceRedirect.label"
              subtext="template.fundraiser.content.eyes-only.forceRedirect.subtext"
              subtextPosition="bottom"
              validation={this.optionalUrlValidation}
              onChange={this.onChange}
            />
          </TemplatePage>
        )}
      </TemplateBuilderContainerEx>
    );
  }
}

const mapState = (state) => {
  const showFitnessDistanceTarget = get(
    state.templateBuilderEx,
    'data.showFitnessDistanceTarget'
  );
  const showFitnessTimeTarget = get(
    state.templateBuilderEx,
    'data.showFitnessTimeTarget'
  );
  const showFitnessNumberOfStepsTarget = get(
    state.templateBuilderEx,
    'data.showFitnessNumberOfStepsTarget'
  );

  const fitnessTargets = get(
    state.templateBuilderEx,
    `data.fitnessSetup.targets`
  );

  const showCustomActivityTarget = get(
    state.templateBuilderEx,
    'data.showCustomActivityTarget'
  );
  const customActivitiesValue1Unit = get(
    state.templateBuilderEx,
    'data.customActivitiesValue1Unit'
  );

  const pageType = state.templateBuilderEx.data.type;
  const name = get(state.templateBuilderEx.data, 'name');
  const teamName =
    pageType === pageTypes.TEAM
      ? get(state.templateBuilderEx.data, 'name')
      : get(state.templateBuilderEx.data, 'teamName');
  const collectionName =
    pageType === pageTypes.COLLECTION
      ? get(state.templateBuilderEx.data, 'name')
      : get(state.templateBuilderEx.data, 'collectionName');

  return {
    type: pageType,
    campaignId: state.templateBuilderEx.data.campaignId,
    isTeamEnabled: state.templateBuilderEx.data.isTeamEnabled,
    isFundraiserEnabled: state.templateBuilderEx.data.isFundraiserEnabled,
    isCollectionsEnabled: state.templateBuilderEx.data.isCollectionsEnabled,
    collectionId: state.templateBuilderEx.data.collectionId,
    campaignUrl: state.templateBuilderEx.data.campaignUrl,
    campaignDefault:
      state.templateBuilderEx.data.defaultCampaignAbout ||
      get(state, 'templateBuilderEx.data.campaign.template.value.about'),
    teamDefault:
      state.templateBuilderEx.data.defaultTeamAbout ||
      get(
        state,
        'templateBuilderEx.data.campaign.template.value.defaultTeamAbout'
      ),
    fundraiserDefault:
      state.templateBuilderEx.data.defaultFundraiserAbout ||
      get(
        state,
        'templateBuilderEx.data.campaign.template.value.defaultFundraiserAbout'
      ),
    collectionDefault:
      state.templateBuilderEx.data.defaultCollectionAbout ||
      get(
        state,
        'templateBuilderEx.data.campaign.template.value.defaultCollectionAbout'
      ),
    about: get(state.templateBuilderEx.data, 'template.value.about'),
    amount: get(state.templateBuilderEx.data, 'targetAmount'),
    organisationName: get(
      state.campaignPage,
      'campaignRecord.data.data.organization.name'
    ) || get(state.templateBuilderEx, 'data.organization.name'),
    firstName: get(state.session, 'account.firstName'),
    name,
    teamName,
    collectionName,
    managerName: get(state.templateBuilderEx.data, 'manager.firstName'),
    enableCollectionPageManager: get(
      state.campaignPage,
      'campaignRecord.data.data.template.value.collectionSetup.enablePageManager'
    ),
    isSystemAdmin: get(state, 'session.isSystemAdmin'),
    customExtraFields:
      templateHelper.getValue(
        state.templateBuilderEx,
        'template.value.customExtraFields'
      ) || [],
    showFitnessTarget: get(state.templateBuilderEx.data, 'showFitnessTarget'),
    showFitnessDistanceTarget,
    showFitnessTimeTarget,
    showFitnessNumberOfStepsTarget,
    fitnessTargets,
    showCustomActivityTarget,
    customActivitiesValue1Unit
  };
};

const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(templateBuilderActions, dispatch),
    getCollectionTree(url, path) {
      dispatch(treeActions.getTreeRecord('page/collection', url, path));
    },
    clearAutocomplete(path) {
      dispatch(autocompleteOuterClear(path));
    },
    clearTreeItem(path) {
      dispatch(treeActions.clear(path));
    }
  };
};

const FundraiserTemplate = withRouter(
  connect(mapState, mapDispatch)(FundraiserTemplateView)
);
export default FundraiserTemplate;
