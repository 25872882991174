import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as recurringActions from './reducer';

import get from 'lodash.get';
import { I18n } from 'react-redux-i18n';

import { Common } from '../../components';
import MyUrlModal from './myUrlModal';

class RecurringPanelContainer extends React.Component {
  onSettingsClick = () => {
    if (this.props.isOrganizationAdmin) {
      const organizationId = get(this.props, 'organization.id');
      this.props.history.push(
        `/organization/edit/${organizationId}/donation-forms`
      );
    } else {
      this.props.history.push('/organizations');
    }
  };

  onMyURLClick = () => {
    this.props.showMyUrlModal();
  };

  render() {
    const { Grid } = Common;
    const { Panel } = Grid.Managed;

    return (
      <div>
        <Panel>
          {this.props.isOrganizationAdmin && (
            <Common.Button primary onClick={this.onMyURLClick} id="my-url">
              {I18n.t('recurring.panel.my-url.button')}
            </Common.Button>
          )}
          <Common.Button onClick={this.onSettingsClick} id="settings">
            {I18n.t('recurring.panel.settings.button')}
          </Common.Button>
        </Panel>
        <MyUrlModal />
      </div>
    );
  }
}

const mapState = ({ session, recurring }) => {
  return {
    isOrganizationAdmin: session.isOrganizationAdmin,
    organization: session.organization,
    isModalOpen: recurring.showMyUrlModal
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    showMyUrlModal() {
      dispatch(recurringActions.changeMyURLModalVisibility(true));
    }
  };
};

const RecurringPanel = withRouter(
  connect(mapState, mapDispatch)(RecurringPanelContainer)
);
export default RecurringPanel;
