import get from 'lodash.get';
import { updatePriorityRecord } from '../../../components/template/dependentOrganisations/helper';
import { generalFormBuilderPath } from '../../../constants/formBuilder';
import { gridKeys } from '../../../constants/gridKeys';
import { listReadyStateClear } from '../../../helpers/donationHelper';
import {
  getNewFormBuilderModel, needRemapFormbuilder
} from '../../../helpers/formBuilderHelper';
import update from '../../../helpers/update';
import * as entityHandlers from '../../../modules/bin/entityHandlers';
import { Record } from '../../../modules/bin/utility';
import { CLEAR, LIST_READY_STATE_CLEAR } from '../../../modules/general';
import { BULK_UPLOAD_DIALOG_TOGGLE, BULK_UPLOAD_STATUS_CHANGE, CREATE_FAILURE, CREATE_REQUESTED, CREATE_SUCCESS, ERROR_CLEAR, GET_EXPORT_FILE_FAILURE, GET_EXPORT_FILE_REQUESTED, GET_EXPORT_FILE_SUCCESS, GET_FAILURE, GET_REQUESTED, GET_SUCCESS, LIST_DATA_FAILURE, LIST_DATA_REQUESTED, LIST_DATA_SUCCESS, METADATA_FAILURE, METADATA_REQUESTED, METADATA_SUCCESS, TOGGLE_COLUMNS_CHANGE, UPDATE_FAILURE, UPDATE_REQUESTED, UPDATE_SUCCESS } from './eventTypes';
import {
  paths
} from './../constants';
import {
  getEventCampaignsGrid,
  getEventDonationsGrid,
  getEventFundraisingGrid, getEventsGrid, getTiersGrid
} from './../gridConfiguration';
import { CLONE_FAILURE, CLONE_REQUESTED, CLONE_SUCCESS, GET_ACTIVE_INTEGRATION_FAILURE, GET_ACTIVE_INTEGRATION_REQUESTED, GET_ACTIVE_INTEGRATION_SUCCESS, GRID_TAB_CHANGE, ON_PRIORITY_CHANGE, UPDATE_ACTIVE_INTEGRATION_FAILURE, UPDATE_ACTIVE_INTEGRATION_REQUESTED, UPDATE_ACTIVE_INTEGRATION_SUCCESS, UPDATE_EVENT_STATUS_FAILURE, UPDATE_EVENT_STATUS_REQUESTED, UPDATE_EVENT_STATUS_SUCCESS } from './../store/eventTypes';
  
const i18nEventListKey = 'event.list.header';
const i18nDonationListKey = 'donation.list.header';
const i18nCampaignListKey = 'campaign.list.header';
const i18nOrganizationTierListKey = 'event.organization-tier-list.header';

const initialState = {
  timestamp: new Date().getTime(),
  record: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  recordClone: Record.getDefaultState(),
  statusRecord: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  activeIntegration: Record.getDefaultState(),
  [gridKeys.EVENTS]: getEventsGrid(i18nEventListKey),
  [gridKeys.EVENT_ORGANIZATIONS]: getEventCampaignsGrid(i18nCampaignListKey),
  [gridKeys.EVENT_DONATIONS]: getEventDonationsGrid(i18nDonationListKey),
  [gridKeys.EVENT_FUNDRAISERS]: getEventFundraisingGrid(i18nCampaignListKey),
  [gridKeys.ORGANIZATIONS_TIER]: getTiersGrid(i18nOrganizationTierListKey),
  bulkOrgUpload: {
    dialogVisible: false,
    statusMessage: "",
    error: false,
  },
};

// State Reducer
const eventReducer = (state = initialState, action: { type: any; payload: { key: string | undefined; data: { data: any; }; id: any; value: any; }; }) => {
  let newState = state;
  switch (action.type) {
    case BULK_UPLOAD_DIALOG_TOGGLE:
      newState = {
        ...state,
        bulkOrgUpload: {
          ...state.bulkOrgUpload,
          dialogVisible: !state.bulkOrgUpload.dialogVisible,
        },
      };
      break;

    case BULK_UPLOAD_STATUS_CHANGE:
      newState = {
        ...state,
        bulkOrgUpload: {
          ...state.bulkOrgUpload,
          statusMessage: action.payload.value.message,
          error: action.payload.value.error || false,
        },
      };
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case GRID_TAB_CHANGE:
      newState = update.set(newState, paths.GRID_TAB_FILTER, action.payload);
      break;

    case CLEAR:
      newState = initialState;
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.EVENT_DONATIONS);
      newState = listReadyStateClear(newState, gridKeys.EVENT_FUNDRAISERS);
      newState = listReadyStateClear(newState, gridKeys.EVENT_ORGANIZATIONS);
      break;

    case CREATE_REQUESTED:
    case CLONE_REQUESTED:
    case UPDATE_REQUESTED:
    case UPDATE_EVENT_STATUS_REQUESTED:
    case UPDATE_ACTIVE_INTEGRATION_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
    case GET_ACTIVE_INTEGRATION_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case CREATE_SUCCESS:
    case CLONE_SUCCESS:
    case UPDATE_SUCCESS:
    case UPDATE_EVENT_STATUS_SUCCESS:
    case UPDATE_ACTIVE_INTEGRATION_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_ACTIVE_INTEGRATION_SUCCESS:
      const tempAction = {
        ...action,
        payload: {
          key: action.payload.key,
          data: action.payload.data.data
        }
      };
      newState = entityHandlers.getRecordSuccessHandler(
        state,
        tempAction,
        false
      );
      break;

    case CREATE_FAILURE:
    case CLONE_FAILURE:
    case UPDATE_FAILURE:
    case UPDATE_EVENT_STATUS_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
    case GET_ACTIVE_INTEGRATION_FAILURE:
    case UPDATE_ACTIVE_INTEGRATION_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case GET_REQUESTED:
      newState = entityHandlers.getRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
      newState = entityHandlers.getSuccessHandler(state, action);

      const formBuilderModel = get(
        newState,
        `record.data.data.${generalFormBuilderPath}`
      );
      // for backward compatibility with old structure of formbuilder
      if (needRemapFormbuilder(formBuilderModel)) {
        const newFormBuilderModel = getNewFormBuilderModel(formBuilderModel);
        newState = update.set(
          newState,
          `record.data.data.${generalFormBuilderPath}`,
          newFormBuilderModel
        );
      }
      break;

    case GET_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case ON_PRIORITY_CHANGE:
      newState = updatePriorityRecord(
        newState,
        gridKeys.ORGANIZATIONS_TIER,
        action.payload.id,
        action.payload.value
      );
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;
    default:
      return newState;
  }
  return newState;
};

export default eventReducer;
