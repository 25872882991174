import React from 'react';
import { Button, Modal } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { Translate } from 'react-redux-i18n';

/**
 * This React Component renders a modal which asks for approval to delete the
 * given component.
 * Renders two buttons for cancellation and confirmation.
 * @param {boolean} open - the Modal is displayed or not.
 * @param {function()} closeModal - handles closing the modal.
 * @param {function(formRoot: Object, node:Object)} deleteComponent - deletes the node.
 * @param {Object} formRoot - the root node of the FormBuilder.
 * @param {Object} node - node describing the current component.
 * @constructor
 */
const DeleteComponentModal = ({
  open,
  closeModal,
  deleteComponent,
  formRoot,
  node
}) => (
  <Modal
    className="wizard deleteComponentModal"
    size="mini"
    dimmer="inverted"
    onClose={closeModal}
    open={open}
  >
    <Modal.Header>
      <Translate value="components.section.remove-question.label" />
    </Modal.Header>
    <Modal.Content>
      <p>
        <Translate value="components.section.remove-question.description" />
      </p>
      <div className="button-wrapper align-right" style={{ marginTop: '10px' }}>
        <Button
          primary
          onClick={() => {
            deleteComponent(formRoot, node);
            closeModal();
          }}
          style={{ marginRight: '10px' }}
        >
          <Translate value="components.section.remove-question.confirm" />
        </Button>
        <Button onClick={closeModal}>
          <Translate value="components.section.remove-question.cancel" />
        </Button>
      </div>
    </Modal.Content>
  </Modal>
);

DeleteComponentModal.propTypes = {
  open: PropTypes.bool,
  closeModal: PropTypes.func,
  deleteComponent: PropTypes.func,
  formRoot: PropTypes.object,
  node: PropTypes.object
};

export default DeleteComponentModal;
