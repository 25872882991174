export const FitnessContract = {
  fitnessEnabled: 'fitnessSetup.enabled',

  fitnessTemplateView: 'fitnessTemplateView',
  fitnessSetup: 'fitnessSetup',
  fitnessTargets: 'fitnessSetup.targets',
  fitnessAllowedTypes: 'fitnessSetup.allowedTypes',
  fitnessAllowedPlatforms: 'fitnessSetup.allowedPlatforms',
  allowPageTracking: 'template.value.fitnessSetup.allowPageTracking',
  fitnessAllowedTrackedStatistics: 'fitnessSetup.allowedTrackedStatistics',
  fitnessThermometersToShow: 'template.value.fitnessSetup.thermometersToShow',
  fitnessLeaderboardsToShow: 'template.value.fitnessSetup.leaderboardsToShow',
  
  fitnessValidFrom: 'template.value.fitnessSetup.validFrom',
  fitnessValidTo: 'template.value.fitnessSetup.validTo',
  fitnessValidDateRule: 'template.value.fitnessSetup.validDateRule',

  fundraiserDates: "fitnessFundraiserDates",
  fundraiserDistanceTarget: "fitnessFundraiserDistanceTarget",
  fundraiserTimeTarget: "fitnessFundraiserTimeTarget",
  fundraiserNumberOfStepsTarget: "fitnessFundraiserNumberOfStepsTarget",
  fundraiserRepsTarget: "fitnessFundraiserRepsTarget",
  teamDistanceTarget: "fitnessTeamDistanceTarget",
  teamTimeTarget: "fitnessTeamTimeTarget",
  teamNumberOfStepsTarget: "fitnessTeamNumberOfStepsTarget",
  teamRepsTarget: "fitnessTeamRepsTarget",
};

export type FitnessContractKey = keyof typeof FitnessContract;
export const FitnessContractKeys = Object.keys(FitnessContract);
export const FitnessContractValues = Object.values(FitnessContract);