import React from "react";

import { GridOptions } from '../../../components/common/grid/gridOptions';
import { columnTypes } from '../../../components/common/grid/constants';
import { ColumnModel } from '../../../components/common/grid/columnModel';

import {
  programsAvailableFiltersKeys,
  applicationsAvailableFiltersKeys,
  pendingApprovalProgramsAvailableFiltersKeys,
  vipProgramsAvailableFiltersKeys,
  eventProgramsAvailableFiltersKeys,
  eventApplicationsAvailableFilterKeys,
  eventPendingApprovalProgramsAvailableFilterKeys,
  eventVipProgramsAvailableFilterKeys,
  campaignApplicationsAvailableFilterKeys,
  campaignPendingApprovalProgramsAvailableFilterKeys,
  campaignVIPProgramsAvailableFilterKeys,
  campaignProgramsAvailableFiltersKeys,
  eventAmbassadorStatusStyles
} from '../constants';
import { Icon } from 'semantic-ui-react';

export const getProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'event.name',
        `${translationKey}.event-name`
      ).visibleByDefault(),
      new ColumnModel('name', `${translationKey}.name`).setType(
        columnTypes.STRING
      ),
      new ColumnModel(
        'newEntriesEndAt',
        `${translationKey}.newEntriesEndAt`
      ).setType(columnTypes.DATE),
      new ColumnModel('endsAt', `${translationKey}.ends-at`)
        .setType(columnTypes.DATE)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.availableCount',
        `${translationKey}.metrics.availableCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.pendingCount',
        `${translationKey}.metrics.pendingCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.acceptedCount',
        `${translationKey}.metrics.acceptedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.claimedCount',
        `${translationKey}.metrics.claimedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.rejectedCount',
        `${translationKey}.metrics.rejectedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.ambassadorFundraisedAmount',
        `${translationKey}.metrics.ambassadorFundraisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault()
    ])
    .orderBy('metrics.ambassadorFundraisedAmount', 'desc')
    .addAvailableFiltersKeys(programsAvailableFiltersKeys)
    .getState();
};

export const getApplicationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(applicationsAvailableFiltersKeys)
    .getState();
};

export const getPendingApprovalProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(pendingApprovalProgramsAvailableFiltersKeys)
    .getState();
};

export const getVIPProgramGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(vipProgramsAvailableFiltersKeys)
    .getState();
};

export const getEventProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel(
        'availability.newEntries',
        `${translationKey}.application-status`,
        (value) => (
          <div
            style={{
              width: "100%",
              textAlign: "right",
            }}
          >
            <Icon
              name="circle"
              style={{
                color: value ? "#56B68B" : "#757575",
              }}
            />
            {value ? "Open" : "Closed"}
          </div>
        )
      )
        .setType(columnTypes.BOOLEAN)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.availableCount',
        `${translationKey}.metrics.availableCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.pendingCount',
        `${translationKey}.metrics.pendingCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.acceptedCount',
        `${translationKey}.metrics.acceptedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.claimedCount',
        `${translationKey}.metrics.claimedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.rejectedCount',
        `${translationKey}.metrics.rejectedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.ambassadorFundraisedAmount',
        `${translationKey}.metrics.ambassadorFundraisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault()
    ])
    .orderBy('metrics.ambassadorFundraisedAmount', 'desc')
    .addAvailableFiltersKeys(eventProgramsAvailableFiltersKeys)
    .getState();
};

export const getEventApplicationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(eventApplicationsAvailableFilterKeys)
    .getState();
};

export const getEventPendingApprovalProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(eventPendingApprovalProgramsAvailableFilterKeys)
    .getState();
};

export const getEventVipProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(eventVipProgramsAvailableFilterKeys)
    .getState();
};

export const getCampaignProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel(
        'campaign.name',
        `${translationKey}.campaign-name`
      ).visibleByDefault(),
      new ColumnModel('organization.name', `${translationKey}.program-name`),
      new ColumnModel(
        'metrics.availableCount',
        `${translationKey}.metrics.availableCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.pendingCount',
        `${translationKey}.metrics.pendingCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.acceptedCount',
        `${translationKey}.metrics.acceptedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.claimedCount',
        `${translationKey}.metrics.claimedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.rejectedCount',
        `${translationKey}.metrics.rejectedCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'metrics.ambassadorFundraisedAmount',
        `${translationKey}.metrics.ambassadorFundraisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault()
    ])
    .orderBy('metrics.ambassadorFundraisedAmount', 'desc')
    .addAvailableFiltersKeys(campaignProgramsAvailableFiltersKeys)
    .getState();
};

export const getCampaignApplicationsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(campaignApplicationsAvailableFilterKeys)
    .getState();
};

export const getCampaignPendingApprovalProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ).visibleByDefault(),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(campaignPendingApprovalProgramsAvailableFilterKeys)
    .getState();
};

export const getCampaignVipProgramsGrid = (translationKey) => {
  return new GridOptions()
    .addColumns([
      new ColumnModel('firstName', `${translationKey}.firstName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('lastName', `${translationKey}.lastName`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel(
        'organization.name',
        `${translationKey}.organization-name`
      ),
      new ColumnModel('createdAtLocal', `${translationKey}.createdAtLocal`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.raisedAmount',
        `${translationKey}.fundraiser.raisedAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'fundraiser.targetAmount',
        `${translationKey}.fundraiser.targetAmount`
      )
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('email', `${translationKey}.email`)
        .setType(columnTypes.STRING)
        .visibleByDefault(),
      new ColumnModel('status', `${translationKey}.status`)
        .setType(columnTypes.BADGE)
        .visibleByDefault()
        .setStyles(eventAmbassadorStatusStyles),
      new ColumnModel('campaign.name', `${translationKey}.campaign-name`),
      new ColumnModel('event.name', `${translationKey}.event-name`),
      new ColumnModel(
        'lastUpdatedAtLocal',
        `${translationKey}.lastUpdatedAtLocal`
      ).setType(columnTypes.DATETIME)
    ])
    .orderBy('fundraiser.raisedAmount', 'desc')
    .addAvailableFiltersKeys(campaignVIPProgramsAvailableFilterKeys)
    .getState();
};
