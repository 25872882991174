let CustomVideoBlotExport;

if (typeof document !== 'undefined') {
  let ReactQuill = require('react-quill');
  let BlockEmbed = ReactQuill.Quill.import('blots/embed');

  CustomVideoBlotExport = class VideoBlot extends BlockEmbed {
    static create(value) {
      let node = super.create();
      if (value && value.length) {
        if (value[0] && value[0].tagName === 'IFRAME') {
          node.appendChild(value[0]);
        } else {
          node.appendChild(value[0].children[0]);
        }
      }
      return node;
    }

    static value(domNode) {
      return domNode.children;
    }
  };
  CustomVideoBlotExport.blotName = 'custom-video';
  CustomVideoBlotExport.tagName = 'div';
  CustomVideoBlotExport.className = 'content-video-cont-inner';
}

export default CustomVideoBlotExport;
