export function useCopyToClipboard(text: string) {
  async function copy() {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand('copy', true, text);
    }
  }
  return {
    copy
  };
}