import React, { useEffect, useRef, useState } from 'react';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { Popup } from 'semantic-ui-react';

interface ICurrencyInputProps {
  value?: number | string;
  maxValue?: number;
  minValue?: number;
  setValue: (num: number) => void;
}

const convertToNumber = (num?: number | string) => {
  const value = Number(num);
  if (isNaN(value)) return 0;
  return value;
};

export const CurrencyInput = ({
  value,
  maxValue,
  minValue,
  setValue
}: ICurrencyInputProps) => {
  const inputRef = useRef(null);
  const num = convertToNumber(value);
  const isError = minValue && num < minValue;
  const [displayValue, setDisplayValue] = useState(num?.toFixed(2));

  const onChange = (data: NumberFormatValues) => {
    const floatValue = data.floatValue || 0;
    let isAllowed = true;
    if (maxValue) {
      isAllowed = floatValue <= maxValue;
    }
    return isAllowed;
  };

  useEffect(() => {
    setDisplayValue(num?.toFixed(2));
  }, [value]);

  const component = (
    <div className={`ui input${isError ? ' error' : ''}`}>
      <NumberFormat
        ref={inputRef}
        value={displayValue}
        onBlur={(event: any) => {
          const numberString = event.target.value.substring(1).replace(',', '');
          setValue(Number(numberString));
        }}
        prefix="$"
        displayType="input"
        type="text"
        isAllowed={onChange}
        thousandSeparator
        allowNegative={false}
        decimalScale={2}
      />
    </div>
  );

  if (isError) {
    return (
      <Popup
        content={`Value must be greater than $${minValue?.toFixed(2)}`}
        trigger={component}
      />
    );
  }
  return component;
};
