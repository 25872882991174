import { Record } from '../../modules/bin/utility';
import update from '../../helpers/update';
import * as entityHandlers from '../../modules/bin/entityHandlers';
import get from 'lodash.get';
import config from '../../constants';
import {
  getQuestionsFromModel,
  getCustomFieldsWithAnswers
} from '../../helpers/formBuilderHelper';
import {
  saleContract,
  getTicketContract,
  getMerchandiseContract
} from './ticketingContracts';
import { getDataWithQuestionFields } from '../../helpers/dataCaptureHelper';

import { CLEAR, LIST_READY_STATE_CLEAR } from '../../modules/general';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS,
  IMPERSONATE_ADMIN_SUCCESS
} from '../../modules/session';

import {
  generalFormBuilderPath,
  fieldCategoryKey
} from '../../constants/formBuilder';
import { isArrayWithItems } from '../../helpers/arrayHelper';
import { gridKeys } from '../../constants/gridKeys';
import { gridTabs } from './constants';
import { listReadyStateClear } from '../../helpers/donationHelper';
import { getSalesGrid, getTicketsGrid } from './gridConfiguration';
import { pageTypesStrings } from '../../constants/pageTypes';

const ON_VALUE_CHANGE = 'ticketing/ON_VALUE_CHANGE';

export const SALE_REFUND_REQUESTED = 'ticketing/SALE_REFUND_REQUESTED';
export const SALE_REFUND_SUCCESS = 'ticketing/SALE_REFUND_SUCCESS';
export const SALE_REFUND_FAILURE = 'ticketing/SALE_REFUND_FAILURE';

export const SALE_REFUND_AND_CANCEL_REQUESTED =
  'ticketing/SALE_REFUND_AND_CANCEL_REQUESTED';
export const SALE_REFUND_AND_CANCEL_SUCCESS =
  'ticketing/SALE_REFUND_AND_CANCEL_SUCCESS';
export const SALE_REFUND_AND_CANCEL_FAILURE =
  'ticketing/SALE_REFUND_AND_CANCEL_FAILURE';

export const TICKET_REFUND_REQUESTED = 'ticketing/TICKET_REFUND_REQUESTED';
export const TICKET_REFUND_SUCCESS = 'ticketing/TICKET_REFUND_SUCCESS';
export const TICKET_REFUND_FAILURE = 'ticketing/TICKET_REFUND_FAILURE';

export const REFUND_CONFIRMATION_SHOW = 'ticketing/REFUND_CONFIRMATION_SHOW';
export const REFUND_CONFIRMATION_HIDE = 'ticketing/REFUND_CONFIRMATION_HIDE';

export const RECEIPT_CONFIRMATION_SHOW = 'ticketing/RECEIPT_CONFIRMATION_SHOW';
export const RECEIPT_CONFIRMATION_HIDE = 'ticketing/RECEIPT_CONFIRMATION_HIDE';

export const SALE_RECEIPT_REQUESTED = 'ticketing/SALE_RECEIPT_REQUESTED';
export const SALE_RECEIPT_SUCCESS = 'ticketing/SALE_RECEIPT_SUCCESS';
export const SALE_RECEIPT_FAILURE = 'ticketing/SALE_RECEIPT_FAILURE';

export const TICKET_RECEIPT_REQUESTED = 'ticketing/TICKET_RECEIPT_REQUESTED';
export const TICKET_RECEIPT_SUCCESS = 'ticketing/TICKET_RECEIPT_SUCCESS';
export const TICKET_RECEIPT_FAILURE = 'ticketing/TICKET_RECEIPT_FAILURE';

export const GET_SALE_REQUESTED = 'ticketing/GET_SALE_REQUESTED';
export const GET_SALE_SUCCESS = 'ticketing/GET_SALE_SUCCESS';
export const GET_SALE_FAILURE = 'ticketing/GET_SALE_FAILURE';

// prettier-ignore
export const GET_SALE_RELATED_REQUESTED = 'ticketing/GET_SALE_RELATED_REQUESTED';
export const GET_SALE_RELATED_SUCCESS = 'ticketing/GET_SALE_RELATED_SUCCESS';
export const GET_SALE_RELATED_FAILURE = 'ticketing/GET_SALE_RELATED_FAILURE';

export const GET_TICKETS_REQUESTED = 'ticketing/GET_TICKETS_REQUESTED';
export const GET_TICKETS_SUCCESS = 'ticketing/GET_TICKETS_SUCCESS';
export const GET_TICKETS_FAILURE = 'ticketing/GET_TICKETS_FAILURE';

export const CREATE_REQUESTED = 'ticketing/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'ticketing/CREATE_SUCCESS';
export const CREATE_FAILURE = 'ticketing/CREATE_FAILURE';

export const UPDATE_REQUESTED = 'ticketing/UPDATE_REQUESTED';
export const UPDATE_SUCCESS = 'ticketing/UPDATE_SUCCESS';
export const UPDATE_FAILURE = 'ticketing/UPDATE_FAILURE';

export const GET_CAMPAIGN_REQUESTED = 'ticketing/GET_CAMPAIGN_REQUESTED';
export const GET_CAMPAIGN_SUCCESS = 'ticketing/GET_CAMPAIGN_SUCCESS';
export const GET_CAMPAIGN_FAILURE = 'ticketing/GET_CAMPAIGN_FAILURE';

export const GET_AVAILABLE_TICKETS_REQUESTED =
  'ticketing/GET_AVAILABLE_TICKETS_REQUESTED';
export const GET_AVAILABLE_TICKETS_SUCCESS =
  'ticketing/GET_AVAILABLE_TICKETS_SUCCESS';
export const GET_AVAILABLE_TICKETS_FAILURE =
  'ticketing/GET_AVAILABLE_TICKETS_FAILURE';

export const COUPON_CREATE_REQUESTED = 'ticketing/COUPON_CREATE_REQUESTED';
export const COUPON_CREATE_SUCCESS = 'ticketing/COUPON_CREATE_SUCCESS';
export const COUPON_CREATE_FAILURE = 'ticketing/COUPON_CREATE_FAILURE';

export const TICKET_CREATE_REQUESTED = 'ticketing/TICKET_CREATE_REQUESTED';
export const TICKET_CREATE_SUCCESS = 'ticketing/TICKET_CREATE_SUCCESS';
export const TICKET_CREATE_FAILURE = 'ticketing/TICKET_CREATE_FAILURE';

export const METADATA_REQUESTED = 'ticketing/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'ticketing/METADATA_SUCCESS';
export const METADATA_FAILURE = 'ticketing/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'ticketing/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'ticketing/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'ticketing/LIST_DATA_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'ticketing/TOGGLE_COLUMNS_CHANGE';
export const ACTIVE_TAB_SET = 'ticketing/ACTIVE_TAB_SET';

export const IS_REFUNDED_CLEAR = 'ticketing/IS_REFUNDED_CLEAR';
export const ERROR_CLEAR = 'ticketing/ERROR_CLEAR';
export const RECEIPT_MESSAGE_ERROR = 'ticketing/RECEIPT_MESSAGE_ERROR';

export const GET_EXPORT_FILE_REQUESTED = 'ticketing/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'ticketing/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'ticketing/GET_EXPORT_FILE_FAILURE';

const i18nSalesListKey = 'sale.list.header';
const i18nTicketsListKey = 'ticket.list.header';

const initialState = {
  selectedView: gridTabs.SALES,
  record: Record.getDefaultState(),
  saveRecord: Record.getDefaultState(),
  ticketsRecord: Record.getDefaultState(),
  relatedRecord: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  [gridKeys.SALES]: getSalesGrid(i18nSalesListKey),
  [gridKeys.TICKETS]: getTicketsGrid(i18nTicketsListKey),
  sale: {} as any
};

export default (state = initialState, action: any) => {
  let newState = state;

  switch (action.type) {
    case REFUND_CONFIRMATION_SHOW:
    case RECEIPT_CONFIRMATION_SHOW:
      newState = update.set(
        newState,
        action.payload.recordPath,
        action.payload.value
      );
      newState = update.set(newState, action.payload.confirmationPath, true);
      break;

    case RECEIPT_CONFIRMATION_HIDE:
    case REFUND_CONFIRMATION_HIDE:
      newState = update.set(newState, action.payload.confirmationPath, false);
      break;

    case GET_SALE_REQUESTED:
    case SALE_REFUND_AND_CANCEL_REQUESTED:
    case GET_TICKETS_REQUESTED:
    case CREATE_REQUESTED:
    case UPDATE_REQUESTED:
    case SALE_REFUND_REQUESTED:
    case TICKET_REFUND_REQUESTED:
    case SALE_RECEIPT_REQUESTED:
    case TICKET_RECEIPT_REQUESTED:
    case GET_CAMPAIGN_REQUESTED:
    case GET_AVAILABLE_TICKETS_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
    case GET_SALE_RELATED_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SALE_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);

      const sale = get(newState, 'record.data.data') as any;
      //sale
      const salePhoneNumber = get(sale, 'customer.phoneNumber');
      const saleAddress = get(sale, 'customer.address');
      const saleQuestions = getQuestionsFromModel(
        get(sale, `campaign`),
        fieldCategoryKey.SALES
      );
      const saleFields = get(sale, 'fields');
      const saleCustomFieldsWithAnswers = getCustomFieldsWithAnswers(
        saleQuestions,
        saleFields,
        salePhoneNumber,
        saleAddress
      );

      newState = update.set(
        newState,
        `record.data.data.${saleContract.formbuilder}`,
        saleCustomFieldsWithAnswers
      );

      //tickets & merchandise
      if (sale && isArrayWithItems(sale.details)) {
        for (let index = 0; index < sale.details.length; index++) {
          const phoneNumber = get(
            sale,
            `details.${index}.customer.phoneNumber`
          );
          const address = get(sale, `details.${index}.customer.address`);
          const fields = get(sale, `details.${index}.fields`);
          if (get(sale, `details.${index}.ticket`)) {
            const ticketContract = getTicketContract(index);
            const ticketQuestions = get(sale, ticketContract.formbuilder);
            const ticketCustomFieldsWithAnswers = getCustomFieldsWithAnswers(
              ticketQuestions,
              fields,
              phoneNumber,
              address
            );
            newState = update.set(
              newState,
              `record.data.data.${ticketContract.formbuilder}`,
              ticketCustomFieldsWithAnswers
            );
          }
          if (get(sale, `details.${index}.merchandiseSku`)) {
            const merchandiseContract = getMerchandiseContract(index);
            const merchandiseQuestions = get(
              sale,
              merchandiseContract.formbuilder
            );
            const merchandiseCustomFieldsWithAnswers =
              getCustomFieldsWithAnswers(
                merchandiseQuestions,
                fields,
                phoneNumber,
                address
              );
            newState = update.set(
              newState,
              `record.data.data.${merchandiseContract.formbuilder}`,
              merchandiseCustomFieldsWithAnswers
            );
          }
        }
      }
      break;

    case GET_TICKETS_SUCCESS:
    case UPDATE_SUCCESS:
    case CREATE_SUCCESS:
    case GET_CAMPAIGN_SUCCESS:
    case GET_AVAILABLE_TICKETS_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
    case GET_SALE_RELATED_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case GET_SALE_RELATED_FAILURE:
    case GET_SALE_FAILURE:
    case GET_TICKETS_FAILURE:
    case CREATE_FAILURE:
    case UPDATE_FAILURE:
    case GET_CAMPAIGN_FAILURE:
    case GET_AVAILABLE_TICKETS_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case SALE_REFUND_SUCCESS:
    case SALE_REFUND_AND_CANCEL_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'sale.refundConfirmation', false);
      newState = update.set(newState, 'sale.isRefunded', true);
      break;

    case TICKET_REFUND_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'ticket.refundConfirmation', false);
      newState = update.set(newState, 'ticket.isRefunded', true);
      break;

    case SALE_REFUND_FAILURE:
    case SALE_REFUND_AND_CANCEL_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'sale.refundConfirmation', false);
      break;

    case SALE_REFUND_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'ticket.refundConfirmation', false);
      break;

    case SALE_RECEIPT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'sale.reissueConfirmation', false);
      break;

    case TICKET_RECEIPT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'ticket.reissueConfirmation', false);
      break;

    case SALE_RECEIPT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'sale.reissueConfirmation', false);
      break;

    case SALE_RECEIPT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      newState = update.set(newState, 'ticket.reissueConfirmation', false);
      break;

    case IS_REFUNDED_CLEAR:
      newState = update.set(
        newState,
        `${action.payload.entity}.isRefunded`,
        false
      );
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS:
      newState = initialState;
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    // case LINKED_ENTITY_FAILURE
    //   ...
    //   break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case ACTIVE_TAB_SET:
      newState = update(newState, { selectedView: { $set: action.payload } });
      break;

    case RECEIPT_MESSAGE_ERROR:
      newState = entityHandlers.messageErrorHandler(state, action, [
        'receipt.absentEmail'
      ]);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.SALES);
      newState = listReadyStateClear(newState, gridKeys.TICKETS);
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    default:
      return newState;
  }

  return newState;
};

export const onValueChange = (path: string, value: any) => {
  return {
    type: ON_VALUE_CHANGE,
    payload: {
      path,
      value
    }
  };
};

export const showReceiptConfirmation = (item: any, entity: string) => {
  return {
    type: RECEIPT_CONFIRMATION_SHOW,
    payload: {
      recordPath: `${entity}.reissueRecord`,
      confirmationPath: `${entity}.reissueConfirmation`,
      value: item
    }
  };
};

export const hideReceiptConfirmation = (entity: string) => {
  return {
    type: RECEIPT_CONFIRMATION_HIDE,
    payload: {
      confirmationPath: `${entity}.reissueConfirmation`
    }
  };
};

export const showRefundConfirmation = (item: any, entity: string) => {
  return {
    type: REFUND_CONFIRMATION_SHOW,
    payload: {
      recordPath: `${entity}.refundRecord`,
      confirmationPath: `${entity}.refundConfirmation`,
      value: item
    }
  };
};

export const closeRefundConfirmation = (entity: string) => {
  return {
    type: REFUND_CONFIRMATION_HIDE,
    payload: {
      confirmationPath: `${entity}.refundConfirmation`
    }
  };
};

export const clearIsRefunded = (entity: string) => ({
  type: IS_REFUNDED_CLEAR,
  payload: { entity: entity }
});

export const clearRecordMessages = (record: string) => ({
  type: ERROR_CLEAR,
  payload: { key: record }
});

export const refundAndCancelSale = (id: string, refundAmount?: number, verificationToken?: string) => {
  return entityHandlers.deleteRecord(
    'sale',
    SALE_REFUND_AND_CANCEL_REQUESTED,
    SALE_REFUND_AND_CANCEL_SUCCESS,
    SALE_REFUND_AND_CANCEL_FAILURE,
    refundAmount ? `${id}?refundAmount=${refundAmount}` : id,
    'saveRecord',
    verificationToken || null,
  );
};

export const callActionsAndAwait = (actions: Array<Function>) => {
  return async (dispatch: any, getState: any) => {
    const results = await Promise.all(
      actions.map((action) => action(dispatch, getState))
    );
    dispatch({
      type: 'ACTIONS_CALLED_AND_AWAITED',
      payload: results
    });
  };
};

export const refundInstallment = (
  id: string,
  installmentIds: Array<string | number>,
  verificationToken?: string,
) => {
  const promises = installmentIds.map((installmentId) =>
    entityHandlers.executeRecordRequest(
      `${config.baseApiHost}/api/v2/Sale/${id}/installment/${installmentId}/refund`,
      'POST',
      SALE_REFUND_REQUESTED,
      SALE_REFUND_SUCCESS,
      SALE_REFUND_FAILURE,
      'saveRecord',
      null,
      verificationToken || null,
    )
  );
  return callActionsAndAwait(promises);
};

export const cancelInstallment = (id: string, verificationToken?: string) => {
  return entityHandlers.executeRecordRequest(
    `${config.baseApiHost}/api/Sale/${id}?refund=false`,
    'DELETE',
    SALE_REFUND_REQUESTED,
    SALE_REFUND_SUCCESS,
    SALE_REFUND_FAILURE,
    'saveRecord',
    null,
    verificationToken || null,
  );
};

export const cancelAndRefundInstallment = (
  id: string,
  installmentIds: Array<string | number>,
  verificationToken?: string,
) => {
  const promises = installmentIds.map((installmentId) =>
    entityHandlers.executeRecordRequest(
      `${config.baseApiHost}/api/v2/Sale/${id}/installment/${installmentId}/refund`,
      'POST',
      SALE_REFUND_REQUESTED,
      SALE_REFUND_SUCCESS,
      SALE_REFUND_FAILURE,
      'saveRecord',
      null,
      verificationToken || null,
    )
  );

  promises.push(cancelInstallment(id));

  return callActionsAndAwait(promises);
};

export const refundTicket = (id: string) => {
  return entityHandlers.deleteRecord(
    'saleDetail',
    TICKET_REFUND_REQUESTED,
    TICKET_REFUND_SUCCESS,
    TICKET_REFUND_FAILURE,
    id,
    'saveRecord'
  );
};

export const refundSale = (id: string, refundAmount: number, verificationToken?: string) => {
  return entityHandlers.executeRecordRequest(
    `${config.baseApiHost}/api/v2/sale/${id}/refund`,
    'POST',
    SALE_REFUND_REQUESTED,
    SALE_REFUND_SUCCESS,
    SALE_REFUND_FAILURE,
    'saveRecord',
    {
      refundAmount
    },
    verificationToken || null,
  );
};

export const reissueSale = (id: string) => {
  return entityHandlers.executeRecordRequest(
    `${config.baseApiHost}/api/v2/sale/${id}/invoice`,
    'POST',
    SALE_RECEIPT_REQUESTED,
    SALE_RECEIPT_SUCCESS,
    SALE_RECEIPT_FAILURE,
    'saveRecord'
  );
};

export const reissueTicket = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/saleDetail/${record.id}/customer`,
    'PUT',
    TICKET_RECEIPT_REQUESTED,
    TICKET_RECEIPT_SUCCESS,
    TICKET_RECEIPT_FAILURE,
    record,
    (_state: any, data: any) => {
      // data = getDataWithQuestionFields(data, `ticket.${generalFormBuilderPath}`, 'customer.address', 'customer.phoneNumber');
      return update(data, {
        issueTicket: { $set: true }
      });
    },
    'saveRecord'
  );

export const getSaleRecord = (id: string) => {
  return entityHandlers.getRecord(
    'sale',
    GET_SALE_REQUESTED,
    GET_SALE_SUCCESS,
    GET_SALE_FAILURE,
    id
  );
};

export const getSaleRelatedRecord = (id: string) => {
  return entityHandlers.getRecord(
    'sale',
    GET_SALE_RELATED_REQUESTED,
    GET_SALE_RELATED_SUCCESS,
    GET_SALE_RELATED_FAILURE,
    `${id}/related`,
    'relatedRecord'
  );
};

export const getTicketsRecord = (id: string) => {
  return entityHandlers.getRecord(
    'saleDetail',
    GET_TICKETS_REQUESTED,
    GET_TICKETS_SUCCESS,
    GET_TICKETS_FAILURE,
    id
  );
};

export const createRecord = (record: any) =>
  entityHandlers.createRecord(
    'sale',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = update.set(data, 'type', 'offline');

      data = getDataWithQuestionFields(
        data,
        saleContract.formbuilder,
        'customer.address',
        'customer.phoneNumber'
      );
      if (data.details && data.details.length) {
        for (let index = 0; index < data.details.length; index++) {
          data.details[index] = getDataWithQuestionFields(
            data.details[index],
            `ticket.${generalFormBuilderPath}`,
            'customer.address',
            'customer.phoneNumber'
          );
        }
      }

      return data;
    },
    'saveRecord'
  );

export const updateSaleRecord = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/sale/${record.id}/customer`,
    'PUT',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = getDataWithQuestionFields(
        data,
        saleContract.formbuilder,
        'customer.address',
        'customer.phoneNumber'
      );
      return data;
    },
    'saveRecord'
  );

export const updateTicketRecord = (record: any) =>
  entityHandlers.executeRequest(
    `${config.baseApiHost}/api/v2/saleDetail/${record.id}/customer`,
    'PUT',
    UPDATE_REQUESTED,
    UPDATE_SUCCESS,
    UPDATE_FAILURE,
    record,
    (_state: any, data: any) => {
      data = getDataWithQuestionFields(
        data,
        `ticket.${generalFormBuilderPath}`,
        'customer.address',
        'customer.phoneNumber'
      );
      return data;
    },
    'saveRecord'
  );

export const getCampaignRecord = (id: string) =>
  entityHandlers.getRecord(
    'page/campaign',
    GET_CAMPAIGN_REQUESTED,
    GET_CAMPAIGN_SUCCESS,
    GET_CAMPAIGN_FAILURE,
    id
  );

export const getAvailableTicketsRecord = (request: any) =>
  entityHandlers.getSearchRecord(
    'ticket',
    GET_AVAILABLE_TICKETS_REQUESTED,
    GET_AVAILABLE_TICKETS_SUCCESS,
    GET_AVAILABLE_TICKETS_FAILURE,
    request,
    'ticketsRecord'
  );

export const toggleColumnsChange = (listKey: string, toggleColumns: any) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );

export const getSaleMetadata = (listKey: string) =>
  entityHandlers.getMetadata(
    'sale',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getSaleListData = (
  listKey: string,
  id: string,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    'sale',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );

export const getSaleListDataBySearchTerm = (
  listKey: string,
  id: string,
  request: any,
  searchTerm: string
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getSaleListData(listKey, id, newRequest, true);
};

export const getTicketMetadata = (listKey: string) =>
  entityHandlers.getMetadata(
    'saleDetail',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );

export const getTicketListData = (
  listKey: string,
  id: string,
  request: any,
  invalidate = false,
  cachedData?: any
) =>
  entityHandlers.getListData(
    'saleDetail',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData,
    'v3'
  );

export const getTicketListDataBySearchTerm = (
  listKey: string,
  id: string,
  request: any,
  searchTerm: string
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getTicketListData(listKey, id, newRequest, true);
};

export const setView = (view: any) => ({ type: ACTIVE_TAB_SET, payload: view });

export const getReceiptMessageError = (record: any) => ({
  type: RECEIPT_MESSAGE_ERROR,
  payload: { key: record }
});

// export const getExportFile = (
//   entity: any,
//   request: any,
//   fileName: string,
//   version: string
// ) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName,
//     version
//   );
// };

export const unknownEntityMap = (entity: any, type: pageTypesStrings) =>
  entity?.map((item: any) => ({
    type: type,
    data: {
      id: item.id,
      amount: item.amount
    }
  })) || [];

export const entityMap = (entity: any, type: pageTypesStrings, campaign: any) =>
  entity?.map((item: any) => ({
    type,
    imageUrl: `${item.mainImageUrl || campaign?.mainImageUrl}?w=100`,
    data: {
      id: item.id,
      name: item.name,
      urlFull: item.urlFull || campaign?.urlFull
    }
  })) || [];
