import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import * as templateHelper from '../../template/templateHelper';
import { stringToArray, arrayToString } from '../../../helpers/arrayHelper';

import { Form } from '../../';
import { TemplateHeader } from '../../template/templateHeader';

import { fitnessPlatformOptions } from '../../../pages/campaign/constants';

class FitnessPlatformsContainer extends Component {
  isFitnessPlatformOptionChecked = (option) => {
    return stringToArray(this.props.value).some((item) => item === option);
  };

  handleFitnessPlatformOptionChange = (value, evt, { checked }) => {
    const fitnessPlatforms = stringToArray(this.props.value);

    if (checked) {
      fitnessPlatforms.push(value);
    } else {
      const fitnessPlatformIndex = fitnessPlatforms.findIndex(
        (item) => item === value
      );

      if (fitnessPlatformIndex !== -1) {
        fitnessPlatforms.splice(fitnessPlatformIndex, 1);
      }
    }

    this.props.onChange(this.props.path, arrayToString(fitnessPlatforms));
  };

  getFitnessPlatformOptions = () => {
    return (
      <div className="fitnessPlatformOptions">
        {fitnessPlatformOptions.map((option, index) => {
          const isChecked = this.isFitnessPlatformOptionChecked(option.value);

          return (
            <Form.Checkbox
              key={option.key || index}
              inputLabel={I18n.t(
                `template.page.content.fitness.fitnessPlatforms.options.${option.i18nKey}`
              )}
              checked={isChecked}
              disabled={option.disabled}
              onChange={this.handleFitnessPlatformOptionChange.bind(
                this,
                option.value
              )}
            />
          );
        })}
      </div>
    );
  };

  render() {
    const fitnessPlatformOptions = this.getFitnessPlatformOptions();

    return (
      <React.Fragment>
        <TemplateHeader
          as="h4"
          label="template.page.content.fitness.fitnessPlatforms.label"
        />
        {fitnessPlatformOptions}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

const FitnessPlatforms = connect(mapState)(FitnessPlatformsContainer);
export default FitnessPlatforms;
