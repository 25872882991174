import update from '../helpers/update';

import { CLEAR as GENERAL_CLEAR } from '../modules/general';

export const CARD_CLEAR = 'card/CARD_CLEAR';
export const SET_PAYMNET_REQUEST = 'card/SET_PAYMNET_REQUEST';
export const SET_PAYMENT_STARTED = 'card/SET_PAYMENT_STARTED';
export const SET_PAYMENT_SUCCESS = 'card/SET_PAYMENT_SUCCESS';
export const MAKE_PAYMENT_ERROR = 'card/MAKE_PAYMENT_ERROR';

const initialState = {};

const cardReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case GENERAL_CLEAR: {
      newState = initialState;
      break;
    }

    case CARD_CLEAR:
      newState = update.unset(newState, action.payload.path);
      break;

    case SET_PAYMNET_REQUEST:
      newState = update.set(
        newState,
        `${action.payload.path}.needPaymentData`,
        true
      );
      break;

    case SET_PAYMENT_STARTED:
      newState = update.set(
        newState,
        `${action.payload.path}.paymentInProgress`,
        true
      );
      break;

    case SET_PAYMENT_SUCCESS:
      newState = update.set(
        newState,
        `${action.payload.path}.isConnected`,
        true
      );
      newState = update.set(
        newState,
        `${action.payload.path}.needPaymentData`,
        false
      );
      newState = update.set(
        newState,
        `${action.payload.path}.paymentInProgress`,
        false
      );
      break;

    case MAKE_PAYMENT_ERROR:
      newState = update.set(
        newState,
        `${action.payload.path}.paymentInProgress`,
        false
      );
      newState = update.set(
        newState,
        `${action.payload.path}.serverErrorOccured`,
        true
      );
      break;

    default:
      break;
  }

  return newState;
};
export default cardReducer;

export const cardActions = {
  setPaymentRequest: (path) => {
    return {
      type: SET_PAYMNET_REQUEST,
      payload: {
        path
      }
    };
  },
  setPaymentStarted: (path) => {
    return {
      type: SET_PAYMENT_STARTED,
      payload: {
        path
      }
    };
  },
  setPaymentSuccess: (path) => {
    return {
      type: SET_PAYMENT_SUCCESS,
      payload: {
        path
      }
    };
  },
  makePaymentErrorOccured: (error, path) => {
    return {
      type: MAKE_PAYMENT_ERROR,
      payload: {
        error,
        path
      }
    };
  },
  clear: (path) => {
    return {
      type: CARD_CLEAR,
      payload: {
        path
      }
    };
  }
};
