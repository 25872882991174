import update from 'immutability-helper';

update.extend('$popById', (value, object) => {
  /// TODO: Find a better way to clone.
  const obj = JSON.parse(JSON.stringify(object)); //update([], value));

  for (var i = 0; i < obj.length; i++) {
    if (obj[i] && obj[i].id === value) {
      obj.splice(i, 1);
      break;
    }
  }
  return obj;
});

update.extend('$auto', (value, object) =>
  object ? update(object, value) : update({}, value)
);

update.set = (obj, path, value) => {
  const pathMap = {};
  let pathPointer = pathMap;

  const pathArray = path.split('.');

  pathArray.forEach((item, idx) => {
    if (idx === pathArray.length - 1) {
      pathPointer['$auto'] = { [item]: { $set: value } };
    } else {
      const temp = {};
      pathPointer['$auto'] = { [item]: temp };
      pathPointer = temp;
    }
  });

  return update(obj, pathMap);
};

update.unset = (obj, path) => {
  const pathMap = {};
  let pathPointer = pathMap;

  const pathArray = path.split('.');

  pathArray.forEach((item, idx) => {
    if (idx === pathArray.length - 1) {
      pathPointer['$auto'] = { $unset: [`${item}`] };
    } else {
      const temp = {};
      pathPointer['$auto'] = { [item]: temp };
      pathPointer = temp;
    }
  });

  return update(obj, pathMap);
};

export default update;
