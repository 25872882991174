import '../../../../helpers/aggregateUrlExtension';
import * as entityHandlers from '../../../../modules/bin/entityHandlers';
import { ColumnModel } from '../../../../components/common/grid/columnModel';
import { columnTypes } from '../../../../components/common/grid/constants';
import { GridOptions } from '../../../../components/common/grid/gridOptions';
import { gridKeys } from '../../../../constants/gridKeys';
import { getCurrentFinancialYear } from '../../../../helpers/dateAndTimeHelper';
import { listReadyStateClear } from '../../../../helpers/donationHelper';
import {
    getCampaignClickableColumnForCampaignFundraisers,
    getFundraiserClickableColumnUrl, getTeamClickableColumnUrl
} from '../../../../helpers/publicSiteUrlHelper';
import update from '../../../../helpers/update';
import { Record } from '../../../../modules/bin/utility';
import { CLEAR, LIST_READY_STATE_CLEAR } from '../../../../modules/general';
import {
    IMPERSONATE_FUNDRAISER_FAILURE,
    IMPERSONATE_FUNDRAISER_REQUESTED,
    IMPERSONATE_FUNDRAISER_SUCCESS
} from '../../../../modules/session';
import { countryCode } from '../../constants';
import { ACTIVE_TAB_SET, DETAIL_STORE_CLEAR, DONATIONS_LIST_DATA_FAILURE, DONATIONS_LIST_DATA_REQUESTED, DONATIONS_LIST_DATA_SUCCESS, DONATIONS_METADATA_FAILURE, DONATIONS_METADATA_REQUESTED, DONATIONS_METADATA_SUCCESS, DONATIONS_TOGGLE_COLUMNS_CHANGE, DONATION_SUMMARY_MODAL_OPEN, DONATION_SUMMARY_REPORT_FAILURE, DONATION_SUMMARY_REPORT_REQUESTED, DONATION_SUMMARY_REPORT_SUCCESS, ERROR_CLEAR, FUNDRAISING_LIST_DATA_FAILURE, FUNDRAISING_LIST_DATA_REQUESTED, FUNDRAISING_LIST_DATA_SUCCESS, FUNDRAISING_METADATA_FAILURE, FUNDRAISING_METADATA_REQUESTED, FUNDRAISING_METADATA_SUCCESS, FUNDRAISING_TOGGLE_COLUMNS_CHANGE, GET_DETAIL_FAILURE, GET_DETAIL_REQUESTED, GET_DETAIL_SUCCESS, ON_VALUE_CHANGE, TOTAL_AMOUNT_DONATED_FAILURE, TOTAL_AMOUNT_DONATED_REQUESTED, TOTAL_AMOUNT_DONATED_SUCCESS, TOTAL_AMOUNT_FUNDRAISED_FAILURE, TOTAL_AMOUNT_FUNDRAISED_REQUESTED, TOTAL_AMOUNT_FUNDRAISED_SUCCESS } from './accountDetailTypes';

const i18nFundraisersListKey = 'campaign.list.header';
const i18nDonationListKey = 'donation.list.header';
const financialYearDate = getCurrentFinancialYear();

const initialState = {
  donationSummaryModal: {
    open: false,
    country: countryCode.au,
    dateFrom: financialYearDate.start,
    dateTo: financialYearDate.end
  },
  impersonateFundraiser: {
    fundraiserUrlFull: ''
  },
  timestamp: new Date().getTime(),
  detailRecord: Record.getDefaultState(),
  totalDonatedRecord: Record.getDefaultState(),
  donationSummaryRecord: Record.getDefaultState(),
  totalFundraisingRecord: Record.getDefaultState(),
  impersonatedFundraiserRecord: Record.getDefaultState(),
  // TODO: use shared donation and fundraisers list
  [gridKeys.ACCOUNT_VIEW_DONATIONS]: new GridOptions()
    .addColumns([
      new ColumnModel(
        'donatedAtLocal',
        `${i18nDonationListKey}.donationDateTime`
      )
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel(
        'campaign.name',
        `${i18nDonationListKey}.campaign-name`
      ).makeClickable(
        getCampaignClickableColumnForCampaignFundraisers,
        true,
        false
      ),
      new ColumnModel(
        'team.name',
        `${i18nDonationListKey}.team-name`
      ).makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel(
        'fundraiser.name',
        `${i18nDonationListKey}.page-name`
      ).makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel(
        'donor.lastName',
        `${i18nDonationListKey}.donor.lastName`
      ),
      new ColumnModel(
        'donor.firstName',
        `${i18nDonationListKey}.donor.firstName`
      ),
      new ColumnModel(
        'organization.name',
        `${i18nDonationListKey}.organization-name`
      ),
      new ColumnModel('donor.email', `${i18nDonationListKey}.donor-email`),
      new ColumnModel(
        'donor.phoneNumber',
        `${i18nDonationListKey}.donor-phone-number`
      ),
      new ColumnModel(
        'receiptNumber',
        `${i18nDonationListKey}.receiptNumber`
      ).visibleByDefault(),
      new ColumnModel('amount', `${i18nDonationListKey}.amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'financials.refunded',
        `${i18nDonationListKey}.refunded`
      ).setType(columnTypes.BOOLEAN),
      new ColumnModel('type', `${i18nDonationListKey}.type`)
    ])
    .orderBy('donatedAtLocal', 'desc')
    .getState(),
  [gridKeys.ACCOUNT_VIEW_FUNDRAISING]: new GridOptions()
    .addColumns([
      new ColumnModel('createdAtLocal', `${i18nFundraisersListKey}.createdAt`)
        .setType(columnTypes.DATETIME)
        .visibleByDefault(),
      new ColumnModel('name', `${i18nFundraisersListKey}.page-name`)
        .setWidth(2)
        .visibleByDefault(),
      new ColumnModel(
        'account.firstName',
        `${i18nFundraisersListKey}.first-name`
      ).setWidth(2),
      new ColumnModel(
        'account.lastName',
        `${i18nFundraisersListKey}.last-name`
      ).setWidth(2),
      new ColumnModel(
        'account.email',
        `${i18nFundraisersListKey}.email`
      ).setWidth(2),
      new ColumnModel('phoneNumber', `${i18nFundraisersListKey}.phoneNumber`),
      new ColumnModel(
        'team.name',
        `${i18nFundraisersListKey}.team-name`
      ).makeClickable(getTeamClickableColumnUrl, true, false),
      new ColumnModel(
        'organization.name',
        `${i18nFundraisersListKey}.organization-name`
      ),
      new ColumnModel(
        'campaign.name',
        `${i18nFundraisersListKey}.campaign-name`
      )
        .visibleByDefault()
        .makeClickable(
          getCampaignClickableColumnForCampaignFundraisers,
          true,
          false
        ),
      new ColumnModel(
        'fundraiser.name',
        `${i18nDonationListKey}.page-name`
      ).makeClickable(getFundraiserClickableColumnUrl, true, false),
      new ColumnModel('raisedAmount', `${i18nFundraisersListKey}.raisedAmount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel('targetAmount', `${i18nFundraisersListKey}.target-amount`)
        .setType(columnTypes.DECIMAL)
        .visibleByDefault(),
      new ColumnModel(
        'averageDonationAmount',
        `${i18nFundraisersListKey}.averageDonationAmount`
      ).setType(columnTypes.DECIMAL),
      new ColumnModel(
        'donationCount',
        `${i18nFundraisersListKey}.donationCount`
      )
        .setType(columnTypes.INT32)
        .visibleByDefault(),
      new ColumnModel(
        'additionalDonationsNeededForTarget',
        `${i18nFundraisersListKey}.neededAverageCount`
      ).setType(columnTypes.INT32)
    ])
    .orderBy('raisedAmount', 'desc')
    .getState()
};

// State Reducer
const accountDetailReducer = (state = initialState, action: any) => {
  let newState = state;
  switch (action.type) {
    case CLEAR: {
      newState = initialState;
      break;
    }

    case DONATIONS_METADATA_REQUESTED:
    case FUNDRAISING_METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case DONATIONS_METADATA_SUCCESS:
    case FUNDRAISING_METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case DONATIONS_LIST_DATA_REQUESTED:
    case FUNDRAISING_LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case DONATIONS_LIST_DATA_SUCCESS:
    case FUNDRAISING_LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case DONATIONS_LIST_DATA_FAILURE:
    case FUNDRAISING_LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.ACCOUNT_VIEW_DONATIONS);
      break;

    case GET_DETAIL_REQUESTED:
    case DONATION_SUMMARY_REPORT_REQUESTED:
    case TOTAL_AMOUNT_DONATED_REQUESTED:
    case TOTAL_AMOUNT_FUNDRAISED_REQUESTED:
    case IMPERSONATE_FUNDRAISER_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_DETAIL_SUCCESS:
    case TOTAL_AMOUNT_DONATED_SUCCESS:

    case TOTAL_AMOUNT_FUNDRAISED_SUCCESS:
    case IMPERSONATE_FUNDRAISER_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case DONATION_SUMMARY_REPORT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(
        newState,
        'donationSummaryModal',
        initialState.donationSummaryModal
      );
      newState = update.set(
        newState,
        'donationSummaryRecord',
        Record.getDefaultState(false)
      );
      break;

    case GET_DETAIL_FAILURE:
    case TOTAL_AMOUNT_DONATED_FAILURE:
    case DONATION_SUMMARY_REPORT_FAILURE:
    case TOTAL_AMOUNT_FUNDRAISED_FAILURE:
    case IMPERSONATE_FUNDRAISER_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case DONATIONS_TOGGLE_COLUMNS_CHANGE:
    case FUNDRAISING_TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    case DETAIL_STORE_CLEAR:
      newState = initialState;
      break;

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case ACTIVE_TAB_SET:
      newState = update(newState, { selectedView: { $set: action.payload } });
      break;

    case DONATION_SUMMARY_MODAL_OPEN:
      const { open } = action.payload;

      if (open) {
        newState = update.set(newState, 'donationSummaryModal.open', open);
      } else {
        newState = update.set(
          newState,
          'donationSummaryModal',
          initialState.donationSummaryModal
        );
      }

      break;

    case DONATIONS_METADATA_FAILURE:
    case FUNDRAISING_METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case ON_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    default:
      return state;
  }

  return newState;
};

export default accountDetailReducer;
