let CustomImageContainerBlotExport;
if (typeof window !== 'undefined') {
  let ReactQuill = require('react-quill');
  let BlockEmbed = ReactQuill.Quill.import('blots/inline');

  CustomImageContainerBlotExport = class CustomImageBlot extends BlockEmbed {
    static create(value) {
      let node = super.create();
      if (value.className) {
        node.appendChild(value);
      }
      return node;
    }

    static value(domNode) {
      return domNode;
    }
  };
  CustomImageContainerBlotExport.blotName = 'custom-image-container';
  CustomImageContainerBlotExport.tagName = 'div';
  CustomImageContainerBlotExport.className = 'content-image-cont';
}
export default CustomImageContainerBlotExport;
