import React, { Component } from 'react';
import { Form } from 'semantic-ui-react';

class InputView extends Component {
  render() {
    const { required, value, label, subtext } = this.props;

    return (
      <Form.Field className="field-section" required={required}>
        <label>{label}</label>
        {value}
        <div>{subtext}</div>
      </Form.Field>
    );
  }
}

export default InputView;
