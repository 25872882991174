import { pipe, reject, equals, insert, indexOf } from 'ramda';
import { actionTypes } from './store';
import { sameParent, getParent } from './formBuilderHelpers';

/**
 * This action creator updates the parent node of the two input nodes,
 * so the order its children is changed accordingly.
 * This action creator is mainly used for drag and drop.
 * @param {Object} childToStay The node which has to stay in its place.
 * @param {Object} childToPut The node which we want to move.
 * @param {string} formRoot the name of the FormBuilder.
 */
export const reorderNodes =
  (childToStay, childToPut, formRoot) => (dispatch) => {
    const parent = sameParent(childToStay, childToPut, formRoot);
    // If the tow nodes do not have the same parent, or they are the same then we made a mistake.
    if (!parent || childToStay === childToPut) return;
    // If we're moving 'up' in the children array, then we should place the moved node
    // after the staying node, in the other case we should place it before.
    const changeDirectionUpward =
      indexOf(childToStay, parent.children) >
      indexOf(childToPut, parent.children);
    const childArray = pipe(
      // Remove the node we want to move
      reject(equals(childToPut)),
      // And put it back
      insert(
        indexOf(childToStay, parent.children) + (changeDirectionUpward ? 1 : 0),
        childToPut
      )
    )(parent.children);
    // Update the parent node with the new children array.
    const newParent = {
      ...parent,
      children: childArray
    };
    // eslint-disable-next-line consistent-return
    return dispatch({
      type: actionTypes.EDIT_NODE,
      node: newParent,
      oldNode: parent
    });
  };
/**
 * This function updates the parent node's children with the added node.
 * @param {Object} parent The parent node we want to update.
 * @param {Object} child the node which represents the node to be added to the children
 */
export const addChildNode = (parent, child) => (dispatch) => {
  const newChildren = [...parent.children, child];
  const newParent = {
    ...parent,
    children: newChildren
  };
  return dispatch({
    type: actionTypes.EDIT_NODE,
    node: newParent,
    oldNode: parent
  });
};

/**
 * This action creator deletes a node from the children array of a given parent node.
 * @param {Object} possibleParentNode The parent of the node.
 * @param {Object} node the node we want to remove from the parent's children array.
 */
export const deleteNode = (possibleParentNode, node) => (dispatch) => {
  const parent = getParent(node, possibleParentNode);
  // Filter out the node from the parent's children array.
  const newChildren = reject(equals(node), parent.children);
  // Update the parent.
  const newParent = {
    ...parent,
    children: newChildren
  };
  return dispatch({
    type: actionTypes.EDIT_NODE,
    node: newParent,
    oldNode: parent
  });
};
/**
 * This action creator updates a node in the tree.
 * @param {Object} newNode The node in the current state.
 * @param {Object} oldNode The updated node.
 */
export const editNode = (newNode, oldNode) => (dispatch) =>
  dispatch({
    type: actionTypes.EDIT_NODE,
    node: { ...newNode },
    oldNode
  });
/**
 * This action creator adds a new FormBuilder to the store.
 * @param {Object} newForm The root node of the FormBuilder tree.
 * @param {string} key The name of the new FormBuilder
 */
export const addNewForm = (newForm, key) => (dispatch) =>
  dispatch({ type: actionTypes.ADD_FORMBUILDER, newForm, key });
