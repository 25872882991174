export const i18DonationAmountKeys = {
  label: 'label',
  amount: 'amount.label',
  isDefault: 'amount.isDefault',
  description: 'description.label'
};

export const i18WaiverKeys = {
  label: 'label',
  subtext: 'subtext'
};

export const i18SkuKeys = {
  label: 'label',
  name: 'name.label',
  description: 'description.label',
  quantity: 'quantity.label',
  price: 'price.label'
};

export const i18GiftMatchingKeys = {
  label: 'label',
  name: {
    label: 'name.label',
    placeholder: 'name.placeholder',
    subtext: 'name.subtext'
  },
  multiplier: {
    label: 'multiplier.label',
    placeholder: 'multiplier.placeholder',
    subtext: 'multiplier.subtext'
  },
  donationFrom: {
    label: 'donationFrom.label',
    placeholder: 'donationFrom.placeholder',
    subtext: 'donationFrom.subtext'
  },
  donationMessage: {
    label: 'donationMessage.label',
    placeholder: 'donationMessage.placeholder',
    subtext: 'donationMessage.subtext'
  },
  image: {
    label: 'image.label',
    subtext: 'image.subtext'
  },

  instanceLabel: 'instance.label',
  key: {
    label: 'instance.key.label',
    placeholder: 'instance.key.placeholder',
    subtext: 'instance.key.subtext'
  },
  operator: {
    label: 'instance.operator.label',
    placeholder: 'instance.operator.placeholder',
    subtext: 'instance.operator.subtext'
  },
  value: {
    label: 'conditions.value.label',
    labelFrom: 'conditions.value.labelFrom',
    labelTo: 'conditions.value.labelTo',

    placeholder: 'conditions.value.placeholder',
    placeholderFrom: 'conditions.value.placeholderFrom',
    placeholderTo: 'conditions.value.placeholderTo',

    subtext: 'conditions.value.subtext',
    subtextFrom: 'conditions.value.subtextFrom',
    subtextTo: 'conditions.value.subtextTo'
  },
  valueDateTime: {
    label: 'conditions.valueDateTime.label',
    labelFrom: 'conditions.valueDateTime.labelFrom',
    labelTo: 'conditions.valueDateTime.labelTo',

    placeholderDate: 'conditions.valueDateTime.placeholderDate',
    placeholderDateFrom: 'conditions.valueDateTime.placeholderDateFrom',
    placeholderDateTo: 'conditions.valueDateTime.placeholderDateTo',

    placeholderTime: 'conditions.valueDateTime.placeholderTime',
    placeholderTimeFrom: 'conditions.valueDateTime.placeholderTimeFrom',
    placeholderTimeTo: 'conditions.valueDateTime.placeholderTimeTo',

    subtext: 'conditions.valueDateTime.subtext'
  },
  pageFundraiser: {
    label: 'conditions.pageFundraiser.label',
    placeholder: 'conditions.pageFundraiser.placeholder',
    subtext: 'conditions.pageFundraiser.subtext'
  },
  pageTeam: {
    label: 'conditions.pageTeam.label',
    placeholder: 'conditions.pageTeam.placeholder',
    subtext: 'conditions.pageTeam.subtext'
  },
  pageCollection: {
    label: 'conditions.pageCollection.label',
    placeholder: 'conditions.pageCollection.placeholder',
    subtext: 'conditions.pageCollection.subtext'
  },
  conditions: {
    label: 'conditions.label'
  }
};
