import React from 'react';
import { Form } from 'semantic-ui-react';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateAutocomplete } from '../../../components/template/templateAutocomplete';
import { TemplateCurrency } from '../../../components/template/templateCurrency';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateFormBuilderAnswers } from '../../../components/template/templateFormBuilderAnswers';
import { saleContract, templateTabs } from '../ticketingContracts';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateRadioGroup } from '../../../components/template/templateRadioGroup';
import { ticketingValidationPaths } from '../validationModels';
import { saleShippingTypeOptions } from '../constants';
import { TemplateDynamicText } from '../../../components/template/templateDynamicText';

export const getInvoiceInfromationTab = (props) => {
  const {
    onChange,
    onCampaignChange,
    getCampaignRequest,
    getCampaignItem,
    required,
    optional,
    formValidation,
    formBuilderAnswers,
    feeCovered
  } = props;
  return (
    <TemplatePage
      id={templateTabs.SALE.getTabName()}
      i18nKey="ticketing.edit.invoice-information.header"
    >
      <TemplateHeader
        as="h3"
        label="ticketing.edit.invoice-information.header"
      />

      <TemplateAutocomplete
        id="sale-campaign"
        entity="page/campaign"
        path={saleContract.campaignId}
        selected="campaign"
        label="ticketing.edit.invoice-information.campaign.label"
        placeholder="ticketing.edit.invoice-information.campaign.placeholder"
        validation={required}
        onChange={onCampaignChange}
        request={getCampaignRequest}
        getItem={getCampaignItem}
      />

      <Form.Group widths="equal" path={saleContract.amount}>
        <TemplateCurrency
          id="sale-amount"
          path={saleContract.amount}
          validation={optional}
          label="ticketing.edit.invoice-information.amount.label"
          allowDecimal
          onChange={onChange}
        />

        <TemplateDynamicText
          className="vertical-align-field"
          path={saleContract.fee}
          label={`ticketing.edit.invoice-information.fee.${
            feeCovered ? 'covered' : 'notCovered'
          }.label`}
        />
      </Form.Group>

      <TemplateHeader
        as="h5"
        path={saleContract.shippingInformation}
        label="ticketing.edit.invoice-information.shipping.header"
      />

      <TemplateHeader
        as="h5"
        path={saleContract.shippingType}
        label="ticketing.edit.invoice-information.shipping.type.label"
      />

      <TemplateRadioGroup
        id="sale-shippingType"
        path={saleContract.shippingType}
        onChange={onChange}
        options={saleShippingTypeOptions}
      />

      <TemplateCurrency
        id="sale-cost"
        path={saleContract.shippingCost}
        validation={optional}
        label="ticketing.edit.invoice-information.shipping.cost.label"
        allowDecimal
        onChange={onChange}
      />

      <TemplateHeader
        as="h5"
        path="messageOfSupportHeader"
        label="ticketing.edit.invoice-information.message-of-support.header"
      />

      <TemplateInput
        id="sale-firstName"
        path={saleContract.firstName}
        label="ticketing.edit.invoice-information.first-name.label"
        placeholder="ticketing.edit.invoice-information.first-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.firstName]}
      />

      <TemplateInput
        id="sale-lastName"
        path={saleContract.lastName}
        label="ticketing.edit.invoice-information.last-name.label"
        placeholder="ticketing.edit.invoice-information.last-name.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.lastName]}
      />

      <TemplateInput
        id="sale-email"
        path={saleContract.email}
        label="ticketing.edit.invoice-information.email.label"
        placeholder="ticketing.edit.invoice-information.email.placeholder"
        onChange={onChange}
        validation={formValidation[ticketingValidationPaths.email]}
      />

      <TemplateInput
        id="sale-businessName"
        path={saleContract.businessName}
        label="ticketing.edit.invoice-information.business-name.label"
        placeholder="ticketing.edit.invoice-information.business-name.placeholder"
        onChange={onChange}
        validation={optional}
      />

      <TemplateFormBuilderAnswers
        id="sale-templateFormBuilderAnswers"
        path={saleContract.formbuilder}
        onChange={onChange}
        validation={formBuilderAnswers}
      />

      <TemplateCheckbox
        id="sale-issue-invoice"
        path={saleContract.issueInvoice}
        inputLabel="ticketing.edit.invoice-information.issue-invoice.label"
        subtext="ticketing.edit.invoice-information.issue-invoice.subtext"
        onChange={onChange}
      />
    </TemplatePage>
  );
};
