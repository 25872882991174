import React, { Component } from 'react';
import { Menu, Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

class Pager extends Component {
  onItemClick(key) {
    if (this.props.onClick) {
      this.props.onClick(key);
    }
  }

  render() {
    const items = !this.props.children
      ? []
      : React.Children.map(this.props.children, (item, idx) =>
          React.cloneElement(item, {
            id: item.props.id,
            index: idx + 1,
            onClick: this.onItemClick.bind(this),
            children: item.props.children
          })
        );

    return (
      <Menu vertical fluid className="pager">
        {items}
      </Menu>
    );
  }
}

class Item extends Component {
  onClick(key) {
    if (this.props.onClick) {
      this.props.onClick(key);
    }
  }
  render() {
    const buttonContent = this.props.showCheckmark ? (
      <Icon name="checkmark" style={{ marginLeft: '1%' }} color="green" />
    ) : (
      this.props.index
    );

    return (
      <Menu.Item
        active={this.props.active === true}
        as="a"
        disabled={this.props.disabled}
        onClick={this.onClick.bind(this, this.props.id)}
      >
        <Button circular compact size="small">
          {buttonContent}
        </Button>
        {this.props.children}
        {this.props.pointing && <Icon name="chevron right" floated="right" />}
      </Menu.Item>
    );
  }
}

class LinkItem extends Component {
  onClick(key) {
    if (this.props.onClick) {
      this.props.onClick(key);
    }
  }
  render() {
    const buttonContent = this.props.showCheckmark ? (
      <Icon name="checkmark" style={{ marginLeft: '1%' }} color="green" />
    ) : (
      this.props.index
    );

    const classes = classNames({
      item: true,
      disabled: this.props.disabled,
      active: this.props.active
    });
    return (
      <Menu.Item
        as={() => {
          if (this.props.disabled) {
            return (
              <span className={classes}>
                <Button circular compact size="small">
                  {buttonContent}
                </Button>
                {this.props.children}
              </span>
            );
          }

          return (
            <Link to={this.props.id} className={classes}>
              <Button circular compact size="small">
                {buttonContent}
              </Button>
              {this.props.children}
            </Link>
          );
        }}
        disabled={this.props.disabled}
      ></Menu.Item>
    );
  }
}

export const TemplatePage = (props) => {
  return props.children;
};

Pager.Item = Item;
Pager.LinkItem = LinkItem;
export default Pager;
