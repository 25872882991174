import config from './index';

export const TARGET_VALIDATION = {
  amount: true,
  maxValue: config.MAX_TARGET
};

export const AMOUNT_VALIDATION = {
  amount: true,
  maxValue: config.MAX_DONATION_VALUE
};
