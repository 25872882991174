import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

import get from 'lodash.get';
import { cloneDeep } from 'lodash';

import * as programActions from '../../store/vipProgramActions';

import { Record } from '../../../../modules/bin/utility';
import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import { formBuilderActions } from '../../../../components/formbuilder/store';

import BasePage from '../../../../components/common/basePageView';
import clear from '../../../../components/clear';
import ApplicationTemplate from '../templateEx';
import RecordResultMessage from '../../../../components/common/recordResult';
import { TemplateOptions } from '../../../../components/template/templateOptions';
import Spinner from '../../../../components/common/spinner';

import { paths } from '../constants/template';
import { eventAmbassadorStatus } from '../../constants';

class EditApplication extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isApplicationPartTwoVisible: true
    };

    const eventAmbassadorId = get(props, 'match.params.id');

    props.programActions.getEventAmbassadorRecord(eventAmbassadorId);
  }

  componentDidUpdate() {
    if (
      Record.isSuccess(this.props.eventAmbassadorRecord) &&
      Record.notStarted(this.props.campaignAmbassadorProgramRecord)
    ) {
      const campaignId = get(
        this.props.eventAmbassadorRecord,
        'data.data.campaignId'
      );

      this.props.programActions.getCampaignAmbassadorProgramByCampaignRecord(
        campaignId
      );
    }
  }

  onSave = (data) => {
    this.props.programActions.updateApplicationRecord(data);
  };

  onCancel = () => {
    this.goToApplicationsView();
  };

  initTemplate = () => {
    let eventAmbassador = cloneDeep(
      get(this.props, 'eventAmbassadorRecord.data.data')
    );
    const campaignAmbassadorProgram = get(
      this.props,
      'campaignAmbassadorProgramRecord.data.data'
    );

    const templateOptions = new TemplateOptions();

    if (
      eventAmbassador.status !== eventAmbassadorStatus.CLAIMED &&
      eventAmbassador.status !== eventAmbassadorStatus.RESCINDED
    ) {
      templateOptions.hide(paths.PART_TWO_HEADER);
      templateOptions.hide(paths.APPLICATION_CONFIRMATION);

      this.setState({
        isApplicationPartTwoVisible: false
      });
    }

    if (!get(eventAmbassador, 'template.value.formBuilder.additional')) {
      templateOptions.hide(paths.APPLICATION_ADDITIONAL);
      templateOptions.hide(paths.OGRANIZATION_QUESTIONS_HEADER);
    }

    if (!campaignAmbassadorProgram.availability.updatingEntries) {
      templateOptions.disable(paths.EDIT_BUTTON);
    }

    templateOptions.setModel(eventAmbassador);

    templateOptions.isEdit();

    this.props.templateBuilderActions.setTemplateModel(
      templateOptions.getWithRule()
    );
  };

  isAllRecordsReady = () => {
    return (
      Record.isRecordReady(this.props.eventAmbassadorRecord) &&
      Record.isRecordReady(this.props.campaignAmbassadorProgramRecord)
    );
  };

  clearMessages = () => {
    this.props.programActions.clearRecordMessages('editApplicationRecord');
  };

  goToApplicationsView = () => {
    this.props.history.push('/vip-program/applications');
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    const isSaving = Record.isRecordLoading(this.props.editApplicationRecord);

    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }

    const eventAmbassadorProgramName = get(
      this.props.campaignAmbassadorProgramRecord,
      'data.data.eventAmbassadorProgram.name'
    );

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.editApplicationRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToApplicationsView}
        />

        <h1>
          <Translate
            value={I18n.t('applications.template.header', {
              programName: eventAmbassadorProgramName
            })}
          />
        </h1>
        <ApplicationTemplate
          onSave={this.onSave}
          isLoading={isSaving}
          onCancel={this.onCancel}
          isApplicationPartTwoVisible={this.state.isApplicationPartTwoVisible}
        />
      </BasePage>
    );
  }
}

const mapState = ({ program, templateBuilderEx }) => {
  return {
    eventAmbassadorRecord: program.eventAmbassadorRecord,
    campaignAmbassadorProgramRecord: program.campaignAmbassadorProgramRecord,
    editApplicationRecord: program.editApplicationRecord,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

const EditApplicationContainer = clear(
  connect(mapState, mapDispatch)(EditApplication)
);
export default EditApplicationContainer;
