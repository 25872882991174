import get from 'lodash.get';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import { Container, Menu } from 'semantic-ui-react';
import { Common } from '../../../components';
import Grid from '../../../components/common/grid';
import { i18nKeys, tabs } from '../constants';

// Actions
import { withRouter } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ContextMenu from '../../../components/common/grid/contextMenu';
import { CONTEXT_MENU_KEYS } from '../../../constants/contextMenuEntries';
import { gridKeys } from '../../../constants/gridKeys';
import { pageTypesStrings } from '../../../constants/pageTypes';
import { hideCondition } from '../../../helpers/hideHelper';
import {
  impersonateFundraiser,
  setRedirectUrl
} from '../../../modules/session';
import {
  OFFLINE_DONATION_TYPE, ONLINE_DONATION_TYPE, PLEDGE_DONATION_TYPE
} from '../../campaign/constants';
import ReceiptWindowContainer from '../../donation/receipt-window';
import * as donationsActions from '../../donation/store/donationActions';
import RefundWindowContainer from '../../donation/refund-window';
import * as accountActions from './store/accountDetailActions';
;

class DonationsTable extends Component {
  isViewSelected(view) {
    return this.props.match.params.tab === view;
  }

  handleMenuItemClick = (e, { name }) => {
    this.props.history.push(
      `/accounts/detail/${this.props.match.params.id}/${name}`
    );
  };

  getTabsButtons = (hasFundraising, hasDonations) => {
    if (hasFundraising || hasDonations) {
      return (
        <Menu
          className="accounts-tab-buttons-group"
          style={{ margin: '0px', borderRight: '0px' }}
        >
          {hasFundraising && (
            <Menu.Item
              name={tabs.FUNDRAISING}
              active={this.isViewSelected(tabs.FUNDRAISING)}
              onClick={this.handleMenuItemClick}
            >
              <Translate value={i18nKeys.FUNDRAISING_TAB_TITLE} />
            </Menu.Item>
          )}
          {hasDonations && (
            <Menu.Item
              name={tabs.DONATIONS}
              active={this.isViewSelected(tabs.DONATIONS)}
              onClick={this.handleMenuItemClick}
            >
              <Translate value={i18nKeys.DONATIONS_TAB_TITLE} />
            </Menu.Item>
          )}
        </Menu>
      );
    }
  };

  setDefaultView(hasFundraising, hasDonations) {
    if (hasFundraising) {
      this.props.setView(tabs.FUNDRAISING);
    } else if (hasDonations) {
      this.props.setView(tabs.DONATIONS);
    }
  }

  hideColumnCondition = (item) => {
    return hideCondition(
      this.props.isOrganizationAdmin,
      item,
      'path',
      'organization.name'
    );
  };

  isContextMenuItemHide = (item) => {
    return item.type === pageTypesStrings.FUNDRAISER;
  };

  onRefundContextClick = (id, item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  hideRefundButton = (item) => {
    return item.type !== ONLINE_DONATION_TYPE;
  };

  onRefundContextClick = (id, item) => {
    this.props.donationActions.showRefundConfirmation(item);
  };

  hideDeleteButton = (item) => {
    return (
      item.type !== OFFLINE_DONATION_TYPE && item.type !== PLEDGE_DONATION_TYPE
    );
  };

  onViewContextClick = (id, item) => {
    this.props.history.push(`/donations/view/${id}`);
  };

  onReissueContextClick = (id, item) => {
    if (item.donor.email) {
      this.props.donationActions.showReceiptConfirmation(item);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  onImpersonateFundraiserContextClick = (id, item) => {
    this.props.impersonateFundraiser(item.accountId);
    this.props.onValueChange(
      'impersonateFundraiser.fundraiserUrlFull',
      item.urlFull
    );
  };

  render() {
    const donated = this.props.totalAmountDonated.data;

    const hasFundraising = !this.props.totalAmountFundraised.error.show;
    const hasDonations = this.props.totalAmountDonated.error.show
      ? false
      : donated !== undefined && donated !== 0;

    // if view is not selected
    if (!this.props.selectedView) {
      this.setDefaultView(hasFundraising, hasDonations);
    }

    const tabsButtons = this.getTabsButtons(hasFundraising, hasDonations);
    const isDonationsViewSelected = this.isViewSelected(tabs.DONATIONS);
    const isFundraisingViewSelected = this.isViewSelected(tabs.FUNDRAISING);
    const baseFilter = {
      key: 'accountId',
      operator: 'EqualTo',
      value: this.props.match.params.id
    };

    return (
      <Container className="donations-container">
        <RefundWindowContainer />
        <ReceiptWindowContainer />

        {tabsButtons}
        {isDonationsViewSelected && hasDonations && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_VIEW_DONATIONS}
            list={this.props.list}
            actions={this.props.donationActionGrid}
            search
            toggleColumns
            filter={baseFilter}
            hideColumn={this.hideColumnCondition}
          >
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.VIEW_DONATION.key}
                label={I18n.t('donation.list.context.view-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.VIEW_DONATION
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.EDIT_DONATION.key}
                label={I18n.t('donation.list.context.edit-donation')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.EDIT_DONATION
                }
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.REFUND_DONATION.key}
                label={I18n.t('donation.list.context.refund-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.hideRefundButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.DELETE_DONATION.key}
                label={I18n.t('donation.list.context.delete-donation')}
                onClick={this.onRefundContextClick}
                isHiden={this.hideDeleteButton}
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.REISSUE_RECEIPT.key}
                label={I18n.t('donation.list.context.reissue-receipt')}
                onClick={this.onReissueContextClick}
              />
            </ContextMenu>
          </Grid.Managed>
        )}
        {isFundraisingViewSelected && hasFundraising && (
          <Grid.Managed
            listKey={gridKeys.ACCOUNT_VIEW_FUNDRAISING}
            list={this.props.fundraisinglist}
            actions={this.props.fundraisingActionGrid}
            search
            filter={baseFilter}
            toggleColumns
            hideColumn={this.hideColumnCondition}
          >
            <ContextMenu>
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.EDIT_PAGE.key}
                label={I18n.t('campaign.list.view.context.edit-page')}
                contextMenuOptions={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.EDIT_PAGE}
                onClick={() =>
                  this.props.setRedirectUrl(
                    this.props.history.location.pathname
                  )
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.NAVIGATE_TO_PUBLIC.key}
                label={I18n.t('campaign.list.view.context.view-public-page')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.NAVIGATE_TO_PUBLIC
                }
              />
              <ContextMenu.Item
                key={CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.VIEW_PAGE_DONATIONS.key}
                label={I18n.t('campaign.list.view.context.view-page-donations')}
                contextMenuOptions={
                  CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.VIEW_PAGE_DONATIONS
                }
              />
              {this.props.isSystemAdmin && (
                <ContextMenu.Item
                  key={
                    CONTEXT_MENU_KEYS.ACCOUNT_DETAILS.IMPERSONATE_FUNDRAISER.key
                  }
                  label={I18n.t(
                    'campaign.list.view.context.impersonate-fundraiser-page'
                  )}
                  onClick={this.onImpersonateFundraiserContextClick}
                />
              )}
            </ContextMenu>
          </Grid.Managed>
        )}
      </Container>
    );
  }
}

const mapState = ({ accountDetail, session }) => {
  const list = get(accountDetail, `${gridKeys.ACCOUNT_VIEW_DONATIONS}`);
  const fundraisinglist = get(
    accountDetail,
    `${gridKeys.ACCOUNT_VIEW_FUNDRAISING}`
  );

  return {
    selectedView: accountDetail.selectedView,
    list: list,
    fundraisinglist: fundraisinglist,
    totalAmountDonated: accountDetail.totalDonatedRecord,
    totalAmountFundraised: accountDetail.totalFundraisingRecord,
    isOrganizationAdmin: get(session, 'isOrganizationAdmin'),
    isSystemAdmin: get(session, 'isSystemAdmin')
  };
};

const mapDispatch = (dispatch) => {
  var donationTabActions = {
    getMetadata: accountActions.getDonationsMetadata,
    getListData: accountActions.getDonationsListData,
    getListDataBySearchTerm: accountActions.getDonationsListDataBySearchTerm,
    toggleColumnsChange: accountActions.donationsToggleColumnsChange
  };

  var fundraisingActions = {
    getMetadata: accountActions.getFundraisingMetadata,
    getListData: accountActions.getFundraisingListData,
    getListDataBySearchTerm: accountActions.getFundraisingListDataBySearchTerm,
    toggleColumnsChange: accountActions.fundraisingToggleColumnsChange,
    onValueChange: accountActions.onValueChange
  };

  const donationActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    donationTabActions
  );
  const fundraisingActionGrid = Common.Grid.Managed.bindGridActions(
    dispatch,
    fundraisingActions
  );
  const donationActions = bindActionCreators(donationsActions, dispatch);

  return {
    setRedirectUrl: bindActionCreators(setRedirectUrl, dispatch),
    donationActions,
    donationActionGrid,
    fundraisingActionGrid,
    setView(view) {
      dispatch(accountActions.setView(view));
    },
    impersonateFundraiser(id) {
      dispatch(impersonateFundraiser(id));
    },
    onValueChange(path, value) {
      dispatch(fundraisingActions.onValueChange(path, value));
    }
  };
};

const AccountDetailDonationsContainer = withRouter(
  connect(mapState, mapDispatch)(DonationsTable)
);
export default AccountDetailDonationsContainer;
