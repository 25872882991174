import {
  ApiError,
  ListPaginated
} from '../../campaign/create/merchandise/merchandise.model';
import {
  HtttpRequestMethods,
  sendRequest
} from '../../campaign/create/merchandise/api/request';
import { IExportHistory } from '../models/export-history';
import { ExportHistoryUrls } from './constants';

export interface ISearchExportHistoryArgs {
  page?: number;
  pageSize?: number;
}

const getRequestBody = ({
  page = 1,
  pageSize = 50
}: ISearchExportHistoryArgs) => {
  return {
    page,
    pageSize,
    resultType: 'Full',
    filters: [
      {
        key: 'completed',
        operator: 'EqualTo',
        value: [true]
      }
    ],
    orderBy: {
      key: 'createdAt',
      direction: 'desc'
    }
  };
};

export interface ISearchExportHistoryResult {
  data: ListPaginated<IExportHistory>;
  error: ApiError[];
}

export async function searchExportHistory(
  apikey: string,
  sessionKey: string,
  body: ISearchExportHistoryArgs
): Promise<ISearchExportHistoryResult> {
  return sendRequest({
    apikey,
    sessionKey,
    url: ExportHistoryUrls.Search,
    method: HtttpRequestMethods.Post,
    body: getRequestBody(body)
  }).then((response) => response.json());
}
