import { cloneDeep } from 'lodash';

export const isObject = (obj) => {
  return typeof obj === 'object' && obj !== null;
};

export const resetObject = (obj) => {
  const newObject = cloneDeep(obj);
  Object.keys(newObject).forEach((k) => (newObject[k] = null));
  return newObject;
};
