import get from 'lodash.get';

export const hideCondition = (isOrganizationAdmin, item, field, key) => {
  const itemField = get(item, field);
  if (isOrganizationAdmin) {
    if (itemField === key) {
      return true;
    }
  }
};
