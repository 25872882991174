import config from '.';

export const generalFormBuilderPath = 'template.value.formBuilder';

export const addresFields = {
  STREET: 'street',
  SUBURB: 'suburb',
  STATE: 'state',
  POSTCODE: 'postCode',
  COUNTRY: 'country'
};

export const cityFields = {
  SUBURB: 'suburb',
  COLLOQUIAL_SUBURB: 'colloquial_suburb',
  STATE: 'state',
  COUNTRY: 'country'
};

export const addressFieldsValidation = {
  [addresFields.STREET]: {
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.STREET_MAX_LENGTH
  },
  [addresFields.SUBURB]: {
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.CITY_MAX_LENGTH
  },
  [addresFields.STATE]: {
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.STATE_MAX_LENGTH
  },
  [addresFields.POSTCODE]: {
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.POST_CODE_MAX_LENGTH
  },
  [addresFields.COUNTRY]: {
    maxLength: true,
    length: config.FORMBUILDER_ADDRESS.COUNTRY_MAX_LENGTH
  }
};

export const geocodingKeys = {
  STATE: 'administrative_area_level_1 political',
  COUNTRY: 'country political',
  SUBURB: 'locality political',
  POSTCODE: 'postal_code',
  STREET_NUMBER: 'street_number',
  SUBPREMISE: 'subpremise',
  STREET: 'route'
};

export const cityGeocodingKeys = {
  STATE: 'administrative_area_level_1 political',
  COUNTRY: 'country political',
  SUBURB: 'locality political',
  COLLOQUIAL_SUBURB: 'colloquial_area locality political'
};

export const fieldCategoryKey = {
  DONATION: 'DONATION',
  FUNDRAISING: 'FUNDRAISING',
  SALES: 'SALES',
  ENTRY: 'ENTRY',
  CONFIRMATION: 'CONFIRMATION',
  ADDITIONAL: 'ADDITIONAL'
};

export const customFieldCategory = {
  [fieldCategoryKey.DONATION]: 'donation',
  [fieldCategoryKey.FUNDRAISING]: 'registration',
  [fieldCategoryKey.SALES]: 'sales',
  [fieldCategoryKey.ENTRY]: 'entry',
  [fieldCategoryKey.CONFIRMATION]: 'confirmation',
  [fieldCategoryKey.ADDITIONAL]: 'additional'
};

//for backward compatibility
export const oldCustomFieldCategory = {
  [fieldCategoryKey.DONATION]: 'Donation',
  [fieldCategoryKey.FUNDRAISING]: 'Registration'
};
