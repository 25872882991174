// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translate } from 'react-redux-i18n';

// Actions
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as generalActions from '../../../modules/general';
import * as merchandiseActions from '../reducer';

// Helpers
import clear from '../../../components/clear';
import { TemplateOptions } from '../../../components/template/templateOptions';
import { Record } from '../../../modules/bin/utility';

// Components
import MerchandiseTemplate from '../templateEx';
import RecordResultMessage from '../../../components/common/recordResult';
import { merchandiseContract } from '../constants';

class CreateMerchandise extends Component {
  onValueChange = (path, value) => {
    this.props.templateBuilderActions.setResultValue(path, value);
  };

  onSave = (data) => {
    this.props.merchandiseActions.createMerchandise(data);
  };

  goToMerchandiseView = () => {
    this.props.history.push(`/merchandise`);
  };

  clearMessages = () => {
    this.props.merchandiseActions.clearRecordMessages('recordCreate');
  };

  initTemplateBuilderModel = () => {
    let model = new TemplateOptions();

    model.setValue(merchandiseContract.enabled, true);

    this.props.templateBuilderActions.setTemplateModel(model.get());
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.recordCreate);

    if (!this.props.isTemplateInited) {
      this.initTemplateBuilderModel();
    }
    return (
      <div>
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToMerchandiseView}
        />
        <h1>
          <Translate value="merchandise.create.title" />
        </h1>
        <MerchandiseTemplate onSave={this.onSave} isLoading={isSaving} />
      </div>
    );
  }
}

const mapState = ({ merchandise, templateBuilderEx }) => {
  return {
    recordCreate: merchandise.recordCreate,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    merchandiseActions: bindActionCreators(merchandiseActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
  };
};

const CreateMerchandiseContainer = clear(
  connect(mapState, mapDispatch)(CreateMerchandise)
);
export default CreateMerchandiseContainer;
