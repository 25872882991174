import axios from 'axios';
import constants from '../constants';

export default class AutocompleteService {
  static searchEntities(entity, token, request) {
    return axios.post(
      `${constants.baseApiHost}/api/v2/${entity}/search`,
      {
        filters: request.filters,
        includeDataTemplate: request.includeDataTemplate,
        orderBy: request.orderBy,
        page: request.page,
        pageSize: request.pageSize,
        resultType: request.resultType,
        text: request.text
      },
      {
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
  }
}
