import React from 'react';

import { TemplatePage } from '../../../../components/common/pager';
import TemplateValidatedAutocomplete from '../../../../components/template/custom/validatedAutocomplete';
import { TemplateCurrency } from '../../../../components/template/templateCurrency';
import { TemplateDatePicker } from '../../../../components/template/templateDatePicker';
import TemplateEditableUploadContainer from '../../../../components/template/templateEditableUpload';
import { TemplateInput } from '../../../../components/template/templateInput';
import { TemplateTextArea } from '../../../../components/template/templateTextArea';

import {
  entityTypes,
  imageFormats,
  imageTypes
} from '../../../../constants/imageUpload';
import { paths } from '../constants/template';
import config from '../../../../constants';

export const getSetupTab = (props) => {
  const {
    onChange,
    required,
    programNameValidation,
    participantNameValidation,
    getEventRequest,
    getRecordRequest,
    clearRecord,
    getEventItem,
    dateValidation,
    optional,
    target,
    requestValidation
  } = props;

  return (
    <TemplatePage id="setup" i18nKey="programs.template.page.setup.title">
      <TemplateInput
        id="setupVipProgramName"
        path={paths.PROGRAM_NAME}
        label="programs.template.page.setup.vip-program-name.label"
        onChange={onChange}
        validation={programNameValidation}
      />

      <TemplateValidatedAutocomplete
        id="setupVipProgramEvents"
        valueEntity="page/event"
        entity="program"
        valuePath={paths.EVENT_ID}
        path={paths.EVENT}
        recordPath="eventAmbassadorProgramRequestRecord"
        selected="event"
        label="programs.template.page.setup.event-name.label"
        request={getEventRequest}
        getItem={getEventItem}
        onChange={onChange}
        getRecordRequest={getRecordRequest}
        clearRecord={clearRecord}
        validation={requestValidation}
      />

      <TemplateDatePicker
        id="setupVipProgramApplicationsOpenDate"
        path={paths.DATE_APPLICATIONS_OPEN}
        label="programs.template.page.setup.applications-open-date.label"
        onChange={onChange}
        validation={dateValidation}
      />

      <TemplateDatePicker
        id="setupVipProgramApplicationsCloseDate"
        path={paths.DATE_APPLICATIONS_CLOSE}
        label="programs.template.page.setup.applications-close-date.label"
        onChange={onChange}
        validation={dateValidation}
      />

      <TemplateDatePicker
        id="setupVipProgramDateClaimedClose"
        path={paths.DATE_CLAIMS_CLOSE}
        label="programs.template.page.setup.date-claimed-close.label"
        onChange={onChange}
        validation={dateValidation}
      />

      <TemplateInput
        id="setupVipProgramTerminologyName"
        path={paths.TERMINOLOGY}
        label="programs.template.page.setup.terminology-name.label"
        subtextPosition="top"
        subtext="programs.template.page.setup.terminology-name.sub-text"
        onChange={onChange}
        validation={participantNameValidation}
      />

      <TemplateEditableUploadContainer
        id="setupVipProgramHeroImage"
        path={paths.BANNER}
        entity={entityTypes.EVENT}
        position="top"
        height="500"
        width="1500"
        format={imageFormats}
        uploadType={imageTypes.HERO_IMAGE}
        label="programs.template.page.setup.hero-image.label"
        onChange={onChange}
        validation={required}
      />

      <TemplateTextArea
        id="setupVipProgramDescription"
        path={paths.INTRO_TEXT}
        label="programs.template.page.setup.intro-text.label"
        subtext="programs.template.page.setup.intro-text.subtext"
        validation={optional}
        onChange={onChange}
        maxLength={config.VIP_PROGRAM_INTRO_TEXT_MAX_LENGTH}
      />

      <TemplateCurrency
        id="setupFundraisingTarget"
        path={paths.FUNDRAISER_DEFAULT_TARGET_AMOUNT}
        validation={target}
        label="programs.template.page.setup.fundraising-target.label"
        subtext="programs.template.page.setup.fundraising-target.subtext"
        onChange={onChange}
      />

      <TemplateCurrency
        id="setupMinimumFundraisingTarget"
        path={paths.FUNDRAISER_MINIMUM_TARGET_AMOUNT}
        validation={target}
        label="programs.template.page.setup.minimum-fundraising-target.label"
        subtext="programs.template.page.setup.minimum-fundraising-target.subtext"
        onChange={onChange}
      />
    </TemplatePage>
  );
};
