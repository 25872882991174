import React, { Component } from 'react';
import { Modal, Header, Button, List } from 'semantic-ui-react';
import { I18n, Translate } from 'react-redux-i18n';

class StripeReconnectModal extends Component {
  render() {
    const { isOpened, onClose } = this.props;

    return (
      <Modal
        open={isOpened}
        className="stripe-reconnect-modal"
        size="tiny"
        onClose={onClose}
        closeIcon
      >
        <Modal.Content>
          <Header
            content={I18n.t(
              'template.organisation.content.finance.modal.title'
            )}
            textAlign="center"
          />
          <div className="stripe-reconnect-content">
            {I18n.t('template.organisation.content.finance.modal.description')}
          </div>
          <List as="ul">
            {[...Array(3).keys()].map((item) => (
              <List.Item as="li">
                <p>
                  <Translate
                    value={`template.organisation.content.finance.modal.li.${
                      item + 1
                    }`}
                    dangerousHTML
                  />
                </p>
              </List.Item>
            ))}
          </List>
        </Modal.Content>

        <Modal.Actions>
          <div className="button-wrapper align-right">
            <Button onClick={onClose}>
              {I18n.t('template.organisation.content.finance.modal.cancel')}
            </Button>
            <Button
              primary
              disabled={this.props.isLoading}
              loading={this.props.isLoading}
              onClick={this.props.onReconnectClick}
            >
              {I18n.t('template.organisation.content.finance.modal.confirm')}
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default StripeReconnectModal;
