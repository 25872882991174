export const AboutElementTypes = {
  Text: 'text',
  Video: 'video',
  Image: 'image',
  PDF: 'pdf',
  ImageGallery: 'image-gallery',
  GoogleMap: 'google-map'
};

export const AboutElementStringTypes = {
  [AboutElementTypes.Text]: 'Text',
  [AboutElementTypes.Video]: 'Video',
  [AboutElementTypes.Image]: 'Image',
  [AboutElementTypes.PDF]: 'PDF',
  [AboutElementTypes.ImageGallery]: 'Image gallery',
  [AboutElementTypes.GoogleMap]: 'Google map'
};

export const AboutElementIcons = {
  [AboutElementTypes.Text]: 'pencil',
  [AboutElementTypes.Video]: 'file video outline',
  [AboutElementTypes.Image]: 'file image outline',
  [AboutElementTypes.PDF]: 'file pdf outline',
  [AboutElementTypes.ImageGallery]: 'film',
  [AboutElementTypes.GoogleMap]: 'map'
};
