export const i18CouponKeys = {
  discountTypeValue: 'discountTypeValue',
  addButton: 'buttons.add',
  saveButton: 'buttons.save',
  cancelButton: 'buttons.cancel',
  deleteButton: 'buttons.delete',
  allowOtherCouponsInSameSale: 'allowOtherCouponsInSameSale.label',
  couponIcon: {
    subtext: 'coupon-icon.subtext'
  },
  label: {
    addTitle: 'label.add-discount-code',
    editTitle: 'label.edit-discount-code'
  },
  instanceCode: {
    label: 'instance.code',
    subtext: 'instance.subtext'
  },
  usageLimitValue: {
    label: 'usageLimitValue.label',
    subtext: 'usageLimitValue.subtext',
    redeemedLabel: {
      partOneText: 'usageLimitValue.redeemedLabel.partOneText',
      partTwoText: 'usageLimitValue.redeemedLabel.partTwoText'
    }
  },
  deleteCouponModal: {
    title: 'title',
    content: 'content',
    acceptButton: 'buttons.accept',
    declineButton: 'buttons.decline'
  },
  tableList: {
    title: 'tableList.title',
    editButton: 'tableList.edit-button',
    columns: {
      discountCode: 'tableList.columns.discountCode',
      amount: 'tableList.columns.amount',
      usedCount: 'tableList.columns.usedCount',
      action: 'tableList.columns.action'
    }
  }
};
