import React, { createRef, RefObject, useEffect } from 'react';
import { useState } from 'react';
import AnimateHeight from 'react-animate-height';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Form, Modal } from 'semantic-ui-react';
import { Common } from '../../../components';
import Autocomplete from '../../../components/form/autocomplete';
import { useAppDispatch } from '../../../hooks';
import { ITerminal } from '../../../models/Terminal';
import { RootState } from '../../../store';
import {
  getSearchOptions,
  defaultTerminalListSearch,
  updateTerminalBulk,
  updateTerminal
} from '../store/terminalActions';
import { unassignedFilter } from '../store/terminalReducer';

interface IAssignDeviceProps {
  terminalList: ITerminal[];
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

interface IDeviceData {
  organizationId?: number;
  campaignId?: number;
  teamId?: number;
  collectionId?: number;
  fundraiserId?: number;
}

interface IFormAutocompleteProps {
  type: string;
  options: any;
  formRef: RefObject<any>;
  value?: number;
  required?: boolean;
  onChange: (num?: number) => void;
  onSearch: (searchQuery?: string) => void;
}

const generateSearchFilter = (orgId?: number | null, campaignId?: number) => {
  let filter = [
    {
      key: 'status',
      operator: 'EqualTo',
      value: 1
    }
  ];

  if (orgId) {
    filter.push({
      key: 'organizationId',
      operator: 'EqualTo',
      value: orgId
    });
  }

  if (campaignId) {
    filter.push({
      key: 'campaignId',
      operator: 'EqualTo',
      value: campaignId
    });
  }

  return filter;
};

const FormAutocomplete = ({
  type,
  options,
  formRef,
  value,
  required,
  onChange,
  onSearch
}: IFormAutocompleteProps) => {
  let validation = required ? { required: true } : undefined;

  const generateOptions = (data: any) => {
    const { list } = data;
    if (list) {
      return list.map((x: any) => ({
        text: x.name,
        subtext: x.urlPath,
        value: x.id
      }));
    }
    return [{}];
  };

  return (
    <Form className="assign-device-form">
      <Form.Field width={16}>
        <Autocomplete
          id={`assign-device-${type}`}
          myRef={formRef}
          label={<Translate value={`terminal.list.${type}`} />}
          value={value}
          onChange={(_: any, obj: any) => onChange(obj.value)}
          search={(_: any, { searchQuery }: any) => onSearch(searchQuery)}
          options={generateOptions(options.data)}
          loading={options.ready}
          validation={validation}
        />
      </Form.Field>
    </Form>
  );
};

export const AssignDevice = ({
  terminalList,
  isOpen,
  setIsOpen
}: IAssignDeviceProps) => {
  // hooks
  const dispatch = useAppDispatch();
  const terminalReducer = useSelector((state: RootState) => state.terminal);
  // states
  const [deviceData, setDeviceData] = useState<IDeviceData>();
  const [isLoading, setIsLoading] = useState(false);
  // refs
  const orgRef = createRef<any>();
  const campaignRef = createRef<any>();
  const teamRef = createRef<any>();
  const collectionRef = createRef<any>();
  const fundraiserRef = createRef<any>();
  // defaults
  const singleAssign = terminalList.length === 1;

  let timer: NodeJS.Timeout | null = null;

  const searchOptions = (action: any) => {
    timer && clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(action);
    }, 500);
  };

  const onOrganizationSearch = (searchQuery?: string) => {
    searchOptions(getSearchOptions(searchQuery, 'orgOptions', 'organization'));
  };

  const onOrganizationSelect = (orgId?: number) => {
    const filter = generateSearchFilter(orgId);
    dispatch(getSearchOptions('', 'campaignOptions', 'page/campaign', filter));
    setDeviceData({ ...deviceData, organizationId: orgId });
  };

  const onCampaignSearch = (searchQuery?: string) => {
    if (!deviceData?.organizationId) return;
    const filter = generateSearchFilter(deviceData.organizationId);
    searchOptions(
      getSearchOptions(searchQuery, 'campaignOptions', 'page/campaign', filter)
    );
  };

  const onCampaignSelect = (campaignId?: number) => {
    const filter = generateSearchFilter(null, campaignId);
    dispatch(
      getSearchOptions('', 'collectionOptions', 'page/collection', filter)
    );
    dispatch(getSearchOptions('', 'teamOptions', 'page/team', filter));
    // prettier-ignore
    dispatch(getSearchOptions('', 'fundraiserOptions', 'page/fundraiser', filter));
    setDeviceData({ ...deviceData, campaignId });
  };

  const onCollectionSearch = (searchQuery?: string) => {
    if (!deviceData?.campaignId) return;
    const filter = generateSearchFilter(null, deviceData.campaignId);
    searchOptions(
      getSearchOptions(
        searchQuery,
        'collectionOptions',
        'page/collection',
        filter
      )
    );
  };

  const onFundraiserSelect = (fundraiserId?: number) => {
    setDeviceData({
      ...deviceData,
      fundraiserId,
      teamId: undefined
    });
    teamRef?.current?.clearValue();
  };

  const onFundraiserSearch = (searchQuery?: string) => {
    if (!deviceData?.fundraiserId) return;
    const filter = generateSearchFilter(null, deviceData.fundraiserId);
    searchOptions(
      getSearchOptions(
        searchQuery,
        'fundraiserOptions',
        'page/fundraiser',
        filter
      )
    );
  };

  const onTeamSelect = (teamId?: number) => {
    setDeviceData({
      ...deviceData,
      fundraiserId: undefined,
      teamId
    });
    fundraiserRef?.current?.clearValue();
  };

  const onTeamSearch = (searchQuery?: string) => {
    if (!deviceData?.campaignId) return;
    const filter = generateSearchFilter(null, deviceData.campaignId);
    searchOptions(
      getSearchOptions(searchQuery, 'teamOptions', 'page/team', filter)
    );
  };

  const onAssignConfirm = () => {
    if (!deviceData || terminalList.length === 0) return;
    setIsLoading(true);
    if (deviceData?.organizationId) {
      let action = {};
      if (singleAssign) {
        action = updateTerminal({
          ...terminalList[0],
          // explicitly set the data on assignment in case the dirty data exists in terminalList[0]
          // i.e. unassignment incorrectly set data
          campaignId: deviceData.campaignId,
          organizationId: deviceData.organizationId,
          fundraiserId: deviceData.fundraiserId,
          teamId: deviceData.teamId,
          collectionId: deviceData.collectionId
        });
      } else {
        action = updateTerminalBulk({
          ids: terminalList.map((x) => x.id),
          campaignId: deviceData.campaignId,
          organizationId: deviceData.organizationId
        });
      }
      dispatch(action);
    }
  };

  const onCancel = () => {
    orgRef?.current?.clearValue();
    campaignRef?.current?.clearValue();
    teamRef?.current?.clearValue();
    collectionRef?.current?.clearValue();
    fundraiserRef?.current?.clearValue();
    setDeviceData(undefined);
    setIsLoading(false);
    setIsOpen(false);
    dispatch(defaultTerminalListSearch(unassignedFilter));
  };

  useEffect(() => {
    dispatch(getSearchOptions('', 'orgOptions', 'organization'));
  }, []);

  useEffect(() => {
    if (
      isOpen &&
      isLoading &&
      (terminalReducer.recordEdit.success.show ||
        terminalReducer.recordEdit.error)
    ) {
      setTimeout(() => onCancel(), 100);
    }
  }, [terminalReducer, terminalReducer.recordEdit.error]);

  return (
    <Modal open={isOpen} size="tiny">
      <Modal.Content>
        <h3 className="modal-title">
          <Translate
            value="terminal.assign-modal.title"
            count={terminalList.length}
          />
        </h3>
        <p className="modal-description">
          <Translate
            value={`terminal.assign-modal.description`}
            count={terminalList.length}
          />
        </p>
        <FormAutocomplete
          type="organization"
          formRef={orgRef}
          options={terminalReducer.orgOptions}
          value={deviceData?.organizationId}
          onChange={onOrganizationSelect}
          onSearch={onOrganizationSearch}
          required
        />
        <FormAutocomplete
          type="campaign"
          formRef={campaignRef}
          options={terminalReducer.campaignOptions}
          value={deviceData?.campaignId}
          onChange={onCampaignSelect}
          onSearch={onCampaignSearch}
          required
        />
        {terminalList.length === 1 && (
          <>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={
                deviceData?.campaignId &&
                terminalReducer.collectionOptions.data?.list?.length > 0
                  ? 'auto'
                  : 0
              }
            >
              <FormAutocomplete
                type="collection"
                formRef={collectionRef}
                options={terminalReducer.collectionOptions}
                value={deviceData?.collectionId}
                onChange={(collectionId?: number) =>
                  setDeviceData({ ...deviceData, collectionId })
                }
                onSearch={onCollectionSearch}
              />
            </AnimateHeight>
            <AnimateHeight
              animateOpacity
              duration={300}
              height={
                deviceData?.campaignId &&
                terminalReducer.teamOptions.data?.list?.length > 0
                  ? 'auto'
                  : 0
              }
            >
              <FormAutocomplete
                type="team"
                formRef={teamRef}
                options={terminalReducer.teamOptions}
                value={deviceData?.teamId}
                onChange={onTeamSelect}
                onSearch={onTeamSearch}
              />

              <FormAutocomplete
                type="fundraiser"
                formRef={fundraiserRef}
                options={terminalReducer.fundraiserOptions}
                value={deviceData?.fundraiserId}
                onChange={onFundraiserSelect}
                onSearch={onFundraiserSearch}
              />
            </AnimateHeight>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Common.Button
          disabled={isLoading}
          onClick={onCancel}
          content={<Translate value="terminal.unassign-modal.cancel" />}
        />
        <Common.Button
          primary
          loading={isLoading}
          disabled={!deviceData?.organizationId}
          content={<Translate value="terminal.assign-modal.assign" />}
          onClick={onAssignConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};
