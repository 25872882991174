import React from 'react';

export const ColouredText = (props) => {
  const { className, title, backgroundColor } = props;

  return (
    <div className={className} style={{ backgroundColor: backgroundColor }}>
      {title}
    </div>
  );
};
