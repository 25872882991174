//------------React/Redux
import React, { Component } from 'react';
import { connect } from 'react-redux';

//------------Components
import { CollectionBuilder } from '../../collectionBuilder';
import DefaultAmountItem from '../../collectionBuilder/components/donationAmountItem';

//------------Helpers
import * as templateHelper from '../templateHelper';
import { MAX_DONATION_AMOUNT_ITEMS_COUNT } from '../../collectionBuilder/constants';

//------------Constants
interface ManagedDefaultAmountsProps {
  id?: string;
  value?: any;
  path?: string;
  disabled?: boolean;
  validation?: any;
  onChange?: any;
  parser?: (s?: string) => any;
}

class ManagedDefaultAmounts extends Component {
  props: ManagedDefaultAmountsProps;

  constructor(props: ManagedDefaultAmountsProps) {
    super(props);
    this.props = {
      ...props
    };
  }

  getModel = () => {
    const isFirstItem = !this.props.value || this.props.value.length === 0;
    return {
      isDefault: isFirstItem,
      amount: '',
      description: ''
    };
  };

  render() {
    const { path, disabled, validation, onChange } = this.props;
    return (
      <CollectionBuilder
        id={this.props.id}
        path={path}
        disabled={disabled}
        onChange={onChange}
        validation={validation}
        maxItemsCount={MAX_DONATION_AMOUNT_ITEMS_COUNT}
        entity="page"
        i18Key="donation.default-donation-amounts"
        model={this.getModel()}
        itemType={<DefaultAmountItem parser={this.props.parser} />}
      ></CollectionBuilder>
    );
  }
}

const mapState = (
  state: { templateBuilderEx: any },
  ownProps: { path: any }
) => {
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    value,
    disabled: templateHelper.getState(
      state.templateBuilderEx,
      ownProps.path,
      'disabled'
    )
  };
};

// @ts-ignore
export const TemplateDefaultAmounts = connect(mapState)(ManagedDefaultAmounts);
