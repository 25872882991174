import React from 'react';
import get from "lodash.get";

import { formatDecimalNumber } from '../../../helpers/converterHelper';

import { ViewTable } from '../../../components/layouts/viewTable';
import { ViewSectionRow } from '../../../components/layouts/viewSectionRow';
import { DonationPaymentSectionRow } from './components/donationPaymentSectionRow';

import {
  ONLINE_DONATION_TYPE,
  PENDING_DONATION_TYPE
} from '../../campaign/constants';
import {
  donationI18Keys,
  getPaymentMethodValue
} from './constants/donationSettings';

import currencyHelper from '../../../helpers/currencyHelper';

export const DonationDetails = (props) => {
  const { donor, amount: donationAmount, financials, type, message, currency: donationCurrency, organization } = props.donation;
  const { isShowEmptyFields, displayOrgCurrency } = props;

  const amount = displayOrgCurrency && get(financials, "exchangeRate")
    ? donationAmount * get(financials, "exchangeRate")
    : donationAmount;
  const currency = displayOrgCurrency ? get(organization, "currency") : donationCurrency;

  const firstName = donor?.firstName;
  const lastName = donor?.lastName;
  const email = donor?.email;
  const net = financials?.feeDetail?.net;
  const donationTotalFees = financials && donationAmount - net;
  const totalFees = displayOrgCurrency ? donationTotalFees * get(financials, "exchangeRate") : donationTotalFees;

  const isOnlineOrPending =
    type === ONLINE_DONATION_TYPE || type === PENDING_DONATION_TYPE;

  const amountString = currencyHelper.formatCurrency(amount, currency);
  const totalFeesString = currencyHelper.formatCurrency(totalFees, currency);

  const creditCardTitle = getPaymentMethodValue(financials?.paymentMethod);

  return (
    <React.Fragment>
      <div className="donation-details-section">
        <ViewTable title="Donation Details">
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.first-name`}
            data={firstName}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.last-name`}
            data={lastName}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.email`}
            data={email}
          />
          <ViewSectionRow
            isShowEmptyFields={isShowEmptyFields}
            title={`${donationI18Keys.donationDetailsI18Key}.support-message`}
            data={message}
          />
          <ViewSectionRow
            title={`${donationI18Keys.donationDetailsI18Key}.amount`}
            data={amountString}
          />
          {isOnlineOrPending && (
            <React.Fragment>
              <ViewSectionRow
                title={`${donationI18Keys.donationDetailsI18Key}.total-fees`}
                data={totalFeesString}
              />
              <DonationPaymentSectionRow
                title={creditCardTitle}
                data={financials}
              />
            </React.Fragment>
          )}
        </ViewTable>
      </div>
    </React.Fragment>
  );
};
