import React from 'react';
import { connect } from 'react-redux';
import * as templateHelper from '../template/templateHelper';
import { TemplateHeader } from '../template/templateHeader';
import { TemplateInput } from '../template/templateInput';
import { TemplateText } from '../template/templateText';
import {
  Button,
  Icon,
  Message,
  MessageHeader,
  MessageContent,
} from 'semantic-ui-react';
import { v4 as uuid } from 'uuid';
import './web-tracking.css';
import { I18n } from 'react-redux-i18n';
import { InfoBox } from '../common/infoBox';

export const WebTrackingValidation = {
  GoogleAnalytics: {
    pattern: /UA-[0-9]+-[0-9]/g,
    errorMessegeKey: 'errors.validation.pattern.googleanalytics'
  },
  GoogleTagManager: {
    pattern: /GTM-[A-Z0-9]+/g,
    errorMessegeKey: 'errors.validation.pattern.googletagmanager'
  },
  FacebookPixel: {
    pattern: /[0-9]{15}/g,
    errorMessegeKey: 'errors.validation.pattern.facebookpixel'
  }
};

export type WebTrackerKey =
| 'GoogleTagManager'
| 'FacebookPixel'
| 'GoogleAnalytics';

export interface IWebTracker {
  id: string;
  key: WebTrackerKey;
  value: string;
}

export function useWebTracking({
  type,
  path,
  campaignValues = [],
  organisationValues = [],
  onChange
}: WebTrackingViewStateProps & WebTrackingViewProps) {
  const [trackers, setTrackers] = React.useState<IWebTracker[]>(
    (type === "campaign" ? campaignValues : organisationValues).map((item) => ({
      ...item,
      id: item?.id || uuid()
    }))
  );

  React.useEffect(() => {
    if (onChange) onChange(path, trackers);
  }, [trackers]);

  const handleOnAdd = React.useCallback(
    (key: WebTrackerKey, value: string = '') => {
      setTrackers((items) => [...items, { id: uuid(), key, value }]);
    },
    [setTrackers]
  );

  const handleOnRemove = React.useCallback(
    (id: string) => {
      setTrackers((items) => items.filter((item) => item.id !== id));
    },
    [setTrackers]
  );

  const handleOnUpdate = React.useCallback(
    (id: string, value: string) => {
      setTrackers((items) =>
        items.map((item) =>
          item.id === id
            ? {
              ...item,
              value
            }
            : item
        )
      );
    },
    [setTrackers]
  );

  const gtms = trackers.filter((item) => item.key === 'GoogleTagManager');
  const fbps = trackers.filter((item) => item.key === 'FacebookPixel');

  return {
    gtms,
    fbps,
    handleOnAdd,
    handleOnUpdate,
    handleOnRemove
  };
}

export interface WebTrackingViewStateProps {
  campaignValues: IWebTracker[];
  organisationValues: IWebTracker[];
}

export interface WebTrackingViewProps {
  type: "organisation" | "campaign";
  path: string;
  onChange: (path: string, value: any) => void;
}

export const WebTrackingView: React.FunctionComponent<
WebTrackingViewProps & WebTrackingViewStateProps
> = ({ type, path, campaignValues, organisationValues, onChange }) => {
  const { fbps, gtms, handleOnAdd, handleOnUpdate, handleOnRemove } =
  useWebTracking({
    type,
    path,
    campaignValues,
    organisationValues,
    onChange
  });

  return (
    <>
      <TemplateHeader
        as="h2"
        label="template.organisation.content.advanced.web-tracking.header"
      />
      {type === "organisation" && (
        <TemplateText
          className="web-tracking__description"
          size="small"
          label="template.organisation.content.advanced.web-tracking.description"
        />
      )}
      {/* Tag manager  */}
      <TemplateHeader
        as="h3"
        label="template.organisation.content.advanced.web-tracking.googletagmanager.header"
      />
      <div className="web-tracking__content-section">
        <TemplateText
          className="web-tracking__description"
          size="small"
          label="template.organisation.content.advanced.web-tracking.googletagmanager.description"
        />
        <div className="web-tracking__description">
          <a
            href="https://grassrootz.zendesk.com/hc/en-us/articles/6812966400655-Setting-up-Google-Analytics-4-using-Google-Tag-Manager-on-Grassrootz"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="info circle" />
            {I18n.t(
              'template.organisation.content.advanced.web-tracking.googletagmanager.link-text'
            )}
          </a>
        </div>
      </div>

      {type === "campaign" && organisationValues.filter((item) => item.key === 'GoogleTagManager').length > 0 && (
        <InfoBox
          header="template.page.content.advanced.web-tracking.googletagmanager.ids-in-use.header"
          content="template.page.content.advanced.web-tracking.googletagmanager.ids-in-use.description"
        />
      )}

      {gtms.map((item, index) => (
        <div className="web-tracking__input-field" key={item.id}>
          <TemplateInput
            validation={WebTrackingValidation.GoogleTagManager}
            placeholder="template.organisation.content.advanced.web-tracking.googletagmanager.placeholder"
            value={item.value}
            onChange={(path: string, value: string) => {
              handleOnUpdate(item.id, value);
            }}
          />
          <Button
            icon
            style={{
              margin: '0'
            }}
            type="button"
            onClick={() => {
              handleOnRemove(item.id);
            }}
          >
            <Icon name="close" />
          </Button>
        </div>
      ))}
      <Button type="button" onClick={() => handleOnAdd('GoogleTagManager')}>
        Add
      </Button>

      {/* FB pixel  */}
      <TemplateHeader
        as="h3"
        label="template.organisation.content.advanced.web-tracking.facebookpixel.header"
      />

      <div className="web-tracking__content-section">
        <TemplateText
          className="web-tracking__description"
          size="small"
          label="template.organisation.content.advanced.web-tracking.facebookpixel.description"
        />
        <div className="web-tracking__description">
          <a
            href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
            target="_blank"
            rel="noreferrer"
          >
            <Icon name="info circle" />
            {I18n.t(
              'template.organisation.content.advanced.web-tracking.facebookpixel.link-text'
            )}
          </a>
        </div>
      </div>

      {type === "campaign" && organisationValues.filter((item) => item.key === 'FacebookPixel').length > 0 && (
        <InfoBox
          header="template.page.content.advanced.web-tracking.facebookpixel.ids-in-use.header"
          content="template.page.content.advanced.web-tracking.facebookpixel.ids-in-use.description"
        />
      )}

      {fbps.map((item, index) => (
        <div className="web-tracking__input-field" key={item.id}>
          <TemplateInput
            validation={WebTrackingValidation.FacebookPixel}
            placeholder="template.organisation.content.advanced.web-tracking.facebookpixel.placeholder"
            value={item.value}
            onChange={(path: string, value: string) =>
              handleOnUpdate(item.id, value)
            }
          />
          <Button
            icon
            style={{
              margin: '0'
            }}
            type="button"
            onClick={() => handleOnRemove(item.id)}
          >
            <Icon name="close" />
          </Button>
        </div>
      ))}
      <Button type="button" onClick={() => handleOnAdd('FacebookPixel')}>
        Add
      </Button>
    </>
  );
};

const mapState = (
  state: any,
  ownProps: WebTrackingViewProps
): WebTrackingViewStateProps => {
  const ownValues = templateHelper.getValue(state.templateBuilderEx, ownProps.path);
  return {
    campaignValues: ownProps.type === "campaign" ? ownValues : [],
    organisationValues: ownProps.type === "organisation" ? ownValues : templateHelper.getValue(state.templateBuilderEx, "organization.template.value.webTracking") || [],
  };
};

export const WebTrackingTemplate = connect(mapState)(WebTrackingView);
