import { paths } from '../components/authentication/constants';
import update from '../helpers/update';
import { Record } from './bin/utility';
import * as entityHandlers from '../modules/bin/entityHandlers';

export const RESET_EMAIL_CHANGE = 'authentication/RESET_EMAIL_CHANGE';
export const RESET_PASSWORD_CHANGE = 'authentication/RESET_PASSWORD_CHANGE';
export const RESET_CONFIRM_PASSWORD_CHANGE =
  'authentication/RESET_CONFIRM_PASSWORD_CHANGE';

export const FORGOT_PASSWORD_REQUESTED =
  'authentication/FORGOT_PASSWORD_REQUESTED';
export const FORGOT_PASSWORD_SUCCESS = 'authentication/FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'authentication/FORGOT_PASSWORD_FAILURE';

export const UPDATE_PASSWORD_REQUESTED =
  'authentication/UPDATE_PASSWORD_REQUESTED';
export const UPDATE_PASSWORD_SUCCESS = 'authentication/UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAILURE = 'authentication/UPDATE_PASSWORD_FAILURE';

export const ACTIVATE_USER_REQUESTED = 'authentication/ACTIVATE_USER_REQUESTED';
export const ACTIVATE_USER_SUCCESS = 'authentication/ACTIVATE_USER_SUCCESS';
export const ACTIVATE_USER_FAILURE = 'authentication/ACTIVATE_USER_FAILURE';

export const UPDATE_ACCOUNT_REQUESTED =
  'authentication/UPDATE_ACCOUNT_REQUESTED';
export const UPDATE_ACCOUNT_SUCCESS = 'authentication/UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILURE = 'authentication/UPDATE_ACCOUNT_FAILURE';

export const MESSAGE_CLEAR = 'authentication/MESSAGE_CLEAR';
export const ON_VALUE_CHANGE = 'authentication/ON_VALUE_CHANGE';

export const forgotPassword = (email) => {
  return entityHandlers.createRecord(
    'account/password/reset',
    FORGOT_PASSWORD_REQUESTED,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAILURE,
    { email: email },
    null,
    'forgotPasswordRecord'
  );
};

export const updatePassword = (token, newPassword) => {
  const data = {
    token: token,
    newPassword: newPassword
  };

  return entityHandlers.createRecord(
    'account/password/update',
    UPDATE_PASSWORD_REQUESTED,
    UPDATE_PASSWORD_SUCCESS,
    UPDATE_PASSWORD_FAILURE,
    data,
    null,
    'updatePasswordRecord'
  );
};

export const clearRecordMessages = (recordKey) => ({
  type: MESSAGE_CLEAR,
  payload: { key: recordKey }
});

export const activateUser = (inviteId) => {
  return entityHandlers.createRecord(
    'session/activate',
    ACTIVATE_USER_REQUESTED,
    ACTIVATE_USER_SUCCESS,
    ACTIVATE_USER_FAILURE,
    { id: inviteId },
    null,
    'invite.activateRecord'
  );
};

export const updateAccount = (id, record) => {
  return entityHandlers.patchRecord(
    'account',
    UPDATE_ACCOUNT_REQUESTED,
    UPDATE_ACCOUNT_SUCCESS,
    UPDATE_ACCOUNT_FAILURE,
    id,
    record,
    'invite.createAccountRecord'
  );
};

export const resetEmailChange = (email) => {
  return {
    type: RESET_EMAIL_CHANGE,
    payload: {
      value: email,
      path: paths.RESET_EMAIL
    }
  };
};

export const resetPasswordChange = (password) => {
  return {
    type: RESET_PASSWORD_CHANGE,
    payload: {
      value: password,
      path: paths.RESET_PASSWORD
    }
  };
};

export const resetConfirmPasswordChange = (confirmPassword) => {
  return {
    type: RESET_CONFIRM_PASSWORD_CHANGE,
    payload: {
      value: confirmPassword,
      path: paths.RESET_CONFIRM_PASSWORD
    }
  };
};

export const onValueChange = (path, value) => {
  return {
    type: ON_VALUE_CHANGE,
    payload: {
      path: path,
      value: value
    }
  };
};

const initialState = {
  forgotPasswordRecord: Record.getDefaultState(),
  updatePasswordRecord: Record.getDefaultState(),
  reset: {
    password: '',
    confirmPassword: '',
    email: '',
    emailValidation: {
      email: true,
      required: true
    },
    passwordValidation: {
      required: true
    }
  },
  invite: {
    activateRecord: Record.getDefaultState(),
    createAccountRecord: Record.getDefaultState(),
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: ''
  }
};

const authenticationReducer = (state = initialState, action) => {
  let newState = state;

  switch (action.type) {
    case RESET_EMAIL_CHANGE:
    case RESET_PASSWORD_CHANGE:
    case RESET_CONFIRM_PASSWORD_CHANGE:
    case ON_VALUE_CHANGE:
      newState = update.set(
        newState,
        action.payload.path,
        action.payload.value
      );
      break;

    case UPDATE_PASSWORD_REQUESTED:
    case FORGOT_PASSWORD_REQUESTED:
    case ACTIVATE_USER_REQUESTED:
    case UPDATE_ACCOUNT_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case FORGOT_PASSWORD_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_ACCOUNT_SUCCESS:
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      break;

    case ACTIVATE_USER_SUCCESS:
      const { data } = action.payload;
      newState = entityHandlers.getRecordSuccessHandler(state, action);
      newState = update.set(newState, 'invite.lastName', data.account.lastName);
      newState = update.set(
        newState,
        'invite.firstName',
        data.account.firstName
      );
      break;

    case FORGOT_PASSWORD_FAILURE:
    case UPDATE_PASSWORD_FAILURE:
    case ACTIVATE_USER_FAILURE:
    case UPDATE_ACCOUNT_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case MESSAGE_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    default:
      break;
  }

  return newState;
};

export default authenticationReducer;
