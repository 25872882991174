export const exportFileNames = {
  DEFAULT_NAME: 'exportFile.csv',
  ADMINS: 'exportAdmins.csv',
  DONORS: 'exportDonors.csv',
  FUNDRAISERS: 'exportFundraisers.csv',
  SALES: 'exportSales.csv',
  TICKETS: 'exportTickets.csv',
  DONATIONS: 'exportDonations.csv',
  ORGANIZATIONS: 'exportOrganizations.csv',
  CAMPAIGNS: 'exportCampaigns.csv',
  EVENT_ORGANIZATIONS: 'exportEventOrganizations.csv',
  EVENT_DONATIONS: 'exportEventDonations.csv',
  EVENT_FUNDRAISERS: 'exportEventFundraisers.csv',
  MERCHANDISE: 'exportMerchandise.csv',
  RECURRING: 'exportRecurring.csv',
  PROGRAMS: 'exportPrograms.csv',
  APPLICATIONS: 'exportApplications.csv',
  PENDING_APPROVAL_PROGRAMS: 'exportPendingApprovalPrograms.csv',
  VIPPROGRAMS: 'exportVIPsPrograms.csv',
  EVENT_PROGRAMS: 'exportPrograms.csv',
  EVENT_APPLICATIONS: 'exportApplications.csv',
  EVENT_PENDING_APPROVAL_PROGRAMS: 'exportEventPendingApprovalPrograms.csv',
  EVENT_VIP_PROGRAMS: 'exportVipProrgams.csv',
  CAMPAIGN_PROGRAMS: 'exportCampaignPrograms.csv',
  CAMPAIGN_APPLICATIONS: 'exportApplications.csv',
  CAMPAIGN_PENDING_APPROVAL_PROGRAMS:
    'exportCampaignPendingApprovalPrograms.csv',
  CAMPAIGN_VIP_PRORGAMS: 'exportVipProrgams.csv',
  CAMPAIGNS_DASHBOARD: 'campaginDashboard.csv',
  EVENTS_DASHBOARD: 'eventDashboard.csv',
  ORGANIZATIONS_DASHBOARD: 'organisationDashboard.csv'
};
