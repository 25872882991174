import _ from 'lodash';
import { toTitleCase } from '../helpers/stringExstension';
import { IColumnData } from '../models/table/columnData';
import {
  notificationEntityOptions,
  notificationEventTypeOptions
} from '../pages/webhooks/constants';

const ViewListPath = 'webhooks.page.view.list';

export const webhookHeaders = {
  ORGANIZATION: `${ViewListPath}.organization`,
  ENTITY: `${ViewListPath}.entity`,
  TYPE: `${ViewListPath}.type`,
  ENDPOINT: `${ViewListPath}.endpoint`,
  SUCCESS: `${ViewListPath}.success`,
  FAILED: `${ViewListPath}.failed`,
  TOTAL: `${ViewListPath}.total`,
  PERCENTAGE: `${ViewListPath}.percentage`
};

export const webhookColumns = {
  ORGANIZATION_NAME: 'organization.name',
  ENTITY: 'singleEntity',
  TYPE: 'singleType',
  SUBSCRIBER_ENDPOINT: 'subscriberEndpoint',
  SUCCESS_COUNT: 'totalSuccessCount',
  FAILED_COUNT: 'totalFailedCount',
  TOTAL_COUNT: 'totalCallCount',
  PERCENTAGE: 'successPercentage'
};

export const webhookDefaultOrderBy = {
  path: 'createdAt',
  order: 'desc'
};

export const webhooksAvailableFiltersKeys = ['organizationId'];

export const webhookCreateVertical = [
  {
    name: 'webhooks.page.setup.title',
    description: 'webhooks.page.setup.title',
    isActive: true
  }
];

export const webhookEditVertical = [
  {
    name: 'webhooks.page.edit.title',
    description: 'webhooks.page.edit.title',
    isActive: true
  }
];

export const getEntityText = (
  value: any,
  options: { value: any; text: string; locale?: string }[]
) => {
  const data = _.find(options, (option) => {
    let text = option.text;
    if (option.locale) {
      text = option.locale;
    }
    return text === toTitleCase(value);
  });
  if (data) {
    return data.text;
  }
  return value;
};

export const webhookColumnData: IColumnData[] = [
  {
    header: webhookHeaders.ORGANIZATION,
    path: webhookColumns.ORGANIZATION_NAME,
    defaultVisible: false
  },
  {
    header: webhookHeaders.ENTITY,
    path: webhookColumns.ENTITY,
    defaultVisible: false,
    customFormatter: (v: string) => getEntityText(v, notificationEntityOptions)
  },
  {
    header: webhookHeaders.TYPE,
    path: webhookColumns.TYPE,
    defaultVisible: false,
    // prettier-ignore
    customFormatter: (v: string) => getEntityText(v, notificationEventTypeOptions)
  },
  {
    header: webhookHeaders.ENDPOINT,
    path: webhookColumns.SUBSCRIBER_ENDPOINT,
    defaultVisible: true
  },
  {
    header: webhookHeaders.SUCCESS,
    path: webhookColumns.SUCCESS_COUNT,
    defaultVisible: true
  },
  {
    header: webhookHeaders.FAILED,
    path: webhookColumns.FAILED_COUNT,
    defaultVisible: true
  },
  {
    header: webhookHeaders.TOTAL,
    path: webhookColumns.TOTAL_COUNT,
    defaultVisible: true
  },
  {
    header: webhookHeaders.PERCENTAGE,
    path: webhookColumns.PERCENTAGE,
    defaultVisible: true,
    customFormatter: (v: number) => `${v.toFixed(2)}%`
  }
];
