import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Grid, Item, Button } from 'semantic-ui-react';

import * as organizationActions from './store/organizationActions';
import stripeIcon from '../../images/stripeIcon.png';
import { Record } from '../../modules/bin/utility';
import { withRouter } from 'react-router-dom';
import * as querystring from 'querystring';
import * as templateHelper from '../../components/template/templateHelper';
import StripeReconnectModal from './stripe-reconnect-model';
import { StripeUrlModal } from './stripeUrlModal';
import { stripeLink } from './constants';

class TemplateStripeConnection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isReconnectModalOpen: false
    };
  }

  onConnectClick = () => {
    this.props.organizationActions.getStripeLink(this.props.organizationId);
  };

  onReconnectClick = () => {
    this.props.organizationActions.getStripeReconnectLink(
      this.props.organizationId
    );
  };

  redirectToStripe = () => {
    let stripeUrl = '';
    if (this.props.isConnected) {
      let parsedUrl = querystring.parse(this.props.stripeLinkRecord.data.data);
      parsedUrl['redirect_uri'] = `${window.location.origin}/stripe/connect`;
      stripeUrl = unescape(querystring.stringify(parsedUrl));
    } else {
      stripeUrl = this.props.stripeLinkRecord.data.data;
    }
    window.location.href = stripeUrl;
  };

  setReconnectModalOpen = (value) => {
    this.setState({
      isReconnectModalOpen: value
    });
  };

  render() {
    if (Record.isRecordReady(this.props.stripeLinkRecord)) {
      this.redirectToStripe();
    }
    const buttonCaption = this.props.isConnected ? 'Connected' : 'Connect';
    const accountKey = this.props.accountKey;

    return (
      <React.Fragment>
        <StripeReconnectModal
          isOpened={this.state.isReconnectModalOpen}
          isLoading={Record.isRecordLoading(this.props.stripeLinkRecord)}
          onClose={this.setReconnectModalOpen.bind(this, false)}
          onReconnectClick={this.onReconnectClick}
        />
        <Grid style={{ marginTop: 12 }}>
          <Grid.Row>
            <Grid.Column width={11}>
              <Item.Group>
                <Item>
                  <Item.Image size="tiny" src={stripeIcon} />
                  <Item.Content>
                    <Item.Header>Stripe</Item.Header>
                    <Item.Description>
                      <Translate
                        value="template.organisation.content.finance.description"
                        dangerousHTML
                        {...{ stripeLink }}
                      />
                    </Item.Description>
                    {accountKey && (
                      <Item.Description>
                        <Translate
                          value="template.organisation.content.finance.accountKey"
                          dangerousHTML
                          {...{ accountKey }}
                        />
                      </Item.Description>
                    )}
                    {this.props.isSystemAdmin && (
                      <p
                        className="Links"
                        style={{
                          cursor: "pointer",
                          marginTop: "1rem",
                        }}
                        onClick={() => this.props.organizationActions.toggleStripeUrlModal()}
                      >
                        {I18n.t('template.organisation.content.finance.change')}
                      </p>
                    )}
                  </Item.Content>
                </Item>
              </Item.Group>
            </Grid.Column>
            <Grid.Column width={5}>
              {!this.props.isConnected && <br />}
              <div className="btnStripeContainer">
                <Button
                  id="btnStripeConnect"
                  style={{ marginRight: '1.1%' }}
                  floated="right"
                  onClick={this.onConnectClick}
                  loading={Record.isRecordLoading(this.props.stripeLinkRecord)}
                  disabled={this.props.isConnected}
                >
                  {buttonCaption}
                </Button>
                {this.props.isConnected && (
                  <div className="btnStripeReconnect">
                    <p
                      className="Links"
                      onClick={this.setReconnectModalOpen.bind(this, true)}
                    >
                      {I18n.t(
                        'template.organisation.content.finance.reconnect'
                      )}
                    </p>
                  </div>
                )}
              </div>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <StripeUrlModal />
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const paymentPlatforms = templateHelper.getValue(
    state.templateBuilderEx,
    ownProps.path
  );

  const isConnected = paymentPlatforms?.some((item) => {
    return item.platform === 'stripe';
  });

  return {
    isConnected,
    organizationId: templateHelper.getValue(state.templateBuilderEx, 'id'),
    stripeLinkRecord: state.organization.stripeLinkRecord,
    accountKey: templateHelper.getValue(
      state.templateBuilderEx,
      'paymentPlatforms[0].accountKey'
    ),
    isSystemAdmin: state.session.isSystemAdmin,
  };
};

const mapDispatch = (dispatch) => {
  return {
    organizationActions: bindActionCreators(organizationActions, dispatch),
  };
};
export default withRouter(
  connect(mapState, mapDispatch)(TemplateStripeConnection)
);
