import Input from './input';
import TextArea from './textArea';
import InputView from './inputView';
import FormBuilderView from './formBuilderView';
import NumberInput from './numberInput';
import Checkbox from './checkbox';
import HtmlEditor from '../common/quill/htmlEditor_new';
import Upload from './upload';
import ColorPicker from './colorPicker';
import Dropdown from './dropdown';
import Autocomplete from './autocomplete';
import Search from './search';
import EditableUpload from './editableUpload';

export default {
  Input,
  InputView,
  FormBuilderView,
  TextArea,
  NumberInput,
  Checkbox,
  HtmlEditor,
  Upload,
  ColorPicker,
  Dropdown,
  Autocomplete,
  Search,
  EditableUpload
};
