import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import get from 'lodash.get';

import * as ticketingActions from '../store/ticketingActions';
import * as donationActions from '../../donation/store/donationActions';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { setRedirectUrl } from '../../../modules/session';
import { formBuilderActions } from '../../../components/formbuilder/store';

import clear from '../../../components/clear';
import { Record } from '../../../modules/bin/utility';

import { Button, Grid } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import BasePage from '../../../components/common/basePageView';
import RecordResultMessage from '../../../components/common/recordResult';
import RefundSaleWindowContainer from '../modals/refundSaleWindow/RefundSaleWindow';
import RefundTicketWindowContainer from '../modals/refundTicketWindow';
import ReceiptSaleWindowContainer from '../modals/receiptSaleWindow';
import ReceiptTicketWindowContainer from '../modals/receiptTicketWindow';
import { TicketingStatus, TicketingHeader } from '../components';
import TicketingTemplate from '../template';
import { TemplateOptions } from '../../../components/template/templateOptions';
import {
  saleContract,
  getTicketContract,
  templateTabs,
  getMerchandiseContract
} from '../ticketingContracts';
import { saleShippingType } from '../constants';

class EditTicketing extends Component {
  constructor(props) {
    super(props);

    const saleId = get(this.props, 'match.params.saleId');
    this.props.getSaleRecord(saleId);
  }

  isSaleTab = () => {
    const step = get(this.props, 'match.params.step');
    return step === templateTabs.SALE.key;
  };

  getItemId = () => {
    const step = get(this.props, 'match.params.step');
    const pattern = /[0-9]+/;
    const result = step.match(pattern);
    return result ? result[0] : null;
  };

  getItemStep = () => {
    const step = get(this.props, 'match.params.step');
    const itemId = this.getItemId();

    const stepKey = Object.keys(templateTabs).find((item) => {
      return templateTabs[item].getTabName(itemId) === step;
    });

    return templateTabs[stepKey].key;
  };

  getItemNumber = () => {
    const sale = get(this.props, 'record.data.data');
    if (sale) {
      const id = this.getItemId();
      return sale.details.findIndex((element) => {
        // check that item exists if so compare string value
        // converting to string value for both items as `id` can either be number or string....
        if (element?.id && id) {
          return element.id.toString() === id.toString();
        }
        return false;
      });
    }
    return null;
  };

  onSave = (data) => {
    this.props.updateSaleRecord(data);

    const tickets = data.details;
    tickets.map((ticket) => this.props.updateTicketRecord(ticket));
  };

  clearMessages = () => {
    this.props.clearRecordMessages('saveRecord');
  };

  reissueRecord = (data, key) => {
    if (get(data, 'customer.email')) {
      this.props.showReceiptConfirmation(data, key);
    } else {
      this.props.donationActions.getReceiptMessageError('saveRecord');
    }
  };

  onReissueClick = () => {
    if (this.getItemId()) {
      const ticketNumber = this.getItemNumber();
      const data = get(this.props, `record.data.data.details[${ticketNumber}]`);
      this.reissueRecord(data, 'ticket');
    } else {
      this.reissueRecord(get(this.props, 'record.data.data'), 'sale');
    }
  };

  onRefundClick = () => {
    if (this.getItemId()) {
      const ticketNumber = this.getItemNumber();
      const data = get(this.props, `record.data.data.details[${ticketNumber}]`);

      if (data.merchandiseSkuId) {
        this.props.showRefundConfirmation(data, 'merchandise');
      }
      if (data.ticketId) {
        this.props.showRefundConfirmation(data, 'ticket');
      }
    } else {
      this.props.showRefundConfirmation(
        get(this.props, 'record.data.data'),
        'sale'
      );
    }
  };

  goToSales = () => {
    let url = '/sales';
    if (this.props.redirectUrl) {
      url = this.props.redirectUrl;
      this.props.setRedirectUrl();
    }
    this.props.history.push(url);
  };

  initTemplateBuilderModel = () => {
    const sale = get(this.props, 'record.data.data');
    const campaignId = get(sale, 'campaign.id');

    if (!this.props.isTemplateInited && sale) {
      const model = new TemplateOptions()
        .setModel(sale)
        .disable(saleContract.campaignId)
        .disable(saleContract.amount)
        .disable(saleContract.shippingType)
        .disable(saleContract.shippingCost)
        .hide(saleContract.issueInvoice)
        .hide(saleContract.oneClickFundraising);

      const shippingType = get(sale, saleContract.shippingType);
      if (!shippingType) {
        model.setValue(saleContract.shippingType, saleShippingType.Default);
      }

      if (sale.details && sale.details.length > 0) {
        for (let index = 0; index < sale.details.length; index++) {
          if (sale.details[index].ticket) {
            const ticketContract = getTicketContract(index);
            model.setValue(ticketContract.campaignId, campaignId);
            model.disable(ticketContract.campaignId);
            model.hide(ticketContract.ticketId);
            model.hide(ticketContract.issueInvoice);
            model.disable(ticketContract.amount);
            model.disable(ticketContract.ticketName);

            const ticketFormbuilder = get(sale, ticketContract.formbuilder);
            this.props.formBuilderActions.setFormBuilderModel(
              ticketFormbuilder
            );
          }
          if (sale.details[index].merchandiseSku) {
            const merchandiseContract = getMerchandiseContract(index);
            model.setValue(merchandiseContract.campaignId, campaignId);
            model.disable(merchandiseContract.campaignId);
            model.disable(merchandiseContract.amount);
            model.disable(merchandiseContract.merchandiseName);
            model.disable(merchandiseContract.merchandiseImage);

            const merchandiseFormbuilder = get(
              sale,
              merchandiseContract.formbuilder
            );
            this.props.formBuilderActions.setFormBuilderModel(
              merchandiseFormbuilder
            );
          }
        }
      }

      model.isEdit();
      this.props.templateBuilderActions.setTemplateModel(model.get());
    }
  };

  render() {
    const isLoading = Record.isRecordLoading(this.props.record);
    const isNotStarted = Record.notStarted(this.props.record);
    const isSaving = Record.isRecordLoading(this.props.saveRecord);
    const isSuccess = Record.isSuccess(this.props.saveRecord);
    const recordData = this.props.record?.data?.data;

    if (isLoading || isNotStarted) {
      return <Spinner />;
    }

    if (isSuccess) {
      this.goToSales();
    }

    this.initTemplateBuilderModel();

    const step = this.getItemStep();

    return (
      <div className="sale-detail">
        <RecordResultMessage
          record={this.props.saveRecord}
          onDismiss={this.clearMessages}
        />

        <RefundSaleWindowContainer />
        <RefundTicketWindowContainer />
        <RefundTicketWindowContainer path="merchandise" />
        <ReceiptSaleWindowContainer />
        <ReceiptTicketWindowContainer />

        <BasePage>
          <Grid>
            <Grid.Row columns={2} style={{ padding: '0px' }}>
              <Grid.Column width={5}>
                <h1 style={{ display: 'inline-block' }}>
                  {I18n.t(`ticketing.edit.titles.${step}`)}
                </h1>
              </Grid.Column>
              <Grid.Column width={11}>
                <Button style={{ float: 'right' }} onClick={this.onRefundClick}>
                  {I18n.t('ticketing.edit.buttons.refund')}
                </Button>
                <Button
                  style={{ float: 'right' }}
                  onClick={this.onReissueClick}
                >
                  {I18n.t('ticketing.edit.buttons.reissue')}
                </Button>
              </Grid.Column>
            </Grid.Row>
            <TicketingStatus ticketData={recordData} />
            {this.isSaleTab() && (
              <TicketingHeader
                ticketId={recordData?.id}
                ticketData={recordData}
              />
            )}
            <TicketingTemplate onSave={this.onSave} isLoading={isSaving} />
          </Grid>
        </BasePage>
      </div>
    );
  }
}

const mapState = ({ ticketing, templateBuilderEx, session }) => {
  return {
    record: get(ticketing, 'record'),
    saveRecord: get(ticketing, 'saveRecord'),
    isTemplateInited: templateBuilderEx.inited,
    redirectUrl: session.redirectUrl
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(ticketingActions, dispatch);
  dispatchers.donationActions = bindActionCreators(donationActions, dispatch);
  dispatchers.templateBuilderActions = bindActionCreators(
    templateBuilderActions,
    dispatch
  );
  dispatchers.formBuilderActions = bindActionCreators(
    formBuilderActions,
    dispatch
  );
  dispatchers.setRedirectUrl = bindActionCreators(setRedirectUrl, dispatch);

  return dispatchers;
};

const EditTicketingContainer = withRouter(
  clear(connect(mapState, mapDispatch)(EditTicketing))
);
export default EditTicketingContainer;
