import React, { Component } from 'react';

class TimeFormatter extends Component {
  render() {
    if (!this.props.value || !this.props.value[0] || !this.props.value[1]) {
      return null;
    }

    const hoursAbbreviate = this.props.value[0] === 1 ? 'hr' : 'hrs';
    return (
      <div>
        {this.props.value[0]}
        {hoursAbbreviate}, {this.props.value[1]}min
      </div>
    );
  }
}

export default TimeFormatter;
