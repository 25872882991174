import React, { Component } from 'react';
import { Grid, GridRow } from 'semantic-ui-react';

class ErrorPanel extends Component {
  render() {
    const { title, children } = this.props;
    return (
      <Grid centered>
        <GridRow>
          <h4>{title}</h4>
        </GridRow>
        <GridRow>{children}</GridRow>
      </Grid>
    );
  }
}

export default ErrorPanel;
