import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';
import { v4 } from 'uuid';
import { I18n } from 'react-redux-i18n';

import * as templateBuilderActions from '../../../modules/template-builder-ex';
import * as programActions from '../store/vipProgramActions';

import VipProgramTemplate from './templateEx';
import { Record } from '../../../modules/bin/utility';
import BasePage from '../../../components/common/basePageView';
import clear from '../../../components/clear';
import { TemplateOptions } from '../../../components/template/templateOptions';
import { formBuilderActions } from '../../../components/formbuilder/store';
import RecordResultMessage from '../../../components/common/recordResult';

import { paths } from './constants/template';
import config from '../../../constants';

class VipProgramCreate extends Component {
  onSave = (data) => {
    this.props.programActions.createRecord(data);
  };

  onCancel = (data) => {
    this.props.templateBuilderActions.clearItems(data);
    this.goToVipProgramsView();
  };

  getFormBuliderModel = () => {
    return {
      children: [
        {
          children: [
            {
              children: [],
              formKey: 'application-entry',
              identifier: v4(),
              props: {
                description:
                  'This will be the target set on your fundraising page',
                required: true,
                title: 'How much are you aiming to fundraise?',
                isForced: true,
                ignore: true,
                valueReference: 'targetAmount'
              },
              type: 'targetAmount'
            },
            {
              children: [],
              formKey: 'application-entry',
              identifier: v4(),
              props: {
                required: true,
                title: 'Phone Number',
                isForced: true,
                ignore: true,
                valueReference: 'phoneNumber'
              },
              type: 'phoneNumber'
            }
          ],
          formKey: 'application-entry',
          identifier: v4(),
          props: { title: 'firstSection' },
          type: 'section'
        }
      ],
      formKey: 'application-entry',
      identifier: v4(),
      props: {},
      type: 'root'
    };
  };

  initTemplate = () => {
    let model = new TemplateOptions();

    model.setValue(paths.TERMS_AND_CONDITIONS_ENABLED, true);
    model.setValue(paths.IS_ACTIVE, true);
    model.setValue(paths.WAIVERS_ENABLED, false);

    model.setValue(
      paths.APPLICATION_RECEIVED,
      I18n.t(
        'programs.template.page.emails.application-received-section.defaultText'
      )
    );
    model.setValue(
      paths.APPLICATION_ACCEPTED,
      I18n.t(
        'programs.template.page.emails.application-accepted-section.defaultText'
      )
    );
    model.setValue(
      paths.APPLICATION_REJECTED,
      I18n.t(
        'programs.template.page.emails.application-rejected-section.defaultText'
      )
    );

    model.setValue(paths.APPLICATION_ENTRY, this.getFormBuliderModel());
    model.hide(paths.WAIVERS);

    this.props.formBuilderActions.setFormBuilderModel(
      this.getFormBuliderModel()
    );
    this.props.templateBuilderActions.setTemplateModel(model.get());
  };

  clearMessages = () => {
    this.props.programActions.clearRecordMessages('recordCreate');
  };

  goToVipProgramsView = () => {
    this.props.history.push(config.DEFAULT_PROGRAMS_URL);
  };

  render() {
    const isSaving = Record.isRecordLoading(this.props.recordCreate);
    if (!this.props.isTemplateInited) {
      this.initTemplate();
    }
    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToVipProgramsView}
        />

        <h1>
          <Translate value="programs.template.create-header" />
        </h1>
        <VipProgramTemplate
          onSave={this.onSave}
          onCancel={this.onCancel}
          isLoading={isSaving}
        />
      </BasePage>
    );
  }
}

const mapState = ({ program, templateBuilderEx }) => {
  return {
    recordCreate: program.recordCreate,
    isTemplateInited: templateBuilderEx.inited
  };
};

const mapDispatch = (dispatch) => {
  return {
    programActions: bindActionCreators(programActions, dispatch),
    templateBuilderActions: bindActionCreators(
      templateBuilderActions,
      dispatch
    ),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

const CreateVipProgramContainer = clear(
  connect(mapState, mapDispatch)(VipProgramCreate)
);
export default CreateVipProgramContainer;
