import {
  customFieldCategory,
  generalFormBuilderPath
} from '../../../../constants/formBuilder';

export const paths = {
  FIRST_NAME: 'firstName',
  LAST_NAME: 'lastName',
  EMAIL: 'email',
  TARGET_AMOUNT: 'targetAmount',
  FIELDS: 'fields',
  APPLICATION_ADDITIONAL: `${generalFormBuilderPath}.${customFieldCategory.ADDITIONAL}`,
  APPLICATION_ENTRY: `${generalFormBuilderPath}.${customFieldCategory.ENTRY}`,
  APPLICATION_CONFIRMATION: `${generalFormBuilderPath}.${customFieldCategory.CONFIRMATION}`,
  EDIT_BUTTON: 'edit_button',
  APPLY_BUTTON: 'apply_button',
  PART_TWO_HEADER: 'part-two-header',
  OGRANIZATION_QUESTIONS_HEADER: 'organization-questions-header'
};
