import update from '../../../helpers/update';
import { ISearchFilter } from '../../../models/search/v2/searchFilter';
import {
  getListData,
  getMetadata,
  createRecord,
  updateRecord,
  deleteRecord,
  getRecord,
  updateBulk,
  createBulkRecords
} from '../../../modules/bin/entityHandlers';
import {
  TERMINAL_API_PATH,
  TERMINAL_MESSAGE_CLEAR,
  TERMINAL_GET_REQUESTED,
  TERMINAL_GET_SUCCESS,
  TERMINAL_GET_FAILURE,
  TERMINAL_METADATA_REQUESTED,
  TERMINAL_METADATA_SUCCESS,
  TERMINAL_METADATA_FAILURE,
  TERMINAL_LIST_DATA_REQUESTED,
  TERMINAL_LIST_DATA_SUCCESS,
  TERMINAL_LIST_DATA_FAILURE,
  TERMINAL_CREATE_SUCCESS,
  TERMINAL_CREATE_FAILURE,
  TERMINAL_CREATE_REQUESTED,
  TERMINAL_UPDATE_REQUESTED,
  TERMINAL_UPDATE_SUCCESS,
  TERMINAL_UPDATE_FAILURE,
  TERMINAL_DELETE_REQUESTED,
  TERMINAL_DELETE_FAILURE,
  TERMINAL_DELETE_SUCCESS,
  TERMINAL_SEARCH_OPTIONS_REQUESTED,
  TERMINAL_SEARCH_OPTIONS_FAILURE,
  TERMINAL_SEARCH_OPTIONS_SUCCESS,
  TERMINAL_SET_LIST_DATA
} from './terminalTypes';

export const clearTerminalErrors = (record: string) => ({
  type: TERMINAL_MESSAGE_CLEAR,
  payload: { key: record }
});

export const updateTerminalListData = (data: any) => ({
  type: TERMINAL_SET_LIST_DATA,
  payload: data
});

export const getTerminal = (id: string) =>
  getRecord(
    TERMINAL_API_PATH,
    TERMINAL_GET_REQUESTED,
    TERMINAL_GET_SUCCESS,
    TERMINAL_GET_FAILURE,
    id
  );

export const getTerminalMetadata = (listKey: any) =>
  getMetadata(
    TERMINAL_API_PATH,
    listKey,
    TERMINAL_METADATA_REQUESTED,
    TERMINAL_METADATA_SUCCESS,
    TERMINAL_METADATA_FAILURE
  );

export const defaultTerminalListSearch = (filter: ISearchFilter) =>
  getTerminalListData(
    'terminalList',
    undefined,
    { page: 1, pageSize: 50, text: '', resultType: 'Full', filters: [filter] },
    true
  );

export const getTerminalListData = (
  listKey: any,
  id: any,
  request: any,
  invalidate = false,
  cachedData?: any
) => {
  return getListData(
    TERMINAL_API_PATH,
    listKey,
    id,
    TERMINAL_LIST_DATA_REQUESTED,
    TERMINAL_LIST_DATA_SUCCESS,
    TERMINAL_LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
};
export const getTerminalListDataBySearchTerm = (
  listKey: any,
  id: any,
  request: any,
  searchTerm: any
) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getTerminalListData(listKey, id, newRequest, true);
};

export const createTerminal = (data: {
  serialNumber: string;
  organizationId?: number;
  campaignId?: number;
  label?: string;
  model?: string;
}) =>
  createRecord(
    TERMINAL_API_PATH,
    TERMINAL_CREATE_REQUESTED,
    TERMINAL_CREATE_SUCCESS,
    TERMINAL_CREATE_FAILURE,
    data,
    null,
    'recordCreate'
  );

export const createTerminalBulk = (
  data: {
    serialNumber: string;
    label?: string;
    model?: string;
  }[]
) =>
  createBulkRecords(
    TERMINAL_API_PATH,
    TERMINAL_CREATE_REQUESTED,
    TERMINAL_CREATE_SUCCESS,
    TERMINAL_CREATE_FAILURE,
    data,
    null,
    'recordCreate'
  );

export const updateTerminal = (terminal: any) =>
  updateRecord(
    TERMINAL_API_PATH,
    TERMINAL_UPDATE_REQUESTED,
    TERMINAL_UPDATE_SUCCESS,
    TERMINAL_UPDATE_FAILURE,
    terminal.id,
    terminal,
    null,
    'recordEdit'
  );

export const updateTerminalBulk = (data: {
  ids: number[];
  organizationId?: number;
  campaignId?: number;
}) =>
  updateBulk(
    TERMINAL_API_PATH,
    TERMINAL_UPDATE_REQUESTED,
    TERMINAL_UPDATE_SUCCESS,
    TERMINAL_UPDATE_FAILURE,
    data,
    null,
    'recordEdit'
  );

export const deleteTerminal = (id: number) =>
  deleteRecord(
    TERMINAL_API_PATH,
    TERMINAL_DELETE_REQUESTED,
    TERMINAL_DELETE_SUCCESS,
    TERMINAL_DELETE_FAILURE,
    id
  );

export const getSearchOptions = (
  searchText: any,
  key: string,
  urlPath: string,
  customFilter = [] as any
) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: customFilter
  };

  return getListData(
    urlPath,
    key,
    null,
    TERMINAL_SEARCH_OPTIONS_REQUESTED,
    TERMINAL_SEARCH_OPTIONS_SUCCESS,
    TERMINAL_SEARCH_OPTIONS_FAILURE,
    request
  );
};

export const getOrganisationOptions = (searchText: any) => {
  const request = {
    page: 1,
    pageSize: 75,
    resultType: 'Full',
    text: searchText,
    orderBy: {
      key: 'name',
      direction: 'asc'
    },
    filters: []
  };

  return getListData(
    'organization',
    'orgOptions',
    null,
    TERMINAL_SEARCH_OPTIONS_REQUESTED,
    TERMINAL_SEARCH_OPTIONS_SUCCESS,
    TERMINAL_SEARCH_OPTIONS_FAILURE,
    request
  );
};
