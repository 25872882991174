import React, { Component } from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';
import * as organizationActions from './store/organizationActions';
import { Common } from '../../components';
import ContextMenu from '../../components/common/grid/contextMenu';
import { i18nKeys, organizationColumns, stripeName } from './constants';
import BasePage from '../../components/common/basePageView';
import clear from '../../components/clear';
import { gridKeys } from '../../constants/gridKeys';
import get from 'lodash.get';
import { Record } from '../../modules/bin/utility';
import Spinner from '../../components/common/spinner';
import Button from '../../components/common/button';
import GeneralFormatter from '../../components/common/grid/cells/generalFormatter';
import { Table } from 'semantic-ui-react';
import { countries } from '../../constants/countries';
import { CONTEXT_MENU_KEYS } from '../../constants/contextMenuEntries';
import RecordResultMessage from '../../components/common/recordResult';
import { getPaymentClickableColumnUrl } from '../../helpers/publicSiteUrlHelper';
import { ExportDownloadProvider } from "../export-history";

class OrganizationPage extends Component {

  onToggleColumnsChange = (toggleColumns) => {
    this.props.toggleColumnsChange(toggleColumns);
  };

  getPanel = () => {
    const { Grid } = Common;
    const { Panel } = Grid.Managed;
    return (
      <Panel name="right">
        {this.props.isExportDataAllowed &&
          <ExportDownloadProvider entity='Organization'>
            {({ open }) => (
              <Button onClick={() => open(this.props.list.request)}>
                <Translate value="organization.export" />
              </Button>
            )}
          </ExportDownloadProvider>
        }
        <Common.Button
          id="btnCreateOrganisation"
          primary
          onClick={this.onCreateClick}
        >
          <Translate value="organization.create" />
        </Common.Button>
      </Panel>
    );
  };

  onCreateClick = () => {
    this.props.history.push(`/organizations/create/about`);
  };

  isContextMenuDashboardHidden = (item) => {
    return !item.paymentPlatforms || !item.paymentPlatforms.length;
  };

  getContextMenu = () => {
    return (
      <ContextMenu>
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.ORGANIZATIONS.VIEW_DASHBOARD.key}
          label={I18n.t(i18nKeys.DASHBOARD_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.ORGANIZATIONS.VIEW_DASHBOARD}
          isHiden={this.isContextMenuDashboardHidden}
        />
        <ContextMenu.Item
          key={CONTEXT_MENU_KEYS.ORGANIZATIONS.EDIT.key}
          label={I18n.t(i18nKeys.EDIT_ACTION)}
          contextMenuOptions={CONTEXT_MENU_KEYS.ORGANIZATIONS.EDIT}
        />
      </ContextMenu>
    );
  };

  columnRender = (column, value) => {
    if (column.uniqueId === organizationColumns.PAYMENT_PLATFORM) {
      if (value) {
        const paymentPlatform = value.find((p) => p.platform === stripeName);

        if (paymentPlatform) {
          const url = getPaymentClickableColumnUrl(paymentPlatform);

          return (
            <Table.Cell collapsing>
              <GeneralFormatter
                value={
                  <a target="_blank" href={url} rel="noreferrer">
                    {paymentPlatform.platform.capitalize()}
                  </a>
                }
              />
            </Table.Cell>
          );
        }
      }
      return (
        <Table.Cell collapsing>
          <GeneralFormatter value={'N/A'} />
        </Table.Cell>
      );
    }

    if (column.id === organizationColumns.PAYMENT_CONNECTED) {
      const isOrgConnectedToPaymentPlatform = value && value.length !== 0;

      return (
        <Table.Cell collapsing>
          <GeneralFormatter
            value={isOrgConnectedToPaymentPlatform.toString().capitalize()}
          />
        </Table.Cell>
      );
    }

    if (column.id === organizationColumns.COUNTRY) {
      return (
        <Table.Cell collapsing>
          <GeneralFormatter value={countries[value].label} />
        </Table.Cell>
      );
    }
  };

  clearMessages = () => {
    this.props.listActions.clearRecordMessages('recordEdit');
    this.props.listActions.clearRecordMessages('recordCreate');
  };

  showSpinner = () => {
    return Record.isRecordLoading(this.props.exportFileRecord);
  };

  render() {
    if (this.showSpinner()) {
      return <Spinner />;
    }

    const { Grid } = Common;
    const panel = this.getPanel();
    const contextMenu = this.getContextMenu();

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
        />
        <RecordResultMessage
          record={this.props.recordCreate}
          onDismiss={this.clearMessages}
        />
        <h1>
          <Translate value={i18nKeys.PAGE_TITLE} />
        </h1>
        <Grid.Managed
          listKey={gridKeys.ORGANIZATIONS}
          list={this.props.list}
          actions={this.props.listActions}
          search
          columnRender={this.columnRender}
          toggleColumns
        >
          {panel}
          {contextMenu}
        </Grid.Managed>
      </BasePage>
    );
  }
}

const mapState = ({ organization, session }) => {
  const list = get(organization, gridKeys.ORGANIZATIONS);
  return {
    list: list,
    exportFileRecord: organization.exportFileRecord,
    recordCreate: organization.saveRecord,
    recordEdit: organization.updateRecord,
    isExportDataAllowed: get(session, 'permissions.exportData') === 'allow'
  };
};

const mapDispatch = (dispatch) => {
  var actions = {
    // getExportFile: organizationActions.getExportFile,
    getMetadata: organizationActions.getMetadata,
    getListData: organizationActions.getListData,
    getListDataBySearchTerm: organizationActions.getListDataBySearchTerm,
    toggleColumnsChange: organizationActions.toggleColumnsChange
  };

  const listActions = Common.Grid.Managed.bindGridActions(dispatch, actions);

  return {
    listActions
  };
};

const OrganizationPageContainer = clear(
  connect(mapState, mapDispatch)(OrganizationPage)
);
export default OrganizationPageContainer;
