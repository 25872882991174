import React from 'react';

import moment from 'moment';

import { isArrayWithItems } from '../../helpers/arrayHelper';

import config from '../../constants';

export const FormBuilderSectionRow = (props) => {
  const getCustomFieldRow = () => {
    const { field, isShowEmptyFields } = props;

    switch (field.type) {
      case 'date': {
        return (
          <React.Fragment>
            {(field.value || isShowEmptyFields) && (
              <div className="view-details-content-row column">
                <h4>{field.label}</h4>
                <div className="column-content">
                  <span>
                    {field.value
                      ? moment(field.value).format(
                          config.DEFAULT_FULL_DATE_FORMAT
                        )
                      : 'N/A'}
                  </span>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }

      case 'checkbox':
      case 'multipleChoice': {
        return (
          <React.Fragment>
            {(field.value || isShowEmptyFields) && (
              <div className="view-details-content-row column">
                <h4>{field.label}</h4>
                <div className="column-content">
                  <span>
                    {isArrayWithItems(field.value)
                      ? field.value.join('; ')
                      : 'N/A'}
                  </span>
                </div>
              </div>
            )}
          </React.Fragment>
        );
      }

      default:
        return (
          <React.Fragment>
            {(field.value || isShowEmptyFields) && (
              <div className="view-details-content-row column">
                <h4>{field.label}</h4>
                <div className="column-content">
                  <span>{field.value}</span>
                </div>
              </div>
            )}
          </React.Fragment>
        );
    }
  };

  return <React.Fragment>{getCustomFieldRow()}</React.Fragment>;
};
