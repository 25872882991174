import { v4 as uuidv4 } from 'uuid';

export interface ListPaginated<T> {
  totalCount: number;
  page: number;
  pageSize: number;
  list: Array<T>;
}

export interface ApiError {
  error: string;
  param: string;
}

export interface IPricing {
  price?: number;
  hasCountryTax: boolean;
}

export interface IInventory {
  quantity: number | null;
  sold?: number;
  remaining?: number;
  soldOut?: boolean;
}

export interface ISku {
  id?: number;
  name: string;
  description?: string;
  enabled: boolean;
  pricing: IPricing;
  inventory: IInventory;
  salesTotalAmount?: number;
  template?: any;
}

export interface IMerchandise {
  id?: number;
  campaignId?: number;
  name: string;
  description?: string;
  mainImagePath?: string;
  mainImageUrl?: string;
  enabled: boolean;
  requiresShipping: boolean;
  inventory: IInventory;
  skus: Array<ISku>;
  template: any;
}

interface IGetSkuOptions {
  index?: number;
  hasCountryTax?: boolean;
}

export const getSkuModel = ({ index, hasCountryTax = true }: IGetSkuOptions = {}): ISku => {
  return {
    name: '',
    enabled: true,
    inventory: {
      quantity: 0
    },
    pricing: {
      hasCountryTax
    },
    template: {
      value: {
        index
      }
    }
  };
};

interface IGetMerchandiseOptions {
  index?: number;
}

export const getMerchandiseModel = ({ index }: IGetMerchandiseOptions = {}): IMerchandise => {
  return {
    name: '',
    description: '',
    enabled: true,
    requiresShipping: false,
    inventory: {
      quantity: 0
    },
    skus: [getSkuModel()],
    template: {
      value: {
        identifier: uuidv4(),
        index
      }
    }
  };
};
