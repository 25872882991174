import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Button } from 'semantic-ui-react';

// LIBS
import { Translate, I18n } from 'react-redux-i18n';

// REDUCERS
import {
  securityModalChange,
  clearSecurityDisableRecordMessages,
  disableSecurityModalChange
} from './reducer';

// COMPONENTS
import RecordResultMessage from '../../../components/common/recordResult';
import DisableSecurityModal from './MFA/disableSecurityModal';
import { Record } from '../../../modules/bin/utility';

class Security extends Component {
  handleSecurityModalOpen = () => {
    this.props.securityModalChange(true);
  };

  handleDisableSecurityModal = () => {
    this.props.disableSecurityModalChange(true);
  };

  handleDisableSecurityMessageSuccess = () => {
    this.props.clearSecurityDisableRecordMessages('disableSecurityRecord');
  };

  render() {
    const isMfaActive = this.props.accountDetail?.hasMfaActive;
    const organization =
      this.props.session?.organization ||
      this.props.session?.campaigns?.find((x) => x.organization?.hasMfaActive)
        ?.organization;
    const isOrgMfaActive = organization?.hasMfaActive;

    return (
      <React.Fragment>
        <RecordResultMessage
          record={this.props.disableSecurityRecord}
          onDismiss={this.handleDisableSecurityMessageSuccess}
          customSuccessMessage={I18n.t('security.disable-tab-success-message')}
        />
        <DisableSecurityModal />
        <div className="security-container">
          <Card fluid style={{ backgroundColor: 'rgba(216, 216, 216, 0.1)' }}>
            <div className="security-content-container">
              <Card.Content>
                <h3 className="security-title">
                  <Translate value={'account.account-security.general.title'} />
                </h3>
                <div className="security-instructions">
                  <span className="instructions-text">
                    <Translate
                      value={'account.account-security.general.instructions'}
                    />
                  </span>
                </div>
                <div
                  className={`mfa-container ${
                    isMfaActive ? 'active' : 'disabled'
                  }`}
                >
                  {isMfaActive && (
                    <React.Fragment>
                      <div className="security-active-button-container">
                        <Button
                          className="mfa-button"
                          floated="left"
                          disabled={isOrgMfaActive}
                          onClick={this.handleDisableSecurityModal}
                        >
                          <Translate
                            value={
                              'account.account-security.general.activated.button'
                            }
                          />
                        </Button>
                      </div>
                      <span className="security-active-title">
                        <Translate
                          value={
                            'account.account-security.general.activated.title'
                          }
                        />
                      </span>
                      {isOrgMfaActive && (
                        <span className="security-active-force-mfa-note">
                          <Translate
                            value={
                              'account.account-security.general.activated.forceMfa'
                            }
                            orgName={organization?.name}
                          />
                        </span>
                      )}
                    </React.Fragment>
                  )}
                  {!isMfaActive && (
                    <Button
                      primary
                      className="mfa-button"
                      floated="left"
                      onClick={this.handleSecurityModalOpen}
                    >
                      <Translate
                        value={
                          'account.account-security.general.disabled.button'
                        }
                      />
                    </Button>
                  )}
                </div>
                <div className="security-container-bottom-space"></div>
              </Card.Content>
            </div>
          </Card>
        </div>
      </React.Fragment>
    );
  }
}

const mapState = ({ accountSettings, session }) => {
  return {
    session: session,
    accountDetail: accountSettings.record.data.data,
    disableSecurityRecord: accountSettings.disableSecurityRecord,
    isLoading: Record.isRecordLoading(accountSettings.disableSecurityRecord)
  };
};

const mapDispatch = (dispatch) => {
  return {
    securityModalChange: (open) => {
      dispatch(securityModalChange(open));
    },
    disableSecurityModalChange: (open) => {
      dispatch(disableSecurityModalChange(open));
    },
    clearSecurityDisableRecordMessages: (record) => {
      dispatch(clearSecurityDisableRecordMessages(record));
    }
  };
};

export default connect(mapState, mapDispatch)(Security);
