import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { Button, Modal } from 'semantic-ui-react';
import AnimateHeight from 'react-animate-height';

import { Common } from '../../../components';
import { useAppDispatch } from '../../../hooks';
import { RootState } from '../../../store';
import {
  createTerminalBulk,
  defaultTerminalListSearch
} from '../store/terminalActions';
import { RouteParams } from '../../../containers/app';
import { useParams } from 'react-router-dom';
import { assignedFilter, unassignedFilter } from '../store/terminalReducer';
import { ITerminalMetadata } from '../../../models/Terminal';
import { DeviceInput } from './DeviceInput';

interface IAddDeviceProps {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

interface IDeviceListProps {
  terminalList: ITerminalMetadata[];
  setTerminalList: (data: ITerminalMetadata[]) => void;
  onRemove: (terminal: ITerminalMetadata) => void;
}

const DeviceList = ({
  terminalList,
  setTerminalList,
  onRemove
}: IDeviceListProps) => {
  const [activeId, setActiveId] = useState<number | null>();
  const [showEditSection, setShowEditSection] = useState(false);

  const onEdit = (newTerminal: ITerminalMetadata) => {
    const terminal = terminalList.find((x) => x.id === newTerminal.id);

    if (!terminal) {
      return;
    }

    terminal.serialNumber = newTerminal.serialNumber;
    terminal.model = newTerminal.model || '';
    terminal.label = newTerminal.label || '';
    setTerminalList([...terminalList]);
    setActiveId(null);
  };

  const forMatLabelModel = (terminal: ITerminalMetadata) => {
    if (terminal.label && terminal.model) {
      return `${terminal.label} | ${terminal.model}`;
    } else if (terminal.label) {
      return terminal.label;
    } else if (terminal.model) {
      return terminal.model;
    }
    return null;
  };

  return (
    <div id="device-list" className="device-list-container">
      {terminalList.map((terminal) => (
        <div className="device-item-container">
          <div className="device-item">
            <b>{terminal.serialNumber}</b>
            <p>{forMatLabelModel(terminal)}</p>
            <div className="options">
              <div
                className="Links"
                onClick={() => {
                  const showItem = activeId === terminal.id;
                  setActiveId(showItem ? null : terminal.id);
                  if (!showItem) setShowEditSection(true);
                }}
                style={{ cursor: 'pointer' }}
              >
                <Translate value="terminal.add-modal.edit" />
              </div>
              <Button
                icon="close"
                className="device-close-icon"
                circular
                compact
                size="mini"
                onClick={() => onRemove(terminal)}
              />
            </div>
          </div>
          <AnimateHeight
            animateOpacity
            duration={300}
            height={activeId === terminal.id ? 'auto' : 0}
            onAnimationEnd={() => !activeId && setShowEditSection(false)}
          >
            {showEditSection && (
              <DeviceInput
                inputData={terminal}
                onConfirm={onEdit}
                confirmValue="terminal.add-modal.update"
              />
            )}
          </AnimateHeight>
        </div>
      ))}
    </div>
  );
};

export const AddDevice = ({ isOpen, setIsOpen }: IAddDeviceProps) => {
  // hooks
  const dispatch = useAppDispatch();
  const terminalReducer = useSelector((state: RootState) => state.terminal);
  const { tab } = useParams<RouteParams>();
  // states
  const [terminalList, setTerminalList] = useState<ITerminalMetadata[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  // defaults
  const isAssignedTab = tab === 'assigned';

  const onRemove = (terminal: ITerminalMetadata) => {
    setTerminalList(terminalList.filter((x) => x.id !== terminal.id));
  };

  const onAdd = (terminal: ITerminalMetadata) => {
    terminalList.push(terminal);
    setTerminalList([...terminalList]);
    setTimeout(() => {
      var objDiv = document.getElementById('device-list');
      if (objDiv) {
        objDiv.scrollTop = objDiv.scrollHeight;
      }
    }, 1);
  };

  const onAssignConfirm = () => {
    if (terminalList.length > 0) {
      setIsLoading(true);
      // prettier-ignore
      const mappedData = terminalList.map((x) => ({
          serialNumber: x.serialNumber || '',
          label: x.label,
          model: x.model
          // NOTE: add below when required
          // organizationId: x.organizationId,
          // campaignId: x.campaignId,
        })).filter((x) => !!x.serialNumber);

      dispatch(createTerminalBulk(mappedData));
    }
  };

  useEffect(() => {
    if (
      isOpen &&
      isLoading &&
      (terminalReducer.recordCreate.success.show ||
        terminalReducer.recordCreate.error)
    ) {
      setTimeout(() => {
        const filter = isAssignedTab ? assignedFilter : unassignedFilter;
        setIsLoading(false);
        setIsOpen(false);
        setTerminalList([]);
        dispatch(defaultTerminalListSearch(filter));
      }, 100);
    }
  }, [terminalReducer, terminalReducer.recordCreate.error]);

  return (
    <Modal open={isOpen} size="tiny" className="add-device-modal">
      <Modal.Content>
        <h3 className="modal-title">
          <Translate value="terminal.add-modal.title" />
        </h3>
        <DeviceInput onConfirm={onAdd} confirmValue="terminal.add-modal.add" />
        <DeviceList
          terminalList={terminalList}
          setTerminalList={setTerminalList}
          onRemove={onRemove}
        />
      </Modal.Content>
      <Modal.Actions>
        <Common.Button
          disabled={isLoading}
          onClick={() => {
            setIsOpen(false);
            setTerminalList([]);
          }}
          content={<Translate value="terminal.add-modal.cancel" />}
        />
        <Common.Button
          primary
          loading={isLoading}
          disabled={terminalList.length === 0}
          content={<Translate value="terminal.add-modal.confrim" />}
          onClick={onAssignConfirm}
        />
      </Modal.Actions>
    </Modal>
  );
};
