import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';

class ButtonGroup extends Component {
  render() {
    return (
      <Menu className="button-group">
        {this.props.children &&
          this.props.children.map((item) => {
            return (
              <Menu.Item
                {...item}
                onClick={this.onFilterButtonClick.bind(this, item.key)}
              />
            );
          })}
      </Menu>
    );
  }
}

class ButtonGroupItem extends Component {}

ButtonGroup.Item = ButtonGroupItem;
export default ButtonGroup;
