export enum WebTrackingTypes {
  GoogleAnalytics = "GoogleAnalytics",
  GoogleTagManager = "GoogleTagManager",
  FacebookPixel = "FacebookPixel"
}

export type WebTrackerKeys = keyof typeof WebTrackingTypes;
export type WebTrackers = typeof WebTrackingTypes[WebTrackerKeys];

export interface IWebTracker { key: WebTrackers, value: string }

export const defaultWebTrackingElements: Array<IWebTracker> = [
  {
    key: WebTrackingTypes.GoogleAnalytics,
    value: ''
  },
  {
    key: WebTrackingTypes.GoogleTagManager,
    value: ''
  },
  {
    key: WebTrackingTypes.FacebookPixel,
    value: ''
  },
];

export const getWebTrackers = (webTracking: Array<IWebTracker> = [], webTrackerKey: WebTrackerKeys) => {
  if (Array.isArray(webTracking)) {
    return webTracking.filter(tracker => tracker?.key === webTrackerKey);
  }
  return defaultWebTrackingElements.filter(tracker => tracker?.key === webTrackerKey);
}

export const getTrackersList = (webTracking: Array<IWebTracker> = []) => [
  ...getWebTrackers(webTracking, WebTrackingTypes.GoogleAnalytics),
  ...getWebTrackers(webTracking, WebTrackingTypes.GoogleTagManager),
  ...getWebTrackers(webTracking, WebTrackingTypes.FacebookPixel)
];
