import React from 'react';
import { I18n } from 'react-redux-i18n';
import { Button } from 'semantic-ui-react';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import Styles from './merchandise-header.module.css';

const MerchandiseHeaderTranslationPath =
  'template.page.content.merchandise.merchandise';

const MerchandiseHeaderTranslationPaths = {
  Header: `${MerchandiseHeaderTranslationPath}.header`,
  Button: `${MerchandiseHeaderTranslationPath}.button`
};

export const MerchandiseItemLabels = {
  Header: I18n.t(MerchandiseHeaderTranslationPaths.Header),
  Button: I18n.t(MerchandiseHeaderTranslationPaths.Button)
};

interface MerchandiseHeaderViewProps {
  onAddItem?: () => void;
}

export const MerchandiseHeaderView: React.FunctionComponent<
  MerchandiseHeaderViewProps
> = ({ onAddItem }) => {
  return (
    <div className={Styles.MerchandiseHeader}>
      <TemplateHeader
        as="h3"
        className={Styles.MerchandiseHeaderText}
        label={MerchandiseHeaderTranslationPaths.Header}
      />
      {onAddItem ? (
        <Button
          primary
          className={Styles.MerchandiseHeaderButton}
          onClick={onAddItem}
        >
          {MerchandiseItemLabels.Button}
        </Button>
      ) : null}
    </div>
  );
};
