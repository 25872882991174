//---------------React/Redux
import React from 'react';

//---------------Components
import { Icon, Image } from 'semantic-ui-react';
import EditableImageUpload from '../../editableImageUpload';

//---------------Constansts
import {
  imageTypes,
  imageFormats,
  getFullImagePathByType
} from '../../../../constants/imageUpload';

class AboutImageGalleryUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageError: false
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.url !== this.props.url) {
      this.setState({ imageError: false });
    }
  }

  getDeepCopy = (value) => {
    return JSON.parse(JSON.stringify(value));
  };

  imageLoadErrorHandler = (event) => {
    if (!this.state.imageError) {
      this.setState({ imageError: true });
    }
  };

  onSuccess = (imagePath, url) => {
    const valueCopy = this.props.value
      ? this.getDeepCopy(this.props.value)
      : [];
    valueCopy.push(url ? { imagePath, imageUrl: url } : imagePath);
    this.props.onValueChange(valueCopy);
  };

  handleRemove = (index) => {
    const valueCopy = this.getDeepCopy(this.props.value);
    valueCopy.splice(index, 1);
    this.props.onValueChange(valueCopy);
  };

  render() {
    const width = 1500;
    const height = 500;

    return (
      <div className="image-gallery-container">
        <div className="image-gallary-inner-container">
          <EditableImageUpload
            path={this.props.guid}
            format={imageFormats}
            height={height}
            width={width}
            entity={this.props.entity}
            uploadType={imageTypes.ASSET}
            onSuccess={this.onSuccess}
            showImage={false}
            showImageUrl
          >
            <div className="update-uploaded-inner">
              <div className="update-dropzone cropper-add-image-box">
                <div className="update-dropzone-inner">
                  <Icon name="add" />
                </div>
              </div>
            </div>
          </EditableImageUpload>
          <div className="select-image-text">
            <div className="select-image-text-inner">
              <h4>Select an image from your computer</h4>
              <div>
                {imageFormats.join(', ')} format. <br />
                At least {width} x {height} pixels
              </div>
            </div>
          </div>
        </div>

        {this.props.value && (
          <div className="image-gallary-inner-container">
            {this.props.value.map((img, index) => {
              const imagePath = img.imagePath || img;

              return (
                <div className="image-gallery-upload-with-remove-container">
                  <Icon
                    className="image-gallery-remove-file-icon remove-image-button"
                    name="remove circle"
                    size="large"
                    onClick={this.handleRemove.bind(this, index)}
                  />
                  <Image
                    className="image-gallery-uploaded-picture"
                    src={getFullImagePathByType(imagePath, imageTypes.ASSET)}
                  />
                </div>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

export default AboutImageGalleryUpload;
