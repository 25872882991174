import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../../';
import * as templateHelper from '../templateHelper';

import get from 'lodash.get';

import './customMediaSelector.css';

class CustomMediaSelector extends Component {
  constructor(props) {
    super(props);

    if (!props.value) {
      props.onChange(this.props.path, props.children[0].props.id);
    }
  }

  onMediaItemChange = (evt, obj) => {
    this.props.onChange(this.props.path, obj.value);
  };

  render() {
    const selectedItemKey = this.props.value;
    let MediaRenderer = null;

    React.Children.forEach(this.props.children, (item, i) => {
      // if we find needed radio
      if (item.props.id === selectedItemKey) {
        MediaRenderer = item;
      }
    });

    return (
      <div className="custom-mediaselector-container">
        <div className="custom-mediaselector-options">
          {React.Children.map(this.props.children, (item, i) => {
            const text = templateHelper.getTranslation(item.props.radioLabel);
            return (
              <Form.Checkbox
                key={item.props.id}
                inputLabel={text}
                checked={selectedItemKey === item.props.id}
                value={item.props.id}
                onChange={this.onMediaItemChange.bind(this)}
                radio
              />
            );
          })}
        </div>
        {MediaRenderer}
      </div>
    );
  }
}

export const TemplateRadio = (props) => {
  return props.children;
};

const mapState = (state, ownProps) => {
  const data = React.Children.map(ownProps.children, (item, i) => {
    return {
      value: templateHelper.getValue(state.templateBuilderEx, item.props.path),
      id: item.props.id
    };
  });

  return {
    data,
    value: get(state.templateBuilderEx.data, ownProps.path)
  };
};

export const TemplateCustomMediaSelector =
  connect(mapState)(CustomMediaSelector);
