import React, { Component } from 'react';
import { connect } from 'react-redux';

import get from 'lodash.get';
import { Message } from 'semantic-ui-react';

import * as validationHelper from '../../../helpers/validationHelper';
import * as templateHelper from '../templateHelper';

import { Record } from '../../../modules/bin/utility';
import { TemplateAutocomplete } from '../templateAutocomplete';

class ValidatedAutocomplete extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.record !== this.props.record) {
      if (
        Record.isSuccess(this.props.record) ||
        Record.isError(this.props.record)
      ) {
        this.props.onChange(
          this.props.path,
          get(this.props.record, 'data.data')
        );
      }
    }
  }

  clearMessages = () => {
    this.props.clearRecord(this.props.recordPath);
  };

  onChange = (path, value, item) => {
    const recordData = get(this.props.record, 'data.data');

    if (recordData) {
      this.clearMessages();
    }

    this.props.getRecordRequest(value);
    this.props.onChange(path, value, item);
  };

  render() {
    const { id, valueEntity, valuePath, selected, label, request, getItem } =
      this.props;

    const isLoading = Record.isRecordLoading(this.props.record);

    return (
      <React.Fragment>
        <TemplateAutocomplete
          id={id}
          entity={valueEntity}
          loading={isLoading}
          path={valuePath}
          selected={selected}
          label={label}
          onChange={this.onChange}
          request={request}
          getItem={getItem}
          validation={this.required}
        />
        {!isLoading && (
          <Message negative hidden={this.props.isValid}>
            <p>{this.props.errorMessage}</p>
          </Message>
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  const entity = ownProps.entity;
  const value = templateHelper.getValue(state.templateBuilderEx, ownProps.path);

  return {
    record: get(state, `${entity}.${ownProps.recordPath}`),
    isValid: validationHelper.isValid(ownProps.validation, value),
    errorMessage: validationHelper.getValidationErrorMessage(
      ownProps.validation,
      value
    )
  };
};

const TemplateValidatedAutocomplete = connect(
  mapState,
  null
)(ValidatedAutocomplete);
export default TemplateValidatedAutocomplete;
