import { RSAA } from 'redux-api-middleware';
import constants from '../../constants';
import {
  CREATE_MERCHANDISE_SUCCESS,
  CREATE_MERCHANDISE_FAILURE,
  CREATE_SKU_FAILURE,
  CREATE_SKU_REQUESTED,
  CREATE_SKU_SUCCESS
} from '../../pages/merchandise/reducer';

export const executeSkuRequest = (endpoint, method, model, dispatch, state) => {
  return dispatch({
    [RSAA]: {
      endpoint,
      method,
      headers: {
        ApiKey: constants.apikey,
        Authorization: `bearer ${state.session.key}`,
        'Content-Type': 'application/json'
      },
      body: model ? JSON.stringify(model) : null,
      types: [
        CREATE_SKU_REQUESTED,
        CREATE_SKU_SUCCESS,
        {
          type: CREATE_SKU_FAILURE,
          payload: { error: ['merchandise.sku.failed'] }
        }
      ]
    }
  });
};

export const manageSkusRequest = (merchandise, data, dispatch, state) => {
  const deafultEndpoint = `${constants.baseApiHost}/api/v2/merchandise/${merchandise.data.id}/sku`;

  const availableSkus = data.availableSkus || [];
  let skus = data.skus || [];

  const newSkus = skus.filter((sku) => !sku.id);
  const editSkus = skus.filter((sku) =>
    availableSkus.some((availableSku) => availableSku === sku.id)
  );
  const deleteSkus = availableSkus.filter(
    (availableSku) => !skus.some((sku) => sku.id === availableSku)
  );

  let promises = [];

  newSkus.forEach((sku) => {
    promises.push(
      executeSkuRequest(
        deafultEndpoint,
        'POST',
        {
          ...sku,
          enabled: true
        },
        dispatch,
        state
      )
    );
  });

  editSkus.forEach((sku) => {
    promises.push(
      executeSkuRequest(
        `${deafultEndpoint}/${sku.id}`,
        'PUT',
        {
          ...sku,
          enabled: true
        },
        dispatch,
        state
      )
    );
  });

  deleteSkus.forEach((sku) => {
    promises.push(
      executeSkuRequest(
        `${deafultEndpoint}/${sku}`,
        'DELETE',
        null,
        dispatch,
        state
      )
    );
  });

  return promises;
};

export const manageMerchandiseAdditionalRequests = (
  merchandise,
  data,
  dispatch,
  state
) => {
  const skusPromises = manageSkusRequest(merchandise, data, dispatch, state);
  const promises = [...skusPromises];

  Promise.all(promises).then((values) => {
    const errors = values.filter((value) => value.error === true);
    if (errors.length === 0) {
      dispatch({ type: CREATE_MERCHANDISE_SUCCESS, payload: {} });
    } else {
      dispatch({
        type: CREATE_MERCHANDISE_FAILURE,
        payload: { errors: errors }
      });
    }
  });
};

export const executeMerchandiseRequest = (
  endpoint,
  method,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  data,
  beforeExecuteCallback,
  key
) => {
  return (dispatch, getState) => {
    const state = getState();
    const msg = !data
      ? null
      : beforeExecuteCallback
      ? beforeExecuteCallback(state, data)
      : data;

    dispatch({
      [RSAA]: {
        endpoint,
        method,
        headers: {
          ApiKey: constants.apikey,
          Authorization: `bearer ${state.session.key}`,
          'Content-Type': 'application/json'
        },
        body: msg ? JSON.stringify(msg) : null,
        types: [
          {
            type: actionTypeRequest,
            payload: async (action, state, res) => {
              return {
                key
              };
            }
          },
          {
            type: actionTypeSuccess,
            payload: async (action, state, res) => {
              const json = await res.json();
              await manageMerchandiseAdditionalRequests(
                json,
                msg,
                dispatch,
                state
              );
              return {
                ...json,
                key
              };
            }
          },
          {
            type: actionTypeFailure,
            payload: async (action, state, res) => {
              const json = await res.json();
              return {
                ...json,
                key,
                status: res.status
              };
            }
          }
        ]
      }
    });
  };
};

export const createMerchandiseRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/'
) =>
  executeMerchandiseRequest(
    `${constants.baseApiHost}/api/${version}${entity}`,
    'POST',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey
  );

export const updateMerchandiseRecord = (
  entity,
  actionTypeRequest,
  actionTypeSuccess,
  actionTypeFailure,
  id,
  record,
  beforeExecuteCallback,
  recordKey = 'record',
  version = 'v2/'
) =>
  executeMerchandiseRequest(
    `${constants.baseApiHost}/api/${version}${entity}/${id}`,
    'PUT',
    actionTypeRequest,
    actionTypeSuccess,
    actionTypeFailure,
    record,
    beforeExecuteCallback,
    recordKey
  );
