import React from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { Modal, Button } from 'semantic-ui-react';

import { toggleChangeAvailabilityModal, updateProgramAvailability } from './store/vipProgramActions';

export const ChangeAvailabilityModal: React.FC = () => {
  const dispatch = useDispatch();
  const open = useSelector((state: any) => state.program.changeAvailabilityModal.isModalOpen);
  const type = useSelector((state: any) => state.program.changeAvailabilityModal.type);
  const itemId = useSelector((state: any) => state.program.changeAvailabilityModal.itemId);

  return (
    <Modal
      className="wizard"
      size="tiny"
      dimmer="inverted"
      open={open}
    >
      <Modal.Header>
        <Translate value="eventPrograms.change-availability-modal.header" />
      </Modal.Header>
      <Modal.Content>
        <div
          style={{
            textAlign: "center",
          }}
        >
          <Translate value={`eventPrograms.change-availability-modal.${type === "OpenApplications" ? "open" : "close"}-content`} />
        </div>
        <div
          style={{
            marginTop: "2rem",
            textAlign: "center",
          }}
        >
          <Button
            primary
            style={{
              width: 95,
            }}
            onClick={() => {
              dispatch(toggleChangeAvailabilityModal(type));
              dispatch(updateProgramAvailability({
                id: itemId,
                status: type,
              }));
            }}
          >
            <Translate value="eventPrograms.change-availability-modal.confirm" />
          </Button>
          <Button
            style={{
              width: 95,
            }}
            onClick={() => dispatch(toggleChangeAvailabilityModal(type))}
          >
            <Translate value="eventPrograms.change-availability-modal.cancel" />
          </Button>
        </div>
      </Modal.Content>
    </Modal>
  );
};
