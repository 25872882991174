//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { connect } from 'react-redux';

//---------------Actions
import {
  getFundraiserOptions,
  getTeamOptions,
  getCollectionOptions
} from './giftMatchingReducer';

//---------------Components
import { Form, Checkbox, Dropdown } from 'semantic-ui-react';
import { Form as CommonForm } from '../..';

//---------------Helpers
import { Record } from '../../../modules/bin/utility';
import * as templateHelper from '../../../components/template/templateHelper';
import update from '../../../helpers/update';
import * as validationHelper from '../../../helpers/validationHelper';

//---------------Constants
import { i18GiftMatchingKeys } from '../constants/translation';
import {
  campaignGiftMatchingConditionType,
  campaignGiftMatchingConditionOperator,
  getPageTypeOptions
} from '../../../constants/giftMatching';
import { giftMatchingPath } from '../constants/paths';

class GiftMatchingConditionPageItemContainer extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.state = {
      checked: false,
      pageType: null
    };
  }

  componentDidMount() {
    const condition = this.props.conditions.find(
      (condition) =>
        condition.key === campaignGiftMatchingConditionType.PageFundraiser ||
        condition.key === campaignGiftMatchingConditionType.PageTeam ||
        condition.key === campaignGiftMatchingConditionType.PageCollection
    );
    if (condition) {
      this.setState({
        checked: true,
        pageType: condition.key
      });

      if (condition.key === campaignGiftMatchingConditionType.PageFundraiser) {
        this.props.getFundraiserOptions(
          `gift-matching-${this.props.index}-fundraisers`,
          this.props.campaignId,
          '',
          condition.value[0]
        );
      }
      if (condition.key === campaignGiftMatchingConditionType.PageTeam) {
        this.props.getTeamOptions(
          `gift-matching-${this.props.index}-teams`,
          this.props.campaignId,
          '',
          condition.value[0]
        );
      }
      if (condition.key === campaignGiftMatchingConditionType.PageCollection) {
        this.props.getCollectionOptions(
          `gift-matching-${this.props.index}-collections`,
          this.props.campaignId,
          '',
          condition.value[0]
        );
      }
    }
  }

  handleConditionChange = (checked) => {
    if (checked) {
      this.setState({
        checked: true,
        pageType: campaignGiftMatchingConditionType.PageFundraiser
      });
      this.props.onChange(
        this.props.index,
        giftMatchingPath.conditions,
        update(this.props.conditions, {
          $push: [
            {
              key: campaignGiftMatchingConditionType.PageFundraiser,
              operator: campaignGiftMatchingConditionOperator.EqualTo,
              value: [null]
            }
          ]
        })
      );
    } else {
      this.setState({
        checked: false,
        pageType: null
      });
      this.props.onChange(
        this.props.index,
        giftMatchingPath.conditions,
        this.props.conditions.filter(
          (condition) =>
            condition.key !==
              campaignGiftMatchingConditionType.PageFundraiser &&
            condition.key !== campaignGiftMatchingConditionType.PageTeam &&
            condition.key !== campaignGiftMatchingConditionType.PageCollection
        )
      );
    }
  };

  handlePageTypeChange = (event, { value }) => {
    if (value !== this.state.pageType) {
      const conditionIndex = this.props.conditions.findIndex(
        (item) => item.key === this.state.pageType
      );

      if (conditionIndex !== -1) {
        this.props.onChange(
          this.props.index,
          giftMatchingPath.conditions,
          update(this.props.conditions, {
            [conditionIndex]: {
              key: { $set: value },
              value: { $set: [null] }
            }
          })
        );

        this.setState({
          pageType: value
        });
      }
    }
  };

  onFundraiserSearch = (event, searchTerm) => {
    const { index } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getFundraiserOptions(
        `gift-matching-${index}-fundraisers`,
        this.props.campaignId,
        searchTerm.searchQuery
      );
    }, 500);
  };

  fundraiserClear = () => {
    const { index } = this.props;
    this.props.getFundraiserOptions(
      `gift-matching-${index}-fundraisers`,
      this.props.campaignId,
      ''
    );
  };

  onTeamSearch = (event, searchTerm) => {
    const { index } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getTeamOptions(
        `gift-matching-${index}-teams`,
        this.props.campaignId,
        searchTerm.searchQuery
      );
    }, 500);
  };

  teamClear = () => {
    const { index } = this.props;
    this.props.getTeamOptions(
      `gift-matching-${index}-teams`,
      this.props.campaignId,
      ''
    );
  };

  onCollectionSearch = (event, searchTerm) => {
    const { index } = this.props;

    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.props.getCollectionOptions(
        `gift-matching-${index}-collections`,
        this.props.campaignId,
        searchTerm.searchQuery
      );
    }, 500);
  };

  collectionClear = () => {
    const { index } = this.props;
    this.props.getCollectionOptions(
      `gift-matching-${index}-collections`,
      this.props.campaignId,
      ''
    );
  };

  getFundraiserOptions = () => {
    let options = [];
    if (this.props.fundraiserOptions) {
      const { list } = this.props.fundraiserOptions.data;
      if (list) {
        options = list.map((x) => {
          return {
            text: x.fundraiser.name,
            value: x.fundraiser.id
          };
        });
      }
    }

    return options;
  };

  getTeamOptions = () => {
    let options = [];
    if (this.props.teamOptions) {
      const { list } = this.props.teamOptions.data;
      if (list) {
        options = list.map((x) => {
          return {
            text: x.team.name,
            value: x.team.id
          };
        });
      }
    }

    return options;
  };

  getCollectionOptions = () => {
    let options = [];
    if (this.props.collectionOptions) {
      const { list } = this.props.collectionOptions.data;
      if (list) {
        options = list.map((x) => {
          return {
            text: x.collection.name,
            value: x.collection.id
          };
        });
      }
    }

    return options;
  };

  getConditionInputComponent = () => {
    const { i18Key } = this.props;
    const condition = this.props.conditions.find(
      (el) => el.key === this.state.pageType
    );

    if (condition) {
      const validationErrorMessage = validationHelper.getValidationErrorMessage(
        this.required,
        get(condition, 'value.0')
      );

      return (
        <React.Fragment>
          {condition.key ===
            campaignGiftMatchingConditionType.PageFundraiser && (
            <CommonForm.Autocomplete
              // label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.pageFundraiser.label}`)}
              value={get(condition, 'value.0')}
              onChange={(evt, obj) =>
                this.props.handleConditionValueChange(
                  0,
                  condition.key,
                  obj.value
                )
              }
              search={this.onFundraiserSearch}
              options={this.getFundraiserOptions()}
              loading={
                this.props.fundraiserOptions
                  ? Record.isRecordLoading(this.props.fundraiserOptions)
                  : false
              }
              clear={this.fundraiserClear}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageFundraiser.placeholder}`
              )}
              subtext={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageFundraiser.subtext}`
              )}
              validation={this.required}
              validationErrorMessage={validationErrorMessage}
            />
          )}
          {condition.key === campaignGiftMatchingConditionType.PageTeam && (
            <CommonForm.Autocomplete
              // label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.pageTeam.label}`)}
              value={get(condition, 'value.0')}
              onChange={(evt, obj) =>
                this.props.handleConditionValueChange(
                  0,
                  condition.key,
                  obj.value
                )
              }
              search={this.onTeamSearch}
              options={this.getTeamOptions()}
              loading={
                this.props.teamOptions
                  ? Record.isRecordLoading(this.props.teamOptions)
                  : false
              }
              clear={this.teamClear}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageTeam.placeholder}`
              )}
              subtext={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageTeam.subtext}`
              )}
              validation={this.required}
              validationErrorMessage={validationErrorMessage}
            />
          )}
          {condition.key ===
            campaignGiftMatchingConditionType.PageCollection && (
            <CommonForm.Autocomplete
              // label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.pageCollection.label}`, { alias: this.props.collectionGeneralName || "Collection" })}
              value={get(condition, 'value.0')}
              onChange={(evt, obj) =>
                this.props.handleConditionValueChange(
                  0,
                  condition.key,
                  obj.value
                )
              }
              search={this.onCollectionSearch}
              options={this.getCollectionOptions()}
              loading={
                this.props.collectionOptions
                  ? Record.isRecordLoading(this.props.collectionOptions)
                  : false
              }
              clear={this.collectionClear}
              placeholder={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageCollection.placeholder}`,
                { alias: this.props.collectionGeneralName || 'collection' }
              )}
              subtext={I18n.t(
                `${i18Key}.${i18GiftMatchingKeys.pageCollection.subtext}`,
                { alias: this.props.collectionGeneralName || 'collection' }
              )}
              validation={this.required}
              validationErrorMessage={validationErrorMessage}
            />
          )}
        </React.Fragment>
      );
    }
  };

  render() {
    const { i18Key } = this.props;

    return (
      <React.Fragment>
        {this.props.isEdit && (
          <React.Fragment>
            <Form.Field>
              <span>
                <Checkbox
                  checked={this.state.checked}
                  onChange={(event, { checked }) => {
                    this.handleConditionChange(checked);
                  }}
                  label={I18n.t(`${i18Key}.conditions.certainPage.label`)}
                />
              </span>
            </Form.Field>
            {this.state.checked && (
              <Form.Field
                required={this.required}
                className="padding-left-style"
              >
                {/* <label>{I18n.t(`${i18Key}.conditions.certainPage.pageType.label`)}</label> */}
                <Dropdown
                  options={getPageTypeOptions(this.props.collectionGeneralName)}
                  onChange={this.handlePageTypeChange}
                  value={this.state.pageType}
                  selection
                  icon="chevron down"
                />
                <div className={`input-sub input-sub-bottom`}>
                  {I18n.t(`${i18Key}.conditions.certainPage.pageType.subText`)}
                </div>
              </Form.Field>
            )}
            {this.state.checked && this.state.pageType && (
              <Form.Field className="padding-left-style">
                {this.getConditionInputComponent()}
              </Form.Field>
            )}
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    isEdit: state.templateBuilderEx.isEdit,
    campaignId: templateHelper.getValue(state.templateBuilderEx, 'id'),
    collectionGeneralName: templateHelper.getValue(
      state.templateBuilderEx,
      'template.value.collectionSetup.generalName'
    ),
    fundraiserOptions: get(
      state.giftMatching,
      `gift-matching-${ownProps.index}-fundraisers`
    ),
    teamOptions: get(
      state.giftMatching,
      `gift-matching-${ownProps.index}-teams`
    ),
    collectionOptions: get(
      state.giftMatching,
      `gift-matching-${ownProps.index}-collections`
    )
  };
};

const mapDispatch = (dispatch) => {
  return {
    getFundraiserOptions(path, campaignId, searchtext, fundraiserId) {
      dispatch(
        getFundraiserOptions(path, campaignId, searchtext, fundraiserId)
      );
    },
    getTeamOptions(path, campaignId, searchtext, teamId) {
      dispatch(getTeamOptions(path, campaignId, searchtext, teamId));
    },
    getCollectionOptions(path, campaignId, searchtext, collectionId) {
      dispatch(
        getCollectionOptions(path, campaignId, searchtext, collectionId)
      );
    }
  };
};

const GiftMatchingConditionPageItem = connect(
  mapState,
  mapDispatch
)(GiftMatchingConditionPageItemContainer);
export default GiftMatchingConditionPageItem;
