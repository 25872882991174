import React, { Component } from 'react';
import { Form, Checkbox } from 'semantic-ui-react';

class CheckboxInput extends Component {
  render() {
    const { label, className, inputLabel, subtext, required, ...pprops } =
      this.props;
    return (
      <Form.Field className={className} required={required}>
        {label && <label>{label}</label>}
        <Checkbox label={inputLabel} {...pprops} />
        {subtext && <div className="input-sub">{subtext}</div>}
      </Form.Field>
    );
  }
}

export default CheckboxInput;
