import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { List, Form } from 'semantic-ui-react';

import TemplateBuilderContainerEx from '../../../components/template/builder/indexEx';
import { TemplatePage } from '../../../components/common/pager';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateText } from '../../../components/template/templateText';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';
import { TemplateDatePicker } from '../../../components/template/templateDatePicker';
import { TemplateTimePicker } from '../../../components/template/templateTimePicker';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';

import config from '../../../constants';
import { getUrl } from '../../../modules/bin/utility';
import get from 'lodash.get';
import { AppDispatch, RootState } from '../../../store';
import { setResultValue } from '../../../modules/template-builder-ex';
import { useAppDispatch } from '../../../hooks';
import { paths } from '../constants/template';
import { setTimeAndPlaceDateTime } from '../../../helpers/templateHelper';

interface EventCloneTemplateProps {
  isLoading: boolean;
  onSave: (data: any) => void;
}

const requiredField = {
  required: true
};

const requiredMaxLength = {
  ...requiredField,
  maxLength: true,
  length: config.URL_PATH_MAX_LENGTH
};

const locationValidation = {
  maxLength: true,
  length: config.LOCATION_MAX_LENGTH
};

const timeDetailsValidation = {
  maxLength: true,
  length: config.TIME_DETAILS_MAX_LENGTH
};

const dateValidation = {
  date: true
};

const EventCloneTemplate = ({ onSave, isLoading }: EventCloneTemplateProps) => {
  const eventSelector = useSelector((state: RootState) => state.event);
  const dispatch: AppDispatch = useAppDispatch();
  const [subdomainTouched, setSubdomainTouched] = useState(false);
  const [lowestTier, setLowestTier] = useState('');
  const [isTimeAndPlaceEnabled, setIsTimeAndPlaceEnabled] = useState(false);
  const { record } = eventSelector;

  const onChange = (path: string, value: any) => {
    dispatch(setResultValue(path, value));
  };

  const onEventChange = (path: any, value: any) => {
    if (!subdomainTouched) {
      onChange('urlPath', getUrl(value));
    }
    onChange(path, value);
  };

  const onEventDomainChange = (path: any, value: any) => {
    setSubdomainTouched(true);
    onChange(path, getUrl(value));
  };

  const getDateAndTimeValidation = (path: any) => {
    return [getChildValidation(path, dateValidation)];
  };

  const getChildValidation = (path: any, validation: { date: boolean }) => {
    return {
      path,
      validation
    };
  };

  const onAllowTimeAndPlaceChange = (path: any, value: boolean) => {
    setIsTimeAndPlaceEnabled(value);
    onChange(path, value);
  };

  useEffect(() => {
    let event = get(record, 'data.data');
    if (!event) return;
    // time and place
    const startDate = get(event, 'timeAndPlace.time.start');
    const endDate = get(event, 'timeAndPlace.time.end');
    const includesTime = get(event, 'timeAndPlace.time.includesTime');
    const timeDetails = get(event, 'timeAndPlace.time.text');
    const place = get(event, 'timeAndPlace.place.text');

    onChange('template', event.template);

    // setup display rules for the time and place data
    if (startDate || endDate || timeDetails || place) {
      onAllowTimeAndPlaceChange(paths.ALLOW_TIME_AND_PLACE, true);
      // prettier-ignore
      const timeAndPlace = setTimeAndPlaceDateTime(event, startDate, endDate, includesTime);
      onChange('timeAndPlace', timeAndPlace);
    } else {
      onAllowTimeAndPlaceChange(paths.ALLOW_TIME_AND_PLACE, false);
    }

    // update all organisations to the lowest prioirty
    const tiers = get(event, 'template.value.tiers');
    const organizations = event.organizations;
    if (!tiers || !organizations || organizations.length === 0) return;
    // prettier-ignore
    // first over the tiers from lowest to highest priority
    const orderedTiers = Object.keys(tiers).map((k) => tiers[k].key).reverse();
    // order the organizations based on the tiers
    const sortedOrganizations = organizations.sort((a: any, b: any) => {
      let index1 = orderedTiers.indexOf(a);
      let index2 = orderedTiers.indexOf(b);
      return index1 === -1 ? 1 : index2 === -1 ? -1 : index1 - index2;
    });
    // lowest tier currently being used is the sortedOrganizations[0].priority;
    const lowestTier = sortedOrganizations[0].priority;
    setLowestTier(lowestTier);
    // map lowest tier to organizations
    const newOrganizations = organizations.map((data: any) => {
      data.priority = lowestTier;
      return data;
    });
    // set new data
    onChange('organizations', newOrganizations);
  }, [record]);

  return (
    // @ts-ignore
    <TemplateBuilderContainerEx onSave={onSave} isLoading={isLoading}>
      <TemplatePage id="setup" i18nKey="template.page.setup">
        <div className="template-heading">
          <TemplateHeader
            as="h3"
            label="event.template.page.setup-clone.header"
          />

          <TemplateText
            label="event.template.page.setup-clone.subheader"
            size="small"
          />
        </div>

        <TemplateInput
          id="setupEventName"
          path="name"
          label="event.template.page.setup-clone.event-name.label"
          subtext="event.template.page.setup-clone.event-name.subtext"
          placeholder="event.template.page.setup-clone.event-name.label"
          validation={requiredMaxLength}
          onChange={onEventChange}
        />

        <TemplateInput
          id="setupEventDomain"
          path="urlPath"
          label="event.template.page.setup-clone.event-subdomain.label"
          placeholder="event.template.page.setup-clone.event-domain.label"
          validation={requiredField}
          onChange={onEventDomainChange}
        />

        <TemplateHorizontalRule />

        <TemplateHeader
          as="h3"
          path="timeAndPlace"
          label="event.template.page.setup-clone.time-and-place.header"
        />

        <TemplateCheckbox
          id="fundraisersFundraising"
          path="template.value.timeAndPlace.enabled"
          inputLabel="event.template.page.setup-clone.time-and-place.checkbox.label"
          onChange={onAllowTimeAndPlaceChange}
        />

        {isTimeAndPlaceEnabled && (
          <>
            <Form.Group
              widths="equal"
              path="dateAndTime"
              validation={getDateAndTimeValidation(paths.START_DATE)}
            >
              <TemplateDatePicker
                id="setupStartDate"
                path="timeAndPlace.date.start"
                label="event.template.page.setup.time-and-place.start-date.label"
                subtext="event.template.page.setup.time-and-place.start-date.subtext"
                onChange={onChange}
                validation={dateValidation}
              />

              <TemplateTimePicker
                id="setupStartTime"
                path="timeAndPlace.time.start"
                label="event.template.page.setup.time-and-place.time.label"
                subtext="event.template.page.setup.time-and-place.time.subtext"
                onChange={onChange}
              />
            </Form.Group>

            <Form.Group
              widths="equal"
              path="dateAndTime"
              validation={getDateAndTimeValidation(paths.END_DATE)}
            >
              <TemplateDatePicker
                id="setupEndDate"
                path="timeAndPlace.date.end"
                label="event.template.page.setup.time-and-place.end-date.label"
                subtext="event.template.page.setup.time-and-place.end-date.subtext"
                onChange={onChange}
                validation={dateValidation}
              />

              <TemplateTimePicker
                id="setupEndTime"
                path="timeAndPlace.time.end"
                label="event.template.page.setup.time-and-place.time.label"
                subtext="event.template.page.setup.time-and-place.time.subtext"
                onChange={onChange}
              />
            </Form.Group>

            <TemplateInput
              id="setupTimeDetails"
              path="timeAndPlace.time.text"
              label="event.template.page.setup-clone.time-and-place.time-details.label"
              placeholder="event.template.page.setup-clone.time-and-place.time-details.placeholder"
              validation={timeDetailsValidation}
              onChange={onChange}
            />

            <TemplateInput
              id="setupLocation"
              path="timeAndPlace.place.text"
              label="event.template.page.setup-clone.time-and-place.place.label"
              subtext="event.template.page.setup-clone.time-and-place.place.subtext"
              validation={locationValidation}
              onChange={onChange}
            />

            <TemplateHorizontalRule path="timeAndPlace" />
          </>
        )}

        <TemplateHeader
          as="h3"
          label="event.template.page.setup-clone.fundraising.header"
        />

        <TemplateInput
          id="fundraising-signup-text"
          path="template.value.registrationPageText"
          label="event.template.page.setup-clone.fundraising.signup-text"
          validation={requiredField}
          onChange={onChange}
        />

        <TemplateHorizontalRule />

        <TemplateHeader
          as="h3"
          label="event.template.page.setup-clone.donations.header"
        />

        <TemplateInput
          id="donation-text"
          path="template.value.donationSetup.donationPageText"
          label="event.template.page.setup-clone.donations.donation-text"
          validation={requiredField}
          onChange={onChange}
        />

        <TemplateHorizontalRule />

        <TemplateHeader
          as="h4"
          label="event.template.page.setup-clone.notes.header"
        />

        <List bulleted>
          <List.Item className="padding-left-style">
            {I18n.t('event.template.page.setup-clone.notes.sentence-1')}
          </List.Item>
          <List.Item className="padding-left-style">
            {I18n.t('event.template.page.setup-clone.notes.sentence-2')}
          </List.Item>
          <List.Item className="padding-left-style">
            {I18n.t('event.template.page.setup-clone.notes.sentence-3')}
          </List.Item>
          <List.Item className="padding-left-style">
            {I18n.t('event.template.page.setup-clone.notes.sentence-4')}
          </List.Item>
          <List.Item className="padding-left-style">
            {I18n.t('event.template.page.setup-clone.notes.sentence-5', {
              lowestTier: lowestTier
                ? lowestTier
                : I18n.t(
                    'event.template.page.setup-clone.notes.sentence-5-empty'
                  )
            })}
          </List.Item>
          <List.Item className="padding-left-style">
            <b>{I18n.t('event.template.page.setup-clone.notes.sentence-6')}</b>
          </List.Item>
        </List>
      </TemplatePage>
    </TemplateBuilderContainerEx>
  );
};

export default EventCloneTemplate;
