// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

// Actions
import * as eventActions from '../store/eventActions';

// Components
import clear from '../../../components/clear';
import BasePage from '../../../components/common/basePageView';
import { Common } from '../../../components';
import ListsViewContainer from './listview';

// Constants
import config from '../../../constants/index';
import { i18nKeys } from '../constants';
import { gridKeys } from '../../../constants/gridKeys';

// Helpers
import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import * as pageHelper from '../../../helpers/pageHelper';

class EventView extends Component {
  constructor(props) {
    super(props);
    pageHelper.loadRecordIfRequired(props);
  }

  onEditEventClick = () => {
    const eventId = get(this.props, 'match.params.id');
    this.props.history.push(`/events/edit/${eventId}/setup`);
  };

  onVisitEventClick = () => {
    const linkToPublicSite = get(this.props, 'record.data.data.urlFull');
    window.open(linkToPublicSite, '_blank');
  };

  render() {
    const eventId = get(this.props, 'match.params.id');
    const baseFilter = { key: 'eventId', operator: 'EqualTo', value: eventId };

    let recordData = null;
    const isRecordReady = Record.isRecordReady(this.props.record);

    if (isRecordReady) {
      recordData = this.props.record.data.data;
    }

    const headerData = !isRecordReady
      ? { loaded: false, id: null, name: null }
      : {
          loaded: true,
          id: recordData.id,
          name: recordData.name,
          image: recordData.mainImagePath
        };

    return (
      <BasePage>
        <div>
          <Common.PageHeader
            loading={!headerData.loaded}
            name={headerData.name}
            image={headerData.image}
            headerRightPanel={
              <div className="page-header-right-panel">
                {!this.props.isEventAdmin && !this.props.isEventOrganizerAdmin && (
                  <Common.Button onClick={this.onEditEventClick}>
                    <Translate value={i18nKeys.VIEW_EDIT_BUTTON} />
                  </Common.Button>
                )}
                <Common.Button onClick={this.onVisitEventClick}>
                  <Translate value={i18nKeys.VIEW_VISIT_BUTTON} />
                </Common.Button>
              </div>
            }
          >
            <Link to={config.DEFAULT_EVENTS_URL} push>
              <Common.PageHeader.Breadcrumb
                label={I18n.t(i18nKeys.VIEW_LABEL)}
              />
            </Link>
            <Common.PageHeader.Breadcrumb label={headerData.name} />
          </Common.PageHeader>
        </div>
        <ListsViewContainer
          baseFilter={baseFilter}
          filterPath="/events/view"
          organizationsGridKey={gridKeys.EVENT_ORGANIZATIONS}
          donationsGridKey={gridKeys.EVENT_DONATIONS}
          fundraisersGridKey={gridKeys.EVENT_FUNDRAISERS}
        />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = (state) => {
  return {
    record: state.event.record,
    isEventAdmin: get(state.session, 'isEventAdmin'),
    isEventOrganizerAdmin: get(state.session, 'isEventOrganizerAdmin')
  };
};

const mapDispatch = (dispatch) => {
  const dispatchers = bindActionCreators(eventActions, dispatch);

  return dispatchers;
};

/** Connects component to Redux store */
const ViewEventContainer = clear(connect(mapState, mapDispatch)(EventView));
export default ViewEventContainer;
