import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../';

import * as templateHelper from './templateHelper';
import { getFullImagePathByType } from '../../constants/imageUpload';

class TemplateEditableUpload extends Component {
  onSuccess = (imagePath) => {
    this.props.onChange(this.props.path, imagePath);
  };

  render() {
    const {
      label,
      subtext,
      value,
      height,
      width,
      format,
      position,
      uploadType,
      uploading,
      validation,
      entity,
      onChange,
      path,
      disabled,
      disableCropping
    } = this.props;
    return (
      <Form.EditableUpload
        label={label}
        subtext={subtext}
        url={value}
        height={height}
        width={width}
        format={format}
        position={position}
        uploadType={uploadType}
        entity={entity}
        uploading={uploading}
        required={validation && validation.required}
        onChange={onChange}
        path={path}
        onSuccess={this.onSuccess}
        disableCropping={disableCropping}
        disabled={disabled}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  const { id } = ownProps;

  const label = templateHelper.getTranslation(ownProps.label);
  const subtext = templateHelper.getTranslation(ownProps.subtext);
  const imagePath = templateHelper.getValue(
    state.templateBuilderEx,
    ownProps.path
  );
  const value = imagePath
    ? getFullImagePathByType(imagePath, ownProps.uploadType)
    : '';
  const disabled = templateHelper.getState(
    state.templateBuilderEx,
    ownProps.path,
    'disabled'
  );

  // Component configuration
  const path = ownProps.path;
  const height = ownProps.height;
  const width = ownProps.width;
  const format = ownProps.format;
  const position = ownProps.position;
  const uploadType = ownProps.uploadType;

  return {
    id,
    label,
    subtext,
    value,
    height,
    width,
    format,
    position,
    uploadType,
    path,
    disabled
  };
};

const TemplateEditableUploadContainer = connect(mapState)(
  TemplateEditableUpload
);
export default TemplateEditableUploadContainer;
