import React from 'react';
import AnimateHeight from 'react-animate-height';

import { I18n } from 'react-redux-i18n';

interface IViewSectionRowProps {
  title: string;
  data: any;
  className?: string;
  dynamicI18KeyTitle?: string;
  backgroundColor?: string;
  isShowEmptyFields?: boolean;
}

export const ViewSectionRow = ({
  title,
  data,
  className,
  dynamicI18KeyTitle,
  backgroundColor,
  isShowEmptyFields
}: IViewSectionRowProps) => {
  return (
    <React.Fragment>
      <AnimateHeight
        animateOpacity
        duration={300}
        height={data || isShowEmptyFields ? 'auto' : 0}
      >
        <div className="view-details-content-row">
          <h4>{dynamicI18KeyTitle || I18n.t(title)}</h4>
          <span className={className} style={{ background: backgroundColor }}>
            {data}
          </span>
        </div>
      </AnimateHeight>
    </React.Fragment>
  );
};
