import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import { Record } from '../../../modules/bin/utility';
import { Button, Modal } from 'semantic-ui-react';
import * as ticketingActions from '../store/ticketingActions';

class ReceiptTicketWindow extends Component {
  onReissueClick = () => {
    this.props.reissueTicket(this.props.reissueRecord);
  };

  onReissueConfirmClose = () => {
    this.props.hideReceiptConfirmation('ticket');
  };

  render() {
    const email = get(this.props.reissueRecord, 'customer.email');

    const isOpen = this.props.reissueConfirmation;
    const isIssuing = Record.isRecordLoading(this.props.saveRecord);

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        closeIcon
        open={isOpen}
      >
        <Modal.Header>
          {I18n.t('ticket.modals.reissue-ticket.title')}
        </Modal.Header>
        <Modal.Content>
          {I18n.t('ticket.modals.reissue-ticket.content', { email: email })}
          <div className="button-wrapper align-right margin-top-10">
            <Button
              className="stackable-btn"
              primary
              onClick={this.onReissueClick}
              loading={isIssuing}
              disabled={isIssuing}
            >
              Yes
            </Button>
            <Button onClick={this.onReissueConfirmClose}>No</Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ ticketing }) => {
  return {
    saveRecord: get(ticketing, 'saveRecord'),
    reissueRecord: get(ticketing, 'ticket.reissueRecord'),
    reissueConfirmation: get(ticketing, 'ticket.reissueConfirmation')
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(ticketingActions, dispatch);
};

const ReceiptTicketWindowContainer = connect(
  mapState,
  mapDispatch
)(ReceiptTicketWindow);
export default ReceiptTicketWindowContainer;
