//---------------React/Redux
import React from 'react';

//---------------Components
import EditableUpload from '../../../form/editableUpload';

//---------------Constants
import {
  imageTypes,
  imageFormats,
  getFullImagePathByType
} from '../../../../constants/imageUpload';

class AboutImageUpload extends React.Component {
  render() {
    return (
      <div>
        <EditableUpload
          path={this.props.guid}
          format={imageFormats}
          height={500}
          width={1500}
          position="top"
          entity={this.props.entity}
          uploadType={imageTypes.ASSET}
          url={getFullImagePathByType(this.props.value, imageTypes.ASSET)}
          onSuccess={this.props.onValueChange}
        />
      </div>
    );
  }
}

export default AboutImageUpload;
