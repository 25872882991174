import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class CellLinkWrapper extends Component {
  render() {
    const { isUrlInternal, url, target, children } = this.props;

    return isUrlInternal ? (
      <Link to={url} target={target}>
        {children}
      </Link>
    ) : (
      <a href={url} target={target}>
        {children}
      </a>
    );
  }
}

export default CellLinkWrapper;
