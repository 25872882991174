import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Form } from '../../../../components';
import * as templateBuilderActions from '../../../../modules/template-builder-ex';
import { TemplateHeader } from '../../../../components/template/templateHeader';
import { TemplateHorizontalRule } from '../../../../components/template/templateHorizontalRule';

import * as templateHelper from '../../../../components/template/templateHelper';

function CustomSettings(props) {
  const [hideCharityLeaderboard, setHideCharityLeaderboard] = useState(
    props.hideCharityLeaderboard || false
  );
  const [eventCampaignName, setEventCampaignName] = useState(
    props.campaignNameTemplate || ''
  );
  const [customEventName, setCustomEventName] = useState(
    props.customEventName || ''
  );

  return (
    <div>
      <TemplateHorizontalRule />
      <TemplateHeader as="h3" label="Custom Settings" />

      <Form.Input
        placeholder="Event campaign template name"
        label="Event Campaign Template Name"
        subtext={`This field allows to set the campaign name for ${props.eventName}`}
        value={eventCampaignName}
        onChange={(event) => {
          const value = event.target.value;
          setEventCampaignName(value);
          props.setResultValue(
            'template.value.customText.campaignNameTemplate',
            value
          );
        }}
      />

      <Form.Input
        placeholder="Custom event name"
        label="Custom Event Name"
        subtext="This field allows for the use of italics or bold on the event name, but it only affects what's displayed on public pages, not in other places."
        value={customEventName}
        onChange={(event) => {
          const value = event.target.value;
          setCustomEventName(value);
          props.setResultValue('template.value.customText.eventName', value);
        }}
      />

      <Form.Checkbox
        id="eventEditHideCharityLeaderboard"
        inputLabel="Hide Charity Leaderboard"
        checked={hideCharityLeaderboard}
        onChange={() => {
          setHideCharityLeaderboard(!hideCharityLeaderboard);
          props.setResultValue(
            'template.value.hideCharityLeaderboard',
            !hideCharityLeaderboard
          );
        }}
      />
    </div>
  );
}

const mapState = (state) => {
  return {
    eventName: templateHelper.getValue(state.templateBuilderEx, 'name'),
    campaignNameTemplate: templateHelper.getValue(
      state.templateBuilderEx,
      'template.value.customText.campaignNameTemplate'
    ),
    customEventName: templateHelper.getValue(
      state.templateBuilderEx,
      'template.value.customText.eventName'
    ),
    hideCharityLeaderboard: templateHelper.getValue(
      state.templateBuilderEx,
      'template.value.hideCharityLeaderboard'
    )
  };
};

const mapDispatch = (dispatch) => {
  return bindActionCreators(templateBuilderActions, dispatch);
};

export default connect(mapState, mapDispatch)(CustomSettings);
