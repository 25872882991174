import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import * as templateHelper from '../../../components/template/templateHelper';
import * as validationHelper from '../../../helpers/validationHelper';
import { Button, Icon, Message } from 'semantic-ui-react';
import { I18n } from 'react-redux-i18n';
import { TemplateHeader } from '../../../components/template/templateHeader';
import { TemplateText } from '../../../components/template/templateText';
import { TemplateInput } from '../../../components/template/templateInput';
import { TemplateCheckbox } from '../../../components/template/templateCheckbox';

import { RootState } from '../../../store';
import { TemplateHorizontalRule } from '../../../components/template/templateHorizontalRule';
import { useTemplateList, useTemplateListItem } from './useTemplateList';
import { TemplateRichTextEditor } from '../../../components/template/templateRichTextEditor/templateRichTextEditor';

const i18Key = 'template.page.content.termsAndConditionSetup';

const TermsAndConditionSetupConstants = {
  SIGNATURE_VERIFICATION_ENABLED: 'template.value.enableSignature',
  TERMS_AND_CONDITIONS_ENABLED:
    'template.value.termsAndConditionSetup.allowTermsAndConditions',
  TERMS_AND_CONDITIONS_TITLE: 'template.value.termsAndConditionSetup.title',
  TERMS_AND_CONDITIONS_HTML_CONTENT:
    'template.value.termsAndConditionSetup.htmlContent',
  WAIVERS_ENABLED: 'template.value.termsAndConditionSetup.allowWaivers',
  WAIVERS: 'template.value.termsAndConditionSetup.waivers'
};

const Validation = {
  required: {
    required: true
  }
};

interface TermsAndConditionSetupBuilderProps<Value = any> {
  id?: string;
  path: string;
  validation?: any;
  onChange?(path: string, values: Value): void;
}

export const TermsAndConditionSetupBuilder: React.FC<
  TermsAndConditionSetupBuilderProps
> = (props) => {
  const campaignSelector = useSelector((state: RootState) => state.campaign);
  const templateBuilderEx = useSelector(
    (state: RootState) => state.templateBuilderEx
  );
  const values = templateHelper.getValue(templateBuilderEx, props.path) || [];
  const isValid = validationHelper.isValid(props.validation, values);
  const errorMessage = validationHelper.getValidationErrorMessage(
    props.validation,
    values
  );
  const showTerms = templateHelper.getValue(
    templateBuilderEx,
    TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_ENABLED
  );
  const showWaivers = templateHelper.getValue(
    templateBuilderEx,
    TermsAndConditionSetupConstants.WAIVERS_ENABLED
  );

  useEffect(() => {
    if (!props.onChange || !values || !showTerms) {
      return;
    }
    if (values.title === null || !values.hasOwnProperty('title')) {
      const defaultTitle = I18n.t(i18Key + '.terms.title.default', {
        name:
          campaignSelector?.record?.data?.data?.name ||
          (templateBuilderEx?.data as any)?.name
      });

      props.onChange(
        TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_TITLE,
        defaultTitle
      );
    }
  }, [values, showTerms]);

  return (
    <>
      {/* TODO: re-add once signature API changes have been implemented */}
      {/* <TemplateHeader as="h3" label={i18Key + '.signature.header'} />
      <TemplateText size="small" label={i18Key + '.signature.description'} />
      <TemplateCheckbox
        id={TermsAndConditionSetupConstants.SIGNATURE_VERIFICATION_ENABLED}
        path={TermsAndConditionSetupConstants.SIGNATURE_VERIFICATION_ENABLED}
        inputLabel={i18Key + '.signature.checkbox'}
        onChange={props.onChange}
      />
      <TemplateHorizontalRule /> */}
      <TemplateHeader as="h3" label={i18Key + '.terms.header'} />
      <TemplateText size="small" label={i18Key + '.terms.description'} />
      <TemplateCheckbox
        id={TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_ENABLED}
        path={TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_ENABLED}
        inputLabel={i18Key + '.terms.checkbox'}
        onChange={props.onChange}
      />
      {showTerms && (
        <>
          <TemplateInput
            id={TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_TITLE}
            path={TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_TITLE}
            label={i18Key + '.terms.title.label'}
            subtext={i18Key + '.terms.title.description'}
            subtextPosition="top"
            onChange={props.onChange}
          />
          <TemplateRichTextEditor
            id={
              TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_HTML_CONTENT
            }
            path={
              TermsAndConditionSetupConstants.TERMS_AND_CONDITIONS_HTML_CONTENT
            }
            label={i18Key + '.terms.rich-text.label'}
            subtextPosition="top"
            onChange={props.onChange}
            validation={Validation.required}
          />
        </>
      )}
      <TemplateHorizontalRule />
      <TemplateHeader as="h3" label={i18Key + '.waiver.header'} />
      <TemplateText size="small" label={i18Key + '.waiver.description'} />
      <TemplateCheckbox
        id={TermsAndConditionSetupConstants.WAIVERS_ENABLED}
        path={TermsAndConditionSetupConstants.WAIVERS_ENABLED}
        inputLabel={i18Key + '.waiver.checkbox'}
        onChange={props.onChange}
      />
      {showWaivers && (
        <WaiverBuilder
          path={TermsAndConditionSetupConstants.WAIVERS}
          onChange={props.onChange}
          validation={props.validation}
        />
      )}
      {!isValid && (
        <>
          <br />
          <Message negative hidden={isValid}>
            <p>{errorMessage}</p>
          </Message>
        </>
      )}
    </>
  );
};

const WaiverConstants = {
  TERMS_AND_CONDITIONS_TITLE: 'title',
  TERMS_AND_CONDITIONS_HTML_CONTENT: 'htmlContent'
};

export const getWaiverModel = () => ({
  title: null,
  htmlContent: ''
});

const WaiverPaths = {
  title: 'title',
  htmlContent: 'htmlContent'
};

interface WaiverBuilder<Value = any> {
  path: string;
  onChange?(path: string, values: Value[]): void;
  validation?: any;
}

const WaiverBuilder: React.FC<WaiverBuilder> = (props) => {
  const templateBuilderEx = useSelector(
    (state: RootState) => state.templateBuilderEx
  );
  const values = templateHelper.getValue(templateBuilderEx, props.path) || [];
  const { onAdd, onDelete } = useTemplateList({
    values,
    path: props.path,
    onChange: props.onChange,
    getModel: getWaiverModel
  });

  return (
    <>
      {values.map((item: any, index: number) => (
        <WaiverItemBuilder
          key={index}
          waiver={item}
          path={props.path}
          index={index}
          onChange={props.onChange}
          onDelete={() => onDelete(index)}
        />
      ))}
      <br />
      <Button fluid onClick={() => onAdd()}>
        {I18n.t(i18Key + '.waiver.button')}
      </Button>
    </>
  );
};

interface WaiverItemBuilderProps<Value = any> {
  index: number;
  path: string;
  waiver: any;
  onChange?(path: string, values: Value): void;
  onDelete?(): void;
}

const WaiverItemBuilder: React.FC<WaiverItemBuilderProps> = (props) => {
  const campaignSelector = useSelector((state: RootState) => state.campaign);
  const templateBuilderEx = useSelector(
    (state: RootState) => state.templateBuilderEx
  );
  const { getPath } = useTemplateListItem({
    index: props.index,
    path: props.path
  });

  useEffect(() => {
    if (!props.onChange || !props.waiver) {
      return;
    }

    const titlePath = getPath(WaiverConstants.TERMS_AND_CONDITIONS_TITLE);

    if (props.waiver.title === null) {
      const defaultTitle = I18n.t(i18Key + '.waiver.title.default', {
        name:
          campaignSelector?.record?.data?.data?.name ||
          (templateBuilderEx?.data as any)?.name
      });

      props.onChange(titlePath, defaultTitle);
    }
  }, []);

  return (
    <Container
      label={I18n.t(i18Key + '.waiver.label', { id: `${props.index + 1}` })}
      onDelete={props.onDelete}
    >
      <TemplateInput
        id={getPath(WaiverConstants.TERMS_AND_CONDITIONS_TITLE)}
        path={getPath(WaiverConstants.TERMS_AND_CONDITIONS_TITLE)}
        label={i18Key + '.waiver.title.label'}
        subtext={i18Key + '.waiver.title.description'}
        subtextPosition="top"
        onChange={props.onChange}
      />
      <TemplateRichTextEditor
        id={getPath(WaiverConstants.TERMS_AND_CONDITIONS_HTML_CONTENT)}
        path={getPath(WaiverConstants.TERMS_AND_CONDITIONS_HTML_CONTENT)}
        label={i18Key + '.waiver.rich-text.label'}
        subtextPosition="top"
        onChange={props.onChange}
        validation={Validation.required}
      />
    </Container>
  );
};

interface IContainerProps {
  label?: React.ReactNode;
  children?: React.ReactNode;
  onDelete?: () => void;
}

export const Container: React.FunctionComponent<IContainerProps> = ({
  label,
  children,
  onDelete
}) => {
  const [isOpen, setOpen] = React.useState(true);

  return (
    <div
      style={{
        marginTop: '2rem',
        border: '1px solid rgba(34, 36, 38, 0.15)'
      }}
    >
      <div
        style={{
          backgroundColor: '#EEE',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Icon
          name={isOpen ? 'chevron down' : 'chevron up'}
          className="right-float"
          style={{ cursor: 'pointer', margin: '0.5rem', flexShrink: '1' }}
          onClick={() => setOpen((open) => !open)}
        />
        <h5
          style={{
            margin: 0,
            padding: '0.5rem',
            flex: 1
          }}
        >
          {label}
        </h5>
        <Icon
          name="remove circle"
          className="right-float"
          size="large"
          style={{ cursor: 'pointer', margin: '0.5rem' }}
          onClick={onDelete}
        />
      </div>
      <div
        style={{
          display: isOpen ? 'flex' : 'none',
          flexDirection: 'column',
          padding: '1rem',
          gap: '0.5rem'
        }}
      >
        {children}
      </div>
    </div>
  );
};
