import update from '../../helpers/update';
import * as entityHandlers from '../../modules/bin/entityHandlers';
import * as merchandiseEntityHandlers from '../../modules/bin/merchandiseEntityHandlers';
import { listReadyStateClear } from '../../helpers/donationHelper';
import { getFullQuestionFields } from '../../helpers/dataCaptureHelper';
import { Record } from '../../modules/bin/utility';

import { getMerchandiseGrid } from './gridConfiguration';
import { gridKeys } from '../../constants/gridKeys';
import { LIST_READY_STATE_CLEAR, CLEAR } from '../../modules/general';
import {
  DELETE_IMPERSONATE_ADMIN_SUCCESS,
  IMPERSONATE_ADMIN_SUCCESS
} from '../../modules/session';
import { templateKeys } from '../../constants/templateKeys';
import get from 'lodash.get';

export const METADATA_REQUESTED = 'merchandise/METADATA_REQUESTED';
export const METADATA_SUCCESS = 'merchandise/METADATA_SUCCESS';
export const METADATA_FAILURE = 'merchandise/METADATA_FAILURE';

export const LIST_DATA_REQUESTED = 'merchandise/LIST_DATA_REQUESTED';
export const LIST_DATA_SUCCESS = 'merchandise/LIST_DATA_SUCCESS';
export const LIST_DATA_FAILURE = 'merchandise/LIST_DATA_FAILURE';

export const GET_EXPORT_FILE_REQUESTED =
  'merchandise/GET_EXPORT_FILE_REQUESTED';
export const GET_EXPORT_FILE_SUCCESS = 'merchandise/GET_EXPORT_FILE_SUCCESS';
export const GET_EXPORT_FILE_FAILURE = 'merchandise/GET_EXPORT_FILE_FAILURE';

export const TOGGLE_COLUMNS_CHANGE = 'merchandise/TOGGLE_COLUMNS_CHANGE';

export const ERROR_CLEAR = 'merchandise/ERROR_CLEAR';

export const CREATE_REQUESTED = 'merchandise/CREATE_REQUESTED';
export const CREATE_SUCCESS = 'merchandise/CREATE_SUCCESS';
export const CREATE_FAILURE = 'merchandise/CREATE_FAILURE';

export const CREATE_MERCHANDISE_REQUESTED =
  'merchandise/CREATE_MERCHANDISE_REQUESTED';
export const CREATE_MERCHANDISE_SUCCESS =
  'merchandise/CREATE_MERCHANDISE_SUCCESS';
export const CREATE_MERCHANDISE_FAILURE =
  'merchandise/CREATE_MERCHANDISE_FAILURE';

export const CREATE_SKU_REQUESTED = 'merchandise/CREATE_SKU_REQUESTED';
export const CREATE_SKU_SUCCESS = 'merchandise/CREATE_SKU_SUCCESS';
export const CREATE_SKU_FAILURE = 'merchandise/CREATE_SKU_FAILURE';

export const GET_REQUESTED = 'merchandise/GET_REQUESTED';
export const GET_SUCCESS = 'merchandise/GET_SUCCESS';
export const GET_FAILURE = 'merchandise/GET_FAILURE';

const i18nListKey = 'merchandise.list.header';

const initialState = {
  timestamp: new Date().getTime(),
  record: Record.getDefaultState(),
  recordEdit: Record.getDefaultState(),
  recordCreate: Record.getDefaultState(),
  exportFileRecord: Record.getDefaultState(),
  [gridKeys.MERCHANDISE]: getMerchandiseGrid(i18nListKey)
};

// State Reducer
export default (state = initialState, action) => {
  let newState = state;
  switch (action.type) {
    case CLEAR:
    case DELETE_IMPERSONATE_ADMIN_SUCCESS:
    case IMPERSONATE_ADMIN_SUCCESS: {
      newState = initialState;
      break;
    }

    case ERROR_CLEAR:
      newState = entityHandlers.crudClearErrorHandler(
        state,
        action.payload.key
      );
      break;

    case METADATA_REQUESTED:
      newState = entityHandlers.metadataRequestedHandler(state, action);
      break;

    case METADATA_SUCCESS:
      newState = entityHandlers.metadataSuccessHandler(state, action);
      break;

    case METADATA_FAILURE:
      newState = entityHandlers.listMetadataFailureHandler(state, action);
      break;

    case LIST_DATA_REQUESTED:
      newState = entityHandlers.listDataRequestedHandler(state, action);
      break;

    case LIST_DATA_SUCCESS:
      newState = entityHandlers.listDataSuccessHandler(state, action);
      break;

    case LIST_DATA_FAILURE:
      newState = entityHandlers.listDataFailureHandler(state, action);
      break;

    case LIST_READY_STATE_CLEAR:
      newState = listReadyStateClear(newState, gridKeys.MERCHANDISE);
      break;

    case GET_REQUESTED:
    case CREATE_REQUESTED:
    case GET_EXPORT_FILE_REQUESTED:
      newState = entityHandlers.getRecordRequestedHandler(state, action);
      break;

    case GET_SUCCESS:
    case CREATE_SUCCESS:
    case GET_EXPORT_FILE_SUCCESS:
      //https://grassrootz.visualstudio.com/grassrootz/_workitems/edit/3218
      if (get(action, 'payload.data.data.skus')) {
        const enabledSkus = get(action, 'payload.data.data.skus').filter(
          (sku) => sku.enabled
        );
        const newAction = update.set(
          action,
          `payload.data.data.skus`,
          enabledSkus
        );
        newState = entityHandlers.getRecordSuccessHandler(state, newAction);
      } else {
        newState = entityHandlers.getRecordSuccessHandler(state, action);
      }
      break;

    case GET_FAILURE:
    case CREATE_FAILURE:
    case GET_EXPORT_FILE_FAILURE:
      newState = entityHandlers.crudErrorHandler(state, action);
      break;

    case TOGGLE_COLUMNS_CHANGE:
      newState = entityHandlers.toggleColumnChangeHandler(state, action);
      break;

    default:
      break;
  }
  return newState;
};

export const clearRecordMessages = (record) => ({
  type: ERROR_CLEAR,
  payload: { key: record }
});

export const getMetadata = (listKey) =>
  entityHandlers.getMetadata(
    'merchandise',
    listKey,
    METADATA_REQUESTED,
    METADATA_SUCCESS,
    METADATA_FAILURE
  );
export const getListData = (
  listKey,
  id,
  request,
  invalidate = false,
  cachedData
) =>
  entityHandlers.getListData(
    'merchandise',
    listKey,
    id,
    LIST_DATA_REQUESTED,
    LIST_DATA_SUCCESS,
    LIST_DATA_FAILURE,
    request,
    invalidate,
    cachedData
  );
export const getListDataBySearchTerm = (listKey, id, request, searchTerm) => {
  const newRequest = update(request, { text: { $set: searchTerm } });
  return getListData(listKey, id, newRequest, true);
};

export const toggleColumnsChange = (listKey, toggleColumns) =>
  entityHandlers.toggleColumnsChange(
    listKey,
    toggleColumns,
    TOGGLE_COLUMNS_CHANGE
  );
// export const getExportFile = (entity, request, fileName) => {
//   return entityHandlers.getExportFile(
//     entity,
//     GET_EXPORT_FILE_REQUESTED,
//     GET_EXPORT_FILE_SUCCESS,
//     GET_EXPORT_FILE_FAILURE,
//     request,
//     'exportFileRecord',
//     fileName
//   );
// };

export const getRecord = (id) =>
  entityHandlers.getRecord(
    'merchandise',
    GET_REQUESTED,
    GET_SUCCESS,
    GET_FAILURE,
    id
  );

export const createMerchandise = (record) =>
  merchandiseEntityHandlers.createMerchandiseRecord(
    'merchandise',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    record,
    (state, data) => {
      data = update.set(data, 'fields', getFullQuestionFields(data));
      data = update.set(data, 'template.key', templateKeys.MERCHANDISE);

      return data;
    },
    'recordCreate'
  );

export const updateMerchandise = (data) =>
  merchandiseEntityHandlers.updateMerchandiseRecord(
    'merchandise',
    CREATE_REQUESTED,
    CREATE_SUCCESS,
    CREATE_FAILURE,
    data.id,
    data,
    (state, data) => {
      data = update.set(data, 'fields', getFullQuestionFields(data));

      return data;
    },
    'recordEdit'
  );
