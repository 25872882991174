import React, { Component } from 'react';
import { Form, Button, Icon, Header, Input, Checkbox } from 'semantic-ui-react';

let classNames = require('classnames');

class FormBuilderPlain extends Component {
  render() {
    const heading = this.props.heading || 'Data capture';
    const description = this.props.description;
    const formBuilderFields = this.props.formBuilderFields;
    const addButtonText = this.props.addButtonText || 'Add question +';

    return (
      <div className="form-builder">
        <Header as="h3">{heading}</Header>
        {description && (
          <p className="form-builder-description">{description}</p>
        )}
        {formBuilderFields
          ? formBuilderFields.map((options, index) => (
              <FormBuilderPlain.Field key={index} {...options} />
            ))
          : this.props.children}
        <Button basic fluid className="add-button" content={addButtonText} />
      </div>
    );
  }
}

class FBField extends Component {
  state = {
    visibilityToggle: this.props.open || false,
    dragged: false
  };

  editHandler() {
    this.setState({
      visibilityToggle: !this.state.visibilityToggle
    });
  }

  render() {
    const fieldClasses = classNames({
      open: this.state.visibilityToggle,
      dragged: this.state.dragged,
      ['type-' + this.props.type]: this.props.type,
      'form-builder-field': true
    });
    const { visibilityToggle } = this.state;
    const { typeName } = this.props;
    const questionNamePlaceholder =
      this.props.namePlaceholder || 'E.g. Name, Phone';
    const { helper } = this.props;
    const helperTextPlaceholder =
      this.props.helperPlaceholder || 'E.g. Your full name';
    return (
      <div className="form-builder-field-wrapper">
        <div className={fieldClasses}>
          <div className="field-header">
            <Icon name="block layout" />
            <strong>{typeName}</strong>
            <Button
              basic
              compact
              as="a"
              floated="right"
              onClick={this.editHandler.bind(this)}
            >
              Edit
            </Button>
          </div>
          {visibilityToggle && (
            <div className="field-content">
              <Form>
                <Form.Field>
                  <label>Question name</label>
                  <Input placeholder={questionNamePlaceholder} />
                </Form.Field>
                {helper && (
                  <Form.Field>
                    <label className="optional">Helper text</label>
                    <div className="input-sub">
                      Additional instructions for this field
                    </div>
                    <Input placeholder={helperTextPlaceholder} />
                  </Form.Field>
                )}
                <Form.Field>
                  <Checkbox label="This is a required field" />
                </Form.Field>
              </Form>
              <Button content="Save question" />
              <Button
                negative
                basic
                content="Delete"
                className="delete-button"
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

FormBuilderPlain.Field = FBField;
export default FormBuilderPlain;
