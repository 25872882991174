//---------------React/Redux
import React, { Component } from 'react';
import { I18n } from 'react-redux-i18n';
import get from 'lodash.get';
import moment from 'moment';

//---------------Components
import { Form, Checkbox, Message } from 'semantic-ui-react';
import { Form as CommonForm } from '../..';
import DatePickerInput from '../../form/datePicker';
import TimePickerInput from '../../form/timePicker';
import ValidatedCurrency from '../../common/validatedCurrency';

//---------------Helpers
import { convertDateAndTimeToDateString } from '../../../helpers/dateAndTimeHelper';

//---------------Constants
import { i18GiftMatchingKeys } from '../constants/translation';
import {
  campaignGiftMatchingConditionTypeSettings,
  campaignGiftMatchingConditionOperator,
  condtionIputType
} from '../../../constants/giftMatching';
import config from '../../../constants';

class GiftMatchingConditionItem extends Component {
  constructor(props) {
    super(props);

    this.required = {
      required: true
    };

    this.dateValidation = {
      required: true,
      date: true
    };
  }

  handleConditionChange = (checked) => {
    this.props.handleConditionChange(this.props._key, checked);
  };

  handleDateValueChange = (arrayIndex, key, value, time) => {
    this.props.handleConditionValueChange(
      arrayIndex,
      key,
      convertDateAndTimeToDateString(value, time)
    );
  };

  handleTimeValueChange = (arrayIndex, key, value, date) => {
    this.props.handleConditionValueChange(
      arrayIndex,
      key,
      convertDateAndTimeToDateString(date, value)
    );
  };

  getTimeValue = (time) => {
    const timePattern = /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/;

    if (timePattern.test(time)) {
      return time;
    } else {
      return moment(time).format(config.DEFAULT_TIME_FORMAT);
    }
  };

  getConditionInputComponent = () => {
    const { condition, i18Key } = this.props;

    if (condition && condition.operator) {
      const showMultileFields =
        condition.operator === campaignGiftMatchingConditionOperator.Between;

      switch (
        campaignGiftMatchingConditionTypeSettings[condition.key].inputType
      ) {
        case condtionIputType.number: {
          const value1 = get(condition, 'value.0');
          const value2 = get(condition, 'value.1');

          const validationErrorMessage =
            showMultileFields &&
            value1 &&
            value2 &&
            parseInt(value1, 10) >= parseInt(value2, 10)
              ? I18n.t(
                  'errors.validation.gift-matching-conditions-value-invalid'
                )
              : '';

          return (
            <React.Fragment>
              <Form.Group widths="equal">
                <ValidatedCurrency
                  // label={I18n.t(`${i18Key}.${showMultileFields ? i18GiftMatchingKeys.value.labelFrom : i18GiftMatchingKeys.value.label}`)}
                  //subtext={I18n.t(`${i18Key}.${showMultileFields ? i18GiftMatchingKeys.value.subtextFrom : i18GiftMatchingKeys.value.subtext}`)}
                  allowDecimal={false}
                  value={get(condition, 'value.0')}
                  onChange={(event) =>
                    this.props.handleConditionValueChange(
                      0,
                      condition.key,
                      event.target.value
                    )
                  }
                  style={{ margin: '0px' }}
                  validation={this.required}
                  //placeholder={`${i18Key}.${condition.key}.${showMultileFields ? i18GiftMatchingKeys.value.placeholderFrom : i18GiftMatchingKeys.value.placeholder}`}
                  placeholder={I18n.t(
                    `${i18Key}.${condition.key}.${
                      showMultileFields
                        ? i18GiftMatchingKeys.value.placeholderFrom
                        : i18GiftMatchingKeys.value.placeholder
                    }`
                  )}
                />
                {showMultileFields && (
                  <ValidatedCurrency
                    // label={I18n.t(`${i18Key}.${i18GiftMatchingKeys.value.labelTo}`)}
                    //subtext={I18n.t(`${i18Key}.${i18GiftMatchingKeys.value.subtextTo}`)}
                    allowDecimal={false}
                    value={get(condition, 'value.1')}
                    onChange={(event) =>
                      this.props.handleConditionValueChange(
                        1,
                        condition.key,
                        event.target.value
                      )
                    }
                    style={{ margin: '0px' }}
                    validation={this.required}
                    //placeholder={`${i18Key}.${condition.key}.${i18GiftMatchingKeys.value.placeholderTo}`}
                    placeholder={I18n.t(
                      `${i18Key}.${condition.key}.${i18GiftMatchingKeys.value.placeholderTo}`
                    )}
                  />
                )}
              </Form.Group>
              <Message negative hidden={!validationErrorMessage}>
                <p>{validationErrorMessage}</p>
              </Message>
            </React.Fragment>
          );
        }
        case condtionIputType.date: {
          const dateTime1 =
            get(condition, 'value.0') &&
            get(condition, 'value.0').replaceAll('/', '-');
          const dateTime2 =
            get(condition, 'value.1') &&
            get(condition, 'value.1').replaceAll('/', '-');

          const date1Value =
            dateTime1 && moment(dateTime1).isValid()
              ? moment(dateTime1).format(config.DEFAULT_DATE_FORMAT)
              : null;
          const date2Value =
            dateTime2 && moment(dateTime2).isValid()
              ? moment(dateTime2).format(config.DEFAULT_DATE_FORMAT)
              : null;

          const time1Value = dateTime1 ? this.getTimeValue(dateTime1) : null;
          const time2Value = dateTime2 ? this.getTimeValue(dateTime2) : null;

          return (
            <div>
              <Form.Field required={this.required}>
                {/* <label>{I18n.t(`${i18Key}.${showMultileFields ? i18GiftMatchingKeys.valueDateTime.labelFrom : i18GiftMatchingKeys.valueDateTime.label}`)}</label> */}
                <Form.Group widths="equal">
                  <DatePickerInput
                    value={date1Value}
                    placeholder={I18n.t(
                      `${i18Key}.${condition.key}.${
                        showMultileFields
                          ? i18GiftMatchingKeys.valueDateTime
                              .placeholderDateFrom
                          : i18GiftMatchingKeys.valueDateTime.placeholderDate
                      }`
                    )}
                    onChange={(value) =>
                      this.handleDateValueChange(
                        0,
                        condition.key,
                        value,
                        time1Value === '00:00' ? null : time1Value
                      )
                    }
                    validation={this.dateValidation}
                  />
                  <TimePickerInput
                    value={time1Value === '00:00' ? null : time1Value}
                    placeholder={I18n.t(
                      `${i18Key}.${condition.key}.${
                        showMultileFields
                          ? i18GiftMatchingKeys.valueDateTime
                              .placeholderTimeFrom
                          : i18GiftMatchingKeys.valueDateTime.placeholderTime
                      }`
                    )}
                    onChange={(value) =>
                      this.handleTimeValueChange(
                        0,
                        condition.key,
                        value,
                        date1Value
                      )
                    }
                  />
                </Form.Group>
                <div className={`input-sub input-sub-bottom`}>
                  {I18n.t(
                    `${i18Key}.${condition.key}.${i18GiftMatchingKeys.valueDateTime.subtext}`,
                    { timeZone: this.props.timezone }
                  )}
                </div>
              </Form.Field>
              {showMultileFields && (
                <Form.Field required={this.required}>
                  {/* <label>{I18n.t(`${i18Key}.${i18GiftMatchingKeys.valueDateTime.labelTo}`)}</label> */}
                  <Form.Group widths="equal">
                    <DatePickerInput
                      value={date2Value}
                      placeholder={I18n.t(
                        `${i18Key}.${condition.key}.${i18GiftMatchingKeys.valueDateTime.placeholderDateTo}`
                      )}
                      onChange={(value) =>
                        this.handleDateValueChange(
                          1,
                          condition.key,
                          value,
                          time2Value === '00:00' ? null : time2Value
                        )
                      }
                      validation={this.dateValidation}
                    />
                    <TimePickerInput
                      value={time2Value === '00:00' ? null : time2Value}
                      placeholder={I18n.t(
                        `${i18Key}.${condition.key}.${i18GiftMatchingKeys.valueDateTime.placeholderTimeTo}`
                      )}
                      onChange={(value) =>
                        this.handleTimeValueChange(
                          1,
                          condition.key,
                          value,
                          date2Value
                        )
                      }
                    />
                  </Form.Group>
                  <div className={`input-sub input-sub-bottom`}>
                    {I18n.t(
                      `${i18Key}.${condition.key}.${i18GiftMatchingKeys.valueDateTime.subtext}`,
                      { timeZone: this.props.timezone }
                    )}
                  </div>
                </Form.Field>
              )}
            </div>
          );
        }
        default:
          return <React.Fragment />;
      }
    }
  };

  render() {
    const {
      _key,
      i18Key,
      condition,
      donationMatchedCount,
      donationMatchedTotalAmount
    } = this.props;

    const isChecked = !!condition;
    const showOperatorSelect =
      isChecked &&
      campaignGiftMatchingConditionTypeSettings[_key].allowedOperators.length >
        1;
    let label = '';

    switch (_key) {
      case 'giftMatchingTotal':
        if (donationMatchedTotalAmount !== undefined) {
          label =
            I18n.t(`${i18Key}.conditions.${_key}.label`) +
            ` - currently $${donationMatchedTotalAmount.toLocaleString()} total matched`;
        }
        else {
          label =
            I18n.t(`${i18Key}.conditions.${_key}.label`);
        }
        break;
      case 'donationCount':
        if (donationMatchedCount !== undefined) {
          label =
            I18n.t(`${i18Key}.conditions.${_key}.label`) + ` - currently ${donationMatchedCount} matched donation(s)`;
        }
        else {
          label =
            I18n.t(`${i18Key}.conditions.${_key}.label`);
        }
        break;
      default:
        label = I18n.t(`${i18Key}.conditions.${_key}.label`);
    }

    return (
      <React.Fragment>
        <Form.Field>
          <span>
            <Checkbox
              checked={isChecked}
              onChange={(event, { checked }) => {
                this.handleConditionChange(checked);
              }}
              label={label}
            />
          </span>
        </Form.Field>

        {showOperatorSelect && (
          <Form.Field className="padding-left-style">
            <Form.Group widths="equal">
              {React.Children.toArray(
                campaignGiftMatchingConditionTypeSettings[
                  _key
                ].allowedOperators.map((item) => {
                  return (
                    <CommonForm.Checkbox
                      inputLabel={I18n.t(
                        `${i18Key}.conditions.${_key}.operators.${item}.label`
                      )}
                      checked={condition.operator === item}
                      onChange={this.props.handleConditionOperatorChange.bind(
                        this,
                        _key,
                        item
                      )}
                      radio
                    />
                  );
                })
              )}
            </Form.Group>
          </Form.Field>
        )}

        {isChecked && (
          <Form.Field className="padding-left-style">
            {this.getConditionInputComponent()}
          </Form.Field>
        )}
      </React.Fragment>
    );
  }
}

export default GiftMatchingConditionItem;
