import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Form } from '../';
import * as templateHelper from './templateHelper';

class ManagedCheckbox extends Component {
  onValueChange(evt, obj) {
    if (this.props.onChange) {
      this.props.onChange(this.props.path, obj.checked);
    }
  }

  render() {
    return (
      <Form.Checkbox
        id={this.props.id}
        className={this.props.className}
        label={this.props.label}
        inputLabel={this.props.inputLabel || this.props.inputLabelComponent}
        subtext={this.props.subtext}
        checked={this.props.value === true}
        onChange={this.onValueChange.bind(this)}
        disabled={this.props.disabled}
        radio={this.props.radio}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    label: templateHelper.getTranslation(ownProps.label),
    subtext: templateHelper.getTranslation(ownProps.subtext),
    inputLabel: templateHelper.getTranslation(
      ownProps.inputLabel,
      ownProps.translationObject
    ),
    value:
      ownProps.value ||
      templateHelper.getValue(state.templateBuilderEx, ownProps.path),
    disabled:
      ownProps.disabled ||
      templateHelper.getState(
        state.templateBuilderEx,
        ownProps.path,
        'disabled'
      )
  };
};

export const TemplateCheckbox = connect(mapState)(ManagedCheckbox);
