import { omit } from 'ramda';
import config from '../../constants';

/**
 * All possible field types in a component
 */
const fieldTypes = {
  conditional: 'conditional',
  defaultValue: 'defaultValue',
  description: 'description',
  maximumValue: 'maximumValue',
  minimumValue: 'minimumValue',
  noText: 'noText',
  options: 'options',
  placeholderText: 'placeholderText',
  required: 'required',
  optOut: 'optOut',
  title: 'title',
  link: 'link',
  content: 'content',
  dropdown: 'dropdown',
  yesText: 'yesText',
  helperText: 'helperText',
  hidden: 'hidden',
  specification: 'type',
  doNotExport: 'doNotExport',
  exportLabel: 'exportLabel'
};

/**
 * All possible display modes for fields in a component
 */
const fieldViewTypes = {
  text: 'text',
  textArea: 'textArea',
  checkbox: 'checkbox',
  checkboxWithConditional: 'checkboxWithConditional',
  dropdown: 'dropdown'
};

/**
 * All possible node types in the tree describing the FormBuilder state.
 */
const nodeTypes = {
  root: 'root',
  section: 'section',
  textInput: 'textInput',
  textArea: 'textArea',
  date: 'date',
  dropdown: 'dropdown',
  checkbox: 'checkbox',
  phone: 'phoneNumber',
  address: 'address',
  country: 'country',
  consent: 'consent',
  additionalPhoneNumber: 'additionalPhoneNumber',
  additionalEmailAddress: 'additionalEmailAddress',
  groupDivider: 'groupDivider',
  targetAmount: 'targetAmount'
};

const additionNodeTypes = {
  additionalFirstName: 'additionalFirstName',
  additionalLastName: 'additionalLastName'
};

const fieldViewTypesValidation = {
  title: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_TITLE_MAX_LENGTH
  },
  link: {
    url: true
  },
  options: {
    required: true,
    maxLength: true,
    length: config.FORMBUILDER_OPTIONS_MAX_LENGTH,
    formbuilderOptions: true
  },
  exportLabel: {
    maxLength: true,
    length: config.FORMBUILDER_EXPORT_LABEL_MAX_LENGTH
  }
};

const fieldsVisibility = {
  visible: {
    key: 'visible',
    isVisible: () => {
      return true;
    }
  },
  isSystemAdminOnly: {
    key: 'isSystemAdminOnly',
    isVisible: (isSystemAdmin) => {
      return !!isSystemAdmin;
    }
  },
  hidden: {
    key: 'hidden',
    isVisible: () => {
      return false;
    }
  }
};

/**
 * All possible component type nodes in the tree describing the FormBuilder state.
 */
const componentTypes = omit([nodeTypes.root, nodeTypes.section], nodeTypes);

export {
  fieldTypes,
  nodeTypes,
  additionNodeTypes,
  componentTypes,
  fieldViewTypes,
  fieldViewTypesValidation,
  fieldsVisibility
};
