import React, { Component } from 'react';
import get from 'lodash.get';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Form, Button, Input } from 'semantic-ui-react';
import {
  clearRecordMessages,
  createDonationSummaryReport,
  donationSummaryModalOpen,
  onValueChange
} from '../store/accountDetailActions';
import * as validationHelper from '../../../../helpers/validationHelper';
import { Record } from '../../../../modules/bin/utility';
import ConfirmationModal from '../../../../components/common/confirmationModal';
import DatePickerInput from '../../../../components/form/datePicker';
import Dropdown from '../../../../components/form/dropdown';
import RecordResultMessage from '../../../../components/common/recordResult';
import { countryCodeOptions } from '../../constants';
import {
  donationSummaryModalFields,
  donationSummaryModalValidation
} from './donationSummaryModalValidation';

class DonationSummaryModal extends Component {
  donationSummaryModalClose = () => {
    this.props.donationSummaryModalOpen(false);
  };

  donationSummaryModalSubmit = () => {
    const donationSummaryModal = get(this.props, 'donationSummaryModal');
    const country = get(donationSummaryModal, 'country');
    const dateFrom = get(donationSummaryModal, 'dateFrom');
    const dateTo = get(donationSummaryModal, 'dateTo');
    this.props.createDonationSummaryReport(
      this.props.email,
      country,
      dateFrom,
      dateTo
    );
  };

  onValueChange = (path, value) => {
    this.props.onValueChange(path, value);
  };

  donationSummaryModalButtons = () => {
    const donationSummaryRecord = get(this.props, 'donationSummaryRecord');
    const isLoading = Record.isRecordLoading(donationSummaryRecord);

    const isCurrencyValid = validationHelper.isValid(
      donationSummaryModalValidation[donationSummaryModalFields.CURRENCY],
      this.props.donationSummaryModal.country
    );

    return (
      <React.Fragment>
        <Button
          primary
          disabled={!isCurrencyValid}
          loading={isLoading}
          onClick={this.donationSummaryModalSubmit}
        >
          <Translate value="account.account-detail.donationSummaryModal.buttons.confirm" />
        </Button>
        <Button onClick={this.donationSummaryModalClose}>
          <Translate value="account.account-detail.donationSummaryModal.buttons.cancel" />
        </Button>
      </React.Fragment>
    );
  };

  clearMessages = () => {
    this.props.clearRecordMessages('donationSummaryRecord');
  };

  donationSummaryModalContent = () => {
    const donationSummaryModal = get(this.props, 'donationSummaryModal');
    const country = get(donationSummaryModal, 'country');
    const dateFrom = get(donationSummaryModal, 'dateFrom');
    const dateTo = get(donationSummaryModal, 'dateTo');

    return (
      <React.Fragment>
        <Form className="donation-summary-form">
          <Form.Field>
            <label>
              <Translate value="account.account-detail.donationSummaryModal.content.email.label" />
            </label>
            <Input
              fluid
              className="email-field"
              value={this.props.email}
              disabled
            />
          </Form.Field>
          <Form.Field className="country-field">
            <Dropdown
              label={
                <Translate value="account.account-detail.donationSummaryModal.content.country.label" />
              }
              options={countryCodeOptions}
              value={country}
              validation={{
                required: true
              }}
              onChange={(_, object) =>
                this.onValueChange('donationSummaryModal.country', object.value)
              }
            />
          </Form.Field>
          <DatePickerInput
            label={
              <Translate value="account.account-detail.donationSummaryModal.content.dateFrom.label" />
            }
            value={dateFrom}
            onChange={(value) => {
              this.onValueChange('donationSummaryModal.dateFrom', value);
            }}
          />
          <DatePickerInput
            label={
              <Translate value="account.account-detail.donationSummaryModal.content.dateTo.label" />
            }
            value={dateTo}
            onChange={(value) => {
              this.onValueChange('donationSummaryModal.dateTo', value);
            }}
          />
        </Form>
      </React.Fragment>
    );
  };

  render() {
    const donationSummaryModalContent = this.donationSummaryModalContent();
    const donationSummaryModalButtons = this.donationSummaryModalButtons();

    return (
      <React.Fragment>
        <RecordResultMessage
          record={this.props.donationSummaryRecord}
          onDismiss={this.clearMessages}
        />
        <ConfirmationModal
          open={this.props.donationSummaryModal.open}
          hideIcon
          customButtons={donationSummaryModalButtons}
          content={donationSummaryModalContent}
          onClose={this.donationSummaryModalClose}
          onSubmit={this.donationSummaryModalSubmit}
        />
      </React.Fragment>
    );
  }
}

const mapState = ({ accountDetail }) => {
  return {
    donationSummaryModal: accountDetail.donationSummaryModal,
    donationSummaryRecord: accountDetail.donationSummaryRecord
  };
};

const mapDispatch = (dispatch) => {
  return {
    createDonationSummaryReport(email, country, dateFrom, dateTo) {
      dispatch(createDonationSummaryReport(email, country, dateFrom, dateTo));
    },
    clearRecordMessages(key) {
      dispatch(clearRecordMessages(key));
    },
    donationSummaryModalOpen(open) {
      dispatch(donationSummaryModalOpen(open));
    },
    onValueChange(path, value) {
      dispatch(onValueChange(path, value));
    }
  };
};

const DonationSummaryModalContainer = connect(
  mapState,
  mapDispatch
)(DonationSummaryModal);
export default DonationSummaryModalContainer;
