import React, { Component } from 'react';
import Frame from './frame';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as authenticationActions from '../../modules/authentication';
import { getPasswordErrorMessage } from '../../helpers/validationHelper';
import {
  Button,
  Header,
  Container,
  Form as SemanticForm
} from 'semantic-ui-react';
import ValidatedInput from '../common/validatedInput';
import { I18n, Translate } from 'react-redux-i18n';
import RecordResultMessage from '../common/recordResult';
import { Record } from '../../modules/bin/utility';
import { withRouter } from 'react-router-dom';
import InvalidPassword from './invalidPassword';

class ResetPassword extends Component {
  handleSubmit = (e) => {
    this.props.authenticationActions.updatePassword(
      this.props.match.params.resetKey,
      this.props.reset.password
    );
  };

  onPasswordChange = (e, { value }) =>
    this.props.authenticationActions.resetPasswordChange(value);

  onConfirmPasswordChange = (e, { value }) =>
    this.props.authenticationActions.resetConfirmPasswordChange(value);

  clearMessages = () => {
    this.props.authenticationActions.clearRecordMessages(
      'updatePasswordRecord'
    );
  };

  goToLogin = () => {
    this.props.history.push(`/login`);
  };

  isResetButtonDisabled = (passwordsErrorMessage, isLoading) => {
    return (
      passwordsErrorMessage !== undefined ||
      this.props.reset.password !== this.props.reset.confirmPassword ||
      isLoading
    );
  };

  render() {
    const isLoading = Record.isRecordLoading(this.props.updatePasswordRecord);
    const isPasswordUpdated =
      this.props.updatePasswordRecord.ready === Record.RECORD_STATUS_READY;
    const { password, confirmPassword } = this.props.reset;

    const passwordsErrorMessage = getPasswordErrorMessage(
      password,
      confirmPassword
    );

    return (
      <Frame>
        <RecordResultMessage
          record={this.props.updatePasswordRecord}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToLogin}
        />
        {!isPasswordUpdated && (
          <div>
            <Header textAlign="center" as="h2">
              Reset your password
            </Header>
            <SemanticForm>
              <Container>
                <ValidatedInput
                  placeholder="New password"
                  label="New password"
                  onChange={this.onPasswordChange}
                  value={password}
                  type="password"
                  error={passwordsErrorMessage !== undefined}
                  errorComponent={
                    <InvalidPassword errors={passwordsErrorMessage} />
                  }
                />

                <ValidatedInput
                  placeholder="Confirm password"
                  label="Confirm password"
                  onChange={this.onConfirmPasswordChange}
                  value={confirmPassword}
                  type="password"
                  errorMessage={
                    password !== confirmPassword
                      ? I18n.t('errors.validation.password.not-equal')
                      : ''
                  }
                />

                <Button
                  primary
                  fluid
                  onClick={this.handleSubmit}
                  loading={isLoading}
                  disabled={this.isResetButtonDisabled(
                    passwordsErrorMessage,
                    isLoading
                  )}
                >
                  Reset password
                </Button>
              </Container>
            </SemanticForm>
          </div>
        )}
        {isPasswordUpdated && (
          <div>
            <Header textAlign="center" as="h2">
              All done!
            </Header>
            <Container textAlign="center">
              <Translate value="general.update-password-success" />
            </Container>
            <Button primary fluid as="a" href="/login">
              Return to log in
            </Button>
          </div>
        )}
      </Frame>
    );
  }
}

const mapStateToProps = ({ authentication }) => {
  return {
    updatePasswordRecord: authentication.updatePasswordRecord,
    reset: authentication.reset
  };
};

const mapDispatch = (dispatch) => {
  return {
    authenticationActions: bindActionCreators(authenticationActions, dispatch)
  };
};

export default withRouter(connect(mapStateToProps, mapDispatch)(ResetPassword));
