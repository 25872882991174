export const i18Keys = {
  ADD_NEW_TICKET: 'add-new-ticket.label',
  ITEM_HEADER: 'label',
  ITEM_TYPE_LABEL: 'type.label',
  ITEM_TITLE_LABEL: 'title.label',
  ITEM_STREAM_LABEL: 'stream.label',
  ITEM_PRICE_LABEL: 'price.label',
  ITEM_QUANTITY_UNLIMITED: 'quantity.unlimited',
  ITEM_PRICE_FREE: 'price.free',
  ITEM_QUANTITY_LABEL: 'quantity.label',
  ITEM_DESCRIPTION_LABEL: 'description.label',
  ITEM_AVAILABLE_UNTIL_LABEL: 'availableUntil.label',
  ITEM_AVAILABLE_UNTIL_SUBTEXT: 'availableUntil.subtext',
  ITEM_FUNDRAISER_TARGET_AMOUNT_LABEL: 'fundraiserTargetAmount.label',
  ITEM_FUNDRAISER_TARGET_AMOUNT_SUBTEXT: 'fundraiserTargetAmount.subtext',
  ITEM_AVAILABLE_FROM_LABEL: 'availableFrom.label',
  ITEM_AVAILABLE_FROM_SUBTEXT: 'availableFrom.subtext',
  ITEM_QUESTIONS_LABEL: 'questions.label'
};
