import get from 'lodash.get';

export const dashboardUrlFilters = {
  SUMMARY_TYPE: 'summaryType',
  GRID_TAB: 'gridTab',
  REPORT_BY_DATE_AND_METRIC_TYPE: 'type',
  TIME_BRACKET: 'timeBracket',
  TIME_LAPSE: 'timeLapse',
  TIME_LAPSE_FROM: 'timeLapseFrom',
  TIME_LAPSE_TO: 'timeLapseTo',
  LOCAL_DATE_FROM: 'LocalDate[from]',
  LOCAL_DATE_TO: 'LocalDate[to]'
};

export const dashboardUrlFiltersSettings = {
  [dashboardUrlFilters.SUMMARY_TYPE]: {
    getPath: () => {
      return dashboardUrlFilters.SUMMARY_TYPE;
    }
  },
  [dashboardUrlFilters.GRID_TAB]: {
    getPath: () => {
      return dashboardUrlFilters.GRID_TAB;
    }
  },
  [dashboardUrlFilters.REPORT_BY_DATE_AND_METRIC_TYPE]: {
    getPath: () => {
      return 'reportByDateAndMetricType';
    }
  },
  [dashboardUrlFilters.TIME_BRACKET]: {
    getPath: () => {
      return dashboardUrlFilters.TIME_BRACKET;
    }
  },
  [dashboardUrlFilters.TIME_LAPSE]: {
    getPath: () => {
      return dashboardUrlFilters.TIME_LAPSE;
    }
  },
  [dashboardUrlFilters.TIME_LAPSE_FROM]: {
    getPath: () => {
      return dashboardUrlFilters.TIME_LAPSE_FROM;
    }
  },
  [dashboardUrlFilters.TIME_LAPSE_TO]: {
    getPath: () => {
      return dashboardUrlFilters.TIME_LAPSE_TO;
    }
  },
  [dashboardUrlFilters.LOCAL_DATE_FROM]: {
    getPath: () => {
      return 'timePeriod.dateFrom';
    }
  },
  [dashboardUrlFilters.LOCAL_DATE_TO]: {
    getPath: () => {
      return 'timePeriod.dateTo';
    }
  }
};

export const reportByDateAndMetricType = {
  Daily: 'Daily',
  Weekly: 'Weekly',
  Monthly: 'Monthly'
};

export const reportByDateAndMetricTypeOptions = [
  {
    text: 'Daily',
    value: reportByDateAndMetricType.Daily
  },
  {
    text: 'Weekly',
    value: reportByDateAndMetricType.Weekly
  },
  {
    text: 'Monthly',
    value: reportByDateAndMetricType.Monthly
  }
];

export const timeLapse = {
  [reportByDateAndMetricType.Daily]: {
    allTime: 'allTime',
    last30Days: 'last30Days',
    last60Days: 'last60Days',
    last180Days: 'last180Days',
    customRange: 'customRange'
  },
  [reportByDateAndMetricType.Weekly]: {
    allTime: 'allTime',
    last2Weeks: 'last2Weeks',
    last10Weeks: 'last10Weeks',
    last25Weeks: 'last25Weeks',
    customRange: 'customRange'
  },
  [reportByDateAndMetricType.Monthly]: {
    allTime: 'allTime',
    last2Months: 'last3Months',
    last10Months: 'last6Months',
    last25Months: 'last9Months',
    customRange: 'customRange'
  }
};

export const timeLapseOptions = {
  [reportByDateAndMetricType.Daily]: {
    [timeLapse[reportByDateAndMetricType.Daily].allTime]: {
      text: 'All time',
      value: null
    },
    [timeLapse[reportByDateAndMetricType.Daily].last30Days]: {
      text: 'Last 30 days',
      value: 30
    },
    [timeLapse[reportByDateAndMetricType.Daily].last60Days]: {
      text: 'Last 60 days',
      value: 60
    },
    [timeLapse[reportByDateAndMetricType.Daily].last180Days]: {
      text: 'Last 180 days',
      value: 180
    },
    [timeLapse[reportByDateAndMetricType.Daily].customRange]: {
      text: 'Custom range',
      value: null
    }
  },
  [reportByDateAndMetricType.Weekly]: {
    [timeLapse[reportByDateAndMetricType.Weekly].allTime]: {
      text: 'All time',
      value: null
    },
    [timeLapse[reportByDateAndMetricType.Weekly].last2Weeks]: {
      text: 'Last 2 weeks',
      value: 14
    },
    [timeLapse[reportByDateAndMetricType.Weekly].last10Weeks]: {
      text: 'Last 10 weeks',
      value: 70
    },
    [timeLapse[reportByDateAndMetricType.Weekly].last25Weeks]: {
      text: 'Last 25 weeks',
      value: 175
    },
    [timeLapse[reportByDateAndMetricType.Weekly].customRange]: {
      text: 'Custom range',
      value: null
    }
  },
  [reportByDateAndMetricType.Monthly]: {
    [timeLapse[reportByDateAndMetricType.Monthly].allTime]: {
      text: 'All time',
      value: null
    },
    [timeLapse[reportByDateAndMetricType.Monthly].last2Months]: {
      text: 'Last 3 months',
      value: 90
    },
    [timeLapse[reportByDateAndMetricType.Monthly].last10Months]: {
      text: 'Last 6 months',
      value: 180
    },
    [timeLapse[reportByDateAndMetricType.Monthly].last25Months]: {
      text: 'Last 9 months',
      value: 270
    },
    [timeLapse[reportByDateAndMetricType.Monthly].customRange]: {
      text: 'Custom range',
      value: null
    }
  }
};

export const summaryType = {
  raisedAmount: 'raisedAmount',
  saleAmount: 'saleRaisedAmount',
  donationAmount: 'donationRaisedAmount',
  numberOfSoldTickets: 'saleDetailCount',
  numberOfDonations: 'donationCount',
  numberOfFundraisingPages: 'teamAndFundraiserCount',
  participantCount: 'participantCount'
};

export const summaryOptions = [
  {
    key: summaryType.raisedAmount,
    i18n: 'raisedAmount',
    prefix: '$',
    isVisible: (_model, _session) => {
      return true;
    }
  },
  {
    key: summaryType.saleAmount,
    i18n: 'saleAmount',
    prefix: '$',
    isVisible: (model, _session) => {
      return get(model, 'ticketing.enabled');
    }
  },
  {
    key: summaryType.donationAmount,
    i18n: 'donationAmount',
    prefix: '$',
    isVisible: (_model, _session) => {
      return true;
    }
  },
  {
    key: summaryType.numberOfSoldTickets,
    i18n: 'numberOfSoldTickets',
    prefix: '',
    isVisible: (model, _session) => {
      return get(model, 'ticketing.enabled');
    }
  },
  {
    key: summaryType.numberOfDonations,
    i18n: 'numberOfDonations',
    prefix: '',
    isVisible: (_model, _session) => {
      return true;
    }
  },
  {
    key: summaryType.numberOfFundraisingPages,
    i18n: 'numberOfFundraisingPages',
    prefix: '',
    isVisible: (model, session) => {
      return (
        !session.isEventOrganizerAdmin &&
        (get(model, 'teams.enabled') || get(model, 'fundraisers.enabled'))
      );
    }
  },
  {
    key: summaryType.participantCount,
    i18n: 'participants',
    prefix: '',
    overrideModelValidation: true,
    isVisible: (_model, session, view) =>
      view === dashboardViewTypeConfigurations.events.key &&
      session.isSystemAdmin
  }
];

export const gridTabs = {
  topCampaigns: 'topCampaigns',
  lastDonations: 'lastDonations',
  topDonations: 'topDonations',
  lastFundraisers: 'lastFundraisers',
  topFundraisers: 'topFundraisers',
  lastSales: 'lastSales',
  campaigns: 'campaigns',
  sales: 'sales',
  tickets: 'tickets',
  fundraisers: 'fundraisers',
  donations: 'donations'
};

export const gridPanelButtons = {
  [gridTabs.lastDonations]: 'donations',
  [gridTabs.topDonations]: 'donations',
  [gridTabs.lastFundraisers]: 'fundraisers',
  [gridTabs.topFundraisers]: 'fundraisers',
  [gridTabs.lastSales]: 'sales'
};

export const gridTabsOptions = {
  [gridTabs.topCampaigns]: {
    i18n: 'topCampaigns',
    isVisible: (
      _model,
      _isCampaignAdmin,
      _isEventAdmin,
      _isEventOrganizerAdmin
    ) => {
      return true;
    }
  },
  [gridTabs.lastDonations]: {
    i18n: 'lastDonations',
    isVisible: (
      _model,
      _isCampaignAdmin,
      isEventAdmin,
      isEventOrganizerAdmin
    ) => {
      return !isEventAdmin && !isEventOrganizerAdmin;
    }
  },
  [gridTabs.topDonations]: {
    i18n: 'topDonations',
    isVisible: (
      _model,
      _isCampaignAdmin,
      isEventAdmin,
      isEventOrganizerAdmin
    ) => {
      return !isEventAdmin && !isEventOrganizerAdmin;
    }
  },
  [gridTabs.lastFundraisers]: {
    i18n: 'lastFundraisers',
    isVisible: (
      model,
      _isCampaignAdmin,
      _isEventAdmin,
      isEventOrganizerAdmin
    ) => {
      return (
        !isEventOrganizerAdmin &&
        (get(model, 'teams.enabled') || get(model, 'fundraisers.enabled'))
      );
    }
  },
  [gridTabs.topFundraisers]: {
    i18n: 'topFundraisers',
    isVisible: (
      model,
      _isCampaignAdmin,
      _isEventAdmin,
      isEventOrganizerAdmin
    ) => {
      return (
        !isEventOrganizerAdmin &&
        (get(model, 'teams.enabled') || get(model, 'fundraisers.enabled'))
      );
    }
  },
  [gridTabs.lastSales]: {
    i18n: 'lastSales',
    isVisible: (model) => {
      return get(model, 'ticketing.enabled');
    }
  },
  [gridTabs.campaigns]: {
    i18n: 'campaigns',
    isVisible: () => true // always show
  },
  [gridTabs.sales]: {
    i18n: 'sales',
    isVisible: (model) => {
      return model?.saleCount > 0;
    }
  },
  [gridTabs.tickets]: {
    i18n: 'tickets',
    isVisible: (model) => {
      return model?.saleCount > 0;
    }
  },
  [gridTabs.fundraisers]: {
    i18n: 'fundraisers',
    isVisible: (model) => {
      return model?.pagesCount > 0;
    }
  },
  [gridTabs.donations]: {
    i18n: 'donations',
    isVisible: (model) => {
      return model?.donationCount > 0;
    }
  }
};

export const gridPageSize = 10;

export const reportTimelineBracket = {
  Default: 'Default',
  TimeToDate: 'TimeToDate',
  DefaultOnline: 'DefaultOnline',
  TimeToDateOnline: 'TimeToDateOnline'
};

export const reportTimelineBracketOptions = {
  [reportTimelineBracket.Default]: {
    text: 'Normal',
    value: reportTimelineBracket.Default
  },
  [reportTimelineBracket.TimeToDate]: {
    text: 'Time to date',
    value: reportTimelineBracket.TimeToDate
  },
  [reportTimelineBracket.DefaultOnline]: {
    text: 'Normal (online)',
    value: reportTimelineBracket.DefaultOnline
  },
  [reportTimelineBracket.TimeToDateOnline]: {
    text: 'Time to date (online)',
    value: reportTimelineBracket.TimeToDateOnline
  }
};

export const chartColors = ['#67B7DC', '#656FD9', '#A367DC'];

export const dashboardViewTypeConfigurations = {
  campaigns: {
    key: 'campaigns',
    entity: 'campaign',
    path: 'page/campaign',
    baseFilter: 'campaignId',
    defaultReportByDateAndMetricType: reportByDateAndMetricType.Daily,
    defaultTimeLapse: timeLapse[reportByDateAndMetricType.Daily].allTime,
    defaultTimeBracket: reportTimelineBracket.TimeToDate,
    disableModelValidation: false,
    showViewAllButton: true,
    showAddOfflineDonationButton: true,
    showCreatePageButton: true,
    getDefaultGridTab: (isEventAdmin, isEventOrganizerAdmin) =>
      isEventAdmin || isEventOrganizerAdmin
        ? gridTabs.lastFundraisers
        : gridTabs.lastDonations,
    gridTabs: [
      gridTabs.lastDonations,
      gridTabs.topDonations,
      gridTabs.lastFundraisers,
      gridTabs.topFundraisers,
      gridTabs.lastSales
    ],
    getTimeBracketOptions: (isSystemAdmin) => {
      let options = [
        reportTimelineBracket.TimeToDate,
        reportTimelineBracket.Default
      ];

      if (isSystemAdmin) {
        options.push(
          reportTimelineBracket.TimeToDateOnline,
          reportTimelineBracket.DefaultOnline
        );
      }

      return options;
    },
    getEditUrl: (id) => {
      return `/campaigns/edit/${id}/setup`;
    },
    getSearchRequest: (searchText) => {
      return {
        page: 1,
        pageSize: 10,
        resultType: 'Full',
        text: searchText,
        orderBy: {
          key: 'name',
          direction: 'asc'
        },
        filters: []
      };
    }
  },
  events: {
    key: 'events',
    entity: 'event',
    path: 'page/event',
    baseFilter: 'eventId',
    defaultReportByDateAndMetricType: reportByDateAndMetricType.Daily,
    defaultTimeLapse: timeLapse[reportByDateAndMetricType.Daily].allTime,
    defaultTimeBracket: reportTimelineBracket.TimeToDate,
    disableModelValidation: false,
    showViewAllButton: true,
    showAddOfflineDonationButton: false,
    showCreatePageButton: false,
    getDefaultGridTab: (isEventAdmin) =>
      isEventAdmin ? gridTabs.lastFundraisers : gridTabs.lastDonations,
    gridTabs: [
      gridTabs.lastDonations,
      gridTabs.topDonations,
      gridTabs.lastFundraisers,
      gridTabs.topFundraisers
    ],
    getTimeBracketOptions: (isSystemAdmin) => {
      let options = [
        reportTimelineBracket.TimeToDate,
        reportTimelineBracket.Default
      ];

      if (isSystemAdmin) {
        options.push(
          reportTimelineBracket.TimeToDateOnline,
          reportTimelineBracket.DefaultOnline
        );
      }

      return options;
    },
    getEditUrl: (id) => {
      return `/events/edit/${id}/setup`;
    }
  },
  organizations: {
    key: 'organizations',
    entity: 'organization',
    path: 'organization',
    baseFilter: 'organizationId',
    defaultReportByDateAndMetricType: reportByDateAndMetricType.Daily,
    defaultTimeLapse: timeLapse[reportByDateAndMetricType.Daily].allTime,
    defaultTimeBracket: reportTimelineBracket.Default,
    disableModelValidation: true,
    showViewAllButton: false,
    showAddOfflineDonationButton: false,
    showCreatePageButton: false,
    getDefaultGridTab: () => gridTabs.topDonations,
    gridTabs: [
      gridTabs.topCampaigns,
      gridTabs.topDonations,
      gridTabs.topFundraisers
    ],
    getTimeBracketOptions: (isSystemAdmin) => {
      let options = [reportTimelineBracket.Default];

      if (isSystemAdmin) {
        options.push(reportTimelineBracket.DefaultOnline);
      }

      return options;
    },

    getEditUrl: (id) => {
      return `/organizations/edit/${id}/about`;
    }
  },
  series: {
    key: 'series',
    entity: 'series',
    path: 'page/series',
    baseFilter: 'seriesId',
    defaultReportByDateAndMetricType: reportByDateAndMetricType.Daily,
    defaultTimeLapse: timeLapse[reportByDateAndMetricType.Daily].allTime,
    defaultTimeBracket: reportTimelineBracket.Default,
    disableModelValidation: true,
    showViewAllButton: false,
    showAddOfflineDonationButton: false,
    showCreatePageButton: false,
    gridTabs: [
      gridTabs.campaigns,
      gridTabs.sales,
      gridTabs.tickets,
      gridTabs.fundraisers,
      gridTabs.donations
    ],
    getDefaultGridTab: () => gridTabs.campaigns,
    getTimeBracketOptions: (isSystemAdmin) => [
      reportTimelineBracket.TimeToDate,
      reportTimelineBracket.Default,
      reportTimelineBracket.TimeToDateOnline,
      reportTimelineBracket.DefaultOnline
    ]
  }
};

export const dashboardCompareMaxSize = 3;
export const dashboardAvailableFilterKeys = [
  'LocalDate[from]',
  'LocalDate[to]',
  'organizationId',
  'campaignId',
  'eventId',
  'timeBracket',
  'type',
  'summaryType',
  'gridTab',
  'timeLapse',
  'timeLapseFrom',
  'timeLapseTo'
];

/**
 * Maps dashboard route param key to export entity
 */
export const dashboardExportEntityMap = {
  'organizations': 'Organization'
};
