import React, { Component } from 'react';
import { I18n, Translate } from 'react-redux-i18n';
import { Modal, Form, Button } from 'semantic-ui-react';
import { connect } from 'react-redux';
import WizardRadioFieldWithImage from './wizardRadioWithImage';
import {
  selectChildTemplate,
  selectParentTemplate,
  changeChildCreateWindowVisible
} from '../../store/campaignActions';

class CreateChildrenModal extends Component {
  onChange = (value) => {
    this.props.selectTemplate(value);
  };

  onNext = () => {
    this.props.onCommand(this.props.parent, this.props.value);
  };

  onClose = () => {
    this.props.closeWindow();
    this.props.clearTemplate();
  };

  getChildTemplates(parentKey) {
    return this.props.data.children[parentKey]
      ? this.props.data.children[parentKey].map((item) =>
          Object.assign({}, { key: item }, this.props.data.items[item])
        )
      : [];
  }

  render() {
    const data = this.getChildTemplates(this.props.parent);

    return (
      <Modal
        className="wizard"
        size="tiny"
        dimmer="inverted"
        onClose={this.onClose}
        open={this.props.open}
      >
        <Modal.Header>
          <div className="button-wrapper align-right">
            <Button
              icon="close"
              circular
              color="grey"
              compact
              size="mini"
              onClick={this.onClose}
              className="close"
            />
          </div>
          <Translate value="campaign.create.modal2.title" />
        </Modal.Header>
        <Modal.Content>
          <Form>
            <div className="wizard-items-with-image-container">
              {data.map((item) => {
                const imagePath = require('../../../../images/campaignIcons/' +
                  item.key +
                  '.svg');
                return (
                  <div className="wizard-item-with-image">
                    <WizardRadioFieldWithImage
                      icon={imagePath}
                      key={item.key}
                      value={item.key}
                      label={I18n.t(`campaign.create.list.${item.key}.title`)}
                      checked={this.props.value === item.key}
                      onChange={this.onChange.bind(this)}
                    >
                      <div className="input-sub">
                        {item.example && (
                          <a
                            href={item.example}
                            onClick={(event) => event.stopPropagation()}
                          >
                            <Translate value="general.view-example" />
                          </a>
                        )}
                      </div>
                    </WizardRadioFieldWithImage>
                  </div>
                );
              })}
            </div>
            <div className="button-wrapper align-right">
              <Button
                primary
                disabled={this.props.value === null}
                onClick={this.props.onCommand}
                type="button"
              >
                <Translate value="general.get-started" />
              </Button>
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapState = ({ campaign }) => {
  return {
    data: campaign.templates.metadata,
    open: campaign.childWindowVisible,
    value: campaign.childTemplate,
    parent: campaign.parentTemplate
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    closeWindow() {
      dispatch(changeChildCreateWindowVisible(false));
    },
    selectTemplate(value) {
      dispatch(selectChildTemplate(value));
    },
    clearTemplate() {
      dispatch(selectChildTemplate(null));
      dispatch(selectParentTemplate(null));
    }
  };
};

const CreateChildModalContainer = connect(
  mapState,
  mapDispatch
)(CreateChildrenModal);
export default CreateChildModalContainer;
