import React, { Component } from 'react';
import { connect } from 'react-redux';

import * as templateHelper from './templateHelper';

import ValidatedTextArea from '../common/validatedTextArea';

class ManagedTextArea extends Component {
  onValueChange(_evt, obj) {
    if (this.props.onChange) {
      // Apply regex pattern to data.
      if (this.props.pattern && obj.value && obj.value.length > 0) {
        const match = obj.value.match(this.props.pattern);
        if (!match) return;
      }

      this.props.onChange(this.props.path, obj.value);
    }
  }

  render() {
    const label = templateHelper.getTranslation(this.props.label);
    const inputLabel = templateHelper.getTranslation(this.props.inputLabel);
    const placeholder = templateHelper.getTranslation(this.props.placeholder);

    let subtext = templateHelper.getTranslation(this.props.subtext);

    if (!subtext && this.props.subtext) {
      const { key, translationObject } = this.props.subtext;
      subtext = templateHelper.getTranslation(key, translationObject);
    }

    const { validation, value, showValidationIcon, maxLength } = this.props;

    return (
      <ValidatedTextArea
        id={this.props.id}
        label={label}
        inputLabel={inputLabel}
        subtextPosition={this.props.subtextPosition || 'bottom'}
        subtext={subtext}
        value={value || ''}
        pattern={this.props.pattern}
        style={this.props.style || {}}
        placeholder={placeholder}
        onChange={this.onValueChange.bind(this)}
        required={validation && validation.required}
        validation={validation}
        showValidationIcon={showValidationIcon}
        maxLength={maxLength}
      />
    );
  }
}

const mapState = (state, ownProps) => {
  return {
    value: templateHelper.getValue(state.templateBuilderEx, ownProps.path)
  };
};

export const TemplateTextArea = connect(mapState)(ManagedTextArea);
