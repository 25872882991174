import React from 'react';
import update from '../../../helpers/update';

export interface IUseTemplateListItemProps<T extends any = any> {
  index: number;
  path: string;
  onChange?: (index: number, path: string, value: T) => void;
  onDelete?: (index: number) => void;
}

export function useTemplateListItem<T extends any = any>(props: IUseTemplateListItemProps<T>) {
  const getPath = React.useCallback(
    (path: string = '') => `${props.path}.${props.index}.${path}`,
    [props.path, props.index]
  );

  const onChange = React.useCallback(
    (path: string, value: T) => {
      if (!props.onChange) return;
      props.onChange(props.index, path.replace(getPath(), ''), value);
    },
    [props.path, props.index, props.onChange]
  );

  const onDelete = React.useCallback(() => {
    if (!props.onDelete) return;
    props.onDelete(props.index);
  }, [props.index, props.onDelete]);

  return {
    getPath,
    onChange,
    onDelete
  };
}

export interface IUseTemplateListProps<T extends any = any> {
  path: string;
  values?: T[];
  onChange?: (path: string, values: T[]) => void;
  getModel?: () => T;
}

export function useTemplateList<T extends any = any>(props: IUseTemplateListProps<T>) {
  const getValues = React.useCallback(
    () => JSON.parse(JSON.stringify(props.values || [])) as T[],
    [props.values]
  );

  const onChange = React.useCallback(
    (index: number, path: string, value: T) => {
      if (!props.onChange) return;
      const values = getValues();
      const item = (values?.[index] || {}) as T;
      const next = update.set(item, path, value);
      values[index] = next;
      props.onChange(props.path, values);
    },
    [props.path, props.onChange, props.values]
  );

  const onDelete = React.useCallback(
    (index: number) => {
      if (!props.onChange) return;
      const values = getValues();
      values.splice(index, 1);
      props.onChange(props.path, values);
    },
    [props.path, props.onChange, props.values]
  );

  const onAdd = React.useCallback(
    (item?: T) => {
      if (!props.onChange) return;
      const values = getValues();
      if (item) values.push(item);
      else if (typeof props.getModel === "function") values.push(props.getModel());
      else values.push({} as T);
      props.onChange(props.path, values);
    },
    [props.path, props.onChange, props.values]
  );

  return {
    onAdd,
    onChange,
    onDelete
  };
}
