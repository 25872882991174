// React / Redux / Related
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import { bindActionCreators } from 'redux';

// Actions
import * as merchandiseActions from '../reducer';
import * as generalActions from '../../../modules/general';
import * as templateBuilderActions from '../../../modules/template-builder-ex';
import { formBuilderActions } from '../../../components/formbuilder/store';

// Helpers
import { Record as RecordHelper } from '../../../modules/bin/utility';
import get from 'lodash.get';
import { cloneDeep } from 'lodash';
import clear from '../../../components/clear';
import { TemplateOptions } from '../../../components/template/templateOptions';
import update from '../../../helpers/update';

// Components
import { Grid } from 'semantic-ui-react';
import Spinner from '../../../components/common/spinner';
import RecordResultMessage from '../../../components/common/recordResult';
import BasePage from '../../../components/common/basePageView';
import MerchandiseTemplate from '../templateEx';

// Constants
import { merchandiseContract } from '../constants';

class MerchandiseEdit extends Component {
  constructor(props) {
    super(props);
    const { id } = props.match.params;
    props.getRecord(id);
  }

  goToMerchandiseView = () => {
    this.props.history.push('/merchandise');
  };

  onSave = (data) => {
    this.props.updateMerchandise(data);
  };

  clearMessages = () => {
    this.props.clearRecordMessages('recordEdit');
  };

  isAllRecordsReady = () => {
    return RecordHelper.isRecordReady(this.props.record);
  };

  render() {
    if (!this.isAllRecordsReady()) {
      return <Spinner />;
    }

    let merchandise = cloneDeep(get(this.props, 'record.data.data'));

    if (!this.props.isTemplateInited) {
      const formBuilderKey = merchandiseContract.formbuilder;
      const formBuilder = get(merchandise, formBuilderKey);

      const skus = get(merchandise, 'skus') || [];
      const availableSkus = skus.map((sku) => sku.id);

      merchandise = update(merchandise, {
        availableSkus: { $set: availableSkus }
      });

      const templateOptions = new TemplateOptions()
        .setModel(merchandise)
        .isEdit()
        .disable('organizationId');

      this.props.formBuilderActions.setFormBuilderModel(formBuilder);
      this.props.setTemplateModel(templateOptions.getWithRule());
    }

    const isSaving = RecordHelper.isRecordLoading(this.props.recordEdit);

    return (
      <BasePage>
        <RecordResultMessage
          record={this.props.recordEdit}
          onDismiss={this.clearMessages}
          redirectOnSuccess={this.goToMerchandiseView}
        />

        <Grid>
          <Grid.Row>
            <Grid.Column width={5}>
              <h1>
                <Translate value="merchandise.edit.title" /> {merchandise.name}
              </h1>
            </Grid.Column>
          </Grid.Row>
        </Grid>

        <MerchandiseTemplate onSave={this.onSave} isLoading={isSaving} />
      </BasePage>
    );
  }
}

/** Maps the state to properties */
const mapState = ({ merchandise, templateBuilderEx }) => {
  const record = merchandise.record || {};

  return {
    record,
    recordEdit: merchandise.recordEdit,
    isTemplateInited: templateBuilderEx.inited
  };
};

/** Maps the actions to properties */
const mapDispatch = (dispatch) => {
  return {
    ...bindActionCreators(merchandiseActions, dispatch),
    ...bindActionCreators(templateBuilderActions, dispatch),
    ...bindActionCreators(generalActions, dispatch),
    formBuilderActions: bindActionCreators(formBuilderActions, dispatch)
  };
};

/** Connects component to Redux store */
const EditMerchandiseContainer = clear(
  connect(mapState, mapDispatch)(MerchandiseEdit)
);
export default EditMerchandiseContainer;
