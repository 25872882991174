import React from 'react';
import Styles from '../fundraising-resources.module.css';
import { FileUploadIcon } from './thumbnail-image';
import { getImagePath } from '../../../../../helpers/imageUrlHelper';

function useResourceUrl({ item }: any) {
  const hasUrl = new Boolean(item.resource?.url);
  const isUpload = item.resourceType === 'upload';
  const url = !hasUrl
    ? ''
    : isUpload
    ? getImagePath(item.resource?.url)
    : item.resource?.url;

  return {
    url,
    hasUrl
  };
}

interface FundraisingResourceItemProps {
  item: any;
  onEdit?: () => void;
}

export const FundraisingResourceItem: React.FunctionComponent<
  FundraisingResourceItemProps
> = ({ item, onEdit }) => {
  const resource = useResourceUrl({ item });

  return (
    <div className={Styles.ResourcesListItem}>
      <div className={Styles.ResourcesListItemAside}>
        <div className={Styles.ResourcesListItemImage}>
          {item?.placeholder && item?.placeholder?.url ? (
            <img
              className={Styles.ResourcesListItemImg}
              src={
                item.placeholder.url ? getImagePath(item.placeholder.url) : ''
              }
            />
          ) : (
            <div className={Styles.ResourcesListItemPlaceholder}>
              <FileUploadIcon color="#b7b7b7" />
            </div>
          )}
        </div>
        {onEdit ? (
          <div className={Styles.ResourcesListItemEdit} onClick={onEdit}>
            Edit
          </div>
        ) : null}
      </div>
      <div className={Styles.ResourcesListItemContent}>
        <div className={Styles.ResourcesListItemTitle}>{item.name}</div>
        <div className={Styles.ResourcesListItemDescription}>
          {item.description}
        </div>
        {resource.hasUrl ? (
          <a
            className={Styles.ResourcesListItemLink}
            href={resource.url}
            target="_blank"
            rel="noreferrer"
          >
            {resource.url}
          </a>
        ) : null}
      </div>
    </div>
  );
};
